/*************************************************
 * Collider
 * @exports
 * @file ObservabilityAction.js
 * @author Prakash // on 05/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../../components/ApiCalls'

import {
	LIST_DISK_DETAILS,
	GET_DISK_DETAILS,
	GET_DISK_METRICS,
	GET_QUEUE_METRICS,
	GET_CLUSTER_METRICS,
	GET_CLUSTER_HEAD_NODE_METRICS,
	LIST_TROUBLESHOOT_RESULTS,
	LIST_ALERTS,
	GET_ALERTS_DAILY_TREND
} from '../../config'
import { refreshPage } from '../../utils/utility';
// import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
// import { ACTION_TYPES } from '../types'

//import Cookies from 'js-cookie'

/**
 * Action to list-disk-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listDiskDetails = (params) => {
	return async () => {
		try {

			const url = LIST_DISK_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-disk-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getDiskDetails = (params) => {
	return async () => {
		try {

			const url = GET_DISK_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-disk-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getDiskMetrics = (params) => {
	return async () => {
		try {

			const url = GET_DISK_METRICS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-queue-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueueMetrics = (params) => {
	return async () => {
		try {

			const url = GET_QUEUE_METRICS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-cluster-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getClusterMetrics = (params) => {
	return async () => {
		try {

			const url = GET_CLUSTER_METRICS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-cluster-head-node-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getClusterHeadNodeMetrics = (params) => {
	return async () => {
		try {

			const url = GET_CLUSTER_HEAD_NODE_METRICS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

// Action to fetch troubleshoot results
export const listTroubleshootResults = (params) => {
	return async () => {
		try {

			const url = LIST_TROUBLESHOOT_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
};
/**
 * Action to list-alerts
 * @param {Object} body
 * @param {Function} callback
 */
export const listAlerts = (params) => {
	return async () => {
		try {

			const url = LIST_ALERTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}
/**
 * Action to get-alerts-daily-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getAlertsDailyTrend = (params) => {
	return async () => {
		try {

			const url = GET_ALERTS_DAILY_TREND;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}