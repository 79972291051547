import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import moment from 'moment';

import { getAccountNameFromId, getRegionName, momentDateGivenFormat } from '../../utils/utility';
import { DURATION_OPTIONS } from '../../utils/constants'
import { listAllProviders, listClusterFilters, listAllProjects, listUsers, setCommonPropsDetails } from '../../actions/commonActionNew'
import { listQueues } from '../../actions/Collider/ClusterAction'
import MultiSelectSection from '../common/MultiSelectSection';



const AdvancedFilters = ({ selectedFilters, filterState, closeSidePanel }) => {
    const durationRef = useRef()
    const [state, setState] = useState({
        selectedProvider: filterState.selectedProvider,
        selectedAccount: filterState.selectedAccount,
        selectedRegion: filterState.selectedRegion,        
        selectedDuration: filterState.selectedDuration,
        datePickerStartDate: filterState.datePickerStartDate,
        datePickerEndDate: filterState.datePickerEndDate,
        selectedClusters: filterState.selectedClusters,
        selectedQueue: filterState.selectedQueue,
        selectedUser: filterState.selectedUser,
        selectionRange: [{
            startDate: filterState.datePickerStartDate,
            endDate: filterState.datePickerEndDate,
            key: 'selection',
        }]
    })

    const [dateState, setDateState] = useState([{
        startDate: new Date(),
        endDate: null,
        key: 'selection'
    }]);

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const usersList = useSelector(state => state?.filters?.usersList);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callClusterList: true, callQueueList: true, callUserList: true 
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, providers: response, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''), callClusterList: true, callQueueList: true, callUserList: true }));
                }
            })
        }
    }, [dispatch, providers]);

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }

            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjRegions, state.selectedAccount, state.selectedProject, state.selectedProjectTags]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response }))
                        }
                    });
            }
        }
    }, [state.selectedProvider, dispatch, propProjects]);

    useEffect(() => {
        if (state.callClusterList) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response,
                            filteredClusters: response,
                            selectedClusters: "",
                            callClusterList: false
                        }));
                    }
            })
        }
    }, [dispatch, state.callClusterList, state.selectedProvider, state.selectedAccount, state.selectedRegion])

    useEffect(() => {
        if (state.callQueueList) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response, selectedQueue: "", callQueueList: false }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        if (state.callUserList) {
            let params = {};
            if(!usersList || !usersList.length) {
                dispatch(listUsers(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, usersList: response }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, usersList: usersList }));
            }
        }
    }, [dispatch, state.callUserList, usersList])
  
    useEffect(() => {
      const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)
  
      let datePickerStartDate = ''
      let datePickerEndDate = ''
      if(state.selectedDuration === "currentMonth") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "currentYear") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "lastMonth") {
        datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
        datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "quarterly") {
        if (currentMonth >= 0 && currentMonth <= 2) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
        } else if (currentMonth > 2 && currentMonth < 6) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
        } else if (currentMonth > 5 && currentMonth < 9) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
        } else {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
      } else if(state.selectedDuration === "half-yearly") {
        if (currentMonth >= 0 && currentMonth <= 5) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
        } else {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
      } else if(state.selectedDuration === "yearly") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
      }
      if(state.selectedDuration !== 'custom') {
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
  
        let dateSelection= {
          startDate: new Date(datePickerStartDate),
          endDate: new Date(datePickerEndDate),
          key: 'selection'
        }
        setDateState([dateSelection])
      }
  
    }, [state.selectedDuration])

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                selectedDuration: "custom",
            }));    
        }

        setDateState([date.selection])
    };

    const removeItem = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: state[field].filter(e => e !== value) }))
    }

    const onSearch = () => {
        let obj = {
            selectedProvider: state.selectedProvider,
            selectedAccount: state.selectedAccount ? state.selectedAccount : [],
            selectedProject: state.selectedProject ? state.selectedProject : '',
            selectedProjectTags: state.selectedProjectTags ? state.selectedProjectTags : [],
            selectedRegion: state.selectedRegion ? state.selectedRegion : [],
            selectedClusters: state.selectedClusters ? state.selectedClusters : [],
            selectedQueue: state.selectedQueue ? state.selectedQueue : [],
            selectedUser: state.selectedUser ? state.selectedUser : [],
            datePickerStartDate: state.datePickerStartDate,
            datePickerEndDate: state.datePickerEndDate
        }

        selectedFilters(obj)
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 9999999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            <div className='search-content bg-dark2 w-60 overflow-auto'>
                <div className='header-search bd-highlight bg-black d-flex justify-content-between p-2'>
                    <div className='flex-fill bd-highlight text-white'>
                        <h5>Advanced Searh</h5>
                        <div className='mr-2 d-flex'>
                        </div>
                    </div>

                    <div className='text-right flex-fill bd-highlight align-self-center'>
                        <i className='far fa-times fa-lg text-white cursorPointer' onClick={() => closeSidePanel()}></i>
                    </div>
                </div>
                <div className='m-4'>
                    <div className='d-flex mt-2 pt-2'>
                        <div className='py-1 w-50'>
                            <div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Provider</p>
                                    {state.inputValidationError && !state.selectedProvider ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    if(!state.isProviderOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                    }
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedProvider ? state.selectedProvider : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.isProviderOpen ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["provider_name", "provider_name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.providers}
                                                selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedQueue: [] }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        <div className='py-1 pl-3 w-50'>
                            <div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Project</p>
                                </div>
                                <div onClick={(event) => {
                                    if(!state.isProjectOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isProjectOpen")
                                    }
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.selectedProject ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedProject ? state.selectedProject : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.isProjectOpen ?
                                        <div className='position-relative'>
                                            <MultiSelectSection
                                                fields={["project", "project"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.projects}
                                                selectedValues={state.selectedProject ? state.selectedProject : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, 
                                                            selectedProject: value,
                                                            selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : []
                                                        }))
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                        <div className='py-1 w-50'><div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Account</p>
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showAccount")
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showAccount ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["account_id", "account_name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.accounts}
                                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedAccount: value, callClusterList: true, callQueueList: true }))
                                                }}
                                                singleSelection={false}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            {state.selectedAccount && state.selectedAccount.length ? 
                                state.selectedAccount.map(row => {
                                    return(
                                        <span key={row} className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{getAccountNameFromId(row, state.accounts)}
                                            <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeItem('selectedAccount', row) } ></i>
                                        </span>
                                    )
                                })
                            : null}
                        </div>
                        <div className='py-1 pl-3 w-50'>
                            <div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Region</p>
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showRegion")
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showRegion ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["region", "name"]}
                                                options={state.regions}
                                                className={'w-70'}
                                                removeTop={true}
                                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true, callQueueList: true }))
                                                }}
                                                singleSelection={false}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            {state.selectedRegion && state.selectedRegion.length ? 
                                state.selectedRegion.map(row => {
                                    return(
                                        <span key={row} className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{getRegionName(row, state.regions)}
                                            <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeItem('selectedRegion', row) } ></i>
                                        </span>
                                    )
                                })
                            : null}
                        </div>
                    </div>

                    <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                        <div className='py-1 w-50'>
                            
                        <div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Cluster</p>
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showCluster")
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.selectedClusters && state.selectedClusters.length ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedClusters && state.selectedClusters.length ? state.selectedClusters.length+' Selected' : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showCluster ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                // fields={["account_id", "account_name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.clusters}
                                                selectedValues={state.selectedClusters ? state.selectedClusters : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedClusters: value, callQueueList: true }))
                                                }}
                                                singleSelection={false}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            {state.selectedClusters && state.selectedClusters.length ? 
                                state.selectedClusters.map(row => {
                                    return(
                                        <span key={row} className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{row}
                                            <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeItem('selectedClusters', row) } ></i>
                                        </span>
                                    )
                                })
                            : null}
                        </div>
                        <div className='py-1 pl-3 w-50'>
                            <div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Queue</p>
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showQueue")
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.selectedQueue && state.selectedQueue.length ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedQueue && state.selectedQueue.length ? state.selectedQueue.length+' Selected' : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showQueue ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                // fields={["region", "name"]}
                                                options={state.queues}
                                                className={'w-70'}
                                                removeTop={true}
                                                selectedValues={state.selectedQueue ? state.selectedQueue : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedQueue: value }))
                                                }}
                                                singleSelection={false}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            {state.selectedQueue && state.selectedQueue.length ? 
                                state.selectedQueue.map(row => {
                                    return(
                                        <span key={row} className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{row}
                                            <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeItem('selectedQueue', row) } ></i>
                                        </span>
                                    )
                                })
                            : null}
                        </div>
                    </div>

                    <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                        <div className='py-1 w-50'>
                            <div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">User</p>
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showUser")
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.selectedUser && state.selectedUser.length ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedUser && state.selectedUser.length ? state.selectedUser.length+' Selected' : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showUser ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                // fields={["account_id", "account_name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.usersList}
                                                selectedValues={state.selectedUser ? state.selectedUser : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedUser: value }))
                                                }}
                                                singleSelection={false}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            {state.selectedUser && state.selectedUser.length ? 
                                state.selectedUser.map(row => {
                                    return(
                                        <span key={row} className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{row}
                                            <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeItem('selectedUser', row) } ></i>
                                        </span>
                                    )
                                })
                            : null}
                        </div>
                        <div className='py-1 pl-3 w-50'>
                            <div className='w-60'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Duration</p>
                                </div>
                                <div onClick={(event) => {
                                    if(state.showDuration) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "showDuration")
                                    }
                                }}>
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-mediumDarkGray p-2 rounded-5 ${state.datePickerStartDate ? 'text-white' : 'placeholder'}`}>
                                        {state.datePickerStartDate ?
                                            <span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
                                        : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showDuration ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["value", "label"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={DURATION_OPTIONS}
                                                selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                                callbackMultiSelect={(value) => {
                                                    if(typeof(value) === 'string') {
                                                        if(value !== 'custom') {
                                                            setState(prevState => ({ ...prevState, selectedDuration: value }))
                                                        } else {
                                                            setState(prevState => ({ ...prevState, showDateRangePicker: value === 'custom' ? true : false }))
                                                        }
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}
                                                singleSelection={true}
                                                hideSearch={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                                {state.showDateRangePicker ?
                                <div className="position-absolute z999"  ref={durationRef}
                                    // style={{"z-index": "999999999","right": "20px","height": "450px"}} 
                                
                                >
                                    <div className="RangePickerWithStartEndTime text-black5 pt-2 ml-n1">
                                        {/* <div className='d-flex justify-content-between'>
                                            <p className="mb-2 w-100 text-white">Date Picker</p>
                                            <i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}></i>
                                        </div> */}
                                        <div className='headerDateRange' style={{'z-index': '999999999','right': '30px', 'top': '50px'}}>
                                            <DateRange
                                                editableDateInputs={true}
                                                onChange={handleSelect}
                                                moveRangeOnFirstSelection={false}
                                                ranges={dateState}
                                            />
                                        </div>
                                        <div className={`d-flex flex-wrap pt-2 border-top displayNone`}>
                                            <span className={`mr-2 f12 align-self-center text-white`}>Last</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+7d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }))}>1 Week</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+14d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }))}>2 Week</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+30d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }))}>1 Month</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+60d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }))}>2 Month</span>
                                            <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+90d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }))}>3 Months</span>
                                        </div>
                                        <div className='d-flex justify-content-end pt-2 mt-2 border-top displayNone'>
                                            <button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            : null}
                            </div>
                        </div>
                    </div>

                    <div className='fixed-buttons justify-content-end bg-transparent border-0'>
                        <div className='align-self-start'>
                            <div className='d-flex'>
                                {state.pageType !== "View" ?
                                    <button className={`btn btn-primary mr-2`} 
                                        onClick={onSearch}
                                    >
                                        Search
                                    </button>
                                : null}
                                <button className={`btn btn-secondary mr-2`} 
                                    // onClick={navigateTo}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdvancedFilters;