import React, { useState } from 'react';
import { useTable, useRowSelect } from 'react-table';

const FileManagerTable = ({
  columns,
  data,
  loading,
  onRowSelect,
  selectedColor = false,
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(false)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
      data
    },
    useRowSelect
  );

  const onRowClick = (row) => {
    // if (row.original.type === 'directory') {
      onRowSelect(row.original, `${row.original.current_directory}/${row.original.url}`);
    // }
  };

  const renderTableSkeleton = () => {
    return <>
      <div className="data-loading text-center">
        <i className="fa fa-cloud-download fa-3x mb-2"></i>
        <span className="d-block">Loading...</span>
      </div></>
	}

  const renderNoData = () => {
		return (
			<>
        <table {...getTableProps()} className="table table-sm table-hover text-white">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <tr>
              <td className="text-center" colSpan={5}>This folder is empty.</td>
            </tr>
          </tbody>
        </table>
      </>
		)
	}

  return (
    <>
      {loading ? (
				renderTableSkeleton()
			) : (
        <>
        {(!data || !data.length)  ? (
          renderNoData()
          ) : (
            <>
              <div className="table-responsive mb-4">
                <table {...getTableProps()} className="table table-sm table-hover text-white">
                  <thead>
                    {headerGroups.map(headerGroup => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr 
                          className={`${selectedRowIndex === i ? (selectedColor ? selectedColor : 'bg-lightGray') : ''} w-100`}
                          {...row.getRowProps()} onClick={() => {
                            setSelectedRowIndex(i)
                            onRowClick(row)
                          }}
                        >
                          {row.cells.map(cell => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default FileManagerTable