import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { listWorkstationCatalogDetails, subscribeCatalog, getCatalogFilters } from '../../actions/CatalogAction'
 import { Store as CommonNotification } from 'react-notifications-component';
import windows from '../../assets/images/windows-pngwing.png'
import linux from '../../assets/images/linux-pngwing.png'
import aws from '../../assets/images/aws-pngwing.png'
import gcp from '../../assets/images/gcp-pngwing.png'
import ubuntu from '../../assets/images/ubuntu-pngwing.png'
import CatalogDetailsSidePanel from './CatalogDetailsSidePanel';
import Search from '../common/SearchWithHiddenInput'
import RequestWorkstationSidePanel from './RequestWorkstationSidePanel';
import { capitalizeFirstLetter } from '../../utils/utility';

const Catalog = (props) => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 9,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    // const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
            userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.catalog_action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].catalog_action
                    }
				} else {
                    if(row.action) {
						actionsAllowed = row.action
					} else if(row.catalog_action) {
						actionsAllowed = row.catalog_action
					}
                }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    const handleCatalogTypeChange = (event) => {
        setState(prevState => ({ ...prevState, showLoading: true, listCatalogs: [], filteredCatalogs: [], nextCatalogToken: '', catalogType: event.target.value, callListWorkstationCatalogDetails: true }))
    }

    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationCatalogDetails: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.callListWorkstationCatalogDetails) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextCatalogToken) {
                params.next_token = state.nextCatalogToken
            }
            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            // params.size = 9
    
            dispatch(listWorkstationCatalogDetails(params))
                .then((response) => {
                    if(response) {
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextCatalogToken) {
                            totalResults = state.listCatalogs &&  state.listCatalogs.length ? state.listCatalogs : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }
                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                        }
                        

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }

                        let json = response.catalog_details ? response.catalog_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                        
                        setState(prevState => ({ ...prevState, listCatalogs: totalResults, filteredCatalogs: totalResults, detailsFormat, selectedFilters: params, nextCatalogToken: response.next_token, showLoading: false, callListWorkstationCatalogDetails: false, startRecord, currentPage, totalPages, totalRecords: response.total ? response.total : 0 }))
                    }
                })
        }
    }, [state.callListWorkstationCatalogDetails, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listCatalogs, state.nextCatalogToken, state.startRecord, state.currentPage, state.perPage, state.catalogType])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextCatalogToken) {
            setState(prevState => ({ ...prevState, showLoading: true, callListWorkstationResults: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.onSubscribe && state.subscribedRow) {
            let params = state.subscribedRow
            // params.subscribed = false
            dispatch(subscribeCatalog(params))
                .then((response) => {
                    setState(prevState => ({
                        ...prevState,
                        subscribed: true,
                        onSubscribe: false,
                        loadingSubscription: false,
                    }))
                    if(response.status) {
                        props.onSubscribe(state.subscribedRow)
                    } else {
                        let messageType = 'danger'
                        let message = response.message
            
                        // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    }
                })
        }

    }, [dispatch, state.onSubscribe, state.subscribedRow, props])

    // const onClickSubscribe = (event, row, index) => {
    //     event.stopPropagation();
    //     setState(prevState => ({ ...prevState, ['loadingSubscription_'+index]: true, loadingSubscription: true, onSubscribe: true, subscribedRow: row }))
    // }

    

    // const launchNICE_DCV = () => {
    //     // Attempt to open the NICE DCV link
    //     window.location.href = "nice-dcv://";
    
    //     // Set a timer to check if the page loses focus (indicating that NICE DCV was not opened)
    //     const timeoutId = setTimeout(() => {
    //         alert("NICE DCV is not installed on your system.");
    //     }, 1000);
    
    //     // Listen for visibility change events to detect if the page loses focus
    //     document.addEventListener('visibilitychange', () => {
    //         if (!document.hidden) {
    //             // Page is visible again, clear the timeout
    //             clearTimeout(timeoutId);
    //         }
    //     });
    // };

    return (
        state.showLoading ?
            <div className='d-flex justify-content-center m-4'>
                <Spinner className='text-center' color='light' />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <CatalogDetailsSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedDetails={state.selectedDetails}
                />
            : null}
            {state.showRequestSection && Object.entries(state.selectedCatalog).length ?
                <RequestWorkstationSidePanel
                    closeSidePanel={() => setState(prevState => ({...prevState, showRequestSection: false, selectedCatalog: {} }))}
                    selectedCatalog={state.selectedCatalog}
                    catalogTypes={state.catalogResponse}
                    catalogType={state.catalogType}
                />
            : null}
            <div className='p-2 h-100 overflowYAuto'>
                <div className='d-flex justify-content-between mb-2'>
                    <p className='mb-0 align-self-center'>
                        <small>Showing {state.filteredCatalogs && state.filteredCatalogs.length} out of total {state.totalRecords} catalog(s)</small>
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{state.selectedFilters.provider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <small className='ml-1 mr-2'>with the account</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.account_id}</small>
                            {state.selectedFilters.account_id.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <small className='ml-1'>in the</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.region[0]}</small>
                            {state.selectedFilters.region.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            <small className='ml-1'>region</small>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='d-flex mr-2 justify-content-end w-50'>
                        <div className={`transparentSelectBorderBottom align-self-end mr-2`}>
                            <select className="form-control" onChange={handleCatalogTypeChange} value={state.catalogType}>
                                {/* <option key="All" value="All">ALL</option> */}
                                {state.catalogTypes && state.catalogTypes.map((cat) => {
                                    return(
                                        <option key={cat.value} value={cat.value}>{cat.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {state.listCatalogs && state.listCatalogs.length ? 
                            <div className='d-flex justify-content-end w-40'>
                                {state.totalRecords > state.perPage ?
                                    <div className='pagination errorPagePagination f12 mr-2'>
                                        <span className='mx-3 text-nowrap'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                        <span className='displayNone'>
                                            Go to
                                            <input
                                                type='number'
                                                value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ''
                                                    this.gotoPage(page)
                                                }}
                                                className='ml-1 inputClass'
                                            />
                                        </span>
                                        <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextCatalogToken ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                    </div>
                                : null}
                                <div className='align-self-center w-100'>
                                    <Search
                                        data={state.listCatalogs ? state.listCatalogs : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-darkGray text-white f12 border-mediumDarkGray'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-lightGray f12'}
                                        searchPlaceHolder={'Search....'}
                                        className={'bg-transparent pl-2 form-control-sm f12 w-92 text-white'}
                                        filteredData={(filteredCatalogs) => {
                                            let totalPages = 1                
                                            if(filteredCatalogs.length > state.perPage) {
                                                totalPages = Math.ceil(filteredCatalogs.length / state.perPage)
                                            }
                                            setState(prevState => ({ ...prevState, filteredCatalogs, totalPages, currentPage: 1, startRecord: 0 }))
                                        }}
                                    />
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
                <div className={`row catalogScreen ${state.loadingSubscription ? 'disabled' : ''}`}>
                    {state.filteredCatalogs && state.filteredCatalogs.length ?
                        state.filteredCatalogs.slice(state.startRecord, state.startRecord + state.perPage).map((row, index) => {
                            return(
                                <div className='col-lg-4 col-md-4 col-sm-4 mb-2 cursorPointer' key={'row_'+(state.startRecord + index)}>
                                    <div className='bg-darkGray rounded' style={{minHeight: '260px', maxHeight: '260px'}} onClick={(e) => {
                                        setState(prevState => ({ ...prevState, selectedDetails: row }))
                                    }}>
                                        <div className={`w-100 bg-${row.category ? (row.category === 'Virtual Workstation' ? 'gold' :  row.category) : 'black'}`}>
                                            <p className={`py-2 text-center ${(row.category === 'bronze') ? 'text-white' : 'text-black'}`}>{row.category ? capitalizeFirstLetter(row.category) : 'Category'}</p>
                                        </div>
                                        <div className='d-flex py-2 px-3 justify-content-between mb-1' >
                                            <div style={{minWidth: '30px', maxWidth: '30px'}} className='align-self-center'>
                                                {row.platform === 'windows' ?
                                                    <img src={windows} className='img-fluid mb-3' alt='WINDOWS' />
                                                :
                                                    <img src={linux} className='img-fluid mb-3' alt='LINUX'/>
                                                }
                                                {row.provider === 'aws' || row.provider === 'AWS' ?
                                                    <img src={aws} className='img-fluid mb-3' alt='AWS' />
                                                :
                                                    <img src={gcp} className='img-fluid mb-3' alt='GCP' />
                                                }
                                                <img src={ubuntu} className='img-fluid' alt='UBUNTU' />
                                            </div>
                                            <div className='ml-3 w-90'>
                                                {/* <div className='d-flex justify-content-end mb-2'>
                                                    <span className={`align-self-center badge badge-outline-${row.subscribed ? 'info' : 'danger'} align-self-start aiopsBadgeWidth`} >{row.subscribed ? 'Subscribed' : 'Not Subscribed'}</span>
                                                </div> */}
                                                <p className='mb-2'>
                                                    Name <span className='text-white ml-2'>{row.image_name}</span>
                                                </p>
                                                <p className='mb-2'>
                                                    Os <span className='text-white ml-2'>{row.platform}</span>
                                                </p>
                                                <p className='mb-2'>
                                                    <span className='mr-1'>Resources </span>
                                                    <span className='text-white'>
                                                        {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                        {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                        {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                                    </span>
                                                </p>
                                                <p className='mb-2 hiddenEllipsesChar'>
                                                    Description <span className='text-white ml-2'>{row.description}</span>
                                                </p>

                                                <p className='mb-2 d-flex displayNone'>
                                                    <span className='mr-1'>Capacity</span>
                                                    <span className='text-white'>{row.instance_type} </span>
                                                    <span className='text-white ml-2'>(
                                                        {row.vcpus} vCPU,
                                                        {row.memory} GB memory 
                                                    )</span>
                                                </p>
                                                <div className='d-flex justify-content-between mt-3' style={{minHeight: '31px'}}>
                                                    <p className='mb-0 text-cyon align-self-center moveBottomLeft' 
                                                        onClick={(e) => {
                                                            setState(prevState => ({ ...prevState, selectedDetails: row }))
                                                        }}
                                                    >Details</p>
                                                    {/* <div className=' d-flex justify-content-center mt-4' onClick={() => launchNICE_DCV()}>Connect via NiceDCV
                                                    </div> */}
                                                    {state.actionsAllowed && state.actionsAllowed.includes('request') ?
                                                        <button
                                                            className={`btn btn-sm bg-green text-white moveBottomRight`}
                                                            // onClick={(event) => onClickSubscribe(event, row, index)}
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                setState(prevState => ({ ...prevState, selectedCatalog: row, selectedIndex: index, showRequestSection: true }))
                                                            }}
                                                            >
                                                            {state.loadingSubscription && state['loadingSubscription_'+index] ? (
                                                                <React.Fragment>
                                                                    {'Subscribing'}
                                                                    <Spinner className='text-center ml-2' color='light' size='sm' />{' '}
                                                                </React.Fragment>
                                                            ) : 'Request'
                                                            }
                                                        </button>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='d-flex justify-content-center m-4'>
                            There are no data on this criteria. Please try adjusting your filter.
                        </div>
                    }
                </div>
            </div>
            </React.Fragment>
    );
};

export default Catalog