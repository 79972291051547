/*************************************************
 * Collider
 * @exports
 * @file UserFieldDetails.js
 * @author Prakash // on 1/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React from 'react';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../../utils/utility';

export const UserFieldDetails = ({ detailsFormat, selectedDetails, selectedType }) => {
    return (
        <div className='m-2 flex-grow-1 overflow-auto'>
            <div className="rounded bg-dark3 p-3">
                {detailsFormat && detailsFormat.length ? 
                    detailsFormat.map((row, index) => {
                        return(
                            <div className={`${!index ? '' : 'mt-2'}`}>
                                <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                <small>{row.description}</small>
                                {row.fields && row.fields.map(fld => {
                                    return(
                                        <React.Fragment>
                                            {selectedDetails && selectedDetails[fld] ?
                                                <div className="d-flex mt-3">
                                                    <span className="minWidth220 mr-3 align-self-center">{removeUnderScore(fld)}</span>
                                                    {typeof selectedDetails[fld] === 'boolean' ?
                                                        <span className={`text-white`}>{selectedDetails[fld] ? 'True' : 'False'}</span>
                                                    : isValidDateTime(selectedDetails[fld]) ?
                                                        <span className="text-white">{momentConvertionUtcToLocalTime(selectedDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                    : convertToLowerCase(fld) === "status" ?
                                                        <span className={`align-self-center badge bg-mulbery text-white`}>{selectedDetails[fld]}</span>
                                                    : convertToLowerCase(fld) === "tags" ?
                                                        <div calss="d-flex flex-wrap">
                                                            {selectedDetails[fld].map(item => {
                                                                return(
                                                                    <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                )
                                                            })}
                                                        </div>
                                                    : Array.isArray(selectedDetails[fld]) ?
                                                        <div className='w-100 mr-2'>
                                                            <div calss="d-flex flex-wrap">
                                                                {selectedDetails[fld].map((item, itIndex) => {
                                                                    return(
                                                                        typeof item === 'string' ?
                                                                            <span key={"tak_key"+item.key} className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                        : typeof item === 'object' ?
                                                                            <div className='p-3 bg-dark rounded w-100 mr-2 mt-2'>
                                                                                {Object.entries(item).map(([iKey, iValue], iiIndex) => {
                                                                                    return(
                                                                                        typeof iValue === 'boolean'?
                                                                                            <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 f13 mt-3'>{iKey} <span className='text-white ml-3'>{iValue ? 'True' : 'False'}</span></p>
                                                                                        :
                                                                                            <p key={itIndex+'_key_'+iiIndex} className='mr-2 mb-1 f13'>{iKey}: <span className='text-white ml-3'>{iValue}</span></p>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        : null
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    : Array.isArray(selectedDetails[fld]) ?
                                                            <div calss="d-flex flex-wrap">
                                                                {selectedDetails[fld].map(item => {
                                                                    return(
                                                                        <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item}</span>
                                                                    )
                                                                })}
                                                            </div>
                                                    :
                                                        <span className="text-white">{fld === 'provider' ? selectedDetails[fld][0].toUpperCase() : selectedDetails[fld]}</span>
                                                    }
                                                </div>
                                            : null}
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        )
                    })
                :
                    <div className='d-flex justify-content-center m-4'>
                        There are no data for user.
                    </div>
                }
            </div>
        </div>
    )
}