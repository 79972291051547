/*************************************************
 * Collider
 * @exports
 * @file SearchComponent.js
 * @author Rajasekar // on 02/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import { InputGroup } from 'reactstrap'

class Search extends Component {
	constructor(props) {
		super(props)
		this.inputRef = React.createRef()
		this.state = { 
			searchText: '',
			hideInputSection: this.props.hideInputSection
		}
	}

	componentDidMount = () =>{}

	componentDidUpdate = (prevProps) => {
		if(this.props.resetSearchBox && this.props.resetSearchBox !== prevProps.resetSearchBox) {
			this.setState({ searchText: '' }, () => this._handleSearch())
		}
	}

	/**
	 * Handles search using the search text
	 */
	_handleSearch = () => {
		let currentList = []
		let newList = []
		if (this.state.searchText !== '') {
			currentList = this.props.data
			if(Array.isArray(currentList) && currentList.every(item => typeof item === 'string')) {
				let searchTerm = this.state.searchText.toLowerCase();
				newList = currentList.filter(e => e.toLowerCase().includes(searchTerm));
			} else {
				newList =
					currentList &&
					currentList.filter(item => {
						let isPresent = []
						isPresent = this.recursiveSearch(item, this.state.searchText)
							.flat(Infinity)
							.filter(bool => bool === true)  // for multi arrray [[1,2],[3,4],[4,5]]
						if (isPresent[0]) {
							return true
						} else {
							return false
						}
					})
			}
		} else {
			newList = this.props.data
			this.props.filteredData(newList, this.state.searchText)			
		}
		if (newList) {
			this.props.filteredData(newList, this.state.searchText)
		}
	}

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			// console.log(item[key], typeof item[key])
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

	render() {
		return (
			<div id={this.props.ID} className={`input-group ${!this.props.applyTags && !this.props.applyLiteDarkTags ? 'dark-feild' : ''} ${this.props.topClassName}`}>
				{this.props.applyTags ? (
						<div className='form-group mb-0'>
							<input
								type='text'
								value={this.state.searchText}
								onChange={e => {
									this.setState({ searchText: e.target.value }, () => this._handleSearch())
								}}
								className='form-control form-control-sm'
								placeholder={this.props.searchPlaceHolder && this.props.searchPlaceHolder !== '' ? this.props.searchPlaceHolder : 'Search'}
							/>
						</div>
				) : (
					<div className='d-flex w-100'>
						<div className={`input-group-prepend ${this.props.InputGroupPretendWidth ? this.props.InputGroupPretendWidth : ""}`}>
							<div className={`input-group-text bg-transparent ${this.state.hideInputSection ? '': ''} ${this.props.searchClassName}`}>
								<i className={`fal fa-search cursorPointer ${this.props.searchIconColor} `} 
									onClick={() => 
										this.setState({ hideInputSection: !this.state.hideInputSection },
											() => {
												this.inputRef.current.focus()
											}	
										)
									} />
							</div>
						</div>
						<div className={`input-enclose ${this.props.inputEncloseClass ? this.props.inputEncloseClass : "w-100"} ${this.state.hideInputSection ? 'displayNone': ''}`}>
							<input
								value={this.state.searchText}
								ref={this.inputRef}
								onChange={e => {
									this.setState({ searchText: e.target.value }, () => this._handleSearch())
								}}
								type='text'
								className={`form-control ${this.props.className ? this.props.className : ''}`}
								placeholder='Search'
							/>
						</div>
					</div>
				)}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Search.propTypes = {
	data: PropTypes.array,
	filteredData: PropTypes.func,
	applyTags: PropTypes.bool,
}

export default Search
