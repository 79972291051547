// App.js
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWrapper from './common/AppWrapper';
import { AppRoutes } from '../routes'; // Import your separate route configuration
import '../assets/fontawesome5/css/all.min.css'
// import '../assets/css/bootstrap.css'
import '../assets/css/bootstrap.min.css'
import '../assets/css/style.scss'
import '../assets/css/dev_style.scss'
import '../assets/css/collider.scss'
import '../assets/css/dev.css'

// MSAL imports
// import { MsalProvider } from "@azure/msal-react";
import { AuthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { loginRequest, msalConfig } from '../authConfig'

import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);


const WrappedView = () => {
    const { instance } = useMsal()

    const handleRedirect = () => {
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('ACCESS_TOKEN_EXPIERS_ON');
        instance.loginRedirect({...loginRequest})
            .catch((error) => console.log(error))
    };

    const account = msalInstance.getAllAccounts()[0];
    if(!account) {
        handleRedirect()
    }
    
    return (
        <React.Fragment>
            <AuthenticatedTemplate>
                <Router>
                    <AppWrapper>
                        <AppRoutes />
                    </AppWrapper>
                </Router>
            </AuthenticatedTemplate>
        </React.Fragment>
    )
}

function App({ msalInstance }) {  
    return (
        <MsalProvider instance={msalInstance}>
            <WrappedView />
        </MsalProvider>
    );
}

export default App