import React, { useCallback, useEffect, useState } from 'react';
import FileUploadModal from './FileUploadModal';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Store as CommonNotification } from 'react-notifications-component';
import { createFolder, deleteFile, downloadFile, getFileContent, extractFile, renameFile } from '../../../../actions/files/FileAction';
import EditFile from './EditFile';
import { setCommonPropsDetails } from '../../../../actions/commonActionNew';
import { noSpace } from '../../../../utils/utility';

export default function FileActions({ handleFileBrowserRefresh, state, getLastObjectFromBreadcrumb, fromScreen, selectedFile }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newState, setNewState] = useState({
    showDeleteLoading: false
  })

  const dispatch = useDispatch()

  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);
  const doubleClickOpenFile = useSelector(state => state?.filters?.commonPropsDetails?.doubleClickOpenFile || false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const createFolderFunction = () => {
    setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: true, hasError: false }))
    let params = {}
    params.folder_name = newState.folder_name
    let currentPath = currentLocation.path ? currentLocation.path : ''
    params.path = currentPath+'/'+newState.folder_name
    params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''
    params.cluster_name = state.selectedClusters
    if(currentLocation.owner) {
      params.owner = currentLocation.owner
    }
    if(newState.folder_name) {
      dispatch(createFolder(params))
        .then((response) => {
          if(response && !response.error) {
            setNewState(prevNewState => ({ ...prevNewState, folderResponse: response }))
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Folder Creation Failed"

            if(response.status) {
              message = response && response.data && response.data.message ? response.data.message : "Folder Created Successfully"
                messageType = 'success'
                setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, showCreateLoading: false, folder_name: "" }))
                // toggle()
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: false }))}, 1000)
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showCreateLoading: false }))
            }


            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    } else {
      setNewState(prevNewState => ({ ...prevNewState, hasError: true, showCreateLoading : false }))
    }
  }

  const handleFileRename = () => {
    if(state.statsDetail && state.statsDetail.data && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.old_path = data.path

      let fileName = params.old_path;
      // Split the file name into parts based on the "/"
      // let fileType = fileName.substring(fileName.lastIndexOf(".") + 1);
      // Remove the last string (file name)
      let newPath = fileName.substring(0, fileName.lastIndexOf("/"));
      // Add the new file name to the end

      let newFileName = newPath
      newFileName += '/'+newState.fileName
      // newFileName += '.'+fileType
      params.new_path = newFileName
      // setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false }))
      dispatch(renameFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "File rename failed"

            if(response.status) {
              message = response && response && response.message ? response.message : "file renamed successfull"
                messageType = 'success'
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false, renameModal: false }))}, 1000)
                handleFileBrowserRefresh()
                // if(response.data && response.data.result) {
                //   let fileUrl = response.data.result
                //   window.open(fileUrl, '_blank')
                // }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileExtract = () => {
    if(state.statsDetail && state.statsDetail.data && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      // if(data.owner) {
      //   params.owner = data.owner
      // }
      dispatch(extractFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Extract failed"

            if(response.status) {
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showExtactLoading: false }))}, 1000)
                message = response && response.message ? response.message : "Extracted"
                messageType = 'success'
                handleFileBrowserRefresh()
                // if(response.data && response.data.result) {
                //   let fileUrl = response.data.result
                //   window.open(fileUrl, '_blank')
                // }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showExtactLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileDownload = () => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      dispatch(downloadFile(params))
        .then((response) => {
          if(response && !response.error) {
            let messageType = 'danger'
            let message = response && response.message ? response.message : "Download Failed"

            if(response.status) {
              message = response && response.data && response.data.message ? response.data.message : "Download initiated Successfully"
                messageType = 'success'
                setTimeout(() => { setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading: false }))}, 1000)
                if(response.data && response.data.result) {
                  let fileUrl = response.data.result
                  window.open(fileUrl, '_blank')
                }
            } else {
                setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading: false }))
            }

            CommonNotification.addNotification({
                //title: 'Wonderful!',
                message: message,
                type: messageType,
                insert: 'top',
                container: 'top-center',
                // animationIn: ['animate__animated', 'animate__fadeIn'],
                // animationOut: ['animate__animated', 'animate__fadeOut'],
                dismiss: {
                    duration: 5000,
                    onScreen: false,
                    pauseOnHover: true,
                    showIcon: true,
                }
            });
          }
        })
    }
  }

  const handleFileEdit = useCallback(() => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      dispatch(getFileContent(params))
        .then((response) => {
          if(response && !response.error) {
            if(response.status) {
              if(!fromScreen) {
                setNewState(prevNewState => ({ ...prevNewState, fileContent: response, showEditLoading: false }))
              } else {
                selectedFile(response)
              }
            } else {
              let messageType = 'danger'
              let message = response && response.message ? response.message : "Download Failed"
                setNewState(prevNewState => ({ ...prevNewState, showEditLoading: false }))
                CommonNotification.addNotification({
                  //title: 'Wonderful!',
                  message: message,
                  type: messageType,
                  insert: 'top',
                  container: 'top-center',
                  // animationIn: ['animate__animated', 'animate__fadeIn'],
                  // animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                      duration: 5000,
                      onScreen: false,
                      pauseOnHover: true,
                      showIcon: true,
                  }
              });
            }            
          }
        })
    }
  }, [dispatch, state, fromScreen, selectedFile])

  const deleteFunction = () => {
    if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
      let data = state.statsDetail.data
      let params = {}
      params.provider = state.selectedProvider.toLowerCase()
      params.account_id = state.selectedAccount ? state.selectedAccount : []
      params.region = state.selectedRegion ? state.selectedRegion : []
      params.cluster_name = state.selectedClusters
      params.path = data.path
      if(data.owner) {
        params.owner = data.owner
      }
      if(params.path) {
          dispatch(deleteFile(params)).then((response) => {
              let messageType = 'success'
              let message = response.data && response.data.message ? response.data.message : 'Error in deleting in file'
              if(!response.status) {   
                  messageType = 'danger'
                  setNewState(prevState => ({ ...prevState, showDeleteLoading: false }))
              } else {
                  message = response.data && response.data.message ? response.data.message : 'File Deleted Successfully'
                  setNewState(prevState => ({ ...prevState, deleteModal: false, showDeleteLoading: false }))
              }

              CommonNotification.addNotification({
                  //title: 'Wonderful!',
                  message: message,
                  type: messageType,
                  insert: 'top',
                  container: 'top-center',
                  // animationIn: ['animate__animated', 'animate__fadeIn'],
                  // animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                      duration: 5000,
                      onScreen: false,
                      pauseOnHover: true,
                      showIcon: true,
                  }
              });
          })   
      } else {
        setNewState(prevState => ({ ...prevState, showDeleteLoading: false }))
      }
    }
  }

  useEffect(() => {
    if(doubleClickOpenFile) {
      dispatch(setCommonPropsDetails('doubleClickOpenFile', false))
      if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showEditLoading) {
        setNewState(prevNewState => ({ ...prevNewState, showEditLoading: true }))
        handleFileEdit()
      }
    }
  },[doubleClickOpenFile, state.statsDetail, handleFileEdit, dispatch, newState.showEditLoading])

  return (
    <div className="row justify-content-between align-items-center">
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end col-3">
        <div className='mb-1'>
          {/* {state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'zip' ? */}
            <button type="button" className={`cursorPointer btn ${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray' : 'bg-lightGray notAllowedCursor text-mediumDarkGray'} btn-sm mr-2`} onClick={() => {
              if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showExtactLoading) {
                handleFileExtract()
                setNewState(prevNewState => ({ ...prevNewState, showExtactLoading : true}))
              }
            }}>
              {newState.showExtactLoading ?
                  <span className='mx-1'>Extracting <Spinner size='sm' color='dark' className='mx-2' /></span>
              : 
                <span><i className='fa fa-envelope-open mr-2'></i>Extract</span>
              }
            </button>
          {/* : null} */}
          <button type="button" className={`cursorPointer btn ${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray' : 'bg-lightGray notAllowedCursor text-mediumDarkGray'} btn-sm mr-2`} onClick={() => {
            if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.name && !newState.renameModal) {
              // handleFileDownload()
              setNewState(prevNewState => ({ ...prevNewState, renameModal: true, fileName: state.statsDetail && state.statsDetail.data && state.statsDetail.data.name ? state.statsDetail.data.name : "" }))
            }
          }}>
              Rename
          </button>
          <button type="button" className={`cursorPointer btn ${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray' : 'bg-lightGray notAllowedCursor text-mediumDarkGray'} btn-sm mr-2`} onClick={() => {
            if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showDownloadLoading) {
              handleFileDownload()
              setNewState(prevNewState => ({ ...prevNewState, showDownloadLoading : true}))
            }
          }}>
            {newState.showDownloadLoading ?
                <span className='mx-1'>Downloading <Spinner size='sm' color='dark' className='mx-2' /></span>
            : 
              <span><i className='fas fa-download mr-2'></i>Download</span>
            }
          </button>
          <button type="button" className={`cursorPointer btn ${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray' : 'bg-lightGray notAllowedCursor text-mediumDarkGray'} btn-sm mr-2`} onClick={() => {
            // if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file') {
              setNewState(prevNewState => ({ ...prevNewState, deleteModal: true }))
            // }
          }}>
            <i className='fas fa-trash mr-2'></i>Delete
          </button>
          <button type="button" className={`cursorPointer btn ${state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' ? 'bg-white text-black border-mediumDarkGray' : 'bg-lightGray notAllowedCursor text-mediumDarkGray'} btn-sm mr-2`} onClick={() => {
            if(state.statsDetail && state.statsDetail.data && state.statsDetail.data.type === 'file' && !newState.showEditLoading) {
              setNewState(prevNewState => ({ ...prevNewState, showEditLoading : true}))
              handleFileEdit()
            }
          }}>
            <div className='d-flex'>
              {newState.showEditLoading ?
                  <span className='mx-1'>Opening <Spinner size='sm' color='dark' className='mx-2' /></span>
              : 
                <span><i className='fas fa-pencil f12 mr-2'></i>Open</span>
              }
            </div>
          </button>
          <button type="button" className={`cursorPointer btn bg-white text-black btn-sm mr-2`} 
            onClick={() => setNewState(prevNewState => ({ ...prevNewState, createModal: true }))}
          >
            Create Folder
          </button>
          <div className="btn-group border-mediumDarkGray" role="group" aria-label="assetComparison">
            <button type="button" className={`cursorPointer btn bg-white text-black btn-sm`} onClick={handleFileBrowserRefresh}><i className="fa fa-sync"></i></button>
            <button type="button" className={`cursorPointer btn bg-white text-black border-mediumDarkGray-left btn-sm`} onClick={toggleModal}><i className='far fa-upload mr-2'></i>Upload</button>
          </div>
          {/* <button className="btn btn-secondary btn-sm mr-3" type="button" onClick={handleFileBrowserRefresh}>
            <i className="fa fa-sync"></i>
          </button>
          <button className="btn btn-secondary btn-sm mr-3" type="button" onClick={toggleModal}>
            <i className="fa fa-upload"></i> Upload
          </button> */}
          <FileUploadModal
            isOpen={modalIsOpen}
            toggle={toggleModal}
            setModalIsOpen={setModalIsOpen}
            handleFileBrowserRefresh={handleFileBrowserRefresh}
            state={state}
            getLastObjectFromBreadcrumb={getLastObjectFromBreadcrumb}
          />
          
          <Modal isOpen={newState.renameModal} toggle={() => setNewState(prevNewState => ({ ...prevNewState, renameModal: !newState.renameModal }))}>
            <ModalHeader toggle={() => setNewState(prevNewState => ({ ...prevNewState, renameModal: !newState.renameModal }))}>Rename FIle</ModalHeader>
            <ModalBody>
            {/* <span>Rename "{state.statsDetail && state.statsDetail.data && state.statsDetail.data.name ? state.statsDetail.data.name : ""}" file</span> */}
            {/* {props.confirmDetails ? props.confirmDetails : 'Please confirm the action'}  */}
              <Input
                  type='text'
                  placeholder={'rename file'}
                  className={`inputTextbox bg-transparent border-lightGray text-black`}
                  // maxlength='128'
                  value={newState.fileName ? newState.fileName : ''}
                  onChange={e => setNewState(prevNewState => ({ ...prevNewState, fileName: noSpace(e.target.value)}))}
              />
            </ModalBody>
            <ModalFooter className="border-0">
            <Button color="primary" onClick={() => {
              setNewState(prevNewState => ({ ...prevNewState, showRenameBtnLoading: true }))
              handleFileRename()
            }}>
                {newState.showRenameBtnLoading ?
                  <Spinner size='sm' color='light' className='mx-2' />
                : null}
                <span>Submit</span>
            </Button>{' '}
            <Button color="secondary" onClick={() => {
                setNewState(prevNewState => ({ ...prevNewState, renameModal: !newState.renameModal, showRenameBtnLoading: false, name: '' }))
            }}>
                Cancel
            </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={newState.createModal} toggle={() => setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal }))}>
            <ModalHeader toggle={() => setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal }))}>Create Folder</ModalHeader>
            <ModalBody>
                <span className={`${newState.hasError ? 'text-danger' : ''}`}> Please enter the folder name</span>
                <Input
                    type='text'
                    placeholder='Enter Folder name'
                    className={`inputTextbox bg-transparent border-lightGray text-black`}
                    // maxlength='128'
                    value={newState.folder_name ? newState.folder_name : ''}
                    onChange={e => setNewState(prevNewState => ({ ...prevNewState, folder_name: e.target.value }))}
                />
                {newState.deleteConfirmMessage ?
                    <small className='text-red'>{newState.deleteConfirmMessage}</small>
                : null}

            </ModalBody>
            <ModalFooter className="border-0">
            <Button color="primary" onClick={() => {
              if(!newState.showCreateLoading) {
                createFolderFunction()
              }
            }}>
                {newState.showCreateLoading ?
                    <Spinner size='sm' color='dark' className='mx-2' />
                : null}Create
            </Button>{' '}
            {!newState.showCreateLoading ?
              <Button color="secondary" onClick={() => setNewState(prevNewState => ({ ...prevNewState, createModal: !newState.createModal, hasError: false, folder_name: '' }))}>
                  Cancel
              </Button>
            : null}
            </ModalFooter>
          </Modal>
          
          <Modal isOpen={newState.deleteModal} toggle={() => setNewState(prevNewState => ({ ...prevNewState, deleteModal: !newState.deleteModal, deleteDetails: {} }))}>
            <ModalHeader toggle={() => setNewState(prevNewState => ({ ...prevNewState, deleteModal: !newState.deleteModal, deleteDetails: {} }))}>Delete</ModalHeader>
            <ModalBody>
              Are you sure you want to delete the file <span className='f18 mx-1'>"{state.statsDetail && state.statsDetail.data && state.statsDetail.data.name ? state.statsDetail.data.name : ''}"</span>
            </ModalBody>
            <ModalFooter className="border-0">
            <Button color="primary" onClick={() => {
                setNewState(prevNewState => ({ ...prevNewState, showDeleteLoading: true }))
                if(!newState.showDeleteLoading) {
                    deleteFunction()
                }
            }}>
              {newState.showDeleteLoading ?
                <Spinner size='sm' color='light' className='mx-2' />
              : null}Ok
            </Button>{' '}
            <Button color="secondary" onClick={() => {
                if(!newState.showDeleteLoading) {
                    setNewState(prevNewState => ({ ...prevNewState, deleteModal: false, showDeleteLoading: false }))
                }
            }}>
              Cancel
            </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
      {newState.fileContent && newState.fileContent.data ?
        <EditFile
          state={state}
          newState={newState}
          // closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
          closeSidePanel={() => setNewState(prevState => ({ ...prevState, fileContent: false }))}
        />
      : null}
    </div>
  );
}