/*************************************************
 * Kiosk
 * @exports
 * @file DetailsSidePanel.js
 * @author Prakash // on 28/11/2023
 * @copyright © 2023 Kiosk. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { ClusterFieldDetails } from './ClusterFieldDetails';
import { QueuesFieldDetails } from './QueuesFieldDetails';
import { UserFieldDetails } from './UserFieldDetails';
import { ProjectFieldDetails } from './ProjectFieldDetails';


const DetailsSidePanel = (props) => {
    const clickOut = useRef();
    const [state, setState] = useState({
        showLoading: false,
        summaryLoader: true,
        selectedTab: 'Details',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: '+3h'
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    const handleClickOutside = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            props.closeSidePanel()
        }
    }

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }} onClick={handleClickOutside}>
            <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div>
            <div className="search-content bg-dark2 w-80 h-100 d-flex flex-column" ref={clickOut}>
                <div className="header-search bd-highlight bg-black d-flex justify-content-between p-2">
                    <div className="flex-fill bd-highlight text-white">
                        <h5>{props.selectedType} Details</h5>
                    </div>

                    <div className="text-right flex-fill bd-highlight align-self-center">
                        <i className="far fa-times fa-lg text-white cursorPointer" onClick={() => props.closeSidePanel()}></i>
                    </div>
                </div>                
                <React.Fragment>
                    <div className="mt-2">
                        <ul className={`nav nav-tabs border-bottom bg-transparent shadow-none`} id="myTab" role="tablist">
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${state.selectedTab === 'Details' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                        </ul>
                    </div>
                    {state.selectedTab === "Details" ?
                        props.selectedType === "Cluster" ?
                            <ClusterFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : props.selectedType === "Queue" ?
                            <QueuesFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : props.selectedType === "User" ?
                            <UserFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : props.selectedType === "Project" ?
                            <ProjectFieldDetails
                                detailsFormat={props.detailsFormat}
                                selectedDetails={props.selectedDetails}
                                selectedType={props.selectedType}
                            />
                        : null
                    : null}
                </React.Fragment>   
            </div>
        </div>
    )
}
export default DetailsSidePanel;