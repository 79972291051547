/*************************************************
 * Tvastar
 * @exports
 * @file CreateUpdateQos.js
 * @author Prakash // on 07/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation } from 'react-router-dom';

import { Spinner, Input } from 'reactstrap'
import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../config/urlPath'

import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { createQosPolicy, editQosPolicy, getQosParameters, getQosPolicy } from '../../../actions/Collider/QosAction'
import MultiSelectSection from '../../common/MultiSelectSection';
import { capitalizeAllLetter, getAccountNameFromId, getRegionName, noSpace } from '../../../utils/utility';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import QosDetails from './QosDetails';
import QosAssocationDetails from './QosAssocationDetails';

const CreateUpdateQos = () => {
	const [state, setState] = useState({
		listLoading: false,
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)

	const navigate = useNavigate();
	const location = useLocation();
    const receivedData = location.state || false
	

	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])

	useEffect(() => {
		let params = {}
		if (providers) {
			setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "")				
			}));			
		} else {
			dispatch(listAllProviders(params))
				.then((response) => {
					if(response) {
						let selectedProvider = response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
						setState(prevState => ({ ...prevState, providers: response, selectedProvider }));
					}
				})
		}
	}, [providers, dispatch, handleInputChange])

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedData: receivedData.selectedData ? receivedData.selectedData : '',
				pageType: receivedData.pageType ? receivedData.pageType : '',
			}))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [receivedData])


	useEffect(() => {
		if(state.selectedData && Object.entries(state.selectedData).length) {
			let params = {}
			params.provider = state.selectedData.provider
			params.account_id = state.selectedData.account_id
			params.region = state.selectedData.region
			params.cluster_name = state.selectedData.cluster_name
			params.qos_name = state.selectedData.qos_name
			dispatch(getQosPolicy(params))
				.then((response) => {
					if(response && !response.error) {
						let parameters = response.qos_policy && response.qos_policy.parameters ? response.qos_policy.parameters : []
						let obj = response.qos_policy ? response.qos_policy : {}
						// dispatch(setHpcPropsDetails('qosParamaters', parameters))
						dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
						setState(prevState => ({ ...prevState, parameters }))
					}
				})
		}
	}, [state.selectedData, dispatch])

	// Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
		if (state.selectedProvider) {
			handleInputChange('provider', state.selectedProvider.toLowerCase())
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider

            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts, handleInputChange]);

    useEffect(() => {
        if(state.selectedProvider) {
			let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount && state.selectedAccount.length) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, dispatch, state.selectedAccount, propProjRegions]);

	useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
			params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])

	useEffect(() => {
		if(state.pageType && state.pageType !== 'edit') {
			let params = {
				name: 'qos'
			}
			dispatch(getQosParameters(params))
				.then((response) => {
					if(response && !response.error) {
						let parameters = response.parameters ? response.parameters : []
						setState(prevState => ({ ...prevState, parameters }))
					}
				})
		}
	}, [state.pageType, dispatch])

	const onFinish = () => {
		let hasError = false
		let data = qosCreateEditInputs

		if (!data.provider || !data.account_id || !data.region || !data.cluster_name) {
			hasError = true
		}
		// let parameters = data.parameters ? data.parameters : []
		// let parameterIssueMessage = ''
		// if(parameters.length) {
		// 	if(state.pageType === 'edit') {
		// 		state.parameters.forEach(row => {
		// 			if(row.value) {
		// 				if(!parameters.filter(e => e.paramter === row.paramter).length || !parameters.filter(e => e.paramter === row.paramter)[0].value) {
		// 					hasError = true	
		// 					parameterIssueMessage = 'Existing qos policy should not be left empty'
		// 				}
		// 			}
		// 		})
		// 	}
		// } else {
		// 	hasError = true
		// 	if(state.pageType === 'edit') {
		// 		parameterIssueMessage = 'Please fill the exising qos details to proceed'
		// 	} else {
		// 		parameterIssueMessage = 'Existing qos policy should not be left empty'
		// 	}
		// }

		setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

		if(!hasError) {
			if(!state.pageType || state.pageType !== 'edit') {
				setState(prevState => ({ ...prevState, callCreateQos: true, saveLoading: true }))
			} else {
				setState(prevState => ({ ...prevState, callUpdateQos: true, saveLoading: true }))
			}
		}
	}

	useEffect(() => {
		if(state.callCreateQos) {
			setState(prevState => ({ ...prevState, callCreateQos: false }))
			let params = qosCreateEditInputs
			
			dispatch(createQosPolicy(params))
				.then((response) => {
					if(response) {
						let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in saving Qos'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Qos saved successfully'
							dispatch(setHpcPropsDetails('qosCreateEditInputs', {}))
							navigate(URL_PATH.MANAGE_QOS)
                        } else {
							setState(prevState => ({ ...prevState, saveLoading: false }))
						}
						CommonNotification.addNotification({
							//title: 'Wonderful!',
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ['animate__animated', 'animate__fadeIn'],
							// animationOut: ['animate__animated', 'animate__fadeOut'],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});

						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callCreateQos, qosCreateEditInputs, dispatch, navigate])

	// To update the state
	const updateLocationState = useCallback(() => {
		if(state.selectedData && Object.entries(state.selectedData).length) {
			let params = {}
			params.provider = state.selectedData.provider
			params.account_id = state.selectedData.account_id
			params.region = state.selectedData.region
			params.cluster_name = state.selectedData.cluster_name
			params.qos_name = state.selectedData.qos_name
			dispatch(getQosPolicy(params))
				.then((response) => {
					if(response && !response.error) {
						navigate(URL_PATH.CREATE_QOS, { 
							state: {
								selectedData: response.qos_policy ? response.qos_policy : {},
								pageType: 'edit',
							}
						})
					}
				})
		}
	}, [state.selectedData, dispatch, navigate])

	useEffect(() => {
		if(state.callUpdateQos) {
			setState(prevState => ({ ...prevState, callUpdateQos: false }))
			let params = qosCreateEditInputs
			dispatch(editQosPolicy(params))
				.then((response) => {
					if (response) {
						let messageType = 'danger'
						let message = response.message ? response.message : 'Error in updating Qos'
						if(response.status) {
							messageType = 'success'
							message = response.message ? response.message : 'Qos updated successfully'
							updateLocationState()
						}
						setState(prevState => ({ ...prevState, saveLoading: false }))
						CommonNotification.addNotification({
							//title: "Wonderful!",
							message: message,
							type: messageType,
							insert: 'top',
							container: 'top-center',
							// animationIn: ["animate__animated", "animate__fadeIn"],
							// animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							},
						})
						
						if(response.status) {
							setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))	
						} else {
							setState(prevState => ({ ...prevState, showLoading: false, finishBtn: 'Saved' }))
						}
					} else {
						setState(prevState => ({ ...prevState, disableFinish: false, finishBtn: 'Save', queryLoading: false }))
					}
				})
		}

	}, [state.callUpdateQos, qosCreateEditInputs, dispatch, updateLocationState])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<div className='container-fluid overflow-auto flex-grow-1 bg-muted' onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`loaderOverlay ${state.queryLoading ? '' : 'displayNone'}`}>
				<div className='overlayEqualizerLoader'>
					<div className='spinner-item'></div>
					<div className='spinner-item'></div>
					<div className='spinner-item'></div>
					<div className='spinner-item'></div>
					<div className='spinner-item'></div>
				</div>
			</div>
			<div className='row h-100'>
				<div className='col-sm-12 p-0'>
					<div className='title-section pb-3'>
						<div className='d-flex justify-content-between'>
							<div className='align-self-center'>
								<div className='d-flex'>
									<h6 className='text-white m-0'>{state.pageType === 'edit' ? 'Modify' : 'Create'} Qos</h6>
								</div>
								{/* <p className='text-white m-0'>Create qos on your Cloud</p> */}
							</div>
						</div>
					</div>

					<div className='p-2 mb-4'>
						<div className='rounded bg-dark p-3'>
							<div className={`d-flex`}>
								<div className='col-sm-3 p-0'>
									<div className='d-flex justify-content-between'>
										<label className='align-self-center m-0'>Name</label>
										{state.hasError &&
										(qosCreateEditInputs && (!qosCreateEditInputs.qos_name ||
											qosCreateEditInputs.qos_name === '')) ? (
											<small className='text-issues'>required</small>
										) : null}
									</div>
									{state.pageType !== 'edit' ?
										<Input
											type='text'
											placeholder='Enter Qos Name'
											className='inputTextbox border-lightGray bg-transparent text-white'
											// maxlength='128'
											value={
												qosCreateEditInputs && qosCreateEditInputs.qos_name &&
												qosCreateEditInputs.qos_name !== ''
													? qosCreateEditInputs.qos_name
													: ''
											}
											onChange={e => {
												setState(prevState => ({ ...prevState, qos_name: noSpace(e.target.value)}))
												handleInputChange('qos_name', noSpace(e.target.value))
											}}
										/>
									
									:
										<p className='mb-0 mt-2 text-white f16 border-lightGray rounded-5 px-2 py-1'>{qosCreateEditInputs && qosCreateEditInputs.qos_name ? qosCreateEditInputs.qos_name : ''}</p>
									}
								</div>
								<div className='col-sm-6 pr-0'>
									<label className='align-self-center m-0'>Description</label>
									<Input
										type='textarea'
										className='inputTextbox border-lightGray bg-transparent text-white'
										placeholder='Enter Qos Description'
										rows='4'
										id='comment'
										// maxlength='256'
										onChange={e => handleInputChange('description', e.target.value)}
										value={state.description}
									/>
								</div>
							</div>
							<div className={`d-flex mt-3`}>
								<div className='col-sm-2 pl-0'>
									<div className='d-flex justify-content-between'>
										<label className='align-self-center m-0'>Provider </label>
										{state.hasError && (qosCreateEditInputs && !qosCreateEditInputs.provider) ? 
											<small className='text-issues'>required</small>
										: null}
									</div>
									<div className='border-lightGray bg-transparent rounded-5'
										onClick={(event) => {
											if(!state.isProviderOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
											}
										}}
									>
										<p className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${qosCreateEditInputs && qosCreateEditInputs.provider ? 'text-white' : 'placeholder'}`}>
											{qosCreateEditInputs && qosCreateEditInputs.provider ? capitalizeAllLetter(qosCreateEditInputs.provider) : 'Select'}
											<i className='fa fa-caret-down mt-1'/>
										</p>
										{state.isProviderOpen && state.pageType !== 'edit' ?
											<MultiSelectSection
												fields={["provider_name", "provider_name"]}
												options={state.providers}
												selectedValues={state.selectedProvider ? state.selectedProvider : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState,  selectedProvider: value, selectedAccount: '', selectedRegion: '', cluster_name: '' }))
														handleInputChange('account_id', '')
														handleInputChange('region', '')
														handleInputChange('cluste_name', '')
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'minWidth220'}
											/>
										: null}
									</div>
								</div>
								<div className='col-sm-2 pl-0'>
									<div className='d-flex justify-content-between'>
										<label className='align-self-center m-0'>Account </label>
										{state.hasError && qosCreateEditInputs && !qosCreateEditInputs.account_id ?  
											<small className='text-issues'>required</small>
										: null}
									</div>
									<div className='border-lightGray bg-transparent rounded-5'
										onClick={(event) => {
											if(!state.isAccountOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
											}
										}}
									>
										<p className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${qosCreateEditInputs && qosCreateEditInputs.account_id && qosCreateEditInputs.account_id.length ? 'text-white' : 'placeholder'}`}>
											{qosCreateEditInputs && qosCreateEditInputs.account_id ? getAccountNameFromId(qosCreateEditInputs.account_id, state.accounts) : 'Select'}
											<i className='fa fa-caret-down mt-1'/>
										</p>
										{state.isAccountOpen && state.pageType !== 'edit' ?
											<MultiSelectSection
												fields={["account_id", "account_name"]}
												options={state.accounts}
												selectedValues={state.selectedAccount ? state.selectedAccount : []}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({  ...prevState,  selectedAccount: value, cluster_name: '' }))
														handleInputChange('account_id', value)
														handleInputChange('cluster_name', '')
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'w-100'}												
												// addAllField={true}
											/>
										: null}
									</div>
								</div>
								<div className='col-sm-2 pl-0'>
									<div className='d-flex justify-content-between'>
										<label className='align-self-center m-0'>Region </label>
										{state.hasError && qosCreateEditInputs && !qosCreateEditInputs.region ? 
											<small className='text-issues'>required</small>
										: null}
									</div>
									<div className='border-lightGray bg-transparent rounded-5'
										onClick={(event) => {
											if(!state.isRegionOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
											}
										}}
									>
										<p className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${qosCreateEditInputs && qosCreateEditInputs.region ? 'text-white' : 'placeholder'}`}>
											{qosCreateEditInputs && qosCreateEditInputs.region ? getRegionName(qosCreateEditInputs.region, state.regions) : 'Select'}
											<i className='fa fa-caret-down mt-1'/>
										</p>
										{state.isRegionOpen && state.pageType !== 'edit' ?
											<MultiSelectSection
												fields={["region", "name"]}
												options={state.regions}
												selectedValues={state.selectedRegion ? state.selectedRegion : []}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, selectedRegion: value, cluster_name: '' }))
														handleInputChange('region', value)
														handleInputChange('cluster_name', '')
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'w-100'}
												// addAllField={true}
											/>
										: null}
									</div>
								</div>
								<div className='col-sm-2 pl-0'>
									<div className='d-flex justify-content-between'>
										<label className='align-self-center m-0'>Cluster Name </label>
										{state.hasError && qosCreateEditInputs && !qosCreateEditInputs.cluster_name ? 
											<small className='text-issues'>required</small>
										: null}
									</div>
									<div className='border-lightGray bg-transparent rounded-5'
										onClick={(event) => {
											if(!state.isClusterOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isClusterOpen")
											}
										}}
									>
										<p className={`d-flex justify-content-between mt-2 px-2 mb-2 ${qosCreateEditInputs && qosCreateEditInputs.cluster_name ? 'text-white' : 'placeholder'}`}>
											{qosCreateEditInputs && qosCreateEditInputs.cluster_name ? qosCreateEditInputs.cluster_name : 'Select'}
											<i className='fa fa-caret-down mt-1'/>
										</p>
										{state.isClusterOpen && state.pageType !== 'edit' ?
											<MultiSelectSection
												// fields={["region", "name"]}
												options={state.clusters}
												selectedValues={state.cluster_name ? state.cluster_name : []}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, cluster_name: '' }))
														handleInputChange('cluster_name', value)
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={false}
												topClass={'auto'}
												widthClass={'w-100'}
												// addAllField={true}
											/>
										: null}
									</div>
								</div>
							</div>
						</div>
						
						{!state.parameters || !state.parameters.length ? (
							<div className='d-flex justify-content-center m-4'>
								<Spinner className='text-center' color='light' />
							</div>
						) : (
							<React.Fragment>
								<div className='rounded bg-dark p-3 mt-3'>
									{state.parameterIssueMessage ?
										<small className='text-danger ml-2 align-self-center'>{state.parameterIssueMessage}</small>
									: null}
									<QosDetails 
										masterData={state.parameters}
										onChildPageClick={(event) => handleChildClick(event, 'parent')}
									/>
								</div>
								<div className='rounded bg-dark p-3 mt-3'>
									<QosAssocationDetails 
										masterData={state.parameters}
										onChildPageClick={(event) => handleChildClick(event, 'parent')}
									/>
								</div>
							</React.Fragment>
						)}

						{/* <div className='mt-4 d-flex justify-content-end'>
							{state.pageType !== 'View' ?
								state.saveLoading ?
									<button className='btn btn-info'>
										<Spinner size='sm' color='light' className='ml-2' />
									</button>
								: 
									<button className={`d-flex justify-content-end cursorPointer btn bg-info text-white`} onClick={ () => onFinish()}>{state.pageType === 'edit' ? 'Update' : 'Save'}</button>
							: null}
							<Link
								className='float-right btn btn-light text-muted text-decoration-none ml-1'
								to={URL_PATH.MANAGE_QOS}
							>
								Cancel
							</Link>
						</div> */}
					</div>					
					<div className='fixed-buttons justify-content-end bg-transparent border-0'>
						{state.pageType !== 'View' ?
							state.saveLoading ?
								<button className={`btn btn-primary mr-2`}>
									<Spinner size='sm' color='light' className='mx-2' /> 
									{state.pageType === 'edit' ? 'Updating' : 'Saving'}
								</button>
							:
								<button className={`d-flex justify-content-end cursorPointer btn bg-info text-white`} onClick={ () => onFinish()}>{state.pageType === 'edit' ? 'Update' : 'Save'}</button>
						: null}
						<p className='mb-0 btn btn-light text-muted ml-1'
							onClick={() => {
								navigate(URL_PATH.MANAGE_QOS, { state: { selectedManage: 'QOS' } });
							}}
						>Cancel</p>
						{/* <Link
							className='float-right btn btn-light text-muted text-decoration-none ml-1'
							to={URL_PATH.MANAGE_QOS}
						>
							Cancel
						</Link> */}
					</div>
				</div>
			</div>
		</div>
	)
}

export default CreateUpdateQos
