import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  uploadUserFiles
} from "../../../../actions/files/FileAction";

const FileUploadModal = ({ isOpen, toggle, setModalIsOpen, handleFileBrowserRefresh, state, getLastObjectFromBreadcrumb }) => {
  const dispatch = useDispatch();
  const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
  );

  const handleFileChange = (e) => {
    setSelectedFiles([...e.target.files]);
  };

  const handleFileUpload = async () => {
    const formData = new FormData();

    if (selectedFiles.length > 0) {
      setUploadProgress(true)
      let currentPath = currentLocation.path ? currentLocation.path : ''
      // if(currentLocation.owner) {
      //   params.owner = currentLocation.owner
      // }
      formData.append('destination_path', currentPath);
      formData.append('provider', state.selectedProvider ? state.selectedProvider.toLowerCase() : '');
      formData.append('account_id', state.selectedAccount);
      formData.append('cluster_name', state.selectedClusters);
      formData.append('region', state.selectedRegion);

      selectedFiles.forEach((file) => {
        formData.append('files[]', file);
      });

      dispatch(uploadUserFiles(formData))
      .then((response) => {
        if(response.status) {
          setSelectedFiles([])
          setModalIsOpen(!isOpen)
          handleFileBrowserRefresh()
        } else {
          setSelectedFiles([])
          setErrorMessage(response.message)
        }
        setUploadProgress(false)
      })
    }
  };

  return (
    <Modal
      isOpen={isOpen} 
      toggle={toggle}
      backdrop={'static'}
      keyboard={true}
      onClosed={() => {
        setErrorMessage(false)
        setUploadProgress(false)
      }}
    >
      {!errorMessage && (
        <>
          <ModalHeader toggle={toggle} close={closeBtn}>File Upload </ModalHeader>
          <ModalBody>
            <input type="file" onChange={handleFileChange} multiple={true} />
            {selectedFiles.length > 0 && (
              <div className="mt-3">
                <p>Selected Files:</p>
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li key={index}>{file.name}</li>
                  ))}
                </ul>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleFileUpload} size="sm">{!uploadProgress ? 'Upload File' : <Spinner size="sm">Loading...</Spinner> }</Button>
            <Button color="secondary" onClick={toggle} size="sm">Cancel</Button>
          </ModalFooter>
        </>
      )}

      {errorMessage && (
        <>
          <ModalBody className="text-center">
            <h6>Error</h6>
            <p>{errorMessage}</p>
            <Button color="secondary mt-2" onClick={toggle} size="sm">Close</Button>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default FileUploadModal;