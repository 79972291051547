/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 28/04/2023
 * @copyright © 2023 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Spinner, UncontrolledTooltip } from 'reactstrap'

import Select from 'react-select'
import MultiSelectSection from '../../common/MultiSelectSection';

import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getCostFilters, costComparison, getCostAllTagsKeys, getCostAllTagsValues } from '../../../actions/cost/CostAction'

import { getAccountNameFromId, getRegionName, momentDateGivenFormat, onlyNumeric, capitalizeAllLetter } from '../../../utils/utility'

const LandingPage = () => {
	const tagsRef = useRef();	
	const sliderRef = useRef();
	const getBlockWidthRef = useRef();
	const sliderRef1 = useRef();
	const getBlockWidthRef1 = useRef();
	const tagValueRef = useRef();	
	const metricRef = useRef()
	
	const[state, setState] = useState({
		showLoading: true,
		minMaxHeight: "770px",
		addToCompareError: false,
		selectedPeriod: "1",
		// costSource: ["aws", "kubernetes"],
		selectedSource: "",
		metrics: [
			// {label: "All", value: "All"},
			{label: "Cost", value: "cost"},
			{label: "Usage", value: "usage"}
		],
		groupBy: [
			{label: "Service", value: "Service"},
			{label: "Service Api", value: "Service Api"}
		],
		selectedGroupBy: "Service",
		periodOptions: [
			{label: "+7d", value: "Last 1 Week"},
			{label: "+14d", value: "Last 2 Week"},
			{label: "+30d", value: "Last 1 Month"},
			{label: "+14d", value: "Last 2 Week"},
			{label: "+14d", value: "Last 2 Week"},
		],

		selectedMetric: ["cost", "usage"],
		itemMovedLeft: 0,
		itemMovedRight: 0,
		emptyOptions: []
	})

	const tagKeys = useSelector(state => state.cost.costTagKeys);
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	
	const dispatch = useDispatch(); // Create a dispatch function
	
	useEffect(() => {
        // ... (componentDidMount logic)        
		if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            providers: response,
                            selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '',
                            callSearch: true
                        }));
                    }
            })
        }
    }, [dispatch, providers]);

	// Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
        if (state.selectedProvider && state.selectedProvider.length) {

			dispatch(getCostAllTagsKeys({})).then((response) => {})

			let filterParams = {}
			dispatch(getCostFilters(filterParams))
				.then((response) => {
					if(response) {
						let resResult = {}
						let selectedSource = ""
						if(state.selectedProvider && response[state.selectedProvider]) {
							let results = response[state.selectedProvider]
							if(results && Object.entries(results).length) {
								resResult = results[Object.keys(results)[0]]
								selectedSource = Object.keys(results)[0]
							}
						}

						setState(prevState => ({ ...prevState, costFiltersResponse: response, costFilters: resResult, selectedSource, showLoading: selectedSource ? true : false }))
					}
				})
        }
    }, [state.selectedProvider, dispatch]);


	useEffect(() => {
		if(state.costFiltersResponse) {

			let response = state.costFiltersResponse
			let results = response[state.selectedProvider.toLowerCase()]
			let costSource = results && Object.keys(results).length ? Object.keys(results) : []

			setState(prevState => ({ ...prevState, costSource, selectedSource: costSource && costSource.length ? costSource[0] : "" }))
		}

	}, [state.selectedProvider, state.costFiltersResponse]);
	

	useEffect(() => {	
		window.addEventListener('resize', () => {
			setState(prevState => ({ ...prevState, getWindowSize: true }))
		})
	}, [])

	
	useEffect(() => {
		if(state.getWindowSize) {
			// const {innerWidth, innerHeight} = window;
			let browserBorder = 100
			let headerHeight = 180
			let tableHeader = 130
			let filterSize = state.showSlectedFilters ? 240 : 0
			let outerHeight = (window.outerHeight - browserBorder - headerHeight - tableHeader - filterSize ) + "px"

			setState(prevState => ({ ...prevState, outerHeight, minMaxHeight: outerHeight, getWindowSize: false }))
		}
	}, [state.getWindowSize, state.showSlectedFilters])

	const slideRight = () => {
		// let totalWidth = this.getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width
		let width = getBlockWidthRef.current.childNodes[0] && getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width

		// let width1 = getBlockWidthRef1.current.childNodes[0] && getBlockWidthRef1.current.childNodes[0].getBoundingClientRect().width
		if((state.totalItemShowing + state.itemMovedRight) <= state.totalAccArray.length) {
			sliderRef.current.scrollLeft += width
			if(state.servicesToShow && state.servicesToShow.length) {
				sliderRef1.current.scrollLeft += width
			}

			setState(prevState => ({ ...prevState, itemMovedRight: state.itemMovedRight + 1 }))

		}
	}

	const slideLeft = () => {
		if(state.itemMovedRight >= 1) {
			let width = getBlockWidthRef.current.childNodes[0] && getBlockWidthRef.current.childNodes[0].getBoundingClientRect().width
			sliderRef.current.scrollLeft -= width
			if(state.servicesToShow && state.servicesToShow.length) {
				sliderRef1.current.scrollLeft += width
			}

			setState(prevState => ({ ...prevState, itemMovedRight: state.itemMovedRight - 1 }))
		}
	}

	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getCostAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))

						// if(state.tagsSelected && state.tagsSelected.filter(e => e.key === state.tag_key).length) {
							// let value = state.tagsSelected.filter(e => e.key === state.tag_key).map(e => e.value)
							// handleMultiSelectChange("tag_value", value, response)
						// }
					}
					
				})
		}
	}, [dispatch, state.tag_key, state.tagsSelected])

	const addNewTag = () => {
		let tagsSelected = state.tagsSelected ? state.tagsSelected : []

		if(state.tag_key && state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== state.tag_key)
			state.tag_value.forEach(row => {
				let dataRow = {
					key: state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			setState(prevState => ({ ...prevState, tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [], changesInFilter: true }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.tagsSelected.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, tagsSelected: filteredResult }))
	}

	const addToCompare = () => {
		let commonFilters = {}		
		if(state.selectedSource) {
			commonFilters.source = state.selectedSource
		}
		if(state.selectedMetric && state.selectedMetric.length) {
			commonFilters.metric_name = state.selectedMetric.filter(e => e !== "All")
		}
		
		if(state.selectedGroupBy) {
			let aggregateBy = [state.selectedGroupBy]
			if(state.selectedSource === "aws") {
				aggregateBy = ["resource_type"]
				if(state.selectedGroupBy !== "Service") {
					aggregateBy = ["resource_type", "operation"]
				}			
			}			
			commonFilters.aggregate_by = aggregateBy
		}

		let selectedFilters = state.selectedFilters ? state.selectedFilters : []
		
		let dataRow = {}
		if(state.selectedAccount) {
			dataRow.account_id = state.selectedAccount
		}
		if(state.selectedRegion) {
			dataRow.region = state.selectedRegion
		}
		if(state.selectedTags && state.selectedTags.length) {
			dataRow.tags = state.selectedTags
		}

		if(!state.selectedAccount && !state.selectedRegion && (!state.selectedTags || (state.selectedTags && !state.selectedTags.length))) {
			setState(prevState => ({ ...prevState, addToCompareError: true }))
		} else {

			selectedFilters.push(dataRow)
			commonFilters.selectedFilters = selectedFilters

			setState(prevState => ({ 
				...prevState, 
				commonFilters, 
				selectedFilters, 
				showSlectedFilters: true,
			
				selectedAccount: "",
				selectedRegion: "",
				selectedTags: [],
				tagsSelected: [],
				tag_key: "",
				tag_value: "",
				changesInFilter: false,
				getWindowSize: true,
				addToCompareError: false
			 }))
		}
	}
	//on change filter items

	const handleClickOutside = (event) => {

		if(state.showTagFilter) {
			if (tagsRef && !tagsRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showTagFilter: false }))
			}

			if (tagValueRef && !tagValueRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, isTagValueOpen: false }))
			} else {
				setState(prevState => ({ ...prevState, isTagValueOpen: true }))
			}
		}

		if (metricRef && !metricRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showMertics: false }))
		} else {
			setState(prevState => ({ ...prevState, showMertics: true }))
		}

	}

	useEffect(() => {
		if(state.callonChangeGroupBy) {
			let commonFilters = state.commonFilters ? state.commonFilters : {}
			if(state.selectedSource) {
				commonFilters.source = state.selectedSource
			}
			if(state.selectedMetric && state.selectedMetric.length) {
				commonFilters.metric_name = state.selectedMetric.filter(e => e !== "All")
			}
			if(state.selectedGroupBy) {
				let aggregateBy = [state.selectedGroupBy]
				if(state.selectedSource === "aws") {
					aggregateBy = ["resource_type"]
					if(state.selectedGroupBy !== "Service") {
						aggregateBy = ["resource_type", "operation"]
					}			
				}
				commonFilters.aggregate_by = aggregateBy
			}		
	
			setState(prevState => ({ ...prevState, commonFilters, callonChangeGroupBy: false }))
		}
	}, [state.callonChangeGroupBy, state.commonFilters, state.selectedGroupBy, state.selectedMetric, state.selectedSource])

	useEffect(() => {
		if(state.callonChangeSource) {
			let commonFilters = state.commonFilters ? state.commonFilters : {}
			if(state.selectedSource) {
				commonFilters.source = state.selectedSource
			}
			
			let selectedGroupBy = ["Service"]
			let aggregateBy = ["resource_type"]
			if(selectedGroupBy !== "Service") {
				aggregateBy = ["resource_type", "operation"]
			}
			let groupBy = [
				{label: "Service", value: "Service"},
				{label: "Service Api", value: "Service Api"}
			]
			let selectedMetric = ["cost", "usage"]
			
			if(state.selectedSource !== "aws") {
				groupBy = [
					{label: "Namespace", value: "namespace"},
					{label: "Cluster Name", value: "cluster_name"}
				]
				selectedGroupBy = ["namespace"]
				aggregateBy = ["namespace"]
				
				// metrics = [ 
				// 	{label: "Cost", value: "cost"}
				// ]
				selectedMetric = ["cost"]
			}
			commonFilters.aggregate_by = aggregateBy
			commonFilters.metric_name = selectedMetric
			
			setState(prevState => ({ ...prevState, groupBy, selectedGroupBy, selectedMetric, commonFilters, callonChangeSource: false }))
		}
	}, [state.callonChangeSource, state.commonFilters, state.selectedSource])

	const onSearch = () => {
		let params = {}
		if(state.commonFilters.source) {
			params.source = state.commonFilters.source
		}
		if(state.commonFilters.metric_name) {
			params.metric_name = state.commonFilters.metric_name.filter(e => e !== "All")
		}
		if(state.commonFilters.aggregate_by) {
			params.aggregate_by = state.commonFilters.aggregate_by
		}
		if(state.start_day && state.end_day) {
			params.start_day = parseInt(state.start_day)
			params.end_day = parseInt(state.end_day)
		}
		if(state.selectedPeriod) {
			params.duration = state.selectedPeriod+"M"
		}

		params.filters = state.commonFilters.selectedFilters
		if(state.commonFilters.selectedFilters.length === 1) {
			params.comparison_type = "trend"
		}
		// state.commonFilters.selectedFilters.forEach(row => {
		// 	filters.push
		// 	Object.entries(row).forEach(([key, value]) => {
		// 		params[key] = value
		// 	})
		// })
		dispatch(costComparison(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({ ...prevState, compareResponse: response, compareLoading: false, formCompareStructure: true, formShowOptions: true }))
				}
			})
		
		setState(prevState => ({ ...prevState, paramsFilter: state.commonFilters.metric_name }))
	}

	const clearFilers = () => {
		setState(prevState => ({ ...prevState, commonFilters: {}, selectedFilters: [], showSlectedFilters: false, getWindowSize: true }))
	}

	useEffect(() => {
		if(state.formCompareStructure) {

			let compareResponse = state.compareResponse
	
			let compareResponseResults = state.compareResponse && state.compareResponse.results ? state.compareResponse.results : {}
	
			let tableData = []
			let filters = []
			if(compareResponse.filters) {
				filters = compareResponse.filters
			} else if(compareResponse.dates) {
				filters = compareResponse.dates
			}
	
			
	
			let servicesToShow = []
			Object.entries(compareResponseResults).forEach(([cKey, cValue], index) => {
				if(!index && state.paramsFilter && state.paramsFilter.length === 1 && state.paramsFilter[0] === "usage") {
					setState(prevState => ({ ...prevState, ["show_"+index]: true }))
				}
				
				let serRow = {}
				let tableRow = {}
				serRow.service = cKey
				tableRow.label = cKey
				
				let datas = {}
				// cValue.forEach(row => {
					if(cValue.data && Object.entries(cValue.data).length) {
						serRow.service_api = Object.keys(cValue.data)
					} 
					filters.forEach((fil, index) => {					
						tableRow.filter = fil
						let datasRow = {}					
						if(cValue.hasOwnProperty("cost")) {
							datasRow["cost"] = cValue["cost"][index]
						}
						if(cValue.hasOwnProperty("usage")) {
							datasRow["usage"] = cValue["usage"][index]
						}
						if(cValue.data && Object.entries(cValue.data).length) {
							serRow.service_api = Object.keys(cValue.data)
							let apis = []
							Object.entries(cValue.data).forEach(([opKey, opValue]) => {
								let childRow = {}
								childRow.label = opKey
								if(opValue.hasOwnProperty("cost")) {
									childRow["cost"] = opValue["cost"][index]
								}
								if(opValue.hasOwnProperty("usage")) {
									childRow["usage"] = opValue["usage"][index]
								}
								apis.push(childRow)
							})
							datasRow.apis = apis
						}
						datas["filter_"+index] = (datasRow)
	
						tableRow.datas = datas
					})
					
					servicesToShow.push(serRow)
				// })
				tableData.push(tableRow)
			})
	
			setState(prevState => ({ ...prevState, tableData, servicesToShow, filters, compareResponseResults, formCompareStructure: false }))
		}
	}, [state.formCompareStructure, state.compareResponse, state.paramsFilter])

	useEffect(() => {
		if(state.formShowOptions) {
			let totalAccArray = state.compareResponse && state.compareResponse.filters ? state.compareResponse.filters : (state.compareResponse && state.compareResponse.dates ? state.compareResponse.dates : [])
			setState(prevState => ({ ...prevState, totalAccArray, totalItemShowing: 4, formShowOptions: false }))
		}
	}, [state.formShowOptions, state.compareResponse])

	const removeFilter = (filters) => {
		let commonFilters = state.commonFilters
		let selectedFilters = commonFilters.selectedFilters.filter(e => e !== filters)
		commonFilters.selectedFilters = selectedFilters

		if(selectedFilters.length < 1) {
			commonFilters = {}
		}
		setState(prevState => ({ ...prevState, commonFilters, selectedFilters }))
	}

	const handleInputPeriod = (e) => {
        const { name, value } = e.target;
		
		let period = ""
		if(value > 6) {
			period = 6
		} else {
			period = value
		}

		setState(prevState => ({ ...prevState, [name]: period }))
	}

	const handleSpecificDayInput = (e) => {
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: onlyNumeric(value), validateDay: true }))

	}

	useEffect(() => {
		if(state.validateDay) {
			if(state.start_day && state.end_day) {
				if(state.start_day > state.end_day) {
					setState(prevState => ({ ...prevState, end_day: "", validateDay: false }))
				}
			} else {
				setState(prevState => ({ ...prevState, validateDay: false }))
			}
		}
	}, [state.validateDay, state.start_day, state.end_day])

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={handleClickOutside}>
			<div className={`loaderOverlay ${state.compareLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div>
			
			<div className='col-sm-12 p-0'>
				<div className='row mx-0 pb-2 bg-nero'>
					<div className='col-lg-3 col-3 py-2'>
						<h6 className='text-white m-0'>Cost Comparison</h6>
						<p className='text-white m-0'>New cost comparison on entities</p>
					</div>
					<div className='col-lg-9 col-9 py-2'>
						<div className="containerFilterSection">
							<div className="d-flex justify-content-end mt-2">
								<div className="mr-4">
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0 text-white">Provider </label>
									</div>
									<Select
										placeholder={'Select'}
										isSearchable={false}
										// menuIsOpen={true}
										className={`selectOption f13 p-0`}
										components={{
											IndicatorSeparator: () => null
										}}
										value={({
											value: state.selectedProvider && state.selectedProvider !== "" ? state.selectedProvider : 'Select',
											label: state.selectedProvider && state.selectedProvider !== "" ? state.selectedProvider.toUpperCase() : <span className="placeholder">Select</span>
										})}
										options={state.providers && state.providers.length && state.providers.map(row => ({
											value: row.provider_name,
											label: row.provider_name,	
										}))}
										onChange={event => setState(prevState => ({ ...prevState, selectedProvider: event.value.toLowerCase(), selectedAccount: [], selectedRegion: [] }))}
									/>
								</div>
								<div className="mr-4">
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0 text-white">Source </label>
									</div>
									<Select
										placeholder={'Select'}
										isSearchable={false}
										// menuIsOpen={true}
										className={`selectOption f13 p-0`}
										components={{
											IndicatorSeparator: () => null
										}}
										value={({
											value: state.selectedSource && state.selectedSource !== "" ? state.selectedSource : 'Select',
											label: state.selectedSource && state.selectedSource !== "" ? capitalizeAllLetter(state.selectedSource) : <span className="placeholder">Select</span>
										})}
										options={state.costSource && state.costSource.map(item => ({
											value: item,
											label: capitalizeAllLetter(item),
										}))}
										onChange={event => setState(prevState => ({ ...prevState, selectedSource: event.value, callonChangeSource: true }))}
									/>
								</div>

								<div className="mr-4" style={{ minWidth: "150px"}} ref={metricRef}>
									<div className="d-flex justify-content-between">
										<label className="align-self-center text-white">Metric </label>
										{state.hasError && (!state.selectedMetric || !state.selectedMetric.length) ? (
											<small className="text-issues">required</small>
										) : null}
									</div>
									<p className={`d-flex boderDottedBottom mb-0 align-self-center pl-3 ${state.selectedMetric && state.selectedMetric.length ? 'text-white' : 'placeholder'}`} style={{height: "30px"}}>{state.selectedMetric && state.selectedMetric.length ? state.selectedMetric.length+' Selected' : 'Select'}</p>
									{state.showMertics ?
										<div className='position-relative'>
											<MultiSelectSection 
												fields={["value", "label"]}
												// className={'position-unset'}
												removeTop={true}
												options={state.metrics}
												selectedValues={state.selectedMetric ? state.selectedMetric : []}
												callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedMetric: value }))}
											/>
										</div>
									: null}
									{/* <div className={`multiSelectOptionSm align-self-end`}>
										<ReactMultiSelectCheckboxes
											placeholderButtonLabel="All"
											getDropdownButtonLabel={() => getMultiSelectedCount("selectedMetric", state.selectedMetric ? state.selectedMetric : [])}
											options={
												state.metrics && state.metrics.length ?
													state.metrics.map(row => ({
														value: row.value,
														label: row.label,
													}))
												: state.emptyOptions
											}
											onChange={arr => { handleMultiSelectChange("selectedMetric", arr ? arr : [], state.metrics)}}
											value={state.selectedMetric && state.selectedMetric.length && state.selectedMetric.map(row => ({
												value: row
											}))}
										/>
									</div> */}
								</div>
								
								<div className="mr-4">
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0 text-white">Group By </label>
										{state.hasError && (!state.selectedGroupBy || !state.selectedGroupBy.length) ? (
											<small className="text-issues">required</small>
										) : null}
									</div>
									<Select
										isSearchable={false}
										className={`selectOption f13 p-0`}
										components={{
											IndicatorSeparator: () => null
										}}
										value={({
											value: state.selectedGroupBy && state.selectedGroupBy !== "" ? state.selectedGroupBy : "",	
											label: state.selectedGroupBy && state.selectedGroupBy !== "" ? (state.groupBy.filter(e => e.value === state.selectedGroupBy) && state.groupBy.filter(e => e.value === state.selectedGroupBy).length ? state.groupBy.filter(e => e.value === state.selectedGroupBy)[0].label : state.selectedGroupBy) : <span className="placeholder">Select</span>
										})}
										options={state.groupBy && state.groupBy.map(row => ({
											value: row.value,
											label: row.label,	
										}))}
										onChange={event => setState(prevState => ({ ...prevState, selectedGroupBy: event.value, callonChangeGroupBy: true }))}
									/>
								</div>

								<div className="mr-4">
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0 text-white">Account </label>
										{state.hasError && (state.selectedAccount || state.selectedAccount === "") ? (
											<small className="text-issues">required</small>
										) : null}
									</div>
									<Select
										isSearchable={true}
										className={`selectOption f13 p-0`}
										components={{
											IndicatorSeparator: () => null
										}}
										value={({
											value: state.selectedAccount && state.selectedAccount !== "" ? state.selectedAccount : "",
											label: state.selectedAccount && state.selectedAccount !== "" ? getAccountNameFromId(state.selectedAccount, state.accounts) : <span className="placeholder">Select</span>
										})}
										options={state.accounts && state.accounts.length && state.accounts.map(row => ({
											value: row.account_id,
											label: row.account_name,	
										}))}
										onChange={event => setState(prevState => ({ 
											...prevState, 
											selectedAccount: event.value,
											changesInFilter: true,
										}))}
									/>
								</div>

								<div className="mr-4">
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0 text-white">Region </label>
										{state.hasError && (state.selectedRegion || state.selectedRegion === "") ? (
											<small className="text-issues">required</small>
										) : null}
									</div>
									<Select
										isSearchable={true}
										className={`selectOption f13 p-0`}
										components={{
											IndicatorSeparator: () => null
										}}
										value={({
											value: state.selectedRegion && state.selectedRegion !== "" ? state.selectedRegion : "",
											label: state.selectedRegion && state.selectedRegion !== "" ? getRegionName(state.selectedRegion, state.regions) : <span className="placeholder">Select</span>
										})}
										options={state.regions && state.regions.length && state.regions.map(row => ({
											value: row.region,
											label: row.name,	
										}))}
										onChange={event => setState(prevState => ({ 
											...prevState, 
											selectedRegion: event.value,
											changesInFilter: true,
										}))}
									/>
								</div>		

								<div className="mr-4" ref={tagsRef}>
									<div className="" onClick={() => setState(prevState => ({ ...prevState, showTagFilter: true }))}>
										<label className="mb-0 text-white">Tags</label>
										<p className='mb-0 STDborderBottom mt-3' style={{ minWidth: "150px" }}>
											{!state.selectedTags || (state.selectedTags && !state.selectedTags.length) ?
												<span className='text-white'>All</span>
											: state.selectedTags && state.selectedTags.length ?
												<span className='text-white'>{state.selectedTags.length} selected</span>
											: null}
										</p>
									</div>
									{state.showTagFilter ?
										<div className="costPopupTagSection border mt-1" style={{ right: "10px" }}>
											<div className="p-3 bg-black">
												<div className={`w-100 mt-3`}>
													<p className="mb-2 w-100 text-white">Tags</p>
													<div className={`d-flex`}>
														<label className="mb-1 w-40 text-white">Key</label>
														<label className="mb-1 w-40 ml-3 text-white">Value</label>
													</div>
													<div className={`w-100`}>
														<div className={`d-flex mt-3`}>
															<Select
																isSearchable={true}
																components={{
																	IndicatorSeparator: () => null
																}}
																className={`selectOption mr-2 mt-2`}
																value={({
																	value: state.tag_key,
																	label: state.tag_key && state.tag_key !== "" ? state.tag_key : <span className="placeholder">Key</span>
																})}														
																
																options={tagKeys && tagKeys.map(item => ({
																	value: item,
																	label: item,	
																}))}
																onChange={event => setState(prevState => ({ 
																	...prevState, 
																	tag_key: event.value,
																	tagValues: [],
																}))}
															/>
															{state.tag_key && state.tag_key !== "" && !state.tagValues.length ?
																<div className='ml-3'>
																	<Spinner className='text-center align-self-center' color='dark' size='sm' />
																</div>
															:
																<React.Fragment>
																	<div className='w-40 mt-1 boderDottedBottom' ref={tagValueRef}>
																		<p className={`d-flex mt-3 mb-0 ${state.tag_value && state.tag_value.length ? 'text-white' : 'placeholder'}`}>{state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}</p>
																		{state.isTagValueOpen ?
																			<div className='position-relative'>
																				<MultiSelectSection 
																					// fields={["account_id", "account_name"]}
																					className={'border-0'}
																					removeTop={true}
																					options={state.tagValues}
																					selectedValues={state.tag_value ? state.tag_value : []}
																					callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
																				/>
																			</div>
																		: null}
																	</div>
																	{/* <div className={`multiSelectOption align-self-center mr-2 z998`} style={{marginTop: "1.3rem"}}>
																		<ReactMultiSelectCheckboxes
																			placeholderButtonLabel="Select"
																			getDropdownButtonLabel={() => getMultiSelectedCount("tag_value", state.tag_value ? state.tag_value : [])}
																			options={
																				state.tagValues && state.tagValues.length ? 
																					state.tagValues.map(row => ({
																						value: row,
																						label: row,
																					}))
																				: state.emptyOptions
																			}
																			onChange={arr => { handleMultiSelectChange("tag_value", arr ? arr : [], state.tagValues) }}
																			value={state.tag_value && state.tag_value.map(acc => ({
																				value: acc
																			}))}
																		/>
																	</div> */}
																	<span className={`far fa-plus cursorPointer text-white f18 align-self-center ml-4`} onClick={() => addNewTag("tags")}></span>
																</React.Fragment>
															}
														</div>
													</div>
												</div>
												{state.tagsSelected && state.tagsSelected.length ? 
													state.tagsSelected.map((tag, index) => {
														return(
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeTagSection(tag) } ></i>
															</span>
														)
													})
												: null}
												<div className="d-flex mt-3">
													<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => setState(prevState => ({ ...prevState, tagsSelected: [], selectedTags: [] }))}>Clear</span>
													<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => setState(prevState => ({ ...prevState, showTagFilter: false, selectedTags: state.tagsSelected }))}>Select</button>
												</div>
											</div>
										</div>
									: null}
								</div>
								<span className='text-info cursorPointer mt-3' onClick={addToCompare}>Add to Compare</span>
							</div>
						</div>
					</div>
				</div>
				<div className='row mx-0'>
					{state.showSlectedFilters ?
						<div className="bg-black w-100 m-2 p-3">
							<div className='d-flex justify-content-between mb-1'>
								<p className="mb-0 w-100 text-white">Selected Filters</p>
								{!state.compareLoading ? 
									<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showSlectedFilters: false, getWindowSize: true }))}></i>
								: null}
							</div>
							{state.commonFilters ?
								<React.Fragment>
									<div className='bg-black rounded p-2 mb-2'>
										<div className='d-flex flex-wrap'>
											{state.commonFilters.source && state.commonFilters.source !== "" ?
												<span className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Source : <span className='text-info'> {state.commonFilters.source}</span> </span>
											: null}
											{state.commonFilters.metric_name && state.commonFilters.metric_name.length ?
												<div className='d-flex'>
													{state.commonFilters.metric_name.filter(e => e !== "All").map(row => {
														return(
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																<span className='text-primary-color'>Metric :</span> 
																{row}
															</span>
														)
													})}
												</div>
											: null}
											{state.commonFilters.aggregate_by && state.commonFilters.aggregate_by !== "" ?
												<div className='d-flex'>
													{state.commonFilters.aggregate_by.map(row => {
														return(
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																<span className='text-primary-color'>Group By :</span> 
																{row}
															</span>
														)
													})}
												</div>
											: null}
										</div>
									</div>
									
									{state.commonFilters.selectedFilters && state.commonFilters.selectedFilters.length ? 
										state.commonFilters.selectedFilters.map((filters, index) => {
											return(
												filters && Object.entries(filters).length ?
													<div className='bg-black d-flex justify-content-between rounded p-2 mb-2'>
														<div className='d-flex flex-wrap'>
															{filters.account_id && filters.account_id !== "" ?
																<small className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Account : <span className='text-info'> {getAccountNameFromId(filters.account_id, state.accounts)}</span> </small>
															: null}
															{filters.region && filters.region !== "" ?
																<small className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Region : <span 	className='text-info'> {getRegionName(filters.region, state.regions)}</span> </small>
															: null}
															{filters.tags ?
																<div className='d-flex'>
																	{filters.tags.map(row => {
																		return(
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>Tag :</span> 
																				{row.key +" : "+row.value}
																			</span>
																		)
																	})}
																</div>
															: null}
														</div>
														{/* <div className='' */}
														{!state.compareLoading ? 
															<i className='fa fa-times-circle px-2' onClick={() => removeFilter(filters)} />
														: null}
													</div>
												: null
											)
										})
									: null}
								</React.Fragment>
							: null}

							<div className='d-flex justify-content-end'>
								<React.Fragment>
									<div className='d-flex mr-4'>
										<span className='align-self-center text-info cursorPointer mt-3 ml-4' onClick={() => setState(prevState => ({ ...prevState, showDayOptions: true }))}>Specific Day</span>
									</div>
									{/* {state.selectedFilters && state.selectedFilters.length === 1 ?
										!state.showDayOptions ?
										: null
									: null} */}
								</React.Fragment>
								{state.showDayOptions ?
									<div className='d-flex dlpDescriptionForm dlpDescriptionFormPlain justify-content-end'>
										<div className=''>
											<p className={`mb-1 mr-2 mt-2`}>Day</p>
											<div className='d-flex'>
												<div className='selectBoxBottomColor text-white' style={{width: "70px"}}>
													<input
														type='text'
														className={`inputField w-100`}
														style={{ minHeight: '38px' }}
														placeholder={'Start'}
														name="start_day"
														value={state.start_day}
														onChange={handleSpecificDayInput}
														// onChange={e => setState(prevState => ({ ...prevState, start_day: onlyNumeric(e.target.value)}), validateDay("start_day"))}
													/>
												</div>
												<div className='selectBoxBottomColor text-white ml-2' style={{width: "70px"}}>
													<input
														type='text'
														className={`inputField w-100`}
														style={{ minHeight: '38px' }}
														placeholder={'End'}
														name="end_day"
														value={state.end_day}
														onChange={handleSpecificDayInput}
														// onChange={e => setState(prevState => ({ ...prevState, end_day: onlyNumeric(e.target.value) }), validateDay("end_day"))}
													/>
												</div>
												<span className='align-self-center text-info cursorPointer mt-3 ml-4' onClick={() => setState(prevState => ({ ...prevState, showDayOptions: false, start_day: "", end_day: "" }))}>Hide Specific Day</span>
											</div>
										</div>
									</div>
								: null}
								<div className='d-flex dlpDescriptionForm dlpDescriptionFormPlain justify-content-end'>
									<div className='mx-4'>
										<p className={`mb-1 mr-2 mt-2 align-self-end`}>Period</p>
										<div className={`d-flex`}>
											<div className='selectBoxBottomColor text-white' style={{width: "70px"}}>
												<input
													type='text'
													className={`inputField w-100`}
													style={{ minHeight: '38px' }}
													placeholder={'Month'}
													name="selectedPeriod"
													value={state.selectedPeriod}
													onChange={handleInputPeriod}
													// onChange={e => setState(prevState => ({ ...prevState, selectedPeriod: onlyNumeric(e.target.value) }), validatePeriod() )}
												/>
											</div>
											<p className={`m1-2 mr-2 mt-2 mb-1 align-self-end`}>Month(s)</p>
										</div>
									</div>
								</div>
								<div className="d-flex justify-content-end align-self-center mt-3 mb-2">
									{state.compareLoading ?
										<button className="btn btn-secondary">
											Comparing <Spinner size='sm' color='light' className='ml-2' />
										</button>
									: 
										<React.Fragment>
										<button type="button" className={`cursorPointer btn bg-cyon text-white`} onClick={()=> setState(prevState => ({ ...prevState, compareLoading: true, compareResponse: {}, servicesToShow: [] }), onSearch())}>Compare</button>
										{!state.compareLoading ? 
											<span className='align-self-center text-info cursorPointer mt-3 ml-4' onClick={() => clearFilers()}>Clear</span>
										: null}
										</React.Fragment>
									}
								</div>
							</div>

						</div>
					: state.selectedFilters && state.selectedFilters.length ?
						<span className='text-info cursorPointer mt-3 d-flex justiy-content-end pl-3' onClick={() => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters, getWindowSize: true }))}>Filters</span>
					: null}
				</div>
				<div className='row mx-0 my-1 d-flex justify-content-center'>
					{state.addToCompareError ?
						<p className='mb-0 text-danger'>Please select account/region/tags to compare</p>
					: null}
				</div>
				<div className='row mx-0'>
					<div className="m-2 d-flex justify-content-between w-100">
						<div className='ml-2 w-60'>
							<p className='font-weight-bold m-0 f15 text-white'>Comparison</p>
							{/* <p className="mb-0 small">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
						</div>
						
						<div className="d-flex justify-content-end">
							{state.totalAccArray ?
								<div className="align-self-center">
									<div className="d-flex justify-content-end">
										<small className="align-self-center">
											<span className='mr-2'>Showing</span>
											{state.totalAccArray.length > 4 ?  
												'  '+((state.totalItemShowing+state.itemMovedRight) > state.totalAccArray.length ? state.itemMovedRight - 1 : state.itemMovedRight) +' - '+ ((state.totalItemShowing+state.itemMovedRight) > state.totalAccArray.length ? state.totalAccArray.length : (state.totalItemShowing+state.itemMovedRight)) +' ('+state.totalAccArray.length+') '
											: 
												state.totalAccArray.length ? 
												' '+state.totalAccArray.length +' ('+state.totalAccArray.length+') '
												: ' 0'
											} 
										</small>
										<h5 className={`mb-0 mx-2 ${state.itemMovedRight > 0 ? 'text-white bg-info rounded-50' : 'bg-lightGray disabled rounded-50'} `}><span className="fad fa-arrow-circle-left cursorPointer" onClick={() => slideLeft()}></span></h5>
										<h5 className={`mb-0 ${state.totalAccArray.length > 4 && state.totalAccArray.length >= (state.totalItemShowing + state.itemMovedRight) ? 'text-white bg-info rounded-50' : 'bg-lightGray disabled rounded-50'}`}><span className="fad fa-arrow-circle-right cursorPointer" onClick={() => slideRight()}></span></h5>
									</div>
								</div>
							: null}
						</div>
					</div>
				</div>
				<div className='row mx-0'>
					<div className='mx-2 mt-2 bg-black px-2 d-flex w-100'>
						<div className='w-18'>
							<div className='d-flex'>
								<p className="w-100 m-0 f18 font-weight-bold text-white align-self-center py-3">Service</p>
							</div>
						</div>
						<div className='w-82 overflow-x-atuo overflow-hidden' ref={sliderRef}>
							<div className='d-flex' ref={getBlockWidthRef}>
								
								{state.compareResponse && state.compareResponse.filters ?
									state.compareResponse.filters.map((row, index) => {
										return(
											<div className={`d-flex compare-cost justify-content-between pt-2 pr-2 py-2 pl-2`}>
												<div className="d-flex w-100">
													<div className='w-100'>
														<p className="m-0 font-weight-bold text-white f16" id={"headerName_"+index}>
															{row.account_id ? 
																<span>{getAccountNameFromId(row.account_id, state.accounts)}</span>
															: row.region ?
																<span>{getRegionName(row.region, state.regions)}</span>
															: row.tags && row.tags.length ?
																<span>{row.tags[0].key +" "+row.tags[0].value}</span> 
															: 
																<span>{row}</span> 
															}
															<UncontrolledTooltip placement='top' target={"headerName_"+index}>
																<div className=''>
																	{row.account_id ? 
																		<div>Account: {getAccountNameFromId(row.account_id, state.accounts)}</div>
																	: null}
																	{row.region ? 
																		<div>Region: {getRegionName(row.region, state.region)}</div>
																	: null}
																	{row.tags && row.tags.length ? 
																		<div className='d-flex flex-wrap'>
																			{row.tags.map((tag, index) => {
																				return(
																					<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
																					</span>
																				)
																			})}
																		</div>
																	: null}
																</div>
															</UncontrolledTooltip> 
														</p>
														<div className="d-flex justify-content-between mt-1">
															<span className='align-self-center text-white'>{state.paramsFilter && state.paramsFilter.length && state.paramsFilter.includes("usage") ? "Usage" : <span>&nbsp;</span>}
															</span>
															{state.paramsFilter && state.paramsFilter.length && state.paramsFilter.includes("cost") ?
																<span className='d-flex justiy-content-end align-self-center text-white'>Cost</span>
															: null}
														</div>
													</div>
												</div>
											</div>
										)
									})
								: state.compareResponse && state.compareResponse.dates ?
								state.compareResponse.dates.map((row, index) => {
									return(
										<div className={`d-flex compare-cost justify-content-between pr-2 py-2 pl-2`}>
											<div className="d-flex w-100">
												<div className='w-100'>
													<p className="m-0 font-weight-bold text-white" id={"headerName_"+index}>
														{momentDateGivenFormat(row, "MMM YYYY")}
													</p>
													<div className="d-flex justify-content-between mt-1">	
														<span className='align-self-center text-white'>{state.paramsFilter && state.paramsFilter.length && state.paramsFilter.includes("usage") ? "Usage" : <span>&nbsp;</span>}
														</span>
														{state.paramsFilter && state.paramsFilter.length && state.paramsFilter.includes("cost") ?
															<span className='d-flex justiy-content-end align-self-center text-white'>Cost</span>
														: null}
													</div>
												</div>
											</div>
										</div>
									)
								})
							: null}
							</div>
						</div>
					</div>
					<div className="m-2 bg-black px-2 d-flex w-100 overflowYAuto vh100" >
						<div className='w-18'>
							{state.servicesToShow && state.servicesToShow.length ?
								state.servicesToShow.map((row, index) => {
									return(
										<React.Fragment>
										<div className={`d-flex border-right compare-cost-service`}>
											{row.service_api && row.service_api.length ?
												<i className="fas fa-angle-right f18 align-self-center mr-2 cursorPointer" onClick={() => setState(prevState => ({ ...prevState, ["show_"+index]: !state["show_"+index] }))}></i>
											: null}
											<h6 className="text-white mb-0 align-self-center cursorPointer" onClick={() => setState(prevState => ({ ...prevState, ["show_"+index]: !state["show_"+index] }))}>{row.service}</h6>
										</div>
										{state["show_"+index] ?
											row.service_api && row.service_api.length && row.service_api.map(seRow => {
												return(
													<div className={`d-flex border-right compare-cost-service`}>
														<h6 className="text-primary-color mb-0 align-self-center cursorPointer">{seRow}</h6>
													</div>
												)
											})
										: null}
										</React.Fragment>
									)
								})
							: null}
						</div>
						
						<div className='w-82 h-100' ref={sliderRef1}>
							{state.servicesToShow && state.servicesToShow.length ?
								state.servicesToShow.map((row, index) => {
									return(
										state.compareResponseResults.hasOwnProperty(row.service) ?
											<React.Fragment>
											<div className={`${state.filters && state.filters.length > 1 ? "d-flex" : ""}`} ref={getBlockWidthRef1}>
												{state.filters && state.filters.map((met, mIndex) => {
													return(
														<div className='compare-cost justify-content-between border-bottom'>
															<div className='d-flex justify-content-between'>
																<span className={`text-white align-self-center pl-3`}>{state.compareResponseResults[row.service].hasOwnProperty("usage") ? state.compareResponseResults[row.service].usage[mIndex] : <span>&nbsp;</span>}</span>
																{state.compareResponseResults[row.service].hasOwnProperty("cost") ?
																	<span className={`d-flex justiy-content-end text-white align-self-center pr-3`}>{state.compareResponseResults[row.service].cost[mIndex]}</span>
																: null}

																{!state.compareResponseResults[row.service].hasOwnProperty("cost") && !state.compareResponseResults[row.service].hasOwnProperty("usage") ?
																	<span className={`text-white align-self-center pr-3`}>&nbsp;</span>
																: null}
															</div>
														</div>
													)
												})}
											</div>
											{row.service_api && row.service_api.length && state.compareResponseResults[row.service].hasOwnProperty("data") ?
												row.service_api.map(ope => {
													return(
														<div className={`${state.filters && state.filters.length > 1 ? "d-flex" : ""}`}>
															{state.filters && state.filters.map((met, mIndex) => {
																return(
																	state["show_"+index] && state.compareResponseResults[row.service].data.hasOwnProperty(ope) ?
																		<div className='compare-cost-child justify-content-between border-bottom'>
																			<div className='d-flex justify-content-between'>
																				<span className={`text-white align-self-center pl-3`}>{state.compareResponseResults[row.service].data[ope].hasOwnProperty("usage") ? state.compareResponseResults[row.service].data[ope].usage[mIndex] : <span>&nbsp;</span>}</span>
																				{state.compareResponseResults[row.service].data[ope].hasOwnProperty("cost") ?
																					<span className={`d-flex justiy-content-end text-white align-self-center pr-3`}>{state.compareResponseResults[row.service].data[ope].cost[mIndex]}</span>
																				: null}
																			</div>
																		</div>
																	: null
																)
															})}
														</div>
													)
												})
											: null}
											</React.Fragment>
										: null
									)
								})
							: null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LandingPage