/*************************************************
 * Collider
 * @exports
 * @file commonActionNew.js
 * @author Prakash // on 11/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../components/ApiCalls'
import {
	LIST_PROVIDERS,
	LIST_ACCOUNTS,
	LIST_REGIONS,
	LIST_PROJECTS,
	LIST_CLUSTET_FILTERS,
	GET_USER_MENU,
	GET_USER_PROFILE,
	GET_ALL_TAGS_KEYS,
	GET_ALL_TAGS_VALUES,
	GET_SEARCH_META_RESULTS,
	LIST_SERVICES,
	LIST_USER_DETAILS,
	LIST_USERS,
	LIFECYCLE_NOTIFICATIONS,
	GET_LIFECYCLE_END_TIME,
} from '../config'
import { ACTION_TYPES } from './types'



/**
 * Action to set hpc props details
 *   for cost page
 * @author Prakash // on 29/01/2024
*/
export const setCommonPropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let commonPropsDetails = JSON.parse(JSON.stringify(getState().filters.commonPropsDetails))
		commonPropsDetails[key] = value
		dispatch({ type: ACTION_TYPES.SET_COMMON_PROPS_DETAILS, payload: commonPropsDetails });
	}
}

/**
 * Action to get all providers
 * @param {Function} callback
 */
export const listAllProviders = () => {
	return async (dispatch) => {
		try {
			const url = LIST_PROVIDERS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.get(url);

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_PROVIDERS_LIST, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response
		}
	};
};

/**
 *  Action to get all accounts
 * @param {Object} body
 * @param {Function}
 */
export const listAllAccounts = (params) => {
	return async dispatch => {		
		try {
			const url = LIST_ACCOUNTS;
			const response = await ApiCalls.post(url, params)
			
			dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			return false
		}
	}
}
/**
 *  Action to get all regions
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllRegions = (params) => {
	return async dispatch => {		
		try {
			const url = LIST_REGIONS;
			const response = await ApiCalls.post(url, params)

			dispatch({ type: ACTION_TYPES.SET_REGIONS_LIST, payload: response.data, })
			
			return response && response.data
		} catch (error) {
			return error.response
		}
	}
}

/**
 *  Action to list-projects
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllProjects = (params) => {
	return async dispatch => {		
		try {
			const url = LIST_PROJECTS;
			const response = await ApiCalls.post(url, params)

			dispatch({ type: ACTION_TYPES.SET_PROJECTS_LIST, payload: response.data, })
			
			return response && response.data
		} catch (error) {
			return error.response
		}
	}
}

/**
 *  Action to list-cluster-filters
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusterFilters = (params) => {
	return async dispatch => {		
		try {
			const url = LIST_CLUSTET_FILTERS;
			const response = await ApiCalls.post(url, params)

			dispatch({ type: ACTION_TYPES.SET_PROJECTS_LIST, payload: response.data, })
			
			return response && response.data
		} catch (error) {
			return error.response
		}
	}
}

/**
 *  Action to get all services
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllServices = (params) => {
	
	return async dispatch => {		
		try {
			const url = LIST_SERVICES;
			const response = await ApiCalls.post(url, params)

			dispatch({ type: ACTION_TYPES.SET_SERVICES_LIST, payload: response.data, })
			
			return response && response.data
		} catch (error) {
			return error.response
		}
	}
}


/**
 * Action to get-all-tags-keys
 * @param {Object} body
 * @param {Function} callback
*/
export const getAllTagsKeys = (params) => {	
	return async dispatch => {
		try {
			const url = GET_ALL_TAGS_KEYS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			  dispatch({ type: ACTION_TYPES.SET_TAG_KEYS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response
		}
	};
}

/**
 * Action to get-all-tags-values
 * @param {Object} body
 * @param {Function} callback
*/
export const getAllTagsValues = (params) => {
	return async () => {
		try {

			const url = GET_ALL_TAGS_VALUES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params);

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response
		}
	};
}


/**
 *  Action to get user meu
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 20/08/2023
 */
export const getUserMenu = () => {
	return async (dispatch) => {
		try {
			const url = GET_USER_MENU;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.get(url);

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_USER_MENU, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response
		}
	}
}
/**
 *  Action to get user profile
 * @param {Object} body
 * @param {Function} callback
 */

export const getUserProfile = callback => {
	return async (dispatch) => {
		try {
			const url = GET_USER_PROFILE;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.get(url);
			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_PROFILE_DETAILS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response
		}
	}
}

/**
 *  Action to get all accounts
 * @param {Object} body
 * @param {Function} callback
 */
export const getSearchMetaResults = (params) => {
	return async (dispatch) => {
		try {
			const url = GET_SEARCH_META_RESULTS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_PROFILE_DETAILS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response
		}
	}
}

/**
 *  Action to get all services
 * @param {Object} body
 * @param {Function} callback
 */
export const listUserDetails = (params) => {
	
	return async dispatch => {		
		try {
			const url = LIST_USER_DETAILS;
			const response = await ApiCalls.post(url, params)

			dispatch({ type: ACTION_TYPES.SET_SERVICES_LIST, payload: response.data, })
			
			return response && response.data
		} catch (error) {
			return error.response
		}
	}
}

/**
 *  Action to list-users
 * @param {Object} body
 * @param {Function} callback
 */
export const listUsers = (params) => {
	
	return async dispatch => {		
		try {
			const url = LIST_USERS;
			const response = await ApiCalls.post(url, params)
			dispatch({ type: ACTION_TYPES.SET_USERS_LIST, payload: response.data });
			
			return response && response.data
		} catch (error) {
			return error.response
		}
	}
}



export const DynamicApiToDevCall = (API_END_POINT, apiMethod, URL, body) => {
	return async () => {
		try {
			if(apiMethod === "get") {
				const response = await ApiCalls.get(API_END_POINT);
				return response && response.data
			} else {
				const response = await ApiCalls.post(API_END_POINT, body);
				return response && response.data
			}
			// URL = API_END_POINT
			// if(URL === "/database/list/listvmtypes") {
			// 	const response = await ApiCalls.post(URL, body)
			// 	// const response = await ApiCalls.post(URL, body);
			// 	return response && response.data
			// 	// callback(true, response.data ? response.data : [])
			// } else {
			// 	if(apiMethod === "get") {
			// 		const response = await ApiCalls.get(URL);
			// 		return response && response.data
			// 		// callback(true, response && response.data)
			// 	} else {
			// 		let splitURL = URL.split("/")
			// 		let response = ""
			// 		if(splitURL.includes("listvmtypes")) {
			// 			response = await ApiCalls.post(URL, body);
			// 			return response && response.data
			// 			// callback(true, response && response.data)
			// 		} else {
			// 			let path = URL
			// 			// if(API_END_POINT) {
			// 			// 	path += '/'+API_END_POINT.toLowerCase()
			// 			// }
			// 			// path += URL
						
			// 			response = await ApiCalls.post(path, body);
			// 		}
			// 		// callback(true, response && response.data)

			// 		return response && response.data
			// 	}
			// }
		} catch (error) {
			if(error.response && error.response.status === 500) {
				return false
			}
			return error.response
		}
	}
}

/**
 * Action to get all providers
 * @param {Function} callback
 */
export const lifecycleNotification = (params) => {
	return async (dispatch) => {
		try {
			const url = LIFECYCLE_NOTIFICATIONS;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			// const response = await ApiCalls.get(url, { params });
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data
		}
	};
};

/**
 * Action to get all providers
 * @param {Function} callback
 */
export const getLifecycleEndTime = (params) => {
	return async (dispatch) => {
		try {
			const url = GET_LIFECYCLE_END_TIME;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			// const response = await ApiCalls.get(url, { params });
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data
		}
	};
};