/*************************************************
 * Kiosk
 * @exports
 * @file BudgetAction.js
 * @author parakash // on 18-12-2023
 * @copyright © 2024 Kiosk. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'

import {
    LIST_BUDGETS,
	LIST_DEPARTMENTS,
    GET_DEPARTMENT_BUDGET,
    UPDATE_BUDGET,
	GET_BUDGET_DASHBOARD,
	GET_TOP_SPENDERS,
	GET_TOP_OVERSPENDERS,
	GET_OVERSPENDERS_FORECAST,
	GET_TOP_SPENDERS_FORECAST,
	GET_BUDGET_SUMMARY,
	LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS,
	GET_SPEND_COMPARISION_WITH_PREVIOUS_YEAR,
	GET_CLOUD_COMMITMENT_VS_SPEND,
	GET_CLOUD_PROVIDER_BUDGET,
	INSERT_CLOUD_PROVIDER_BUDGET,
	GET_CURRENT_CLOUD_COMMITMENT_VS_SPEND,
	GET_CLOUD_PROVIDER_COMMITMENT,
	GET_BUDGET_OBSERVATIONS,
	LIST_DEPARTMENTS_MAPPINGS,
	CREATE_DEPARTMENT,
	EDIT_DEPARTMENT,
	DELETE_DEPARTMENT,
	GET_UNTAGGED_RESOURCES,
	GET_CURRENCY_CONVERSION_DETAILS
} from '../../config'
import { refreshPage } from '../../utils/utility'
import { ACTION_TYPES } from '../types';

/**
 * Action to list-budgets
 * @param {Object} body
 * @param {Function} callback
 */
export const listBudgets = (params) => {
	return async () => {
		try {

			const url = LIST_BUDGETS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to list-departments
 * @param {Object} body
 * @param {Function} callback
 */
export const listDepartments = (params) => {
	return async dispatch => {
		try {

			const url = LIST_DEPARTMENTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_LIST_DEPARTMENT, payload: response.data && response.data.results ? response.data.results : [] });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-dpeartment-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const getDepartmentBudget = (params) => {
	return async () => {
		try {

			const url = GET_DEPARTMENT_BUDGET;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to update-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const updateBudget = (params) => {
	return async () => {
		try {

			const url = UPDATE_BUDGET;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-budget-dashboard
 * @param {Object} body
 * @param {Function} callback
 */
export const getBudgetDashboard = (params) => {
	return async () => {
		try {

			const url = GET_BUDGET_DASHBOARD;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-top-spenders
 * @param {Object} body
 * @param {Function} callback
 */
export const getTopSpenders = (params) => {
	return async () => {
		try {

			const url = GET_TOP_SPENDERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-top-overspenders
 * @param {Object} body
 * @param {Function} callback
 */
export const getTopOverspenders = (params) => {
	return async () => {
		try {

			const url = GET_TOP_OVERSPENDERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-overspenders-forecast
 * @param {Object} body
 * @param {Function} callback
 */
export const getOverspendersForecast = (params) => {
	return async () => {
		try {

			const url = GET_OVERSPENDERS_FORECAST;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}


/**
 * Action to get-top-spenders-forecast
 * @param {Object} body
 * @param {Function} callback
 */
export const getTopSpendersForecast = (params) => {
	return async () => {
		try {

			const url = GET_TOP_SPENDERS_FORECAST;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-summary
 * @param {Object} body
 * @param {Function} callback
 */
export const getSummary = (params) => {
	return async () => {
		try {

			const url = GET_BUDGET_SUMMARY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to list-departments-initiatives-projects
 * @param {Object} body
 * @param {Function} callback
 */
export const listDepartmentsInitiativesProjects = (params) => {
	return async (dispatch) => {
		try {

			const url = LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			
			dispatch({ type: ACTION_TYPES.SET_LIST_DEPARTMENTS_INITIATIVES_PROJECTS_ACCOUNTS, payload: response.data && response.data.results ? response.data.results : [] });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-spend-comparison-with-previous-year
 * @param {Object} body
 * @param {Function} callback
 */
export const getSpendComparisionWithPreviousYear = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_SPEND_COMPARISION_WITH_PREVIOUS_YEAR;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-cloud-commitment-vs-spend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCloudCommitmentVsSpend = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_CLOUD_COMMITMENT_VS_SPEND;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_CLOUD_COMMITMENT_VS_SPEND, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to insert-cloud-provider-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const insertCloudProviderBudget = (params) => {
	return async (dispatch) => {
		try {

			const url = INSERT_CLOUD_PROVIDER_BUDGET;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-cloud-provider-budget
 * @param {Object} body
 * @param {Function} callback
 */
export const getCloudProviderBudget = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_CLOUD_PROVIDER_BUDGET;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-current-cloud-commitment-vs-spend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCurrentCloudCommitmentVsSpend = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_CURRENT_CLOUD_COMMITMENT_VS_SPEND;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_CURRENT_CLOUD_COMMITMENT_VS_SPEND_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-current-cloud-commitment-vs-spend
 * @param {Object} body
 * @param {Function} callback
 */
export const getCloudProviderCommitment = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_CLOUD_PROVIDER_COMMITMENT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_CURRENT_CLOUD_COMMITMENT_VS_SPEND_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-observations
 * @param {Object} body
 * @param {Function} callback
 */
export const getObservations = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_BUDGET_OBSERVATIONS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to list-departments-mappings
 * @param {Object} body
 * @param {Function} callback
 */
export const listDepartmentsMappings = (params) => {
	return async (dispatch) => {
		try {

			const url = LIST_DEPARTMENTS_MAPPINGS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

/**
 * Action to get-observations
 * @param {Object} body
 * @param {Function} callback
 */
export const createDepartment = (params) => {
	return async (dispatch) => {
		try {

			const url = CREATE_DEPARTMENT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

export const editDepartment = (params) => {
	return async (dispatch) => {
		try {

			const url = EDIT_DEPARTMENT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

export const deleteDepartment = (params) => {
	return async (dispatch) => {
		try {

			const url = DELETE_DEPARTMENT;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

export const getUntaggedResources = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_UNTAGGED_RESOURCES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}

export const getCurrencyConversionDetails = (params) => {
	return async (dispatch) => {
		try {

			const url = GET_CURRENCY_CONVERSION_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.get(url)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_GET_OBSERVATIONS, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	};
}