import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserFiles } from '../../../../actions/files/FileAction';
import TreeView from './TreeView';
import _ from 'lodash'

function loadTreeData(data) {
  const newArray = data.forEach((obj) => {
    if (obj.path) {
      return { ...obj, expanded: false, path: obj.path };
    } else {
      return { ...obj, expanded: false, path: `${obj.current_directory}/${obj.url}`.replace(/\\/g, '/'), children: [] };
    }
  });

  return newArray;
}

const createParams = (newState, selectedPath='') => {
  let params = {}
  if(selectedPath) {
    params.path = selectedPath
  }

  if (newState) {
    params.provider = newState.selectedProvider ? newState.selectedProvider.toLowerCase() : ''
    params.account_id = newState.selectedAccount
    params.cluster_name = newState.selectedClusters
    params.region = newState.selectedRegion
  }
  return params
}

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const [newState, setNewState ] = useState({})
  const { state, selectedCluster, onNodeClick } = props
  const [treeData, setTreeData] = useState([]);

  const fileManageFilterChange = useSelector(state => state?.filters?.commonPropsDetails?.fileManageFilterChange || false);
  // const currentLocation = useSelector(state => state?.filters?.commonPropsDetails?.currentLocation || false);

  useEffect(() => {
    if(!_.isEqual(state.fileManageFilterChange, fileManageFilterChange)) {
      setNewState(prevNewState => ({ 
        ...prevNewState, 
        selectedProvider: fileManageFilterChange.selectedProvider,
        selectedAccount: fileManageFilterChange.selectedAccount ? fileManageFilterChange.selectedAccount : [],
        selectedRegion: fileManageFilterChange.selectedRegion ? fileManageFilterChange.selectedRegion : [],
        selectedClusters: fileManageFilterChange.selectedClusters ? fileManageFilterChange.selectedClusters : '',
        fileManageFilterChange,
      }))
      if(fileManageFilterChange.selectedClusters) {
        setNewState(prevNewState => ({ ...prevNewState, callUserFiles: true }))        
        setTreeData([])
      }
    }
  }, [fileManageFilterChange, state.fileManageFilterChange])

  // useEffect(() => {
  //   if(!_.isEqual(state.currentLocation, currentLocation && currentLocation.children)) {
  //     alert(1)
  //     setNewState(prevNewState => ({ ...prevNewState, currentLocation, callUserFiles: true }))       
  //   }
  // }, [state.currentLocation, currentLocation])

  const handleNodeToggle = async (node) => {
    // console.log("node", node)
    let params = createParams(newState, node.path)
    if(params.provider && params.cluster_name) {
      if (!node.expanded && ((node && node.children && node.children.length === 0) || !node.children)) {
        params.directory = true
        await dispatch(getUserFiles(params))
        .then((response) => {
          if(response.data) {
            // console.log("response.data[0]", response.data[0])
            const updatedTreeData = toggleNode(treeData, node.path, response.data && response.data.length ? response.data[0].children : '');
            setTreeData(updatedTreeData);
          }
        })
      } else {
        const updatedTreeData = toggleNode(treeData, node.path, node && node.children);
        setTreeData(updatedTreeData);
      }
    }
  };

  const toggleNode = (data, nodeId, child) => {
    return data.map((node) => {
      if (node && node.path === nodeId) {
        return { ...node, expanded: !node.expanded, children: child };
      } else if (node && node.children) {
        return { ...node, expanded: node.expanded, children: toggleNode(node.children, nodeId, child) };
      }
      return node;
    });
  };

  useEffect(() => {
    if(newState.callUserFiles) {
      setNewState(prevNewState => ({ ...prevNewState, callUserFiles: false }))
      let params = createParams(newState)
      params.directory = true
      if(params.provider && params.cluster_name) {
        dispatch(getUserFiles(params))
        .then((response) => {
          if(response.data) {
            setTreeData(response.data)
          }
        })
      }
    }
  }, [newState.callUserFiles, dispatch, selectedCluster, newState])

  return (
    <div className="p-2 folder-tree">
      {treeData && treeData.length > 0 &&
        <TreeView
          data={treeData}
          onNodeToggle={handleNodeToggle}
          onNodeToggleFolder={handleNodeToggle}
          onNodeClick={onNodeClick}
          parent={true}
        />
      }
    </div>
  );
};

export default Sidebar;