import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Input, Spinner } from 'reactstrap';
import MultiSelectSection from '../common/MultiSelectSection';
import { subscribeCatalog } from '../../actions/CatalogAction'
import { Store as CommonNotification } from 'react-notifications-component';
import { onlyNumeric, removeUnderScore } from '../../utils/utility';

const RequestWorkstationSidePanel = ({ selectedCatalog, catalogTypes, catalogType,closeSidePanel }) => {
    const clickOut = useRef();
    
    const [state, setState] = useState({
        showLoading: false
    })
    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
        if(state.onRequest) {
            setState(prevState => ({ ...prevState, onRequest: false}))
            let hasError = false
            // if(!state.workstation_name || !state.selectedInstances || !Object.entries(state.selectedInstances).length) {
            //     hasError = true
            // }

            if(catalogTypes && catalogTypes[catalogType]) {
                catalogTypes[catalogType].forEach(int => {
                    if(!state[int.output_field]) {
                        hasError = true
                    }
                })
            }


            setState(prevState => ({ ...prevState, hasError, saveLoading: !hasError ? true : false }))

            if(!hasError) {
                let params = selectedCatalog
                params.workstation_name = state.workstation_name
                if(state.additional_storage_size) {
                    params.additional_storage_size = state.additional_storage_size                    
                }
                params = {...params, ...state.selectedInstances }
                if(!params.description) {
                    delete params.description
                }

                // params.subscribed = false
                dispatch(subscribeCatalog(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, saveLoading: false }))
                        let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in submitting request'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Reqeust submitted successfully'
                        }

                        closeSidePanel()

                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })
            }
        }

    }, [dispatch, state.onRequest, selectedCatalog, state.selectedInstances, state.workstation_name, closeSidePanel, state.additional_storage_size, catalogTypes, catalogType, state])

    const handleInputChange = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: value }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
        let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div>
            <div className="search-content bg-highlight bg-muted w-40" ref={clickOut}>
                <div className="header-search bg-black bd-highlight d-flex justify-content-between p-2">
                    <div className="flex-fill bd-highlight text-white">
                        <h5>Workstation Request</h5>
                    </div>
                    <div className="text-right flex-fill bd-highlight align-self-center">
                        <i className="far fa-times fa-lg text-white cursorPointer" onClick={() => closeSidePanel()}></i>
                    </div>
                </div>
                <div className='m-2 overflowYAuto'>
                    <div className="rounded bg-dark3 p-3">
                        {catalogTypes && catalogTypes[catalogType] ?
                            catalogTypes[catalogType].map((int, index) => {
                                return(
                                    <React.Fragment key={'init_'+index}>
                                    {int.type === 'text' ?
                                        <div className='d-flex py-2'>
                                            <div className='py-1 w-80'>
                                                <p className='b-block mb-0 d-flex'>
                                                {int.label} <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                    {state.hasError && !state[int.output_field] ?
                                                        <span className='mb-0 text-red pl-3'>required</span>
                                                    : null}
                                                </p>
                                                <div className='d-flex mt-2'>
                                                    <Input
                                                        type='text'
                                                        placeholder={removeUnderScore(int.output_field)}
                                                        className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                        value={state[int.output_field] ? state[int.output_field] : ''}
                                                        onChange={e => handleInputChange(int.output_field, e.target.value)}
                                                        //setState(prevState => ({ ...prevState, [int.output_field]: e.target.value
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : int.type === 'dropdown' ?
                                        <div className='d-flex py-2'>
                                            <div className='py-1 w-50'>
                                                <div className='d-flex'>
                                                    <p className="b-block mb-0 text-primary-color">{int.label}</p>
                                                    <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                    {state.hasError && !state[int.output_field] ?
                                                        <p className='pl-3 text-danger align-self-center mb-0'>required</p>
                                                    : null}
                                                </div>
                                                <div className={`w-100 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                                    if(!state.showRole) {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'singleDropDown', "showRole")
                                                    }
                                                }}>
                                                    <p className={`d-flex p-2 bg-transparent border-lightGray mb-0 rounded-5 d-flex justify-content-between ${state.instance_type ? 'text-white' : 'placeholder'}`}>
                                                        {state.instance_type ? state.instance_type : 'Select'}
                                                        <i className='fa fa-caret-down mt-1'/>
                                                    </p>
                                                    {state.showRole ?
                                                        <div className='position-relative'>
                                                            <MultiSelectSection 
                                                                fields={["instance_type", "instance_type"]}
                                                                className={'w-70'}
                                                                removeTop={true}
                                                                options={selectedCatalog.allowed_instance_types ? selectedCatalog.allowed_instance_types : []}
                                                                selectedValues={state.instance_type ? state.instance_type : ''}
                                                                callbackMultiSelect={(value) => {
                                                                    if(!value || typeof(value) === 'string') {
                                                                        setState(prevState => ({ ...prevState, instance_type: value, selectedInstances: selectedCatalog.allowed_instance_types.filter(e => e.instance_type === value).length ? selectedCatalog.allowed_instance_types.filter(e => e.instance_type === value)[0] : {} }))
                                                                        if(value) {
                                                                            handleInputChange(int.output_field, value)
                                                                        }
                                                                    } else {
                                                                        value.preventDefault()
                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                    }
                                                                    
                                                                }}
                                                                singleSelection={true}
                                                                hideSearch={true}
                                                                // makeLabelUpperCase={'capitalizeAllLetter'}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                            <div className='py-1 w-50 pl-3 displayNone'>
                                                <div className='d-flex'>
                                                    <p className="b-block mb-0 text-primary-color mb-0">Additional Storage Size</p>
                                                </div>
                                                <div className='d-flex'>
                                                    <Input
                                                        type='number'
                                                        placeholder='size in GB'
                                                        maxLength={5}
                                                        className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                        value={state.additional_storage_size ? state.additional_storage_size : ''}
                                                        onChange={e => {
                                                            if (e.target.value.length <= 5) {
                                                                setState(prevState => ({ ...prevState, additional_storage_size: onlyNumeric(e.target.value)}))
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                    </React.Fragment>
                                )
                            })
                        : null}
                        {state.selectedInstances && state.selectedInstances.vcpus ?
                            <div className='d-flex py-2'>
                                <p className='b-block mb-0 d-flex w-20'>vCPUs</p>
                                <p className='pl-3 b-block mb-0 text-white'>{state.selectedInstances.vcpus}</p>
                            </div>
                        : null}
                        {state.selectedInstances && state.selectedInstances.memory ?
                            <div className='d-flex py-2'>
                                <p className='b-block mb-0 d-flex w-20'>Memory</p>
                                <p className='pl-3 b-block mb-0 text-white'>
                                    {state.selectedInstances.memory}
                                    {state.selectedInstances && state.selectedInstances.memory_unit ? 
                                        <span className='ml-1'>{state.selectedInstances.memory_unit}</span>
                                    : null}
                                </p>
                            </div>
                        : null}
                        {state.selectedInstances && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_type ?
                            <div className='d-flex py-2'>
                                <p className='b-block mb-0 d-flex w-20'>
                                Storage&nbsp;Type
                                </p>
                                <p className='pl-3 b-block mb-0 text-white'>
                                    {selectedCatalog.storage_details.storage_type}
                                </p>
                            </div>
                        : null}
                        {selectedCatalog && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_size ?
                            <div className='d-flex py-2'>
                                <p className='b-block mb-0 d-flex w-20'>
                                Storage&nbsp;Size
                                </p>
                                <p className='pl-3 b-block mb-0 text-white'>
                                    {selectedCatalog.storage_details.storage_size}
                                    {selectedCatalog && selectedCatalog.storage_details && selectedCatalog.storage_details.storage_unit ? 
                                        <span className='ml-1'>{selectedCatalog.storage_details.storage_unit}</span>
                                    : null}
                                </p>
                            </div>
                        : null}
                        {selectedCatalog && selectedCatalog.price_per_month ?
                            <div className='d-flex py-2'>
                                <p className='b-block mb-0 d-flex w-20'>
                                Cost per Month
                                </p>
                                <p className='pl-3 b-block mb-0 text-white'>
                                    {state.selectedInstances.price_per_month}
                                    {state.selectedInstances && state.selectedInstances.price_unit ?
                                        <span className='ml-2  text-primary-color'>({state.selectedInstances.price_unit})</span>
                                    : null}
                                </p>
                                {/* <div className='d-flex'>
                                    <Input
                                        type='text'
                                        placeholder='storage size'
                                        className={`inputTextbox bg-transparent border-lightGray text-white`}
                                        value={state.size ? state.size : ''}
                                        onChange={e => setState(prevState => ({ ...prevState, size: e.target.value }))}
                                    />
                                </div> */}
                            </div>
                        : null}
                    </div>

                    <div className={`d-flex mb-2 mt-4 justify-content-end`}>
                        {state.saveLoading ?
                            <button className='btn btn-success'>
                                Requesting <Spinner size='sm' color='light' className='ml-2' />
                            </button>
                        : 
                            <button className={`d-flex justify-content-end cursorPointer btn bg-green text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, onRequest: true }))}>Request</button>
                        }
                        <button className={`ml-2 d-flex justify-content-end cursorPointer btn btn-light`} onClick={ () => closeSidePanel()}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequestWorkstationSidePanel;