/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file AnomalyDashboard.js
 * @author Prakash // on 23/03/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
// import PropTypes from 'prop-types'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { listCostAnomalyResults} from '../../../actions/cost/CostAction'

import _ from 'lodash'
import { CSVLink } from "react-csv";
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchComponent'

// import RightSection from './RightSection'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'


const AnomalyDashboard = () => {
    const[state, setState] = useState({
        showLoading: true,
        minMaxAnomalySectionHeight: 500,
        jumpDate: '',
        details: {},
        dynamicTableColumn: [],
    
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
    
        groupType: 'All',
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        pageType: "Anomalies",
        showRightSide: true
    })
    const costCommonPageFilter = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.costCommonPageFilter);

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        getWindowSize()
    }, [])

    // Use a ref to store the previous selector value
    // const prevCostCommonPageFilter = React.useRef();

    useEffect(() => {
        if(costCommonPageFilter && Object.entries(costCommonPageFilter).length) {
            // if (!state.costCommonPageFilter || (state.costCommonPageFilter && !_.isEqual(state.costCommonPageFilter !== costCommonPageFilter))) {
                // prevCostCommonPageFilter.current = costCommonPageFilter;
                setState(prevState => ({
                    ...prevState,
                    brushStartTime: costCommonPageFilter &&  costCommonPageFilter.brushStartTime ? costCommonPageFilter.brushStartTime : costCommonPageFilter.start_time,
                    brushEndTime: costCommonPageFilter.brushEndTime ? costCommonPageFilter.brushEndTime : costCommonPageFilter.end_time,
                    anomalyListLoader: true,
                    callListCostAnomalyResults: true,
                    costCommonPageFilter,
                }))
            // }
        }
    }, [costCommonPageFilter, state.costCommonPageFilter])

    useEffect(() => {
        if(state.callListCostAnomalyResults) {
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(state.brushStartTime, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.brushEndTime, 'YYYY-MM-DD HH:mm:ss')
            params.aggregate_by = state.groupedBy
            if(state.groupType !== 'All') {
                params.anomaly_type = state.groupType
            }

            if(params.start_time && params.end_time)  {
                dispatch(listCostAnomalyResults(params))
                    .then((response) => {
                        if(response) {
                            let data = response.results && response.results.details ? response.results.details : []
                            setState(prevState => ({ ...prevState, anomalyResponse: data, callListCostAnomalyResults: false }))
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, anomalyResponse: [], callListCostAnomalyResults: false }))
            }
        }
    }, [state.callListCostAnomalyResults, dispatch, state.brushEndTime, state.brushStartTime, state.groupType, state.groupedBy])

    const getWindowSize = () =>  {
        // const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100
        let filterHeight = 300
                
		let outerHeight = (window.outerHeight - browserBorder - headerHeight - filterHeight) + "px"

		setState(prevState => ({ ...prevState, minMaxAnomalySectionHeight: outerHeight }))
    }

    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const gotoPage = (pageNumber) => {
        // Go to the specified page and update state        
        if(pageNumber > 0  && pageNumber <= state.totalPages) {
            let startRecord = (pageNumber - 1) * state.perPage
            setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
        } else  if(pageNumber > state.totalPages) {
            setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
        } else {
            setState(prevState => ({ ...prevState, pageNumber }))
        }
    }

    useEffect(() => {
        
        let groupAllAnomaly = []

        let totalCount = 0
        
        let groupAnomalycategory = []
        if(state.anomalyResponse && state.anomalyResponse.length) {
            totalCount = state.anomalyResponse.reduce( function(a, b){ return a + (b.count ? b.count : (b.details ? b.details.length : 0))}, 0);
            state.anomalyResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count ? item.count : (item.details ? item.details.length : 0)
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((dataRow.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array
                groupAnomalycategory.push(dataRow)
                item.events.forEach(alt => {
                    groupAllAnomaly.push(alt)
                })
            })
        }
        
        // let groupAnomalyTotalCount = state.anomalyResponse && state.anomalyResponse.total_count ? state.anomalyResponse.total_count : 0
        // let groupAnomalyfilteredCount = state.anomalyResponse && state.anomalyResponse.filtered_count ? state.anomalyResponse.filtered_count : 0
        
        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        groupAnomalycategory.push(dataRow)

        let orderedArray = _.orderBy(groupAnomalycategory, ['orderBy'], ['asc'])

        setState(prevState => ({ 
            ...prevState, 
            groupAllAnomaly,
            groupAnomalycategory: orderedArray, 
            // groupAnomalyTotalCount, 
            anomalyListLoader: false 
        }))
    }, [state.anomalyResponse]);

    useEffect(() => {
        let filterdCategoryAnomaly = state.groupAllAnomaly
        if(state.selectedCategory !== 'Important / All') {
            filterdCategoryAnomaly = state.anomalyResponse.filter(arr => arr.label === state.selectedCategory)
            if(filterdCategoryAnomaly && filterdCategoryAnomaly.length) {
                filterdCategoryAnomaly = filterdCategoryAnomaly[0].events ? filterdCategoryAnomaly[0].events : []
            }
        }
        setState(prevState => ({ ...prevState, groupAnomaly: filterdCategoryAnomaly }))
        
    }, [state.selectedCategory, state.groupAllAnomaly, state.anomalyResponse])

    useEffect(() => {        
        let data = state.groupAnomaly ? state.groupAnomaly : []
        data = _.orderBy(data, ['anomaly_time'], ['desc'])

        let totalPages = 1
		if(data.length > state.perPage) {
			totalPages = Math.ceil(data.length / state.perPage)
		}

        let priceUnit = ""
        if(data.length) {
            priceUnit = data[0].additional_details && data[0].additional_details.pricing_unit ? data[0].additional_details.pricing_unit : ""
        }

        setState(prevState => ({ ...prevState, totalFilteredArray: data, filteredArray: data, priceUnit, totalPages }))

        // let scroll = document.getElementById('scrollTop');
        // scroll.scrollIntoView();
    }, [state.groupAnomaly, state.perPage])

    
	useEffect(() => {
		let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === "string" && key !== "event_data") {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})
			setState(prevState => ({ ...prevState, headers }))
		}
	}, [state.filteredArray])

    useEffect(() => {
        // let ExpectedCost = "Expected Cost "+(<span className="text-primary-color f12">{state.priceUnit}</span>)
        let dynamicTableColumn = []
        let dataRow = {}

        dataRow.Header = ' '
        dataRow.accessor = 'severity'
        dataRow.Cell = cellInfo => (
            <span className={`badge ${
                cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ?
                    'risk-badge-'+cellInfo.row.original.severity.toLowerCase()
                : 'badge-secondary'
                }`}>
                {cellInfo.row.original.severity && cellInfo.row.original.severity !== '' ? (cellInfo.row.original.severity.toLowerCase() === 'low' ? 'L' : cellInfo.row.original.severity.toLowerCase() === 'medium' ? 'M' :  cellInfo.row.original.severity.toLowerCase() === 'high' ? 'H' : cellInfo.row.original.severity.toLowerCase() === 'critical' ? 'C' : '-') : '-'}
            </span>)
        dataRow.width = 55
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Date'
        dataRow.accessor = 'anomaly_time'
        dataRow.width = 150
        dataRow.HeaderUnit = "local"
        dataRow.Cell = cellInfo => (
            <span>{cellInfo.row.original.anomaly_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.anomaly_time, 'DD MMM YYYY HH:mm:ss') : ''}</span>
        )
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = 'Duration'
        dataRow.accessor = d => d.additional_details && d.additional_details.duration
        dataRow.Cell = cellInfo => (<div className="d-flex"><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration ? cellInfo.row.original.additional_details.duration : ''}</span><span className="mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.duration_unit ? cellInfo.row.original.additional_details.duration_unit : ''}</span></div>)
        dataRow.width = 140				
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Impact Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_impact_cost
        dataRow.Cell = cellInfo => (<div className="d-flex"><span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_cost ? cellInfo.row.original.additional_details.total_impact_cost : ''}</span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_impact_percentage ? <span className="mr-1 text-success f12 align-self-center">({cellInfo.row.original.additional_details.total_impact_percentage}%)</span> : ""}</div>)
        dataRow.HeaderUnit = state.priceUnit
        dataRow.width = 160
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        // dataRow.Header = "Actual Cost "+(<span className="text-primary-color f12">({state.priceUnit})</span>)
        dataRow.Header = "Actual Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_actual_cost
        dataRow.Cell = cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_actual_cost ? cellInfo.row.original.additional_details.total_actual_cost : ''}</span>)
        dataRow.HeaderUnit = state.priceUnit
        dataRow.width = 160
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Expected Cost"
        dataRow.accessor = d => d.additional_details && d.additional_details.total_expected_cost
        dataRow.Cell = cellInfo => (<span>{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.total_expected_cost ? cellInfo.row.original.additional_details.total_expected_cost : ''}</span>)
        dataRow.HeaderUnit = state.priceUnit
        dataRow.width = 180
        dynamicTableColumn.push(dataRow)

        dataRow = {}
        dataRow.Header = "Description"
        dataRow.accessor = d => d.anomaly_message ? d.anomaly_message : ""
        dataRow.className = "whiteSpaceUnset"
        dataRow.width = 600
        dynamicTableColumn.push(dataRow)

        setState(prevState => ({ ...prevState, dynamicTableColumn }))
    }, [state.priceUnit, state.filteredArray])

    
    const handleChange = (event) => {
        let groupType = event.target.value
        let groupedBy = state.groupedBy
        if(state.groupType !== "All" && state.groupedBy === "anomaly_type") {
            groupedBy =  "severity"
        }
        setState(prevState => ({ ...prevState, groupType, groupedBy, callListCostAnomalyResults: true, anomalyListLoader: true }))
    }    
    
    return (
        <div className="" id="scrollTop">
            <div className="w-100">
                <div className={`d-flex mt-2 ml-1 ${state.anomalyListLoader ? 'disabled' : ''}`}>
                    <p className="m-0 align-self-end text-extraLightGray small mr-1">Show </p>
                    <div className={`transparentSelectBorderBottom align-self-end`}>
                        <select className="form-control" 
                        onChange={handleChange}
                        value={state.groupType}
                        >
                            <option key="All" value="All">ALL</option>
                            <option key="account_id" value="account_id">Account</option>
                            <option key="region" value="region">Region</option>
                            <option key="resource_type" value="resource_type">Service</option>
                            <option key="operation" value="operation">Operation</option>
                        </select>
                    </div> 
                    <p className="m-0 align-self-end small mx-2 text-extraLightGray">anomalies grouped by</p>
                    {/* <p className="m-0 align-self-end small mx-2">Showing Anomalies groupd by</p> */}
                    <div className={`transparentSelectBorderBottom align-self-end`}>
                        <select className="form-control" 
                            onChange={(event) => {
                                setState(prevState => ({ ...prevState, groupedBy: event.target.value, anomalyListLoader: true, selectedCategory: "Important / All", callListCostAnomalyResults: true, groupAnomalycategory: [] }))
                            }}
                            value={state.groupedBy}                            
                            >
                                <option key="severity" value="severity">Severity</option>
                                <option key="account_id" value="account_id">Account</option>
                                <option key="region" value="region">Region</option>
                                <option key="resource_type" value="resource_type">Service</option>
                                {/* <option key="operation" value="operation">Operation</option> */}
                                {/* <option key="asset_name" value="asset_name">Asset</option> */}
                                {/* <option key="service_name" value="service_name">Service</option> */}
                                {/* {(state.groupType === "All" || state.groupType === "logs") ? 
                                    <option key="anomaly_type" value="anomaly_type">Anomaly Type</option>
                                : null} */}
                        </select>
                    </div>
                </div>
                
                <div className="d-flex mt-1">
                    <div className={`${state.expandRIghtSection ? 'w-100' : 'w-100'} mr-1`}>
                        <div className='card p-3'>
                            <div className="d-flex mb-2 justify-content-between">
                                <div className="d-flex mr-2 w-60">
                                    <p className="f12 m-0 align-self-center text-extraLightGray pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.totalFilteredArray && state.totalFilteredArray.length}{state.totalFilteredArray && state.totalFilteredArray.length > 1 ? ' anomalies' : ' anomalies'}
                                    <span className="f12 m-0 align-self-center pt-1 text-cyon ml-1">{state.brushStartTime ? momentDateGivenFormat(state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.brushEndTime, 'DD MMM YYYY HH:mm') : ''}</span><span className="ml-2">(local time)</span></p>
                                </div>
                                <div className="d-flex justify-content-end w-40 mb-n2">
                                    {state.totalFilteredArray && state.totalFilteredArray.length ?
                                        <CSVLink 
                                            data={state.filteredArray} 
                                            headers={state.headers ? state.headers : []}
                                            filename={"cost-anomalies.csv"}
                                            className={"align-self-center mr-2"}
                                            target="_blank"
                                        >
                                            <i className="fas fa-download text-primary-color"></i>
                                            {/* <span className="text-primary-color mx-2">csv</span> */}
                                        </CSVLink>
                                    : null}
                                    {state.totalFilteredArray && state.totalFilteredArray.length ?
                                        <Search
                                            data={state.totalFilteredArray ? state.totalFilteredArray : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            filteredData={(filteredArray) => {
                                                setState(prevState => ({ ...prevState, filteredArray, errorDetails: {}, merticsAssetNameInvolved: [] }))
                                            }}
                                            topClassName={'bg-darkGray align-self-center w-80 mr-1 border-lightGray rounded-5 f12'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-gray5 f12'}
                                            searchPlaceHolder={'Search....'}
                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                        />
                                    : null}
                                    {state.filteredArray && state.filteredArray.length > state.perPage ?
                                        <div className="pagination errorPagePagination f12 displayNone">
                                            <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-cyon'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-cyon'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                            <span>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                        gotoPage(page)
                                                    }}
                                                    className="ml-1 inputClass"
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-cyon'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-cyon'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                        </div>
                                    : null}
                                </div>
                            </div>
                            
                            <div className="d-flex">
                                {state.groupAnomalycategory ? 
                                    <div className="pr-3 categoryBoxSection">
                                        <div className="bg-darkGray px-2 pt-2 select-bgg">
                                            {state.groupedBy === "severity" ?
                                                state.groupAnomalycategory.map(item => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                            <div key="important/all" className={`d-flex p-2 cursorPointer ${state.selectedCategory === item.category ? 'text-cyon disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category }))}>
                                                                <p className="m-0 f-20">{item.category}</p>
                                                                <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                            </div>
                                                        :
                                                            <div  key="others" className={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category }))}>
                                                                <div className="d-flex">
                                                                    {item.totalBars.map(bar => {
                                                                        return(
                                                                        <div key={item.category} className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                        )
                                                                    })}
                                                                    <div className={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                        <p className="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    )
                                                })                                            
                                            : 
                                                state.groupAnomalycategory.map((item, index) => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                            <div key="importantall2" className={`d-flex cursorPointer mb-3 ${state.selectedCategory === item.category ? 'text-cyon disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category }))}>
                                                                <p className="m-0 f-20">{item.category}</p>
                                                                <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                            </div>
                                                        :
                                                            <div key={item.category} className={`d-flex cursorPointer mb-2 ${state.selectedCategory === item.category ? 'text-cyon disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => setState(prevState => ({ ...prevState,selectedCategory: item.category }))}>
                                                                <div className="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                    <p className="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                    <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                    <input className="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                                </div>
                                                            </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                : null}
                                <div className={`${state.groupAnomalycategory ? 'w-100' : 'w-100'}`}>
                                    <div className={`pl-2 overflowYAuto vh100`}> 
                                        {state.anomalyListLoader ? 
                                            <div className='d-flex justify-content-center m-4'>
                                                <Spinner className='text-center' color='light' />
                                            </div>
                                        : state.filteredArray && state.dynamicTableColumn.length && state.filteredArray.length ?
                                            <ResizeableDarkThemeTable
                                                columns={state.dynamicTableColumn}
                                                data={state.filteredArray}
                                                perPage={10}
                                                tableHead={'bg-darkGray text-white'}
                                                tableBody={'bg-white'}
                                                tableType={'table-light-hover'}
                                                perPageClass={`bg-transparent text-lightGray`}
                                                textColor={'text-black'}
                                                selectedColor={'bg-backGroundGray'}
                                                dashboard={true}
                                                sortByColumn={"anomaly_time"}
                                                riskTooltip={[]}
                                                onClickRow={tableRow => {}}
                                            />
                                        :
                                            <div className='d-flex justify-content-center m-4'>
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AnomalyDashboard