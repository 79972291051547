import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { Input } from "reactstrap";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../common/MultiSelectSection";
import { validateAmiId } from "../../../utils/utility";

const ClusterSection = (props) => {
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );
  const createUpdateClusterValidation = useSelector(
    (state) =>
      state?.hpc?.hpcPropsDetails?.createUpdateClusterValidation || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const [state, setState] = useState({});

  const callPageVist = useCallback(() => {
    let clusterConfiguration =
      createUpdateCluster && createUpdateCluster.cluster_configuration
        ? createUpdateCluster.cluster_configuration
        : {};

    Object.entries(clusterConfiguration).forEach(([key, value]) => {
      let clusterFields = [
        "os",
        "custom_ami",
        "cloudwatch_logs_deletion_policy",
        "disable_simultaneous_multitreading",
        "detailed_monitoring",
        "cloudwatch_logs_retention_in_days",
        "cloudwatch_logs_enabled",
        "cloudwatch_dashboard_enabled"
      ];
      if (key === "os" && value) {
        setState((prevState) => ({ ...prevState, operatingSystem: true }));
      } else if (key === "custom_ami" && value) {
        setState((prevState) => ({ ...prevState, customAmi: true }));
      }
      if (clusterFields.includes(key)) {
        setState((prevState) => ({ ...prevState, [key]: value }));
      }
    });

    let selected_tags =
      createUpdateCluster &&
      createUpdateCluster.tags &&
      createUpdateCluster.tags.length
        ? createUpdateCluster.tags
        : [];

    setState((prevState) => ({ ...prevState, selected_tags }));
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const addNewTagValue = () => {
    let selectedTags = state.selected_tags ? state.selected_tags : [];

    if (state.tag_key && state.tag_value && state.tag_value.length) {
      let dataRow = {};
      dataRow.key = state.tag_key;
      dataRow.value = state.tag_value;
      selectedTags.push(dataRow);
    }

    setState((prevState) => ({
      ...prevState,
      selected_tags: selectedTags,
      tag_key: "",
      tag_value: "",
    }));

    handleInputChange("tags", selectedTags);
  };

  const removeTagSection = (tag) => {
    let filteredResult =
      state.selected_tags && state.selected_tags.filter((e) => e !== tag);
    setState((prevState) => ({ ...prevState, selected_tags: filteredResult }));

    handleInputChange("tags", filteredResult);
  };

  const handleInputChange = (label, value) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    obj[label] = value;
    if (label === "provider") {
      obj["account_id"] = "";
      obj["region"] = "";
    }

    if (label === "custom_ami") {
      delete obj.os;
    } else if (label === "os") {
      delete obj.custom_ami;
    }
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const handleConfigurationInputChange = (label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let clusterConfiguration = obj.cluster_configuration
      ? obj.cluster_configuration
      : {};
    clusterConfiguration[label] = value;
    obj["cluster_configuration"] = clusterConfiguration
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    clusterConfiguration[label] = type === "integer" ? parseInt(value) : value;
    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div onClick={(event) => handleChildClick(event, "parent")}>
      <div className="rounded bg-dark p-3 mt-3">
        <div className="d-flex">
          <div className="py-1 w-100">
            <div className="w-40">
              <div className="d-flex justify-content-between">
                <p className="b-block mb-0">Cluster name</p>
                {createUpdateClusterValidation &&
                createUpdateCluster &&
                !createUpdateCluster.cluster_name ? (
                  <span className="ml-1 text-danger">required</span>
                ) : null}
              </div>

              <Input
                type="text"
                placeholder="Enter Cluster name"
                className={`inputTextbox bg-transparent border-lightGray ${
                  props.pageMode !== "Create"
                    ? "disabled text-primary-color"
                    : "text-white"
                }`}
                // maxlength='128'
                value={
                  createUpdateCluster && createUpdateCluster.cluster_name
                    ? createUpdateCluster.cluster_name
                    : ""
                }
                onChange={(e) => {
                  if (props.pageMode === "Create") {
                    handleInputChange("cluster_name", e.target.value);
                  }
                }}
              />
            </div>
            {propsClusterFilters &&
            propsClusterFilters.description &&
            propsClusterFilters.description.cluster_name ? (
              <p className="mt-1 mb-0 text-info">
                {propsClusterFilters.description.cluster_name}
              </p>
            ) : null}
          </div>
        </div>
        <div className="d-flex mt-3">
          <div className="py-1 w-100">
            <div className="d-flex justify-content-start">
              <div className="d-flex mr-2">
                <div className="ml-1 form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    disabled={
                      !state.operatingSystem && props.pageMode !== "Create"
                        ? true
                        : false
                    }
                    checked={state.operatingSystem ? true : false}
                    onChange={() => {
                      if (props.pageMode === "Create") {
                        setState((prevState) => ({
                          ...prevState,
                          operatingSystem: true,
                          customAmi: false,
                        }));
                      }
                    }}
                    value={state.operatingSystem ? true : false}
                  />
                </div>
                <p
                  className="form-check-label mb-0 mr-2 text-white align-self-center text-primary-color"
                  onClick={() => {
                    if (props.pageMode === "Create") {
                      setState((prevState) => ({
                        ...prevState,
                        operatingSystem: true,
                        customAmi: false,
                      }));
                    }
                  }}
                >
                  Operating System
                </p>
              </div>
              <div className="d-flex mr-2">
                <div className="ml-1 form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    disabled={
                      !state.customAmi && props.pageMode !== "Create"
                        ? true
                        : false
                    }
                    checked={state.customAmi ? true : false}
                    onChange={() => {
                      if (props.pageMode === "Create") {
                        setState((prevState) => ({
                          ...prevState,
                          customAmi: true,
                          operatingSystem: false,
                        }));
                      }
                    }}
                    value={state.customAmi ? true : false}
                  />
                </div>
                <p
                  className="form-check-label mb-0 mr-2 text-white align-self-center text-primary-color"
                  onClick={() => {
                    if (props.pageMode === "Create") {
                      setState((prevState) => ({
                        ...prevState,
                        customAmi: true,
                        operatingSystem: false,
                      }));
                    }
                  }}
                >
                  Custom AMI
                </p>
              </div>
            </div>
            {state.operatingSystem ? (
              <React.Fragment>
                <div className="w-20 mt-2">
                  {/* <div className='d-flex justify-content-between'>
                                    <label className='align-self-center m-0'>Operating system</label>
                                    {state.hasError && state.os ? 
                                        <small className='text-issues'>required</small>
                                    : null}
                                </div> */}
                  <div
                    className="border-lightGray bg-transparent rounded-5"
                    onClick={(event) => {
                      if (!state.showOs) {
                        event.preventDefault();
                        handleChildClick(
                          event,
                          "child",
                          "singleDropDown",
                          "showOs"
                        );
                      }
                    }}
                  >
                    <p
                      className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                        state.os && props.pageMode === "Create"
                          ? "text-white"
                          : "placeholder "
                      }`}
                    >
                      {state.os
                        ? propsClusterFilters &&
                          propsClusterFilters.os &&
                          propsClusterFilters.os.filter(
                            (e) => e.value === state.os
                          ).length
                          ? propsClusterFilters.os.filter(
                              (e) => e.value === state.os
                            )[0].key
                          : state.os
                        : "Select"}
                      {props.pageMode === "Create" ? (
                        <i className="fa fa-caret-down mt-1" />
                      ) : null}
                    </p>
                    {state.showOs && props.pageMode === "Create" ? (
                      <MultiSelectSection
                        fields={["value", "key"]}
                        options={
                          propsClusterFilters && propsClusterFilters.os
                            ? propsClusterFilters.os
                            : []
                        }
                        selectedValues={state.os ? state.os : ""}
                        callbackMultiSelect={(value) => {
                          if (!value || typeof value === "string") {
                            setState((prevState) => ({
                              ...prevState,
                              os: value,
                            }));
                            handleConfigurationInputChange("os", value);
                          } else {
                            value.preventDefault();
                            handleChildClick(
                              value,
                              "search",
                              "singleDropDown",
                              ""
                            );
                          }
                        }}
                        singleSelection={true}
                        hideSearch={false}
                        topClass={"auto"}
                        widthClass={"minWidth220"}
                      />
                    ) : null}
                  </div>
                </div>
                {propsClusterFilters &&
                propsClusterFilters.description &&
                propsClusterFilters.description.cluster_configuration &&
                propsClusterFilters.description.cluster_configuration.os ? (
                  <p className="mt-1 mb-0 text-info">
                    {propsClusterFilters.description.cluster_configuration.os}
                  </p>
                ) : null}
              </React.Fragment>
            ) : state.customAmi ? (
              <div className="w-20 mt-2">
                <Input
                  type="text"
                  placeholder="Enter custom ami"
                  className="inputTextbox border-lightGray bg-transparent text-white"
                  // maxlength='128'
                  disabled={props.pageMode !== "Create" ? true : false}
                  value={state.custom_ami ? state.custom_ami : ""}
                  onChange={(e) => {
                    if (props.pageMode === "Create") {
                      setState((prevState) => ({
                        ...prevState,
                        custom_ami: validateAmiId(e.target.value),
                      }));
                      handleConfigurationInputChange(
                        "custom_ami",
                        validateAmiId(e.target.value)
                      );
                    }
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="d-flex mt-3">
          <div className="py-1 w-100 align-self-center">
            <div className="d-flex">
              <label className="mb-0">
                <input
                  type="checkbox"
                  className="inputdemo mt-1"
                  checked={
                    state.disable_simultaneous_multitreading ? true : false
                  }
                  disabled={
                    props.selectedData &&
                    props.selectedData.cluster_configuration &&
                    props.selectedData.cluster_configuration
                      .disable_simultaneous_multitreading
                      ? true
                      : false
                  }
                  onChange={(event) => {
                    if (
                      props.pageMode === "Create" ||
                      (props.selectedData &&
                        props.selectedData.cluster_configuration &&
                        !props.selectedData.cluster_configuration
                          .disable_simultaneous_multitreading)
                    ) {
                      let disable_simultaneous_multitreading =
                        !state.disable_simultaneous_multitreading;
                      setState(prevState => ({ ...prevState, disable_simultaneous_multitreading: !state.disable_simultaneous_multitreading}))
                      handleConfigurationInputChange(
                        "disable_simultaneous_multitreading",
                        disable_simultaneous_multitreading
                      );
                    }
                  }}
                />
              </label>
              <span className="mb-0 text-lightGray f16 ml-2">
                Disable simultaneous multitreading
              </span>
            </div>
          </div>
        </div>

        <p className="text-white f16 mt-3">Monitoring</p>
        <div className="bg-dark3 p-3 rounded mt-3">
          <div className={"d-flex"}>
            <div className="py-1 w-100 align-self-center">
              <div className="d-flex">
                <label className="mb-0">
                  <input
                    type="checkbox"
                    className="inputdemo mt-1"
                    checked={state.detailed_monitoring ? true : false}
                    // disabled={
                    //   props.selectedData &&
                    //   props.selectedData.cluster_configuration &&
                    //   props.selectedData.cluster_configuration
                    //     .detailed_monitoring
                    //     ? true
                    //     : false
                    // }
                    onChange={(event) => {
                      if (
                        props.pageMode === "Create" ||
                        (props.selectedData &&
                          props.selectedData.cluster_configuration &&
                          !props.selectedData.cluster_configuration
                            .detailed_monitoring)
                      ) {
                        let detailed_monitoring = !state.detailed_monitoring;
                        setState(prevState => ({ ...prevState, detailed_monitoring: !state.detailed_monitoring}))
                        handleConfigurationInputChange(
                          "detailed_monitoring",
                          detailed_monitoring
                        );
                      }
                    }}
                  />
                </label>
                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                  Enable detailed monitoring
                </span>
              </div>
            </div>
          </div>
          <div className={"d-flex"}>
            <div className="py-1 w-100 align-self-center">
              <div className="d-flex">
                <label className="mb-0">
                  <input
                    type="checkbox"
                    className="inputdemo mt-1"
                    checked={state.cloudwatch_dashboard_enabled ? true : false}
                    // disabled={
                    //   props.selectedData &&
                    //   props.selectedData.cluster_configuration &&
                    //   props.selectedData.cluster_configuration
                    //     .cloudwatch_dashboard_enabled
                    //     ? true
                    //     : false
                    // }
                    onChange={(event) => {
                      if (
                        props.pageMode === "Create" ||
                        (props.selectedData &&
                          props.selectedData.cluster_configuration &&
                          !props.selectedData.cluster_configuration
                            .cloudwatch_dashboard_enabled)
                      ) {
                        let cloudwatch_dashboard_enabled =
                          !state.cloudwatch_dashboard_enabled;
                        setState(prevState => ({ ...prevState, cloudwatch_dashboard_enabled: !state.cloudwatch_dashboard_enabled}))
                        handleConfigurationInputChange(
                          "cloudwatch_dashboard_enabled",
                          cloudwatch_dashboard_enabled
                        );
                      }
                    }}
                  />
                </label>
                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                  Enable cloudwatch dashboard
                </span>
              </div>
            </div>
          </div>
          <div className={"d-flex"}>
            <div className="py-1 w-100 align-self-center">
              <div className="d-flex">
                <label className="mb-0">
                  <input
                    type="checkbox"
                    className="inputdemo mt-1"
                    // disabled={
                    //   props.selectedData &&
                    //   props.selectedData.cluster_configuration &&
                    //   props.selectedData.cluster_configuration
                    //     .cloudwatch_logs_enabled
                    //     ? true
                    //     : false
                    // }
                    checked={state.cloudwatch_logs_enabled ? true : false}
                    onChange={(event) => {
                      if (
                        props.pageMode === "Create" ||
                        (props.selectedData &&
                          props.selectedData.cluster_configuration &&
                          !props.selectedData.cluster_configuration
                            .cloudwatch_logs_enabled)
                      ) {
                        let cloudwatch_logs_enabled =
                          !state.cloudwatch_logs_enabled;
                        // setState(prevState => ({ ...prevState, cloudwatch_logs_enabled: !state.cloudwatch_logs_enabled}))
                        handleConfigurationInputChange(
                          "cloudwatch_logs_enabled",
                          cloudwatch_logs_enabled
                        );
                      }
                    }}
                  />
                </label>
                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                  Enable cloudwatch logs
                </span>
              </div>
            </div>
          </div>
          {state.cloudwatch_logs_enabled ? (
            <div className={"d-flex mt-2 pt-2"}>
              <div className="py-1 w-20">
                <div className="d-flex justify-content-between">
                  <label className="align-self-center m-0">
                    Deletion policy
                  </label>
                  {state.hasError && state.cloudwatch_logs_deletion_policy ? (
                    <small className="text-issues">required</small>
                  ) : null}
                </div>
                <div
                  className="border-lightGray bg-transparent rounded-5"
                  onClick={(event) => {
                    if (!state.showDeletionPolicy) {
                      event.preventDefault();
                      handleChildClick(
                        event,
                        "child",
                        "singleDropDown",
                        "showDeletionPolicy"
                      );
                    }
                  }}
                >
                  <p
                    className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${
                      state.cloudwatch_logs_deletion_policy
                        ? "text-white hiddenEllipses"
                        : "placeholder"
                    }`}
                  >
                    {state.cloudwatch_logs_deletion_policy ? state.cloudwatch_logs_deletion_policy : "Select"}
                    <i className="fa fa-caret-down mt-1" />
                  </p>
                  {state.showDeletionPolicy && props.pageMode !== "edit" ? (
                    <MultiSelectSection
                      // fields={["value", "key"]}
                      options={
                        propsClusterFilters &&
                        propsClusterFilters.deletion_policy
                          ? propsClusterFilters.deletion_policy
                          : []
                      }
                      selectedValues={
                        state.deletion_policy ? state.deletion_policy : ""
                      }
                      callbackMultiSelect={(value) => {
                        if (!value || typeof value === "string") {
                          setState((prevState) => ({
                            ...prevState,
                            ldap_tls_request_certificate: value,
                          }));
                          handleConfigurationInputChange(
                            "cloudwatch_logs_deletion_policy",
                            value
                          );
                        } else {
                          value.preventDefault();
                          handleChildClick(
                            value,
                            "search",
                            "singleDropDown",
                            ""
                          );
                        }
                      }}
                      singleSelection={true}
                      hideSearch={false}
                      topClass={"auto"}
                      widthClass={"minWidth220"}
                    />
                  ) : null}
                </div>
              </div>
              {/* <div className='py-1 w-20'>
                                <div className='d-flex justify-content-between'>
                                    <label className='align-self-center m-0'>Deletion policy</label>
                                    {state.hasError && !state.cloudwatch_logs_deletion_policy ? (
                                        <small className='text-issues'>required</small>
                                    ) : null}
                                </div>
                                {props.pageMode !== 'edit' ?
                                    <Input
                                        type='text'
                                        placeholder='Enter deletion policy'
                                        className='inputTextbox border-lightGray bg-transparent text-white'
                                        // maxlength='128'
                                        value={state.cloudwatch_logs_deletion_policy ? state.cloudwatch_logs_deletion_policy : ''}
                                        onChange={e => {
                                            setState(prevState => ({ ...prevState, cloudwatch_logs_deletion_policy: e.target.value}))
                                            handleConfigurationInputChange('cloudwatch_logs_deletion_policy', e.target.value)
                                        }}
                                    />
                                
                                :
                                    <p className='mb-0 mt-2 text-white f16 border-lightGray rounded-5 px-2 py-1'>{state.cloudwatch_logs_deletion_policy ? state.cloudwatch_logs_deletion_policy : ''}</p>
                                }
                            </div> */}
              <div className="py-1 w-20 ml-5">
                <div className="d-flex justify-content-between">
                  <label className="align-self-center m-0">
                    Retention in days
                  </label>
                  {state.hasError &&
                  !state.cloudwatch_logs_retention_in_days ? (
                    <small className="text-issues">required</small>
                  ) : null}
                </div>
                {props.pageMode !== "edit" ? (
                  <div
                    className="border-lightGray bg-transparent rounded-5"
                    onClick={(event) => {
                      if (!state["isLogsRetentionDays"]) {
                        event.preventDefault();
                        handleChildClick(
                          event,
                          "child",
                          "singleDropDown",
                          "isLogsRetentionDays"
                        );
                      }
                    }}
                  >
                    <p
                      className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                        state["cloudwatch_logs_retention_in_days"] &&
                        props.pageMode === "Create"
                          ? "text-white"
                          : "placeholder"
                      }`}
                    >
                      {state["cloudwatch_logs_retention_in_days"]
                        ? propsClusterFilters.cloudwatch_logs_retention_in_days && propsClusterFilters.cloudwatch_logs_retention_in_days.filter(
                            (e) =>
                              e.value ===
                              state["cloudwatch_logs_retention_in_days"]
                          ).length
                          ? propsClusterFilters.cloudwatch_logs_retention_in_days.filter(
                              (e) =>
                                e.value ===
                                state["cloudwatch_logs_retention_in_days"]
                            )[0].key
                          : state["cloudwatch_logs_retention_in_days"]
                        : "Select"}
                      <i className="fa fa-caret-down mt-1" />
                    </p>
                    {state["isLogsRetentionDays"] &&
                    props.pageMode === "Create" ? (
                      <MultiSelectSection
                        fields={["value", "key"]}
                        options={
                          propsClusterFilters &&
                          propsClusterFilters.cloudwatch_logs_retention_in_days
                            ? propsClusterFilters.cloudwatch_logs_retention_in_days.map(
                                (d) => ({
                                  key: d,
                                  value: `${d}`,
                                })
                              )
                            : []
                        }
                        selectedValues={
                          state["cloudwatch_logs_retention_in_days"]
                            ? state["cloudwatch_logs_retention_in_days"]
                            : ""
                        }
                        callbackMultiSelect={(value) => {
                          if (!value || typeof value === "string") {
                            handleConfigurationInputChange(
                              "cloudwatch_logs_retention_in_days",
                              value,
                              "integer"
                            );
                            setState((prevState) => ({
                              ...prevState,
                              cloudwatch_logs_retention_in_days: value,
                            }));
                          } else {
                            value.preventDefault();
                            handleChildClick(
                              value,
                              "search",
                              "singleDropDown",
                              ""
                            );
                          }
                        }}
                        singleSelection={true}
                        hideSearch={false}
                        topClass={"auto"}
                        widthClass={"minWidth220"}
                      />
                    ) : null}
                  </div>
                ) : (
                  <p className="mb-0 mt-2 text-white f16 border-lightGray rounded-5 px-2 py-1">
                    {state.cloudwatch_logs_retention_in_days
                      ? state.cloudwatch_logs_retention_in_days
                      : ""}
                  </p>
                )}
              </div>
              {propsClusterFilters &&
              propsClusterFilters.description &&
              propsClusterFilters.description.storage_configuration &&
              propsClusterFilters.description.storage_configuration
                .cloudwatch_logs_retention_in_days ? (
                <p className="mt-1 mb-0 text-info">
                  {
                    propsClusterFilters.description.storage_configuration
                      .cloudwatch_logs_retention_in_days
                  }
                </p>
              ) : null}
            </div>
          ) : null}
        </div>

        <div className="d-flex mt-2 pt-2 border-darkGray-top">
          <div className="py-3 w-100">
            <p className="b-block mb-0">Tags</p>
            <div className="d-flex mb-1">
              <Input
                type="text"
                placeholder="Enter Key"
                className={`inputTextbox bg-transparent border-lightGray text-white w-30`}
                // maxlength='128'
                value={state.tag_key ? state.tag_key : ""}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    tag_key: e.target.value,
                  }))
                }
              />
              <Input
                type="text"
                placeholder="Enter value"
                className={`inputTextbox bg-transparent border-lightGray text-white w-40 ml-3`}
                // maxlength='128'
                value={state.tag_value ? state.tag_value : ""}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    tag_value: e.target.value,
                  }))
                }
              />
              <span
                className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center ml-3`}
                onClick={() => addNewTagValue()}
              ></span>
            </div>
            {state.selected_tags && state.selected_tags.length ? (
              <div className="d-flex flex-wrap">
                {state.selected_tags
                  .filter((e) => e.value !== "All")
                  .map((tag, tIndex) => {
                    return (
                      <span
                        key={"tag_" + tIndex}
                        className="d-flex badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2 breakWhiteSpace"
                        id={"tag_" + tIndex}
                      >
                        <div
                          className="hiddenEllipses"
                          style={{ maxWidth: "250px" }}
                        >
                          {tag.key + " : " + tag.value}
                        </div>
                        <i
                          className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                          onClick={() => removeTagSection(tag)}
                        ></i>
                      </span>
                    );
                  })}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClusterSection;
