import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MultiSelectSection from '../../../common/MultiSelectSection';

import { getAllTagsKeys, getAllTagsValues } from '../../../../actions/commonActionNew'

const TagsSection = (props) => {
    const [state, setState] = useState({
        inputComponent: props.inputComponent,
        selectedPage: props.selectedPage,
        selectedOption: props.selectedOption,
        selectedFilters: props.selectedFilters,
        selectedProvider: props.selectedProvider,
        emptyOptions: [],
    })

    const dispatch = useDispatch()
    const tagKeys = useSelector(state => state.filters.tagKeys);

    useEffect(() => {
        let params = {}
        params.provider = state.selectedProvider.toLowerCase()
        dispatch(getAllTagsKeys(params))

    }, [dispatch, state.selectedProvider])

	useEffect(() => {
        if(state.tag_key) {
            let params = {}
            params.tags = [{ "key": state.tag_key }]
            dispatch(getAllTagsValues(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, tagValues: response }))
                })
        }
	}, [dispatch, state.tag_key])

	const addNewTag = () => {
		let selectedTags = state.selectedFilters ? state.selectedFilters : []

		if(state.tag_key && state.tag_key !== "" && state.tag_value && state.tag_value.length) {
			state.tag_value.forEach(tag => {
				if(tag !== "All" && (!(selectedTags.filter(e => e.key === state.tag_key && e.value !== tag).length) || selectedTags.filter(e => e.key !== state.tag_key))) {
					let dataRow = {}
                    dataRow.label = "Tags"
					dataRow.key = state.tag_key
					dataRow.value = tag
                    dataRow.userView = state.selectedOption+" : "+state.tag_key + " : "+ tag
					selectedTags.push(dataRow)
				}
			})
		}

		setState(prevState => ({ ...prevState, selectedFilters: selectedTags, tag_key: "",  tag_value: [], tagValues: [] }))
	}

	const removeTagSection = (tag) => {
		// filteredResult = state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = state.selectedFilters.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedFilters: filteredResult }))
	}

    const applyFilter = () => {
        if(state.selectedFilters && state.selectedFilters.length) {
            return props.selectedInput(state.selectedOption, state.selectedFilters)
        } else {
            return props.selectedInput(false);
        }
    }

    const closeFilter = () => {
        return props.selectedInput(false);
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='container-fluid mt-2 py-3 bg-dark3 containerBorderFilterSection' onClick={(event) => handleChildClick(event, 'parent')}>
            <p className="text-white mb-2">{state.selectedOption}</p>
            <div className="d-flex flex-wrap mb-3">
                <div className='w-30'>
                    <div onClick={(event) => {
                        if(!state.showTagKey) {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'singleDropDown', 'showTagKey')
                        }
                    }}>
                        <p className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${state.tag_key ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>
                            {state.tag_key ? state.tag_key : 'Select'}
                            <i className='fa fa-caret-down mt-1'/>
                        </p>
                        {state.showTagKey ?
                            <div className='position-relative'>
                            <MultiSelectSection 
                                // fields={["account_id", "account_name"]}
                                // className={}
                                removeTop={true}
                                options={tagKeys ? tagKeys : []}
                                selectedValues={state.tag_key ? state.tag_key : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, tag_key: value }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                widthClass={'minWidth220'}
                            />
                            </div>
                        : null}
                    </div>
                </div>
                <div className='w-40 ml-3'>
                    <div onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'muliselectDropDown', "showSecurityGraoupDropdown")
                    }}>
                        <p className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${state.tag_value && state.tag_value.length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}
                            <i className='fa fa-caret-down mt-1'/>
                        </p>
                        {state.showSecurityGraoupDropdown ?
                            <div className='position-relative'>
                            <MultiSelectSection 
                                // fields={["account_id", "account_name"]}
                                // className={}
                                removeTop={true}
                                options={state.tagValues ? state.tagValues : []}
                                selectedValues={state.tag_value ? state.tag_value : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState, tag_value: value }))
                                }}
                                widthClass={'minWidth220'}
                            />
                            </div>
                        : null}
                    </div>
                </div>
                <span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center ml-2`} onClick={() => addNewTag()}></span>
            </div>
            
            {state.selectedFilters && state.selectedFilters.length ? 
                state.selectedFilters.map(tag => {
                    return(
                        <span className="badge bg-mediumDarkGray mr-2 mt-2 align-self-center f14 text-white px-2">{tag.key +" : "+tag.value}
                            <i className='ml-2 f14 fas fa-times-circle cursorPointer text-white' onClick={ () => removeTagSection(tag) } ></i>
                        </span>
                    )
                })
            : null}
            
            <div className='row mt-2 mb-3'>
                <div className="col-sm-6"></div>
                <div className="col-sm-6 justify-content-end d-flex">
                    <span className="dropCancelBtn d-block p-2 mr-2" onClick={() => closeFilter()}>
                        Cancel
                    </span>
                    <span className="dropApplyBtn d-block p-2" onClick={() => applyFilter()}>
                        Apply
                    </span>
                </div>
            </div>
        </div>
    );
}
export default TagsSection