/*************************************************
 * Collider
 * @exports
 * @file aiopsReducer.js
 * @author Prakash // on 22/06/2023
 * @copyright © 2021 Collider. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
    aiopsCoveredResources: {},
    aiopsRemediationEstimate: {},
    remediationDetails: {},
    artifactDetails: {},
    listCaseDetails: {},
    remediationCount: {},
    remediationTrend: {},
    remediationTotalCount: {},
    serviceCasesTotalCount: {},
    accountCasesTotalCount: [],
    regionCasesTotalCount: [],
    resourceCasesTotalCount: [],
    severityCasesTotalCount: [],
    statusCasesTotalCount: [],
    aiopsDailyTrend: {},
    caseDetails: {},
    eventDetails: {},
    alertEventDetails: {},
    totalAlertCount: [],
    aiopsPropsDetails: {},
    aiopsMetricsAlertEventDailyTrend: {},
    listAutomationActionsProps: [],
    diagnosticsPropsDetails: {},
    aiopsDiagnosticTemplateList: [],
    diagnosticsTroubleshootDetails: [],
}

/**
 * To store assets list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const aiopsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
        case ACTION_TYPES.SET_AIOPS_COVERED_RESOURCES:
        return {
            ...state,
            aiopsCoveredResources: { ...action.payload },
        }
        case ACTION_TYPES.SET_AIOPS_REMEDIATION_ESTIMATE:
        return {
            ...state,
            aiopsRemediationEstimate: { ...action.payload },
        }
        case ACTION_TYPES.SET_REMEDIATION_DETAILS:
        return {
            ...state,
            remediationDetails: { ...action.payload },
        }
        case ACTION_TYPES.SET_ARTIFACT_DETAILS:
        return {
            ...state,
            artifactDetails: { ...action.payload },
        }
        case ACTION_TYPES.SET_REMEDIATION_STATUS:
        return {
            ...state,
            remediationStatus: { ...action.payload },
        }
        case ACTION_TYPES.SET_LIST_CASE_DETAILS:
        return {
            ...state,
            listCaseDetails: { ...action.payload },
        }
        case ACTION_TYPES.SET_REMEDIATION_COUNT:
        return {
            ...state,
            remediationCount: { ...action.payload },
        }
        case ACTION_TYPES.SET_AIOPS_SERVICE_CASES_TOTAL_COUNT:
        return {
            ...state,
            serviceCasesTotalCount: { ...action.payload },
        }
        case ACTION_TYPES.SET_AIOPS_ACCOUNT_CASES_TOTAL_COUNT:
        return {
            ...state,
            accountCasesTotalCount: [ ...action.payload ],
        }
        case ACTION_TYPES.SET_AIOPS_REGION_CASES_TOTAL_COUNT:
        return {
            ...state,
            regionCasesTotalCount: [ ...action.payload ],
        }
        case ACTION_TYPES.SET_AIOPS_RESOURCE_CASES_TOTAL_COUNT:
        return {
            ...state,
            resourceCasesTotalCount: [ ...action.payload ],
        }
        case ACTION_TYPES.SET_AIOPS_SEVERITY_CASES_TOTAL_COUNT:
        return {
            ...state,
            severityCasesTotalCount: [ ...action.payload ],
        }
        case ACTION_TYPES.SET_AIOPS_STATUS_CASES_TOTAL_COUNT:
        return {
            ...state,
            statusCasesTotalCount: [ ...action.payload ],
        }
        case ACTION_TYPES.SET_AIOPS_DAILY_TREND:
        return {
            ...state,
            aiopsDailyTrend: { ...action.payload },
        }
        case ACTION_TYPES.SET_TOTAL_REMEDIATION_COUNT:
        return {
            ...state,
            remediationTotalCount: { ...action.payload },
        }
        case ACTION_TYPES.SET_AIOPS_REMEDIATION_TREND:
        return {
            ...state,
            remediationTrend: { ...action.payload },
        }
        case ACTION_TYPES.SET_GET_CASE_DETAILS:
        return {
            ...state,
            caseDetails: { ...action.payload },
        }       
        case ACTION_TYPES.SET_AIOPS_EVENT_DETAILS:
        return {
            ...state,
            eventDetails: { ...action.payload },
        }
        case ACTION_TYPES.SET_ALERT_EVENT_DETAILS:
        return {
            ...state,
            alertEventDetails: [ ...action.payload ],
        }
        case ACTION_TYPES.SET_AIOPS_TOTAL_ALERT_COUNT:
        return {
            ...state,
            totalAlertCount: [ ...action.payload ],
        }
		case ACTION_TYPES.SET_AIOPS_PROPS_DETAILS:
        return {
            ...state,
            aiopsPropsDetails: { ...action.payload },
        }
        case ACTION_TYPES.SET_AIOPS_DAILY_TREND_BY_ASSETS:
        return {
            ...state,
            aiopsMetricsAlertEventDailyTrend: { ...action.payload },
        }  
        case ACTION_TYPES.SET_AIOPS_DIAGNOSTICS_LIST:
            return {
                ...state,
                aiopsDiagnosticsList: { ...action.payload },
            }
        case ACTION_TYPES.SET_AIOPS_DIAGNOSTICS_DETAILS:
            return {
                ...state,
                aiopsDiagnosticsDetails: { ...action.payload },
            }
        case ACTION_TYPES.SET_AIOPS_DIAGNOSTICS_TEMPLATE_LIST:
            return {
                ...state,
                aiopsDiagnosticTemplateList: [ ...action.payload ],
            }
        case ACTION_TYPES.SET_DIAGNOSTICS_TROUBLESHOOT_DETAILS:
            return {
                ...state,
                diagnosticsTroubleshootDetails: [ ...action.payload ],
            }
        case ACTION_TYPES.SET_DIAGNOSTICS_PROPS_DETAILS:
			return {
				...state,
				diagnosticsPropsDetails: { ...action.payload },
			}
        case ACTION_TYPES.SET_AIOPS_RCA_LIST:
            return {
                ...state,
                aiopsRcaList: { ...action.payload },
            }
        case ACTION_TYPES.SET_AIOPS_RCA_DETAILS:
            return {
                ...state,
                aiopsRcaDetails: { ...action.payload },
            }
        case ACTION_TYPES.SET_LIST_AUTOMATION_ACTIONS:
            return {
                ...state,
                listAutomationActionsProps: [ ...action.payload ],
            }
		default:
			return state
	}
}
