/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsTab.js
 * @author Prakash // on 05/04/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listAlerts, getAlertsDailyTrend } from '../../actions/Collider/ObservabilityAction'
// import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'

import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionUtcToLocalTime, capitalizeFirstLetter, momentConvertionLocalToUtcTime, subDays, getDayFromSelectedDuration, subHours } from '../../utils/utility'

import Search from '../common/SearchComponent'
import ApexTrendBarChart from '../common/charts/ApexTrendBarChart'
import MultiSelectSection from '../common/MultiSelectSection'
import { DateRange } from 'react-date-range'

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const AlertsTab = (props) => {
    const dateRef = useRef();

    const [state, setState] = useState({
        alertListLoader: true,
        
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
        
        pageType: "Alerts",
        alertType: "All",
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        minimizeGraphSection: false,
        selectedGraphDuration: '+3d'
    })

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

    const dispatch = useDispatch()

    

    useEffect(() => {
       	let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedGraphDuration !== 'custom') {
            let period = state.selectedGraphDuration.substring(state.selectedGraphDuration.length, state.selectedGraphDuration.length - 1);
            let durationBtw = getDayFromSelectedDuration(state.selectedGraphDuration);
            
            if (period === 'd') {
                datePickerStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                datePickerStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');

            setState(prevState => ({ 
                ...prevState, 
                datePickerStartDate, 
                datePickerEndDate,
                alertListLoader: true,
                graphData: {},
                callAlertsDatilyTrend: true,
                callAlertGroups: true
            }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

            setDateState([dateSelection])
        }

	}, [state.selectedGraphDuration])

    useEffect(() => {
        if(state.callAlertsDatilyTrend) {
            setState(prevState => ({ ...prevState, callAlertsDatilyTrend: false }))
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            let assetId = props.selectedData && props.selectedData.asset_id ? props.selectedData.asset_id : ""
            // params.asset_id = assetId
            if(props.selectedClusterDetails && Object.entries(props.selectedClusterDetails).length) {
                params.provider = props.selectedClusterDetails.provider
                params.account_id = props.selectedClusterDetails.account_id
                params.region = props.selectedClusterDetails.region
                params.cluster_name = props.selectedClusterDetails.cluster_name
            } else {
                params.asset_id = assetId
            }

            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            dispatch(getAlertsDailyTrend(params))
                .then((response) => {
                    if(response && !response.error) {
                        setState(prevState => ({ ...prevState, alertDailyTrendData: response }))   
                    } else {
                        setState(prevState => ({ ...prevState, alertDailyTrendData: {} }))
                    }
                })
                
            setState(prevState => ({ ...prevState, callAlertsTrend: true }))

        }
    }, [state.callAlertsDatilyTrend, dispatch, state.selectedAsset, state.datePickerStartDate, state.datePickerEndDate, props])

    useEffect(() => {
        // let graphData = {
        //     start_time: momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
        //     end_time: momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
        // }
        let graphData = {}
        let trendSeverityCount = state.alertDailyTrendData && state.alertDailyTrendData.total_count ? state.alertDailyTrendData.total_count : {}
        let graphDetails = state.alertDailyTrendData && state.alertDailyTrendData.results ? state.alertDailyTrendData.results : {}           
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            graphData = {
                data: graphDetails.data.count,
                labels: graphDetails.labels,
                // start_time: momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                // end_time: momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            }
        }

        setState(prevState => ({ ...prevState, graphData, trendSeverityCount }))

    }, [state.alertDailyTrendData,])

    const resetGraph = () => {
        let graphData = state.graphData
        setState(prevState => ({ ...prevState, graphData: {}, zoomedStartDate: "", zoomedEndDate: "", calltoGetCategoryALerts: true }))
        setTimeout(() => {
            setState(prevState => ({ ...prevState, graphData }))            
        }, 2000); 
    }
    
    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

    const gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= state.totalPages) {
            let startRecord = (pageNumber - 1) * state.perPage
            setState(prevState => ({ ...prevState,  currentPage: pageNumber, pageNumber, startRecord }))
        } else  if(pageNumber > state.totalPages) {
            setState(prevState => ({ ...prevState,  pageNumber: state.currentPage })  )
        } else {
            setState(prevState => ({ ...prevState,  pageNumber }))
        }
    }

    useEffect(() => {
        if(state.callAlertGroups) {
            setState(prevState => ({ ...prevState, callAlertGroups: false }))
            let params = {}
            // params.alerts_case_id = '2408-1028-7602'
            
            if(props.selectedClusterDetails && Object.entries(props.selectedClusterDetails).length) {
                params.provider = props.selectedClusterDetails.provider
                params.account_id = props.selectedClusterDetails.account_id
                params.region = props.selectedClusterDetails.region
                params.cluster_name = props.selectedClusterDetails.cluster_name
            } else {
                params.asset_id = props.selectedData && props.selectedData.asset_id ? props.selectedData.asset_id : ""
            }

            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            // let assetId = state.selectedAsset && state.selectedAsset.asset_id ? state.selectedAsset.asset_id : ""
            // params.asset_id = assetId

            params.aggregate_by = state.groupedBy
            
            if(state.alertType !== "All") {
                params.alert_type = state.alertType
            }   
            
            dispatch(listAlerts(params))
                .then((response) => {
                    if(response && !response.error) {
                        let data = response.results && response.results.details ? response.results.details : []
                        setState(prevState => ({ ...prevState, alertResponse: data, alertListLoader: false }))
                    } else {
                        setState(prevState => ({ ...prevState, alertResponse: [], alertListLoader: false }))
                    }
                })
        }

    }, [state.callAlertGroups, state.alertGroupData, dispatch, props, state.alertType, state.groupedBy, state.selectedAsset, state.datePickerEndDate, state.datePickerStartDate])

    useEffect(() => {
        let listAllAlerts = []
        let totalCount = 0
        let alertsCategory = []
        if(state.alertResponse && state.alertResponse.length) {
            totalCount = state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
            state.alertResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                alertsCategory.push(dataRow)
                item.alerts.forEach(alt => {
                    listAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ""
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        alertsCategory.push(dataRow)

        setState(prevState => ({ ...prevState, listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc']), calltoGetCategoryALerts: true  }))
    }, [state.alertResponse])

    useEffect(() => {
        if(state.calltoGetCategoryALerts) {
            let filterdCategoryAlerts = state.listAllAlerts
            if(state.selectedCategory !== 'Important / All') {
                filterdCategoryAlerts = state.alertResponse.filter(arr => arr.label === state.selectedCategory)
                if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                    filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
                }
            }
    
            let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])
    
            let totalPages = 1
            if(orderedArray.length > state.perPage) {
                totalPages = Math.ceil(orderedArray.length / state.perPage)
            }
            setState(prevState => ({ ...prevState, totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlert: "", selectedAlertId: "", totalPages, startRecord: 0, currentPage: 1, calltoGetCategoryALerts: false, }))
        }
    }, [state.calltoGetCategoryALerts, state.alertResponse, state.listAllAlerts, state.perPage, state.selectedCategory])

    const filterZoomData = (zoomedStartDate, zoomedEndDate) => {
        let data = state.totalFilteredArray ? state.totalFilteredArray : []

        data = data.filter(e => momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") >= zoomedStartDate && momentConvertionUtcToLocalTime(e.event_time, "YYYY-MM-DD HH:mm:ss") <= zoomedEndDate)

        data = _.orderBy(data, ['event_time'], ['desc'])

        let totalPages = 1
		if(data.length > state.perPage) {
			totalPages = Math.ceil(data.length / state.perPage)
		}

        setState(prevState => ({ ...prevState, totalFilteredArray: data, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, zoomedStartDate, zoomedEndDate }))

    }

    const getAlertDetails = (alertItemDetails) => {
        setState(prevState => ({ 
            ...prevState, 
            selectedAlertId: alertItemDetails.event_id === state.selectedAlertId ? "" : alertItemDetails.event_id, 
            selectedAlert: alertItemDetails.event_id === state.selectedAlertId ? "" : alertItemDetails,
            expandAlertDetails: true,
            expandAssetDetails: false
        }))
    }
    
    const handleChange = (event) => {
        let groupedBy = state.groupedBy
        if(event.target.value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }
        setState(prevState => ({ ...prevState, alertType: event.target.value, groupedBy, selectedAlert: "", selectedAlertId: "", alertListLoader: true, callAlertGroups: true }))
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false,
                selectedGraphDuration: "custom",                
                alertListLoader: true,
                graphData: {},
                callAlertsDatilyTrend: true,
                callAlertGroups: true
            }));
    
        }
        setDateState([date.selection])
    };

    return (
        <div className="" onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='d-flex justify-content-between mb-2'>
                <p className='mb-1 align-self-center'>Showing data in <span className='ml-1'>(local time)</span> </p>
                <div className='py-1 w-50 pl-3 d-flex justify-content-end'>
                    <p className="b-block mb-0 text-primary-color align-self-center mr-3">Duration</p>
                    <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'singleDropDown', "showDuration")
                    }}>
                        <p className={`d-flex p-2 bg-transparent border-lightGray mb-0 rounded-5 d-flex justify-content-between ${state.roleId ? 'text-white' : 'placeholder'}`}>
                            {state.selectedGraphDuration && durationOptions.filter(e => e.value === state.selectedGraphDuration).length ? durationOptions.filter(e => e.value === state.selectedGraphDuration)[0].option : 'Select'}
                            <i className='fa fa-caret-down mt-1'/>
                        </p>
                        {state.showDuration ?
                            <div className='position-relative'>
                                <MultiSelectSection 
                                    fields={["value", "option"]}
                                    className={'w-70'}
                                    removeTop={true}
                                    options={durationOptions}
                                    selectedValues={state.selectedGraphDuration ? state.selectedGraphDuration : ''}
                                    callbackMultiSelect={(value) => {
                                        if(value !== 'custom') {
                                            setState(prevState => ({ ...prevState, selectedGraphDuration: value }))
                                        } else {
                                            setState(prevState => ({ ...prevState, showDateRangePicker: true }))
                                        }
                                    }}
                                    singleSelection={true}
                                    hideSearch={true}
                                    // makeLabelUpperCase={'capitalizeAllLetter'}
                                />
                            </div>
                        : null}
                    </div>
                </div>
                {state.showDateRangePicker ?                                                
                    <div className='headerDateRange mt-2' style={{'z-index': '999999999','position': 'absolute','right': '20px','margin-top': '50px'}} ref={dateRef}>
                        <DateRange
                            editableDateInputs={true}
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            ranges={dateState}
                        />
                    </div>
                : null}
            </div>
            <div className="bg-dark2 mt-2 p-2 rounded">
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <label className="m-0 p-0">Total ({state.trendSeverityCount && state.trendSeverityCount.total ? state.trendSeverityCount.total : 0})</label>
                        <div className="d-flex ml-2 align-self-center">
                            <div className={`f10 badge-square-sm risk-badge-critical`}>C</div>
                            <p className="m-0 f11 text-white"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Critical ? state.trendSeverityCount.severity.Critical : 0}</p>
                        </div>
                        <div className="d-flex ml-2 align-self-center">
                            <div className={`f10 badge-square-sm risk-badge-high`}>H</div>
                            <p className="m-0 f11 text-white"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.High ? state.trendSeverityCount.severity.High : 0}</p>
                        </div>
                        <div className="d-flex ml-2 align-self-center">
                            <div className={`f10 badge-square-sm risk-badge-medium`}>M</div>
                            <p className="m-0 f11 text-white"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Medium ? state.trendSeverityCount.severity.Medium : 0}</p>
                        </div>
                        <div className="d-flex ml-2 align-self-center">
                            <div className={`f10 badge-square-sm risk-badge-low`}>L</div>
                            <p className="m-0 f11 text-white"><span className="mx-1">:</span> {state.trendSeverityCount && state.trendSeverityCount.severity && state.trendSeverityCount.severity.Low ? state.trendSeverityCount.severity.Low : 0}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        {!state.minimizeGraphSection ?
                            <i className='far fa-redo cursorPointer' onClick={()=> resetGraph()}></i>
                        : null}
                        <i className={`fa fa-caret-${state.minimizeGraphSection ? "down" : "down"} cursorPointer ml-3 text-gray2`} onClick={() => setState(prevState => ({ ...prevState, minimizeGraphSection: !state.minimizeGraphSection }))}></i>
                    </div>
                </div>
                {state.graphData && Object.keys(state.graphData).length && !state.minimizeGraphSection ?
                    <div className="mt-n2"> 
                        <ApexTrendBarChart
                            graphData={state.graphData}
                            graphRiskCountSection={false}
                            sparkline={false}
                            yaxis={false}
                            yaxisLabel={true}
                            yaxisTickAmount={2}
                            xaxis={true}
                            xaxisFormat={'string'}
                            xaxisLabel={true}
                            axisLabelColor={'#50586A'}
                            legend={false}
                            stacked={false}
                            height={100}
                            horizontal={false}
                            barHeight={'40%'}
                            barEndShape={'flat'}
                            columnWidth={'0%'}
                            backgroundBarShape={'rounded'}
                            backgroundBarColors={['#333947']}
                            showBackgroundBarColors={true}
                            className={"transparentTooltip"}
                            colors={['#3DAFE8']}
                            zoomEnabled={true}
                            getZoomPeriod={true}
                            zoomPeriod={(zoomedStartDate, zoomedEndDate) => {filterZoomData(zoomedStartDate, zoomedEndDate)}}
                        />
                    </div>
                : null}
            </div>
            <div className="d-flex mt-2">
                <div className="rounded bg-dark2 p-2 col-sm-12">
                    <div className={`d-flex mt-2 ml-1 mb-2 ${state.alertListLoader ? 'disabled' : ""}`}>
                        <div className="d-flex">
                            <p className="m-0 align-self-end small mr-2">Show </p>
                            <div className={`transparentSelectBorderBottom align-self-end`}>
                                <select className="form-control" 
                                onChange={handleChange}
                                value={state.alertType}
                                >
                                    <option key="All" value="All">ALL</option>
                                    <option key="Application" value="Application">Application</option>
                                    <option key="Infra" value="Infra">Infra</option>
                                </select>
                            </div>
                            <p className="m-0 align-self-end small mx-2"> alerts grouped by</p>
                            <div className={`transparentSelectBorderBottom align-self-end`}>
                                <select className="form-control" 
                                    onChange={(event) => {
                                        setState(prevState => ({ ...prevState, groupedBy: event.target.value, selectedAlert: "", selectedAlertId: "", selectedCategory: "Important / All", alertListLoader: true, callAlertGroups: true }))
                                    }}
                                    value={state.groupedBy}                            
                                    >
                                    <option value='severity'>Severity</option>
                                    <option value='event_name'>Name</option>
                                    {state.alertType !== "Application" ?
                                        <React.Fragment>
                                        {/* <option value='asset_name'>Assets</option> */}
                                        {/* <option value='resource_type'>Service</option> */}
                                        <option value='category'>Category</option>
                                        </React.Fragment>
                                    : null}
                                </select>
                            </div>
                        </div>
                        <div className="d-flex ml-2 w-30">
                            <p className="m-0 align-self-end small mx-2">Category</p>
                            {state.alertsCategory ? 
                                <div className={`transparentSelectBorderBottom align-self-end`}>
                                    <select className="form-control" 
                                        value={state.selectedCategory}
                                        onChange={(event) => {
                                            setState(prevState => ({ ...prevState, selectedCategory: event.target.value, calltoGetCategoryALerts: true }))
                                        }}>
                                        {state.alertsCategory.map(item => {
                                            return(
                                            <option value={item.category}>{item.category+" "+item.count}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className="d-flex mb-2 justify-content-between">
                        <div className="d-flex mr-2 w-70">
                            <p className="f12 m-0 align-self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.totalFilteredArray && state.totalFilteredArray.length}{state.totalFilteredArray && state.totalFilteredArray.length > 1 ? ' alerts' : ' alert'}</p>
                            
                            {state.zoomedStartDate && state.zoomedStartDate !== "" ? 
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{momentDateGivenFormat(state.zoomedStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.zoomedEndDate, 'DD MMM YYYY HH:mm')}</p>
                            :
                                <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{state.brushStartTime ? momentDateGivenFormat(state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.brushEndTime, 'DD MMM YYYY HH:mm') : ""}</p>
                            }
                        </div>
                        <div className="d-flex justify-content-end w-30 mb-n2">
                            {state.totalFilteredArray && state.totalFilteredArray.length ?
                                <Search
                                    data={state.totalFilteredArray ? state.totalFilteredArray : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-black5 align-self-center w-100 mr-1 border-gray5 rounded-5 f12'}
                                    searchClassName={'px-2 f12'}
                                    searchIconColor={'text-gray5 f12'}
                                    searchPlaceHolder={'Search alerts....'}
                                    className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                    filteredData={(filteredArray) => setState(prevState => ({ ...prevState, filteredArray }))}
                                />
                            : null}
                            {state.filteredArray && state.filteredArray.length > state.perPage ?
                                <div className="pagination errorPagePagination f12 displayNone">
                                    <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                    <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                    <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                    <span>
                                        Go to
                                        <input
                                            type='number'
                                            value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPage}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) : ""
                                                gotoPage(page)
                                            }}
                                            className="ml-1 inputClass"
                                        />
                                    </span>
                                    <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                    <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                </div>
                            : null}
                        </div>
                    </div>
                    
                    <div className="d-flex">
                        <div className={`w-100`}>
                            <div className={`bg-dark3 py-3 pl-2 ${!state.minimizeGraphSection ? "obsMessageSection" : "obsMessageSectionLg"}`}>
                                {state.alertListLoader ? 
                                    <div className='d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='white' size='lg' />
                                    </div>
                                : state.filteredArray && state.filteredArray.length ?
                                    // state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                    state.filteredArray.map((item, i) => {
                                        return(
                                            <div key={'item_'+i} className={`px-2 mb-2 pb-1 border-bottom-black5 ${state.selectedAlertId && state.selectedAlertId === item.event_id ? "bg-muted" : ""}`}> 
                                                <div className="d-flex cursorPointer" onClick={() => getAlertDetails(item)}>
                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                        {item.severity ? 
                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                        : 'U'}
                                                    </span>
                                                    <p className="mb-0">
                                                        <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ""} </span>
                                                        {/* <span className="f12 mb-0 mr-2 text-purple-2"> {item.total_alerts+ (item.total_alerts>1 ?' alerts':' alert')}</span>  */}
                                                        <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                            {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                            {item.event_name ? ' : ' + item.event_name : ""}
                                                            {item.event_type ? ' : '+ item.event_type : ""}</span>
                                                        {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_type ? item.event_type : ""}</span> */}
                                                        {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_name ? item.event_name : ""}</span> */}
                                                        {item.asset_name ?
                                                            <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                        : null}
                                                        <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                        {item.account_id ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                        : null}
                                                        {item.region ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                        : null}
                                                        {item.resource_type ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                        : null}
                                                        {item.service_name ?
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                        : null}
                                                        {item.description ?
                                                            <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                        : null}
                                                    </p>
                                                </div>
                                                {state.selectedAlertId && state.selectedAlertId === item.event_id ?
                                                    <div className="rounded bg-dark3 p-3">
                                                        <div className="accordion" id="accordionExample">
                                                            <div className={`card bg-dark border-0 mb-2`}>
                                                                <div id={'heading_event'} onClick={() => setState(prevState => ({ ...prevState, expandAlertDetails: !state.expandAlertDetails }))}>
                                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                                        <div className="text-white"><span className={`far ${state.expandAlertDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Alert Details</div>
                                                                        {/* <div className="text-info">Showing event details</div> */}
                                                                    </div>
                                                                </div>
                                                                <div id={'collapse_event'} className={`collapse ${state.expandAlertDetails ? 'show' : ""}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                                                    <div className="card-body">
                                                                        <div className="d-flex justify-content-between mb-2 pb-2">
                                                                            <div>
                                                                                <p className="mb-0 f16 text-white">{state.selectedAlert.event_name ? state.selectedAlert.event_name : ""}</p>
                                                                                <p className="mb-0 f12">{state.selectedAlert.description ? state.selectedAlert.description : ""}</p>
                                                                            </div>
                                                                            <div>
                                                                                <p className="b-block mb-0">Severity</p>
                                                                                <span className={`mr-2 badge ${state.selectedAlert.severity ? 'risk-badge-'+state.selectedAlert.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                                                    {state.selectedAlert.severity ? capitalizeFirstLetter(state.selectedAlert.severity) : 'Unknown'}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mb-1 border-top">
                                                                            <div className="py-1 w-50">
                                                                                <p className="b-block mb-0">Alert Time</p>
                                                                                <p className="mb-0 text-white">
                                                                                    {state.selectedAlert.type === 'alert' ?
                                                                                        state.selectedAlert.event_time ? momentConvertionUtcToLocalTime(state.selectedAlert.event_time, 'DD MMM YYYY HH:mm') : ""
                                                                                    :
                                                                                        state.selectedAlert.created_at ? momentConvertionUtcToLocalTime(state.selectedAlert.created_at, 'DD MMM YYYY HH:mm') : ""
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                            <div className="py-1 w-50 pl-3">
                                                                                <p className="b-block mb-0">Alert Metrics</p>
                                                                                {state.selectedAlert.type === 'alert' ?
                                                                                    <p className="mb-0 text-white">
                                                                                        {state.selectedAlert.event_source ? state.selectedAlert.event_source : ""}
                                                                                        {state.selectedAlert.event_type ? (' : ')+state.selectedAlert.event_type : ""}
                                                                                    </p>
                                                                                :        
                                                                                    // <p className="mb-0 text-white">
                                                                                    //     {state.selectedAlert.event_type ? state.selectedAlert.event_type : ""}
                                                                                    // </p>
                                                                                null}
                                                                            </div>
                                                                        </div>
                                                                    
                                                                        <div className="d-flex mb-1 border-top">
                                                                            <div className="py-1 w-50">
                                                                                <p className="b-block mb-0">Threshold / Statistic / Period</p>
                                                                                <p className="mb-0 text-warning">
                                                                                    {state.selectedAlert.threshold && state.selectedAlert.threshold}{state.selectedAlert.unit ? (state.selectedAlert.unit ? (state.selectedAlert.unit.toLowerCase() === 'percent' ? ' %' : state.selectedAlert.unit) : "") : ""}
                                                                                    {state.selectedAlert.statistic ? (' / ')+state.selectedAlert.statistic : ""}
                                                                                    {state.selectedAlert.period ? (' / ')+state.selectedAlert.period : ""}
                                                                                </p>
                                                                            </div>
                                                                            <div className="py-1 w-50 pl-3">
                                                                                <p className="b-block mb-0">Provider : Account : Region </p>
                                                                                <p className="mb-0 text-white">
                                                                                    {state.selectedAlert.provider ? state.selectedAlert.provider.toUpperCase() : ""}
                                                                                    {state.selectedAlert.account_id ? (' : ')+state.selectedAlert.account_id : ""}
                                                                                    {state.selectedAlert.region ? (' : ')+state.selectedAlert.region : ""}
                                                                                </p>
                                                                            </div>
                                                                        </div>                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className={`card bg-dark border-0 mb-2`}>
                                                                <div id={'heading_config_assets'} onClick={() => setState(prevState => ({ ...prevState, expandAssetDetails: !state.expandAssetDetails }))}>
                                                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                        <div className="text-white"><span className={`far ${state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                        {/* <div className="text-info">Showing asset details</div> */}
                                                                    </div>
                                                                </div>
                                                                <div id={'collapse_config_assets'} className={`collapse ${state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                    <div className="card-body">
                                                                        <div className="d-flex mb-2">
                                                                            <div className="py-1 w-50">
                                                                                <p className="b-block mb-0">Name</p>
                                                                                <p className="mb-0 text-white">{state.selectedAlert.asset_name ? state.selectedAlert.asset_name : ""}</p>
                                                                            </div>
                                                                            <div className="py-1 w-50 pl-3">
                                                                                <p className="b-block mb-0">Id</p>
                                                                                <p className="mb-0 text-white">{state.selectedAlert && state.selectedAlert
                                                                                .asset_id ? state.selectedAlert.asset_id : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mb-2 border-top">
                                                                            <div className="py-1 w-50">
                                                                                <p className="b-block mb-0">Resource</p>
                                                                                <p className="mb-0 text-white">
                                                                                    {state.selectedAlert.provider ? state.selectedAlert.provider.toUpperCase() : ""}
                                                                                    {state.selectedAlert.account_id ? (" : ")+state.selectedAlert.account_id : ""}
                                                                                    {state.selectedAlert.region ? (" : ")+state.selectedAlert.region : ""}
                                                                                </p>
                                                                            </div>
                                                                            <div className="py-1 w-50 pl-3">
                                                                                <p className="b-block mb-0">Services</p>
                                                                                <p className="mb-0 text-white">
                                                                                    {state.selectedAlert.resource_type ? state.selectedAlert.resource_type : ""}
                                                                                    {state.selectedAlert.service_name ? (" : ")+state.selectedAlert.service_name : ""}
                                                                                </p>
                                                                            </div>
                                                                        </div>                                                        
                                                                        <div className="d-flex mb-2 border-top">
                                                                            <div className="py-1 w-100">
                                                                                <p className="b-block mb-0">ARN</p>
                                                                                <p className="mb-0 text-white">{state.selectedAlert.asset_arn ? state.selectedAlert.asset_arn : ""}</p>
                                                                            </div>
                                                                        </div>
                                                                        {state.selectedAlert && state.selectedAlert.category ? 
                                                                            <div className="d-flex mb-2 border-top">
                                                                                <div className="py-1 w-100">
                                                                                    <p className="b-block mb-0">Category</p>
                                                                                    <div className={`d-flex flex-wrap`}>
                                                                                        {Array.isArray(state.selectedAlert.category) ?
                                                                                            state.selectedAlert.category.map(item => {
                                                                                                return (
                                                                                                    <small key={'alert_'+item} className={`mr-1 mb-1 badge badge-light`}>{item}</small>
                                                                                                )
                                                                                            })
                                                                                        : 
                                                                                            <small className={`mr-1 mb-1 badge badge-light`}>{state.selectedAlert.category}</small>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        )
                                    })
                                :
                                    <div className='d-flex justify-content-center m-4'>
                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlertsTab