import React, { useRef } from 'react';
import FileList from "./file-list";
import FileFilter from './file-filter';

export default function Files() {
	const filterRef = useRef();
	return (
		<div className="file-browser">
			<div className='title-section p-2'>
				<div className="d-flex justify-content-end">
					<div className="col-1g-3 col-md-3 col-sm-4 pl-2 align-self-center">
					<h6 className="text-white m-0">File Manager</h6>
					{/* <p className="text-white m-0">Count board view of resources on your Cloud</p> */}
					</div>
					<div className='col-1g-9 col-md-9 col-sm-9'>
						<div className="d-flex justify-content-end">
							<div ref={filterRef}>
								<FileFilter 
									onFilterChange={(cluster) => {
										// setSelectedCluster(cluster)
										// setState(prevState => ({
										// ...prevState,
										// selectedClusters: cluster
										// }))
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-xl-12 col-lg-12 col-md-12 col-12">
				<div className="row">
					<FileList />
				</div>
			</div>
		</div>
	);
}