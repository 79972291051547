/*************************************************
 * Collider
 * @exports
 * @file RightSection.js
 * @author Prakash // on 29/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Input } from 'reactstrap'
import { setHpcPropsDetails } from  '../../../../actions/Collider/HpcAction'
import { listQueues } from '../../../../actions/Collider/ClusterAction'
import { thousandSeparator } from '../../../../utils/utility';
import { listUsersByCluster } from '../../../../actions/Collider/ClusterAction'
import _ from 'lodash'
import Search from '../../../common/SearchWithHiddenInput';

const RightSection = (props) => {
	const[state, setState] = useState({
		showDetails: false,
        callUserList: true,
        selectedQueueSection: true,
        selectedUserSection: true
	})

	const dispatch = useDispatch(); // Create a dispatch function

    const selectedAplliesTo = useSelector(state => state?.hpc?.hpcPropsDetails?.selectedAplliesTo || false);
    const createInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.createInputs || false);
    
    useEffect(() => {
        if(!_.isEqual(selectedAplliesTo, state.selectedAplliesTo)) {
            setState(prevState => ({ ...prevState, selectedAplliesTo }))
            let params = {}
            params.provider = selectedAplliesTo.selectedProvider
            params.account_id = selectedAplliesTo.selectedAccount
            if(selectedAplliesTo.selectedRegion) {
                params.region = selectedAplliesTo.selectedRegion
            }
            params.cluster_name= selectedAplliesTo.selectedClusters

            let fieldName = selectedAplliesTo.selectedProvider+'_'+selectedAplliesTo.selectedAccount+'_'+selectedAplliesTo.selectedRegion+'_'+selectedAplliesTo.selectedClusters
            setState(prevState => ({ ...prevState, fieldName, hasError: false }));

            if(params.provider && params.cluster_name) {
                dispatch(listQueues(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, queues: response }));
                        }
                    })

                dispatch(listUsersByCluster(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, clusterUserList: response, filterClusterUserList: response }));
                        }
                    })
            }
        }
    }, [selectedAplliesTo, state.selectedAplliesTo, dispatch])

    useEffect(() => {
        let hasError = false
        let obj = createInputs ? createInputs : {}
        let details = obj.details ? obj.details : []
        details.forEach(row => {     
            if(row.applies_to && row.applies_to.length) {
                row.applies_to.forEach(app => {
                    if(app.account_id === selectedAplliesTo.selectedAccount && app.region === selectedAplliesTo.selectedRegion && app.cluster_name === selectedAplliesTo.selectedClusters) {
                        let queueTotal = 0
                        if(app.queues && app.queues.length) {
                            queueTotal = app.queues.reduce((sum, queue) => sum + queue.budget, 0);
                        }
                        let queueError = []
                        if(queueTotal > Math.round(app.budget)) {
                            hasError = true
                            let error = 'The total queue budget should not exceed cluster '+app.cluster_name+' budget of '+app.provider+' provider'
                            queueError.push(error)
                        }
                        setState(prevState => ({ ...prevState, queueError }))
                        
                        let userTotal = 0
                        if(app.users && app.users.length) {
                            userTotal = app.users.reduce((sum, user) => sum + user.budget, 0);
                        }
    
                        let userError = []
                        if(userTotal > Math.round(app.budget)) {
                            hasError = true
                            let error = 'The total user budget should not exceed cluster '+app.cluster_name+' budget of '+app.provider+' provider'
                            userError.push(error)
                        }
                        setState(prevState => ({ ...prevState, userError }))

                        if(app.queues && app.queues.length) {
                            app.queues.forEach(queue => {
                                setState(prevState => ({ 
                                    ...prevState, 
                                    ['queue_'+state.fieldName+'_'+queue.queue_name]: queue.budget ? queue.budget : 0
                                }))
                            })
                        } else {
                            if(state.queues && state.queues.length) {
                                state.queues.forEach(app => {
                                    setState(prevState => ({ ...prevState, ['queue_'+state.fieldName+'_'+app]: 0 }))
                                })
                            }
                        }
                        if(app.users && app.users.length) {
                            app.users.forEach(user => {
                                setState(prevState => ({ 
                                    ...prevState, 
                                    ['user_'+state.fieldName+'_'+user.user_id]: user.budget ? user.budget : 0
                                }))
                            })
                        } else {
                            if(state.users && state.users.length) {
                                state.users.forEach(row => {
                                    setState(prevState => ({ ...prevState, ['user_'+state.fieldName+'_'+row.userId]: 0 }))
                                })
                            }
                        }
                        setState(prevState => ({ ...prevState, queueTotal, userTotal }))
                    }
                })
            }
        })

        setState(prevState => ({ ...prevState, hasError }))
    }, [createInputs, selectedAplliesTo, state.queues, state.users, state.fieldName])

    // useEffect(() => {
    //     if(props.receivedData && Object.entries(props.receivedData).length) {
    //         setState(prevState => ({ 
    //             ...prevState, 
    //             selectedData: props.receivedData.selectedData ? props.receivedData.selectedData : '',
    //             pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
    //         }))
    //         if(props.receivedData.pageType) {
    //             // onClickEditBudget(props.receivedData)
    //         }
    //     }
    // }, [props.receivedData])

    const onChangeHandleValidation = (type, selectedItem, value) => {
        //initiative annual amount and total monthly amount check
        let hasError = false
        let queues = []
        if(state.queues && state.queues.length) {
            state.queues.forEach(row => {
                let dataRow = {}
                dataRow.queue_name = row
                if(type === 'queue' && selectedItem === row) {
                    dataRow.budget = Math.round(value)
                } else {
                    dataRow.budget = state['queue_'+state.fieldName+'_'+row] ? Math.round(state['queue_'+state.fieldName+'_'+row]) : 0
                }
                queues.push(dataRow)
            })
        }        

        let users = []
        if(state.clusterUserList && state.clusterUserList.length) {
            state.clusterUserList.forEach(row => {
                let dataRow = {}
                dataRow.user_id = row.userId
                if(type === 'user' && selectedItem === row.userId) {
                    dataRow.budget = Math.round(value)
                } else {
                    dataRow.budget = state['user_'+state.fieldName+'_'+row.userId] ? Math.round(state['user_'+state.fieldName+'_'+row.userId]) : 0
                }
                users.push(dataRow)
            })
        }

        let queueTotal = queues.reduce((sum, queue) => sum + queue.budget, 0);
        let userTotal = users.reduce((sum, user) => sum + user.budget, 0);
        
        setState(prevState => ({ ...prevState, queueTotal, userTotal }))

        let obj = createInputs ? createInputs : {}
        let details = obj.details ? obj.details : []

        if(details && details.length) {
            // let selectedProviderDetails = details.filter(e => e.provider === selectedAplliesTo.selectedProvider) && details.filter(e => e.provider === selectedAplliesTo.selectedProvider).length ? details.filter(e => e.provider === selectedAplliesTo.selectedProvider)[0] : {}
            
            // let appliesTo = selectedProviderDetails.applies_to ? selectedProviderDetails.applies_to : []
            details.forEach(det => {
                if(det.applies_to && det.applies_to.length) {
                    det.applies_to.forEach(row => {
                        if(row.account_id === selectedAplliesTo.selectedAccount && row.region === selectedAplliesTo.selectedRegion && row.cluster_name === selectedAplliesTo.selectedClusters) {
                            row.queues = queues
                            row.users = users
    
                            let queueError = ''
                            if(Math.round(selectedAplliesTo.budget) < queueTotal) {
                                queueError = 'Queue budget total should be less than the cluster budget '+row.budget
                            }
                            let userError = ''
                            if(Math.round(selectedAplliesTo.budget) < userTotal) {
                                userError = 'User budget total should be less than the cluster budget '+row.budget
                            }
    
                            setState(prevState => ({ ...prevState, queueError, userError }))
                        }
                    })
                }
            })
        }
        dispatch(setHpcPropsDetails('createInputs', obj))
        
        if(hasError) {
            dispatch(setHpcPropsDetails('onClickSave', 'checkForError'))
        } else {
            if(type === 'save') {
                dispatch(setHpcPropsDetails('onClickSave', 'save'))
            } else {
                dispatch(setHpcPropsDetails('onClickSave', 'checkForErrorHasNoError'))
            }
        }
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
        <div className='col-sm-12 p-0' onClick={(event) => handleChildClick(event, 'parent')}>
            {selectedAplliesTo.selectedClusters ?
                <React.Fragment>
                <div className='col-sm-12 bg-dark2 rounded mt-3 p-2'>
                    <div className='py-1 d-flex' onClick={() => setState(prevState => ({ ...prevState, selectedQueueSection: !state.selectedQueueSection }))}>
                        <span className={`far ${state.selectedQueueSection ? "fa-minus-circle" : "fa-plus-circle"} mr-2 align-self-center text-white`}></span>
                        <p className="b-block text-white mb-0">Queue(s)</p>
                        <div className='ml-3'>
                            Balance to be Allocated
                            <span className={`ml-2 text-${selectedAplliesTo.budget - (state.queueTotal ? state.queueTotal : 0) <= 0 ? 'danger' : 'info'}`}>
                                {selectedAplliesTo.budget ? thousandSeparator(selectedAplliesTo.budget - (state.queueTotal ? state.queueTotal : 0)) : 0}
                            </span>
                        </div>
                    </div>
                    {state.selectedQueueSection ?
                        <React.Fragment>
                        {state.hasError && state.queueError ?
                            <p className="b-block text-danger f13 mb-1">{state.queueError}</p>
                        : null}
                        {state.queues && state.queues.length ?
                            state.queues.map((queue, queueIndex) => {
                                return(
                                    <div className='col-sm-12' key={'queue_'+queueIndex}>
                                        <div className='row p-2'>
                                            <div className='col-sm-5 mb-2 pl-0 align-self-center'>
                                                <div className='d-flex justify-content-start'>
                                                    <p className="b-block mb-0 text-white">{queue}</p>
                                                </div>
                                            </div>
                                            <div className='col-sm-3 mb-2 pl-0'>
                                                <Input
                                                    type="number"
                                                    placeholder={''}
                                                    className={`inputTextbox border-lightGray bg-transparent text-white`}
                                                    readOnly={state.pageType === 'View' ? true : false}
                                                    // maxlength="128"
                                                    value={state['queue_'+state.fieldName+'_'+queue] ? state['queue_'+state.fieldName+'_'+queue] : ''}
                                                    onChange={e => {
                                                        setState(prevState => ({ ...prevState, ['queue_'+state.fieldName+'_'+queue]: e.target.value }))
                                                        onChangeHandleValidation('queue', queue, e.target.value)
                                                    }}
                                                />
                                            </div>
                                        
                                        </div>
                                    </div>
                                )
                            })
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                <p>There are no queues for the selected applies to.</p>
                            </div>
                        }
                        </React.Fragment>
                    : null}
                </div>
                <div className='col-sm-12 bg-dark2 mt-3 mb-5 rounded p-2'>
                    <div className='d-flex justify-content-between py-1'>
                        <div className='py-1 d-flex' onClick={() => setState(prevState => ({ ...prevState, selectedUserSection: !state.selectedUserSection }))}>
                            <span className={`far ${state.selectedUserSection ? "fa-minus-circle" : "fa-plus-circle"} mr-2 align-self-center text-white`}></span>
                            <p className="b-block mb-0 text-white">User(s)</p>
                            <div className='ml-3'>
                                Balance to be Allocated
                                <span className={`ml-2 text-${selectedAplliesTo.budget - (state.userTotal ? state.userTotal : 0) <= 0 ? 'danger' : 'info'}`}>
                                    {selectedAplliesTo.budget ? thousandSeparator(selectedAplliesTo.budget - (state.userTotal ? state.userTotal : 0)) : 0}
                                </span>
                            </div>
                        </div>          
                        {state.selectedUserSection ?
                            state.clusterUserList && state.clusterUserList.length ?
                                <Search
                                    data={state.clusterUserList ? state.clusterUserList : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center w-30'}
                                    searchClassName={'px-2 f12'}
                                    searchIconColor={'text-white f12'}
                                    searchPlaceHolder={'Search....'}
                                    className={"bg-transparent text-white form-control-sm f12"}
                                    filteredData={(filterClusterUserList) => setState(prevState => ({ ...prevState, filterClusterUserList }))}
                                />
                            : null
                        : null}
                    </div>
                    {state.selectedUserSection ?
                        <React.Fragment>
                        {state.hasError && state.userError ?
                            <p className="b-block text-danger f13 mb-1">{state.userError}</p>
                        : null}
                        {state.filterClusterUserList && state.filterClusterUserList.length ?
                            state.filterClusterUserList.map((user, userIndex) => {
                                return(
                                    <div className='col-sm-12' key={'users_'+state.selectedProvider+'_'+userIndex}>
                                        <div className='row p-2'>
                                            <div className='col-sm-5 mb-2 pl-0 align-self-center'>
                                                <div className='d-flex justify-content-start'>
                                                    <p className="b-block mb-0 text-white">
                                                        {user.shortName ? user.shortName : (user.userName ? user.userName : '')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-sm-3 mb-2 pl-0'>
                                                <Input
                                                    type="number"
                                                    placeholder={''}
                                                    className={`inputTextbox border-lightGray bg-transparent text-white`}
                                                    readOnly={state.pageType === 'View' ? true : false}
                                                    // maxlength="128"
                                                    value={state['user_'+state.fieldName+'_'+user.userId] ? state['user_'+state.fieldName+'_'+user.userId] : ''}
                                                    onChange={e => {
                                                        setState(prevState => ({ ...prevState, ['user_'+state.fieldName+'_'+user.userId]: e.target.value }))
                                                        onChangeHandleValidation('user', user.userId, e.target.value)
                                                        // handleMonthlyDistributionChange(e.target.value)
                                                        // onChangeHandleValidation(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) 
                        :
                            <div className='d-flex justify-content-center m-4'>
                                <p>There are no queues for the selected applies to.</p>
                            </div>
                        }
                        </React.Fragment>
                    : null}
                </div>
                </React.Fragment>
            : null}
		</div>
	)
}

export default RightSection