/*************************************************
 * Collider
 * @exports
 * @file QueryAction.js
 * @author parakash // on 2022
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../components/ApiCalls'

import {
	GET_QUERIES,
	START_QUERY_EXECUTION,
	GET_QUERY_RESULT,
	SET_OBSERVABILITY_ASSET_FAVOURITE_QUERY,
	UNSET_OBSERVABILITY_ASSET_FAVOURITE_QUERY

} from '../config'
import { refreshPage } from '../utils/utility'

import { ACTION_TYPES } from './types'
//import Cookies from 'js-cookie'

/**
 * Action to get-queries
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueries = (params) => {
	return async dispatch => {		
		try {
			const url = GET_QUERIES;
			const response = await ApiCalls.post(url, params);
			
			dispatch({ type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to start-query-execution
 * @param {Object} body
 * @param {Function} callback
 */
export const startQueryExection= (params) => {
	return async () => {		
		try {
			const url = START_QUERY_EXECUTION;
			const response = await ApiCalls.post(url, params);
			
			// dispatch({ type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to get-query-results
 * @param {Object} body
 * @param {Function} callback
 */
export const getQueryResult = (params) => {
	return async dispatch => {		
		try {
			const url = GET_QUERY_RESULT;
			const response = await ApiCalls.post(url, params);
			
			dispatch({ type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_RESULT, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to set-favourite-query
 * @param {Object} body
 * @param {Function} callback
 */
 export const setFavouriteQuery = (params) => {
	return async () => {		
		try {
			const url = SET_OBSERVABILITY_ASSET_FAVOURITE_QUERY;
			const response = await ApiCalls.post(url, params);
			
			// dispatch({ type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_RESULT, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to unset-favourite-query
 * @param {Object} body
 * @param {Function} callback
 */
 export const unsetFavouriteQuery = (params) => {
	return async () => {
		try {
			const url = UNSET_OBSERVABILITY_ASSET_FAVOURITE_QUERY;
			const response = await ApiCalls.post(url, params);
			
			// dispatch({ type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_RESULT, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}