/*************************************************
 * Collider
 * @exports
 * @file RightSection.js
 * @author Prakash // on 05/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import { Input, Spinner } from 'reactstrap'
import { setCostPropsDetails } from  '../../../../actions/cost/CostAction'
import { capitalizeAllLetter, onlyNumeric, thousandSeparator } from '../../../../utils/utility';
import { URL_PATH } from '../../../../config/urlPath';
import MultiSelectSection from '../../../common/MultiSelectSection';
import _ from 'lodash'

const RightSection = (props) => {
	const[state, setState] = useState({
		showDetails: false,
        expandMenuId: 'projects',
        months: [{label: 'Jan', value: 0}, {label:'Feb', value: 1}, {label:'Mar', value: 2}, {label:'Apr', value: 3}, {label:'May', value: 4}, {label:'Jun', value: 5}, {label:'Jul', value: 6}, {label:'Aug', value: 7}, {label:'Sep', value: 8}, {label:'Oct', value: 9}, {label:'Nov', value: 10}, {label:'Dec', value: 11}],        
        quarters: [
            [{label: 'Jan', value: 0}, {label: 'Feb', value: 1}, {label: 'Mar', value: 2}],
            [{label: 'Apr', value: 3}, {label: 'May', value: 4}, {label: 'Jun', value: 5}],
            [{label: 'Jul', value: 6}, {label: 'Aug', value: 7}, {label: 'Sep', value: 8}],
            [{label: 'Oct', value: 9}, {label: 'Nov', value: 10}, {label: 'Dec', value: 11}]
        ]
	})

	const navigate = useNavigate();
	// const tagKeys = useSelector(state => state.cost.costTagKeys);
	const dispatch = useDispatch(); // Create a dispatch function

    const selectedInitiative = useSelector(state => state.cost.costPropsDetails?.selectedInitiative || false);
    const createInputs = useSelector(state => state.cost.costPropsDetails?.createInputs || false);
    const onClickSave = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.onClickSave ? state.cost.costPropsDetails.onClickSave : false);
    const currencyUnit = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.currencyUnit ? state.cost.costPropsDetails.currencyUnit : 'usd')

    useEffect(() => {
        if(props.receivedData && Object.entries(props.receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                department: props.receivedData.department ? props.receivedData.department : '',
                year: props.receivedData.year ? props.receivedData.year : '',
                pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
            }))
        }
    }, [props.receivedData])

    useEffect(() => {
        if(onClickSave === 'stopLoading') {
            setState(prevState => ({ ...prevState, onSaveFunction: false }))
            dispatch(setCostPropsDetails('onClickSave', ''))
        }
    }, [onClickSave, dispatch])

    const projectCommitedBalanceDetails = useCallback((allocation, monthly_distribution, pIndex, row, selectedProjCloudProvider) => {
        let projectMontlyDistrubutition = monthly_distribution
        if (projectMontlyDistrubutition) {
            let projectMonthlySum = projectMontlyDistrubutition.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0); // Initial value of the accumulator is set to 0
            
            let projectBalance = allocation - projectMonthlySum
            

            var quarters = [];
            for (var i = 0; i < projectMontlyDistrubutition.length; i += 3) {
                var quarter = projectMontlyDistrubutition.slice(i, i + 3);
                var quarterSum = quarter.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                quarters.push(quarterSum);
            }
            quarters.forEach((quater, qIndex) => {
                quater = parseFloat(quater).toFixed(2)
                setState(prevState => ({
                    ...prevState,
                    ['project_quater_'+row.project+'_'+qIndex+'_'+pIndex]:  parseFloat(quater)
                }))
            })

            let halfYearlyArray = []
            for (let i = 0; i < projectMontlyDistrubutition.length; i += 6) {
                let halfYearly = projectMontlyDistrubutition.slice(i, i + 6);
                let halfYearlySum = halfYearly.reduce((acc, val) => acc + (isNaN(val) ? 0 : parseFloat(val)), 0);
                halfYearlyArray.push(halfYearlySum);
            }
            
            halfYearlyArray.forEach((half, hIndex) => {
                half = parseFloat(half).toFixed(2)
                setState(prevState => ({
                    ...prevState,
                    ['project_half_'+hIndex+'_'+row.project+'_'+pIndex]:  parseFloat(half)
                }))
            })

            setState(prevState => ({ ...prevState, ['projectMonthlySum_'+selectedProjCloudProvider+'_'+pIndex]: Math.round(projectMonthlySum), ['projectBalance_'+row.project+'_'+pIndex]: Math.round(projectBalance) }))
        }
    }, [])

    useEffect(() => {
        if(state.formtData) {
            setState(prevState => ({ ...prevState, formtData: false }))

            let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
    
            let projects = initiatives && initiatives.projects ? initiatives.projects : []
    
            if(projects && projects.length) {
                projects.forEach((proj, pIndex) => {
                    let totalAllocation = 0
                    let totalDistributionSum = 0
                    let projectCloudProviders = []
                    let projectCloudBudget = proj && proj.cloud_budget ? proj.cloud_budget : {}
                    if(projectCloudBudget && Object.entries(projectCloudBudget).length) {
                        projectCloudProviders = Object.keys(projectCloudBudget)
                        let selectedProjCloudProvider = projectCloudProviders[0]
                        let selectedProjProviderDetails = projectCloudBudget[selectedProjCloudProvider]
    
                        let distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
                        
                        state.months.forEach((mnt, mIndex) => {
                            setState(prevState => ({ ...prevState, ['project_'+proj.project+'_'+mnt.label+'_'+pIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
                        })
                        
                        let allocation = selectedProjProviderDetails && selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
    
                        projectCommitedBalanceDetails(allocation, distribution, pIndex, proj, selectedProjCloudProvider)
                        
                        setState(prevState => ({ ...prevState, ['projectCloudProviders_'+pIndex]: projectCloudProviders, ['selectedProjCloudProvider_'+pIndex]: selectedProjCloudProvider, ['projectAlloation_'+proj.project+'_'+pIndex]: allocation }))
                    }
                    
                    let projectProviders = []
                    Object.entries(proj.cloud_budget).forEach(([key, value], objIndex) => {
    
                        // let allocation = value.allocation 
                        let projectDistrubutition = value.monthly_distribution
    
                        let projDistributionSum = 0
                        if(projectDistrubutition) {
                            projDistributionSum = projectDistrubutition.reduce((a, b) => {
                                return a + parseFloat(b);
                            }, 0)
                        }

                        totalAllocation += Math.round(value.allocation) 
                        totalDistributionSum += Math.round(projectDistrubutition)
    
                        let projRow = {}
                        projRow.provider = key
                        projRow.allocation = value.allocation 
                        projRow.distributuion = Math.round(projDistributionSum)
                        projRow.balance = Math.round(value.allocation - projDistributionSum)
        
                        projectProviders.push(projRow)

                        if(Object.entries(proj.cloud_budget).length-1 === objIndex) {
                            let totalDepRow = {}
                            totalDepRow.provider = 'Total'
                            totalDepRow.allocation = totalAllocation
                            totalDepRow.distributuion = totalDistributionSum
                            totalDepRow.balance = totalAllocation - totalDistributionSum
                            projectProviders.unshift(totalDepRow);
                        }
        
                        
                        setState(prevState => ({ ...prevState, ['projectProviders_'+proj.project]: projectProviders}))
                    })
                })

            }
        }
        //project format end
    }, [state.formtData, createInputs, projectCommitedBalanceDetails, selectedInitiative, state.months])

    useEffect(() => {
        if (!_.isEqual(selectedInitiative, state.selectedInitiativeObj)) {
            setState(prevState => ({ ...prevState, formtData: true, selectedInitiativeObj: selectedInitiative }))
        }
    }, [selectedInitiative, state.selectedInitiativeObj])

     //for Initiative// 
     const onChangeProjCloudProvider = (selectedProjCloudProvider, pIndex) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
        
        let projects = initiatives && initiatives.projects ? initiatives.projects : []
        
        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[selectedProjCloudProvider] ? selectedProjCloudBudget.cloud_budget[selectedProjCloudProvider] : {}
        
        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
        

        let distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
        state.months.forEach((mnt, mIndex) => {
            setState(prevState => ({ ...prevState, ['project_'+selectedProjCloudBudget.project+'_'+mnt.label+'_'+pIndex]: distribution && distribution[mIndex] ? distribution[mIndex] : 0 }))
        })

        projectCommitedBalanceDetails(allocations, distribution, pIndex, selectedProjProviderDetails, selectedProjCloudProvider)
        setState(prevState => ({ ...prevState, ['projectAlloation_'+selectedProjCloudBudget.project+'_'+pIndex]: allocations }))
    }

    useEffect(() => {
        if(selectedInitiative && Object.entries(selectedInitiative).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedInitiative: selectedInitiative.initiative,
                projectOptions: selectedInitiative.projects && selectedInitiative.projects.length ? selectedInitiative.projects : [] 
            }))
        }
    }, [selectedInitiative])
    
	const handleProjectInputChange = (label, pIndex, value) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}
        
        
        // let invObj = initiatives && initiatives[pIndex] ? initiatives[pIndex] : {}
        if(label === 'allocation') {
            value = parseFloat(value)

            selectedProjProviderDetails[label] = value

            if(!state['montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]) {
                projectCommitedBalanceDetails(value, selectedProjCloudBudget.monthly_distribution, pIndex, selectedProjCloudBudget, state['selectedProjCloudProvider_'+pIndex])
            }
            
        }
        // invObj.projects = newProj
        // initiatives[selectedInitiative.iIndex] = invObj
        // dispatch(setCostPropsDetails('createInputs', obj))
	}

    const callProjectEqualMonthlyDistribution = useCallback((pIndex, value) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}

        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}

        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0

        let monthly_distribution = []
        let amount = parseFloat(value/12).toFixed(2)
        state.months.forEach(mnt => {
            monthly_distribution[mnt.value] = parseFloat(amount)
        })        
        selectedProjProviderDetails.monthly_distribution = monthly_distribution

        dispatch(setCostPropsDetails('createInputs', obj))
        projectCommitedBalanceDetails(allocations, monthly_distribution, pIndex, selectedProjCloudBudget)

    }, [state, createInputs, dispatch, selectedInitiative, projectCommitedBalanceDetails])

    const handleProjectMonthlyEqualSplit = (monthlySplit, pIndex, value, row) => {

        if(monthlySplit) {
            let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
            let obj = createInputs ? createInputs : {}
            let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}
    
            let projects = initiatives && initiatives.projects ? initiatives.projects : []
            
            let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}

            let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}
            
            let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
            let amount = parseFloat(allocations/12).toFixed(2)
            state.months.forEach(mnt => {
                setState(prevState => ({ ...prevState, ['project_'+row.project+'_'+mnt.label+'_'+pIndex]: parseFloat(amount) }))
            })

            callProjectEqualMonthlyDistribution(pIndex, value)
        }
    }

    const handleProjectMonthlySplit = (label, pIndex, value, row) => {
        let selectedInitiativeIndex = selectedInitiative && selectedInitiative.iIndex
        let obj = createInputs ? createInputs : {}
        let initiatives = obj && obj.initiatives && obj.initiatives[selectedInitiativeIndex]? obj.initiatives[selectedInitiativeIndex] : {}

        let projects = initiatives && initiatives.projects ? initiatives.projects : []

        let selectedProjCloudBudget = projects && projects[pIndex] && projects[pIndex] ? projects[pIndex] : {}
        let selectedProjProviderDetails = selectedProjCloudBudget && selectedProjCloudBudget.cloud_budget && selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] ? selectedProjCloudBudget.cloud_budget[state['selectedProjCloudProvider_'+pIndex]] : {}

        let allocations = selectedProjProviderDetails.allocation ? selectedProjProviderDetails.allocation : 0
        
        let monthly_distribution = selectedProjProviderDetails && selectedProjProviderDetails.monthly_distribution ? selectedProjProviderDetails.monthly_distribution : []
        state.months.forEach(mnt => {
            if(label === mnt.value) {
                let amount = value ? parseFloat(value).toFixed(2) : 0
                monthly_distribution[mnt.value] = parseFloat(amount)
            } else {
                monthly_distribution[mnt.value] = state['project_'+row.project+'_'+mnt.label+'_'+pIndex] ? parseFloat(state['project_'+row.project+'_'+mnt.label+'_'+pIndex]) : 0
            }
        })
        
        selectedProjProviderDetails.monthly_distribution = monthly_distribution  
        
        projectCommitedBalanceDetails(allocations, monthly_distribution, pIndex, row)

        // obj.initiatives = initiatives
        dispatch(setCostPropsDetails('createInputs', obj))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const expandCollapse = (section) => {
        setState(prevState => ({ ...prevState, expandMenuId: state.expandMenuId === section ? "" : section }))
    }

    const expandProjects = (row) => {
        setState(prevState => ({ ...prevState, expandProj: state.expandProj === row.project ? "" : row.project }))
    }

    const navigateTo = () => {
        navigate(URL_PATH.COST_BUDGET_LIST); // Redirect to the specified path
    }

    const onChangeHandleValidation = (type, mnt) => {
        //initiative annual amount and total monthly amount check
        let hasError = false
        let obj = createInputs ? createInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        if(initiatives.length) {
            initiatives.forEach((row, rIndex) => {
                Object.entries(row.cloud_budget).forEach(([key, value]) => {
                    let projects = row.projects ? row.projects : []
                    projects.forEach(proj => {
                        let projectAllocationError = []
                        let projectProviders = []
                        let totalAllocation = 0
                        let totalDistributionSum = 0
                        if(proj.cloud_budget && Object.entries(proj.cloud_budget).length) {
                            Object.entries(proj.cloud_budget).forEach(([key, value], objIndex) => {
                                let projecttDistrubutition = value.monthly_distribution
                                let distributionSum = 0
                                if(projecttDistrubutition) {
                                    distributionSum = projecttDistrubutition.reduce((a, b) => {
                                        return a + parseFloat(b);
                                    }, 0)
                                }

                                totalAllocation += Math.round(value.allocation) 
                                totalDistributionSum += Math.round(distributionSum)

                                let dataRow = {}
                                dataRow.provider = key
                                dataRow.allocation = Math.round(value.allocation)
                                dataRow.distributuion = Math.round(distributionSum)
                                dataRow.balance = Math.round(value.allocation) - Math.round(distributionSum)
                                
                                if(value.allocation < distributionSum) {
                                    hasError = true
                                    let error ='Annual commitment amount of '+capitalizeAllLetter(key)+' should be more than the total monthly commitment.'
                                    
                                    projectAllocationError.push(error)
                                }
                                projectProviders.push(dataRow)

                                if(Object.entries(proj.cloud_budget).length-1 === objIndex) {
                                    let totalDepRow = {}
                                    totalDepRow.provider = 'Total'
                                    totalDepRow.allocation = totalAllocation
                                    totalDepRow.distributuion = totalDistributionSum
                                    totalDepRow.balance = totalAllocation - totalDistributionSum
                                    projectProviders.unshift(totalDepRow);
                                }
                                
                                setState(prevState => ({ ...prevState, ['projectAllocationError_'+proj.project]: projectAllocationError }))
                            })
                        }

                        setState(prevState => ({ ...prevState, ['projectProviders_'+proj.project]: projectProviders }))
                    })

                    let projectTotalAllocation = 0
                    let projectIntitativeAllocationError = []
                    projects.forEach(proj => {
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            let projData = proj.cloud_budget[key]
                            projectTotalAllocation += projData.allocation
                        }
                    })
                    

                    projects.forEach(proj => {
                        projectIntitativeAllocationError = []
                        if(proj.cloud_budget.hasOwnProperty(key)) {
                            if(projectTotalAllocation > value.allocation) {
                                hasError = true
                                let error = 'Total Project Annual commitment amount of '+capitalizeAllLetter(key)+' should be less than the initiative annual commitment.'
                                projectIntitativeAllocationError.push(error)
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: projectIntitativeAllocationError }))
                            } else {
                                setState(prevState => ({ ...prevState, ['projectIntitativeAllocationError_'+key+'_'+proj.project]: [] }))
                            }
                        }
                    })
                    
                })
            })
        }

        // if(initiatives.length) {
        //     initiatives.forEach((int, iIndex) => {
        //         let projectAllocations = 0
        //         if(int.projects && int.projects.length) {
        //             int.projects.forEach(proj => {
        //                 projectAllocations += parseFloat(proj.allocation ? proj.allocation : 0)
        //             })

        //             int.projects.forEach((proj, pIndex) => {
        //                 let projectAllocationError = ''
        //                 if(proj.monthly_distribution) {
        //                     const projSum = proj.monthly_distribution.reduce((a, b) => {
        //                         return a + parseFloat(b);
        //                     }, 0); // Initial value of the accumulator is set to 0
        //                     if(parseFloat(projSum) > parseFloat(proj.allocation) && !state['project_montly_split_equal_'+proj.project+'_'+pIndex]) {
        //                         hasError = true
        //                         projectAllocationError ='Annual commitment amount should be greater than the monthly commitment.'
        //                     }

        //                     setState(prevState => ({ 
        //                         ...prevState,
        //                         ['projectAllocationError_'+iIndex+'_'+pIndex]: projectAllocationError
        //                     }))
        //                 }

        //                 let projInitAllocationAmount = ''
        //                 if(projectAllocations > int.allocation) {
        //                     hasError = true
        //                     projInitAllocationAmount = 'The total commitment amount for the project should not exceed the annual commitment for the initiative.'
        //                 }

        //                 setState(prevState => ({ ...prevState, 
        //                     projInitAllocationAmount,
        //                     ['projInitAllocationAmount_'+iIndex+'_'+pIndex]: projInitAllocationAmount
        //                 }))

        //                 if(mnt) {
        //                     let projects = int.projects
        //                     let initiativeMonthlyAllocation = int.monthly_distribution[mnt.value] ? int.monthly_distribution[mnt.value] : 0
        //                     const values = projects.map(subArray => subArray.monthly_distribution[mnt.value]);
        //                     const projectsMontlyAllocation = values.reduce((a, b) => a + b, 0);
        //                     let projectMonthlyAllocatonError = ''
        //                     if(parseFloat(initiativeMonthlyAllocation) < parseFloat(projectsMontlyAllocation)) {
        //                         hasError = true
        //                         projectMonthlyAllocatonError = 'The total amount for projects in '+ mnt.label+'  ('+parseFloat(projectsMontlyAllocation)+') should not exceed to the initiative '+ mnt.label+' month ('+parseFloat(initiativeMonthlyAllocation)+').'
        //                     }
    
        //                     setState(prevState => ({ ...prevState, ['projIniSeparateMonthlyError_'+iIndex+'_'+pIndex]: projectMonthlyAllocatonError }))
        //                 }
        //             })

        //         }

        //         let initProjectAllocationAmount = ''
        //         if(projectAllocations > int.allocation) {
        //             if(projectAllocations > int.allocation) {
        //                 hasError = true
        //                 initProjectAllocationAmount = 'The total commitment amount for the project should not exceed the annual commitment for the initiative.'
        //             }
        //         }
        //         setState(prevState => ({ ...prevState,
        //             ['initProjectAllocationAmount_'+iIndex]: initProjectAllocationAmount,
        //         }))        
        //     })
        // }
        if(hasError) {
            dispatch(setCostPropsDetails('onClickSave', 'checkForError'))
        } else {
            if(type === 'save') {
                dispatch(setCostPropsDetails('onClickSave', 'save'))
            } else {
                dispatch(setCostPropsDetails('onClickSave', 'checkForErrorHasNoError'))
            }
        }
    }
	
	return (
        <div className='col-sm-12 p-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='row text-white mx-0 pb-2 bg-muted'>
                {state.selectedInitiative ?
                    <div className="p-2 rounded overflowYAuto w-100 mb-5">
                        <div className=''>
                            <div className={`px-2 mt-2 py-3 bg-dark`}> 
                                <div className={`mb-0 cursorPointer`} 
                                onClick={() => expandCollapse('projects')}>
                                    <div className={`mb-0 d-flex justify-content-between`}>
                                        <div><span className={`far ${state.expandMenuId === 'projects' ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>Projects</div>
                                        <div className='d-flex'>
                                            <span className='mr-3 text-white f14 font-weight-normal align-self-center'>Total</span>
                                            <h5 className='text-warning mb-0'>
                                                {createInputs && createInputs.initiatives && createInputs.initiatives.length && createInputs.initiatives[selectedInitiative.iIndex] && createInputs.initiatives[selectedInitiative.iIndex].projects && createInputs.initiatives[selectedInitiative.iIndex].projects.length ?
                                                    thousandSeparator(createInputs.initiatives[selectedInitiative.iIndex].projects.reduce((a, b) => {
                                                        return a + parseFloat(b.allocation);
                                                    }, 0))
                                                : 0}
                                            </h5>
                                        </div>
                                    </div>
                                    {state.projInitAllocationAmount ?
                                        <p className='mb-1 text-danger'>{state.projInitAllocationAmount}</p>
                                    : null}
                                </div>
                                {state.expandMenuId === 'projects' ?
                                    <div className='border-lightGray rounded p-3 mt-2'>
                                        <React.Fragment>
                                        <p className='mb-1'>Showing projects of {state.selectedInitiative}</p>
                                        {state.projectOptions && state.projectOptions.map((row, pIndex) => {
                                            return(
                                                <div key={'proj_'+pIndex} className={`px-2 py-3 bg-dark3 ${pIndex ? 'border-mediumDarkGray-top' : ""}`}> 
                                                    <div className={`mb-0 d-flex justify-content-between cursorPointer`} 
                                                        onClick={() => expandProjects(row)}>
                                                        <div>
                                                            <span className={`far ${state.expandProj === row.project ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>{row.project}
                                                        </div>
                                                    </div>
                                                    {state.expandProj === row.project ?
                                                        <div className="my-3">
                                                            {state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project] && state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project].length ?
                                                                state['projectIntitativeAllocationError_'+state['selectedProjCloudProvider_'+pIndex]+'_'+row.project].map(err => {
                                                                    return(
                                                                        <p className='mb-1 text-danger'>{err}</p>
                                                                    )
                                                                })
                                                            : null}
                                                            {state['projectAllocationError_'+row.project] && state['projectAllocationError_'+row.project].length ?
                                                                state['projectAllocationError_'+row.project].map(err => {
                                                                    return(
                                                                        <p className='mb-1 text-danger'>{err}</p>
                                                                    )
                                                                })
                                                            : null}
                                                            <div className='d-flex'>
                                                                <div className="py-1 w-30">
                                                                    <div className='d-flex justify-content-between'>
                                                                        <p className="b-block mb-0 text-primary-color">Cloud Provider</p>
                                                                    </div>
                                                                    <div className={`w-80 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', "showProjCloudProvider_"+pIndex)
                                                                    }}>
                                                                        <p className={`d-flex p-2 bg-mediumDarkGray mb-0 rounded-5 d-flex justify-content-between ${state['selectedProjCloudProvider_'+pIndex] ? 'text-white' : 'placeholder'}`}>
                                                                            {state['selectedProjCloudProvider_'+pIndex] ? capitalizeAllLetter(state['selectedProjCloudProvider_'+pIndex]) : 'Select'}
                                                                            <i className='fa fa-caret-down mt-1'/>
                                                                        </p>
                                                                        {state["showProjCloudProvider_"+pIndex] ?
                                                                            <div className='position-relative'>
                                                                                <MultiSelectSection
                                                                                    // fields={''}
                                                                                    className={'w-70'}
                                                                                    removeTop={true}
                                                                                    options={state['projectCloudProviders_'+pIndex] ? state['projectCloudProviders_'+pIndex] : []}
                                                                                    selectedValues={state['selectedProjCloudProvider_'+pIndex] ? state['selectedProjCloudProvider_'+pIndex] : ''}
                                                                                    makeLabelUpperCase={'capitalizeAllLetter'}
                                                                                    callbackMultiSelect={(value) => {
                                                                                        setState(prevState => ({ 
                                                                                            ...prevState, 
                                                                                            ['selectedProjCloudProvider_'+pIndex]: value,
                                                                                            ['project_montly_split_equal_'+value+'_'+pIndex]: state['project_montly_split_equal_'+value+'_'+pIndex] ? true : false
                                                                                        }))
                                                                                        onChangeProjCloudProvider(value, pIndex)
                                                                                    }}
                                                                                    singleSelection={true}
                                                                                    hideSearch={true}
                                                                                />
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="py-1 w-40">
                                                                    <p className="b-block mb-1 mr-2 align-self-center text-primary-color">Annual&nbsp;Budget</p>
                                                                    <div className='d-flex'>
                                                                        <Input
                                                                            type="text"
                                                                            placeholder="Enter amount"
                                                                            className={`inputTextbox border-lightGray bg-transparent text-white`}
                                                                            // maxlength="128"
                                                                            value={state['projectAlloation_'+row.project+'_'+pIndex] ? state['projectAlloation_'+row.project+'_'+pIndex] : ""}
                                                                            readOnly={state.pageType === 'View' ? true : false}
                                                                            onChange={e => {
                                                                                setState(prevState => ({ ...prevState, ['projectAlloation_'+row.project+'_'+pIndex]: onlyNumeric(e.target.value) }))
                                                                                handleProjectInputChange('allocation', pIndex, onlyNumeric(e.target.value))
                                                                                if(state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]) {
                                                                                    handleProjectMonthlyEqualSplit(true, pIndex, onlyNumeric(e.target.value), row)
                                                                                }
                                                                                onChangeHandleValidation()
                                                                            }}
                                                                            />
                                                                        <p className="b-block mb-0 ml-2 align-self-center">{currencyUnit ? capitalizeAllLetter(currencyUnit) : ''}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="py-1 w-30 mt-3">
                                                                    <div className='d-flex my-3 justify-content-end align-self-center'>
                                                                        <p className="form-check-label mb-0 mr-2 text-white align-self-center text-primary-color" onClick={() => setState(prevState => ({ ...prevState, ['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]: !state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex] }))}>Equal&nbsp;Monthly&nbsp;Budget</p>
                                                                        <div className="ml-1 form-check">
                                                                            <input type="checkbox"
                                                                                className="form-check-input"
                                                                                readOnly={state.pageType === 'View' ? true : false}
                                                                                checked={state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex] ? true : false}  
                                                                                value={state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex] ? true : false}
                                                                                onChange={(e) => {
                                                                                    let monthlySplit = !state['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]
                                                                                    setState(prevState => ({ ...prevState, ['project_montly_split_equal_'+state['selectedProjCloudProvider_'+pIndex]+'_'+pIndex]: monthlySplit }))
                                                                                    handleProjectMonthlyEqualSplit(monthlySplit, pIndex, state['projectAlloation_'+row.project+'_'+pIndex], row)
                                                                                    
                                                                                    // onChange={() => setState(prevState => ({ ...prevState, splitEqual: !state.splitEqual }))}
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=''>
                                                                {state['projectProviders_'+row.project] && state['projectProviders_'+row.project].length ?
                                                                    <div className='bg-dark2 m-1 p-2 rounded'>
                                                                        {state['projectProviders_'+row.project].map((pro, proIndex) => {
                                                                            return(
                                                                                <div key={'pro_'+proIndex} className={`d-flex justify-content-between my-1 ${proIndex ? 'pt-2 border-mediumDarkGray-top' : ''}`}>
                                                                                    <div className='align-self-center w-10'>
                                                                                        <div className="py-1 pl-3">
                                                                                            <span className="ml-2 f16 text-center mb-0 text-info">{pro.provider ? capitalizeAllLetter(pro.provider) : ''}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='align-self-center w-30'>
                                                                                        <div className="d-flex justify-content-between py-1 pl-3">
                                                                                            <p className="b-block mb-0 f16">Committed</p>
                                                                                            <span className="ml-2 f16 mb-0 text-warning">{pro.allocation ? thousandSeparator(pro.allocation) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='align-self-center w-30'>
                                                                                        <div className="d-flex justify-content-between py-1 pl-3">
                                                                                            <p className="b-block mb-0 f16">Allocated</p>
                                                                                            <span className="ml-2 f16 mb-0 text-warning">{pro.distributuion ? thousandSeparator(pro.distributuion) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='align-self-center w-30'>
                                                                                        <div className="d-flex justify-content-between py-1 pl-3">
                                                                                            <p className="b-block mb-0 f16">Balance</p>
                                                                                            <span className={`ml-2 f16 mb-0 ${pro.balance > 0 ? 'text-info' : 'text-danger'}`}>{pro.balance ? thousandSeparator(pro.balance) : 0}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                : null}                                                            
                                                                {state['projIniSeparateMonthlyError_'+selectedInitiative.iIndex+'_'+pIndex] ?
                                                                    <p className='mb-1 text-danger'>{state['projIniSeparateMonthlyError_'+selectedInitiative.iIndex+'_'+pIndex]}</p>
                                                                : null}                                                            
                                                                <p className='mb-0 mt-2'>Monthly Budget</p>
                                                                <div className='row mt-2'>
                                                                    {state.quarters && state.quarters.map((quarter, rowIndex) => {
                                                                        return(
                                                                            <div className='col-sm-3' key={'quater_'+rowIndex}>
                                                                                {quarter.map(mnt => {
                                                                                    return(
                                                                                        <div className='col-sm-12 mb-3' key={'dep_'+mnt.label}>
                                                                                            <div className='d-flex justify-content-between'>
                                                                                                <p className="b-block mb-0 text-primary-color">{mnt.label+' '+(state.year ? state.year : '')}</p>
                                                                                            </div>
                                                                                            <Input
                                                                                                type="number"
                                                                                                placeholder={''}
                                                                                                readOnly={state.pageType === 'View' ? true : false}
                                                                                                className={`inputTextbox border-lightGray bg-transparent text-white`}
                                                                                                // maxlength="128"
                                                                                                value={state['project_'+row.project+'_'+mnt.label+'_'+pIndex] ? state['project_'+row.project+'_'+mnt.label+'_'+pIndex] : ""}
                                                                                                onChange={e => {
                                                                                                    setState(prevState => ({ ...prevState, ['project_'+row.project+'_'+mnt.label+'_'+pIndex]: e.target.value }))
                                                                                                    handleProjectMonthlySplit(mnt.value, pIndex, e.target.value, row)
                                                                                                    onChangeHandleValidation('', mnt)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                                <div className='borderBottomMiddleBlue'></div>
                                                                                <span className='d-flex justify-content-center mt-2'>Q{rowIndex+1} {state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex] ? thousandSeparator(Math.round(state['project_quater_'+row.project+'_'+rowIndex+'_'+pIndex])) : 0}</span>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                                <div className='d-flex justify-content-between mt-4'>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                    <div className='w-50 borderBottomMiddleBlue'></div>
                                                                </div>
                                                                <div className='d-flex justify-content-between mt-2'>
                                                                    <span className='w-50 d-flex justify-content-center'>H1 {state['project_half_0_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_0_'+row.project+'_'+pIndex]), 0) : 0}</span>
                                                                    <span className='w-50 d-flex justify-content-center'>H2 {state['project_half_1_'+row.project+'_'+pIndex] ? thousandSeparator(Math.round(state['project_half_1_'+row.project+'_'+pIndex]),0) : 0}</span>
                                                                </div>

                                                                <div className='m-2 bg-muted rounded p-2'>
                                                                    <p className='mb-0'>Accounts</p>
                                                                    <div className="table-responsive">
                                                                        <table className="table">
                                                                            <thead className='text-white bg-black'>
                                                                                <th>Provider</th>
                                                                                <th>Account</th>
                                                                                <th>Billing Contribution</th>
                                                                            </thead>
                                                                            <tbody className="text-white">
                                                                                {row.accounts && row.accounts.length ?
                                                                                    row.accounts.map(acc => {
                                                                                        return(
                                                                                            <tr>
                                                                                                <td>{capitalizeAllLetter(acc.cloudProviderId)}</td>
                                                                                                <td>{acc.billingUnit}</td>
                                                                                                <td>{acc.billingContribution}%</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                : null}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                            )
                                        })}
                                        </React.Fragment>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                : null}
			</div>
            <div className='fixed-buttons justify-content-end bg-transparent border-0'>
                <div className='align-self-start'>
                    {state.onSaveFunction ?
                        <button className={`btn btn-primary mr-2`}>
                            <Spinner size='sm' color='light' className='mx-2' /> 
                            {state.pageType !== "Edit" ?  "Saving"  : "Updating"}
                        </button>
                    :
                        <div className='d-flex'>
                            {state.pageType !== "View" ?
                                <button className={`btn btn-primary mr-2`} 
                                    onClick={() => {
                                        setState(prevState => ({ ...prevState, onSaveFunction: true }))
                                        onChangeHandleValidation('save')
                                    }}>
                                    {state.pageType !== "Edit" ?  "Save"  : "Update"}
                                </button>
                            : null}
                            <button className={`btn btn-secondary mr-2`} 
                                onClick={navigateTo}>
                                Cancel
                            </button>
                        </div>
                    } 
                </div>
            </div>
		</div>
	)
}

export default RightSection