/*************************************************
 * Collider
 * @exports
 * @file detectorsAction.js
 * @author Rajasekar // on 27/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import axios from 'axios'
//import Cookies from 'js-cookie'
import ApiCalls from '../../components/ApiCalls'

import {
	DETECTORS_END_POINT,
	GET_TOTAL_COUNT,
	GET_DAY_WISE_COUNT,
	GET_ALL_IDENTIFIERS_RESULTS,
	GET_AGGREGATED_IDENTIFIER_RESULTS,

	GET_MONITORING_SNAPSHOT,

	BILLING_END_POINT,
	GET_BILLING_SNAPSHOT,

	CONFIGURATION_END_POINT,
	GET_CONFIGURATION,
	
	GET_AGGREGATED_ACCOUNTS_BY_REGION,
	
	GET_TOP_EVENTS,
	GET_ASSET_DETAIL,
	LIST_ASSETS,
	GET_COVERED_SERVICES,
	GET_EVENTS_GRAPH_COUNT,
	GET_RECENT_EVENTS,
} from '../../config'
import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater, refreshPage } from '../../utils/utility'

/**
 * Action to get detectors related issues
 * @param {Object} body
 * @param {Function} callback
 */
export const getRelatedIssues = (body, callback) => {
	return async dispatch => {
		try {
			const url = DETECTORS_END_POINT + GET_ALL_IDENTIFIERS_RESULTS;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
				}
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get total count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalCount = (body, callback) => {
	return async dispatch => {
		try {
			const url = DETECTORS_END_POINT + GET_TOTAL_COUNT;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
				}
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 * Action to get day wise count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDayWiseCount = (body, callback) => {
	return async dispatch => {
		try {
			const url = DETECTORS_END_POINT + GET_DAY_WISE_COUNT;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
				}
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 * Action to get monitoring snapshot
 * @param {Object} body
 */
export const getMonitoringSnapShot = (body, dayType, callback) => {
	return async dispatch => {
		try {
			const url = GET_MONITORING_SNAPSHOT;
			const response = await ApiCalls.post(url, body)

			let monitoringSnapShot = response && response.data

			let object = {}
			if(Object.entries(monitoringSnapShot).length) {
				Object.entries(monitoringSnapShot).length && Object.entries(monitoringSnapShot).forEach(([subKey,subValue], index) => {
					if(subKey !== 'request') {
						let totalArray = []		
						let data = []
						let label = []
						if(subValue.metrics) {
							subValue.metrics.forEach(metric => {
								data.push(metric.Value)
								if(dayType === 'h') {
									label.push(momentTime(metric.Timestamp))
								} else {
									label.push(momentDate(metric.Timestamp, 'DD MMM'))
								}
							})
							// label = ['10 DEC', '11 DEC', '12 DEC', '13 DEC', '14 DEC', '15 DEC', '16 DEC', '17 DEC', '18 DEC', '19 DEC']
							// data = [0, 15, 20, 5, 8, 10, 0, 12, 20, 6]
							subValue.metricsChart = monitoringApexMetrics(subKey, data, label, subValue.details.unit, index)
						}
						totalArray.push(subValue)
						object[subKey] = totalArray
					}
				})
			}
			
			callback(true, object)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

const monitoringApexMetrics = (metric, data, label, unit, index) => {		
	if(unit === 'Bytes/second') { unit = 'Bytes' } 
	else if(unit === 'Kilobytes/second') { unit = 'Kilobytes' } 
	else if(unit === 'Megabytes/second') { unit = 'Megabytes' } 
	else if(unit === 'Gigabytes/second') { unit = 'Gigabytes' } 
	else if(unit === 'Terabytes/second') { unit = 'Terabytes' }
	
	if(unit === 'Bits /second') { unit = 'Bits' } 
	else if(unit === 'Kilobits/second') { unit = 'Kilobits' } 
	else if(unit === 'Megabits/second') { unit = 'Megabits' } 
	else if(unit === 'Gigabits/second') { unit = 'Gigabits' } 
	else if(unit === 'Terabits/second') { unit = 'Terabits' }

	if(unit === 'Count/second') { unit = 'Count'}

	let graphLabel = []
	let createdGraphData = []
	// let modifiedGraphData = []
	let fontColors = []
	let startEndLabels = []
	if(label.length) {
		label.forEach((item, x) => {
			graphLabel.push(item)
			if(label.length > 5) {
				let length = label.length - 2
				let interval = parseInt(length / 3)
				let second = interval
				let third =  interval * 2
				let fourth = interval * 3
				if(fourth > label.length - 1) {
					if(x === fourth) {
						startEndLabels.push(item)
					}
				}

				if(x === 0 || x === second || x === third || x === fourth || x === (label.length - 1)) {
					startEndLabels.push(item)
				}
			} else {
				startEndLabels.push(item)
			}
		})
		let labelType = ''
		let data1 = []	
		data.forEach((item, x) => {
			createdGraphData.push(item)
			let dataItems1 = {}
			dataItems1.x = label[x]
			//dataItems1.y  = parseFloat(item,2)
			
			if(unit === 'Percent') {
				labelType = '%'
				item = parseFloat(item).toFixed(2) + '%';
			} else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes') {
				item = convertBytes(item, unit)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds') {
				item = convertSeconds(item, unit)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
				item = convertBits(item, unit)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else if(unit === 'Count') {
				item = countFormater(item)
				labelType = item.split(' ')[item.split(' ').length - 1]
			} else {
				item = parseInt(item)
			}
			dataItems1.y = item.toString()
			data1.push(dataItems1)

			fontColors.push('#B8BBBE')
		})

		let series = []
		let dataRow = {}
		dataRow.data = data1
		dataRow.name = metric
		dataRow.labels = graphLabel
		series.push(dataRow)
		let options = {
			chart: {
				id:'monitoring_'+index,
				group:'monitoring',
				height: 100,
				type: 'area',
				zoom: {
					enabled: false
				},
				sparkline: {
					enabled: false
				},
				toolbar: {
					show: false,
				}
			},
			legend: {
				show: false
			},
			dataLabels: {
				enabled: false
			},
			stroke: {
				show: true,
				curve: 'smooth',
				lineCap: 'butt',
				colors: undefined,
				width: 2,
				dashArray: 0,      
			},
			grid: {
				borderColor: '#343947',
                strokeDashArray: 0,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    },  
                },   
                yaxis: {
                    lines: {
                        show: false
                    },
                    labels: {
                        style: {
                            colors: ['#969AA7'],
                        }
                    }
                },
				padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: -2
				}
			},			
			yaxis: {
				labels: {
					show: true,
					style: {
						colors: fontColors
					},
					formatter: function(value) {
						if(unit === 'Percent') {
							return parseFloat(value).toFixed(2) + '%';
						} else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes') {
							return convertBytes(value, unit)
						} else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds') {
							return convertSeconds(value)
						} else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
							return convertBits(value, unit)
						} else if(unit === 'Count') {
							return countFormater(value, unit)
						} else {
							return parseInt(value)
						}
					}
				},
				axisBorder: {
                    show: true,
                    // color:'#434959',
                    color: '#343947',
                    widht: 2,
                    offsetX: -15,
                    // offsetY: 0
                },
                axisTicks: {
                    show: false
                },
				tickAmount: 2
			},
			labels: graphLabel,
			xaxis: {
				Categories: [graphLabel],
				tickAmount: 'dataPoints',
				show: true,
				// range: 7,
				labels: {
					rotate: 0,
					show: true,
					formatter: function(value) {
						if(value === startEndLabels[0] || value === startEndLabels[1] || value === startEndLabels[2] || value === startEndLabels[3] || value === startEndLabels[4]) {
							return value
						}
					},
					style: {
						colors: fontColors,
						fontSize: '9px'

					},
				},
				axisTicks: {
					show: false,
				},				
				axisBorder: {
                    show: true,
                    color: '#343947',
                    widht: 2,
                },
				tooltip: {
					enabled: false
				},
				// min: min,
				// max: max,
			},
			colors: ['#775BA2'],
			fill: {
                type: "gradient",
                gradient: {
                    // shadeIntensity: 1,
                    // opacityFrom: 0.7,
                    // opacityTo: 0.9,
                    // stops: [0, 90, 100]
                    gradientToColors: [ '#775BA2'],
                    shadeIntensity: 0,
                    opacityFrom: .3,
                    opacityTo: 1,
                    stops: [50, 90]
                }
            },
			tooltip: {
				enabled: true,
				enabledOnSeries: false,
				shared: false,
				followCursor: false,
				intersect: false,
				inverseOrder: false,
				custom: function({series, seriesIndex, dataPointIndex, w}) {
					let val  = series[seriesIndex][dataPointIndex] + ' '+ labelType
					//let val  = series[seriesIndex][dataPointIndex];
					let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
					//let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
					//val = Math.abs(Math.round(val))
					let name = w.globals.initialSeries[0].name
					//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
					return '<div class="arrow_box"> ' +  labelName + ' <span style="color:'+ w.globals.colors[seriesIndex] +'">' + name +' '+ val +'</span> </div>'
				},
				fillSeriesColor: false,
				theme: false,
				style: {
				fontSize: '12px',				
				},
				onDatasetHover: {
					highlightDataSeries: false,
				},
				x: {
					show: false,
					format: 'dd MMM',
					formatter: undefined,
				},
				y: {
					show: false,
					formatter: undefined,
					title: {
						formatter: (seriesName) => seriesName,
					},
				},
				marker: {
					show: false,
				}
			}
		}

		let graph = {}
		graph.series = series
		graph.options = options

		return graph;
	}
	
}

/**
 * Action to get billing snapshot
 * @param {Object} body
 */
export const getBillingSnapshot = (body, callback) => {
	return async dispatch => {
		try {
			const url = BILLING_END_POINT + GET_BILLING_SNAPSHOT;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
				}
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 * Action to get event top details
 * @param {Object} body
 */
export const getTopEvents = (params) => {
	return async () => {
		try {
			const url = GET_TOP_EVENTS;
			const response = await ApiCalls.post(url, params)
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			return false
		}
	}
}
/**
 * Action to get event recent details
 * @param {Object} body
 */
export const getRecentEvents = (params) => {
	return async () => {
		try {
			const url = GET_RECENT_EVENTS;
			const response = await ApiCalls.post(url, params)
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			return false
		}
	}
}
/**
 * Action to get event graph details
 * @param {Object} body
 */
export const getEventGraphCounts = (params) => {
	return async () => {
		try {
			const url = GET_EVENTS_GRAPH_COUNT;
			const response = await ApiCalls.post(url, params)
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			return false
		}
	}
}

/**
 * Action to get configuration
 * @param {Object} body
 */
export const getConfiguration = body => {
	return async dispatch => {
		try {
			const url = CONFIGURATION_END_POINT + GET_CONFIGURATION;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
				}
			})
			
			// callback(true, response && response.data)
			myLog('configuration', response && response.data)
		} catch (error) {
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			// callback(false, respone)
			myLog('configuration', error)
		}
	}
}
/**
 * Action to get asset details
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetDetails = (params) => {
	return async () => {		
		try {
			const url = GET_ASSET_DETAIL;
			const response = await ApiCalls.post(url, params)
			
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}

			return
		}
	}
}

/**
 * Action to get asset details
 * @param {Object} body
 * @param {Function} callback
 */
export const listAssets = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_ASSETS;
			const response = await ApiCalls.post(url, body)
			
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get-covered-services
 * @param {Object} body
 * @param {Function} callback
 */
 export const getCoveredServices = (body) => {
	return async () => {
		try {
			const url = GET_COVERED_SERVICES;
			const response = await ApiCalls.post(url, body)
			
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			// return respone
		}
	}
}

/**
 * Action to get-aggregated-accounts-by-region
 * @param {Object} body
 * @param {Function} callback
 */
 export const getAggregatedAccountsByRegion = (body) => {
	return async () => {
		try {
			const url = GET_AGGREGATED_ACCOUNTS_BY_REGION;
			const response = await ApiCalls.post(url, body)
			
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			// return respone
		}
	}
}

/**
 * Action to get-aggregated-identifier-results
 * @param {Object} body
 * @param {Function} callback
 */
 export const getAggregatedIdentifierResults = (body, callback) => {
	return async dispatch => {
		try {
			const url = DETECTORS_END_POINT + GET_AGGREGATED_IDENTIFIER_RESULTS;
			const response = await axios.post(url, body, {
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`
				}
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get-covered-services
 * @param {Object} body
 * @param {Function} callback
 */
export const getCoveredServicesNew = (params) => {
	return async () => {		
		try {
			const url = GET_COVERED_SERVICES;
			const response = await ApiCalls.post(url, params)
			
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}

			return
		}
	}
}