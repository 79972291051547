/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 04/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../actions/commonActionNew';
import { listDiskDetails, getDiskDetails, getDiskMetrics, getQueueMetrics, getClusterHeadNodeMetrics, getClusterMetrics } from '../../actions/Collider/ObservabilityAction';
import { getClusterDetails } from '../../actions/Collider/ClusterAction';
import { listWorkstationResults, getWorkStationMetrics } from '../../actions/CatalogAction'
import { momentDateGivenFormat, subHours, subDays, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, getRegionName } from '../../utils/utility';

import { HOURLY_CUSTOM_DURATION_OPTIONS } from '../../utils/constants'
import { UncontrolledTooltip, Spinner } from 'reactstrap';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import MetricsTab from '../common/Mertics/MetricsTab';
import Search from '../common/SearchComponent'
import DetailsTab from './DetailsTab';
import AlertsTab from './AlertsTab';
import TroubleshootTab from './TroubleshootTab';
import FilterSection from '../common/FiltersSection';

const LandingPage = ()  => {
    const clickOutside = useRef()
    // State variables
    const [state, setState] = useState({
        showLoading: true,
        selectedGraphDuration: '+3h',
        selectedDuration: '+3h',
        pageType: 'Details',
        selectedMetricsType: 'Core Metrics',
        showSearchBtn: true,
        disableSearchBtn: true,
        categoryList: [],
        assetCategory: [],
        selectedService: [],
        isCategoryOpen: false,
        tagsArray: [0],
        nextErrorListToken: '',
        boxSection: ['Cluster', 'Disk', 'Workstation'],
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster']
    });

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);
        
    // Refs
    const dateRef = useRef();
    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);

    useEffect(() => {
        if(state.showLoading || state.showDetailsLoading || state.showMetricLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false, showMetricLoading: false, showDetailsLoading: false })) }, 5000);
        }
    }, [state.showLoading, state.showMetricLoading, state.showDetailsLoading])


    const onExpandCollapse = useCallback((type) => {
        setState(prevState => ({
            ...prevState,
            selectedLeftOption: type,
            showDetailsLoading: true,
            metricDetails: [],
            totalMenuItems: [],
            selectedAsset: {},
            selectedCluster: {},
            filteredLeftMenuItems: [],
            showLeftMenuLoading: true,
            [prevState.selectedLeftOption + '_section']: false,
            [type + '_section']: !prevState.selectedLeftOption + '_section',
            ['show_' + type + '_loading']: true,
            parentExpanded: true
        }));
        setTimeout(() => setState(prevState => ({ ...prevState, ['show_' + type + '_loading']: false })), 3000)

    }, [])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            onExpandCollapse(state.selectedLeftOption ? state.selectedLeftOption : 'Cluster')
        }
    }, [state.callSearch, state.selectedLeftOption, onExpandCollapse])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            callSearch: true
        }))
    }
    
    // Call onSearch when changesInFilter is updated
    useEffect(() => {
        if (state.functionCallType === 'onLoad' && state.changesInFilter) {
            setState(prevState => ({
                ...prevState,
                changesInFilter: false,
                showLoading: true,
                resetEnabled: false,
                nextErrorListToken: '',
                selectedAsset: {}
            }));        
        }
    }, [state.changesInFilter, state.functionCallType, state]);
    
    const onReset = () => {
        setState(prevState => ({
            ...prevState,
            selectedProvider: '',
            selectedAccount: [],
            selectedDuration: '+7d',
            changesInFilter: state.resetEnabled,
            datePickerStartDate: '',
            datePickerEndDate: ''
        }),
            () => {
                if (state.resetEnabled) {
                // durationFunction('onLoad');
                }
            }
        );
    };

    useEffect(() => {
        if(state.parentExpanded) {
            setState(prevState => ({ ...prevState, parentExpanded: false }))
            if(state.selectedLeftOption === 'Disk') {            
                let params = {}
                params.provider = state.selectedProvider.toLowerCase();            
                if(state.selectedAccount && state.selectedAccount.length) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion && state.selectedRegion.length) {
                    params.region = state.selectedRegion
                }            
                if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                    params.cluster_name = state.selectedClusters
                }
                if(state.selectedProjectTags && state.selectedProjectTags.length) {
                    params.project_tags = state.selectedProjectTags
                }
                dispatch(listDiskDetails(params))
                    .then((response) => {
                        if(response) {
                            let selectedAsset = {}
                            let results = response.results ? response.results : []
                            
                            if (results && results.length) {
                                selectedAsset = results[0]
                            }
                    
                            setState(prevState => ({
                                ...prevState,
                                totalDiskItems: results,
                                filteredDiskItems: results,
                                selectedAsset,
                                showLoading: false,
                                showMetricLoading: true,
                                ['show_' + state.selectedLeftOption + '_loading']: false,
                                callAssetDetails: true
                            }))
                        }
                    })
            } else if(state.selectedLeftOption === 'Cluster') {
                let type = state.selectedLeftOption;
                let params = {};
                params.provider = state.selectedProvider ? state.selectedProvider.toLowerCase() : ''            
                if(state.selectedAccount && state.selectedAccount.length) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion && state.selectedRegion.length) {
                    params.region = state.selectedRegion
                }
                if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                    params.cluster_name = state.selectedClusters
                }
                if(state.selectedProjectTags && state.selectedProjectTags.length) {
                    params.project_tags = state.selectedProjectTags
                }
                dispatch(getClusterDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results ? response.results : []
    
                            let detailsFormat = response.cluster_details ? response.cluster_details : []
            
                            let selectedCluster = {}    
                            let selectedAsset = {}
                            if(results && results.length) {
                                selectedCluster = results[0]
                                selectedAsset = results[0]
                                selectedAsset.selected_cluster_type = "all"
                                // if(results[0].head_node) {
                                //     selectedAsset = results[0].head_node
                                // }
                            }
                            setState(prevState => ({
                                ...prevState,
                                detailsFormat,
                                clusterDetails: results,
                                selectedCluster,
                                [type + '_cluster_' + selectedCluster.cluster_name]: true,
                                // [selectedCluster.cluster_name + '_head_node']: true,
                                selectedAsset,
                                showMetricLoading: true,
                                showDetailsLoading: false,
                                ['show_' + state.selectedLeftOption + '_loading']: false
                            }))
                        }
                })
            } else if(state.selectedLeftOption === 'Workstation') {            
                let params = {"return_all": true, "metrics": false, 'is_asset_present': true}
                if(state.selectedProvider) {
                    params.provider = state.selectedProvider.toLowerCase()
                }
                if(state.selectedAccount && state.selectedAccount.length) {
                    params.account_id = state.selectedAccount
                }
                if(state.selectedRegion && state.selectedRegion.length) {
                    params.region = state.selectedRegion
                }
                if(state.selectedProjectTags && state.selectedProjectTags.length) {
                    params.project_tags = state.selectedProjectTags
                }
                
                dispatch(listWorkstationResults(params))
                    .then((response) => {
                        if(response) {
                            // let totalResults = state.totalWorkstations &&  state.totalWorkstations.length ? state.totalWorkstations : []
    
                            // let selectedAsset = {}
                            // if(response.results && response.results.length) {
                            //     totalResults = totalResults.concat(response.results)
                            //     selectedAsset = totalResults[0]
                            // }
                            let totalResults = []
                            let selectedAsset = {}
                            if(response.results && response.results.length) {
                                totalResults = response.results
                                selectedAsset = totalResults[0]
                            }
    
                            let detailsFormat = response.workstation_details ? response.workstation_details : []
    
                            setState(prevState => ({
                                ...prevState,
                                totalWorkstations: totalResults,
                                selectedAsset,
                                showLoading: false,
                                detailsFormat,
                                callAssetDetails: true,
                                ['show_' + state.selectedLeftOption + '_loading']: false
                            }));
                        }
                    })
            }
        }
    }, [state, dispatch]);

    useEffect(() => {        
        let type = state.selectedLeftOption;
        let data = []
        if(type === 'Disk') {
            data = state.totalDiskItems
        } else if(type === 'Cluster') {
            data = state.clusterDetails
        } else if(type === 'Workstation') {
            data = state.totalWorkstations
        }
        setState(prevState => ({
            ...prevState,
            totalMenuItems: data,
            filteredLeftMenuItems: data,
            showLeftMenuLoading: false,            
        }));
    }, [state.totalDiskItems, state.clusterDetails, state.totalWorkstations, state.selectedLeftOption])

    useEffect(() => {
        if(state.callAssetDetails) {
            let type = state.selectedLeftOption;
            let pageType = state.pageType
            setState(prevState => ({
                ...prevState,
                pageType: '',
                showDetailsLoading: type === 'Disk' ? true : false,
                // metricDetails: {},
                callAssetDetails: false
            }));
            setTimeout(() => {
                setState(prevState => ({ ...prevState, pageType }))
            }, 2000);
        }

    }, [state.callAssetDetails, state.selectedLeftOption, state.pageType])

    

    const getDiskDetailsFunction = useCallback(() => {
        let params = {};
        params.provider = state.selectedAsset && state.selectedAsset.provider;
        params.service_name = state.selectedAsset && state.selectedAsset.service_name;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id ? state.selectedAsset.asset_id : '';
        
        params.provider = state.selectedProvider.toLowerCase();            
        if(state.selectedAccount && state.selectedAccount.length) {
            params.account_id = state.selectedAccount
        }
        if(state.selectedRegion && state.selectedRegion.length) {
            params.region = state.selectedRegion
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }

        if (state.selectedAsset && state.selectedAsset.asset_id) {            
            dispatch(getDiskDetails(params))
                .then((response) => {
					if(response) {
                        let detailsFormat = response.disk_details ? response.disk_details : []
                        let results = response.results ? response.results : []
                        setState(prevState => ({
                            ...prevState,
                            detailsFormat,
                            diskDetails: results,
                            showDetailsLoading: false,
                        }));
                    }
            });
        }   
    }, [state.selectedAsset, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedProjectTags])    

    const getDiskMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id;        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }
    
        if (state.selectedAsset && state.selectedAsset.asset_id) {
            dispatch(getDiskMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                })
        } else {
            setState(prevState => ({
                ...prevState,
                metricDetails: [],
                showMetricLoading: false
            }))
        }
    }, [dispatch, state.selectedAsset, state.selectedGraphDuration, state.selectedProjectTags])
    
    
    const getQueueMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id;
        if(state.selectedQueue) {
            params.queue_name = state.selectedQueue;
        }
        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }
    
        if (state.selectedAsset && state.selectedAsset.asset_id) {            
            dispatch(getQueueMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                });
        }
    }, [dispatch, state.selectedAsset, state.selectedQueue, state.selectedGraphDuration, state.selectedProjectTags])

    const getClusterMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }
    
        if (state.selectedAsset && state.selectedAsset.asset_id) {
            dispatch(getClusterMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                })
        }
    }, [dispatch, state.selectedAsset, state.selectedGraphDuration, state.selectedProjectTags])
    
    const getClusterHeadNodeMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);
        let startTime = '';
        if (period === 'd') {
            startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        } else {
            startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
        }
        let endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');

        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id;
        if (state.selectedProcess) {
            params.process_name = state.selectedProcess;
        }
        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }

        if (state.selectedAsset && state.selectedAsset.asset_id) {
            dispatch(getClusterHeadNodeMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false
                        }));
                    }
                })
        }
    }, [dispatch, state.selectedAsset, state.selectedProcess, state.selectedGraphDuration, state.selectedProjectTags])

    
    const getWorkStationMetricsFunction = useCallback(() => {
        let duration = state.selectedGraphDuration

        let startTime = '';
        let endTime = ''
        if(duration === 'custom') {
            startTime = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss');
            endTime = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss');
        } else {
            let period = duration.substring(duration.length, duration.length - 1);
            let durationBtw = getDayFromSelectedDuration(duration);
            
            if (period === 'd') {
                startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');
        }


        let params = {};
        params.start_time = startTime;
        params.end_time = endTime;
        params.asset_id = state.selectedAsset && state.selectedAsset.asset_id       
        
        if(state.selectedAsset && state.selectedAsset.account_id) {
            params.account_id = state.selectedAsset.account_id
        }
        if(state.selectedAsset && state.selectedAsset.region) {
            params.region = state.selectedAsset.region
        }
        if(state.selectedProjectTags && state.selectedProjectTags.length) {
            params.project_tags = state.selectedProjectTags
        }

        if(params.asset_id) {
            dispatch(getWorkStationMetrics(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState, 
                            metricDetails: response,
                            showMetricLoading: false,
                        }))
                    }
                })
        } else {
            setState(prevState => ({
                ...prevState, 
                metricDetails: [],
                showMetricLoading: false,
            }))
        }
    }, [dispatch, state.selectedAsset, state.selectedGraphDuration, state.datePickerStartDate, state.datePickerEndDate, state.selectedProjectTags])

    useEffect(() => {
        if(state.selectedAsset) {
            if(state.selectedLeftOption === 'Disk') {
                getDiskDetailsFunction()
                getDiskMetricsFunction()
            } else if(state.selectedLeftOption === 'Cluster') {
                if(state.selectedAsset && state.selectedAsset.selected_cluster_type === "all") {
                    getClusterMetricsFunction()
                } else {
                    if(state.selectedAsset.queue_name) {
                        getQueueMetricsFunction()
                    } else {
                        getClusterHeadNodeMetricsFunction()
                    }
                }
            } else if(state.selectedLeftOption === 'Workstation') {
                getWorkStationMetricsFunction()
            }
        }
    }, [state.selectedAsset, getDiskDetailsFunction, getDiskMetricsFunction, getQueueMetricsFunction, getClusterHeadNodeMetricsFunction, getWorkStationMetricsFunction, getClusterMetricsFunction, state.selectedLeftOption])

    const onClickCluster = (item) => {
        let selectedAsset = item
        selectedAsset.selected_cluster_type = "all"
        let type = state.selectedLeftOption;
        let headNode = false;
        let queueSection = false;
        let selectedProcess = '';
        let selectedQueue = '';
        // if (item.head_node) {
        //     selectedAsset = item.head_node;
        //     headNode = true;
        // } else if (item.queues && item.queues.length) {
        //     queueSection = true;
        //     selectedAsset = item.queues[0];
        //     selectedQueue = item.queues[0].queue_name;
        // }

        let expandedCluster = state.selectedCluster && state.selectedCluster.cluster_name;
        
        setState(prevState => ({
            ...prevState,
            [type + '_cluster_' + expandedCluster]: false,
            [type + '_cluster_' + item.cluster_name]: !prevState[type + '_cluster_' + item.cluster_name],
            [item.cluster_name + '_head_node']: headNode,
            [item.cluster_name + '_queue_section']: queueSection,
            selectedCluster: item,
            selectedAsset,
            selectedProcess,
            selectedQueue,
            callAssetDetails: true,
            // setPageType: true
        }));
    }

    // useEffect(() => {
    //     if(state.setPageType) {
    //         let pageType = state.pageType
    //         setState(prevState => ({ ...prevState, pageType: '', setPageType: false  }))
    //         setTimeout(() => {
    //             setState(prevState => ({ ...prevState, pageType }))
    //         }, 2000);        
    //     }
    // }, [state.setPageType, state.pageType])
    
    const onClickClusterProcess = (type, item) => {
        if(type === 'process') {
            setState(prevState => ({ ...prevState, selectedProcess: item, selectedQueue: '', showDetailsLoading: false, showMetricLoading: true, callAssetDetails: true }))
        } else {
            setState(prevState => ({ ...prevState, selectedAsset: item, selectedQueue: item.queue_name, selectedProcess: '', showDetailsLoading: false, showMetricLoading: true, metricDetails: [], callAssetDetails: true }))
        }
    }

    const onExpandCollapseClusterChild = (section, type, item) => {
        setState(prevState => ({ 
            ...prevState,
            expandChild: true,
            [item.cluster_name + '_head_node']: {},
            [item.cluster_name + '_queue_section']: true,
            selectedAsset: {},
            selectedProcess: "",
            selectedQueue: "",
            selectedSection: section,
            selectedItemDetails: item
        }))
    };
    
    useEffect(() => {
        if(state.expandChild) {
            let section = state.selectedSection
            let item = state.selectedItemDetails
            
            let selectedAsset = {};
            let headNode = false;
            let queueSection = false;
            let selectedProcess = '';
            let selectedQueue = '';
            if (section === 'headNode' && item.head_node) {
                selectedAsset = item.head_node;
                selectedAsset.selected_cluster_type = ""
                headNode = true;
            } else if (item.queues && item.queues.length) {
                selectedAsset = item.queues[0];
                selectedAsset.selected_cluster_type = ""
                queueSection = true;
                selectedQueue = item.queues[0].queue_name;
            }
            setState(prevState => ({
                ...prevState,
                [item.cluster_name + '_head_node']: headNode,
                [item.cluster_name + '_queue_section']: queueSection,
                selectedAsset,
                selectedProcess,
                selectedQueue,
                callAssetDetails: true,
                expandChild: false
            }));
        }
    }, [state.expandChild, state.selectedSection, state.selectedItemDetails])    

    const handleSelect = (date) => {
        // let datePickerStartDate = momentDateGivenFormat(
        //     date.selection.startDate,
        //     'YYYY-MM-DD 00:00:00'
        // );
        // let datePickerEndDate = momentDateGivenFormat(
        //     date.selection.endDate,
        //     'YYYY-MM-DD 23:59:59'
        // );
        // setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }))     
        
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false,
                selectedGraphDuration: "custom",
                metricDetails: [], 
                showMetricLoading: true,
            }));
    
        }
        setDateState([date.selection])
    };
    
    // const handleSelectNew = (date) => {
    //     let datePickerStartDate = momentDateGivenFormat(date[0], 'YYYY-MM-DD HH:mm:00');
    //     let datePickerEndDate = momentDateGivenFormat(date[1], 'YYYY-MM-DD HH:mm:00');
    //     setState(prevState => ({
    //         ...prevState,
    //         datePickerStartDate,
    //         datePickerEndDate,
    //         showDateRangePicker: false,
    //         selectedGraphDuration: "custom",
    //         metricDetails: [], 
    //         showMetricLoading: true,
    //     }));
    // };

    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}

        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
    
    const onChangePageType = () => {
        // setState(prevState => ({ ...prevState, brushStartTime: '', brushEndTime: '' }));
    };

    useEffect(() => {

    }, [state.showMetricLoading])

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            {/* <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div> */}
            
            <div className='col-sm-12'>
                <div className='row text-white pb-2 bg-nero'>
                    <div className='col-lg-4 col-md-12 col-sm-12 py-2 align-self-center'>
                        <h6 className='text-white m-0'>Observability</h6>
                        <p className='text-white m-0'>Unified view of Metrics, Alerts, and Events in your Cloud HPC</p>
                    </div>
                    
                    <div className='col-lg-8 col-md-12 col-sm-12 py-2' ref={clickOutside}>
                        <div className='row mx-0 topBasicFilter justify-content-end'>
                            <FilterSection
                                filters={state.topSectionFilters}
                                selectedFilters={(filter) => handleSelectedFilters(filter)}
                                clickedonParent={state.clickedonParent}
                                filterAdvancedSearch={false}
                                onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                                onReset={onReset}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-sm-12'>
                <div className='row'>
                    <div className={`col-sm-3 mt-2 pl-2 pr-1`}>
                        <div className='bg-black pt-2 rounded w-100 overflowYAuto vh100'>
                            <div className='accordion' id='accordionDetails'>
                                <div className={`card bg-black border-0`}>
                                    {state.boxSection && state.boxSection.map(row => {
                                        return(
                                            <div key={row}>
                                                <div className={`bg-darkGray p-3 mx-2 ${state[row+'_section'] ? '' : 'mb-2'} `}>
                                                    <div className='d-flex justify-content-between text-white' onClick={() => onExpandCollapse(row)}>
                                                        <h6 className='mb-0'>{row}</h6>
                                                        {state[row+'_section'] ? 
                                                            <i className='fa fa-caret-up cursorPointer ml-3 f16 text-mediumGray'/>
                                                        : 
                                                            <i className='fa fa-caret-down cursorPointer ml-3 f16 text-mediumGray'/>
                                                        }
                                                    </div>
                                                </div>

                                                <div id={'collapse_details'} className={`collapse ${state[row+'_section'] ? 'show' : ''}`} aria-labelledby={'heading_details'} data-parent='#accordionDetails'>
                                                    <div className='card-body m-0'>
                                                        {!state['show_'+row+'_loading'] && !state.showLeftMenuLoading ?
                                                            <React.Fragment>
                                                            <div className='d-flex justify-content-end mt-2'>
                                                                <div className='form-group mb-0 align-self-end my-2'>
                                                                    <Search
                                                                        ID={'automation_plan_search'}
                                                                        data={state.totalMenuItems ? state.totalMenuItems : []}
                                                                        applyLiteDarkTags={true}
                                                                        topClassName={'bg-mediumDarkGray f12 border-0 align-self-center'}
                                                                        searchClassName={'px-2 f12 bg-mediumDarkGray'}
                                                                        searchIconColor={'text-lightGray f12'}
                                                                        searchPlaceHolder={'Search....'}
                                                                        className={'bg-transparent text-white form-control-sm f12'}
                                                                        hideInputSection={false}
                                                                        // topClassName={'dark-search mr-0'}
                                                                        filteredData={(filteredLeftMenuItems, searchText) => {
                                                                            setState(prevState => ({ ...prevState, filteredLeftMenuItems, searchText, emptySearchText: false }))
                                                                        }}
                                                                        resetSearchBox={state.emptySearchText}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {state.filteredLeftMenuItems && state.filteredLeftMenuItems.length ?
                                                                state.filteredLeftMenuItems.map((item, i) => {
                                                                    return (
                                                                        <div key={"item_"+i}>
                                                                        {state.selectedLeftOption === 'Workstation' ?
                                                                            <div key={"workstation_"+i}>
                                                                                <div className={`px-2 cursorPointer ${i ? 'border-top' : ''} bg-darkGray ${state.selectedAsset && state.selectedAsset.workstation_id === item.workstation_id ? ' text-cyon' : ''}`} id={state.selectedLeftOption+'_asset_name_'+item.asset_id} onClick={() => setState(prevState => ({ ...prevState, selectedAsset: item, showMetricLoading: true, metricDetails: [], callAssetDetails: true, showDetailsLoading: true, selectedCluster: {} }))}>
                                                                                    <div className='d-flex'>
                                                                                        <p className={`m-0 f-20 pl pr-1 pt-2 pb-1 hiddenEllipses ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-cyon' : 'text-white'}`}>
                                                                                            {item.id_name_map ?
                                                                                                <span>
                                                                                                    {(item.id_name_map.value ? item.id_name_map.value : item.image_id)}
                                                                                                    <span className={`ml-1 ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-cyon' : 'text-primary-color'} mb-0`}>
                                                                                                        {item.id_name_map.key ? ' : '+item.id_name_map.key : ''}
                                                                                                    </span>
                                                                                                </span>
                                                                                            : null}
                                                                                        </p>


                                                                                    </div>
                                                                                    <div className='d-flex flex-wrap mb-1'>
                                                                                        <p className='mr-1 mb-0 text-primary-color'>{item.service_name ? item.service_name : ''}</p>
                                                                                        {item.alerts ?
                                                                                            <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Alerts {item.alerts}</span>
                                                                                        : null}
                                                                                        {item.anomalies ?
                                                                                            <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                        : null}
                                                                                    </div>
                                                                                    
                                                                                    <UncontrolledTooltip placement='top' target={state.selectedLeftOption+'_asset_name_'+item.asset_id}>
                                                                                        <div className='uncontrolledTooltipLeftAlign'>
                                                                                            <p className='mb-1 text-primary-color wordBreakAll'><span className='text-white mr-1'>Asset: </span>
                                                                                                {item.id_name_map && item.id_name_map !== '' ?
                                                                                                    <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : '')}</span>
                                                                                                :
                                                                                                    <span className={`ml-1`}>{item.asset_name}</span>
                                                                                                }
                                                                                            </p>
                                                                                            {item.id_name_map && item.id_name_map !== '' ?
                                                                                                item.id_name_map.key ? 
                                                                                                    <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                                                                                : null
                                                                                            :
                                                                                                item.resource_type ?
                                                                                                    item.resource_type === 'ApiGateway' ?
                                                                                                        item.rest_api_name && item.rest_api_name !== '' ?
                                                                                                            <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                                                                        : null
                                                                                                    : item.resource_type === 'EC2' ?
                                                                                                        item.tags && item.tags.length && item.tags.filter(e => e.key === 'Name').length ?
                                                                                                            <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === 'Name')[0].value}</p>
                                                                                                        : null
                                                                                                    : null
                                                                                                : null
                                                                                            }
                                                                                            <p className='mb-1 text-primary-color'>
                                                                                                <span className='text-white mr-1'>Resources: </span>
                                                                                                {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                                {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                                                                {item.region ?  (' : ')+getRegionName(item.region, state.regions) : <span>&nbsp;</span>}
                                                                                            </p>
                                                                                            <p className='mb-1 text-primary-color'>
                                                                                                <span className='text-white mr-1'>Services: </span>
                                                                                                {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                                                                {/* {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>} */}
                                                                                            </p>
                                                                                            <div className='d-flex flex-wrap'>
                                                                                                {item.alerts ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Alerts {item.alerts}</span>
                                                                                                : null}
                                                                                                {item.anomalies ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                                : null}
                                                                                                {item.errors ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Errors {item.errors}</span>
                                                                                                : null}
                                                                                                {item.events ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Events {item.events}</span>
                                                                                                : null}
                                                                                            </div>
                                                                                            
                                                                                        </div>    
                                                                                    </UncontrolledTooltip>
                                                                                </div>
                                                                            </div>
                                                                        : state.selectedLeftOption === 'Disk' ?
                                                                            <div key={"disk_"+i}>
                                                                                <div className={`px-2 cursorPointer ${i ? 'border-top' : ''} bg-darkGray ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? ' text-cyon disabled' : ''}`} id={state.selectedLeftOption+'_asset_name_'+item.asset_id} onClick={() => setState(prevState => ({ ...prevState, selectedAsset: item, showMetricLoading: true, metricDetails: [], callAssetDetails: true, showDetailsLoading: true, selectedCluster: {} }))}>
                                                                                    <div className='d-flex'>
                                                                                        <p className={`m-0 f-20 pl pr-1 pt-2 pb-1 hiddenEllipses ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-cyon' : 'text-white'}`}>
                                                                                            {item.id_name_map ?
                                                                                                <span>
                                                                                                    {(item.id_name_map.value ? item.id_name_map.value : '')}
                                                                                                    <span className={`ml-1 ${state.selectedAsset && state.selectedAsset.asset_id === item.asset_id ? 'text-cyon' : 'text-primary-color'} mb-0`}>
                                                                                                        {item.id_name_map.key ? ' : '+item.id_name_map.key : ''}
                                                                                                    </span>
                                                                                                </span>
                                                                                            : null}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className='d-flex flex-wrap mb-1'>
                                                                                        <p className='mr-1 mb-0 text-primary-color'>{item.storage_type ? item.storage_type : ''}</p>
                                                                                        {item.alerts ?
                                                                                            <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Alerts {item.alerts}</span>
                                                                                        : null}
                                                                                        {item.anomalies ?
                                                                                            <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                        : null}
                                                                                    </div>
                                                                                    
                                                                                    <UncontrolledTooltip placement='top' target={state.selectedLeftOption+'_asset_name_'+item.asset_id}>
                                                                                        <div className='uncontrolledTooltipLeftAlign'>
                                                                                            <p className='mb-1 text-primary-color wordBreakAll'><span className='text-white mr-1'>Asset: </span>
                                                                                                {item.id_name_map && item.id_name_map !== '' ?
                                                                                                    <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : '')}</span>
                                                                                                :
                                                                                                    <span className={`ml-1`}>{item.asset_name}</span>
                                                                                                }
                                                                                            </p>
                                                                                            {item.id_name_map && item.id_name_map !== '' ?
                                                                                                item.id_name_map.key ? 
                                                                                                    <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                                                                                : null
                                                                                            :
                                                                                                item.resource_type ?
                                                                                                    item.resource_type === 'ApiGateway' ?
                                                                                                        item.rest_api_name && item.rest_api_name !== '' ?
                                                                                                            <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                                                                        : null
                                                                                                    : item.resource_type === 'EC2' ?
                                                                                                        item.tags && item.tags.length && item.tags.filter(e => e.key === 'Name').length ?
                                                                                                            <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === 'Name')[0].value}</p>
                                                                                                        : null
                                                                                                    : null
                                                                                                : null
                                                                                            }
                                                                                            <p className='mb-1 text-primary-color'>
                                                                                                <span className='text-white mr-1'>Resources: </span>
                                                                                                {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                                {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                                                                {item.region ?  (' : ')+getRegionName(item.region, state.regions) : <span>&nbsp;</span>}
                                                                                            </p>
                                                                                            <p className='mb-1 text-primary-color'>
                                                                                                <span className='text-white mr-1'>Services: </span>
                                                                                                {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                                                                {/* {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>} */}
                                                                                            </p>
                                                                                            <div className='d-flex flex-wrap'>
                                                                                                {item.alerts ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Alerts {item.alerts}</span>
                                                                                                : null}
                                                                                                {item.anomalies ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                                : null}
                                                                                                {item.errors ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Errors {item.errors}</span>
                                                                                                : null}
                                                                                                {item.events ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Events {item.events}</span>
                                                                                                : null}
                                                                                            </div>
                                                                                            
                                                                                        </div>    
                                                                                    </UncontrolledTooltip>
                                                                                </div>
                                                                            </div>
                                                                        : state.selectedLeftOption === 'Cluster' ?
                                                                            <div key={"cluster_"+i}>
                                                                                <div className={`p-3 cursorPointer ${i ? 'border-top' : ''} ${state.selectedCluster && state.selectedCluster.cluster_name === item.cluster_name ? 'bg-darkGray text-cyon' : ''}`}>
                                                                                    <div className='d-flex justify-content-between align-self-center mb-3' onClick={() => setState(prevState => ({ ...prevState, showDetailsLoading: true, showMetricLoading: true,  metricDetails: [] }), onClickCluster(item))}>
                                                                                        <div className='cursorPointer' id={state.selectedLeftOption+'_asset_name_'+item.cluster_name}>
                                                                                            {/* <div className='d-flex'>
                                                                                                <p className={`m-0 hiddenEllipses f-20 pl pr-1 pt-2 pb-1 ${state.selectedCluster && state.selectedCluster.cluster_name === item.cluster_name ? 'text-cyon' : 'text-black'}`}>
                                                                                                    {item.cluster_name}
                                                                                                </p>
                                                                                            </div> */}
                                                                                            <div className='d-flex flex-wrap'>
                                                                                                {item.asset_name ? 
                                                                                                    <p className={`hiddenEllipses mb-0 wordBreakAll`}>{item.asset_name}</p>
                                                                                                : null}
                                                                                            </div>
                                                                                        </div>
                                                                                        {item.head_node && state[state.selectedLeftOption+'_cluster_'+item.cluster_name] ? 
                                                                                            <i className='fa fa-caret-up cursorPointer ml-3 f16 text-lightGray pr-2 align-self-center'/>
                                                                                        : 
                                                                                            <i className='fa fa-caret-down cursorPointer ml-3 f16 text-lightGray pr-2 align-self-center'/>
                                                                                        }
                                                                                    </div>
                                                                                    <UncontrolledTooltip placement='top' target={state.selectedLeftOption+'_asset_name_'+item.cluster_name}>
                                                                                        <div className='uncontrolledTooltipLeftAlign'>
                                                                                            <p className='mb-1 text-primary-color wordBreakAll'><span className='text-white mr-1'>Cluster: </span>
                                                                                                {item.cluster_name}
                                                                                            </p>
                                                                                            {item.asset_name ? 
                                                                                                <p className='mb-1 text-primary-color wordBreakAll'><span className='text-white mr-1'>Asset: </span>
                                                                                                    {item.asset_name}
                                                                                                </p>
                                                                                            : null}
                                                                                            <p className='mb-1 text-primary-color'>
                                                                                                <span className='text-white mr-1'>Resources: </span>
                                                                                                {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                                                                {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                                                                {item.region ?  (' : ')+getRegionName(item.region, state.regions) : <span>&nbsp;</span>}
                                                                                            </p>
                                                                                            <p className='mb-1 text-primary-color'>
                                                                                                <span className='text-white mr-1'>Services: </span>
                                                                                                {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                                                                {/* {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>} */}
                                                                                            </p>
                                                                                            <div className='d-flex flex-wrap'>
                                                                                                {item.alerts ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Alerts {item.alerts}</span>
                                                                                                : null}
                                                                                                {item.anomalies ?
                                                                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>Anomalies {item.anomalies}</span>
                                                                                                : null}
                                                                                            </div>
                                                                                            
                                                                                        </div>    
                                                                                    </UncontrolledTooltip>
                                                                                    {state[state.selectedLeftOption+'_cluster_'+item.cluster_name] && item.head_node ? 
                                                                                        <div className={`${item.processes && item.processes.length ? 'bg-dark2' : ''} p-3 ml-2`}>
                                                                                            <div className={`pl-1 mb-2 ${state[item.cluster_name+'_head_node'] ? '' : ''} `} onClick={() => setState(prevState => ({ ...prevState, showDetailsLoading: true, showMetricLoading: true,  metricDetails: [], }), onExpandCollapseClusterChild('headNode', row, item))}>
                                                                                                <div className={`d-flex cursorPointer ${item.processes && item.processes.length ? 'text-black' : 'text-cyon'}`}>
                                                                                                    {item.processes && item.processes.length ?
                                                                                                        state[item.cluster_name+'_head_node'] ? 
                                                                                                            <i className='far fa-minus f12 mr-2 align-self-center text-white'/>
                                                                                                        : 
                                                                                                            <i className='far fa-plus f12 mr-2 align-self-center text-white'/>
                                                                                                    : null}
                                                                                                    <p className={`mb-0 ${item.processes && item.processes.length && state[item.cluster_name+'_head_node'] ? 'text-cyon' : 'text-white'}`} >Head Node</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            {state[item.cluster_name+'_head_node'] && item.processes ?
                                                                                                <div className='mb-2 ml-2 p-2 bg-black'>
                                                                                                    {item.processes.map(prc => {
                                                                                                        return(
                                                                                                        <div key={"clu_"+prc} className='d-flex pl-3 mr-1' onClick={() => onClickClusterProcess('process', prc)}>
                                                                                                            <p className={`hiddenEllipses m-0 pr-1 pt-2 pb-1 f13 ${state.selectedProcess === prc ? 'text-cyon' : 'text-white'}`}>
                                                                                                                {prc}
                                                                                                                <span className='ml-1 text-primary-color'>(process)</span>
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            : null}
                                                                                        
                                                                                            {item.queues && item.queues.length ?
                                                                                                <div className={`pl-1 mb-2 mt-2 d-flex cursorPointer ${item.processes && item.processes.length ? 'text-black' : 'text-cyon'}`} onClick={() => setState(prevState => ({ ...prevState, showDetailsLoading: false }), onExpandCollapseClusterChild('queueSection', row, item))}>
                                                                                                    {state[item.cluster_name+'_queue_section'] ? 
                                                                                                        <i className='far fa-minus f12 mr-2 align-self-center text-white'/>
                                                                                                    : 
                                                                                                        <i className='far fa-plus f12 mr-2 align-self-center text-white'/>
                                                                                                    }
                                                                                                    <p className={`mb-0 ${item.processes && item.processes.length && state[item.cluster_name+'_queue_section'] ? 'text-cyon' : 'text-white'}`}>Queues</p>
                                                                                                </div>
                                                                                            : null}
                                                                                            {state[item.cluster_name+'_queue_section'] && item.queues && item.queues.length ?
                                                                                                <div className='mb-2 ml-2 p-2 bg-black'>
                                                                                                    {item.queues.map(que => {
                                                                                                        return(
                                                                                                        <div key={"que_"+que.name} onClick={() => onClickClusterProcess('queue', que)}>
                                                                                                        <div className='d-flex pl-3 mr-1'>
                                                                                                            <p className={`hiddenEllipses m-0 f-20 pl pr-1 pt-2 pb-1 ${state.selectedQueue === que.queue_name ? 'text-cyon' : 'text-white'}`}>
                                                                                                                {que.queue_name}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                        
                                                                                                        {que.asset_name ? 
                                                                                                            <div className={`d-flex flex-wrap mb-1 pl-3 mr-1 ${state.selectedQueue === que.queue_name ? 'text-cyon' : 'text-white'}`}>
                                                                                                                <p className={`mb-1 wordBreakAll`}>{que.asset_name}</p>
                                                                                                            </div>
                                                                                                        : null}
                                                                                                        </div>
                                                                                                        )
                                                                                                    })}
                                                                                                </div>
                                                                                            : null}
                                                                                        </div>
                                                                                    : null}
                                                                                </div>
                                                                            </div>
                                                                        : null}
                                                                        </div>
                                                                    )
                                                                })
                                                            : 
                                                                <div className='d-flex justify-content-center m-4'>
                                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                                </div>
                                                            }
                                                            </React.Fragment>
                                                        :
                                                            <div className='d-flex justify-content-center m-4'>
                                                                <Spinner className='text-center' color='light' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>                                        
                    <div className={`col-sm-4 mt-2 px-1`}>
                        <div className='rounded bg-black p-2 overflowYAuto vh100'>
                            {state.selectedAsset && state.selectedAsset.asset_name ?
                                <div className='d-flex justify-content-between mb-1'>
                                    <p className='mb-1'>Showing data in 
                                        {/* <span className='text-cyon ml-1'>
                                            {state.endTime && state.startTime ?
                                                momentDateGivenFormat(state.startTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.endTime, 'DD MMM YYYY HH:mm')
                                            : null}
                                        </span> */}
                                        <span className='ml-1'>(local time)</span>
                                    </p>
                                    <div className="d-flex">
                                        <label className='mb-0 mr-2 align-self-center'>Duration</label>
                                        <div style={{ minWidth: "130px" }}>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                className='f13 p-0 filterSelectDrop'
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                }}
                                                value={{
                                                    value: state.selectedGraphDuration ? state.selectedGraphDuration : 'Select',
                                                    label: state.selectedGraphDuration ? HOURLY_CUSTOM_DURATION_OPTIONS.filter(e => e.value === state.selectedGraphDuration)[0].option : <span className='placeholder'>Select</span>
                                                }}
                                                options={HOURLY_CUSTOM_DURATION_OPTIONS && HOURLY_CUSTOM_DURATION_OPTIONS.map(row => ({
                                                    value: row.value,
                                                    label: row.option,
                                                }))}
                                                onChange={event => {
                                                    if(event.value !== 'custom') {
                                                        setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true, selectedGraphDuration: event.value }))
                                                    } else {
                                                        setState(prevState => ({ ...prevState, showDateRangePicker: true }))
                                                    }                                                    
                                                }}
                                            />
                                            {state.showDateRangePicker ?                                                
                                                <div className='headerDateRange mt-2' style={{'z-index': '999999999','position': 'absolute','right': '10px'}} ref={dateRef}>
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={handleSelect}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={dateState}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            :
                                <p className='f12 m-3 align-self-center'>Please select an asset to get the Metric details</p>
                            }
                            <div className='d-flex justify-content-between mb-1'>
                                {state.selectedAsset && state.selectedAsset.id_name_map ?
                                    <span className='text-cyon'>
                                        <span className='mr-1'>{state.selectedProcess ? state.selectedProcess : (state.selectedQueue ? state.selectedQueue : "")}</span>
                                        {(state.selectedAsset.id_name_map.value ? state.selectedAsset.id_name_map.value : state.selectedAsset.image_id)}
                                        <span className={`ml-1 text-cyon mb-0`}>
                                            {state.selectedAsset.id_name_map.key ? ' : '+state.selectedAsset.id_name_map.key : ''}
                                        </span>
                                    </span>
                                : state.selectedAsset && state.selectedAsset.asset_name ?
                                    <span className={`ml-1 text-cyon mb-0`}>
                                        <span className='mr-1'>{state.selectedProcess ? state.selectedProcess : (state.selectedQueue ? state.selectedQueue : "")}</span>
                                        {state.selectedAsset.asset_name}
                                    </span>
                                : null}
                                {/* {state.selectedAsset && state.selectedAsset.asset_name ?
                                    <div className={`rounded mr-2 bg-black text-cyon px-2 py-1 f12 hiddenEllipses align-self-center`} id={`metricsTitle`}>
                                        {state.selectedAsset.asset_name}
                                    </div>
                                : null} */}
                            </div>
                            {state.selectedAsset && Object.entries(state.selectedAsset).length && !state.showMetricLoading ?
                                <MetricsTab
                                    metricDetails={state.metricDetails}
                                    selectedDetails={state.selectedAsset}
                                    height={115}
                                />
                            : state.selectedAsset && state.showMetricLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='light' />
                                </div>
                            : null}
                        </div>
                    </div>
                    <div className={`col-sm-5 mt-2 pl-1 pr-2`}>
                        <div className='bg-dark3 rounded px-3 pb-3 overflowYAuto vh100'>
                            <div className='w-100'>
                                <ul className={`row nav nav-tabs border-bottom bg-dark3 shadow-none justify-content-between`} id='myTab' role='tablist'>
                                    <span className={`d-flex ${state.selectedLeftOption ? '' : 'disabled' }`}>
                                        <li className='nav-item'>
                                            <span className={`nav-link text-white cursorPointer ${state.pageType === 'Details' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, pageType: 'Details', menuDisabled: true }), onChangePageType())}>
                                                Details
                                            </span>
                                        </li>
                                        <li className='nav-item'>
                                            <span className={`nav-link text-white cursorPointer ${state.pageType === 'Alerts' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, pageType: 'Alerts', menuDisabled: true }),  onChangePageType())}>
                                                Alerts 
                                                {state.selectedAsset && state.selectedAsset.alerts ?
                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>{state.selectedAsset.alerts}</span>
                                                : null} 
                                            </span>
                                        </li>
                                        {/* <li className='nav-item'>
                                            <span className={`nav-link text-black cursorPointer ${state.pageType === 'Events' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, pageType: 'Events', menuDisabled: true }),  onChangePageType())}>
                                                Events
                                                {state.selectedAsset && state.selectedAsset.events ?
                                                    <span className='badge risk-badge-critical f10 mx-1 align-self-center py-1'>{state.selectedAsset.events}</span>
                                                : null} 
                                            </span>
                                        </li> */}
                                        <li className='nav-item displayNone'>
                                            <span className={`nav-link text-white cursorPointer ${state.pageType === 'Troubleshooting' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, pageType: 'Troubleshooting', menuDisabled: true }))}>
                                                Troubleshooting
                                            </span>
                                        </li>
                                    </span>
                                </ul>                                                
                                {state.pageType === 'Details' ?
                                    state.showDetailsLoading ? 
                                        <div className='d-flex justify-content-center m-4'>
                                            <Spinner className='text-center' color='light' />
                                        </div>
                                    : ((state.selectedLeftOption === 'Disk' && state.diskDetails && Object.entries(state.diskDetails).length) || (state.selectedLeftOption === 'Workstation' && state.selectedAsset && Object.entries(state.selectedAsset).length) || (state.selectedLeftOption === 'Cluster' && state.selectedAsset && Object.entries(state.selectedAsset) && state.selectedCluster && Object.entries(state.selectedCluster).length)) ?
                                        <DetailsTab
                                            selectedLeftOption={state.selectedLeftOption}
                                            selectedData= {state.selectedLeftOption === 'Disk' ? state.diskDetails : state.selectedAsset}
                                            selectedClusterDetails={state.selectedCluster}
                                            detailsFormat={state.detailsFormat}
                                        />
                                    :
                                        <div className='d-flex justify-content-center m-4'>
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>                                                        
                                : state.pageType === 'Alerts' ?
                                    <AlertsTab 
                                        selectedLeftOption={state.selectedLeftOption}
                                        selectedData= {state.selectedLeftOption === 'Disk' ? state.diskDetails : state.selectedAsset}
                                        selectedClusterDetails={state.selectedCluster}
                                        menuDisabled={() => setState({ menuDisabled: false })}
                                    />
                                : state.pageType === 'Events' ?
                                    <React.Fragment></React.Fragment>
                                    // <EventsTab 
                                    //     menuDisabled={() => setState({ menuDisabled: false })}
                                    // />
                                : state.pageType === 'Troubleshooting' ?
                                    <TroubleshootTab 
                                        selectedLeftOption={state.selectedLeftOption}
                                        selectedData={state.selectedAsset}
                                        selectedCluster={state.selectedCluster}
                                        // menuDisabled={() => setState({ menuDisabled: false })}
                                    />
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default LandingPage