/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file UserDetails.js
 * @author Prakash // on 27/11/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listUserDetails } from '../../../actions/commonActionNew'
import { getUserCostBreakup } from '../../../actions/cost/CostAction'

import ApexMixedBarLineChart from '../../common/charts/ApexMixedBarLineChart';
import ApexDonutChart from '../../common/charts/ApexDonutChart';
import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';
import ApexBarChart from '../../common/charts/ApexBarChart';
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import DetailsSidePanel from './DetailsSidePanel';
import { capitalizeTheFirstLetterOfEachWord, currentUTCtime, momentDateGivenFormat, removeUnderScore, thousandSeparator } from '../../../utils/utility';

import StickyDarkThemeTable from '../../designComponents/Table/StickyDarkThemeTable';
import moment from 'moment';
import { Spinner } from 'reactstrap';
import { CSVLink } from 'react-csv';

const UserDetails = (props) => {
    const [state, setState] = useState({
        showLoading: true,

        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 5,

        userDetails: [],
        selectedUserDetails: {},
        filteredUserDetails: [],
        callSearch: true,
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"}
		],
        graphOrder: ['cpus', 'cpu_hours', 'nodes', 'node_hours', 'jobs'],
        graphOrderColors: [
            ['#854097'],
            ['#693EBC'],
            ['#775BA2'],
            ['#9A77D1'],
            ['#039BE5', '#43B02A', '#F75E3F']
        ],
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            params.spendboard = true
            params.start_time = props.startDate
            params.end_time = props.endDate
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedClusters && props.selectedClusters.length) {
                params.cluster_name = props.selectedClusters
            }
            
            if(props.selectedUser && props.selectedUser.length) {
                params.user_name = props.selectedUser
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            params.size = 5

            dispatch(listUserDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextToken && state.nextToken.length) {
                            totalResults = state.userDetails &&  state.userDetails.length ? state.userDetails : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(results && results.length) {
                            totalResults = totalResults.concat(results)
                        }

                        let totalPages = 1
                        if(totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
                        
                        let responseTypes = response && response.results && response.results.length && response.results[0].data && Object.keys(response.results[0].data).length ? Object.keys(response.results[0].data) : [] 
                        let topStatusType = ['Budget/Spend']
                        topStatusType = [...topStatusType, ...responseTypes]
                        
                        setState(prevState => ({ ...prevState, userDetails: totalResults, filteredUserDetails: totalResults, topStatusType, detailsFormat: response && response.user_details ? response.user_details : [], showLoading: false, callBudgetSpendTrendSection: true, callSpendTrendSection: true, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));

                        props.callBack()
                    }
                })
        }
    }, [state.callSearch, dispatch, state.perPage, props, state.userDetails, state.currentPage, state.nextToken, state.startRecord])

    useEffect(() => {
        if(state.callBudgetSpendTrendSection) {
            setState(prevState => ({ ...prevState, callBudgetSpendTrendSection: false }))
            state.filteredUserDetails.forEach((item, index) => {
                if(item.trend) {
                    let budgetSpendTrendData = item.trend ? item.trend : []
                    //horizontal spend and budget bar
                    let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                    let labels = ['Budget', 'Spend']
                    let dataUnit = ''
                
                    let horiBarData = {}
                    horiBarData.data = data
                    horiBarData.labels = labels        
                    horiBarData.unit = dataUnit
                    // horiBarData.tooltipValues = tooltip
                    
                    // setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData }))
                
                    
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
                    graphData['Budget'] = budgetSpendTrendData.budget
                    graphData['Spend'] = budgetSpendTrendData.spend
                    
                    setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData, ["budgetSpendBarLineGraphData_"+index]: graphData }))
                }
            })
        }
    }, [state.callBudgetSpendTrendSection, state.filteredUserDetails])    

    useEffect(() => {
        if(state.callSpendTrendSection) {
            setState(prevState => ({ ...prevState, callSpendTrendSection: false }))

            state.filteredUserDetails.forEach((item, index) => {
                if(item.breakdown) {
                    let spendStackedTrend = item.breakdown
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
            
                    Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                        graphData[key] = value
                    })

                    let spendDonutResponse = item.breakdown && item.breakdown.spend_total ? item.breakdown.spend_total : {}
    
                    let donut = []
                    let donut_total_count = 0				
                    spendDonutResponse && Object.entries(spendDonutResponse).forEach(([key, value]) => {
                        let donutRow = {}
                        donutRow.item_count = value
                        donutRow.item_name = key
                        donut.push(donutRow)
                        donut_total_count += value 
                    })

                    
            
                    let spendDonutData = {
                        'items': donut,
                        'label': 'Total',
                        'total_count': donut_total_count
                    }
                    // colors={['#854097', '#693EBC', '#9A77D1', '#B05B6B']}
                    let trendBreakDownColor = ['purple3', 'purple', 'dark-theme-purple', 'high']
                    
                    setState(prevState => ({ ...prevState, ["spendDonutData_"+index]: spendDonutData, ['spendStackedTrendData_'+index]: graphData, ["trendBreakDownTotal_"+index]: spendDonutResponse, trendBreakDownColor }))
                }
            })
        }
    }, [state.callSpendTrendSection, state.filteredUserDetails])

    const onChangePeriod = (period, selectedPeriodIndex, item) => {

        setState(prevState => ({ ...prevState, 
            ["budgetSpendData_"+selectedPeriodIndex]: {}, 
            ["budgetSpendBarLineGraphData_"+selectedPeriodIndex]: {}, 
            ["spendDonutData_"+selectedPeriodIndex]: {}, 
            ['spendStackedTrendData_'+selectedPeriodIndex]: {}, 
            ["trendBreakDownTotal_"+selectedPeriodIndex]: [],
        }))

        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        params.spendboard = true
        if(props.selectedAccount && props.selectedAccount.length) {
            params.account_id = props.selectedAccount
        }
        if(props.selectedRegion && props.selectedRegion.length) {
            params.region = props.selectedRegion
        }
        if(props.selectedClusters && props.selectedClusters.length) {
            params.cluster_name = props.selectedClusters
        }
        if(period) {
            params.duration_aggregate_by = period
        }
        if(item.userId) {
            params.requested_user_id = item.userId
        }

        dispatch(listUserDetails(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        selectedClusterDetails: results,
                        selectedFilteredClusterDetails: results,
                        callSelectedBudgetSpendTrendSection: true,
                        selectedPeriodIndex
                    }));
                }
        })
    }

    useEffect(() => {
        if(state.callSelectedBudgetSpendTrendSection) {
            setState(prevState => ({ ...prevState, callSelectedBudgetSpendTrendSection: false }))
            state.selectedFilteredClusterDetails.forEach(item => {
                
                if(item.trend) {
                    let budgetSpendTrendData = item.trend ? item.trend : []
                    //horizontal spend and budget bar
                    let data = [budgetSpendTrendData && budgetSpendTrendData.budget_total ? budgetSpendTrendData.budget_total : 0, budgetSpendTrendData && budgetSpendTrendData.spend_total ? budgetSpendTrendData.spend_total : 0]
                    let labels = ['Budget', 'Spend']
                    let dataUnit = ''
                
                    let horiBarData = {}
                    horiBarData.data = data
                    horiBarData.labels = labels        
                    horiBarData.unit = dataUnit
                    // horiBarData.tooltipValues = tooltip
                    
                    // setState(prevState => ({ ...prevState, ["budgetSpendData_"+index]: horiBarData }))
                
                    
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
                    graphData['Budget'] = budgetSpendTrendData.budget
                    graphData['Spend'] = budgetSpendTrendData.spend
                    
                    setState(prevState => ({ ...prevState, ["budgetSpendData_"+state.selectedPeriodIndex]: horiBarData, ["budgetSpendBarLineGraphData_"+state.selectedPeriodIndex]: graphData }))
                }
                
                if(item.breakdown) {
                    let spendStackedTrend = item.breakdown
                    let graphData = {}
                    graphData.labels = item.dates ? item.dates : []
            
                    Object.entries(spendStackedTrend).length && spendStackedTrend.spend && Object.entries(spendStackedTrend.spend).forEach(([key, value]) => {
                        graphData[key] = value
                    })

                    let spendDonutResponse = item.breakdown && item.breakdown.spend_total ? item.breakdown.spend_total : {}
    
                    let donut = []
                    let donut_total_count = 0				
                    spendDonutResponse && Object.entries(spendDonutResponse).forEach(([key, value]) => {
                        let donutRow = {}
                        donutRow.item_count = value
                        donutRow.item_name = key
                        donut.push(donutRow)
                        donut_total_count += value 
                    })
            
                    let spendDonutData = {
                        'items': donut,
                        'label': 'Total',
                        'total_count': donut_total_count
                    }
                    
                    setState(prevState => ({ ...prevState, ["spendDonutData_"+state.selectedPeriodIndex]: spendDonutData, ['spendStackedTrendData_'+state.selectedPeriodIndex]: graphData, ["trendBreakDownTotal_"+state.selectedPeriodIndex]: spendDonutResponse }))
                }
            })
        }

    }, [state.callSelectedBudgetSpendTrendSection, state.selectedFilteredClusterDetails, state.selectedPeriodIndex])

    const getExpandedItemDetails = (period, item, index) => {
        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        // params.spendboard = true
        params.duration_aggregate_by = period
        if(item.account_id && item.account_id.length) {
            params.account_id = item.account_id
        }
        if(item.region && item.region.length) {
            params.region = item.region
        }
        if(item.cluster_name && item.cluster_name.length) {
            params.cluster_name = item.cluster_name
        }
        if(item.userId) {
            params.requested_user_id = item.userId
        }

        dispatch(getUserCostBreakup(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        ['selectedItemDetails_'+index]: results,
                        selectedItemIndex: index,                        
                        callSelectedBreakDownDetail: true
                    }));
                }
        })

    }

    useEffect(() => {
        if(state.callSelectedBreakDownDetail) {
            setState(prevState => ({ ...prevState, callSelectedBreakDownDetail: false, ['showLoading_'+state.selectedItemIndex]: false })) 
            if(state['selectedItemDetails_'+state.selectedItemIndex] && state['selectedItemDetails_'+state.selectedItemIndex].length) {
                var trendTable = []
                state['selectedItemDetails_'+state.selectedItemIndex].forEach((item, index) => {

                    let budgetSpendTrendData = item.data ? item.data : []
                    let jobsData = {}
                    jobsData.labels = item.dates ? item.dates : []
                    jobsData.total = budgetSpendTrendData.hasOwnProperty('jobs') ? budgetSpendTrendData['jobs'] : []
                    jobsData.success = budgetSpendTrendData.hasOwnProperty('success') ? budgetSpendTrendData['success'] : []
                    jobsData.failed = budgetSpendTrendData.hasOwnProperty('failed') ? budgetSpendTrendData['failed'] : []
                    
                    let nodesData = {}
                    nodesData.labels = item.dates ? item.dates : []
                    nodesData.nodes = budgetSpendTrendData.hasOwnProperty('nodes') ? budgetSpendTrendData['nodes'] : []

                    let vcpusData = {}
                    vcpusData.labels = item.dates ? item.dates : []
                    vcpusData.vxpus = budgetSpendTrendData.hasOwnProperty('cpus') ? budgetSpendTrendData['cpus'] : []

                    let cpuHours = {}
                    cpuHours.labels = item.dates ? item.dates : []
                    cpuHours.cpu_hours = budgetSpendTrendData.hasOwnProperty('cpu_hours') ? budgetSpendTrendData['cpu_hours'] : []

                    let nodeHours = {}
                    nodeHours.labels = item.dates ? item.dates : []
                    nodeHours.node_hours = budgetSpendTrendData.hasOwnProperty('node_hours') ? budgetSpendTrendData['node_hours'] : []

                    setState(prevState => ({ 
                        ...prevState, 
                        ["nodes_"+state.selectedItemIndex]: nodesData,
                        ["cpus_"+state.selectedItemIndex]: vcpusData,
                        ["cpu_hours_"+state.selectedItemIndex]: cpuHours,
                        ["node_hours_"+state.selectedItemIndex]: nodeHours,
                        ["jobs_"+state.selectedItemIndex]: jobsData,                        
                    }))

                    let labels = item.dates ? item.dates : []
                    if(item.breakdown && item.breakdown.spend && Object.entries(item.breakdown.spend).length) {
                        Object.entries(item.breakdown.spend).forEach(([key, value]) => {
                            var result = {
                                "Title": key,
                                'total': item.breakdown.spend_total && item.breakdown.spend_total[key] ? item.breakdown.spend_total[key] : 0
                            }
                            
                            value.forEach((vItem, vIndex) => {
                                result[labels[vIndex]] = vItem
                            })
                            trendTable.push(result);
                        })
                    } else if(item.data && Array.isArray(item.data)) {
        
                        let data = item.data
                        // let totalCount = item.data.reduce(function(a, b){
                        // 	return a + b;
                        // }, 0);
                        var result = {
                            "Title": "Cost",
                            'total': item.total
                        }
                        
                        data.forEach((item,index) => {
                            if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                result[labels[index]] = item
                            }
                        })
                        
                        if(item.forecast && item.forecast.dates) {
                            item.forecast && item.forecast.dates.forEach((lab, index) => {					
                                result[lab] = item.forecast.data[index]
                            })
                        }
        
                        trendTable.push(result);
                    }
                })
                
                if(trendTable.length) {
                    let userHeaders = ['Title', 'total']
                    const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
                        let sticky = false
                        let header = ''
                        let width = '100%'
                        let textColor = ''
                        if(tKey === 'Title') {
                            sticky = true
                            header = tKey
                            width = 300
                        } else if(tKey === 'total') {
                            sticky = true
                            header = 'Total'
                            width = 75
                        } else {
                            if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
                                textColor = "text-info"
                            }
                            // if(state.granualrity === 'monthly') {
                            //     header = moment(tKey).format('MMM YY')
                            //     width = 75
                            // } else  if(state.granualrity === 'daily') {
                            //     header = moment(tKey).format('MMM DD')
                            //     width = 75
                            // } else  {
                            header = moment(tKey).format('MMM DD')
                            width = 75
                            userHeaders.push(tKey)
                            // }
                        }
                        return {
                            Header: header,
                            accessor: tKey,
                            width: width,
                            sticky: sticky ? "left" : "",
                            textColor: textColor,
                            className: 'text-truncate text-right'
                        }
                    })
                    
                    setState(prevState => ({ ...prevState, ['trendTable_'+state.selectedItemIndex]: trendTable, ['filteredArray_'+state.selectedItemIndex]: trendTable, ['columns_'+state.selectedItemIndex]: columns, userHeaders }))
                } else {
                    const columns = []	
                    setState(prevState => ({ ...prevState, ['trendTable_'+state.selectedItemIndex]: trendTable, ['filteredArray_'+state.selectedItemIndex]: trendTable, ['columns_'+state.selectedItemIndex]: columns }))
                }
            }
        }
    }, [state.callSelectedBreakDownDetail, state])

    

    useEffect(() => {
        if(state.refreshClusters) {
            setState(prevState => ({ ...prevState, refreshClusters: false }))
        }
    }, [state.refreshClusters])

    const handleClickOutside = (event) => {}

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callSearch: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const gotoPage = (pageNumber) => {
        // Go to the specified page and update state        
        if(pageNumber > 0  && pageNumber <= state.totalPages) {
            let startRecord = (pageNumber - 1) * state.perPage
            setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
        } else  if(pageNumber > state.totalPages) {
            setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
        } else {
            setState(prevState => ({ ...prevState, pageNumber }))
        }
    }

    return (
        <div className='bg-transparent' onClick={handleClickOutside}>
            {state.showSidePanel ? 
                <DetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showSidePanel: false, selectedDetails: {}, selectedType: '' }))}                    
                    selectedProvider={props.selectedProvider}
                    selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedDetails={state.selectedDetails}
                    selectedType={state.selectedType}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            <div className='overflowYAuto vh100'>
                <div className='d-flex justify-content-between'>
                    <p className='mb-0 align-self-center'>
                        <small>Showing {state.filteredUserDetails && state.filteredUserDetails.length} out of total {state.userDetails && state.userDetails.length} user(s)</small>
                    </p>
                    {state.totalRecords > state.perPage ?
                        <div className='d-flex justify-content-end'>
                            <div className='pagination errorPagePagination f12 mt-2'>
                                <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                <span className='displayNone'>
                                    Go to
                                    <input
                                        type='number'
                                        value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) : ''
                                            gotoPage(page)
                                        }}
                                        className='ml-1 inputClass'
                                    />
                                </span>
                                <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button>
                                <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                            </div>
                        </div>
                    : null}
                </div>
                {state.filteredUserDetails && state.filteredUserDetails.length ?
                    state.filteredUserDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, index) => {
                        return(
                            <div className='bg-dark3 mb-2 pb-2' key={"row_"+index}>
                                <div className={`d-flex`}>
                                    <div className="col-lg-2 col-md-2 col-sm-2 pr-0">
                                        <div className='py-2'>
                                            <div className="d-flex">
                                                <div className="py-1 w-100">
                                                    <p className="b-block mb-0">Name</p>
                                                    <p className="mb-0 text-white">
                                                        { item.userName ? item.userName : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex pt-2">
                                                <div className="py-1 w-100">
                                                    <p className="b-block mb-0">Id</p>
                                                    <p className="mb-0 text-white">{item.userId ? item.userId : ''}</p>
                                                </div>
                                            </div>                                        
                                            <div className="d-flex pt-2">
                                                <div className="py-1 w-100">
                                                    <p className="b-block mb-0">Organization</p>
                                                    <p className="mb-0 text-white">{item.organization ? item.organization : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="px-4 py-2">
                                            <div className='d-flex justify-content-between'>
                                                <div className='align-self-center'>
                                                    {item.user_count ?
                                                        <p className='mb-2'>
                                                            Slum Version <span className='text-white ml-2'>{item.slum_version}</span>
                                                        </p>
                                                    : null}
                                                    {item.user_count ?
                                                        <p className='mb-2'>
                                                            Users <span className='text-white ml-2'>{item.user_count}</span>
                                                        </p>
                                                    : null}
                                                    {item.description ?
                                                        <p className='mb-2'>
                                                            Description <span className='text-white ml-2'>{item.description}</span>
                                                        </p>
                                                    : null}
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 pr-0 cursor-pointer">
                                        {state["budgetSpendData_"+(state.startRecord + index)] && Object.entries(state["budgetSpendData_"+(state.startRecord + index)]).length ?
                                            <div className='mt-n2 mb-n2 mr-4 align-self-center'> 
                                                <ApexBarChart
                                                    graphData={state["budgetSpendData_"+(state.startRecord + index)]}
                                                    sparkline={false}
                                                    yaxis={true}
                                                    yaxisLabel={true}
                                                    xaxis={false}
                                                    xaxisFormat={'string'}
                                                    xaxisLabel={false}
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={0}
                                                    legend={false}
                                                    legendPostion={'bottom'}
                                                    legendHorizontalAlign={'center'}
                                                    legendUseSeriesColor={true}
                                                    stacked={false}
                                                    height={200}
                                                    horizontal={true}
                                                    barHeight={'40%'}
                                                    barEndShape={'rounded'}
                                                    columnWidth={'25%'}
                                                    gradient={false}
                                                    gradientColor={['#009fdf', '#CD5A9F']}
                                                    hideTooltipValue={true}
                                                    backgroundBarShape={'rounded'}
                                                    backgroundBarColors={['#333947']}
                                                    showBackgroundBarColors={false}
                                                    className={'transparentTooltip'}
                                                    dataLabels= {true}
                                                    dataLabelsTextAnchor = {'end'}
                                                    dataLabelPosition={'top'}
                                                    colors={['#43b02a', '#009FDF']}
                                                    dataLabelsOffsetX={0}
                                                    chartDistributed={true}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                    <div className="col-lg-2 col-md-2 col-sm-2 pr-0 cursor-pointer">
                                        <div className='d-flex'>
                                            {state['budgetSpendBarLineGraphData_'+(state.startRecord + index)] && Object.entries(state['budgetSpendBarLineGraphData_'+(state.startRecord + index)]).length ?
                                                <React.Fragment>
                                                    <div className='mb-n3'>
                                                        <ApexMixedBarLineChart 
                                                            graphData={state['budgetSpendBarLineGraphData_'+(state.startRecord + index)]}
                                                            sparkline={false}
                                                            yaxis={true}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'datetime'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#666666'}
                                                            paddingLeft={-25}
                                                            legend={true}
                                                            legendPostion={'bottom'}
                                                            legendHorizontalAlign={'center'}
                                                            legendUseSeriesColor={true}
                                                            stacked={false}
                                                            height={200}
                                                            horizontal={false}
                                                            barHeight={'40%'}
                                                            barEndShape={'flat'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            gradientColor={['#039BE5', '#5F5BA2']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'flat'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={'transparentTooltip'}
                                                            colors={['#43b02a', '#009FDF']}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-5 col-sm-5 pr-0 cursor-pointer">
                                        <div className='d-flex'>
                                            <div className='align-self-center mr-4 mt-3'>
                                                {state['spendDonutData_'+(state.startRecord + index)] && Object.entries(state['spendDonutData_'+(state.startRecord + index)]).length ? 
                                                    <ApexDonutChart
                                                        labelColor={"#999999"}
                                                        valueColor={"#999999"}
                                                        // labels={['Jobs', 'Workstation', 'Disk', 'Storage']}
                                                        graphData={state['spendDonutData_'+(state.startRecord + index)]}
                                                        legend={false}
                                                        showTotal={true}
                                                        showTotalLable={true}
                                                        showGraphHeading={false}
                                                        height={120}
                                                        width={120}
                                                        legendWidth={200}
                                                        size={'70%'}
                                                        gradient={false}
                                                        gradientColor={['#A88CCC', '#D88ACF']}
                                                        // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                        // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                        colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                        className={'transparentTooltip'}
                                                    />
                                                : null}
                                            </div>                                        
                                            {state['spendStackedTrendData_'+(state.startRecord + index)] && Object.entries(state['spendStackedTrendData_'+(state.startRecord + index)]).length ?
                                                <React.Fragment>
                                                    <div className='mt-n3 mb-n3 mr-2 w-75'>
                                                        <ApexStackedBarChart 
                                                            graphData={state['spendStackedTrendData_'+(state.startRecord + index)]}
                                                            sparkline={false}
                                                            yaxis={false}
                                                            yaxisLabel={true}
                                                            xaxis={true}
                                                            xaxisFormat={'datetime'}
                                                            xaxisLabel={true}
                                                            axisLabelColor={'#666666'}
                                                            paddingLeft={-25}
                                                            legend={true}
                                                            stacked={true}
                                                            height={210}
                                                            horizontal={false}
                                                            // barHeight={'40%'}
                                                            barEndShape={'flat'}
                                                            columnWidth={'25%'}
                                                            gradient={true}
                                                            stroke={'hide'}
                                                            // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                            // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                            colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                            hideTooltipValue={true}
                                                            backgroundBarShape={'flat'}
                                                            backgroundBarColors={['#333947']}
                                                            showBackgroundBarColors={false}
                                                            className={'transparentTooltip'}
                                                        />
                                                    </div>
                                                    <ul className="stock-legend w-25 align-self-center mb-0 mt-3">
                                                        {state["trendBreakDownTotal_"+(state.startRecord + index)] && Object.values(state["trendBreakDownTotal_"+(state.startRecord + index)]).map((value, tIndex) => {
                                                        return(
                                                            <li key={'trend_'+tIndex} className='d-flex mb-2'><span className={`legend-circle bg-${state.trendBreakDownColor[tIndex]}`}></span> <span className="pt-1 ml-2 text-muted d-inline-block"> &nbsp;{thousandSeparator(value)}</span></li>
                                                            )
                                                        })}
                                                    </ul>
                                                </React.Fragment>
                                            : null}
                                        </div>
                                    </div>                                                
                                    <div className="col-lg-1 col-md-1 col-sm-1 pr-0 cursor-pointer align-self-center">
                                        <div className={`transparentSelectBorderBottom mx-2 w-70 mb-4 d-flex`}>
                                            {/* <small className=''>Show </small> */}
                                            <select className="form-control" 
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    setState((prevState) => ({
                                                        ...prevState,
                                                        ['selected_Period_' + (state.startRecord + index)]: selectedValue,
                                                        ['showLoading_'+(state.startRecord + index)]: state['showExpandedSectoin_'+(state.startRecord + index)]
                                                    }));
                                                    onChangePeriod(selectedValue, (state.startRecord + index), item);
                                                    if(state['showExpandedSectoin_'+(state.startRecord + index)]) {
                                                        getExpandedItemDetails(selectedValue, item, (state.startRecord + index));
                                                    }

                                                    // Use the selectedValue directly in the callback to avoid potential issues

                                                }}
                                                value={state['selected_Period_'+(state.startRecord + index)]}
                                            >
                                                {state.periodOptions && state.periodOptions.map(item => {
                                                    return(
                                                        <option
                                                            key={'opt_'+item.value} 
                                                            value={item.value}
                                                        >{item.label}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <p className='mb-0 d-flex justify-content-center text-cyon' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'User', showSidePanel: true }))}>Details</p>
                                        {!state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                            <p className='fa fa-expand-wide text-cyon d-flex justify-content-center mt-3' 
                                                onClick={() => {
                                                    setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: true, ['showLoading_'+(state.startRecord + index)]: true }))
                                                    getExpandedItemDetails(state['selected_Period_' + (state.startRecord + index)], item, (state.startRecord + index))
                                                }} 
                                            />
                                        :
                                            <p className='fa fa-compress text-cyon d-flex justify-content-center mt-3' onClick={() => setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: false }))} />
                                        }
                                    </div>
                                </div>
                                {state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                    state['showLoading_'+(state.startRecord + index)] ?
                                        <div className='bg-dark px-2 py-3 mx-3 mt-2'>
                                            <div className='d-flex justify-content-center m-4'>
                                                <Spinner className='text-center' color='light'   />
                                            </div>
                                        </div>
                                    :
                                        <div className='bg-dark px-2 py-3 mx-3 mt-2'>
                                            {state['selectedItemDetails_'+(state.startRecord + index)] && state['selectedItemDetails_'+(state.startRecord + index)].length && state['selectedItemDetails_'+(state.startRecord + index)][0].data && Object.entries(state['selectedItemDetails_'+(state.startRecord + index)][0].data).length ?
                                                <div className="col-lg-12 col-md-12 col-sm-12 pr-0">
                                                    <div className='d-flex flex-wrap justify-content-start'>
                                                        {state.graphOrder && state.graphOrder.map((order, oIndex) => {
                                                            return(
                                                                <div key={'order_'+oIndex} className="col-lg-2 col-md-2 col-sm-2 pl-0 pr-2 mb-2">
                                                                    {state[order+'_'+(state.startRecord + index)] && Object.entries(state[order+'_'+(state.startRecord + index)]).length ?
                                                                        <React.Fragment>
                                                                        <p className='mb-2 text-white'>{capitalizeTheFirstLetterOfEachWord (removeUnderScore(order))}</p>
                                                                        <div className='mb-1'>
                                                                            <ApexLineChartSpendBoard
                                                                                graphData={state[order+'_'+(state.startRecord + index)]}
                                                                                sparkline={false}
                                                                                yaxis={true}
                                                                                yaxisLabel={true}
                                                                                yaxisTitle={false}
                                                                                // yaxisTitleLabel={state['selectedChartType_'+index]}
                                                                                yAxisTickAmount={2}
                                                                                xaxis={true}
                                                                                xaxisFormat={'datetime'}
                                                                                xaxisLabel={true}
                                                                                axisLabelColor={'#666666'}
                                                                                paddingLeft={20}
                                                                                legend={false}
                                                                                legendPostion={'bottom'}
                                                                                legendHorizontalAlign={'center'}
                                                                                legendUseSeriesColor={true}
                                                                                stacked={order==='jobs' ? true : false}
                                                                                height={120}
                                                                                horizontal={false}
                                                                                barHeight={'40%'}
                                                                                barEndShape={'flat'}
                                                                                columnWidth={'25%'}
                                                                                gradient={true}
                                                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                                                hideTooltipValue={true}
                                                                                backgroundBarShape={'flat'}
                                                                                backgroundBarColors={['#333947']}
                                                                                showBackgroundBarColors={false}
                                                                                className={'transparentTooltip'}
                                                                                colors={state.graphOrderColors[oIndex]}
                                                                                stroke={2}
                                                                            />
                                                                        </div>
                                                                        </React.Fragment>
                                                                    : null}
                                                                </div>
                                                            )                                                                
                                                        })}
                                                    </div>
                                                </div>
                                            : null}
                                            {state['trendTable_'+(state.startRecord + index)] ?
                                                <div className="col-lg-12 col-md-12 col-sm-12 pr-0 mt-3">
                                                    <div className="d-flex justify-content-between py-2">
                                                        <p className="mb-0 align-self-center">Showing {state['filteredArray_'+(state.startRecord + index)] && state['filteredArray_'+(state.startRecord + index)].length} of total {state['trendTable_'+(state.startRecord + index)] && state['trendTable_'+(state.startRecord + index)].length} Cost and usage breakdown</p>
                                                        {state['trendTable_'+(state.startRecord + index)] && state['trendTable_'+(state.startRecord + index)].length ?
                                                            <div className="d-flex">
                                                                <CSVLink
                                                                    data={state['filteredArray_'+(state.startRecord + index)] ? state['filteredArray_'+(state.startRecord + index)] : []} 
                                                                    headers={state.userHeaders ? state.userHeaders : []}
                                                                    filename={'spenboard-users-'+new Date().getTime()+'.csv'}
                                                                    className={"mt-2"}
                                                                    target="_blank"
                                                                >
                                                                    <span className="d-flex text-primary-color mr-2">
                                                                        <i className="fas fa-download text-primary-color mr-2"></i>
                                                                    </span>
                                                                </CSVLink>
                                                                {/* <div className={`mb-n2`}>
                                                                    <Search
                                                                        data={state.trendTable ? state.trendTable : []}
                                                                        filteredData={filteredArray => {
                                                                            setState(prevState => ({ ...prevState, filteredArray }))
                                                                        }}
                                                                        topClassName={'bg-mediumDarkGray align-self-center w-80 mr-1 border-lightGray rounded-5 f12'}
                                                                        searchClassName={'px-2 f12'}
                                                                        searchIconColor={'text-white f12'}
                                                                        searchPlaceHolder={'Search....'}
                                                                        className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                                    />
                                                                </div> */}
                                                            </div>
                                                        : null}
                                                    </div>
                                                    <div className='stickyTable spendBoardBudgetTable' id={'table_'+(state.startRecord + index)}>
                                                        <StickyDarkThemeTable
                                                            id={'table_'+(state.startRecord + index)}
                                                            data={state['filteredArray_'+(state.startRecord + index)].length ? state['filteredArray_'+(state.startRecord + index)] : state['filteredArray_'+(state.startRecord + index)]}
                                                            columns = {state['columns_'+(state.startRecord + index)]}
                                                            onClickRow={tableRow => {}}
                                                            dashboard = {state['filteredArray_'+(state.startRecord + index)].length ? true : false}
                                                            //tooltipFor={[]}
                                                            marginRight={10}
                                                            tableHead={'bg-black text-white'}
                                                            tableBody={'bg-dark3 text-white'}
                                                            tableType={'table-dark-hover'}
                                                            perPageClass={`bg-transparent text-lightGray`}
                                                            textColor={'text-black'}
                                                            selectedColor={'bg-transparent'}
                                                            sortByColumn={"anomaly_time"}
                                                            riskTooltip={[]}
                                                        />
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                : null}
                            </div>
                        )
                    })
                : 
                    <div className='d-flex justify-content-center m-4'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
                
                {state.totalRecords > state.perPage ?
                    <div className='d-flex justify-content-end'>
                        <div className='pagination errorPagePagination f12 mt-2'>
                            <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                            <span className='displayNone'>
                                Go to
                                <input
                                    type='number'
                                    value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) : ''
                                        gotoPage(page)
                                    }}
                                    className='ml-1 inputClass'
                                />
                            </span>
                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button>
                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}
export default UserDetails