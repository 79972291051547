/*************************************************
 * Collider
 * @exports
 * @file urlPath.js
 * @author Rajasekar // on 19/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
export const URL_PATH = {
	COST_RECOMMENDATION: '/cost/recommendations',
	COST_POWER_TUNING: '/cost/powertuning',
	COST_ANOMALIES: '/cost/anomalies',
	COST_EXPLORER: '/cost/explorer',
	COST_COMPARISON: '/cost/comparison',
	COST_QUERIES: '/cost/queries',
	COST_BUDGET_DASHBOARD: '/cost/budget/dashboard',
	COST_BUDGET_LIST: '/cost/budget',
	COST_BUDGET_CREATE: '/cost/budget/create',
	
	HPC_BUDGET: '/hpc/budget',
	HPC_CREATE_BUDGET: '/hpc/budget/create',
	HPC_SPENDBOARD: '/hpc/spendboard',
	HPC_REPORTS: '/hpc/reports',
	HPC_DASHBOARD: '/hpc/dashboard',
	JOB_STATUS: '/hpc/jobstatus',
	CLUSTER_DETAILS: '/hpc/cluster',
	CREATE_CLUSTER: '/hpc/cluster/create',
	QUEUE_DETAILS: '/hpc/queue',
	OBSERVABILITY: '/hpc/observability',	
	CATALOG_LANDINGPAGE: '/hpc/workstation',
	WORKSTATION_REQUEST_LANDINGPAGE: '/hpc/request',
	HPC_ALERTS: '/hpc/alerts',
	FILES: '/hpc/files',

	MANAGE_QOS: '/hpc/qos',
	CREATE_QOS: '/hpc/qos/create',
	CREATE_GROUP: '/hpc/group/create',
	EDIT_QOS: '/hpc/qos/edit',
	HPC_STATUS: '/hpc/status',
	HPC_LICENSES: '/hpc/licenses',
	ADMIN_USERS: '/admin/users',
	ADMIN_DEPARTMENT_MAPPING: '/admin/departments',
	ADMIN_DEPARTMENT_MAPPING_CREATE: '/admin/departments/create',

	

	//resource start
	RESOURCES_DASHBOARD: '/resources/dashboard',
	RESOURCES_LANDING_PAGE: '/resources/inventory',
	RESOURCES_SEARCH: '/resources/search',
	RESOURCE_COMPARISON: '/resources/compare',
	CONSOLIDATED_COMPARISON: '/resources/compare/consolidated',
	ASSETS_COMPARISON: '/resources/compare/assets',
	RESOURCES_DETAILS: '/resources/asset',	
	RESOURCES_QUERY_EVENTS: '/resources/queries',
	MANAGE_ALARMS: '/resources/alarms',
	LIFECYCLE_LANDING_PAGE: '/resources/lifecycle',
	//resource end
	
	GOVERNANCE_DASHBOARD: '/governance/dashboard',
	GOVERNANCE_MANAGE_RULES: '/governance/rules',
	GOVERNANCE_CREATE_NEW_RULE: '/governance/rule/create',
	GOVERNANCE_EDIT_NEW_RULE: '/governance/rule/edit',
	
	ERROR_PAGE: '/error',
	LOADING_PAGE: '/authorize',
	PERMISSION_DENINED: '/permission/denied',
	LIFECYCLE_NOTIFICATIONS: '/lifecycle-notification',

	//File Browser
}
