import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import NewRequest from './NewRequest';
import RequestHistory from './RequestHistory';
import FilterSection from '../../common/FiltersSection';

const LandingPage = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        topSectionFilters: ['provider', 'account', 'region'],
        selectedTab: "New Request"
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);

    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), selectedTab: "New Request" }));
    //     }
    // }, [providers]);
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            callSearch: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            let prevValue = state.selectedTab
            setState(prevState => ({ ...prevState, callSearch: false, selectedTab: "" }))
            setTimeout(() => {
                setState(prevState => ({ ...prevState, selectedTab: prevValue })) // Set the same value
            }, 1000); // Change to the desired delay (in milliseconds)
        }
    }, [state.callSearch, state.selectedTab])

    const handleClickOutside = (event) => {
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
    };

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleClickOutside(event)}>
            <div className='col-sm-12 p-0 h-100 d-flex flex-column overflow-hidden'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-4 col-md-12 col-sm-12 py-2'>
                        <h6 className='text-white m-0'>Request</h6>
                        <p className='text-white m-0'>Consolidated view of workstation request</p>
                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 py-2' ref={clickOutside}>
                        <div className='row mx-0 justify-content-end'>
                            <FilterSection
                                filters={state.topSectionFilters}
                                topFilterOptions= {state.topFilterOptions}
                                selectedFilters={(filter) => handleSelectedFilters(filter)}
                                clickedonParent={state.clickedonParent}
                                filterAdvancedSearch={false}
                                onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                                onReset={onReset}
                            />
                        </div>
                    </div>
                </div>
                <div className='row px-2 flex-grow-1 h-100 overflow-hidden'>
                    <div className='col-lg-12 h-100 mt-2 overflow-hidden'>
                        <div className='card h-100'>
                            <div className='d-flex justify-content-between'>
                                <ul className={`nav nav-tabs border-mediumDarkGray-bottom bg-transparent shadow-none w-100 mb-0`} id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <span className={`nav-link text-white cursorPointer ${state.selectedTab === 'New Request' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'New Request' }))}>New Request</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={`nav-link text-white cursorPointer ${state.selectedTab === 'Request History' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Request History' }))}>Request History</span>
                                    </li>
                                </ul>
                            </div>
                            {state.selectedTab === 'New Request' && state.selectedProvider ?
                                <NewRequest
                                    onSubscribe={(subscribedRow) => setState(prevState => ({...prevState, subscribedRow, selectedTab: 'Workstations' }))}
                                    selectedProvider={state.selectedProvider} 
                                    selectedAccount={state.selectedAccount} 
                                    selectedRegion={state.selectedRegion} 
                                />
                            : state.selectedTab === 'Request History' && state.selectedProvider ?
                                <RequestHistory
                                    onSubscribe={(subscribedRow) => setState(prevState => ({...prevState, subscribedRow, selectedTab: 'Workstations' }))}
                                    selectedProvider={state.selectedProvider} 
                                    selectedAccount={state.selectedAccount} 
                                    selectedRegion={state.selectedRegion} 
                                />
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage