/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file AssetComparison.js
 * @author Prakash // on 21/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getCoveredServices, getAggregatedAccountsByRegion } from '../../../actions/detectors/detectorsAction'

import { UncontrolledTooltip, Spinner } from 'reactstrap'
import { getAccountNameFromId, getRegionName } from '../../../utils/utility'

import Select from 'react-select'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import MultiSelectSection from '../../common/MultiSelectSection'
// import _ from 'lodash'
// import { DURATION_FILTER_OPTIONS } from '../../../utils/constants'

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

const AssetComparison = () => {
    const accRegRef = useRef()
    const tagsRef = useRef()
    const sliderWrap = useRef()
    const toggleRef = useRef()
    const [state, setState] = useState({
        showLoading: true,
        showServicesLoading: true,
        changesInFilter: true,

        //filter section start
        showBy: ["Account", "Region", "Account/Region"],
        showByValue: "Account",

        providers: [],
        selectedProvider: "",
        
        accounts: [],
        accountSelected: [],
        selectedAccount: [],

        regions: [],
        regionSelected: [],
        selectedRegion: [],
        accRegionArray: [0],

        selectedDuration: "+7d",

        tagsArray: [0],
        selectedTags: [],
        tagFilter: false,           

        //filter section end

        catServiceType: ["Category", "Services"],
        selectedCatSer: "Services",            

        totalItemShowing: 0,
        totalItemsPerPage: 0, 

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,      
        emptyOptions: [],
    })

    const dispatch = useDispatch()
    const providers = useSelector(state => state?.filters?.providers || false);
    const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
    
    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: "onLoad"
            }));
        } else { 
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callSearch: "onLoad"
                        }));
                    }
                })
        }
    }, [dispatch, providers]);

	// Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

    useEffect(() => {
        if(state.callSearch) {
            if(state.showByValue === "Account/Region") {
                if(!state.selectedAccReg || !state.selectedAccReg.length) {
                    setState(prevState => ({ ...prevState, showFilterSection: true }))
                }
            }

            let callGetCoveredServices = state.callSearch === 'onLoad' ? true : false
            let callGetCoveredServiceAggreate = state.callSearch !== 'onLoad' && state.selectedShowBy !== 'Account/Region' ? true : false
            let callGetAggregatedAccountsByRegion = state.callSearch !== 'onLoad' && state.selectedShowBy === 'Account/Region' ? true : false

            setState(prevState => ({ 
                ...prevState, 
                changesInFilter: false,
                showLoading: true,
                showServicesLoading: true,
                selectedShowBy: state.showByValue,
                selectedAccount: state.accountSelected,
                selectedRegion: state.regionSelected,
                resetEnabled: true,
                totalAccArray: [],
                totalItemShowing: 0,
                totalItemsPerPage: 0,
                callSearch: '',
                callGetCoveredServices,
                callGetCoveredServiceAggreate,
                callGetAggregatedAccountsByRegion,
            }))
                // () => {
                //     if(type === "onLoad") {
                //         this.getCoveredServices()
                //     } else {
                //         if(state.selectedShowBy !== "Account/Region") {
                //             this.getCoveredServiceAggreate()
                //         } else {
                //             this.getAggregatedAccountsByRegion()
                //         }
                //     }
                // }

                // setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false }))}, 3000)

        }
    }, [state.callSearch, state.accountSelected, state.regionSelected, state.selectedAccReg, state.selectedShowBy, state.showByValue])

    const onReset = () => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: state.providers && state.providers.length && state.providers[0].provider_name ? state.providers[0].provider_name.toLowerCase() : "",
            accountSelected: [],
            regionSelected: [],
            changesInFilter: state.resetEnabled,
        }))
    }

    useEffect(() => {
        if(state.callGetCoveredServices) {

            let params = {}
            params.provider = state.selectedProvider && state.selectedProvider !== "" ? state.selectedProvider.toLowerCase() : ""
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            let aggregateBy = "resource_type"
            if(state.selectedCatSer === "Category") {
                aggregateBy = "category"
            }
            params.aggregate_by = [aggregateBy]

            let callGetCoveredServiceAggreate = state.selectedShowBy !== 'Account/Region' ? true : false
            let callGetAggregatedAccountsByRegion = state.selectedShowBy === 'Account/Region' ? true : false
            
            dispatch(getCoveredServices(params))
                .then((response) => {
                    let results = response && response.results && response.results.length ? response.results : []
                    let selectedCategory = results.length && results[0] ? results[0].resource_type : ""
                    if(state.selectedCatSer === "Category") {
                        selectedCategory = results.length && results[0] ? results[0].category : ""
                    }
                    setState(prevState => ({
                        ...prevState,
                        categoryList: results,
                        filterCategoryList: results,
                        selectedCategory,
                        showLoading: false,
                        showServicesLoading: selectedCategory !== "" ? true : false,
                        callGetCoveredServices: false,
                        callGetCoveredServiceAggreate,
                        callGetAggregatedAccountsByRegion
                    }))
                })
        }
    }, [state.callGetCoveredServices, dispatch, state.selectedAccount, state.selectedCatSer, state.selectedProvider, state.selectedRegion, state.selectedShowBy])
    

    const slideLeft = () => {
        let averageSliderItemWidth = 278
        
		let sliderWrapWidth = sliderWrap.current.getBoundingClientRect().width
		let totalItemsPerPage = sliderWrapWidth/averageSliderItemWidth;
        let totalItemShowing = parseInt(totalItemsPerPage)
        
		if(state.totalItemShowing) {
            totalItemShowing = state.totalItemShowing - 1 
		}

        setState(prevState => ({ ...prevState, totalItemShowing }))
	}

	const slideRight = useCallback(() => {
        let averageSliderItemWidth = 278
        
		let sliderWrapWidth = sliderWrap.current && sliderWrap.current.getBoundingClientRect().width
		let totalItemsPerPage = sliderWrapWidth/averageSliderItemWidth;
        totalItemsPerPage = parseInt(totalItemsPerPage)
        let totalItemShowing = parseInt(totalItemsPerPage)
        
		if(state.totalItemShowing) {
            totalItemShowing = state.totalItemShowing + 1
		}

        setState(prevState => ({ ...prevState, totalItemsPerPage, totalItemShowing }))
	}, [state.totalItemShowing])

    useEffect(() => {
        if(state.callGetCoveredServiceAggreate) {

            let inputError = false
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== "") {
                params.provider = state.selectedProvider.toLowerCase()
            } else {
                inputError = true
            }
    
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
    
            if(state.selectedCatSer === "Category") {
                params.category = state.selectedCategory
            } else {
                params.resource_type = state.selectedCategory
            }
            
            let aggregateBy = ["service_name"]
            if(state.selectedShowBy === "Account") {
                aggregateBy.push("account_id")
            } else if(state.selectedShowBy === "Region") {
                aggregateBy.push("region")
            }
            params.aggregate_by = aggregateBy
            params.assets_present = true
            params.radial = true
            if(!inputError && params.provider !== "") {
                dispatch(getCoveredServices(params))
                    .then((response) => {
                        let results = response && response.results && response.results.length ? response.results : []
                        let totalAccArray = []
                        
                        if(results.length && results[0].data && Object.entries(results[0].data).length) {
                            Object.entries(results[0].data).forEach(([key, resource], index) => {
                                let dataRow = {}
                                dataRow.key = key
                                dataRow.status = 1
                                dataRow.index = index
                                totalAccArray.push(dataRow)
                            })
                        }
    
                        setState(prevState => ({
                            ...prevState,
                            categoryServiceList: results,
                            totalAccArray, 
                            totalAccountArray: totalAccArray,
                            showServicesLoading: false,
                            callGetCoveredServiceAggreate: false,
                            showLoading: false,
                            // callSlideRight: totalAccArray && totalAccArray.length ? true : false
                        }))

                        setTimeout(() => { slideRight() }, 1000);
                    })
            }
        }
    }, [state.callGetCoveredServiceAggreate, dispatch, slideRight, state.selectedAccount, state.selectedCatSer, state.selectedCategory, state.selectedProvider, state.selectedRegion, state.selectedShowBy])

    useEffect(() => {
        if(state.callGetAggregatedAccountsByRegion) {

            let inputError = false
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== "") {
                params.provider = state.selectedProvider.toLowerCase()
            } else {
                inputError = true
            }
            params.filter = state.selectedAccReg
    
            if(state.selectedCatSer === "Category") {
                params.category = state.selectedCategory
            } else {
                params.resource_type = state.selectedCategory
            }
            params.aggregate_by = ["service_name"]
    
            if(!inputError && params.provider !== "") {
                dispatch(getAggregatedAccountsByRegion(params))
                    .then((response) => {

                        let results = response && response.length ? response : []
                        let totalAccArray = []
                        if(results.length && results[0].data && Object.entries(results[0].data).length) {
                            Object.entries(results[0].data).forEach(([key, resource], index) => {
                                let dataRow = {}
                                dataRow.key = key
                                dataRow.status = 1
                                dataRow.index = index
                                totalAccArray.push(dataRow)
                            })
                        }
    
                        setState(prevState => ({
                            ...prevState,
                            categoryServiceList: results,
                            totalAccArray, 
                            totalAccountArray: totalAccArray,
                            showServicesLoading: false,
                            callGetAggregatedAccountsByRegion: false
                            // callSlideRight: totalAccArray && totalAccArray.length ? true : false
                        }))
                        setTimeout(() => { slideRight() }, 1000);
                    })
            }
        }
    }, [state.callGetAggregatedAccountsByRegion, dispatch, slideRight, state.selectedAccReg, state.selectedCatSer, state.selectedCategory, state.selectedProvider])

    const onClickCategory = (item) => {
        let selectedCategory = item.category ? item.category : item.resource_type
        setState(prevState => ({ 
            ...prevState, 
            selectedCategory, 
            totalItemShowing: 0, 
            showServicesLoading: true,
            callGetCoveredServiceAggreate: state.selectedShowBy !== 'Account/Region' ? true : false,
            callGetAggregatedAccountsByRegion: state.selectedShowBy === 'Account/Region' ? true : false,
        }))
    }
    // const selectedColumn = (column, field, index, item) => {
    //     let selectedService = item.service_name ? item.service_name : ""
    //     if(field !== "") {
    //         selectedService = field+"_"+index
    //     }        

    //     setState(prevState => ({ ...prevState, selectedService }))
    // }

    useEffect(() => {
        if(state.onChagneAccRegSeleced) {
            setState(prevState => ({ ...prevState, onChagneAccRegSeleced : false }))
            let data = []
            state.accRegionArray.forEach(item => {
                let dataRow = {}
                if(state["accounts_"+item] && state["accounts_"+item] !== "" && state["regions_"+item] && state["regions_"+item] !== "") {
                    dataRow.account_id = state["accounts_"+item]
                    dataRow.region = state["regions_"+item]
                    data.push(dataRow)
                }
            })
    
            setState(prevState => ({ ...prevState, selectedAccReg : data }))

        }
    }, [state.onChagneAccRegSeleced, state])

	const addSection = (array) => {
        let rowList = state[array];
        if(state[array]) {
            let value = state[array][state[array].length - 1]
            value = value+1 
            rowList.push(value);
        }
        
        setState(prevState => ({ ...prevState, [array]: rowList }))
    }

    const removeSection = (item, i) => {
        setState(prevState => ({ ...prevState, ["accounts_"+item]: "", ["regions_"+item]: "" }))
        let rowList = state.accRegionArray;
        rowList.splice(i, 1);
        setState(prevState => ({ ...prevState, accRegionArray: rowList }))
    }

	const resetFilterSelection = () => {
		state.accRegionArray.forEach(item => {
			setState(prevState => ({ ...prevState, ["accounts_"+item]: "", ["regions_"+item]: "" }))
		})
		setState(prevState => ({ ...prevState, accountSelected: [], regionSelected: [], selectedAccReg: [] }))
	}

    const removefilter = (index, value, field) => {
        let filteredResult = state[field]
        filteredResult = state[field].filter(e => e !== value)
        
        // filteredResult = state[field].filter(e => e.key !== option.key && e.value !== option.value)
        setState(prevState => ({ ...prevState, [field]: filteredResult, changesInFilter: true }))
    }

	const showDropFunction = (index) => {
		for(let i=0; i<state.totalAccArray.length;i++) {
			if(i === index) {
                setState(prevState => ({ ...prevState, ['showDrop'+i]: !state['showDrop'+i] }))
            } else {
                setState(prevState => ({ ...prevState, ['showDrop'+i]: false, moveToDestination: '' }))
            }
		}
	}

	const removeColumn = (index) => {		
		let totalAccArray = state.totalAccArray.filter(e => e.index !== index)
        let totalArray = []
        totalAccArray.forEach((item, i) => {
            item.index = i
            totalArray.push(item)  
        })

		setState(prevState => ({ ...prevState, totalAccArray: totalArray, ['text_'+index]: false, ['showDrop'+index]: false, moveToDestination: ''}))
	}

	const onChangeText = (e) => {
		const re = /^[0-9\b]+$/;
		// if value is not blank, then test the regex
	
		if (e.target.value === '' || re.test(e.target.value)) {
		   setState(prevState => ({ ...prevState, moveToDestination: e.target.value}))
		}
	}

	const moveColumnTo = (index, source) => {
		let destination = state.moveToDestination
		if(destination > 0 && destination !== '' && state.totalAccArray.length > 1 && destination <= state.totalAccArray.length) {
			let totalArray = state.totalAccArray

			destination = destination-1
                
            totalArray[source] = {...totalArray[source], status: 1, index: destination}

            //remove `from` item and store it
            let f = totalArray.splice(source, 1)[0];
            
            // insert stored item into position `to`
            totalArray.splice(destination, 0, f);            
            
            for(let i = 0; i < state.totalAccArray.length; i++) {
                totalArray[i] = {...totalArray[i], index: i}
            }
			
			setState(prevState => ({ ...prevState, totalAccArray: totalArray, ['text_'+index]: false, ['showDrop'+index]: false, moveToDestination: ''}))
		}
	}

    const redirectResourceSearch = (acc, item) => {
        // let accountId = []
        // let region = []
        // if(state.selectedShowBy === "Account") {
        //     accountId = [acc.key]
        //     if(state.selectedRegion.length && !state.selectedRegion.includes("All")) {
        //         region = state.selectedRegion
        //     }
        // } else if(state.selectedShowBy === "Region" ) {
        //     if(state.selectedAccount.length && !state.selectedAccount.includes("All")) {
        //         accountId = state.selectedAccount
        //     }
        //     region = [acc.key]
        // } else { 
        //     acc.key.split("_").map((item, j) => {
        //         if(!j) {
        //             accountId = [item]
        //         } else {
        //             region = [item]
        //         }
        //     })
        // }

        // this.props.history.push({
        //     pathname: URL_PATH.RESOURCES_SEARCH,
        //     state: { 
        //         selectedProvider: state.selectedProvider ? state.selectedProvider : "",
        //         selectedAccount: accountId,
        //         selectedRegion: region,
        //         selectedService: [item.service_name]
        //     }
        // })
        
    }

    const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

        if(state.showFilterSection) {
			if (accRegRef.current && !accRegRef.current.contains(event.target)) {
                setState(prevState => ({ ...prevState, showFilterSection: false }))
            }
		}

		if(state.showTagFilter) {
			if (tagsRef.current && !tagsRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showTagFilter: false }))
			}
		}
        if(state.totalAccArray && state.totalAccArray.length) {
            if (toggleRef.current && !toggleRef.current.contains(event.target)) {
                setState(prevState => ({ ...prevState, showServiceTypeOptions: false }))
            } else {
                setState(prevState => ({ ...prevState, showServiceTypeOptions: !state.showServiceTypeOptions }))
            }
        }
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="container-fluid overflow-auto flex-grow-1 px-0" onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div>
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 bg-nero'>
                    <div className='col-lg-4 col-4 pl-2 align-self-center'>
                        <h6 className="text-white m-0">Assets Comparison</h6>
                        <p className='text-white mb-0'>Compilation of assets perspectives between your accounts and regions</p>
                    </div>
                    <div className='col-lg-8 col-8'>
                        <div className='row mx-0 d-flex justify-content-end'>
                            <div className={`form-group cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                                onClick={(event) => {
                                    if(!state.isProviderOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                    }
                                }}
                            >
                                <label className='mb-1 mt-1 f10 d-flex'>Provider</label>
                                <p className={`d-flex justify-content-between px-2 mt-2 mb-0 hiddenEllipses ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedProvider ? state.selectedProvider : 'Select'}
                                    <i className='fa fa-caret-down mt-1'/>
                                </p>
                                {state.isProviderOpen ?
                                    <MultiSelectSection 
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, selectedProvider: value, selectedProject: '', changesInFilter: true }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                    />
                                : null}
                            </div>
                            <div className={`form-group cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                                onClick={(event) => {
                                    if(!state.isShowBy) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isShowBy")
                                    }
                                }}
                            >
                                <label className='mb-1 mt-1 f10 d-flex'>Show By</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 px-2 hiddenEllipses ${state.showByValue ? 'text-white' : 'placeholder'}`}>
                                    {state.showByValue ? state.showByValue : 'Select'}
                                    <i className='fa fa-caret-down mt-1'/>
                                </p>
                                {state.isShowBy ?
                                    <MultiSelectSection 
                                        // fields={["provider_name", "provider_name"]}
                                        options={state.showBy}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.showByValue ? state.showByValue : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, showByValue: value, changesInFilter: true }))
                                                resetFilterSelection()
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                    />
                                : null}
                            </div>
                            <div className='col-3 pl-0'>
                                <div className={`form-group flex-fill bd-highlight mb-sm-0 styled-feild`} ref={accRegRef}>
                                    <div className="" onClick={() => setState(prevState => ({ ...prevState, showFilterSection: true }))}>
                                        <label>Account/Region</label>
                                        <p className='pt-2 mb-0'>
                                            {state.showByValue === "Account/Region" ?
                                                state.selectedAccReg && state.selectedAccReg.length ? 
                                                    <span>{state.selectedAccReg.length} Selected</span>
                                                : 
                                                    <span className="placeholder">Select</span>
                                            :
                                                <React.Fragment>
                                                {state.accountSelected && state.accountSelected.length && state.accountSelected.includes("All") ?
                                                    <span>All / </span>
                                                : state.accountSelected && state.accountSelected.length && !state.accountSelected.includes("All") ?
                                                    <span>{state.accountSelected.length} selected / </span>
                                                :
                                                    <span className="placeholder">Select</span>
                                                }

                                                {state.regionSelected && state.regionSelected.length && state.regionSelected.includes("All") ?
                                                    <span>All</span>
                                                : state.regionSelected && state.regionSelected.length && !state.regionSelected.includes("All") ?
                                                    <span>{state.regionSelected.length} selected</span>
                                                : state.accountSelected && state.accountSelected.length ?
                                                    <span>All</span>
                                                :
                                                    <span className="placeholder">&nbsp;</span>
                                                }
                                                </React.Fragment>
                                            }
                                        </p>
                                    </div>
                                    {state.showFilterSection ?
                                        <div className="resourcePopupFilterSection border mt-1">
                                            <div className="p-3 reactSelectDropTransparent bg-darkGray">
                                                {state.showByValue === "Account/Region" ?
                                                    <div className={`w-100 mt-2`}>
                                                        <div className={`d-flex`}>
                                                            <label className="mb-1 w-40 text-white">Account</label>
                                                            <label className="mb-1 w-40 ml-3 text-white">Region</label>
                                                        </div>
                                                        {state.accRegionArray.map((index, i) => {
                                                            return(
                                                                <div key={'accRegArr_'+i} className={`w-100 ${i ? "mt-2 border-top-black4" : ""}`}>
                                                                    <div className={`d-flex ${!i ? "" : "mt-3"}`}>
                                                                        <Select
                                                                            placeholder={'Select'}
                                                                            isSearchable={false}
                                                                            //onFocus={this.openProivderMenu}
                                                                            className='f13 p-0 w-40'
                                                                            value={({
                                                                                value: state["accounts_"+index] && state["accounts_"+index] !== "" ? state["accounts_"+index] : 'Select',
                                                                                label: state["accounts_"+index] && state["accounts_"+index] !== "" ? state.accounts.filter(e => e.account_id === state["accounts_"+index])[0].account_name : <span className="placeholder">Select</span>
                                                                            })}
                                                                            options={state.accounts.filter(e => e.account_id !== "All").map(item => ({
                                                                                value: item.account_id,
                                                                                label: item.account_name
                                                                            }))}
                                                                            onChange={event =>
                                                                                setState(prevState => ({ ...prevState, ["accounts_"+index]: event.value, changesInFilter: true }))
                                                                            }
                                                                        />
                                                                        <Select
                                                                            placeholder={'Select'}
                                                                            isSearchable={false}
                                                                            //onFocus={this.openProivderMenu}
                                                                            className='f13 p-0 dark-select-theme-with-dropdown-indicator w-40 ml-3'
                                                                            value={({
                                                                                value: state["regions_"+index] && state["regions_"+index] !== "" ? state["regions_"+index] : 'Select',
                                                                                label: state["regions_"+index] && state["regions_"+index] !== "" ? state.regions.filter(e => e.region === state["regions_"+index])[0].name : <span className="placeholder">Select</span>
                                                                            })}
                                                                            options={state.regions.filter(e => e.region !== "All").map(item => ({
                                                                                value: item.region,
                                                                                label: item.name,	
                                                                            }))}
                                                                            onChange={event =>{
                                                                                setState(prevState => ({ ...prevState, ["regions_"+index]: event.value, changesInFilter: true, onChagneAccRegSeleced: true }))
                                                                                // accRegSelected()
                                                                            }}
                                                                        />
                                                                        
                                                                        {state.accRegionArray.length > 1 ?
                                                                            <span className={`far fa-trash text-danger cursor-pointer f18 align-self-center ml-4`} onClick={() => removeSection(index, i)}></span>
                                                                        : null}
                                                                        {(state.accRegionArray.length - 1) ===  i ?
                                                                            <span className={`far fa-plus text-info cursor-pointer f18 align-self-center ml-4`} onClick={() => addSection("accRegionArray")}></span>
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                :
                                                    <React.Fragment>
                                                    <div className="w-100">
                                                        <div className="d-flex flex-wrap mb-1">
                                                            <label className="mb-0 text-white">Account1</label>
                                                            {state.accountSelected && state.accountSelected.length && !state.accountSelected.includes("All") ?
                                                                state.accountSelected.map((cat, index) => {
                                                                    return(
                                                                        <span  key={'cat__'+index} className="badge badge-secondary ml-2 mb-1">
                                                                            {cat}
                                                                            <i onClick={ () => removefilter(index, cat, 'accountSelected') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            : state.accountSelected && state.accountSelected.includes("All") ?
                                                                <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                            : null}
                                                        </div>
                                                        {state.showByValue === "Account" ?
                                                            <div className='w-40 mt-1 border rounded-5' 
                                                                onClick={(event) => {
                                                                    event.preventDefault();
                                                                    handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                                                                }}
                                                            >
                                                                <p className={`d-flex justify-content-between py-2 px-2 mb-0 ${state.accountSelected && state.accountSelected.length ? 'text-white' : 'placeholder'}`}>
                                                                    {state.accountSelected && state.accountSelected.length ? state.accountSelected.length+' Selected' : 'Select'}
                                                                    <i className='fa fa-caret-down mt-1'/>
                                                                </p>
                                                                {state.isAccountOpen ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            fields={["account_id", "account_name"]}
                                                                            className={''}
                                                                            removeTop={true}
                                                                            options={state.accounts}
                                                                            widthClass={'minWidth220'}
                                                                            selectedValues={state.accountSelected ? state.accountSelected : []}
                                                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, accountSelected: value }))}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        : 
                                                            <div className='w-40 mt-1 border rounded-5' 
                                                                onClick={(event) => {
                                                                    if(!state.isAccountSingleOpen) {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', "isAccountSingleOpen")
                                                                    }
                                                                }}
                                                            >
                                                                <p className={`d-flex justify-content-between py-2 px-2 mb-0 ${state.accountSelected && state.accountSelected.length ? 'text-white' : 'placeholder'}`}>
                                                                    {state.accountSelected && state.accountSelected.length ? state.accountSelected[0] : 'Select'}
                                                                    <i className='fa fa-caret-down mt-1'/>
                                                                </p>
                                                                {state.isAccountSingleOpen ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            fields={["account_id", "account_name"]}
                                                                            className={'border-0'}
                                                                            removeTop={true}
                                                                            options={state.accounts}
                                                                            widthClass={'minWidth220'}
                                                                            selectedValues={state.accountSelected ? state.accountSelected : []}
                                                                            callbackMultiSelect={(value) => {
                                                                                if(!value || typeof(value) === 'string') {
                                                                                    setState(prevState => ({ ...prevState, accountSelected: [value], changesInFilter: true }))
                                                                                } else {
                                                                                    value.preventDefault()
                                                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                                                }
                                                                            }}
                                                                            singleSelection={true}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="w-100 mt-3">
                                                        <div className="d-flex flex-wrap mb-1">
                                                            <label className="mb-0 text-white">Region</label>
                                                            {state.regionSelected && state.regionSelected.length && !state.regionSelected.includes("All") ?
                                                                state.regionSelected.map((cat, index) => {
                                                                    return(
                                                                        <span key={'regSel_'+index} className="badge badge-secondary ml-2 mb-1">
                                                                            {cat}
                                                                            <i onClick={ () => removefilter(index, cat, 'regionSelected') } className='ml-1 fal fa-times cursorPointer'></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            : state.regionSelected && state.regionSelected.includes("All") ?
                                                                <span className="badge badge-secondary ml-2 mb-1">All</span>
                                                            : null}
                                                        </div>
                                                        {state.showByValue === "Region" ?
                                                            <div className='w-40 mt-1 border rounded-5' 
                                                                 onClick={(event) => {
                                                                    event.preventDefault();
                                                                    handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
                                                                }}
                                                            >
                                                                <p className={`d-flex justify-content-between py-2 px-2 mb-0 ${state.regionSelected && state.regionSelected.length ? 'text-white' : 'placeholder'}`}>
                                                                    {state.regionSelected && state.regionSelected.length ? state.regionSelected.length+' Selected' : 'Select'}
                                                                    <i className='fa fa-caret-down mt-1'/>
                                                                </p>
                                                                {state.isRegionOpen ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            fields={["region", "name"]}
                                                                            className={'border-0'}
                                                                            removeTop={true}
                                                                            options={state.regions}
                                                                            widthClass={'minWidth220'}
                                                                            selectedValues={state.regionSelected ? state.regionSelected : []}
                                                                            callbackMultiSelect={(value) => {
                                                                                setState(prevState => ({ ...prevState, regionSelected: value }))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        : 
                                                            <div className='w-40 mt-1 border rounded-5' 
                                                                onClick={(event) => {
                                                                    if(!state.isRegionSingleOpen) {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', "isRegionSingleOpen")
                                                                    }
                                                                }}
                                                            >
                                                                <p className={`d-flex justify-content-between py-2 px-2 mb-0 ${state.regionSelected && state.regionSelected.length ? 'text-white' : 'placeholder'}`}>
                                                                    {state.regionSelected && state.regionSelected.length ? state.regionSelected[0] : 'Select'}
                                                                    <i className='fa fa-caret-down mt-1'/>
                                                                </p>
                                                                {state.isRegionSingleOpen ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            fields={["region", "name"]}
                                                                            className={'border-0'}
                                                                            removeTop={true}
                                                                            options={state.regions}
                                                                            widthClass={'minWidth220'}
                                                                            selectedValues={state.regionSelected ? state.regionSelected : []}
                                                                            singleSelection={true}
                                                                            callbackMultiSelect={(value) => {
                                                                                if(!value || typeof(value) === 'string') {
                                                                                    setState(prevState => ({ ...prevState, regionSelected: [value], changesInFilter: true }))
                                                                                } else {
                                                                                    value.preventDefault()
                                                                                    handleChildClick(value, "search", 'singleDropDown', "")
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        }
                                                    </div>
                                                    </React.Fragment>			
                                                }															
                                                <div className="d-flex justify-content-end mt-3">
                                                    <span className={`text-info mr-2 align-self-center`} onClick={() => resetFilterSelection()}>Clear</span>
                                                    {/* <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => setState({ showFilterSection: false }, () => filteredResults())}>Filter</button> */}
                                                    <button className={`btn btn-sm btn-light`} onClick={() => setState(prevState => ({ ...prevState, showFilterSection: false }))}>Select</button>
                                                </div>
                                            </div>
                                        </div>
                                    : null}
                                </div>
                            </div>
                        
                            <div className="btn-group align-self-center mb-3" role="group" aria-label="assetComparison">
                                <button type="button" className={`${state.showLoading ? 'disabled' : 'cursorPointer'} btn bg-cyon text-white`} onClick={()=> setState(prevState => ({ ...prevState, callSearch: true }))}>Search</button>
                                <button type="button" className={`${state.showLoading ? 'disabled' : 'cursorPointer'} btn bg-cyon text-white`} onClick={()=> onReset()}><i className='far fa-redo'></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className='row mx-0 mt-2 '>
                    <div className='col-sm-12 px-2 vh100'>
                        <div className="bg-black rounded h100">
                            {state.totalAccArray && state.totalAccArray.length ?
                                <div className="col-sm-12 d-flex justify-content-between p-2">
                                    <div className='d-flex w-50'>
                                        <div className='w-20'>
                                            <select className='form-control bg-transparent border-lightGray select1 text-lightGray' style={{minWidth: '150px'}} id="mySelect" 
                                                onChange={(e) => {
                                                    setState(prevState => ({ 
                                                        ...prevState, 
                                                        selectedCatSer: e.target.value,
                                                        showServiceTypeOptions: false,
                                                        totalItemShowing:0,
                                                        totalItemsPerPage: 0, 
                                                        categoryList: [],
                                                        filterCategoryList: [],
                                                        categoryServiceList: [],
                                                        emptySearchText: true,
                                                        callGetCoveredServices: true
                                                    }))
                                                }}>
                                                {state.catServiceType.map(item => {
                                                    return(
                                                        <option
                                                            key={item}
                                                            // className={`${state.selectedCatSer === item ? "selected" :  ""}`}
                                                            value={item}
                                                        >{item}</option>
                                                    )
                                                })}
                                            </select>
                                            <div className="select-sm-bg curvedDropdown minWidth120 displayNone" ref={toggleRef}>
                                                <div className="dropdown">
                                                    <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                                        {state.selectedCatSer ? state.selectedCatSer : <span className="placeholder">Select</span>}
                                                    </span>
                                                    <ul className={`dropdown-menu p-0 ${state.showServiceTypeOptions ? "show" : ''}`}>
                                                        {state.catServiceType.map(item => {
                                                            return (
                                                                <li 
                                                                    key={item}
                                                                    onClick={() => {
                                                                        setState(prevState => ({ 
                                                                            ...prevState, 
                                                                            selectedCatSer: item, 
                                                                            showServiceTypeOptions: false,
                                                                            totalItemShowing:0,
                                                                            totalItemsPerPage: 0, 
                                                                            categoryList: [],
                                                                            filterCategoryList: [],
                                                                            categoryServiceList: [],
                                                                            emptySearchText: true,
                                                                            callGetCoveredServices: true
                                                                        }))
                                                                    }}
                                                                    className={`${state.selectedCatSer === item ? "selected" :  ""}`}
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {state.categoryList && state.categoryList.length ?
                                            <SearchWithHiddenInput
                                                data={state.categoryList ? state.categoryList : []}
                                                applyLiteDarkTags={true}
                                                searchClassName={"border-0"}
                                                searchIconColor={"text-gray3"}
                                                className="form-control-sm text-gray3 bg-darkGray border-0"
                                                hideInputSection={true}
                                                searchPlaceHolder={'Search....'}
                                                topClassName={"dark-search align-self-center ml-3"}
                                                filteredData={(filterCategoryList, searchText) => {
                                                    setState(prevState => ({ ...prevState, filterCategoryList, emptySearchText: false }))
                                                }}
                                                resetSearchBox={state.emptySearchText}
                                            />
                                        : null}
                                    </div>
                                    
                                    <div className="d-flex justify-content-end align-self-center">
                                        <small className="align-self-center">
                                            Showing 
                                            {state.totalAccArray &&  state.totalAccArray.length && state.totalAccArray.length > state.totalItemsPerPage ?  
                                                ' '+((state.totalItemShowing + 1) - state.totalItemsPerPage) +' - '+ state.totalItemShowing +' ('+state.totalAccArray.length+') '
                                            : 
                                                state.totalAccArray.length ? 
                                                ' '+state.totalAccArray.length +' ('+state.totalAccArray.length+') '
                                                : ' 0'
                                            } 
                                        </small>
                                        <h5 className={`mb-0 mx-2 ${state.totalItemShowing > state.totalItemsPerPage ? 'text-white bg-info rounded-50' : 'bg-darkGray disabled rounded-50'} `}><span className="fad fa-arrow-circle-left cursorPointer" onClick={() => slideLeft()}></span></h5>
                                        
                                        <h5 className={`mb-0 ${state.totalAccArray.length > state.totalItemShowing ? 'text-white bg-info rounded-50' : 'bg-darkGray disabled rounded-50'}`}><span className="fad fa-arrow-circle-right cursorPointer" onClick={() => slideRight()}></span></h5>
                                    </div>
                                </div>
                            : null}
                            <div className="d-flex">
                                <div className="col-sm-1 p-0 border-right-info overflowYAuto vh100">
                                    <div className="w-100">
                                        {state.filterCategoryList && state.filterCategoryList.length ?
                                            state.filterCategoryList.map((item, i) => {
                                                return (
                                                <div key={'fil_'+i} className={`px-2 cursorPointer ${i ? "" : ""} ${state.selectedCategory && state.selectedCategory === (item.category || item.resource_type) ? 'bg-mediumDarkGray text-info disabled' : ""}`} onClick={() => onClickCategory(item)}>
                                                    <div className="hiddenEllipses d-flex" id={"category_"+i}>
                                                        <p className={`m-0 f-20 py-3 ${state.selectedCategory && state.selectedCategory === item.category ? 'text-info' : 'text-white'}`}>{item.category ? item.category : item.resource_type}</p>
                                                    </div>
                                                    <UncontrolledTooltip className='ucTooltipWhiteLiteBg' placement='top' target={"category_"+i}>
                                                        <div className="text-left">  
                                                            <p className="mb-1 text-white"><span className=" mr-1">{state.selectedCatSer}: </span>{item.category ? item.category : item.resource_type}</p>
                                                        </div>    
                                                    </UncontrolledTooltip>
                                                </div>
                                                )
                                            })
                                        : null}
                                    </div>
                                </div>
                                {state.showServicesLoading ?
                                    <div className='col-sm-12 d-flex justify-content-center mt-4'>
                                        <Spinner className='text-center' color='light' />
                                    </div>
                                :
                                    <div className="col-sm-11 p-0">
                                        <div className="w-100 overflowYAuto vh100">
                                            <div className="d-flex">
                                                {state.categoryServiceList && state.categoryServiceList.length ?
                                                    <div className="col-sm-2 border-right-info bg-darkGray">
                                                        <div className="d-flex justify-content-center" style={{minHeight: "50px"}}>
                                                            <p className="f16 text-white align-self-center mb-0">Services</p>
                                                        </div>
                                                        
                                                        {state.categoryServiceList.map((item, index) => {
                                                            return(
                                                                <div  key={'catSer_'+index} className="hiddenEllipses d-flex border-top-dark" id={"service_name_"+index}>
                                                                    <p className={`m-0 f-20 pl-2 py-3 ${state.selectedService === item.service_name ? 'text-info' : 'text-white'}`}>
                                                                    {item.service_name +(item.total ? " ("+item.total+") ": "")}
                                                                    </p>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                : null}
                                                <div className="col-sm-10 p-0">
                                                    {state.totalAccArray && state.totalAccArray.length ?
                                                        <div className={`col-sm-12 pl-0`}>
                                                            <div className="d-flex" ref={sliderWrap}>
                                                                {state.totalAccArray.filter(item => item.status === 1).slice(state.totalItemShowing - state.totalItemsPerPage, state.totalItemShowing).map((acc, index) => {
                                                                    return(
                                                                        state.categoryServiceList && state.categoryServiceList.length && state.categoryServiceList[0].data && state.categoryServiceList[0].data.hasOwnProperty(acc.key) ?
                                                                            <div  key={'acc_'+index} className={`d-flex justify-content-between minMax270Width bg-darkGray px-3 py-1 ${!index ? "" : "ml-2"}`}>
                                                                                <div className="text-white w-70">
                                                                                    {state.selectedShowBy === "Account" ?
                                                                                        <React.Fragment>
                                                                                        <p className="mb-0 hiddenEllipses text-center">
                                                                                            <span className={``}>{getAccountNameFromId(acc.key, state.accounts)}</span>
                                                                                            <span className="ml-2 text-primary-color">{acc.key}</span>
                                                                                        </p>
                                                                                        {state.selectedRegion.length && !state.selectedRegion.includes("All") ?
                                                                                            <p className="mb-0 hiddenEllipses">
                                                                                                <span className="">{state.selectedRegion[0]}</span>
                                                                                                <span className="ml-2 text-primary-color">{getRegionName(state.selectedRegion[0], state.regions)}</span>
                                                                                            </p>
                                                                                        : 
                                                                                            <p className="mb-0 text-center text-primary-color">All Region</p>
                                                                                        }
                                                                                        </React.Fragment>
                                                                                    : state.selectedShowBy === "Region" ? 
                                                                                        <React.Fragment>
                                                                                        {state.selectedAccount.length && !state.selectedAccount.includes("All") ?
                                                                                            <p className="mb-0 hiddenEllipses text-center">
                                                                                                <span className={``}>{getAccountNameFromId(state.selectedAccount[0], state.accounts)}</span>
                                                                                                <span className="ml-2 text-primary-color">{state.selectedAccount[0]}</span>
                                                                                            </p>
                                                                                        : 
                                                                                            <p className="mb-0 text-center text-primary-color">All Account</p>
                                                                                        }
                                                                                        <p className="mb-0 hiddenEllipses">
                                                                                            <span className={``}>{acc.key}</span>
                                                                                            {state.selectedShowBy === "Region" ? 
                                                                                                <span className="ml-2 text-primary-color">{state.selectedShowBy === "Region" ? getRegionName(acc.key, state.regions) : ""}</span>
                                                                                            : null}
                                                                                        </p>
                                                                                        </React.Fragment>
                                                                                    : 
                                                                                        acc.key.split("_").map((item, j) => {
                                                                                            return(
                                                                                                <span key={'split_'+j}>
                                                                                                {!j ?
                                                                                                    <p  className="mb-0 hiddenEllipses text-center">
                                                                                                        <span className={``}>{getAccountNameFromId(item, state.accounts)}</span>
                                                                                                        <span className="ml-2 text-primary-color">{item}</span>
                                                                                                    </p>
                                                                                                : 
                                                                                                    <p className="mb-0 hiddenEllipses">
                                                                                                        <span className={`text-white`}>{item}</span>
                                                                                                        <span className="ml-2 text-primary-color">{getRegionName(item, state.regions)}</span>
                                                                                                    </p>
                                                                                                }
                                                                                                </span>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                <div className={`w-30`}>
                                                                                    <div className={`text-right`}>
                                                                                        <div className="dropdown w-100">
                                                                                            <div><span className="far fa-chevron-square-down fa-lg cursorPointer" onClick={ () => showDropFunction(index) }>
                                                                                            </span></div>
                                                                                            <ul className={`comparisonRemoveDrop f10 ${state['showDrop'+index] && state['showDrop'+index] ? '' : 'displayNone' }`}>
                                                                                                <li onClick={ () => removeColumn(index)}><span>Remove</span></li>
                                                                                                <li className={` ${state['text_'+index] ? '' : 'mb-1'} `} onClick={()=> setState(prevState => ({ ...prevState, ['text_'+index]: state['text_'+index] ? false : true }))}><span>Move To</span></li>
                                                                                                <li className={`mb-1 ${state['text_'+index] ? '' : 'displayNone'} `}>
                                                                                                    <span>
                                                                                                        <input type="text" className="moveTextbox" onChange={e => onChangeText(e)} value={state.moveToDestination} />
                                                                                                        <button className="btn btn-sm btn-primary align-self-left moveBtn" onClick={()=> moveColumnTo(index, acc.index) }>Submit</button>
                                                                                                    </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                        {/* <p className="mb-0 text-muted f12">{getAccountNameFromId(state.resources[acc.key].account_id, state.accounts)}</p> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    : null
                                                                    )
                                                                })}
                                                            </div>
                                                            {state.categoryServiceList && state.categoryServiceList.length ?
                                                                state.categoryServiceList.map((item, iIndex) => {
                                                                    return(
                                                                        <div key={'fil_cat_'+iIndex} className="d-flex overflow-hidden">
                                                                            {state.totalAccArray.filter(item => item.status === 1).slice(state.totalItemShowing - state.totalItemsPerPage, state.totalItemShowing).map((acc, index) => {
                                                                                return(  
                                                                                    <div key={'acco_cat_'+index} className={`bg-darkGray minMax270Width py-3 border-top-dark text-white text-center cursorPointer ${!index ? "" : "ml-2"}`} onClick={() => redirectResourceSearch(acc, item)}>
                                                                                        {item.data && item.data[acc.key] ? item.data[acc.key] : "-"}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    : 
                                                        <div className='d-flex justify-content-center mt-4'>
                                                            <p className='mb-0'>No services for <span className='text-info'>{state.selectedCategory}</span></p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AssetComparison