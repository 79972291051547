/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file CreateNewBudget.js
 * @author Prakash // on 25/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'reactstrap';
import { setHpcPropsDetails } from '../../../../actions/Collider/HpcAction';
import { URL_PATH } from '../../../../config/urlPath';

const CreateNewBudget = () => {

    const [state, setState] = useState({
        // receivedData: {
        //     pageType: 'edit',
        //     selectedData: {
        //         "budget_id": '1',
        //         "budget_name": "Test",
        //         "financial_year": 2024,
        //         "department": "RDD",
        //         "initiative": "initiative1",
        //         "project": "project1",
        //         "details": [
        //           {
        //             "provider": "aws",
        //             "budget": 4800,
        //             "applies_to": [
        //               {
        //                 "provider": "aws",
        //                 "account_id": "554248189203",
        //                 "region": "us-east-1",
        //                 "cluster_name": "my-cluster",
        //                 "budget": 1000,
        //                 "queues": [
        //                   {
        //                     "queue_name": "large",
        //                     "budget": 150
        //                   },
        //                   {
        //                     "queue_name": "medium",
        //                     "budget": 150
        //                   },
        //                   {
        //                     "queue_name": "small",
        //                     "budget": 300
        //                   }
        //                 ],
        //                 "users": [
        //                   {
        //                     "user_id": "madhan@cloudinvisibl.onmicrosoft.com",
        //                     "budget": 400
        //                   },
        //                   {
        //                     "user_id": "9ac54765-8a75-4dbd-aaf9-5d1cadb9e46c",
        //                     "budget": 500
        //                   }
        //                 ]
        //               },
        //               {
        //                 "provider": "aws",
        //                 "account_id": "211125365329",
        //                 "region": "us-east-1",
        //                 "cluster_name": "awuse1nprpc01",
        //                 "budget": "2000"
        //               }
        //             ]
        //           },
        //           {
        //             "provider": "gcp",
        //             "budget": 4800,
        //             "applies_to": [
        //               {
        //                 "provider": "gcp",
        //                 "account_id": "develop-375210",
        //                 "region": "us-central1",
        //                 "cluster_name": "gceuw2nprhpc01",
        //                 "budget": "2500",
        //                 "queues": [
        //                   {
        //                     "queue_name": "LongQ",
        //                     "budget": 1000
        //                   },
        //                   {
        //                     "queue_name": "NewQ",
        //                     "budget": 200
        //                   },
        //                   {
        //                     "queue_name": "qFEA",
        //                     "budget": 500
        //                   },
        //                   {
        //                     "queue_name": "workq",
        //                     "budget": 450
        //                   }
        //                 ],
        //                 "users": []
        //               }
        //             ]
        //           }
        //         ],
        //         "description": "ssssssss"
        //     }
        // }
    })
    
    const location = useLocation();
    const receivedData = location.state;
    
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const onClickSave = useSelector(state => state?.hpc?.hpcPropsDetails?.onClickSave || false);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        event.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
        };

        const handlePopstate = () => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
        }
        };

        const handleBeforeRedirect = (location) => {
        const message = 'Are you sure you want to leave the form? Your changes may not be saved.';
        if (window.confirm(message)) {
            // Handle user confirmation, e.g., navigate away or perform other actions
            navigate(location.pathname); // Redirect manually after confirmation
        } else {
            // Prevent the redirect if the user cancels
            return false;
        }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopstate);

        // Use window.onbeforeunload to handle redirects
        const unblock = () => {};
        window.onbeforeunload = () => handleBeforeRedirect({ pathname: '/' });

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopstate);
            window.onbeforeunload = null; // Remove the onbeforeunload handler when the component is unmounted
            unblock(); // Remove the history.block when the component is unmounted
        };
    }, [navigate]); // Re-run the effect when navigate changes

    useEffect(() => {
        if(onClickSave === 'stopLoading') {
            setState(prevState => ({ ...prevState, onSaveFunction: false }))
            dispatch(setHpcPropsDetails('onClickSave', ''))
        }
    }, [onClickSave, dispatch])

    useEffect(() => {
        if(receivedData && Object.entries(receivedData).length) {
            setState(prevState => ({ 
                ...prevState, 
                selectedData: receivedData.selectedData ? receivedData.selectedData : '',
                pageType: receivedData.pageType ? receivedData.pageType : '',
            }))
        }
    }, [receivedData])

    const navigateTo = () => {
        navigate(URL_PATH.HPC_BUDGET); // Redirect to the specified path
    }

    return (
        <div className={`container-fluid overflow-auto flex-grow-1 px-0`}>
            <div className='d-flex'>
                <div className={`Gray-right col-md-6 p-0`}>
                    <LeftSection 
                        receivedData={receivedData}
                    />
                </div>
                <div className={`col-md-6 px-2`}>
                    <RightSection 
                        receivedData={receivedData}
                    />
                </div>
                <div className='fixed-buttons justify-content-end bg-transparent border-0'>
                    {state.onSaveFunction ?
                        <button className={`btn btn-primary mr-2`}>
                            <Spinner size='sm' color='light' className='mx-2' /> 
                            {state.pageType !== "edit" ?  "Saving"  : "Updating"}
                        </button>
                    :
                        <button className={`btn btn-primary mr-2`} onClick={() => {
                            setState(prevState => ({ ...prevState, onSaveFunction: true }))
                            dispatch(setHpcPropsDetails('onClickSave', 'save'))
                        }}>
                            {state.pageType !== "edit" ?  "Save"  : "Update"}
                        </button>
                    
                    }
                    <button className={`btn btn-secondary ${state.onSaveFunction ? "disbaled" : ""}`} onClick={() => navigateTo()}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default CreateNewBudget