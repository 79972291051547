/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertsDashboard.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import MultiSelectSection from '../../common/MultiSelectSection';
import { CSVLink } from "react-csv";

import { listAlerts } from '../../../actions/Collider/ObservabilityAction'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import Search from '../../common/SearchComponent'
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import AlertDetails from './AlertDetails';

// import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable'
// import RightSection from './RightSection'

const AlertsDashboard = (props) => {
    // const toggleRef = useRef()
    const [state, setState] = useState({

        showLoading: true,
        
        jumpDate: '',
        alertDetails: {},
        showAnnotation: true,
    
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,
        
        alertType: "All",
        groupedBy: 'severity',
        selectedCategory: 'Important / All',
        pageType: "Alerts",
        showRightSide: true,
    
        tagsArray: [0],
    })
    const dispatch = useDispatch(); // Create a dispatch function
    const tagKeys = useSelector(state => state.cost.costTagKeys);
    const hpcAlertPageFilters = useSelector(state => state?.hpc?.hpcPropsDetails?.hpcAlertPageFilters || false);

	useEffect(() => {
        if(hpcAlertPageFilters && Object.entries(hpcAlertPageFilters).length && !_.isEqual(hpcAlertPageFilters, state.hpcAlertPageFilters)) {
            if(hpcAlertPageFilters.start_time && hpcAlertPageFilters.end_time && hpcAlertPageFilters.selectedProvider) {
                setState(prevState => ({
                    ...prevState, 
                    hpcAlertPageFilters,
                    emptySearchText: true,
                    // start_time: hpcAlertPageFilters.start_time ? hpcAlertPageFilters.start_time : '',
                    // end_time: hpcAlertPageFilters.end_time ? hpcAlertPageFilters.end_time : '',
                    // selectedProvider: hpcAlertPageFilters.selectedProvider ? hpcAlertPageFilters.selectedProvider : '',
                    // selectedAccount: hpcAlertPageFilters.selectedAccount ? hpcAlertPageFilters.selectedAccount : '',
                    // selectedRegion: hpcAlertPageFilters.selectedRegion ? hpcAlertPageFilters.selectedRegion : '',
                    showRightSide: false,
                    alertListLoader: true,
                    callAlerts: true
                }))
            }
        }
    }, [hpcAlertPageFilters, state.hpcAlertPageFilters])

    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }
            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
	}

    const gotoPage = (pageNumber) => {
        if(pageNumber > 0  && pageNumber <= state.totalPages) {
            let startRecord = (pageNumber - 1) * state.perPage
            setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
        } else  if(pageNumber > state.totalPages) {
            setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
        } else {
            setState(prevState => ({ ...prevState, pageNumber }))
        }
    }

    useEffect(() => {
        if(state.callAlerts) {
            setState(prevState => ({ ...prevState, callAlerts: false }))
            let params = {}
            params.provider = hpcAlertPageFilters.selectedProvider.toLowerCase();

            if(hpcAlertPageFilters.selectedProjectTags && hpcAlertPageFilters.selectedProjectTags.length) {
                params.project_tags = hpcAlertPageFilters.selectedProjectTags
            }
            
            if(hpcAlertPageFilters.selectedAccount && hpcAlertPageFilters.selectedAccount.length) {
                params.account_id = hpcAlertPageFilters.selectedAccount
            }
            if(hpcAlertPageFilters.selectedRegion && hpcAlertPageFilters.selectedRegion.length) {
                params.region = hpcAlertPageFilters.selectedRegion
            }
            params.start_time = momentConvertionLocalToUtcTime(hpcAlertPageFilters.start_time, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(hpcAlertPageFilters.end_time, 'YYYY-MM-DD HH:mm:ss')
            params.aggregate_by = state.groupedBy
            if(state.alertType !== "All") {
                params.alert_type = state.alertType
            }
            params.include_name = true
    
            dispatch(listAlerts(params))
                .then((response) => {
                    if(response && !response.error) {
                        let data = response.results && response.results.details ? response.results.details : []
                        setState(prevState => ({ ...prevState, alertResponse: data, alertListLoader: false, structureAlerts: true }))
                        // this.props.setAiopsPropsDetails('tabOptions', {})
                    } else {
                        setState(prevState => ({ ...prevState, alertsList: [], alertListLoader: false }))
                    }
                })
        }
    }, [state.callAlerts, dispatch, state.alertType, state.groupedBy, hpcAlertPageFilters])

    useEffect(() => {
        setState(prevState => ({ ...prevState, structureAlerts: false }))
        let listAllAlerts = []
        
        let totalCount = 0
        let alertsCategory = []
        if(state.alertResponse && state.alertResponse.length) {
            totalCount = state.alertResponse.reduce( function(a, b){ return a + b.count;}, 0);
            state.alertResponse.forEach((item, index) => {
                let dataRow = {}
                dataRow.count = item.count
                dataRow.category = item.label
                dataRow.orderBy = index+1
                let percentage = Math.ceil(((item.count / totalCount) * 100)/10)
                let array = []
                for(let i=1; i<=percentage; i++) {
                    array.push(i)
                }
                dataRow.totalBars = array

                alertsCategory.push(dataRow)
                item.alerts.forEach(alt => {
                    listAllAlerts.push(alt)
                })
            })
        }

        let dataRow = {}
        dataRow.count = ''
        dataRow.category = 'Important / All'
        dataRow.orderBy = 0
        alertsCategory.push(dataRow)

        setState(prevState => ({ ...prevState, listAllAlerts, alertsList: listAllAlerts, alertsCategory: _.orderBy(alertsCategory, ['orderBy'], ['asc']), getCategoryAlerts: true}))
    }, [state.structureAlerts, state.alertResponse])
    
    const getAlertDetails = useCallback((alertItemDetails) => {
        alertItemDetails.group_id = alertItemDetails.event_id ? alertItemDetails.event_id : ''
        alertItemDetails.type = 'alert'
        setState(prevState => ({ ...prevState, selectedAlertId: alertItemDetails.event_id }))

        let obj = {}
        obj.selectedTab = 'Details'
        obj.details = alertItemDetails
        obj.merticsAssetNameInvolved = alertItemDetails.asset_id ? [alertItemDetails.asset_id] : []
        obj.datePickerStartDate = hpcAlertPageFilters.start_time 
        obj.datePickerEndDate = hpcAlertPageFilters.end_time 
        obj.pageType = "Alerts"
        dispatch(setHpcPropsDetails('alertDetails', obj))
    }, [hpcAlertPageFilters.end_time , hpcAlertPageFilters.start_time , dispatch])

    useEffect(() => {
        setState(prevState => ({ ...prevState, getCategoryAlerts: false }))
        let filterdCategoryAlerts = state.listAllAlerts
        if(state.selectedCategory !== 'Important / All') {
            filterdCategoryAlerts = state.alertResponse.filter(arr => arr.label === state.selectedCategory)
            if(filterdCategoryAlerts && filterdCategoryAlerts.length) {
                filterdCategoryAlerts = filterdCategoryAlerts[0].alerts ? filterdCategoryAlerts[0].alerts : []
            }
        }

        let orderedArray = _.orderBy(filterdCategoryAlerts, ['event_time'], ['desc'])

        let totalPages = 1
		if(orderedArray.length > state.perPage) {
			totalPages = Math.ceil(orderedArray.length / state.perPage)
		}

        setState(prevState => ({ ...prevState, totalFilteredArray: orderedArray, filteredArray: orderedArray, selectedAlertId: '', totalPages, startRecord: 0, currentPage: 1, callFormHeaders: true }))
        if(orderedArray && orderedArray.length) {
            getAlertDetails(orderedArray[0])
        }
    }, [state.getCategoryAlerts, state.alertResponse, state.listAllAlerts, state.perPage, state.selectedCategory, getAlertDetails])    
    
    const handleChange = (event) => {
        let groupedBy = state.groupedBy
        if(event.target.value === "Application" && groupedBy !== "severity" && groupedBy!== "event_name") {
            groupedBy = "severity"
        }
        setState(prevState => ({ ...prevState, alertType: event.target.value, groupedBy, selectedAlertId: '', alertListLoader: true, callAlerts: true }))
    }

    // handleClickOutside(event) {
    //     if (this.toggleRef && !this.toggleRef.current.contains(event.target)) {
    //         this.setState({ showPageTypeOptions: false })
    //     } else {
    //         this.setState({ showPageTypeOptions: true })
    //     }
    // }

    const getAllTagsKeys = () => {
        if(!tagKeys || !tagKeys.length) {
            let params = {}
            dispatch(getAllTagsKeys(params))
        }
    }

    // useEffect(() => {
	// 	if(state.tag_key && state.tag_key.length) {
	// 		let params = {}
	// 		params.tags = [{ "key": state.tag_key }]
	// 		dispatch(getAllTagsValues(params))
	// 			.then((response) => {
	// 				if(response) {
	// 					setState(prevState => ({ ...prevState, tagValues: response }))
	// 				}
	// 			})
	// 	}
	// }, [state.tag_key, dispatch])

    const addNewTag = (index) => {
		let selectedTags = state.selected_tags ? state.selected_tags : []

		if(state.tag_key && state.tag_key !== '' && state.tag_value && state.tag_value.length) {
			state.tag_value.forEach(tag => {
				if(!selectedTags.filter(e => e.key === state.tag_key && e.value === tag).length) {
					let dataRow = {}
					dataRow.key = state.tag_key
                    dataRow.value = tag
					selectedTags.push(dataRow)
				}
			})
		}

        setState(prevState => ({ ...prevState, selectedTags, tag_key: '', tag_value: [], tagValues: []}))
    }

    const resetFilterSelection = () => {
        state.tagsArray.forEach(item => {
            setState(prevState => ({ ...prevState, ["tag_key_"+item]: "", ["tag_value_"+item]: "" }))
        })

        setState(prevState => ({ ...prevState, tagsArray: [0], selectedService: [], assetCategory: [] }))
    }

    // const filteredResults = () => {
    //     let results = state.totalFilteredArray ? state.totalFilteredArray : []
        
    //     if(results && results.length && state.tagsArray && state.tagsArray.length) {
    //         let tagMatchedData = []
    //         let recordExist = false
    //         state.tagsArray.forEach(item => {
    //             if(state["tag_key_"+item] && state["tag_key_"+item] !== "" && state["tag_value_"+item] && state["tag_value_"+item] !== "") {
    //                 recordExist = true
    //                 if(tagMatchedData && tagMatchedData.length) {
    //                     let newTagsMatched = []
    //                     tagMatchedData.forEach(itm => {
    //                         if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === state["tag_key_"+item] && e.value === state["tag_value_"+item]).length) {
    //                             newTagsMatched.push(itm)
    //                         }
    //                     })
    //                     tagMatchedData = newTagsMatched
    //                 } else {
    //                     results.forEach(itm => {
    //                         if(itm.tags && itm.tags.length && itm.tags.filter(e => e.key === state["tag_key_"+item] && e.value === state["tag_value_"+item]).length) {
    //                             tagMatchedData.push(itm)
    //                         }
    //                     })                        
    //                 }
    //             }
    //         })
    //         if(recordExist) {
    //             results = tagMatchedData
    //         }
    //     }

    //     if(state.searchText && state.searchText !== '') {
	// 		results =
	// 			results &&
	// 			results.filter(item => {
	// 				let isPresent = []
	// 				isPresent = recursiveSearch(item, state.searchText)
	// 					.flat()
	// 					.filter(bool => bool === true)
	// 				if (isPresent[0]) {
	// 					return true
	// 				} else {
	// 					return false
	// 				}
	// 			})
	// 	}

    //     let totalPages = 1
    //     if(results && results.length > state.perPage) {
    //         totalPages = Math.ceil(results.length / state.perPage)
    //     }
    //     let showLoadMore = false
    //     if(results.length > state.endRecord) {
    //         showLoadMore = true
    //     } 

    //     setState(prevState => ({ ...prevState, filteredArray: results, totalPages, startRecord: 0, currentPage: 1, showLoadMore }))
    //     // formHeaders()    
    // }

	useEffect(() => {
        if(state.callFormHeaders) {
            setState(prevState => ({ ...prevState, callFormHeaders: false }))
            let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
            if(dataResult.length) {
                let headers = []
                Object.entries(dataResult[0]).forEach(([key, value]) => {
                    if(typeof value === "string" && key !== "event_data") {
                        let headerRow = {}
                        headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                        headerRow.key = key
                        headers.push(headerRow)
                    }
                })
                setState(prevState => ({ ...prevState, headers }))
            }
        }
	}, [state.callFormHeaders, state.filteredArray])

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="" onClick={(event) => handleChildClick(event, 'parent')}>
            <div className="w-100">
                <div className={`d-flex mt-2 ml-1 ${state.alertListLoader ? 'disabled' : ''}`}>
                    <p className="m-0 align-self-end small mr-2">Show </p>
                    <div className={`transparentSelectBorderBottom align-self-end`}>
                        <select className="form-control" 
                        onChange={handleChange}
                        value={state.alertType}
                        >
                            <option key="All" value="All">ALL</option>
                            <option key="Application" value="Application">Application</option>
                            <option key="Infra" value="Infra">Infra</option>
                        </select>
                    </div> 
                    <p className="m-0 align-self-end small mr-2"> alerts groupd by</p>
                    <div className={`transparentSelectBorderBottom align-self-end`}>
                        <select className="form-control" 
                            onChange={(event) => {
                                setState(prevState => ({ ...prevState, groupedBy: event.target.value, selectedAlertId: '', selectedCategory: "Important / All", alertListLoader: true, callAlerts: true }))
                            }}
                            value={state.groupedBy}                            
                        >
                            <option value='severity'>Severity</option>
                            <option value='event_name'>Name</option>
                            {state.alertType !== "Application" ?
                                <React.Fragment>
                                <option value='asset_name'>Assets</option>
                                <option value='resource_type'>Service</option>
                                <option value='category'>Category</option>
                                </React.Fragment>
                            : null}
                        </select>
                    </div>
                    <i className="fas fa-filter align-self-end cursorPointer ml-3 f14" onClick={() => {
                        setState(prevState => ({ ...prevState, showFilterSection: !state.showFilterSection }))
                        getAllTagsKeys()
                    }}></i>
                </div>
                
                <div className="d-flex mt-2">
                    <div className={`${state.expandRIghtSection ? 'w-60' : 'w-60'} mr-1`}>
                        {state.showFilterSection ?
                            <div className="observabilityEventDashboardFilter border mt-2">
                                <div className="p-3 zapInputDark bg-muted">
                                    <div className={`w-100 mt-2`}>
                                        <label className="mb-1">Tags</label>
                                        <div className={`w-100`}>
                                            <div className={`d-flex`}>
                                                <div className='w-40' onClick={(event) => {
                                                    if(state.show_tag_key) {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'singleDropDown', 'show_tag_key')
                                                    }
                                                }}>
                                                    <p className={`d-flex mr-2 mb-0 bg-mediumDarkGray p-2 rounded-5 ${state.tag_key && state.tag_key.length ? 'text-white' : 'placeholder'}`}>{state.tag_key ? state.tag_key : 'Select'}</p>
                                                    {state.show_tag_key ?
                                                        <div className='position-relative'>
                                                            <MultiSelectSection 
                                                                // fields={["region", "name"]}
                                                                // className={'w-70'}
                                                                removeTop={true}
                                                                options={tagKeys}
                                                                selectedValues={state.tag_key ? state.tag_key : []}
                                                                callbackMultiSelect={(value) => {
                                                                    if(!value || typeof(value) === 'string') {
                                                                        setState(prevState => ({ ...prevState, tag_key: value, tagValues: [] }))
                                                                    } else {
                                                                        value.preventDefault()
                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                    }
                                                                }}
                                                                singleSelection={true}
                                                                hideSearch={false}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                                {state.tag_key && !state.tagValues.length ?
                                                    <div className='ml-3'>
                                                        <Spinner className='text-center align-self-center' color='dark' size='sm' />
                                                    </div>
                                                :
                                                    <div className='w-40 mr-2' onClick={(event) => {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'muliselectDropDown', 'show_tag_value')
                                                    }}>
                                                        <p className={`d-flex mr-2 mb-0 bg-mediumDarkGray p-2 rounded-5 ${state.tag_value && state.tag_value.length ? 'text-white' : 'placeholder'}`} >
                                                            {state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}
                                                        </p>
                                                        {state.show_tag_value ?
                                                            <div className='position-relative'>
                                                                <MultiSelectSection 
                                                                    // fields={["region", "name"]}
                                                                    // className={'position-unset'}
                                                                    removeTop={true}
                                                                    options={state.tagValues}
                                                                    selectedValues={state.tag_value ? state.tag_value : []}
                                                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
                                                                />
                                                            </div>
                                                        : null}
                                                    </div>
                                                }
                                                <span className={`far fa-plus cursor-pointer f18 align-self-center ml-4`} onClick={() => addNewTag()}></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                        <span className={`text-info mr-2 align-self-center`} onClick={() => resetFilterSelection()}>Clear</span>
                                        <button className={`btn btn-sm bg-info text-white mr-2`} onClick={() => setState(prevState => ({ ...prevState, showFilterSection: false }))}>Filter</button>
                                        <button className={`btn btn-sm btn-light`} onClick={() => setState(prevState => ({ ...prevState, showFilterSection: false }))}>Close</button>
                                    </div>
                                </div>
                            </div>
                        : null}
                        <div className="rounded bg-dark p-3">
                            <div className="d-flex mb-2 justify-content-between">
                                <div className="mr-2 w-40">
                                    <p className="f12 m-0 align-self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.totalFilteredArray && state.totalFilteredArray.length}{state.totalFilteredArray && state.totalFilteredArray.length > 1 ? ' alerts' : ' alert'}</p>
                                    {/* {state.totalErrorList && state.totalErrorList.length}{state.totalErrorList && state.totalErrorList.length > 1 ? ' errors' : ' error'} from </p> */}
                                    <p className="f12 m-0 align-self-center pt-1 text-info ml-1">{hpcAlertPageFilters.start_time  ? momentDateGivenFormat(hpcAlertPageFilters.start_time , 'DD MMM YYYY') +' - '+ momentDateGivenFormat(hpcAlertPageFilters.end_time , 'DD MMM YYYY') : ''}</p>
                                    {/*  */}
                                </div>
                                <div className="d-flex justify-content-end w-60 mb-n2 align-self-center">
                                    {state.totalFilteredArray && state.totalFilteredArray.length ?
                                        <CSVLink 
                                            data={state.filteredArray} 
                                            headers={state.headers ? state.headers : []}
                                            filename={"observability-alerts.csv"}
                                            className={"align-self-center"}
                                            target="_blank"
                                        >
                                            <i className="fas fa-download text-primary-color mr-2"></i>
                                            {/* <span className="text-primary-color mx-2">csv</span> */}
                                        </CSVLink>
                                    : null}
                                    {state.totalFilteredArray && state.totalFilteredArray.length ?
                                        <Search
                                            data={state.totalFilteredArray ? state.totalFilteredArray : []}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-gray5 f12'}
                                            searchPlaceHolder={'Search alerts....'}
                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                            resetSearchBox={state.emptySearchText}
                                            filteredData={(filteredArray) => {
                                                setState(prevState => ({ ...prevState, filteredArray, alertDetails: {}, merticsAssetNameInvolved: [], emptySearchText: false }))
                                                if(filteredArray && filteredArray.length) {
                                                    getAlertDetails(filteredArray[0])
                                                }
                                            }}
                                        />
                                    : null}
                                    {state.filteredArray && state.filteredArray.length > state.perPage ?
                                        <div className="pagination errorPagePagination f12">
                                            <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                            <span>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ""
                                                        gotoPage(page)
                                                    }}
                                                    className="ml-1 inputClass"
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                        </div>
                                    : null}
                                </div>
                            </div>
                            
                            <div className="d-flex errorSectionScroll">
                                {state.alertsCategory ? 
                                    <div className="w-20 pr-3 categoryBoxSection">
                                        <div className="bg-muted px-2 pt-2 select-bgg">
                                            {state.groupedBy === "severity" ?
                                                state.alertsCategory.map(item => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                            <div className={`d-flex p-2 cursorPointer ${state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))}>
                                                                <p className="m-0 f-20">{item.category}</p>
                                                                <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                            </div>
                                                        :
                                                            <div className={`d-flex justify-content-end mb-2 ${!item.count ? 'disabled opacity50' : 'cursorPointer'}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))}>
                                                                <div className="d-flex">
                                                                    {item.totalBars.map(bar => {
                                                                        return(
                                                                        <div className={`line-survey-1 risk-bg-${item.category.toLowerCase()}`}></div>
                                                                        )
                                                                    })}
                                                                    <div className={`font-weight-bold text-center risk-border-${item.category.toLowerCase()} text-white p-2`}>
                                                                        <p className="small mb-0" style={{minWidth: "75px"}}>{item.category+" "+item.count}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    )
                                                })                                            
                                            : 
                                                state.alertsCategory.map((item, index) => {
                                                    return(
                                                        item.category === "Important / All" ?
                                                            <div className={`d-flex cursorPointer mb-3 ${state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))}>
                                                                <p className="m-0 f-20">{item.category}</p>
                                                                <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                            </div>
                                                        :
                                                        <div className={`d-flex cursorPointer mb-2 ${state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, getCategoryAlerts: true }))}>
                                                            <div className="d-flex justify-content-between mb-2" style={{maxWidth: "160px"}}>
                                                                <p className="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                                <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                                <input className="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                : null}
                                {/* {state.alertsCategory ? 
                                    <div className="mr-1 w-20">
                                        <div className="col-12 p-0 bg-dark3 h-100">
                                            {state.alertsCategory.map(item => {
                                                return(
                                                    <div className={`d-flex p-2 border-bottom cursorPointer ${state.selectedCategory === item.category ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled' : 'text-white')}`} onClick={() => this.setState({ selectedCategory: item.category }, () => this.getCategoryAlerts())}>
                                                        <p className="m-0 f-20">{item.category}</p>
                                                        <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                : null} */}
                                <div className={`${state.alertsCategory ? 'w-80' : 'w-100'}`}>
                                    <div className={`bg-dark3 py-3 pl-2`} style={{minHeight: "545px"}}>
                                        {state.alertListLoader ? 
                                            <div className='d-flex justify-content-center m-4'>
                                                <Spinner className='text-center' color='white' size='lg' />
                                            </div>
                                        : state.filteredArray && state.filteredArray.length ?
                                            state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                return(
                                                    <React.Fragment>
                                                    <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${state.startRecord + state.perPage !== state.startRecord + i ? 'border-bottom-black5' : ''} ${state.selectedAlertId !== item.group_id ? 'cursorPointer' : 'bg-muted text-white pt-1'}`} 
                                                    onClick={() => getAlertDetails(item)}
                                                    >  
                                                        <div className="d-flex">
                                                            <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                                {item.severity ?    
                                                                    (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                                : 'U'}
                                                            </span>
                                                            <p className="mb-0">
                                                                <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                {/* <span className="f12 mb-0 mr-2 text-purple-2"> {item.total_alerts+ (item.total_alerts>1 ?' alerts':' alert')}</span>  */}
                                                                <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                                    {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                                    {item.event_name ? ' : ' + item.event_name : ''}
                                                                    {item.event_type ? ' : '+ item.event_type : ''}</span>
                                                                {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_type ? item.event_type : ""}</span> */}
                                                                {/* <span className="mb-0 mr-2 f12 text-purple-2 font-weight-bold">{item.event_name ? item.event_name : ""}</span> */}
                                                                {item.asset_name ?
                                                                    <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                                : null}
                                                                <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                {item.account_id ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+item.account_id}</span>
                                                                : null}
                                                                {item.region ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+item.region}</span>
                                                                : null}
                                                                {item.resource_type ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                                : null}
                                                                {item.service_name ?
                                                                    <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                                : null}
                                                                {item.description ?
                                                                    <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.description}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    </React.Fragment>
                                                )
                                            })
                                        :
                                            <div className='d-flex justify-content-center m-4'>
                                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!state.alertListLoader ?
                        <div className={`${state.expandRIghtSection ? 'w-40' : 'w-40'}`}>
                            <AlertDetails />
                        </div>
                    : null}
                </div>
            </div>
        </div>
    )
}

export default AlertsDashboard