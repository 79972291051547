import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
// import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { listWorkstatioRequestHistory } from '../../../actions/CatalogAction'
import WorkstationSidePanel from '../WorkstationSidePanel';
import Search from '../../common/SearchWithHiddenInput'
import { CSVLink } from 'react-csv';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { capitalizeFirstLetter, capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime } from '../../../utils/utility';

const RequestHistory = (props) => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion
    })

    const dispatch = useDispatch(); // Create a dispatch function
    // const history = useHistory();
    // const navigate = useNavigate();

    useEffect(() => {
        setState(prevState => ({ ...prevState, callListReqeustHistory: true }))
    }, [])

    useEffect(() => {
        if(state.callListReqeustHistory) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextHistoryToken) {
                params.next_token = state.nextHistoryToken
            }
    
            dispatch(listWorkstatioRequestHistory(params))
                .then((response) => {
                    if(response) {
                        let totalResults = [] 
                        if(state.nextHistoryToken) {
                            totalResults = state.listRequestHistory &&  state.listRequestHistory.length ? state.listRequestHistory : []
                        }

                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                        }
                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                        
                        setState(prevState => ({ ...prevState, listRequestHistory: totalResults, filterListRequests: totalResults, detailsFormat, selectedFilters: params, nextHistoryToken: response.next_token, showLoading: false, callListReqeustHistory: false }));
                    }
                })
        }
    }, [state.callListReqeustHistory, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listRequestHistory, state.nextHistoryToken])

    

	useEffect(() => {
        let dataResult = state.filterListRequests && state.filterListRequests.length ? state.filterListRequests : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListRequests])

    return (
        state.showLoading ?
            <div className='d-flex justify-content-center m-4'>
                <Spinner className='text-center' color='light'   />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedDetails={state.selectedDetails}
                    showOnlyDeatils={true}
                />
            : null}
            <div className='p-2 h-100 overflowYAuto'>
                <div className='mt-2'>
                    <div className='d-flex justify-content-between pt-2'>
                        <div className=''>
                            <p className='mb-0'>
                                <small>Showing {state.filterListRequests && state.filterListRequests.length ? state.filterListRequests.length : 0 } out of total {state.listRequestHistory && state.listRequestHistory.length ? state.listRequestHistory.length : 0} request history</small>
                                {state.selectedProvider ?
                                    <React.Fragment>
                                    <small className='ml-1'>obtained from the</small>
                                    <small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
                                    <small className='ml-1'>provider</small>
                                    </React.Fragment>
                                : null}
                                {state.selectedAccount && state.selectedAccount.length ?
                                    <React.Fragment>
                                    <small className='ml-1'>with the account</small>
                                    <small className='badge badge-outline-info mx-1'>{state.selectedAccount[0]}</small>
                                    {state.selectedAccount.length > 1 ?
                                        <small className='ml-1 text-info'>more</small>
                                    : null}
                                    </React.Fragment>
                                : null}
                                {state.selectedRegion && state.selectedRegion.length ?
                                    <React.Fragment>
                                    <small className='ml-1'>in the</small>
                                    <small className='badge badge-outline-info mx-1'>{state.selectedRegion[0]}</small>
                                    {state.selectedRegion.length > 1 ?
                                        <small className='ml-1 text-info'>more</small>
                                    : null}
                                    <small className='ml-1'>region</small>
                                    </React.Fragment>
                                : null}
                            </p>
                        </div>
                        
                        <div className='align-self-center w-30'>
                            <div className='d-flex justify-content-end'>
                                {state.filterListRequests && state.filterListRequests.length ?
                                    <CSVLink
                                        data={state.filterListRequests ? state.filterListRequests : []} 
                                        headers={state.tableHeaders ? state.tableHeaders : []}
                                        filename={'workstation_history_'+new Date().getTime()+'.csv'}
                                        className={'mt-2'}
                                        target='_blank'
                                    >
                                        <span className='d-flex text-primary-color mr-2'>
                                            <i className='fas fa-download text-primary-color mr-2'/>
                                        </span>
                                    </CSVLink>
                                : null}
                                {state.listRequestHistory && state.listRequestHistory.length ?
                                    <Search
                                        data={state.listRequestHistory ? state.listRequestHistory : []}
                                        applyTags={false}
                                        applyLiteDarkTags={true}
                                        topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                        searchClassName={'px-2 f12'}
                                        searchIconColor={'text-white f12'}
                                        searchPlaceHolder={'Search....'}
                                        className={"bg-transparent text-white form-control-sm f12"}
                                        filteredData={(filterListRequests) => setState(prevState => ({ ...prevState, filterListRequests }))}
                                    />
                                : null}
                            </div>                                    
                        </div>
                    </div>

                    <div className='row mx-0'>
                        <div className='col-lg-12 col-12 px-0 py-1'>
                            {state.filterListRequests && state.filterListRequests.length ?
                                <ResizeableDarkThemeTable
                                    columns={[
                                        {
                                            Header: 'Name',
                                            accessor: 'workstation_name',
                                            Cell: cellInfo => (
                                                <div className="d-flex justify-content-between cursor-pointer align-self-center">
                                                    <div className='text-info mr-2' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))}>{cellInfo.row.original.workstation_name}</div>
                                                </div>
                                            ),
                                            width: 200
                                        },
                                        {
                                            Header: 'Status',
                                            accessor: 'Status',
                                            Cell: cellInfo => (
                                                cellInfo.row.original.status ?
                                                    <span className={`badge badge-outline-${cellInfo.row.original.status === 'rejected' ? 'danger' : cellInfo.row.original.status === 'approved' ? 'success' : 'info'}`}>{cellInfo.row.original.status}</span>
                                                : 
                                                    <span className={`badge badge-outline-secondary`}>No Status</span>
                                            ),
                                        },
                                        {
                                            Header: 'Date',
                                            accessor: 'created_at',
                                            Cell: cellInfo => (<span>{cellInfo.row.original.created_at ?  momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : ''}</span>),
                                            width: 200
                                        },
                                        {
                                            Header: 'Requested By',
                                            accessor: 'user_name',
                                            width: 200
                                        },
                                        {
                                            Header: 'Category',
                                            accessor: d => d.category ? capitalizeFirstLetter(d.category) : '',
                                            width: 150,
                                        },
                                        {
                                            Header: 'Resources',
                                            accessor: d => d.provider + ' : ' + d.account_id + ' : ' + d.region,
                                            Cell: cellInfo => (
                                                <React.Fragment>
                                                    <span className=''>
                                                        {(cellInfo.row.original.asset_name
                                                            ? cellInfo.row.original.asset_name + ' : '
                                                            : '') +
                                                            cellInfo.row.original.provider +
                                                            ' : ' +
                                                            cellInfo.row.original.account_id +
                                                            ' : ' +
                                                            (cellInfo.row.original.region === 'NA'
                                                                ? 'Global'
                                                                : cellInfo.row.original.region)}
                                                    </span>
                                                </React.Fragment>
                                            ),
                                            width: 300
                                        },
                                        {
                                            Header: 'Instance Type',
                                            // accessor: 'instance_type',
                                            accessor: d => d.instance_type + ' : ' + d.vcpus + ' : ' + d.memory,
                                            Cell: cellInfo => (
                                                <React.Fragment>
                                                    <span className=''>
                                                        {(cellInfo.row.original.instance_type ? cellInfo.row.original.instance_type : '') + '  ('+
                                                        cellInfo.row.original.vcpus + ' vCPUs'+
                                                        '     memory' +
                                                        cellInfo.row.original.memory +
                                                        '  '+
                                                        (cellInfo.row.original.memory_unit ? cellInfo.row.original.memory_unit : '')
                                                        +')'}
                                                    </span>
                                                </React.Fragment>
                                            ),
                                            width: 250,
                                        },
                                        {
                                            Header: 'Description',
                                            accessor: 'description',
                                            width: 350,
                                        },
                                        // {
                                        //     Header: ' ',
                                        //     Cell: cellInfo => (
                                        //         <div className="d-flex justify-content-between cursor-pointer align-self-center">
                                        //             <div className='text-info mr-2'>Details</div>
                                        //         </div>
                                        //     ),
                                        //     width: 100
                                        // }
                                    ]}
                                    data={state.filterListRequests}
                                    perPage={10}
                                    tableHead={'bg-black text-white'}
                                    tableBody={'bg-dark3 text-white'}
                                    tableType={'table-dark-hover-none'}
                                    perPageClass={`bg-transparent text-lightGray`}
                                    textColor={'text-black'}
                                    selectedColor={'bg-transparent'}
                                    dashboard={state.filterListRequests && state.filterListRequests.length ? true : false}
                                    sortByColumn={''}
                                    riskTooltip={[0]}
                                    onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistRequestHistory: tableRow }))}
                                    // tableSize={'table-sm'}
                                    paginationIndex={state.paginationIndex ? state.paginationIndex : 0}
                                    checkForNextSetoffRecords={state.nextHistoryToken ? true : false}
                                    callToFetchNewRecord={(paginationIndex) => {
                                        if(state.nextHistoryToken) {
                                            setState(prevState => ({ ...prevState, callListReqeustHistory: true, paginationIndex }))
                                        }
                                    }}
                                    showPaginationTop={false}
                                />
                            : 
                                <div className='w-100 d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
    );
};

export default RequestHistory