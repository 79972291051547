/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file ManagePolicies.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { listAllProviders, listAllAccounts, listAllRegions, getAllTagsKeys, getAllTagsValues, setCommonPropsDetails } from '../../actions/commonActionNew'
import { getGovMetaData, listGovernancePolicies, getPolicyDetails, listAssets, cleareNewEditRuleDetails, setGovernancePropsDetails, listGovernanceType, deleteGovernancePolicy } from '../../actions/governance/GovernanceAction'
import _ from 'lodash'
import { CSVLink } from "react-csv"
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../common/SearchComponent'
import { URL_PATH } from '../../config/urlPath'
import MultiSelectSection from '../common/MultiSelectSection';

import { capitalizeAllLetter, getAccountNameFromId, getRegionName, capitalizeTheFirstLetterOfEachWord } from '../../utils/utility'
import FilterSidePanel from './FilterSidePanel';
import { Store as CommonNotification } from 'react-notifications-component';
// import FilterSidePanel from './FilterSidePanel'
// import ViewSidePanel from './ViewSidePanel'

const ManagePolicies = () => {
	const advanceSearchRef = useRef();
	const tagKeyRef = useRef();
	const tagValueRef = useRef();

	const [state, setState] = useState({
		queryLoading: true,
		showLoading: false,
		selectedGovernanceType: "All",
		filteredArray: [],
		viewOptions: [
			{label: "Policy", value: "policy_wise"},
			{label: "Policy Rule", value: "rule_wise"}
		],
		policyTypes: [
			{label: "Configured", value: "Configured"},
			{label: "Not Configured", value: "Not Configured"},
			{label: "All Rules", value: "all_rules"}
		],
		selectedViewOption: "policy_wise",
		selectedView: "policy_wise",
		selectedPolicyType: "Configured",
	})

	const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
	const location = useLocation()

    const currentPath = location.pathname;
	const tagKeys = useSelector(state => state?.filters?.tagKeys);	
    const providers = useSelector(state => state?.filters?.providers || false)
    const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
					if(row.action) {
						actionsAllowed = row.action
					}
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
        if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "",  callSearch: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name : "", callSearch: true }));
                    }
                })
        }
    }, [dispatch, providers]);// Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
		dispatch((listGovernanceType()))
			.then((response)=> {
				if(response) {
					setState(prevState => ({ ...prevState, governanceTypes: response && response.length ? response : [] }))
				}
			})
	}, [dispatch])

	useEffect(() => {
		if(state.queryLoading) {
			setTimeout(() => { setState(prevState => ({ ...prevState, queryLoading: false })) }, 10000);
		}
	}, [state.queryLoading])

	useEffect(() => {
		let params = {}
		dispatch(getGovMetaData(params))
			.then((response) => {
				if(response) {
					if(!response.error) {
						setState(prevState => ({ ...prevState, metaData: response }))
					} else {
						setState(prevState => ({ ...prevState, metaData: [] }))
					}
				}
			})
    }, [dispatch]); // Empty dependency array ensures this runs only once on mount	

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {
				"enabled": true,
			}	
	
			if(state.selectedGovernanceType && state.selectedGovernanceType !== "All") {
				params.governance_type = state.selectedGovernanceType
			}
	
			if(state.selectedViewOption) {
				params.view = state.selectedViewOption
				if(state.selectedPolicyType === "all_rules") {
					params.all_rules = true
				}
			}
	
			
			let appliesTo = {}
			if(state.selectedAccount && state.selectedAccount !== "") {
				appliesTo.account_id = state.selectedAccount
			}
			if(state.selectedRegion && state.selectedRegion.length) {
				appliesTo.region = state.selectedRegion
			}
			if(state.selectedTags && state.selectedTags.length) {
				appliesTo.tags = state.selectedTags
			}
			if(state.selectedUsers && state.selectedUsers.length) {
				appliesTo.users = state.selectedUsers
			}
			if(state.selectedRoles && state.selectedRoles.length) {
				appliesTo.roles = state.selectedRoles
			}
	
			if(state.selectedGovernanceType !== "opa" && appliesTo && Object.entries(appliesTo).length) {
				params.applies_to = appliesTo
			}
	
			if(state.selectedViewOption === "rule_wise") {
				if(state.selectedFilters && state.selectedFilters.length) {
					let groupedData = _.chain(state.selectedFilters)
									.groupBy('label')
									.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
	
					groupedData.forEach(row => {
						let data = []
						row.groupValue.forEach(item => {
							data.push(item.value)
						})
						params[row.groupLabel] = data
					})
		
				}
			}
	
			params.not_configured = false
			if(state.selectedPolicyType === "Not Configured") {
				params.not_configured = true
			}
			
			
			let showFilteredList = true
			if(Object.entries(params).length === 2) {
				showFilteredList = false
			}
	
			let dynamicRulePoliceColumns = []
			if(state.selectedViewOption === "rule_wise") {
				let dataRow = {}
		
				dataRow.Header = 'Type'
				dataRow.accessor = 'governance_type'
				dataRow.Cell = cellInfo => (
					<span>{cellInfo.row.original.governance_type ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type).length ? state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
				)
				dataRow.width = 200
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}
				dataRow.Header = 'Service'
				dataRow.accessor = 'resource_type'	
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 100	: 250
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}
				dataRow.Header = 'Category'
				dataRow.accessor = 'severity'
				dataRow.Cell = cellInfo => (
					Array.isArray(cellInfo.row.original.rule_category) ?
						cellInfo.row.original.rule_category.length ?
							<div className='d-flex'>
								{cellInfo.row.original.rule_category.map((row, rIndex) => {
									return(
										<span className={`${!rIndex ? '' : 'mx-1'}`}>{row}{(rIndex+1) === cellInfo.row.original.rule_category.length ? '  Policy Rules' : ','}</span>
									)
								})}
							</div>
						: null
					: cellInfo.row.original.rule_category ?
						<span>{cellInfo.row.original.rule_category} Policy Rules</span>
					: null
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 200 : 350
				dynamicRulePoliceColumns.push(dataRow)
	
				if(state.selectedPolicyType !== "all_rules") {	
					dataRow = {}
					dataRow.Header = 'Resources'
					// dataRow.accessor = 'severity'
					dataRow.Cell = cellInfo => (
						<div className="d-flex">
							<span className='mr-2'>{cellInfo.row.original.appResources}</span>
							{cellInfo.row.original.showMore ?
								<span className={`badge status-badge badge-outline-info`} onClick={() => setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))}>more</span>
							: null}
						</div>
					)
					dataRow.width = 400
					dynamicRulePoliceColumns.push(dataRow)
				}
	
				dataRow = {}	
				dataRow.Header = 'Tags'
				dataRow.Cell = cellInfo => (
					<div className="d-flex">
						{cellInfo.row.original.ruleTags && cellInfo.row.original.ruleTags.length ? 
							<span className={`badge status-badge risk-badge-outline-medium risk-medium`}>{cellInfo.row.original.ruleTags[0]}</span>
						: null}
					</div>
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 150 : 250
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}	
				dataRow.Header = 'Status'
				dataRow.Cell = cellInfo => (
					<span className={`badge status-badge badge-outline-${cellInfo.row.original.ruleEnabled ? "success" : "danger"}`}>{cellInfo.row.original.ruleEnabled ? "Enabled" : "Disabled"}</span>
				)
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 100 : 150
				dynamicRulePoliceColumns.push(dataRow)
	
				dataRow = {}	
				dataRow.Header = 'Description'
				dataRow.accessor = "ruleDescription"
				dataRow.width = state.selectedPolicyType !== "all_rules" ? 500 : 600
				dynamicRulePoliceColumns.push(dataRow)
	
				if(state.selectedPolicyType !== "all_rules") {	
					dataRow = {}
					dataRow.Header = 'Actions'
					// dataRow.accessor = 'severity'
					dataRow.Cell = cellInfo => (
						<div className="d-flex justify-content-between align-self-center">
							<p className="m-0 p-0 mx-1 cursorPointer" 
								onClick={() => {
									setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))
								}}
							>
								<i className="fas fa-ellipsis-h"></i>
							</p>
						</div>
					)
					dataRow.width = 100
					dynamicRulePoliceColumns.push(dataRow)
				}
			}
			
			dispatch(listGovernancePolicies(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, governanceRules: response, filteredArray: [], showLoading: false, showFilteredList, queryLoading: false, selectedView: state.selectedViewOption, dynamicRulePoliceColumns, callStructurePolicyDetails: true }))
					} else {
						setState(prevState => ({ ...prevState, showLoading: false, governanceRules: [], filteredArray: [], showFilteredList, queryLoading: false, selectedView: state.selectedViewOption, dynamicRulePoliceColumns }))
					}
				})
		}
	}, [state.callSearch, dispatch, state.governanceTypes, state.selectedAccount, state.selectedFilters, state.selectedGovernanceType, state.selectedPolicyType, state.selectedRegion, state.selectedRoles, state.selectedTags, state.selectedUsers, state.selectedViewOption])

	useEffect(() => {
		if(state.callStructurePolicyDetails) {
			setState(prevState => ({ ...prevState, callStructurePolicyDetails: false }))
			let data = state.governanceRules
			let policies = []
			data.length && data.forEach(row => {
				let policy_metadata = row.policy_metadata ? row.policy_metadata : {}
				let policyData = row.policy ? row.policy : {}
				let dataRow = {...policyData, ...policy_metadata}
	
				let appliesTo = row.policy_metadata && row.policy_metadata.applies_to ? row.policy_metadata.applies_to : []
				if(appliesTo.length) {
					let appAccounts = []	
					let appRegions = []
					let appTags = []
					let appUsers = []
					let appRoles = []
					let appResources = row.policy_metadata && row.policy_metadata.provider ? capitalizeAllLetter(row.policy_metadata.provider) : ""
					appliesTo.forEach((item, index) => {
						if(item.account_id){
							appAccounts.push(item.account_id)
						}
	
						if(item.region && Array.isArray(item.region)) {
							item.region.forEach(reg => {
								appRegions.push(reg)
							})
						} else if(item.region) {
							appRegions.push(item.region)
						}
						item.tags && item.tags.length && item.tags.forEach(tag => {
							appTags.push(tag)
						})
						item.users && item.users.length && item.users.forEach(usr => {
							appUsers.push(usr)
						})
						item.roles && item.roles.length && item.roles.forEach(rol => {
							appRoles.push(rol)
						})
					})
					
					if(appAccounts.length) {
						appResources += (row.policy_metadata && row.policy_metadata.provider ? " : " : "")+getAccountNameFromId(appAccounts[0], state.accounts)
					} 
	
					if(appRegions.length) {
						appResources += " : "+getRegionName(appRegions[0], state.regions)
					}
	
					// if(appRegions.length) {
					// 	appResources += " : "+appRegions[0]
					// }
	
					// row.appAccounts = appAccounts
					// row.appRegions = appRegions
					// row.appTags = appTags
					// row.appUsers = appUsers
					// row.appRoles = appRoles
	
					dataRow.appResources = appResources
					dataRow.showMore = (appAccounts.length > 1 || appRegions.length > 1) ? true : false
				}
				dataRow.ruleDescription = policyData.description
				dataRow.ruleTags = policyData.tags
				dataRow.ruleEnabled = policyData.enabled
	
				dataRow.ruleData = row
				policies.push(dataRow)
			})
	
			setState(prevState => ({ ...prevState, governancePolicies: policies, filteredArray: policies, callFormHeaders: true }))
		}
	}, [state.callStructurePolicyDetails, state.accounts, state.governanceRules, state.regions])

	useEffect(() => {
		if(state.callFormHeaders) {
			setState(prevState => ({ ...prevState, callFormHeaders: false }))
			let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
			if(dataResult.length) {
				let headers = []
				Object.entries(dataResult[0]).forEach(([key, value]) => {
					if(typeof value === "string") {
						let headerRow = {}
						headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
						headerRow.key = key
						headers.push(headerRow)
					}
				})
				setState(prevState => ({ ...prevState, headers }))
			}
		}
	}, [state.callFormHeaders, state.filteredArray])

	useEffect(() => {
		if(state.calltoGetPolicyInformation) {			
			let details = state.metaData[state.selectedGovernanceType] ? state.metaData[state.selectedGovernanceType] : {}

			let data = []

			if(Object.entries(details).length) {
				data = Object.keys(details)
			}

			setState(prevState => ({ ...prevState, calltoGetPolicyInformation: false, policyTypeOptions: data, mainDropdown: data }))
		}
	}, [state.calltoGetPolicyInformation, state.metaData, state.selectedGovernanceType])

	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [state.tag_key, dispatch])

	const addNewTag = () => {
		let selectedTags = state["selectedTags"] ? state["selectedTags"] : []
		if(state["tag_key"] && state["tag_key"] !== "" && state["tag_value"] && state["tag_value"] !== "" ) {
			let dataRow = {
				key: state["tag_key"],
				value: state["tag_value"],
			}
			selectedTags.push(dataRow)
		}


		setState(prevState => ({ ...prevState, selectedTags: selectedTags, tag_key: "",  tag_value: "", tagValues: [] }))
	}

	const removeTagSection = (tag) => {
		// filteredResult = state.selectedTags.filter(e => e.key !== tag.key && e.value !== tag.value)
		let filteredResult = state.selectedTags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedTags: filteredResult }))
	}
	const onReset = () => {
		if(state.selectedFilters && state.selectedFilters.length) {
			let groupedData = _.chain(state.selectedFilters)
							.groupBy('label')
							.map((value, key) => ({ groupLabel: key, groupValue: value })).value()
			
			groupedData.forEach(row => {
				row.groupValue.forEach(item => {
					setState(prevState => ({ ...prevState, ["options_"+state.selectedOption+"".value]: false }))
				})
			})
		}

		setState(prevState => ({ 
			...prevState,
			selectedGovernanceType: "All",
			selectedAccount: "",
			selectedRegion: [],
			selectedTags: [],
			selectedUsers: [],
			selectedRoles: [],
			selectedFilters: [],
			selectedPolicyType: "Configured",
			callSearch: true
		}))
	}

    const comparisonFilter = () => {
		if(!state.searchText || state.searchText === '') {
            setState(prevState => ({ ...prevState, showMainDropdownOption: state.showMainDropdownOption ? false : true, selectedDefaultOption: '' }))
		}
	}
    
    useEffect(() => {
		setState(prevState => ({ ...prevState, showMainDropdownOption: false }))

		let filterData = _.orderBy(state.policyTypeOptions, ['Asc'])
		if(state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(state.searchText) ||
				filter.includes(state.searchText)
			)
		}
		setState(prevState => ({ 
			...prevState, 
			selectedDefaultOption: '',
			mainDropdown: filterData, 
			showMainDropdownOption: state.searchText === '' ? false : true 
		}))
	}, [state.searchText, state.policyTypeOptions])

	useEffect(() => {
		if(state.calltoGetSelectedOptionValues) {
			let details = state.metaData[state.selectedGovernanceType]
			let selectedOptionValues = details && details[state.selectedOption] ? details[state.selectedOption] : []
	
			setState(prevState => ({ ...prevState, childDropdownOptions: selectedOptionValues, filteredChildDropdownOptions: selectedOptionValues, calltoGetSelectedOptionValues: false }))
		}

	}, [state.calltoGetSelectedOptionValues, state.metaData, state.selectedOption, state.selectedGovernanceType])


	const updateMultiSelectedOptions = (row, options) => {
		let data = state.selectedFilters ? state.selectedFilters : []
		let selectedMultiOptions = state.selectedMultiOptions ? state.selectedMultiOptions : []

		if(state["options_"+state.selectedOption+"_"+row]) {				
			let dataRow = {}
			dataRow.label = state.selectedOption
			dataRow.value = row

			data.push(dataRow)
		} else {
			data = data.filter(e => e.value !== row)
		}

		setState(prevState => ({ ...prevState, selectedFilters: data, selectedMultiOptions }))
	}

	useEffect(() => {
		if(state.showAdvancedSearch) {

			if(state.selectedGovernanceType === "infra") {
				if(!tagKeys || !tagKeys.length) {
					let params = {}
					// params.account_id = "265469181489"
					// params.region = "us-east-2"
					dispatch(getAllTagsKeys(params))
				}
			} else {
	
				let params = {}
				if(state.selectedAccount && state.selectedAccount !== "") {
					params.account_id = state.selectedAccount
				}
				// params.service_name = "iam-users"
				dispatch(listAssets(params))
					.then((response) => {
						if (response) {
							setState(prevState => ({ ...prevState, applies_to_users: response.results ? response.results : [] }))
						} else {
							setState(prevState => ({ ...prevState, applies_to_users: [] }))
						}
					})
	
				let roleParams = {}
				if(state.selectedAccount && state.selectedAccount !== "") {
					roleParams.account_id = state.selectedAccount
				}
				roleParams.service_name = "iam-roles"
	
				dispatch(listAssets(roleParams))
					.then((response) => {
						if (response) {
							setState(prevState => ({ ...prevState, applies_to_roles: response.results ? response.results : [] }))
						} else {
							setState(prevState => ({ ...prevState, applies_to_roles: [] }))
						}
					})
			}
		}
	}, [state.showAdvancedSearch, dispatch, state.selectedAccount, state.selectedGovernanceType, tagKeys])
	
	const onClickCreateRule = ruleObj => {
		dispatch(cleareNewEditRuleDetails());
		navigate(URL_PATH.GOVERNANCE_CREATE_NEW_RULE, {state: { ruleDetails: {} } })
	}

	const onSideViewRule = (ruleId) => {
		setState(prevState => ({ ...prevState, queryLoading: true }))
		let params = {}
		params.policy_name = ruleId
		dispatch(getPolicyDetails(params))
			.then((response) => {
				if(response) {
					let ruleDetails = response
					let policyBasicDetails = ruleDetails.policy_metadata ? ruleDetails.policy_metadata : {}	
					setState(prevState => ({ ...prevState, queryLoading: false, ruleDetails, policyBasicDetails, showListDetailsPanel: true }))
				} else {
					setState(prevState => ({ ...prevState, editGovernanceRule: false, queryLoading: false }))
				}
			})
	}

	const onClickEditRule = (ruleId) => {
		dispatch(setGovernancePropsDetails('appliesToError', false))
		let params = {}
		params.policy_name = ruleId
		dispatch(getPolicyDetails(params))
			.then((response) => {
				if(response) {
					navigate(URL_PATH.GOVERNANCE_EDIT_NEW_RULE, { state: { ruleDetails: response } });
				} else {
					setState(prevState => ({ ...prevState, editGovernanceRule: false }))
				}
			})
	}

	const onClickDeleteRule = (ruleId) => {
		let params = {}
		params.policy_name = ruleId
		dispatch(deleteGovernancePolicy(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response.message ? response.message : 'Error in deleting policy'
					if(response && response.status) {
						messageType = 'success'
						message = response.message ? response.message : 'Policy deleted successfully'

						setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deletePolicyDetails: '', deleteModal: false })) }, 2000)
					}

					setTimeout(() => { setState(prevState => ({ ...prevState, showDeleteLoading: false })) }, 2000)
	
					// setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
	
					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
						}
					});
				}
			})
	}

	const removeFilterData = (row) => {
		let selectedFilters = state.selectedFilters.filter(e => e.label === row.label && e.value === row.value)

		setState(prevState => ({ ...prevState, selectedFilters }))
	}

	const handleChildClick = (event, type, dropdownType, section) => {
		if (advanceSearchRef && advanceSearchRef.current && !advanceSearchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showMainDropdownOption: false, selectedOption: "" }))
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
		<div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`loaderOverlay ${state.queryLoading ? "" : "displayNone"}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div>
			{/* {state.showListDetailsPanel ? (
				<ViewSidePanel
					visitFrom={"List"}
					selectedProvider={state.selectedProvider}
					// resource_type={state.resource_type}
					ruleDetails={state.ruleDetails}
					governanceType={state.policyBasicDetails && state.policyBasicDetails.governance_type}
					closeSidePanel={() => setState(prevState => ({ ...prevState, showListDetailsPanel: false }))}
				/>
			) : null} */}
			{state.showFilterDetailsPanel ? (            
				<FilterSidePanel
					masterData={state.masterData}
					closeSidePanel={() => setState(prevState => ({ ...prevState, showFilterDetailsPanel: false }))}
				/>
			) : null}
			<div className="col-sm-12 p-0">
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-6 col-3 py-2'>
                        <h6 className='text-white m-0'>Governance Rules</h6>
                        <p className='text-white m-0'>Filter user governance rules on your Cloud</p>
                    </div>
					<div className="col-lg-6 d-flex justify-content-end align-self-center">
						<button className="ml-3 btn bg-green text-white btn-sm" onClick={() => onClickCreateRule(state.selectedTableRow)}>
							Create Rule
						</button>
					</div>
				</div>

				<div className='col-lg-12 py-2'>
					<div className='bg-dark rounded p-3'>
						<div className="d-flex">
							<div className="mr-4" style={{minWidth: "200px" }}>
								<div className="d-flex justify-content-between">
									<label className="align-self-center m-0">Governance Type </label>
								</div>
								<div className='mt-1 boderDottedBottom'
									onClick={(event) => {
										if(!state.isGovernanceTypeOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isGovernanceTypeOpen")
										}
									}}
								>
									<p className={`d-flex justify-content-between hiddenEllipses mt-2 mb-2 ${state.selectedGovernanceType ? 'text-white' : 'placeholder'}`}>
										{state.selectedGovernanceType ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === state.selectedGovernanceType).length ? state.governanceTypes.filter(e => e.value === state.selectedGovernanceType)[0].label : state.selectedGovernanceType) : 'Select'}
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isGovernanceTypeOpen ?
										<MultiSelectSection
											fields={["value", "label"]}
											options={state.governanceTypes}
											selectedValues={state.selectedGovernanceType ? state.selectedGovernanceType : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedGovernanceType: value, calltoGetPolicyInformation: true }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											addAllField={true}
											hideSearch={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
										/>
									: null}
								</div>
							</div>
							{state.selectedGovernanceType !== "opa" && state.selectedGovernanceType !== "opa_kubernetes"  ?
								<React.Fragment>
								<div className="mr-4" style={{minWidth: "120px" }}>
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0">Provider</label>
									</div>
									<div className='mt-1 boderDottedBottom'
										onClick={(event) => {
											if(!state.isProviderOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
											}
										}}
									>
										<p className={`d-flex justify-content-between hiddenEllipses mt-2 mb-2 ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
											{state.selectedProvider ? state.selectedProvider : 'Select'}
											<i className='fa fa-caret-down mt-1 mr-1'/>
										</p>
										{state.isProviderOpen ?
											<MultiSelectSection
												fields={["provider_name", "provider_name"]}
												options={state.providers}
												selectedValues={state.selectedProvider ? state.selectedProvider : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: '', selectedRegion: '' }))
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={true}
												topClass={'auto'}
												widthClass={'minWidth220'}
											/>
										: null}
									</div>
								</div>
								<div className="mr-4" style={{minWidth: "150px" }}>
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0">Account</label>
									</div>
									<div className='mt-1 boderDottedBottom' 
										onClick={(event) => {
											if(!state.isAccountOpen) {
												event.preventDefault();
												handleChildClick(event, 'child', 'singleDropDown', "isAccountOpen")
											}
										}}
									>
										<p className={`d-flex justify-content-between hiddenEllipses mt-2 mb-2 ${state.selectedAccount ? 'text-white' : 'placeholder'}`}>
											{state.selectedAccount ? getAccountNameFromId(state.selectedAccount, state.accounts) : 'Select'}
											<i className='fa fa-caret-down mt-1 mr-1'/>
										</p>
										{state.isAccountOpen ?
											<MultiSelectSection
												fields={["account_id", "account_name"]}
												options={state.accounts}
												selectedValues={state.selectedAccount ? state.selectedAccount : ''}
												callbackMultiSelect={(value) => {
													if(!value || typeof(value) === 'string') {
														setState(prevState => ({ ...prevState, selectedAccount: value, selectedRegion: '' }))
													} else {
														value.preventDefault()
														handleChildClick(value, "search", 'singleDropDown', "")
													}
												}}
												singleSelection={true}
												hideSearch={true}
												topClass={'auto'}
												widthClass={'minWidth220'}
											/>
										: null}
									</div>
								</div>
								</React.Fragment>
							:null}
							<div className="mr-4" style={{minWidth: "150px" }}>
								<div className="d-flex justify-content-between">
									<label className="align-self-center m-0">Region</label>
								</div>
								<div className='mt-1 boderDottedBottom'
									onClick={(event) => {
										if(!state.isRegionOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isRegionOpen")
										}
									}}
								>
									<p className={`d-flex justify-content-between hiddenEllipses mt-2 mb-2 ${state.selectedRegion ? 'text-white' : 'placeholder'}`}>
										{state.selectedRegion ? getRegionName(state.selectedRegion, state.regions) : 'Select'}
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isRegionOpen ?
										<MultiSelectSection
											fields={["region", "name"]}
											options={state.regions}
											selectedValues={state.selectedRegion ? state.selectedRegion : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedRegion: value }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
										/>
									: null}
								</div>
							</div>
							<div className="mr-4" style={{minWidth: "150px" }}>
								<div className="d-flex justify-content-between">
									<label className="align-self-center m-0">View</label>
								</div>
								<div className='mt-1 boderDottedBottom'
									onClick={(event) => {
										if(!state.isViewOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isViewOpen")
										}
									}}
								>
									<p className={`d-flex justify-content-between hiddenEllipses  mt-2 mb-2 ${state.selectedViewOption ? 'text-white' : 'placeholder'}`}>
										{state.selectedViewOption ? state.viewOptions.filter(e => e.value === state.selectedViewOption)[0].label : 'Select'}
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isViewOpen ?
										<MultiSelectSection
											fields={["value", "label"]}
											options={state.viewOptions}
											selectedValues={state.selectedViewOption ? state.selectedViewOption : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedViewOption: value, selectedPolicyType: value === "policy_wise" && state.selectedPolicyType === "all_rules" ? "Configured" : state.selectedPolicyType }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											hideSearch={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
										/>
									: null}
								</div>
							</div>
							<div className="mr-4" style={{minWidth: "150px" }}>
								<div className="d-flex justify-content-between">
									<label className="align-self-center m-0">Policy Type</label>
								</div>
								<div className='mt-1 boderDottedBottom'
									onClick={(event) => {
										if(!state.isPolicyType) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isPolicyType")
										}
									}}
								>
									<p className={`d-flex justify-content-between hiddenEllipses  mt-2 mb-2 ${state.selectedPolicyType ? 'text-white' : 'placeholder'}`}>
										{state.selectedPolicyType ? state.selectedPolicyType : 'Select'}
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isPolicyType ?
										<MultiSelectSection
											fields={["label", "value"]}
											options={state.policyTypes && (state.selectedPolicyType !== "rule_wise" ? state.policyTypes.filter(e => e.value !== "all_rules") : state.policyTypes)}
											selectedValues={state.selectedPolicyType ? state.selectedPolicyType : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedPolicyType: value }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
											hideSearch={true}
											topClass={'auto'}
											widthClass={'minWidth220'}
										/>
									: null}
								</div>
							</div>
							{!state.showAdvancedSearch ?							
								<div className="align-self-center">
									<div className="d-flex align-self-center">
										<div className="btn-group bg-info rounded" role="group" aria-label="governance-dash">
											<button type="button" className={`cursorPointer btn text-white border-right`} onClick={()=> setState(prevState => ({ ...prevState, queryLoading: true, callSearch: true }))}>Search</button>
											<button type="button" className={`cursorPointer btn text-white border-left`} onClick={onReset}><i className='far fa-redo'></i></button>
										</div>
									</div>
									
									{/* {state.selectedViewOption === "rule_wise" ?
										<p className="mb-0 mt-2 text-info cursorPointer" onClick={() => setState(prevState => ({ ...prevState, showAdvancedSearch: !state.showAdvancedSearch }))}>Advanced Search</p>
									: null} */}
								</div>
							: null}
						</div>
						{state.selectedViewOption === "rule_wise" && state.showAdvancedSearch ? 
							<React.Fragment>
							<div className="mt-3">
								{state.selectedFilters && state.selectedFilters.length ? 
									<div className="d-flex flex-wrap mt-3 mb-2">
										{state.selectedFilters.map(row => {
											return (
												<small key={'filter_'+row.label} className={`badge badge-secondary mr-1`}>{row.label+" : "+row.value}
												<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={() => removeFilterData(row)} ></i>
												</small>
												
											)
										})}
									</div>
								: null}
								<div className="position-relative d-inline-block w-25 mt-2" ref={advanceSearchRef}>
									<label className="mb-1">Search</label>
									<div className="comparisonTextboxDiv p-0 m-0">
										<input type="text" className={`comparisonTextbox bg-transparent text-white border-lightGray py-2 pl-3 rounded`}
											onChange={e => setState(prevState => ({ ...prevState, searchText: e.target.value }))}
											value={state.searchText}
											onClick={ () => comparisonFilter() }
											placeholder={state.placeHolder}
										/>
									</div>
									{state.showMainDropdownOption ?
										<div className={`dropdownOptions topAuto mt-2 bg-muted border-muted`}>
											{state.mainDropdown && state.mainDropdown.map(row => {
												return(
													<button key={'drop_'+row} className="d-flex justify-content-between mb-3 bg-muted text-white cursorPointer" onClick={() => setState(prevState => ({ ...prevState, selectedOption: row, showDropdown: false, searchKey: "", calltoGetSelectedOptionValues: true }))}>
														<p className="mb-0 text-left">{row}</p>
													</button>
												)
											})}
										</div>
									: null}
									{state.selectedOption && state.selectedOption !== "" ?
										<div className={`dropdownOptions topAuto mt-2 bg-muted border-dark`}>
											{state.filteredChildDropdownOptions && state.filteredChildDropdownOptions.map(row => {
												return(		
													<div key={'cbildDrop_'+row} className="d-flex mb-3 bg-muted text-white cursorPointer" onClick={() => {
														setState(prevState => ({ ...prevState, ["options_"+state.selectedOption+"_"+row]: !state["options_"+state.selectedOption+"_"+row] }))
														updateMultiSelectedOptions(row, state.filteredChildDropdownOptions)
													}}>
														<div className="ml-1 form-check">
															<input type="checkbox"
																className="form-check-input"
																checked={state["options_"+state.selectedOption+"_"+row]}
															/>
														</div>
														<p className="ml-1 mb-0 text-left">{row}</p>
													</div>
												)
											})}
										</div>
									: null}
								</div>
							</div>
							
							{state.selectedGovernanceType === "infra" ?
								<div className="mt-3">
									<div className="d-flex justify-content-between">
										<label className="align-self-center m-0">Tags</label>
									</div>
									<div className="d-flex">
										<div className='mt-1 boderDottedBottom' ref={tagKeyRef} 
											onClick={(event) => {
												if(!state.isTagOpen) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "isTagOpen")
												}
											}}
										>
											<p className={`d-flex justify-content-between hiddenEllipses  mt-2 mb-2 ${state.selectedGovernanceType ? 'text-white' : 'placeholder'}`}>
												{state.selectedGovernanceType ? state.selectedGovernanceType : 'Select'}
												<i className='fa fa-caret-down mt-1 mr-1'/>
											</p>
											{state.isTagOpen ?
												<MultiSelectSection
													// fields={["provider_name", "provider_name"]}
													options={state.tagKeys}
													selectedValues={state.tag_key ? state.tag_key : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string') {
															setState(prevState => ({ ...prevState, tag_key: value }))
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={true}
													topClass={'auto'}
													widthClass={'minWidth220'}
												/>
											: null}
										</div>
										<div className='mt-1 boderDottedBottom' ref={tagValueRef} 
											onClick={(event) => {
												if(!state.isTagValueOpen) {
												    event.preventDefault();
												    handleChildClick(event, 'child', 'singleDropDown', "isTagValueOpen")
												}
											}}
										>
											<p className={`d-flex justify-content-between hiddenEllipses mt-2 mb-2 ${state.selectedGovernanceType ? 'text-white' : 'placeholder'}`}>
												<i className='fa fa-caret-down mt-1 mr-1'/>
												{state.selectedGovernanceType ? state.selectedGovernanceType : 'Select'}
											</p>
											{state.isTagValueOpen ?
												<MultiSelectSection
													// fields={["provider_name", "provider_name"]}
													options={state.tagValues}
													selectedValues={state.tag_value ? state.tag_value : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string') {
															setState(prevState => ({ ...prevState, tag_value: value }))
														} else {
															value.preventDefault()
															handleChildClick(value, "search", 'singleDropDown', "")
														}
													}}
													singleSelection={true}
													hideSearch={true}
													topClass={'auto'}
													widthClass={'minWidth220'}
												/>
											: null}
										</div>
										<span className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-end mr-4`} onClick={() => addNewTag()}></span>

										{state.selectedTags && state.selectedTags.length ? 
											state.selectedTags.map((tag, index) => {
												return(
													<span key={'tag_'+index} className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
														<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeTagSection(tag) } ></i>
													</span>
												)
											})
										: null}
									</div>
								</div>
							: null}

							<div className='mt-4 d-flex'>
								<div className="btn-group" role="group" aria-label="governance-dash">
									<button type="button" className={`cursorPointer btn btn-secondary border-right`} onClick={()=> setState(prevState => ({ ...prevState, queryLoading: true, callSearch: true }))}>Search</button>
									<button type="button" className={`cursorPointer btn btn-secondary border-left`} onClick={()=> onReset()}><i className='far fa-redo'></i></button>
								</div>
								<button className="ml-4 btn btn-secondary" onClick={() => setState(prevState => ({ ...prevState, showAdvancedSearch: false }))} >Cancel</button>
							</div>
							</React.Fragment>
						: null}
					</div>
				</div>

				<div className='col-lg-12 py-2'>
					<div className='rounded bg-dark p-3'>
						<div className='d-flex justify-content-between mb-1'>
							<p className='mb-1 align-self-center'>Showing {state.filteredArray && state.filteredArray.length} of total {state.governancePolicies && state.governancePolicies.length} {state.selectedView === "policy_wise" ? "Policies" : "Rule(s)"}</p>
							{state.governancePolicies && state.governancePolicies.length ?
								<div className="d-flex w-40 justify-content-end">
									<CSVLink 
										data={state.filteredArray ? state.filteredArray : []} 
										headers={state.headers ? state.headers : []}
										filename={"resource-alerts.csv"}
										className={"mt-2"}
										target="_blank"
									>
										<span className="d-flex text-primary-color mr-2">
											<i className="fas fa-download text-primary-color mr-2"></i>
											{/* csv */}
										</span>
									</CSVLink>
									<div className={`mb-n2 w-50 ${state.showLoading ? "disabled" : ""}`}>
										<Search
											data={state.governancePolicies ? state.governancePolicies : []}
											filteredData={filteredArray => {
												setState(prevState => ({ ...prevState, filteredArray }))
											}}
											topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
											searchClassName={'px-2 f12'}
											searchIconColor={'text-white f12'}
											searchPlaceHolder={'Search....'}
											className={"bg-transparent text-white form-control-sm f12"}
										/>
									</div>
								</div>
							: null}
						</div>
					
						{state.governancePolicies && state.governancePolicies.length && !state.showLoading ? 
							<React.Fragment>
							{state.selectedView === "policy_wise" ?
								<ResizeableDarkThemeTable
									columns={[
										{
											Header: "Type",
											Cell: cellInfo => (
												<span>{cellInfo.row.original.governance_type ? (state.governanceTypes && state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type).length ? state.governanceTypes.filter(e => e.value === cellInfo.row.original.governance_type)[0].label : cellInfo.row.original.governance_type) : "" }</span>
											),
											accessor: "governance_type",
											width: 200
										},
										{
											Header: "Policy Name",
											accessor: "policy_name",
											width: 200
										},
										{
											Header: "Category",
											Cell: cellInfo => (
												cellInfo.row.original.rule_category && cellInfo.row.original.rule_category.length ?
													<div className='d-flex'>
														{cellInfo.row.original.rule_category.map((row, rIndex) => {
															return(
																<span className={`${!rIndex ? '' : 'mx-1'}`}>{row}{(rIndex+1) === cellInfo.row.original.rule_category.length ? '  Policy Rules' : ','}</span>
															)
														})}
													</div>
												: null
											),
											accessor: "rule_category",
											width: 400
										},
										{
											Header: "Resources",
											Cell: cellInfo => (
												<div className="d-flex">
													<span className='mr-2'>{cellInfo.row.original.appResources}</span>
													{cellInfo.row.original.showMore ?
														<span className={`badge status-badge badge-outline-info`}>more</span>
													: null}
												</div>
											),
											width: 250
										},
										{
											Header: "Actions",
											Cell: cellInfo => (
												<div className="d-flex justify-content-between align-self-center">
													{state.selectedView === "rule_wise" ?
														<p className="m-0 p-0 mx-1 cursorPointer" 
														// onClick={() => setState(prevState => ({ ...prevState, masterData: cellInfo.row.original && cellInfo.row.original.ruleData ? cellInfo.row.original.ruleData : cellInfo.row.original, showFilterDetailsPanel: true }))}
														><i className="fal fa-eye"></i></p>
													:
														<React.Fragment>
														{state.actionsAllowed && state.actionsAllowed.includes('update') ?
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => onClickEditRule(cellInfo.row.original.policy_name)}> <i className="fas fa-pencil"></i></p>
														: null}
														{state.actionsAllowed && state.actionsAllowed.includes('update') ?
															<p className="m-0 p-0 mr-1 cursorPointer" onClick={() => setState(prevState =>({ ...prevState, deletePolicyDetails: cellInfo.row.original.policy_name, deleteModal: true }))}> <i className="fas fa-trash"></i></p>
														: null}
														{/* <p className="m-0 p-0 mx-1 cursorPointer" 
															onClick={() => onSideViewRule(cellInfo.row.original.policy_name)}
														>
														<i className="fal fa-book-open"></i></p> */}
														</React.Fragment>
													}
												</div>
											),
											width: 75
										},											
										{
											Header: "Description",
											accessor: "description",
											width: 400
										},
									]}
									
									data={state.filteredArray ? state.filteredArray : state.governancePolicies}
									perPage={20}
									tableHead={'bg-black text-white'}
									tableBody={'bg-dark3 text-white'}
									tableType={'table-dark-hover'}
									perPageClass={`bg-transparent text-lightGray`}
									textColor={'text-black'}
									selectedColor={'bg-transparent'}
									dashboard={state.filteredArray && state.filteredArray.length ? true : false}
									onClickRow={tableRow => {}}
									showPaginationTop={true}
									sortByColumn={"Date"}
									riskTooltip={[]}
								/>
							:
								<ResizeableDarkThemeTable
									columns={state.dynamicRulePoliceColumns}
									data={state.filteredArray ? state.filteredArray : state.governancePolicies}
									perPage={20}
									dashboard={(state.filteredArray ? state.filteredArray.length : (state.governancePolicies && state.governancePolicies.length)) ? true : false}
									sortByColumn={"Date"}
									riskTooltip={[0]}
									tableType={'table-dark-hover'}
									onClickRow={tableRow => {}}
									// tableSize={"table-sm"}
									// tableHead={"bg-dark"}
									tooltipFor={[]}
								/>
							}
							</React.Fragment>
						: (!state.showLoading && !state.governancePolicies) ||
							(state.governancePolicies && !state.governancePolicies.length && !state.showLoading) ? 
							<div className="d-flex justify-content-center m-4">
								<p>There are no data on this criteria. Please try adjusting your filter.</p>
							</div>
						: null}
						{state.showLoading ? (
							<div className="d-flex justify-content-center m-4">
								<Spinner className="text-center" color="white" size="lg" />
							</div>
						) : null}
					</div>
				</div>
			</div>
			<Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>
				<ModalHeader toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>Delete Policy</ModalHeader>
				<ModalBody>
					Are you sure you want to delete the policy <span className='f18 mx-1 text-red'>{state.deletePolicyDetails ? state.deletePolicyDetails : ''}</span>

				</ModalBody>
				<ModalFooter className="border-0">
				<Button color="primary" onClick={() => {
					if(!state.showDeleteLoading) {
						setState(prevState => ({ ...prevState, showDeleteLoading: true }))
						onClickDeleteRule(state.deletePolicyDetails ? state.deletePolicyDetails : '')
					}
				}}>
					{state.showDeleteLoading ?
						<Spinner size='sm' color='light' className='mx-2' />
					: null}Ok
				</Button>{' '}
				<Button color="secondary" onClick={() => {
					if(!state.showDeleteLoading) {
						setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))
					}
				}}>
					Cancel
				</Button>
				</ModalFooter>
			</Modal>
		</div>
	)
}

export default ManagePolicies;
