import React from 'react';
// import Search from '../../../common/SearchComponent'
// import _ from 'lodash'
import Select from 'react-select'
import MultiSelectSection from '../../../common/MultiSelectSection';

// import { getDayFromSelectedDuration } from '../../../../utils/utility'

class SingleInputSection extends React.Component {
    
    constructor(props) {
        super(props)
        this.dropDownRef = React.createRef()

        this.state = {
            inputComponent: this.props.inputComponent,
            selectedOption: this.props.selectedOption,
            selectedFilters: this.props.selectedFilters,
            emptyOptions: [],
        }
    }

    componentDidMount = () => {}

    addDetails = (inputs, index) => {
        let selectedFilters = this.state.selectedFilters ? this.state.selectedFilters : []
        let text = this.state[inputs.field]

        if(text !== "" && !selectedFilters.filter(e => e.label === this.state.type && e.value === this.state[inputs.field]).length  ) {
            let dataRow = {}
            dataRow.label = this.state.selectedOption
            dataRow.value = this.state[inputs.field]
            dataRow.userView = this.state.selectedOption+": "+this.state[inputs.field]
            selectedFilters.push(dataRow)
        }

        this.setState({ selectedFilters, [inputs.field]: "" })
        
    }

    removeDetails = (row) => {
        let selectedFilters = this.state.selectedFilters ? this.state.selectedFilters : []

        selectedFilters = selectedFilters.filter(e => e.label !== row.label && e.value !== row.value)

        this.setState({ selectedFilters })
        
    }
	
	handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []

        let prevState = this.state[field] ? this.state[field] : []
        if(prevState.length === 1 && prevState[0] === "All") {
            prevState = []
        }
        if(value.includes("All")) {
            if(!prevState.includes("All")) {
                if(state.filter(e => e.value).length) {
                    selectedValue = state.map(e => e.value)
                } else {
                    selectedValue = state
                }
            } else {
                const index = value.indexOf("All");
                if (index > -1) {
                    value.splice(index, 1);
                }
                selectedValue = value
            }
        } else if(!prevState.includes("All")) {
            selectedValue = value
        }

        if(selectedValue.length && !selectedValue.includes("All")) {
            if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
                selectedValue.push("All")
            }
        }

        this.setState({ [field]: selectedValue })
	}
	
	getMultiSelectedCount = (type, array) => {
		return array.length && array.includes('All') ? 'All' :  array.length ? array.length +' Selected' : 'All'
		// return array.length && array.includes('All') ? 'All' : array.length ? array.length === 1 ? array[0].label : array.length +' Selected' : 'All'
	}

    applyFilter = () => {
        if(this.state.selectedFilters && this.state.selectedFilters.length) {
            return this.props.selectedInput(this.state.selectedOption, this.state.selectedFilters)
        } else {
            return this.props.selectedInput(false);
        }
    }

    closeFilter = () => {
        return this.props.selectedInput(false);
    }

    handleClickOutside = (event) => {
        if (this.dropDownRef.current &&  !this.dropDownRef.current.contains(event.target)) {
            this.setState({ isDropdownOpen: false })
        } else {
            this.setState({ isDropdownOpen: true })
        }
	}
    
    render() {
        return (
            
            <div className='container-fluid mt-2 py-3 bg-dark3 containerBorderFilterSection' onClick={(event) => this.handleClickOutside(event)}>
                <p className="text-white mb-2">{this.state.selectedOption}</p>
                <div className="d-flex flex-wrap mb-3">
                    {this.state.inputComponent && Object.values(this.state.inputComponent).map((inpCom, gIndex)  => {
                        return(
                            <React.Fragment>
                            {inpCom.component === "select" ?
                                <Select
                                    isSearchable={false}
                                    className={`selectOption f13 p-0 mr-2 minWidth150`}
                                    value={({
                                        value: this.state[inpCom.field] && this.state[inpCom.field] !== "" ? this.state[inpCom.field] : "",
                                        label: this.state[inpCom.field] && this.state[inpCom.field] !== "" ? this.state[inpCom.field] : <span className="placeholder">Select</span>
                                    })}
                                    options={inpCom.options && inpCom.options.map(row => ({
                                        value: row.value,
                                        label: row.label,	
                                    }))}
                                    onChange={event => this.setState({ [inpCom.field]: event.value })}
                                />
                            : inpCom.component === "multiselect" ?
                                <div className='w-40 mt-1 boderDottedBottom' ref={this.dropDownRef}>
                                    <p className={`d-flex mt-2 mb-0 ${this.state[inpCom.field] && this.state[inpCom.field].length ? 'text-white' : 'placeholder'}`}>{this.state[inpCom.field] && this.state[inpCom.field].length ? this.state[inpCom.field].length+' Selected' : 'Select'}</p>
                                    {this.state.isDropdownOpen ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["value", "label"]}
                                                className={'border-0'}
                                                removeTop={true}
                                                options={inpCom.options}
                                                selectedValues={this.state[inpCom.field] ? this.state[inpCom.field] : []}
                                                callbackMultiSelect={(value) => this.setState(prevState => ({ ...prevState, [inpCom.field]: value }))}
                                            />
                                        </div>
                                    : null}
                                </div>
                            : inpCom.component === "multipleAddTextBox" ?  
                                <div className="d-flex">
                                    <input 
                                        type="text" 
                                        className={`bg-transparent text-white border-lightGray py-2 pl-3 mr-2 minWidth400 mr-2`}
                                        style={{minHeight: "38px"}}
                                        placeholder={inpCom.label ? inpCom.label : 'Enter details'}
                                        value={this.state[inpCom.field] ? this.state[inpCom.field] : ""}
                                        onChange={e => this.setState({ [inpCom.field]: e.target.value })}
                                    />
                                
                                    <span className={`far fa-plus cursor-pointer f18 mb-1 align-self-center mr-4`} onClick={() => this.addDetails(inpCom)}></span>                                    
                                </div>
                            : null}
                            </React.Fragment>
                        )
                    })}
                </div>
                
                {this.state.selectedFilters && this.state.selectedFilters.length ? 
                    this.state.selectedFilters.map(row => {
                        return(
                            <span className="badge bg-info mr-2 mt-2 align-self-center f14 text-white px-2">{row.userView}
                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-white' onClick={ () => this.removeDetails(row) } ></i>
                            </span>
                        )
                    })
                : null}
                
                <div className='row mt-2 mb-3'>
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 justify-content-end d-flex">
                        <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                            Cancel
                        </span>
                        <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default SingleInputSection;
