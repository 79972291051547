import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap'
import _ from 'lodash'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import moment from 'moment';

import { momentDateGivenFormat } from '../../utils/utility';
import { DURATION_OPTIONS } from '../../utils/constants'
import { listAllProviders, listAllProjects, listClusterFilters, listUsers, setCommonPropsDetails } from '../../actions/commonActionNew'
import { listQueues } from '../../actions/Collider/ClusterAction'
import MultiSelectSection from './MultiSelectSection';



const FilterSection = (props) => {
    const durationRef = useRef()
    const clickOut = useRef()
    const [state, setState] = useState({
        selectedDuration: 'currentMonth',
        screens: ['QOS', 'Group'],
        changeManage: props.changeManage,
        reportViewMode: [
            {value: "cluster_name", label: "Cluster"},
            {value: "queue_name", label: "Queue"},
            {value: "user", label: "User"},
            {value: "project", label: "Project"}
        ],
        selectedType: 'cluster_name',
        onLoad: true,
        projectLoader: true,
        accountLoader: true,
        regionLoader: true,
        clusterLoader: true
    })

    const [dateState, setDateState] = useState([{
        startDate: new Date(),
        endDate: null,
        key: 'selection'
    }]);

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const clickedonParent = useSelector(state => state?.filters?.commonPropsDetails?.clickedonParent || false);    
    const filterSelectedFilters = useSelector(state => state?.filters?.commonPropsDetails?.filterSelectedFilters || false);
    const usersList = useSelector(state => state?.filters?.usersList)

    const location = useLocation();
    const locationData = location.state || false

    useEffect(() => {
        if(props.screen === 'job_status' && !_.isEqual(filterSelectedFilters, state.filterSelectedFilters)) {
            setState(prevState => ({ 
                ...prevState,
                selectedProvider: filterSelectedFilters.selectedProvider,
                selectedProject: filterSelectedFilters.selectedProject,
                selectedProjectTags: filterSelectedFilters.selectedProjectTags,
                selectedAccount: filterSelectedFilters.selectedAccount ? filterSelectedFilters.selectedAccount : [],
                selectedRegion: filterSelectedFilters.selectedRegion ? filterSelectedFilters.selectedRegion : [],
                selectedClusters: filterSelectedFilters.selectedClusters ? filterSelectedFilters.selectedClusters : [],
                selectedQueue: filterSelectedFilters.selectedQueue ? filterSelectedFilters.selectedQueue : [],
                selectedUser: filterSelectedFilters.selectedUser ? filterSelectedFilters.selectedUser : [],
                datePickerStartDate: filterSelectedFilters.datePickerStartDate,
                datePickerEndDate: filterSelectedFilters.datePickerEndDate,
                filterSelectedFilters,
                callSearch: true,
            }))
        }
    }, [props.screen, filterSelectedFilters, state.filterSelectedFilters])
    
    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
                selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "",
                callAccountList: true,
                callRegionList: true,
                callClusterList: true
            }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
        }
    }, [dispatch, providers]);   
    
    useEffect(() => {
        if(state.onLoad) {
            if(state.selectedProvider) {
                if(!props.onLoadFields) {
                    if(props.filters.includes('duration')) {
                        if(state.datePickerStartDate) {
                            setState(prevState => ({ ...prevState, onLoad: false, callSearch: true }))    
                        }
                    } else {
                        setState(prevState => ({ ...prevState, onLoad: false, callSearch: true }))
                    }
                } else {
                    let exist = true
                    props.onLoadFields.forEach(fil => {
                        if(!state[fil]) {
                            exist = false
                        }
                    })

                    if(exist) {
                        setState(prevState => ({ ...prevState, onLoad: false, callSearch: true }))    
                    }
                }
            }
        }
    }, [state.onLoad, state.datePickerStartDate, state.selectedProvider, props.filters, props.onLoadFields, state])

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider], projectLoader: false }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response, projectLoader: false }))
                        }
                    });
            }
        }
    }, [state.selectedProvider, dispatch, propProjects]);

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if (state.callAccountList && props.filters && props.filters.includes('account')) {
            setState(prevState => ({ ...prevState, callAccountList: false, accountLoader: true }));
            let params = {};
            let label = ''
            params.aggregate_by = 'account_id';
            params.provider = state.selectedProvider.toLowerCase();
            label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }

            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label], selectedAccount: [], accountLoader: false }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                            }
                            setState(prevState => ({ ...prevState, accounts: response, selectedAccount: [], accountLoader: false }));
                        }
                    })
            }
        }
    }, [state.callAccountList, state.selectedProvider, dispatch, propProjAccounts, state.selectedProject, state.selectedProjectTags, props.filters]);

    useEffect(() => {
        if (state.callRegionList && props.filters && props.filters.includes('region')) {
            setState(prevState => ({ ...prevState, callRegionList: false, regionLoader: true }));
            let params = {};
            let label = ''
            params.aggregate_by = 'region';
            params.provider = state.selectedProvider.toLowerCase();
            label = state.selectedProvider
            if(state.selectedProject) {
                label = '_'+state.selectedProject
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            
            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label], selectedRegion: [], regionLoader: false }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, regions: response, selectedRegion: [], regionLoader: false }))
                    })
            }
        }
    }, [state.callRegionList, state.selectedProvider, state.selectedAccount, dispatch, propProjRegions, state.selectedProject, state.selectedProjectTags, props.filters]);

    useEffect(() => {
        if (state.callClusterList && props.filters && props.filters.includes('cluster')) {
            setState(prevState => ({ ...prevState, callClusterList: false, clusterLoader: true }));
            let params = {};
            params.aggregate_by = 'cluster_name';
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedProject) {
                params.project_tags = state.selectedProjectTags
            }
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response, clusterLoader: false }));
                        if(props.showSelectedCluster) {
                            setState(prevState => ({ ...prevState, selectedClusters: response.length ? response[0] : "" }));
                        }
                    }
            })
        }
    }, [dispatch, state.callClusterList, state.selectedProject, state.selectedProjectTags, state.selectedProvider, state.selectedAccount, state.selectedRegion, props])

    useEffect(() => {
        if (state.callQueueList && props.filters && props.filters.includes('queue')) {
            setState(prevState => ({ ...prevState, callQueueList: false, filterLoader: true }));
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response, selectedQueue: "", filterLoader: false }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, props])

    useEffect(() => {
        if (state.callUserList && props.filters && props.filters.includes('user')) {
            setState(prevState => ({ ...prevState, callUserList: false, userLoader: true }));
            let params = {};
            if(!usersList || !usersList.length) {
                dispatch(listUsers(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, usersList: response, userLoader: false }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, usersList: usersList }));
            }
        }
    }, [dispatch, state.callUserList, usersList, props])
  
    useEffect(() => {
      const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)
  
      let datePickerStartDate = ''
      let datePickerEndDate = ''
      if(state.selectedDuration === "currentMonth") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "currentYear") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "lastMonth") {
        datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
        datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
      } else if(state.selectedDuration === "quarterly") {
        if (currentMonth >= 0 && currentMonth <= 2) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
        } else if (currentMonth > 2 && currentMonth < 6) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
        } else if (currentMonth > 5 && currentMonth < 9) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
        } else {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
      } else if(state.selectedDuration === "half-yearly") {
        if (currentMonth >= 0 && currentMonth <= 5) {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
        } else {
          datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
          datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        }
      } else if(state.selectedDuration === "yearly") {
        datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
        datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
      }
      if(state.selectedDuration !== 'custom') {
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
  
        let dateSelection= {
          startDate: new Date(datePickerStartDate),
          endDate: new Date(datePickerEndDate),
          key: 'selection'
        }
        setDateState([dateSelection])
      }
  
    }, [state.selectedDuration])

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, selectedDuration: "custom", }));    
        }

        setDateState([date.selection])
    };

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedDuration: 'currentMonth', callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch && state.selectedProvider) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount ? state.selectedAccount : [],
                selectedProject: state.selectedProject ? state.selectedProject : [],
                selectedProjectTags: state.selectedProjectTags ? state.selectedProjectTags : [],
                selectedRegion: state.selectedRegion ? state.selectedRegion : [],
                selectedClusters: state.selectedClusters ? state.selectedClusters : [],
                selectedQueue: state.selectedQueue ? state.selectedQueue : [],
                selectedUser: state.selectedUser ? state.selectedUser : [],
                selectedType: state.selectedType ? state.selectedType : '',
                datePickerStartDate: state.datePickerStartDate,
                datePickerEndDate: state.datePickerEndDate,
                changeManage: state.changeManage
            }
            if(props.screen === 'QOS') {
                if(props.changeManage) {
                    props.selectedFilters(obj)
                }
            } else {
                props.selectedFilters(obj)
            }
        }
    }, [state.callSearch, props, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedProject, state.selectedProjectTags, state.selectedProvider, state.selectedQueue, state.selectedRegion, state.selectedUser, state.changeManage, state.selectedType])

    // useEffect(() => {
    //     if(props.changeManage && props.screen === 'QOS') {
    //         setState(prevState => ({ ...prevState, changeManage: props.changeManage, callSearch: true }))
    //     }
    // }, [props.changeManage, props.screen, state.changeManage])
    useEffect(() => {
		if(locationData && Object.entries(locationData).length) {            
			setState(prevState => ({  ...prevState, changeManage: locationData.selectedManage ? locationData.selectedManage : 'QOS', callSearch: true }))
		}
	}, [locationData, dispatch])

	const handleChildClick = (event, type, dropdownType, section) => {	
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    useEffect(() => {
        if(state.selectedType === 'user') {
            dispatch(listUsers({}))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, usersList: response }));
                    }
            })
        }
    }, [state.selectedType, dispatch])

    useEffect(() => {
        if(state.selectedType === 'project' && state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(state.selectedProvider)) {
                setState(prevState => ({ ...prevState, projects: propProjects[state.selectedProvider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[state.selectedProvider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, projects: response }))
                        }
                    });
            }
        }
    }, [state.selectedType, state.selectedProvider, dispatch, propProjects]); 

    useEffect(() => {
        if(clickedonParent) {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild: [] }))

            dispatch(setCommonPropsDetails('clickedonParent', false))
        }

    }, [clickedonParent, dispatch, state.clickedChild])

    return (
        <div className='w-100' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='d-flex justify-content-end'>
                {props.filters && props.filters.includes('provider') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                        onClick={(event) => {
                            if(!state.isProviderOpen) {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                            }
                        }}
                    >
                        <label className='mb-1 mt-1 f10 d-flex'>Provider</label>
                        <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
                            {state.selectedProvider ? state.selectedProvider : 'Select'}
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </p>
                        {state.isProviderOpen ?
                            <MultiSelectSection 
                                fields={["provider_name", "provider_name"]}
                                options={state.providers}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, selectedProvider: value, selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedQueue: [], callAccountList: true, callRegionList: true, callClusterList: true, selectedStatus: "", projectLoader: true }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                            />
                        : null}
                    </div>
                : null}
                {props.filters && props.filters.includes('project') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}}
                        onClick={(event) => {
                            if(!state.isProjectOpen) {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "isProjectOpen")
                            }
                        }}
                    >
                        <label className='mb-1 mt-1 f10 d-flex'>Project</label>
                        <div className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedProject ? 'text-white' : 'placeholder'}`}>
                            {state.projectLoader ? 
                                <Spinner size='sm' color='light' /> 
                            :
                                state.selectedProject ? state.selectedProject : 'Select'
                            }
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </div>
                        {state.isProjectOpen && !state.projectLoader ?
                            <MultiSelectSection
                                fields={["project", "project"]}
                                options={state.projects}
                                selectedValues={state.selectedProject ? state.selectedProject : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, 
                                            selectedProject: value,
                                            selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : [], selectedAccount: [], selectedRegion: [], selectedClusters: [], accounts: [], regions: [], clusters: [], callAccountList: true, callRegionList: true, callClusterList: true }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                widthClass={'minWidth220'}
                            />
                        : null}
                    </div>
                : null}
                {props.filters && props.filters.includes('account') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                        onClick={(event) => {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                        }}
                    >
                        <label className='mb-2 mt-1 f10 d-flex'>Account</label>
                        <div className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>
                            {state.accountLoader ? 
                                <Spinner size='sm' color='light' /> 
                            :
                                state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'
                            }
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </div>
                        {state.isAccountOpen && !state.accountLoader ?
                            <MultiSelectSection 
                                fields={["account_id", "account_name"]}
                                options={state.accounts}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                callbackMultiSelect={(value) => {
                                    setState(prevState => ({ ...prevState, selectedAccount: value, callRegionList: true, callClusterList: true }))
                                }}
                            />
                        : null}
                    </div>
                : null}
                {props.filters && props.filters.includes('region') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                        onClick={(event) => {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
                        }}
                    >
                        <label className='mb-2 mt-1 f10 d-flex'>Region</label>
                        <div className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>
                            {state.regionLoader ? 
                                <Spinner size='sm' color='light' /> 
                            :
                                state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'
                            }
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </div>
                        {state.isRegionOpen && !state.regionLoader ?
                            <MultiSelectSection 
                                fields={["region", "name"]}
                                options={state.regions}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value, callClusterList: true }))}
                            />
                        : null}
                    </div>
                : null}
                {props.filters && props.filters.includes('cluster') ?
                    !props.showSelectedCluster ?
                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                            onClick={(event) => {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'muliselectDropDown', "isClusterOpen")
                            }}
                        >
                            <label className='mb-2 mt-1 f10 d-flex'>Cluster</label>
                            <div className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedClusters && state.selectedClusters.length ? 'text-white' : 'placeholder'}`}>
                                {state.clusterLoader ? 
                                    <Spinner size='sm' color='light' /> 
                                :
                                    state.selectedClusters && state.selectedClusters.length ? state.selectedClusters.length+' Selected' : 'Select'
                                }
                                <i className='fa fa-caret-down mt-1 mr-1'/>
                            </div>
                            {state.isClusterOpen && !state.clusterLoader ?
                                <MultiSelectSection 
                                    options={state.clusters}
                                    widthClass={'minWidth220'}
                                    selectedValues={state.selectedClusters ? state.selectedClusters : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedClusters: value }))}
                                />
                            : null}
                        </div>
                    : 
                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                            onClick={(event) => {
                                if(!state.isProviderOpen) {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "isClusterOpen")
                                }
                            }}
                        >
                            <label className='mb-1 mt-1 f10 d-flex'>Cluster</label>
                            <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedClusters ? 'text-white' : 'placeholder'}`}>
                                {state.selectedClusters ? state.selectedClusters : 'Select'}
                                <i className='fa fa-caret-down mt-1 mr-1'/>
                            </p>
                            {state.isClusterOpen ?
                                <MultiSelectSection 
                                    // fields={["provider_name", "provider_name"]}
                                    options={state.clusters}
                                    widthClass={'minWidth220'}
                                    selectedValues={state.selectedClusters ? state.selectedClusters : ''}
                                    callbackMultiSelect={(value) => {
                                        if(!value || typeof(value) === 'string') {
                                            setState(prevState => ({ ...prevState, selectedClusters: value }))
                                        } else {
                                            value.preventDefault()
                                            handleChildClick(value, "search", 'singleDropDown', "")
                                        }
                                    }}
                                    singleSelection={true}
                                />
                            : null}
                        </div>
                : null}
                {props.filters && props.filters.includes('queue') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                    onClick={(event) => {
                        event.preventDefault();
                        handleChildClick(event, 'child', 'muliselectDropDown', "isQueueOpen")
                    }}>
                        <label className='mb-2 mt-1 f10 d-flex'>Queue</label>
                        <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedQueue && state.selectedQueue.length ? 'text-white' : 'placeholder'}`}>
                            {state.selectedQueue && state.selectedQueue.length ? state.selectedQueue.length+' Selected' : 'Select'}
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </p>
                        {state.isQueueOpen ?
                            <MultiSelectSection 
                                options={state.queues}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedQueue ? state.selectedQueue : []}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedQueue: value }))}
                            />
                        : null}
                    </div>
                : null}
                {props.filters && props.filters.includes('user') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}} 
                        onClick={(event) => {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'muliselectDropDown', "isUserOpen")
                    }}>
                        <label className='mb-2 mt-1 f10 d-flex'>User</label>
                        <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedUser && state.selectedUser.length ? 'text-white' : 'placeholder'}`}>
                            {state.selectedUser && state.selectedUser.length ? state.selectedUser.length+' Selected' : 'Select'}
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </p>
                        {state.isUserOpen ?
                            <MultiSelectSection
                                // fields={["value", "label"]}
                                options={state.usersList}
                                selectedValues={state.selectedUser ? state.selectedUser : ''}
                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedUser: value }))}
                                widthClass={'minWidth220'}
                            />
                        : null}
                    </div>
                : null}
                
                {props.filters && props.filters.includes('manage_qos') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} 
                        style={{minWidth: "120px", maxWidth: "120px"}}
                        onClick={(event) => {
                            if(!state.isScreenOpen) {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "isScreenOpen")
                            }
                        }}
                    >
                        <label className='mb-2 mt-1 f10 d-flex'>Manage</label>
                        <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.changeManage ? 'text-white' : 'placeholder'}`}>
                            {state.changeManage ? state.changeManage : 'Select'}
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </p>
                        {state.isScreenOpen ?
                            <MultiSelectSection 
                                // fields={["region", "name"]}
                                options={state.screens}
                                selectedValues={state.changeManage}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, changeManage: value }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                widthClass={'minWidth220'}
                                singleSelection={true}
                                hideSearch={true}
                            />
                        : null}
                    </div>
                : null}

                {props.filters && props.filters.includes('type') ?
                    <React.Fragment>
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}} 
                        onClick={(event) => {
                            if(!state.isTypeOpen) {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "isTypeOpen")
                            }
                    }}>
                        <label className='mb-2 mt-1 f10 d-flex'>Type</label>
                        <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedType ? 'text-white' : 'placeholder'}`}>
                            {state.selectedType ? (state.reportViewMode.filter(e => e.value === state.selectedType).length ? state.reportViewMode.filter(e => e.value === state.selectedType)[0].label : state.selectedType) : 'Select'}
                            <i className='fa fa-caret-down mt-1 mr-1'/>
                        </p>
                        {state.isTypeOpen ?
                            <MultiSelectSection
                                fields={["value", "label"]}
                                options={state.reportViewMode}
                                selectedValues={state.selectedType ? state.selectedType : ''}
                                callbackMultiSelect={(value) => {
                                    if(!value || typeof(value) === 'string') {
                                        setState(prevState => ({ ...prevState, selectedType: value }))
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                                hideSearch={true}
                                widthClass={'minWidth220'}
                            />
                        : null}
                    </div>
                    {state.selectedType === "user" ?
                        <div className={`form-group my-2 cursor-pointer flex-fill bd-highlight styled-feild zIndex1 mr-3`}
                            style={{minWidth: '120px', maxWidth: '120px'}}
                            onClick={(event) => {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'muliselectDropDown', "isUserOpen")
                            }}
                        >
                            <label className='mb-2 mt-1 f10 d-flex'>User</label>
                            <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedUser && state.selectedUser.length ? 'text-white' : 'placeholder'}`}>
                                {state.selectedUser && state.selectedUser.length ? state.selectedUser.length+' Selected' : 'Select'}
                                <i className='fa fa-caret-down mt-1 mr-1'/>
                            </p>
                            {state.isUserOpen ?
                                <MultiSelectSection
                                    // fields={["value", "label"]}
                                    options={state.usersList}
                                    selectedValues={state.selectedUser ? state.selectedUser : ''}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedUser: value }))}
                                    // widthClass={'widthMaxContent'}
                                    widthClass={'minWidth220'}
                                />
                            : null}
                        </div>
                    : state.selectedType === 'project' ?
                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                            onClick={(event) => {
                                if(!state.isProjectOpen) {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "isProjectOpen")
                                }
                            }}
                        >
                            <label className='mb-2 mt-1 f10 d-flex'>Project</label>
                            <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedProject ? 'text-white' : 'placeholder'}`}>
                                {state.selectedProject ? state.selectedProject : 'Select'}
                                <i className='fa fa-caret-down mt-1 mr-1'/>
                            </p>
                            {state.isProjectOpen ?
                                <MultiSelectSection
                                    fields={["project", "project"]}
                                    options={state.projects}
                                    selectedValues={state.selectedProject ? state.selectedProject : ''}
                                    callbackMultiSelect={(value) => {
                                        if(!value || typeof(value) === 'string') {
                                            setState(prevState => ({ ...prevState, 
                                                selectedProject: value,
                                                selectedProjectTags: state.projects.filter(e => e.project === value).length && state.projects.filter(e => e.project === value)[0] && state.projects.filter(e => e.project === value)[0].tags ? state.projects.filter(e => e.project === value)[0].tags : []
                                            }))
                                        } else {
                                            value.preventDefault()
                                            handleChildClick(value, "search", 'singleDropDown', "")
                                        }
                                    }}
                                    singleSelection={true}
                                    widthClass={'minWidth220'}
                                />
                            : null}
                        </div>
                    : null}
                    </React.Fragment>
                : null}
                
                {props.filters && props.filters.includes('duration') ?
                    <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '190px', maxWidth: '190px'}}
                        onClick={(event) => {
                            if(!state.isDurationOpen) {
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "isDurationOpen")
                            }
                    }}>
                        <label className='mb-2 mt-1 f10 d-flex'>Duration</label>
                        <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.datePickerStartDate ? 'text-white' : 'placeholder'}`}>
                            {state.datePickerStartDate ?
                                <span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
                            : 'Select'}
                        </p>
                        {state.isDurationOpen ?
                            <MultiSelectSection
                                fields={["value", "label"]}
                                options={DURATION_OPTIONS}
                                widthClass={'minWidth220'}
                                selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                callbackMultiSelect={(value) => {
                                    if(typeof(value) === 'string') {
                                        if(value !== 'custom') {
                                            setState(prevState => ({ ...prevState, selectedDuration: value }))
                                        } else {
                                            setState(prevState => ({ ...prevState, showDateRangePicker: value === 'custom' ? true : false }))
                                        }
                                    } else {
                                        value.preventDefault()
                                        handleChildClick(value, "search", 'singleDropDown', "")
                                    }
                                }}
                                singleSelection={true}
                            />
                        : null}
                        {state.showDateRangePicker ?
                            <div className="position-absolute z999" 
                            // style={{"z-index": "999999999","right": "20px","height": "450px"}} 
                            ref={durationRef}>
                                <div className="RangePickerWithStartEndTime text-black5 pt-2 ml-n1">
                                    {/* <div className='d-flex justify-content-between'>
                                        <p className="mb-2 w-100 text-white">Date Picker</p>
                                        <i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}></i>
                                    </div> */}
                                    <div className='headerDateRange' style={{'z-index': '999999999','right': '30px', 'top': '50px'}}>
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={handleSelect}
                                            moveRangeOnFirstSelection={false}
                                            ranges={dateState}
                                        />
                                    </div>
                                    <div className={`d-flex flex-wrap pt-2 border-top displayNone`}>
                                        <span className={`mr-2 f12 align-self-center text-white`}>Last</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+7d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }))}>1 Week</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+14d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }))}>2 Week</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+30d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }))}>1 Month</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+60d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }))}>2 Month</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+90d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }))}>3 Months</span>
                                    </div>
                                    <div className='d-flex justify-content-end pt-2 mt-2 border-top displayNone'>
                                        <button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        : null}
                    </div>
                : null}
                
                <div className='align-self-center'>
                    <div className='btn-group align-self-center' role='group' aria-label='Basic example'>
                        <button type='button' className={`${state.showLoading ? 'disabled' : 'cursorPointer'} btn bg-cyon text-white border-right`}
                        onClick={() => setState(prevState => ({ ...prevState, callSearch: true }))}
                        >Search</button>
                        <button type='button' className={`${state.showLoading ? 'disabled' : 'cursorPointer'} btn bg-cyon text-white border-left`} onClick={() => onReset()}><i className='far fa-redo'/></button>
                    </div>
                    {props.filterAdvancedSearch ?
                        <p className='small mt-1 text-info mb-0 d-flex justify-content-center' onClick={() => props.onClickAdvancedSearch()}>Advanced Search</p>
                    : null}
                </div>
            </div>
        </div>
    );
};

export default FilterSection;