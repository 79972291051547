/*************************************************
 * Collider
 * @exports
 * @file SavedFiltersSidePane.js
 * @author Prakash // on 18/04/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Spinner, Input } from 'reactstrap'

import { listCostTemplates, saveTemplate } from '../../../actions/cost/CostAction'

import { capitalizeFirstLetter, momentConvertionUtcToLocalTime, momentDateGivenFormat } from '../../../utils/utility'
import Search from '../../common/SearchComponent';
 import { Store as CommonNotification } from 'react-notifications-component';

const SavedFiltersSidePane = (props) => {
    const[state, setState] = useState({
		minMaxHeight: 700,
		showLoading: true,
		template_name: "",
		description: "",		

		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		perPage: 15,
	})
	
	// const tagKeys = useSelector(state => state.filters.tagKeys);

    const dispatch = useDispatch(); // Create a dispatch function

    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel()
        }
    }, [props]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);

	const templateViewFunction = useCallback(() => {		
		let inputFilters = props.selectedFilters
		let selectedFilters = []
		if(inputFilters.Tags) {
			selectedFilters.push(inputFilters.Tags)
		}
		if(inputFilters.Duration) {
			selectedFilters.push(inputFilters.Duration)
		}
		selectedFilters = [...selectedFilters, ...inputFilters.filters]
		setState(prevState => ({ ...prevState, savedFilters: props.selectedFilters, selectedFilters }))
				
	}, [props.selectedFilters])

	const listCostTemplatesFunction = useCallback(() => {
		let params = {}
		params.source = props.selectedProvider
		dispatch(listCostTemplates(params))
			.then((response) => {
				if(response) {
					let totalPages = 1
					if(response.length > state.perPage) {
						totalPages = Math.ceil(response.length / state.perPage)
					}
					
					setState(prevState => ({ ...prevState, listData: response, filteredListData: response, totalPages }))
				}
			})
	}, [dispatch, props.selectedProvider, state.perPage])

	useEffect(() => {
        // const {innerWidth, innerHeight} = window;
		let browserBorder = 100
        let headerHeihgt = 100
		let outerHeight = (window.outerHeight - browserBorder - headerHeihgt)

		setState(prevState => ({ ...prevState, minMaxHeight: outerHeight }))

		if(props.templateType === "view") {
			templateViewFunction()
		} else {
			setState(prevState => ({ ...prevState, selectedFilters: props.selectedFilters }))
		}
		listCostTemplatesFunction()

	}, [props.selectedFilters, props.templateType, templateViewFunction, listCostTemplatesFunction])

    const navigatePage = (action, currentPage) => {
		let startRecord = state.startRecord
		if(action === 'next' && currentPage !== state.totalPages) {
			startRecord = startRecord + state.perPage;
			setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
		} else if(action === 'previous' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
			startRecord = startRecord - state.perPage;
		} else if(action === 'start' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: 1 }));
			startRecord = 0;
		} else if(action === 'end' && currentPage !== 0) {
			setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
			startRecord = (state.totalPages - 1) * state.perPage;
		}
		setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

	useEffect(() => {
		if(state.callSaveFunction) {
			let hasError = false
			let params = {}
			params.source = props.selectedSource
			params.provider = props.selectedProvider		
			
			if(state.template_name && state.template_name !== "") {
				params.template_name = state.template_name
			} else {
				hasError = true
			}
	
			if(state.description && state.description !== "") {
				params.description = state.description
			} else {
				hasError = true
			}
	
			let selectedFilters = state.selectedFilters
	
			let filters = []
			if(selectedFilters.filter(e => e.label !== "Duration" && e.label !== "Tags").length) {
				selectedFilters.forEach(row => {
					if(row.label !== "Duration" && row.label !== "Tags") {
						filters.push(row)
					} else {
						params[row.label] = row
					}
				})
			}
	
			params.filters = filters
			
			if(!hasError) {
				dispatch(saveTemplate(params))
					.then((response) => {
						let messageType = 'danger'		
						if(response.status) {
							messageType = 'success'
						}
						let message = response && response.message
						CommonNotification.addNotification({
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 5000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						});
						if(response.status) {
							setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false }))
							setTimeout(() => { listCostTemplates() }, 2000)
						} else {
							setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false }))
						}
					})
			} else {
				setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, hasError }))
			}
		}

	}, [dispatch, props.selectedProvider, state.description, state.selectedFilters, state.template_name, state.callSaveFunction])

	const handleNameChange = e => {
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: value }))
	}

	const handleDesciption = e => {		
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: value }))
	}
	
	return (
		<div className="advanced-search" style={{ zIndex: 9999999 }}>
			{/* <div className={`loaderOverlay ${state.queryLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div> */}
            <div className="search-content bg-muted w-80">
                <div className="header-search bd-highlight d-flex justify-content-between p-2 bg-nero">
                    <div className="flex-fill bd-highlight text-white">
						<h5>{props.templateType === "save" ? "Save Filter (as Template)" : props.templateType === "view" ? "View Template" : "Templates"}</h5>
					</div>

					<div className="text-right flex-fill bd-highlight align-self-center">
						<i className={`far fa-times text-white cursorPointer`} onClick={() => props.closeSidePanel()}></i>
					</div>
				</div>
				<div className={`overflow-auto p-2`}>						
					{props.templateType !== "template" ?
						<div className="card-body bg-white m-2 pt-2 rounded counts_threads">
							<div className="d-flex">
								<div className="py-1 w-50">
									<div className='w-100'>
										<div className="d-flex justify-content-between">
											<label className="mb-1">Title </label>
											{state.hasError && (!state.template_name || state.template_name === "") ?
												<small className={`text-danger mr-2`}>(required)</small>
											: null}
										</div>
										<p className="mb-0 text-black">
											{props.templateType === "view" ?
												capitalizeFirstLetter(state.savedFilters && state.savedFilters.template_name)
											: 
												<Input
													type="text"
													placeholder="Enter template name"
													className={`inputTextbox text-black`}
													// maxlength="128"
													name="template_name"
													value={state.template_name || ''}
													onChange={handleNameChange}
												/>
											}
										</p>
									</div>
									<div className='w-100 mt-4'>
										<div className="d-flex justify-content-between">
											<label className="mb-1">Source </label>
										</div>
										<p className="mb-0 text-black">{capitalizeFirstLetter(props.selectedProvider)}</p>
									</div>
								</div>
								<div className="py-1 w-50 pl-3">
									<div className="d-flex justify-content-between">
										<label className="mb-1">Description </label>
										{state.hasError && (!state.description || state.description === "") ?
											<small className={`text-danger mr-2`}>(required)</small>
										: null}
									</div>
									{props.templateType === "view" ? 
										<p className="mb-0 text-black">{state.savedFilters && state.savedFilters.description}</p>
									: 
										<Input
											type='textarea'
											className='inputTextbox text-black'
											placeholder='Enter Description'
											rows='4'
											id='comment'
											maxlength='256'
											name="description"
											onChange={handleDesciption}
											value={state.description}
										/>
									}
								</div>
							</div>
							<div className='bg-darkGray p-3 rounded my-4'>
								<div className='d-flex justify-content-between mb-2'>
									<p className="mb-2 w-100 text-white">Selected Filters</p>
								</div>
								<div className="d-flex flex-wrap">
									{state.selectedFilters && !state.selectedFilters.filter(e => e.label === "Account").length ?
										<small className="font-weight-bold align-self-center cursorPointer mr-2 mt-2">Account : <span className='text-info'>All</span></small>
									: null}

									{state.selectedFilters && !state.selectedFilters.filter(e => e.label === "Region").length ?
										<small className="font-weight-bold align-self-center cursorPointer mx-2 mt-2">Region : <span className='text-info'>All</span></small>
									: null}
									
									{state.selectedFilters && state.selectedFilters.length ? 
										state.selectedFilters.map((filters, index) => {
											return(
												filters.label === "Duration" ?
													<React.Fragment>
														<small className="font-weight-bold align-self-center mt-2 cursorPointer mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </small>
													</React.Fragment>
												: filters.value && !Array.isArray(filters.value) ?
													<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.label}</span>
												: filters.label === "Tags" ?
													filters.value.map(row => {
														return(
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																<span className='text-primary-color'>Tag :</span> 
																{row.key +" : "+row.value}
															</span>
														)
													})
												:
													filters.value.map(row => {
														return(
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																<span className='text-primary-color'>{filters.type+" : "}</span> 
																{filters.label +" : "+row}
															</span>
														)
													})
											)
										})
									: null}
								</div>
							</div>
							<div className={`d-flex mb-2 justify-content-${props.templateType === "save" ? "between" : "end"}`}>
								{props.templateType === "save" ?
									state.saveLoading ?
										<button className="btn btn-success">
											Saving <Spinner size='sm' color='light' className='ml-2' />
										</button>
									: 
										<button className={`d-flex justify-content-end cursorPointer btn-sm ${state.saveLoading ? 'disabled btn-primary' : 'btn-primary'}`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>Save</button>
								: null}
								<button className={`d-flex justify-content-end cursorPointer btn-sm btn-light`} onClick={ () => props.closeSidePanel()}>Cancel</button>
							</div>
						</div>
					: null}
					<div className={`d-flex pb-2 justify-content-end mt-2`}>
						<div className="d-flex mr-2 w-40">
							<p className="f12 m-0 align-self-center pt-1">Showing {state.filteredListData && state.filteredListData.length} of total {state.listData && state.listData.length} template(s)</p>
						</div>
						<div className="d-flex justify-content-end w-60 mb-n2">
							{state.listData && state.listData.length ?
								<Search
									data={state.listData ? state.listData : []}
									applyTags={false}
									applyLiteDarkTags={true}
									// topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
									// searchClassName={'px-2 f12'}
									// searchIconColor={'text-gray5 f12'}
									// searchPlaceHolder={'Search....'}
									// className={"bg-transparent text-white pl-0 form-control-sm f12"}
									
									topClassName={'bg-transparent align-self-center w-80 mr-1 border-mediumDarkGray rounded-5 f12'}
									searchClassName={'px-2 f12'}
									searchIconColor={'text-gray5 f12'}
									searchPlaceHolder={'Search....'}
									className={"bg-transparent text-white pl-0 form-control-sm f12"}

									filteredData={(filteredListData) => {
										let totalPages = 1
										if(filteredListData.length > state.perPage) {
											totalPages = Math.ceil(filteredListData.length / state.perPage)
										}
										setState(prevState => ({ ...prevState, filteredListData, startRecord: 0, currentPage: 1, totalPages }))
									}}
								/>
							: null}

							{state.filteredListData && state.filteredListData.length > state.perPage ?
								<div className="pagination errorPagePagination f12 mt-n1">
									<span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
									<button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
									<button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
									<button><i className={`fal fa-angle-right cursorPointer ${!state.nextErrorListToken && state.nextErrorListToken ==="" && state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
									<button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
								</div>
							: null}
						</div>
					</div>
					<div className="table-responsive">
						<table className="table">
							<thead className="text-white bg-dark3">
								<tr>
									<th className="" width="10%">Date</th>
									<th className="" width="10%">Title</th>
									<th className="" width="30%">Description</th>
									<th className="" width="50%">Filters</th>
								</tr>
							</thead>
							<tbody className="text-white">
								{state.filteredListData && state.filteredListData.length ?
									state.filteredListData.slice(state.startRecord, state.startRecord + state.perPage).map((row, i) => {
										return(
											<tr className={`i ? 'border-mediumDarkGray-top' : ''`}> 
												<td className="">{row.created_at ? momentConvertionUtcToLocalTime(row.created_at, 'DD MMM YYYY HH:mm') : ""} </td>
												<td className="">{row.template_name ? row.template_name : ""}</td>
												<td className="">{row.description ? row.description : ""}</td>
												<td className="d-flex flex-wrap">
													{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Account").length ?
														<small className="font-weight-bold align-self-center cursorPointer mr-2">Account : <span className='text-info'>All</span></small>
													: null}	

													{Array.isArray(row.filters) && !row.filters.filter(e => e.label === "Region").length ?
														<small className="font-weight-bold align-self-center cursorPointer mx-2">Region : <span className='text-info'>All</span></small>
													: null}

													{row["Duration"] ?
														<div className='d-flex ml-2'>
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
														</div>
													: null}

													{row["Tags"] ?
														row["Tags"].value.map(tag => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>Tag :</span> {tag.key +" : "+tag.value}</span>
															)
														})
													: null}

													{row.filters ? 
														Array.isArray(row.filters) ? 
															row.filters.map(flt => {
															return(
																Array.isArray(flt.value) ?
																	flt.value.map(item => {
																		return(
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>{flt.type} : </span> 
																				{flt.label +" : "+item}
																			</span>
																		)
																	})
																: 
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>{flt.label+" : "}</span> {flt.output_label ? flt.output_label : flt.value}</span>
															)
															})
														: typeof(row.filters) === "object" ?
															Object.entries(row.filters).map(([fKey, fValue]) => {
															return(
																Array.isArray(fValue) ?
																	fValue.map(item => {
																		return(
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				{fKey +" : "+item}
																			</span>
																		)
																	})
																: 
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>{fKey+" : "+fValue}</span></span>
															)
															})
														: null
													: null}
												</td>
											</tr>
										)
									})
								: (!state.showLoading && !state.assetsList) ||
									(state.assetsList && !state.assetsList.length && !state.showLoading) ? 
									<div className="d-flex justify-content-center m-4">
										<p>There are no data on this criteria. Please try adjusting your filter.</p>
									</div>
								: null}
								{/* {state.showLoading ? (
									<div className="d-flex justify-content-center m-4">
										<Spinner className="text-center" color='dark' size="lg" />
									</div>
								) : null} */}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SavedFiltersSidePane