/*************************************************
 * Collider
 * @exports
 * @file metricsAction.js
 * @author prakash
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
// import cognitoService from '../../services/cognitoService'
// import { myLog } from '../../utils/utility'

import ApiCalls from '../components/ApiCalls'
import {
    GET_MONITORING_METRICS_BY_TREND,
	GET_MONITORING_METRICS_BY_TREND_V2,
	GET_SRE_DETAILS,
	GET_PEAK_DIP_DETECTION_RESULTS
} from '../config'
import { refreshPage } from '../utils/utility';

// import { ACTION_TYPES } from './types'
//import Cookies from 'js-cookie'
// import axios from 'axios'


/**
 * Action to get Remediation Status
 * @param {Object} body
 * @param {Function} return
 */
export const getMonitoringMetricsByTrend = (params) => {
	return async dispatch => {		
		try {
			const url = GET_MONITORING_METRICS_BY_TREND;
			const response = await ApiCalls.post(url, params);
			
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to get Remediation Status
 * @param {Object} body
 * @param {Function} return
 */
 export const getMonitoringMetricsByTrendV2 = (params) => {
	return async dispatch => {		
		try {
			const url = GET_MONITORING_METRICS_BY_TREND_V2;
			const response = await ApiCalls.post(url, params);
			
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to get-peak-dip-detection-results
 * @param {Object} body
 * @param {Function} return
 */
export const getPeakDipDetectionResults = (params) => {
	return async dispatch => {		
		try {
			const url = GET_PEAK_DIP_DETECTION_RESULTS;
			const response = await ApiCalls.post(url, params);
			
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to get sre details 
 * @param {Object} body
 * @param {Function} return
 * @author Prakash // on 21/06/2023
 */
 export const getSreDetails = (params) => {
	return async dispatch => {		
		try {
			const url = GET_SRE_DETAILS;
			const response = await ApiCalls.post(url, params);
			
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				////localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}