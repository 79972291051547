/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Recommendations.js
 * @author Prakash // on 10/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React from 'react';
import RecommendationsLeftSection from './RecommendationsLeftSection'
import RecommendationsRightSection from './RecommendationsRightSection'

const Recommendations = () => {
    return (
        <div className={`container-fluid overflow-auto flex-grow-1 px-0`}>
            <div className='d-flex vh100'>
                <div className={`border-right col-md-5 p-0 vh100`}>
                    <RecommendationsLeftSection />
                </div>
                <div className={`col-md-7 px-2 vh100`}>
                    <RecommendationsRightSection />
                </div>
            </div>
        </div>
    )
}

export default Recommendations