/*************************************************
 * Collider
 * @exports
 * @file QueueDetailsSidePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { listStackEvents } from '../../actions/CatalogAction';
import { Spinner } from 'reactstrap';
import Search from '../common/SearchComponent'
import Select from 'react-select';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase, getDayFromSelectedDuration, momentConvertionLocalToUtcTime, subDays, subHours, momentDateGivenFormat } from '../../utils/utility';
import MetricsTab from '../common/Mertics/MetricsTab';
import { getQueueMetrics } from '../../actions/Collider/ObservabilityAction';
// import { RangePicker } from 'react-minimal-datetime-range';
// import 'react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

const durationOptions = [    
    { value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const QueueDetailsSidePanel = ({ selectedQueueDetails, closeSidePanel, detailsFormat }) => {
    const [state, setState] = useState({
        showLoading: false,
        summaryLoader: true,
        selectedTab: 'Details',
        minMaxHeight: '770px',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        selectedGraphDuration: '+3h'
    })

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

    const clickOut = useRef();
    const dateRef = useRef();
    const dispatch = useDispatch(); // Create a dispatch function    
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        getWindowSize();
    }, [handleKeyDown]);


    const getWindowSize = () => {
        // Calculate outerHeight and set it to state
        
		// const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 100
				
		let calculatedOuterHeight = (window.outerHeight - browserBorder - headerHeight ) + 'px'
        
        // Use the setState setter function to update the state
        setState(prevState => ({ ...prevState, minMaxHeight: calculatedOuterHeight }));
    }

    useEffect(() => {
        if (state.callEventsList && selectedQueueDetails) {
            let params = {}
            params.provider = selectedQueueDetails.provider
            params.account_id = selectedQueueDetails.account_id
            params.region = selectedQueueDetails.region
            params.cluster_name = selectedQueueDetails.cluster_name
            params.queue_name = selectedQueueDetails.queue_name
            
				
			if(state.nextToken) {
				params.next_token = state.nextToken
			}

            // Fetch job metrics data here using params and update the state
            dispatch(listStackEvents(params))
                .then((response) => {
                    if(response) {
                        let nextToken = ""
                        if(response.next_token) {
                            nextToken = response.next_token	
                        }

                        let results = [] 
                        if(state.nextToken && state.nextToken.length) {
                            results = state.listEvents && state.listEvents.length ? state.listEvents : []
                        }
                        
                        if(response.results && response.results.length) {
                            results = results.concat(response.results)
                        }
                        
                        let totalPages = 1
                        if(results && results.length) {
                            totalPages = Math.ceil(results.length / state.perPage)
                        }
                        setState(prevState => ({
                            ...prevState,
                            listEvents: results,
                            totalEvents: response.total ? response.total : 0,
                            totalPages,
                            nextToken,
                            filteredListEvents: results,
                            showEventsLoading: false,
                            callEventsList: false
                        }));
                    }
                })
        }
    }, [state.callEventsList, dispatch, selectedQueueDetails, state.nextToken, state.listEvents, state.perPage])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {            
            setState(prevState => ({ ...prevState, showEventsLoading: true, callEventsList: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    useEffect(() => {
        if(state.callMetricAPi) {

            let duration = state.selectedGraphDuration
            let startTime = '';
            let endTime = ''
            if(duration === 'custom') {
                startTime = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss');
                endTime = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss');
            } else {
                let period = duration.substring(duration.length, duration.length - 1);
                let durationBtw = getDayFromSelectedDuration(duration);
                
                if (period === 'd') {
                    startTime = momentConvertionLocalToUtcTime(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
                } else {
                    startTime = momentConvertionLocalToUtcTime(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
                }
                endTime = momentConvertionLocalToUtcTime(new Date(), 'YYYY-MM-DD HH:mm:ss');
            }
    
            let params = {};
            params.start_time = startTime;
            params.end_time = endTime;
            params.asset_id = selectedQueueDetails && selectedQueueDetails.asset_id;
            if(selectedQueueDetails && selectedQueueDetails.queue_name) {
                params.queue_name = selectedQueueDetails.queue_name
            }
            
            if(selectedQueueDetails && selectedQueueDetails.account_id) {
                params.account_id = selectedQueueDetails.account_id
            }
            if(selectedQueueDetails && selectedQueueDetails.region) {
                params.region = selectedQueueDetails.region
            }
        
            if (selectedQueueDetails && selectedQueueDetails.asset_id) {            
                dispatch(getQueueMetrics(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({
                                ...prevState,
                                metricDetails: response,
                                showMetricLoading: false,
                                callMetricAPi: false
                            }));
                        }
                    });
            }
        }
    }, [state.callMetricAPi, dispatch, state.selectedGraphDuration, selectedQueueDetails, state.datePickerEndDate, state.datePickerStartDate])

    const handleClickOutside = (event) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
        if (dateRef.current && !dateRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
    }

    const handleSelect = (date) => {
        // let datePickerStartDate = momentDateGivenFormat(
        //     date.selection.startDate,
        //     'YYYY-MM-DD 00:00:00'
        // );
        // let datePickerEndDate = momentDateGivenFormat(
        //     date.selection.endDate,
        //     'YYYY-MM-DD 23:59:59'
        // );
        // setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }))     
        
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false,
                selectedGraphDuration: "custom",
                metricDetails: [], 
                showMetricLoading: true,
                callMetricAPi: true
            }));
    
        }
        setDateState([date.selection])
    };

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }} onClick={handleClickOutside}>
            <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div>
            <div className="search-content bg-dark2 w-70 h-100 d-flex flex-column" ref={clickOut}>
                <div className="header-search bd-highlight bg-black d-flex justify-content-between p-2">
                    <div className="flex-fill bd-highlight text-white">
                        <h5>Queue Details</h5>
                        <div className="mr-2 d-flex">
                        </div>
                    </div>

                    <div className="text-right flex-fill bd-highlight align-self-center">
                        <i className="far fa-times fa-lg text-white cursorPointer" onClick={() => closeSidePanel()}></i>
                    </div>
                </div>                
                <React.Fragment>
                    <div className="mt-2">
                        <ul className={`nav nav-tabs border-bottom bg-transparent shadow-none`} id="myTab" role="tablist">
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${state.selectedTab === 'Details' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${state.selectedTab === 'Observability' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Observability', showMetricLoading: true, callMetricAPi: true }))}>Observability</span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${state.selectedTab === 'Events' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', showEventsLoading: true, callEventsList: true }))}>Events</span>
                            </li>
                        </ul>
                    </div>
                    {state.selectedTab === "Details" ?
                        <div className='m-2 flex-grow-1 overflow-auto'>
                            <div className="rounded bg-dark3 p-3">
                                {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                    return(
                                        <div className={`${!index ? '' : 'mt-2'}`}>
                                            <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                            <small>{row.description}</small>
                                            {row.fields && row.fields.map(fld => {
                                                return(
                                                    fld === "queue_configuration" ?
                                                        <div className={`rounded bg-dark2 p-2 mb-2 mt-2`}>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Subnet IDs</span>
                                                                <div calss="d-flex flex-wrap">
                                                                    {selectedQueueDetails[fld] && selectedQueueDetails[fld].subnet_ids ?
                                                                        selectedQueueDetails[fld].subnet_ids.map(ids => {
                                                                            return(
                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{ids}</span>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Security Group IDs</span>
                                                                <div calss="d-flex flex-wrap">
                                                                    {selectedQueueDetails[fld] && selectedQueueDetails[fld].security_group_ids ?
                                                                        selectedQueueDetails[fld].security_group_ids.map(ids => {
                                                                            return(
                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{ids}</span>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Policies</span>
                                                                <div calss="d-flex flex-wrap">
                                                                    {selectedQueueDetails[fld] && selectedQueueDetails[fld].policies ?
                                                                        selectedQueueDetails[fld].policies.map(pol => {
                                                                            return(
                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{pol}</span>
                                                                            )
                                                                        })
                                                                    : null}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Script Location</span>
                                                                <span className={`align-self-center text-white`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].script_location ? selectedQueueDetails[fld].script_location : ""}</span>
                                                            </div>

                                                            <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Volume Details</p>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Type</span>
                                                                <span className={`align-self-center text-white`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_type ? selectedQueueDetails[fld].volume_type : ""}</span>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Size</span>
                                                                <span className={`align-self-center text-white`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_size && selectedQueueDetails[fld].volume_size}</span>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Encryption</span>
                                                                <span className={`align-self-center text-white`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].volume_encryption ? "True" : "False"}</span>
                                                            </div>

                                                            <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Bucket</span>
                                                                <span className={`align-self-center text-white`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].bucket_name ? selectedQueueDetails[fld].bucket_name : ""}</span>
                                                            </div>
                                                            <div className="d-flex mt-3">
                                                                <span className="minWidth220 mr-3 align-self-center">Write Access</span>
                                                                <span className={`align-self-center text-white`}>{selectedQueueDetails[fld] && selectedQueueDetails[fld].enable_s3_write_access ? "True" : "False"}</span>
                                                            </div>

                                                            <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Compute Resources</p>
                                                            {selectedQueueDetails[fld] && selectedQueueDetails[fld].compute_resources ?
                                                                selectedQueueDetails[fld].compute_resources.map((comp, cIndex) => {
                                                                    return (
                                                                        <div className={`bg-dark p-2 rounded mb-2 ${!cIndex ? "mt-2" : ""}`}>
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">Name</span>
                                                                            <span className={`align-self-center text-white`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">Instance Type</span>
                                                                            <span className={`align-self-center text-white`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">Min Instance Count</span>
                                                                            <span className={`align-self-center text-white`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">Max Instance Count</span>
                                                                            <span className={`align-self-center text-white`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">Efa Enabled</span>
                                                                            <span className={`align-self-center text-white`}>{comp && comp.ena_enabled ? "True" : "False"}</span>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">Placement Group Enabled</span>
                                                                            <span className={`align-self-center text-white`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                        </div>
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">Disable Simultaneous Multithreading</span>
                                                                            <span className={`align-self-center text-white`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                        </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    :
                                                        <div className="d-flex mt-3">
                                                            <span className="minWidth220 mr-3 align-self-center">{removeUnderScore(fld)}</span>
                                                            {selectedQueueDetails && selectedQueueDetails[fld] && fld !== "queue_configuration" ?
                                                                <React.Fragment>
                                                                {typeof selectedQueueDetails[fld] === 'boolean' ?
                                                                    <span className={`text-white`}>{selectedQueueDetails[fld] ? 'True' : 'False'}</span>
                                                                : isValidDateTime(selectedQueueDetails[fld]) ?
                                                                    <span className="text-white">{momentConvertionUtcToLocalTime(selectedQueueDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                : convertToLowerCase(fld) === "status" ?
                                                                    <span className={`align-self-center badge bg-mulbery text-white`}>{selectedQueueDetails[fld]}</span>
                                                                : convertToLowerCase(fld) === "tags" ?
                                                                    <div calss="d-flex flex-wrap">
                                                                        {selectedQueueDetails[fld].map(item => {
                                                                            return(
                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                : 
                                                                    <span className="text-white">{fld === 'provider' ? selectedQueueDetails[fld].toUpperCase() : selectedQueueDetails[fld]}</span>
                                                                }
                                                                </React.Fragment>
                                                            : null}
                                                        </div>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    : state.selectedTab === "Events" ?
                        <div className='m-2 flex-grow-1 overflowYAuto vh100'>
                            <div className="h-100">
                                {state.showEventsLoading ?
                                    <div className='d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='dark'   />
                                    </div>
                                : state.listEvents && state.listEvents.length ?
                                    <div className='bg-dark3 p-2 mx-2 rounded'>
                                        <div className="d-flex mb-2 justify-content-between">
                                            <div className="d-flex mr-2 w-70">
                                                <p className="f12 m-0 align-self-center pt-1">Showing {state.filteredListEvents && state.filteredListEvents.length} of total {state.listEvents && state.listEvents.length} event(s)</p>
                                                <span className='ml-3 far fa-redo f12 cursorPointer text-info align-self-center' onClick={() => setState(prevState => ({ ...prevState, showEventsLoading: true, callEventsList: true }))}> <span className='ml-2'>refresh </span></span>
                                            </div>
                                            <div className='d-flex justify-content-end w-60'>
                                                {state.filteredListEvents && state.filteredListEvents.length > state.perPage ?
                                                    <div className="pagination errorPagePagination f12">
                                                        <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                                        <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                                        {/* <span>
                                                            Go to
                                                            <input
                                                                type='number'
                                                                value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPage}
                                                                onChange={e => {
                                                                    const page = e.target.value ? Number(e.target.value) : ""
                                                                    gotoPage(page)
                                                                }}
                                                                className="ml-1 inputClass"
                                                            />
                                                        </span> */}
                                                        {/* ${state.currentPage === state.totalPages ? (state.nextCatalogToken && state.nextCatalogToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i> */}

                                                        <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                                    </div>
                                                : null}
                                                <div className=" w-50 mb-n2">
                                                    {state.listEvents && state.listEvents.length ?
                                                        <Search
                                                            data={state.listEvents ? state.listEvents : []}
                                                            applyTags={false}
                                                            applyLiteDarkTags={true}
                                                            topClassName={'bg-transparent align-self-center w-100 mr-1 border-mediumDarkGray f12'}
                                                            searchClassName={'px-2 f12'}
                                                            searchIconColor={'text-lightGray f12'}
                                                            searchPlaceHolder={'Search....'}
                                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                            filteredData={(filteredListEvents) => {
                                                                let totalPages = 1
                                                                if(filteredListEvents.length > state.perPage) {
                                                                    totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                                                }
                                                                setState(prevState => ({ 
                                                                    ...prevState,  
                                                                    // refreshClusters: true,
                                                                    filteredListEvents,
                                                                    totalPages
                                                                }))
                                                                // setState(prevState => ({ ...prevState, filteredListEvents }))
                                                            }}
                                                        />
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        {state.filteredListEvents && state.filteredListEvents.length ?
                                            state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                return(
                                                    <div key={"fil_"+i} className={`px-2 mb-2 pb-1 ${!i ? "" : "border-mediumDarkGray-top"}`}> 
                                                        <div className="d-flex cursorPointer">
                                                            <p className="mb-0">
                                                                <span className="mb-0 mr-2 text-white f12 font-weight-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                                                {item.resource_type ?
                                                                    <span className={`mb-0 mr-2 f12 ml-2`}>{item.resource_type}</span>
                                                                : null}
                                                                {item.resource_status ?
                                                                    <span className={`mb-0 mr-2 f12 text-info ml-2`}>{item.resource_status}</span>
                                                                : null}
                                                                {item.user_id ?
                                                                    <span className={`mb-0 mr-2 f12 ml-2`}>{item.user_id}</span>
                                                                : null}
                                                                {item.resource_status_reason ?
                                                                    <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.resource_status_reason}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <div className='d-flex justify-content-center m-4'>
                                                There are no events on this criteria. Please try adjusting your filter.
                                            </div>
                                        }
                                    </div>
                                :
                                    <div className='d-flex justify-content-center m-4'>
                                        Therer are no events on this criteria.
                                    </div>
                                }
                            </div>
                        </div>
                    : state.selectedTab === 'Observability' ?  
                        <div className='rounded bg-black m-2 p-2 overflowYAuto h-100'>
                            {selectedQueueDetails && selectedQueueDetails.asset_id ?
                                <div className='d-flex justify-content-between mb-1'>
                                    <p className='mb-1'>Showing data for the period of <span className='ml-1 text-info'>{state.datePickerStartDate ? momentDateGivenFormat(state.datePickerStartDate, "DD MMM YYYY HH:mm") : ''} - {state.datePickerStartDate ? momentDateGivenFormat(state.datePickerEndDate, "DD MMM YYYY HH:mm") : ''}</span> <span className='ml-2'>(local time)</span>
                                    </p>
                                    <div className="d-flex">
                                        <label className='mb-0 mr-2 align-self-center'>Duration</label>
                                        <div style={{ minWidth: "130px" }}>
                                            <Select
                                                placeholder={'Select'}
                                                isSearchable={false}
                                                className='f13 p-0 filterSelectDrop'
                                                components={{
                                                    IndicatorSeparator: () => null,
                                                }}
                                                value={{
                                                    value: state.selectedGraphDuration ? state.selectedGraphDuration : 'Select',
                                                    label: state.selectedGraphDuration ? durationOptions.filter(e => e.value === state.selectedGraphDuration)[0].option : <span className='placeholder'>Select</span>
                                                }}
                                                options={durationOptions && durationOptions.map(row => ({
                                                    value: row.value,
                                                    label: row.option,
                                                }))}
                                                onChange={event => {
                                                    if(event.value !== 'custom') {
                                                        setState(prevState => ({ ...prevState, metricDetails: [], showMetricLoading: true, selectedGraphDuration: event.value, showDateRangePicker: false }))
                                                    } else {
                                                        if(state.selectedGraphDuration === 'custom') {
                                                            setState(prevState => ({ ...prevState, showDateRangePicker: true }))
                                                        } else {
                                                            setState(prevState => ({ ...prevState, selectedGraphDuration: 'custom', showDateRangePicker: true }))
                                                        }
                                                    }                                                    
                                                }}
                                            />
                                            {state.showDateRangePicker ?
                                                <div className='headerDateRange mt-2' style={{'z-index': '999999999','position': 'absolute','right': '10px'}} ref={dateRef}>
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={handleSelect}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={dateState}
                                                    />
                                                    {/* // <div className='obsDateTimePicker text-black5 pt-2 ml-n1' style={{'z-index': '999999999','position': 'absolute','right':0}}>
                                                    //     <RangePicker
                                                    //         show={true} // default is false
                                                    //         disabled={false}
                                                    //         placeholder={['Start Time', 'End Time']}
                                                    //         defaultTimes={['00:00', '23:59']} // ['hh:mm', 'hh:mm']
                                                    //         onConfirm={handleSelectNew}
                                                    //         // onClose={() => console.log('onClose')}
                                                    //         // onClear={() => console.log('onClear')}
                                                    //         style={{ width: '610px', margin: '0 auto' }}
                                                    //     />
                                                    // </div> */}
                                                    
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                </div>
                            :
                                <p className='f12 m-3 align-self-center'>Please select an asset to get the Metric details</p>
                            }
                            <div className='d-flex justify-content-between mb-1'>
                                {selectedQueueDetails && selectedQueueDetails.asset_name ?
                                    <span className={`ml-1 text-cyon mb-0`}>
                                        <span className='mr-1'>{state.selectedProcess ? state.selectedProcess : (state.selectedQueue ? state.selectedQueue : "")}</span>
                                        {selectedQueueDetails.asset_name}
                                    </span>
                                : null}
                                {/* {selectedQueueDetails && selectedQueueDetails.asset_name ?
                                    <div className={`rounded mr-2 bg-black text-cyon px-2 py-1 f12 hiddenEllipses align-self-center`} id={`metricsTitle`}>
                                        {selectedQueueDetails.asset_name}
                                    </div>
                                : null} */}
                            </div>
                            {state.showMetricLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='dark'   />
                                </div>
                            : Object.entries(selectedQueueDetails).length && !state.showMetricLoading ?
                                <MetricsTab
                                    metricDetails={state.metricDetails}
                                    selectedDetails={selectedQueueDetails}
                                    height={115}
                                />
                            : null}
                        </div>
                    : null}
                </React.Fragment>   
            </div>
        </div>
    )
}
export default QueueDetailsSidePanel;