import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { UncontrolledTooltip } from 'reactstrap'
import { listUserDetails, listClusterFilters } from '../../../actions/commonActionNew'
import { getHpcReportQueries } from '../../../actions/Collider/HpcReportAction'
import _ from 'lodash'
import { getAccountNameFromId, getRegionName, momentDateGivenFormat } from '../../../utils/utility'

import MultiSelectSection from '../../common/MultiSelectSection';
import ReportSection from './ReportSection';
import Search from '../../common/SearchComponent';
import DiskReportSection from './DiskReportSection';
import FilterSection from '../../common/FiltersSection';

const ReportSample = () => {
    const clickOutside = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',

        selectedCategory: "All",
		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		overAllTotalPages: 1,
		perPage: 10,

        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'duration'],
        typeOptions: ['User', 'Location', 'Software']
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callSearch: true }));
    //     }
    // }, [providers]);

    useEffect(() => {
        let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        }
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
     
    }, [state.selectedDuration])

    const getReportClusters = (account_id, region, report_id) => {
        let params = {}
        params.provider = state.selectedProvider.toLowerCase()
        if(account_id && account_id.length) {
            params.account_id = account_id
        }
        if(region && region.length) {
            params.region = region
        }
        params.aggregate_by = 'cluster_name'
        dispatch(listClusterFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, ['clusters_'+report_id]: response, ['selectedClusters_'+report_id]: (report_id === 'HPC014' || report_id === 'HPC017') ? response[0] : [] }));
                }
        })
    }
                                                                                
    const getReportUsers = (account_id, region, cluster, report_id) => {
        let params = {}
        params.provider = state.selectedProvider.toLowerCase()
        if(account_id && account_id.length) {
            params.account_id = account_id
        }
        if(region && region.length) {
            params.region = region
        }
        if(cluster && ((Array.isArray(cluster) && cluster.length) || !Array.isArray(cluster))) {
            params.cluster_name = cluster
        }

        dispatch(listUserDetails(params))
            .then((response) => {
                if(response) {
                    let totalResults = []

                    let results = response && response.results && response.results.length ? response.results : []
                    if(results && results.length) {
                        totalResults = totalResults.concat(results)
                    }
                    
                    setState(prevState => ({
                        ...prevState,
                        ['users_'+report_id]: totalResults
                    }));
                }
        })
    }

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],            
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], selectedDuration: 'currentMonth', callSearch: true }));
    }

    useEffect(() => {
		if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			params.provider = state.selectedProvider && state.selectedProvider.toLowerCase()
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount.filter(e => e !== "All")
			}
			
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion.filter(e => e !== "All")
			}
			
			if(state.selectedClusters && state.selectedClusters.length) {
				params.cluster_name = state.selectedClusters.filter(e => e !== "All")
			}
            
			dispatch(getHpcReportQueries(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, queryListJson: response, listLoading: false, formQueryList: true, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, queryListJson: [], listLoading: false, showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters]) //state.selectedAccount, state.selectedRegion, state.selectedClusters

    useEffect(() => {
		if(state.formQueryList && state.queryListJson && state.queryListJson.length) {
            setState(prevState => ({ ...prevState, formQueryList: false }))
			let catArray = []
			let category = []
			state.queryListJson.forEach((item, index) => {
				item.category.forEach(cat => {
					let catRow = {}
					catRow.category = cat
					if(!category.includes(cat)) {
						category.push(cat)
					}
					if(item.sub_category) {
						catRow.sub_category = item.sub_category
					}
					catArray.push(catRow)
				})
			})
	
			let categories = []
			let totalCatCount = 0
			_.chain(catArray)
				.groupBy('category')
				.map((value, key) => ({ label: key, sub: value })).value()
				.forEach((category, i) => {
					let totalCount = 0
					let dataRow = {}
					dataRow.category = category.label
					let sub_category = []
					_.chain(category.sub)
					.groupBy('sub_category')
					.map((value, key) => ({ slabel: key, sSub: value })).value()
					.forEach((subCat, i) => {
						if(category.sub.filter(e => e.sub_category).length) {
							let subCatRow = {}
							subCatRow.sub_category = subCat.slabel
							subCatRow.count = subCat.sSub.length
							sub_category.push(subCatRow)
						}
						totalCatCount += subCat.sSub.length
						totalCount += subCat.sSub.length
					})
					dataRow.count = totalCount
					if(sub_category && sub_category.length) {
						dataRow.sub_category = sub_category
					}
                    categories.push(dataRow)
				})
	
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			dataRow.sub_category = ""	
			categories.push(dataRow)
	
			setState(prevState => ({ ...prevState, totalQueryList: state.queryListJson, queryList: state.queryListJson, categories: _.orderBy(categories, ['count'], ['desc']), getAllQueries: true, filterQueryList: true }))
		}
	}, [state.formQueryList, state.queryListJson])

	useEffect(() => {
		if(state.filterQueryList) {
			let data = state.totalQueryList
			if(state.selectedSubCategory) {
				data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
			} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
				data = data.filter(e => e.category.includes(state.selectedCategory))
			}
	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}

            // let filteredArray =   _.orderBy(data, ['report_id'], ['asc'])
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, filterQueryList: false }))
		}
	}, [state.filterQueryList, state.perPage, state.selectedCategory, state.selectedSubCategory, state.totalQueryList])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const querySelection = (i, item) => {
        let selectedReport = {}
        if(item !== state.selectedReport) {
            selectedReport = item
        }
        setState(prevState => ({  ...prevState, selectedReport, getUsers: true }))
    }

    const removeItem = (field, value) => {
        let results = state[field].filter(e => e !== value)

        setState(prevState => ({ ...prevState, [field]: results}))

    }

    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                </div>
            </div>
            <div className='row text-white mx-0 pb-2 bg-nero'>
                <div className='col-lg-2 col-md-12 col-sm-12 py-2 align-self-center'>
                    <h6 className='text-white m-0'>Reports</h6>
                    <p className='text-white m-0'>Consolidated view of reports</p>
                </div>
                <div className='col-lg-10 col-md-12 col-sm-12 py-2' ref={clickOutside}>
                    <div className='row mx-0 topBasicFilter justify-content-end'>
                        <FilterSection
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            {state.showReportDetails ?
                <ReportSection 
                    selectedProvider={state.selectedProvider}
                    accounts={state.accounts}
                    selectedAccount={state.selectedAccount}
                    regions={state.regions}
                    selectedRegion={state.selectedRegion}
                    selectedClusters={state.selectedClusters}
                    selectedReportInput={{
                        account_id: state['selectedAccount_'+state.selectedReport.report_id],
                        region: state['selectedRegion_'+state.selectedReport.report_id],
                        cluster_name: state['selectedClusters_'+state.selectedReport.report_id],
                        type: state['selectedType_'+state.selectedReport.report_id],
                        user_name: state['selectedUser_'+state.selectedReport.report_id],
                    }}
                    datePickerStartDate={state.datePickerStartDate}
                    datePickerEndDate={state.datePickerEndDate}
                    selectedReport={state.selectedReport}
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showReportDetails: false }))}
                />
            // : state.showLicenseUsageReport
            //     <showLicenseUsageReport 
            //         selectedProvider={state.selectedProvider}
            //         accounts={state.accounts}
            //         selectedAccount={state.selectedAccount}
            //         regions={state.regions}
            //         selectedRegion={state.selectedRegion}
            //         selectedClusters={state.selectedClusters}
            //         selectedReportInput={{
            //             account_id: state['selectedAccount_'+state.selectedReport.report_id],
            //             region: state['selectedRegion_'+state.selectedReport.report_id],
            //             cluster_name: state['selectedClusters_'+state.selectedReport.report_id],
            //             user_name: state['selectedUser_'+state.selectedReport.report_id],
            //         }}
            //         datePickerStartDate={state.datePickerStartDate}
            //         datePickerEndDate={state.datePickerEndDate}
            //         selectedReport={state.selectedReport}
            //         closeSidePanel={() => setState(prevState => ({ ...prevState, showReportDetails: false }))}
            //     /> 
            // 
            : state.showDiskReport ? 
                <DiskReportSection
                    selectedProvider={state.selectedProvider}
                    accounts={state.accounts}
                    selectedAccount={state.selectedAccount}
                    regions={state.regions}
                    selectedRegion={state.selectedRegion}
                    selectedClusters={state.selectedClusters}
                    selectedReportInput={{
                        account_id: state['selectedAccount_'+state.selectedReport.report_id],
                        region: state['selectedRegion_'+state.selectedReport.report_id],
                        cluster_name: state['selectedClusters_'+state.selectedReport.report_id],
                        user_name: state['selectedUser_'+state.selectedReport.report_id],
                    }}
                    datePickerStartDate={state.datePickerStartDate}
                    datePickerEndDate={state.datePickerEndDate}
                    selectedReport={state.selectedReport}
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showDiskReport: false }))}
                />
            : null}
            <div className='m-2'>
                <div className={`d-flex pb-2 justify-content-between`}>
                    <div className="d-flex mr-2 w-40">
                        <p className="f12 m-0 align-self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.queryList && state.queryList.length}{state.queryList && state.queryList.length > 1 ? ' queries' : ' query'}</p>
                    </div>
                    <div className="d-flex justify-content-end w-60 mb-n2">
                        {state.queryList && state.queryList.length ?
                            <Search
                                data={state.queryList ? state.queryList : []}
                                applyTags={false}
                                applyLiteDarkTags={true}
                                topClassName={'bg-darkGray align-self-center w-50 mr-1 border-lightGray rounded-5 f12'}
                                searchClassName={'px-2 f12'}
                                searchIconColor={'text-white f12'}
                                searchPlaceHolder={'Search reports....'}
                                className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                filteredData={(filteredArray) => {
                                    setState(prevState => ({ ...prevState, filteredArray, startRecord: 0, currentPage: 1 }))
                                }}
                            />
                        : null}
                        {state.filteredArray && state.filteredArray.length > state.perPage ?
                            <div className="pagination errorPagePagination f12">
                                <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                            </div>
                        : null}
                    </div>
                </div>
                <div className='d-flex bg-black p-2'>
                    {state.categories ? 
                        <div className="w-20 pr-3 categoryBoxSection" id="scrollTop">
                            <div className="bg-darkGray px-2 pt-2 select-bgg">
                                {state.categories.map((item, index) => {
                                    return(
                                        <div key={'cat_'+index}>
                                        {item.category === "All" ?
                                            <div className={`d-flex justify-content-between cursorPointer mb-2 ${state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: "All", selectedSubCategory: "", getAllQueries: true }))}>
                                                <p className="m-0 f-20">{item.category} Reports</p>
                                                {/* <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p> */}
                                                {/* <input className="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" /> */}
                                            </div>
                                        :
                                            <React.Fragment>
                                            <div className={`d-flex cursorPointer mb-2 ${(!state.selectedSubCategory || state.selectedSubCategory === '') && item.category.includes(state.selectedCategory) ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`}>
                                                <div className="d-flex justify-content-between mb-2" style={{maxWidth: "250px"}} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, selectedSubCategory: "", filterQueryList: true }))}>
                                                    <p className="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}>{item.category}</p>
                                                    <UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
                                                    <input className="form-control form-control-sm w-25 border-0 bg-mediumDarkGray text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" defaultValue={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
                                                </div>
                                            </div>
                                            </React.Fragment>
                                        }
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    : null}
                    <div className={`${state.categoires ? 'w-80' : 'w-100'} h-100`} id="scrollTop">
                        <div className="accordion" id="accordionExample">
                            {state.filteredArray && state.filteredArray.length ?
                                state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                    return(
                                        <div key={'filArr_'+i} className={`card bg-darkGray border-0 mb-2 p-2 overflow-unset`}>
                                            <div className="">
                                                <div id={'heading_'+i} className="p-3 cursor-pointer" onClick={() => querySelection(i, item)}>
                                                    <div className="mb-1 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                                        <div className="text-white">
                                                            <span className={`far ${state.selectedReport && state.selectedReport.report_id === item.report_id ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>
                                                            {item.report_id +" - "+ (item.title ? item.title : "")}
                                                        </div>
                                                    </div>
                                                </div>
                                                {state.selectedReport && state.selectedReport.report_id === item.report_id ?
                                                    <div className={`px-2 py-3 bg-black rounded mx-3 mt-1`}>
                                                        <p className='d-flex flex-wrap'>
                                                            <span className='mr-2 align-self-end text-white'>Account</span>
                                                            <div className='' style={{minWidth: "150px"}} onClick={(event) => {
                                                                event.preventDefault();
                                                                handleChildClick(event, 'child', 'muliselectDropDown', "showAccount")
                                                            }}>
                                                                <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state['selectedAccount_'+item.report_id] && state['selectedAccount_'+item.report_id].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state['selectedAccount_'+item.report_id] && state['selectedAccount_'+item.report_id].length ? state['selectedAccount_'+item.report_id].length +' Selected' : 'Select'}</p>
                                                                {state.showAccount ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            fields={["account_id", "account_name"]}
                                                                            // className={'position-unset'}
                                                                            removeTop={true}
                                                                            // borderClass={'boderDottedBottom'}
                                                                            widthClass={'minWidth220'}
                                                                            options={propProjAccounts[state.selectedProvider]}
                                                                            selectedValues={state['selectedAccount_'+item.report_id] ? state['selectedAccount_'+item.report_id] : ''}
                                                                            callbackMultiSelect={(value) => {
                                                                                setState(prevState => ({ ...prevState, ['selectedAccount_'+item.report_id]: value, ['selectedClusters_'+item.report_id]: [], ['selectedUser_'+item.report_id]: '' }))
                                                                                getReportClusters(value, state['selectedRegion_'+item.report_id], item.report_id)
                                                                                getReportUsers(value, state['selectedRegion_'+item.report_id], '', item.report_id)
                                                                            }}
                                                                            singleSelection={false}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            {state['selectedAccount_'+item.report_id] && state['selectedAccount_'+item.report_id].length ? 
                                                                state['selectedAccount_'+item.report_id].map((tab, addIndex) => {
                                                                    return(
                                                                        <span key={'tab2_'+addIndex} className={`badge badge-info mt-3 f13 align-self-end p-1 px-2 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                        {getAccountNameFromId(tab, state.accounts)}
                                                                            <i onClick={ () => removeItem('selectedAccount_'+item.report_id, tab) } className="ml-1 fal fa-times cursorPointer"></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            : null}

                                                            <span className='mx-2 align-self-end text-white'>Region</span>
                                                            <div className='' style={{minWidth: "150px"}} onClick={(event) => {
                                                                event.preventDefault();
                                                                handleChildClick(event, 'child', 'muliselectDropDown', "showRegion")
                                                            }}>
                                                                <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state['selectedRegion_'+item.report_id] && state['selectedRegion_'+item.report_id].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state['selectedRegion_'+item.report_id] && state['selectedRegion_'+item.report_id].length ? state['selectedRegion_'+item.report_id].length +' Selected' : 'Select'}</p>
                                                                {state.showRegion ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            fields={["region", "name"]}
                                                                            // className={'position-unset'}
                                                                            removeTop={true}
                                                                            // borderClass={'boderDottedBottom'}
                                                                            widthClass={'minWidth220'}
                                                                            options={propProjRegions[state.selectedProvider]}
                                                                            selectedValues={state['selectedRegion_'+item.report_id] ? state['selectedRegion_'+item.report_id] : ''}
                                                                            callbackMultiSelect={(value) => {
                                                                                setState(prevState => ({ ...prevState, ['selectedRegion_'+item.report_id]: value, ['selectedClusters_'+item.report_id]: [], ['selectedUser_'+item.report_id]: '' }))
                                                                                getReportClusters(state['selectedAccount_'+item.report_id], value, item.report_id)
                                                                                getReportUsers(state['selectedAccount_'+item.report_id], value, '', item.report_id)
                                                                            }}
                                                                            singleSelection={false}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            {state['selectedRegion_'+item.report_id] && state['selectedRegion_'+item.report_id].length ? 
                                                                state['selectedRegion_'+item.report_id].map((tab, addIndex) => {
                                                                    return(
                                                                        <span key={'tab2_'+addIndex} className={`badge badge-info mt-3 f13 align-self-end p-1 px-2 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                        {getRegionName(tab, state.regions)}
                                                                            <i onClick={ () => removeItem('selectedRegion_'+item.report_id, tab) } className="ml-1 fal fa-times cursorPointer"></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            : null}

                                                            <span className='mx-2 align-self-end text-white'>Cluster</span>
                                                            <div className='' style={{minWidth: "150px"}} onClick={(event) => {
                                                                if(item.report_id === 'HPC014' || item.report_id === 'HPC017') {
                                                                    if(!state.showClusters) {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', 'showClusters')
                                                                    }
                                                                } else {
                                                                    event.preventDefault();
                                                                    handleChildClick(event, 'child', 'muliselectDropDown', "showClusters")
                                                                }
                                                            }}>
                                                                <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 
                                                                    ${state['selectedClusters_'+item.report_id] ? 'text-white' : 'placeholder'}
                                                                `} style={{height: "38px"}}>
                                                                    {(item.report_id === 'HPC014' || item.report_id === 'HPC017') ?
                                                                        state['selectedClusters_'+item.report_id] ? state['selectedClusters_'+item.report_id] : 'Select'
                                                                    :
                                                                        state['selectedClusters_'+item.report_id] && state['selectedClusters_'+item.report_id].length ? state['selectedClusters_'+item.report_id].length +' Selected' : 'Select'
                                                                    }
                                                                </p>
                                                                {state.showClusters ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            // fields={["region", "name"]}
                                                                            // className={'position-unset'}
                                                                            removeTop={true}
                                                                            // borderClass={'boderDottedBottom'}
                                                                            widthClass={'minWidth220'}
                                                                            options={state['clusters_'+item.report_id]}
                                                                            selectedValues={state['selectedClusters_'+item.report_id] ? state['selectedClusters_'+item.report_id] : ''}
                                                                            callbackMultiSelect={(value) => {
                                                                                if(item.report_id === 'HPC014' || item.report_id === 'HPC017') {
                                                                                    if(!value || typeof(value) === 'string') {
                                                                                        setState(prevState => ({ ...prevState, ['selectedClusters_'+item.report_id]: value, ['selectedUser_'+item.report_id]: '' }))
                                                                                        getReportUsers(state['selectedAccount_'+item.report_id], state['selectedRegion_'+item.report_id], value, item.report_id)
                                                                                    } else {
                                                                                        value.preventDefault()
                                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                                    }
                                                                                } else {
                                                                                    setState(prevState => ({ ...prevState, ['selectedClusters_'+item.report_id]: value, ['selectedUser_'+item.report_id]: '' }))
                                                                                    getReportUsers(state['selectedAccount_'+item.report_id], state['selectedRegion_'+item.report_id], value, item.report_id)
                                                                                }

                                                                            }}
                                                                            singleSelection={(item.report_id === 'HPC014' || item.report_id === 'HPC017') ? true : false}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>

                                                            {item.report_id !== 'HPC014' && item.report_id !== 'HPC017' && state['selectedClusters_'+item.report_id] && state['selectedClusters_'+item.report_id].length ? 
                                                                state['selectedClusters_'+item.report_id].map((tab, addIndex) => {
                                                                    return(
                                                                        <span key={'tab2_'+addIndex} className={`badge badge-info mt-3 f13 align-self-end p-1 px-2 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                        {tab}
                                                                            <i onClick={ () => removeItem('selectedClusters_'+item.report_id, tab) } className="ml-1 fal fa-times cursorPointer"></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            : null}

                                                            {item.report_id !== 'HPC017' ?
                                                                <React.Fragment>
                                                                <span className='mx-2 align-self-end text-white'>User</span>
                                                                <div className='' style={{minWidth: "200px"}} onClick={(event) => {
                                                                    if(!state["showUser_"+item.report_id]) {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', "showUser_"+item.report_id)
                                                                    }
                                                                }}>
                                                                    <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state['selectedUser_'+item.report_id] ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state['selectedUser_'+item.report_id] ? state['selectedUser_'+item.report_id] : 'Select'}</p>
                                                                    {state["showUser_"+item.report_id] ?
                                                                        <div className='position-relative'>
                                                                            <MultiSelectSection 
                                                                                fields={["userName", "userName"]}
                                                                                // className={'position-unset'}
                                                                                removeTop={true}
                                                                                // borderClass={'boderDottedBottom'}
                                                                                widthClass={'minWidth220'}
                                                                                options={state['users_'+item.report_id]}
                                                                                selectedValues={state['selectedUser_'+item.report_id] ? state['selectedUser_'+item.report_id] : ''}
                                                                                callbackMultiSelect={(value) => {
                                                                                    if(!value || typeof(value) === 'string') {
                                                                                        setState(prevState => ({ ...prevState, ['selectedUser_'+item.report_id]: value }))
                                                                                    } else {
                                                                                        value.preventDefault()
                                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                                    }
                                                                                }}
                                                                                singleSelection={true}
                                                                            />
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                                </React.Fragment>
                                                            : 
                                                                <React.Fragment>
                                                                <span className='mx-2 align-self-end text-white'>Type</span>
                                                                <div className='' style={{minWidth: "150px"}} onClick={(event) => {
                                                                    if(!state.showClusters) {
                                                                        event.preventDefault();
                                                                        handleChildClick(event, 'child', 'singleDropDown', 'showReportType')
                                                                    }
                                                                }}>
                                                                    <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state['selectedType_'+item.report_id] ? 'text-white' : 'placeholder'}
                                                                    `} style={{height: "38px"}}>
                                                                        {state['selectedType_'+item.report_id] ? state['selectedType_'+item.report_id] : 'Select'}
                                                                    </p>
                                                                    {state.showReportType ?
                                                                        <div className='position-relative'>
                                                                            <MultiSelectSection 
                                                                                // fields={["region", "name"]}
                                                                                // className={'position-unset'}
                                                                                removeTop={true}
                                                                                // borderClass={'boderDottedBottom'}
                                                                                widthClass={'minWidth220'}
                                                                                options={state.typeOptions}
                                                                                selectedValues={state['selectedType_'+item.report_id] ? state['selectedType_'+item.report_id] : ''}
                                                                                callbackMultiSelect={(value) => {
                                                                                    if(!value || typeof(value) === 'string') {
                                                                                        setState(prevState => ({ ...prevState, ['selectedType_'+item.report_id]: value}))
                                                                                    } else {
                                                                                        value.preventDefault()
                                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                                    }
                                                                                }}
                                                                                singleSelection={true}
                                                                            />
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                                </React.Fragment>
                                                            }
                                                        </p>
                                                        <div className={`d-flex justify-content-end w-99 mt-2 ${state.queryLoading ? "disabled" : ""}`}>
                                                            <button className={`btn btn-primary f12 align-self-center mr-2 
                                                                ${state.hasError ? "" : ""}`} 
                                                                    onClick={() => {
                                                                        if(state.selectedReport && state.selectedReport.report_id === 'HPC014') {
                                                                            setState(prevState => ({ ...prevState, showDiskReport: true }))
                                                                        } else if(state.selectedReport && state.selectedReport.report_id === 'HPC0171') {
                                                                            setState(prevState => ({ ...prevState, showLicenseUsageReport: true }))
                                                                        } else {
                                                                            setState(prevState => ({ ...prevState, showReportDetails: true }))
                                                                        }
                                                                    }}
                                                                >
                                                                    Run Report
                                                                </button>
                                                            <button className={`btn btn-light f12 align-self-center`}onClick={() => setState(prevState => ({ ...prevState, ['expand_'+i]: false, selectedQuery: "", selectedReport: {} }))}>Close</button>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    )
                                })
                            : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportSample