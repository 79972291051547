import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Label, Input } from "reactstrap";
import ExistingDatabaseSection from "./ExistingDatabaseSection";
import NewDatabaseSection from "./NewDatabaseSection";

const DatabaseSection = (props) => {
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const handleDatabaseOptionChange = (e) => {
    if (window.confirm(
          "If you change the database option, the values already entered in the database fields will be lost. Are you sure you want to proceed ?"
        )
      ) {
        setState((prevState) => ({ ...prevState, databaseOption: e.target.value }));
        let obj = createUpdateCluster ? createUpdateCluster : {};
        obj["database_configuration"] = {};
      }
  };

  const callPageVist = useCallback(() => {
    let databaseConfiguration =
      createUpdateCluster && createUpdateCluster.database_configuration
        ? createUpdateCluster.database_configuration
        : {};
    if (!state.databaseOption) {
      setState((prevState) => ({ ...prevState, databaseOption: Object.keys(databaseConfiguration).includes("password_secret_arn") ? "existing" : "new" }));
    }
  }, [createUpdateCluster, propsClusterFilters]);

  useEffect(() => callPageVist(), [callPageVist]);

  const [state, setState] = useState({});
  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div onClick={(event) => handleChildClick(event, "parent")}>
      <div className="rounded bg-dark p-3 mb-3">
        <p className="text-white f16 mt-3">Database Options</p>
        <div className="d-flex">
          <div className="py-1 px-3 w-20 align-self-center">
            <div className="d-flex">
              <Label className="mb-0 ml-4">
                <Input
                  className="inputdemo mt-1 text-primary"
                  type="radio"
                  name="databaseOption"
                  id="databaseOption"
                  value="existing"
                  checked={state.databaseOption === "existing"}
                  onChange={handleDatabaseOptionChange}
                />
                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                  Use Existing
                </span>
              </Label>
            </div>
          </div>
          <div className="py-1 px-3 w-20 align-self-center">
            <div className="d-flex">
              <Label className="mb-0 ml-4">
                <Input
                  className="inputdemo mt-1 text-primary"
                  type="radio"
                  name="databaseOption"
                  id="databaseOption"
                  value="new"
                  checked={state.databaseOption === "new"}
                  onChange={handleDatabaseOptionChange}
                />
                <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                  Create New
                </span>
              </Label>
            </div>
          </div>
        </div>

        {state.databaseOption === "existing" ?
          <ExistingDatabaseSection pageMode={props.pageMode} />
        :
          <NewDatabaseSection pageMode={props.pageMode} />
        }
      </div>
    </div>
  );
};

export default DatabaseSection;
