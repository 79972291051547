/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 01/06/2023
 * @copyright © 2021 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listAllAccounts, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { setCostPropsDetails, getCostAnomalyDailyTrend, getCostTopAnomalies } from '../../../actions/cost/CostAction'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, addHours, subHours, twoDateDiffrence, subDays, getDayFromSelectedDuration } from '../../../utils/utility'

import Chart from 'react-apexcharts'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import AnomalyDashboard from './AnomalyDashboard'
import { Spinner } from 'reactstrap'
import ApexBarChart from '../../common/charts/ApexBarChart'
import MultiSelectSection from '../../common/MultiSelectSection';

const durationOptions = [    
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
    { value: '+14d', option: 'Last 2 weeks' },
    { value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

let timer = null;

const LandingPage = () => {
    const daterRangeRef = useRef();

    const[state, setState] = useState({
        showLoading: true,

        //filter section start

        selectedProvider: '',

        selectedAccount: [],
        isAccountOpen: false,

        selectedRegion: [],
        isRegionOpen: false,

        selectedDuration: '+3d',

        selectionRange: [{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }],
        //filter section end

        emptyOptions: [],
        callOnSearch: true

    })

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);
    
    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    
    // Use a ref to store the previous selector value
    const prevCostCommonPageFilter = React.useRef();

    // Component lifecycle method
    useEffect(() => {
        // ... (componentDidMount logic)       
        if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callSearch: true }));
                    }
            })
        }
    }, [dispatch, providers])

    useEffect(() => {
        if(state.callOnSearch) {
            prevCostCommonPageFilter.current = ""
            let duration = state.selectedDuration;
            let period = duration.substring(duration.length, duration.length - 1);
            let durationBtw = getDayFromSelectedDuration(duration);
            let sliderStartDate = '';
            if (period === 'd') {
                sliderStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            } else {
                sliderStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss');
            }
            let sliderEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss');
    
            let selectionRange = [{
                startDate: new Date(sliderStartDate),
                endDate: new Date(sliderEndDate),
                key: 'selection',   
            }]
    
            setState(
                prevState => ({
                    ...prevState,
                    sliderStartDate,
                    sliderEndDate,
                    selectionRange,
                    changesInFilter: true,
                    // functionCallType: type,
                    callOnSearch: false,
                    brushStartTime: "",
                    brushEndTime: "",
                    datePickerStartDate: sliderStartDate,
                    datePickerEndDate: sliderEndDate
                })
            );
        }
    }, [state.callOnSearch, state.selectedDuration])
    
    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);
    
    // Call onSearch when changesInFilter is updated
    useEffect(() => {
        if (state.changesInFilter) {
            let sliderStartDate = state.sliderStartDate;
            let sliderEndDate = state.sliderEndDate;
            if (state.selectedDuration === 'custom') {
                sliderStartDate = state.datePickerStartDate && state.datePickerStartDate !== '' ? state.datePickerStartDate : sliderStartDate;
                sliderEndDate = state.datePickerEndDate && state.datePickerEndDate !== '' ? state.datePickerEndDate : sliderEndDate;
            }
            
            setState(prevState => ({
                ...prevState,
                brushStartTime: '',
                brushEndTime: '',
                alertSeries: [], alertOptions: {},
                eventSeries: [], eventOptions: {},
                // anomalySeries: [], anomalyOptions: {},
                errorSeries: [], errorOptions: {},
                changesInFilter: false,
                showLoading: true,
                // resetEnabled: state.functionCallType === 'onLoad' ? false : true,
                sliderStartDate,
                sliderEndDate,
            }))
            let obj = {}
            obj.start_time = state.sliderStartDate
            obj.end_time = state.sliderEndDate
            dispatch(setCostPropsDetails('costCommonPageFilter', obj))

            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
        }
    }, [state.changesInFilter, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedDuration, state.sliderEndDate, state.sliderStartDate]);

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: '', selectedAccount: [], selectedRegion: [], selectedDuration: '+3h', resetFilter: true, changesInFilter: false, datePickerStartDate: '',  datePickerEndDate: ''}));
    }

    useEffect(() => {
        if(state.resetFilter) {
            setState(prevState => ({ ...prevState, resetFilter: false, callOnSearch: true }));
            // durationFunction('onLoad')
        }
    }, [state.resetFilter]);

    // const costCommonPageFilterProps = useCallback((brushStartTime, brushEndTime) => {
        
    // }, [dispatch])

    useEffect(() => {
        if(state.brushMoved && state.brushSelectionValue) {
            clearTimeout(timer)

            let xaxisStartTime = momentDateGivenFormat(state.brushSelectionValue.min, 'YYYY-MM-DD HH:mm:ss');
            let xaxisEndTime = momentDateGivenFormat(state.brushSelectionValue.max, 'YYYY-MM-DD HH:mm:ss');
            prevCostCommonPageFilter.current = state.brushSelectionValue;
    
            if(prevCostCommonPageFilter && prevCostCommonPageFilter !== state.brushSelectionValue) {
                // if(state.brushStartTime !== xaxisStartTime && state.brushEndTime !== xaxisEndTime) {
                timer = setTimeout(() => {
                    setState(prevState => ({ ...prevState, brushStartTime: xaxisStartTime, brushEndTime: xaxisEndTime }));

                    if(state.brushStartTime && state.brushEndTime) {
                        let obj = {}
                        obj.brushStartTime = xaxisStartTime
                        obj.brushEndTime = xaxisEndTime
                        dispatch(setCostPropsDetails('costCommonPageFilter', obj))
                    }
                }, 1000)
            }

            setState(prevState => ({ ...prevState, brushMoved: false }))
        }
    }, [dispatch, state.brushSelectionValue, state.brushMoved])

    // const brushSelection = useCallback((xaxis) => {
    //     clearTimeout(timer)
    //     let xaxisStartTime = momentDateGivenFormat(xaxis.min, 'YYYY-MM-DD HH:mm:ss');
    //     let xaxisEndTime = momentDateGivenFormat(xaxis.max, 'YYYY-MM-DD HH:mm:ss');
    //     prevCostCommonPageFilter.current = xaxis;

    //     if(state.brushStartTime) {
    //         if(state.brushStartTime !== xaxisStartTime && state.brushEndTime !== xaxisEndTime) {
    //             timer = setTimeout(() => costCommonPageFilterProps(xaxisStartTime, xaxisEndTime, true), 1000)
    //         }
    //         // timer = setTimeout(() => setState(prevState => ({ ...prevState, brushStartTime: xaxisStartTime, brushEndTime: xaxisEndTime })), 1500);
    //     } else if(!state.brushStartTime) {
    //         costCommonPageFilterProps(state.sliderStartDate, state.sliderEndDate, false)
    //     }

        
    // }, [state.brushStartTime, state.brushEndTime, state.sliderStartDate, state.sliderEndDate, costCommonPageFilterProps])

    useEffect(() => {
        if(state.sliderStartDate && state.sliderEndDate) {
            let params = {}
            params.start_time = momentConvertionLocalToUtcTime(state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss')
            params.duration_aggregate_by = 'seconds'
            
            dispatch(getCostAnomalyDailyTrend(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, anomalyDailyTrend: response && response.results && response.results.length ? response.results[0] : {}, trendSpinner: false }))
                    }
                })
        }
    }, [dispatch, state.sliderEndDate, state.sliderStartDate])

    useEffect(() => {
        if(state.sliderStartDate && state.sliderEndDate) {
            let brushSeries = [];
            let brushRow = {};
            brushRow.data = [
                { x: momentDateGivenFormat(state.sliderStartDate, 'YYYY-MM-DD HH:mm'), y: 0 },
                { x: momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm'), y: 0 }
            ];
            brushSeries.push(brushRow);
        
            let endTIme = momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss');
            let time = endTIme;
            let brushHours = 1;
            let diff = twoDateDiffrence(state.sliderEndDate, state.sliderStartDate);
        
            if (diff > 1 && diff <= 3) {
                brushHours = 6;
            } else if (diff > 3 && diff <= 7) {
                brushHours = 24; //3
            } else if (diff > 7 && diff <= 14) {
                brushHours = 36; //6
            } else if (diff > 14 && diff <= 30) {
                brushHours = 48; //12
            } else if (diff > 30) {
                brushHours = 96; //24
            }
        
            let brushStartTime = subHours(time, brushHours);
            let brushEndTime = time;
        
            let optionsBrush = {
                chart: {
                    redrawOnParentResize: false,
                    background: '#D9D9D6',
                    id: 'chart1',
                    type: 'line',
                    sparkline: {
                        enabled: true
                    },
                    zoom: {
                        enabled: false
                    },
                    brush: {
                        target: '',
                        enabled: true,
                        autoScaleYaxis: true
                    },
                    selection: {
                        enabled: true,
                        fill: {
                            color: '#009FDF', //cyon
                            opacity: 1
                        },
                        xaxis: {
                            min: new Date(momentDateGivenFormat(brushStartTime, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                            max: new Date(momentDateGivenFormat(brushEndTime, 'YYYY-MM-DD HH:mm:ss')).getTime()
                        }
                    },
                    // events: {
                    //     selection: (chartContext, { xaxis, yaxis }) => {
                    //         brushSelection(xaxis)
                    //     }
                    // },
                    events: {
                        selection: (chartContext, { xaxis, yaxis }) => {
                            // brushSelection(xaxis);
                            setState(prevState => ({ ...prevState, brushSelectionValue: xaxis, brushMoved: true }))
                        },
                    }
                },
                markers: {
                    size: 0
                },
                colors: ['#D9D9D6'],
                fill: {
                    type: 'solid',
                    color: '#000',
                    opacity: 1
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    show: false,
                    tickAmount: 2
                },
                annotations: {
                    yaxis: [{
                        y: 1,
                        borderColor: '#000',
                        label: {
                        borderColor: '#00E396',
                        style: {
                            color: '#fff',
                            background: '#00E396'
                        },
                        text: ''
                        }
                    }]
                }
            };
        
            setState(prevState => ({ ...prevState, brushSeries, optionsBrush }));
    
            // getCostAnomalyDailyTrendFunction()
        }
    }, [state.sliderStartDate, state.sliderEndDate]);

    useEffect(() => {
        let trendGraphData = {}
        // let colors = ['#FF0000']
        let colors = ['#8e3a80']
        let anomalySeries = []
        // let columnWidth = ''
        let anomalyCount = 0
        let graphDetails = state.anomalyDailyTrend
        if(graphDetails && graphDetails.data && graphDetails.data) {
            trendGraphData.data = graphDetails.data
            trendGraphData.labels = graphDetails.dates

            
            anomalyCount = graphDetails.data.reduce((a, b) => a + b, 0)
            
            let dataArray = []
            let graphLabels = graphDetails.dates

            graphDetails.data.forEach((val, index) => {
                let dataRow = {}
                dataRow = {}
                dataRow.x =  momentConvertionUtcToLocalTime(graphLabels[index], 'YYYY-MM-DD HH:mm:ss')
                dataRow.y = val
                dataArray.push(dataRow)
            })

            if(!graphLabels.includes(momentConvertionLocalToUtcTime(state.sliderStartDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0 
                dataArray.push(dataRow) //to initialize the start time in the graph
            }
            
            if(!graphLabels.includes(momentConvertionLocalToUtcTime(state.sliderEndDate, 'YYYY-MM-DD HH:mm:00'))) {
                let dataRow = {}
                dataRow.x = momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
                dataRow.y = 0
                dataArray.push(dataRow) //to initialize the end time in the graph
            }
            let seriesRow = {}
            seriesRow.name = ''
            seriesRow.data = dataArray

            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        } else {
            let dataArray = []
            let dataItems = {}
            dataItems.x = momentDateGivenFormat(state.sliderStartDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(addHours(state.sliderStartDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0 
            dataArray.push(dataItems) //to initialize the start time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(subHours(state.sliderEndDate, 1), 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph

            dataItems = {}
            dataItems.x = momentDateGivenFormat(state.sliderEndDate, 'YYYY-MM-DD HH:mm:00')
            dataItems.y = 0
            dataArray.push(dataItems) //to initialize the end time in the graph
            // trendGraphData.data = [0, 0]
            // trendGraphData.labels = [state.sliderStartDate, state.sliderEndDate]

            let seriesRow = {}
            seriesRow.name = 'No Data'
            seriesRow.data = dataArray
            anomalySeries = [seriesRow]

            // if(dataArray.length <= 10) {
                // columnWidth = '1%'
            // }
        }

        let barOptions = {                
            // rangeBarOverlap: false,
            // rangeBarGroupRows: false,
            columnWidth: '100%'
        }

        // if(columnWidth !== '') {
        //     barOptions.columnWidth = '0%'
        // }
        
        let anomalyOptions = {
            chart: {
                id:'',
                // redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                sparkline: {
                    enabled: false
                },
                animations: {
                    enabled: false,
                },
                type: 'bar',
                height: 150,
                stacked: false,
            },
            markers: {
                size: 0
            },
            colors: colors,
            plotOptions: {
                bar: {
                    columnWidth: '100%',   
                }
                // bar: {
                //     horizontal: false,
                //     columnWidth: columnWidth,
                //     distributed: false,
                //     rangeBarOverlap: false,
                //     rangeBarGroupRows: false,
                // },       
            },
            dataLabels: {
                enabled: false
            },                
            grid: {
                show: false,
            },
            xaxis: {
                show: true,
                tooltip: {
                    enabled: false
                },
                axisTicks: {
                    show: false
                },
                type: 'datetime',
                axisBorder: {
                    show: true,
                    color: '#434B5E',
                },
                labels: {
                    show: true,
                    datetimeUTC: false,
                    style: {
                        colors: '#B8BBBE',
                        fontSize: '10px',
                        fontWeight: 'bolder',
                    },
                    offsetX: 0,
                    offsetY: -5,
                },
            },
            yaxis: {
                show: false,
            },
            legend: {
                show: false,
            },
            tooltip: {
                enabled: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex];
                    // let labelName = w.globals.initialSeries[seriesIndex].name
                    return '<div class="arrow_box">' +
                        '<span> '+ momentDateGivenFormat(w.globals.initialSeries[0].data[dataPointIndex].x, "DD MMM YYYY HH:mm") + 
                        ' <span style="color:'+ w.globals.colors[seriesIndex] +'"> Anomalies </span> ' + val + ' </span></div>'
                },
                style: {
                    fontSize: '9px',
                },
            },
        }

        setState(prevState => ({ ...prevState, anomalySeries, anomalyOptions, anomalyCount, trendGraphData }))

    }, [state.anomalyDailyTrend, state.sliderEndDate, state.sliderStartDate])
    
    useEffect(() => {
        if(state.sliderStartDate && state.sliderEndDate) {
            let params = {
                'start_time': momentConvertionLocalToUtcTime(state.sliderStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.sliderEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'top': 5,            
                'aggregate_by': [['asset_name', 'anomaly_name']],
            }

            // let response = [
            //     {
            //         'data': {
            //             'asset_name': {},
            //             'anomaly_name': {
            //                 '573921696245:us-east-2:S3:GlacierStorage': 38,
            //                 '573921696245:us-east-2:SystemsManager:AdvancedParameterStore': 36,
            //                 '573921696245:us-east-2:KMS:GenerateDataKey': 35,
            //                 '573921696245:us-east-1': 27,
            //                 '573921696245:us-east-1:EC2': 26
            //             }
            //         }
            //     }
            // ]

            // setState(prevState => ({ ...prevState, topCostAnomalies: response.length && response[0].data ? response[0].data : {}  }))

            dispatch(getCostTopAnomalies(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, topCostAnomalies: response.length && response[0].data ? response[0].data : {}  }))
                    }
                })
        }
    }, [dispatch, state.sliderEndDate, state.sliderStartDate])

    const handleMultiSelectChange = (field, arrayValue) => {
        let value = arrayValue.map(item => item.value);
        let selectedValue = [];
        if (field === 'selectedAccount') {
            let prevState = state[field];
            if (value.includes('All')) {
                if (!prevState.includes('All')) {
                    state.accounts.forEach(acc => {
                        selectedValue.push(acc.account_id);
                    });
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value;
                }
            } else if (!prevState.includes('All')) {
                selectedValue = value;
            }
        
            if (selectedValue.length && !selectedValue.includes('All')) {
                if (!selectedValue.includes('All') && selectedValue.length === (state.accounts.length - 1)) {
                selectedValue.push('All');
                }
            }
            setState(prevState => ({ ...prevState, [field]: selectedValue, changesInFilter: true }));
        } else if (field === 'selectedRegion') {
            let prevState = state[field];
            if (value.includes('All')) {
                if (!prevState.includes('All')) {
                    state.regions.forEach(reg => {
                        selectedValue.push(reg.region);
                    });
                } else {
                    const index = value.indexOf('All');
                    if (index > -1) {
                        value.splice(index, 1);
                    }
                    selectedValue = value;
                }
            } else if (!prevState.includes('All')) {
                selectedValue = value;
            }
        
            if (selectedValue.length && !selectedValue.includes('All')) {
                if (!selectedValue.includes('All') && selectedValue.length === (state.regions.length - 1)) {
                selectedValue.push('All');
                }
            }

            setState(prevState => ({ ...prevState, [field]: selectedValue }));
        }
    };

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                showDateRangePicker: false, 
                selectedGraphDuration: "custom",
                changesInFilter: true,
            }));    
        }
        setDateState([date.selection])
    };

    useEffect(() => {
        if(state.topCostAnomalies && state.topCostAnomalies.asset_name) {
            let list = state.topCostAnomalies.asset_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels
            setState(prevState => ({ ...prevState, topAssets: topStatusData }))
        }
        
        if(state.topCostAnomalies && state.topCostAnomalies.anomaly_name) {
            let list = state.topCostAnomalies.anomaly_name
            let labels = Object.keys(list).sort(function(a,b){return list[b] - list[a]})

            let topStatusData = {}
            let data = []
            labels.forEach(item => {
                data.push(list[item])
            })
            
            for(let i=labels.length; i<5; i++) {
                data.push(0)
                labels.push('empty')
            }

            topStatusData.data = data
            topStatusData.labels = labels

            setState(prevState => ({ ...prevState, topAnomalies: topStatusData }))
        }        
    }, [state.topCostAnomalies])

    const handleChildClick = (event, type, dropdownType, section) => {	
		if (daterRangeRef.current && !daterRangeRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();

		// console.log("type", type)
		// console.log("section", section)
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-5 col-3 py-2'>
                        <h6 className='text-white m-0'>Anomalies</h6>
                        <p className='text-white m-0'>Unified view of cost Anomlies in your Cloud</p>
                    </div>
                    
                    <div className='col-lg-7 col-7 py-2'>
                        <div className='d-flex mx-0 topBasicFilter justify-content-end'>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}}
                                onClick={(event) => {
                                    if(!state.isProviderOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                    }
                                }}
                            >
                                <label className='mb-1 mt-1 f10 d-flex'>Provider</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedProvider ? state.selectedProvider : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isProviderOpen ?
                                    <MultiSelectSection 
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, selectedProvider: value, changesInFilter: true, selectedAccount: [] }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                    />
                                : null}
                            </div>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                                }}
                            >
                                <label className='mb-2 mt-1 f10 d-flex'>Account</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isAccountOpen ?
                                    <MultiSelectSection 
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value }))
                                        }}
                                    />
                                : null}
                            </div>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '220px', maxWidth: '220px'}}
                                onClick={(event) => {
                                    if(!state.isDurationOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isDurationOpen")
                                    }
                                }}
                            >
                                <label className='mb-1 mt-1 f10 d-flex'>Duration</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedDuration ? 'text-white' : 'placeholder'}`}>
                                    <span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isDurationOpen ?
                                    <MultiSelectSection 
                                        fields={["value", "option"]}
                                        options={durationOptions}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                if(value !== 'custom') {
                                                    setState(prevState => ({ ...prevState, selectedDuration: value, showDateRangePicker: false, getDurationDetails: true }))
                                                } else {
                                                    setState(prevState => ({ ...prevState, showDateRangePicker: true, selectedDuration: value }))
                                                }
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                    />
                                : null}
                            </div>
                            
                            {state.showDateRangePicker ?                                                
                                <div className='headerDateRange mt-2' style={{'z-index': '999999999','position': 'absolute','right': '20px','margin-top': '90px', 'top': '75px'}} ref={daterRangeRef}>
                                    <DateRange
                                        editableDateInputs={true}
                                        onChange={handleSelect}
                                        moveRangeOnFirstSelection={false}
                                        ranges={dateState}
                                    />
                                </div>
                            : null}
                            <div className={`ml-3 align-self-center`}>
                                <div className='btn-group' role='group' aria-label='Basic example'>
                                    <button type='button' className={`cursorPointer  btn bg-cyon text-white border-right`} onClick={() => setState(prevState => ({ ...prevState, showLoading: false, callOnSearch: true }))}>Search</button>
                                    <button type='button' className={`cursorPointer btn bg-cyon text-white border-left`} onClick={onReset}><i className='far fa-redo'/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mx-0 mt-2'>
                    <div className='col-lg-12 py-2'>
                        <div className='rounded bg-black p-3'>
                            <p className='mb-1'>Showing data for 
                                <span className='text-cyon ml-1'>
                                    {state.brushTime ?
                                        state.brushStartTime ? 
                                            momentDateGivenFormat(state.brushStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.brushEndTime, 'DD MMM YYYY HH:mm') 
                                        : ''
                                    : 
                                        state.sliderStartDate ? 
                                            momentDateGivenFormat(state.sliderStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.sliderEndDate, 'DD MMM YYYY HH:mm') 
                                        : ''
                                    }
                                </span>
                                <span className='ml-2'>(local time)</span>
                            </p>
                            
                            <div className={`d-flex ${!state.minimizeGraphSection && state.anomalySeries && state.anomalySeries.length ? 'mt-n4' : 'mt-4'}`}>
                                {/* <div className='w-5 d-flex justify-content-end mt-n2'>
                                    <p className='mb-0 align-self-center pr-3'>Anomalies</p>
                                </div> */} 
                                {state.trendSpinner ?
                                    <div className='w-100 d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='light' />
                                    </div>    
                                : 
                                    <React.Fragment>
                                    <div className='d-flex w-80'>
                                        <div className='w-85 transparentTooltip mt-n2'>
                                            {state.trendGraphData && Object.entries(state.trendGraphData).length ?
                                                <ApexBarChart
                                                    graphData={state.trendGraphData}
                                                    sparkline={false}
                                                    yaxis={false}
                                                    yaxisLabel={false}
                                                    xaxis={true}
                                                    xaxisFormat={'dateWithtime'}
                                                    xaxisLabel={true}
                                                    axisLabelColor={'#666666'}
                                                    paddingLeft={0}
                                                    legend={false}
                                                    legendPostion={'bottom'}
                                                    legendHorizontalAlign={'center'}
                                                    legendUseSeriesColor={false}
                                                    stacked={false}
                                                    height={150}
                                                    horizontal={false}
                                                    barHeight={'40%'}
                                                    columnWidth={'25%'}
                                                    gradient={false}
                                                    gradientColor={['#009fdf', '#CD5A9F']}
                                                    hideTooltipValue={true}
                                                    backgroundBarColors={['#333947']}
                                                    className={'transparentTooltip'}
                                                    dataLabels= {false}
                                                    colors={['#8e3a80']}
                                                />
                                            : null}
                                            {/* {state.anomalySeries && state.anomalySeries.length ?
                                                <Chart options={state.anomalyOptions} series={state.anomalySeries} type='bar' height={140} />
                                            : null} */}
                                        </div>
                                        <div className='pl-2 w-15 align-self-center justify-content-center'>
                                            {/* <small className='mb-0 text-purple-2'>Total: </small> */}
                                            <p className='mb-0 text-white f14 mb-1'>{state.anomalyCount && state.anomalyCount}</p>
                                            <p className='mb-0 text-purple-2 mb-0'>Anomalies </p>
                                        </div>
                                    </div>
                                    <div className='w-20'>
                                        <div className={`card p-3 rounded bg-darkGray border-0 mb-2`}>
                                            <div className='row mb-3'>
                                                <div className='col-lg-12'>
                                                    <label className='text-white'>Top 5 Anomalies</label>
                                                    {state.topAnomalies && Object.entries(state.topAnomalies).length ?
                                                        <div className='mt-n2 mb-n4'> 
                                                            <ApexBarChart
                                                                graphData={state.topAnomalies}
                                                                sparkline={true}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={false}
                                                                xaxisFormat={'string'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#50586A'}
                                                                paddingLeft={-10}
                                                                legend={false}
                                                                stacked={false}
                                                                height={80}
                                                                horizontal={true}
                                                                barHeight={'40%'}
                                                                barEndShape={'flat'}
                                                                columnWidth={'25%'}
                                                                gradient={true}
                                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                                hideTooltipValue={true}
                                                                backgroundBarShape={'flat'}
                                                                backgroundBarColors={['#333947']}
                                                                showBackgroundBarColors={false}
                                                                className={'transparentTooltip'}
                                                            />
                                                        </div>
                                                    : null}
                                                    {/* </div> */}
                                                </div>
                                                {/* <div className='col-lg-6 pl-0 displayNone'>
                                                    <label className='text-white'>Top 5 Assets</label>
                                                    {state.topAssets && Object.entries(state.topAssets).length ?
                                                        <div className='mt-n2 mb-n4'> 
                                                            <ApexBarChart
                                                                graphData={state.topAssets}
                                                                sparkline={true}
                                                                yaxis={false}
                                                                yaxisLabel={false}
                                                                xaxis={false}
                                                                xaxisFormat={'string'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#50586A'}
                                                                paddingLeft={-10}
                                                                legend={false}
                                                                stacked={false}
                                                                height={80}
                                                                horizontal={true}
                                                                barHeight={'40%'}
                                                                barEndShape={'rounded'}
                                                                columnWidth={'25%'}
                                                                gradient={true}
                                                                gradientColor={['#039BE5', '#5F5BA2']}
                                                                hideTooltipValue={true}
                                                                backgroundBarShape={'rounded'}
                                                                backgroundBarColors={['#333947']}
                                                                showBackgroundBarColors={false}
                                                                className={'transparentTooltip'}
                                                            />
                                                        </div>
                                                    : null}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    </React.Fragment>
                                }
                            </div>
                            
                            <div className='d-flex w-100 mt-n2'>
                                {state.brushSeries && state.brushSeries.length && state.optionsBrush && Object.entries(state.optionsBrush).length ?
                                    <React.Fragment>
                                    <div className='w-5 d-flex justify-content-end pr-2'>
                                        
                                    </div>
                                    <div className='removeSvgPoint w-80'>
                                        <Chart options={state.optionsBrush} series={state.brushSeries} type='line' height={8} />
                                        <div className='d-flex justify-content-between mt-3 text-info'>
                                            <small className='mb-0 align-self-center f10'>{momentDateGivenFormat(state.sliderStartDate, 'DD MMM HH:mm')}</small>
                                            <small className='mb-0 align-self-center f10'>{momentDateGivenFormat(state.sliderEndDate, 'DD MMM HH:mm')}</small>
                                        </div>
                                    </div>
                                    <div className='pl-2 w-15  text-info'>
                                        <small className='mb-0 align-self-center f10'>{momentDateGivenFormat(state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(state.brushEndTime, 'DD MMM HH:mm')}</small>
                                    </div>
                                    </React.Fragment>
                                : null}
                            </div>
                        </div>
                        <AnomalyDashboard 
                            loading={() => setState(prevState => ({ ...prevState, showLoading: false }))}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage