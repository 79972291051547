import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useLocation } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash'
import { listWorkstationResults, applyWorkstationActions, getCatalogFilters, disableLifecyclePolicy } from '../../actions/CatalogAction'
import { convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
import windows from '../../assets/images/windows-pngwing.png'
import linux from '../../assets/images/linux-pngwing.png'
import aws from '../../assets/images/aws-black-pngwing.png'
import gcp from '../../assets/images/gcp-pngwing.png'
// import ubuntu from '../../assets/images/ubuntu-pngwing.png'
// import * as ServiceController  from "windows-service-controller";

import ApexListBarChart from '../common/charts/listCharts/ApexListBarChart'
import ApexListLineChart from '../common/charts/listCharts/ApexListLineChart'

// import Search from '../common/SearchWithHiddenInput'
import WorkstationSidePanel from './WorkstationSidePanel'
import { Store as CommonNotification } from 'react-notifications-component';

// import ConfirmationScreen from '../common/ConfirmationScreen';

let searchWorstation = null

const Workstation = (props) => {

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        emptyOptions: [],
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 5,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const receivedData = location.state;
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    // const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.workstation_action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].workstation_action
                    }
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])


    // useEffect(() => {
    //     setState(prevState => ({ ...prevState, callListWorkstationResults : true }))
    // }, [])

    const handleCatalogTypeChange = (event) => {
        setState(prevState => ({ ...prevState, showLoading: true, listCatalogs: [], filteredCatalogs: [], catalogType: event.target.value, callListWorkstationResults: true }))
    }

    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }        
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []                    
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationResults: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.resetFilter) {
            setState(prevState => ({ ...prevState, resetFilter: false }));
        }
    }, [state.resetFilter]);

    useEffect(() => {
        if(state.callListWorkstationResults) {
            setState(prevState => ({ ...prevState, callListWorkstationResults: false }))
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }
            
            if(props.subscribedRow && props.subscribedRow.workstation_id) {
                params.workstation_id = props.subscribedRow && props.subscribedRow.workstation_id
            }

            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            if(state.searchText) {
                params.search_input = state.searchText
            } else {
                params.size = state.perPage
            }

            let currentToken = []
            if(state.token && state.token.length) {
                params.next_token = state.token
                currentToken = state.token
            } else if(state.previous_token && state.previous_token.length) {
                params.previous_token = state.previous_token
                currentToken = state.previous_token
            }
    
            dispatch(listWorkstationResults(params))
                .then((response) => {
					if(response) {
                        
                        let totalResults = response.results 

                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])

                        setState(prevState => ({ ...prevState, workstationList: totalResults, filterdWorkStationList: totalResults, totalRecords: response.total ? response.total : 0, selectedFilters: params, detailsFormat, showLoading: false, callFormGraph: true, nextCatalogToken: response.next_token, previousToken: response.previous_token, currentToken }));
                    }
                })
        }
    }, [state.callListWorkstationResults, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, receivedData, state.workstationList, state.nextCatalogToken, state.token, props, state.catalogType, state.perPage, state.previous_token, state.searchText])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        if(action === 'next') {
            setState(prevState => ({ ...prevState, showLoading: true, currentPage: currentPage+1, token: state.nextCatalogToken, previous_token: [], callListWorkstationResults: true }))
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, showLoading: true, currentPage: currentPage-1, token: [], previous_token: state.previousToken, callListWorkstationResults: true  }));
        } 
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const getGrapUnitValue = useCallback((val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                if(valueType === "round") {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
                } else {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                }
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit, valueType)
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {          
            if(valueType === "round") { 
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
            } else {
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
            }
        }
        return val
    }, [])

    useEffect(() => {
        if(state.callFormGraph) {
            let graphDetails = state.filterdWorkStationList ? state.filterdWorkStationList : []
            if(graphDetails && graphDetails.length) {
                graphDetails.forEach(row => {
                    if(row.metrics && Object.entries(row.metrics).length) {
                        let graph = row.metrics
                        let graphData = graph.data
                        let graphLabels = graph.dates                

                        let cpuRow = {}
                        let cpuSum = 0

                        let memoryRow = {}
                        let memorySum = 0
                        
                        let diskRow = {}
                        let diskSum = 0

                        let networkRow = []
                        let networkAvg = []

                        Object.entries(graphData).forEach(([key, item]) => {                    
                            let avgData = {}
                            if(item.statistics) {
                                if(item.statistics.avg) {
                                    if(item.unit) {
                                        // avgData.avg = item.statistics.avg 
                                        avgData.avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                    } else {
                                        avgData.avg = item.statistics.avg
                                    }
                                }
                                if(item.statistics.max) {
                                    if(item.unit) {
                                        avgData.max = getGrapUnitValue(item.statistics.max, item.unit, "round")
                                    } else {
                                        avgData.max = item.statistics.max
                                    }
                                }
                            }
                            if(item.category === "CPU") {
                                let data = []                        
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        cpuSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                cpuRow.data = data
                                cpuRow.labels = graphLabels
                                
                                setState(prevState => ({ ...prevState, ['statistics_cpu_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Memory") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        memorySum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                memoryRow.data = data
                                memoryRow.labels = graphLabels
                                setState(prevState => ({ ...prevState, ['statistics_memory_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Disk") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        diskSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                diskRow.data = data
                                diskRow.labels = graphLabels

                                setState(prevState => ({ ...prevState, ['statistics_disk_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Network") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                let newData = networkRow && networkRow.data ? networkRow.data : {}
                                newData[key] = data
                                
                                networkRow.data = newData
                                networkRow.labels = graphLabels
        
                                if(item.statistics) {
                                    if(item.statistics.avg) {
                                        let avg = 0
                                        if(item.unit) {
                                            avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                        } else {
                                            avg = item.statistics.avg
                                        }
                                        networkAvg.push(avg)
                                    }
                                }
        
                                setState(prevState => ({ ...prevState, ['statistics_network_'+row.workstation_id]: networkAvg }))
                            }
                        })
                        if(cpuSum > 0 && cpuRow && Object.entries(cpuRow).length) {
                            setState(prevState =>  ({ ...prevState, ['cpu_'+row.workstation_id]: cpuRow }))
                        }
                        if(memorySum > 0 && memoryRow && Object.entries(memoryRow).length) {
                            setState(prevState =>  ({ ...prevState, ['memory_'+row.workstation_id]: memoryRow }))
                        } 
                        if(diskSum > 0 && diskRow && Object.entries(diskRow).length) {
                            setState(prevState =>  ({ ...prevState,['disk_'+row.workstation_id]: diskRow }))
                        }
                        if(networkRow && Object.entries(networkRow).length) {
                            setState(prevState =>  ({ ...prevState,['network_'+row.workstation_id]: networkRow }))
                        }
                    }
                })
            }

        }
    }, [state.callFormGraph, state.filterdWorkStationList, getGrapUnitValue])

    const onChangeAction = (row, option) => {
        if(option === 'terminate') {
            setState(prevState => ({ ...prevState, terminateModal: true, selectedTerminateDetails: row }))
        } else if(option === "details") {
            setState(prevState => ({ ...prevState, selectedDetails: row }))
        } else {
            setState(prevState => ({ ...prevState, selectedDetails: row, selectedAction: option }))
        }
    }

    // const onChangeActionNew = () => {
    //     setState(prevState => ({ ...prevState, selectedDetails: row, showConfirmationScreen: true }))
    // }

    // useEffect(() => {
    //     setState(prevState => ({ ...prevState, selectedDetails: row }))
    // }, [state.updateSelectedAction])

    const deleteWorkstationFunction = () => {
        let params = {}
        params.workstation_id = state.deleteWorksatationDetails && state.deleteWorksatationDetails.workstation_id
        params.action = 'unsubscribe'
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
                    setState(prevState => ({ ...prevState,  showDeleteLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, deleteModal: false, showDeleteLoading: false, token: state.currentToken, callListWorkstationResults: true }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
    }

    const terminateFunction = () => {
        let params = {}
        params.workstation_id = state.selectedTerminateDetails && state.selectedTerminateDetails.workstation_id
        params.action = 'terminate'
        if(params.workstation_id) {
            dispatch(applyWorkstationActions(params)).then((response) => {
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
                    setState(prevState => ({ ...prevState, showTerminateLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, terminateModal: false, showTerminateLoading: false, callListWorkstationResults: true }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
    }

    const callDisableFuction = (row, disabled_policy) => {

		let params = {
			disable: disabled_policy ? true : false,			
			workstation_id: row && row.workstation_id
		}
		
		dispatch(disableLifecyclePolicy(params))
			.then((response) => {
				let messageType = ""
				if(response.status) {
					messageType = 'success'
					// setState(prevState => ({ ...prevState, callListLifecyclePolicies: true }))
				} else {
					messageType = 'danger'
				}
				setState(prevState => ({ ...prevState, submitLoading: false }))
	
				let message = response && response.message ? response.message : 'Error in disabling policy '
	
				CommonNotification.addNotification({
					message: message,
					type: messageType,
					insert: "top",
					container: "top-center",
					dismiss: {
						duration: 5000,
						onScreen: false,
						pauseOnHover: true,
						showIcon: true,
					}
				});
			})
	}

    return (
        state.showLoading ?
            <div className='d-flex justify-content-center m-4'>
                <Spinner className='text-center' color='light' />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={(string) => setState(prevState => ({...prevState, selectedDetails: {}, selectedAction: "", callListWorkstationResults: string === 'refresh' ? true : false, showLoading: false }))}
                    selectedDetails={state.selectedDetails} 
                    selectedAction={state.selectedAction}
                    detailsFormat={state.detailsFormat}
                />
            : null}

            {/* {state.showConfirmationScreen ? 
                <ConfirmationScreen 
                    title={"Unsubscribe"}
                    content={"Do you want to unsubcribe"}
                    input={false}
                    onConfirmation={(data) => onUnsubscribe("")}
                />
            : null} */}
            <div className='p-2 flex-grow-1'>
                <div className='d-flex justify-content-between mb-3'>
                    <p className='mb-0 align-self-center'>
                        <small>Showing {state.filterdWorkStationList && state.filterdWorkStationList.length} out of total {state.totalRecords} workstation(s)</small>
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{state.selectedFilters.provider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <small className='ml-1'>with the account</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.account_id[0]}</small>
                            {state.selectedFilters.account_id.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <small className='mx-1'>in the</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.region[0]}</small>
                            {state.selectedFilters.region.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            <small className='ml-1'>region</small>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='d-flex mr-2 justify-content-end w-50'>
                        <div className={`transparentSelectBorderBottom align-self-end mr-2`}>
                            <select className="form-control" onChange={handleCatalogTypeChange} value={state.catalogType}>
                                {/* <option key="All" value="All">ALL</option> */}
                                {state.catalogTypes && state.catalogTypes.map((cat) => {
                                    return(
                                        <option key={cat.value} value={cat.value}>{cat.label}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {/* {state.workstationList && state.workstationList.length ? */}
                            <div className='w-50'>
                                <div className='d-flex justify-content-end w-100'>
                                    {state.totalRecords > state.perPage ?
                                        <div className='pagination errorPagePagination f12 mr-2'>
                                            <span className='mx-3'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                                            {/* <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button>  */}
                                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                            <span className='displayNone'>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ''
                                                        this.gotoPage(page)
                                                    }}
                                                    className='ml-1 inputClass'
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                            {/* <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === Math.ceil(state.totalRecords / state.perPage) ? 'text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button> */}
                                        </div>
                                    : null}
                                </div>
                                {/* <Search
                                    data={state.workstationList ? state.workstationList : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-darkGray text-white f12 border-mediumDarkGray'}
                                    searchClassName={'px-2 f12'}
                                    searchIconColor={'text-lightGray f12'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent pl-2 form-control-sm f12 w-92 text-white'}
                                    filteredData={(filterdWorkStationList) => {
                                        let totalPages = 1                
                                        if(filterdWorkStationList.length > state.perPage) {
                                            totalPages = Math.ceil(filterdWorkStationList.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filterdWorkStationList, totalPages }))
                                    }}
                                /> */}
                                <div className='d-flex justify-content-end w-100'>
                                    <div className='d-flex w-100 border-lightGray rounded-5'>
                                        <div className={`input-group-prepend w-10`}>
                                            <div className={`input-group-text bg-transparent`}>
                                                <i className={`fal fa-search cursorPointer text-lightGray f12`} />
                                            </div>
                                        </div>
                                        <div className={`input-enclose w-90`}>
                                            <input
                                                value={state.searchText}
                                                onChange={e => {
                                                    setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                                    clearTimeout(searchWorstation)
                                                    if(e.target.value.length > 2) {
                                                        searchWorstation = setTimeout(() => { setState(prevState => ({ ...prevState, callListWorkstationResults: true, showLoading: true }))}, 1000); 
                                                    } else if(!e.target.value.length) {
                                                        setState(prevState => ({ ...prevState, callListWorkstationResults: true, showLoading: true }))
                                                    }
                                                }}
                                                type='text'
                                                className={`form-control bg-transparent pl-2 form-control-sm f12 text-white border-lightGray-left border-0`}
                                                placeholder='Search'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        {/* : null} */}
                    </div>
                </div>
                <div className='container-fluid'>
                    {state.filterdWorkStationList && state.filterdWorkStationList.length ?
                        state.filterdWorkStationList.slice(state.startRecord, state.startRecord + state.perPage).map((row, index) => {
                            return (
                                <div className={`row mb-2 bg-backGroundGray`} key={"row_"+(state.startRecord + index)} style={{minHeight: "228px"}}> 
                                    <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer align-self-center">
                                        <div className="px-3 py-2">
                                            <div className="d-flex justify-content-between mb-1">
                                                <div style={{minWidth: "30px", maxWidth: "30px"}} className='align-self-center'>
                                                    {row.platform === "windows" ?
                                                        <img src={windows} className="img-fluid mb-3" alt="WINDOWS" />
                                                    :
                                                        <img src={linux} className="img-fluid mb-3" alt="LINUX"/>
                                                    }
                                                    {row.provider === "aws" || row.provider === "AWS" ?
                                                        <img src={aws} className="img-fluid mb-3" alt="AWS" />
                                                    :
                                                        <img src={gcp} className="img-fluid mb-3" alt="GCP" />
                                                    }
                                                    {/* <img src={ubuntu} className="img-fluid" alt="UBUNTU" /> */}
                                                </div>
                                                <div className='ml-3 align-self-center'>
                                                    <p className='mb-2'>
                                                        Name <span className='text-black ml-2'>{row.image_name}</span>
                                                    </p>
                                                    <p className='mb-2'>
                                                        Os <span className='text-black ml-2'>{row.platform}</span>
                                                    </p>
                                                    <p className="mb-2">
                                                        <span className="mr-1">Resources </span>
                                                        <span className='text-black'>
                                                            {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                            {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                            {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                                        </span>
                                                    </p>
                                                    <p className='mb-2 hiddenEllipsesChar'>
                                                        Description <span className='text-black ml-2'>{row.description}</span>
                                                    </p>
                                                    {/* <p className='mb-0 detailsLink text-cyon align-self-center'>Details</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-9 col-md-9 col-sm-9 bg-darkGray p-0'>
                                        <div className='d-flex'>
                                            <div className='col-lg-10 col-md-10 col-sm-10 align-self-center'>
                                                <div className='d-flex'>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 pr-0 cursorPointer align-self-center">
                                                        
                                                        <p className='mb-1 d-flex'>
                                                            <span className='minWidth60'>Name</span>
                                                            <span className='text-white ml-2'>{row.workstation_name} </span>
                                                        </p>
                                                        <p className='mb-1 d-flex'> 
                                                            <span className='minWidth60'>Status</span> 
                                                            <span className={`badge badge-outline-${row.status === "failed" ? "red" : row.status === "stopped" ? "yellow" : row.status === "hibernated" ? "yellow" : "info"} text-${row.status === "failed" ? "red" : row.status === "stopped" ? "yellow" : row.status === "hibernated" ? "yellow" : "info"}  ml-2`}>{row.status}</span>
                                                            {row.status_datetime ? 
                                                                <span className='ml-2 text-white'>({row.status_datetime})</span> 
                                                            : null}
                                                        </p>
                                                        
                                                        <p className='mb-1 d-flex'>
                                                            <span className='minWidth60'>Capacity</span>
                                                            <span className='text-white ml-2'>{row.instance_type} </span>
                                                            <span className='text-white ml-2'>(
                                                                {row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                                {row.vcpus} vCPU,
                                                                <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>
                                                            )</span>
                                                        </p>
                                                        {row.id_name_map ?
                                                            <p className='mb-1 d-flex' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>
                                                                <span className='minWidth60'>Asset</span>
                                                                <span className='ml-2 text-white'>{(row.id_name_map.value ? row.id_name_map.value : "")}
                                                                {row.id_name_map.key ?
                                                                    (<span className={`ml-1 text-info mb-0`}>
                                                                    {row.id_name_map.key }</span>)
                                                                : null}
                                                                </span>
                                                            </p>
                                                        : row.asset_name ?
                                                            <p className='mb-1 d-flex' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}> 
                                                                <span className='minWidth60'>Asset</span>  
                                                                <span className='text-white ml-2'>{row.asset_name} </span>
                                                            </p>
                                                        : null}
                                                        {row.public_ip_address ?
                                                            <p className='mb-1 d-flex'>
                                                                <span className='minWidth60'>Public IP</span>
                                                                <span className='text-white ml-2'>{row.public_ip_address} </span>
                                                            </p>
                                                        : row.private_ip_address ?
                                                            <p className='mb-1 d-flex'>
                                                                <span className='minWidth60'>Private IP</span>
                                                                <span className='text-white ml-2'>{row.private_ip_address} </span>
                                                            </p>
                                                        : null}
                                                        {row.volume_details && row.volume_details.length ?
                                                            <p className='mb-1 d-flex'>
                                                                <span className='minWidth60'>Volume</span>
                                                                <span className='text-white ml-2'>
                                                                    {row.volume_details[0].volume_id ? row.volume_details[0].volume_id : ''} 
                                                                </span>
                                                                {row.volume_details[0].volume_size ?
                                                                    <span className='text-white ml-2'>({row.volume_details[0].volume_size} GB)</span>
                                                                : null}
                                                            </p>
                                                        : null}
                                                        {row.user_name ?
                                                            Array.isArray(row.user_name) && row.user_name.length ?
                                                                <p className='mb-1 d-flex'>
                                                                    <span className='minWidth60'>User</span>
                                                                    <span className='text-white ml-2'>{row.user_name[0]} </span>
                                                                    {row.user_name.length > 1 ?
                                                                        <small className='align-self-center ml-2 text-info' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>more</small>
                                                                    : null}
                                                                </p>
                                                            :
                                                                <p className='mb-1 d-flex'>
                                                                    <span className='minWidth60'>User</span>
                                                                    <span className='text-white ml-2'>{row.user_name} </span>
                                                                </p>
                                                        : null}
                                                    </div>
                                                    <div className="col-lg-5 col-md-5 col-sm-5 cursorPointer align-self-center">
                                                        <div className='row border-darkGray-bottom'>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 pr-0 pt-1">
                                                                <p className='mb-1'>CPU</p>
                                                                {state["cpu_"+row.workstation_id] ? 
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div className="w-75">
                                                                            <ApexListBarChart
                                                                                graphData={state["cpu_"+row.workstation_id]}
                                                                                name={'Line'}
                                                                                xaxis={false}
                                                                                xaxisFormat={'datetime'}
                                                                                yaxis={false}
                                                                                yaxisLabel={false}
                                                                                markerSize={0}
                                                                                hideMarkerLength={30}
                                                                                gradient={false}
                                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                                colors={['#039BE5']}
                                                                                sparkline={true}
                                                                                zoom={false}
                                                                                height={34}
                                                                                width={'100%'}
                                                                                grid={false}
                                                                                className={"transparentTooltip mb-n2"}
                                                                                tooltip={true}
                                                                                stacked={false}
                                                                                animations={false}
                                                                            />
                                                                        </div>
                                                                        {state["statistics_cpu_"+row.workstation_id] && Object.entries(state["statistics_cpu_"+row.workstation_id]).length ?
                                                                            <div className="w-25 pl-1 pt-1 mb-0 align-self-center f11">
                                                                                <p className="d-flex mb-2 text-primary-color f8">Avg <span className="pl-1 text-primary-color">{state["statistics_cpu_"+row.workstation_id].avg && state["statistics_cpu_"+row.workstation_id].avg ? state["statistics_cpu_"+row.workstation_id].avg : 0}</span></p>
                                                                                <p className="d-flex mb-0 text-primary-color f8">Max <span className="pl-1 text-primary-color">{state["statistics_cpu_"+row.workstation_id].max && state["statistics_cpu_"+row.workstation_id].max}</span></p>
                                                                            </div>
                                                                        : null}
                                                                    </div>
                                                                :
                                                                    <p className='mb-0 mt-2 text-white'>NA</p>
                                                                }
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 pr-0 pt-1">
                                                                <p className='mb-1'>Memory</p>
                                                                {state["memory_"+row.workstation_id] ? 
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="w-75">
                                                                            <ApexListBarChart
                                                                                graphData={state["memory_"+row.workstation_id]}
                                                                                name={'Line'}
                                                                                xaxis={false}
                                                                                xaxisFormat={'datetime'}
                                                                                yaxis={false}
                                                                                yaxisLabel={false}
                                                                                markerSize={0}
                                                                                hideMarkerLength={30}
                                                                                gradient={false}
                                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                                colors={['#039BE5']}
                                                                                sparkline={true}
                                                                                zoom={false}
                                                                                height={34}
                                                                                width={'100%'}
                                                                                grid={false}
                                                                                className={"transparentTooltip mb-n2"}
                                                                                tooltip={true}
                                                                                stacked={false}
                                                                                animations={false}
                                                                            />
                                                                        </div>
                                                                        {state["statistics_memory_"+row.workstation_id] && Object.entries(state["statistics_memory_"+row.workstation_id]).length ?
                                                                            <div className="w-25 pl-1 pt-1 mb-0 align-self-center f11">
                                                                                <p className="d-flex mb-2 text-primary-color f8">Avg <span className="pl-1 text-primary-color">{state["statistics_memory_"+row.workstation_id].avg && state["statistics_memory_"+row.workstation_id].avg ? state["statistics_memory_"+row.workstation_id].avg : 0}</span></p>
                                                                                <p className="d-flex mb-0 text-primary-color f8">Max <span className="pl-1 text-primary-color">{state["statistics_memory_"+row.workstation_id].max && state["statistics_memory_"+row.workstation_id].max}</span></p>
                                                                            </div>
                                                                        : null}                                                      
                                                                    </div>
                                                                :
                                                                    <p className='mb-0 mt-2 text-white'>NA</p>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 pr-2 pt-1">
                                                                <p className='mb-1 pt-1'>Network</p>
                                                                {state["network_"+row.workstation_id] ? 
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="w-75">
                                                                            <ApexListLineChart 
                                                                                graphData={state["network_"+row.workstation_id]}
                                                                                name={'Line'}
                                                                                xaxis={false}
                                                                                xaxisFormat={'datetime'}
                                                                                yaxis={false}
                                                                                yaxisLabel={false}
                                                                                markerSize={0}
                                                                                hideMarkerLength={30}
                                                                                gradient={false}
                                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                                colors={['#775BA2', '#039BE5']}
                                                                                sparkline={true}
                                                                                zoom={false}
                                                                                height={34}
                                                                                width={'100%'}
                                                                                grid={false}
                                                                                className={"transparentTooltip metricsDetailCurrentTooltipAlignment"}
                                                                                tooltip={true}
                                                                                stacked={true}
                                                                                axisBorder={true}
                                                                            />
                                                                        </div>
                                                                        {state["statistics_network_in_"+row.workstation_id] && Object.entries(state["statistics_network_in_"+row.workstation_id]).length ?
                                                                            <div className="w-25 pl-1 pt-1 mb-0 align-self-center f11">
                                                                                <p className="d-flex mb-2 text-primary-color f8">Avg <span className="pl-1 text-primary-color">{state["statistics_network_in_"+row.workstation_id].avg && state["statistics_network_in_"+row.workstation_id].avg ? state["statistics_network_in_"+row.workstation_id].avg : 0}</span></p>
                                                                                <p className="d-flex mb-0 text-primary-color f8">Max <span className="pl-1 text-primary-color">{state["statistics_network_in_"+row.workstation_id].max && state["statistics_network_in_"+row.workstation_id].max}</span></p>
                                                                            </div>
                                                                        : null}  
                                                                    </div>
                                                                :
                                                                    <p className='mb-0 mt-2 text-white'>NA</p>
                                                                }
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 pr-0 pt-1">
                                                                <p className='mb-1'>Disk</p>
                                                                {state["disk_"+row.workstation_id] ? 
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="w-75">
                                                                            <ApexListLineChart 
                                                                                graphData={state["disk_"+row.workstation_id]}
                                                                                name={'Line'}
                                                                                xaxis={false}
                                                                                xaxisFormat={'datetime'}
                                                                                yaxis={false}
                                                                                yaxisLabel={false}
                                                                                markerSize={0}
                                                                                hideMarkerLength={30}
                                                                                gradient={false}
                                                                                gradientColor={['#039BE5', '#039BE5']}
                                                                                colors={['#775BA2', '#039BE5']}
                                                                                sparkline={true}
                                                                                zoom={false}
                                                                                height={34}
                                                                                width={'100%'}
                                                                                grid={false}
                                                                                className={"transparentTooltip metricsDetailCurrentTooltipAlignment"}
                                                                                tooltip={true}
                                                                                stacked={true}
                                                                                axisBorder={true}
                                                                            />
                                                                        </div>
                                                                        {state["statistics_disk_"+row.workstation_id] && Object.entries(state["statistics_disk_"+row.workstation_id]).length ?
                                                                            <div className="w-25 pl-1 pt-1 mb-0 align-self-center f11">
                                                                                <p className="d-flex mb-2 text-primary-color f8">Avg <span className="pl-1 text-primary-color">{state["statistics_disk_"+row.workstation_id].avg && state["statistics_disk_"+row.workstation_id].avg ? state["statistics_disk_"+row.workstation_id].avg : 0}</span></p>
                                                                                <p className="d-flex mb-0 text-primary-color f8">Max <span className="pl-1 text-primary-color">{state["statistics_disk_"+row.workstation_id].max && state["statistics_disk_"+row.workstation_id].max}</span></p>
                                                                            </div>
                                                                        : null}  
                                                                    </div>
                                                                :
                                                                    <p className='mb-0 mt-2 text-white'>NA</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 cursorPointer align-self-center">
                                                        <div className={`mb-4 d-flex justify-content-center`}>
                                                            <p className={`mb-0 f18 align-self-center badge badge-outline-${row.status === "failed" ? "red" : row.status === "stopped" ? "yellow" : row.status === "hibernated" ? "yellow" : "info"} text-${row.status === "failed" ? "red" : row.status === "stopped" ? "yellow" : row.status === "hibernated" ? "yellow" : "info"}  ml-2`}>{row.status}</p>
                                                        </div>
                                                        <p className='text-cyon align-self-center mb-4 f18  d-flex justify-content-center' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>View Details</p>
                                                        {state.catalogType !== 'citrix' && row.status !== 'requested' && row.status !== 'rejected' ?
                                                            <div className=' d-flex justify-content-center'>
                                                                {row.asset_id ?
                                                                    <select 
                                                                        className='form-control bg-transparent border-lightGray select1 text-white' style={{minWidth: '150px'}} id="mySelect"
                                                                        value={state.selectedAction}
                                                                        onChange={(e) => onChangeAction(row, e.target.value)}>
                                                                        {!state.selectedAction ? 
                                                                            <option>Select</option>
                                                                        : null}
                                                                        {state.actionsAllowed && state.actionsAllowed.includes('start') ?
                                                                            <option key={"start"} value={"start"}>Start</option>
                                                                        : null}
                                                                        {state.actionsAllowed && state.actionsAllowed.includes('stop') ?
                                                                            <option key={"stop"} value={"stop"}>Stop</option>
                                                                        : null}
                                                                        {state.actionsAllowed && state.actionsAllowed.includes('modify') ?
                                                                            <option key={"modify"} value={"modify"}>Modify</option>
                                                                        : null}
                                                                        {state.actionsAllowed && state.actionsAllowed.includes('terminate') ?
                                                                            <option key={"terminate"} value={"terminate"}>Terminate</option>
                                                                        : null}
                                                                        {state.actionsAllowed && state.actionsAllowed.includes('hibernate') && row.hibernate ?
                                                                            <option key={"hibernate"} value={"hibernate"}>Hibernate</option>
                                                                        : null}
                                                                    </select>
                                                                : row.status === "initiated" ?
                                                                    <span className={`f13 badge badge-outline-green`}>
                                                                        Launch Initiated    
                                                                        {/* <Spinner className='text-center ml-2' color='light' size='sm' />{' '} */}
                                                                    </span>
                                                                : state.showButtonLoading && row.workstation_id === state.selectedDetails && state.selectedDetails.workstation_id ?
                                                                    <button className={`btn btn-sm bg-green text-white`}>
                                                                        Launching
                                                                        <Spinner className='text-center ml-2' color='light' size='sm' />{' '}
                                                                    </button>
                                                                : !state.showButtonLoading ?
                                                                    <div className='d-flex'>
                                                                        {/* <p className='mb-0 detailsLink text-cyon align-self-center'>View Details</p> */}
                                                                        {state.actionsAllowed && state.actionsAllowed.includes('launch') ?
                                                                            <button
                                                                                className={`btn btn-sm bg-green text-white`}
                                                                                onClick={() => onChangeAction(row, "launch")}
                                                                            >
                                                                                Launch
                                                                            </button>
                                                                        : null}
                                                                        {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                                                            <button
                                                                                className={`btn btn-sm bg-red text-white ml-2`}
                                                                                onClick={() => {
                                                                                    setState(prevState => ({ ...prevState, deleteModal: true, deleteWorksatationDetails: row }))
                                                                                }}
                                                                            >
                                                                                Delete
                                                                            </button>
                                                                        : null}
                                                                    </div>
                                                                : null}

                                                            </div>
                                                        : null}

                                                        {state.actionsAllowed && state.actionsAllowed.includes('delete') && (row.status === 'rejected' || row.status === 'requested') ?
                                                            <div className=' d-flex justify-content-center'>
                                                                {!state.showButtonLoading ?
                                                                    <div className='d-flex'>
                                                                        <button
                                                                            className={`btn btn-sm bg-red text-white ml-2`}
                                                                            onClick={() => {
                                                                                setState(prevState => ({ ...prevState, deleteModal: true, deleteWorksatationDetails: row }))
                                                                            }}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </div>
                                                                : null}
                                                                
                                                            </div>
                                                        : null}                                         
                                                    </div>
                                                </div>
                                                {/* {row.nicedcv_description ?
                                                    <p className='d-flex justify-content-center text-white mt-2 mb-0'>{row.nicedcv_description}</p>     
                                                : null} */}
                                                <div className="d-flex pl-3 w-100">                                                    
                                                    {row.nicedcv_link ?
                                                        <div className='d-flex justify-content-end text-info w-50'>
                                                            <Link to={row.nicedcv_link} className='text-info text-decoration-none align-self-center ' target="_blank" rel="noopener noreferrer">
                                                                Connect via NiceDCV
                                                            </Link>
                                                        </div>
                                                    : null}
                                                    <div className='d-flex w-50 justify-content-end'>
                                                        <div className={`form-check form-check-inline mr-2 align-self-center`}>
                                                            <input
                                                                className='form-check-input m-0'
                                                                type='checkbox'
                                                                checked={state['disabled_policy_'+row.workstation_id] === undefined ? row.disable : state['disabled_policy_'+row.workstation_id]}
                                                                onChange={e => {
                                                                    let disabled_policy = state['disabled_policy_'+row.workstation_id] === undefined && row.disable ? false : !state['disabled_policy_'+row.workstation_id]

                                                                    setState(prevState => ({ ...prevState, ['disabled_policy_'+row.workstation_id]: disabled_policy }))
                                                                    callDisableFuction(row, disabled_policy)
                                                                }}	
                                                                // title={state['disabled_policy_'+row.workstation_id] ? "not enable" : "enable"}								
                                                            />
                                                            {!state['disabled_policy_'+row.workstation_id] ?
                                                                <span className="checkmark" onClick={e => {
                                                                    let disabled_policy = !state['disabled_policy_'+row.workstation_id]
                                                                    setState(prevState => ({ ...prevState, disabled_policy }))
                                                                    callDisableFuction(row, disabled_policy)
                                                                }}></span>
                                                            : null}
                                                        </div>
                                                        <span className="mb-0 f16 text-white">Disable Lifecycle</span>
                                                        {row.lifecycle_message && row.lifecycle_message.length ?
                                                            <React.Fragment>
                                                            <span className='fas fa-info-circle ml-2 align-self-center cursor-pointer' id={'disable_info_'+row.workstation_id}></span>
                                                            <UncontrolledTooltip target={'disable_info_'+row.workstation_id}>
                                                                <div key={index} className={`d-flex flex-column py-2 uncontrolledTooltipLeftAlign justify-content-start`}>
                                                                    <ul className="list-unstyled pl-2 mb-0 f12 text-lightGray font-weight-bolder">
                                                                        {row.lifecycle_message ?
                                                                            row.lifecycle_message.map(inf => {
                                                                                return(
                                                                                    <li className='mb-2'> {inf}</li>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </ul>
                                                                </div>
                                                            </UncontrolledTooltip>
                                                            </React.Fragment>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-2 col-md-2 col-sm-2 pr-0 cursorPointer align-self-center d-flex justify-content-end">
                                                <div className="featureBox shadow-none mr-2">
                                                    <div className="px-3 rounded bg-black border-info">
                                                        <div className="row py-2 px-1 sectionBoxNew">
                                                            <div className={`w-100`}>
                                                                <div className="py-1">
                                                                    <p className="b-block text-center mb-0 f16">Cost ({row.price_unit})
                                                                        {/* <span className="ml-2 f15 mb-0 text-warning">300</span> */}
                                                                    </p>
                                                                </div>
                                                                <div className="py-1">
                                                                    <h5 className="ml-2 text-center mb-0 text-warning">{row.billing_total_cost && row.billing_total_cost}</h5>
                                                                </div>
                                                                {/* <div className="py-1">
                                                                    <p className="b-block mb-0">Memory 
                                                                    <span className="ml-2 mb-0 text-white">1</span></p>
                                                                </div>
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">Network (gbps):
                                                                    <span className="ml-2 mb-0 text-white">5</span></p>
                                                                </div> */}
                                                                <div className='bg-black px-3 py-2 rounded'>
                                                                    {/* <p className="mb-1">Price ({row.price_unit ? row.price_unit : "USD"})</p> */}
                                                                    <div className='d-flex'>
                                                                        <div className='w-100'>
                                                                            <p className="text-center b-block mb-0">OnDemand</p>
                                                                            <p className="text-center mb-0 text-warning">{row.price_per_hour && row.price_per_hour}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div className='w-100 pr-2'>
                                                                            <p className="text-center b-block mb-0">Current Month</p>
                                                                            <p className="text-center mb-0 text-success">{row.billing_monthly_cost ? row.billing_monthly_cost : 0}
                                                                                {/* <span className="text-primary-color f12 ml-1">(31%)</span> */}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex'>
                                                                        <div className='w-100 pr-2'>
                                                                            <p className="text-center b-block mb-0">Current Month Hrs</p>
                                                                            <p className="text-center mb-0 text-success">{row.current_month_total_hours ? row.current_month_total_hours: 0}
                                                                                {/* <span className="text-primary-color f12 ml-1">(31%)</span> */}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                    
                                    <Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, deleteWorksatationDetails: {} }))} {...state.selectedDeleteItem}>
										<ModalHeader toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, deleteWorksatationDetails: {} }))}>Delete Workstation</ModalHeader>
										<ModalBody>
											Are you sure you want to delete the workstation <span className='f18 mx-1'>"{state.deleteWorksatationDetails && state.deleteWorksatationDetails.workstation_name ? state.deleteWorksatationDetails.workstation_name : ''}"</span>
											{/* <Input
												type='text'
												placeholder='Enter Queue name   '
												className={`inputTextbox bg-transparent border-lightGray text-black`}
												// maxlength='128'
												value={state.queue_name ? state.queue_name : ''}
												onChange={e => handleInputChange('queue_name', e.target.value)}
											/> */}
											{state.deleteConfirmMessage ?
												<small className='text-red'>{state.deleteConfirmMessage}</small>
											: null}
											{/* Are you sure you want to delete the <span className='f18 mx-1 text-red'>{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : 'Name'}</span> queue */}

										</ModalBody>
										<ModalFooter className="border-0">
										<Button color="primary" onClick={() => {
                                            setState(prevState => ({ ...prevState, showDeleteLoading: true }))
                                            if(!state.showDeleteLoading) {
                                                deleteWorkstationFunction()
                                            }
                                        }}>
											{state.showDeleteLoading ?
												<Spinner size='sm' color='light' className='mx-2' />
											: null}Ok
										</Button>{' '}
										<Button color="secondary" onClick={() => {
                                            if(!state.showDeleteLoading) {
                                                setState(prevState => ({ ...prevState, deleteModal: false, deleteWorksatationDetails: {}, showDeleteLoading: false }))
                                            }
                                        }}>
											Cancel
										</Button>
										</ModalFooter>
									</Modal>
                                    <Modal isOpen={state.terminateModal} toggle={() => setState(prevState => ({ ...prevState, terminateModal: !state.terminateModal, selectedTerminateDetails: {} }))} {...state.selectedDeleteItem}>
										<ModalHeader toggle={() => setState(prevState => ({ ...prevState, terminateModal: !state.terminateModal, selectedTerminateDetails: {} }))}>Terminate Workstation</ModalHeader>
										<ModalBody>
											Are you sure you want to terminate the workstation <span className='f18 mx-1'>"{state.selectedTerminateDetails && state.selectedTerminateDetails.workstation_name ? state.selectedTerminateDetails.workstation_name : ''}"</span>
										</ModalBody>
										<ModalFooter className="border-0">
										<Button color="primary" onClick={() => {
                                            setState(prevState => ({ ...prevState, showTerminateLoading: true }))
                                            terminateFunction()
                                        }}>
											{state.showTerminateLoading ?
												<Spinner size='sm' color='light' className='mx-2' />
											: null}Ok
										</Button>{' '}
										<Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, terminateModal: false, selectedTerminateDetails: {}, showTerminateLoading: false }))}>
											Cancel
										</Button>
										</ModalFooter>
									</Modal>
                                </div>
                            )
                        })
                    :
                        <div className='d-flex justify-content-center m-4'>
                            There are no data on this criteria. Please try adjusting your filter.
                        </div>
                    }
                </div>
            </div>
            </React.Fragment>
    );
};
export default Workstation