/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ManageBudget.js
 * @author Prakash // on 08/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { listBudgets, getCloudProviderBudget, getCurrencyConversionDetails } from '../../../actions/cost/BudgetAction'
import { capitalizeAllLetter, capitalizeTheFirstLetterOfEachWord, currentLocaltime, momentConvertionUtcToLocalTime, momentDateFormat, thousandSeparator } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { URL_PATH } from '../../../config/urlPath';
import { CSVLink } from 'react-csv';
import CreateProviderAllcoation from './CreateProviderAllcoation';

const ManageBudget = () => {	
	const[state, setState] = useState({
		showLoading: true,
		currencyUnit: 'usd'
	})

    // const providers = useSelector(state => state.filters.providers);
	const navigate = useNavigate();
	const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true, callCloudProviderBudget: true }));
		
		dispatch(getCurrencyConversionDetails())
			.then((response) => {
				setState(prevState => ({ ...prevState, conversionDetails: response.conversion_details ? response.conversion_details : '' }))
			})
	}, [dispatch])

	useEffect(() => {
		if(state.callSearch) {
			let params = {}
	
			dispatch(listBudgets(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listBudgets: response, filterListBudgets: response, callSearch: false, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listBudgets: [], filterListBudgets: [], callSearch: false, showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
		if(state.callCloudProviderBudget) {
			setState(prevState => ({ ...prevState, callCloudProviderBudget: false }))
			let params = {
				year: parseInt(momentDateFormat(currentLocaltime(), 'YYYY')),
				currency_unit: state.currencyUnit
			}
			// if(!state.allocationDetails || !state.allocationDetails.length) {
				dispatch(getCloudProviderBudget(params))
					.then((response) => {
						if(response && !response.error) {
							let results = response.results ? response.results : []
							if(results && results.length) {
								results.forEach(row => {
									setState(prevState => ({ ...prevState, [row.cloudProviderId]: row.allocation ? row.allocation : '', showLoading: false }))
								})
							}
							setState(prevState => ({ ...prevState, allocationDetails: results, showLoading: false }))
						} else {
							setState(prevState => ({ ...prevState, allocationDetails: [], showLoading: false }))
						}
					})
			// }
		}
	}, [state.callCloudProviderBudget, dispatch, state.allocationDetails, state.currencyUnit])

	useEffect(() => {
        let dataResult = state.filterListBudgets && state.filterListBudgets.length ? state.filterListBudgets : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListBudgets])

    const navigateTo = () => {
        navigate(URL_PATH.COST_BUDGET_CREATE); // Redirect to the specified path
    }

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='container-fluid overflow-auto flex-grow-1 px-0'>
			<div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div>
			
			
			<div className='col-sm-12 p-0'>
				<div className='row mx-0 pb-2 bg-nero'>
					<div className='col-lg-3 col-3 py-2'>
						<h6 className='text-white m-0'>Manage Budget</h6>
						<p className='text-white m-0'>Manage list of budget</p>
					</div>
					<div className='col-lg-9 col-9 py-2'>
                        <div className='row mx-0 d-flex justify-content-end'>
							{state.allocationDetails && state.allocationDetails.length ?
								state.allocationDetails.map((row, iRow) => {
									return(
										<div key={row.cloudProviderId}>
										{!iRow ?
											<div className='d-flex mb-1 text-right text-lightGray'>Currency is in
												<div className="d-flex mx-1 align-self-center">
													<input type="radio"
														className=""
														checked={state.currencyUnit === 'usd' ? true : false}  
														onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true, callCloudProviderBudget: true }))}
														value={state.currencyUnit ? true : false}
													/>
												</div>
												<p className="mb-0 mr-1 text-white align-self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true }))}>USD</p>
												<div className="d-flex mx-1 align-self-center">
													<input type="radio"
														className=""
														checked={state.currencyUnit === 'gbp' ? true : false}  
														onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: false, callCloudProviderBudget: true }))}
														value={state.currencyUnit ? true : false}
													/>
												</div>
												<p className="mb-0 mr-1 text-white align-self-center text-lightGray" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true }))}>GBP</p>
											</div>
										: iRow === 1 ?
											state.conversionDetails ?
												<div className='d-flex mb-1 text-lightGray'>{state.conversionDetails}</div>
											: 
												<div className='d-flex mb-1 text-lightGray'>&nbsp;</div>
										: 
											<div className='d-flex mb-1 text-lightGray'>&nbsp;</div>
										}
											<div className='bg-dark3 border-mediumDarkGray rounded px-5 py-3 mr-3 align-self-center'>
												<div className='d-flex justify-content-center'>
													<div className='w-fit-content'>
														<p className='text-white text-center mb-1 f14'>{capitalizeAllLetter(row.cloudProviderId)}&nbsp;Commitment<span className='ml-1 text-lightGray f14'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
														<p className='text-center f18 mb-0 text-warning'>{thousandSeparator(row.allocation)} <span className='ml-1 text-lightGray f14'>{capitalizeAllLetter(state.currencyUnit)}</span></p>
													</div>
												</div>
											</div>
										</div>
									)
								})	
							: null}
							<button className={`btn bg-green p-1 text-white align-self-center`} onClick={() => {
								setState(prevState => ({ ...prevState, showProviderBudgetSection: true }))
							}}>Provider&nbsp;Budget</button>
						</div>
					</div>				
				</div>
				{state.showProviderBudgetSection ?
					<CreateProviderAllcoation 
						closeSidePanel={() => setState(prevState => ({...prevState, showProviderBudgetSection: false }))}
						refreshApi={() => setState(prevState => ({...prevState, allocationDetails: [], callCloudProviderBudget: true }))}
					/>
				: null}
				<div className='row mx-0'>
					<div className='col-sm-12'>
						<div className='mt-2'>
							{/* <div className='d-flex justify-content-end pt-2'>
								<p className='ml-3 mb-1 text-right text-primary-color'>Currency is in in GBP.</p>
							</div> */}
							<div className='d-flex justify-content-between pt-2'>
								<div className=''>
									{/* <div className='d-flex'>
										<p className='mb-0'>Budget Forecast</p>
										<span className='ml-3 far fa-redo f12 cursorPointer text-info align-self-center' onClick={() => setState(prevState => ({ ...prevState, callListlistBudgetsRefresh: true }))}> <span className='ml-2'>refresh </span></span>
									</div> */}
									<p className='mb-0'>
										<small>Showing {state.filterListBudgets && state.filterListBudgets.length} out of total {state.listBudgets && state.listBudgets.length} budget(s), Currency is in usd</small>
										{state.selectedProvider ?
											<React.Fragment>
											<small className='ml-1'>obtained from the</small>
											<small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
											<small className='ml-1'>provider</small>
											</React.Fragment>
										: null}
										{state.selectedAccount && state.selectedAccount.length ?
											<React.Fragment>
											<small className='ml-1'>with the account</small>
											<small className='badge badge-outline-info mx-1'>{state.selectedAccount[0]}</small>
											{state.account_id.length > 1 ?
												<small className='ml-1 text-info'>more</small>
											: null}
											</React.Fragment>
										: null}
										{state.selectedRegion && state.selectedRegion.length ?
											<React.Fragment>
											<small className='ml-1'>in the</small>
											<small className='badge badge-outline-info mx-1'>{state.selectedRegion[0]}</small>
											{state.selectedRegion.length > 1 ?
												<small className='ml-1 text-info'>more</small>
											: null}
											<small className='ml-1'>region</small>
											</React.Fragment>
										: null}
										{/* {state.selectedFilters && state.selectedFilters.start_time ?
											<React.Fragment>
												<small className='ml-1'>for the period starting from</small>
												<small className='ml-1 text-info'>{momentConvertionUtcToLocalTime(state.selectedFilters.start_time, 'YYYY-MM-DD HH:mm')}</small> 
												<small className='ml-1'>to</small>
												<small className='ml-1 text-info'>{momentConvertionUtcToLocalTime(state.selectedFilters.end_time, 'YYYY-MM-DD HH:mm')}</small>
												<small className='ml-1'>(local time)</small>
											</React.Fragment>
										: null} */}
									</p>
								</div>
								
								<div className='align-self-center w-30'>
									<div className='d-flex justify-content-end'>
										{state.filterListBudgets && state.filterListBudgets.length ?
											<CSVLink
												data={state.filterListBudgets ? state.filterListBudgets : []} 
												headers={state.tableHeaders ? state.tableHeaders : []}
												filename={'job-lists.csv'}
												className={'mt-2'}
												target='_blank'
											>
												<span className='d-flex text-primary-color mr-2'>
													<i className='fas fa-download text-primary-color mr-2'/>
												</span>
											</CSVLink>
										: null}
										{state.listBudgets && state.listBudgets.length ?
											<Search
												data={state.listBudgets ? state.listBudgets : []}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-white f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white form-control-sm f12"}
												filteredData={(filterListBudgets) => setState(prevState => ({ ...prevState, filterListBudgets }))}
											/>
										: null}
										<button className={`btn bg-green p-1 text-white ml-2`} onClick={navigateTo}>Create&nbsp;Budget</button>
									</div>                                    
								</div>
							</div>

							<div className='row mx-0'>
								<div className='col-lg-12 col-12 px-0 py-1'>
									{state.filterListBudgets && state.filterListBudgets.length ?
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: 'Date',
													accessor: d => d.modified_at ? "modified_at" : 'created_at',
													Cell: cellInfo => (
													<span>
														{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
													</span>
													),
													width: 200
												},
												{
													Header: 'Name',
													accessor: 'budget_name',
													width: 200,
												},
												{
													Header: 'Allocation',
													accessor: 'allocation',
													width: 200,
												},
												{
													Header: 'Department',
													accessor: 'department',
													width: 200,
												},
												{
													Header: 'Year',
													accessor: 'year',
													width: 100,
												},
												{
													Header: 'Actions',
													Cell: cellInfo => (
														<div className="d-flex justify-content-between align-self-center">
                                                            <Link 
                                                                to={URL_PATH.COST_BUDGET_CREATE} 
                                                                state={{
																	department: cellInfo.row.original.department,
																	year: cellInfo.row.original.year,
																	pageType: 'View',

																}}
                                                                className='text-info'>
                                                                View
                                                            </Link>
															<Link 
                                                                to={URL_PATH.COST_BUDGET_CREATE} 
                                                                state={{
																	department: cellInfo.row.original.department,
																	year: cellInfo.row.original.year,
																	pageType: 'Edit',

																}}
                                                                className='text-info ml-3'>
                                                                Edit
                                                            </Link>
														</div>
													),
													width: 100
												},
												{
													Header: 'Description',
													accessor: 'description',
													width: 450
												},
											]}
											data={state.filterListBudgets}
											perPage={10}
											tableHead={'bg-black text-white'}
											tableBody={'bg-dark3 text-white'}
											tableType={'table-dark-hover'}
											perPageClass={`bg-transparent text-lightGray`}
											textColor={'text-black'}
											selectedColor={'bg-transparent'}
											dashboard={state.filterListBudgets && state.filterListBudgets.length ? true : false}
											sortByColumn={''}
											riskTooltip={[0]}
											onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistBudgets: tableRow }))}
											// tableSize={'table-sm'}
										/>
									: 
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManageBudget