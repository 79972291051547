/*************************************************
 * Collider
 * @exports
 * @file DynamicLoadAction.js
 * @author Prakash // on 02/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
// import { API } from 'aws-amplify'
// import {} from '../config'
// import { myLog } from '../utils/utility'
import axios from 'axios'
import { refreshPage } from '../utils/utility';
//import Cookies from 'js-cookie'

let endPointUrl = ''
const apiUrl = process.env;
if(apiUrl && apiUrl.REACT_APP_COLLIDER_API_URL) {
	endPointUrl = apiUrl.REACT_APP_COLLIDER_API_URL
}

export const dynamicCallApi = (EndPoint, URL, body, callback) => {
	if(EndPoint !== '') {
		EndPoint = '/'+EndPoint
	} 
	return async () => {		
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json', // Modify this as per your required headers
					Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` //Authorization token
					// Add other headers as needed
				},
			};
			const url = endPointUrl+ EndPoint + URL;
			// const response = await axios.get(url, config)
			const response = await axios.post(url, body, config)
			// const response = await axios.post(url, params, config)
			
			// dispatch({ type: ACTION_TYPES.SET_OBSERVABILITY_QUERY_LIST, payload: response.data });

			return response && response.data
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			// dispatch({ type: ACTION_TYPES.SET_ACCOUNTS_LIST, payload: [] });
			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				// Cookies.remove('auth_token')
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}