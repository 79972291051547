/*************************************************
 * Tvastar
 * @exports
 * @file FilterSidePanel.js
 * @author Prakash // on 09/12/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link } from 'react-router-dom'
import _ from 'lodash'

// import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { Store as CommonNotification } from 'react-notifications-component';

import { URL_PATH } from '../../config/urlPath'

import { listAllAccounts } from '../../actions/commonActionNew'
import { editGovernancePolicy, setNewEditRuleDetails } from '../../actions/governance/GovernanceAction'

import { setCommonPropsDetails } from '../../actions/commonActionNew';

import DynamicServices from './Section/DynamicServices';
import RdsServiceEnginesTemplate from './Section/RdsServiceEnginesTemplate';
import SnsServiceLogs from './Section/SnsServiceLogs';
import RdsServiceLogs from './Section/RdsServiceLogs';
import RdsServiceBlockStore from './Section/RdsServiceBlockStore';
import ElasticServiceBlockStore from './Section/ElasticServiceBlockStore';
import Ec2ServiceBlockStore from './Section/Ec2ServiceBlockStore';
import RdsServiceCapacity from './Section/RdsServiceCapacity';
import DedicatedMasterServiceCapacity from './Section/DedicatedMasterServiceCapacity';
import DataNodeServiceCapacity from './Section/DataNodeServiceCapacity';

const FilterSidePanel = (props) => {
	const [state, setState] = useState({
		governanceTypes: [
			{label: "Infrastructure Policy", value: "infra"},
			{label: "Access Managment Policy", value: "access"},
		],

		actionMethods: [
			{value: "immediately", label: "Immediately after the violation"},
			{value: "run_at", label: "At"},
			{value: "run_after", label: "After"},
		]
	})
	const dispatch = useDispatch(); // Create a dispatch function
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
	const editNewRuleDetails = useSelector(state => state?.governance?.editNewRuleDetails || false)

	const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            props.closeSidePanel();
        }
    }, [props]);      

	const getAccounts = () => {
		let provider = editNewRuleDetails && editNewRuleDetails.provider ? editNewRuleDetails.provider : ""
		let params = {}
		params.provider = provider
		let label = provider
		params.aggregate_by = 'account_id'
		if(propAccounts && propAccounts.hasOwnProperty(label)) {
			setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
		} else {
			dispatch(listAllAccounts(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, accounts: response }));
						let obj = propAccounts ? propAccounts : {}
						obj[label] = response
						dispatch(setCommonPropsDetails('propAccounts', obj))
					}
				})
		}
	}

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

	useEffect(() => {
		getAccounts()
		structureServices()
	}, [])

	const structureServices = () => {
		let policy_metadata = props.masterData && props.masterData.policy_metadata ? props.masterData.policy_metadata : {}
		let policyData = props.masterData && props.masterData.policy ? props.masterData.policy : {}
		let policies = props.masterData && props.masterData.policy ? [props.masterData.policy] : []

		let masterRules = props.masterData && props.masterData.master_rule ? [props.masterData.master_rule] : []
		
		let data = []
		policies && policies.forEach(pol => {
			if(policies.filter(e => e.rule_id === pol.rule_id).length) {
				let dataRow = pol
				if(policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call) {
					dataRow.api_to_call = policies.filter(e => e.rule_id === pol.rule_id)[0].api_to_call
				}
				data.push(dataRow)
			}
		})

		let obj = policy_metadata
		obj.policies = data
		dispatch(setNewEditRuleDetails(obj))

		let groupPolicies = data ? _.chain(data).groupBy("rule_category").map((value, key) => ({ label: key, ruleCategoryGroupPolicies: value, ruleIdGroup: _.chain(value).groupBy('rule_id').map((value, key) => ({ label: key, policies: value })).value() })).value() : []

		let appliesToArray = [0]
		if(policy_metadata && policy_metadata.applies_to && policy_metadata.applies_to.length) {
			appliesToArray = []
			policy_metadata.applies_to.forEach((row, index) => {
				appliesToArray.push(index)
				setState(prevState => ({ ...prevState, ["selected_account_"+index]: row.account_id,  ["selected_region_"+index]: (row.region ? row.region : []) }))

				if(policyData.governance_type && policyData.governance_type === "infra") {
					setState(prevState => ({ ...prevState, ["selected_tags_"+index]: (row.tags ? row.tags : [])  }))
				} else if(policyData.governance_type && policyData.governance_type === "infra") {
					setState(prevState => ({ ...prevState, ["selected_users_"+index]: (row.users ? row.users : []), ["selected_roles_"+index]: (row.roles ? row.roles : [])  }))
				}
			})
		}
		
		let notifications = policy_metadata && policy_metadata.notifications ? policy_metadata.notifications : []
		let notificationArray = [0]
		if(notifications.length) {
			notificationArray = []
			notifications.forEach((row, index) => {
				notificationArray.push(index)
				setState(prevState => ({ 
					...prevState,
					["selected_notfication_type_"+index]: row.notfication_type, 
					["shared_details_"+index]: (row.notification_id ? row.notification_id : [])
				}))
			})
		}

		setState(prevState => ({ ...prevState, policy_metadata, policyData: policies, appliesToArray, notificationArray, groupPolicies, newGroup: data, masterRules }))
	}

	const generateIamPolicy = () => {
		let params = editNewRuleDetails
		dispatch(generateIamPolicy(params))
			.then((response) => {
				if(response) {
					let generatedIamPoilcy = response
					let accounts = generatedIamPoilcy.map(e => e.account_id)
					
					let selectedAccount = state.accounts && state.accounts.length ? state.accounts[0] : ""
					let accPolicies = generatedIamPoilcy.map(e => e.account_id === selectedAccount ? e.policy : {})
					
					setState(prevState => ({ ...prevState, generatedIamPoilcy: response, accounts, filteredAccounts: accounts, selectedAccount, accPolicies }))
				} else {
					setState(prevState => ({ ...prevState, generatedIamPoilcy: {} }))
				}
			})
	}

	const validatePolicyInput = (category, boolean, polIndex) => {
		setState(prevState => ({ ...prevState, [category]: boolean }))
	}

	const onFinish = () => {
		let hasError = false
		let data = editNewRuleDetails

		setState(prevState => ({ ...prevState, hasError, onClickFinish: true }))
		validateInput(hasError)	
	}

	const validateInput = (hasError) => {
		let hasPolicyError = false

		let policies = editNewRuleDetails && editNewRuleDetails.policies ? editNewRuleDetails.policies : []

		if(!hasPolicyError && !hasError) {
			policies.forEach(pol => {			
				if(pol.variables && pol.variables.length) {
					pol.variables.forEach(varb => {
						if(!hasPolicyError && (!varb.manditory || varb.manditory === "undefined")) {
							hasPolicyError = false
						} else if(!hasPolicyError) {
							if(varb.value && varb.value) {
								hasPolicyError = true
							} else if(varb.value_list && !varb.value_list.length) {
								hasPolicyError = true
							}
						}
					})
				}
			})
		
		}

		if(!hasPolicyError && !hasError) {
			setState(prevState => ({ ...prevState, queryLoading: true, callUpdatePolicy: false }))
		}
	}

	useEffect(() => {
		if(state.callUpdatePolicy) {
			setState(prevState => ({ ...prevState, callUpdatePolicy: false }))
			let params = editNewRuleDetails
			dispatch(editGovernancePolicy(params))
				.then((response) => {
					if(response) {
						let messageType = 'success'		
						let message = 'User governance policy updated successfully'
						CommonNotification.addNotification({
							//title: "Wonderful!",
							message: message,
							type: messageType,
							insert: "top",
							container: "top-center",
							// animationIn: ["animate__animated", "animate__fadeIn"],
							// animationOut: ["animate__animated", "animate__fadeOut"],
							dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,			  
							}
						});
						setState(prevState => ({ ...prevState, queryLoading: false, finishBtn: 'Saved' }))
						setTimeout(() => { props.closeSidePanel() },5000)
					} else {
						setState(prevState => ({ ...prevState, disableFinish : false, finishBtn: 'Save' }))
					}
				})
		}
	}, [dispatch, editNewRuleDetails, props, state.callUpdatePolicy])
	
	return (
		<div className="advanced-search">
			<div className={`loaderOverlay ${state.queryLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div>
			<div className="search-content bg-muted w-0">
                <div className="header-search bd-highlight bg-black d-flex justify-content-between p-2">
                    <div className="flex-fill bd-highlight text-white">
                        <h5>Details</h5>
                    </div>
					{/* <div className="mr-2 d-flex">
						{/* <div className="mr-3">
							<p className="m-0 small">Last Scan: 29 Sep 2020 11:10</p>
						</div>
						<div className="mr-3">
							<p className="m-0 pl-2 small">Account:  {state.selectedAccount ? state.selectedAccount: 'All'}</p>
						</div>
						<div className="mr-3">
							<p className="m-0 pl-2 small">Region:  {state.selectedRegion ? state.selectedRegion: 'All'}</p>
						</div>
						<div>
							<p className="m-0 pl-2 small">Duration: 
								{DURATION_FILTER_OPTIONS.map((item, index) => {
									return (
										state.selectedDuration === item.value ?
											item.option
										: null
									)
								})}
							</p>
						</div>
					</div> */}
					<div className="text-right flex-fill bd-highlight align-self-center">
                        <i className="far fa-times fa-lg text-white cursorPointer" onClick={() => props.closeSidePanel()}></i>
                    </div>
				</div>


				<div className={`${!state.showLoading ? 'overflow-auto' : ''} p-2`}>
					<div className="rounded bg-dark dlpDescriptionForm dlpDescriptionFormPlain p-3 overflowYAuto" style={{minHeight: state.minMaxHeight, maxHeight: state.minMaxHeight}}>
						<div className="d-flex mt-3">
							<div className={`w-100`}>
								<div className="accordion" id="accordionExample">
									<div className={`card bg-dark3 border-0 mb-2`}>
										<div id={'heading_event'} onClick={() => setState(prevState => ({ ...prevState, expandPolicyInformation: !state.expandPolicyInformation }))}>
											<div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
												<div className="text-white"><span className={`far ${state.expandPolicyInformation ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Policy Information</div>
												{/* <div className="text-info">Showing event details</div> */}
											</div>
										</div>
										<div id={'collapse_event'} className={`collapse ${state.expandPolicyInformation ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
											<div className="card-body bg-dark3 border-0 mb-2 overflow-unset">
												<div className="d-flex mb-2">
													<div className="py-1 w-50">
														<p className="b-block mb-0">Name:</p>
														<p className="mb-0 text-white">{state.policy_metadata && state.policy_metadata.policy_name ? state.policy_metadata.policy_name : ''}</p>
													</div>
													<div className="py-1 w-50 pl-3">
														<p className="b-block mb-0">Type:</p>
														<p className="mb-0 text-white">{state.policy_metadata && state.policy_metadata.governance_type && state.governanceTypes.filter(e => e.value === state.policy_metadata.governance_type).length ? state.governanceTypes.filter(e => e.value === state.policy_metadata.governance_type)[0].label : ""}</p>
													</div>
												</div>

												<div className="d-flex mb-2 border-mediumDarkGray-top">
													<div className="py-1 w-100">
														<p className="b-block mb-0">Description:</p>
														<p className="mb-0 text-white">{state.policy_metadata && state.policy_metadata.description ? state.policy_metadata.description : ''}</p>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className={`card bg-dark3 border-0 mb-2`}>
										<div id={'heading_event'} onClick={() => setState(prevState => ({ ...prevState, expandAppliesTo: !state.expandAppliesTo }))}>
											<div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
												<div className="text-white"><span className={`far ${state.expandAppliesTo ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Apply Policies</div>
												{/* <div className="text-info">Showing event details</div> */}
											</div>
										</div>
										<div id={'collapse_event'} className={`collapse ${state.expandAppliesTo ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
											<div className="card-body bg-dark3 border-0 mb-2 overflow-unset">
												{state.appliesToArray && state.appliesToArray.map(item => {
													return(
													<div className="p-3 rounded bg-dark2">
														<div className="d-flex mb-2">
															<div className="py-1 w-20">
																<p className="b-block mb-0">Account:</p>
																<p className="mb-0 text-white">{state["selected_region_"+item] ? state["selected_region_"+item] : ''}</p>
															</div>
															<div className="py-1 w-30 pl-3">
																<p className="b-block mb-0">Region:</p>
																<p className="mb-0 text-white">
																	{state.regions && state["selected_region_"+item] && state["selected_region_"+item].length ? 
																		state["selected_region_"+item].includes("All") || state["selected_region_"+item].length === state.regions && state.regions.length ?
																			<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																		: 
																			state["selected_region_"+item].map((row, index) => {
																				return(
																					<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{state.regions && state.regions.filter(e => e.value === row).length ? state.regions.filter(e => e.value === row)[0].label : row}</span>
																				)
																			})
																	: null}
																</p>
															</div>
															{state.policyData && state.policyData.governance_type && state.policyData.governance_type === "infra" ?
																<div className="py-1 w-50 pl-3">
																	<p className="b-block mb-0">Tags:</p>
																	<p className="mb-0 text-white">
																		{state["selected_tags_"+item] && state["selected_tags_"+item].length ? 
																			state["selected_tags_"+item].map(tag => {
																				return(
																					<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key +" : "+tag.value}</span>
																				)
																			})
																		:
																			<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																		}
																	</p>
																</div>
															: state.policyData && state.policyData.governance_type && state.policyData.governance_type === "access" ?
																<div className="py-1 w-50 pl-3 d-flex justify-content-between">
																	<div className="">
																		<p className="b-block mb-0">Users:</p>
																		<p className="mb-0 text-white">
																			{state["selected_users_"+item] && state["selected_users_"+item].length ? 
																				state["selected_users_"+item].map(user => {
																					return(
																						<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{user}</span>
																					)
																				})
																			:
																				<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																			}
																		</p>
																	</div>
																	<div className="">
																		<p className="b-block mb-0">Roles:</p>
																		<p className="mb-0 text-white">
																			{state["selected_roles_"+item] && state["selected_roles_"+item].length ? 
																				state["selected_roles_"+item].map(role => {
																					return(
																						<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{role}</span>
																					)
																				})
																			:
																				<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">All</span>
																			}	
																		</p>
																	</div>
																</div>
															: null}
														</div>
													</div>
													)
												})}
											</div>
										</div>
									</div>

									{/* <div className={`card bg-dark3 border-0 mb-2`}>
										<div id={'heading_event'} onClick={() => setState(prevState => ({ ...prevState, expandNotifications: !state.expandNotifications }))}>
											<div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
												<div className="text-white"><span className={`far ${state.expandNotifications ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Notifications</div>
												 <div className="text-info">Showing event details</div>
											</div>
										</div>
										<div id={'collapse_event'} className={`collapse ${state.expandNotifications ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
											{state.notificationArray && state.notificationArray.map(item => {
												return(
												<div className="p-3 rounded bg-dark2">
													<div className="d-flex mb-2">
														<div className="py-1 w-20">
															<p className="b-block mb-0">Notify to:</p>
															<p className="mb-0 text-white">{state["selected_notfication_type_"+item] ? state["selected_notfication_type_"+item] : ''}</p>
														</div>
														<div className="py-1 w-30 pl-3">
															<p className="b-block mb-0">Details:</p>
															<p className="mb-0 text-white">
																{state["shared_details_"+item] && state["shared_details_"+item].length ? 
																	state["shared_details_"+item].map(share => {
																		return(
																			<span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{share}</span>
																		)
																	})
																: null}
															</p>
														</div>
													</div>
												</div>
												)
											})}
										</div>
									</div> */}

									{state.masterRules && state.masterRules.length ?
										state.masterRules.map((pol, polIndex) => {
											return(
												<div className={`card bg-dark border-0 mb-2 overflow-unset`}>
													<div className="d-flex justify-content-between text-white border-mediumDarkGray-bottom">
														<div className="d-flex">
															<p className="mb-0 f20 text-warning cursorPointer">{pol.rule_category} policy rule</p>
														</div>
													</div>
													{!pol.template ?
														<DynamicServices
															masterData={pol}
															resourceType = {pol.resource_type}
															rule_category = {pol.rule_category}
															onClickFinish={state.onClickFinish}
															validateInput={(boo, noInputDetected) => {
																setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category + '_validation_' + polIndex]: noInputDetected ? (boo ? 'failed' : 'success') : 'failed' }))
																validatePolicyInput(pol.rule_category, boo, polIndex)
															}}
															actionMethods={state.actionMethods}
															selectedModeType={state.selectedModeType}
														/>
													: pol.rule_category === "Engine" ?
														<RdsServiceEnginesTemplate 
															masterData = {pol}
															onClickFinish={state.onClickFinish}
															validateInput={boo => {
																setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																validatePolicyInput(pol.rule_category, boo, polIndex)
															}}
															selectedModeType={state.selectedModeType}
															actionMethods={state.actionMethods}
														/>
													: pol.rule_category === "Log" ?
														(pol.rule_id === "AWS_GOV_RDS_0002" || pol.rule_id === "AWS_GOV_AURORA_RDS_0002") ?
															<RdsServiceLogs
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
														: pol.rule_id === "AWS_GOV_SNS_0002" ?
															<SnsServiceLogs 
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
														: null
													: pol.rule_category === "BlockStore" ?
														pol.rule_id === "AWS_GOV_RDS_0007" ?
															<RdsServiceBlockStore
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
														: pol.rule_id === "AWS_GOV_ES_0006" ?
															<ElasticServiceBlockStore
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
														: pol.rule_id === "AWS_GOV_EC2_0012" ?
															<Ec2ServiceBlockStore
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
														: null
													: pol.rule_category === "Capacity" ?
														(pol.rule_id === "AWS_GOV_RDS_0005" || pol.rule_id === "AWS_GOV_AURORA_RDS_0005" || pol.rule_id === "AWS_GOV_EC2_0008" || pol.rule_id === "AWS_GOV_EC_0004" || pol.rule_id === "HPC_GOV_0029"|| pol.rule_id === "HPC_GOV_0032"|| pol.rule_id === "HPC_GOV_0035") ?
															<RdsServiceCapacity
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
															: (pol.rule_id === "AWS_GOV_ES_0003" || pol.rule_id === "AWS_GOV_OS_0003") ?
															<DedicatedMasterServiceCapacity
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
														: (pol.rule_id === "AWS_GOV_ES_0004" || pol.rule_id === "AWS_GOV_OS_0004") ?
															<DataNodeServiceCapacity
																masterData = {pol}
																onClickFinish={state.onClickFinish}
																validateInput={boo => {
																	setState(prevState => ({ ...prevState, onClickFinish: false, [pol.rule_category+"_validation"]: boo ? "failed" : "success" })) 
																	validatePolicyInput(pol.rule_category, boo, polIndex)
																}}
																selectedModeType={state.selectedModeType}
																actionMethods={state.actionMethods}
															/>
														
														: null
													: null}
												</div>
											)
										})
									: null}

									<div className='mt-4'>
										<button className="btn btn-primary" onClick={() => onFinish()} >
											{state.finishBtn ? state.finishBtn : 'Save'} {props.disableFinish ? <Spinner size='sm' color='light' className='ml-2' /> : null}
										</button>
										<Link className='float-right btn btn-light text-muted text-decoration-none ml-1' to={URL_PATH.GOVERNANCE_MANAGE_RULES} >
											Cancel
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default FilterSidePanel