/*************************************************
 * Collider
 * @exports
 * @file SignInReducer.js
 * @author Rajasekar // on 23/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../actions/types'

const INITIAL_STATE = {
	authData: {},
}

/**
 * To store authenticated user data in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const authenticationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_AUTH_TOKENS:
			return {
				...state,
				authData: { ...action.payload },
			}
		default:
			return state
	}
}
