/*************************************************
 * Collider
 * @exports
 * @file CostAction.js
 * @author parakash // on 3-02-2023
 * @copyright © 20214Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'

import {
    HPC_LIST_BUDGET_DETAILS,
    HPC_INSERT_BUDGET_DETAILS,
    HPC_UPDATE_BUDGET_DETAILS,
	HPC_DELETE_BUDGET_DETAILS,
	GET_UPDATES
} from '../../config'
import { ACTION_TYPES } from '../types'
//import Cookies from 'js-cookie'

/**
 * Action to set hpc props details
 *   for cost page
 * @author Prakash // on 29/01/2024
*/
export const setHpcPropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		let hpcPropsDetails = JSON.parse(JSON.stringify(getState().hpc.hpcPropsDetails))
		hpcPropsDetails[key] = value
		dispatch({ type: ACTION_TYPES.SET_HPC_PROPS_DETAILS, payload: hpcPropsDetails });
	}
}

/**
 * Action to list-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const listBudgetDetails = (params) => {
	return async () => {
		try {

			const url = HPC_LIST_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}

/**
 * Action to insert-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const insertBudgetDetails = (params) => {
	return async () => {
		try {

			const url = HPC_INSERT_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to update-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const updateBudgetDetails = (params) => {
	return async () => {
		try {

			const url = HPC_UPDATE_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}

/**
 * Action to delete-budget-details
 * @param {Object} body
 * @param {Function} callback
*/
export const deleteBudgetDetails = (params) => {
	return async () => {
		try {

			const url = HPC_DELETE_BUDGET_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}

/**
 * Action to get-updates
 * @param {Object} body
 * @param {Function} callback
*/
export const getUpdates = (params) => {
	return async () => {
		try {

			const url = GET_UPDATES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.get(url)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}