
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 07/03/2024
 * @copyright © 2019-24 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { UncontrolledTooltip } from 'reactstrap';

import { setCommonPropsDetails, listClusterFilters } from '../../../actions/commonActionNew'
import { listQueues, getQueueDetails, getJobDetails, getQueueAvailabilityDetails } from '../../../actions/Collider/ClusterAction'

import ApexSingleDonutChart from '../../common/charts/ApexSingleDonutChart';
import ApexDonutWithToolTipChart from '../../common/charts/ApexDonutWithToolTipChart';
import { momentDateFormat } from '../../../utils/utility';
import FilterSection from '../../common/FiltersSection';

const LandingPage = () => {
    const clickOutside = useRef();
    // State variables for filters
    const [state, setState] = useState({
        showPageLoading: true,
        startRecordRunning: 0, 
        currentPageRunning: 1,
        totalPagesRunning: 1,
        perPage: 20,
        
        startRecordPending: 0, 
        currentPagePending: 1,
        totalPagesPending: 1,
        topSectionFilters: ['provider', 'account', 'region', 'cluster' ],
    })

    const providers = useSelector(state => state?.filters?.providers || false);

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showPageLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showPageLoading: false })) }, 5000);
        }
    }, [state.showPageLoading])

    // list all providers
    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callClusterList: true }));
    //     }
    // }, [providers]);

    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            showPageLoading: true,
            callQueueList: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], selectedClusters: [], showPageLoading: true, callSearch: true, startRecordRunning: 0, currentPageRunning: 1, totalPagesRunning: 1, startRecordPending: 0, currentPagePending: 1, totalPagesPending: 1, perPage: 20, callClusterList: true }));
    }                

    useEffect(() => {
        if (state.callClusterList) {
            setState(prevState => ({ ...prevState, callClusterList: false }))
            let params = {};
            params.aggregate_by = 'cluster_name';
            params.provider = state.selectedProvider.toLowerCase();

            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, clusters: response, selectedClusters: response.length ? response[0] : '',  callQueueList: true }));
                    }
            })
        }
    }, [dispatch, state.callClusterList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.onLoad])

    useEffect(() => {
        if (state.callQueueList) {
            setState(prevState => ({ ...prevState, callQueueList: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(listQueues(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, queues: response, selectedQueue: response.length ? response[0] : '', callGetQueueDetails: true, callGetQueueAvailability: true, callGetJobDetails: true, showPageLoading: true }));
                    }
            })
        }
    }, [dispatch, state.callQueueList, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters])

    useEffect(() => {
        if (state.callGetQueueDetails) {
            setState(prevState => ({ ...prevState, callGetQueueDetails: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            if(state.selectedQueue) {
                params.queue_name = state.selectedQueue
            }
            dispatch(getQueueDetails(params))
                .then((response) => {
                    let results = []
                    if(response) {
                        results = response.results ? response.results[0] : []
                        setState(prevState => ({ ...prevState, queueDetails: results, structureQueueDetails: true, showPageLoading: false }));
                    }
            })
        }
    }, [dispatch, state.callGetQueueDetails, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue])
    useEffect(() => {
        if (state.callGetQueueAvailability) {
            setState(prevState => ({ ...prevState, callGetQueueAvailability: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            dispatch(getQueueAvailabilityDetails(params))
                .then((response) => {
                    if(response) {
                        // results = response.results ? response.results[0] : []
                        setState(prevState => ({ ...prevState, queueAvailility: response.queue_details, showPageLoading: false }));
                    }
            })
        }
    }, [dispatch, state.callGetQueueAvailability, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue])

    useEffect(() => {
        if (state.callGetJobDetails) {
            setState(prevState => ({ ...prevState, callGetJobDetails: false }))
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            if(state.selectedClusters) {
                params.cluster_name = state.selectedClusters
            }
            if(state.selectedQueue) {
                params.queue_name = state.selectedQueue
            }
            dispatch(getJobDetails(params))
                .then((response) => {
                    let results = []
                    if(response) {
                        results = response.results ? response.results : []
                        let runningJobs = results['Running Jobs'] ? results['Running Jobs'] : {}
                        let pendingJobs = results['Pending Jobs'] ? results['Pending Jobs'] : {}

                        let totalPagesRunning = 1
                        if(runningJobs.jobs_summary && runningJobs.jobs_summary.length > state.perPage) {
                            totalPagesRunning = Math.ceil(runningJobs.jobs_summary.length / state.perPage)
                        }
                        let totalPagesPending = 1
                        if(pendingJobs.jobs_summary && pendingJobs.jobs_summary.length > state.perPage) {
                            totalPagesPending = Math.ceil(pendingJobs.jobs_summary.length / state.perPage)
                        }
                        setState(prevState => ({
                            ...prevState,
                            jobDetails: results,
                            runningJobs,
                            pendingJobs,
                            filterdJobDetails: results,
                            totalPagesRunning,
                            totalPagesPending
                        }));
                    }
            })
        }
    }, [dispatch, state.callGetJobDetails, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, state.selectedQueue, state.perPage])

    useEffect(() => {
        if (state.structureQueueDetails) {
            setState(prevState => ({ ...prevState, structureQueueDetails: false }))
            state.queueDetails && state.queueDetails.chart_details && Object.entries(state.queueDetails.chart_details).forEach(([key, value]) => {
                let donut = []
                let donut_total_count = 0				
                value.data.forEach((row, rIndex) => {
                    let donutRow = {}
                    donutRow.item_count = row > 0 ? row : 0
                    donutRow.item_name = {label: value.labels[rIndex], tooltip: value.tooltip[rIndex] }
                    donut.push(donutRow)
                    donut_total_count += row 
                })
        
                let donutData = {
                    'items': donut,
                    'label': key,
                    'total_count': donut_total_count
                }        
                //services Donut start
                setState(prevState => ({ ...prevState, ['donutGraph_'+key]: donutData }))
            })
        }
    }, [state.structureQueueDetails, state.queueDetails])

    // Function to navigate between pages
    const navigateRunningPage = (action, currentPageRunning) => {
        // Update startRecordRunning and currentPageRunning based on action
        let startRecordRunning = state.startRecordRunning
        if(action === 'next' && currentPageRunning !== state.totalPagesRunning) {
            startRecordRunning = startRecordRunning + state.perPage;
            setState(prevState => ({ ...prevState, currentPageRunning: currentPageRunning+ 1 }));
        } else if(action === 'previous' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: currentPageRunning - 1 }));
            startRecordRunning = startRecordRunning - state.perPage;
        } else if(action === 'start' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: 1 }));
            startRecordRunning = 0;
        } else if(action === 'end' && currentPageRunning !== 0) {
            setState(prevState => ({ ...prevState, currentPageRunning: state.totalPagesRunning }));
            startRecordRunning = (state.totalPagesRunning - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecordRunning }))
    }
    const navigatePendingPage = (action, currentPagePending) => {
        // Update startRecordPending and currentPagePending based on action
        let startRecordPending = state.startRecordPending
        if(action === 'next' && currentPagePending !== state.totalPagesPending) {
            startRecordPending = startRecordPending + state.perPage;
            setState(prevState => ({ ...prevState, currentPagePending: currentPagePending+ 1 }));
        } else if(action === 'previous' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: currentPagePending - 1 }));
            startRecordPending = startRecordPending - state.perPage;
        } else if(action === 'start' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: 1 }));
            startRecordPending = 0;
        } else if(action === 'end' && currentPagePending !== 0) {
            setState(prevState => ({ ...prevState, currentPagePending: state.totalPagesPending }));
            startRecordPending = (state.totalPagesPending - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecordPending }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPagesRunning) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPagesRunning) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const handleChildClick = (event, type, dropdownType, section) => {		
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}
        
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showPageLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                </div>
            </div>
            <div className='row text-white mx-0 pb-2 bg-nero'>
                <div className='col-lg-4 col-md-12 col-sm-12 py-2 align-self-center'>
                    <h6 className='text-white m-0'>Queue Status</h6>
                    <p className='text-white m-0'>Consolidated view of queue status.</p>
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12 py-2' ref={clickOutside}>
                    <div className='row mx-0 topBasicFilter justify-content-end'>
                        <FilterSection
                            filters={state.topSectionFilters}
                            onLoadFields={['selectedClusters']}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            showSelectedCluster={true}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='row mx-0 mt-2'>
                <div className={`col-lg-2 col-md-2 col-sm-2`}>
                    <div className="bg-dark3 pt-2 w-100 vh100 overflow-auto">
                        <p className='f18 text-white ml-1 mb-0'>Queues</p>
                        {state.queues && state.queues.length ?
                            state.queues.map((item, i) => {
                                return (
                                    <React.Fragment  key={'queue_'+i}>
                                    <div className={`px-2 cursorPointer ${i ? "border-mediumDarkGray-top" : ""} ${state.selectedQueue === item ? 'bg-dark3 text-info disabled' : ""}`} 
                                        onClick={() => {
                                            if(state.selectedQueue !== item) {
                                                setState(prevState => ({ ...prevState, selectedQueue: item, showPageLoading: true, callGetQueueDetails: true, callGetJobDetails:  true }))
                                            }
                                        }}
                                    >
                                        <div className="hiddenEllipses d-flex">
                                            <p className={`m-0 f-20 pl pr-1 pt-2 pb-1 ${state.selectedQueue && state.selectedQueue === item ? 'text-info' : 'text-white'}`}>
                                                {item}
                                            </p>
                                        </div>
                                        <p className='text-green mb-1 d-flex f12'>
                                            <span className='text-primary-color mr-2'>Cores Available :</span> 
                                            {state.queueAvailility && state.queueAvailility.filter(e => e.queue_name === item).length && state.queueAvailility.filter(e => e.queue_name === item)[0].cpu_cores ? state.queueAvailility.filter(e => e.queue_name === item)[0].cpu_cores.available : ""}
                                        </p>
                                        <div className="d-flex flex-wrap mb-1">
                                            <p className="mr-1 mb-0">{item.service_name ? item.service_name : ""}</p>
                                            {state.queueAvailility && state.queueAvailility.filter(e => e.queue_name === item).length && state.queueAvailility.filter(e => e.queue_name === item)[0].jobs_count && state.queueAvailility.filter(e => e.queue_name === item)[0].jobs_count.running ?
                                                <span className="badge risk-badge-low f10 mr-1 align-self-center py-1">Running {state.queueAvailility.filter(e => e.queue_name === item)[0].jobs_count.running}</span>
                                            : null}
                                            {state.queueAvailility && state.queueAvailility.filter(e => e.queue_name === item).length && state.queueAvailility.filter(e => e.queue_name === item)[0].jobs_count && state.queueAvailility.filter(e => e.queue_name === item)[0].jobs_count.pending ?
                                                <span className="badge risk-badge-critical f10 mr-1 align-self-center py-1">Pending {state.queueAvailility.filter(e => e.queue_name === item)[0].jobs_count.pending}</span>
                                            : null}
                                        </div>
                                        
                                        {/* <UncontrolledTooltip placement='top' target={"asset_name_"+item.asset_id}>
                                            <div className="uncontrolledTooltipLeftAlign">
                                                <p className="mb-1 text-primary-color wordBreakAll"><span className="text-white mr-1">Asset: </span>
                                                    {item.id_name_map && item.id_name_map !== "" ?
                                                        <span className={`ml-1`}>{(item.id_name_map.value ? item.id_name_map.value : "")}</span>
                                                    :
                                                        <span className={`ml-1`}>{item.asset_name}</span>
                                                    }
                                                </p>
                                                {item.id_name_map && item.id_name_map !== "" ?
                                                    item.id_name_map.key ? 
                                                        <p className={`mb-1 wordBreakAll`}>{item.id_name_map.key}</p>
                                                    : null
                                                :
                                                    item.resource_type ?
                                                        item.resource_type === "ApiGateway" ?
                                                            item.rest_api_name && item.rest_api_name !== "" ?
                                                                <p className={`mb-1 wordBreakAll`}>{item.rest_api_name})</p>
                                                            : null
                                                        : item.resource_type === "EC2" ?
                                                            item.tags && item.tags.length && item.tags.filter(e => e.key === "Name").length ?
                                                                <p className={`mb-1 wordBreakAll`}>{item.tags.filter(e => e.key === "Name")[0].value}</p>
                                                            : null
                                                        : null
                                                    : null
                                                }
                                                <p className="mb-1 text-primary-color">
                                                    <span className="text-white mr-1">Resources: </span>
                                                    {item.provider ? item.provider.toUpperCase() : <span>&nbsp;</span>}
                                                    {item.account_id ? (' : ')+item.account_id : <span>&nbsp;</span>}
                                                    {item.region ?  (' : ')+getRegionName(item.region, this.state.regions) : <span>&nbsp;</span>}
                                                </p>
                                                <p className="mb-1 text-primary-color">
                                                    <span className="text-white mr-1">Services: </span>
                                                    {item.resource_type ?  ('  ')+item.resource_type : <span>&nbsp;</span>}
                                                    {item.service_name ?  (' : ')+item.service_name : <span>&nbsp;</span>}
                                                </p>
                                                <div className="d-flex flex-wrap">
                                                    {item.alerts ?
                                                        <span className="badge risk-badge-critical f10 mx-1 align-self-center py-1">Alerts {item.alerts}</span>
                                                    : null}
                                                    {item.anomalies ?
                                                        <span className="badge risk-badge-critical f10 mx-1 align-self-center py-1">Anomalies {item.anomalies}</span>
                                                    : null}
                                                    {item.errors ?
                                                        <span className="badge risk-badge-critical f10 mx-1 align-self-center py-1">Errors {item.errors}</span>
                                                    : null}
                                                    {item.events ?
                                                        <span className="badge risk-badge-critical f10 mx-1 align-self-center py-1">Events {item.events}</span>
                                                    : null}
                                                </div>
                                                
                                            </div>    
                                        </UncontrolledTooltip> */}
                                    </div>
                                    </React.Fragment>
                                )
                            })
                        : 
                            <div className='d-flex justify-content-center m-4'>
                                <p>There are no data on this criteria. Please try adjusting your filter.</p>
                            </div>
                        }
                    </div>
                </div>
                <div className='col-lg-10 col-md-10 col-sm-10 d-flex justify-content-center pl-0'>
                    <div className="bg-dark3 pt-2 w-100 vh100 overflow-auto p-3">
                        <div className='row'>
                            {state.queueDetails && state.queueDetails.chart_details ?
                                Object.keys(state.queueDetails.chart_details).map((key, kIndex) => {
                                    return(
                                        <React.Fragment key={'key_'+kIndex}>
                                        {state['donutGraph_'+key] ? 
                                            <div className='col-lg-3 col-md-3 col-sm-3'>
                                                <p className='d-flex justify-content-center text-white'>{key}</p>
                                                <div className='d-flex justify-content-center'>
                                                    <ApexDonutWithToolTipChart
                                                        graphData={state['donutGraph_'+key]}
                                                        legend={false}
                                                        showTotal={true}
                                                        showTotalLable={true}
                                                        totalLableType={'integer'}
                                                        showGraphHeading={false}
                                                        height={150}
                                                        width={150}
                                                        legendWidth={200}
                                                        size={'70%'}
                                                        gradient={false}
                                                        gradientColor={['#A88CCC', '#CD5A9F']}
                                                        colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']}
                                                        // className={'transparentTooltip'}
                                                    />
                                                </div>
                                            </div>
                                        : null}
                                        </React.Fragment>
                                    )
                                })
                            : null}
                        </div>
                        <div className='row mx-0 mt-2'>
                            <div className='col-lg-6 pb-2 pl-0'>
                                <div className='card p-3 h-100'>
                                    <div className="d-flex jsutify-content-between mb-1">
                                        <p className='mb-0 text-white w-30'>Running Jobs</p>
                                        <ul className="stock-legend mb-0 d-flex justify-content-end w-70">
                                            <li><span className="legend-circle" style={{backgroundColor: "#854097"}}></span> <span className="text-muted d-inline-block ml-1">vCPUs</span></li>
                                            <li><span className="legend-circle" style={{backgroundColor: "#693EBC"}}></span> <span className="text-muted d-inline-block ml-1"> CPU Cores</span></li>
                                            <li><span className="legend-circle" style={{backgroundColor: "#775BA2"}}></span> <span className="text-muted d-inline-block ml-1"> Memory</span></li>
                                            <li><span className="legend-circle" style={{backgroundColor: "#9A77D1"}}></span> <span className="text-muted d-inline-block ml-1"> Node</span></li>
                                        </ul>
                                    </div>
                                    <div className="d-flex jsutify-content-between mb-2">
                                        <p className='mb-0 w-50 align-self-center'>{state.runningJobs && state.runningJobs.description ? state.runningJobs.description : ""}</p>
                                        {state.runningJobs && state.runningJobs.jobs_summary && state.runningJobs.jobs_summary.length > state.perPage ?
                                            <div className="pagination f12 w-50">
                                                <span className="mx-3">Page <strong>{state.currentPageRunning} of {state.totalPagesRunning}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPageRunning === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigateRunningPage('start', state.currentPageRunning)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${state.currentPageRunning === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigateRunningPage('previous', state.currentPageRunning)}></i></button> 
                                                {/* <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPageRunning}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span> */}
                                                <button><i className={`fal fa-angle-right cursorPointer ${state.currentPageRunning === state.totalPagesRunning ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigateRunningPage('next', state.currentPageRunning)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPageRunning === state.totalPagesRunning ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigateRunningPage('end', state.currentPageRunning)}></i></button>
                                            </div>
                                        : null}

                                    </div>
                                    {state.runningJobs && state.runningJobs.jobs_summary && state.runningJobs.jobs_summary.length ?
                                        state.runningJobs.jobs_summary.slice(state.startRecordRunning, state.startRecordRunning + state.perPage).map((row, rIndex )=> {
                                            return(
                                                <div key={'job_'+rIndex} className={`d-flex w-100 pt-2 ${rIndex ? 'border-mediumDarkGray-top': ''}`}>
                                                    <div className={`d-flex mr-2 px-2 mb-2 pb-1 w-75`}>
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            {/* <span className={`f10 mr-2 align-self-center badge-sm risk-badge-critical`}>C</span> */}
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{row.start_time ? momentDateFormat(row.start_time, 'DD MMM YYYY HH:mm') : null} (utc time)</span>
                                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>{row.job_name ? row.job_name : ''}</span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">job id <span className='text-white'>{row.job_id ? row.job_id : ''}</span></span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">submitted by <span className='text-white'> {row.submitted_by ? row.submitted_by : ''}.</span></span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">Allocated cpu cores {row.cpu_cores ? row.cpu_cores : 0}</span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">memory {row.memory ? row.memory : 0}</span>
                                                            {row.memory_unit ? 
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold ml-1">({row.memory_unit})</span>
                                                            : null}
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mx-2">nodes {row.nodes ? row.nodes : 0}</span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">vCPUs {row.vcpus ? row.vcpus : 0}</span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">wall time {row.wall_time ? row.wall_time : 0}</span>
                                                            
                                                            {row.expected_completion_time ?
                                                                <span className="ml-2 mb-0 f12 text-primary-color font-weight-bold">expected completion time</span>
                                                            : null}
                                                            {row.expected_completion_time ?
                                                                <span className="ml-2 mb-0 f12 text-primary-color font-weight-bold">{row.expected_completion_time} (utc time)</span>
                                                            : null}
                                                        </p>
                                                    </div>
                                                    <div className='w-25 align-self-center'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className="pr-2 mb-1 w-50">
                                                                <div className="progress bg-mediumDarkGray" id={'item_critical_'+rIndex}>
                                                                    <div className={`progress-bar bg-purple3`} 
                                                                        role="progressbar"
                                                                        style={{"width": (row.used_vcpus_percentage && row.used_vcpus_percentage)+'%'}} aria-valuenow="25" aria-valuemin="0"
                                                                        aria-valuemax="100"></div>
                                                                </div>
                                                                <UncontrolledTooltip placement='top' className="text-gray3" target={'item_critical_'+rIndex}>vCPUs <span className="text-purple3">
                                                                    {row.vcpus ? row.vcpus : 0}
                                                                </span></UncontrolledTooltip>
                                                                {/* <div className="bd-highlight d-flex">
                                                                    <small className="text-white bd-highlight">Critical</small>
                                                                    <small className="text-white bd-highlight ml-auto">200</small>
                                                                </div> */}
                                                                <div className="progress mt-3 bg-mediumDarkGray" id={'item_medium_'+rIndex}>
                                                                    <div className={`progress-bar bg-dark-theme-purple`} 
                                                                        role="progressbar"
                                                                        style={{"width": (row.used_memory_percentage && row.used_memory_percentage)+'%'}} aria-valuenow="25" aria-valuemin="0"
                                                                        aria-valuemax="100"></div>
                                                                </div>
                                                                <UncontrolledTooltip placement='top' className="text-gray3" target={'item_medium_'+rIndex}>Memory <span className="text-dark-theme-purple">
                                                                    {row.memory ? row.memory : 0}
                                                                    {row.memory_unit ? ' ('+row.memory_unit+')' : ""}
                                                                </span></UncontrolledTooltip>
                                                            </div>
                                                            <div className="pr-2 mb-1 w-50">
                                                                <div className="progress bg-mediumDarkGray" id={'item_high_'+rIndex}>
                                                                    <div className={`progress-bar bg-purple`} 
                                                                        role="progressbar"
                                                                        style={{"width": (row.used_cpu_cores_percentage && row.used_cpu_cores_percentage)+'%'}} aria-valuenow="25" aria-valuemin="0"
                                                                        aria-valuemax="100"></div>
                                                                </div>
                                                                <UncontrolledTooltip placement='top' className="text-gray3" target={'item_high_'+rIndex}>CPU Cores <span className="text-purple">{row.cpu_cores ? row.cpu_cores : 0}</span></UncontrolledTooltip>
                                                                <div className="progress mt-3 bg-mediumDarkGray" id={'item_low_'+rIndex}>
                                                                    <div className={`progress-bar bg-high`} 
                                                                        role="progressbar"
                                                                        style={{"width": (row.used_nodes_percentage && row.used_nodes_percentage)+'%'}} aria-valuenow="25" aria-valuemin="0"
                                                                        aria-valuemax="100"></div>
                                                                </div>
                                                                <UncontrolledTooltip placement='top' className="text-gray3" target={'item_low_'+rIndex}>Nodes <span className="text-high">{row.nodes ? row.nodes : 0}</span></UncontrolledTooltip>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                            <div className='col-lg-6 pb-2 p-0'>
                                <div className='card p-3 h-100'>
                                    <div className="d-flex jsutify-content-between mb-2">
                                        <p className='mb-1 text-white w-30'>Pending Jobs</p>
                                        <ul className="stock-legend mb-0 d-flex justify-content-end w-70">
                                            <li><span className="legend-circle" style={{backgroundColor: "#854097"}}></span> <span className="text-muted d-inline-block ml-1">vCPUs</span></li>
                                            <li><span className="legend-circle" style={{backgroundColor: "#693EBC"}}></span> <span className="text-muted d-inline-block ml-1"> CPU Cores</span></li>
                                            <li><span className="legend-circle" style={{backgroundColor: "#775BA2"}}></span> <span className="text-muted d-inline-block ml-1"> Memory</span></li>
                                        </ul>
                                    </div>
                                    <div className="d-flex jsutify-content-between mb-2">
                                        <p className='mb-0 w-50 align-self-center'>{state.pendingJobs && state.pendingJobs.description ? state.pendingJobs.description : ""}</p>
                                        {state.pendingJobs && state.pendingJobs.jobs_summary && state.pendingJobs.jobs_summary.length > state.perPage ?
                                            <div className="pagination f12 w-50">
                                                <span className="mx-3">Page <strong>{state.currentPagePending} of {state.totalPagesPending}</strong> </span>
                                                <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPagePending === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePendingPage('start', state.currentPagePending)}></i></button> 
                                                <button><i className={`fal fa-angle-left cursorPointer ${state.currentPagePending === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePendingPage('previous', state.currentPagePending)}></i></button> 
                                                {/* <span>
                                                    Go to
                                                    <input
                                                        type='number'
                                                        value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPagePending}
                                                        onChange={e => {
                                                            const page = e.target.value ? Number(e.target.value) : ""
                                                            gotoPage(page)
                                                        }}
                                                        className="ml-1 inputClass"
                                                    />
                                                </span> */}
                                                <button><i className={`fal fa-angle-right cursorPointer ${state.currentPagePending === state.totalPagesPending ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePendingPage('next', state.currentPagePending)}></i></button> 
                                                <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPagePending === state.totalPagesPending ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePendingPage('end', state.currentPagePending)}></i></button>
                                            </div>
                                        : null}

                                    </div>
                                    {state.pendingJobs && state.pendingJobs.jobs_summary && state.pendingJobs.jobs_summary.length ?
                                        state.pendingJobs.jobs_summary.slice(state.startRecordPending, state.startRecordPending + state.perPage).map((row, rIndex)=> {
                                            return(
                                                <div key={'pen_'+rIndex} className={`d-flex w-100 pt-2 ${rIndex ? 'border-mediumDarkGray-top': ''}`}>
                                                    <div className={`d-flex mr-2 px-2 mb-2 pb-1 w-75`}>
                                                        <p className="mb-0 d-flex flex-wrap">
                                                            {/* <span className={`f10 mr-2 align-self-center badge-sm risk-badge-critical`}>C</span> */}
                                                            <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{row.start_time ? momentDateFormat(row.start_time, 'DD MMM YYYY HH:mm') : null} (utc time)</span>
                                                            <span className={`mb-0 mr-2 f12 text-purple-2`}>{row.job_name ? row.job_name : ''}</span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">job id <span className='text-white'>{row.job_id ? row.job_id : ''}</span></span>
                                                            <span className="mb-0 mr-2 f12 font-weight-bold">submitted by <span className='text-white'>{row.submitted_by ? row.submitted_by : ''}.</span></span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">Requested cpu cores {row.cpu_cores ? row.cpu_cores : 0}</span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">memory {row.memory ? row.memory : 0}</span>
                                                            {row.memory_unit ? 
                                                                <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">({row.memory_unit})</span>
                                                            : null}
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">vCPUs {row.vcpus ? row.vcpus : 0}</span>
                                                            <span className="mb-0 f12 text-primary-color font-weight-bold">wall time {row.wall_time ? row.wall_time : 0}</span>
                                                        </p>
                                                    </div>
                                                    <div className='w-25 align-self-center'>
                                                        <div className='d-flex justify-content-center'>
                                                            <div className='mr-2'>
                                                                <ApexSingleDonutChart
                                                                    labelColor={"#999999"}
                                                                    valueColor={"#999999"}
                                                                    graphData={{label: 'vCPUs', value: row.vcpus ? row.vcpus : 0}}
                                                                    legend={false}
                                                                    showTotal={false}
                                                                    showTotalLable={true}
                                                                    showGraphHeading={false}
                                                                    height={25}
                                                                    width={25}
                                                                    legendWidth={200}
                                                                    size={'70%'}
                                                                    gradient={false}
                                                                    colors={['#854097']}
                                                                    className={'transparentTooltip'}
                                                                />
                                                            </div>
                                                            <div className='mr-2'>
                                                                <ApexSingleDonutChart
                                                                    labelColor={"#999999"}
                                                                    valueColor={"#999999"}
                                                                    graphData={{label: 'CPU Cores', value: row.cpu_cores ? row.cpu_cores : 0}}
                                                                    legend={false}
                                                                    showTotal={false}
                                                                    showTotalLable={true}
                                                                    showGraphHeading={false}
                                                                    height={25}
                                                                    width={25}
                                                                    legendWidth={200}
                                                                    size={'70%'}
                                                                    gradient={false}
                                                                    colors={['#693EBC']}
                                                                    className={'transparentTooltip'}
                                                                />
                                                            </div>
                                                            <div className='mr-2'>
                                                                <ApexSingleDonutChart
                                                                    labelColor={"#999999"}
                                                                    valueColor={"#999999"}
                                                                    graphData={{label: 'Memory'+(row.memory_unit ? ' ('+row.memory_unit+')' : ""), value: row.memory ? row.memory : 0}}
                                                                    legend={false}
                                                                    showTotal={false}
                                                                    showTotalLable={true}
                                                                    showGraphHeading={false}
                                                                    height={25}
                                                                    width={25}
                                                                    legendWidth={200}
                                                                    size={'70%'}
                                                                    gradient={false}
                                                                    colors={['#775BA2']}
                                                                    className={'transparentTooltip'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                    
            </div>
        </div>
    );
};

export default LandingPage