/*************************************************
 * Collider
 * @exports
 * @file commonAction.js
 * @author Prakash // on 11/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../components/ApiCalls'
import {
	KIOSK_END_POJNT,
	LIST_PROVIDERS,
	LIST_REGIONS,
	LIST_SERVICES,
	LIST_ACCOUNTS,
	LIST_CATEGORIES,	
	GET_USER_ORGANIZATION,
	LIST_EVENT_SOURCES,
	CAPTURE_UI_ERRORS,

	LIST_ORGANIZATIONS,
	LIST_MODULES,
	LIST_ACCOUNTS_DETAILS,
	
	// TAGGING,
	
	GET_ALL_TAGS,
	GET_ALL_TAGS_KEYS,
	GET_ALL_TAGS_VALUES,

	
	GET_USER_PROFILE,
	GET_USER_MENU,

	
	GET_RESOURCE_SEARCH_META_RESULTS,
	GET_SEARCH_META_RESULTS,
	SAVE_SEARCH_META_RESULTS,
	GET_VARIOUS_COUNTS_BY_ACCOUNT,
	SET_FAVOURITE_QUERY,
	UNSET_FAVOURITE_QUERY
	
} from '../config'
import { myLog } from '../utils/utility'
import { ACTION_TYPES } from './types'
// import { toast } from 'react-toastify'
// import { SUCCESS, ERROR } from '../utils/constants'

/**
 * Action to show notification
 * @param {Function} callback
 */
// export const showNotification = (type, message) => {
// 	return () => {
// 		switch (type) {
// 			case SUCCESS:
// 				toast.success(
// 					<div className='d-flex align-items-centerr w-100'>
// 						<i className='fa fa-check-circle px-2' />
// 						<div className='project-font'>{message}</div>
// 					</div>
// 				)
// 				break
// 			case ERROR:
// 				toast.error(
// 					<div className='d-flex align-items-centerr w-100'>
// 						<i className='fa fa-times-circle px-2' />
// 						<div className='project-font'>{message}</div>
// 					</div>
// 				)
// 				break
// 			default:
// 				break
// 		}
// 	}
// }

/**
 * Action to get all providers
 * @param {Function} callback
 */
export const listAllProviders = callback => {
	return async dispatch => {
		try {
			const url = LIST_PROVIDERS;
			const response = await ApiCalls.get(url);
			dispatch({
				type: ACTION_TYPES.SET_PROVIDERS_LIST,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			dispatch({
				type: ACTION_TYPES.SET_PROVIDERS_LIST,
				payload: [],
			})

			let response = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, response)
		}
	}
}
/**
 *  Action to get all services
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllServices = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_SERVICES;
			const response = await ApiCalls.post(url, body);
			dispatch({
				type: ACTION_TYPES.SET_SERVICES_LIST,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 *  Action to get all regions
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllRegions = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_REGIONS;
			const response = await ApiCalls.post(url, body);
			dispatch({
				type: ACTION_TYPES.SET_REGIONS_LIST,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 *  Action to get all accounts
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllAccounts = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_ACCOUNTS;
			
			const response = await ApiCalls.get(url, body);
			dispatch({
				type: ACTION_TYPES.SET_ACCOUNTS_LIST,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
			// 	//localStorage.setItem('ACCESS_TOKEN', '');
            //     //window.location.href = '/signin';
			// }
			callback(false, respone)
		}
	}
}

/**
 *  Action to get all categories
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllCategory = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_CATEGORIES;
			const response = await ApiCalls.post(url, body);
			dispatch({
				type: ACTION_TYPES.SET_CATEGORIES_LIST,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to get list  event sources
 * @param {Object} body
 * @param {Function} callback
 */
export const listEventSources = callback => {
	return async dispatch => {
		try {
			const url = LIST_EVENT_SOURCES;
			const response = await ApiCalls.get(url);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to capture-ui-errors
 * @param {Object} body
 * @param {Function} callback
 */
 export const captureUiErrors = (body, callback) => {
	return async dispatch => {
		try {
			const url = CAPTURE_UI_ERRORS;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to get all organizations
 * @param {Object} body
 * @param {Function} callback
 */
export const listOrganizations = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_ORGANIZATIONS;
			const response = await ApiCalls.get(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
*  Action to get all modules
* @param {Object} body
* @param {Function} callback
*/
export const listModules = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_MODULES;
			const response = await ApiCalls.get(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to get all accounts details
 * @param {Object} body
 * @param {Function} callback
 */
export const listAllAccountsDetails = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_ACCOUNTS_DETAILS;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to get user orgaziation (get user details with extrenal Id)
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserOrganization = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_USER_ORGANIZATION;
			const response = await ApiCalls.get(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

export const apiToCall = (URL, body, callback) => {
	return async dispatch => {
		try {
			const url = '/commonservices/' + URL;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

export const apiToDevCall = (URL, body, callback) => {
	return async dispatch => {
		try {
			const url = '/commonservices' + URL;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

export const DynamicApiToDevCall = (API_END_POINT, apiMethod, URL, body, callback) => {
	return async () => {
		try {
			API_END_POINT = KIOSK_END_POJNT
			if(URL === "/database/list/listvmtypes") {
				const response = await ApiCalls.post(URL, body);
				callback(true, response.data ? response.data : [])
			} else {
				if(apiMethod === "get") {
					const response = await ApiCalls.get(URL);
					callback(true, response && response.data)
				} else {
					let splitURL = URL.split("/")
					let response = ""
					if(splitURL.includes("listvmtypes")) {
						response = await ApiCalls.post(URL, body);
						callback(true, response && response.data)
					} else {
						response = await ApiCalls.post(URL, body);
					}
					callback(true, response && response.data)
				}
			}
		} catch (error) {
			myLog('apiToCall', error)
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, {})
		}
	}
}

export const getAllTags = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_ALL_TAGS;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

export const getAllTagsKeys = (body, callback) => {	
	return async dispatch => {
		try {
			const url = GET_ALL_TAGS_KEYS;
			const response = await ApiCalls.post(url, body);
			dispatch({
				type: ACTION_TYPES.SET_TAG_KEYS,
				payload: response && response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

export const getAllTagsValues = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_ALL_TAGS_VALUES;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to get user profile
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserProfile = callback => {
	return async dispatch => {
		try {
			const url = GET_USER_PROFILE;
			const response = await ApiCalls.get(url);
			dispatch({
				type: ACTION_TYPES.SET_PROFILE_DETAILS,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to get user meu
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 18/06/2023
 */
export const getUserMenu = callback => {
	return async dispatch => {
		try {
			const url = GET_USER_MENU;
			const response = await ApiCalls.get(url);
			dispatch({
				type: ACTION_TYPES.SET_USER_MENU,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}


export const getResourceSearchMetaResults = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_RESOURCE_SEARCH_META_RESULTS;
			const response = await ApiCalls.post(url, body);
			dispatch({
				type: ACTION_TYPES.SET_RESOURCE_SEARCH_META_LIST,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to get all accounts
 * @param {Object} body
 * @param {Function} callback
 */
export const getSearchMetaResults = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_SEARCH_META_RESULTS;
			const response = await ApiCalls.post(url, body);
			// dispatch({
			// 	type: ACTION_TYPES.SET_SEARCH_META_LIST,
			// 	payload: response.data,
			// })
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to save-search-meta-results
 * @param {Object} body
 * @param {Function} callback
 */
export const saveSearchMetaResults = (body, callback) => {
	return async dispatch => {
		try {
			const url = SAVE_SEARCH_META_RESULTS;
			const response = await ApiCalls.post(url, body);
			// dispatch({
			// 	type: ACTION_TYPES.SET_SEARCH_META_LIST,
			// 	payload: response.data,
			// })
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get-various-counts-by-account
 * @param {Object} body
 * @param {Function} callback
 */
 export const getVariousCountsByAccount = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_VARIOUS_COUNTS_BY_ACCOUNT;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to set favourite query
 * @param {Object} body
 * @param {Function} callback
 */
export const setFavouriteQuery = (body, callback) => {
	return async dispatch => {
		try {
			const url = SET_FAVOURITE_QUERY;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 *  Action to unset favourite query
 * @param {Object} body
 * @param {Function} callback
 */
export const unsetFavouriteQuery = (body, callback) => {
	return async dispatch => {
		try {
			const url = UNSET_FAVOURITE_QUERY;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
                //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}