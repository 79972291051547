/*************************************************
 * Collider
 * @exports
 * @file SpendAction.js
 * @author Prakash // on 26/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import {
	GET_JOB_COST_TREND,
	GET_JOB_TOTAL_COST,
	GET_JOB_UTILIZATION_TREND,
	GET_COST_TREND_BREAK_DOWN,
} from '../../config'
import ApiCalls from '../../components/ApiCalls';
import { refreshPage } from '../../utils/utility';
// import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
// import { ACTION_TYPES } from '../types'

//import Cookies from 'js-cookie'

/**
 * Action to get-job-cost-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobCostTrend = (params) => {
	return async () => {		
		try {
			const url = GET_JOB_COST_TREND;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to get-job-total-cost
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobTotalCost = (params) => {
	
	return async () => {		
		try {
			const url = GET_JOB_TOTAL_COST;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to get-cost-trend-break-down
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostTrendBreakDown = (params) => {
	return async () => {		
		try {
			const url = GET_COST_TREND_BREAK_DOWN;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}

/**
 * Action to get-job-utilization-trend
 * @param {Object} body
 * @param {Function} callback
 */
export const getJobUtilizationTrend = (params) => {
	return async () => {		
		try {
			const url = GET_JOB_UTILIZATION_TREND;
			const response = await ApiCalls.post(url, params)

			return response && response.data
		} catch (error) {			
			// Throw the error to be caught in the component if needed
			// throw error;
			
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			}
			return error.response
		}
	}
}