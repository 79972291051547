import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, UncontrolledTooltip } from 'reactstrap';
import { setCommonPropsDetails } from '../../actions/commonActionNew'
import { getJobStatusApis, listJobDetails, cancelJob } from '../../actions/Collider/JobStatusAction'
import _ from 'lodash'
import { momentDateGivenFormat, momentConvertionLocalToUtcTime, momentConvertionUtcToLocalTime, currentLocaltime, numberFormater, capitalizeTheFirstLetterOfEachWord, capitalizeFirstLetter, convertToLowerCase } from '../../utils/utility'

import { CSVLink } from 'react-csv';

import ApexStackedBarChart from '../common/charts/ApexStackedBarChart'
import ApexBarChart from '../common/charts/ApexBarChart'
import ApexDonutChart from '../common/charts/ApexDonutChart'
import ResizeableDarkThemeTable from '../designComponents/Table/ResizeableDarkThemeTable'

import JobDetailsSidePanel from './JobDetailsSidePanel'
import Search from '../common/SearchWithHiddenInput'
import CreateJobPanel from './CreateJobPanel';
import AdvancedFilters from './AdvancedFilters';
import { Store as CommonNotification } from 'react-notifications-component';
import FilterSection from '../common/FiltersSection';

let refreshPage = null
const Dashboard = () => {
    const clickOutside = useRef()
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',
        graphType: [{label:'Job', value:'job_name'}, {label:'User', value:'user_name'}],
        selectedGraphType: 'job_name',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'duration']
    })

    const providers = useSelector(state => state?.filters?.providers || false);
    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    // useEffect(() => {
    //     if(providers) {
    //         setState(prevState => ({ ...prevState, providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '', callSearch: true }));
    //     }
    // }, [providers])
    
    useEffect(() => {
       	let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        }
        setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate }));
        
	}, [state.selectedDuration])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedQueue: filter.selectedQueue ? filter.selectedQueue : [],
            selectedUser: filter.selectedUser ? filter.selectedUser : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true
        }))
    }

    useEffect(() => {
        if(state.callSearch) {
            if(state.selectedProvider && state.datePickerStartDate) {
                clearTimeout(refreshPage)
                setState(prevState => ({ ...prevState, showLoading: true, callSearch: false, callGetJobStatusApisFunction: true, callListJobDetailsFunction: true }))
                // setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 4000);
                
                const delayInMilliseconds = 5 * 60 * 1000; // 5 minutes
                if(state.selectedDuration !== 'custom' && state.selectedDuration !== 'lastMonth') {
                    refreshPage = setTimeout(() => setState(prevState => ({ ...prevState, callSearch: true })), delayInMilliseconds)
                }
            }
        }

    }, [state.callSearch, state.selectedProvider, state.datePickerStartDate, state.selectedDuration])

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], callSearch: true, selectedDuration: 'currentMonth' }));
    }

    useEffect(() => {
        if(state.callGetJobStatusApisFunction) {
            let filterParams = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                filterParams.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                filterParams.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                filterParams.account_id = state.selectedAccount
            }
            if(state.selectedUser && state.selectedUser.length) {
                filterParams.user_name = state.selectedUser
            }
            
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                filterParams.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                filterParams.cluster_name = state.selectedClusters
            }
    
            let top = {
                'start_time': momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by': [state.selectedGraphType],
                'top': 5
            }
            let topParams = {...filterParams, ...top}
    
            let trend = {
                'start_time': momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by': ['status'],
                'radial': true
            }
            let trendParams = {...filterParams, ...trend}
    
            let total = { 
                'duration_aggregate_by': ['daily', 'monthly', 'weekly'],
            }
            let totalParams = {...filterParams, ...total}
    
            let params = {
                'top': topParams,
                'trend': trendParams,
                'total': totalParams
            }
    
            dispatch(getJobStatusApis(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            statusSection: response,
                            topStatusResponse: response.top ? response.top : [],
                            statusTrendData: {},
                            topStatusData: {},
                            statusDonutData: {},
                            showLoading: false,
                            callGetJobStatusApisFunction: false
                        }));
                    }
                })
        }
    }, [state.callGetJobStatusApisFunction, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedGraphType, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.selectedUser])


    useEffect(() => {
        if(state.callGetJobTobStatusApisFunction) {
            let filterParams = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                filterParams.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                filterParams.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                filterParams.account_id = state.selectedAccount
            }
            if(state.selectedUser && state.selectedUser.length) {
                filterParams.user_name = state.selectedUser
            }
            
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                filterParams.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                filterParams.cluster_name = state.selectedClusters
            }
    
            let top = {
                'start_time': momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by': [state.selectedGraphType],
                'top': 5,
            }
            let topParams = {...filterParams, ...top}
    
            let params = {
                'top': topParams
            }
    
            dispatch(getJobStatusApis(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            topStatusResponse: response.top ? response.top : [],
                            topStatusData: {},
                            selectedTopStatusFilter: '',
                            showLoading: false,
                            callTopStatusSection: true,
                            callGetJobTobStatusApisFunction: false
                        }));
                    }
                })
        }
    }, [state.callGetJobTobStatusApisFunction, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedClusters, state.selectedGraphType, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.selectedUser])

    useEffect(() => {
        if(state.statusSection && Object.entries(state.statusSection).length) {
            setState(prevState => ({
                ...prevState,
                callStatusCountSection: true,
                callStatusTrendSection: true,
                callTopStatusSection: true,
                callStatusDonutSection: true,
        }))
        }
    }, [state.statusSection])

    useEffect(() => {
        if(state.callStatusCountSection) {
            let total = 0
            state.statusSection && state.statusSection.trend && state.statusSection.trend.forEach(item => {
                total = item.total ? (item.total.Success ? item.total.Success : 0) + (item.total.Failed ? item.total.Failed : 0) : 0
            })
    
            let statusCount = 0
            let statusDailyCount = 0
            let statusWeeklyCount = 0
            let statusMonthlyCount = 0
    
            if(state.statusSection && state.statusSection.total) {
                let total = state.statusSection.total && state.statusSection.total.length ? state.statusSection.total[0] : {}
                statusCount = total.count ? total.count : 0
                statusDailyCount = total.daily && total.daily.count ? total.daily.count : 0
                statusWeeklyCount = total.weekly && total.weekly.count ? total.weekly.count : 0
                statusMonthlyCount = total.monthly && total.monthly.count ? total.monthly.count : 0
            }
    
            setState(prevState => ({ ...prevState, statusPeriodCount: total, statusCount, statusDailyCount, statusMonthlyCount, statusWeeklyCount, callStatusCountSection: false }));
        }
    }, [state.callStatusCountSection, state.statusSection])

    useEffect(() => {
        if(state.callStatusTrendSection) {
            let graphData = {}
            let totalSuccessRate = 0
            let totalFailureRate = 0
            state.statusSection && state.statusSection.trend && state.statusSection.trend.forEach(item => {
                graphData.labels = item.dates ? item.dates : []
                item.data && Object.entries(item.data).forEach(([key,value]) => {
                    if(key !== 'InProgress') {
                        graphData[key] = value
                    }
                })
    
                totalSuccessRate = item.rate && item.rate.success_rate ? item.rate.success_rate : ''
                totalFailureRate = item.rate && item.rate.failure_rate ? item.rate.failure_rate : ''
            })

            let donut = []
            let donut_total_count = 0
            
            let donutRow = {}
            donutRow.item_count = totalSuccessRate
            donutRow.item_name = "Success"
            donut.push(donutRow)
            donut_total_count += totalSuccessRate 

            donutRow = {}
            donutRow.item_count = totalFailureRate
            donutRow.item_name = "Failed"
            donut.push(donutRow)
            donut_total_count += totalFailureRate 
            
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let successFailureDonutData = {
                'items': donut,
                'label': 'Total',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, successFailureDonutData, statusTrendData: graphData, totalSuccessRate, totalFailureRate, callStatusTrendSection: false }));
        }
    }, [state.callStatusTrendSection, state.statusSection])

    useEffect(() => {
        if(state.callStatusDonutSection) {
            let donut = []
            let donut_total_count = 0
            
            state.statusSection && state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
                let donutRow = {}
                donutRow.item_count = value
                donutRow.item_name = key
                donut.push(donutRow)
                donut_total_count += value 
            })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label': 'Total',
                'total_count': donut_total_count
            }
                
            setState(prevState => ({ ...prevState, statusDonutData, callStatusDonutSection: false }));
        }
    }, [state.callStatusDonutSection, state.statusSection])

    useEffect(() => {
        if(state.callTopStatusSection) {
            let top = state.topStatusResponse ? state.topStatusResponse : []
            let topStatusType = top.filter(e => e.label).map(e => e.label)
            topStatusType = _(topStatusType).sortBy().value()
    
            let filterData = []
            let type = state.selectedTopStatusFilter ? state.selectedTopStatusFilter : topStatusType[0]
            if(top && top.length) {
                filterData = top.filter(e => e.label === type)
                filterData = filterData.length ? filterData[0].data : []
            }
    
            let data = []
            let labels = []
            let dataUnit = ''
            let tooltip = []
    
            filterData.length && filterData.forEach(item => {
                let value = item.top && item.top.value ? item.top.value : 0
                if(type === 'RunTime' || type === 'RunTime' || type === 'RunTime') {
                    value = value.toFixed(2)
                }
                data.push(value)
                labels.push(item[state.selectedGraphType])
                dataUnit = item.top && item.top.unit ? item.top.unit : ''
                
                let tooltipRow = {}
                // tooltipRow.duration = item.duration ? unitTimeConvertion(item.duration, 'seconds') : '0  second'
                // {console.log}
                let tooltipValue = item.top && item.top.display_value ? item.top.display_value : parseInt(value)
                // if(item.top && item.top.unit && item.top.unit !== "count") {
                //     tooltipRow.unit = item.top.unit
                //     if(tooltipRow.unit === 'seconds') {
                //         tooltipValue = parseInt(value)
                //     }
                // } else if(item.top && item.top.unit && item.top.unit === "count") {
                //     tooltipValue = parseInt(value)
                // }
                tooltipRow.value = tooltipValue
                // if(item.time_saved) {
                    // tooltipRow.time_saved = item.time_saved ?  unitTimeConvertion(item.time_saved, 'seconds') : '0 second'
                // }
                tooltipRow.description = item.description ? item.description : ''
                tooltip.push(tooltipRow)
            })
    
            for(let i=filterData.length; i<5; i++) {
                data.push(0)
                labels.push(' ')
            }
    
            let graphData = {}
            graphData.data = data
            graphData.labels = labels        
            graphData.unit = dataUnit
            graphData.tooltipValues = tooltip
    
            setState(prevState => ({ ...prevState, topStatusData: graphData, selectedTopStatusFilter: type, callTopStatusSection: false, topStatusType }));
        }
    }, [state.callTopStatusSection, state.selectedTopStatusFilter, state.topStatusResponse, state.selectedGraphType])

    useEffect(()  => {
        if(state.callListJobDetailsFunction || state.callListJobDetailsRefresh) {
            setState(prevState => ({ ...prevState, callListJobDetailsFunction: false, callListJobDetailsRefresh: false  }));
            let params = {}
            if(state.selectedProvider) {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            
            if(state.selectedClusters && state.selectedClusters.length && !state.selectedClusters.includes("All")) {
                params.cluster_name = state.selectedClusters
            }
            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            if(state.callListJobDetailsRefresh) {
                params.end_time = momentConvertionLocalToUtcTime(currentLocaltime(), 'YYYY-MM-DD HH:mm:ss')
            } else {
                params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            }
            if(state.selectedUser && state.selectedUser.length) {
                params.user_name = state.selectedUser
            }

            if(state.selectedStatus) {
                params.status = state.selectedStatus
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }

            // params.size = 5
    
            dispatch(listJobDetails(params))
                .then((response) => {
					if(response) {
                        let totalResults = []
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextToken && state.nextToken.length) {
                            totalResults = state.jobDetails &&  state.jobDetails.length ? state.jobDetails : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        let results = response && response.results ? response.results : []
                        if(results && results.length) {
                            totalResults = totalResults.concat(results)
                        }
                        
                        let json = response.job_details ? response.job_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])                        
                        setState(prevState => ({ ...prevState, jobDetails: totalResults, filteredJobDetails: totalResults, startRecord, currentPage,  selectedFilters: params, detailsFormat, nextToken: response.next_token, totalRecords: response.total }));
                    } else {
                        setState(prevState => ({ ...prevState, callListJobDetailsFunction: false, callListJobDetailsRefresh: false }))
                    }
            })
        }
    }, [state.callListJobDetailsFunction, state.callListJobDetailsRefresh, dispatch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedClusters, state.nextToken, state.currentPage, state.jobDetails, state.perPage, state.selectedStatus, state.startRecord, state.selectedUser, state.selectedProjectTags])

    useEffect(() => {
        let dataResult = state.filteredJobDetails && state.filteredJobDetails.length ? state.filteredJobDetails : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, jobheaders: headers }))
		}
    }, [state.jobDetails, state.filteredJobDetails])

    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

        event.stopPropagation();

        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild, clickedOutSide: true }))
        }
	}

    const cancelJobFunction = () => {
        setState(prevState => ({ ...prevState, showCancelLoding: true }))
        let params = state.cancelJobDetails
        dispatch(cancelJob(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, cancelModal: !state.cancelModal, showCancelLoding: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showCancelLoding: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showCancelLoding: false }))
                }
            })
    }

    useEffect(() => {
        if(state.selectedJobDetails && Object.entries(state.selectedJobDetails).length && !state.showJobDetails) {
            setState(prevState => ({ ...prevState, showJobDetails: true }))
        }
    }, [state.selectedJobDetails, state.showJobDetails])

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                </div>
            </div>
            {state.showJobDetails ? (
                <JobDetailsSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, showJobDetails: false, selectedJobDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedJobDetails={state.selectedJobDetails} 
                />
            ) : null}
            {state.showCreateJob ? (
                <CreateJobPanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, showCreateJob: false }))}
                />
            ) : null}
            {state.showAdvancedFilters ?
                <AdvancedFilters
                    selectedFilters={(filter) => {
                        // handleSelectedFilters(filter)
                        dispatch(setCommonPropsDetails('filterSelectedFilters', filter))
                        setState(prevState => ({ ...prevState, showAdvancedFilters: false }))
                    }}
                    filterState={state}
                    closeSidePanel={() => setState(prevState => ({...prevState, showAdvancedFilters: false }))}
                />
            : null}
            <div className='row text-white mx-0 pb-2 bg-nero'>
                <div className='col-lg-2 col-md-12 col-sm-12 py-2 align-self-center'>
                    <h6 className='text-white m-0'>Job Status</h6>
                    <p className='text-white m-0'>Consolidated view of job status</p>
                </div>
                <div className='col-lg-10 col-md-12 col-sm-12 py-2' ref={clickOutside}>
                    <div className='row mx-0 topBasicFilter justify-content-end'>
                        <FilterSection 
                            filters={state.topSectionFilters}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={true}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                            screen="job_status"
                        />
                    </div>
                </div>
            </div>
            <div className='row mx-0'>
                <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center'>
                    <div className='py-1 align-self-center'>
                        {state.successFailureDonutData && Object.entries(state.successFailureDonutData).length ?
                            <div className='ml-5'>
                                <ApexDonutChart
                                    graphData={state.successFailureDonutData}
                                    legend={false}
                                    showTotal={true}
                                    showTotalLable={true}
                                    totalLableType={'integer'}
                                    showGraphHeading={false}
                                    height={75}
                                    width={75}
                                    legendWidth={200}
                                    size={'70%'}
                                    gradient={false}
                                    gradientColor={['#A88CCC', '#CD5A9F']}
                                    formatedColors={{'failed': '#CD5A9F', 'success': '#009FDF'}}
                                    colors={['#8e3a80', '#CD5A9F']}
                                    className={'transparentTooltip'}
                                />
                            </div>
                        : null}
                        {/* <div className='small text-center text-black pb-1'>
                            <p className='jobSuccessRate' style={{ }}>
                                Success Rate: <span>{state.totalSuccessRate ? state.totalSuccessRate : 0}%</span>
                            </p>
                            <p className='jobFailureRate'>
                                Failure Rate: <span>{state.totalFailureRate ? state.totalFailureRate : 0}%</span>
                            </p>
                        </div> */}
                    </div>
                    <div className='align-self-center'>
                        <ul className="stock-legend m-0 pl-2" style={{maxWidth: "140px"}}>
                            <li className="w-100"><span className="legend-circle bg-cyon"></span> <span className="text-cyon d-inline-block"> &nbsp;Success: {state.totalSuccessRate && state.totalSuccessRate}%</span></li> 
                            <li className="w-100"><span className="legend-circle bg-mulbery"></span> <span className="text-mulbery d-inline-block"> &nbsp;Failed: {state.totalFailureRate && state.totalFailureRate}%</span></li>
                        </ul>
                        {/* <small className='w-100'>Success: {state.totalSuccessRate ? state.totalSuccessRate : 0}%</small>
                        <small className='w-100'>Failed: {state.totalFailureRate ? state.totalFailureRate : 0}%</small> */}
                    </div>
                    {state.statusSection && state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total ? 
                        <div className='d-flex justify-content-start pl-3'>
                            {Object.entries(state.statusSection.trend[0].total).map(([key, value], index) => {
                                return(
                                    <div key={'key__'+index}>
                                        {convertToLowerCase(key) === "completed" || convertToLowerCase(key) === "finished" ?
                                            <div className={`dash-box bg-transparent border-cyon ${state.selectedStatus === key ? 'bg-cyon text-white' : 'text-cyon'} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', callListJobDetailsFunction: true }))}>
                                                <p className='mb-0'>{numberFormater(value)}</p>
                                                <small>{capitalizeFirstLetter(key.toLowerCase())}</small>
                                            </div>

                                        :
                                            <div className={`dash-box bg-transparent border-mulbery  ${state.selectedStatus === key ? 'bg-mulbery text-white' : 'text-mulbery'} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', callListJobDetailsFunction: true }))}>
                                                <p className='mb-0'>{numberFormater(value)}</p>
                                                <small>{capitalizeFirstLetter(key.toLowerCase())}</small>
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    : null}
                </div>                    
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end'>
                {state.selectedDuration !== 'custom' && state.selectedDuration !== 'lastMonth' ?
                    <p className="mb-0 align-self-center text-white">Last refreshed at <span className='text-info'>{momentDateGivenFormat(new Date(), "HH:mm")}</span> <span className='text-info'>(local time)</span></p>
                : null}
            </div>
            <div className='row mx-0'>
                <div className='col-lg-6 pb-2'>
                    <div className='card p-3 h-100'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <div className='text-center'>
                                    <div className='date-box-sm block1' id='statusView_total'>
                                        <p className='mb-0'>{numberFormater(state.statusCount ? state.statusCount : 0)}</p>
                                    </div>
                                    <UncontrolledTooltip target='statusView_total'>Total: {state.statusCount ? state.statusCount : 0}</UncontrolledTooltip>
                                    <small>Overall</small>
                                </div>
                                <div className='text-center' id='statusView_monthly'>
                                    <div className='date-box-sm block1'>
                                        <p className='mb-0'>{numberFormater(state.statusMonthlyCount ? state.statusMonthlyCount : 0)}</p>
                                    </div>
                                    <UncontrolledTooltip target='statusView_monthly'>Current Month: {state.statusMonthlyCount ? state.statusMonthlyCount : 0}</UncontrolledTooltip>
                                    <small className='d-flex justify-content-center' >Current</small>
                                    <small>Month</small>
                                </div>
                                <div className='text-center align-self-end' id='statusView_weekly'>
                                    <div className='date-box-sm block1'>
                                        <p className='mb-0'>{numberFormater(state.statusWeeklyCount ? state.statusWeeklyCount : 0)}</p>
                                    </div>
                                    <UncontrolledTooltip target='statusView_weekly'>Current Week: {state.statusWeeklyCount ? state.statusWeeklyCount : 0}</UncontrolledTooltip>
                                    <small className='d-flex justify-content-center' >Current</small>
                                    <small>Week</small>
                                </div>
                                <div className='text-center' id='statusView_daily'>
                                    <div className='date-box-sm block1'>
                                        <p className='mb-0'>{numberFormater(state.statusDailyCount ? state.statusDailyCount : 0)}</p>
                                    </div>
                                    <UncontrolledTooltip target='statusView_daily'>Today: {state.statusDailyCount ? state.statusDailyCount : 0}</UncontrolledTooltip>
                                    <small>Today</small>
                                </div>
                            </div>
                            {state.statusDonutData && Object.entries(state.statusDonutData).length ?
                                <div className='ml-5'>
                                    <ApexDonutChart
                                        graphData={state.statusDonutData}
                                        legend={false}
                                        showTotal={true}
                                        showTotalLable={true}
                                        totalLableType={'integer'}
                                        showGraphHeading={false}
                                        height={75}
                                        width={75}
                                        legendWidth={200}
                                        size={'70%'}
                                        gradient={false}
                                        gradientColor={['#A88CCC', '#D88ACF']}
                                        // colors={['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']}
                                        formatedColors={{'cancelled': '#8e3a80', 'completed': '#43B02A', 'failed': '#c6007e', 'stopped':  '#CD5A9F', 'suspended': '#693EBC', 'timeout': '#775BA2'}}
                                        // formatedColors={{'cancelled': '#FFCD00', 'completed': '#43B02A', 'failed': '#D2291C', 'stopped':  '#ff8200', 'suspended': '#039BE5', 'timeout': '#df5667'}}
                                        colors={['#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80']}
                                        className={'transparentTooltip'}
                                    />
                                </div>
                            : null}
                        </div>
                        {state.statusTrendData && Object.entries(state.statusTrendData).length ?
                            <div className='mt-n5 mb-n3'>
                                <ApexStackedBarChart 
                                    graphData={state.statusTrendData ? state.statusTrendData : {}}
                                    sparkline={false}
                                    yaxis={false}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={'date'}
                                    xaxisLabel={true}
                                    axisLabelColor={'#B8BBBE'}
                                    paddingLeft={0}
                                    legend={true}
                                    stacked={true}
                                    height={300}
                                    horizontal={false}
                                    barHeight={'40%'}
                                    barEndShape={'flat'}
                                    columnWidth={'25%'}
                                    formatedColors={{'cancelled': '#8e3a80', 'completed': '#43B02A', 'failed': '#c6007e', 'stopped':  '#CD5A9F', 'suspended': '#693EBC', 'timeout': '#775BA2'}}
                                    colors={['#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80']}
                                    gradient={true}
                                    gradientColor={['#039BE5', '#5F5BA2']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'flat'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={'transparentTooltip'}
                                    showTooltipTotal={true}
                                />
                            </div>
                        : null}
                    </div>
                </div>
                <div className='col-lg-6 pb-2 pl-0'>
                    <div className='card p-3 h-100'>
                        <div className='d-flex justify-content-between'>
                            <div className='text-white'>
                                <p className='mb-0'>Top 5 Jobs</p>
                                <small>Consolidated view of job status</small>
                            </div>
                            <div className='d-flex'>
                                <select className='form-control bg-transparent border-lightGray select1 text-lightGray mr-3' style={{minWidth: '100px', maxWidth: '100px'}} id='mySelect' value={state.selectedGraphType ? state.selectedGraphType : 'Select'}
                                onChange={(e) => setState(prevState => ({ 
                                    ...prevState,
                                    selectedGraphType: e.target.value,
                                    callGetJobTobStatusApisFunction: true
                                }))}>
                                    {!state.selectedGraphType ?
                                        <option>Select</option>
                                    : null}
                                    {state.graphType && state.graphType.map(item => {
                                        return(
                                            <option
                                                key={'type_'+item.value} 
                                                value={item.value}
                                                className={`${state.selectedGraphType === item.value ? 'selected' :  ''}`}
                                            >{item.label}</option>
                                        )
                                    })}
                                </select>
                                
                                <select className='form-control bg-transparent border-lightGray select1 text-lightGray' style={{minWidth: '100px', maxWidth: '100px'}} id='mySelect' value={state.selectedTopStatusFilter ? state.selectedTopStatusFilter : 'Select'}
                                onChange={(e) => setState(prevState => ({ 
                                    ...prevState,
                                    selectedTopStatusFilter: e.target.value, 
                                    showTopStatusFilter: false,
                                    topStatusData: {},
                                    callTopStatusSection: true
                                }))}>
                                    {!state.selectedTopStatusFilter ?
                                        <option>Select</option>
                                    : null}
                                    {state.topStatusType && state.topStatusType.map(item => {
                                        return(
                                            <option
                                                key={'opt_'+item} 
                                                value={item}
                                                className={`${state.selectedTopStatusFilter === item ? 'selected' :  ''}`}
                                            >{item}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>
                        <small className='mt-1 mb-0 small text-primary-color'>View top 5 hpc jobs by selection - {state.selectedTopStatusFilter}</small>
                        {state.topStatusData && Object.entries(state.topStatusData).length ?
                            <div className='mb-n4'> 
                                <ApexBarChart
                                    graphData={state.topStatusData}
                                    screen={'job_status'}
                                    sparkline={false}
                                    yaxis={true}
                                    yaxisLabel={true}
                                    xaxis={true}
                                    xaxisFormat={'string'}
                                    xaxisLabel={true}
                                    axisLabelColor={'#999999'}
                                    paddingLeft={0}
                                    legend={false}
                                    stacked={false}
                                    height={275}
                                    horizontal={true}
                                    barHeight={'40%'}
                                    barEndShape={'rounded'}
                                    barRadius={8}
                                    columnWidth={'25%'}
                                    showTitle={true}
                                    //'#FFCD00', '#ff8200', '#d2291c', '#43b02a', '#0057b8', '#8e3a80'
                                    gradient={true}
                                    gradientColor={['#0057b8', '#8e3a80']}
                                    hideTooltipValue={true}
                                    // backgroundBarShape={'F'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={'transparentTooltip'}
                                    dataLabels={true}
                                    dataLabelsTextAnchor={'middle'}
                                    dataLabelPosition={''}
                                />
                            </div>
                        : null}
                    </div>
                </div>
            </div>
            <div className='row mx-0'>
                <div className='col-sm-12'>
                    <div className='bg-white mt-2 mb-3'>
                        <div className='d-flex justify-content-between px-3 pt-2'>
                            <div className='text-black'>
                                <div className='d-flex'>
                                    <p className='mb-0'>Jobs List</p>
                                    <span className='ml-3 far fa-redo f12 cursorPointer text-info align-self-center' onClick={() => setState(prevState => ({ ...prevState, jobDetails: [], filteredJobDetails: [], nextToken: [], startRecord: 0, currentPage: 1, paginationIndex: 0, callListJobDetailsRefresh: true }))}> <span className='ml-2'>refresh </span></span>
                                </div>
                                <p className='mb-0 text-dark'>
                                    <small>Showing {state.filteredJobDetails && state.filteredJobDetails.length} out of total {state.totalRecords ? state.totalRecords : 0} job(s)</small>
                                    {state.selectedFilters && state.selectedFilters.provider ?
                                        <React.Fragment>
                                        <small className='ml-1'>obtained from the</small>
                                        <small className='ml-1 text-info'>{state.selectedFilters.provider.toUpperCase()}</small>
                                        <small className='ml-1'>provider</small>
                                        </React.Fragment>
                                    : null}
                                    {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                                        <React.Fragment>
                                        <small className='ml-1'>with the account</small>
                                        <small className='badge badge-outline-info mx-1'>{state.selectedFilters.account_id[0]}</small>
                                        {state.selectedFilters.account_id.length > 1 ?
                                            <small className='ml-1 text-info'>more</small>
                                        : null}
                                        </React.Fragment>
                                    : null}
                                    {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                                        <React.Fragment>
                                        <small className='ml-1'>in the</small>
                                        <small className='badge badge-outline-info mx-1'>{state.selectedFilters.region[0]}</small>
                                        {state.selectedFilters.region.length > 1 ?
                                            <small className='ml-1 text-info'>more</small>
                                        : null}
                                        <small className='ml-1'>region</small>
                                        </React.Fragment>
                                    : null}
                                    {state.selectedFilters && state.selectedFilters.start_time ?
                                        <React.Fragment>
                                            <small className='ml-1'>for the period starting from</small>
                                            <small className='ml-1 text-info'>{momentConvertionUtcToLocalTime(state.selectedFilters.start_time, 'YYYY-MM-DD HH:mm')}</small> 
                                            <small className='ml-1'>to</small>
                                            <small className='ml-1 text-info'>{momentConvertionUtcToLocalTime(state.selectedFilters.end_time, 'YYYY-MM-DD HH:mm')}</small>
                                            <small className='ml-1'>(local time)</small>
                                        </React.Fragment>
                                    : null}
                                </p>
                            </div>
                            
                            <div className='align-self-center w-30'>
                                <div className='d-flex justify-content-end'>
                                    {state.filteredJobDetails && state.filteredJobDetails.length ?
                                        <CSVLink 
                                            data={state.filteredJobDetails ? state.filteredJobDetails : []} 
                                            headers={state.jobheaders ? state.jobheaders : []}
                                            filename={'job-lists.csv'}
                                            className={'align-self-center'}
                                            target='_blank'
                                        >
                                            <span className='d-flex text-primary-color mr-2'>
                                                <i className='fas fa-download text-primary-color mr-2'/>
                                                {/* csv */}
                                            </span>
                                        </CSVLink>
                                    : null}
                                    {state.jobDetails && state.jobDetails.length ?
                                        <div className='align-self-center mr-2 w-60'>
                                            <Search
                                                data={state.jobDetails ? state.jobDetails : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-extraLightGray text-black f12 border-extraLightGray'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-black f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={'bg-transparent text-black pl-0 form-control-sm f12 w-90'}
                                                filteredData={(filteredJobDetails) => setState(prevState => ({ ...prevState, filteredJobDetails }))}
                                            />
                                        </div>
                                    : null}
                                    <button className={`cursorPointer btn bg-green text-white align-self-center`} onClick={ () => setState(prevState => ({ ...prevState, showCreateJob: true }))}>Create&nbsp;Job</button>
                                </div>                                    
                            </div>
                        </div>

                        <div className='row mx-0'>
                            <div className='col-lg-12 col-12 px-3 py-1'>
                                {state.filteredJobDetails && state.filteredJobDetails.length ?
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: 'Status',
                                                accessor: 'Status',
                                                Cell: cellInfo => (
                                                    <div className='d-flex'>
                                                        {cellInfo.row.original.provider === 'gcp' ?
                                                            <span className={`badge badge-outline-${convertToLowerCase(cellInfo.row.original.status)}`}>{cellInfo.row.original.status}</span>
                                                        : (cellInfo.row.original.status ?
                                                            <span className={`badge badge-outline-${convertToLowerCase(cellInfo.row.original.status)}`}>{cellInfo.row.original.status}</span>
                                                        : 
                                                            <span className={`badge badge-outline-secondary`}>No Status</span>
                                                        )}
                                                        {cellInfo.row.original.error_code ?
                                                            <span className={`ml-2 text-info cursor-pointer fa fa-info-circle align-self-center`} onClick={() => setState(prevState => ({ ...prevState, errorJobDetails: cellInfo.row.original, errorModal: true }))}></span>
                                                        : null}
                                                    </div>
                                                ),
                                            },
                                            {
                                                Header: 'Job Name',
                                                accessor: 'job_name',
                                                width: 250,
                                            },
                                            {
                                                Header: 'Job Id',
                                                accessor: 'job_id',
                                                Cell: cellInfo => (<div className='d-flex'>
                                                    <span className='text-info cursorPointer' onClick={() =>  setState(prevState => ({ ...prevState, selectedJobDetails: cellInfo.row.original }))}> {cellInfo.row.original.job_id ? cellInfo.row.original.job_id : ''} </span>
                                                    {cellInfo.row.original.job_cancel_request ?
                                                        <span className={`ml-2 text-danger cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, cancelJobDetails: cellInfo.row.original, cancelModal: true }))}>Cancel</span>
                                                    : null}
                                                </div>),
                                                width: 250,
                                            },
                                            {
                                                Header: 'Queue',
                                                accessor: 'queue_name',
                                                width: 200,
                                            },
                                            {
                                                Header: 'Cluster',
                                                accessor: 'cluster_name',
                                                width: 150,
                                            },
                                            {
                                                Header: 'User',
                                                accessor: 'user_name',
                                                width: 150,
                                            },
                                            {
                                                Header: 'Elapsed time',
                                                accessor: 'execution_time',
                                                // width: 200,
                                                // Cell: cellInfo => (<span>{cellInfo.row.original.elapsed_time_in_secs ? cellInfo.row.original.elapsed_time_in_secs : ''}</span>)
                                            },
                                            {
                                                Header: 'Start Time',
                                                accessor: 'start_time',
                                                Cell: cellInfo => (<span>{cellInfo.row.original.start_time ? momentConvertionUtcToLocalTime(cellInfo.row.original.start_time, 'DD MMM YYYY HH:mm') : ''}</span>),
                                                // width: 180,
                                            },
                                            {
                                                Header: 'End Time',
                                                accessor: 'end_time',
                                                Cell: cellInfo => (<span>{cellInfo.row.original.end_time ?  momentConvertionUtcToLocalTime(cellInfo.row.original.end_time, 'DD MMM YYYY HH:mm') : ''}</span>),
                                                // width: 180,
                                            },
                                            {
                                                Header: ' ',
                                                width: 1,
                                                // accessor: 'end_time',
                                                // Cell: cellInfo => (<span className='text-info cursorPointer'>Details</span>)
                                            },
                                        ]}
                                        data={state.filteredJobDetails}
                                        totalData={state.totalRecords}
                                        perPage={10}
                                        tableHead={'bg-black text-white'}
                                        tableBody={'bg-white'}
                                        tableType={'table-light-hover'}
                                        perPageClass={`bg-transparent text-lightGray`}
                                        textColor={'text-black'}
                                        selectedColor={'bg-backGroundGray'}
                                        dashboard={state.filteredJobDetails && state.filteredJobDetails.length ? true : false}
                                        sortByColumn={''}
                                        riskTooltip={[0]}
                                        // onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedJobDetails: tableRow }))}
                                        onClickRow={tableRow => {}}
                                        // tableSize={'table-sm'}
                                        paginationIndex={state.paginationIndex ? state.paginationIndex : 0}
                                        checkForNextSetoffRecords={state.nextToken && state.nextToken.length ? true : false}
                                        callToFetchNewRecord={(paginationIndex) => {
                                            if(state.nextToken && state.nextToken.length) {
                                                setState(prevState => ({ ...prevState, callListJobDetailsFunction: true, paginationIndex }))
                                            }
                                        }}
                                        showPaginationTop={false}
                                    />
                                : 
                                    <div className='d-flex justify-content-center m-4'>
                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </div>
                                }
                            </div>
                            
                            <Modal isOpen={state.cancelModal} toggle={() => setState(prevState => ({ ...prevState, cancelModal: !state.cancelModal }))}>
                                <ModalHeader toggle={() => setState(prevState => ({ ...prevState, cancelModal: !state.cancelModal }))}>Cancel Job</ModalHeader>
                                <ModalBody>
                                    {/* Please enter the
                                    <span className='f18 mr-1'>
                                        <span className='f14 mx-1'>job id</span>
                                        {state.cancelJobDetails && state.cancelJobDetails.job_id ? state.cancelJobDetails.job_id : ''}
                                    </span> 
                                    to cancel the {state.selectedManage}
                                    <Input
                                        type='text'
                                        placeholder={'Enter '+state.selectedManage+' name'}
                                        className={`inputTextbox bg-transparent border-lightGray text-black`}
                                        // maxlength='128'
                                        value={state.name ? state.name : ''}
                                        onChange={e => setState(prevState => ({ ...prevState, name: noSpace(onlyNumber(e.target.value)) }))}
                                    />
                                    {state.deleteConfirmMessage ?
                                        <small className='text-red'>{state.deleteConfirmMessage}</small>
                                    : null} */}
                                    Are you sure you want to cancel the running job <span className='f18 mx-1 text-red'>{state.cancelJobDetails && state.cancelJobDetails.job_name ? state.cancelJobDetails.job_name : ''}<span className='ml-1 text-primary-color'>({state.cancelJobDetails && state.cancelJobDetails.job_id ? state.cancelJobDetails.job_id : ''})</span></span>

                                </ModalBody>
                                <ModalFooter className="border-0">
                                <Button color="primary" onClick={() => cancelJobFunction()}>
                                    {state.showCancelLoding ?
                                        <Spinner size='sm' color='light' className='mx-2' />
                                    : null}Ok
                                </Button>{' '}
                                <Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, cancelModal: !state.cancelModal, showCancelLoding: false, name: '' }))}>
                                    Cancel
                                </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal isOpen={state.errorModal} toggle={() => setState(prevState => ({ ...prevState, errorModal: !state.errorModal }))}>
                                <ModalHeader toggle={() => setState(prevState => ({ ...prevState, errorModal: !state.errorModal }))}>Error Details</ModalHeader>
                                <ModalBody>
                                    <div className='f16 mr-1'>
                                        <span className='f14 mx-1'>Error Code :</span>
                                        <span  className='ml-2'>{state.errorJobDetails && state.errorJobDetails.error_code ? state.errorJobDetails.error_code : ''}</span>
                                    </div>
                                    <div className='f16 mr-1'>
                                        <span className='f14 mx-1'>Error Message :</span>
                                        <span  className='ml-2'>{state.errorJobDetails && state.errorJobDetails.error_reason ? state.errorJobDetails.error_reason : ''}</span>
                                    </div>
                                </ModalBody>
                                <ModalFooter className="border-0">
                                    <Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, errorModal: !state.errorModal }))}>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard    