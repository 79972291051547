/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file UserDetails.js
 * @author Prakash // on 27/11/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listUserDetails } from '../../../actions/commonActionNew'
import ApexLineChartSpendBoard from '../../common/charts/ApexLineChartSpendBoard';
import DetailsSidePanel from './DetailsSidePanel';
import { momentConvertionUtcToLocalTime, momentDateGivenFormat, removeUnderScore } from '../../../utils/utility';
import { Spinner } from 'reactstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';

const UserDetails = (props) => {
    const [state, setState] = useState({
        showLoading: true,

        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 5,

        userDetails: [],
        selectedUserDetails: {},
        filteredUserDetails: [],
        callSearch: true,
        periodOptions: [
            {label: "Daily", value: "daily"},
            {label: "Weekly", value: "weekly"},
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"}
		],
        graphOrderColors: [['#854097'],['#693EBC'],['#775BA2'], ['#9A77D1']],
        // order: ['jobs_executed', 'cumulative_CPU_cores', 'compute_cost', 'concurrent_CPU_cores']
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
  
    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            let params = {}
            params.provider = props.selectedProvider.toLowerCase()
            params.reports = true
            params.start_time = props.startDate
            params.end_time = props.endDate
            if(props.selectedAccount && props.selectedAccount.length) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length) {
                params.region = props.selectedRegion
            }
            if(props.selectedClusters && props.selectedClusters.length) {
                params.cluster_name = props.selectedClusters
            }
            
            if(props.selectedUser && props.selectedUser.length) {
                params.user_name = props.selectedUser
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            params.size = 5

            dispatch(listUserDetails(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    let totalResults = []
                    let startRecord = state.startRecord
                    let currentPage = state.currentPage 
                    if(state.nextToken && state.nextToken.length) {
                        totalResults = state.userDetails &&  state.userDetails.length ? state.userDetails : []
                        startRecord = startRecord + state.perPage;
                        currentPage = currentPage+ 1
                    }

                    if(results && results.length) {
                        totalResults = totalResults.concat(results)
                    }

                    let totalPages = 1
                    if(totalResults.length > state.perPage) {
                        totalPages = Math.ceil(totalResults.length / state.perPage)
                    }
                        
                        setState(prevState => ({ ...prevState, userResponse: response, userDetails: totalResults, filteredUserDetails: totalResults, detailsFormat: response && response.user_details ? response.user_details : [], showLoading: false, callConcurrentCpuSection: false, callTrendSection: true, calltoGetTrendTableDetails: true, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));

                        props.callBack()
                    }
            })
        }
    }, [state.callSearch, dispatch, state.perPage, props, state.userDetails, state.nextToken, state.startRecord, state.currentPage])

    useEffect(() => {
        if(state.callConcurrentCpuSection) {
            setState(prevState => ({ ...prevState, callConcurrentCpuSection: false }))

            state.filteredUserDetails.forEach((item, index) => {
                let graphDetails = item.concurrent_CPU_cores ? item.concurrent_CPU_cores : []
                let graphData = {}
                graphData.labels = graphDetails.dates ? graphDetails.dates : []
                graphData.concurrent_CPU_cores = graphDetails.data ? graphDetails.data : []
                    
                setState(prevState => ({ ...prevState, ["concurrent_CPU_cores_"+index]: graphData, ['selected_Period_'+index]: 'daily' }))
            })
        }
    }, [state.callConcurrentCpuSection, state.filteredUserDetails])

    useEffect(() => {
        if(state.callTrendSection) {
            
            setState(prevState => ({ ...prevState, callTrendSection: false }))

            state.filteredUserDetails.forEach((item, index) => {
                if(item.trend) {
                    let trendDetails = item.trend
                    Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([trKey, trValue]) => { 
                        let graphData = {}
                        if(trValue.dates) {
                            graphData.labels = trValue.dates ? trValue.dates : []    
                        } else {
                            graphData.labels = item.dates ? item.dates : []
                        }
                        graphData[trKey] = trValue.data
                        setState(prevState => ({ ...prevState, [trKey+'_'+index]: graphData }))
                    })
                }
                // if(item.trend) {
                //     let trendDetails = item.trend
                    
                //     Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([key, value]) => {
                //         let graphData = {}
                //         graphData.labels = item.dates ? item.dates : []
                //         graphData[key] = value
                //         setState(prevState => ({ ...prevState, [key+'_'+index]: graphData }))
                //     })
                // }
            })
        }
    }, [state.callTrendSection, state.filteredUserDetails])

    useEffect(() => {
        if(state.calltoGetTrendTableDetails) {
            setState(prevState => ({ ...prevState, calltoGetTrendTableDetails: false })) 
            
            if(state.filteredUserDetails && state.filteredUserDetails.length) {
                state.filteredUserDetails.forEach((item, index) => {
                    if(item.reports && item.reports.length) {
                        let columns = []
                        let dataRow = {}
                        dataRow.Header = 'Date'
                        dataRow.Cell = cellInfo => (
                            <React.Fragment>
                                <span>{cellInfo.row.original.date ? momentConvertionUtcToLocalTime(cellInfo.row.original.date, (item.monthly_breakup ? "MMM YY" : "DD MMM YY")) : ""}</span>
                            </React.Fragment>
                        )
                        dataRow.width = 180
                        columns.push(dataRow)
                        item.order && item.order.forEach(ord => {
                            dataRow = {}
                            dataRow.Header = removeUnderScore(ord)
                            dataRow.accessor = ord
                            dataRow.width = 220
                            columns.push(dataRow)
                        })

                        setState(prevState => ({ ...prevState, ["reports_"+index]: item.reports, ['filteredReports_'+index]: item.reports, ['report_columns_'+index]: columns }))
                    } else {
                        setState(prevState => ({ ...prevState, ["reports_"+index]: [], ['filteredReports_'+index]: [], ['report_columns_'+index]: [] }))
                    }
                })
            }
        }
    }, [state.calltoGetTrendTableDetails, state])

    const onChangePeriod = (period, selectedPeriodIndex, item) => {
        setState(prevState => ({ 
            ...prevState, 
            ["concurrent_CPU_cores_"+selectedPeriodIndex]: {},
            ["reports_"+selectedPeriodIndex]: [],
            ['filteredReports_'+selectedPeriodIndex]: []
        }))
        
        state.filteredUserDetails.forEach((item, index) => {
            if(item.trend && index === selectedPeriodIndex) {
                let trendDetails = item.trend
                Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([key, value]) => {
                    setState(prevState => ({ ...prevState, [key+'_'+index]: {} }))
                })
            }
        })

        let params = {}
        params.provider = props.selectedProvider.toLowerCase()
        params.start_time = props.startDate
        params.end_time = props.endDate
        params.reports = true
        if(props.selectedAccount && props.selectedAccount.length) {
            params.account_id = props.selectedAccount
        }
        if(props.selectedRegion && props.selectedRegion.length) {
            params.region = props.selectedRegion
        }
        if(props.selectedClusters && props.selectedClusters.length) {
            params.cluster_name = props.selectedClusters
        }
        if(period) {
            params.duration_aggregate_by = period
        }
        if(item.userId) {
            params.requested_user_id = item.userId
        }

        dispatch(listUserDetails(params))
            .then((response) => {
                if(response) {
                    let results = response && response.results && response.results.length ? response.results : []
                    setState(prevState => ({
                        ...prevState,
                        selectedUserDetails: results,
                        selectedFilteredUserDetails: results,
                        // callSelectedConcurrentCpuSection: true,
                        callSelectedTrenSection: true,
                        selectedPeriodIndex,
                        ['showLoading_'+selectedPeriodIndex]: false
                    }));
                }
        })
    }

    useEffect(() => {
        if(state.callSelectedConcurrentCpuSection) {
            setState(prevState => ({ ...prevState, callSelectedConcurrentCpuSection: false }))

            state.selectedFilteredUserDetails.forEach((item, index) => {
                let graphDetails = item.concurrent_CPU_cores ? item.concurrent_CPU_cores : []
                let graphData = {}
                graphData.labels = graphDetails.dates ? graphDetails.dates : []
                graphData.concurrent_CPU_cores = graphDetails.data ? graphDetails.data : []
                    
                setState(prevState => ({ ...prevState, ["concurrent_CPU_cores_"+state.selectedPeriodIndex]: graphData }))
            })
        }
    }, [state.callSelectedConcurrentCpuSection, state.selectedFilteredUserDetails, state.selectedPeriodIndex])

    useEffect(() => {
        if(state.callSelectedTrenSection) {            
            setState(prevState => ({ ...prevState, callTrendSection: false }))

            state.selectedFilteredUserDetails.forEach(item => {
                if(item.trend) {
                    let trendDetails = item.trend
                    Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([trKey, trValue]) => { 
                        let graphData = {}
                        if(trValue.dates) {
                            graphData.labels = trValue.dates ? trValue.dates : []    
                        } else {
                            graphData.labels = item.dates ? item.dates : []
                        }
                        graphData[trKey] = trValue.data
                        setState(prevState => ({ ...prevState, [trKey+'_'+state.selectedPeriodIndex]: graphData }))
                    })
                }
                // if(item.trend) {
                //     let trendDetails = item.trend
                //     Object.entries(trendDetails).length && Object.entries(trendDetails).forEach(([key, value]) => {
                //         let graphData = {}
                //         graphData.labels = item.dates ? item.dates : []
                //         graphData[key] = value

                //         setState(prevState => ({ ...prevState, [key+'_'+state.selectedPeriodIndex]: graphData }))
                //     })
                    
                // }
            })
        }
    }, [state.callSelectedTrenSection, state.selectedFilteredUserDetails, state.selectedPeriodIndex])

    useEffect(() => {
        if(state.calltoGetSelectedTrendTableDetails) {
            setState(prevState => ({ ...prevState, calltoGetSelectedTrendTableDetails: false }))
            
            if(state.selectedFilteredUserDetails && state.selectedFilteredUserDetails.length) {
                state.selectedFilteredUserDetails.forEach((item, index) => {
                    let reports= []

                    let labels = item.dates ? item.dates : []
                    labels.forEach((lab, labIndex) =>{ 
                        let reportRow = {}
                        reportRow.timestamp = lab
                        Object.entries(item.trend).forEach(([key, value]) => {
                            reportRow[key] = value[labIndex] ? value[labIndex] : 0
                        })
                        reports.push(reportRow)
                    })

                    if(reports.length) {
                        const reportColumns = Object.entries(reports[0]).map(([tKey, tValue]) =>{
                            let header = ''
                            let width = '100%'
                            let textColor = ''
                            let className = 'text-truncate'
                            let accessor = ''
                            let Cell = ''
                            if(tKey === 'timestamp') {
                                header = 'Date'
                                width = 180
                                accessor = tKey
                                Cell = cellInfo => (<span>{cellInfo.row.original.timestamp ? momentDateGivenFormat(cellInfo.row.original.timestamp, 'DD MMM YYYY')  : ''}</span>)
                            } else {
                                header = removeUnderScore(tKey)
                                width = 200
                                className = 'text-truncate'
                                accessor = tKey
                                Cell = cellInfo => cellInfo.row.original[tKey]
                            }
                            return {
                                Header: header,
                                accessor: accessor,
                                width: width,
                                Cell: Cell,
                                textColor: textColor,
                                className: className
                            }
                        })
                        
                        setState(prevState => ({ ...prevState, ["reports_"+state.selectedPeriodIndex]: reports, ['filteredReports_'+state.selectedPeriodIndex]: reports, ['report_columns_'+state.selectedPeriodIndex]: reportColumns }))
                    } else {
                        const reportColumns = []	
                        setState(prevState => ({ ...prevState, ['reports_'+state.selectedPeriodIndex]: reports, ['filteredReports_'+state.selectedPeriodIndex]: reports, ['report_columns_'+state.selectedPeriodIndex]: reportColumns }))
                    }
                })
            }
        }
    }, [state.calltoGetSelectedTrendTableDetails, state])

    const handleClickOutside = (event) => {}

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callSearch: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const gotoPage = (pageNumber) => {
        // Go to the specified page and update state        
        if(pageNumber > 0  && pageNumber <= state.totalPages) {
            let startRecord = (pageNumber - 1) * state.perPage
            setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
        } else  if(pageNumber > state.totalPages) {
            setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
        } else {
            setState(prevState => ({ ...prevState, pageNumber }))
        }
    }

    const downloadPDF = async (index, item) => {
        const chartContainer1 = document.getElementById('chartContainer_'+index+'_0');
        const chartContainer2 = document.getElementById('chartContainer_'+index+'_2');
        const chartContainer3 = document.getElementById('chartContainer_'+index+'_2');
        const chartContainer4 = document.getElementById('chartContainer_'+index+'_3');
        const chartContainer5 = document.getElementById('chartContainer_'+index+'_4');
        const chartContainer6 = document.getElementById('chartContainer_'+index+'_5');
        // const chartContainer2 = document.getElementById('chartContainer2');
    
        if (!chartContainer1) {
          console.error("Chart container not found.");
          return;
        }
    
        try {
            const canvas1 = await html2canvas(chartContainer1);
            const canvas2 = await html2canvas(chartContainer2);
            const canvas3 = await html2canvas(chartContainer3);
            const canvas4 = await html2canvas(chartContainer4);
            const canvas5 = await html2canvas(chartContainer5);
            const canvas6 = await html2canvas(chartContainer6);
            const pdf = new jsPDF();
            
            let selectedPeriod = state.periodOptions.filter(e => e.value === state['selected_Period_' + index]).length ? state.periodOptions.filter(e => e.value === state['selected_Period_' + index])[0].label : state['selected_Period_' + index]

            pdf.text(`Report for the user ${item.userName}`, 10, 10) 
            pdf.text(`spanning from ${momentDateGivenFormat(props.startDate, 'DD MMM YYYY')} to ${momentDateGivenFormat(props.endDate, 'DD MMM YYYY')}`, 10, 20)
            // pdf.text(`Report for User - ${item.userName}`, 15, 10);
            // pdf.text(`Period from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            // pdf.text(`Frequency from - ${momentConvertionUtcToLocalTime(props.startDate, 'DD MMM YYYY') + ' to '+momentConvertionUtcToLocalTime(props.endDate, 'DD MMM YYYY') }`, 15, 10);
            pdf.addImage(canvas1.toDataURL('image/png'), 'PNG', 10, 30, 100, 60);
            pdf.addImage(canvas2.toDataURL('image/png'), 'PNG', 110, 30, 100, 60);
            pdf.addImage(canvas3.toDataURL('image/png'), 'PNG', 10, 100, 100, 60);
            pdf.addImage(canvas4.toDataURL('image/png'), 'PNG', 110, 100, 100, 60);
            pdf.addImage(canvas5.toDataURL('image/png'), 'PNG', 10, 170, 100, 60);
            pdf.addImage(canvas6.toDataURL('image/png'), 'PNG', 110, 170, 100, 60);
        
            const timestamp = new Date().getTime();
            pdf.save(`user_${timestamp}.pdf`);

            setState(prevState => ({ ...prevState, downloading: false }))

        } catch (error) {
          console.error("Error capturing charts:", error);
          setState(prevState => ({ ...prevState, downloading: false }))
        }
    };

    return (
        <div className='bg-transparent' onClick={handleClickOutside}>
            <div className={`loaderOverlay ${(state.showLoading || state.downloading) ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                </div>
            </div>
            {state.showSidePanel ? 
                <DetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showSidePanel: false, selectedDetails: {}, selectedType: '' }))}                    
                    selectedProvider={props.selectedProvider}
                    selectedAccount={props.selectedAccount}
                    selectedRegion={props.selectedRegion}
                    startDate={props.startDate}
                    endDate={props.endDate}
                    selectedDetails={state.selectedDetails}
                    selectedType={state.selectedType}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            <div className='overflowYAuto'>
                <div className='d-flex justify-content-between'>
                    <p className='mb-0 align-self-center'>
                        <small>Showing {state.filteredUserDetails && state.filteredUserDetails.length} out of total {state.totalRecords} user(s)</small>
                    </p>
                
                    {state.totalRecords > state.perPage ?
                        <div className='d-flex justify-content-end'>
                            <div className='pagination errorPagePagination f12 mt-2'>
                                <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                <span className='displayNone'>
                                    Go to
                                    <input
                                        type='number'
                                        value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) : ''
                                            gotoPage(page)
                                        }}
                                        className='ml-1 inputClass'
                                    />
                                </span>
                                <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button>
                                <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                            </div>
                        </div>
                    : null}
                </div>
                {state.filteredUserDetails && state.filteredUserDetails.length ?
                    state.filteredUserDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, index) => {
                        return(
                            <div className='bg-dark3 mb-2 pb-2' key={"row_"+(state.startRecord + index)}>
                                <div className='d-flex'>
                                    {!state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                        <React.Fragment>
                                        <div className="col-lg-3 col-md-3 col-sm-3 pr-0">
                                            <div className='py-2'>
                                                <div className="d-flex">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Name</p>
                                                        <p className="mb-0 text-white">
                                                            { item.userName ? item.userName : ''}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="d-flex pt-2">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Id</p>
                                                        <p className="mb-0 text-white">{item.userId ? item.userId : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex pt-2">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">Organization</p>
                                                        <p className="mb-0 text-white">{item.organization ? item.organization : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {state['showLoading_'+(state.startRecord + index)] ?
                                            <div className="col-lg-9 col-md-9 col-sm-9 pr-0 cursorPointer">
                                                <div className='d-flex justify-content-center mt-4 align-self-center'>
                                                    <Spinner className='text-center' color='light'   />
                                                </div>
                                            </div>
                                        :   
                                            <React.Fragment>
                                            {item.trend && Object.entries(item.trend).length ? 
                                                item.order && item.order.slice(0,4).map((key, keyIndex) => {
                                                    return(
                                                        state[key+'_'+(state.startRecord + index)] && Object.entries(state[key+'_'+(state.startRecord + index)]).length ?
                                                            <div className="col-lg-2 col-md-2 col-sm-2 pr-0 cursorPointer">
                                                                <div className='d-flex'>
                                                                    <div className='mt-n2 mb-n2 mr-4 align-self-center'> 
                                                                        <ApexLineChartSpendBoard
                                                                            graphData={state[key+'_'+(state.startRecord + index)]}
                                                                            sparkline={false}
                                                                            yaxis={true}
                                                                            yaxisLabel={true}
                                                                            xaxis={true}
                                                                            xaxisFormat={'datetime'}
                                                                            xaxisTitle={item.title && item.title[key] ? item.title[key] : key}
                                                                            xaxisLabel={true}
                                                                            axisLabelColor={'#666666'}
                                                                            paddingLeft={10}
                                                                            legend={false}
                                                                            stacked={false}
                                                                            height={200}
                                                                            customHpcDashboardTooltip={true}
                                                                            className={'transparentTooltip'}
                                                                            // colors={['#43b02a', '#009FDF']}
                                                                            colors={state.graphOrderColors[keyIndex]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        : null
                                                    )
                                                })
                                            : 
                                                <div className="col-lg-6 col-md-6 col-sm-6 pr-0 cursorPointer">&nbsp;</div>
                                            }
                                            
                                            <div className="col-lg-1 col-md-1 col-sm-1 pr-0 align-self-center">
                                                <div className={`transparentSelectBorderBottom mx-2 w-70 mb-4 d-flex cursor-pointer`}>
                                                    <select className="form-control" 
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            setState((prevState) => ({
                                                                ...prevState,
                                                                ['selected_Period_' + (state.startRecord + index)]: selectedValue,
                                                                ['showLoading_'+(state.startRecord + index)]: true
                                                            }));
                                                            onChangePeriod(selectedValue, (state.startRecord + index), item);

                                                        }}
                                                        value={state['selected_Period_'+(state.startRecord + index)]}
                                                    >
                                                        {state.periodOptions && state.periodOptions.map(item => {
                                                            return(
                                                                <option
                                                                    key={'opt_'+item.value} 
                                                                    value={item.value}
                                                                >{item.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <p className='mb-0 d-flex justify-content-center text-cyon cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'User', showSidePanel: true }))}>Details</p>
                                                {!state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                                    <p className='fa fa-expand-wide text-cyon d-flex justify-content-center mt-3 cursor-pointer' 
                                                        onClick={() => {
                                                            setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: true }))
                                                        }} 
                                                    />
                                                :
                                                    <p className='fa fa-compress text-cyon d-flex justify-content-center mt-3 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: false }))} />
                                                }
                                            </div>
                                            </React.Fragment>
                                        }
                                        </React.Fragment>
                                    : 
                                        <div className="col-lg-12 col-md-12 col-sm-12 pr-0">
                                            <div className='d-flex justify-content-between py-2'>
                                                <div className="d-flex justify-content-between w-60">
                                                    <div className="d-flex">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Name</p>
                                                            <p className="mb-0 text-white">
                                                                { item.userName ? item.userName : ''}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex ml-4">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Id</p>
                                                            <p className="mb-0 text-white">{item.userId ? item.userId : ''}</p>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex ml-4">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Organization</p>
                                                            <p className="mb-0 text-white">{item.organization ? item.organization : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-end align-self-center mr-4'>
                                                    <div className={`transparentSelectBorderBottom mx-2 w-50 d-flex`}>
                                                        <select className="form-control" 
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                setState((prevState) => ({
                                                                    ...prevState,
                                                                    ['selected_Period_' + (state.startRecord + index)]: selectedValue,
                                                                    ['showLoading_'+(state.startRecord + index)]: true
                                                                }));
                                                                onChangePeriod(selectedValue, (state.startRecord + index), item);

                                                            }}
                                                            value={state['selected_Period_'+(state.startRecord + index)]}
                                                        >
                                                            {state.periodOptions && state.periodOptions.map(item => {
                                                                return(
                                                                    <option
                                                                        key={'opt_'+item.value} 
                                                                        value={item.value}
                                                                    >{item.label}</option>
                                                                )
                                                            })}
                                                        </select>
                                                    </div>
                                                    <p className='mb-0 ml-4 text-cyon align-self-center' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: item, selectedType: 'User', showSidePanel: true }))}>Details</p>
                                                    <p className='fa fa-compress text-cyon ml-4 mb-0 align-self-center cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, ['showExpandedSectoin_'+(state.startRecord + index)]: false }))} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {state['showExpandedSectoin_'+(state.startRecord + index)] ?
                                    state['showLoading_'+(state.startRecord + index)] ?
                                        <div className='bg-dark px-2 py-3 mx-3 mt-2'>
                                            <div className='d-flex justify-content-center m-4'>
                                                <Spinner className='text-center' color='light'   />
                                            </div>
                                        </div>
                                    :
                                        <div className='bg-dark p-3 mx-3 mt-2'>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pr-0">
                                                <div className='d-flex justify-content-end'>
                                                    <p className='text-info mb-1 cursor-pointer' onClick={() => {
                                                        setState(prevState => ({ ...prevState, downloading: true })) 
                                                        downloadPDF((state.startRecord + index), item)
                                                    }}>Download PDF</p>
                                                </div>
                                                <div className='row'>
                                                    {item.trend && Object.entries(item.trend).length ? 
                                                        item.order && item.order.map((key, keyIndex) => {
                                                            return(
                                                                state[key+'_'+(state.startRecord + index)] && Object.entries(state[key+'_'+(state.startRecord + index)]).length ?
                                                                    <div className="col-lg-6 col-md-6 col-sm-6 pl-0 mb-3" id={'chartContainer_'+(state.startRecord + index)+'_'+keyIndex}>
                                                                        <div className='bg-dark3 p-3 rounded'>
                                                                            <p className='mb-2 text-white f16'>
                                                                                {item.title && item.title[key] ? item.title[key] : removeUnderScore(key)}
                                                                                {item.total && item.total[key] && (item.total[key].total || item.total[key].total === 0) ?
                                                                                    <span className='ml-4 text-primary-color'><span className='text-primary-color'>Total</span> {item.total[key].total} </span>
                                                                                : null}
                                                                                {key === "avg_cumulative_CPU_hours" && item.total && item.total[key] && (item.total[key].avg || item.total[key].avg === 0) ?
                                                                                    <span className='ml-2 text-primary-color'><span className='text-primary-color'>Avg </span>{item.total[key].avg}</span>
                                                                                : null}
                                                                                {item.total && item.total[key] && (item.total[key].max || item.total[key].max === 0) ?
                                                                                    <span className='ml-2 text-primary-color'><span className='text-primary-color'>Max </span>{item.total[key].max}</span>
                                                                                : null}
                                                                            </p>
                                                                            <div className='mt-n2 mr-4 align-self-center'> 
                                                                                <ApexLineChartSpendBoard
                                                                                    graphData={state[key+'_'+(state.startRecord + index)]}
                                                                                    sparkline={false}
                                                                                    yaxis={true}
                                                                                    yaxisLabel={true}
                                                                                    yaxisTitle={item.labels && item.labels[key] ? item.labels[key] : ''}
                                                                                    xaxis={true}
                                                                                    xaxisFormat={'datetime'}
                                                                                    xaxisLabel={true}
                                                                                    axisLabelColor={'#666666'}
                                                                                    paddingLeft={10}
                                                                                    legend={false}
                                                                                    stacked={false}
                                                                                    height={300}
                                                                                    customHpcDashboardTooltip={true}
                                                                                    className={'transparentTooltip'}
                                                                                    // colors={['#43b02a', '#009FDF']}
                                                                                    colors={state.graphOrderColors[keyIndex]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                : null
                                                            )
                                                        } )
                                                    : null}
                                                </div>
                                            </div>

                                            {state['reports_'+(state.startRecord + index)] && state['reports_'+(state.startRecord + index)].length ?
                                                <div className="col-lg-12 col-md-12 col-sm-12 pr-0 mt-3">
                                                    <div className='' id={'table_'+(state.startRecord + index)}>
                                                        <ResizeableDarkThemeTable
                                                            id={'table_'+(state.startRecord + index)}
                                                            data={state['filteredReports_'+(state.startRecord + index)].length ? state['filteredReports_'+(state.startRecord + index)] : state['filteredReports_'+(state.startRecord + index)]}
                                                            columns = {state['report_columns_'+(state.startRecord + index)]}
                                                            onClickRow={tableRow => {}}
                                                            dashboard = {state['filteredReports_'+(state.startRecord + index)].length ? true : false}
                                                            //tooltipFor={[]}
                                                            marginRight={10}
                                                            tableHead={'bg-black text-white'}
                                                            tableBody={'bg-dark3 text-white'}
                                                            tableType={'table-dark-hover'}
                                                            perPageClass={`bg-transparent text-lightGray`}
                                                            textColor={'text-black'}
                                                            selectedColor={'bg-transparent'}
                                                            sortByColumn={"anomaly_time"}
                                                            riskTooltip={[]}
                                                        />
                                                    </div>
                                                </div>
                                            : null}
                                        </div>
                                : null}
                            </div>
                        )
                    })
                : 
                    <div className='d-flex justify-content-center m-4'>
                        There are no data on this criteria. Please try adjusting your filter.
                    </div>
                }
                
                {state.totalRecords > state.perPage ?
                    <div className='d-flex justify-content-end'>
                        <div className='pagination errorPagePagination f12 mt-2'>
                            <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                            <span className='displayNone'>
                                Go to
                                <input
                                    type='number'
                                    value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                    onChange={e => {
                                        const page = e.target.value ? Number(e.target.value) : ''
                                        gotoPage(page)
                                    }}
                                    className='ml-1 inputClass'
                                />
                            </span>
                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button>
                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}
export default UserDetails