/*************************************************
 * Tvastar
 * @exports
 * @file QueryEvents.js  //copy of query list from the observability Events QueryDetail page
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { getDayFromSelectedDuration, momentDateGivenFormat, currentLocaltime, subHours, subDays, momentConvertionLocalToUtcTime, alphaNumeric } from '../../../utils/utility'
import Select from 'react-select'
import MultiSelectSection from '../../common/MultiSelectSection';
import Search from '../../common/SearchComponent'	
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getQueries, startQueryExection, getQueryResult, setFavouriteQuery, unsetFavouriteQuery } from '../../../actions/QueryAction'
import { dynamicCallApi } from '../../../actions/DynamicLoadAction'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import _ from 'lodash'

 import { Store as CommonNotification } from 'react-notifications-component';

// import { RangePicker } from "react-minimal-datetime-range";
// import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";
import QueryResults from './QueryResults'
import { useCallback } from 'react';

const durationOptions = [
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+3h', option: 'Last 3 hours' }, 
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const queryDateTime = current => {
	return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 90))
}

const QueryEvents = () => {
	const dateRef = useRef();
	const daterRangeListRef = useRef();

    // State variables for filters
    const [state, setState] = useState({		
        minMaxHeight: '770px',
		listLoading: true,

		selectedProvider: "",
		
		selectedAccount: [],
		isAccountOpen: false,

		selectedRegion: [],
		isRegionOpen: false,

		selectedDuration: "+7d",		

		startRecord: 0, 
		currentPage: 1,
		totalPages: 1,
		overAllTotalPages: 1,
		perPage: 10,

		selectedCategory: "All",
	})
	

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);
	// const providers = useSelector(state => state.filters.providers);
	
    const dispatch = useDispatch(); // Create a dispatch function
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const observabilityQueryList = useSelector(state => state.observability.observabilityQueryList)
  
    useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callGetQueries: true, getDurationDetails: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callGetQueries: true, getDurationDetails: true }));
                    }
            })
        }
        // Run the code inside this block once on component mount
    }, [dispatch, providers]); // Empty dependency array ensures this runs only once on mount

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);
	
	useEffect(() => {
		if(state.callGetQueries) {
			let params = {}
			params.aggregate_by = ["category", "sub_category"]
			params.provider = state.selectedProvider.toLowerCase()
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount.filter(e => e !== "All")
			}
			
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion.filter(e => e !== "All")
			}
			// if(observabilityQueryList && observabilityQueryList.length) {
			// 	setState(prevState => ({ ...prevState, queryListJson: observabilityQueryList, listLoading: false, formQueryList: true, callGetQueries: false }))
			// } else {
			dispatch(getQueries(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, queryListJson: response, listLoading: false, formQueryList: true, callGetQueries: false }))
					} else {
						setState(prevState => ({ ...prevState, queryListJson: [], listLoading: false, callGetQueries: false }))
					}
				})
			// }
		}
	}, [state.callGetQueries, dispatch, observabilityQueryList, state.selectedAccount, state.selectedProvider, state.selectedRegion])

	useEffect(() => {
		if(state.formQueryList && state.queryListJson && state.queryListJson.length) {
			let catArray = []
			let category = []
			state.queryListJson.forEach((item, index) => {
				item.category.forEach(cat => {
					let catRow = {}
					catRow.category = cat
					if(!category.includes(cat)) {
						category.push(cat)
					}
					if(item.sub_category) {
						catRow.sub_category = item.sub_category
					}
					catArray.push(catRow)
				})
			})
	
			let categories = []
			let totalCatCount = 0
			_.chain(catArray)
				.groupBy('category')
				.map((value, key) => ({ label: key, sub: value })).value()
				.forEach((category, i) => {
					let totalCount = 0
					let dataRow = {}
					dataRow.category = category.label
					let sub_category = []
					_.chain(category.sub)
					.groupBy('sub_category')
					.map((value, key) => ({ slabel: key, sSub: value })).value()
					.forEach((subCat, i) => {
						if(category.sub.filter(e => e.sub_category).length) {
							let subCatRow = {}
							subCatRow.sub_category = subCat.slabel
							subCatRow.count = subCat.sSub.length
							sub_category.push(subCatRow)
						}
						totalCatCount += subCat.sSub.length
						totalCount += subCat.sSub.length
					})
					dataRow.count = totalCount
					if(sub_category && sub_category.length) {
						dataRow.sub_category = sub_category
					}
					categories.push(dataRow)
				})
	
			let dataRow = {}
			dataRow.category = "All"
			dataRow.count = totalCatCount
			dataRow.sub_category = ""	
			categories.push(dataRow)
	
			setState(prevState => ({ ...prevState, totalQueryList: state.queryListJson, queryList: state.queryListJson, categories: _.orderBy(categories, ['count'], ['desc']), getAllQueries: true }))
		}
	}, [state.formQueryList, state.queryListJson])

	useEffect(() => {
		if(state.filterQueryList) {
			let data = state.totalQueryList
			if(state.selectedSubCategory && state.selectedSubCategory !== "") {
				data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
			} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
				data = data.filter(e => e.category.includes(state.selectedCategory))
			}
	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, filterQueryList: false }))
		}
	}, [state.filterQueryList, state.perPage, state.selectedCategory, state.selectedSubCategory, state.totalQueryList])

	useEffect(() => {
		if(state.getAllQueries) {
			let data = state.totalQueryList	
			let totalPages = 1
			if(data.length > state.perPage) {
				totalPages = Math.ceil(data.length / state.perPage)
			}
	
			setState(prevState => ({ ...prevState, filteredArray: data, totalPages, startRecord: 0, currentPage: 1, getAllQueries: false }))
		}
	}, [state.getAllQueries, state.perPage, state.totalQueryList])
	
	const handleMultiSelectChange = (field, arrayValue, stateOptions) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []
		if(field === 'selectedAccount' || field === "selectedQueryAccount") {
			let prevState = state[field] ? state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					state.accounts.forEach(acc => {
						selectedValue.push(acc.account_id)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (state.accounts.length -1)) {
					selectedValue.push('All')
				}
			}	
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		} else if(field === 'selectedRegion' || field === "selectedQueryRegion") {
			let prevState = state[field] ? state[field] : []
			if(value.includes('All')) {
				if(!prevState.includes('All')) {
					state.regions.forEach(reg => {
						selectedValue.push(reg.region)
					})
				} else {
					const index = value.indexOf('All');
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes('All')) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes('All')) {
				if(!selectedValue.includes('All') && selectedValue.length === (state.regions.length -1)) {
					selectedValue.push('All')
				}
			}
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		} else {
			//for array			
			if(stateOptions) {
				let prevState = state[field] ? state[field] : []
                if(prevState.length === 1 && prevState[0] === "All") {
                    prevState = []
                }
				if(value.includes('All')) {
					if(!prevState.includes('All')) {
						if(stateOptions.filter(e => e.value).length) {
							selectedValue = stateOptions.map(e => e.value)
						} else {
							selectedValue = stateOptions
						}
					} else {
						const index = value.indexOf('All');
						if (index > -1) {
							value.splice(index, 1);
						}
						selectedValue = value
					}
				} else if(!prevState.includes('All')) {
					selectedValue = value
				}
	
				if(selectedValue.length && !selectedValue.includes('All')) {
					if(!selectedValue.includes('All') && selectedValue.length === (stateOptions.length -1)) {
						selectedValue.push('All')
					}
				}
			} else {
				selectedValue = value
			}
			setState(prevState => ({ ...prevState, [field]: selectedValue }))
		}
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		if (dateRef.current && !dateRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
		event.stopPropagation();
		if (daterRangeListRef.current && !daterRangeListRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}

		// console.log("type", type)
		// console.log("section", section)
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	const handleSelect = (date) => {
		let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
				startTime: datePickerStartDate, 
				endTime: datePickerEndDate,
                showDateRangePicker: false,
				isDurationOpen: false
                // selectedGraphDuration: "custom",
                // metricDetails: [], 
                // showMetricLoading: true,
                // callMetricAPi: true
            }));
    
        }

        setDateState([date.selection])

	}

    // const handleSelectNew = (date) => {
	// 	// let startTime = momentDateGivenFormat(date[0], "YYYY-MM-DD 00:00:00")
	// 	// let endTime = momentDateGivenFormat(date[1], "YYYY-MM-DD 23:59:59")
	// 	// let startTime = date[0]
	// 	// let endTime = date[1]
	// 	let startTime = momentDateGivenFormat(date[0], "YYYY-MM-DD HH:mm:00")
	// 	let endTime = momentDateGivenFormat(date[1], "YYYY-MM-DD HH:mm:00")
	// 	setState(prevState => ({ ...prevState, datePickerStartDate: startTime, datePickerEndDate: endTime, startTime, endTime, showDateRangePicker: false }))
	// }

	useEffect(() => {
		if(state.getDurationDetails) {
			let duration = state.selectedDuration
			let period = duration.substring(duration.length, duration.length-1)
			let durationBtw = getDayFromSelectedDuration(duration)
			let startTime = ""
			if(period === "d") {
				startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			} else {
				startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			}
			
			let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')

			let selectionRange = [{
				startDate: new Date(startTime),
				endDate: new Date(endTime),
				key: 'selection',
			}]

			setState(prevState => ({ ...prevState, startTime, endTime, selectionRange, queryStartTime: startTime, queryEndTime: endTime, getDurationDetails: false }))
		}
	}, [state.getDurationDetails, state.selectedDuration])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

	const querySelection = (i, item) => {
		if(state.selectedQuery !== item) {
			if(item.display_output && Object.entries(item.display_output).length) {
				Object.values(item.display_output).forEach(out => {
					if(out.default) {
						setState(prevState => ({ ...prevState, [item.query_id+"_"+out.output_field]: out.default }))
					}
					if(out.api_details && Object.entries(out.api_details).length) {
						let apiDetails = out.api_details
						let params = apiDetails.api_request
						if(apiDetails.additional_fields && apiDetails.additional_fields) {
							apiDetails.additional_fields.forEach(addFields => {
								if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
									params.account_id = state.selectedAccount
								}
								if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
									params.region = state.selectedRegion
								}
							})
						}
						let manditoryFieldsFilled = true
						if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
							apiDetails.mandatory_fields.forEach(manFields => {
								if(!state[item.query_id+"_"+manFields] || state[item.query_id+"_"+manFields] === "" || (Array.isArray(state[item.query_id+"_"+manFields]) && !state[item.query_id+"_"+manFields].length)) {
									manditoryFieldsFilled = false
								} else {
									params[manFields] = state[item.query_id+"_"+manFields]
								}
							})
						}
						if(manditoryFieldsFilled) {
							dynamicCallApiFunction(params, item, apiDetails, out.output_field, out.output_type)
						}
					} else if(out.type === "dropdown" && (out.output_type === "list" || out.output_type === "string")) {
						let options = []
						let dataRow = {}
						dataRow.key = "All"
						dataRow.value = "All"
						options.push(dataRow)

						// let results = response.results
						options.forEach(row => {
							let dataRow = {}
							dataRow.key = row
							dataRow.value = row
							options.push(dataRow)
						})
						// let options = ["All"]
						// options =  options.concat(out.values)

						setState(prevState => ({ ...prevState, [item.query_id+"_options_"+out.output_field]: options }))

						// handleMultiSelectChange(item.query_id+"_"+out.output_field, [], options)
					}
				})
			}

			// console.log("item", item)

			let selectedQueryIds = []
			let required_fields = item.required_fields ? item.required_fields : []
			required_fields.forEach(row => {
				selectedQueryIds.push(item.query_id+'_'+row)
			})

			setState(prevState => ({ 
				...prevState, 
				queryErrorMessage: "",
				selectedQuery: item,
				// selectedQueryAccount: state.selectedAccount,
				// selectedQueryRegion: state.selectedRegion,
				selectedQueryDuration: state.selectedDuration,
				queryStartTime: state.startTime,
				queryEndTime: state.endTime,
				showQueryDateTimePicker: false,
				hasError: false,
				formQueryBuilder: true,
				selectedQueryIds
			}))
		} else {
			setState(prevState => ({ ...prevState, selectedQuery: "", hasError: false }))
		}
	}

	// useEffect(() => {
	// 	const handleClickOutside = (event) => {
	// 	  // Check if the clicked element is one of the div elements or their descendants
	// 	  if (
	// 		divRefs.some((ref) => ref.current && ref.current.contains(event.target))
	// 	  ) {
	// 		// Clicked inside one of the div elements
	// 		return;
	// 	  }
	
	// 	  // Clicked outside of the div elements
	// 	  console.log('Clicked outside of the div elements');
	// 	};
	
	// 	// Add a click event listener to the document
	// 	document.addEventListener('click', handleClickOutside);
	
	// 	// Remove the event listener when the component unmounts
	// 	return () => {
	// 	  document.removeEventListener('click', handleClickOutside);
	// 	};
	//   }, [divRefs]);

	useEffect(() => {
		if(state.formQueryBuilder) {
			let querybuilder = []
			let selectedQuery = state.selectedQuery
			let query = selectedQuery.display_query
			if(query) {
				let splitString = query.split("$")
				let re = /{(.*?)}/;
				splitString.forEach(str => {
					let result = str.split(re);
					if(result.length === 1) {
						let dataRow = {}
						dataRow.input = false
						dataRow.string = result[0]
						querybuilder.push(dataRow)
					} else {
						result.forEach((item, index) => {
							if(item !== "") {
								if(index === 1) {
									let dataRow = {}
									dataRow.input = true
									dataRow.field = item
									dataRow.string = result[0]
									querybuilder.push(dataRow)
								} else {
									let dataRow = {}
									dataRow.input = false
									dataRow.string = item
									querybuilder.push(dataRow)
								}
							}
						})
					}
				})

				// console.log("querybuilder", querybuilder)
				setState(prevState => ({ ...prevState, querybuilder }))
			}
		}
	}, [state.formQueryBuilder, state.selectedQuery])

	useEffect(() => {
		if(state.getQueryDurationDetails) {

			let duration = state.selectedQueryDuration
			let period = duration.substring(duration.length, duration.length-1)
			let durationBtw = getDayFromSelectedDuration(duration)
			let startTime = ""
			if(period === "d") {
				startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			} else {
				startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
			}
			
			let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		
			setState(prevState => ({ ...prevState, queryStartTime: startTime, queryEndTime: endTime, getQueryDurationDetails: false }))
		}
	}, [state.getQueryDurationDetails, state.selectedQueryDuration])
	
	const handleStartTimeChange = (date) => {
		let queryStartTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let queryEndTime = state.queryEndTime
		if(new Date(queryStartTime).getTime() > new Date(queryEndTime).getTime() ) {
			queryEndTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}
		setState(prevState => ({ ...prevState, queryStartTime, queryEndTime}))
	}

	const handleEndTimeChange = (date) => {
		let queryStartTime = state.queryStartTime
		let queryEndTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(queryEndTime).getTime() > new Date(queryStartTime).getTime() ) {
			setState(prevState => ({ ...prevState, queryEndTime}))
		}
	}

	const getQueryResultFunction = useCallback((params) => {
		dispatch(getQueryResult(params))
			.then((response) => {
				if(response) {
					if(response.status === "RUNNING") {
						setTimeout(() => { getQueryResultFunction(response) }, 3000)
					} else if(response.status === "FAILED") {
						setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
					} else {
						setTimeout(() => {
							let results = response.results && response.results.length ? response.results : []
							if(results.length) {
								setState(prevState => ({ ...prevState, queryResult: response, queryLoading: false, showQueryResult: true, executeQuery: false }))
							} else {
								let dataRow = {
									message: "No records to show for the selected criteria"
								}
								setState(prevState => ({ ...prevState, queryErrorMessage: dataRow, queryLoading: false, executeQuery: false }))
							}
						}, 2000)
					}
				} else {
					setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false }))
				}
			})
	}, [dispatch])

	const startQueryExectionFunction = useCallback((params) => {
		dispatch(startQueryExection(params))
			.then((response) => {
				if(response) {
					if(response.status === "FAILED") {
						setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
					} else {
						getQueryResultFunction(response)
					}
				} else {
					setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, executeQuery: false }))
				}
			})
	}, [dispatch, getQueryResultFunction])
	useEffect(() => {
		if(state.executeQuery) {
			let selectedQuery  = state.selectedQuery
			let hasError = false
	
			let params = {}
			params.query_id = selectedQuery.query_id
			params.selectedCategory = state.selectedCategory && state.selectedCategory !== "All" ? state.selectedCategory : selectedQuery.category
			
			if(selectedQuery.required_fields && selectedQuery.required_fields.length) {
				selectedQuery.required_fields.forEach(item => {
					if(item === "account_id" && state[state.selectedQuery.query_id+"_selectedQueryAccount"] && state[state.selectedQuery.query_id+"_selectedQueryAccount"].length && !state[state.selectedQuery.query_id+"_selectedQueryAccount"].includes("All")) {
						// params.account_id = state[state.selectedQuery.query_id+"_selectedQueryAccount"].filter(e => e !== "All")
						params.account_id = state[state.selectedQuery.query_id+"_selectedQueryAccount"]
					} else if(item === "account_id")  {
						params.account_id = "ALL"
					}
					if(item === "region" && state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].length && !state[state.selectedQuery.query_id+"_selectedQueryRegion"].includes("All")) {
						params.region = state[state.selectedQuery.query_id+"_selectedQueryRegion"]
					} else if(item === "region")  {
						params.region = "ALL"
					}
					if(item === "duration") {
						params.start_time = momentConvertionLocalToUtcTime(state.queryStartTime, "YYYY-MM-DD HH:mm:00")
						params.end_time = momentConvertionLocalToUtcTime(state.queryEndTime, "YYYY-MM-DD HH:mm:00")
					}
				})
			}
	
			if(selectedQuery.display_output && Object.entries(selectedQuery.display_output).length) {
				Object.entries(selectedQuery.display_output).forEach(([key, value]) => {
					if(value.type === "text") {
						if(value.output_type === "string") {
							if(!state[state.selectedQuery.query_id+"_"+value.output_field] || state[state.selectedQuery.query_id+"_"+value.output_field] === "") {
								hasError = true
							} else if(value.validation && Object.entries(value.validation).length) {
								let validation = value.validation
								if(validation.min && state[state.selectedQuery.query_id+"_"+value.output_field].length < validation.min) {
									hasError = true
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
								} else if(validation.max && state[state.selectedQuery.query_id+"_"+value.output_field].length > validation.max) {
									hasError = true
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
								} else {
									setState(prevState => ({ ...prevState, ['error_message_'+state.selectedQuery.query_id+"_"+value.output_field]: "" }))
									params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
								}
							} else {
								params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
							}
						} else if(value.output_type === "list") {
							if(!state[state.selectedQuery.query_id+"_"+value.output_field] || !state[state.selectedQuery.query_id+"_"+value.output_field].length) {
								hasError = true
							} else {
								if(value.api_details && value.include_all) {
									if(state[state.selectedQuery.query_id+"_"+value.output_field] && state[state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
										params[value.output_field] = ["All"]
									} else {
										params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
									}
								} else {
									if(state[state.selectedQuery.query_id+"_"+value.output_field].length > 1 && state[state.selectedQuery.query_id+"_"+value.output_field].includes("All")) {
										let data = state[state.selectedQuery.query_id+"_"+value.output_field]
										params[value.output_field] = data.filter(e => e !== 'All')
									} else {
										params[value.output_field] = state[state.selectedQuery.query_id+"_"+value.output_field]
									}
								}
							}
						}
					} else {
						let output = state[state.selectedQuery.query_id+"_"+value.output_field]
						// if(value.output_type === "list") {
						// 	output = state[value.output_field]
						// }
						if(value.output_type === "list") {
							if(!output || !output.length ) {
								hasError = true
							}
						} else {
							if(!value || value === "") {
								hasError = true
							}
						}
						if(output.includes("All") && value.output_type === "list") {
							if(value.include_all) {
								output = ["All"]
							} else {
								output = output.filter(e => e !== 'All')
							}
						}
						params[value.output_field] = output
					}
				})
			}
	
			// console.log("params", params)
	
			setState(prevState => ({ ...prevState, hasError, executeQuery: hasError ? false : true }))
	
			if(!hasError) {
	
				let queryProps = {
					account_id: state[state.selectedQuery.query_id+"_selectedQueryAccount"],
					region: state[state.selectedQuery.query_id+"_selectedQueryRegion"],
					duration: state.selectedQueryDuration,
					params: params,
					start_time: state.queryStartTime,
					end_time: state.queryEndTime,
					selectedQuery: state.selectedQuery,
					querybuilder: state.querybuilder
				}

				
				// if(state[state.selectedQuery.query_id+"_selectedQueryAccount"] && !state[state.selectedQuery.query_id+"_selectedQueryAccount"].includes("All")) {
				// 	queryProps.account_id = state[state.selectedQuery.query_id+"_selectedQueryAccount"]
				// }
				// if(state[state.selectedQuery.query_id+"_selectedQueryRegion"] && !state[state.selectedQuery.query_id+"_selectedQueryRegion"].includes("All")) {
				// 	queryProps.region = state[state.selectedQuery.query_id+"_selectedQueryRegion"]
				// }
	
				if(selectedQuery.async) {
					setState(prevState => ({ ...prevState, queryLoading: true, queryProps, selectedInput: params, executeQuery: false }))
					startQueryExectionFunction(params)
				} else {
					setState(prevState => ({ ...prevState, queryLoading: true, queryProps, executeQuery: false }))
					getQueryResultFunction(params)
				}
			}
		}
	}, [state.executeQuery, state, startQueryExectionFunction, getQueryResultFunction])

	const onKeyDown = (e, field, stateField) => {
		let array = state[stateField] && Array.isArray(state[stateField]) ? state[stateField] : []
		//|| e.keyCode === 188
		if (e.keyCode === 13 || e.keyCode === 9) {
			if(state[field] && state[field] !== "") {
				array.push(state[field].trim())
				if(e.keyCode === 9) {
					e.preventDefault();
				}
				setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
			}
		}
	}

	const onBlur = (e, field, stateField) => {
		let array = state[stateField] ? state[stateField] : []
		if(state[field] && state[field] !== "") {
			array.push(state[field].trim())
			setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
		}
	}

	const removeSelectedBadge = (field, value, index) => {
		let filteredReslt = state[field]
		filteredReslt.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: filteredReslt }))
	}

	const setFavouriteQueryFunction = (index, queryId, type) => {
		let params = {
			query_id: queryId
		}

		if(type) {
			dispatch(setFavouriteQuery(params))
				.then((response) => {
					if(response) {
						updateQueryList()
						
						CommonNotification.addNotification({
							message: "Query "+queryId+" added to favourite",
							type: "success",
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 3000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						})
					}
				})
		} else {
			dispatch(unsetFavouriteQuery(params))
				.then((response) => {
					if(response) {
						updateQueryList()
						CommonNotification.addNotification({
							message: "Query "+queryId+" removed from favourite",
							type: "success",
							insert: "top",
							container: "top-center",
							dismiss: {
								duration: 3000,
								onScreen: false,
								pauseOnHover: true,
								showIcon: true,
							}
						})
					}
				})
		}
	}

	const updateQueryList = () => {
		let params = {}
		dispatch(getQueries(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({ ...prevState, totalQueryList: response }),
						() => {
							let data = state.totalQueryList
							if(state.selectedSubCategory && state.selectedSubCategory !== "") {
								data = data.filter(e => e.category.includes(state.selectedCategory) && e.sub_category === state.selectedSubCategory)
							} else if(state.selectedCategory && state.selectedCategory !== "" && state.selectedCategory !== "All") {
								data = data.filter(e => e.category.includes(state.selectedCategory))
							}
	
							setState(prevState => ({ ...prevState, filteredArray: data }))
						}
					)
				}
			})
	}

	const onChangeTextString = (outputStructure, field, value) => {
		let hasError = false
		let validation = outputStructure.validation
		if(validation && Object.entries(validation).length) {
			if(validation.min && value.length < validation.min) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else if(validation.max && value.length > validation.max) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else {
				setState(prevState => ({ ...prevState, ['error_message_'+field]: "" }))
			}
			
			if(validation.data_type) {
				if(validation.data_type === "alphanumeric") {
					value = alphaNumeric(value)
				}
			}
		}
		
		setState(prevState => ({ ...prevState, [field]: value, hasError }))
	}

	const onChangeTextDropDown = (displayOutput, changedKey) => {
		let selectedQuery = state.selectedQuery
		let keysToReload = displayOutput[changedKey].api_details.keys_to_reload
		if(keysToReload && keysToReload.length) {
			keysToReload.forEach(item => {
				let out = displayOutput[item]
				// if(out.default) {
				// 	setState(prevState => ({ ...prevState, [selectedQuery.query_id+"_"+out.output_field]: selectedQuery.default }))
				// }
				if(out.api_details && Object.entries(out.api_details).length) {
					let apiDetails = out.api_details
					let params = apiDetails.api_request
					if(apiDetails.additional_fields && apiDetails.additional_fields) {
						apiDetails.additional_fields.forEach(addFields => {
							if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
								params.account_id = state.selectedAccount
							}
							if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
								params.region = state.selectedRegion
							}
						})
					}
					let manditoryFieldsFilled = true
					if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
						apiDetails.mandatory_fields.forEach(manFields => {
							if(!state[selectedQuery.query_id+"_"+manFields] || state[selectedQuery.query_id+"_"+manFields] === "" || (Array.isArray(state[selectedQuery.query_id+"_"+manFields]) && !state[selectedQuery.query_id+"_"+manFields].length)) {
								manditoryFieldsFilled = false
							} else {
								params[manFields] = state[selectedQuery.query_id+"_"+manFields]
							}
						})
					}
					
					if(manditoryFieldsFilled) {
						setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_"+out.output_field]: out.output_type === "text" ? "" : [] }),
							() => dynamicCallApiFunction(params, selectedQuery, apiDetails, out.output_field, out.output_type)						
						)
					}
				}
			})
		}
	}

	const dynamicCallApiFunction = (params, selectedQuery, apiDetails, outputField, outputType) => {
		dispatch(dynamicCallApi(apiDetails.api_name, apiDetails.api_resource, params))
			.then((response) => {
				if(response) {
					let options = []
					if(outputType === "list") {
						let dataRow = {}
						dataRow.key = "All"
						dataRow.value = "All"
						options.push(dataRow)
					}
					if(response.results) {
						let results = response.results
						results.forEach(res => {
							if(apiDetails.field_name === "id_name_map") {
								let dataRow = {}
								dataRow.key = res[apiDetails.field_name]
								dataRow.value = res[apiDetails.field_name]
								// options.push(res[apiDetails.field_name])
								options.push(dataRow)
							} else if(res[apiDetails.field_name]){
								let dataRow = {}
								dataRow.key = res[apiDetails.field_name]
								dataRow.value = res[apiDetails.field_name]
								options.push(dataRow)
							}
						})
					}

					console.log(selectedQuery.query_id+"_options_"+outputField)
					console.log("options", options)
					
					setState(prevState => ({ ...prevState, [selectedQuery.query_id+"_options_"+outputField]: options }))
					if(outputType === "list") {
						handleMultiSelectChange(outputField, options)
					}
				}
			})
	}
	return (
		<div className="container-fluid overflow-auto flex-grow-1 px-0" onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`loaderOverlay ${state.queryLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div>
			
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-6 col-6 py-2 align-self-center'>
                        <h6 className='text-white m-0'>Queries</h6>
                        <p className='text-white m-0'>Resources queries on your Cloud</p>
                    </div>
					{!state.showQueryResult ?
						<div className='col-lg-6 col-6 py-2 text-right'>
							<div className='d-flex mx-0 topBasicFilter justify-content-end'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}}
									onClick={(event) => {
										if(!state.isProviderOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
										}
									}}
								>
									<label className='mb-1 mt-1 f10 d-flex'>Provider</label>
									<p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
										{state.selectedProvider ? state.selectedProvider : 'Select'}
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isProviderOpen ?
										<MultiSelectSection 
											fields={["provider_name", "provider_name"]}
											options={state.providers}
											widthClass={'minWidth220'}
											selectedValues={state.selectedProvider ? state.selectedProvider : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [], queryListJson: [], callGetQueries: true }))
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
										/>
									: null}
								</div>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
									onClick={(event) => {
										event.preventDefault();
										handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
									}}
								>
									<label className='mb-2 mt-1 f10 d-flex'>Account</label>
									<p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>
										{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isAccountOpen ?
										<MultiSelectSection 
											fields={["account_id", "account_name"]}
											options={state.accounts}
											widthClass={'minWidth220'}
											selectedValues={state.selectedAccount ? state.selectedAccount : []}
											callbackMultiSelect={(value) => {
												setState(prevState => ({ ...prevState, selectedAccount: value }))
											}}
										/>
									: null}
								</div>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
									onClick={(event) => {
										event.preventDefault();
										handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
									}}
								>
									<label className='mb-2 mt-1 f10 d-flex'>Region</label>
									<p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>
										{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isRegionOpen ?
										<MultiSelectSection 
											fields={["region", "name"]}
											options={state.regions}
											widthClass={'minWidth220'}
											selectedValues={state.selectedRegion ? state.selectedRegion : []}
											callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
										/>
									: null}
								</div>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '220px', maxWidth: '220px'}}
									onClick={(event) => {
										if(!state.isDurationOpen) {
											event.preventDefault();
											handleChildClick(event, 'child', 'singleDropDown', "isDurationOpen")
										}
									}}
								>
									<label className='mb-1 mt-1 f10 d-flex'>Duration</label>
									<p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedDuration ? 'text-white' : 'placeholder'}`}>
										<span> {momentDateGivenFormat(state.startTime, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.endTime, 'DD MMM YYYY')}</span>
										<i className='fa fa-caret-down mt-1 mr-1'/>
									</p>
									{state.isDurationOpen ?
										<MultiSelectSection 
											fields={["value", "option"]}
											options={durationOptions}
											widthClass={'minWidth220'}
											selectedValues={state.selectedDuration ? state.selectedDuration : ''}
											callbackMultiSelect={(value) => {
												if(!value || typeof(value) === 'string') {
													if(value !== 'custom') {
														setState(prevState => ({ ...prevState, selectedDuration: value, showDateRangePicker: false, getDurationDetails: true }))
													} else {
														setState(prevState => ({ ...prevState, showDateRangePicker: true, selectedDuration: value }))
													}
												} else {
													value.preventDefault()
													handleChildClick(value, "search", 'singleDropDown', "")
												}
											}}
											singleSelection={true}
										/>
									: null}
								</div>
								
								{state.showDateRangePicker ?                                                
									<div className='headerDateRange mt-2' style={{'z-index': '999999999','position': 'absolute','right': '20px','margin-top': '90px', 'top': '75px'}} ref={dateRef}>
										<DateRange
											editableDateInputs={true}
											onChange={handleSelect}
											moveRangeOnFirstSelection={false}
											ranges={dateState}
										/>
									</div>
								: null}
								{/* <div className={`ml-3 align-self-center displayNone`}>
									<div className="btn-group" role="group" aria-label="Basic example">
										<button type="button" className={`${state.queryLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-right px-2 py-1`} onClick={() => setState(prevState => ({ ...prevState, queryLoading: false, callOnSearch: true }))}>Search</button>
										<button type="button" className={`${state.queryLoading ? 'disabled' : 'cursorPointer'} btn btn-secondary border-left px-2 py-1`} onClick={()=> onReset()}><i className='far fa-redo'></i></button>
									</div>
								</div> */}
							</div>
						</div>
					: null}
				</div>
				<div className='row mx-0 p-2'>
					{state.showQueryResult ?
						<QueryResults 
							accounts={state.accounts}
							regions={state.regions}
							selectedQuery = {state.selectedQuery}
							queryStartTime = {state.queryStartTime}
							queryEndTime = {state.queryEndTime}
							selectedInput = {state.selectedInput}
							querybuilder = {state.querybuilder}
							queryResult = {state.queryResult}
							selectedProvider = {state.selectedProvider}
							duration = {state.selectedQueryDuration}
							account_id = {state[state.selectedQuery.query_id+"_selectedQueryAccount"]}
							region = {state[state.selectedQuery.query_id+"_selectedQueryRegion"]}
							hideQueryResults = {() => setState(prevState => ({ ...prevState, showQueryResult: false }))}
						/>
					:
                    	<div className='col-lg-12 bg-black p-3 overflowYAuto vh100'>
							{state.listLoading ?
								<div className='d-flex justify-content-center m-4'>
									<Spinner className='text-center' color='light'   />
								</div>
							:	
								<React.Fragment>
								<div className={`d-flex pb-2 justify-content-between`}>
									<div className="d-flex mr-2 w-40">
										<p className="f12 m-0 align-self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.queryList && state.queryList.length}{state.queryList && state.queryList.length > 1 ? ' queries' : ' query'}</p>
									</div>
									<div className="d-flex justify-content-end w-60 mb-n2">
										{state.queryList && state.queryList.length ?
											<Search
												data={state.queryList ? state.queryList : []}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-darkGray align-self-center w-50 mr-1 border-lightGray rounded-5 f12'}
												searchClassName={'px-2 f12'}
												searchIconColor={'text-white f12'}
												searchPlaceHolder={'Search queries....'}
												className={"bg-transparent text-white pl-0 form-control-sm f12"}
												filteredData={(filteredArray) => {
													setState(prevState => ({ ...prevState, filteredArray, startRecord: 0, currentPage: 1 }))
												}}
											/>
										: null}
										{state.filteredArray && state.filteredArray.length > state.perPage ?
											<div className="pagination errorPagePagination f12">
												<span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
												<button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
												<button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
												<button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
												<button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
											</div>
										: null}
									</div>
								</div>
							
								{/* <div className="d-flex errorSectionScroll h-95" > */}
								<div className="d-flex">
									{state.categories ? 
										<div className="w-20 pr-3 categoryBoxSection" id="scrollTop">
											<div className="bg-darkGray px-2 pt-2 select-bgg">
												{state.categories.map((item, index) => {
													return(
														<div key={'cat_'+index}>
														{item.category === "All" ?
															<div className={`d-flex justify-content-between cursorPointer mb-2 ${state.selectedCategory === item.category ? 'text-info disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: "All", selectedSubCategory: "", getAllQueries: true }))}>
																<p className="m-0 f-20">{item.category} Queries</p>
																{/* <p className="m-0 align-self-end ml-2 f-18">{item.count !== '' ? item.count : ''}</p> */}
																{/* <input className="form-control form-control-sm w-25 border-0 bg-grey text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" /> */}
															</div>
														:
															<React.Fragment>
															<div className={`d-flex cursorPointer mb-2 ${(!state.selectedSubCategory || state.selectedSubCategory === '') && item.category.includes(state.selectedCategory) ? 'text-info disabled' : (!item.count ? 'text-gray4 disabled test' : 'text-white')}`}>
																<div className="d-flex justify-content-between mb-2" style={{maxWidth: "250px"}} onClick={() => setState(prevState => ({ ...prevState, selectedCategory: item.category, selectedSubCategory: "", filterQueryList: true }))}>
																	<p className="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'category_'+index}><span className={`far ${item.category.includes(state.selectedCategory) ? 'fa-minus-circle' : 'fa-plus-circle'} mr-1`}></span>{item.category}</p>
																	<UncontrolledTooltip target={'category_'+index}>{item.category}</UncontrolledTooltip>
																	<input className="form-control form-control-sm w-25 border-0 bg-mediumDarkGray text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" defaultValue={item.count !== '' ? item.count : ''} aria-label=".form-control-sm example" />
																</div>
															</div>
															{item.category.includes(state.selectedCategory) && item.sub_category && item.sub_category.length ?
																item.sub_category.map(subCat => {
																	return(
																		<div className={`d-flex justify-content-between mb-2 ml-2 ${subCat.sub_category === state.selectedSubCategory ? 'text-info disabled' : 'cursorPointer'}`} style={{maxWidth: "230px"}} onClick={() => setState(prevState => ({ ...prevState, selectedSubCategory: subCat.sub_category, filterQueryList: true }))}>
																			<p className="mb-0 align-self-center ellipseCategoryBoxSection w-60" id={'sub_category_'+index}>{subCat.sub_category}</p>
																			<UncontrolledTooltip target={'sub_category_'+index}>{subCat.sub_category}</UncontrolledTooltip>
																			<input className="form-control form-control-sm w-25 border-0 bg-mediumDarkGray text-white readOnly  disabled rounded-0 form-number text-right" style={{minWidth: "40px"}} type="text" placeholder="1" value={subCat.count !== '' ? subCat.count : ''} aria-label=".form-control-sm example" />
																		</div>
																	)
																})															
															: null}
															</React.Fragment>
														}
														</div>
													)
												})}
											</div>
										</div>
									: null}
									<div className={`${state.categoires ? 'w-80' : 'w-100'} h-100`} id="scrollTop">
										<div className="accordion" id="accordionExample">
											{state.filteredArray && state.filteredArray.length ?
												state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
													return(
														<div key={'filArr_'+i} className={`card bg-darkGray border-0 mb-2 overflow-unset`}>
															<div className="d-flex justify-content-between">
																<div id={'heading_'+i} className="p-3" onClick={() => querySelection(i, item)}>
																	<div className="mb-1 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
																		<div className="text-white"><span className={`far ${state.selectedQuery && state.selectedQuery.query_id === item.query_id ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>{item.query_id +" - "+ (item.title ? item.title : "")}</div>
																	</div>
																	{!state.selectedQuery || state.selectedQuery.query_id !== item.query_id ?
																		<p className="ml-4 mb-0 f16"> 
																			<span className="mr-2 text-white">Query:</span>
																			{item.display_query}
																			
																			{item.required_fields && item.required_fields.includes("account_id") && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All") ?
																					<span className="ml-1">for the account <span className="ml-1 text-info">{state.selectedAccount.join(", ")}</span></span>
																				:
																					<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the accounts</span></span>
																				}
																				{item.required_fields && item.required_fields.includes("region") && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All") ?
																					<span className="ml-1">for the region <span className="ml-1 text-info">{state.selectedRegion.join(", ")}</span></span>
																				:
																					<span className="ml-1">for <span className="ml-1 text-info">All</span> <span className="ml-1">the regions</span></span>
																				}																
																				{item.required_fields && item.required_fields.includes("duration") && state.selectedDuration ?
																					<span className="ml-1">for the duration 
																					{state.selectedDuration === "custom" ?
																						<span className="ml-1 text-info">{momentDateGivenFormat(state.startTime, "DD MMM YYYY hh:mm A") +" - "+momentDateGivenFormat(state.endTime, "DD MMM YYYY hh:mm A")}</span>
																					: 
																						<span className="ml-1 text-info">{state.selectedDuration && durationOptions ? durationOptions.filter(e => e.value === state.selectedDuration)[0].option : ""}</span>
																					}
																					</span>
																				: null}
																		</p>
																	: null}
																</div>
																<span className={`text-right pr-3 ursorPointer mt-3`} onClick={e => { 
																		// e.stopPropagation() 
																		setState(prevState => ({
																			...prevState, 
																			['favourite_'+i]: typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? false : true) : (state['favourite_'+i] ? false : true),
																			// callSetFavouriteQuery: true
																		}))
																		setFavouriteQueryFunction(i, item.query_id, typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? false : true) : (state['favourite_'+i] ? false : true),)
																	}}
																>
																	<i className={`fal fa-star ${typeof state['favourite_'+i] === 'undefined' ? (item.favourite ? 'fillStar' : '') : (state['favourite_'+i] ? 'fillStar' : '')} `}></i>
																</span>
															</div>
															{state.selectedQuery && state.selectedQuery.query_id === item.query_id ?
																<div id={'collapse_event'} className={`collapse show`} aria-labelledby={'heading_'+i} data-parent="#accordionExample">
																	<div className="card-body">
																		{item.description && item.description !== "" ?
																			<div className="f16 w-95 mb-1"> 
																				<span className="w-100">Description:</span>
																				{item.description.split("\n").map((newLine, dIndex) => {
																					return(
																						<span key={'des_'+dIndex} className="d-flex">
																						{newLine.split("\t").map((tab, i) => {
																							return(
																								<span key={'des_line_'+i} className={`${!i ? "" :""}`}>{tab}</span>
																							)
																						})}
																						</span>
																					)
																				})}
																			</div>
																		: null}
																		{item.controls && Object.entries(item.controls).length ?
																			<div className="d-flex mt-2 p-3 rounded bg-mediumDarkGray">
																				{item.controls.hasOwnProperty("Tactic") ?
																					<div className={`mr-2 border-right-black5
																						${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Technique")) ? "w-100" 
																						: (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Technique")) ? "w-50" : "w-20"}
																					`}>
																						<label className="text-white mb-2">Tactic</label>
																						{item.controls.Tactic.map((ctrls, cIndex) => {
																							return(
																								<p  key={'ctrls_'+cIndex} className="mb-0 d-flex flex-wrap">{ctrls}</p>
																							)
																						})}
																					</div>
																				: null}
																				{item.controls.hasOwnProperty("Technique") ?
																					<div className={`mr-2 border-right-black5
																						${(!item.controls.hasOwnProperty("Action/Impact") && !item.controls.hasOwnProperty("Tactic")) ? "w-100" 
																						: (!item.controls.hasOwnProperty("Action/Impact") || !item.controls.hasOwnProperty("Tactic")) ? "w-50" : "w-20"}
																					`}>
																						<label className="text-white mb-2">Technique</label>
																						{item.controls.Technique.map((ctrls, cIndex) => {
																							return(
																								<p  key={'ctrls_'+cIndex} className="mb-0 d-flex flex-wrap">{ctrls}</p>
																							)
																						})}
																					</div>
																				: null}
																				{item.controls.hasOwnProperty("Action/Impact") ?
																					<div className={`mr-2
																						${(!item.controls.hasOwnProperty("Tactic") && !item.controls.hasOwnProperty("Technique")) ? "w-100" 
																						: (!item.controls.hasOwnProperty("Tactic") || !item.controls.hasOwnProperty("Technique")) ? "w-45" : "w-45"}
																					`}>
																						<label className="text-white mb-2">Action/Impact</label>
																						{item.controls["Action/Impact"].map((ctrls, cIndex) => {
																							return(
																								<p  key={'ctrls_'+cIndex} className="mb-0 d-flex flex-wrap">{ctrls}</p>
																							)
																						})}
																					</div>
																				: null}
																			</div>
																		: null}
																		<div className={`form-group dlpDescriptionForm dlpDescriptionFormPlain px-2 py-3 bg-black rounded mt-2`}>
																			<div className="d-flex flex-wrap">
																				{state.querybuilder && state.querybuilder.map((qry, j) => {
																					return(
																						<span key={'qry_'+j}>
																						{!qry.input ?
																							<p className={`mb-0 ${!j ? "mr-2" : "mx-2"} align-self-end text-info mt-3`}>{qry.string}</p>
																						:
																						<React.Fragment>
																							{state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "text" ?
																								state.selectedQuery.display_output[qry.field].output_type === "string" ?
																									<React.Fragment>
																									{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
																										<div className='' style={{minWidth: "250px"}} onClick={(event) => {
																											event.preventDefault();
																											handleChildClick(event, 'child', 'singleDropDown', "show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)
																										}}>
																											<p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, showDateOptions: !prevState.showDateOptions }))}>
																												{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : 'Select'}
																											</p>
																											{state["show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ?
																												<div className='position-relative'>
																													<MultiSelectSection 
																														fields={["value", "key"]}
																														removeTop={true}
																														widthClass={'minWidth220'}
																														// state[state.selectedQuery.query_id+"_drop_options_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_drop_options_"+state.selectedQuery.display_output[qry.field].output_field]
																														options={state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field]}
																														selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																														callbackMultiSelect={(value) => {
																															if(!value || typeof(value) === 'string') {
																																setState(prevState => ({ 
																																	...prevState,
																																	[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value
																																}))
																																onChangeTextDropDown(state.selectedQuery.display_output, qry.field)
																															} else {
																																value.preventDefault()
																																handleChildClick(value, "search", 'singleDropDown', "")
																															}
																														}}
																														singleSelection={true}
																													/>
																												</div>
																											: null}
																										</div>
																									:
																										<React.Fragment>
																										<input 
																											type="text" 
																											className={`inputField mt-3 ${state.hasError && (!state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] || state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] === "" || state["error_message_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] !== "")  ? 'dottedDangerTextboxLine' : ''}`}
																											placeholder={qry.field}
																											value={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																											onChange={e => onChangeTextString(state.selectedQuery.display_output[qry.field], state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, e.target.value)}
																										/>
																										{state.selectedQuery.display_output[qry.field].validation ?
																											<React.Fragment>
																											<i id={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer mr-2"></i>
																											<UncontrolledTooltip target={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field}>
																											<div className="uncontrolledTooltipLeftAlign">
																												{state.selectedQuery.display_output[qry.field].validation.data_type ?
																													<p className="mb-1 text-primary-color">
																														<span className="text-white mr-1">Data Type:</span>
																														{state.selectedQuery.display_output[qry.field].validation.data_type}
																													</p>
																												: null}
																												{state.selectedQuery.display_output[qry.field].validation.min ?
																													<p className="mb-1 text-primary-color">
																														<span className="text-white mr-1">Min length:</span>
																														{state.selectedQuery.display_output[qry.field].validation.min}
																													</p>
																												: null}
																												{state.selectedQuery.display_output[qry.field].validation.max ?
																													<p className="mb-1 text-primary-color">
																														<span className="text-white mr-1">Max length:</span>
																														{state.selectedQuery.display_output[qry.field].validation.max}
																													</p>
																												: null}
																											</div>
																											</UncontrolledTooltip>
																											</React.Fragment>
																										: null}
																										</React.Fragment>
																									}
																									</React.Fragment>
																								: state.selectedQuery.display_output[qry.field].output_type === "list" ?
																									<React.Fragment>
																									{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
																										<div style={{minWidth: "250px"}} onClick={(event) => {
																											event.preventDefault();
																											handleChildClick(event, 'child', 'muliselectDropDown', 'show_'+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)
																										}}>
																										<p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length+' Selected' : 'Select'}</p>
																										{state['show_'+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ?
																											<div className='position-relative'>
																												<MultiSelectSection 
																													fields={["value", "key"]}
																													// className={'position-unset'}
																													removeTop={true}
																													options={state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field]}
																													selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : []}
																													callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value }))}
																												/>
																											</div>
																										: null}
																										</div>
																									:
																										<React.Fragment>
																										<input 
																											type="text" 
																											className={`inputField mt-3 ${state.hasError && (!state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] || !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length)  ? 'dottedDangerTextboxLine' : ''}`}
																											placeholder={qry.field}
																											value={state[state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field]}
																											onKeyDown={e => onKeyDown(e, state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)}
																											onBlur={e => onBlur(e, state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)}
																											onChange={e => setState(prevState => ({ ...prevState,[state.selectedQuery.query_id+"_listInput_"+state.selectedQuery.display_output[qry.field].output_field]: e.target.value }))}
																										/>
																										{state.selectedQuery.display_output[qry.field].validation ?
																											<React.Fragment>
																											<i id={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer mr-2"></i>
																											<UncontrolledTooltip target={state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field}>
																											<div className="uncontrolledTooltipLeftAlign">
																												{state.selectedQuery.display_output[qry.field].validation.data_type ?
																													<p className="mb-1 text-primary-color">
																														<span className="text-white mr-1">Data Type:</span>
																														{state.selectedQuery.display_output[qry.field].validation.data_type}
																													</p>
																												: null}
																												{state.selectedQuery.display_output[qry.field].validation.min ?
																													<p className="mb-1 text-primary-color">
																														<span className="text-white mr-1">Min length:</span>
																														{state.selectedQuery.display_output[qry.field].validation.min}
																													</p>
																												: null}
																												{state.selectedQuery.display_output[qry.field].validation.max ?
																													<p className="mb-1 text-primary-color">
																														<span className="text-white mr-1">Max length:</span>
																														{state.selectedQuery.display_output[qry.field].validation.max}
																													</p>
																												: null}
																											</div>
																											</UncontrolledTooltip>
																											</React.Fragment>
																										: null}
																										</React.Fragment>
																									}

																									{state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ? 
																										state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																											state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																												return(
																													<span key={'tab_'+addIndex} className={`badge-square badge-secondary mt-3 f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																														{state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field].map(res => {
																															return(
																															res.value === tab ?
																																res.value +(res.key !== res.value ? " ("+res.key+")" : "")
																															: null
																															)
																														})}
																														<i onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
																													</span>
																												)
																											})
																										: state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																											<span className={`badge-square badge-secondary mt-3 f13 align-self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																										: null
																									:
																										state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																											return(
																												<span key={'tab1_'+addIndex} className={`badge-square badge-secondary mt-3 f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																												{tab}
																													<i onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
																												</span>
																											)
																										})
																									}
																									</React.Fragment>
																								: null
																							: state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "dropdown" ?
																								state.selectedQuery.display_output[qry.field].output_type === "list" ?
																									<React.Fragment>
																									{/* <div className={`multiSelectOption ${state.hasError && (!state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] || !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].length) ? 'dangerMultiSelectLine' : ''} align-self-end mt-3 d-flex`}>
																										<ReactMultiSelectCheckboxes
																											placeholderButtonLabel="Select"
																											isSearchable={true}			
																											getDropdownButtonLabel={() => getMultiSelectedCountNew(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : [])}
																											options={state[state.selectedQuery.query_id+"_drop_options_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_drop_options_"+state.selectedQuery.display_output[qry.field].output_field].map(res => ({
																												value: res,
																												label: res,
																											}))}
																											onChange={event => {
																												handleMultiSelectChange(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, event, state[state.selectedQuery.query_id+"_drop_options_"+state.selectedQuery.display_output[qry.field].output_field]) }
																											}
																											value={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map(item => ({
																												value: item
																											}))}
																										/>
																									</div> */}
																									
																									{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && !state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ? 
																									state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
																										return(
																											<span key={'tab2_'+addIndex} className={`badge-square badge-secondary mt-3 f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
																											{tab}
																												<i onClick={ () => removeSelectedBadge(state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
																											</span>
																										)
																									})
																									: state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
																										<span className={`badge-square badge-secondary mt-3 f13 align-self-end p-1 ml-2 mr-1 mb-1`} > All </span>
																									: null}
																									</React.Fragment>
																								: state.selectedQuery.display_output[qry.field].output_type === "string" ? 
																									<div className='' style={{minWidth: "250px"}} onClick={(event) => {
																										event.preventDefault();
																										handleChildClick(event, 'child', 'singleDropDown', "show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field)
																									}}>
																										<p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>
																											{state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : 'Select'}
																										</p>
																										{state["show_"+state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ?
																											<div className='position-relative'>
																												<MultiSelectSection 
																													fields={["value", "option"]}
																													removeTop={true}
																													widthClass={'minWidth220'}
																													options={state[state.selectedQuery.query_id+"_options_"+state.selectedQuery.display_output[qry.field].output_field]}
																													selectedValues={state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field] : ''}
																													callbackMultiSelect={(value) => {
																														if(!value || typeof(value) === 'string') {
																															setState(prevState => ({ 
																																...prevState,
																																[state.selectedQuery.query_id+"_"+state.selectedQuery.display_output[qry.field].output_field]: value
																															}))
																														} else {
																															value.preventDefault()
																															handleChildClick(value, "search", 'singleDropDown', "")
																														}
																													}}
																													singleSelection={true}
																												/>
																											</div>
																										: null}
																									</div>
																								: null
																							: null}
																						</React.Fragment>
																						}
																					</span>
																					)
																				})}
																				{state.selectedQuery.required_fields ?
																					<React.Fragment>
																					{state.selectedQuery.required_fields.includes("account_id") ?
																						<React.Fragment>
																						<p className="mb-0 mr-2 text-info mt-3">for Account</p>
																						<div className="" style={{minWidth: "150px"}} onClick={(event) => {
																							event.preventDefault();
																							handleChildClick(event, 'child', 'muliselectDropDown', state.selectedQuery.query_id+"_show_selectedQueryAccount")
																						}}>
																							<p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state[state.selectedQuery.query_id+"_selectedQueryAccount"] && state[state.selectedQuery.query_id+"_selectedQueryAccount"].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryAccount"]: !state[state.selectedQuery.query_id+"_show_selectedQueryAccount"]  }))}>{state[state.selectedQuery.query_id+"_selectedQueryAccount"] && state[state.selectedQuery.query_id+"_selectedQueryAccount"].length ? state[state.selectedQuery.query_id+"_selectedQueryAccount"].length+' Selected' : 'Select'}</p>
																							{state[state.selectedQuery.query_id+"_show_selectedQueryAccount"] ?
																								<div className='position-relative'>
																									<MultiSelectSection 
																										fields={["account_id", "account_name"]}
																										// className={'position-unset'}
																										removeTop={true}
																										// borderClass={'boderDottedBottom'}
																										options={state.accounts}
																										selectedValues={state[state.selectedQuery.query_id+"_selectedQueryAccount"] ? state[state.selectedQuery.query_id+"_selectedQueryAccount"] : []}
																										callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_selectedQueryAccount"]: value }))}
																										closeDiv={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryAccount"]: false }))}
																									/>
																								</div>
																							: null}
																						</div>
																						</React.Fragment>
																					: null}
																					{state.selectedQuery.required_fields.includes("region") ?
																						<React.Fragment>
																						<p className="mb-0 mx-2 text-info mt-3">Region</p>
																						<div className='' style={{minWidth: "150px"}} onClick={(event) => {
																							event.preventDefault();
																							handleChildClick(event, 'child', 'muliselectDropDown', state.selectedQuery.query_id+"_show_selectedQueryRegion")
																						}}>
																							<p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryRegion"]: !state[state.selectedQuery.query_id+"_show_selectedQueryRegion"]  }))}>{state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].length ? state[state.selectedQuery.query_id+"_selectedQueryRegion"].length+' Selected' : 'Select'}</p>
																							{state[state.selectedQuery.query_id+"_show_selectedQueryRegion"] ?
																								<div className='position-relative'>
																									<MultiSelectSection 
																										fields={["region", "name"]}
																										// className={'position-unset'}
																										removeTop={true}
																										// borderClass={'boderDottedBottom'}
																										widthClass={'minWidth220'}
																										options={state.regions}
																										selectedValues={state[state.selectedQuery.query_id+"_selectedQueryRegion"] ? state[state.selectedQuery.query_id+"_selectedQueryRegion"] : []}
																										callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_selectedQueryRegion"]: value }))}
																										closeDiv={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryRegion"]: false }))}
																									/>
																								</div>
																							: null}
																						</div>
																						{/* <di<v className={`multiSelectOption align-self-end mt-3`}>
																							><ReactMultiSelectCheckboxes
																								placeholderButtonLabel="Select"														
																								getDropdownButtonLabel={() => getMultiSelectedCount(state.selectedQuery.query_id+"_selectedQueryRegion", state[state.selectedQuery.query_id+"_selectedQueryRegion"] ? state[state.selectedQuery.query_id+"_selectedQueryRegion"] : [])}
																								isSearchable={true}
																								options={state.regions && state.regions.map(reg => ({
																									value: reg.region,
																									label: reg.name,
																								}))}
																								onChange={event => {
																									handleMultiSelectChangeNew("regions", state.selectedQuery.query_id+"_selectedQueryRegion", event) }
																								}
																								value={state[state.selectedQuery.query_id+"_selectedQueryRegion"] && state[state.selectedQuery.query_id+"_selectedQueryRegion"].map(item => ({
																									value: item
																								}))}
																							/> 
																						</div>*/}
																						</React.Fragment>
																					: null}
																					{state.selectedQuery.required_fields.includes("duration") ?
																						<React.Fragment>
																						<p className="mb-0 mx-2 text-info mt-3">Duration</p>
																						<div className='' style={{minWidth: "250px"}} onClick={(event) => {
																							event.preventDefault();
																							handleChildClick(event, 'child', 'singleDropDown', "showDateOptions_"+state.selectedQuery.query_id)
																						}}>
																							<p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state.selectedQueryDuration ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state.selectedQueryDuration && durationOptions ? durationOptions.filter(e => e.value === state.selectedQueryDuration)[0].option : 'Select'}</p>
																							{state['showDateOptions_'+state.selectedQuery.query_id] ?
																								<div className='position-relative'>
																									<MultiSelectSection 
																										fields={["value", "option"]}
																										// className={'position-unset'}
																										removeTop={true}
																										// borderClass={'boderDottedBottom'}
																										widthClass={'minWidth220'}
																										options={durationOptions}
																										selectedValues={state.selectedQueryDuration ? state.selectedQueryDuration : ''}
																										callbackMultiSelect={(value) => {
																											if(typeof(value) === 'string') {
																												if(value !== 'custom') {
																													setState(prevState => ({ 
																														...prevState,
																														selectedQueryDuration: value,
																														showQueryDateTimePicker: false,
																														getQueryDurationDetails: true
																													}))
																												} else {
																													setState(prevState => ({ 
																														...prevState,showQueryDateTimePicker: true, 
																														selectedQueryDuration: value
																													}))
																												}
																											} else {
																												value.preventDefault()
																												handleChildClick(value, "search", 'singleDropDown', "")
																											}
																										}}
																										// closeDiv={() => setState(prevState => ({ ...prevState, [state.selectedQuery.query_id+"_show_selectedQueryRegion"]: false }))}
																										singleSelection={true}
																									/>
																								</div>
																							: null}
																						</div>
																						{state.showQueryDateTimePicker ?
																							<div className="d-flex mt-2">
																								<p className="mb-0 mx-2 text-info mt-2 pt-1">start&nbsp;time</p>
																								<Datetime 
																									value={momentDateGivenFormat(state.queryStartTime, 'DD MMM YYYY hh:mm A')}
																									onChange={handleStartTimeChange}
																									dateFormat={'DD MMM YYYY'}
																									timeFormat={'hh:mm A'}
																									inputProps={{ readOnly: true, class: "inputField" }}
																									className="w-100 mt-2"
																									isValidDate={queryDateTime}
																								/>
																								<p className="mb-0 mx-2 align-self-end text-info mt-2 pt-1">end&nbsp;time</p>
																								<Datetime
																									value={momentDateGivenFormat(state.queryEndTime, 'DD MMM YYYY hh:mm A')}
																									onChange={handleEndTimeChange}
																									dateFormat={'DD MMM YYYY'}
																									timeFormat={'hh:mm A'}
																									inputProps={{ readOnly: true, class: "inputField" }}
																									className="w-100 mt-2"
																									isValidDate={queryDateTime}
																								/>
																							</div>
																						: 
																							// <div className="d-flex mt-4">
																							// 	<p className="mb-0 mx-2 align-self-end text-info mt-3">start&nbsp;time</p>
																							// 	<span className="mt-3 text-white">{momentDateGivenFormat(state.queryStartTime, "DD MMM YYYY hh:mm A")}</span>
																							// 	<p className="mb-0 mx-2 align-self-end text-info mt-3">end&nbsp;time</p>
																							// 	<span className="mt-3 text-white">{momentDateGivenFormat(state.queryEndTime, "DD MMM YYYY hh:mm A")}</span>
																							// </div>
																							null
																						}
																						
																						</React.Fragment>
																					: null}
																					</React.Fragment>
																				: null}
																			</div>
																		</div>
																		<div className={`d-flex justify-content-end w-99 mt-2 ${state.queryLoading ? "disabled" : ""}`}>
																			{state.queryErrorMessage && state.queryErrorMessage !== "" ?
																				// <div className="w-100">
																				<React.Fragment>
																					<p className="mb-0 align-self-center text-danger mr-3">{state.queryErrorMessage.message ? state.queryErrorMessage.message : state.queryErrorMessage.errorMessage ? state.queryErrorMessage.errorMessage : ""}</p>
																				</React.Fragment>
																			: null}
																			<button className={`btn btn-primary f12 align-self-center mr-2 
																				${state.hasError ? "" : ""}`} 
																				onClick={() => setState(prevState => ({ ...prevState,queryErrorMessage: "", executeQuery: true }))}>
																					{/* {state.queryLoading ?
																					<Spinner className='text-center mr-1' color='light' size='sm' />
																					: null} */}
																					Run Query
																				</button>
																			<button className={`btn btn-light f12 align-self-center`}onClick={() => setState(prevState => ({ ...prevState, ['expand_'+i]: false, selectedQuery: "" }))}>Close</button>
																		</div>
																	</div>
																</div>
															: null}
														</div>
													)
												})
											:
												<div className='d-flex justify-content-center m-4'>
													There are no data on this criteria. Please try adjusting your filter.
												</div>
											}
										</div>
									</div>
								</div>
								</React.Fragment>
							}
						</div>
					}
				</div>
			</div>
		</div>
	)
}

export default QueryEvents