/*************************************************
 * Kiosk
 * @exports
 * @file JobDetails.js
 * @author Prakash // on 28/11/2023
 * @copyright © 2023 Kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { getJobTotalCost } from '../../../actions/Collider/SpendAction'
import { momentConvertionLocalToUtcTime } from '../../../utils/utility';
import StackedCircleBarChart from '../../common/charts/StackedCircleBarChart';
import { Spinner } from 'reactstrap';
const JobDetails = (props) => {
    const rightRadialStackedBarWidthRef = useRef();
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        callGetJobTotalCostFunction: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 10,
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.callGetJobTotalCostFunction) {
            setState(prevState => ({ ...prevState, callGetJobTotalCostFunction: false }));
            let params = {
                // 'duration': '30d',
                'start_time': momentConvertionLocalToUtcTime(props.startDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentConvertionLocalToUtcTime(props.endDate, 'YYYY-MM-DD HH:mm:ss'),
                'aggregate_by':'job_name', 
                'radial':true
            }
            if(props.selectedDetails && props.selectedDetails.provider) {
                params.provider = props.selectedDetails.provider
            }
            
            if(props.selectedDetails && props.selectedDetails.account_id) {
                params.account_id = props.selectedDetails.account_id
            }

            if(props.selectedDetails && props.selectedDetails.region) {
                params.region = props.selectedDetails.region
            }

            if(props.selectedDetails && props.selectedDetails.cluster_name) {
                params.cluster_name = props.selectedDetails.cluster_name
            }
            
            if(props.selectedDetails && props.selectedDetails.queue_name) {
                params.queue_name = props.selectedDetails.queue_name
            }

            dispatch(getJobTotalCost(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length && response.results[0].data ? response.results[0].data : {}
                        let totalPages = 1                
                        if(Object.entries(results).length > state.perPage) {
                            totalPages = Math.ceil(Object.entries(results).length / state.perPage)
                        }
                        
                        setState(prevState => ({
                            ...prevState,
                            radialTrendResponse: results,
                            filterData: results,
                            totalPages,
                            showLoading: false
                        }));
                    }
            })
        }
    }, [dispatch, state.callGetJobTotalCostFunction, state.perPage, props])

    const count = useCallback((array, key) => {
		let data = array.reduce(function(r, a) {
			return r + a[key]
		}, 0)

		return data
	}, [])

    useEffect(() => {
        if(state.radialTrendResponse && Object.entries(state.radialTrendResponse).length) {
            let graphData = state.radialTrendResponse //radialBarData //radialTrendResponse
            let rightSideGraphColors = ['#8e3a80', '#a22ee6', '#bc47ff', '#c35cff', '#cb70ff', '#d285ff', '#da99ff', '#e15ad2', '#fb74eb', '#fb83ed', '#fc93f0', '#fca2f2', '#fdb2f4', '#fdc1f6']
            let keys = ['Cost']

            let rightSideArray = []
            let rightSideLowest = 0
            let rightSideHighest = 0
            let rightSideTotal = 0
            let rightSideTotalTableData = {}
            graphData && Object.entries(graphData).forEach(([key, value], index) => {
                let State = 'files_' + index
                let dataRow = {}
                let count = value
                dataRow.Cost = value

                dataRow.State = State
                dataRow.page = 'MultipleKeys'
                dataRow.name = key
                dataRow.total = count
                rightSideTotal = graphData.length
                if (rightSideHighest < count) {
                    rightSideHighest = count
                }
                if(count < rightSideLowest) {
                    rightSideLowest = count
                }
                if (count > 0) {
                    rightSideArray.push(dataRow)
                }

                rightSideTotalTableData[State] = dataRow
            })

            let rightSideTotalData = {}
            let data = {}
            data.name = 'Jobs'
            keys.forEach((key, j) => {
                data[key] = count(rightSideArray, key)
            })
            rightSideTotalData.data = data

            if (rightSideArray.length < 25) {
                let length = rightSideArray.length
                for (let i = length; i <= 25; i++) {
                    let dataRow = {}
                    dataRow.State = 'files_' + i
                    dataRow.name = 'empty_data'
                    let count = rightSideLowest / 5
                    dataRow.Cost = count
                    rightSideArray.push(dataRow)
                }
            }
            
            // rightSideArray = _.orderBy(rightSideArray, ['fail_count'], ['desc'])

            setState(prevState => ({ 
                ...prevState, 
                rightSideGraphColors,
                rightSideArray,
                rightSideKeys: keys,
                rightSideHighest,
                rightSideTotalData,
                rightSideTotal,
                rightSideGraphId: 'totalThreats',
                rightSideSvgGraphId: 'svgTotalThreats',
                showRightSideRadialChart: true,
                hideDistributionSection: true,
                showRadialLoading: false,
                rightSideTotalTableData,
            }))
        }
    }, [state.radialTrendResponse, count])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const handleClickOutside = (event) => {};

    return (
        state.showLoading ?
            <div className='d-flex justify-content-center m-4'>
                <Spinner className='text-center' color='light'   />
            </div>
        : state.filterData && Object.entries(state.filterData).length ?
            <div className='row mx-0' onClick={handleClickOutside}>
                <div className='col-lg-5 py-2 pl-2 pr-2'>
                    <div className='card p-3'>
                        <div className='d-flex justify-content-between mt-2 w-100'>
                            <div className='align-self-center'>
                                <h6 className='text-white mb-0 '>Jobs</h6>
                                <small className='mt-1 mb-0 small text-primary-color'>View jobs</small>
                            </div>
                        </div>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-sm-9' ref={rightRadialStackedBarWidthRef}>
                                {state.showRightSideRadialChart ?
                                    <StackedCircleBarChart
                                        ID={state.rightSideGraphId}
                                        svgID={state.rightSideSvgGraphId}
                                        width={
                                            rightRadialStackedBarWidthRef.current &&
                                            rightRadialStackedBarWidthRef.current.offsetWidth
                                        }
                                        data={state.rightSideArray}
                                        keys={state.rightSideKeys}
                                        userHighestValue={true}
                                        highestValue={state.rightSideHighest}
                                        hoverData={state.rightSideTotalData}
                                        totalValue={state.rightSideTotal}
                                        selectedState={
                                            state.selectedChartControl ? state.selectedChartControl : ''
                                        }
                                        tableData={(data, State) => {
                                            if (State && State !== state.selectedChartControl) {
                                                setState(prevState => ({ 
                                                    ...prevState,
                                                    selectedChartControl: State, 
                                                    // showRightSideRadialChart: false,
                                                    refreshStackedCircleBar: true
                                                }))
                                            }
                                        }}
                                        legendText={'Jobs'}
                                        colors={state.rightSideGraphColors}
                                    />
                                : null }
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-7 py-2 pl-2 pr-2'>
                    <div className='card bg-backGroundGray p-3'>
                        <div className='d-flex justify-content-between mt-2'>
                            <p className='mb-1 text-dark'>Showing job cost details</p>
                            <div className='d-flex w-60 justify-content-end'>
                                {state.radialTrendResponse && Object.entries(state.radialTrendResponse).length > state.perPage ?
                                    <div className='pagination errorPagePagination f12 mr-2'>
                                        <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                        <span className='displayNone'>
                                            Go to
                                            <input
                                                type='number'
                                                value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ''
                                                    this.gotoPage(page)
                                                }}
                                                className='ml-1 inputClass'
                                            />
                                        </span>
                                        <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                    </div>
                                : null}
                                
                                {/* {state.radialTrendResponse && Object.entries(state.radialTrendResponse).length ?
                                    <div className='w-50'>
                                        <Search
                                            data={state.radialTrendResponse ? state.radialTrendResponse : {}}
                                            applyTags={false}
                                            applyLiteDarkTags={true}
                                            topClassName={'bg-extraLightGray text-black f12 border-extraLightGray'}
                                            searchClassName={'px-2 f12'}
                                            searchIconColor={'text-black f12'}
                                            searchPlaceHolder={'Search....'}
                                            className={'bg-transparent text-black pl-0 form-control-sm f12 w-80'}
                                            filteredData={(filterData) => setState(prevState => ({ ...prevState, filterData }))}
                                        />
                                    </div>
                                : null} */}

                            </div>
                        </div>
                        <div className='table-responsive mt-2'>
                            <table className='table table-striped mb-0'>
                                <thead className='text-black bg-lightGray'>
                                    <tr>
                                        <th className='f16'>Job Name</th>
                                        <th className='f16'>Cost</th>
                                    </tr>
                                </thead>
                                <tbody className='text-black bg-white'>
                                    {state.filterData && Object.entries(state.filterData).slice(state.startRecord, state.startRecord + state.perPage).map(([key, value], index) => {
                                        return(
                                            <tr key={'tr_'+index} className={`bg-white ${!index ? '' : 'border-top'}`}>
                                                <td>{key}</td>
                                                <td>{value}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        :
            <div className='d-flex justify-content-center m-4'>
                There are no job data on this {props.selectedType}.
            </div>
    );
};

export default JobDetails