/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ClusterDetails.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from 'react-router-dom';
import { setCommonPropsDetails } from '../../actions/commonActionNew'
import { deleteCluster, listClusterDetails } from '../../actions/Collider/ClusterAction'
import _ from 'lodash'
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, noSpace } from '../../utils/utility'
import { Store as CommonNotification } from 'react-notifications-component';

import { CSVLink } from 'react-csv';
import ClusterDetailsSidePanel from './ClusterDetailsSidePanel'
import Search from '../common/SearchWithHiddenInput'
import FilterSection from '../common/FiltersSection';
import { URL_PATH } from '../../config/urlPath';
import { setHpcPropsDetails } from '../../actions/Collider/HpcAction';

const ClusterDetails = () => {        
    const clickOutside = useRef()
    const [state, setState] = useState({
        showLoading: true,

        //filter section start

        selectedProvider: '',
        selectedAccount: [],
        isAccountOpen: false,

        selectedRegion: [],
        isRegionOpen: false,
        
        //filter section end

        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 5,

        clusterDetails: [],
        selectedClusterDetails: {},
        filteredClusterDetails: [],
        clusterheaders: [],
        
        topSectionFilters: ['provider', 'project', 'account', 'region']
    })
    
    
    const dispatch = useDispatch(); // Create a dispatch function
    const navigate = useNavigate();
    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const location = useLocation();
    const locationData = location.state || false
    const currentPath = location.pathname;

    // useEffect(() => {
    //     if(userMenu && Object.entries(userMenu).length) {
	// 		let actionsAllowed = []
	// 		userMenu.menu && userMenu.menu.forEach(row => {
    //             if(row.submenulist && row.submenulist.length) {
    //                 if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
    //                     actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
    //                 }
	// 			} else {
    //                 if(row.action) {
    //                     actionsAllowed = row.action
    //                 }
    //             }
	// 		})
    //         setState(prevState => ({ ...prevState, actionsAllowed }))
	// 	}
    // }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, startRecord: 0,  currentPage: 1 }))  
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            params.size = 5
            dispatch(listClusterDetails(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results && response.results.length ? response.results : []

                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextToken && state.nextToken.length) {
                            totalResults = state.clusterDetails &&  state.clusterDetails.length ? state.clusterDetails : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(results && results.length) {
                            totalResults = totalResults.concat(results)
                        }

                        let totalPages = 1
                        if(totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
                        
                        setState(prevState => ({ ...prevState, clusterDetails: totalResults, filteredClusterDetails: totalResults, detailsFormat: response && response.cluster_details ? response.cluster_details : [], showLoading: false, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));
                    }
            })
        }
    }, [state.callSearch, dispatch, state.perPage, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.selectedProjectTags, state.clusterDetails, state.currentPage, state.nextToken, state.startRecord])

    useEffect(() => {
        if(state.clusterDetails && state.clusterDetails.length) {
            let dataResult = state.clusterDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, clusterheaders: headers }))
        }

        //status graph of how many running days 
        state.clusterDetails && state.clusterDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name:'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label':'Days',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, ["statusDonutData_"+row.cluster_name]: statusDonutData }))
        })
    }, [state.clusterDetails])

    useEffect(() => {
        if(state.selectedClusterDetails && Object.entries(state.selectedClusterDetails).length)
        setState(prevState => ({ ...prevState, showClusterDetails: true }))
    }, [state.selectedClusterDetails])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextToken && state.nextToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callSearch: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const gotoPage = (pageNumber) => {
        // Go to the specified page and update state        
        if(pageNumber > 0  && pageNumber <= state.totalPages) {
            let startRecord = (pageNumber - 1) * state.perPage
            setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
        } else  if(pageNumber > state.totalPages) {
            setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
        } else {
            setState(prevState => ({ ...prevState, pageNumber }))
        }
    }

    const navigateTo = () => {
        navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Create' } });
    }
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value, deleteConfirmMessage: '' }))
	}

    const deleteFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        params.provider = item.provider
        params.account_id = item.account_id
        params.region = item.region
        params.cluster_name = item.cluster_name
        
        dispatch(deleteCluster(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                }
            })
    }

    useEffect(() => {
        if(state.editCluster && state.editClusterDetails) {
            setState(prevState => ({ ...prevState, editCluster: false }))            
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Edit', selectedData: state.editClusterDetails } });
        }
    }, [state.editCluster, state.editClusterDetails, navigate, dispatch])

    useEffect(() => {
        if(state.fixCluster && state.fixClusterDetails) {
            setState(prevState => ({ ...prevState, fixCluster: false }))            
            navigate(URL_PATH.CREATE_CLUSTER, { state: { pageType: 'Fix', selectedData: state.fixClusterDetails } })
        }
    }, [state.fixCluster, state.fixClusterDetails, navigate, dispatch])
    
    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            {/* {state.showCreateClusterPanel ? 
                <CreateCluster
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showCreateClusterPanel: false }))}
                />
            : null} */}
            {state.showClusterDetails ? 
                <ClusterDetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showClusterDetails: false, selectedClusterDetails: {} }))}
                    selectedClusterDetails={state.selectedClusterDetails}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-3 col-md-12 col-sm-12 py-2 align-self-center'>
                        <h6 className='text-white m-0'>Cluster Details</h6>
                        <p className='text-white m-0'>Consolidated view of cluster details</p>
                    </div>
                    <div className='col-lg-9 col-md-12 col-sm-12 py-2' ref={clickOutside}>
                        <div className='row mx-0 topBasicFilter justify-content-end'>
                            <FilterSection
                                filters={state.topSectionFilters}
                                selectedFilters={(filter) => handleSelectedFilters(filter)}
                                clickedonParent={state.clickedonParent}
                                filterAdvancedSearch={false}
                                onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                                onReset={onReset}
                            />
                        </div>
                    </div>
                </div>
                <div className='row mx-0'>
                    <div className='col-lg-12 p-3'>
                        <div className='bg-transparent'>
                            <div className='d-flex justify-content-between'>
                                <div className=''>
                                    <p className='mb-0 f16 text-white'><b>Cluster List</b></p>
                                    <p className='f12 mb-1 align-self-center pt-1'>Showing {state.filteredClusterDetails && state.filteredClusterDetails.length} of total {state.clusterDetails && state.clusterDetails.length} cluster(s)</p>
                                </div>
                                <div className='align-self-center w-30'>
                                    <div className='d-flex justify-content-end'>
                                        {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                                            <CSVLink 
                                                data={state.filteredClusterDetails ? state.filteredClusterDetails : []} 
                                                headers={state.clusterheaders ? state.clusterheaders : []}
                                                filename={'cluster-lists.csv'}
                                                className={'mt-2'}
                                                target='_blank'
                                            >
                                                <span className='d-flex text-primary-color mr-2'>
                                                    <i className='fas fa-download text-primary-color mr-2'/>
                                                    {/* csv */}
                                                </span>
                                            </CSVLink>
                                        : null}
                                        {state.clusterDetails && state.clusterDetails.length ?
                                            <Search
                                                data={state.clusterDetails ? state.clusterDetails : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-white f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white form-control-sm f12"}
                                                filteredData={(filteredClusterDetails) => {
                                                    let totalPages = 1
                                                    if(filteredClusterDetails.length > state.perPage) {
                                                        totalPages = Math.ceil(filteredClusterDetails.length / state.perPage)
                                                    }
                                                    setState(prevState => ({ ...prevState, filteredClusterDetails, totalPages, startRecord: 0, currentPage: 1 })
                                                )}}
                                            />
                                        : null}
                                        {/* <button className={`btn bg-green p-1 text-white ml-2`} onClick={() => setState(prevState => ({ ...prevState, showCreateClusterPanel: true }))}>Create&nbsp;Cluster</button> */}
                                        <button className={`btn bg-green p-1 text-white ml-2`} onClick={() => navigateTo()}>Create&nbsp;Cluster</button>
                                    </div>

                                    {state.totalRecords > state.perPage ?
                                        <div className='pagination errorPagePagination f12'>
                                            <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                            <span className='displayNone'>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ''
                                                        gotoPage(page)
                                                    }}
                                                    className='ml-1 inputClass'
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='vh100'>
                                {state.filteredClusterDetails && state.filteredClusterDetails.length ?
                                    state.filteredClusterDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, index) => {
                                        return(
                                            <div className={`d-flex mb-2 bg-dark3 pr-2`} key={"row_"+(state.startRecord + index)}> 
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2'>
                                                        <div className="d-flex">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Cluster</p>
                                                                <p className="mb-0 text-white">
                                                                    { item.cluster_name ? item.cluster_name : ''}
                                                                    <span className={`mb-0 mr-2 f18 text-white`}>
                                                                        (v {item.cluster_version})
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Asset</p>
                                                                <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Resources</p>
                                                                <p className="mb-0 text-white">
                                                                    {item.account_id ?
                                                                        <span>{' '+item.account_id}</span>
                                                                    : null}
                                                                    {item.region ?
                                                                        <span>{' '+item.region}</span>
                                                                    : null}
                                                                    {item.resource_type ?
                                                                        <span>{' '+item.resource_type}</span>
                                                                    : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="px-4 py-2">
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='align-self-center'>
                                                                {item.user_count ?
                                                                    <p className='mb-2'>
                                                                        Slum Version <span className='text-white ml-2'>{item.slum_version}</span>
                                                                    </p>
                                                                : null}
                                                                {item.user_count ?
                                                                    <p className='mb-2'>
                                                                        Users <span className='text-white ml-2'>{item.user_count}</span>
                                                                    </p>
                                                                : null}
                                                                {item.description ?
                                                                    <p className='mb-2'>
                                                                        Description <span className='text-white ml-2'>{item.description}</span>
                                                                    </p>
                                                                : null}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </div>
                                                {/* <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2'>
                                                        <div className="d-flex">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Application Name</p>
                                                                <p className="mb-0 text-white">{item.tags && item.tags.filter(e => e.key === "Application_Name") && item.tags.filter(e => e.key === "Application_Name").length ? item.tags.filter(e => e.key === "Application_Name")[0].value : <span>&nbsp;</span>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Application Owner</p>
                                                                <p className="mb-0 text-white">{item.tags && item.tags.filter(e => e.key === "Application_Owner") && item.tags.filter(e => e.key === "Application_Owner").length ? item.tags.filter(e => e.key === "Application_Owner")[0].value : <span>&nbsp;</span>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Department</p>
                                                                <p className="mb-0 text-white">{item.tags && item.tags.filter(e => e.key === "Department") && item.tags.filter(e => e.key === "Department").length ? item.tags.filter(e => e.key === "Department")[0].value : <span>&nbsp;</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2'>
                                                        <div className="d-flex">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Name</p>
                                                                <p className="mb-0 text-white">{item.tags && item.tags.filter(e => e.key === "Name") && item.tags.filter(e => e.key === "Name").length ? item.tags.filter(e => e.key === "Name")[0].value : <span>&nbsp;</span>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Project</p>
                                                                <p className="mb-0 text-white">{item.tags && item.tags.filter(e => e.key === "Project") && item.tags.filter(e => e.key === "Project").length ? item.tags.filter(e => e.key === "Project")[0].value : <span>&nbsp;</span>}</p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Environment</p>
                                                                <p className="mb-0 text-white">{item.tags && item.tags.filter(e => e.key === "Environment") && item.tags.filter(e => e.key === "Environment").length ? item.tags.filter(e => e.key === "Environment")[0].value : <span>&nbsp;</span>}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2'>
                                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(0, 3).map(([key, value], tIndex) => {
                                                            return(
                                                                <div key={'tag_'+tIndex} className={`d-flex ${!tIndex ? "" : "pt-2"}`}>
                                                                    <div className="py-1 w-100">
                                                                        <p className="b-block mb-0">{key}</p>
                                                                        {value ? 
                                                                            <p className="mb-0 text-white">{value}</p>   
                                                                        : 
                                                                            <span>&nbsp;</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2'>
                                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(3, 6).map(([key, value], tIndex) => {
                                                            return(
                                                                <div key={'ptag_'+tIndex} className={`d-flex ${!tIndex ? "" : "pt-2"}`}>
                                                                    <div className="py-1 w-100">
                                                                        <p className="b-block mb-0">{key}</p>
                                                                        {value ? 
                                                                            <p className="mb-0 text-white">{value}</p>   
                                                                        : 
                                                                            <span>&nbsp;</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>                                                
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2 d-flex'>
                                                        <div className='p-2 rounded border mr-2 w-80'>
                                                            <div className="d-flex">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">Status</p>
                                                                    <p className="mb-0 text-white">
                                                                        {item.status ?
                                                                            <span className='badge bg-mulbery hiddenEllipses'>{item.status} </span>
                                                                        : 
                                                                            <span>&nbsp;</span>
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">Days Running</p>
                                                                    <p className="mb-0 text-white">
                                                                        <span>{item.number_of_days ? item.number_of_days : 0}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Created at</p>
                                                                    <p className="mb-0 text-white">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : <span>&nbsp;</span>}</p>
                                                                </div>
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Modified at</p>
                                                                    <p className="mb-0 text-white">{item.last_modified_at ?  momentConvertionUtcToLocalTime(item.last_modified_at, 'DD MMM YYYY HH:mm') : 'Not Modified'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mb-0 align-self-center ml-3'>
                                                            <p className='text-cyon w-100' onClick={() => setState(prevState => ({ ...prevState, selectedClusterDetails: item }))}>Details</p>
                                                            {item.status === "CREATE_FAILED" ?
                                                                <p className='text-cyon w-100' onClick={() => setState(prevState => ({ ...prevState, fixClusterDetails: item, fixCluster: true }))}>Fix</p>
                                                            :
                                                                <p className='text-cyon w-100' onClick={() => setState(prevState => ({ ...prevState, editClusterDetails: item, editCluster: true }))}>Edit</p>
                                                            }
                                                            <p className='text-danger w-100 mb-0' onClick={() => setState(prevState => ({ ...prevState, selectedDeleteItem: item, deleteModal: true }))}>Delete</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 displayNone col-md-3 col-sm-3 pr-0 cursorPointer align-self-center d-flex justify-content-end pr-3">
                                                    <div className="featureBox shadow-none mr-2">
                                                        <div className="px-3 rounded bg-black border-info">
                                                            <div className="row p-2 sectionBoxNew">
                                                                <div className={`w-100`}>
                                                                    <div className="py-1">
                                                                        <p className="b-block text-center mb-0 f16">Cost (USD)
                                                                            {/* <span className="ml-2 f15 mb-0 text-warning">300</span> */}
                                                                        </p>
                                                                    </div>
                                                                    <div className="py-1">
                                                                        <h5 className="ml-2 text-center mb-0 text-warning">{!index ? 1400.24 : 2000.50}</h5>
                                                                    </div>
                                                                    {/* <div className="py-1">
                                                                        <p className="b-block mb-0">Memory 
                                                                        <span className="ml-2 mb-0 text-white">1</span></p>
                                                                    </div>
                                                                    <div className="py-1">
                                                                        <p className="b-block mb-0">Network (gbps):
                                                                        <span className="ml-2 mb-0 text-white">5</span></p>
                                                                    </div> */}
                                                                    <div className='bg-black p-3 rounded'>
                                                                        <p className="mb-1">Price (USD)</p>
                                                                        <div className='d-flex'>
                                                                            <div className='w-60 pr-2'>
                                                                                <p className="b-block mb-0">On&nbsp;Demand</p>
                                                                                <p className="mb-0 text-warning">4.352
                                                                                </p>
                                                                            </div>
                                                                            <div className='w-40'>
                                                                                <p className="b-block mb-0">Monthly</p>
                                                                                <p className="mb-0 text-success">3133.44
                                                                                    {/* <span className="text-primary-color f12 ml-1">(31%)</span> */}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                : 
                                    <div className='d-flex justify-content-center m-4'>
                                        There are no data on this criteria. Please try adjusting your filter.
                                    </div>
                                }
                                
                                {state.totalRecords > state.perPage ?
                                    <div className='d-flex justify-content-end'>
                                        <div className='pagination errorPagePagination f12 mt-2'>
                                            <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                            <span className='displayNone'>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ''
                                                        gotoPage(page)
                                                    }}
                                                    className='ml-1 inputClass'
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>
                <ModalHeader toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>Delete</ModalHeader>
                <ModalBody>
                    Please enter the
                    <span className='f18 mr-1'>
                        <span className='f14 mx-1'>Cluster name</span>
                        {state.selectedDeleteItem && state.selectedDeleteItem.cluster_name ? state.selectedDeleteItem.cluster_name : ''}
                    </span> 
                    to delete
                    <Input
                        type='text'
                        placeholder={'Enter cluster name'}
                        className={`inputTextbox bg-transparent border-lightGray text-black`}
                        // maxlength='128'
                        value={state.name ? state.name : ''}
                        onChange={e => handleInputChange('name', noSpace(e.target.value))}
                    />
                    {state.deleteConfirmMessage ?
                        <small className='text-red'>{state.deleteConfirmMessage}</small>
                    : null}
                    {/* Are you sure you want to delete the <span className='f18 mx-1 text-red'>{state.selectedDeleteItem && state.selectedDeleteItem.qos_name ? state.selectedDeleteItem.qos_name : 'Name'}</span> qos */}

                </ModalBody>
                <ModalFooter className="border-0">
                <Button color="primary" onClick={() => {
                    if(!state.showDeleteLoading) {
                        deleteFunction()
                    }
                }}>
                    {state.showDeleteLoading ?
                        <Spinner size='sm' color='light' className='mx-2' />
                    : null}Ok
                </Button>{' '}
                <Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))}>
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default ClusterDetails