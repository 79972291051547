/*************************************************
 * Collider
 * @exports
 * @file ApexDonutChartNew.js
 * @author Prakash // on 28/06/2023
 * @copyright © 2020 Collider. All rights reserved.
 *************************************************/

import React, { useCallback, useEffect, useState } from 'react'
import { capitalizeFirstLetter } from '../../../utils/utility'
import Chart from 'react-apexcharts'


const ApexDonutChartNew = (props) => {

    const [state, setState] = useState({})

	const drawGraph = useCallback((series, labels, colors, overAll, overAllLabel, graphHeading) => {
		let total = {}
		total['show'] = props.showTotal
		total['label'] = labels[0] === 'No Data' ? overAllLabel : overAllLabel
		total['fontSize'] = '10px'
		total['color'] = props.labelColor ? props.labelColor : '#FFFFFF'
		total['formatter'] = function (w) {
		    return labels[0] === 'No Data' ? '' : overAll
		}

		let fillData = {}
		if(props.gradient) {
			fillData = {
				type: 'gradient',
			}
		}
	
		let options = {
			stroke: {
				width: 0
			},	
			chart: {
				type: 'donut',
				sparkline: {
					enabled: true
				},
				zoom: {
					enabled: false,
				},
				width: props.width
				// offsetX: -10
			},
			legend: {
				show: labels[0] === 'No Data' ? false : props.legend,
				fontSize: '12px',
				fontFamily: 'Didact Gothic',
				labels: {
					colors: '#A9A9A9',
					useSeriesColors: false
				},
				width: props.legendWidth ?  props.legendWidth : "",				
				floating: false,
				position: 'right',
				horizontalAlign: 'center',
				markers: {
					width: 10,
					height: 10,
					strokeWidth: 0,
					strokeColor: '#FFFFFF',
					fillColors: undefined,
					radius: 12,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				},
				formatter: function(seriesName, opts) {
					return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
				},
				// tooltipHoverFormatter: function(seriesName, opts) {
				// 	return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex] + '</strong>'
				// }
			},
			dataLabels: {
				enabled: false
			},
			fill: fillData,
			labels: labels,
			// colors: ['#4A397C', '#6E6096', '#08B089', '#39BFA0', '#60687C', '#8E919B',  '#0092F3', '#23D0E7', '#8E6ECC', '#57E7C5'],
			colors: colors,
			tooltip: {
				enabled: true,
				custom: function({ series, seriesIndex, w }) {
					let color = w.globals.colors[seriesIndex]
					if(color === '#D9D9D6' || color === '#2D323E') {
						color = '#EDEDED'
					}
					let val = ''
					if(labels[0] !== 'No Data') {
						val = series[seriesIndex]
					}
					return '<div class="arrow_box d-flex">' +
                      '<span class="mr-2">' +  w.globals.labels[seriesIndex] + '</span>' +
                      '<span style="color:'+ color +'">' + val  + '</span>' +
                      '</div>'
				},
				// fixed: {
				// 	enabled: true,
				// 	position: 'topRight',
				// 	offsetX: 0,
				// 	offsetY: 0,
				// }
			},
			plotOptions: {
				offsetX: 10,
    			offsetY: 0,
				pie: {
					donut: {
						size: props.size,
						labels: {
							show: props.showTotalLable,
							value: {
								fontSize: '10px',
								fontWeight: 700,
								color: props.valueColor ? props.valueColor : '#FFFFFF',
								offsetY: 0
							},
							name: {
								fontSize: '10px',
								color: props.valueColor ? props.valueColor : '#FFFFFF',
								offsetY: -2
							},	
							total: total
						}
					},      
				}
			}
		}

        setState(prevState => ({ ...prevState, series, options, graphHeading }))
    }, [props])
    
    useEffect(() => {
        let overAll = 0
        let overAllLabel = 'Total'
        let graphData = props.graphData;
        let series = []
        let labels = []
		let graphHeading = ''
		let colors = props.colors
		let formatedColorsArray = []
		if(graphData === 'successFailMockData') {
			overAll = 110
			// overAllLabel = 'Total'
            series = [60, 50]
            labels = ['Success', 'Fail']
		} else if(graphData === 'mockData') {
			overAll = 84
            series = [30, 10, 4, 40]
            labels = props.labels ? props.labels : ['Critical', 'High', 'Medium', 'Low']
        } else {
			graphData && Object.entries(graphData).length && Object.entries(graphData).forEach(([key, value]) => {
				if(key === 'label') {
					if(!colors || colors.length !== 2) {
						if(value.toLowerCase() === 'bucket') {
							colors = ['#24A597', '#D9D9D6']
						} else if(value.toLowerCase() === 'prefix') {
							colors = ['#775BA2', '#D9D9D6']
						} else if(value.toLowerCase() === 'files') {
							colors = ['#3F73AD', '#D9D9D6']
						} else if(value.toLowerCase() === 'occurrences') {
							colors = ['#88792E', '#D9D9D6']
						} else if(value.toLowerCase() === 'hits') {
							colors = ['#5F5BA2', '#D9D9D6']
						}
					}	
					graphHeading = capitalizeFirstLetter(value)
					
					overAllLabel = graphHeading
				} else if(key === 'total') {
					overAll = Number.isInteger(value) ? value : parseFloat(value, 2)
				} else if(key === 'items') {
					let totalCount = 0
					if(graphData.label.toLowerCase() === 'risks' || graphData.label.toLowerCase() === 'risk') {
						colors = ['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']
						let riskColors = []
						let result = value.filter(arr => arr.item_name.toLowerCase() === 'critical')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[0])
						}
						result = value.filter(arr => arr.item_name.toLowerCase() === 'high')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[1])
						}
						result = value.filter(arr => arr.item_name.toLowerCase() === 'medium')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[2])
						}
						result = value.filter(arr => arr.item_name.toLowerCase() === 'low')
						if(result && result.length) {
							totalCount += result[0].item_count
							labels.push(capitalizeFirstLetter(result[0].item_name))
							series.push(result[0].item_count)
							riskColors.push(colors[3])
						}
						colors = riskColors
					} else {
						if(value.length) {
							value.forEach(item => {
								if(props.formatedColors && Object.entries(props.formatedColors).length) {
									if(props.formatedColors.hasOwnProperty(item.item_name.toLowerCase())) {
										formatedColorsArray.push(props.formatedColors[item.item_name.toLowerCase()])
									}
								}
								totalCount += item.item_count
								series.push(item.item_count)
								labels.push(item.item_name)
							})
						} else {
							series = [100]
							colors = [props.emptyGraphColor ? props.emptyGraphColor : '#D9D9D6']
							labels = ['No Data']
						}
					}

					if(!graphData.total) {
						overAll = Number.isInteger(totalCount) ? totalCount : parseFloat(totalCount).toFixed(2)
					}
					
					if(!totalCount) {
						series = [100]
						colors = [props.emptyGraphColor ? props.emptyGraphColor : '#D9D9D6']
						labels = ["No Data"]
					}
				}
			})

            if(formatedColorsArray && formatedColorsArray.length) {
                colors = formatedColorsArray
            }

			// if(series.length === 2 && colors.length > 2) {
			// 	colors = [colors[0], '#D9D9D6']
			// }
		}
		
		drawGraph(series, labels, colors, overAll, overAllLabel, graphHeading)
    }, [props, drawGraph])

	return (
		state.series ?
			<div className={`${props.className} apexCommonDonutChart`}>
				<p className={`font-weight-bold mb-1 ${props.graphHeadingPosition ? props.graphHeadingPosition : 'text-center'} ${props.showGraphHeading ? '' : 'displayNone'}`}>{state.graphHeading ? state.graphHeading : ''}</p>
				<Chart options={state.options} series={state.series} type="donut" width={'100%'} />
			</div>
		: null
	)
}

export default ApexDonutChartNew;