/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Inventory.js
 * @author Prakash // on 18/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { listAssetsNew } from '../../../actions/assets/assetsAction'
import { getCoveredServicesNew } from '../../../actions/detectors/detectorsAction'

import { UncontrolledTooltip, Spinner } from 'reactstrap'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import { CSVLink } from "react-csv";

import Search from '../../common/SearchComponent'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'

let searchAssetInventory = null

const Inventory = (props) => {
    
    const toggleRef = useRef()
    const [state, setState] = useState({
        showLoading: true,

        catServiceType: ["Category", "Services"],
        selectedCatSer: "Services", 

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 100,
        changesInFilter: true,
        filteredAssetList: [],
        callGetCoveredServices: true
    })

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(state.callGetCoveredServices) {
            let params = {}
            params.provider = props.selectedProvider && props.selectedProvider !== "" ? props.selectedProvider.toLowerCase() : ""
            if(props.selectedAccount && props.selectedAccount.length && !props.selectedAccount.includes("All")) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length && !props.selectedRegion.includes("All")) {
                params.region = props.selectedRegion
            }
            let aggregateBy = "resource_type"
            if(state.selectedCatSer === "Category") {
                aggregateBy = "category"
            }
            params.aggregate_by = [aggregateBy, "service_name"]
            params.assets_present = true
            params.radial = true
            dispatch(getCoveredServicesNew(params))
                .then((response) => {
                    let results = response && response.results && response.results.length ? response.results : []
                    let selectedCatService = results.length && results[0] ? results[0] : {}
                    let selectedService = selectedCatService.data && Object.entries(selectedCatService.data).length ? Object.keys(selectedCatService.data)[0] : ""

                    let selectedCategory = results.length && results[0] ? results[0].resource_type : ""
                    if(state.selectedCatSer === "Category") {
                        selectedCategory = results.length && results[0] ? results[0].category : ""
                    }
                    
                    setState(prevState => ({ ...prevState, categoryList: results, filterCategoryList: results, selectedCategory, selectedCatService, selectedService, selectedServiceCall: true, showLoading: false, assetListLoader: false, callGetCoveredServices: false }))
                })
                
        }
    }, [dispatch, state.callGetCoveredServices, props.selectedProvider, props.selectedAccount, props.selectedRegion, state.selectedCatSer])

    const onClickCategory = (item) => {
        let selectedCategory = item.category ? item.category : item.resource_type

        let selectedService = item.data && Object.entries(item.data).length ? Object.keys(item.data)[0] : ""

        setState(prevState => ({ ...prevState, selectedCategory, selectedCatService: item, selectedService, selectedServiceCall: true, assetsList: [], filteredAssetList: [], assetListLoader: true }))
    }

    useEffect(() => {
        if(state.selectedServiceCall) {
            setState(prevState => ({ ...prevState, selectedServiceCall: false }))
            let params = {}
            params.provider = props.selectedProvider && props.selectedProvider !== "" ? props.selectedProvider.toLowerCase() : ""
            if(props.selectedAccount && props.selectedAccount.length && !props.selectedAccount.includes("All")) {
                params.account_id = props.selectedAccount
            }
            if(props.selectedRegion && props.selectedRegion.length && !props.selectedRegion.includes("All")) {
                params.region = props.selectedRegion
            }
            params.service_name = state.selectedService

            // if(state.assetResponse && state.assetResponse.next_token && state.fetchRecord === 'next') {
            //     params.next_token = state.assetResponse.next_token
            // } else if(state.assetResponse && state.assetResponse.next_token && state.fetchRecord === 'previous') {
            //     params.previous_token = state.assetResponse.previous_token
            // }

            params.size = 1000

            if(state.searchText) {
                params.asset_search = state.searchText
            }

            // assetResponse: response,
            
            dispatch(listAssetsNew(params))
                .then((response) => {
                    let results = response && response.results && response.results.length ? response.results : []
    
                    let totalPages = 1
                    if(results && results.length > state.perPage) {
                        totalPages = Math.ceil(results.length / state.perPage)
                    }
    
                    setState(prevState => ({ ...prevState, assetsList: results, filteredAssetList: results, totalPages, currentPage: 1, startRecord: 0, totalRecords: response.total ? response.total : 0, assetListLoader: false, callFormHeaders: true, showLoading: false, selectedServiceCall: false }))
                })
        }
    }, [state.selectedServiceCall, dispatch, props.selectedAccount, props.selectedProvider, props.selectedRegion, state.perPage, state.selectedService])

	useEffect(() => {
        if(state.callFormHeaders) {
            let dataResult = state.filteredAssetList && state.filteredAssetList.length ? state.filteredAssetList : []
            if(dataResult.length) {
                let headers = []
                Object.entries(dataResult[0]).forEach(([key, value]) => {
                    if(typeof value === "string") {
                        let headerRow = {}
                        headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                        headerRow.key = key
                        headers.push(headerRow)
                    }
                })
                setState(prevState => ({ ...prevState, headers, callFormHeaders: false }))
            } else {
                setState(prevState => ({ ...prevState, callFormHeaders: false }))
            }
        }
	}, [state.filteredAssetList, state.callFormHeaders])

    const navigatePage = (action, currentPage) => {
        if(currentPage > 0) {
            let startRecord = state.startRecord
            if(action === 'next' && currentPage === state.totalPages && state.assetResponse  && state.assetResponse.next_token) {
                setState(prevState => ({ ...prevState, showLoading: true, fetchRecord: 'next', selectedServiceCall: true }))
            } else if(action === 'previous' && currentPage === 1 && state.assetResponse  && state.assetResponse.previous_token) {
                setState(prevState => ({ ...prevState, showLoading: true, fetchRecord: 'previous', selectedServiceCall: true }))
            } else if(action === 'next' && currentPage !== state.totalPages) {
                startRecord = startRecord + state.perPage
                currentPage = currentPage + 1
            } else if(action === 'previous' && currentPage !== 0) {
                currentPage = currentPage - 1
                startRecord = startRecord - state.perPage
            } else if(action === 'start' && currentPage !== 0) {
                currentPage = 1
                startRecord = 0
            } else if(action === 'end' && currentPage !== 0) {
                currentPage = state.totalPages
                startRecord = (state.totalPages - 1) * state.perPage
            }

            setState(prevState => ({ ...prevState, currentPage, startRecord }))
        } 
    }  

    const getAssetDetails = (item) => {
        setState(prevState => ({
            ...prevState, 
            selectedAssetId: item.asset_id === state.selectedAssetId ? "" : item.asset_id, 
            selectedAsset: item.asset_id === state.selectedAssetId ? "" : item,
            expandAssetDetails: item.asset_id === state.selectedAssetId ? false : true,
        }))
    }

    const handleClickOutside = (event) => {
        if (toggleRef && !toggleRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState,showServiceTypeOptions: false }))
        } else {
            setState(prevState => ({ ...prevState,showServiceTypeOptions: !state.showServiceTypeOptions }))
        }
    }

    return (
        <div className='row h-100' onClick={ handleClickOutside}>
            <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div>
            <div className='col-sm-12'>
                <div className='m-2'>
                    <div className="col-sm-12 mb-1">
                        <div className="row d-flex w-100 justify-content-between">
                            <div className='d-flex w-50'>
                                <div className='w-20'>
                                    <select className='form-control bg-transparent border-lightGray select1 text-lightGray' style={{minWidth: '150px'}} id="mySelect" onChange={(e) =>  
                                        setState(prevState => ({ 
                                            ...prevState,
                                            selectedCatSer: e.target.value, 
                                            showServiceTypeOptions: false,
                                            categoryList: [],
                                            filterCategoryList: [],
                                            emptySearchText: true,
                                            selectedCatService: [],
                                            assetListLoader: true ,
                                            callGetCoveredServices: true
                                        }))
                                    }>
                                        {state.catServiceType.map(item => {
                                            return(
                                                <option
                                                    key={item}
                                                    
                                                    className={`${state.selectedCatSer === item ? "selected" :  ""}`}
                                                    value={item}
                                                >{item}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="select-sm-bg curvedDropdown minWidth120 displayNone" ref={toggleRef}>
                                        <div className="dropdown">
                                            <span className="dropdown-toggle d-flex justify-content-between px-2 text-white">
                                                {state.selectedCatSer ? state.selectedCatSer : <span className="placeholder">Select</span>}
                                            </span>
                                            <ul className={`dropdown-menu p-0 ${state.showServiceTypeOptions ? "show" : ''}`}>
                                                {state.catServiceType.map(item => {
                                                    return(
                                                        <li 
                                                        key={item}
                                                        onClick={() =>
                                                            setState(prevState => ({ 
                                                                ...prevState,
                                                                selectedCatSer: item, 
                                                                showServiceTypeOptions: false,
                                                                categoryList: [],
                                                                filterCategoryList: [],
                                                                emptySearchText: true,
                                                                selectedCatService: [],
                                                                assetListLoader: true
                                                            }))
                                                        }
                                                        className={`${state.selectedCatSer === item ? "selected" :  ""}`}
                                                        >
                                                            {item}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-70 align-self-center ml-2'>
                                    {state.categoryList && state.categoryList.length ?
                                        <SearchWithHiddenInput
                                            data={state.categoryList ? state.categoryList : []}
                                            applyLiteDarkTags={true}
                                            searchClassName={""}
                                            searchIconColor={"text-gray3"}
                                            className="form-control-sm text-gray3 bg-transparent"
                                            hideInputSection={true}
                                            searchPlaceHolder={'Search....'}
                                            topClassName={"dark-search align-self-center ml-2"}
                                            filteredData={(filterCategoryList, searchText) => {
                                                setState(prevState => ({ ...prevState, filterCategoryList, emptySearchText: false }))
                                            }}
                                            resetSearchBox={state.emptySearchText}
                                        />
                                    : null}
                                </div>
                            </div>
                            <CSVLink 
                                data={state.filteredAssetList} 
                                headers={state.headers ? state.headers : []}
                                filename={"resource-inventory.csv"}
                                className={"align-self-center"}
                                target="_blank"
                            >
                                <i className="fas fa-download text-primary-color"></i>
                                {/* <span className="text-primary-color mx-2">csv</span> */}
                            </CSVLink>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-1 pr-0 border-right-info">
                            <div className="bg-black w-100 overflowYAuto vh100">
                                {state.filterCategoryList && state.filterCategoryList.length ?
                                    state.filterCategoryList.map((item, i) => {
                                        return(
                                        <div key={"category_"+i} className={`px-2 cursorPointer ${i ? "border-top" : ""} ${state.selectedCatService && state.selectedCatService.category === (item.category || item.resource_type) ? 'text-info disabled' : ''}`} onClick={() => onClickCategory(item)}>
                                            <div className="hiddenEllipses d-flex" id={"category_"+i}>
                                                <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 ${state.selectedCategory && state.selectedCategory === (item.category || item.resource_type) ? 'text-info' : 'text-white'}`}>{item.category ? item.category : item.resource_type}</p>
                                            </div>
                                            <UncontrolledTooltip placement='top' target={"category_"+i}>
                                                <div className="text-left">  
                                                    <p className="mb-1 text-primary-color"><span className="text-white mr-1">{state.selectedCatSer}: </span>{(item.category ? item.category : item.resource_type) + (item.total ? " ("+item.total+") " : "")}</p>
                                                    <p className="mb-1 text-white">Assets: </p>
                                                    {item.data && Object.entries(item.data).length ?
                                                        Object.entries(item.data).map(([key, value], j) => {
                                                            return(
                                                                <p key={"asset_"+j} className="mb-1 text-primary-color">{key+" : "+value}</p>
                                                            )
                                                        })
                                                    : null}
                                                </div>    
                                            </UncontrolledTooltip>
                                        </div>
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                        <div className="col-sm-2 p-0">
                            <div className="bg-black w-100 overflowYAuto vh100">
                                {state.selectedCatService && state.selectedCatService.data ?
                                    Object.entries(state.selectedCatService.data).map(([key, value], i) => {
                                        return(
                                        <div key={"selectedCatService_"+i} className={`px-2 cursorPointer ${i ? "border-top" : ""} ${state.selectedService === key ? 'text-info disabled' : ''}`} onClick={() => setState(prevState => ({ ...prevState,assetListLoader: true, selectedService: key, selectedServiceCall: true }))}>
                                            <div className="hiddenEllipses d-flex" id={"services_"+i}>
                                                <p className={`m-0 f-20 pl pr-1 pt-2 pb-2 ${state.selectedService === key ? 'text-info' : 'text-white'}`}>{key + (value ? " ("+value+") " : "")}</p>
                                            </div>
                                            <UncontrolledTooltip placement='top' target={"services_"+i}>
                                                <div className="text-left">  
                                                    <p className="mb-1 text-primary-color"><span className="text-white mr-1">Asset: </span>{key + (value ? " ("+value+") " : "")}</p>
                                                </div>    
                                            </UncontrolledTooltip>
                                        </div>
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                        <div className="col-sm-9 pl-0 overflowYAuto vh100">
                            {state.assetListLoader ? 
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='light'   />
                                </div>
                            : 
                                state.filteredAssetList ?
                                    <div className="bg-white w-100 overflowYAuto vh100">
                                        <div className="d-flex justify-content-between mb-1 pt-2 bg-black">
                                            <p className="mb-0 ml-2 align-self-center">Showing 
                                                {state.assetResponse && state.assetResponse.previous_token && !state.assetResponse.next_token ? ' last ' : ''}
                                                <span className='ml-1'>{state.filteredAssetList && state.filteredAssetList.length} of total {state.assetsList && state.assetsList.length} assets in </span>
                                                <span className="text-info">{state.selectedService}</span>
                                            </p>
                                            <div className="d-flex justify-content-end w-60">
                                                {state.filteredAssetList && state.filteredAssetList.length > state.perPage ?
                                                    <div className="mr-3 pagination errorPagePagination f12 mt-n1">
                                                        <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                                        <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                                        <button><i className={`fal fa-angle-right cursorPointer ${!state.nextErrorListToken && state.nextErrorListToken === "" && state.currentPage === state.totalPages ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-lightGray' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                                    </div>
                                                : null}
                                                <div className='w-50 mb-2'>
                                                    {/* {state.assetsList && state.assetsList.length ?
                                                        <Search
                                                            data={state.assetsList ? state.assetsList : []}
                                                            applyTags={false}
                                                            applyLiteDarkTags={true}
                                                            topClassName={'bg-mediumDarkGray f12 border-0 m-0'}
                                                            searchClassName={'border-0'}
                                                            searchIconColor={'text-white f12'}
                                                            inputEncloseClass={'w-50'}
                                                            searchPlaceHolder={'Search....'}
                                                            className={"bg-transparent text-white pl-2 form-control-sm f12 w-80"}
                                                            filteredData={(filteredAssetList) => {
                                                                let totalPages = 1
                                                                if(filteredAssetList && filteredAssetList.length > state.perPage) {
                                                                    totalPages = Math.ceil(filteredAssetList.length / state.perPage)
                                                                }
    
                                                                setState(prevState => ({ ...prevState, filteredAssetList, totalPages, currentPage: 1, startRecord: 0 }))
                                                            }}
                                                        />
                                                    : null} */}
                                                    <div className='d-flex border-lightGray rounded'>
                                                        <i className={`fal fa-search cursorPointer text-white align-self-center pl-2 w-10`} />
                                                        <div className={`w-90`}>
                                                            <input
                                                                value={state.searchText}
                                                                onChange={e => {
                                                                    setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                                                    clearTimeout(searchAssetInventory)
                                                                    if(e.target.value.length > 2) {
                                                                        searchAssetInventory = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, nextCatalogToken: [], startRecord: 0, currentPage: 1, totalPages: 1, assetsList: [], filteredAssetList: [], showLoading: true, selectedServiceCall: true }))}, 1000);
                                                                    } else if(!e.target.value.length) {
                                                                        setState(prevState => ({ ...prevState, startRecord: 0, currentPage: 1, totalPages: 1, assetsList: [], filteredAssetList: [], showLoading: true, selectedServiceCall: true }))
                                                                    }
                                                                }}
                                                                type='text'
                                                                className={`bg-transparent text-white text-md pl-1 py-1 border-0 w-100`}
                                                                placeholder='Search'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>                                                 
                                            </div>
                                        </div>
                                        
                                        <div className="overflowYAuto">
                                            {state.filteredAssetList && state.filteredAssetList.length ?
                                                state.filteredAssetList.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                    return(
                                                        <div key={"filteredAssetList_"+i} className={`p-2 ${i ? "border-top" : ""} ${state.selectedAssetId && state.selectedAssetId === item.asset_id ? "bg-mediumDarkGray" : ""}`}> 
                                                            <div className={`d-flex cursorPointer ${state.selectedAssetId && state.selectedAssetId === item.asset_id ? "pb-1" : ""}`} onClick={() => getAssetDetails(item)}>
                                                                <p className={`mb-0 ${state.selectedAssetId && state.selectedAssetId === item.asset_id ? "text-white" : "text-black"}`}>
                                                                <span className="mb-0 small mr-1 ">Resource</span>
                                                                    {item.account_id ?
                                                                        <span className="mb-0 f12  font-weight-bold">{' '+item.account_id}</span>
                                                                    : null}
                                                                    {item.region ?
                                                                        <span className="mb-0 f12  font-weight-bold">{' : '+item.region}</span>
                                                                    : null}
                                                                    {item.resource_type ?
                                                                        <span className="mb-0 f12  font-weight-bold">{' : '+item.resource_type}</span>
                                                                    : null}
                                                                    {item.service_name ?
                                                                        <span className="mb-0 f12  font-weight-bold mr-2">{' : '+item.service_name}</span>
                                                                    : null}
                                                                    {item.id_name_map ?
                                                                        <React.Fragment>
                                                                        <span className="mb-0 mr-2 f12 text-cyon font-weight-bold">
                                                                            {item.id_name_map.value}
                                                                        </span>
                                                                        {item.id_name_map.key ?
                                                                            <span className="mb-0 mr-2 f12 text-cyon font-weight-bold">
                                                                                {item.id_name_map.key}
                                                                            </span>
                                                                        : null}
                                                                        </React.Fragment>
                                                                    : item.asset_name ?
                                                                        <span className="mb-0 mr-2 f12 text-cyon font-weight-bold">{item.asset_name}</span>
                                                                    : null}
                                                                    {item.asset_id ?
                                                                        <span className="mb-0 mr-2 f12 font-weight-bold">{item.asset_id}</span>
                                                                    : null}
                                                                    {item.asset_arn && item.asset_arn !== item.asset_name ?
                                                                        <span className="mb-0 mr-2 f12 font-weight-bold">{item.asset_arn}</span>
                                                                    : null}                
                                                                </p>
                                                            </div>
                                                            {state.selectedAssetId && state.selectedAssetId === item.asset_id ?
                                                                <div className="accordion" id="accordionExample">
                                                                    <div className={`card bg-darkGray border-0 mb-2`}>
                                                                        <div id={'heading_config_assets'} onClick={() => setState(prevState => ({ ...prevState, expandAssetDetails: !state.expandAssetDetails }))}>
                                                                            <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                                                                <div className="text-white"><span className={`far ${state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                                                                {/* <div className="text-info">Showing asset details</div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div id={'collapse_config_assets'} className={`collapse ${state.expandAssetDetails ? 'show' : ""}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                                                            <div className="card-body">
                                                                                <div className="d-flex mb-2">
                                                                                    <div className="py-1 w-33">
                                                                                        <p className="b-block mb-0 text-lightGray">Name</p>
                                                                                        <p className="mb-0 text-white">{state.selectedAsset.asset_name ? state.selectedAsset.asset_name : ""}</p>
                                                                                    </div>
                                                                                    <div className="py-1 w-33 pl-3">
                                                                                        <p className="b-block mb-0 text-lightGray">Id</p>
                                                                                        <p className="mb-0 text-white">{state.selectedAsset.asset_id ? state.selectedAsset.asset_id : ""}</p>
                                                                                    </div>
                                                                                    <div className="py-1 w-33 pl-3">
                                                                                        <p className="b-block mb-0 text-lightGray">ARN</p>
                                                                                        <p className="mb-0 text-white">{state.selectedAsset.asset_arn ? state.selectedAsset.asset_arn : ""}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex mb-2 border-top">
                                                                                    <div className="py-1 w-33">
                                                                                        <p className="b-block mb-0 text-lightGray">Resource</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {state.selectedAsset.provider ? state.selectedAsset.provider.toUpperCase() : ""}
                                                                                            {state.selectedAsset.account_id ? (" : ")+state.selectedAsset.account_id : ""}
                                                                                            {state.selectedAsset.region ? (" : ")+state.selectedAsset.region : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="py-1 w-33 pl-3">
                                                                                        <p className="b-block mb-0 text-lightGray">Services</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {state.selectedAsset.resource_type ? state.selectedAsset.resource_type : ""}
                                                                                            {state.selectedAsset.service_name ? (" : ")+state.selectedAsset.service_name : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="py-1 w-33 pl-3">
                                                                                        <p className="b-block mb-0 text-lightGray">Created At</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {state.selectedAsset.created_at ? momentConvertionUtcToLocalTime(state.selectedAsset.created_at, 'DD MMM YYYY HH:mm') : ""}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="d-flex mb-2 border-top">
                                                                                    <div className="py-1 w-33">
                                                                                        <p className="b-block mb-0 text-lightGray">Created By</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {state.selectedAsset.created_by ? state.selectedAsset.created_by : "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="py-1 w-33 pl-3">
                                                                                        <p className="b-block mb-0 text-lightGray">Modified At</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {state.selectedAsset.modified_at ? momentConvertionUtcToLocalTime(state.selectedAsset.modified_at, 'DD MMM YYYY HH:mm') : state.selectedAsset.last_modified_at ? momentConvertionUtcToLocalTime(state.selectedAsset.last_modified_at, 'DD MMM YYYY HH:mm') : ""}
                                                                                        </p>
                                                                                    </div><div className="py-1 w-33 pl-3">
                                                                                        <p className="b-block mb-0 text-lightGray">Modified By</p>
                                                                                        <p className="mb-0 text-white">
                                                                                            {state.selectedAsset.modified_by ? state.selectedAsset.modified_by : "-"}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="d-flex mb-2 border-top">
                                                                                    <div className="py-1 w-50">
                                                                                        <p className="b-block mb-0 text-lightGray">Category:</p>
                                                                                        <div className={`d-flex flex-wrap`}>
                                                                                            {state.selectedAsset.category && Array.isArray(state.selectedAsset.category) ?
                                                                                                state.selectedAsset.category.map(cat => {
                                                                                                    return(
                                                                                                        <small key={"selectedAsset_"+i} className={`mr-1 mb-1 badge badge-light`}>{cat}</small>
                                                                                                    )
                                                                                                })
                                                                                            : 
                                                                                                <small className={`mr-1 mb-1 badge badge-light`}>{state.selectedAsset.category}</small>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="py-1 w-50 pl-3">
                                                                                        <p className="b-block mb-0 text-lightGray">Tags:</p>
                                                                                        {state.selectedAsset.tags && state.selectedAsset.tags.map(tag => {
                                                                                            return(
                                                                                                <small key={"selectedAssetTag_"+i} className={`mr-1 mb-1 badge badge-light text-left whiteSpaceNoarmal`}>{tag.key+': '+tag.value}</small>
                                                                                            )
                                                                                        })}
                                                                                        {/* <ul className='tags my-1 px-3'>
                                                                                            {state.selectedAsset.tags && state.selectedAsset.tags.map((tag, index) => {
                                                                                                return(
                                                                                                    <li key={index}>
                                                                                                        <span className='tag f14'>{tag.key+': '+tag.value}</span>
                                                                                                    </li>
                                                                                                )
                                                                                            })}
                                                                                        </ul> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : null}
                                                        </div>
                                                    )
                                                })
                                            :
                                                <div className='d-flex justify-content-center m-4'>
                                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Inventory