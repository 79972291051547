/*************************************************
 * Collider
 * @exports
 * @file IamReducer.js
 * @author Rajasekar // on 22/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
	iamRoles: { results: [] },
	iamUsers: { results: [] },
	createPolicyDetails: {},
	conditions: {},
	selectedStatements: [],
}

/**
 * To store iam details in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const iamReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_CREATE_POLICY_DETAILS:
			return {
				...state,
				createPolicyDetails: { ...state.createPolicyDetails, ...action.payload },
			}
		case ACTION_TYPES.SET_CREATE_POLICY_DETAILS_EMPTY:
			return {
				...state,
				createPolicyDetails: {},
				conditions: {},
			}
		case ACTION_TYPES.SET_IAM_ROLES_ASSETS_LIST:
			return {
				...state,
				iamRoles: { ...action.payload },
			}
		case ACTION_TYPES.SET_IAM_USERS_ASSETS_LIST:
			return {
				...state,
				iamUsers: { ...action.payload },
			}
		case ACTION_TYPES.SET_EDIT_CATEGORY_SELECTED:
			return {
				...state,
				selectedStatements: { ...action.payload },
			}
		case ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS:
			return {
				...state,
				conditions: { ...state.conditions, ...action.payload },
			}
		case ACTION_TYPES.SET_IAM_EVENTS_CONDITIONS_EMPTY:
			return {
				...state,
				conditions: {},
			}
		default:
			return state
	}
}
