/*************************************************
 * Collider
 * @exports
 * @file PrimaryMetricsSection  .js
 * @author Prakash // on 14/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Spinner } from 'reactstrap'
import { momentDateGivenFormat, addHours, momentConvertionUtcToLocalTime, getConvertedBytesUnit, convertBytes, convertBytesTo, convertSeconds, countFormater, convertBits, subDays } from '../../../utils/utility'
import Chart from 'react-apexcharts'

import { getMonitoringMetricsByTrendV2 } from '../../../actions/MetricsActionNew'
import { setCostPropsDetails } from  '../../../actions/cost/CostAction'

const PrimaryMetricsSection = (props) => {

    const[state, setState] = useState({
        showLoading: true,
        sreMetricsDetails: [],
        show_latency: true,
        show_error_rate: true,
        showAnnotation: true,
        showPrimaryMertics: false,
        showSecondaryMertics: true,
        selectedDuration: '3',
    })

    const priSecMetricDetails = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.priSecMetricDetails ? state.cost.costPropsDetails.priSecMetricDetails : {})

    useState(() => {
        setState(prevState => ({
            ...prevState, 
            metricsStartTime: subDays(props.endTime, 3),  //for only last 14 days
            metricsEndTime: props.endTime  //for only last 14 days
        }))
    }, [props.endTime])

    const dispatch = useDispatch(); // Create a dispatch function
    useEffect(() => {
        if(state.getGraphDetails) {
            if(props.assetDetails && props.assetDetails.asset_id) {
                let params = {}
                params.provider = props.assetDetails && props.assetDetails.provider ? props.assetDetails.provider : 'aws'
                params.source = 'cloudwatch'
                params.asset_id = props.assetDetails.asset_id

                let metricsStartTime = subDays(props.endTime, state.selectedDuration)
                let metricsEndTime = props.endTime

                params.start_time = metricsStartTime
                params.end_time = metricsEndTime
                // {'source':'cloudwatch',"duration": "3h", "asset_id": "61614f64fc4cb905f58e2f5346e95ee1"}
                
                dispatch(getMonitoringMetricsByTrendV2(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, metricsByTrend: response, showLoading: false, getGraphDetails: false, structureGraphDetails: true, metricsStartTime, metricsEndTime }))
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, metricsByTrend: [], showLoading: false }))
            }
        }
    }, [state.getGraphDetails, state.selectedDuration, dispatch, props.assetDetails, props.endTime])

	

    useEffect(() => {
        if(state.structureGraphDetails && state.metricsByTrend && state.metricsByTrend.length) {
            setState(prevState => ({ ...prevState, structureGraphDetails: false }))
            let obj = {}
            obj = priSecMetricDetails
            obj[props.assetDetails.asset_id] = state.metricsByTrend
            dispatch(setCostPropsDetails('priSecMetricDetails', obj))

            let graphDetails = state.metricsByTrend
            if(graphDetails) {
    
                graphDetails.forEach((graph, index) => {
                    let graphData = graph.data
                    let graphDates = graph.dates
    
                    Object.entries(graphData).forEach(([key, item]) => {
                        
    
                        let array = []
                        let graphLabels = []
    
                        let maxValue = item.statistics && item.statistics.max ? item.statistics.max : 1
                        // let avgValue = item.statistics && item.statistics.avg ? item.statistics.avg : 1
                        // let minValue = item.statistics && item.statistics.min ? item.statistics.min : 1
                        
                        let graphConvertTo = ""
    
                        // let maxRandomNumber = maxValue
                        // let minRandomNumber = minValue
                        if(item.unit) {
                            graphConvertTo = getConvertedUnit(maxValue, item.unit)
                            // maxRandomNumber = this.getGrapUnitValueWithoutUnit(maxValue, item.unit, "round")
                            // minRandomNumber = this.getGrapUnitValueWithoutUnit(minValue, item.unit, "round")
                        }
                        item.values.forEach((val, j) => {
                            graphLabels.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                            let arrayValues = []
                            arrayValues.push(momentConvertionUtcToLocalTime(graphDates[j], 'YYYY-MM-DD HH:mm:ss'))
                            if(item.unit) {
                                val = getGrapUnitValue(val, item.unit, "", graphConvertTo)
                            }
                            arrayValues.push(val)
                            array.push(arrayValues)
                        })
    
                        if(array.length === 1) {
                            let arrayValues = []
                            arrayValues.push(addHours(momentConvertionUtcToLocalTime(graphDates[0], 'YYYY-MM-DD HH:mm:ss'), 0.5))
                            arrayValues.push(0)
                            array.push(arrayValues)
                        }
    
                        let series = [{
                            data: array,
                            name: key,
                        }]
                        let xaxisAnnotation = []
                        if(state.showAnnotation && props.changeDetails && props.changeDetails.event_time && props.changeDetails.event_time !== "") {
                            xaxisAnnotation = [{
                                x: new Date(momentConvertionUtcToLocalTime(props.changeDetails.event_time, 'YYYY-MM-DD HH:mm:ss')).getTime(),
                                strokeDashArray: 0,
                                borderColor: '#F75E3F',
                                label: {
                                    borderColor: '#F75E3F',
                                    style: {
                                        color: '#fff',
                                        background: '#F75E3F',
                                    },
                                    orientation: 'horizontal',
                                    text: "C"
                                }
                            }]
                        }
    
                        // let alertLocation = 20
                        // let anomalyLocation = 1
                        // let errorLocation = 40
                        // if(maxValue <= 2) {
                        //     alertLocation = 1
                        //     anomalyLocation = 1
                        //     errorLocation = 1
                        // }
    
                        let points = []
    
                        // let gandalfData = assetDetails && assetDetails.primary_metric_events && assetDetails.primary_metric_events.filter(e => e.event_name === key).length ? assetDetails.primary_metric_events.filter(e => e.event_name === key) : []
    
                        let events = props.changeDetails && props.changeDetails.events && props.changeDetails.events.length ? props.changeDetails.events : []
    
                        let gandalfData = events && events.filter(e => e.event_name === key).length ? events.filter(e => e.event_name === key) : []
                         
                        if(state["selectedMetricsLegend_"+key] === key && state["selectedLegendType_"+key] && state["selectedLegendType_"+key] !== "") {
                            gandalfData = gandalfData.filter(e => e.event_type === state["selectedLegendType_"+key]).length ? gandalfData.filter(e => e.event_type === state["selectedLegendType_"+key]) : []
                        }
                        
                        gandalfData.forEach(evnt => {
                            points.push({
                                x: new Date(momentConvertionUtcToLocalTime(evnt.event_time, "YYYY-MM-DD HH:mm:ss")).getTime(),
                                y: Math.floor(Math.random() * (maxValue - 1) + 1),
                                // mouseEnter: function(e) {
                                //     // do something on marker click
                                // },
                                marker: {
                                    size: 6,
                                    fillColor: evnt.event_type === "alerts" ? "#3F73AD" : evnt.event_type === "anomalies" ? "#775BA2" : evnt.event_type === "errors" ? "#24A597" : evnt.event_type === "issues" ? "#FF4560" : "#858B99",
                                    strokeColor: evnt.event_type === "alerts" ? "#3F73AD" : evnt.event_type === "anomalies" ? "#775BA2" : evnt.event_type === "errors" ? "#24A597" : evnt.event_type === "issues" ? "#FF4560" : "#858B99",
                                    radius: 2,
                                    OffsetX: 0,
                                    OffsetY: 0,
                                    cssClass: '',
                                },
                                label: {
                                    // borderColor: "#FF4560",
                                    // offsetY: 0,
                                    // style: {
                                    //     color: "#fff",
                                    //     background: "#FF4560"
                                    // },                
                                    // text: ""
                                }
                            })
                        })
                        
                        let annotations = {
                            xaxis: xaxisAnnotation,
                            // position: 'back',
                            points: points
                        }
    
                        let options = {
                            chart: {
                                // group: 'metrics_and_sre',
                                // id:'metrics_'+i,
                                type: 'area',
                                width: '100%',
                                toolbar: {
                                    show: false,
                                },
                                zoom: {
                                    enabled: false
                                },
                                sparkline: {
                                    enabled: false
                                }
                            },
                            stroke: {
                                show: true,
                                curve: 'smooth',
                                lineCap: 'butt',
                                width: 2,
                                dashArray: 0,      
                            },
                            colors: ['#775BA2'],
                            fill: {
                                type: "gradient",
                                gradient: {
                                    // shadeIntensity: 1,
                                    // opacityFrom: 0.7,
                                    // opacityTo: 0.9,
                                    // stops: [0, 90, 100]
                                    gradientToColors: ['#775BA2'],
                                    shadeIntensity: 0,
                                    opacityFrom: .3,
                                    opacityTo: 1,
                                    stops: [30, 90]
                                }
                            },
                            tooltip: {
                                custom: function({series, seriesIndex, dataPointIndex, w}) {
                                    let dateLable = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0]);
                                    dateLable = dateLable.toString()
                                    dateLable = momentDateGivenFormat(dateLable, 'DD MMM HH:mm')
                                    let val = w.globals.initialSeries[seriesIndex].data[dataPointIndex][1]
                                    return '<div class="arrow_box">'+dateLable+' <span style="color:'+ w.globals.colors +'">' + parseFloat(val).toFixed(2) + '</span> </div>'
                                },
                                fixed: {
                                    enabled: false
                                },
                                x: {
                                    show: false,
                                },
                                y: {
                                    show: false
                                },        
                                marker: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'datetime',
                                labels: {
                                    datetimeUTC: false,
                                    style: {
                                        colors: '#60687C'
                                    },
                                    // formatter: function (value) {
                                    //     return momentDateGivenFormat(value, 'HH:mm')
                                    // },
                                },                            
                                axisTicks: {
                                    show: false
                                },
                            },
                            dataLabels: {
                                enabled: false
                            },
                            yaxis: {
                                show: true,
                                axisTicks: {
                                    show: false,
                                },
                                axisBorder: {
                                    show: false,
                                    color: '#60687C'
                                },
                                tooltip: {
                                    enabled: false
                                },
                                // tickAmount: 2,
                                min: 0,
                                // max: yaxisMaxValue, 
                                tickAmount: 1,
                                labels: {
                                    offsetX: -10,
                                    // minWidth: 50,
                                    show: true,
                                    style: {
                                        fontSize: '9px',
                                        colors: '#60687C'
                                    },
                                    // formatter: (value) => { 
                                    //     if(Number(value) === value && value % 1 === 0) {
                                    //         return Math.ceil(value)
                                    //     } else {
                                    //         return Math.ceil(value)
                                    //     }
                                    // },
                                    // offsetX: 0,
                                    // offsetY: 0,
                                }
                            },
                            grid: {
                                show: true,
                                borderColor: '#2D323E',
                                strokeDashArray: 0,
                                position: 'back',
                                xaxis: {
                                    lines: {
                                        show: true
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#FFF'],
                                        }
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },   
                                yaxis: {
                                    lines: {
                                        show: false
                                    },
                                    labels: {
                                        style: {
                                            colors: ['#969AA7'],
                                        }
                                    }
                                },                
                                padding: {
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                },
                            },
                            annotations: annotations,
                        }

                        setState(prevState => ({ ...prevState, [key+'_primary_'+graph.asset_id]: series, [key+'_primary_options_'+graph.asset_id]: options, structureGraphDetails: false }))
                    })
                })                
            }            
        }
    }, [state.structureGraphDetails, state.metricsByTrend, props.assetDetails, dispatch, priSecMetricDetails, props.changeDetails, state])

    const getConvertedUnit = (val ,unit) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = getConvertedBytesUnit(val, unit)                            
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                // val = getConvertedSecondsUnit(val, unit)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                // val = getConvertedBitsUnit(val, unit)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                // val = getConvertedCountFormater(val)
            } else {
                val = ""
            }
        } else if(val !== '') {           
            val = ""
        }
        return val
    }

    const getGrapUnitValue = (val ,unit, valueType, convertTo) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseInt(val))
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                if(convertTo) {
                    val = convertBytesTo(val, unit, valueType, convertTo)
                } else {
                    val = convertBytes(val, unit, valueType)
                }                         
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType, convertTo)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType, convertTo)
            } else if(unit === 'Count') {
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {           
            val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
        }
        return val
    }

    // const getGrapUnitValueWithoutUnit = (val ,unit, valueType) => {
    //     if(val !== '' && unit !== '') {
    //         if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
    //             val = convertBytesWithoutUnit(val, unit, valueType)                            
    //         } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
    //             val = convertSecondsWithoutUnit(val, unit, valueType)
    //         } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
    //             val = convertBitsWithoutUnit(val, unit, valueType)
    //         } else if(unit === 'Count') {
    //             val = val ? Math.ceil(val) : 0
    //             val = countFormaterWithoutUnit(val)
    //         } else {
    //             val = parseInt(val)
    //         }
    //     } else if(val !== '') {           
    //         val = val > 1 ? Math.ceil(val) : (!val ? val : parseInt(val))
    //     }
    //     return val
    // }

    return (
        <div className="" >
            {props.assetDetails ?
                <div className={`card bg-dark border-0 p-3`}>
                    <div className={`${state.showPrimaryMertics ? "mb-1" : ""} d-flex justify-content-between`}>
                        <div className="d-flex text-white cursorPointer" onClick={() => setState(prevState => ({ ...prevState, showPrimaryMertics: !state.showPrimaryMertics, getGraphDetails: state.showPrimaryMertics ? false : true, showLoading: !state.showPrimaryMertics }))}>
                            <span className={`far ${state.showPrimaryMertics ? 'fa-minus-circle' : 'fa-plus-circle'} align-self-center mr-2`}></span>Metrics
                            
                            <p className="f13 m-0 align-self-center text-info ml-3">{"("+momentConvertionUtcToLocalTime(state.metricsStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentConvertionUtcToLocalTime(state.metricsEndTime, 'DD MMM YYYY HH:mm')+")"}</p>
                        </div>
                        {state.showPrimaryMertics ?
                            <div className={`d-flex flex-wrap pt-2 mt-n4`}>
                                <span className={`mr-2 f12 align-self-center text-white`}>Last</span>
                                <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "1" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showLoading: true, getGraphDetails: true, selectedDuration: "1" }))}>1 Day</span>
                                <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "3" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showLoading: true, getGraphDetails: true, selectedDuration: "3" }))}>3 Days</span>
                                <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "7" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showLoading: true, getGraphDetails: true, selectedDuration: "7" }))}>1 Week</span>
                                <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "14" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showLoading: true, getGraphDetails: true, selectedDuration: "14" }))}>2 Week</span>
                            </div>
                        : null}
                    </div>
                    <p className={`${state.showPrimaryMertics ? "mb-2" : "mb-1"}`}>{props.assetDetails && props.assetDetails.summary ? props.assetDetails.summary : ""}</p>
                    <div className={`collapse ${state.showPrimaryMertics ? 'show' : ''}`}>
                        {!state.showLoading ?
                            state.metricsByTrend && state.metricsByTrend.length ? 
                                <div className="row">
                                    {state.metricsByTrend.map((item, index) => {
                                        return (
                                            Object.keys(item.data).map((key, i) => {
                                                return (
                                                    <div className="col-sm-6 mb-3 ">
                                                        <div className="p-3 rounded bg-dark3">
                                                            <div className="d-flex justify-content-between">
                                                                <p className="text-white f16 p-1 mb-0">{key}</p>
                                                            </div>
                                                            {state[key+'_primary_options_'+item.asset_id] && Object.keys(state[key+'_primary_options_'+item.asset_id]).length && state[key+'_primary_'+item.asset_id] && state[key+'_primary_'+item.asset_id].length ?
                                                                <div className="cursorPointer transparentTooltip mb-n4 mt-n3">
                                                                    <Chart options={state[key+'_primary_options_'+item.asset_id]} series={state[key+'_primary_'+item.asset_id]} type="area" height={120} width={'100%'}/>
                                                                </div>
                                                            :
                                                                <div style={{minHeight:"90px"}} className="d-flex align-self-center justify-content-center">
                                                                    <div className='align-self-center'>
                                                                        No Data
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        )
                                    })}
                                </div>
                            :
                                <div className='d-flex justify-content-center m-4'>
                                    No Metrics details found
                                </div>
                        :
                            <div className='d-flex justify-content-center m-0 p-0 col-sm-12'>
                                <Spinner className='text-center' color='light' />
                            </div>
                        }
                    </div>
                </div>
            : 
                <div className={`bg-dark rounded p-3`}>
                    <p className={`mb-2 text-white cursorPointer`}><b>Metrics</b></p>
                    <div className='d-flex justify-content-center m-4'>
                        No Metrics details found
                    </div>
                </div>
            }
        </div>
    )
}

export default PrimaryMetricsSection