import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
// import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap'
import _ from 'lodash'
import { listWorkstationRequests, changeWorkstationStatus, applyWorkstationActions } from '../../../actions/CatalogAction'
 import { Store as CommonNotification } from 'react-notifications-component';
import windows from '../../../assets/images/windows-pngwing.png'
import linux from '../../../assets/images/linux-pngwing.png'
import aws from '../../../assets/images/aws-pngwing.png'
import gcp from '../../../assets/images/gcp-pngwing.png'
import ubuntu from '../../../assets/images/ubuntu-pngwing.png'
import WorkstationSidePanel from '../WorkstationSidePanel';
import Search from '../../common/SearchWithHiddenInput'
import { capitalizeFirstLetter, momentConvertionUtcToLocalTime } from '../../../utils/utility';

const NewRequest = (props) => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 9,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    // const history = useHistory();
    // const navigate = useNavigate();

    useEffect(() => {
        setState(prevState => ({ ...prevState, callListRequest: true }))
    }, [])

    useEffect(() => {
        if(state.callListRequest) {
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== 'All')
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== 'All')
            }

            if(state.nextReqeustToken !== '') {
                params.next_token = state.nextReqeustToken
            }

            // params.size = 9
    
            dispatch(listWorkstationRequests(params))
                .then((response) => {
                    if(response) {
                        let totalResults = []
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextReqeustToken) {
                            totalResults = state.listNewReqeusts &&  state.listNewReqeusts.length ? state.listNewReqeusts : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }
                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                        }
                        

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }
                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])
                        
                        setState(prevState => ({ ...prevState, listNewReqeusts: totalResults, filterNewReqeust: totalResults, detailsFormat, selectedFilters: params, nextReqeustToken: response.next_token, showLoading: false, callListRequest: false, startRecord, currentPage, totalPages, totalRecords: response.total ? response.total : 0 }));
                    }
                })
        }
    }, [state.callListRequest, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, state.listNewReqeusts, state.nextReqeustToken, state.currentPage, state.perPage, state.startRecord])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextReqeustToken) {
            setState(prevState => ({ ...prevState, showLoading: true, callListRequest: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    useEffect(() => {
        if(state.selectedStatus && state.selectedCatalog) {
            if(state.selectedStatus === 'launch') {
                let params = {}
                params.workstation_id = state.selectedCatalog.workstation_id
                params.action = state.selectedStatus
    
                dispatch(applyWorkstationActions(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, ['hideApproved_'+state.selectedCatalog.workstation_id]: true, callListRequest: true }))
                        let messageType = 'success'
                        let message = response.message
                        if(!response.status) {   
                            messageType = 'danger'  
                        } else {
                            setState(prevState => ({ ...prevState, hideActionBtn: true }))
                        }
                        // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })
            } else {
                let params = state.selectedCatalog
                params.status = state.selectedStatus
    
                dispatch(changeWorkstationStatus(params))
                    .then((response) => {
                        setState(prevState => ({ ...prevState, requestLoading: false }))
    
                        let messageType = 'danger'
                        let message = response.message ? response.message : 'Error in '+state.selectedStatus+' request'
                        if(response.status) {
                            messageType = 'success'
                            message = response.message ? response.message : 'Reqeust '+state.selectedStatus+' successfully'
    
                            if(state.selectedStatus === 'rejected') {
                                setState(prevState => ({ ...prevState, ['hideRejected_'+state.selectedCatalog.workstation_id]: true, callListRequest: true }))
                            } else {
                                setState(prevState => ({ ...prevState, ['hideApproved_'+state.selectedCatalog.workstation_id]: true, callListRequest: true }))                            
                            }
                        } else {
                            setState(prevState => ({ 
                                ...prevState, ['loadingRejected_'+state.selectedCatalog.workstation_id]: false, ['loadingApproved_'+state.selectedCatalog.workstation_id]: false 
                            }))
                        }
    
                        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    })

            }
        }

    }, [dispatch, state.selectedStatus, state.selectedCatalog])

    return (
        state.showLoading ?
            <div className='d-flex justify-content-center m-4'>
                <Spinner className='text-center' color='light'   />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {} }))}
                    detailsFormat={state.detailsFormat}
                    selectedDetails={state.selectedDetails}
                    showOnlyDeatils={true}
                />
            : null}
            <div className='p-2 h-100 overflowYAuto'>
                <div className='d-flex justify-content-between mb-3'>
                    <p className='mb-0 align-self-center'>
                        <small>Showing {state.filterNewReqeust && state.filterNewReqeust.length} out of total {state.totalRecords} catalog(s)</small>
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{state.selectedFilters.provider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <small className='ml-1 mr-2'>with the account</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.account_id}</small>
                            {state.selectedFilters.account_id.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <small className='ml-1'>in the</small>
                            <small className='badge badge-outline-info mx-1'>{state.selectedFilters.region[0]}</small>
                            {state.selectedFilters.region.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            <small className='ml-1'>region</small>
                            </React.Fragment>
                        : null}
                    </p>
                    {state.listNewReqeusts && state.listNewReqeusts.length ? 
                        <div className='d-flex justify-content-end w-40'>
                            {state.totalRecords > state.perPage ?
                                <div className='pagination errorPagePagination f12 mr-2'>
                                    <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                    <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                    <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                    <span className='displayNone'>
                                        Go to
                                        <input
                                            type='number'
                                            value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) : ''
                                                this.gotoPage(page)
                                            }}
                                            className='ml-1 inputClass'
                                        />
                                    </span>
                                    <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextCatalogToken ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                    <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                </div>
                            : null}
                            <div className='align-self-center w-50'>
                                <Search
                                    data={state.listNewReqeusts ? state.listNewReqeusts : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-darkGray text-white f12 border-mediumDarkGray'}
                                    searchClassName={'px-2 f12'}
                                    searchIconColor={'text-lightGray f12'}
                                    searchPlaceHolder={'Search....'}
                                    className={'bg-transparent pl-2 form-control-sm f12 w-92 text-white'}
                                    filteredData={(filterNewReqeust) => {
                                        let totalPages = 1                
                                        if(filterNewReqeust.length > state.perPage) {
                                            totalPages = Math.ceil(filterNewReqeust.length / state.perPage)
                                        }
                                        setState(prevState => ({ ...prevState, filterNewReqeust, totalPages, currentPage: 1, startRecord: 0 }))
                                    }}
                                />
                            </div>
                        </div>
                    : null}
                </div>
                <div className={`row catalogScreen`}>
                    {state.filterNewReqeust && state.filterNewReqeust.length ?
                        state.filterNewReqeust.map((row, index) => {
                            return(
                                <div className='col-lg-4 col-md-4 col-sm-4 mb-2 cursorPointer px-1' key={'row_'+index}>
                                    <div className='bg-darkGray rounded' style={{minHeight: '290px', maxHeight: '290px'}} onClick={(e) => {
                                        setState(prevState => ({ ...prevState, selectedDetails: row }))
                                    }}>
                                        <div className={`w-100 bg-${row.category ? (row.category === 'Virtual Workstation' ? 'gold' :  row.category) : 'black'}`}>
                                            <p className={`py-2 text-center ${(row.category === 'bronze') ? 'text-white' : 'text-black'}`}>{row.category ? capitalizeFirstLetter(row.category) : 'Category'}</p>
                                        </div>
                                        <div className='d-flex py-2 px-3 justify-content-between mb-1' >
                                            <div style={{minWidth: '30px', maxWidth: '30px'}} className='align-self-center'>
                                                {row.platform === 'windows' ?
                                                    <img src={windows} className='img-fluid mb-3' alt='WINDOWS' />
                                                :
                                                    <img src={linux} className='img-fluid mb-3' alt='LINUX'/>
                                                }
                                                {row.provider === 'aws' || row.provider === 'AWS' ?
                                                    <img src={aws} className='img-fluid mb-3' alt='AWS' />
                                                :
                                                    <img src={gcp} className='img-fluid mb-3' alt='GCP' />
                                                }
                                                <img src={ubuntu} className='img-fluid' alt='UBUNTU' />
                                            </div>
                                            <div className='ml-3 w-90'>
                                                {/* <div className='d-flex justify-content-end mb-2'>
                                                    <span className={`align-self-center badge badge-outline-${row.subscribed ? 'info' : 'danger'} align-self-start aiopsBadgeWidth`} >{row.subscribed ? 'Subscribed' : 'Not Subscribed'}</span>
                                                </div> */}
                                                <p className='mb-2'>
                                                    Name <span className='text-white ml-2'>{row.workstation_name}</span>
                                                </p>
                                                <p className='mb-2'>
                                                    Os <span className='text-white ml-2'>{row.platform}</span>
                                                </p>
                                                <p className='mb-2'>
                                                    <span className='mr-1'>Resources </span>
                                                    <span className='text-white'>
                                                        {row.provider ? row.provider.toUpperCase() : <span>&nbsp;</span>}
                                                        {row.account_id ? (' : ')+row.account_id : <span>&nbsp;</span>}
                                                        {row.region ?  (' : ')+row.region : <span>&nbsp;</span>}
                                                    </span>
                                                </p>
                                                <p className='mb-2 hiddenEllipsesChar'>
                                                    Description <span className='text-white ml-2'>{row.description}</span>
                                                </p>

                                                <p className='mb-2 d-flex'>
                                                    <span className='mr-1'>Capacity</span>
                                                    <span className='text-white'>{row.instance_type} </span>
                                                    <span className='text-white ml-2'>(
                                                        {row.vcpus} vCPU,
                                                        <span className='ml-1'>{row.memory} GB memory </span>
                                                    )</span>
                                                </p>
                                                <p className='mb-2 d-flex'>
                                                    <span className='mr-1'>Requested By</span>
                                                    <span className='text-white ml-2'>{row.user_name} </span>
                                                </p>
                                                <p className='mb-2 d-flex'>
                                                    <span className='mr-1'>Created At</span>
                                                    {row.created_at ?
                                                        <span className='text-white ml-2'>{momentConvertionUtcToLocalTime(row.created_at, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                    : null}
                                                </p>
                                                <div className='d-flex justify-content-between mt-3' style={{minHeight: '31px'}}>
                                                    <p className='mb-0 text-cyon align-self-center moveBottomLeft' 
                                                        onClick={(e) => {
                                                            setState(prevState => ({ ...prevState, selectedDetails: row }))
                                                        }}
                                                    >Details</p>

                                                    <div className='d-flex justify-content-end moveBottomRight'>
                                                        {!state['hideRejected_'+row.workstation_id] && !state['hideApproved_'+row.workstation_id] ?
                                                            <button
                                                                className={`btn btn-sm bg-mediumDarkGray text-white mr-3`}
                                                                // onClick={(event) => onClickSubscribe(event, row, index)}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    
                                                                    if(!state['loadingRejected_'+row.workstation_id] && !state['loadingApproved_'+row.workstation_id]) {
                                                                        setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'rejected', ['loadingRejected_'+row.workstation_id]: true }))
                                                                    }
                                                                }}
                                                                >
                                                                {state['loadingRejected_'+row.workstation_id] ? (
                                                                    <React.Fragment>
                                                                        {'Rejecting'}
                                                                        <Spinner className='text-center ml-2' color='light' size='sm' />{' '}
                                                                    </React.Fragment>
                                                                ) : 'Reject'
                                                                }
                                                            </button>
                                                        : null}
                                                        {row.approve_launch ?
                                                            <button className={`btn btn-sm bg-green text-white`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    if(!state['loadingRejected_'+row.workstation_id] && !state['loadingApproved_'+row.workstation_id]) {
                                                                        setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'launch', ['loadingApproved_'+row.workstation_id]: true }))
                                                                    }
                                                                }}
                                                            >
                                                                {state['loadingApproved_'+row.workstation_id] ? (
                                                                    <React.Fragment>
                                                                        {'Approving'}
                                                                        <Spinner className='text-center ml-2' color='light' size='sm' />{' '}
                                                                    </React.Fragment>
                                                                ) : 'Approve & Lanuch'
                                                                }
                                                            </button>
                                                        : !state['hideRejected_'+row.workstation_id] && !state['hideApproved_'+row.workstation_id] ?
                                                            <button
                                                                className={`btn btn-sm bg-green text-white`}
                                                                // onClick={(event) => onClickSubscribe(event, row, index)}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    if(!state['loadingRejected_'+row.workstation_id] && !state['loadingApproved_'+row.workstation_id]) {
                                                                        setState(prevState => ({ ...prevState, selectedCatalog: row, selectedStatus: 'approved', ['loadingApproved_'+row.workstation_id]: true }))
                                                                    }
                                                                }}
                                                                >
                                                                {state['loadingApproved_'+row.workstation_id] ? (
                                                                    <React.Fragment>
                                                                        {'Approving'}
                                                                        <Spinner className='text-center ml-2' color='light' size='sm' />{' '}
                                                                    </React.Fragment>
                                                                ) : 'Approve'
                                                                }
                                                            </button>
                                                        : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className='d-flex justify-content-center m-4'>
                            There are no data on this criteria. Please try adjusting your filter.
                        </div>
                    }
                </div>
            </div>
            </React.Fragment>
    );
};

export default NewRequest