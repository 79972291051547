
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file AlertDetails.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import { useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter } from '../../../utils/utility'


const AlertDetails = (props) => {
    const [state, setState] = useState({
        expandAlertDetails: true
    })
    const alertDetails = useSelector(state => state?.hpc?.hpcPropsDetails?.alertDetails || false);

    useEffect(() => {
        if(!_.isEqual(alertDetails, state.alertDetails)) {
            setState(prevState => ({
                ...prevState, 
                alertDetails,
                showSpinner: true,
                expandAlertDetails: true,
                expandAssetDetails: false
            }))

            setTimeout(() => setState(prevState => ({ ...prevState, showSpinner: false })), 2000)
        }
    }, [alertDetails, state.alertDetails])

    return (
        <div className="mt-2 errorSectionScroll">
            {state.showSpinner ?
                <div className='d-flex justify-content-center m-4'>
                    <Spinner className='text-center' color='light' />
                </div>
            :
                alertDetails.details && Object.entries(alertDetails.details).length ?
                    <div className="rounded bg-dark3 p-3">
                        <div className="accordion" id="accordionExample">
                            <div className={`card bg-dark border-0 mb-2`}>
                                <div id={'heading_event'} onClick={() => this.setState({ expandAlertDetails: !state.expandAlertDetails })}>
                                    <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_event'}aria-expanded="true" aria-controls={'collapse_event'}>
                                        <div className="text-white"><span className={`far ${state.expandAlertDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Alert Details</div>
                                        {/* <div className="text-info">Showing event details</div> */}
                                    </div>
                                </div>
                                <div id={'collapse_event'} className={`collapse ${state.expandAlertDetails ? 'show' : ''}`} aria-labelledby={'heading_event'} data-parent="#accordionExample">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between mb-2 pb-2 border-mediumDarkGray-bottom">
                                            {alertDetails.details.type === 'alert' ?
                                                <div>
                                                    <p className="mb-0 f16 text-white">{alertDetails.details.event_name ? alertDetails.details.event_name : ''}</p>
                                                    <p className="mb-0 f12">{alertDetails.details.description ? alertDetails.details.description : ''}</p>
                                                </div>
                                            : 
                                                <div>
                                                    <div className="d-flex flex-wrap">
                                                        <p className="mb-0 f16 text-white">{alertDetails.details.resource_type ? alertDetails.details.resource_type : ''}</p>
                                                        <p className="mb-0 f16 text-white">{alertDetails.details.event_type ? '_'+alertDetails.details.event_type : ''}</p>
                                                        <p className="mb-0 f16 text-white">{alertDetails.details.asset_name ? '_'+alertDetails.details.asset_name : ''}</p>
                                                    </div>
                                                    <p className="mb-0 f12">{alertDetails.details.event_type ? alertDetails.details.event_type : ''}</p>
                                                </div>
                                            }
                                            <div>
                                                <p className="b-block mb-0">Severity:</p>
                                                <span className={`mr-2 badge ${alertDetails.details.severity ? 'risk-badge-'+alertDetails.details.severity.toLowerCase() : 'badge-secondary' }`}>  
                                                    {alertDetails.details.severity ? capitalizeFirstLetter(alertDetails.details.severity) : 'Unknown'}
                                                </span>
                                            </div>
                                        </div>
                                        {alertDetails.details.type === 'group' ?
                                            <React.Fragment>
                                            <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                                <div className="py-1 w-50">
                                                    <div className="d-flex flex-wrap"> 
                                                        <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                        {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                        <p className={`align-self-center mb-0 mr-2 badge ${alertDetails.details.first_event_severity ? 'risk-badge-'+alertDetails.details.first_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                            {alertDetails.details.first_event_severity ? capitalizeFirstLetter(alertDetails.details.first_event_severity) : 'Unknown'}
                                                        </p>
                                                        <p className="b-block mb-0 mr-2 text-white">for the first alert observed on</p>
                                                        <p className="mb-0 text-white">{alertDetails.details.first_event ? momentConvertionUtcToLocalTime(alertDetails.details.first_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.first_event_threshold && alertDetails.details.first_event_threshold}
                                                        {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.first_event_statistic ? (' : ')+alertDetails.details.first_event_statistic : ''}
                                                        {alertDetails.details.first_event_period ? (' : ')+alertDetails.details.first_event_period : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                                <div className="py-1 w-50">
                                                    <div className="d-flex flex-wrap"> 
                                                        <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                        {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                        <p className={`align-self-center mb-0 mr-2 badge ${alertDetails.details.last_event_severity ? 'risk-badge-'+alertDetails.details.last_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                            {alertDetails.details.last_event_severity ? capitalizeFirstLetter(alertDetails.details.last_event_severity) : 'Unknown'}
                                                        </p>
                                                        <p className="b-block mb-0 mr-2 text-white">for the last alert observed on</p>
                                                        <p className="mb-0 text-white">{alertDetails.details.last_event ? momentConvertionUtcToLocalTime(alertDetails.details.last_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.last_event_threshold && alertDetails.details.last_event_threshold}
                                                        {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.last_event_statistic ? (' : ')+alertDetails.details.last_event_statistic : ''}
                                                        {alertDetails.details.last_event_period ? (' : ')+alertDetails.details.last_event_period : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                                <div className="py-1 w-50">
                                                    <div className="d-flex flex-wrap"> 
                                                        <p className="b-block mb-0 mr-2 text-white">Severity</p>
                                                        {/* <span className={`align-self-center mr-1 fa fa-circle risk-${item.severity.toLowerCase()}`}></span> */}
                                                        <p className={`align-self-center mb-0 mr-2 badge ${alertDetails.details.highest_event_severity ? 'risk-badge-'+alertDetails.details.highest_event_severity.toLowerCase() : 'badge-secondary' }`}>  
                                                            {alertDetails.details.highest_event_severity ? capitalizeFirstLetter(alertDetails.details.highest_event_severity) : 'Unknown'}
                                                        </p>
                                                        <p className="b-block mb-0 mr-2 text-white">for the highest alert observed on</p>
                                                        <p className="mb-0 text-white">{alertDetails.details.highest_event ? momentConvertionUtcToLocalTime(alertDetails.details.highest_event, 'DD MMM YYYY HH:mm') : ''}</p>
                                                    </div>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.highest_event_threshold && alertDetails.details.highest_event_threshold}
                                                        {alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.highest_event_statistic ? (' : ')+alertDetails.details.highest_event_statistic : ''}
                                                        {alertDetails.details.highest_event_period ? (' : ')+alertDetails.details.highest_event_period : ''}
                                                    </p>
                                                </div>
                                            </div>
                                            </React.Fragment>

                                        : null}
                                        {alertDetails.details.type === 'alert' ?
                                            <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Alert Time:</p>
                                                    <p className="mb-0 text-white">
                                                        {alertDetails.details.type === 'alert' ?
                                                            alertDetails.details.event_time ? momentConvertionUtcToLocalTime(alertDetails.details.event_time, 'DD MMM YYYY HH:mm') : ''
                                                        :
                                                            alertDetails.details.created_at ? momentConvertionUtcToLocalTime(alertDetails.details.created_at, 'DD MMM YYYY HH:mm') : ''
                                                        }
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Alert Metrics:</p>
                                                    {alertDetails.details.type === 'alert' ?
                                                        <p className="mb-0 text-white">
                                                            {alertDetails.details.event_source ? alertDetails.details.event_source : ''}
                                                            {alertDetails.details.event_type ? (' : ')+alertDetails.details.event_type : ''}
                                                        </p>
                                                    :        
                                                        // <p className="mb-0 text-white">
                                                        //     {alertDetails.details.event_type ? alertDetails.details.event_type : ''}
                                                        // </p>
                                                    null}
                                                </div>
                                            </div>
                                        : null}
                                        {alertDetails.details.type === 'alert' ?
                                            <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Threshold / Statistic / Period:</p>
                                                    <p className="mb-0 text-warning">
                                                        {alertDetails.details.threshold && alertDetails.details.threshold}{alertDetails.details.unit ? (alertDetails.details.unit ? (alertDetails.details.unit.toLowerCase() === 'percent' ? ' %' : alertDetails.details.unit) : '') : ''}
                                                        {alertDetails.details.statistic ? (' : ')+alertDetails.details.statistic : ''}
                                                        {alertDetails.details.period ? (' : ')+alertDetails.details.period : ''}
                                                    </p>
                                                </div>
                                                <div className="py-1 w-50 pl-3">
                                                    <p className="b-block mb-0">Provider / Account / Region :</p>
                                                    <p className="mb-0 text-white">
                                                        {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ''}
                                                        {alertDetails.details.account_id ? (' : ')+alertDetails.details.account_id : ''}
                                                        {alertDetails.details.region ? (' : ')+alertDetails.details.region : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        : null}
                                        {alertDetails.details.type !== 'alert' ?
                                            <div className="d-flex mb-1 border-top">
                                                <div className="py-1 w-50">
                                                    <p className="b-block mb-0">Provider / Account / Region :</p>
                                                    <p className="mb-0 text-white">
                                                        {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ''}
                                                        {alertDetails.details.account_id ? (' : ')+alertDetails.details.account_id : ''}
                                                        {alertDetails.details.region ? (' : ')+alertDetails.details.region : ''}
                                                    </p>
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>

                            {state.assetDetails && state.assetDetails.length ?
                                <div className={`card bg-dark border-0 mb-2`}>
                                    <div id={'heading_config_assets'} onClick={() => this.setState({ expandAssetDetails: !state.expandAssetDetails })}>
                                        <div className="p-3 mb-0 d-flex justify-content-between" data-toggle="collapse" data-target={'#collapse_config_assets'}aria-expanded="true" aria-controls={'collapse_config_assets'}>
                                            <div className="text-white"><span className={`far ${state.expandAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                                            {/* <div className="text-info">Showing asset details</div> */}
                                        </div>
                                    </div>
                                    <div id={'collapse_config_assets'} className={`collapse ${state.expandAssetDetails ? 'show' : ''}`} aria-labelledby={'heading_config_assets'} data-parent="#accordionExample">
                                        {state.assetDetails.map((item, iIndex) => {
                                            return(
                                            <div key={'asset_'+iIndex} className="card-body m-2 pt-2 rounded counts_threads">
                                                <div className="d-flex mb-2 border-mediumDarkGray-bottom">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Name:</p>
                                                        <p className="mb-0 text-white">{item.asset_name ? item.asset_name : ''}</p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Id:</p>
                                                        <p className="mb-0 text-white">{item.asset_id ? item.asset_id : ""}</p>
                                                    </div>
                                                </div>
                                                <div className="d-flex mb-2">
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Resource:</p>
                                                        <p className="mb-0 text-white">
                                                            {alertDetails.details.provider ? alertDetails.details.provider.toUpperCase() : ""}
                                                            {alertDetails.details.account_id ? (" : ")+alertDetails.details.account_id : ""}
                                                            {alertDetails.details.region ? (" : ")+alertDetails.details.region : ""}
                                                        </p>
                                                    </div>
                                                    <div className="py-1 w-50 pl-3">
                                                        <p className="b-block mb-0">Services:</p>
                                                        <p className="mb-0 text-white">
                                                            {item.resource_type ? item.resource_type : ''}
                                                            {item.service_name ? (" : ")+item.service_name : ''}
                                                        </p>
                                                    </div>
                                                </div>                                                        
                                                <div className="d-flex mb-2 border-mediumDarkGray-bottom">
                                                    <div className="py-1 w-100">
                                                        <p className="b-block mb-0">ARN:</p>
                                                        <p className="mb-0 text-white">{item.asset_arn ?item.asset_arn : ''}</p>
                                                    </div>
                                                </div>
                                                {item.category ? 
                                                    <div className="d-flex mb-2 border-mediumDarkGray-bottom">
                                                        <div className="py-1 w-100">
                                                            <p className="b-block mb-0">Category:</p>
                                                            <div className={`d-flex flex-wrap`}>
                                                                {item.category.map(item => {
                                                                    return (
                                                                        <small key={'cat_'+item} className={`mr-1 mb-1 badge badge-light`}>{item}</small> 
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null}
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            : null}
                        </div>
                    </div>
                :
                    <div className='d-flex justify-content-center m-4'>
                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                    </div>
            }
        </div>
    )
}

export default AlertDetails