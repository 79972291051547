import ApiCalls from "../../components/ApiCalls";
import {
  GET_USER_FILES,
  UPLOAD_USER_FILES,
  CREATE_FOLDER,
  DOWNLOAD_FILE,
  GET_STATS,
  DELETE_FILE,
  GET_FILE_CONTENT,
  SAVE_FILE_CONTENT,
  RENAME_FILE,
  EXTRACT_FILE
} from "../../config"
// import { ACTION_TYPES } from "../types"

/**
 * Action to list-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const getUserFiles = (params) => {
  return async () => {
    try {
      if(params.path) {
        params.path = params.path.replace(/\\/g, '/')
      }

      
      const response = await ApiCalls.post(GET_USER_FILES, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

/**
 * Action to list-cluster-details
 * @param {Object} body
 * @param {Function} callback
 */
export const uploadUserFiles = (params) => {
  return async () => {
    try {
      const response = await ApiCalls.post(UPLOAD_USER_FILES, params, { formContentType: false });
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const createFolder = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(CREATE_FOLDER, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const downloadFile = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(DOWNLOAD_FILE, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const getStats = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(GET_STATS, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const deleteFile = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(DELETE_FILE, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const getFileContent = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(GET_FILE_CONTENT, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const saveFileContent = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(SAVE_FILE_CONTENT, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const renameFile = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(RENAME_FILE, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}

export const extractFile = (params) => {
  return async () => {
    try {      
      const response = await ApiCalls.post(EXTRACT_FILE, params);
      return response.data;
    } catch (error) {
      return error.response && error.response.data ? error.response.data : {}
    }
  }
}