import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input } from 'reactstrap';
import { Store as CommonNotification } from 'react-notifications-component';
import { getLifecycleEndTime, lifecycleNotification } from '../../actions/commonActionNew' 
import { URL_PATH } from '../../config/urlPath';
import { onlyNumeric } from '../../utils/utility';

let getSesssionTime = null
const LifecycleNotifications = () => {

  const [state, setState] = useState({
    confirmAction: true
  })

  const navigate = useNavigate();
  const dispatch = useDispatch(); // Create a dispatch function

  const navigatoTo = useCallback(() => {
    navigate(URL_PATH.JOB_STATUS)
  }, [navigate])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if(params <= 0) {
      navigatoTo()
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      // Create an object to store the extracted parameters and values
      // Get values from the URL
      const resourceId = urlParams.has('resource_id') ? urlParams.get('resource_id') : null;
      const expirationTime = urlParams.has('expiration_time') ? urlParams.get('expiration_time') : null;
      // const action = urlParams.has('action') ? urlParams.get('action') : null;

      setState(prevState => ({ ...prevState, resourceId, expirationTime, confirmAction: true}))
    }
  }, [navigatoTo])

  useEffect(() => {
    if(!state.confirmAction) {
      navigatoTo()
    }
  }, [state.confirmAction, navigatoTo])

  const handleConfirmation = () => {
    // Get the search params from the current URL
    let hasError = false
    
    let params = {} //resource_id:"64ab0933d6ee3cb295c35b2ee7c66f77",expiration_time:"1714646797", action: "approved"}
    params.resource_id = state.resourceId
    params.expiration_time = state.expirationTime
    // params.action = state.action
    // if(params.action === 'extended') {
      if(state.approve_shutdown) {
        params.action = 'approved'        
      } else {
        params.action = 'extended'
        params.extended_duration_in_hrs = parseInt(state.extended_duration_in_hrs)
        if(state.extended_duration_in_hrs < 1 || state.extended_duration_in_hrs > 96) {
          hasError = true
        }
      }
    // }
    setState(prevState => ({ ...prevState, hasError, showConfirmActionLoading: hasError ? false : true }))
    
    if(!hasError) {
      dispatch(lifecycleNotification(params))
        .then((response) => {
          let messageType = 'danger'
          let message = response && response.message ? response.message : 'Error in notificaiton'
          if(response && !response.error) {
              if(response.status && response.status !== 500) {
                  messageType = 'success'
                  message = response.message ? response.message : 'Notified'
                  navigatoTo()
              } else {
                setState(prevState => ({ ...prevState, showConfirmActionLoading: false  }))
              }
          } else {
            setState(prevState => ({ ...prevState, showConfirmActionLoading: false  }))
          }

          CommonNotification.addNotification({
              //title: 'Wonderful!',
              message: message,
              type: messageType,
              insert: 'top',
              container: 'top-center',
              // animationIn: ['animate__animated', 'animate__fadeIn'],
              // animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
              }
          });
        })
    }
  }

  const getNextScheduleTime = (hours) => {    
    let params = {}
    const urlParams = new URLSearchParams(window.location.search);
    params.resource_id = urlParams.has('resource_id') ? urlParams.get('resource_id') : null;
    params.expiration_time = urlParams.has('expiration_time') ? urlParams.get('expiration_time') : null;
    params.extended_duration_in_hrs  = hours
    params.action = 'extended'
    let hasError = false
    if(hours < 1 || hours > 96) {
      hasError = true
    }

    setState(prevState => ({ ...prevState, hasError }))

    if(!hasError) {
      dispatch(getLifecycleEndTime(params))
        .then((response) => {
          if(response) {
            setState(prevState => ({ ...prevState, nextScheduleResponse: response, scheduleLoading: false }))
          } else {
            setState(prevState => ({ ...prevState, nextScheduleResponse: {}, scheduleLoading: false }))
          }
        })
    }
  }

  // Render the child components normally
  return (
    <Modal isOpen={state.confirmAction} toggle={() => setState(prevState => ({ ...prevState, confirmAction: !state.confirmAction }))}>
      <ModalHeader toggle={() => setState(prevState => ({ ...prevState, confirmAction: !state.confirmAction }))}>Workstation Shutdown Alert</ModalHeader>
      <ModalBody>
        <p>Please confirm the action</p>
          <div className="d-flex">
            <div className={`form-check form-check-inline align-self-center mr-2`}>
              <input
                className='form-check-input m-0'
                type='checkbox'
                checked={state.approve_shutdown}
                disabled={state.extended_duration_in_hrs ? true : false}
                onChange={(e) => setState(prevState => ({ ...prevState, approve_shutdown: !state.approve_shutdown, extended_duration_in_hrs: '' }))}	
                // title={state.retain_configuration ? "not enable" : "enable"}								
              />
              {!state.retain_configuration ?
                <span className="checkmark" onClick={e => setState(prevState => ({ ...prevState, approve_shutdown: !state.approve_shutdown}))}></span>
              : null}
            </div>
            <span className="mt-2p f16 text-lightGray">Approve Shutdown</span>
          </div>
          <p className='my-2'>(or)</p>
          <div className='d-flex flex-wrap'>
            <label className='mr-2 align-self-end mb-0'>Extend Session</label>
            <Input
              type='text'
              placeholder={`${state.approve_shutdown ? '' : 'Enter hrs'}`}
              className={`inputTextbox bg-transparent border-0 rounded-0 border-lightGray-bottom text-black w-20 ${state.approve_shutdown ? 'bg-lightGray pe-none disabled' : ''}`}
              // maxlength='128'
              value={state.extended_duration_in_hrs ? state.extended_duration_in_hrs : ''}
              onChange={e => {
                if(!state.approve_shutdown) {
                  clearTimeout(getSesssionTime)
                  setState(prevState => ({ ...prevState, extended_duration_in_hrs: onlyNumeric(e.target.value) }))
                  getSesssionTime = setTimeout(() => { getNextScheduleTime(e.target.value) }, 1000); 
                }
              }}
            />
            <span className='ml-2 align-self-end'>hours
            </span>
          </div>
          {/* {state.extended_duration_in_hrs ?
            <p className='mr-2 align-self-end mt-3'>
              <span className='text-info cursor_pointer' onClick={() => {
                if(!state.scheduleLoading) {
                  setState(prevState => ({ ...prevState, scheduleLoading: true }))
                  getNextScheduleTime()
                }
              }}>
                {state.scheduleLoading ? 
                  <Spinner size='sm' color='dark' className='mr-2' />
                : 
                  <span className='mr-2 cursor-pointer'>click here</span>
                }
              </span> 
              to check next scheduled time
            </p>
          : null} */}
          {state.hasError && state.extended_duration_in_hrs ?
            <p className='my-2 text-danger'>Extend session should be between 1 and 96 hours.</p>
          : state.nextScheduleResponse && state.nextScheduleResponse.message?
            <p className='my-2 text-danger'>{state.nextScheduleResponse.message}</p>
          : null}
        {/* <span className='f18 mx-1 text-red'>{state.deleteDepartment && state.deleteDepartment.department ? state.deleteDepartment.department : ''}</span> */}
      </ModalBody>
      <ModalFooter className="border-0">
      {/* {state.nextScheduleResponse && state.nextScheduleResponse.message?
        <p className='align-self-center text-info mr-2 mb-0'>{state.nextScheduleResponse.message}</p>
      : null} */}
      <Button color="primary" onClick={() => {
        if(!state.showConfirmActionLoading && !state.scheduleLoading) {
          setState(prevState => ({ ...prevState, showConfirmActionLoading: true }))
          handleConfirmation()
        }
      }}>
          {state.showConfirmActionLoading ?
            <Spinner size='sm' color='light' className='mr-2' />
          : null}
          Confirm
      </Button>{' '}
      <Button color="secondary" onClick={() => {
          setState(prevState => ({ ...prevState, confirmAction: !state.confirmAction, name: '' }))
          // navigatoTo()
      }}>
          Cancel
      </Button>
      </ModalFooter>
    </Modal>
  )
}

export default LifecycleNotifications;
