import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from 'react-dom/client'
import App from './components/App';
import { Provider } from 'react-redux';
import store from './store';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const pca = new PublicClientApplication(msalConfig);
await pca.initialize();

const el = document.getElementById('root');
const root = createRoot(el); // Use createRoot

pca.addEventCallback(event => {
    if(event.eventType === EventType.LOGIN_SUCCESS) {
        pca.setActiveAccount(event.payload.account)
    }
});

// Initialize MSAL
pca.handleRedirectPromise()
    .then(() => {
            root.render(
                <Provider store={store}>
                <ReactNotifications />
                <App msalInstance={pca} />
                </Provider>
            );
    })
    .catch((error) => {
        console.error("MSAL initialization error:", error);
    });



// const el = document.getElementById('root')
// const root = ReactDOM.createRoot(el)

// root.render(
    // <Provider store={store}>
    //     <ReactNotifications />
    //     <App msalInstance={pca}/>
    // </Provider> 
// ) 