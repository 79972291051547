/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 1/06/2023
 * @copyright © 2022 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { momentDateGivenFormat } from '../../../utils/utility'

import MultiSelectSection from '../../common/MultiSelectSection';

import Chart from 'react-apexcharts'

import Inventory from './Inventory'

const LandingPage = () => {

    const [state, setState] = useState({
        showLoading: true,

        selectedShowBy: "",

        typeofPages: [
            {label: "Inventory", value: "Inventory"}
        ],
        selectedPage: "Inventory",
        topSectionFilters: ['provider', 'account', 'region']
        
        //filter section end
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
    
    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callSearch: true  }));
                }
                })
        }
    }, [dispatch, providers]);  

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedAccount: [], selectedRegion: [], showInventory: false, callSearch: true }));
    }

    useEffect(() => {
        if (state.callSearch) {
            setState(prevState => ({ ...prevState, showInventory: true, callSearch: false }))
        }
    }, [state.callSearch]);

    const handleChildClick = (event, type, dropdownType, section) => {

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className="container-fluid overflow-auto flex-grow-1 px-0" onClick={(event) => handleChildClick(event, 'parent')}>
            {/* <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div> */}
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 bg-nero'>
                    <div className='col-lg-4 col-4 py-2 align-self-center'>
                        <h6 className="text-white m-0">Resources</h6>
                        <p className="text-white m-0">Count board view of resources on your Cloud</p>
                    </div>
                    <div className='col-lg-8 col-8 py-2'>
                        <div className='row mx-0 justify-content-end'>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}}
                                onClick={(event) => {
                                    if(!state.isProviderOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                    }
                                }}
                            >
                                <label className='mb-1 mt-1 f10 d-flex'>Provider</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedProvider ? state.selectedProvider : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isProviderOpen ?
                                    <MultiSelectSection 
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [] }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                    />
                                : null}
                            </div>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                                }}
                            >
                                <label className='mb-2 mt-1 f10 d-flex'>Account</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isAccountOpen ?
                                    <MultiSelectSection 
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value }))
                                        }}
                                    />
                                : null}
                            </div>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
                                }}
                            >
                                <label className='mb-2 mt-1 f10 d-flex'>Region</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isRegionOpen ?
                                    <MultiSelectSection 
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
                                    />
                                : null}
                            </div>
                            <div className={`ml-3 align-self-center`}>
                                <div className='btn-group' role='group' aria-label='Basic example'>
                                    <button type='button' className={`cursorPointer btn bg-cyon text-white border-right text-white`} onClick={() => setState(prevState => ({ ...prevState, showInventory: false, callSearch: true }))}>Search</button>
                                    <button type='button' className={`cursorPointer btn bg-cyon text-white border-left text-white`} onClick={()=> onReset()}><i className='far fa-redo'/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <React.Fragment>
                    {state.showBrushGraph ? 
                        <div className="rounded bg-transparent w-100 mt-2">
                            <div className="d-flex">
                                {state.brushSeries && state.brushSeries.length && state.optionsBrush && Object.entries(state.optionsBrush).length ?
                                    <React.Fragment>
                                    <div className="w-5 d-flex justify-content-end pr-2">&nbsp;</div>
                                    <div className="removeSvgPoint w-80">
                                        <Chart options={state.optionsBrush} series={state.brushSeries} type="line" height={8} />
                                    </div>
                                    <div className="pl-2 w-15">
                                        <small className="mb-0 align-self-center text-info f10">{momentDateGivenFormat(state.brushStartTime, 'DD MMM HH:mm') +' - '+momentDateGivenFormat(state.brushEndTime, 'DD MMM HH:mm')}</small>
                                    </div>
                                    </React.Fragment>
                                : null}
                            </div>
                        </div>
                    : null}
                    {state.selectedProvider && state.showInventory ?
                        <Inventory 
                            selectedProvider={state.selectedProvider}
                            selectedAccount={state.selectedAccount}
                            selectedRegion={state.selectedRegion}
                        />
                    : null}
                </React.Fragment>
            </div>
        </div>
    )
}

export default LandingPage