/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Kiosk
 * @exports
 * @file AlertConfirmation.js
 * @author Prakash // on 26/03/2024
 * @copyright ©2024 Kiosk. All rights reserved.
 *************************************************/
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

const AlertConfirmation = (props) => {
    const [state, setState] = useState({
        alertModal: true
    })

    return (
        <Modal isOpen={state.alertModal} toggle={() => setState(prevState => ({ ...prevState, alertModal: !state.alertModal }))}>
            <ModalHeader toggle={() => setState(prevState => ({ ...prevState, alertModal: !state.alertModal }))}>{props.confirmDetails && props.confirmDetails.heading ? props.confirmDetails.heading : 'Confirm Action'}</ModalHeader>
            <ModalBody>
            {props.confirmDetails && props.confirmDetails.message ? props.confirmDetails.message : 'Please confirm the action'} 
            {/* <span className='f18 mx-1 text-red'>{state.deleteDepartment && state.deleteDepartment.department ? state.deleteDepartment.department : ''}</span> */}
            </ModalBody>
            <ModalFooter className="border-0">
            <Button color="primary" onClick={() => props.confirmation('confirmed')}>
                {/* <Spinner size='sm' color='light' className='mx-2' /> */}
                Confirm
            </Button>{' '}
            <Button color="secondary" onClick={() => {
                setState(prevState => ({ ...prevState, alertModal: !state.alertModal, showDeleteLoading: false, name: '' }))
                props.confirmation()
            }}>
                Cancel
            </Button>
            </ModalFooter>
        </Modal>
    )
}

export default AlertConfirmation