import React from 'react';

import _ from 'lodash'
import Select from 'react-select'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'

// import { getDayFromSelectedDuration } from '../../../../utils/utility'

class InstanceTypeInputSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            inputComponent: this.props.inputComponent,
            selectedPage: this.props.selectedPage,
            selectedOption: this.props.selectedOption,
            selectedFilters: this.props.selectedFilters,
            type: "Include"
        }
    }

    componentDidMount = () => {
		this.structureServices()
	}

	structureServices = () => {
		let serviceResourceArray = []
		let serviceArray = []
		this.props.services &&
			this.props.services.length &&
			_.chain(this.props.services)
			.groupBy('resource_type')
			.map((value, key) => ({ label: key, sub: value }))
			.value()
			.forEach((service, i) => {
				let resourceRow = {}
				resourceRow.label = service.label
				resourceRow.value = service.label
				serviceResourceArray.push(resourceRow)
				//serviceResourceArray.push(service)
				service.sub.forEach((item, j) => {
					let serviceRow = {}
					serviceRow.label = item.service_name
					serviceRow.value = item.service_name
					serviceArray.push(serviceRow)
					//serviceArray.push(item)
				})
			})
		
		this.setState({ services: this.props.services, serviceResourceType: serviceResourceArray, definedService: serviceArray })
	}

    addDetails = () => {
        let selectedFilters = this.state.selectedFilters ? this.state.selectedFilters : []

        // let selectedData = selectedFilters.filter(e => e.label === this.state.type)
        if(this.state.inputText && this.state.inputText !== "") {
            let dataRow = {}
            dataRow.label = this.state.selectedOption
            dataRow.service = this.state.selectedServices
            dataRow.value = this.state.inputText
            dataRow.userView = this.state.selectedOption+": " +this.state.selectedServices+" : "+this.state.inputText
            selectedFilters.push(dataRow)
        }

        this.setState({ selectedFilters, inputText: "" })
        
    }

	remove = (row) => {
		let filteredResult = this.state.selectedFilters.filter(e => e !== row)
		this.setState({selectedFilters: filteredResult })
	}

    applyFilter = () => {
        if(this.state.selectedFilters && this.state.selectedFilters.length) {
            return this.props.selectedInput(this.state.selectedOption, this.state.selectedFilters)
        } else {
            return this.props.selectedInput(false);
        }
    }

    closeFilter = () => {
        return this.props.selectedInput(false);
    }
    
    render() {
        return (
            
            <div className='container-fluid mt-2 py-3 bg-dark3 containerBorderFilterSection'>
                <p className="text-white mb-2">{this.state.selectedOption}</p>
                <div className="d-flex flex-wrap mb-3">
                    <Select
                        isSearchable={true}
                        className={`reactSelectDropTransparent f13 p-0 mr-2 w-20 `}
                        value={({
                            value: this.state.selectedServices,
                            label: this.state.selectedServices && this.state.selectedServices !== "" ? this.state.selectedServices : <span className="placeholder">Key</span>
                        })}														
                        
                        options={this.state.serviceResourceType && this.state.serviceResourceType.map(item => ({
                            value: item.value,
                            label: item.label,
                        }))}
                        onChange={event => this.setState({
                            selectedServices: event.value,
                        })}
                    />
                    <div className="d-flex">
                        <input 
                            type="text" 
                            className={`bg-transparent text-white border-lightGray py-2 pl-3 mr-2 minWidth400`}
                            style={{minHeight: "38px"}}
                            placeholder={"Enter details"}
                            value={this.state.inputText ? this.state.inputText : ""}
                            onChange={e => this.setState({ inputText: e.target.value })}
                        />
                    </div>
                
                    <span className={`far fa-plus cursor-pointer f18 mb-1 align-self-center mr-4`} onClick={() => this.addDetails()}></span>                                    
                </div>
                
                {this.state.selectedFilters && this.state.selectedFilters.length ? 
                    this.state.selectedFilters.map(row => {
                        return(
                            <span className="badge bg-info mr-2 mt-2 align-self-center f14 text-white px-2">{row.userView}
                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-white' onClick={ () => this.remove(row) } ></i>
                            </span>
                        )
                    })
                : null}
                
                <div className='row mt-2 mb-3'>
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 justify-content-end d-flex">
                        <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                            Cancel
                        </span>
                        <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default InstanceTypeInputSection