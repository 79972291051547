import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { Input } from "reactstrap";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../common/MultiSelectSection";
import {
  alphaNumeric,
  onlyNumeric,
} from "../../../utils/utility";

const QuesectionSection = (props) => {
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const [state, setState] = useState({
    queueArray: [0],
    computeArray_0: [0],
    s3AccessArray_0: [0],
  });

  const callPageVist = useCallback(() => {
    let queueConfiguration =
      createUpdateCluster && createUpdateCluster.queue_configuration
        ? createUpdateCluster.queue_configuration
        : {};

    let queueArray = [0];
    if (queueConfiguration && queueConfiguration.length) {
      queueArray = [];
      queueConfiguration.forEach((row, index) => {
        queueArray.push(index);
        if (row.on_node_configured_script) {
          setState((prevState) => ({
            ...prevState,
            ["showNodeScript_" + index]: true,
            ["on_node_configured_script_" + index]:
              row.on_node_configured_script,
          }));
        }
        setState((prevState) => ({
          ...prevState,
          ["queue_name_" + index]: row.queue_name ? row.queue_name : "",
          ["scaledown_idle_time_" + index]: row.scaledown_idle_time
            ? row.scaledown_idle_time
            : "",
          ["update_strategy_" + index]: row.update_strategy
            ? row.update_strategy
            : "",
          ["volume_type_" + index]: row.volume_type ? row.volume_type : "",
          ["volume_size_" + index]: row.volume_size ? row.volume_size : "",
          ["volume_encrypted_" + index]: row.volume_encrypted ? true : false,
          ["subnet_ids_" + index]: row.subnet_ids ? row.subnet_ids : [],
          ["security_group_ids_" + index]: row.security_group_ids
            ? row.security_group_ids
            : [],
          ["policies_" + index]: row.policies ? row.policies : [],
          ["selected_tags_" + index]: row.tags ? row.tags : [],
        }));

        let computeArray = [0];
        if (row.compute_resources && row.compute_resources.length) {
          computeArray = [];
          row.compute_resources.forEach((com, cIndex) => {
            computeArray.push(cIndex);
            setState((prevState) => ({
              ...prevState,
              ["compute_resource_name_" + index + "_" + cIndex]:
                com.compute_resource_name ? com.compute_resource_name : "",
              ["instance_type_" + index + "_" + cIndex]: com.instance_type
                ? com.instance_type
                : "",
              ["max_instance_count_" + index + "_" + cIndex]:
                com.max_instance_count ? com.max_instance_count : 0,
              ["min_instance_count_" + index + "_" + cIndex]:
                com.min_instance_count_ ? com.min_instance_count_ : 0,
              ["efa_enabled_" + index + "_" + cIndex]: com.efa_enabled
                ? true
                : false,
              ["placement_group_enabled_" + index + "_" + cIndex]:
                com.placement_group_enabled ? true : false,
              ["disable_simultaneous_multithreading_" + index + "_" + cIndex]:
                com.disable_simultaneous_multithreading ? true : false,
            }));
          });
        }

        let s3AccessArray = [0];
        if (row.s3_access && row.s3_access.length) {
          if (row.s3_access && row.s3_access.length) {
            s3AccessArray = [];
            row.s3_access.forEach((row, sIndex) => {
              s3AccessArray.push(sIndex);
              setState((prevState) => ({
                ...prevState,
                ["bucket_name_" + index + "_" + sIndex]: row.bucket_name
                  ? row.bucket_name
                  : "",
                ["enable_write_access_" + index + "_" + sIndex]:
                  row.enable_write_access ? true : false,
              }));
            });
          }
        }

        setState((prevState) => ({
          ...prevState,
          queueArray,
          ["computeArray_" + index]: computeArray,
          ["s3AccessArray_" + index]: s3AccessArray,
        }));
      });
    }
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const removefromList = (index, value, listField) => {

    let filteredListItem = state[listField + "_" + index].filter(
      (e) => e !== value
    );

    let array = [];
    filteredListItem.forEach((row) => {
      let rowValue = {
        value: row,
        label: row,
      };
      array.push(rowValue);
    });

    setState((prevState) => ({
      ...prevState,
      [listField + "_" + index]: filteredListItem,
    }));
  };

  const addNewTagValue = (index) => {
    let selectedTags = state["selected_tags_" + index]
      ? state["selected_tags_" + index]
      : [];

    if (
      state["tag_key_" + index] &&
      state["tag_key_" + index] !== "" &&
      state["tag_value_" + index] &&
      state["tag_value_" + index].length
    ) {
      let dataRow = {};
      dataRow.key = state["tag_key_" + index];
      dataRow.value = state["tag_value_" + index];
      selectedTags.push(dataRow);
    }

    setState((prevState) => ({
      ...prevState,
      ["selected_tags_" + index]: selectedTags,
      ["tag_key_" + index]: "",
      ["tag_value_" + index]: "",
    }));

    handleQueueInput(index, "tags", selectedTags);
  };

  const removeTagSection = (tag, index) => {
    let filteredResult =
      state["selected_tags_" + index] &&
      state["selected_tags_" + index].filter((e) => e !== tag);
    setState((prevState) => ({
      ...prevState,
      ["selected_tags_" + index]: filteredResult,
    }));

    handleQueueInput(index, "tags", filteredResult);
  };

  const addSection = (array) => {
    let rowList = state[array];
    if (state[array]) {
      let value = state[array][state[array].length - 1];
      value = value + 1;
      rowList.push(value);
    }

    setState((prevState) => ({ ...prevState, [array]: rowList }));
  };

  const removeCRSection = (array, index, cIndex, j) => {
    setState((prevState) => ({
      ...prevState,
      ["min_instance_count_" + index + "_" + cIndex]: "",
      ["max_instance_count_" + index + "_" + cIndex]: "",
      ["instance_type_" + index + "_" + cIndex]: "",
      ["compute_resource_name_" + index + "_" + cIndex]: "",
    }));

    let rowList = state[array + "_" + index];
    rowList.splice(j, 1);
    setState((prevState) => ({ ...prevState, [array + "_" + index]: rowList }));

    if (array === "s3AccessArray") {
      setTimeout(() => {
        handleS3AccessInput("s3_access", index);
      }, 500);
    } else {
      setTimeout(() => {
        handleComputeResourcesChange(index, cIndex);
      }, 500);
    }
  };

  const removeSection = (array, index, i) => {
    setState((prevState) => ({
      ...prevState,
      ["queue_name_" + index]: "",
      ["subnet_ids_" + index]: [],
      ["security_group_ids_" + index]: [],
      ["on_node_configured_script_" + index]: "",
      ["showNodeScript_" + index]: false,
      ["volume_encrypted_" + index]: "",
      ["volume_encrypted_" + index]: false,
      ["policies_" + index]: [],
      ["selected_tags_" + index]: [],
    }));

    state["computeArray_" + index].forEach((cIndex) => {
      setState((prevState) => ({
        ...prevState,
        ["compute_resource_name_" + index + "_" + cIndex]: "",
        ["instance_type_" + index + "_" + cIndex]: "",
        ["max_instance_count_" + index + "_" + cIndex]: "",
        ["min_instance_count_" + index + "_" + cIndex]: "",
        ["efa_enabled_" + index + "_" + cIndex]: false,
        ["placement_group_enabled_" + index + "_" + cIndex]: false,
        ["disable_simultaneous_multithreading_" + index + "_" + cIndex]: false,
      }));
    });

    state["s3AccessArray_" + index].forEach((sIndex) => {
      setState((prevState) => ({
        ...prevState,
        ["bucket_name_" + index + "_" + sIndex]: "",
        ["enable_write_access_" + index + "_" + sIndex]: false,
      }));
    });

    let rowList = state[array];
    rowList.splice(i, 1);
    setState((prevState) => ({ ...prevState, [array]: rowList }));

    let obj = createUpdateCluster ? createUpdateCluster : {};
    let queueConfiguration =
      obj.queue_configuration && obj.queue_configuration.length
        ? obj.queue_configuration
        : [];
    obj["queue_configuration"] = queueConfiguration.filter(
      (e) => e.arrayIndex !== index
    );
  };

  const addSectionQuerySection = (array) => {
    let rowList = state[array];
    if (state[array]) {
      let value = state[array][state[array].length - 1];
      value = value + 1;
      rowList.push(value);

      setState((prevState) => ({
        ...prevState,
        [array]: rowList,
        ["computeArray_" + value]: [0],
        ["s3AccessArray_" + value]: [0],
      }));
    }

    setState((prevState) => ({ ...prevState, [array]: rowList }));
  };

  const handleQueueInput = (index, label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let queueConfiguration =
      obj.queue_configuration && obj.queue_configuration.length
        ? obj.queue_configuration
        : [];
    let clusterConfiguration = obj.cluster_configuration
      ? obj.cluster_configuration
      : {};
  
    state.queueArray.forEach((qIndex) => {
      if (!queueConfiguration.filter((e) => e.arrayIndex === qIndex).length) {
        let dataRow = { arrayIndex: qIndex };
        queueConfiguration.push(dataRow);
      }
      let selectedQueueConfig = queueConfiguration.filter(
        (e) => e.arrayIndex === qIndex
      )[0];
      if (qIndex === index) {
        setState((prevState) => ({
          ...prevState,
          [label + "_" + qIndex]: value,
        }));
      }
      let inputValue = qIndex === index ? value : state[label + "_" + qIndex];
      
      if (label && label !== "tag_key" && label !== "tag_value") {
        selectedQueueConfig[label] =
          type === "integer" ? parseInt(inputValue) : inputValue;
      }
    });
  
    obj["queue_configuration"] = queueConfiguration;
  
    // Check if the label is 'scaledown_idle_time'
    if (label === 'scaledown_idle_time') {
      clusterConfiguration[label] = type === "integer" ? parseInt(value) : value;
    }
  
    obj["cluster_configuration"] = clusterConfiguration;
  
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  };
  

  const handleComputeResourcesChange = (index, cIndex, label, value) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let queueConfiguration =
      obj.queue_configuration && obj.queue_configuration.length
        ? obj.queue_configuration
        : [];
    state.queueArray.forEach((qIndex) => {
      let computeResources = [];
      state["computeArray_" + qIndex].forEach((row, j) => {
        let comRow = {
          compute_resource_name:
            index === qIndex &&
            row === cIndex &&
            label === "compute_resource_name"
              ? value
              : state["compute_resource_name_" + qIndex + "_" + row],
          instance_type:
            index === qIndex && row === cIndex && label === "instance_type"
              ? value
              : state["instance_type_" + qIndex + "_" + row],
          min_instance_count:
            index === qIndex && row === cIndex && label === "min_instance_count"
              ? parseInt(value)
              : state["min_instance_count_" + qIndex + "_" + row]
              ? Math.round(
                  parseFloat(state["min_instance_count_" + qIndex + "_" + row])
                )
              : 0,
          max_instance_count:
            index === qIndex && row === cIndex && label === "max_instance_count"
              ? parseInt(value)
              : state["max_instance_count_" + qIndex + "_" + row]
              ? Math.round(
                  parseFloat(state["max_instance_count_" + qIndex + "_" + row])
                )
              : 0,
          efa_enabled:
            index === qIndex && row === cIndex && label === "efa_enabled"
              ? value
              : state["efa_enabled_" + qIndex + "_" + row]
              ? true
              : false,
          placement_group_enabled:
            index === qIndex &&
            row === cIndex &&
            label === "placement_group_enabled"
              ? value
              : state["placement_group_enabled_" + qIndex + "_" + row]
              ? true
              : false,
          disable_simultaneous_multithreading:
            index === qIndex &&
            row === cIndex &&
            label === "disable_simultaneous_multithreading"
              ? value
              : state[
                  "disable_simultaneous_multithreading_" + qIndex + "_" + row
                ]
              ? true
              : false,
        };
        computeResources.push(comRow);

        if (index === qIndex && row === cIndex) {
          setState((prevState) => ({
            ...prevState,
            [label + "_" + index + "_" + cIndex]: value,
          }));
        }
      });
      if (queueConfiguration.length === state.queueArray.length) {
        queueConfiguration[qIndex]["compute_resources"] = computeResources;
      } else {
        let dataRow = {
          compute_resources: computeResources,
        };
        queueConfiguration.push(dataRow);
      }
    });
  };

  const handleS3AccessInput = (label, index, sIndex, value, valueField) => {
    setState((prevState) => ({
      ...prevState,
      [label + "_" + index + "_" + sIndex]: value,
    }));

    let obj = createUpdateCluster ? createUpdateCluster : {};
    let queueConfiguration =
      obj.queue_configuration && obj.queue_configuration.length
        ? obj.queue_configuration
        : [];
    state.queueArray.forEach((qIndex) => {
      let s3Accesss = [];
      if (valueField) {
        state["s3AccessArray_" + qIndex].forEach((row) => {
          let dataRow = {};
          dataRow.bucket_name =
            qIndex === index && row === sIndex && label === "bucket_name"
              ? value
              : state["bucket_name_" + qIndex + "_" + row];
          dataRow.enable_write_access =
            qIndex === index &&
            row === sIndex &&
            label === "enable_write_access"
              ? value
              : state["enable_write_access_" + qIndex + "_" + row]
              ? true
              : false;
          if (dataRow.bucket_name) {
            s3Accesss.push(dataRow);
          }
        });
      } else {
        state["s3AccessArray_" + qIndex].forEach((row) => {
          let dataRow = {
            bucket_name: state["bucket_name_" + qIndex + "_" + row],
            enable_write_access: state[
              "enable_write_access__" + qIndex + "_" + row
            ]
              ? true
              : false,
          };
          if (state["bucket_name_" + qIndex + "_" + row]) {
            s3Accesss.push(dataRow);
          }
        });
      }

      queueConfiguration[qIndex]["s3_access"] = s3Accesss;
    });
  };

  const addPolicies = (index) => {
    let policies = state["policies_" + index] ? state["policies_" + index] : [];
    policies.push(state["policy_" + index]);

    handleQueueInput(index, "policies", policies);
    setState((prevState) => ({
      ...prevState,
      ["policies_" + index]: policies,
      ["policy_" + index]: "",
    }));
  };

  const removePolicies = (index, value) => {
    let policies = state["policies_" + index].filter((e) => e !== value);

    handleQueueInput(index, "policies", policies);
    setState((prevState) => ({
      ...prevState,
      ["policies_" + index]: policies,
    }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div
      className="rounded bg-dark p-3"
      onClick={(event) => handleChildClick(event, "parent")}
    >
      {state.queueArray &&
        state.queueArray.map((index, i) => {
          return (
            <div key={"queue_name_" + index} className="rounded bg-dark3 p-3 mb-2">
              <div className="d-flex mt-2">
                <div className="py-1 w-50">
                  <p className="b-block mb-0 d-flex">
                    Queue name{" "}
                    <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                    {state.inputValidationError &&
                    !state["queue_name_" + index] ? (
                      <span className="mb-0 text-red ml-2">required</span>
                    ) : null}
                  </p>
                  <Input
                    type="text"
                    placeholder="Enter queue name"
                    className={`inputTextbox bg-transparent border-lightGray  ${
                      props.pageMode !== "Create"
                        ? "disabled text-primary-color"
                        : "text-white"
                    }`}
                    // maxlength='128'
                    value={
                      state["queue_name_" + index]
                        ? state["queue_name_" + index]
                        : ""
                    }
                    onChange={(e) => {
                      if (props.pageMode === "Create") {
                        handleQueueInput(index, "queue_name", e.target.value);
                      }
                    }}
                  />
                </div>
              </div>

              <div className="d-flex mt-3">
                <div className="py-1 w-100">
                  <div className="w-33">
                    <p className="b-block mb-0 d-flex">
                      Scaledown idle time (minutes){" "}
                      <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                      {state.inputValidationError &&
                      !state["scaledown_idle_time_" + index] ? (
                        <span className="mb-0 text-red ml-2">required</span>
                      ) : null}
                    </p>
                    <Input
                      type="number"
                      placeholder="Enter time"
                      className={`inputTextbox bg-transparent border-lightGray text-white`}
                      // maxlength='128'
                      value={
                        state["scaledown_idle_time_" + index]
                          ? state["scaledown_idle_time_" + index]
                          : ""
                      }
                      onChange={(e) =>
                        handleQueueInput(
                          index,
                          "scaledown_idle_time",
                          onlyNumeric(e.target.value),
                          "integer"
                        )
                      }
                    />
                  </div>
                  {propsClusterFilters &&
                  propsClusterFilters.description &&
                  propsClusterFilters.description.cluster_configuration &&
                  propsClusterFilters.description.cluster_configuration
                    .scaledown_idle_time ? (
                    <p className="mt-1 mb-0 text-info">
                      {
                        propsClusterFilters.description.cluster_configuration
                          .scaledown_idle_time
                      }
                    </p>
                  ) : null}
                </div>
              </div>
              <div className="d-flex mt-3">
                <div className="py-1 w-33">
                  <div
                    onClick={(event) => {
                      if (!state["showQueueUpdateStartegy_" + index]) {
                        event.preventDefault();
                        handleChildClick(
                          event,
                          "child",
                          "singleDropDown",
                          "showQueueUpdateStartegy_" + index
                        );
                      }
                    }}
                  >
                    <p className="b-block mb-0 d-flex hiddenEllipses">
                      Queue update strategy{" "}
                      <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                    </p>
                    <p
                      className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${
                        state["update_strategy_" + index]
                          ? "text-white"
                          : "placeholder"
                      }`}
                      style={{ height: "38px" }}
                    >
                      {state["update_strategy_" + index]
                        ? state["update_strategy_" + index]
                        : "Select"}
                      <i className="fa fa-caret-down mt-1" />
                    </p>
                    {state["showQueueUpdateStartegy_" + index] ? (
                      <div className="position-relative">
                        <MultiSelectSection
                          // fields={["account_id", "account_name"]}
                          // className={}
                          removeTop={true}
                          options={
                            propsClusterFilters &&
                            propsClusterFilters.update_strategy
                              ? propsClusterFilters.update_strategy
                              : []
                          }
                          selectedValues={
                            state["update_strategy_" + index]
                              ? state["update_strategy_" + index]
                              : []
                          }
                          callbackMultiSelect={(value) => {
                            if (!value || typeof value === "string") {
                              setState((prevState) => ({
                                ...prevState,
                                ["update_strategy_" + index]: value,
                              }));
                              handleQueueInput(index, "update_strategy", value);
                            } else {
                              value.preventDefault();
                              handleChildClick(
                                value,
                                "search",
                                "singleDropDown",
                                ""
                              );
                            }
                          }}
                          singleSelection={true}
                          widthClass={"minWidth220"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="d-flex mt-2 mt-3">
                <div
                  className="py-1 w-50"
                  onClick={(event) => {
                    event.preventDefault();
                    handleChildClick(
                      event,
                      "child",
                      "muliselectDropDown",
                      "showSubnetDropdown"
                    );
                  }}
                >
                  <p className="b-block mb-0 d-flex">
                    Subnet ids{" "}
                    <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                    {state.inputValidationError &&
                    (!state["subnet_ids_" + index] ||
                      !state["subnet_ids_" + index].length) ? (
                      <span className="mb-0 text-red ml-2">required</span>
                    ) : null}
                  </p>
                  <p
                    className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${
                      state["subnet_ids_" + index] &&
                      state["subnet_ids_" + index].length
                        ? "text-white"
                        : "placeholder"
                    }`}
                    style={{ height: "38px" }}
                  >
                    {state["subnet_ids_" + index] &&
                    state["subnet_ids_" + index].length
                      ? state["subnet_ids_" + index].length + " Selected"
                      : "Select"}
                    <i className="fa fa-caret-down mt-1" />
                  </p>
                  {state.showSubnetDropdown ? (
                    <div className="position-relative">
                      <MultiSelectSection
                        // fields={["account_id", "account_name"]}
                        // className={'position-unset'}
                        removeTop={true}
                        options={
                          propsClusterFilters && propsClusterFilters.subnet_ids
                            ? propsClusterFilters.subnet_ids
                            : []
                        }
                        selectedValues={
                          state["subnet_ids_" + index]
                            ? state["subnet_ids_" + index]
                            : []
                        }
                        callbackMultiSelect={(value) => {
                          setState((prevState) => ({
                            ...prevState,
                            ["subnet_ids_" + index]: value,
                          }));
                          handleQueueInput(index, "subnet_ids", value);
                        }}
                        widthClass={"minWidth220"}
                      />
                    </div>
                  ) : null}
                  {state["subnet_ids_" + index] &&
                  state["subnet_ids_" + index].length
                    ? state["subnet_ids_" + index].map((row) => {
                        return (
                          <span
                            key={"sub_" + index + "_" + row}
                            className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2"
                          >
                            {row}
                            <i
                              className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                              onClick={() =>
                                removefromList(index, row, "subnet_ids")
                              }
                            ></i>
                          </span>
                        );
                      })
                    : null}
                </div>
              </div>
              <div className="d-flex mt-2 mt-3">
                <div
                  className="py-1 w-50"
                  onClick={(event) => {
                    event.preventDefault();
                    handleChildClick(
                      event,
                      "child",
                      "muliselectDropDown",
                      "showSecurityGraoupDropdown"
                    );
                  }}
                >
                  <p className="b-block mb-0">Additional security groups</p>
                  <p
                    className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${
                      state["security_group_ids_" + index] &&
                      state["security_group_ids_" + index].length
                        ? "text-white"
                        : "placeholder"
                    }`}
                    style={{ height: "38px" }}
                  >
                    {state["security_group_ids_" + index] &&
                    state["security_group_ids_" + index].length
                      ? state["security_group_ids_" + index].length +
                        " Selected"
                      : "Select"}
                    <i className="fa fa-caret-down mt-1" />
                  </p>
                  {state.showSecurityGraoupDropdown ? (
                    <div className="position-relative">
                      <MultiSelectSection
                        // fields={["account_id", "account_name"]}
                        // className={}
                        removeTop={true}
                        options={
                          propsClusterFilters &&
                          propsClusterFilters.security_group_ids
                            ? propsClusterFilters.security_group_ids
                            : []
                        }
                        selectedValues={
                          state["security_group_ids_" + index]
                            ? state["security_group_ids_" + index]
                            : []
                        }
                        callbackMultiSelect={(value) => {
                          setState((prevState) => ({
                            ...prevState,
                            ["security_group_ids_" + index]: value,
                          }));
                          handleQueueInput(index, "security_group_ids", value);
                        }}
                        widthClass={"minWidth220"}
                      />
                    </div>
                  ) : null}
                  {state["security_group_ids_" + index] &&
                  state["security_group_ids_" + index].length
                    ? state["security_group_ids_" + index].map((row) => {
                        return (
                          <span
                            key={"sec_" + index + "_" + row}
                            className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2"
                          >
                            {row}
                            <i
                              className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                              onClick={() =>
                                removefromList(index, row, "security_group_ids")
                              }
                            ></i>
                          </span>
                        );
                      })
                    : null}
                </div>
              </div>

              <p className="b-block text-white my-2">Compute resources</p>
              <div className="rounded bg-dark p-3 mb-3">
                {state["computeArray_" + index] &&
                  state["computeArray_" + index].map((cIndex, j) => {
                    return (
                      <div
                        key={"comp_" + index + "_" + cIndex}
                        className="rounded bg-dark2 p-3 mb-1"
                      >
                        {state["computeArray_" + index].map((row) => {
                          return (
                            <React.Fragment key={"comArr_" + index + "_" + row}>
                              {row !== cIndex &&
                              state[
                                "compute_resource_name_" + index + "_" + cIndex
                              ] &&
                              state[
                                "compute_resource_name_" + index + "_" + cIndex
                              ] ===
                                state[
                                  "compute_resource_name_" + index + "_" + row
                                ] ? (
                                <p className="mb-1 text-danger">
                                  Duplicate Name
                                </p>
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                        <div className="d-flex mt-2">
                          <div className="w-80">
                            <div className="d-flex">
                              <div className="py-1 w-30">
                                <p className="b-block mb-0 d-flex">
                                  Name{" "}
                                  <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                  {state.inputValidationError &&
                                  (!state[
                                    "compute_resource_name_" +
                                      index +
                                      "_" +
                                      cIndex
                                  ] ||
                                    !state[
                                      "compute_resource_name_" +
                                        index +
                                        "_" +
                                        cIndex
                                    ].length) ? (
                                    <span className="mb-0 text-red ml-2">
                                      required
                                    </span>
                                  ) : null}
                                </p>
                                <div className="d-flex">
                                  <Input
                                    type="text"
                                    id={
                                      "compute_resource_name_" +
                                      index +
                                      "_" +
                                      cIndex
                                    }
                                    placeholder="Enter compute resource name"
                                    className={`inputTextbox bg-transparent border-lightGray text-white w-100`}
                                    // maxlength='128'
                                    value={
                                      state[
                                        "compute_resource_name_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ]
                                        ? state[
                                            "compute_resource_name_" +
                                              index +
                                              "_" +
                                              cIndex
                                          ]
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleComputeResourcesChange(
                                        index,
                                        cIndex,
                                        "compute_resource_name",
                                        alphaNumeric(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div
                                className="py-1 w-25 pl-3"
                                onClick={(event) => {
                                  if (
                                    !state[
                                      "showInstanceTypes_" +
                                        index +
                                        "_" +
                                        cIndex
                                    ]
                                  ) {
                                    event.preventDefault();
                                    handleChildClick(
                                      event,
                                      "child",
                                      "singleDropDown",
                                      "showInstanceTypes_" +
                                        index +
                                        "_" +
                                        cIndex
                                    );
                                  }
                                }}
                              >
                                <p className="b-block mb-0 d-flex hiddenEllipses">
                                  Instance type{" "}
                                  <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                  {/* {state.inputValidationError && (!state['instance_type_'+index+'_'+cIndex] || !state['instance_type_'+index+'_'+cIndex].length) ?
                                                                <span className='mb-0 text-red ml-2'>required</span>
                                                            : null} */}
                                </p>
                                <p
                                  className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${
                                    state[
                                      "instance_type_" + index + "_" + cIndex
                                    ]
                                      ? "text-white"
                                      : "placeholder"
                                  }`}
                                  style={{ height: "38px" }}
                                >
                                  {state[
                                    "instance_type_" + index + "_" + cIndex
                                  ]
                                    ? state[
                                        "instance_type_" + index + "_" + cIndex
                                      ]
                                    : "Select"}
                                  <i className="fa fa-caret-down mt-1" />
                                </p>
                                {state[
                                  "showInstanceTypes_" + index + "_" + cIndex
                                ] ? (
                                  <div className="position-relative">
                                    <MultiSelectSection
                                      fields={[
                                        "instance_type",
                                        "instance_type",
                                      ]}
                                      removeTop={true}
                                      options={
                                        propsClusterFilters &&
                                        propsClusterFilters.instance_type
                                          ? propsClusterFilters.instance_type
                                          : []
                                      }
                                      selectedValues={
                                        state[
                                          "instance_type_" +
                                            index +
                                            "_" +
                                            cIndex
                                        ]
                                          ? state[
                                              "instance_type_" +
                                                index +
                                                "_" +
                                                cIndex
                                            ]
                                          : []
                                      }
                                      callbackMultiSelect={(value) => {
                                        if (
                                          !value ||
                                          typeof value === "string"
                                        ) {
                                          setState((prevState) => ({
                                            ...prevState,
                                            ["instance_type_" +
                                            index +
                                            "_" +
                                            cIndex]: value,
                                            ["instanceTypeOptions_" +
                                            index +
                                            "_" +
                                            cIndex]:
                                              propsClusterFilters.instance_type.filter(
                                                (e) => e.instance_type === value
                                              ).length
                                                ? propsClusterFilters.instance_type.filter(
                                                    (e) =>
                                                      e.instance_type === value
                                                  )[0]
                                                : {},
                                          }));
                                          handleComputeResourcesChange(
                                            index,
                                            cIndex,
                                            "instance_type",
                                            value
                                          );
                                          handleComputeResourcesChange(
                                            index,
                                            cIndex,
                                            "efa_enabled",
                                            false
                                          );
                                          handleComputeResourcesChange(
                                            index,
                                            cIndex,
                                            "placement_group_enabled",
                                            false
                                          );
                                        } else {
                                          value.preventDefault();
                                          handleChildClick(
                                            value,
                                            "search",
                                            "singleDropDown",
                                            ""
                                          );
                                        }
                                      }}
                                      singleSelection={true}
                                      widthClass={"minWidth220"}
                                    />
                                  </div>
                                ) : null}
                              </div>
                              <div className="py-1 w-20 pl-3">
                                <p className="b-block mb-0 hiddenEllipses">
                                  Min&nbsp;instance&nbsp;count
                                </p>
                                <div className="d-flex">
                                  <Input
                                    type="number "
                                    placeholder="Enter max instance count"
                                    className={`inputTextbox bg-transparent border-lightGray text-white`}
                                    // maxlength='128'
                                    value={
                                      state[
                                        "min_instance_count_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ]
                                        ? state[
                                            "min_instance_count_" +
                                              index +
                                              "_" +
                                              cIndex
                                          ]
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleComputeResourcesChange(
                                        index,
                                        cIndex,
                                        "min_instance_count",
                                        onlyNumeric(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="py-1 w-20 pl-3">
                                <p className="b-block mb-0 d-flex hiddenEllipses">
                                  Max&nbsp;instance&nbsp;count{" "}
                                  <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                  {state.inputValidationError &&
                                  !state[
                                    "max_instance_count_" + index + "_" + cIndex
                                  ] ? (
                                    <span className="mb-0 text-red ml-2">
                                      required
                                    </span>
                                  ) : null}
                                </p>
                                <div className="d-flex">
                                  <Input
                                    type="number"
                                    placeholder="Enter max instance count"
                                    className={`inputTextbox bg-transparent border-lightGray text-white`}
                                    // maxlength='128'
                                    value={
                                      state[
                                        "max_instance_count_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ]
                                        ? state[
                                            "max_instance_count_" +
                                              index +
                                              "_" +
                                              cIndex
                                          ]
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleComputeResourcesChange(
                                        index,
                                        cIndex,
                                        "max_instance_count",
                                        onlyNumeric(e.target.value)
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-2">
                              <div className="d-flex align-self-center mt-3">
                                <label className="mb-0">
                                  <input
                                    type="checkbox"
                                    className="inputdemo mt-1"
                                    checked={
                                      state[
                                        "efa_enabled_" + index + "_" + cIndex
                                      ]
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      state[
                                        "instanceTypeOptions_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ] &&
                                      state[
                                        "instanceTypeOptions_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ].efa_enabled === false
                                    }
                                    onChange={() => {
                                      let value =
                                        !state[
                                          "efa_enabled_" + index + "_" + cIndex
                                        ];
                                      if (
                                        state[
                                          "instanceTypeOptions_" +
                                            index +
                                            "_" +
                                            cIndex
                                        ] &&
                                        state[
                                          "instanceTypeOptions_" +
                                            index +
                                            "_" +
                                            cIndex
                                        ].efa_enabled === false
                                      ) {
                                        value = false;
                                      }
                                      handleComputeResourcesChange(
                                        index,
                                        cIndex,
                                        "efa_enabled",
                                        value
                                      );
                                    }}
                                  />
                                  <span className="slider round small pl-1"></span>
                                </label>
                                <span className="mb-0 text-lightGray f16">
                                  Use EFA
                                </span>
                              </div>
                              <div className="d-flex align-self-center mt-3">
                                <label className="mb-0">
                                  <input
                                    type="checkbox"
                                    className="inputdemo mt-1"
                                    checked={
                                      state[
                                        "placement_group_enabled_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ]
                                        ? true
                                        : false
                                    }
                                    disabled={
                                      state[
                                        "instanceTypeOptions_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ] &&
                                      state[
                                        "instanceTypeOptions_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ].placement_group_enabled === false
                                    }
                                    onChange={() => {
                                      let value =
                                        !state[
                                          "placement_group_enabled_" +
                                            index +
                                            "_" +
                                            cIndex
                                        ];
                                      if (
                                        state[
                                          "instanceTypeOptions_" +
                                            index +
                                            "_" +
                                            cIndex
                                        ] &&
                                        state[
                                          "instanceTypeOptions_" +
                                            index +
                                            "_" +
                                            cIndex
                                        ].placement_group_enabled === false
                                      ) {
                                        value = false;
                                      }
                                      handleComputeResourcesChange(
                                        index,
                                        cIndex,
                                        "placement_group_enabled",
                                        value
                                      );
                                    }}
                                  />
                                  <span className="slider round small pl-1"></span>
                                </label>
                                <span className="mb-0 text-lightGray f16">
                                  Use placement group
                                </span>
                              </div>
                              <div className="d-flex align-self-center mt-3">
                                <label className="mb-0">
                                  <input
                                    type="checkbox"
                                    className="inputdemo mt-1"
                                    checked={
                                      state[
                                        "disable_simultaneous_multithreading_" +
                                          index +
                                          "_" +
                                          cIndex
                                      ]
                                        ? true
                                        : false
                                    }
                                    onChange={(event) => {
                                      let value =
                                        !state[
                                          "disable_simultaneous_multithreading_" +
                                            index +
                                            "_" +
                                            cIndex
                                        ];
                                      handleComputeResourcesChange(
                                        index,
                                        cIndex,
                                        "disable_simultaneous_multithreading",
                                        value
                                      );
                                    }}
                                  />
                                  <span className="slider round small pl-1"></span>
                                </label>
                                <span className="mb-0 text-lightGray f16">
                                  Turn off multithreading
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="py-1 w-20 justify-content-end align-self-center">
                            <div className="d-flex justify-content-end mt-3">
                              {state["computeArray_" + index].length > 1 ? (
                                <span
                                  className={`cursorPointer text-red f16 align-self-center ml-3`}
                                  onClick={() =>
                                    removeCRSection(
                                      "computeArray",
                                      index,
                                      cIndex,
                                      j
                                    )
                                  }
                                >
                                  {" "}
                                  Remove
                                </span>
                              ) : null}
                              {state["computeArray_" + index].length - 1 ===
                              j ? (
                                <span
                                  className={`cursorPointer text-info f16 ml-3`}
                                  onClick={() =>
                                    addSection("computeArray_" + index)
                                  }
                                >
                                  Add
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <p className="text-white f16 mt-3">Scripts</p>
              <div className="rounded bg-dark2 p-3 mb-3">
                <div className={"d-flex"}>
                  <div className="py-1 w-33 align-self-center">
                    <div className="d-flex">
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          className="inputdemo mt-1"
                          checked={
                            state["showNodeScript_" + index] ? true : false
                          }
                          onChange={() => {
                            setState((prevState) => ({
                              ...prevState,
                              ["showNodeScript_" + index]:
                                !state["showNodeScript_" + index],
                            }));
                          }}
                        />
                      </label>
                      <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                        Run script on node configured
                      </span>
                    </div>
                  </div>
                </div>
                {state["showNodeScript_" + index] ? (
                  <div className={"d-flex my-3"}>
                    <div className="py-1 w-67">
                      <div className="d-flex justify-content-between">
                        {/* <label className='align-self-center m-0'>Node Configured Script</label> */}
                        {state.hasError && !state.on_node_configured_script ? (
                          <small className="text-issues">required</small>
                        ) : null}
                      </div>

                      <Input
                        type="text"
                        placeholder="Enter s3 file path"
                        className="inputTextbox border-lightGray bg-transparent text-white"
                        // maxlength='128'
                        value={
                          state["on_node_configured_script_" + index]
                            ? state["on_node_configured_script_" + index]
                            : ""
                        }
                        onChange={(e) => {
                          handleQueueInput(
                            index,
                            "on_node_configured_script",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              <p className="b-block text-white mt-3">Root volume</p>
              <div className="rounded bg-dark2 p-3 mb-3">
                <div className="d-flex mt-2">
                  <div className="py-1 w-20">
                    <div className="d-flex justify-content-between">
                      <label className="align-self-center m-0">
                        Volume Type
                      </label>
                      {state.hasError && state["volume_type_" + index] ? (
                        <small className="text-issues">required</small>
                      ) : null}
                    </div>
                    <div
                      className="border-lightGray bg-transparent rounded-5"
                      onClick={(event) => {
                        if (!state.showVollumeType) {
                          event.preventDefault();
                          handleChildClick(
                            event,
                            "child",
                            "singleDropDown",
                            "showVollumeType"
                          );
                        }
                      }}
                    >
                      <p
                        className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${
                          state["volume_type_" + index]
                            ? "text-white"
                            : "placeholder"
                        }`}
                      >
                        {state["volume_type_" + index]
                          ? state["volume_type_" + index]
                          : "Select"}
                        <i className="fa fa-caret-down mt-1" />
                      </p>
                      {state.showVollumeType ? (
                        <MultiSelectSection
                          options={
                            propsClusterFilters &&
                            propsClusterFilters.volume_type
                              ? propsClusterFilters.volume_type
                              : []
                          }
                          selectedValues={
                            state["volume_type_" + index]
                              ? state["volume_type_" + index]
                              : ""
                          }
                          callbackMultiSelect={(value) => {
                            if (!value || typeof value === "string") {
                              handleQueueInput(index, "volume_type", value);
                            } else {
                              value.preventDefault();
                              handleChildClick(
                                value,
                                "search",
                                "singleDropDown",
                                ""
                              );
                            }
                          }}
                          singleSelection={true}
                          hideSearch={false}
                          topClass={"auto"}
                          widthClass={"minWidth220"}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="py-1 w-20 pl-3">
                    <p className="b-block mb-0">Root volume size (GB)</p>
                    <Input
                      type="number"
                      placeholder="Enter volume size"
                      className={`inputTextbox bg-transparent border-lightGray text-white`}
                      // maxlength='128'
                      value={
                        state["volume_size_" + index]
                          ? state["volume_size_" + index]
                          : ""
                      }
                      onChange={(e) =>
                        handleQueueInput(
                          index,
                          "volume_size",
                          onlyNumeric(e.target.value),
                          "integer"
                        )
                      }
                    />
                  </div>
                  <div className="py-1 w-20 pl-3 mt-3 align-self-center">
                    <div className="d-flex align-self-center">
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          className="inputdemo mt-1"
                          checked={
                            state["volume_encrypted_" + index] ? true : false
                          }
                          onChange={() => {
                            let value = !state["volume_encrypted_" + index];
                            handleQueueInput(index, "volume_encrypted", value);
                          }}
                        />
                        <span className="slider round small pl-1"></span>
                      </label>
                      <span className="mb-0 text-lightGray f16">
                        Encrypt root volume
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <p className="b-block text-white my-2">S3 Access</p>
              <div className="rounded bg-dark px-2 py-2 mb-3">
                {state["s3AccessArray_" + index] &&
                  state["s3AccessArray_" + index].map((sIndex, x) => {
                    return (
                      <div
                        key={"s3_" + index + "_" + sIndex}
                        className="rounded bg-dark2 p-3 my-3"
                      >
                        <div className="d-flex mt-2">
                          <div className="py-1 w-20">
                            <p className="b-block mb-0">
                              Bucket name
                              {state.inputValidationError &&
                              !state["bucket_name_" + index + "_" + sIndex] ? (
                                <span className="mb-0 text-red ml-2">
                                  required
                                </span>
                              ) : null}
                            </p>
                            <Input
                              type="text"
                              placeholder="Enter bucket name"
                              className={`inputTextbox bg-transparent border-lightGray text-white`}
                              // maxlength='128'
                              value={
                                state["bucket_name_" + index + "_" + sIndex]
                                  ? state["bucket_name_" + index + "_" + sIndex]
                                  : ""
                              }
                              onChange={(e) => {
                                handleS3AccessInput(
                                  "bucket_name",
                                  index,
                                  sIndex,
                                  e.target.value,
                                  "bucket_name_" + index + "_" + sIndex
                                );
                              }}
                            />
                          </div>
                          <div className="py-1 w-20 pl-3 align-self-center mt-3">
                            <label className="mb-0">
                              <input
                                type="checkbox"
                                className="inputdemo mt-1"
                                checked={
                                  state[
                                    "enable_write_access_" +
                                      index +
                                      "_" +
                                      sIndex
                                  ]
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  let value =
                                    !state[
                                      "enable_write_access_" +
                                        index +
                                        "_" +
                                        sIndex
                                    ];
                                  // setState(prevState => ({ ...prevState, ['enable_write_access_'+index+'_'+sIndex]: !state['enable_write_access_'+index+'_'+sIndex]}))
                                  handleS3AccessInput(
                                    "enable_write_access",
                                    index,
                                    sIndex,
                                    value,
                                    "enable_write_access_" +
                                      index +
                                      "_" +
                                      sIndex
                                  );
                                }}
                              />
                              <span className="slider round small pl-1"></span>
                            </label>
                            <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                              Enable write access
                            </span>
                          </div>
                          <div className="py-1 w-20 justify-content-end align-self-center">
                            <div className="d-flex justify-content-end mt-3">
                              {state["s3AccessArray_" + index].length > 1 ? (
                                <span
                                  className={`cursorPointer text-red f16 align-self-center ml-3`}
                                  onClick={() =>
                                    removeCRSection(
                                      "s3AccessArray",
                                      index,
                                      sIndex,
                                      x
                                    )
                                  }
                                >
                                  {" "}
                                  Remove
                                </span>
                              ) : // <span className={`cursorPointer text-red f16 ml-3`} onClick={() => removeCRSection('s3AccessArray', index, cIndex, j)}>Remove</span>
                              null}
                              {state["s3AccessArray_" + index].length - 1 ===
                              x ? (
                                <span
                                  className={`cursorPointer text-info f16 ml-3`}
                                  onClick={() =>
                                    addSection("s3AccessArray_" + index)
                                  }
                                >
                                  Add
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="mt-2 border-darkGray-top pt-2">
                {/* <div className='py-1 w-50'>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showPolicies")
                                }}>
                                    <p className='b-block mb-0 d-flex'>
                                        IAM policies <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                        {state.inputValidationError && (!state['input_policy_'+index] || !state['input_policy_'+index].length) ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </p>
                                    <p className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${state['input_policy_'+index] && state['input_policy_'+index].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>
                                        {state['input_policy_'+index] && state['input_policy_'+index].length ? state['input_policy_'+index].length+' Selected' : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showPolicies ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                // fields={["account_id", "account_name"]}
                                                // className={'position-unset'}
                                                removeTop={true}
                                                options={propsClusterFilters && propsClusterFilters.policies ? propsClusterFilters.policies : []}
                                                selectedValues={state['input_policy_'+index] ? state['input_policy_'+index] : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, ['input_policy_'+index]: value }))
                                                    handleQueueInput(index, 'input_policy', value)
                                                }}
                                                widthClass={'minWidth220'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            {state['policies_'+index] && Array.isArray(state['policies_'+index]) && state['policies_'+index].length ? 
                                state['policies_'+index].map(row => {
                                    return(
                                        <span key={'pol_'+index+'_'+row} className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>{row}
                                            {props.pageMode !== 'View' ?
                                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removefromList(index, row, 'policies') } ></i>
                                            : null}
                                        </span>
                                    )
                                })
                            : null} */}
                <div className="py-1 w-67">
                  <div className="d-flex justify-content-between">
                    <label className="align-self-center m-0">
                      IAM Policies
                    </label>
                    {state.hasError && !state.policy ? (
                      <small className="text-issues">required</small>
                    ) : null}
                  </div>
                  <div className="d-flex">
                    <Input
                      type="text"
                      placeholder="Enter policy"
                      className="inputTextbox border-lightGray bg-transparent text-white"
                      // maxlength='128'
                      value={
                        state["policy_" + index] ? state["policy_" + index] : ""
                      }
                      onChange={(e) => {
                        setState((prevState) => ({
                          ...prevState,
                          ["policy_" + index]: e.target.value,
                        }));
                      }}
                    />
                    <span
                      className={`far fa-plus cursor-pointer f18 mb-1 align-self-center ml-3`}
                      onClick={() => addPolicies(index)}
                    ></span>
                  </div>
                </div>

                {state["policies_" + index] &&
                Array.isArray(state["policies_" + index]) &&
                state["policies_" + index].length
                  ? state["policies_" + index].map((row) => {
                      return (
                        <span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">
                          {row}
                          <i
                            className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                            onClick={() => removePolicies(index, row)}
                          ></i>
                        </span>
                      );
                    })
                  : null}
              </div>

              <div className="d-flex mt-2 pt-2 border-darkGray-top">
                <div className="py-3 w-100">
                  <p className="b-block mb-0">Tags</p>
                  <div className="d-flex mb-1">
                    <Input
                      type="text"
                      placeholder="Enter Key"
                      className={`inputTextbox bg-transparent border-lightGray text-white w-30`}
                      // maxlength='128'
                      value={
                        state["tag_key_" + index]
                          ? state["tag_key_" + index]
                          : ""
                      }
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          ["tag_key_" + index]: e.target.value,
                        }))
                      }
                    />
                    <Input
                      type="text"
                      placeholder="Enter value"
                      className={`inputTextbox bg-transparent border-lightGray text-white w-40 ml-3`}
                      // maxlength='128'
                      value={
                        state["tag_value_" + index]
                          ? state["tag_value_" + index]
                          : ""
                      }
                      onChange={(e) =>
                        setState((prevState) => ({
                          ...prevState,
                          ["tag_value_" + index]: e.target.value,
                        }))
                      }
                    />
                    <span
                      className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center ml-3`}
                      onClick={() => addNewTagValue(index)}
                    ></span>
                  </div>
                  {state["selected_tags_" + index] &&
                  state["selected_tags_" + index].length ? (
                    <div className="d-flex flex-wrap">
                      {state["selected_tags_" + index]
                        .filter((e) => e.value !== "All")
                        .map((tag, tIndex) => {
                          return (
                            <span
                              key={"tag_" + index + "_" + tIndex}
                              className="d-flex badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2 breakWhiteSpace"
                              id={"tag_" + tIndex}
                            >
                              <div
                                className="hiddenEllipses"
                                style={{ maxWidth: "250px" }}
                              >
                                {tag.key + " : " + tag.value}
                              </div>
                              <i
                                className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                                onClick={() => removeTagSection(tag, index)}
                              ></i>
                            </span>
                          );
                        })}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="d-flex justify-content-end">
                {/* far fa-trashd */}
                {state.queueArray.length > 1 ? (
                  <span
                    className={`cursorPointer text-red f16 align-self-center ml-4`}
                    onClick={() => removeSection("queueArray", index, i)}
                  >
                    {" "}
                    Remove
                  </span>
                ) : null}
                {state.queueArray.length - 1 === i ? (
                  <span
                    className={`cursorPointer text-info f16 align-self-center ml-4`}
                    onClick={() => addSectionQuerySection("queueArray")}
                  >
                    {" "}
                    Add
                  </span>
                ) : null}
                {/* far fa-plus */}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default QuesectionSection;
