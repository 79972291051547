/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ResourcesAdvancedSearch.js
 * @author Prakash // on 30/01/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { listAllAccounts, listAllRegions, listAllServices, saveSearchMetaResults, getSearchMetaResults } from '../../../../actions/commonAction'
import { connect } from 'react-redux'

import { CUSTOM_CURRENT_DURATION_FILTER_OPTIONS } from '../../../../utils/constants'
import { Store as CommonNotification } from 'react-notifications-component';
// import { getDayFromSelectedDuration } from '../../../../utils/utility'

import _ from 'lodash'
import Search from '../../../common/SearchComponent'
import SingleInputSection from './SingleInputSection'
import DurationFilterSection from './DurationFilterSection'
import IpAddressess from './IpAddressess'
import ResourcesInputSection from './ResourcesInputSection'
import ServicesInputSection from './ServicesInputSection'
// import AccessedBySection from './AccessedBySection'
import TagsSection from './TagsSection'
import InstanceTypeInputSection from './InstanceTypeInputSection'
import { getAccountNameFromId, getRegionName, momentConvertionUtcToLocalTime } from '../../../../utils/utility'
import Select from 'react-select'

import CustomTable from '../../../designComponents/Table/customTable'

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import ResizeableDarkThemeTable from '../../../designComponents/Table/ResizeableDarkThemeTable'

const defaultDropdown = [ 'Accessed By', 'Account', 'Category', 'Duration', 'Events', "Instance Type", 'IP Address', 'Networking', 'Region', "Tags", "Services"]; //, 'Resources' "Status", 

class ResourcesAdvancedSearch extends Component {
	constructor(props) {
		super(props)
		this.advanceSearchRef = React.createRef()
		this.advanceMultiSearchRef = React.createRef()
		
		this.state = {
			mainDropdown: defaultDropdown,
			searchText: '',
			isLoading: false,
			filteredServiceResourceArray: [],
		}
	}
	
	filterOnchange = () => {
		let filterData = _.orderBy(defaultDropdown, ['Asc'])
		if(this.state.searchText !== '') {
			filterData = filterData.filter(
				filter => 
				filter.toLocaleLowerCase().includes(this.state.searchText) ||
				filter.includes(this.state.searchText)
			)
		}
		this.setState({
			mainDropdown: filterData, 
			// showMainDropdownOption: this.state.searchText === '' ? false : true 
		})	
	}

	onSelectOptions = (value) => {
		this.setState({ selectedOption: value, showMainDropdownOption: false, searchText: "", mainDropdown: defaultDropdown },
			() => this.defineChildFilterVariables()
		)
	}

	defineChildFilterVariables = () => {
		let selectedPage = ""
		let inputComponent = []
		if(this.state.selectedOption === "Account") {
			let options = []
			this.props.accounts.forEach(row => {
				let dataRow = {
					label: row.account_name,
					value: row.account_id
				}
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				value_list: [],
				options,
				field: "account"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "Status") {
			let options = [
				{label: "Start", value: "start"},
				{label: "End", value: "end"},
				{label: "Running", value: "running"}
			]
			let inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				value_list: [],
				options,
				field: "status"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "Region") {
			let options = []
			this.props.regions.forEach(row => {
				let dataRow = {
					label: row.name,
					value: row.region
				}
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				value_list: [],
				options,
				field: "region"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "Category") {
			let options = []

			// let dataRow = {
			// 	label: "All",
			// 	value: "All"
			// }
			// options.push(dataRow)

			this.props.categoryCounts.forEach(row => {
				let dataRow = {}
				dataRow.label = row.category
				dataRow.value = row.category
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "multiselect",
				value_list: [],
				options,
				field: "category"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "Duration") {
			let options = []
			CUSTOM_CURRENT_DURATION_FILTER_OPTIONS.forEach(row => {
				let dataRow = {}
				dataRow.label = row.option
				dataRow.value = row.value
				options.push(dataRow)
			})
			let inputRow = {
				label: this.state.selectedOption,
				component: "Custom_Duration",
				value: "",
				options,
				field: "duration"
			}
			inputComponent.push(inputRow)
		} else if(this.state.selectedOption === "IP Address") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "ipv4, ipv6 etc...",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(this.state.selectedOption === "Events") { //EventSection
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "enter event name",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(this.state.selectedOption === "Networking") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "enter vpc id",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(this.state.selectedOption === "Resources") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: "",
				component: "select",
				options,
				field: "type",
				type: "value",
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options: this.state.serviceResourceType,
				field: "resources",
				type: "value",
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "enter asset name",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "Resources"
		} else if(this.state.selectedOption === "Services") {
			// let options = [
			// 	{label: "Include", value: "include"},
			// 	{label: "Exclude", value: "exclude"}
			// ]

			// let inputRow = {
			// 	label: "",
			// 	component: "select",
			// 	options,
			// 	field: "type",
			// 	type: "value",
			// 	default: "include"
			// }

			// inputComponent.push(inputRow)

			// inputRow = {
			// 	label: this.state.selectedOption,
			// 	component: "multiselect",
			// 	options: this.state.serviceResourceType,
			// 	field: "services",
			// 	type: "value_list",
			// }

			// inputComponent.push(inputRow)

			selectedPage = "Services"
		}else if(this.state.selectedOption === "Accessed By") {
			let inputRow = {
				label: "Enter user name",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		} else if(this.state.selectedOption === "Tags") {
			selectedPage = "Tags"
		} else if(this.state.selectedOption === "Instance Type") {
			let options = [
				{label: "Include", value: "include"},
				{label: "Exclude", value: "exclude"}
			]

			let inputRow = {
				label: this.state.selectedOption,
				component: "select",
				options,
				field: "type",
				type: "value",
				step: 1,
				default: "include"
			}

			inputComponent.push(inputRow)

			inputRow = {
				label: "mx-large, t3-small etc...",
				component: "multipleAddTextBox",
				field: "ids",
				type: "value_list",
				step: 1
			}

			inputComponent.push(inputRow)

			selectedPage = "selectTextList"
		}
		else if(this.state.selectedOption === "Instance Type1") {
			selectedPage = "InstanceType"
		}
		// else if(this.state.selectedOption === "Accessed By") {
		// 	selectedPage = "Accessed By"
		// }

		
		this.setState({ inputComponent, selectedPage })
	}

	filterMultiselectiOptions = (inputs) => {
		let filterData = inputs.options
		if(this.state.multiSelectSearchText !== '') {
			filterData = filterData.filter(filter => filter.includes(this.state.multiSelectSearchText))
		}

		this.setState({
			["multiselect_"+inputs.field]: filterData, 
			// showMainDropdownOption: this.state.searchText === '' ? false : true 
		})

	}

	updateMultiSelectedOptions = (row, inputs, mindex) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []
		let selectedMultiOptions = this.state.selectedMultiOptions ? this.state.selectedMultiOptions : []

		let valueLabel = row
		if(inputs.label === "account_id") {
			valueLabel = getAccountNameFromId(row, this.props.accounts)
		} else if(inputs.label === "region") {
			valueLabel = getRegionName(row, this.props.regions)
		}
		if(this.state["checked_"+inputs.field+"_"+mindex]) {
			let dataRow = {
				label: inputs.label,
				value: row,
				userView: inputs.label+" "+ valueLabel
			}
			data.push(dataRow)
		} else {
			let otherFilters = data.filter(e => e.label !== inputs.label)
			data = data.filter(e => e.label === inputs.label && e.value !== row)

			data = [...otherFilters, ...data]
		}

		this.setState({ selectedFilters: data, selectedMultiOptions, selectedOption: "" })
	}

	updateSelectedFilter = (row, inputs, options, mindex) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []

		let dataRow = {
			label: inputs.label,
			value: row,
			userView: inputs.label+" "+row
		}
		data.push(dataRow)

		this.setState({ selectedFilters: data, selectedOption: ""  })
	}
	
	selectedDurationFunction = (row) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []
		if(row.selectedDuration === "custom") {
			let dataRow = {}
			dataRow.label = 'Duration'
			dataRow.start_time = row.start_time
			dataRow.end_time = row.end_time
			dataRow.userView = 'Duration from '+ row.from+' to '+row.to
			data.push(dataRow)
		} else {
			let dataRow = {}
			dataRow.label = 'Duration'
			dataRow.value = row.selectedDuration
			dataRow.userView = 'Duration '+row.selectedDuration
			data.push(dataRow)
		}

		this.setState({ selectedFilters: data, inputComponent: [], selectedOption: ""  })
	}

	selectedTextListDetails = (selectedOptions, result) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []

		
		let filteredData = data 
		// if(selectedOptions === "Services") {
			filteredData = data.filter(e => e.label !== selectedOptions)
		// }
		
		if(result && result.length) {
			data = [...filteredData, ...result]
		}

		this.setState({ selectedFilters: data, selectedOption: "", selectedPage: "" })
	}

	selectedServices = (selectedOptions, result) => {
		let data = this.state.selectedFilters ? this.state.selectedFilters : []

		
		let filteredData = data 
		filteredData = data.filter(e => e.label !== selectedOptions)
		
		if(result && result.length) {
			data = [...filteredData, ...result]
		}

		this.setState({ selectedFilters: data })
	}

	removeFilterData = (row) => {
		let selectedFilters = this.state.selectedFilters.filter(e => e.label !== row.label && e.value !== row.value)
		this.setState({ selectedFilters })
	}
	
	handleClickOutside = (event) => {
		if (this.advanceSearchRef && this.advanceSearchRef.current && !this.advanceSearchRef.current.contains(event.target)) {
			this.setState({ showMainDropdownOption: false })
		}
		if (this.advanceMultiSearchRef && this.advanceMultiSearchRef.current && !this.advanceMultiSearchRef.current.contains(event.target)) {
			this.setState({ inputComponent: [] })
		}
    }

	onSearch = () => {
		if(this.state.selectedFilters && this.state.selectedFilters.length) {
			let params = {}
			params.provider = this.state.selectedProvider.toLowerCase()
			if(this.state.selectedFilters.filter(e => e.label === "Account").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Account").forEach(row => {
					data.push(row.value)
				})
				params.account_id = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Region").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Region").forEach(row => {
					data.push(row.value)
				})
				params.region = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Category").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Category").forEach(row => {
					data.push(row.value)
				})
				params.category = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Duration").length) {
				this.state.selectedFilters.filter(e => e.label === "Duration").forEach(row => {
					if(row.value) {
						params.duration = row.value
					} else {
						params.start_time = row.start_time
						params.end_time = row.end_time
					}
				})
			}
			if(this.state.selectedFilters.filter(e => e.label === "Events").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Events"), 'type')
				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.event_name = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "IP Address").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "IP Address"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.ip = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Networking").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Networking"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				params.vpc_id = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Resources").length) {
				let groupType = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Resources"), 'type')
				let dataRow = {}
				Object.entries(groupType).forEach(([key, gValue]) => {
					
					let gData = []
					let groupResources = _.groupBy(gValue, 'resources')
					Object.entries(groupResources).forEach(([rKey, rValue]) => {
						let rData = []
						rValue.forEach(item => {
							rData.push(item.value)
						})
						let groupRow = {}
						groupRow[rKey] = rData

						gData.push(groupRow)
					})
					
					dataRow[key] = gData
				})
				params.resources = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Services").length) {
				let groupData = this.state.selectedFilters.filter(e => e.label === "Services")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				
				params.service_name = gData
			}
			if(this.state.selectedFilters.filter(e => e.label === "Tags").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Tags").forEach(row => {
					let dataRow = {}
					dataRow.label = row.key
					dataRow.value = row.value
					data.push(dataRow)
				})				
				params.tags = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Instance Type").length) {

				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Instance Type"), 'service')
				let rData = []
				Object.entries(groupData).forEach(([key, gValue]) => {
					let value = []
					gValue.forEach(row => {
						value.push(row.value)
					})
					let rRow = {}
					rRow.resource_type = key
					rRow.instance_type = value

					rData.push(rRow)
				})
				
				params.instance_type = rData
			}
			if(this.state.selectedFilters.filter(e => e.label === "Accessed By").length) {
				let groupData = this.state.selectedFilters.filter(e => e.label === "Accessed By")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				params.users = gData
			}

			this.props.getFilteredList(params, "Advanced List")

		}
	}

	onReset = () => {
		this.setState({ selectedFilters: [] })
	}

	saveFilterItem = () => {
        this.setState({ showSaveLoading: true, responseMessage: ''})
        let hasError = false
        if(this.state.searchName === '') {
			hasError = true
        }

		let params = {}
		params.provider = this.state.selectedProvider.toLowerCase()
		params.title = this.state.searchName
		let parameters = {}
		if(this.state.selectedFilters && this.state.selectedFilters.length) {
			parameters.provider = this.state.selectedProvider.toLowerCase()
			if(this.state.selectedFilters.filter(e => e.label === "Account").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Account").forEach(row => {
					data.push(row.value)
				})
				parameters.account_id = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Region").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Region").forEach(row => {
					data.push(row.value)
				})
				parameters.region = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Category").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Category").forEach(row => {
					data.push(row.value)
				})
				parameters.category = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Duration").length) {
				this.state.selectedFilters.filter(e => e.label === "Duration").forEach(row => {
					if(row.value) {
						parameters.duration = row.value
					} else {
						parameters.start_time = row.start_time
						parameters.end_time = row.end_time
					}
				})
			}
			if(this.state.selectedFilters.filter(e => e.label === "Events").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Events"), 'type')
				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				parameters.event_name = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "IP Address").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "IP Address"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				parameters.ip = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Networking").length) {
				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Networking"), 'type')

				let dataRow = {}
				Object.entries(groupData).forEach(([key, gValue]) => {
					let gData = []
					gValue.forEach(item => {
						gData.push(item.value)
					})
					dataRow[key] = gData
				})
				
				parameters.vpc_id = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Resources").length) {
				let groupType = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Resources"), 'type')
				let dataRow = {}
				Object.entries(groupType).forEach(([key, gValue]) => {
					
					let gData = []
					let groupResources = _.groupBy(gValue, 'resources')
					Object.entries(groupResources).forEach(([rKey, rValue]) => {
						let rData = []
						rValue.forEach(item => {
							rData.push(item.value)
						})
						let groupRow = {}
						groupRow[rKey] = rData

						gData.push(groupRow)
					})
					
					dataRow[key] = gData
				})
				parameters.resources = dataRow
			}
			if(this.state.selectedFilters.filter(e => e.label === "Services").length) {
				let groupData = this.state.selectedFilters.filter(e => e.label === "Services")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				
				parameters.service_name = gData
			}
			if(this.state.selectedFilters.filter(e => e.label === "Tags").length) {
				let data = []
				this.state.selectedFilters.filter(e => e.label === "Tags").forEach(row => {
					let dataRow = {}
					dataRow.label = row.key
					dataRow.value = row.value
					data.push(dataRow)
				})				
				parameters.tags = data
			}
			if(this.state.selectedFilters.filter(e => e.label === "Instance Type").length) {

				let groupData = _.groupBy(this.state.selectedFilters.filter(e => e.label === "Instance Type"), 'service')
				let rData = []
				Object.entries(groupData).forEach(([key, gValue]) => {
					let value = []
					gValue.forEach(row => {
						value.push(row.value)
					})
					let rRow = {}
					rRow.resource_type = key
					rRow.instance_type = value

					rData.push(rRow)
				})
				
				parameters.instance_type = rData
			}
			if(this.state.selectedFilters.filter(e => e.label === "Accessed By").length) {
				let groupData = this.state.selectedFilters.filter(e => e.label === "Accessed By")
				let gData = []
				groupData.forEach(row => {
					gData.push(row.value)
				})
				parameters.users = gData
			}
		}
		params.parameters = parameters
		if(!hasError) {
			this.props.saveSearchMetaResults(params, (promise, response) => {
				if(promise) {
					this.setState({
						showSaveOption: false,
						showSaveLoading: false,
					})

					let messageType = 'danger'
					let message = response && response.message ? response.message : ""

					if(response && response.status) {
						messageType = 'success'
						this.setState({ selectedFilters: [], searchName: '', })
					}
		
					// setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
	
					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				}
			})
        }

		this.setState({ hasError })
    }

	getFilters = () => {
		let providerPramas = {}
        if (this.state.selectedProvider !== '' && this.state.selectedProvider !== 'All') {
        	providerPramas.provider = this.state.selectedProvider.toLowerCase()
        }
        
        this.props.listAllAccounts(providerPramas, (promise, accounts) => {
        	let allAccounts = []
		    if (promise) {
				accounts.length && accounts.forEach((item, i) => {
					if(i === 0) {
						let accRow = {}
						accRow.account_id = "All"
						accRow.account_name = "All"
						accRow.date = null
						accRow.disabled = false
						accRow.provider = "All"
						allAccounts.push(accRow);
					}

					allAccounts.push(item);
				})
				if(this.state.selectedProvider) {
					this.setState({ accounts: accounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase()), allAccounts: allAccounts.filter(e => e.provider === this.state.selectedProvider.toLowerCase()) })
				} else {
					this.setState({ accounts, allAccounts })
				}
			}
		})
		this.props.listAllRegions(providerPramas, (promise, regions) => {
			let providerRegions = regions[0] && regions[0].provider_regions
			if(providerRegions) {
				let provider_regions_row = {}
				provider_regions_row.availability_zones = []
				provider_regions_row.city = "All"
				provider_regions_row.country = "All"
				provider_regions_row.geography = "All"
				provider_regions_row.name = "All"
				provider_regions_row.region = "All"
				provider_regions_row.region_id = "All"
				provider_regions_row.state = "All"
				providerRegions.unshift(provider_regions_row);
			}
			if (promise) {
				this.setState({ 
					regions: providerRegions, 
					tableRowRegions: providerRegions 
				})
			} else {
				this.setState({ regions: [], tableRowRegions: [] })
			}
		})
		this.props.listAllServices(providerPramas, (promise, services) => {
			if (promise) {
				let serviceResourceArray = []
				let serviceArray = []
				services && services.length &&
				_.chain(services)
				.groupBy('resource_type')
				.map((value, key) => ({ label: key, sub: value }))
				.value()
				.forEach((service, i) => {
					if(i === 0) {
						let dataRow = {}						
						dataRow.category = 'All'
						dataRow.default_region = 'All'
						dataRow.provider_name = 'All'
						dataRow.resource_type = 'All'
						dataRow.service_id = 'All'
						dataRow.service_name = 'All'
						serviceArray.push(dataRow)

						let serRow = {}
						serRow.label = 'All'
						serRow.value = 'All'
						serRow.expanded = true
						let serArray = []
						serRow.children = serArray
						serviceResourceArray.push(serRow)
					}
					
					let serRow = {}
					serRow.label = service.label
					serRow.value = service.label
					serRow.expanded = true
					let serArray = []
					//serviceResourceArray.push(service)
					service.sub.forEach((item, i) => {
						let serItem = {}
						serItem.label = item.service_name
						serItem.value = item.service_name
						serItem.expanded = true
						serArray.push(serItem)
						serviceArray.push(item)
					})
					serRow.children = serArray
					serviceResourceArray.push(serRow)
				})
				this.setState({ services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray  })
				// this.setState({ services, serviceResourceType: serviceResourceArray, definedService: serviceArray  })
			} else {
				this.setState({ services: [] })
			}
		})

		let saveParams = {}
		saveParams.provider = this.state.selectedProvider.toLowerCase()

		this.props.getSearchMetaResults(providerPramas, (promise, response) => {
			if (promise) {
				this.setState({ 
					savedFilters: response,
				})
			} else {
				this.setState({ savedFilters: [] })
			}
		})
	}

	onChangeServiceDropdown  = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					this.state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode.label === 'All' || !selectedNodes.length) {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			this.state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		}	

		let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		this.setState({ filteredServiceResourceArray, selectedService, selectedServiceContent },
			() => {
				let array = []
				this.state.selectedService.forEach(row => {
					let dataRow = {
						label: "Services",
						value: row,
						userView: "Services : "+row
					}
					array.push(dataRow)
				})
				this.selectedServices("Services", array)
			}
		)
	}

	onClickSavedItem = (row) => {
		// let selectedFilters = row.parameters
		let selectedFilters = []

		let selectedProvider = row.provider
		if(row.parameters) {
			if(row.parameters.hasOwnProperty("account_id")) {
				row.parameters["account_id"].forEach(item => {
					let dataRow = {
						label: "Account",
						value: item,
						userView: "Account : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(row.parameters.hasOwnProperty("region")) {
				row.parameters["region"].forEach(item => {
					let dataRow = {
						label: "Region",
						value: item,
						userView: "Region : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(row.parameters.hasOwnProperty("category")) {
				row.parameters["category"].forEach(item => {
					let dataRow = {
						label: "Category",
						value: item,
						userView: "Category : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(row.parameters.hasOwnProperty("service_name")) {
				row.parameters["service_name"].forEach(item => {
					let dataRow = {
						label: "Services",
						value: item,
						userView: "Services : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(row.parameters.hasOwnProperty("users")) {
				row.parameters["users"].forEach(item => {
					let dataRow = {
						label: "Accessed By",
						value: item,
						userView: "Accessed By : "+ item
					}
					selectedFilters.push(dataRow)
				})
			}
			if(row.parameters.hasOwnProperty("event_name")) {
				Object.entries(row.parameters.event_name).forEach(([key, value]) => {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = "Events"
						dataRow.type = key
						dataRow.value = item
						dataRow.userView = "Events: "+key+" "+item
						selectedFilters.push(dataRow)	
					})
				})
			}
			if(row.parameters.hasOwnProperty("vpc_id")) {
				Object.entries(row.parameters.vpc_id).forEach(([key, value]) => {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = "Networking"
						dataRow.type = key
						dataRow.value = item
						dataRow.userView = "Networking: "+key+" "+item
						selectedFilters.push(dataRow)	
					})
				})
			}
			if(row.parameters.hasOwnProperty("ip")) {
				Object.entries(row.parameters.ip).forEach(([key, value]) => {
					value.forEach(item => {
						let dataRow = {}
						dataRow.label = "IP Address"
						dataRow.type = key
						dataRow.value = item
						dataRow.userView = "IP Address: "+key+" "+item
						selectedFilters.push(dataRow)	
					})
				})
			}
			if(row.parameters.hasOwnProperty("instance_type")) {
				row.parameters.instance_type.forEach(item => {
					item.instance_type.forEach(ins => {
						let dataRow = {}
						dataRow.label = "Instance Type"
						dataRow.service = item.resource_type
						dataRow.value = ins
						dataRow.userView = "Instance Type : " +item.resource_type+" : "+ins
						selectedFilters.push(dataRow)
					})
				})
			}			
			if(row.parameters.hasOwnProperty("tags")) {
				row.parameters.tags.forEach(item => {
					let dataRow = {}
					dataRow.label = "Tags"
					dataRow.key = item.label
					dataRow.value = item.value
					dataRow.userView = "Tags : " +item.label+" : "+item.value
					selectedFilters.push(dataRow)
				})
			}
		}

		this.setState({ 
			selectedProvider,
			selectedFilters
		},
			() => this.getFilters()
		)
	}

	changeDefaultDropDown = () => {
		let mainDropdown = defaultDropdown
		if(this.state.selectedProvider.toLowerCase() === "gcp") {
			mainDropdown = defaultDropdown.filter(e => e !== "Instance Type")
		}

		this.setState({ mainDropdown })
	}

	render() {
		return (
			<div className='advanced-search' onClick={ (event) => { this.handleClickOutside(event) } }>
				<div className="search-content bg-muted w-80 overflowYAuto">
					<div className="header-search bg-black bd-highlight d-flex justify-content-between p-2">
						<div className="flex-fill bd-highlight text-white">
							<h5>Advanced Search</h5>
							<p className='m-0'>
								Search Cloud resources database by using advanced and custom queries
							</p>
						</div>
						<div className='text-right flex-fill bd-highlight cursor-pointer'>
							<i className='far fa-times fa-lg text-white' onClick={() => this.props.onCloseModal()}/>
						</div>
					</div>
					<div className='container-fluid py-3'>
						<div className='d-flex'>
						<p className='mb-0 align-self-center mr-2 text-white'>Provider</p>
					    <div className='reactSelectDropTransparent w-10'>
						    <Select
                				placeholder={'All'}
                				// menuIsOpen={true}	
                				isSearchable={false}
                				className='f13 p-0'
                				value={({
                					value: this.state.selectedProvider,
                					label: this.state.selectedProvider ? this.state.selectedProvider.toUpperCase() : <span className='placeholder'>Select</span>
                				})}
                				options={this.props.providers && this.props.providers.length ?
                					this.props.providers.map(item => ({
                					    value: item.provider_name,
                					    label: item.provider_name,	
                				    }))
                			    : []
							    }
                				onChange={event => {
                					this.setState({ selectedProvider: event.value, selectedFilters: [], selectedPage: "", selectedOption: "" },
                						() => {
											this.getFilters()
											this.changeDefaultDropDown()
										}
                					)
                				}}
                			/>
						</div>

						</div>
						{this.state.selectedProvider ? 
						<React.Fragment>
						<div className="d-flex justify-content-between">
							<p className='font-weight-bold m-0 f15'>Filters</p>
							<p className='text-info m-0 f13 text-right cursorPointer w-30' onClick={() => this.setState({ showSavedFilters: this.state.showSavedFilters ? false : true }) }>{this.state.showSavedFilters ? 'Hide Saved Filters' : 'Show Saved Filters' }</p>
						</div>
						{this.state.showSavedFilters ?
							<div className='mt-2'>
								<p className='text-white'>Saved Filters</p>
								{/* <div className='d-flex w-100'>
									<div className='w-100'>
										<div className='d-flex bg-mediumDarkGray'>
											<div className='w-50 py-2 justify-content-start pl-3 d-flex align-self-center text-white'>Name</div>
											<div className='w-15 py-2 justify-content-start d-flex align-self-center text-white'>Created By</div>
											<div className='w-15 py-2 justify-content-start d-flex align-self-center text-white'>Date</div>
											<div className='w-20 py-2 justify-content-start d-flex align-self-center text-white'>&nbsp;</div>
										</div>
										<div className='overflowYAuto vh100'>
											{this.state.savedFilters && this.state.savedFilters.length ?
												this.state.savedFilters.map(row => {
													return(
													<div className='d-flex bg-white' onClick={() => this.setState({ selectedFilters: []}, () => this.onClickSavedItem(row))}>
														<div className='w-40 py-3 pl-3 justify-content-start d-flex align-self-center'>
															{row.title ? row.title : <span>&nbsp;</span>}
														</div>
														<div className='w-20 py-3 justify-content-start d-flex align-self-center'>
															{row.created_by ? row.created_by : <span>&nbsp;</span>}
														</div>
														<div className='w-15 py-3 justify-content-start d-flex align-self-center'>
															{row.created_at ? momentConvertionUtcToLocalTime(row.created_at, "DD MM YYYY HH:mm") : <span>&nbsp;</span>}
														</div>
														<div className='w-20 py-3 justify-content-start d-flex align-self-center'>
															{row.parameters ? Object.entries(row.parameters).length : <span>&nbsp;</span>}  Filters
														</div>
													</div>
													)
												})
											: null}
										</div>
									</div>
								</div> */}
								<div className="savedFilters">
									<ResizeableDarkThemeTable
										columns={[
											{
												Header: 'Name',
												accessor: 'title'
											},
											{
												Header: 'Created By',
												accessor: 'created_by'
											},
											{
												Header: 'Date',
												accessor: 'timestamp',
												Cell: cellInfo => (
													<span>{cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, "DD MM YYYY HH:mm") : <span>&nbsp;</span>}</span>
												),
												width: 200
											},
											{
												Header: ' ',
												// accessor: 'filters',
												Cell: cellInfo => (
													<span>
														{cellInfo.row.original.parameters && !Array.isArray(cellInfo.row.original.parameters) ? Object.entries(cellInfo.row.original.parameters).length : <span>&nbsp;</span>} Filters
													</span>
												)
											},
										]}
										data={this.state.savedFilters ? this.state.savedFilters : []}
										onClickRow={tableRow => {
											this.setState({
												selectedFilters: []
											}, () => this.onClickSavedItem(tableRow))
										}}
										tableHead={'bg-black text-white'}
										tableBody={'bg-white'}
										tableType={'table-light-hover'}
										perPageClass={`bg-transparent text-lightGray`}
										textColor={'text-black'}
										selectedColor={'bg-backGroundGray'}

										dashboard={this.state.savedFilters && this.state.savedFilters.length}										
										perPage={3}
									/>
								</div>
							</div>
						: null}
						<div className='d-flex justify-content-between mt-4'>
							<div className="position-relative d-inline-block w-100">								
								<div className="comparisonTextboxDiv p-0 m-0" ref={this.advanceSearchRef}>
									{this.state.selectedOption && this.state.selectedOption !== "" ? 
										<p className="mb-2 text-white">{this.state.selectedOption}</p>
									: null}
									
									{this.state.selectedFilters && this.state.selectedFilters.length ? 
										<div className="d-flex flex-wrap mt-3 mb-2">
											{this.state.selectedFilters.map(row => {
												return (
													<small className={`badge bg-info text-white mr-1 mb-1`}>{row.userView}
													<i className='ml-2 f14 fas fa-times-circle cursorPointer text-white' onClick={ () => this.removeFilterData(row) } ></i>
													</small>
													
												)
											})}
										</div>
									: null}

									<div className=''>
										<div className="d-flex w-100">
											<input type="text" className={`comparisonTextbox bg-transparent border-mediumDarkGray text-white py-2 pl-3 w-70`}
												onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
												// onKeyUp={this.onKeyUp}
												//value={this.state.searchText && this.state.selectedOption !== "" ? this.state.searchText : (this.state.selectedOption ? this.state.selectedOption +" : " : "")}
												value={this.state.searchText ? this.state.searchText : ""}
												/*{this.state.selectedDefaultOption === '' ? this.state.searchText : this.state.selectedDefaultOption +': ' } */
												onClick={ () => this.setState({ showMainDropdownOption: true, inputComponent: [], selectedPage: ""  }) }
												placeholder={"Filters"}
											/>
												
											<div className={`ml-3 d-flex align-self-center ${this.state.selectedFilters && this.state.selectedFilters.length ? '' : 'disabled'}`}>
												<div className="btn-group" role="group" aria-label="status-builder">
													<button type="button" className={`cursorPointer btn bg-cyon text-white`} onClick={()=> this.onSearch()}>Search</button>
													<button type="button" className={`cursorPointer btn bg-cyon text-white`} onClick={()=> this.onReset()}><i className='far fa-redo'></i></button>
												</div>
											</div>
											
											<p className={`text-info m-0 f13 align-self-center cursorPointer ml-4 ${this.state.selectedFilters && this.state.selectedFilters.length ? '' : 'displayNone'}`}
												onClick={() => this.setState({ showSaveOption: true, selectedPage: "" }) }>
												Save Filter
											</p>
										</div>
										
										{this.state.showSaveOption ?
											<div className={`d-flex dlpDescriptionForm dlpDescriptionFormPlain mt-4`}>
												{this.state.showMessage ?
													<p className={` ${this.state.responseStatus ? 'text-success' : 'text-danger' }`}>{this.state.responseMessage}</p> 
												: null }
												<div className="d-flex w-100 mt-2">                                                
													<input type="text" 
														className={`inputField text-white mr-2 w-50`}
														onChange={e => {
															this.setState({ searchName: e.target.value })
														}}
														value={this.state.searchName}
														placeholder={'Enter search name'}
													/>
													<button
														className={`btn btn-sm bg-green ml-3`}
														onClick={() => this.saveFilterItem()}
														disabled={this.state.showSaveLoading}
													>
														{this.state.showSaveLoading && !this.state.hasError ? 
															(<React.Fragment>
																{'Saving'}
																<Spinner className='text-center ml-2' color='light' size='sm' />{' '}
															</React.Fragment>) 
														: 'Save'
														}
													</button>
													{/* <div className="btn-group">
														<button type="button" className={`cursorPointer btn btn-primary border-right`} onClick={()=> this.saveFilterItem(this.state.searchName, this.state.filterValue)}>Save</button>
													</div> */}
												</div>
												{this.state.hasError && this.state.searchName === '' ? (
													<p className='text-danger f12 text-right'>{this.state.labelMessage}</p>
												) : (
													''
												)}
											</div>
										: null}
									</div>
								</div>

								{this.state.showMainDropdownOption ? 
									<div className={`dropdownOptions topAuto mt-1 bg-white border-mediumDarkGray position-unset w-25 border-radius-0`}>
										{this.state.mainDropdown && this.state.mainDropdown.map(row => {
											return(
												<button className="bg-transparent text-black cursorPointer" onClick={() => this.onSelectOptions(row)}>
													<p className="mb-0 text-left">{row}</p>
												</button>
											)
										})}
									</div>
								: null}
								
								{this.state.inputComponent && this.state.inputComponent.length === 1 ?
									this.state.inputComponent[0].component === "multiselect" ?
										<div className={`dropdownOptions resourceFilter topAuto mt-1 bg-dark3 border-mediumDarkGray position-unset w-25 border-radius-0`} ref={this.advanceMultiSearchRef}>
											{/* <input type="text" className={`comparisonTextbox bg-muted text-white border-dark py-2 pl-3`}
												onChange={e => {this.setState({ multiSelectSearchText: e.target.value }, () => this.filterMultiselectiOptions(this.state.inputComponent[0]))}}
												value={this.state.multiSelectSearchText}
												placeholder={"Search"}
											/> */}
											{this.state.inputComponent[0].options && this.state.inputComponent[0].options.length > 8 ?
												<div className={`mb-1 px-2 pt-2`}>
													<Search
														topClassName={'bg-transparent text-white f12 border-mediumDarkGray'}
														className={'bg-transparent'}
														data={this.state.inputComponent[0].options ? this.state.inputComponent[0].options : []}
														filteredData={(filteredArray, searchText) => {
															this.setState({ ["multiselect_"+this.state.inputComponent[0].field]: searchText !== "" ? filteredArray : this.state.inputComponent[0].options })
														}}
													/>
												</div>
											: null}
											{(this.state["multiselect_"+this.state.inputComponent[0].field] ? this.state["multiselect_"+this.state.inputComponent[0].field] : this.state.inputComponent[0].options).map((row, mIndex) => {
												return(
													<div className="d-flex py-1 optionDiv bg-transparent text-white cursorPointer" onClick={() => this.setState({ ["checked_"+this.state.inputComponent[0].field+"_"+mIndex]: !this.state["checked_"+this.state.inputComponent[0].field+"_"+mIndex] }, () => this.updateMultiSelectedOptions(row.value, this.state.inputComponent[0], mIndex))}>											
														<div className="ml-1 form-check">
															<input type="checkbox"
																className="form-check-input"
																checked={this.state["checked_"+this.state.inputComponent[0].field+"_"+mIndex]}
															/>
														</div>
														<p className="ml-1 mb-0 text-left">{row.label}</p>
													</div>
												)
											})}
										</div>
									: this.state.inputComponent[0].component === "select" ?
										<div className={`dropdownOptions topAuto mt-1 bg-mediumDarkGray position-unset w-25 border-radius-0`}>
											{this.state.inputComponent[0].options && this.state.inputComponent[0].options.map((row, sIndex) => {
												return(
													<button className="d-flex justify-content-between mb-3 bg-transparent text-black cursorPointer" onClick={() => this.updateSelectedFilter(row.value, this.state.inputComponent[0], this.state.inputComponent[0].options, sIndex)}>
														<p className="mb-0 text-left">{row.label}</p>
													</button>
												)
											})}
										</div>
									: this.state.inputComponent[0].component === "Custom_Duration" ?										
										<DurationFilterSection
											inputComponent={this.state.inputComponent}
											selectedInput={(result) => this.selectedDurationFunction(result)}
										/>
									: this.state.selectedPage === "selectTextList" ?
										<SingleInputSection
											inputComponent={this.state.inputComponent}
											selectedPage={this.state.selectedPage}
											selectedOption={this.state.selectedOption}
											selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
											selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
										/>
									:  null
								: 
									<div className='mt-4'>
										{this.state.selectedPage === "selectTextList" ?
											<IpAddressess
												inputComponent={this.state.inputComponent}
												selectedPage={this.state.selectedPage}
												selectedOption={this.state.selectedOption}
												selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
												selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
											/>
										: this.state.selectedPage === "Resources" ?
											<ResourcesInputSection
												inputComponent={this.state.inputComponent}
												selectedPage={this.state.selectedPage}
												selectedOption={this.state.selectedOption}
												selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
												selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
											/>
										: this.state.selectedPage === "ServicesOld" ?
											<ServicesInputSection
												inputComponent={this.state.inputComponent}
												selectedPage={this.state.selectedPage}
												selectedOption={this.state.selectedOption}
												selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
												selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
											/>
										: this.state.selectedPage === "Tags" ?
											<TagsSection
												selectedProvider={this.state.selectedProvider}
												inputComponent={this.state.inputComponent}
												selectedPage={this.state.selectedPage}
												selectedOption={this.state.selectedOption}selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
												selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
											/>
										: this.state.selectedPage === "InstanceType" ?
											<InstanceTypeInputSection
												inputComponent={this.state.inputComponent}
												selectedPage={this.state.selectedPage}
												selectedOption={this.state.selectedOption}selectedFilters={this.state.selectedFilters && this.state.selectedFilters.filter(e => e.label === this.state.selectedOption).length ? this.state.selectedFilters.filter(e => e.label === this.state.selectedOption) : []}
												selectedInput={(selectedOptions, result) => this.selectedTextListDetails(selectedOptions, result)}
											/>
										: this.state.selectedPage === "Services" ?
											<div className={`serviceTreeDropdownTransparent position-absolute mt-n1 ${this.state.filteredServiceResourceArray.length ? '': 'disabled'}`}>
												<DropdownTreeSelect 
													//values={this.state.selectedServiceContent}
													texts={{ placeholder: this.state.selectedServiceContent ? this.state.selectedServiceContent : "Select" }}
													showDropdown={"always"}
													data={this.state.filteredServiceResourceArray}
													onChange={this.onChangeServiceDropdown}
													className="bootstrap-demo"
													keepTreeOnSearch={true}
													keepChildrenOnSearch={true}
												/>
											</div>										
										: null}
									</div>
								}
							</div>							
							{this.state.selectedFilters && this.state.selectedFilters.length ? 
								// <div className='w-30 bg-mediumDarkGray p-3 rounded'>
								<div className='w-30 border-lef-info displayNone'>
									<p className='mb-2 text-black font-weight-bold f16'>Selected Filters</p>
									{this.state.selectedFilters.map(row => {
										return (
											<div className="mt-2 w-100">
											<small className={`badge bg-lightGray mr-1 text-white`}>{row.userView}
											<i className='ml-2 f14 fas fa-times-circle cursorPointer text-white' onClick={ () => this.removeFilterData(row) } ></i>
											</small>
											</div>
										)
									})}
								</div>
							: null}
						</div>
						</React.Fragment>
						: null}
					</div>
				</div>
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
ResourcesAdvancedSearch.propTypes = {
	onCloseModal: PropTypes.func,
	getFilteredList: PropTypes.func,
	getSearchBuilderList: PropTypes.func,
	getSearchMetaResults: PropTypes.func,
	saveSearchMetaResults: PropTypes.func,
	searchResults: PropTypes.array,
	resetForm: PropTypes.number,
}



/**
 * Map all reducer state to the props of the component
 * @param {Object} state
 */
const mapStateToProps = state => {
	// console.log('state', state)
	return {
		searchResults: state.filters.searchMetaList,
		providers: state.filters.providers,
		services: state.filters.services,
		regions: state.filters.regions.length ? state.filters.regions[0].provider_regions : [],
		accounts: state.filters.accounts,
	}
}

export default connect(mapStateToProps, {
	listAllAccounts,
	listAllServices,
	listAllRegions,
	getSearchMetaResults,
	saveSearchMetaResults
})(ResourcesAdvancedSearch)
