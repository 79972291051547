/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file QOS LandingPage.js
 * @author Prakash // on 07/02/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { listQosPolicies, removeQosPolicy } from '../../../actions/Collider/QosAction'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, capitalizeAllLetter, getRegionName, getAccountNameFromId, noSpace } from '../../../utils/utility'

import { CSVLink } from 'react-csv';

import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';

import { URL_PATH } from '../../../config/urlPath';
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import { qosDeleteAccount, qosListAccounts } from '../../../actions/Collider/AccountAction';
import FilterSection from '../../common/FiltersSection';

const LandingPage = () => {
    const clickOutside = useRef() 
    const [state, setState] = useState({
        showLoading: true,

        itemList: [],
        filteredList: [],
        csvheaders: [],
        emptyOptions: [],
        modal: false,
        changeManage: 'QOS',
        selectedManage: 'QOS',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster', 'manage_qos']
    })

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const providers = useSelector(state => state?.filters?.providers || false);

    const location = useLocation();
    const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
                    if(row.action) {
                        actionsAllowed = row.action
                    }
                }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
		if(locationData && Object.entries(locationData).length) {            
			setState(prevState => ({ 
				...prevState,
				selectedManage: locationData.selectedManage ? locationData.selectedManage : 'QOS',
                changeManage: locationData.selectedManage ? locationData.selectedManage : 'QOS',
			}))
		}
	}, [locationData, dispatch])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],
            selectedManage: filter.changeManage ? filter.changeManage : 'QOS',
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }))
            
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters.filter(e => e !== "All")
            }
            let columns = [
                {
                    Header: 'Date',
                    accessor: d => d.modified_at ? "modified_at" : 'created_at',
                    Cell: cellInfo => (
                    <span>
                        {cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
                    </span>
                    ),
                    width: 200
                },
                {
                    Header: 'Name',
                    accessor: state.selectedManage === 'QOS' ? 'qos_name' : 'account',
                    width: 250,
                },
                {
                    Header: 'Provider',
                    accessor: d => d.provider ? capitalizeAllLetter(d.provider) : '',
                    width: 150,
                },
                {
                    Header: 'Account',
                    accessor: 'account_id',
                    width: 200,
                    Cell: cellInfo => (
                        <div className="d-flex justify-content-between align-self-center">
                            {cellInfo.row.original && cellInfo.row.original.account_id ? getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) : ''}
                        </div>
                    ),
                },
                {
                    Header: 'Region',
                    accessor: 'region',
                    width: 200,
                    Cell: cellInfo => (
                        <div className="d-flex justify-content-between align-self-center">
                            {cellInfo.row.original && cellInfo.row.original.region ? getRegionName(cellInfo.row.original.region, state.regions) : ''}
                        </div>
                    ),
                },
                {
                    Header: 'Cluster',
                    accessor: 'cluster_name',
                    width: 200,
                },
                {
                    Header: 'Actions',
                    Cell: cellInfo => (
                        <div className="d-flex align-self-center">
                            {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                state.selectedManage === 'QOS' ?
                                    <Link 
                                        to={URL_PATH.CREATE_QOS} 
                                        state={{
                                            selectedData: cellInfo.row.original,
                                            pageType: 'edit',
                                        }}
                                        className='text-info'>
                                        Edit
                                    </Link>
                                : 
                                    <Link 
                                        to={URL_PATH.CREATE_GROUP} 
                                        state={{
                                            selectedData: cellInfo.row.original,
                                            pageType: 'edit',
                                        }}
                                        className='text-info'>
                                        Edit
                                    </Link>
                            : null}
                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                <p className='ml-3 mb-0 text-danger cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, deleteModal: true, selectedDeleteItem: cellInfo.row.original }))}>Delete</p>
                            : null}
                        </div>
                    ),
                    width: 200
                }
            ]

            setState(prevState => ({ ...prevState, columns }))

            if(state.selectedProvider) {
                if(state.selectedManage === 'QOS') {
                    dispatch(listQosPolicies(params))
                        .then((response) => {
                            if(response) {
                                let results = response && response.results && response.results.length ? response.results : []
                                setState(prevState => ({ ...prevState, detailsFormat: response && response.qos_details ? response.qos_details : [], itemList: results, filteredList: results, showLoading: false }));
                            }
                        })
                } else {
                    dispatch(qosListAccounts(params))
                        .then((response) => {
                            if(response) {
                                let results = response && response.results && response.results.length ? response.results : []
                                
                                setState(prevState => ({ ...prevState, detailsFormat: response && response.qos_details ? response.qos_details : [], itemList: results, filteredList: results, showLoading: false }));
                            }
                        })
                }
            } else {
                setState(prevState => ({ ...prevState, itemList: [], filteredList: [], showLoading: false }));
            }
        }
    }, [dispatch, state.accounts, state.callSearch, state.regions, state.selectedAccount, state.selectedClusters, state.selectedManage, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.actionsAllowed])

    useEffect(() => {
        if(state.itemList && state.itemList.length) {
            let dataResult = state.itemList
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, csvheaders: headers }))
        }
    }, [state.itemList])
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value, deleteConfirmMessage: '' }))
	}

    const removeQosPolicyFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        if(item && item.asset_id) {
            params.asset_id = item.asset_id
        } else {
            params.provider = item.provider
            params.account_id = item.account_id
            params.region = item.region
            params.cluster_name = item.cluster_name
            if(state.selectedManage === 'QOS') {
                params.qos_name = item.qos_name
            } else {
                params.account = item.account
            }
        }
        if(state.selectedManage === 'QOS') {
            if(state.qos_name && state.qos_name === state.selectedDeleteItem.qos_name) {
                dispatch(removeQosPolicy(params))
                    .then((response) => {
                        if(response) {
                            let messageType = 'danger'
                            let message = response && response.message ? response.message : "Error in deleting"
        
                            if(response.status) {
                                messageType = 'success'
                                setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                            } else {
                                setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                            }
        
        
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false,
                                    pauseOnHover: true,
                                    showIcon: true,
                                }
                            });
                        } else {
                            setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                        }
                    })
            } else {
                if(!state.name || state.name === '') {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter qos name", showDeleteLoading: false }))
                } else if(state.name !== item.qos_name) {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered qos name does not match selected qos name", showDeleteLoading: false }))
                }
            }            
        } else {
            if(state.name && state.name === state.selectedDeleteItem.account) {
                dispatch(qosDeleteAccount(params))
                    .then((response) => {
                        if(response) {
                            let messageType = 'danger'
                            let message = response && response.message ? response.message : "Error in deleting"
        
                            if(response.status) {
                                messageType = 'success'
                                setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                            } else {
                                setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                            }
        
        
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: false,
                                    pauseOnHover: true,
                                    showIcon: true,
                                }
                            });
                        } else {
                            setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                        }
                    })
            } else {
                if(!state.name || state.name === '') {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter account", showDeleteLoading: false }))
                } else if(state.name !== item.account) {
                    setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered account does not match selected account", showDeleteLoading: false }))
                }
            }  
        }

    }

    useEffect(() => {
        if(state.callDelete && state.selectedDeleteItem && Object.entries(state.selectedDeleteItem).length) {
            setState(prevState => ({ ...prevState, modal: !state.modal, callDelete: false }))
        }
    }, [state.callDelete, state.selectedDeleteItem, state.modal])



	const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const navigateTo = () => {
        dispatch(setHpcPropsDetails('qosCreateEditInputs', {}))
        let path = URL_PATH.CREATE_GROUP
        if(state.selectedManage === 'QOS') {
            path = URL_PATH.CREATE_QOS
        }
        navigate(path, { state: { pageType: 'Create' } });
    }

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-5 col-md-12 col-sm-12 py-2 align-self-center'>
                        <h6 className='text-white m-0'>Manage QOS and Group</h6>
                        <p className='text-white m-0'>Consolidated view of qos and account association</p>
                    </div>
                    <div className='col-lg-7 col-md-12 col-sm-12 py-2' ref={clickOutside}>
                        <div className='row mx-0 justify-content-end'>
                            <FilterSection
                                filters={state.topSectionFilters}
                                topFilterOptions= {state.topFilterOptions}
                                selectedFilters={(filter) => handleSelectedFilters(filter)}
                                screen="QOS"
                                changeManage={state.changeManage}
                                clickedonParent={state.clickedonParent}
                                filterAdvancedSearch={false}
                                onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                                onReset={onReset}
                            />
                        </div>
                    </div>
                </div>
                <div className='row mx-0'>
                    <div className='col-lg-12 p-3'>
                        <div className='bg-transparent'>
                            <div className='d-flex justify-content-between'>
                                <div className=''>
                                    <p className='mb-0 f16 text-white'><b>{state.selectedManage} List</b></p>
                                    <p className='f12 mb-1 align-self-center pt-1'>Showing {state.filteredList && state.filteredList.length} of total {state.itemList && state.itemList.length} qos</p>
                                </div>
                                <div className='align-self-center w-30'>
                                    <div className='d-flex justify-content-end'>
                                        {state.filteredList && state.filteredList.length ?
                                            <CSVLink 
                                                data={state.filteredList ? state.filteredList : []} 
                                                headers={state.csvheaders ? state.csvheaders : []}
                                                filename={'qos-lists.csv'}
                                                    className={'mt-2'}
                                                    target='_blank'
                                                >
                                                    <span className='d-flex text-primary-color mr-2'>
                                                        <i className='fas fa-download text-primary-color mr-2'/>
                                                    </span>
                                            </CSVLink>
                                        : null}
                                        {state.itemList && state.itemList.length ?
                                            <Search
                                                data={state.itemList ? state.itemList : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-white f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white form-control-sm f12"}
                                                filteredData={(filteredList) => {
                                                    setState(prevState => ({ ...prevState, filteredList }))
                                                }}
                                            />
                                        : null}
                                        
                                        {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                            <button className={`btn bg-green p-1 text-white ml-2`}  onClick={() => navigateTo()}>Create&nbsp;{state.selectedManage}</button>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            <div className='overflowYAuto h100'>
                                <div className='col-lg-12 col-12 px-0 py-1'>
									{state.filteredList && state.filteredList.length ?
										<ResizeableDarkThemeTable
											columns={state.columns}
											data={state.filteredList}
											perPage={20}
											tableHead={'bg-black text-white'}
											tableBody={'bg-dark3 text-white'}
											tableType={'table-dark-hover'}
											perPageClass={`bg-transparent text-lightGray`}
											textColor={'text-black'}
											selectedColor={'bg-transparent'}
											dashboard={state.filteredList && state.filteredList.length ? true : false}
											onClickRow={tableRow => {}}
                                            showPaginationTop={true}

											// tableSize={'table-sm'}
										/>
									: 
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									}
								</div>
                                <Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))} {...state.selectedDeleteItem}>
                                    <ModalHeader toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>Delete {state.selectedManage}</ModalHeader>
                                    <ModalBody>
                                        Please enter the
                                        <span className='f18 mr-1'>
                                            {state.selectedManage === 'QOS' ? 
                                                <React.Fragment>
                                                <span className='f14 mx-1'>qos name</span>
                                                {state.selectedDeleteItem && state.selectedDeleteItem.qos_name ? state.selectedDeleteItem.qos_name : ''}
                                                </React.Fragment>
                                            :
                                                <React.Fragment>
                                                <span className='f14 mx-1'>account name</span>
                                                {state.selectedDeleteItem && state.selectedDeleteItem.account ? state.selectedDeleteItem.account : ''}
                                                </React.Fragment>
                                            }
                                        </span> 
                                        to delete the {state.selectedManage}
                                        <Input
                                            type='text'
                                            placeholder={'Enter '+state.selectedManage+' name'}
                                            className={`inputTextbox bg-transparent border-lightGray text-black`}
                                            // maxlength='128'
                                            value={state.name ? state.name : ''}
                                            onChange={e => handleInputChange('name', noSpace(e.target.value))}
                                        />
                                        {state.deleteConfirmMessage ?
                                            <small className='text-red'>{state.deleteConfirmMessage}</small>
                                        : null}
                                        {/* Are you sure you want to delete the <span className='f18 mx-1 text-red'>{state.selectedDeleteItem && state.selectedDeleteItem.qos_name ? state.selectedDeleteItem.qos_name : 'Name'}</span> qos */}

                                    </ModalBody>
                                    <ModalFooter className="border-0">
                                    <Button color="primary" onClick={() => removeQosPolicyFunction()}>
                                        {state.showDeleteLoading ?
                                            <Spinner size='sm' color='light' className='mx-2' />
                                        : null}Ok
                                    </Button>{' '}
                                    <Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))}>
                                        Cancel
                                    </Button>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage