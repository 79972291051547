/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file QueueDetails.js
 * @author Prakash // on 24/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { setCommonPropsDetails } from '../../actions/commonActionNew'
import { listQueueDetails, deleteQueue } from '../../actions/Collider/ClusterAction'
import _ from 'lodash'
import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, convertBytesTo } from '../../utils/utility'

import { CSVLink } from 'react-csv';
import { Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';

import QueueDetailsSidePanel from './QueueDetailsSidePanel'
import Search from '../common/SearchWithHiddenInput'
import CreateUpdateQueuePanel from './CreateUpdateQueuePanel';
 import { Store as CommonNotification } from 'react-notifications-component';
import FilterSection from '../common/FiltersSection';


const QueueDetails = () => {
    const clickOutside = useRef()
        
    const [state, setState] = useState({
        // showCreateUpdateQueuePanel: true,
        showLoading: true,

        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 10,

        queueDetails: [],
        selectedQueueDetails: {},
        filteredQueueDetails: [],
        queueheaders: [],
        deleteModal: false,

        actionsAllowed: [],
        topSectionFilters: ['provider', 'project', 'account', 'region', 'cluster']
    })    

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
	const currentPath = location.pathname;

    const providers = useSelector(state => state?.filters?.providers || false);
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
					if(row.action) {
						actionsAllowed = row.action
					}
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    // useEffect(() => {
    //     if (providers) {
    //         setState(prevState => ({ ...prevState, providers: providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""), callSearch: true }));
    //     }
    // }, [providers]);
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            selectedClusters: filter.selectedClusters ? filter.selectedClusters : [],            
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: '', selectedProjectTags: [], selectedAccount: [], selectedRegion: [], selectedClusters: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, startRecord: 0,  currentPage: 1 }))
            
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }            
            if(state.selectedClusters && state.selectedClusters.length) {
                params.cluster_name = state.selectedClusters.filter(e => e !== "All")
            }

            if(state.nextToken && state.nextToken.length) {
                params.next_token = state.nextToken
            }
            // params.size = 10
            if(state.selectedProvider) {
                dispatch(listQueueDetails(params))
                    .then((response) => {
                        if(response) {
                            let results = response && response.results && response.results.length ? response.results : []

                            let totalResults = [] 
                            let startRecord = state.startRecord
                            let currentPage = state.currentPage 
                            if(state.nextToken && state.nextToken.length) {
                                totalResults = state.queueDetails &&  state.queueDetails.length ? state.queueDetails : []
                                startRecord = startRecord + state.perPage;
                                currentPage = currentPage+ 1
                            }

                            if(results && results.length) {
                                totalResults = totalResults.concat(results)
                            }
                            let totalPages = 1
                            if(totalResults.length > state.perPage) {
                                totalPages = Math.ceil(totalResults.length / state.perPage)
                            }
                            
                            setState(prevState => ({ ...prevState, queueDetails: totalResults, filteredQueueDetails: totalResults, detailsFormat: response && response.queue_details ? response.queue_details : [], showLoading: false, totalPages, startRecord, currentPage, nextToken: response.next_token, totalRecords: response.total }));
                        }
                })
            } else {
                setState(prevState => ({ ...prevState, queueDetails: [], filteredQueueDetails: [], showLoading: false }));
            }
        }
    }, [dispatch, state.callSearch, state.perPage, state.selectedAccount, state.queueDetails, state.selectedClusters, state.selectedProjectTags, state.selectedProvider, state.selectedRegion, state.currentPage, state.nextToken, state.startRecord])

    useEffect(() => {
        if(state.queueDetails && state.queueDetails.length) {
            let dataResult = state.queueDetails
            let headers = []
            Object.entries(dataResult[0]).forEach(([key, value]) => {
                if(typeof value === 'string') {
                    let headerRow = {}
                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                    headerRow.key = key
                    headers.push(headerRow)
                }
            })
    
            setState(prevState => ({ ...prevState, queueheaders: headers }))
        }

        //status graph of how many running days 
        state.queueDetails && state.queueDetails.forEach(row => {
            let donut = [{
                item_count: row.number_of_days ? row.number_of_days : 0,
                item_name: 'Days'
            }]
            let donut_total_count = row.number_of_days ? row.number_of_days : 0
            // state.statusSection.trend && state.statusSection.trend.length && state.statusSection.trend[0].total && Object.entries(state.statusSection.trend[0].total).forEach(([key, value]) => {
            //     let donutRow = {}
            //     donutRow.item_count = value
            //     donutRow.item_name = key
            //     donut.push(donutRow)
            //     donut_total_count += value 
            // })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let statusDonutData = {
                'items': donut,
                'label': 'Days',
                'total_count': donut_total_count
            }
    
            setState(prevState => ({ ...prevState, ['statusDonutData_'+row.queue_name]: statusDonutData }))
        })
    }, [state.queueDetails])

    useEffect(() => {
        if(state.refreStatusDontGraph) {
            setState(prevState => ({ ...prevState, refreStatusDontGraph: false }))
        }
    }, [state.refreStatusDontGraph])

    useEffect(() => {
        if(state.selectedQueueDetails && Object.entries(state.selectedQueueDetails).length)
        setState(prevState => ({ ...prevState, showQueueDetails: true }))
    }, [state.selectedQueueDetails])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    const gotoPage = (pageNumber) => {
        // Go to the specified page and update state        
        if(pageNumber > 0  && pageNumber <= state.totalPages) {
            let startRecord = (pageNumber - 1) * state.perPage
            setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
        } else  if(pageNumber > state.totalPages) {
            setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
        } else {
            setState(prevState => ({ ...prevState, pageNumber }))
        }
    }

    useEffect(() => {
        if(state.pageMode) {
            setState(prevState => ({ ...prevState, showCreateUpdateQueuePanel: true }))
        }
    }, [state.pageMode])

    
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value, deleteConfirmMessage: '' }))
	}

    const deleteQueueFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.selectedDeleteItem 
        let params = {}
        if(item && item.asset_id) {
            params.asset_id = item.asset_id
        } else {
            params.provider = item.provider
            params.account_id = item.account_id
            params.region = item.region
            params.cluster_name = item.cluster_name
            params.queue_name = item.queue_name
        }
        if(state.queue_name && state.queue_name === state.selectedDeleteItem.queue_name) {
            dispatch(deleteQueue(params))
                .then((response) => {
                    if(response) {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : "Erro in deleting"
    
                        if(response.status) {
                            messageType = 'success'
                            setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false }))
                            // toggle()
                            setTimeout(() => { setState(prevState => ({ ...prevState, callSearch: true, showDeleteLoading: false }))}, 1000)
                        } else {
                            setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                        }
    
    
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                            }
                        });
                    }
                })
        } else {
            if(!state.queue_name || state.queue_name === '') {
                setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter queue name", showDeleteLoading: false }))
            } else if(state.queue_name !== item.queue_name) {
                setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered Queue name does not match selected queue name", showDeleteLoading: false }))
            }
        }

    }

    useEffect(() => {
        if(state.callDelete && state.selectedDeleteItem && Object.entries(state.selectedDeleteItem).length) {
            setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callDelete: false }))
        }
    }, [state.callDelete, state.selectedDeleteItem, state.deleteModal])

    const handleChildClick = (event, type, dropdownType, section) => {
        
        if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            
            {state.showCreateUpdateQueuePanel ? 
                <CreateUpdateQueuePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showCreateUpdateQueuePanel: false, pageMode: '', editQueueDetails: {}, callSearch: true }))}
                    selectedQueueDetails={state.editQueueDetails}
                    pageMode={state.pageMode}
                />
            : null}
            {state.showQueueDetails ? 
                <QueueDetailsSidePanel
                    closeSidePanel={() => setState(prevState => ({ ...prevState, showQueueDetails: false, selectedQueueDetails: {} }))}
                    selectedQueueDetails={state.selectedQueueDetails}
                    detailsFormat={state.detailsFormat ? state.detailsFormat : []}
                />
            : null}
            
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-3 col-md-12 col-sm-12 py-2 align-self-center'>
                        <h6 className='text-white m-0'>Queue Details</h6>
                        <p className='text-white m-0'>Consolidated view of queue details</p>
                    </div>
                    <div className='col-lg-9 col-8 py-2' ref={clickOutside}>
                        <div className='row mx-0 topBasicFilter justify-content-end'>
                            <FilterSection
                                filters={state.topSectionFilters}
                                selectedFilters={(filter) => handleSelectedFilters(filter)}
                                clickedonParent={state.clickedonParent}
                                filterAdvancedSearch={false}
                                onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                                onReset={onReset}
                            />
                        </div>
                    </div>
                </div>
                <div className='row mx-0'>
                    <div className='col-lg-12 p-3'>
                        <div className='bg-transparent'>
                            <div className='d-flex justify-content-between'>
                                <div className=''>
                                    <p className='mb-0 f16 text-white'><b>Queue List</b></p>
                                    <p className='f12 mb-1 align-self-center pt-1'>Showing {state.filteredQueueDetails && state.filteredQueueDetails.length} of total {state.queueDetails && state.queueDetails.length} queue(s)</p>
                                </div>
                                <div className='align-self-center w-30'>
                                    <div className='d-flex justify-content-end'>
                                        {state.filteredQueueDetails && state.filteredQueueDetails.length ?
                                            <CSVLink 
                                                data={state.filteredQueueDetails ? state.filteredQueueDetails : []} 
                                                headers={state.queueheaders ? state.queueheaders : []}
                                                filename={'queue-lists.csv'}
                                                    className={'mt-2'}
                                                    target='_blank'
                                                >
                                                    <span className='d-flex text-primary-color mr-2'>
                                                        <i className='fas fa-download text-primary-color mr-2'/>
                                                    </span>
                                            </CSVLink>
                                        : null}
                                        {state.queueDetails && state.queueDetails.length ?
                                            <Search
                                                data={state.queueDetails ? state.queueDetails : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-white f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white form-control-sm f12"}
                                                filteredData={(filteredQueueDetails) => {
                                                    let totalPages = 1
                                                    if(filteredQueueDetails && filteredQueueDetails.length > state.perPage) {
                                                        totalPages = Math.ceil(filteredQueueDetails.length / state.perPage)
                                                    }
                                                    setState(prevState => ({ ...prevState, filteredQueueDetails, totalPages, startRecord: 0, currentPage: 1 }))
                                                }}
                                            />
                                        : null}
                                        {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                            <button className={`btn bg-green p-1 text-white ml-2`}  onClick={() => setState(prevState => ({ ...prevState, pageMode: 'Create' }))}>Create&nbsp;Queue</button>
                                        : null}
                                    </div>

                                    {state.totalRecords > state.perPage ?
                                        <div className='pagination errorPagePagination f12'>
                                            <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                            <span className='displayNone'>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ''
                                                        gotoPage(page)
                                                    }}
                                                    className='ml-1 inputClass'
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='overflowYAuto h100'>
                                {state.filteredQueueDetails && state.filteredQueueDetails.length ? 
                                    state.filteredQueueDetails.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                        return(
                                            <div className={`d-flex mb-2 bg-dark3 mb-2 ${state.selectedQueueDetails && state.selectedQueueDetails.queue_name !== item.queue_name ? 'cursorPointer' : 'bg-dark text-white'}`} key={"row_"+(state.startRecord + i)}> 
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2'>
                                                        <div className="d-flex">
                                                            <div className="py-1 w-100">
                                                                <div className='d-flex'>
                                                                    <p className="b-block mb-0 mr-2">Queue</p>
                                                                    <p className="mb-0 text-white">
                                                                        { item.queue_name ? item.queue_name : ''}
                                                                    </p>
                                                                </div>
                                                                <div className='d-flex flex-wrap text-primary-color'>
                                                                    {item.nodes && item.nodes.length ?
                                                                        <React.Fragment>
                                                                        <span className='mr-2'>CPUs: {item.nodes[0].cpus ? item.nodes[0].cpus : ''}, </span>

                                                                        <span className='mr-2'>Mem:
                                                                            {item.nodes[0].memory ? 
                                                                                convertBytesTo(item.nodes[0].memory, 'Megabytes', 'round', 'GB')
                                                                             : ''
                                                                            },
                                                                        </span>
                                                                        </React.Fragment>
                                                                    : null}
                                                                    {item.total_nodes ?
                                                                        <span>Nodes: {item.total_nodes}</span>
                                                                    : null} 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Cluster</p>
                                                                <p className="mb-0 text-white">
                                                                    { item.cluster_name ? item.cluster_name : ''}
                                                                    <span className={`mb-0 mr-2 f18 text-white`}>
                                                                        (v {item.cluster_version})
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex pt-2">
                                                            <div className="py-1 w-100">
                                                                <p className="b-block mb-0">Resources</p>
                                                                <p className="mb-0 text-white">
                                                                    {item.account_id ?
                                                                        <span>{' '+item.account_id}</span>
                                                                    : null}
                                                                    {item.region ?
                                                                        <span>{' '+item.region}</span>
                                                                    : null}
                                                                    {item.resource_type ?
                                                                        <span>{' '+item.resource_type}</span>
                                                                    : null}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        {/* <span className='mb-0 small mr-1 text-primary-color'>Users</span>
                                                        <span className={`mb-0 mr-2 f12 text-black`}>
                                                            {item.user_count}
                                                        </span>
                                                        {item.description ?
                                                            <React.Fragment>
                                                            <span className='mb-0 small mr-1 text-primary-color'>Description</span>
                                                            <span className='mb-0 ml-2 f12 text-black font-weight-bold'>{item.description}</span>
                                                            </React.Fragment>
                                                        : null}
                                                    </p> */}
                                                </div>
                                                
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2'>
                                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(0, 3).map(([key, value], tIndex) => {
                                                            return(
                                                                <div className={`d-flex ${!tIndex ? "" : "pt-2"}`}>
                                                                    <div className="py-1 w-100">
                                                                        <p className="b-block mb-0">{key}</p>
                                                                        {value ? 
                                                                            <p className="mb-0 text-white">{value}</p>   
                                                                        : 
                                                                            <span>&nbsp;</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 pl-0 cursorPointer">
                                                    <div className='py-2'>
                                                        {item.parsed_tags && Object.entries(item.parsed_tags).slice(3, 6).map(([key, value], tIndex) => {
                                                            return(
                                                                <div className={`d-flex ${!tIndex ? "" : "pt-2"}`}>
                                                                    <div className="py-1 w-100">
                                                                        <p className="b-block mb-0">{key}</p>
                                                                        {value ? 
                                                                            <p className="mb-0 text-white">{value}</p>   
                                                                        : 
                                                                            <span>&nbsp;</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 col-md-3 col-sm-3 pr-0 cursorPointer">
                                                    <div className='py-2 d-flex'>
                                                        <div className='p-2 rounded border mr-2 w-80'>
                                                            <div className="d-flex">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">Status</p>
                                                                    <p className="mb-0 text-white">
                                                                        {item.status ?
                                                                            <span className={`badge bg-${item.status === 'up' ? 'appleGreen' : 'mulbery'} hiddenEllipses`}>{item.status} </span>
                                                                        : null}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="py-1 w-100">
                                                                    <p className="b-block mb-0">Days Running</p>
                                                                    <p className="mb-0 text-white">
                                                                        <span>{item.number_of_days ? item.number_of_days : 0}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex">
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Created at</p>
                                                                    <p className="mb-0 text-white">{item.created_at ? momentConvertionUtcToLocalTime(item.created_at, 'DD MMM YYYY HH:mm') : ''}</p>
                                                                </div>
                                                                <div className="py-1 w-50">
                                                                    <p className="b-block mb-0">Modified at</p>
                                                                    <p className="mb-0 text-white">{item.last_modified_at ?  momentConvertionUtcToLocalTime(item.last_modified_at, 'DD MMM YYYY HH:mm') : 'Not Modified'}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='ml-3 align-self-center'>
                                                            {/* <p className='mb-0 text-center text-cyon' onClick={() => setState(prevState => ({ ...prevState, editQueueDetails: item, pageMode: 'View' }))}>View</p> */}
                                                            <p className='mb-0 text-center text-cyon' onClick={() => setState(prevState => ({ ...prevState, selectedQueueDetails: item }))}>Details</p>
                                                            {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                                                <p className='mb-0 text-center mt-2 text-cyon' onClick={() => setState(prevState => ({ ...prevState, editQueueDetails: item, pageMode: 'Edit' }))}>Edit</p>
                                                            : null}
                                                            {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                                                <p className='mb-0 text-center mt-2 text-danger' onClick={() => setState(prevState => ({ ...prevState, selectedDeleteItem: item, callDelete: true }))}>Delete</p>
                                                            : null}
                                                        </div>
                                                        <div>
                                                            {/* <Button color="danger" >
                                                                Click Me
                                                            </Button> */}
                                                            <Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))} {...state.selectedDeleteItem}>
                                                                <ModalHeader toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>Delete Queue</ModalHeader>
                                                                <ModalBody>
                                                                    Please enter the queue name <span className='f18 mx-1'>"{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : ''}"</span> to delete the queue
                                                                    <Input
                                                                        type='text'
                                                                        placeholder='Enter Queue name   '
                                                                        className={`inputTextbox bg-transparent border-lightGray text-black`}
                                                                        // maxlength='128'
                                                                        value={state.queue_name ? state.queue_name : ''}
                                                                        onChange={e => handleInputChange('queue_name', e.target.value)}
                                                                    />
                                                                    {state.deleteConfirmMessage ?
                                                                        <small className='text-red'>{state.deleteConfirmMessage}</small>
                                                                    : null}
                                                                    {/* Are you sure you want to delete the <span className='f18 mx-1 text-red'>{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : 'Name'}</span> queue */}

                                                                </ModalBody>
                                                                <ModalFooter className="border-0">
                                                                <Button color="primary" onClick={() => deleteQueueFunction()}>
                                                                    {state.showDeleteLoading ?
                                                                        <Spinner size='sm' color='light' className='mx-2' />
                                                                    : null}Ok
                                                                </Button>{' '}
                                                                <Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>
                                                                    Cancel
                                                                </Button>
                                                                </ModalFooter>
                                                            </Modal>
                                                            </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        )
                                    })
                                : 
                                    <div className='d-flex justify-content-center m-4'>
                                        There are no data on this criteria. Please try adjusting your filter.
                                    </div>
                                }
                                {state.totalRecords > state.perPage ?
                                    <div className='d-flex justify-content-end'>
                                        <div className='pagination errorPagePagination f12 mt-2'>
                                            <span className='mx-3'>Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                            <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                            <span className='displayNone'>
                                                Go to
                                                <input
                                                    type='number'
                                                    value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                    onChange={e => {
                                                        const page = e.target.value ? Number(e.target.value) : ''
                                                        gotoPage(page)
                                                    }}
                                                    className='ml-1 inputClass'
                                                />
                                            </span>
                                            <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextToken && state.nextToken.length ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                            <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                        </div>
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default QueueDetails