/*************************************************
 * Collider
 * @exports
 * @file CreateUpdateUserPanel.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listClusterFilters, listAllProjects, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { createUser, updateUser, listUserFilters } from '../../../actions/UserAction'
import _ from 'lodash'
import { qosListAccounts } from '../../../actions/Collider/AccountAction';
// import yaml from 'js-yaml'
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import MultiSelectSection from '../../common/MultiSelectSection';
 import { Store as CommonNotification } from 'react-notifications-component';
import { validateEmail, getRegionName, getAccountNameFromId, removeUnderScore } from '../../../utils/utility';
import { Input, Spinner } from 'reactstrap';

const CreateUpdateUserPanel = ({ closeSidePanel, refreshApi, pageMode, selectedUserDetails }) => {
    const clickOut = useRef()

    const [state, setState] = useState({
        appliesMenu: [0],
        groupArray: [0],
        updateStrategies: ['drain', 'compute fleet stop', 'terminate', 'force update'],
        clusters: [],
        callUserFilters: true,
        onLoad: true,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false)
    const propProjects = useSelector(state => state?.filters?.commonPropsDetails?.propProjects || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);

    useEffect(() => {
        if(providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
				selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""
            }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response }));
                    }
                })
        }
    }, [dispatch, providers]);

    const getSubMenus = (menu, menuIndex) => {
        let menus = state.menulist.filter(e => e.menu === menu)

        setState(prevState => ({ ...prevState, ['userSubmenu_'+menuIndex]: menus && menus.length && menus[0].submenulist ? menus[0].submenulist : [] }))
    }

    useEffect(() => {
        if(state.callUserFilters) {
            setState(prevState => ({ ...prevState, callUserFilters: false }))
            let params = {};
            dispatch(listUserFilters(params))
                .then((response) => {
                    if(response) {
                        let roleDetails = []
                        if(pageMode !== 'Create') {
                            roleDetails = response.roles ? response.roles.filter(e => e.role_id === selectedUserDetails.roleId).length ? response.roles.filter(e => e.role_id === selectedUserDetails.roleId) : [] : []
                        }
                        let groups = response.groups ? response.groups : []
                        let applications = response.applications ? response.applications : []

                        setState(prevState => ({ ...prevState, rolesList: response.roles ? response.roles : [], roleDetails, groups, applications }));
                    }
                })
        }
    }, [state.callUserFilters, dispatch, pageMode, selectedUserDetails])

    const getAccountRegion = useCallback((provider, project, account, region, arrIndex, type) => { 
        if(!region || (region && !region.length)) {
            let params = {};
            params.provider = provider.toLowerCase();
            
            if(propProjects && propProjects.hasOwnProperty(provider)) {
                setState(prevState => ({ ...prevState, ['projects_'+arrIndex]: propProjects[provider] }));
            } else {
                dispatch(listAllProjects(params))
                    .then((response) => {
                        if(response) {
                            let obj = propProjects ? propProjects : {}
                            obj[provider] = response
                            dispatch(setCommonPropsDetails('propProjects', obj))
                            setState(prevState => ({ ...prevState, ['projects_'+arrIndex]: response }))
                        }
                    });
            }
    
            let accParams = {};
            accParams.provider = provider.toLowerCase();
            accParams.aggregate_by = 'account_id';
            let label = provider
            if(project && project.length) {
                label = ''
                accParams.project_tags = project
            }
    
            if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, ['accounts_'+arrIndex]: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(accParams))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                if(label) {
                                    let obj = propProjAccounts ? propProjAccounts : {}
                                    obj[label] = response
                                    dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                }
                            }
                            setState(prevState => ({ ...prevState, ['accounts_'+arrIndex]: response }));
                        }
                    })
            }
    
            let regParams = {};
            regParams.provider = provider.toLowerCase();
            regParams.aggregate_by = 'region';
            let regLabel = provider
            if(project && project.length) {
                regLabel = ''
                regParams.project_tags = project
            }
            if(account && account.length) {
                regLabel = ''
                regParams.account_id = account
            }
            
            if(propProjRegions && propProjRegions.hasOwnProperty(regLabel)) {
                setState(prevState => ({ ...prevState, ['regions_'+arrIndex]: propProjRegions[regLabel] }));
            } else {
                dispatch(listClusterFilters(regParams))
                    .then((response) => {
                        if(response && response.length) {
                            let obj = propProjRegions ? propProjRegions : {}
                            obj[regLabel] = response
                            dispatch(setCommonPropsDetails('propProjRegions', obj))
                        }
                        setState(prevState => ({ ...prevState, ['regions_'+arrIndex]: response }))
                    })
            }
        }

        let clusParams = {};
        
        if(provider) {
            clusParams.provider = provider.toLowerCase();
        }
        if(project && project.length) {
            clusParams.project_tags = project
        }
		if(account && account.length) {
			clusParams.account_id = account
		}
		if(region && region.length) {
			clusParams.region = region
		}
        clusParams.aggregate_by = 'cluster_name';
        if(provider) {
            dispatch(listClusterFilters(clusParams))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, ['clusters_'+arrIndex]: response }));
                        if(type !== 'edit') {
                            setState(prevState => ({ ...prevState, ["selectedClusters_"+arrIndex]: [] }))
                        }
                    }
            })
        }

    }, [dispatch, propProjAccounts, propProjRegions, propProjects])
	
	const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return() => {
            document.removeEventListener('keydown', handleKeyDown);
        };

    }, [handleKeyDown]);


    //for slurm account

    useEffect(() => {
        let accParams = {};
        accParams.provider = 'aws'
        accParams.aggregate_by = 'account_id'
        let label = 'AWS'
        if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, providerAccounts: propProjAccounts[label] }));
        } else {
            dispatch(listClusterFilters(accParams))
                .then((response) => {
                    if(response) {
                        if(response.length) {
                            let obj = propProjAccounts ? propProjAccounts : {}
                            obj[label] = response
                            dispatch(setCommonPropsDetails('propProjAccounts', obj))
                        }
                        setState(prevState => ({ ...prevState, providerAccounts: response }));
                    }
                })
        }

        let regParams = {};
        regParams.provider = 'aws'
        regParams.aggregate_by = 'region'        
        if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
            setState(prevState => ({ ...prevState, providerRegions: propProjRegions[label] }));
        } else {
            dispatch(listClusterFilters(regParams))
                .then((response) => {
                    if(response && response.length) {
                        let obj = propProjRegions ? propProjRegions : {}
                        obj[label] = response
                        dispatch(setCommonPropsDetails('propProjRegions', obj))
                    }
                    setState(prevState => ({ ...prevState, providerRegions: response }))
                })
        }
    }, [dispatch, propProjAccounts, propProjRegions])

    const getSlurmClusterDetailsFunction = useCallback((provider, account, region, slmIndex, selectedCluster) => {
        let params = {}
        params.provider = provider
        params.account_id = account
        params.region = region
        params.aggregate_by = 'cluster_name'

        dispatch(listClusterFilters(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, ['groupClustersOptions_'+slmIndex]: response, ["grpCluster_"+slmIndex]: selectedCluster ? selectedCluster : '', fetchingCluster: false }));
                } else {
                    setState(prevState => ({ ...prevState, ['groupClustersOptions_'+slmIndex]: [], ["grpCluster_"+slmIndex]: '', fetchingCluster: false }));
                }
        })
    }, [dispatch])

    const getSlurmAccountDetails = useCallback((provider, account, region, cluster, slmIndex) => {
        let params = {}
        params.provider = provider
        params.account_id = account
        params.region = region
        params.cluster_name = cluster

        dispatch(qosListAccounts(params))
            .then((response) => {
                let results = []
                if(response) {
                    results = response.results ? response.results : []
                }
                setState(prevState => ({ ...prevState, ['groupList_'+slmIndex]: results, fetchingSlurmAccount: false }))
        })
    }, [dispatch])

    //for slurm account end

    useEffect(() => {
        if(state.onLoad && pageMode !== 'Create') {
            setState(prevState => ({ ...prevState, shortName: selectedUserDetails.shortName, emailId: selectedUserDetails.emailId, disabled: selectedUserDetails.disabled ? true : false, userName: selectedUserDetails.userName, roleId: selectedUserDetails.roleId, userType: selectedUserDetails.userType, allow_all: selectedUserDetails.allow_all ? true : false, onLoad: false }))

            let groupArray = []
            if(selectedUserDetails.account_list && selectedUserDetails.account_list.length) {
                selectedUserDetails.account_list.forEach((row, index) => {
                    groupArray.push(index)
                    setState(prevState => ({ ...prevState, ["grpAccount_"+index]: row.account_id ? row.account_id : '', ["grpRegion_"+index]: row.region ? row.region : '', ["grpCluster_"+index]: row.cluster_name ? row.cluster_name : '', ["selectedGroup_"+index]: row.account ? row.account : [] }))

                    if(row.provider && row.account_id && row.region) {
                        getSlurmClusterDetailsFunction('aws', row.account_id, row.region, index, row.cluster_name)
                    }
                    if(row.provider && row.account_id && row.region && row.cluster_name) {
                        getSlurmAccountDetails('aws', row.account_id, row.region, row.cluster_name, index)
                    }              
                })
            } else {
                groupArray = [0]
            }
            let applicationId = []
            if(selectedUserDetails.applications && selectedUserDetails.applications.length) {
                selectedUserDetails.applications.forEach(app => {
                    applicationId.push(app.application_id)
                })
            }
            let groupId = []
            if(selectedUserDetails.groups && selectedUserDetails.groups.length) {
                selectedUserDetails.groups.forEach(app => {
                    groupId.push(app.group_id)
                })
            }
            setState(prevState => ({ ...prevState, applicationId, groupId }))

            let appliesMenu = []
            if(selectedUserDetails.menu && selectedUserDetails.menu.length) {
                selectedUserDetails.menu.forEach((row, index) => {
                    appliesMenu.push(0)
                    setState(prevState => ({ ...prevState, ["selectedMenu_"+index]: row.menu ? row.menu : '', ["selectedSubmenu_"+index]: row.subMenu ? row.subMenu : [] }))
                })
            }
        }
    }, [state.onLoad, pageMode, selectedUserDetails, getAccountRegion, getSlurmClusterDetailsFunction, getSlurmAccountDetails])

    useEffect(() => {
        if(state.callSaveFunction) {
            setState(prevState => ({ ...prevState, callSaveFunction: false }))
            let hasError = false
            let params = {}
            if(!state.shortName || !state.userName || !state.roleId) {
                hasError = true
            }
            params.shortName = state.shortName
            if(pageMode === 'Create') {
                if(!state.emailId || !validateEmail(state.emailId)) {
                    hasError = true
                }
                params.emailId = state.emailId
            }
            params.userName = state.userName
            params.roleId = state.roleId
            params.roleName = state.roleDetails.filter(e => e.role_id === state.roleId).length ? state.roleDetails.filter(e => e.role_id === state.roleId)[0].role_name : ''
            params.userType = state.userType
            params.disabled = state.disabled ? true : false

            if(pageMode === 'Edit') {
                params.update_user_id = selectedUserDetails.userId
            }

            if(state.applicationId && state.applicationId.length) {
                let applications = []
                state.applications.forEach(app => {
                    if(state.applicationId.includes(app.application_id)) {
                        applications.push(app)
                    }
                })
                params.applications = applications
            }

            params.allow_all = state.allow_all ? true : false

            if(!state.allow_all) {
                let groups = []
                if(state.groupId && state.groupId.length) {
                    state.groups.forEach(grp => {
                        if(state.groupId.includes(grp.group_id)) {
                            groups.push(grp)
                        }
                    })
                }
                params.groups = groups
            }
            

            let grpAccount = []
            if(state.groupArray && state.groupArray.length) {
                state.groupArray.forEach(slmIndex => {
                    if(state["grpAccount_"+slmIndex] && state["grpRegion_"+slmIndex] && state["grpCluster_"+slmIndex] && state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length) {
                        let slurmRow = {}
                        slurmRow.provider = 'aws'
                        slurmRow.account_id = state["grpAccount_"+slmIndex]
                        slurmRow.region = state["grpRegion_"+slmIndex]
                        slurmRow.cluster_name = state["grpCluster_"+slmIndex]
                        slurmRow.account = state["selectedGroup_"+slmIndex]
                        grpAccount.push(slurmRow)
                    }
                })
            }
            if(grpAccount && grpAccount.length) {
                if(!_.isEqual(selectedUserDetails.account_list, grpAccount)) {
                    params.account_list = grpAccount
                }
            } else {
                params.account_list = []
            }

            setState(prevState => ({ ...prevState, hasError, saveLoading: hasError ? false : true }))

            if(!hasError) {
                if(pageMode === 'Create') {
                    dispatch(createUser(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in saving User'
                            
                            if(response && response.status) {
                                refreshApi()
                                messageType = 'success'
                                message = response.message ? response.message : 'User Created Successfully'
                                
                                setTimeout(() => closeSidePanel(), 2000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false }))
                            }
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
            
                            // setState(prevState => ({ ...prevState, createRespons: response }))
                        })
                } else {
                    dispatch(updateUser(params))
                        .then((response) => {
                            let messageType = 'danger'
                            let message = response.message ? response.message : 'Error in updating User'
                            if(response && response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'User Updated Successfully'

                                refreshApi()
                            }

                            setTimeout(() => setState(prevState => ({ ...prevState, saveLoading: false })), 2000)
            
                            // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))
            
                            CommonNotification.addNotification({
                                //title: 'Wonderful!',
                                message: message,
                                type: messageType,
                                insert: 'top',
                                container: 'top-center',
                                // animationIn: ['animate__animated', 'animate__fadeIn'],
                                // animationOut: ['animate__animated', 'animate__fadeOut'],
                                dismiss: {
                                duration: 5000,
                                onScreen: false,
                                pauseOnHover: true,
                                showIcon: true,
                                }
                            });
                        })
                }
            }
        }
    }, [state.callSaveFunction, dispatch, pageMode, closeSidePanel, refreshApi, state, selectedUserDetails])    

    // const removeSubMenu = (region, menuIndex) => {
	// 	let filteredResult = state["selectedSubMenu_"+menuIndex].filter(e => e !== region)
	// 	setState(prevState => ({ ...prevState, ["selectedSubMenu_"+menuIndex]: filteredResult }))
	// }

    // const addMenuArray = () => {
	// 	let rowList = state.appliesMenu ? state.appliesMenu : [0]
	// 	if (state.appliesMenu) {
	// 		let value = state.appliesMenu[state.appliesMenu.length - 1]
	// 		value = value + 1
	// 		rowList.push(value)
	// 	}

	// 	setState(prevState => ({ ...prevState, appliesMenu: rowList }))
	// }

    const removeMultiSelectItem = (field, value, arrIndex) => {
		let filteredResult = state[field].filter(e => e !== value)
		setState(prevState => ({ ...prevState, [field]: filteredResult }))
	}

    const removeSlurmAccount = (value, arrIndex) => {
		let filteredResult = state["selectedGroup_"+arrIndex].filter(e => e !== value)
		setState(prevState => ({ ...prevState, ["selectedGroup_"+arrIndex]: filteredResult }))
	}

    const addFilterArray = (field) => {
		let rowList = state[field] ? state[field] : [0]
		if (state[field]) {
			let value = state[field][state[field].length - 1]
			value = value + 1
			rowList.push(value)
		}

		setState(prevState => ({ ...prevState, [field]: rowList }))
	}

	const removeFilterArray = (field, index, selItem) => {
		let rowList = state[field] ? state[field] : [0]
		rowList.splice(index, 1);
        if(field === 'groupArray') {
            if(!rowList.length) {
                rowList = [0]
            }
            setState(prevState => ({ ...prevState, ["grpAccount_"+selItem]: '', ["grpRegion_"+selItem]: '', ["grpCluster_"+selItem]: '', ["selectedGroup_"+selItem]: [] }))
        } else {
            setState(prevState => ({ ...prevState, ["moduleName_"+selItem]: '', ["selectedProvider_"+selItem]: '', ["selectedAccount_"+selItem]: [], ["selectedRegion_"+selItem]: [], ["selectedClusters_"+selItem]:  [] }))
        }
		setState(prevState => ({ ...prevState, [field]: rowList }))
    }

    useEffect(() => {
        if(state.roleDetails && state.roleDetails.length) {
            let userType = state.roleDetails[0].user_type
            let menulist = state.roleDetails[0].menulist ? state.roleDetails[0].menulist : []
            let defaultLandingPage = state.roleDetails[0].default_landing_page ? state.roleDetails[0].default_landing_page : {}
            
            let appliesMenu = []
            menulist.forEach((row, index) => {
                appliesMenu.push(index)
                
                let subMenu = row.submenulist.map(item => item.submenu);
                setState(prevState => ({
                    ...prevState,
                    ["selectedMenu_"+index]: row.menu,
                    ["selectedSubMenu_"+index]: subMenu
                }))
            })
            
            setState(prevState => ({ ...prevState, userType, defaultLandingPage, menulist, appliesMenu }))
        }
    }, [state.roleDetails])

    const handleChildClick = (event, type, dropdownType, section) => {	
        
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 9999999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            <div className='search-content bg-dark2 w-70' ref={clickOut}>
                <div className='header-search bd-highlight bg-black d-flex justify-content-between p-2'>
                    <div className='flex-fill bd-highlight text-white'>
                        <h5>{pageMode === "View" ? '' : (pageMode === "Edit" ? "Edit" : "Create")} {pageMode === "View" ? 'Details' : 'User'}</h5>
                        <div className='mr-2 d-flex'>
                        </div>
                    </div>

                    <div className='text-right flex-fill bd-highlight align-self-center'>
                        <i className='far fa-times fa-lg text-white cursorPointer' onClick={() => closeSidePanel()}></i>
                    </div>
                </div>
                <div className='m-2 vh100 overflow-auto'>
                    <div className='rounded bg-dark3 p-3'>
                        <div className='d-flex'>
                            <div className='py-1 w-50'>
                                <p className='b-block mb-0 d-flex'>
                                AD Short Name 
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                    : null}
                                    {state.hasError && !state.shortName ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>
                                {pageMode !== 'View' ?
                                    <div className='d-flex'>
                                        <Input
                                            type='text'
                                            placeholder='short name'
                                            className={`inputTextbox bg-transparent border-lightGray text-white`}
                                            value={state.shortName ? state.shortName : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, shortName: e.target.value }))}
                                        />
                                    </div>
                                : 
                                    <p className='text-white b-block mb-0'>{state.shortName}</p>
                                }
                            </div>
                            <div className='py-1 w-50 pl-3'>
                                <p className='b-block mb-0 d-flex'>
                                Email Id 
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                    : null}
                                    {pageMode !== 'Edit' && state.hasError && !state.emailId ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : pageMode !== 'Edit' && state.hasError && state.emailId && !validateEmail(state.email) ?
                                        <span className='mb-0 text-red ml-2'>Invalid email id</span>
                                    : null}
                                </p>                                
                                {pageMode === 'Create' ?
                                    <div className='d-flex'>
                                        <Input
                                            type='text'
                                            placeholder='email'
                                            className={`inputTextbox bg-transparent border-lightGray text-white`}
                                            value={state.emailId ? state.emailId : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, emailId: e.target.value }))}
                                        />
                                    </div>
                                :
                                    <p className='text-white b-block mb-0'>{state.emailId}</p>
                                }
                            </div>
                        </div>

                        <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                            <div className='py-1 w-50 '>
                                <p className='b-block mb-0 d-flex'>
                                Display Name 
                                    {pageMode !== 'View' ?
                                        <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                    : null}
                                    {state.hasError && !state.userName ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p>                                
                                {pageMode !== 'View' ?
                                    <div className='d-flex'>
                                        <Input
                                            type='text'
                                            placeholder='display name'
                                            className={`inputTextbox bg-transparent border-lightGray text-white`}
                                            value={state.userName ? state.userName : ''}
                                            onChange={e => setState(prevState => ({ ...prevState, userName: e.target.value }))}
                                        />
                                    </div>
                                :
                                    <p className='text-white b-block mb-0'>{state.userName ? state.userName : ''}</p>
                                }
                            </div>
                            <div className='py-1 w-50 pl-3'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0 text-primary-color">Role Name</p>
                                    {state.hasError && !state.roleId ?
                                        <span className='text-danger align-self-center'>required</span>
                                    : null}
                                </div>
                                <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showRole")
                                }}>
                                    <p className={`d-flex p-2 bg-transparent border-lightGray mb-0 rounded-5 d-flex justify-content-between ${state.roleId ? 'text-white' : 'placeholder'}`}>
                                        {state.roleId && state.rolesList && state.rolesList.filter(e => e.role_id === state.roleId).length ? state.rolesList.filter(e => e.role_id === state.roleId)[0].role_name : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showRole && pageMode !== 'View' ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["role_id", "role_name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.rolesList}
                                                selectedValues={state.roleId ? state.roleId : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ 
                                                        ...prevState, 
                                                        roleId: value,
                                                        roleDetails: state.rolesList.filter(e => e.role_id === value).length ? state.rolesList.filter(e => e.role_id === value) : []
                                                    }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={true}
                                                // makeLabelUpperCase={'capitalizeAllLetter'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                            <div className='py-1 w-50'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0 text-primary-color">Application Name</p>
                                    {/* {state.hasError && !state.roleId ?
                                        <span className='text-danger align-self-center'>required</span>
                                    : null} */}
                                </div>
                                <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "showApplications")
                                }}>
                                    <p className={`d-flex p-2 bg-transparent border-lightGray mb-0 rounded-5 d-flex justify-content-between ${state.applicationId && state.applicationId.length ? 'text-white' : 'placeholder'}`}>
                                        {state.applicationId && state.applicationId.length ? state.applicationId.length + 'Selected' : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showApplications && pageMode !== 'View' ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["application_id", "application_name"]}
                                                removeTop={true}
                                                options={state.applications}
                                                selectedValues={state.applicationId ? state.applicationId : ''}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, applicationId: value }))
                                                }}
                                                singleSelection={false}
                                                hideSearch={true}
                                                widthClass={'minWidth220'}
                                                // makeLabelUpperCase={'capitalizeAllLetter'}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        <div className='d-flex flex-wrap w-100'>
                            {state.applicationId && state.applicationId.length ? 
                                state.applicationId.map((app, accIndex) => {
                                    return(
                                        <span key={'acc1_'+accIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{state.applications && state.applications.filter(e => e.application_id === app).length ? state.applications.filter(e => e.application_id === app)[0].application_name : ''}</span>
                                    )
                                })
                            : null}
                        </div>
                        
                        <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                            <div className='py-1 w-50 align-self-center'>
                                <p className='b-block mb-0 d-flex'>
                                    Status
                                </p>
                                <div className='d-flex align-self-center mr-3'>
                                    <div className="toggleOnOffSwitch d-flex mr-2 align-self-center">
                                        <label className="switch mb-0">
                                            <input type="checkbox" 
                                                className="inputdemo" 
                                                checked={state.disabled ? true : false}
                                                onChange={() => {
                                                    if(pageMode !== 'View') {
                                                        setState(prevState => ({ ...prevState, disabled: !state.disabled }))
                                                    }
                                                }}
                                            />
                                            <span className="slider round pl-1"></span>
                                        </label>
                                    </div>
                                    <span className="mb-0 text-lightGray f16 small" onClick={() => setState(prevState => ({ ...prevState, disabled: !state.disabled }))}>Disable</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    {state.roleId ?
                        <div className='bg-black rounded p-3 mb-3'>
                            <div className='d-flex justify-content-between'>
                                <h5 className='mb-0 text-plum cursorPointer d-flex'>Menu Access</h5>
                                {/* <p className='mb-0'>
                                    {state.hasError && (!state.menu || !state.menu.length) ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </p> */}
                            </div>
                            
                            {state.appliesMenu.map((menuIndex, aIndex) => {
                                return(
                                    <div key={'prd_'+menuIndex} className={`d-flex flex-wrap ${menuIndex ? "mt-1 pt-1 border-mediumDarkGray-top":  ""}`}>
                                        <div className="py-1 w-20">
                                            <div className='d-flex justify-content-between'>
                                                <p className="b-block mb-0">Menu</p>
                                                {/* {state.hasError && !state["selectedMenu_"+menuIndex] ?
                                                    <span className='text-danger align-self-center'>required</span>
                                                : null} */}
                                            </div>                                            
                                            <div onClick={(event) => {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', "showMenu_"+menuIndex)
                                            }}>
                                            <p className={`d-flex mr-2 mb-0 bg-transparent border-lightGray justify-content-between p-2 rounded-5 ${state["selectedMenu_"+menuIndex] ? 'text-white' : 'placeholder'}`}>
                                                {state["selectedMenu_"+menuIndex] ? state["selectedMenu_"+menuIndex] : 'Select'}
                                                <i className='fa fa-caret-down mt-1'/>
                                            </p>
                                            {state["showMenu_"+menuIndex] && pageMode !== 'View' ?
                                                <div className='position-relative'>
                                                    <MultiSelectSection 
                                                        fields={["menu", "menu"]}
                                                        className={'w-70'}
                                                        removeTop={true}
                                                        options={state.menulist}
                                                        selectedValues={state["selectedMenu_"+menuIndex] ? state["selectedMenu_"+menuIndex] : []}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState, 
                                                                ["selectedMenu_"+menuIndex]: value,
                                                                ["selectedSubmenu_"+menuIndex]: []
                                                            }))
                                                            getSubMenus(value, menuIndex)
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={false}
                                                    />
                                                </div>
                                            : null}
                                            </div>
                                        </div>
                                        {state["selectedMenu_"+menuIndex] ?
                                            <div className='d-flex w-80'>
                                                <div className="py-1 w-25 displayNone">
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="b-block mb-0">Sub Menus</p>
                                                        {/* {state.hasError && !state["selectedSubMenu_"+menuIndex] ?
                                                            <span className='text-danger align-self-center'>required</span>
                                                        : null} */}
                                                    </div>
                                                    <div onClick={(event) => {
                                                        event.preventDefault();
                                                        handleChildClick(event, 'child', 'muliselectDropDown', "showSubMenu_"+menuIndex)
                                                    }}>
                                                    <p className={`d-flex mr-2 mb-0 bg-transparent border-lightGray justify-content-between p-2 rounded-5 ${state["selectedSubMenu_"+menuIndex] && state["selectedSubMenu_"+menuIndex].length ? 'text-white' : 'placeholder'}`}>
                                                        {state["selectedSubMenu_"+menuIndex] && state["selectedSubMenu_"+menuIndex].length ? state["selectedSubMenu_"+menuIndex].length+' Selected' : 'Select'}
                                                        <i className='fa fa-caret-down mt-1'/>
                                                    </p>
                                                    {state["showSubMenu_"+menuIndex] && pageMode !== 'View' ?
                                                        <div className='position-relative'>
                                                            <MultiSelectSection 
                                                                fields={["submenu", "submenu"]}
                                                                className={'w-70'}
                                                                removeTop={true}
                                                                options={state['userSubmenu_'+menuIndex]}
                                                                selectedValues={state["selectedSubMenu_"+menuIndex] ? state["selectedSubMenu_"+menuIndex] : []}
                                                                callbackMultiSelect={(value) => {
                                                                    setState(prevState => ({ ...prevState, ["selectedSubMenu_"+menuIndex]: value }))
                                                                }}
                                                                singleSelection={false}
                                                                hideSearch={false}
                                                            />
                                                        </div>
                                                    : null}
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-wrap w-75'>
                                                    {Array.isArray(state["selectedSubMenu_"+menuIndex]) ?
                                                        state["selectedSubMenu_"+menuIndex] && state["selectedSubMenu_"+menuIndex].length ? 
                                                            state["selectedSubMenu_"+menuIndex].filter(e => e.value !== "All").map((acc, accIndex) => {
                                                                return(
                                                                    <span key={'acc_'+accIndex+'_'+menuIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{acc}
                                                                    {/* {!state.editMode ?
                                                                        <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeSubMenu(acc, menuIndex) } ></i>
                                                                    : null} */}
                                                                    </span>
                                                                )
                                                            })
                                                        : null
                                                    : 
                                                        <span className='text-white'>{state["selectedSubMenu_"+menuIndex]}</span>
                                                    }
                                                </div>
                                            </div>
                                        : null}
                                    </div>
                                )
                            })}
                            
                        </div>
                    : null}

                    
                    <div className='bg-black rounded p-3 mb-3'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <h5 className='mb-0 text-plum cursorPointer d-flex'>Group Associations</h5>
                                <div className='d-flex align-self-center ml-3'>
                                    <div className="toggleOnOffSwitch d-flex mr-2 align-self-center">
                                        <label className="switch mb-0">
                                            <input type="checkbox" 
                                                className="inputdemo" 
                                                checked={state.allow_all ? true : false}
                                                onChange={() => {
                                                    if(pageMode !== 'View') {
                                                        setState(prevState => ({ ...prevState, allow_all: !state.allow_all }))
                                                    }
                                                }}
                                            />
                                            <span className="slider round pl-1"></span>
                                        </label>
                                    </div>
                                    <span className="mb-0 text-lightGray cursor-pointer f16 small" 
                                        onClick={() => setState(prevState => ({ ...prevState, allow_all: !state.allow_all }))}>Allow All</span>
                                </div>
                            </div>
                        </div>
                        
                        {!state.allow_all ?
                            <React.Fragment>
                            <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                                <div className='py-1 w-50'>
                                    <div className='d-flex justify-content-between'>
                                        {/* <p className="b-block mb-0 text-primary-color"></p> */}
                                        {/* {state.hasError && !state.roleId ?
                                            <span className='text-danger align-self-center'>required</span>
                                        : null} */}
                                    </div>
                                    <div className={`w-50 ${state.pageType === 'View' ? 'disabled' : ''}`} onClick={(event) => {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'muliselectDropDown', "showGroup")
                                    }}>
                                        <p className={`d-flex p-2 bg-transparent border-lightGray mb-0 rounded-5 d-flex justify-content-between ${state.groupId && state.groupId.length ? 'text-white' : 'placeholder'}`}>
                                            {state.groupId && state.groupId.length ? state.groupId.length + ' Selected' : 'Select'}
                                            <i className='fa fa-caret-down mt-1'/>
                                        </p>
                                        {state.showGroup && pageMode !== 'View' ?
                                            <div className='position-relative'>
                                                <MultiSelectSection 
                                                    fields={["group_id", "group_name"]}
                                                    removeTop={true}
                                                    options={state.groups}
                                                    selectedValues={state.groupId ? state.groupId : ''}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, groupId: value }))
                                                    }}
                                                    singleSelection={false}
                                                    hideSearch={true}
                                                    widthClass={'minWidth220'}
                                                />
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            </div>
                            {state.groupId && state.groupId.length && state.groups ?
                                state.groups.map((grp, grpIndex) => {
                                    return(
                                        state.groupId.includes(grp.group_id) ?
                                            <div key={'grp_'+grpIndex} className='p-3 mb-3 bg-dark3 rounded'>
                                                <div className='d-flex mt-2'>
                                                    <div className="py-1 w-50">
                                                        <p className="b-block mb-0">Name</p>
                                                        <p className="mb-0 text-white">
                                                            {grp.group_name ? grp.group_name : <span>&nbsp;</span>}
                                                        </p>
                                                    </div>
                                                </div>
                                                {grp.associations && grp.associations.map((gAss, gAssIndex) => {
                                                    return(
                                                        <div key={'gAss_'+gAssIndex} className='bg-dark2 p-3 mt-3 rounded'>
                                                            {Object.entries(gAss).map(([key, value], objIndex) => {
                                                                return(
                                                                    key !== 'project_tags' ?
                                                                        <div key={'pbj_'+objIndex} className={`${objIndex ? 'mt-2' : ''}`}>
                                                                            {key === 'tags' ?
                                                                                value && value.length ?
                                                                                    <div className='d-flex mt-2 pt-2'>
                                                                                        <div className="py-1 w-100">
                                                                                            <p className="b-block mb-0">{removeUnderScore(key)}</p>
                                                                                            <p className="mb-0 text-white">
                                                                                                {value.map((tag, tagIndex) => {
                                                                                                    return(
                                                                                                        <span key={'tag_'+tagIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key+' : '+tag.value}</span>
                                                                                                    )
                                                                                                })}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null
                                                                            : Array.isArray(value) ?
                                                                                value.every(item => typeof item === 'string') ?
                                                                                    <React.Fragment>
                                                                                    <p className="b-block mb-1 f16">{removeUnderScore(key)}</p>
                                                                                    {value.map((arr, arrIndex) => {
                                                                                        return(
                                                                                            <span key={'arr_'+arrIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-white px-2">{arr}</span>
                                                                                        )
                                                                                    })}
                                                                                    </React.Fragment>
                                                                                : value && value.length ?
                                                                                    <div className='d-flex mt-2 pt-2'>
                                                                                        <div className="py-1 w-100">
                                                                                            <p className="b-block mb-0">{removeUnderScore(key)}</p>
                                                                                            <p className="mb-0 text-white">
                                                                                                {value.map((tag, tagIndex) => {
                                                                                                    return(
                                                                                                        <span key={'tag_'+tagIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key+' : '+tag.value}</span>
                                                                                                    )
                                                                                                })}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                : null
                                                                            : typeof value === 'string' ?
                                                                                <p className="badge border-gray3 mr-2 mt-2 mb-0 align-self-center f14 text-white px-2"><span className='text-lightGray mr-1'>{removeUnderScore(key)} :</span>{value}</p>
                                                                            : typeof value === 'boolean' ?
                                                                                <p className="badge border-gray3 mr-2 mt-2 mb-0 align-self-center f14 text-white px-2"><span className='text-lightGray mr-1'>{removeUnderScore(key)} :</span>{value ? 'True' : 'False'}</p>
                                                                            : null}
                                                                        </div>
                                                                    : null

                                                                )
                                                            })}
                                                        </div>
                                                        // {gAss.project && gAss.project.length ?
                                                        //     <div className='d-flex mt-2 pt-2'>
                                                        //         <div className="py-1 w-100">
                                                        //             <p className="b-block mb-0">Projects</p>
                                                        //             <p className="mb-0 text-white">
                                                        //                 {gAss.project.map((proj, projIndex) => {
                                                        //                     return(
                                                        //                         <span key={'proj_'+projIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{proj}</span>
                                                        //                     )
                                                        //                 })}
                                                        //             </p>
                                                        //         </div>
                                                        //     </div>
                                                        // : null}
                                                        
                                                        // {gAss.project_tags && gAss.project_tags.length ?
                                                        //     <div className='d-flex mt-2 pt-2'>
                                                        //         <div className="py-1 w-100">
                                                        //             <p className="b-block mb-0">Tags</p>
                                                        //             <p className="mb-0 text-white">
                                                        //                 {gAss.project_tags.map((tag, tagIndex) => {
                                                        //                     return(
                                                        //                         <span key={'tag_'+tagIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{tag.key+' : '+tag.value}</span>
                                                        //                     )
                                                        //                 })}
                                                        //             </p>
                                                        //         </div>
                                                        //     </div>
                                                        // : null}
                                                        // </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        : null
                                    )
                                })
                            : null}
                            </React.Fragment>
                        : null}
                    </div>

                    <div className='bg-black rounded p-3 pb-5'>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <h5 className='mb-0 text-plum cursorPointer d-flex'>HPC Cluster Group Associations</h5>
                            </div>
                        </div>
                        
                        {state.groupArray.map((slmIndex, sIndex) => {
                            return(
                                <React.Fragment key={'prd_'+slmIndex}>
                                <div className={`d-flex flex-wrap ${slmIndex ? "mt-1 pt-1 border-mediumDarkGray-top":  ""}`}>
                                    <div className="py-1 w-20">
                                        <div className='d-flex justify-content-between mr-2'>
                                            <p className="b-block mb-0">Account</p>
                                            {state.hasError && !state["grpAccount_"+slmIndex] ?
                                                <span className='text-danger align-self-center'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "groupShowAccount_"+slmIndex)
                                        }}>
                                        <p className={`d-flex mr-2 mb-0 bg-transparent border-lightGray justify-content-between p-2 rounded-5 ${state["grpAccount_"+slmIndex] ? 'text-white' : 'placeholder'}`}>
                                            {state["grpAccount_"+slmIndex] ? getAccountNameFromId(state["grpAccount_"+slmIndex], state.providerAccounts) : 'Select'}
                                            <i className='fa fa-caret-down mt-1'/>
                                        </p>
                                        {state["groupShowAccount_"+slmIndex] && pageMode !== 'View' ?
                                            <div className='position-relative'>
                                                <MultiSelectSection 
                                                    fields={["account_id", "account_name"]}
                                                    className={'w-70'}
                                                    removeTop={true}
                                                    options={state.providerAccounts}
                                                    selectedValues={state["grpAccount_"+slmIndex] ? state["grpAccount_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, ["grpAccount_"+slmIndex]: value }))
                                                    }}
                                                    singleSelection={true}
                                                    hideSearch={false}
                                                />
                                            </div>
                                        : null}
                                        </div>
                                    </div>
                                    <div className="py-1 w-20">
                                        <div className='d-flex justify-content-between mr-2'>
                                            <p className="b-block mb-0">Region</p>
                                            {state.hasError && !state["grpRegion_"+slmIndex] ?
                                                <span className='text-danger align-self-center'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "groupShowRegion_"+slmIndex)
                                        }}>
                                        <p className={`d-flex mr-2 mb-0 bg-transparent border-lightGray justify-content-between p-2 rounded-5 ${state["grpRegion_"+slmIndex] ? 'text-white' : 'placeholder'}`}>
                                            {state["grpRegion_"+slmIndex] ? getRegionName(state["grpRegion_"+slmIndex], state.providerRegions) : 'Select'}
                                            <i className='fa fa-caret-down mt-1'/>
                                        </p>
                                        {state["groupShowRegion_"+slmIndex] && pageMode !== 'View' ?
                                            <div className='position-relative'>
                                                <MultiSelectSection 
                                                    fields={["region", "name"]}
                                                    className={'w-70'}
                                                    removeTop={true}
                                                    options={state.providerRegions}
                                                    selectedValues={state["grpRegion_"+slmIndex] ? state["grpRegion_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, 
                                                            ["grpRegion_"+slmIndex]: value,
                                                            fetchingCluster: true
                                                        }))
                                                        getSlurmClusterDetailsFunction('aws', state["grpAccount_"+slmIndex], value, slmIndex)
                                                    }}
                                                    singleSelection={true}
                                                    hideSearch={false}
                                                />
                                            </div>
                                        : null}
                                        </div>
                                    </div>
                                    
                                    <div className="py-1 w-20">
                                        <div className='d-flex justify-content-between mr-2'>
                                            <p className="b-block mb-0">Cluster</p>
                                            {state.hasError && !state["grpCluster_"+slmIndex] ?
                                                <span className='text-danger align-self-center'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "grpShowCluster_"+slmIndex)
                                        }}>
                                        <p className={`d-flex mr-2 mb-0 bg-transparent border-lightGray justify-content-between p-2 rounded-5 ${state["grpCluster_"+slmIndex] ? 'text-white' : 'placeholder'}`}>
                                            {state["grpCluster_"+slmIndex] ? state["grpCluster_"+slmIndex] : 'Select'}
                                            <i className='fa fa-caret-down mt-1'/>
                                        </p>
                                        {state["grpShowCluster_"+slmIndex] && pageMode !== 'View' && !state.fetchingCluster ?
                                            <div className='position-relative'>
                                                <MultiSelectSection 
                                                    // fields={["account_id", "account_name"]}
                                                    className={'w-70'}
                                                    removeTop={true}
                                                    options={state['groupClustersOptions_'+slmIndex]}
                                                    selectedValues={state["grpCluster_"+slmIndex] ? state["grpCluster_"+slmIndex] : []}
                                                    callbackMultiSelect={(value) => {
                                                        setState(prevState => ({ ...prevState, 
                                                            ["grpCluster_"+slmIndex]: value,
                                                            fetchingSlurmAccount: true
                                                        }))
                                                        getSlurmAccountDetails('aws', state["grpAccount_"+slmIndex], state["grpRegion_"+slmIndex], value, slmIndex)
                                                    }}
                                                    singleSelection={true}
                                                    hideSearch={false}
                                                />
                                            </div>
                                        : null}
                                        </div>
                                    </div>
                                    <div className="py-1 w-20 mb-3">
                                        <p className="b-block mb-0">Group</p>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'muliselectDropDown', "showGroup_"+slmIndex)
                                        }}>
                                            <p className={`d-flex mr-2 mb-0 bg-transparent border-lightGray justify-content-between p-2 rounded-5 ${state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length ? 'text-white' : 'placeholder'}`}>
                                                {state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length ? state["selectedGroup_"+slmIndex].length+' Selected' : 'Select'}
                                                <i className='fa fa-caret-down mt-1'/>
                                            </p>
                                            
                                            {state["showGroup_"+slmIndex] && pageMode !== 'View' && !state.fetchingSlurmAccount ?
                                                <div className='position-relative'>
                                                    <MultiSelectSection 
                                                        fields={["account", "account"]}
                                                        className={'w-70'}
                                                        removeTop={true}
                                                        options={state["groupList_"+slmIndex]}
                                                        selectedValues={state["selectedGroup_"+slmIndex] ? state["selectedGroup_"+slmIndex] : []}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState, ["selectedGroup_"+slmIndex]: value }))
                                                        }}
                                                        singleSelection={false}
                                                        hideSearch={false}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    
                                    {state["selectedGroup_"+slmIndex] && state["selectedGroup_"+slmIndex].length ? 
                                        state["selectedGroup_"+slmIndex].filter(e => e.value !== "All").map((reg, regIndex) => {
                                            return(
                                                <span key={'slurmReg_'+regIndex} className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">{reg}
                                                    {!state.editMode ?
                                                        <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeSlurmAccount(reg, slmIndex) } ></i>
                                                    : null}
                                                </span>
                                            )
                                        })
                                    : null}
                                </div>
                                <div className='d-flex mt-1 mb-3'>
                                    {pageMode !== 'View' ?
                                        <React.Fragment>
                                        {state.groupArray.length === sIndex+1 ?
                                            <span className={`align-self-center mr-3 cursor-pointer text-info`} onClick={() => addFilterArray('groupArray')} >
                                                {/* <i className={`far fa-plus f16 mt-2 mb-1 align-self-end mr-2`}></i> */} 
                                                Add
                                            </span>
                                        : null}
                                        {/* {state.groupArray.length > 1 ? */}
                                            <span className={`align-self-center mr-2 cursor-pointer text-danger`} onClick={() => removeFilterArray('groupArray', sIndex, slmIndex)} >Remove
                                            </span>
                                        {/* : null} */}
                                        </React.Fragment>
                                    : null}
                                </div>
                                </React.Fragment>
                            )
                        })}
                    </div>

                    <div className='fixed-buttons justify-content-end bg-transparent border-0 pb-0'>
                        <div className={`d-flex justify-content-end bg-muted py-2 w-70`}>
                            {state.saveLoading ?
                                <button className='btn btn-info'>
                                    <Spinner size='sm' color='light' className='mr-2' />{pageMode === 'Edit' ? 'Updating' : 'Saving'}
                                </button>
                            :  pageMode !== 'View' ? 
                                    <button className={`d-flex justify-content-end cursorPointer btn bg-info text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))}>{pageMode === 'Edit' ? 'Update' : 'Save'}</button>
                            : null}
                            <button className={`ml-2 d-flex justify-content-end cursorPointer btn btn-light`} onClick={ () => closeSidePanel()}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateUpdateUserPanel