import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { Input } from "reactstrap";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../common/MultiSelectSection";

const AuthenticationSection = (props) => {
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const [state, setState] = useState({});

  const addNewKeyValue = () => {
    let selectedKeys = state.selected_keys ? state.selected_keys : {};

    if (state.key && state.key !== "" && state.value && state.value.length) {
      selectedKeys[state.key] = state.value;
    }

    setState((prevState) => ({
      ...prevState,
      selected_keys: selectedKeys,
      key: "",
      value: "",
    }));

    handleDirectoryInput("", selectedKeys, "additional_sssd_configs");
  };

  const removeKeyValue = (key) => {
    let newSelectedKeys = state.selected_keys;
    delete newSelectedKeys[key];
  
    setState((prevState) => ({
      ...prevState,
      selected_keys: newSelectedKeys,
    }));
  };

  const handleDirectoryInput = (label, value, field) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let directoryConfiguration = obj.directory_configuration
      ? obj.directory_configuration
      : {};
    if (field === "additional_sssd_configs") {
      // let additionalSSdConfigs = directoryConfiguration.additional_sssd_configs ? directoryConfiguration.additional_sssd_configs : []
      // additionalSSdConfigs[label] = value
      directoryConfiguration["additional_sssd_configs"] = value;
    } else {
      // if(label !== 'password_secret_arn') {
      directoryConfiguration[label] = value;
      // }
    }

    obj["directory_configuration"] = directoryConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div
      className="rounded bg-dark p-3"
      onClick={(event) => handleChildClick(event, "parent")}
    >
      <div className={"d-flex"}>
        <div className="py-1 w-100">
          <div className="w-33">
            <p className="b-block mb-0">Domain name</p>
            <Input
              type="text"
              placeholder="Enter name"
              className={`inputTextbox bg-transparent border-lightGray text-white`}
              // maxlength='128'
              value={
                createUpdateCluster &&
                createUpdateCluster.directory_configuration &&
                createUpdateCluster.directory_configuration.domain_name
                  ? createUpdateCluster.directory_configuration.domain_name
                  : ""
              }
              onChange={(e) =>
                handleDirectoryInput("domain_name", e.target.value)
              }
            />
          </div>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.directory_configuration &&
          propsClusterFilters.description.directory_configuration
            .domain_name ? (
            <p className="mt-1 mb-0 text-info">
              {
                propsClusterFilters.description.directory_configuration
                  .domain_name
              }
            </p>
          ) : null}
        </div>
      </div>
      <div className={"d-flex mt-3"}>
        <div className="py-1 w-100">
          <div className="w-33">
            <p className="b-block mb-0">Domain address</p>
            <Input
              type="text"
              placeholder="Enter address"
              className={`inputTextbox bg-transparent border-lightGray text-white`}
              // maxlength='128'
              value={
                createUpdateCluster &&
                createUpdateCluster.directory_configuration &&
                createUpdateCluster.directory_configuration.domain_address
                  ? createUpdateCluster.directory_configuration.domain_address
                  : ""
              }
              onChange={(e) =>
                handleDirectoryInput("domain_address", e.target.value)
              }
            />
          </div>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.directory_configuration &&
          propsClusterFilters.description.directory_configuration
            .domain_address ? (
            <p className="mt-1 mb-0 text-info">
              {
                propsClusterFilters.description.directory_configuration
                  .domain_address
              }
            </p>
          ) : null}
        </div>
      </div>
      <div className={"d-flex mt-3"}>
        <div className="py-1 w-100">
          <div className="w-33">
            <p className="b-block mb-0">
              Password secret amazon resource name (ARN)
            </p>
            <Input
              type="text"
              placeholder="Enter secret arn"
              className={`inputTextbox bg-transparent border-lightGray text-white`}
              // maxlength='128'
              value={
                createUpdateCluster &&
                createUpdateCluster.directory_configuration &&
                createUpdateCluster.directory_configuration.password_secret_arn
                  ? createUpdateCluster.directory_configuration
                      .password_secret_arn
                  : ""
              }
              onChange={(e) =>
                handleDirectoryInput("password_secret_arn", e.target.value)
              }
            />
          </div>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.directory_configuration &&
          propsClusterFilters.description.directory_configuration
            .password_secret_arn ? (
            <p className="mt-1 mb-0 text-info">
              {
                propsClusterFilters.description.directory_configuration
                  .password_secret_arn
              }
            </p>
          ) : null}
        </div>
      </div>
      <div className={"d-flex mt-3"}>
        <div className="py-1 w-100">
          <div className="w-33">
            <p className="b-block mb-0">Domain read only user</p>
            <Input
              type="text"
              placeholder="Enter address"
              className={`inputTextbox bg-transparent border-lightGray text-white`}
              // maxlength='128'
              value={
                createUpdateCluster &&
                createUpdateCluster.directory_configuration &&
                createUpdateCluster.directory_configuration
                  .domain_read_only_user
                  ? createUpdateCluster.directory_configuration
                      .domain_read_only_user
                  : ""
              }
              onChange={(e) =>
                handleDirectoryInput("domain_read_only_user", e.target.value)
              }
            />
          </div>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.directory_configuration &&
          propsClusterFilters.description.directory_configuration
            .domain_read_only_user ? (
            <p className="mt-1 mb-0 text-info">
              {
                propsClusterFilters.description.directory_configuration
                  .domain_read_only_user
              }
            </p>
          ) : null}
        </div>
      </div>
      <div className={"d-flex mt-3"}>
        <div className="py-3 w-100">
          <p className="b-block mb-0">Additional SSSD options</p>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.directory_configuration &&
          propsClusterFilters.description.directory_configuration
            .additional_sssd_configs ? (
            <p className="mb-1 text-info">
              {
                propsClusterFilters.description.directory_configuration
                  .additional_sssd_configs
              }
            </p>
          ) : null}
          <div className="d-flex mb-1">
            <Input
              type="text"
              placeholder="Key"
              className={`inputTextbox bg-transparent border-lightGray text-white w-30`}
              // maxlength='128'
              value={state.key ? state.key : ""}
              onChange={(e) =>
                setState((prevState) => ({ ...prevState, key: e.target.value }))
              }
            />
            <Input
              type="text"
              placeholder="value"
              className={`inputTextbox bg-transparent border-lightGray text-white w-40 ml-3`}
              // maxlength='128'
              value={state.value ? state.value : ""}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  value: e.target.value,
                }))
              }
              // onChange={e => handleDirectoryInput('value', e.target.value, 'additional_sssd_configs')}
            />
            <span
              className={`far fa-plus cursor-pointer f18 mt-2 mb-1 align-self-center ml-3`}
              onClick={() => addNewKeyValue()}
            ></span>
          </div>

          {createUpdateCluster &&
          createUpdateCluster.directory_configuration &&
          createUpdateCluster.directory_configuration.additional_sssd_configs &&
          Object.entries(
            createUpdateCluster.directory_configuration.additional_sssd_configs
          ).length ? (
            <div className="d-flex flex-wrap">
              {Object.entries(
                createUpdateCluster.directory_configuration
                  .additional_sssd_configs
              ).map(([key, value], tIndex) => {
                return (
                  <span
                    className="d-flex badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2 breakWhiteSpace"
                    id={"row_" + tIndex}
                  >
                    <div
                      className="hiddenEllipses"
                      style={{ maxWidth: "250px" }}
                    >
                      {key + " : " + value}
                    </div>
                    {props.pageMode === "Create" ? (
                      <i
                        className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                        onClick={() => removeKeyValue(key, value)}
                      ></i>
                    ) : null}
                  </span>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      <div className={"d-flex mt-3"}>
        <div className="py-1 w-100">
          <div className="w-33">
            <div className="d-flex justify-content-between">
              <label className="align-self-center m-0">
                Request certificate
              </label>
              {state.hasError && state.os ? (
                <small className="text-issues">required</small>
              ) : null}
            </div>
            <div
              className="border-lightGray bg-transparent rounded-5"
              onClick={(event) => {
                if (!state.showRequestCertificate) {
                  event.preventDefault();
                  handleChildClick(
                    event,
                    "child",
                    "singleDropDown",
                    "showRequestCertificate"
                  );
                }
              }}
            >
              <p
                className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${
                  createUpdateCluster &&
                  createUpdateCluster.directory_configuration &&
                  createUpdateCluster.directory_configuration
                    .ldap_tls_request_certificate
                    ? "text-white hiddenEllipses"
                    : "placeholder"
                }`}
              >
                {createUpdateCluster &&
                createUpdateCluster.directory_configuration &&
                createUpdateCluster.directory_configuration
                  .ldap_tls_request_certificate
                  ? createUpdateCluster.directory_configuration
                      .ldap_tls_request_certificate
                  : "Select"}
                <i className="fa fa-caret-down mt-1" />
              </p>
              {state.showRequestCertificate ? (
                <MultiSelectSection
                  // fields={["value", "key"]}
                  options={
                    propsClusterFilters &&
                    propsClusterFilters.ldap_tls_request_certificate
                      ? propsClusterFilters.ldap_tls_request_certificate
                      : []
                  }
                  selectedValues={
                    createUpdateCluster &&
                    createUpdateCluster.directory_configuration &&
                    createUpdateCluster.directory_configuration
                      .ldap_tls_request_certificate
                      ? createUpdateCluster.directory_configuration
                          .ldap_tls_request_certificate
                      : ""
                  }
                  callbackMultiSelect={(value) => {
                    if (!value || typeof value === "string") {
                      setState((prevState) => ({
                        ...prevState,
                        ldap_tls_request_certificate: value,
                      }));
                      handleDirectoryInput(
                        "ldap_tls_request_certificate",
                        value
                      );
                    } else {
                      value.preventDefault();
                      handleChildClick(value, "search", "singleDropDown", "");
                    }
                  }}
                  singleSelection={true}
                  hideSearch={false}
                  topClass={"auto"}
                  widthClass={"minWidth220"}
                />
              ) : null}
            </div>
          </div>
        </div>
        {/* <div className='py-1 w-33'>
                    <p className='b-block mb-0'>Request certificate</p>
                    <Input
                        type='text'
                        placeholder='Enter certificate'
                        className={`inputTextbox bg-transparent border-lightGray text-white`}
                        // maxlength='128'``
                        value={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.ldap_tls_request_certificate ? createUpdateCluster.directory_configuration.ldap_tls_request_certificate : ''}
                        onChange={e => handleDirectoryInput('ldap_tls_request_certificate', e.target.value)}
                    />
                </div> */}
      </div>
      {/* <div className={'d-flex mt-3'}>
                <div className='py-1 w-100 align-self-center'>
                    <div className='d-flex'>
                        <label className="mb-0">
                            <input type="checkbox" 
                                className="inputdemo mt-1" 
                                checked={createUpdateCluster && createUpdateCluster.directory_configuration && createUpdateCluster.directory_configuration.generate_ssh_keys_for_users ? true : false}
                                onChange={() => {
                                    if(props.pageMode !== 'Edit') {
                                        let generate_ssh_keys_for_users = !state.generate_ssh_keys_for_users
                                        // setState(prevState => ({ ...prevState, generate_ssh_keys_for_users: !state.generate_ssh_keys_for_users}))
                                        handleDirectoryInput("generate_ssh_keys_for_users", generate_ssh_keys_for_users)
                                    }
                                }}
                            />
                        </label>
                        <span className="mb-0 text-lightGray f16 ml-2">Disable simultaneous multitreading</span>
                    </div>
                </div>
            </div> */}
    </div>
  );
};

export default AuthenticationSection;
