/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 26/09/2023
 * @copyright © 2023 Collider. All rights reserved.
 * 
 *************************************************/
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { getAccountNameFromId, momentConvertionUtcToLocalTime } from '../../../utils/utility';
import { listLifecyclePolicies, deleteLifecyclePolicies } from '../../../actions/CatalogAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchWithHiddenInput'
import { Spinner } from 'reactstrap'
import ViewSidePanel from './ViewSidePanel';
 import { Store as CommonNotification } from 'react-notifications-component';
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import MultiSelectSection from '../../common/MultiSelectSection';


const LandingPage = ()  => {
    // State variables
    const [state, setState] = useState({
        showLoading: true,
        selectedAccount: [],
        isAccountOpen: false,
        selectedRegion: [],
        isRegionOpen: false,
        emptyOptions: [],
        category: [ 
            {label: "Asset", value: "asset"},
            {label: "Service", value: "service_name"},
            {label: "Tags", value: "tags"}
        ],
        selectedCategory: "asset",
        dynamicHeaders: [],
        topSectionFilters: ['provider', 'account', 'region'],
        policyTypes: ["Asset", "Service", "Tags"],
        selectedPolicy: 'Asset'
    });

    const dispatch = useDispatch(); // Create a dispatch function
	const location = useLocation()
    const currentPath = location.pathname;

    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
					if(row.action) {
						actionsAllowed = row.action
					}
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])
    
    // list all providers
    useEffect(() => {
        if (providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                if(response) {
                    setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '', callSearch: true  }));
                }
                })
        }
    }, [dispatch, providers]);  

	// Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false, dynamicHeaders: [], lifeCyclePolicies: [], filteredPolicies: [] }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            if(state.selectedCategory && state.selectedCategory !== "All") {
                params.category = state.selectedCategory
            }
            if(state.selectedPolicy) {
                params.policy_type = state.selectedPolicy
            }

            dispatch(listLifecyclePolicies(params))
                .then((response) => {
                    setState(prevState => ({ ...prevState, lifeCyclePolicies: response && response.results ? response.results : [], filteredPolicies: response && response.results ? response.results : [], showLoading: false, formHeaders: true }))
                })
        }
    }, [state.callSearch, dispatch, state.selectedAccount, state.selectedCategory, state.selectedProvider, state.selectedRegion, state.selectedPolicy])

    const onClickDelete = useCallback((row) => {
        
        let params = {}
        params.policy_id = row.policy_id
        dispatch(deleteLifecyclePolicies(params))
            .then((response) => { 
                let messageType = "danger"
                if(response.status) {
                    messageType = 'success'
                }
                if(response.status) {
                    setState(prevState => ({ ...prevState, disableSubmit: false, callSearch: true }))
                }

                let message = response && response.message ? response.message : 'Error in deleting lifecycle policy '
                
                CommonNotification.addNotification({
                    message: message,
                    type: messageType,
                    insert: "top",
                    container: "top-center",
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })
    }, [dispatch])

    useEffect(() => {
        if(state.formHeaders) {
            setState(prevState => ({ ...prevState, formHeaders: false }))
            let columns = []
            let dataRow = {}
            dataRow.Header = 'Date'
            // dataRow.accessor = 'created_at'
            dataRow.Cell = cellInfo => (
                <React.Fragment>
                    <span>{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, "DD MMM YYYY HH:mm") : cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, "DD MMM YYYY HH:mm") : ""}</span>
                </React.Fragment>
            )
            dataRow.width = 180
            columns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Provider'
            dataRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
            dataRow.Cell = cellInfo => (
                <React.Fragment>
                <span className=''>
                    {cellInfo.row.original.provider.toUpperCase() + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
                </span>
                </React.Fragment>
            )
            dataRow.width = 250
            columns.push(dataRow)

            dataRow = {}
            dataRow.Header = 'Resource'
            dataRow.accessor = 'resource_type'
            // dataRow.accessor = d => d.resource_type + ' : ' + d.service_name
            // dataRow.Cell = cellInfo => (
            //     <span className=''>{cellInfo.row.original.resource_type + ' : ' + cellInfo.row.original.service_name}
            //     </span>
            // )
            dataRow.width = 250
            columns.push(dataRow)

            if(state.selectedPolicy === 'Asset') {
                dataRow = {}
                dataRow.Header = 'Asset'
                dataRow.accessor = 'asset_name'		
                dataRow.width = 300
                columns.push(dataRow)
            } else if(state.selectedPolicy === 'Service') {
                dataRow = {}
                dataRow.Header = 'Service'
                dataRow.accessor = 'service_name'		
                dataRow.width = 300
                columns.push(dataRow)
            } else if(state.selectedPolicy === 'Tags') {
                dataRow = {}
                dataRow.Header = 'Tags'
                dataRow.accessor = 'tags'
                dataRow.Cell = cellInfo => (
                    <div className='d-flex'>
                        <span className=''>{cellInfo.row.original.tags && cellInfo.row.original.tags.length ? cellInfo.row.original.tags[0].key + ' : ' + cellInfo.row.original.tags[0].value : ''}
                        </span>
                        {cellInfo.row.original.tags && cellInfo.row.original.tags.length > 1 ?
                            <small className='ml-1 text-info'>more</small>
                        : null}
                    </div>
                )
                dataRow.width = 300
                columns.push(dataRow)
            }

            // if(state.actionsAllowed && (state.actionsAllowed.includes('update') || state.actionsAllowed.includes('delete'))) {
                dataRow  = {}
                dataRow.Header = 'Action'
                // dataRow.accessor = 'description'
                dataRow.Cell = cellInfo => (
                    <div className="d-flex">
                        {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                            <p className="m-0 p-0 mr-1 cursorPointer" onClick={() => setState(prevState => ({ ...prevState, selectedDetails: cellInfo.row.original }))}><i className="fas fa-pencil"></i></p>
                        : null}
                        {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                            <p className="m-0 p-0 mx-1 cursorPointer" onClick={(event) => {
                                event.stopPropagation();
                                onClickDelete(cellInfo.row.original)
                            }}><i className="fal fa-trash"></i></p>
                        : null}
                    </div>
                )
                dataRow.width = 150
                columns.push(dataRow)
            // }

            dataRow = {}
            dataRow.Header = 'Description'
            dataRow.accessor = 'description'		
            dataRow.width = 320
            columns.push(dataRow)
            

            setState(prevState => ({ ...prevState, dynamicHeaders: columns }))
        }
    }, [state.formHeaders, state.accounts, state.selectedPolicy, onClickDelete, state.actionsAllowed])

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: '', selectedAccount: [], selectedRegion: [], callSearch: true }));
    };
    
    const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
    }

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            
            {(state.selectedDetails && Object.entries(state.selectedDetails).length) || state.createLifecycle ? 
                <ViewSidePanel 
                    closeSidePanel={() => setState(prevState => ({...prevState, selectedDetails: {}, showLoading: false, createLifecycle: false }))}
                    selectedDetails={state.createLifecycle ? {} : state.selectedDetails}
                    refreshlist={() => {
                        setState(prevState => ({...prevState, callSearch: true }))
                    }}
                    pageType={state.createLifecycle ? "Create" : "Modify"}
                />
            : null}
            
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-4 col-5 py-2'>
                        <h6 className='text-white m-0'>Lifecycle</h6>
                        <p className='text-white m-0'>Unified view of Metrics, Alerts, and Events in your Cloud HPC</p>
                    </div>
                    <div className='col-lg-8 col-md-12 col-sm-12 py-2'>
                        <div className='row mx-0 justify-content-end'>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                                onClick={(event) => {
                                    if(!state.isProviderOpen) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                                    }
                                }}
                            >
                                <label className='mb-1 mt-1 f10 d-flex'>Provider</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedProvider ? state.selectedProvider : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isProviderOpen ?
                                    <MultiSelectSection 
                                        fields={["provider_name", "provider_name"]}
                                        options={state.providers}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, selectedProvider: value, selectedAccount: [], selectedRegion: [] }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                    />
                                : null}
                            </div>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}} 
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
                                }}
                            >
                                <label className='mb-2 mt-1 f10 d-flex'>Account</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isAccountOpen ?
                                    <MultiSelectSection 
                                        fields={["account_id", "account_name"]}
                                        options={state.accounts}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, selectedAccount: value }))
                                        }}
                                    />
                                : null}
                            </div>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '120px', maxWidth: '120px'}}
                                onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
                                }}
                            >
                                <label className='mb-2 mt-1 f10 d-flex'>Region</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.isRegionOpen ?
                                    <MultiSelectSection 
                                        fields={["region", "name"]}
                                        options={state.regions}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
                                    />
                                : null}
                            </div>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '150px', maxWidth: '150px'}}
                                onClick={(event) => {
                                    if(!state.showPolicyType) {
                                        event.preventDefault();
                                        handleChildClick(event, 'child', 'singleDropDown', "showPolicyType")
                                    }
                                }}
                            >
                                <label className='mb-1 mt-1 f10 d-flex'>Polict Type</label>
                                <p className={`d-flex justify-content-between mt-2 mb-0 hiddenEllipses ${state.selectedPolicy ? 'text-white' : 'placeholder'}`}>
                                    {state.selectedPolicy ? state.selectedPolicy : 'Select'}
                                    <i className='fa fa-caret-down mt-1 mr-1'/>
                                </p>
                                {state.showPolicyType ?
                                    <MultiSelectSection 
                                        // fields={["value", "label"]}
                                        options={state.policyTypes}
                                        widthClass={'minWidth220'}
                                        selectedValues={state.selectedPolicy ? state.selectedPolicy : ''}
                                        callbackMultiSelect={(value) => {
                                            if(!value || typeof(value) === 'string') {
                                                setState(prevState => ({ ...prevState, selectedPolicy: value }))
                                            } else {
                                                value.preventDefault()
                                                handleChildClick(value, "search", 'singleDropDown', "")
                                            }
                                        }}
                                        singleSelection={true}
                                    />
                                : null}
                            </div>
                            
                            <div className={`ml-3 align-self-center`}>
                                <div className='btn-group' role='group' aria-label='Basic example'>
                                    <button type='button' className={`cursorPointer btn bg-cyon text-white border-right text-white`} onClick={() => setState(prevState => ({ ...prevState, callSearch: true }))}>Search</button>
                                    <button type='button' className={`cursorPointer btn bg-cyon text-white border-left text-white`} onClick={()=> onReset()}><i className='far fa-redo'/></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-lg-8 col-6 py-2'>
                        <div className='row mx-0 d-flex justify-content-end'>
                            <div className='col-2 pl-0'>
                                <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`}>
                                    <label className='mb-1 f10'>Provider</label>
                                    <Select
                                        placeholder={'Select'}
                                        isSearchable={false}
                                        className='f13 p-0 colorBlack reactSelectDrop'
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                        value={{
                                            value: state.selectedProvider && state.selectedProvider !== '' ? state.selectedProvider : 'Select',
                                            label: state.selectedProvider && state.selectedProvider !== '' ? state.selectedProvider : <span className='placeholder'>Select</span>
                                        }}
                                        options={state.providers && state.providers.length && state.providers.map(row => ({
                                            value: row.provider_name,
                                            label: row.provider_name,
                                        }))}
                                        onChange={event => {
                                            setState(prevState => ({ ...prevState, selectedProvider: event.value, changesInFilter: true, selectedAccount: [], selectedRegion: [] }))
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='col-2 pl-0'>
                                <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} ref={accountRef}>
                                    <label className='mb-2 mt-1 f10 d-flex'>Account</label>
                                    <p className={`d-flex mt-2 mb-0 ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</p>
                                    {state.isAccountOpen ?
                                        <MultiSelectSection 
                                            fields={["account_id", "account_name"]}
                                            options={state.accounts}
                                            selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value }))}
                                        />
                                    : null}
                                </div>
                            </div>                            
                            <div className='col-2 pl-0'>
                                <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} ref={regionRef}>
                                    <label className='mb-2 mt-1 f10 d-flex'>Region</label>
                                    <p className={`d-flex mt-2 mb-0 ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</p>
                                    {state.isRegionOpen ?
                                        <MultiSelectSection 
                                            fields={["region", "name"]}
                                            options={state.regions}
                                            selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
                                        />
                                    : null}
                                </div>
                            </div>                            
                            <div className='col-2 pl-0 displayNone'>
                                <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`}>
                                    <label className='mb-1 f10'>Show By</label>
                                    <Select
                                        placeholder={'Select'}
                                        isSearchable={false}
                                        className='f13 p-0 colorBlack reactSelectDrop'
                                        components={{
                                            IndicatorSeparator: () => null,
                                        }}
                                        value={{
                                            value: state.selectedCategory ? state.selectedCategory : 'Select',
                                            label: state.selectedCategory ? state.category.filter(e => e.value === state.selectedCategory)[0].label : <span className='placeholder'>Select</span>
                                        }}
                                        options={state.category && state.category.map(row => ({
                                            value: row.value,
                                            label: row.label,
                                        }))}
                                        onChange={event => {
                                            setState(prevState => ({ ...prevState, selectedCategory: event.value, changesInFilter: true }))
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='text-right align-self-center '>
                                <div className={`ml-3`}>
                                    <div className={`d-flex justify-content-center`}>
                                        <div className='btn-group' role='group' aria-label='Basic example'>
                                            <button type='button' className={`cursorPointer btn bg-cyon text-white border-right text-white`} onClick={() => setState(prevState => ({ ...prevState, callSearch: true, showLoading: false }))}>Search</button>
                                            <button type='button' className={`cursorPointer btn bg-cyon text-white border-left text-white`} onClick={onReset}><i className='far fa-redo'/></button>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div> */}
                    
                </div>
            </div>
            <div className='row h-100'>
                <div className='col-sm-12 p-0'>
                    <div className='px-3'>
                        {!state.showLoading ?
                            <div className="container-fluid">
                                <div className="row mt-2">
                                    <div className="col-sm-7 align-self-center normalGrayDropdown"></div>
                                    <div className={`d-flex col-sm-5 justify-content-end mb-0 align-self-center`}>
                                        {state.lifeCyclePolicies && state.lifeCyclePolicies.length ?
                                            <Search
                                                data={state.lifeCyclePolicies ? state.lifeCyclePolicies : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-white f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white form-control-sm f12"}
                                                filteredData={filteredPolicies => {
                                                    setState(prevState => ({ ...prevState, filteredPolicies }))
                                                }}
                                            />
                                        : null}
                                        {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                            <button className={`btn bg-green p-1 text-white ml-2`} onClick={() => setState(prevState => ({ ...prevState, createLifecycle: true }))}>Create&nbsp;Policy</button>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        : null}
                        <div className='mt-2 px-3' id='myTabContent'>
                            {state.filteredPolicies && state.filteredPolicies.length && !state.showLoading ? (
                                <ResizeableDarkThemeTable
                                    columns={state.dynamicHeaders}
                                    perPage={20}
                                    tableHead={'bg-black text-white'}
                                    tableBody={'bg-dark3 text-white'}
                                    tableType={'table-dark-hover-none'}
                                    perPageClass={`bg-transparent text-lightGray`}
                                    textColor={'text-black'}
                                    selectedColor={'bg-transparent'}
                                    dashboard={state.filteredPolicies && state.filteredPolicies.length ? true : false}
                                    sortByColumn={''}
                                    riskTooltip={[0]}
                                    onClickRow={tableRow => {}}
                                    data={state.filteredPolicies}
                                    selectedRecord={state.selectedTableRow}
                                />
                            ) : (!state.showLoading && state.filteredPolicies && !state.filteredPolicies.length) ||
                            (state.filteredPolicies && !state.filteredPolicies.length && !state.showLoading) ? (
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                </div>
                            ) : null}
                            {state.showLoading ? (
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='light' />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage