/*************************************************
 * Collider
 * @exports
 * @file Table.js
 * @author Rajasekar // on 30/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	useTable,
	useBlockLayout,
	useResizeColumns,
	usePagination,
	useSortBy,
	useFilters,
	useGlobalFilter,
} from 'react-table'
import { UncontrolledTooltip } from 'reactstrap'

// export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
// 	// const count = preGlobalFilteredRows.length
// 	return (
// 		<div className='input-group dark-feild mb-2 mr-sm-2'>
// 			<div className='input-group-prepend'>
// 				<div className='input-group-text bg-transparent'>
// 					<i className='fal fa-search' />
// 				</div>
// 			</div>
// 			<input
// 				value={globalFilter || ''}
// 				onChange={e => {
// 					setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
// 				}}
// 				type='text'
// 				className='form-control'
// 				placeholder='Search'
// 			/>
// 		</div>
// 		// <span>
// 		// 	Search:{' '}
// 		// 	<input
// 		// 		value={globalFilter || ''}
// 		// 		onChange={e => {
// 		// 			setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
// 		// 		}}
// 		// 		placeholder={`${count} records...`}
// 		// 		style={{
// 		// 			fontSize: '1.1rem',
// 		// 			border: '0',
// 		// 		}}
// 		// 	/>
// 		// </span>
// 	)
// }

const toolTipModifiers = {
	flip: { behavior: ['top'] },
	preventOverflow: { boundariesElement: 'viewport' },
}

function Table({ columns, data, onClickRow, dashboard, tooltipFor, riskTooltip = false, perPage, borderRadius, borderSubSection, fontSize=12, manualWidth = false, hideRowsPerPageSection = false, tableHead = false, tableBody = false, tableType = false, perPageClass=false, textColor = false, selectedColor = false, tableSize = false }) {
	const [selectedRowIndex, setSelectedRowIndex] = useState(0)

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// preGlobalFilteredRows,
		// setGlobalFilter,
		// state,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0, pageSize: perPage },
		},
		useBlockLayout,
		useResizeColumns,
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	)

	return (
		<React.Fragment>
			<div className={`p-0 ${borderRadius ? '' : 'borderRadius0'} ${dashboard ? '' :  borderSubSection === '' ? '' : 'borderSubSection pb-4'} `}>
				{/* <GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
			/> */}
				<div className=''>
					<table {...getTableProps()} 
					
					className={`table mb-1
						${tableSize ? tableSize : 'table-sm'} 
						${tableType ? tableType : 'table-hover'} 
						${textColor ? textColor : 'text-white'} 
					`}>
						<thead className={`${tableHead ? tableHead : ''}`}>
							{headerGroups.map((headerGroup, i) => (
								<tr key={i} className='bg-primary w-100' {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column, index) => (
										<th className={`bg-primary fs-13 ${!manualWidth ? 'w-100' : ''} `} key={index} {...column.getHeaderProps()}>
											<span
												{...column.getSortByToggleProps({ title: 'Sort' })}
												style={{ marginRight: 10, cursor: 'pointer' }}
											>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<React.Fragment>
															<i className='fas fa-sort-down' /> {column.render('Header')}
														</React.Fragment>
													) : (
														<React.Fragment>
															<i className='fas fa-sort-up' /> {column.render('Header')}
														</React.Fragment>
													)
												) : (
													column.render('Header')
													// <React.Fragment>
													// 	<i className='fas fa-sort' /> {column.render('Header')}
													// </React.Fragment>
												)}
											</span>
											{headerGroup.headers.length - 1 === index ? null : (
												<div {...column.getResizerProps()} className={`resizer`} />
											)}
										</th>
									))}
								</tr>
							))}
						</thead>
						<tbody className={`${tableBody ? tableBody : ''}`} {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row)
								return (
									<tr
										className={`${selectedRowIndex === i ? (selectedColor ? selectedColor : 'bg-light') : ''} w-100 ${!manualWidth ? 'w-100' : 'w-100'}`}
										key={i}
										{...row.getRowProps()}
										onClick={() => {
											onClickRow(row.original)
											setSelectedRowIndex(i)
										}}
									>
										{row.cells.map((cell, index) => {
											return (
												<React.Fragment key={index}>
													{tooltipFor && tooltipFor.includes(index) && (
														<UncontrolledTooltip
															key={index}
															placement='top'
															modifiers={toolTipModifiers}
															target={`customIdNotify${i}${index}`}
														>
															{cell.render('Cell')}
														</UncontrolledTooltip>
													)}													
													{riskTooltip && riskTooltip.includes(index) && (
														<UncontrolledTooltip
															key={index}
															placement='top'
															modifiers={toolTipModifiers}
															target={`customIdNotify${i}${index}`}
														>
															{cell.row.values.risk}
														</UncontrolledTooltip>
													)}
													<td
														key={i}
														className={`${fontSize === 9 ? 'f9' : 'fs-12' } ${!manualWidth ? 'w-100' : ''} w-100`} {...cell.getCellProps()} id={`customIdNotify${i}${index}`}
													>
														{/* {moment(cell.render('Cell').props.cell.value).isValid()
														? cell.render('Cell')
														: cell.render('Cell').props.cell.value && cell.render('Cell').props.cell.value.length > 15
														? cell.render('Cell').props.cell.value.substring(0, 15) + '...' */}
														{cell.render('Cell')}
													</td>
												</React.Fragment>
											)
										})}
									</tr>
								)
							})}
							{data.length ?
								null
							:
								<tr className='bg-light w-100 text-center'>
									<td colSpan={columns.length} className='fs-12 w-100'>
										There are no data on this criteria. Please try adjusting your filter.
									</td>
								</tr>
							}
						</tbody>
					</table>
				</div>
			</div>
			{dashboard && data.length > perPage ? (
				<div className='pagination f12'>
					Rows per page:{' '}
					<select
						className={`${perPageClass ? perPageClass : "bg-primary text-primary-color"}`}
						value={pageSize}
						onChange={e => {
							setPageSize(Number(e.target.value))
						}}
					>
						{[perPage * 1, perPage * 2, perPage * 3, perPage * 4, perPage * 5].map(pageSize => (
							<option className='text-primary-color' key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<span className='mx-3 pt-1'>
						Page {pageIndex + 1} of {pageOptions.length}{' '}
					</span>
					<button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
						<i className='fal fa-arrow-to-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => previousPage()} disabled={!canPreviousPage}>
						<i className='fal fa-angle-left f12 text-gray4' />
					</button>{' '}
					<button onClick={() => nextPage()} disabled={!canNextPage}>
						<i className='fal fa-angle-right f12 text-gray4' />
					</button>{' '}
					<button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
						<i className='fal fa-arrow-to-right f12 text-gray4' />
					</button>
				</div>
			) : null}
		</React.Fragment>
	)
}
/**
 * Type of the props used in the component
 */
Table.propTypes = {
	columns: PropTypes.array,
	onClickRow: PropTypes.func,
	onClickColumn: PropTypes.func,
	onClickColumnFor: PropTypes.func,
	data: PropTypes.array,
	dashboard: PropTypes.bool,
	tooltipFor: PropTypes.array,
	riskTooltip: PropTypes.array,
}

export default Table
