/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Kiosk
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 18/03/2024
 * @copyright ©2024 Kiosk. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { deleteDepartment, listDepartmentsMappings } from '../../../actions/cost/BudgetAction'
import { capitalizeTheFirstLetterOfEachWord } from '../../../utils/utility'

import { CSVLink } from 'react-csv';

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'

import Search from '../../common/SearchWithHiddenInput'
import { Store as CommonNotification } from 'react-notifications-component';
import { URL_PATH } from '../../../config/urlPath';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';

const LandingPage = () => {
    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        callSearch: true
    })

    const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation()
    const userMenu = useSelector(state => state?.filters?.userMenu || false);
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
					if(row.action) {
						actionsAllowed = row.action
					}
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, callSearch: false }));
            let params = {}
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
    
            dispatch(listDepartmentsMappings(params))
                .then((response) => {
                    if(response) {
                        let results = response.results ? response.results : []
                        setState(prevState => ({
                            ...prevState,
                            listResponse: results,
                            filterdListResponse: results,
                            showLoading: false
                        }));
                    }
                })
        }
    }, [state.callSearch, dispatch, state.selectedAccount, state.selectedProvider])

    useEffect(() => {
        let dataResult = state.listResponse && state.listResponse.length ? state.listResponse : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, headers }))
		}
    }, [state.listResponse])

    const navigateTo = () => {
        dispatch(setHpcPropsDetails('createMappingInputs', {}))
        navigate(URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE, { state: { pageType: 'Create' } });
    }

    const deleteDepartmentFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let params = state.deleteDepartment
        dispatch(deleteDepartment(params))
            .then((response) => {
                if(response) {
                    let messageType = 'danger'
                    let message = response && response.message ? response.message : "Error in deleting"

                    if(response.status) {
                        messageType = 'success'
                        setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: true, callSearch: true, deleteModal: !state.deleteModal, showDeleteLoading: false }))}, 1000)
                    } else {
                        setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                    }


                    CommonNotification.addNotification({
                        //title: 'Wonderful!',
                        message: message,
                        type: messageType,
                        insert: 'top',
                        container: 'top-center',
                        // animationIn: ['animate__animated', 'animate__fadeIn'],
                        // animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                        }
                    });
                } else {
                    setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                }
            })
    }

    const getTotalProjects = (data) => {
        let totalProjects = 0;
        data.initiatives.forEach(initiative => {
          totalProjects += initiative.projects.length;
        });
        return totalProjects;
    }

    const getTotalAccounts = (data) => {
        let totalAccounts = []
        data.initiatives.forEach(initiative => {
            initiative.projects.forEach(project => {
                project.accounts.forEach(acc => {
                    totalAccounts.push(acc.billingUnit)
                })
                // totalAccounts += project.accounts.length;
            })
        });
        
        return  [...new Set(totalAccounts)].length;
    }

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0'>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                </div>
            </div>
            <div className='row text-white mx-0 pb-2 bg-nero'>
                <div className='col-lg-12 col-md-12 col-sm-12 py-2 align-self-center'>
                    <h6 className='text-white m-0'>Manage Departments</h6>
                    <p className='text-white m-0'>Consolidated view of departments</p>
                </div>
            </div>
            <div className='row mx-0'>
                <div className='col-sm-12'>
                    <div className='bg-dark3 mt-2 mb-3'>
                        <div className='d-flex justify-content-between px-3 pt-2'>
                            <div className='text-black'>
                                <p className='mb-0 text-white'>
                                    <small>Showing {state.filterdListResponse && state.filterdListResponse.length} out of total {state.listResponse && state.listResponse.length} department(s)</small>
                                </p>
                            </div>
                            
                            <div className='align-self-center w-30'>
                                <div className='d-flex justify-content-end'>
                                    {state.filterdListResponse && state.filterdListResponse.length ?
                                        <CSVLink 
                                            data={state.filterdListResponse ? state.filterdListResponse : []} 
                                            headers={state.headers ? state.headers : []}
                                            filename={'job-lists.csv'}
                                            className={'align-self-center'}
                                            target='_blank'
                                        >
                                            <span className='d-flex text-primary-color mr-2'>
                                                <i className='fas fa-download text-primary-color mr-2'/>
                                                {/* csv */}
                                            </span>
                                        </CSVLink>
                                    : null}
                                    {state.listResponse && state.listResponse.length ?
                                        <div className='align-self-center mr-2 w-60'>
                                            <Search
                                                data={state.listResponse ? state.listResponse : []}
                                                applyTags={false}
                                                applyLiteDarkTags={true}
                                                topClassName={'bg-extraLightGray text-black f12 border-extraLightGray'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-black f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={'bg-transparent text-black pl-0 form-control-sm f12 w-90'}
                                                filteredData={(filterdListResponse) => setState(prevState => ({ ...prevState, filterdListResponse }))}
                                            />
                                        </div>
                                    : null}
                                    {state.actionsAllowed && state.actionsAllowed.includes('create') ?
                                        <button className={`cursorPointer btn bg-green text-white align-self-center`} onClick={() => navigateTo()}>Create&nbsp;Department</button>
                                    : null}
                                </div>                                    
                            </div>
                        </div>

                        <div className='row mx-0'>
                            <div className='col-lg-12 col-12 px-3 py-1'>
                                {state.filterdListResponse && state.filterdListResponse.length ?
                                    <ResizeableDarkThemeTable
                                        columns={[
                                            {
                                                Header: 'Department',
                                                accessor: 'department',
                                                width: 250,
                                            },
                                            {
                                                Header: 'Initiatives',
                                                // accessor: 'job_id',
                                                Cell: cellInfo => (<span>{cellInfo.row.original.initiatives && cellInfo.row.original.initiatives.length ? cellInfo.row.original.initiatives.length : ''}</span>),
                                                width: 350,
                                            },
                                            {
                                                Header: 'Projects',
                                                Cell: cellInfo => (<span>
                                                    {getTotalProjects(cellInfo.row.original)}
                                                </span>),
                                                width: 250,
                                            },
                                            {
                                                Header: 'Accounts',
                                                Cell: cellInfo => (<span>
                                                    {getTotalAccounts(cellInfo.row.original)}
                                                </span>),
                                                width: 250,
                                            },
                                            {
                                                Header: 'Action',
                                                Cell: cellInfo => (
                                                    <div className="d-flex align-self-center">
                                                        {state.actionsAllowed && state.actionsAllowed.includes('update') ?
                                                            <Link 
                                                                to={URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE} 
                                                                state={{
                                                                    selectedData: cellInfo.row.original,
                                                                    pageType: 'Edit',

                                                                }}
                                                                className='text-info ml-3'>
                                                                Edit
                                                            </Link>
                                                        : null}
                                                        {state.actionsAllowed && state.actionsAllowed.includes('delete') ?
                                                            <p className='ml-3 mb-0 text-danger cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, deleteModal: true, deleteDepartment: cellInfo.row.original }))}>Delete</p>
                                                        : null}
                                                    </div>
                                                ),
                                                width: 200,
                                            },
                                            {
                                                Header: ' ',
                                                width: 1,
                                                // accessor: 'end_time',
                                                // Cell: cellInfo => (<span className='text-info cursorPointer'>Details</span>)
                                            },
                                        ]}
                                        data={state.filterdListResponse}
                                        perPage={10}
                                        tableHead={'bg-black text-white'}
                                        tableBody={'bg-dark3 text-white'}
                                        tableType={'table-dark-hover'}
                                        perPageClass={`bg-transparent text-lightGray`}
                                        textColor={'text-black'}
                                        selectedColor={'bg-transparent'}
                                        dashboard={state.filterdListResponse && state.filterdListResponse.length ? true : false}
                                        sortByColumn={''}
                                        riskTooltip={[0]}
                                        // onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedJobDetails: tableRow }))}
                                        onClickRow={tableRow => {}}
                                        // tableSize={'table-sm'}
                                        // checkForNextSetoffRecords={true}
                                        // callToFetchNewRecord={() => {
                                        //     if(state.nextToken && state.nextToken.length) {
                                        //         setState(prevState => ({ ...prevState, callListJobDetailsFunction: true }))
                                        //     }
                                        // }}
                                    />
                                : 
                                    <div className='d-flex justify-content-center m-4'>
                                        <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </div>
                                }
                            </div>
                            
                            <Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>
                                <ModalHeader toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>Delete Department</ModalHeader>
                                <ModalBody>
                                    Are you sure you want to delete the department <span className='f18 mx-1 text-red'>{state.deleteDepartment && state.deleteDepartment.department ? state.deleteDepartment.department : ''}</span>

                                </ModalBody>
                                <ModalFooter className="border-0">
                                <Button color="primary" onClick={() => deleteDepartmentFunction()}>
                                    {state.showDeleteLoading ?
                                        <Spinner size='sm' color='light' className='mx-2' />
                                    : null}Ok
                                </Button>{' '}
                                <Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, showDeleteLoading: false, name: '' }))}>
                                    Cancel
                                </Button>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage    