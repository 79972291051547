/*************************************************
 * Collider
 * @exports
 * @file ApexBarChart.js
 * @author Prakash // on 28/06/2023
 * @copyright © 2020 Collider. All rights reserved.
 *************************************************/

import React, { useCallback, useEffect, useState } from 'react'
import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime, unitTimeConvertionDetail, countFormater } from '../../../utils/utility'
import Chart from 'react-apexcharts'

const ApexBarChart = (props) => {

    const [state, setState] = useState({})

    const drawGraph = useCallback((graphData) => {
        let graphLables = graphData.labels
        let data = []
        let highestValue = 0
        let series = []
        for(let x=0;x<graphLables.length;x++) {
            let dataItems = {}
            if(props.xaxisFormat === 'dateWithtime') {
                dataItems.x = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y HH:mm')
            } else if(props.xaxisFormat !== 'string') {
                dataItems.x = momentConvertionUtcToLocalTime(graphLables[x], 'DD MMM Y')
            } else {
                dataItems.x = graphLables[x]
            }
            if(highestValue < graphData.data[x]) {
                highestValue = graphData.data[x]
            }
            dataItems.y  = graphData.data[x]
            data.push(dataItems)

            // let seriesRow = {
            //     data: graphData.data[x],
            //     name: graphLables[x]
            // }

            // series.push(seriesRow)
        }
        
        series = [{
            name: props.graphData.name ? props.graphData.name : '',
            data: data,
            prefixUnit: props.graphData.prefixUnit ? props.graphData.prefixUnit : '',
            unit: props.graphData.unit ? props.graphData.unit : '',
            additionalTooltip: props.graphData.tooltipValues ? props.graphData.tooltipValues : '',
            convertTime: props.graphData.convertTime ? props.graphData.convertTime : '',
        }]
        
        let fillData = {}
        if(props.gradient) {
            fillData = {
                colors:[props.gradientColor ? props.gradientColor[0] : "#26B68F"],
                type: 'gradient', // gradient
                gradient: {
                    type: props.horizontal ? 'horizontal' : 'vertical', // The gradient in the horizontal direction
                    gradientToColors: [props.gradientColor ? props.gradientColor[1] : '#bee9de'], // The color at the end of the gradient
                    opacityFrom: .9, // transparency
                    opacityTo: 1,
                    stops:[0, 120]
                }
            }
        }
        
        let backgroundColors = {}
        if(props.showBackgroundBarColors) {
            backgroundColors = {
                ranges: [{
                    from: 1,
                    to: 0.9,
                    color: 'red'
                }],
                backgroundBarColors: props.backgroundBarColors ? props.backgroundBarColors : ['#EDEDED'],
                backgroundBarOpacity: 1,
                backgroundBarRadius: props.backgroundBarShape && props.backgroundBarShape === 'rounded' ? (props.barRadius ? props.barRadius : 10) : 0,
            }
        }

        let barDataLabels = {}
        if(props.dataLabelPosition && props.dataLabelPosition !== "") {
            barDataLabels.position = props.dataLabelPosition
        }
        let xaxisTitle = {}
        if(props.showTitle) {
            xaxisTitle.text = props.graphData && props.graphData.unit ? props.graphData.unit : ''
            xaxisTitle.style = {
                color: props.axisLabelColor,
                fontSize:  '14px',
                fontWeight:  'bold',
                fill:  {
                    colors: [props.axisLabelColor],
                }
            }
        }
        let yaxisTitle = {}
        if(props.yaxisTitle) {
            yaxisTitle.text = props.yaxisTitle
            yaxisTitle.style = {
                color: props.axisLabelColor,
                fontSize:  '14px',
                fontWeight:  'bold',
                fill:  {
                    colors: [props.axisLabelColor],
                }
            }
        }

        let events = {}
        if(props.dataPointSelection) {
            events.dataPointSelection = function(e, chartContext, config) {
                if(chartContext.w.config.series && chartContext.w.config.series[config.seriesIndex] && chartContext.w.config.series[config.seriesIndex].data && chartContext.w.config.series[config.seriesIndex].data[config.dataPointIndex] && chartContext.w.config.series[config.seriesIndex].data[config.dataPointIndex].x) {
                    props.selectedGraphData(chartContext.w.config.series[config.seriesIndex].data[config.dataPointIndex].x)
                }
            }
        }
        let graidXaxisBorder = {}
        if(props.graidXaxisBorder) {
            graidXaxisBorder = {
                show: true,
                color: '#434B5E',
            }
        }

        let tooltip = {}
        if(props.screen === 'job_status') {
            tooltip = {
                enabled: true,
                style: {
                    fontSize: '9px',
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex]
                    let labelName = capitalizeTheFirstLetterOfEachWord(w.globals.initialSeries[seriesIndex].name.replace("_", " "))
                    let prefixUnit = w.globals.initialSeries[seriesIndex].prefixUnit !== '' ? w.globals.initialSeries[seriesIndex].prefixUnit : ''
                    let unit = w.globals.initialSeries[seriesIndex].unit !== '' ? w.globals.initialSeries[seriesIndex].unit : ''
                    let additionalTooltip = w.globals.initialSeries[seriesIndex].additionalTooltip !== '' ? w.globals.initialSeries[seriesIndex].additionalTooltip[dataPointIndex] : ''
                    let convertTime = w.globals.initialSeries[seriesIndex].convertTime
                    if(unit === "seconds" && convertTime) {
                        val = unitTimeConvertionDetail(val, unit)
                    }
                    let retrunData = '<div class="arrow_box">'
                    if(additionalTooltip === '' && !Object.entries(additionalTooltip).length) {
                        retrunData += ' <span>' +  w.globals.initialSeries[0].data[dataPointIndex].x + '</span> '
                        if(prefixUnit !== '') {
                            retrunData += '<span> ' + prefixUnit + '</span>'
                        }
                        if(labelName !== '') {
                            retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                        } else {
                            retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                        }
                        if(unit !== '' && !convertTime) {
                            retrunData += '<span> ' + unit + '</span>'
                        }
                    } else if(additionalTooltip !== '' && Object.entries(additionalTooltip).length) {
                        if(!!(val % 1)) {
                           val = val.toFixed(2)
                        }
                        retrunData += '<div class="small">'
                        
                        retrunData += '<div>'
                        Object.entries(additionalTooltip).forEach(([key, value]) => {
                            if(value !== '') {
                                retrunData += ''
                                retrunData += '<div class="small"> <span class="small text-gray3">'+capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))+'</span> : '
                                if(prefixUnit !== '') {
                                    retrunData += '<span> ' + prefixUnit + '</span>'
                                }
                                retrunData += '<span class="ml-1">'+(!Array.isArray(value) ? value : value[dataPointIndex])+'</span></div>'
                            }
                        })
                        retrunData += '</div>'
                    }
                    retrunData += '</div>'

                    return retrunData
                }
            }
        } else {
            tooltip = {
                enabled: true,
                style: {
                    fontSize: '9px',
                },
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    let val  = series[seriesIndex][dataPointIndex]
                    let labelName = capitalizeTheFirstLetterOfEachWord(w.globals.initialSeries[seriesIndex].name.replace("_", " "))
                    let prefixUnit = w.globals.initialSeries[seriesIndex].prefixUnit !== '' ? w.globals.initialSeries[seriesIndex].prefixUnit : ''
                    let unit = w.globals.initialSeries[seriesIndex].unit !== '' ? w.globals.initialSeries[seriesIndex].unit : ''
                    let additionalTooltip = w.globals.initialSeries[seriesIndex].additionalTooltip ? w.globals.initialSeries[seriesIndex].additionalTooltip : ''
                    let convertTime = w.globals.initialSeries[seriesIndex].convertTime
                    if(unit === "seconds" && convertTime) {
                        val = unitTimeConvertionDetail(val, unit)
                    }
                    let retrunData = '<div class="arrow_box">'
                    if(additionalTooltip === '' && !Object.entries(additionalTooltip).length) {
                        retrunData += ' <span>' +  w.globals.initialSeries[0].data[dataPointIndex].x + '</span> '
                        if(prefixUnit !== '') {
                            retrunData += '<span> ' + prefixUnit + '</span>'
                        }
                        if(labelName !== '') {
                            retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                        } else {
                            retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                        }
                        if(unit !== '' && !convertTime) {
                            retrunData += '<span> ' + unit + '</span>'
                        }
                    } else if(additionalTooltip !== '' && Object.entries(additionalTooltip).length) {
                        if(!!(val % 1)) {
                           val = val.toFixed(2)
                        }
                        retrunData += '<div class="small">'
                        retrunData += '<span class="small text-gray3">'+w.globals.initialSeries[0].data[dataPointIndex].x+'</span> : </span>'
                        if(labelName !== '') {
                            retrunData += '<span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> '+ val
                        } else {
                            retrunData += '<span style="color:#039BE5"> ' + val + '</span>'
                        }
                        if(unit !== '' && !convertTime) {
                            retrunData += '<span> ' + unit + '</span>'
                        }
                        retrunData += '<div>'
                        Object.entries(additionalTooltip).forEach(([key, value]) => {
                            if(value !== '') {
                                retrunData += ''
                                retrunData += '<div class="small"> <span class="small text-gray3">'+capitalizeTheFirstLetterOfEachWord(key.replace('_', ' '))+'</span> : '
                                if(prefixUnit !== '') {
                                    retrunData += '<span> ' + prefixUnit + '</span>'
                                }
                                retrunData += '<span class="ml-1">'+(!Array.isArray(value) ? value : value[dataPointIndex])+'</span></div>'
                            }
                        })
                        retrunData += '</div>'
                    }
                    retrunData += '</div>'

                    return retrunData
                }
            }
        }

        let options = {
            tooltip: tooltip,
            // colors: props.colors ? props.colors : [],		
            chart: {
                redrawOnParentResize: true,
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: props.zoom,
                },
                sparkline: {
                    enabled: props.sparkline
                },
                animations: {
                    enabled: false
                },
                type: 'bar',
                height: 150,
                stacked: props.stacked,
                events: events
            },
            colors: props.colors ? props.colors : ['#26B68F', '#2ED1BE', '#67E1D6', '#00B5D4'],
            plotOptions: {
                bar: {
                    horizontal: props.horizontal,
                    barHeight: props.barHeight,
                    borderRadius: props.barEndShape && props.barEndShape === 'rounded' ? (props.barRadius ? props.barRadius : 8) : 0,
                    borderRadiusApplication: 'end',
                    // startingShape: 'flat',
                    // endingShape: props.barEndShape ? props.barEndShape : 'flat',
                    columnWidth: props.columnWidth ? props.columnWidth : '1%',
                    distributed: props.chartDistributed ? true : false,
                    rangeBarOverlap: false,
                    colors: backgroundColors,
                    dataLabels: barDataLabels
                    // dataLabels: {
                    //     total: {
                    //       enabled: true,
                    //     //   style: {
                    //     //     fontSize: '13px',
                    //     //     fontWeight: 900
                    //     //   },
                    //       offsetX: -100
                    //     }
                    // }
                    
                },       
            },
            dataLabels: {
                enabled: props.dataLabels,
                // textAnchor: 'props.dataLabelsTextAnchor',
                
                // formatter: function (val, opts) {
                //     return val ? val : ""
                // },
                // enabledOnSeries: undefined,
                // offsetX: 100,
                // offsetY: 100,
                // style: {
                    // fontSize: '14px',
                    // fontFamily: 'Helvetica, Arial, sans-serif',
                    // fontWeight: 'bold',
                    // colors: [props.axisLabelColor ? props.axisLabelColor : "#333333"]
                // },
                offsetX: 20,
            },     
            fill: fillData,        
            grid: {
                show: false,			        
                xaxis: {
                    lines: {
                        show: props.graidXaxisBorder ? props.graidXaxisBorder : false
                    },
                    axisBorder: graidXaxisBorder,
                },
                yaxis: {
                    lines: {
                        show: false
                    }
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: props.paddingLeft ? props.paddingLeft : 10
                },
            },
            xaxis: {
                show: props.xaxis,
                tooltip: {
                    enabled: false
                },
                type: props.xaxisFormat !== 'string' ? 'datetime' : 'category',
                axisBorder: {
                    show: true,
                    color:'#434B5E'
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    datetimeUTC: false,
                    show: props.xaxisLabel,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: props.axisLabelFontSize ?  props.axisLabelFontSize : '13px',
                        fontWeight: 'bolder',
                    },
                },
                
                crosshairs: {
                    show: false,
                    width: 0,
                    position: 'back',
                    opacity: 0,
                    stroke: {
                        color: '#b6b6b6',
                        width: 0,
                        dashArray: 0,
                    },
                    fill: {
                        type: 'solid',
                        color: '#B1B9C4',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        },
                    },
                    dropShadow: {
                        enabled: false,
                        top: 0,
                        left: 0,
                        blur: 1,
                        opacity: 0.4,
                    },
                },
                title: xaxisTitle
            },
            yaxis: {
                show: props.yaxis,
                // props.tickAmount: props.yaxisTickAmont ? props.yaxisTickAmont : 4,
                axisBorder: {
                    show: true,
                    color: '#969AA7',
                },
                tickAmount: parseInt(highestValue) <= 1 ? 1 : "",
                labels: {
                    show: props.yaxisLabel,
                    style: {
                        colors: props.axisLabelColor,
                        fontSize: '13px',
                        fontWeight: 'bolder',
                    },
                    formatter: (value) => {
                        if (typeof value === "string") {
                            return value
                        } else {
                            if(highestValue <= 1) {
                                return parseInt(value)

                            } else {
                                return countFormater(value, "round")
                            }
                        }
                        // if(Number(value) === value && value % 1 === 0) {
                        // } else {
                        //     return Math.ceil(value.toFixed(1))
                        // }
                    },
                },
                title: yaxisTitle
            },
            legend: {
                show: props.legend,
                position: props.legendPostion ? props.legendPostion : "bottom",
                horizontalAlign: props.legendHorizontalAlign ? props.legendHorizontalAlign : 'center',
                // offsetX: 40,
                labels: {
                    // colors: [props.axisLabelColor],
                    useSeriesColors: props.legendUseSeriesColor
                }
            },
        }
        setState(prevState => ({ ...prevState, series, options, graphLables }));
    }, [props])

    useEffect(() => {
        let graphData = props.graphData;
        if(graphData === 'mockData') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64,77,36,34,44,10,1,3,91,45,32,3,37,21,65,45,38,59,16,3,66,85,30,62,49]
            graphData.labels = ["2021-05-12 00:00:00", "2021-05-13 00:00:00", "2021-05-14 00:00:00", "2021-05-15 00:00:00", "2021-05-16 00:00:00", "2021-05-17 00:00:00", "2021-05-18 00:00:00", "2021-05-19 00:00:00", "2021-05-20 00:00:00", "2021-05-21 00:00:00", "2021-05-22 00:00:00", "2021-05-23 00:00:00", "2021-05-24 00:00:00", "2021-05-25 00:00:00", "2021-05-26 00:00:00", "2021-05-27 00:00:00", "2021-05-28 00:00:00", "2021-05-29 00:00:00", "2021-05-30 00:00:00", "2021-05-31 00:00:00", "2021-06-01 00:00:00", "2021-06-02 00:00:00", "2021-06-03 00:00:00", "2021-06-04 00:00:00", "2021-06-05 00:00:00", "2021-06-06 00:00:00", "2021-06-07 00:00:00", "2021-06-08 00:00:00", "2021-06-09 00:00:00", "2021-06-10 00:00:00", "2021-06-11 00:00:00"]
        } else if(graphData === 'mockDataString') {
            graphData = {}
            graphData.data = [45,67,34,71,41,93,64]
            graphData.labels = ["Dep 1", "Dep 2", "Dep 3", "Dep 4", "Dep 5", "Dep 6", "Dep 7"]
        } else if(graphData === "top5MockData") {
            graphData = {}
            graphData.data = [45,67,34,71,41]
            graphData.labels = ["Data 1", "Data 2", "Data 3", "Data 4", "Data 5"]
        }
        
        if(graphData && Object.entries(graphData).length) {
            drawGraph(graphData)
        }
    }, [props, drawGraph])

    return (
        state.series ?
            <div className={`${props.className}`}>
                <Chart options={state.options} series={state.series} type="bar" height={props.height} width={props.width ? props.width : "100%"}/>
            </div>
        : null
    )
}

export default ApexBarChart