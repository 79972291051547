/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 10/04/2023
 * @copyright © 2023 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { Spinner } from 'reactstrap'
import MultiSelectSection from '../../common/MultiSelectSection';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { costGetCostTrend, getCostFilters, getCostFilterValues, listCostTemplates, getCostAllTagsKeys, getCostAllTagsValues, setCostPropsDetails } from '../../../actions/cost/CostAction'

import _ from 'lodash'

import moment from 'moment'
import { capitalizeAllLetter, currentUTCtime, getDayFromSelectedDuration, momentDateGivenFormat, subDays, subHours } from '../../../utils/utility'

// import { RangePicker } from "react-minimal-datetime-range";
// import "react-minimal-datetime-range/lib/react-minimal-datetime-range.min.css";

import ApexStackedBarChart from '../../common/charts/ApexStackedBarChart';

import StickyDarkThemeTable from '../../designComponents/Table/StickyDarkThemeTable';
import { CSVLink } from 'react-csv';
import Search from '../../common/SearchComponent';
import SavedFiltersSidePanel from './SavedFiltersSidePanel';
import { useCallback } from 'react';

const LandingPage = () => {
	const providerRef = useRef();
	const sourceRef = useRef();
	const accountRef = useRef();
	const regionRef = useRef();
	const durationRef = useRef();
	const granularityRef = useRef();

	const tagsRef = useRef();
	const tagKeyRef = useRef();
	const tagValueRef = useRef()
	const searchRef = useRef();
	
	const[state, setState] = useState({
		showLoading: true,
		providers: [],
		minMaxHeight: "770px",
		durationActive: true,
		selectedDuration: "+30d",
		colors: ["#8e3a80", "#ff8200", "#D2291C", "#43B02A", "#0057b8", "#FFCD00"],
		// colors: ["#DEC9E9", "#D2B7E5", "#B185DB", "#7251B5", "#FFE169", "#FAD643", "#DBB42C", "#A47E1B", "#99E2B4", "#88D4AB", "#67B99A", "#358F80", "#FFE0E9", "#FF9EBB", "#E05780", "#8A2846"],
		transColor: ["#8e3a80", "#000000"],
		granualrityOptions: [
			{label: 'Daily', value: "daily"},
			{label: 'Hourly', value: "hourly"},
			{label: 'Monthly', value: "monthly"}
		],
		selectedGranularity: 'daily'
	})

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

	const tagKeys = useSelector(state => state.cost.costTagKeys);
	const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);
	const costFilterValues = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.costFilterValues ? state.cost.costPropsDetails.costFilterValues : {});

    const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
		if(providers) {
            setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", callSearch: true }));
        } else {
			let params = {}
			dispatch(listAllProviders(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({
							...prevState,
							providers: response,
							selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '',
							callSearch: true,
							showLoading: response && !response.length ? false : true
						}));
					}
				})
		}
		
    }, [dispatch, providers]);
	
	useEffect(() => {
		if(state.showLoading) {
			setTimeout(() => { setTimeout(prevState => ({ ...prevState, showLoading: false })) }, 5000);
		}
	}, [state.showLoading])

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

	useEffect(() => {
        let duration = state.selectedDuration;
        let period = duration.substring(duration.length, duration.length - 1);
        let durationBtw = getDayFromSelectedDuration(duration);

       	let datePickerStartDate = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD 00:00:00')
        if(period === "d") {
            datePickerStartDate = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD 00:00:00')
        }
        let datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

        setState(
            prevState => ({
                ...prevState,
                datePickerStartDate,
				datePickerEndDate
            })
        );
	}, [state.selectedDuration])

	useEffect(() => {
		if((state.datePickerStartDate && state.datePickerEndDate)) {
			setState(prevState => ({ ...prevState, callFormSelectedFitlersFunction: true }))

			let dateSelection= {
				startDate: new Date(state.datePickerStartDate),
				endDate: new Date(state.datePickerEndDate),
				key: 'selection'
			}

			setDateState([dateSelection])
		}
		

	}, [state.datePickerStartDate, state.datePickerEndDate])

	const getCostFiltersFunction = useCallback(() => {
		let selectedProvider = state.selectedProvider.toLowerCase()
		let params = {}
		dispatch(getCostFilters(params))
			.then((response) => {
				if(response) {
					let resResult = {}
					let selectedSource = ""
					if(selectedProvider && response[selectedProvider]) {
						let results = response[selectedProvider]
						if(results && Object.entries(results).length) {
							resResult = results[Object.keys(results)[0]]
							selectedSource = Object.keys(results)[0]
						}
					}
					
					//sort object
					const sortedArray = Object.entries(resResult)
						.sort(([, a], [, b]) => a.order - b.order);
					
					const sortedObject = Object.fromEntries(sortedArray);

					setState(prevState => ({ ...prevState, costFiltersResponse: response, costFilters: sortedObject, selectedSource, showLoading: selectedSource ? true : false }))
				}
			})
	}, [dispatch, state.selectedProvider])

	// Call getCostFilters whenever the providers state is updated
    useEffect(() => {
        if (state.providers && state.providers.length) {
			let selectedProvider = state.selectedProvider.toLowerCase()
			if(state.costFiltersResponse && Object.entries(state.costFiltersResponse).length) {
				let resResult = {}
				let selectedSource = ""
				if(selectedProvider && state.costFiltersResponse[selectedProvider]) {
					let results = state.costFiltersResponse[selectedProvider]
					if(results && Object.entries(results).length) {
						resResult = results[Object.keys(results)[0]]
						selectedSource = Object.keys(results)[0]
					}
				}

				//sort object
				const sortedArray = Object.entries(resResult)
				.sort(([, a], [, b]) => a.order - b.order);
			
				const sortedObject = Object.fromEntries(sortedArray);

				setState(prevState => ({ ...prevState, costFilters: sortedObject, selectedSource, showLoading: selectedSource ? true : false, onChangeProvider: true }))
			} else {
				getCostFiltersFunction();
			}
        }
    }, [state.providers, state.selectedProvider, state.costFiltersResponse, getCostFiltersFunction]);

	useEffect(() => {
		if(state.costFilters && Object.entries(state.costFilters).length) {
			setState(prevState => ({ ...prevState, callStructureCostFilterResponse: true, callListCostTemplatesFunction: true }))
			// structureCostFilterResponse()
			// listCostTemplatesFunction()
		}
	}, [state.costFilters])

	useEffect(() => {
		if(state.selectedSource && state.callListCostTemplatesFunction) {
			let params = {}
			// params.source = state.selectedSource
			// params.provider = state.selectedProvider
			dispatch(listCostTemplates(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listData: response, filteredListData: response, searchResultState: response, callListCostTemplatesFunction: false }))
					}
				})
		}
	}, [dispatch, state.selectedSource, state.callListCostTemplatesFunction])
	
	useEffect(() => {
		if(state.callStructureCostFilterResponse) {
			let costFilters = state.costFilters
			costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(cValue.default) {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default, ["selected_value_"+cIndex]: cValue.default }))
					}
				} else {
					setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
				}
	
				if(Object.entries(costFilters).length - 1 === cIndex) {
					setState(prevState => ({ ...prevState, showLoading: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true, callStructureCostFilterResponse: false }))
				}
			})
		}
	}, [state.callStructureCostFilterResponse, state.costFilters])

	useEffect(() => {
		if(state.onChangeProvider) {
			let costFilters = state.costFilters
	
			costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(cValue.default) {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default }))
					} else {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					}
				} else {
					setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
					if(cValue.output_type === "string") {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					} else {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: [] }))
					}
				}
	
				if(Object.entries(costFilters).length - 1 === cIndex) {
					let selectedProvider = state.selectedProvider.toLowerCase()
					let costSources = state.costFiltersResponse[selectedProvider] ? state.costFiltersResponse[selectedProvider] : {}
					
					let resResult = {}
					let selectedSource = ""
					if(costSources && Object.entries(costSources).length) {
						resResult = costSources[Object.keys(costSources)[0]]
						selectedSource = Object.keys(costSources)[0]
					}

					//sort object
					const sortedArray = Object.entries(resResult)
					.sort(([, a], [, b]) => a.order - b.order);
				
					const sortedObject = Object.fromEntries(sortedArray);
	
					setState(prevState => ({ ...prevState, costSources, costFilters: sortedObject, selectedSource, onChangeProvider: false }))
				}
			})
		}
	}, [state.onChangeProvider, state.costFilters, state.costFiltersResponse, state.selectedProvider])

	// function to search every object in array by recurseive
	const recursiveSearch = useCallback((item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}, [])

	useEffect(() => {
		let results = state.listData
		if (state.tempSearchText && state.tempSearchText.length > 2) {
			results =
				results &&
				results.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.tempSearchText)
						.flat()
						.filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
		}

		setState(prevState => ({ ...prevState, searchResultState: results }))
	}, [state.tempSearchText, recursiveSearch, state.listData])

	const clearFilter = () => {
		let costFilters = state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			state["filteredDropOptions_"+cIndex] && state["filteredDropOptions_"+cIndex].map((row, index) => {
				setState(prevState => ({ ...prevState, ["input_change_"+cIndex+"_"+index]: false }))
			})
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(cValue.default) {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: cValue.default, ["selected_value_"+cIndex]: cValue.default }))
				} else {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "", ["selected_value_"+cIndex]: "" }))
				}
			} else {
				setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
				if(cValue.output_type === "string") {
					setState(prevState => ({ ...prevState, ["selected_value_"+cIndex]: "" }))
				} else {
					setState(prevState => ({ ...prevState, ["selected_value_"+cIndex]: [], ["select_all_"+cIndex]: false }))
				}
			}
		})

		setState(prevState => ({ ...prevState, clearFilter: true, durationActive: true, selectedDuration: "+30d", tagsSelected: [], selectedTags: [], selectedGranularity: 'daily' }))
	}

	useEffect(() => {
		if(state.clearFilter) {
			setState(prevState => ({ ...prevState, clearFilter: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))}
	}, [state.clearFilter])

	useEffect(() => {
		if(state.callCostGetCostTrendFunction && state.selectedSource) {
			setState(prevState => ({ ...prevState, callCostGetCostTrendFunction: false }))
			let params = {}
			params.provider = state.selectedProvider.toLowerCase()
			params.source = state.selectedSource
			if(state.selectedAccount && state.selectedAccount.length) {
				params.account_id = state.selectedAccount
			}
			if(state.selectedRegion && state.selectedRegion.length) {
				params.region = state.selectedRegion
			}
			params.duration_aggregate_by = state.selectedGranularity

			params.start_time = state.datePickerStartDate
			params.end_time = state.datePickerEndDate
			

			if(state.tagsSelected && state.tagsSelected) {
				params.tags = state.tagsSelected
			}
			if(state.forecast) {
				params.forecast = state.forecast
			}
			let excludes = {}
			let groupBy = ""
			state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
						if(cValue.output_field === "aggregate_by") {
							Object.entries(cValue.mapped_values).length && Object.entries(cValue.mapped_values).forEach(([mKey, mValue]) => {
								if(state["input_change_"+cIndex] === mValue) {
									groupBy = mKey
								}
							})
						}
						params[cValue.output_field] = state["input_change_"+cIndex]
					}
				} else {
					if(state["selected_value_"+cIndex] && state["selected_value_"+cIndex].length) {
						if(state["includes_"+cIndex] === "Includes") {
							params[cValue.output_field] = state["selected_value_"+cIndex]
						} else {
							excludes[cValue.output_field] = state["selected_value_"+cIndex]
						}
					}
				}
			})
	
			if(excludes && Object.entries(excludes).length) {
				params.excludes = excludes
			}
			if(params.aggregate_by && params.aggregate_by !== "") {
				params.radial = true				
			}
			
			let granualrity = ""
			if(params.duration_aggregate_by && params.duration_aggregate_by !== "") {
				granualrity = params.duration_aggregate_by
			}
	
			dispatch(costGetCostTrend(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, trendData: response.results ? response.results : [], applyLoading: false, trendGraphData: {}, groupBy, granualrity, showLoading: false }))
					}
				})

		}
	}, [state.callCostGetCostTrendFunction, dispatch, state])
	

	const trendGraphStructure = useCallback(() => {
        let graphData = {}
		let labels = []
		let forcastMessage = ""
        state.trendData && state.trendData.forEach(item => {
            if(item.data && Array.isArray(item.data)) {
				let spend = []
			    let forecast = []
			    item.dates.forEach((lab, index) => {
				    labels.push(lab)
				    spend.push(item.data[index])
					forecast.push(0)
			    })
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        labels.push(lab)
				        spend.push(0)
					    forecast.push(item.forecast.data[index])
				    })
				}
				graphData.labels = labels
				graphData.spend = spend
				if(item.forecast && item.forecast.dates) {
				    graphData.forecast = forecast
				}
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				labels.push()
				Object.entries(item.data).forEach(([key, value]) => {
					graphData[key] = value
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			} else if(item.data && !Array.isArray(item.data) && !Object.entries(item.data).length) {
				graphData.labels = item.dates ? item.dates : []
				let data = []
				graphData.labels.forEach(row => {
					data.push(0)
				})
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
				graphData["cost"] = data
			} else {
				graphData["cost"] = item.data
				if(item.forecast_message) {
					forcastMessage = item.forecast_message
				}
			}
        })
		
        setState(prevState => ({ ...prevState, trendGraphData: graphData, forcastMessage }))
    }, [state.trendData])

	const trendTableData = useCallback(() => {
		var trendTable = []
		state.trendData && state.trendData.forEach(item => {
            let labels = item.dates ? item.dates : []
            if(item.data && !Array.isArray(item.data) && Object.entries(item.data).length) {
				Object.entries(item.data).forEach(([key, value]) => {
					// let totalCount = value.reduce(function(a, b){
					// 	return a + b;
					// }, 0);
					var result = {
						"service": key,
						'total': item.count && item.count[key] ? item.count[key] : 0
					}
				
					value.forEach((item,index) => {
						result[labels[index]] = item
					})
					trendTable.push(result);
				})
			} else if(item.data && Array.isArray(item.data)) {

				let data = item.data
				// let totalCount = item.data.reduce(function(a, b){
				// 	return a + b;
				// }, 0);
				var result = {
					"service": "Cost",
					'total': item.total
				}
				
				data.forEach((item,index) => {
					if(momentDateGivenFormat(labels[index], "YYYY-MM-DD") <= momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						result[labels[index]] = item
					}
				})
				
				if(item.forecast && item.forecast.dates) {
				    item.forecast && item.forecast.dates.forEach((lab, index) => {					
				        result[lab] = item.forecast.data[index]
				    })
				}

				trendTable.push(result);
			}
		})
		
		if(trendTable.length) {
			const columns = Object.entries(trendTable[0]).map(([tKey, tValue]) =>{
				let sticky = false
				let header = ''
				let width = '100%'
				let textColor = ''
				// if(state.durationSelector > 7) {
				// 	width = 75
				// }
				if(tKey === 'service') {
					sticky = true
					header = state.groupBy
					width = 300
				} else if(tKey === 'total') {
					sticky = true
					header = 'Total'
					width = 75
				} else {
					if(momentDateGivenFormat(tKey, "YYYY-MM-DD") > momentDateGivenFormat(currentUTCtime(), "YYYY-MM-DD")) {
						textColor = "text-info"
					}
					if(state.granualrity === 'monthly') {
						header = moment(tKey).format('MMM YY')
						width = 75
					} else  if(state.granualrity === 'daily') {
						header = moment(tKey).format('MMM DD')
						width = 75
					} else  {
						header = moment(tKey).format('HH mm')
						width = 75
					}
				}
				return {
					Header: header,
					accessor: tKey,
					width: width,
					sticky: sticky ? "left" : "",
					textColor: textColor
				}
			})
			
			setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
		} else {
			const columns = []	
			setState(prevState => ({ ...prevState, trendTable, filteredArray: trendTable, columns }))
		}
	}, [state.granualrity, state.groupBy, state.trendData])

	useEffect(() => {
		trendGraphStructure()
		trendTableData()
	}, [state.trendData, trendTableData, trendGraphStructure])

	useEffect(() => {
		if(state.filteredArray && state.filteredArray.length) {
			let headers = [] 
			Object.keys(state.filteredArray[0]).forEach(item => {
				let dataRow = {}
				dataRow.label = item
				dataRow.key = item
				headers.push(dataRow)
			})

			setState(prevState => ({ ...prevState, headers }))
		}
	}, [state.filteredArray])

	useEffect(() => {
		if(state.callOnChangeSourceFunction) {
			let selectedProvider = state.selectedProvider.toLowerCase()
			let resResult = {}
		
			let results = state.costFiltersResponse[selectedProvider] ? state.costFiltersResponse[selectedProvider] : {}
			if(results && Object.entries(results)) {
				resResult = results[state.selectedSource]
			}

			//sort object
			const sortedArray = Object.entries(resResult)
			.sort(([, a], [, b]) => a.order - b.order);
		
			const sortedObject = Object.fromEntries(sortedArray);
		
			setState(prevState => ({ ...prevState, costFilters: sortedObject, callOnChangeSourceFunction: false }))
		}
	}, [state.callOnChangeSourceFunction, state.costFiltersResponse, state.selectedProvide, state.selectedSource, state.selectedProvider])

	//on change filter items

    const handleSelect = (date) => {
		
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }
        
        if(datePickerStartDate && datePickerEndDate) {
        	setState(prevState => ({ ...prevState, datePickerStartDate, datePickerEndDate, showDateRangePicker: false, durationActive: false }))
		}

		setDateState([date.selection])
	}

	useEffect(() => {
		if(state.showTagKeyLoading){
			setState(prevState => ({ ...prevState, showTagKeyLoading: false }))
			if(!tagKeys || !tagKeys.length) {
				let params = {}
				// params.account_id = "265469181489"
				// params.region = "us-east-2"
				dispatch(getCostAllTagsKeys(params))
					.then((response) => {
                        if(response) {
							setState(prevState => ({ ...prevState, showTagKeyLoading: !state.showTagKeyLoading }))
						}
					})
			}
		}
	}, [state.showTagKeyLoading, dispatch, tagKeys])

	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			dispatch(getCostAllTagsValues(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, tagValues: response }))
					}
				})
		}
	}, [dispatch, state.tag_key])

	// useEffect(() => {
	// 	if(state.tagsSelected && state.tagsSelected.filter(e => e.key === state.tag_key).length) {
	// 		let value = state.tagsSelected.filter(e => e.key === state.tag_key).map(e => e.value)
	// 		handleMultiSelectChange("tag_value", value, state.tagValues)			
	// 	}
	// }, [state.tagValues, state.tag_key, state.tagsSelected])

	const addNewTag = () => {
		let tagsSelected = state.tagsSelected ? state.tagsSelected : []

		if(state.tag_key && state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== state.tag_key)
			state.tag_value && state.tag_value.length && state.tag_value.forEach(row => {
				let dataRow = {
					key: state.tag_key,
					value: row,
				}
				filteredTags.push(dataRow)
			})

			setState(prevState => ({ ...prevState, tagsSelected: filteredTags, tag_key: "",  tag_value: "", tagValues: [] }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.tagsSelected.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, tagsSelected: filteredResult }))
	}

	const onClickGetDropOptions = (cKey, cValue, cIndex) => {
		
		let params = {}
		if(state.selectedProvider) {
			params.provider = state.selectedProvider.toLowerCase()
		}
		if(state.selectedSource) {
			params.source = state.selectedSource
		}
		if(state.selectedAccount && state.selectedAccount.length) {
			params.account_id = state.selectedAccount
		}
		if(state.selectedRegion && state.selectedRegion.length) {
			params.region = state.selectedRegion
		}
		let filterSelected = []
		state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {			
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
					if(cValue.output_field === "duration_aggregate_by") {
						params[cValue.output_field] = state["input_change_"+cIndex]
						filterSelected.push(cValue.output_field)
					}
				}
			}
			
			if(cValue.output_field === "resource_type") {
				if(state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && state["selected_value_"+cIndex].length) {
					params[cValue.output_field] = state["selected_value_"+cIndex]
					filterSelected.push(cValue.output_field)
				} else if(state["selected_value_"+cIndex] && !Array.isArray(state["selected_value_"+cIndex])) {
					params[cValue.output_field] = state["selected_value_"+cIndex]
					filterSelected.push(cValue.output_field)
				}
			}
		})

		params.aggregate_by = cValue.output_field

		params.start_time = state.datePickerStartDate
		params.end_time = state.datePickerEndDate
		// params.radial = true
		// removeSpacebtwString
		if(!cValue.hasOwnProperty("mapped_values") && !cValue.hasOwnProperty("values")) {
			if(!costFilterValues.hasOwnProperty(state.selectedSource+"_"+cValue.output_field)) {
				dispatch(getCostFilterValues(params))
					.then((response) => {
                        if(response) {
							let results = response.results ? response.results : []
							setState(prevState => ({
								...prevState, 
								["hideDropLoading_"+cIndex]: true, 
								["dropOptions_"+cIndex]: results, 
								["filteredDropOptions_"+cIndex]: results,
								filterSelected,
							}))

							if(results.length && _.isEqual(filterSelected, state[filterSelected])) {
								let obj = costFilterValues
								obj[state.selectedSource+"_"+cValue.output_field] = results
								dispatch(setCostPropsDetails("costFilterValues", obj))
							} else {
								let obj = {}
								obj[state.selectedSource+"_"+cValue.output_field] = results
								dispatch(setCostPropsDetails("costFilterValues", obj))
							}
						}
					})
			} else {
				setState(prevState => ({
					...prevState, 
					["hideDropLoading_"+cIndex]: true, 
					["dropOptions_"+cIndex]: costFilterValues[state.selectedSource+"_"+cValue.output_field], 
					["filteredDropOptions_"+cIndex]: costFilterValues[state.selectedSource+"_"+cValue.output_field]
				}))
			}
		}

		state.costFilters && Object.entries(state.costFilters).forEach(([loopKey, loopValue], loopIndex) => {
			if(loopIndex !== cIndex) {
				setState(prevState => ({ ...prevState, ["showDetails_"+loopIndex]: false }))
			} else {
				setState(prevState => ({ ...prevState,  ["showDetails_"+cIndex]: !prevState["showDetails_"+cIndex] }))
			}
		})

		setState(prevState => ({ ...prevState, showDateRangePicker: false, showTagFilter: false }))
	}

    const searchData  = (e, searchKey, cIndex, state) => {
        let newList = []
        if (searchKey !== "" && searchKey.length >=3 ) {
            let currentList = state
            newList =
                currentList &&
                currentList.filter(item => {
                    let isPresent = []
                    if(typeof item === "string") {
                        return item !== null
                            ? item
                                    .toString()
                                    .toLowerCase()
                                    .indexOf(searchKey.toString().toLowerCase()) > -1
                            : false
                    } else {
                        isPresent = recursiveSearch(item, searchKey)
                            .flat()
                            .filter(bool => bool === true)
                        if (isPresent[0]) {
                            return true
                        } else {
                            return false
                        }
                    }
                    
                })
        } else {
            newList = state
		}
        
        setState(prevState => ({ ...prevState, ["filteredDropOptions_"+cIndex]: newList }))
    }

	const onChangeSelectInput = (state, value, field, index, cIndex) => {
		setState(prevState => ({ ...prevState, [field+"_"+cIndex]: value, callFormSelectedFitlersFunction: true }))
	}

	const onChangeMultiSelectInput = (options, value, field, index, cIndex) => {
		let selectedValue = state["selected_value_"+cIndex] ? state["selected_value_"+cIndex] : []
		if(value !== "All") {
			if(state[field+"_"+cIndex+"_"+index]) {
				selectedValue = selectedValue.filter(e => e !== value)
				setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+index]: !prevState[field+"_"+cIndex+"_"+index], ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
			} else {
				selectedValue.push(value)
				setState(prevState => ({ ...prevState, [field+"_"+cIndex+"_"+index]: !prevState[field+"_"+cIndex+"_"+index], ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
			}
		} else {
			if(state["select_all_"+cIndex]) {
				let selectedValue = []
				options.forEach((row, rIndex) => {
					setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: false, [field+"_"+cIndex+"_"+rIndex]: false, ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
				})
			} else {
				let selectedValue = []
				options.forEach((row, rIndex) => {
					selectedValue.push(row)
					setState(prevState => ({ ...prevState, ["select_all_"+cIndex]: true, [field+"_"+cIndex+"_"+rIndex]: true, ["selected_value_"+cIndex]: selectedValue, callFormSelectedFitlersFunction: true }))
				})
			}

		}
	}

	useEffect(() => {
		if(state.callFormSelectedFitlersFunction) {
			let selectedFilters = []
			
			if(state.datePickerStartDate) {
				let dataRow = {}
				dataRow.label = "Duration"
				dataRow.start_time = state.datePickerStartDate
				dataRow.end_time = state.datePickerEndDate

				selectedFilters.push(dataRow)
			}

			if(state.tagsSelected && state.tagsSelected.length) {
				let dataRow = {}
				dataRow.label = "Tags"
				dataRow.output_field = "tags"
				dataRow.value = state.selectedTags

				selectedFilters.push(dataRow)
			}

			if(state.selectedAccount && state.selectedAccount.length) {
				let dataRow = {}
				dataRow.label = "Account"
				dataRow.output_field = "account_id"
				dataRow.type = 'Includes'
				dataRow.value = state.selectedAccount

				selectedFilters.push(dataRow)
			}

			if(state.selectedGranularity) {
				let dataRow = {}
				dataRow.label = "Granularity"
				dataRow.output_field = "duration_aggregate_by"
				dataRow.value = state.selectedGranularity

				selectedFilters.push(dataRow)
			}

			state.costFilters && Object.entries(state.costFilters).forEach(([cKey, cValue], cIndex) => {
				let dataRow = {}
				if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
					if(state["input_change_"+cIndex] && state["input_change_"+cIndex] !== "") {
						let outputLabel = state["input_change_"+cIndex]
						if(Object.entries(cValue.mapped_values).length) {
							outputLabel = Object.keys(cValue.mapped_values).find(key => cValue.mapped_values[key] === state["input_change_"+cIndex]);	
						}
						dataRow.label = cKey
						dataRow.output_label = outputLabel
						dataRow.output_field = cValue.output_field
						dataRow.value = state["input_change_"+cIndex]
					}
				} else {
					if(state["selected_value_"+cIndex] && state["selected_value_"+cIndex].length) {
						dataRow.label = cKey
						dataRow.output_field = cValue.output_field
						dataRow.type = state["includes_"+cIndex]
						dataRow.value = state["selected_value_"+cIndex]
					}
				}
				
				if(Object.entries(dataRow).length) {
					selectedFilters.push(dataRow)
				}
			})

			setState(prevState => ({ ...prevState, selectedFilters, callFormSelectedFitlersFunction: false }))
		}
	}, [state.callFormSelectedFitlersFunction, state])

	//on change filter items

	const handleClickOutside = (event) => {
		if (providerRef.current && !providerRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isProviderOpen: false }));
        } else if (providerRef.current) {
            setState(prevState => ({ ...prevState, isProviderOpen: true }));
        }
        if (accountRef.current && !accountRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isAccountOpen: false }));
        } else if (accountRef.current) {
            setState(prevState => ({ ...prevState, isAccountOpen: true }));
        }
        if (regionRef.current && !regionRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isRegionOpen: false }));
        } else if (regionRef.current) {
            setState(prevState => ({ ...prevState, isRegionOpen: true }));
        }
		if (sourceRef.current && !sourceRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isSourceOpen: false }));
        } else if (sourceRef.current) {
            setState(prevState => ({ ...prevState, isSourceOpen: true }));
        }
		if (granularityRef.current && !granularityRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isGranularityOpen: false }));
        } else if (granularityRef.current) {
            setState(prevState => ({ ...prevState, isGranularityOpen: true }));
        }

		if (tagsRef.current &&  !tagsRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showTagFilter: false }))
		} else if (tagsRef.current) {
			setState(prevState => ({ ...prevState, showTagFilter: true }))
		}
		
		if (tagKeyRef.current && !tagKeyRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, isTagKeyOpen: false }))
		} else if (tagKeyRef.current) {
			setState(prevState => ({ ...prevState, isTagKeyOpen: true }))
		}

		if (tagValueRef.current && !tagValueRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, isTagValueOpen: false }))
			addNewTag("tags")
		} else if (tagValueRef.current) {
			setState(prevState => ({ ...prevState, isTagValueOpen: true }))
		}

		if (durationRef.current && !durationRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDateRangePicker: false }))
		}

		if (searchRef && !searchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDropdown: false }))
		}
	}

	const refreshFilters = (row) => {		
		let savedFilters = row.filters
		if(row["Duration"]) {
			setState(prevState => ({ 
				...prevState,
				datePickerStartDate: row["Duration"].start_time,
				datePickerEndDate: row["Duration"].end_time
			}))
		}

		if(row["Tags"]) {
			setState(prevState => ({ ...prevState, selectedTags: row["Tags"].value, tagsSelected: row["Tags"].value }))
		}


		let costFilters = state.costFilters

		costFilters && Object.entries(costFilters).forEach(([cKey, cValue], cIndex) => {
			if(cValue.hasOwnProperty("mapped_values") || cValue.hasOwnProperty("values")) {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value }))
				} else {
					setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
				}
			} else {
				if(savedFilters.filter(e => e.output_field === cValue.output_field).length) {
					setState(prevState => ({ 
						...prevState, 
						["input_change_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].value, 
						["includes_"+cIndex]: savedFilters.filter(e => e.output_field === cValue.output_field)[0].type
					}))
				} else {
					if(cValue.output_type === "string") {
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: "" }))
					} else {
						setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))
						setState(prevState => ({ ...prevState, ["input_change_"+cIndex]: [] }))
					}
				}
			}

			if(Object.entries(costFilters).length - 1 === cIndex) {
				setState(prevState => ({ 
					...prevState, 
					durationActive: false,
					// selectedFilters: [],
					refereshFilters: true,
				}))}
		})
	}

	useEffect(() => {
		if(state.refereshFilters) {
			setState(prevState => ({ ...prevState, refereshFilters: false, callFormSelectedFitlersFunction: true, callCostGetCostTrendFunction: true }))}
	}, [state.refereshFilters])

	const onClickMore = (row) => {
		setState(prevState => ({ ...prevState, savedFilters: row, templateType: "view", clickedMore: true }))
	}

	useEffect(() => {
		if(state.clickedMore){
			setState(prevState => ({ ...prevState, clickedMore: false, showTemplatesSection: true }))
		}
	}, [state.clickedMore])

	const handleSearchChange = e => {
        const { name, value } = e.target;
		// let params = {}
		// params.template_name = value
		// if(value.length > 3) {
		// 	dispatch(listCostTemplates(params))
		// 		.then((response) => {
		// 			if(response) {					
		// 				setState(prevState => ({ ...prevState, searchTemplateList: response }))
		// 			}
		// 		})
		// }

		setState(prevState => ({ ...prevState, [name]: value, searchResultState: [], isLoading: true }))
	}

	/**
	 * Renders
	 */
	return (
		<div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={ (event) => handleClickOutside(event) }>
			{/* <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div> */}
			{state.showTemplatesSection ?
				<SavedFiltersSidePanel
					closeSidePanel={() => setState(prevState => ({ ...prevState, showTemplatesSection: false, savedFilters: [], callListCostTemplatesFunction: true }))}
					templateType={state.templateType}
					selectedSource={state.selectedSource}
					selectedFilters={state.savedFilters && Object.entries(state.savedFilters).length ? state.savedFilters : state.selectedFilters}
				/>
			: null}
            <div className='col-sm-12 p-0'>
                <div className='row text-white mx-0 pb-2 bg-nero'>
                    <div className='col-lg-6 col-6 py-2'>
						<h6 className='text-white m-0'>Cost Explorer</h6>
						<p className='text-white m-0'>New cost and usage report</p>
					</div>
					                    
                    <div className='col-lg-6 col-6 py-2'>
                        <div className='d-flex mx-0 topBasicFilter justify-content-end'>
                            <div className='col-2 pl-0'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild zIndex1`} ref={providerRef}>
									<label className='mb-2 mt-1 f10 d-flex'>Provider</label>
									<p className={`d-flex mt-2 mb-0 ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>{state.selectedProvider ? state.selectedProvider : 'Select'}</p>
									{state.isProviderOpen ?
										<MultiSelectSection 
											fields={["provider_name", "provider_name"]}
											options={state.providers}
											selectedValues={state.selectedProvider ? state.selectedProvider : ''}
											callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedProvider: value, isProviderOpen: false }))}
											singleSelection={true}
										/>
									: null}
								</div>
							</div>
							<div className='col-2 pl-0'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild`} ref={sourceRef}>
									<label className='mb-2 mt-1 f10 d-flex'>Source</label>
									<p className={`d-flex mt-2 mb-0 ${state.selectedSource ? 'text-white' : 'placeholder'}`}>{state.selectedSource ? capitalizeAllLetter(state.selectedSource) : 'Select'}</p>
									{state.isSourceOpen ?
										<MultiSelectSection 
											// fields={["account_id", "account_name"]}
											options={state.costSources && Object.keys(state.costSources)}											
											selectedValues={state.selectedSource ? state.selectedSource : []}
											callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedSource: value, callOnChangeSourceFunction: true, isSourceOpen: false }))}
											singleSelection={true}
											makeLabelUpperCase={'capitalizeAllLetter'}
										/>
									: null}
								</div>
							</div>
							<div className='col-2 pl-0'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} ref={accountRef}>
									<label className='mb-2 mt-1 f10 d-flex'>Account</label>
									<p className={`d-flex mt-2 mb-0 ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</p>
									{state.isAccountOpen ?
										<MultiSelectSection 
											fields={["account_id", "account_name"]}
											options={state.accounts}
											selectedValues={state.selectedAccount ? state.selectedAccount : []}
											callbackMultiSelect={(value) => {
												setState(prevState => ({ ...prevState, selectedAccount: value }))
												clearFilter()
											}}
										/>
									: null}
								</div>
							</div>
							<div className='col-2 pl-0'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild`} ref={regionRef}>
									<label className='mb-2 mt-1 f10 d-flex'>Region</label>
									<p className={`d-flex mt-2 mb-0 ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</p>
									{state.isRegionOpen ?
										<MultiSelectSection 
											fields={["region", "name"]}
											options={state.regions}
											selectedValues={state.selectedRegion ? state.selectedRegion : []}
											callbackMultiSelect={(value) => {
												setState(prevState => ({ ...prevState, selectedRegion: value }))
												clearFilter()
											}}
										/>
									: null}
								</div>
							</div>
							<div className='col-2 pl-0'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild`} ref={granularityRef}>
									<label className='mb-2 mt-1 f10 d-flex'>Granularity</label>
									<p className={`d-flex mt-2 mb-0 ${state.selectedGranularity ? 'text-white' : 'placeholder'}`}>{state.selectedGranularity ? capitalizeAllLetter(state.selectedGranularity) : 'Select'}</p>
									{state.isGranularityOpen ?
										<MultiSelectSection 
											fields={["value", "label"]}
											options={state.granualrityOptions}
											selectedValues={state.selectedGranularity ? state.selectedGranularity : []}
											callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedGranularity: value, callOnChangeSourceFunction: true, isGranularityOpen: false }))}
											singleSelection={true}
											makeLabelUpperCase={'capitalizeAllLetter'}
										/>
									: null}
								</div>
							</div>
							<div className='col-2 pl-0'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} ref={tagsRef}>
									<div className="">
										<label>Tags</label>
										<p className='mb-0' style={{paddingTop: "0.6rem"}}>
											{state.selectedTags && state.selectedTags.length ?
												<span>{state.selectedTags.length} selected</span>
											:
												<span className="placeholder">Select</span>
											}
										</p>
									</div>
									{state.showTagFilter ?
										<div className="costPopupTagSection border-mediumDarkGray mt-1" style={{ right: "20%" }}>
											<div className="p-3 bg-muted">
												<div className={`w-100 mt-3`}>
													<p className="mb-2 w-100 text-white">Tags</p>
													<div className={`d-flex`}>
														<label className="mb-1 w-40">Key</label>
														<label className="mb-1 w-40 ml-3">Value</label>
													</div>
													<div className={`w-100`}>
														<div className={`d-flex `}>
															<React.Fragment>
																<div className='w-40 mt-1 boderDottedBottom mr-2' ref={tagKeyRef}>
																	<p className={`d-flex mt-3 mb-0 ${state.tag_key ? 'text-white' : 'placeholder'}`}>{state.tag_key ? state.tag_key : 'Select'}</p>
																	{state.isTagKeyOpen ?
																		<div className='position-relative'>
																			<MultiSelectSection 
																				// fields={["account_id", "account_name"]}
																				className={'border-0'}
																				removeTop={true}
																				options={tagKeys}
																				selectedValues={state.tag_key}
																				callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_key: value, isTagKeyOpen: false, tagValues: [] }))}
																				singleSelection={true}
																			/>
																		</div>
																	: null}
																</div>
															</React.Fragment>
															{state.tag_key && state.tag_key !== "" && state.tagValues && !state.tagValues.length ?
																<div className='ml-3'>
																	<Spinner className='text-center align-self-center' color='light' size='sm' />
																</div>
															:
																<React.Fragment>
																	<div className='w-40 mt-1 boderDottedBottom' ref={tagValueRef}>
																		<p className={`d-flex mt-3 mb-0 ${state.tag_value && state.tag_value.length ? 'text-white' : 'placeholder'}`}>{state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}</p>
																		{state.isTagValueOpen ?
																			<div className='position-relative'>
																				<MultiSelectSection 
																					// fields={["account_id", "account_name"]}
																					className={'border-0'}
																					removeTop={true}
																					options={state.tagValues}
																					selectedValues={state.tag_value ? state.tag_value : []}
																					callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
																				/>
																			</div>
																		: null}
																	</div>
																	<span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => addNewTag("tags")}></span>
																</React.Fragment>
															}
														</div>
													</div>
												</div>
												{state.tagsSelected && state.tagsSelected.length ? 
													state.tagsSelected.map((tag, index) => {
														return(
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2 hiddenEllipses" style={{maxWidth: "350px"}}>{tag.key +" : "+tag.value}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeTagSection(tag) } ></i>
															</span>
														)
													})
												: null}
												<div className="d-flex mt-3">
													<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => setState(prevState => ({ ...prevState, tagsSelected: [], selectedTags: [], tag_key: "", tag_value: [], tagValues: [] }))}>Clear</span>
													<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => setState(prevState => ({ ...prevState, showTagFilter: false, selectedTags: state.tagsSelected, callFormSelectedFitlersFunction: true }))}>Select</button>
												</div>
											</div>
										</div>
									: null}
								</div>
							</div>
							<div className='col-3 pl-0'>
								<div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: true }))}>
									<label className='mb-2 mt-1 f10 d-flex'>Duration</label>
									<p className={`d-flex mt-2 mb-0 ${state.datePickerStartDate ? 'text-white' : 'placeholder'}`}>
										{state.datePickerStartDate ?
											<span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
										: 'Select'}
									</p>
								</div>
								{state.showDateRangePicker ?
									<div className="bg-muted border-lightGray p-3 position-absolute" style={{"z-index": "999999999","right": "20px","height": "500px"}} ref={durationRef}>
										<div className="RangePickerWithStartEndTime text-black5 pt-2 ml-n1">
											<div className='d-flex justify-content-between'>
												<p className="mb-2 w-100 text-white">Date Picker</p>
												<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}></i>
											</div>
											<div className='headerDateRange' style={{'z-index': '999999999','right': '30px', 'top': '50px'}}>
												<DateRange
													editableDateInputs={true}
													onChange={handleSelect}
													moveRangeOnFirstSelection={false}
													ranges={dateState}
												/>
											</div>
											<div className={`d-flex flex-wrap pt-2 border-top mt-4`}>
												<span className={`mr-2 f12 align-self-center text-white`}>Last</span>
												<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+7d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }))}>1 Week</span>
												<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+14d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }))}>2 Week</span>
												<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+30d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }))}>1 Month</span>
												<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+60d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }))}>2 Month</span>
												<span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+90d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }))}>3 Months</span>
											</div>
											<div className='d-flex justify-content-end pt-2 mt-2 border-top'>
												<button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}>Cancel</button>
											</div>
										</div>
									</div>
								: null}
							</div>
						</div>
					</div>
				</div>
				
                <div className='row mx-0'>
					<div className='col-lg-12 bg-transparent m-2'>
						<div className='m-2 d-flex'>
							<div className='ml-2 w-60'>
								<p className='font-weight-bold m-0 f15 text-white'>Trends</p>
								{/* <p className="mb-0 small text-lightGray">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when	 an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
								{state.forcastMessage && state.forcastMessage !== "" ?
									<h6 className='text-danger mb-1'>{state.forcastMessage}</h6>
								: null}
								{!state.showSlectedFilters ?
									<div className='d-flex pt-2 justify-content-between'>
										<div className='d-flex'>
											{state.selectedFilters && state.selectedFilters.length ? 
												<React.Fragment>
													{state.selectedFilters.filter(e => e.label === "Account").length ?
														<div className='d-flex mr-2'>
															<small className="font-weight-bold align-self-center cursorPointer">Account : {state.selectedFilters.filter(e => e.label === "Account")[0].value} </small>
															{/* {state.selectedFilters.filter(e => e.label === "Account").length > 1 ?
																<span className="text-info mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => this.setState({ showSlectedFilters: !state.showSlectedFilters })}>more</span>
															: null} */}
														</div>
													: 
														<small className="font-weight-bold align-self-center cursorPointer mr-2">Account : <span className='text-info'>All</span></small>
													}	

													{state.selectedFilters.filter(e => e.label === "Region").length ?
														<div className='d-flex mx-2'>
															<small className="font-weight-bold align-self-center cursorPointer">Region : {state.selectedFilters.filter(e => e.label === "Region")[0].value} </small>
															{/* {state.selectedFilters.filter(e => e.label === "Region")[0].value.length > 1 ?
																<span className="text-fino mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => this.setState({ showSlectedFilters: !state.showSlectedFilters })}>more</span>
															: null} */}
														</div>
													: 
														<small className="font-weight-bold align-self-center cursorPointer mx-2">Region : <span className='text-info'>All</span></small>
													}
													{state.selectedFilters.filter(e => e.label === "Granularity").length ?
														<div className='d-flex ml-2'>
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Granularity : <span className="text-info">{state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label ? state.selectedFilters.filter(e => e.label === "Granularity")[0].output_label : state.selectedFilters.filter(e => e.label === "Granularity")[0].value}</span> </small>
														</div>
													: null}
													{state.selectedFilters.filter(e => e.label === "Group by").length ?
														<div className='d-flex ml-2'>
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Group by : <span className="text-info">{state.selectedFilters.filter(e => e.label === "Group by")[0].output_label ? state.selectedFilters.filter(e => e.label === "Group by")[0].output_label : state.selectedFilters.filter(e => e.label === "Group by")[0].value}</span> </small>
														</div>
													: null}
													{state.selectedFilters.filter(e => e.label === "Duration").length ?
														<div className='d-flex ml-2'>
															<small className="font-weight-bold align-self-center cursorPointer mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(state.selectedFilters.filter(e => e.label === "Duration")[0].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.selectedFilters.filter(e => e.label === "Duration")[0].end_time, 'DD MMM YYYY')}</span> </small>
														</div>
													: null}
												</React.Fragment>
											: null}
										</div>
										{state.selectedFilters && state.selectedFilters.length ? 
											<p className="text-cyon f14 mb-0 font-weight-bold align-self-center cursorPointer mr-2 ml-4" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>{state.selectedFilters.length} Filters Selected</p>
										: null}
									</div>
								: null}
								{state.showSlectedFilters ?
									<div className="bg-darkGray p-3">
										<div className='d-flex justify-content-between mb-1'>
											<p className="mb-0 w-100 text-white">Selected Filters</p>
											<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showSlectedFilters: false }))}></i>
										</div>
										{/* {state.datePickerStartDate ? 
											<div className='d-flex'>
												<p className="mb-1 mr-2">Date</p>
												<span className='text-info'> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
											</div>
										: null}
										
										{state.tagsSelected && state.tagsSelected.length ? 
											<div className=''>
												<p className="mb-1">Tags</p>
												{state.tagsSelected.map((tag, index) => {
													return(
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => this.removeTagSection(tag) } ></i>
														</span>
													)
												})}
											</div>
										: null} */}
										<div className='d-flex flex-wrap'>
											{!state.selectedFilters.filter(e => e.label === "Account").length ?
												<small className="font-weight-bold align-self-center cursorPointer mr-2 mt-2">Account : <span className='text-info'>All</span></small>
											: null}

											{!state.selectedFilters.filter(e => e.label === "Region").length ?
												<small className="font-weight-bold align-self-center cursorPointer mx-2 mt-2">Region : <span className='text-info'>All</span></small>
											: null}
											
											{state.selectedFilters && state.selectedFilters.length ? 
												state.selectedFilters.map((filters, index) => {
													return(
														filters.label === "Duration" ?
															<React.Fragment>
																<small className="font-weight-bold align-self-center cursorPointer mt-2 mr-2">Duration : <span className='text-info'> {momentDateGivenFormat(filters.start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(filters.end_time, 'DD MMM YYYY')}</span> </small>
															</React.Fragment>
														: filters.value && !Array.isArray(filters.value) ?
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2"><span className='text-primary-color'>{filters.label+" : "}</span>{filters.output_label ? filters.output_label : filters.value}</span>
														: filters.label === "Tags" ?
															<div className='d-flex'>
																{filters.value.map(row => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																			<span className='text-primary-color'>Tag :</span> 
																			{row.key +" : "+row.value}
																		</span>
																	)
																})}
															</div>
														:
															<div className='d-flex'>
																{filters.value.map(row => {
																	return(
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																			<span className='text-primary-color'>{filters.type+" : "}</span> 
																			{filters.label +" : "+row}
																		</span>
																	)
																})}
															</div>
													)
												})
											: null}
										</div>
									</div>
								: null}
							</div>
							<div className='ml-2 w-40 align-self-center'>
								<div className='d-flex justify-content-end mt-2'>
									<span className='text-info cursorPointer mt-3' onClick={() => setState(prevState => ({ ...prevState, showTemplatesSection: true, templateType: "template" }))}>View Templates</span>
								</div>
								<div className="input-group search-drop dark-feild mt-1 placeholderForLightGray">
									<div className="input-group-prepend">
										<div className="input-group-text bg-lightGray">
											<i className="fal fa-search text-white"></i>
										</div>
									</div>
									<input
										ref={searchRef}
										type='text'
										name={"tempSearchText"}
										value={state.tempSearchText}
										onChange={handleSearchChange}
										// onChange={e => {
										// 	setState(prevState => ({ ...prevState, tempSearchText: e.target.value, searchResultState: [], isLoading: true }))
										// }}
										className='form-control form-control-sm bg-lightGray text-white'
										placeholder='Search Template'
										onClick={(e => setState(prevState => ({ ...prevState, showDropdown: true })))}
									/>
									
									<div className={`search-suggestion bg-darkGray`}>
										{state.showDropdown ?
												state.searchResultState && state.searchResultState.length ? 
												state.searchResultState.map((row, index) => {
													return (
														<div key={index} className={`cursorPointer ${index ? 'border-mediumDarkGray-top' : ''} p-2`}>
															<div className="w-100" onClick={() => {
																clearFilter()
																refreshFilters(row)
															}}>
																<span className='font-weight-bold m-0 text-white text-left p-0'>{row.template_name ? row.template_name : ''}</span>
																<span className='small m-0 text-white text-left p-0 text-primary-color'>{row.description ? " : "+row.description : ''}</span>
															</div>
															<div className='d-flex justify-content-between'>
																<div className="d-flex flex-wrap mt-2 w-100 align-self-center text-primary-color" onClick={() => {
																	clearFilter()
																	refreshFilters(row)
																}}>
																	{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Account").length ?
																		<div className='d-flex mr-2'>
																			<small className="font-weight-bold align-self-center cursorPointer text-white">Account : {row.filters.filter(e => e.label === "Account")[0].value} </small>
																			{row.filters.filter(e => e.label === "Account").length > 1 ?
																				<span className="text-info mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
																			: null}
																		</div>
																	: 
																		<small className="font-weight-bold align-self-center cursorPointer mr-2 text-white">Account : <span className='text-info'>All</span></small>
																	}	

																	{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Region").length ?
																		<div className='d-flex mx-2'>
																			<small className="font-weight-bold align-self-center cursorPointer text-white">Region : {row.filters.filter(e => e.label === "Region")[0].value} </small>
																			{row.filters.filter(e => e.label === "Region")[0].value.length > 1 ?
																				<span className="text-fino mx-2 mt-2 align-self-center f10 px-2 cursorPointer" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: !state.showSlectedFilters }))}>more</span>
																			: null}
																		</div>
																	: 
																		<small className="font-weight-bold align-self-center cursorPointer mx-2 text-white">Region : <span className='text-info'>All</span></small>
																	}
																	{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Granularity").length ?
																		<div className='d-flex ml-2'>
																			<small className="font-weight-bold align-self-center cursorPointer mr-2 text-white">Granularity : <span className="text-info">{row.filters.filter(e => e.label === "Granularity")[0].output_label ? row.filters.filter(e => e.label === "Granularity")[0].output_label : row.filters.filter(e => e.label === "Granularity")[0].value}</span> </small>
																		</div>	
																	: null}
																	{Array.isArray(row.filters) && row.filters.filter(e => e.label === "Group by").length ?
																		<div className='d-flex ml-2'>
																			<small className="font-weight-bold align-self-center cursorPointer mr-2 text-white">Group by : <span className="text-info">{row.filters.filter(e => e.label === "Group by")[0].output_label ? row.filters.filter(e => e.label === "Group by")[0].output_label : row.filters.filter(e => e.label === "Group by")[0].value}</span> </small>
																		</div>
																	: null}
																	{row["Duration"] ?
																		<div className='d-flex ml-2'>
																			<small className="font-weight-bold align-self-center cursorPointer mr-2 text-white">Duration : <span className='text-info'> {momentDateGivenFormat(row["Duration"].start_time, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(row["Duration"].end_time, 'DD MMM YYYY')}</span> </small>
																		</div>
																	: null}
																</div>
																{Array.isArray(row.filters) && row.filters.length > 3 ?
																	<span className="badge badge-outline-info mr-2 align-self-center f10 px-2" onClick={() => onClickMore(row)}>more</span>
																: null}
															</div>
															{/* <div className="w-20 align-self-center">
																<span className="badge badge-outline-success mr-1">123</span>
																<span className="badge badge-outline-danger">123</span>
															</div> */}
															{/* <div className="w-10 align-self-center">
																<i class={`fal fa-star ${item.favourite ? 'fillStar' : 'displayNone' } `}></i>
															</div> */}
														</div>
													)
												})
											: state.searchResultState && !state.searchResultState.length ? 
												<div className="cursorPointer border-bottom p-2" >
													<div className="w-100 text-white"> No Templates</div>
												</div>
											:
												state.showDropdown && state.isLoading && (
													<div className='d-flex justify-content-center m-4'>
														<Spinner className='text-center' color='dark' size='sm' />
													</div>
												) 
										: null}
									</div>
								</div>
							</div>
						</div>
						<div className='d-flex mx-2 overflowYAuto vh100'>
							<div className={`${state.hideFilterPanel ? "w-95" : "w-80"} pl-0 mr-2 overflowYAuto vh100`}>
								<div className='bg-black d-flex border flex-column minHeight300 p-3'>
									{state.trendData && state.trendData[0] ?
										<div className='d-flex justify-content-between'>
											<div className=''>
												<p className='mb-0'>Total Cost</p>
												<p className='mb-0 f20 text-white'>
													${state.trendData[0].total && state.trendData[0].total}
												</p>
											</div>
											<div className='border-left pl-2'>
												<p className='mb-0'>Average Cost</p>
												<p className='mb-0 f20 text-white'>
													${state.trendData[0].avg && state.trendData[0].avg}
												</p>
											</div>
											{/* <div className='border-left pl-2'>
												<p className='mb-0'>Forecast Cost</p>
												<p className='mb-0 f20 text-white'>
													${state.trendData[0].avg && state.trendData[0].avg}
												</p>
											</div> */}
										</div>
									: null}
									{state.trendGraphData && Object.entries(state.trendGraphData).length ?
										<ApexStackedBarChart
											tooltipType={"dontConvertDate"}
											graphData={state.trendGraphData}
											sparkline={false}
											yaxis={true}
											yaxisLabel={true}
											xaxis={true}
											xaxisFormat={state.granualrity === "monthly" ? "category" : state.granualrity === "hourly" ? "datetime" : 'date'}
											xaxisLabel={true}
											axisLabelFontSize={'9px'}
											axisLabelColor={'#B8BBBE'}
											paddingLeft={-5}
											legend={true}
											stacked={true}
											height={450}
											horizontal={false}
											barHeight={'40%'}
											barEndShape={'flat'}
											columnWidth={state.granualrity === "monthly" ? '5%' :  ""}
											gradient={true}
											gradientColor={['#039BE5', '#5F5BA2']}
											hideTooltipValue={true}
											backgroundBarShape={'flat'}
											backgroundBarColors={['#333947']}
											showBackgroundBarColors={true}
											className={"transparentTooltip"}
											// colors={["#EDDCD2", "#FFF1E6", "#FDE2E4", "#FAD2E1", "#C5DEDD", "#DBE7E4", "#F0EFEB", "#D6E2E9", "#BCD4E6", "#99C1DE", "#CDB4DB"]}
											colors={state.forecast && !state.forcastMessage ? state.transColor : state.colors}
											forecast={state.forecast && !state.forcastMessage ? true : false}
										/>
									: null}
								</div>
								{state.trendTable ?
									<div className="mt-3">
										<div className="d-flex justify-content-between mt-3 py-2">
											<p className="mb-0 align-self-center">Showing {state.filteredArray && state.filteredArray.length} of total {state.trendTable && state.trendTable.length} Cost and usage breakdown</p>
											{state.trendTable && state.trendTable.length ?
												<div className="d-flex">
													<CSVLink
														data={state.filteredArray ? state.filteredArray : []} 
														headers={state.headers ? state.headers : []}
														filename={"cost-explorers.csv"}
														className={"mt-2"}
														target="_blank"
													>
														<span className="d-flex text-primary-color mr-2">
															<i className="fas fa-download text-primary-color mr-2"></i>
															{/* csv */}
														</span>
													</CSVLink>
													<div className={`mb-n2`}>
														<Search
															data={state.trendTable ? state.trendTable : []}
															filteredData={filteredArray => {
																setState(prevState => ({ ...prevState, filteredArray }))
															}}
															topClassName={'bg-mediumDarkGray align-self-center w-80 mr-1 border-lightGray rounded-5 f12'}
															searchClassName={'px-2 f12'}
															searchIconColor={'text-white f12'}
															searchPlaceHolder={'Search....'}
															className={"bg-transparent text-white pl-0 form-control-sm f12"}
														/>
													</div>
												</div>
											: null}
										</div>
										<div className='stickyTable'>
											<StickyDarkThemeTable
												data={state.filteredArray.length ? state.filteredArray : state.filteredArray}
												columns = { state.columns }
												onClickRow={tableRow => {}}
												dashboard = {state.filteredArray.length ? true : false}
												//tooltipFor={[]}
												marginRight={10}
                                                tableHead={'bg-black text-white'}
                                                tableBody={'bg-white'}
                                                tableType={'table-light-hover'}
                                                perPageClass={`bg-transparent text-lightGray`}
                                                textColor={'text-black'}
                                                selectedColor={'bg-backGroundGray'}
                                                sortByColumn={"anomaly_time"}
                                                riskTooltip={[]}
											/>
										</div>
									</div>
								: null}
							</div>
							<div className={`${state.hideFilterPanel ? "w-5" : "w-20"} bg-black p-3 overflowYAuto vh100`}>
								<div className="d-flex justify-content-between mb-2">
									<p className="font-weight-bold mb-1 f14" onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: !state.hideFilterPanel }))}>Filters</p>
										{/* {state.selectedFilters && state.selectedFilters.length ? 
											<small className="text-blue font-weight-bold align-self-center cursorPointer mr-2" onClick={ () => this.setState({ showSlectedFilters: !state.showSlectedFilters })}>Selected Filters</small>										
										: null} */}
										{state.hideFilterPanel ?
											<i className="fa fa-caret-left align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: false }))}></i>
										: 
											<div className='d-flex'>
												<p className="text-cyon font-weight-bold align-self-center cursorPointer mr-2" onClick={ () => setState(prevState => ({ ...prevState, showTemplatesSection: !state.showTemplatesSection, templateType: "save" }))}>Save Template</p>
												<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, hideFilterPanel: true }))}></i>
											</div>
										}
								</div>
								{!state.hideFilterPanel ?
									<React.Fragment>
									<div className="d-flex mb-2 justify-content-between">
										{state.applyLoading ?
											<button className="btn btn-success">
												Applying <Spinner size='sm' color='light' className='ml-2' />
											</button>
										: 
											<button className={`d-flex border-0 justify-content-end align-self-center cursorPointer btn-sm ${state.applyLoading ? 'disabled btn-primary' : 'btn-primary'}`} 
											onClick={ () => setState(prevState => ({ ...prevState, applyLoading: true, callCostGetCostTrendFunction: true }))}>Apply</button>
										}
										<p className="text-cyon font-weight-bold align-self-center f13 cursorPointer" onClick={ () => clearFilter()}>CLEAR ALL</p>
									</div>

									<div className="zapInputDark">
										{state.costFilters && Object.entries(state.costFilters).map(([cKey, cValue], cIndex) => {
											return(
												<div key={'cosFIl_'+cIndex} className="w-100 mb-2">
													<div className="position-relative d-inline-block w-100">
														<div 
															className={`form-control f14 bg-darkGray border-lightGray text-white`}
															readOnly={true}
															onClick={() => onClickGetDropOptions(cKey, cValue, cIndex)}
														>
															<div className='d-flex justify-content-between'>
																{state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && state["selected_value_"+cIndex].length ?
																	state["selected_value_"+cIndex].length +" "+cKey
																: !state["selected_value_"+cIndex] || (state["selected_value_"+cIndex] && Array.isArray(state["selected_value_"+cIndex]) && !state["selected_value_"+cIndex].length) ?
																	"Choose "+cKey																
																: state["selected_value_"+cIndex] ?
																	cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
																		Object.entries(cValue.mapped_values).find(([key, val]) => val === state["selected_value_"+cIndex]) && Object.entries(cValue.mapped_values).find(([key, val]) => val === state["selected_value_"+cIndex]).length ? 
																			Object.entries(cValue.mapped_values).find(([key, val]) => val === state["selected_value_"+cIndex])[0]
																		: state["selected_value_"+cIndex]
																	: state["selected_value_"+cIndex]
																: null}


																{state["showDetails_"+cIndex] ?
																	<i className="fa fa-caret-up"></i>
																: 
																	<i className="fa fa-caret-down"></i>
																}
															</div>
														</div>

														{state["showDetails_"+cIndex] ?
															cValue.mapped_values && Object.entries(cValue.mapped_values).length ?
																<div className='bg-darkGray' style={{border: "3px solid #383E4D"}}>
																	{Object.entries(cValue.mapped_values).map(([mRow, mValue], mIndex) => {
																		return(
																			<div key={'map_'+mIndex} className={`d-flex flex-wrap pt-2 pb-1 cursorPointer ${state["input_change_"+cIndex] === mValue ? "bg-white" : "" } ${Object.entries(cValue.mapped_values).length - 1 !== mIndex ? "border-lightGray-bottom" : ""}`} onClick={() => onChangeSelectInput(cValue.mapped_values, mValue, "input_change", mIndex, cIndex)}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === mValue ? "text-info" : "text-white" }`}>{mRow}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															: cValue.mapped_values && !Object.entries(cValue.mapped_values).length && cValue.values ?
																<div className='bg-darkGray' style={{border: "3px solid #383E4D"}}>
																	{cValue.values.map((vRow, vIndex) => {
																		return(
																			<div className={`d-flex flex-wrap pt-2 pb-1 cursorPointer ${state["input_change_"+cIndex] === vRow ? "bg-white" : "" } ${Object.entries(cValue.mapped_values).length - 1 !== vIndex ? "border-lightGray-bottom" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === vRow ? "text-info" : "text-white" }`} onClick={() => onChangeSelectInput(cValue.values, vRow, "input_change", vIndex, cIndex)}>{vRow}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															: cValue.output_type === "string" ?
																<div className='bg-darkGray' style={{border: "3px solid #383E4D"}}>
																	{state["filteredDropOptions_"+cIndex].map((row, sIndex) => {
																		return(
																			<div className={`d-flex flex-wrap pt-2 pb-1 cursorPointer ${state["input_change_"+cIndex] === row ? "bg-white" : "" } ${state["filteredDropOptions_"+cIndex].length - 1 !== sIndex ? "border-lightGray-bottom" : ""}`}>
																				<div style={{ display: "inline" }} className={`mr-2`}>
																					<div className="form-check">
																						<p className={`form-check-label mb-0 mr-2  ${state["input_change_"+cIndex] === row ? "text-info" : "text-white" }`} onClick={() => onChangeSelectInput(cValue.values, row, "input_change", sIndex, cIndex)}>{row}</p>
																					</div>
																				</div>
																			</div>
																		)
																	})}
																</div>
															:
																<div className='bg-darkGray' style={{border: "3px solid #383E4D"}}>
																	<div style={{ display: "inline" }} className="mb-1">
																		<div className="form-check ml-2">
																			<input type="radio"
																				onChange={() => setState(prevState => ({ ...prevState, ["includes_"+cIndex]: "Includes" }))}
																				className="form-check-input"
																				checked={state["includes_"+cIndex] === "Includes" ? true : false}
																			/>
																			<p className="form-check-label mb-0 mr-2 text-white">Includes</p>
																		</div>
																	</div>
																	<div style={{ display: "inline" }} className="mb-2 ">
																		<div className="form-check ml-2">
																			<input type="radio"
																				onChange={() => setState(prevState => ({ ["includes_"+cIndex]: "Excludes" }))}
																				className="form-check-input"
																				checked={state["includes_"+cIndex] === "Excludes" ? true : false}
																			/>
																			<p className="form-check-label mb-0 text-white">Excludes</p>
																		</div>
																	</div>
																	{state["hideDropLoading_"+cIndex]  ?
																		state["dropOptions_"+cIndex] ?
																			<React.Fragment>
																			<div contenteditable="true" 
																				className={`form-control bg-mediumDarkGray contentEditable text-white h-auto f14 mt-2`}
																				placeholder="search..."
																				id={"search_"+cIndex}
																				onKeyUp={e => searchData(e, e.currentTarget.textContent, cIndex, state["dropOptions_"+cIndex])}
																			></div>
																			<div className={`topAuto mt-2 bg-darkGray p-2`}>
																				{state["dropOptions_"+cIndex].length === state["filteredDropOptions_"+cIndex].length ?
																					<div className="d-flex flex-wrap border-lightGray-bottom">
																						<div style={{ display: "inline" }} className="mr-2 align-self-end">
																							<div className="form-check">
																								<input type="checkbox"
																									onChange={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}
																									className="form-check-input"
																									checked={state["select_all_"+cIndex]}
																								/>
																								<p className="form-check-label mb-0 mr-2 text-white" onClick={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], "All", "input_change", "", cIndex)}>Select All({state["filteredDropOptions_"+cIndex].length})</p>
																							</div>
																						</div>
																					</div>
																				: null}
																				{state["filteredDropOptions_"+cIndex].map((row, index) => {
																					return(
																						<div className={`d-flex flex-wrap pt-2 ${state["filteredDropOptions_"+cIndex].length - 1 !== index ? "border-lightGray-bottom" : ""}`}>
																							<div style={{ display: "inline" }} className="mr-2 align-self-end">
																								<div className="form-check">
																									<input type="checkbox"
																										onChange={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], row, "input_change", index, cIndex)}
																										className="form-check-input"
																										checked={state["input_change_"+cIndex+"_"+index]}
																									/>
																									<p className="form-check-label mb-0 mr-2 text-white" onClick={() => onChangeMultiSelectInput(state["dropOptions_"+cIndex], row, "input_change", index, cIndex)}>{row}</p>
																								</div>
																							</div>
																						</div>
																					)
																				})}
																			</div>
																			</React.Fragment>
																		: null
																	: !state["hideDropLoading_"+cIndex] ?
																		<div className='d-flex justify-content-center m-4'>
																			<Spinner className='text-center' color='dark' size='sm' />
																		</div>
																	: null}
																</div>
															: null
														}
													</div>
												</div>
											)
										})}
									</div>
									<div className='p-3 bg-darkGray my-3'>
										<div className="form-check align-self-center">
											<input type="checkbox" className="form-check-input" id="forecastCheck" />
											<input 
												type="checkbox" 
												className="form-check-input" 
												id="forecastCheck" 
												checked={state.forecast}
												onChange={e => setState(prevState => ({ ...prevState, forecast: state.forecast ? false : true }))}
											/>
											<label className="form-check-label f16 cursorPointer text-white" onClick={() => setState(prevState => ({ ...prevState, forecast: state.forecast ? false : true }))}>Forecast Data</label>
										</div>
									</div>

									<div className="d-flex mb-2 justify-content-between">
										{state.applyLoading ?
											<button className="btn btn-success">
												Applying <Spinner size='sm' color='light' className='ml-2' />
											</button>
										: 
											<button className={`d-flex justify-content-end cursorPointer btn-sm ${state.applyLoading ? 'disabled btn-primary' : 'btn-primary'}`} 
											onClick={ () => setState(prevState => ({ ...prevState, applyLoading: true, callCostGetCostTrendFunction: true }))}>Apply</button>
										}
										{/* <small className="text-blue font-weight-bold align-self-center f13 cursorPointer" onClick={ () => this.clearFilter()}>CLEAR ALL</small> */}
									</div>
									</React.Fragment>
								: null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default LandingPage