/*************************************************
 * Collider
 * @exports
 * @file ClusterDetailsSidePanel.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import Search from '../common/SearchComponent'

import { momentConvertionUtcToLocalTime, capitalizeTheFirstLetterOfEachWord, removeUnderScore, isValidDateTime, convertToLowerCase } from '../../utils/utility';
import { listStackEvents } from '../../actions/CatalogAction';

const ClusterDetailsSidePanel = ({ selectedClusterDetails, closeSidePanel, detailsFormat }) => {
    // const accounts = useSelector(state => state.filters.accounts);
    // const regions = useSelector(state => state.filters.regions);

    const [state, setState] = useState({
        showLoading: false,
        selectedTab: 'Details',
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
        if (state.selectedTab === "Events") {
            let params = {}
            params.provider = selectedClusterDetails && selectedClusterDetails.provider
            params.account_id = selectedClusterDetails && selectedClusterDetails.account_id
            params.region = selectedClusterDetails && selectedClusterDetails.region
            params.cluster_name = selectedClusterDetails && selectedClusterDetails.cluster_name
            // Fetch job metrics data here using params and update the state
            if(params.cluster_name) {
                dispatch(listStackEvents(params))
                    .then((response) => {
                        if(response) {
                            let results = response.results ? response.results : []
                            let totalPages = 1
                            if(results.length > state.perPage) {
                                totalPages = Math.ceil(results.length / state.perPage)
                            }
                            setState(prevState => ({
                                ...prevState,
                                listEvents: results,
                                filteredListEvents: results,
                                showEventsLoading: false,
                                totalPages,
                            }));
                        }
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    listEvents: [],
                    filteredListEvents: [],
                    showEventsLoading: false,
                }))
            }
        }
    }, [state.selectedTab, dispatch, selectedClusterDetails, state.perPage])

    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }}>
            <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div>
            <div className="search-content bg-muted w-70">
                <div className="header-search bd-highlight bg-black d-flex justify-content-between p-2">
                    <div className="flex-fill bd-highlight text-white">
                        <h5>Cluster Details</h5>
                        <div className="mr-2 d-flex">
                        </div>
                    </div>

                    <div className="text-right flex-fill bd-highlight align-self-center">
                        <i className="far fa-times fa-lg text-white cursor-pointer" onClick={() => closeSidePanel()}></i>
                    </div>
                </div>
                <div className={`px-2`}>
                    <ul className={`nav nav-tabs bg-transparent text-white shadow-none border-bottom-black5`} id="myTab" role="tablist">
                        <li className="nav-item">
                            <span className={`nav-link text-white cursor-pointer ${state.selectedTab === 'Details' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link text-white cursor-pointer ${state.selectedTab === 'Events' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Events', showEventsLoading: true }))}>Events</span>
                        </li>
                    </ul>
                </div>
                <div className='m-2 overflow-auto '>
                    {state.selectedTab === "Details" ? 
                        <div className="rounded bg-dark3 p-3">
                            {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div className={`${!index ? '' : 'mt-2'}`}>
                                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small>{row.description}</small>
                                        {row.fields && row.fields.map(fld => {
                                            return(
                                                <React.Fragment>
                                                    {fld === "queue_configuration" ?
                                                        selectedClusterDetails[fld] && selectedClusterDetails[fld].map((item, iIndex) => {
                                                            return(
                                                            <div className={`rounded bg-dark2 p-2 mb-2 ${!iIndex ? 'mt-2' : ''}`}>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Subnet IDs</span>
                                                                    <div calss="d-flex flex-wrap">
                                                                        {item && item.subnet_ids ?
                                                                            item.subnet_ids.map(ids => {
                                                                                return(
                                                                                    <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{ids}</span>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Security Group IDs</span>
                                                                    <div calss="d-flex flex-wrap">
                                                                        {item && item.security_group_ids ?
                                                                            item.security_group_ids.map(ids => {
                                                                                return(
                                                                                    <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{ids}</span>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Policies</span>
                                                                    <div calss="d-flex flex-wrap">
                                                                        {item && item.policies ?
                                                                            item.policies.map(pol => {
                                                                                return(
                                                                                    <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{pol}</span>
                                                                                )
                                                                            })
                                                                        : null}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Script Location</span>
                                                                    <span className={`align-self-center text-white`}>{item && item.on_node_configured_script ? item.on_node_configured_script : ""}</span>
                                                                </div>

                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Volume Details</p>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Type</span>
                                                                    <span className={`align-self-center text-white`}>{item && item.volume_type ? item.volume_type : ""}</span>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Size</span>
                                                                    <span className={`align-self-center text-white`}>{item && item.volume_size && item.volume_size}</span>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Encryption</span>
                                                                    <span className={`align-self-center text-white`}>{item && item.volume_encrypted ? "True" : "False"}</span>
                                                                </div>

                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Bucket</span>
                                                                    <span className={`align-self-center text-white`}>{item && item.s3_access && item.s3_access.bucket_name ? item.s3_access.bucket_name : ""}</span>
                                                                </div>
                                                                <div className="d-flex mt-3">
                                                                    <span className="minWidth220 mr-3 align-self-center">Write Access</span>
                                                                    <span className={`align-self-center text-white`}>{item && item.s3_access && item.s3_access.enable_s3_write_access ? "True" : "False"}</span>
                                                                </div>

                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>Compute Resources</p>
                                                                {item && item.compute_resources ?
                                                                    item.compute_resources.map(comp => {
                                                                        return (
                                                                            <div className='bg-dark p-2 rounded mb-2'>
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Name</span>
                                                                                <span className={`align-self-center text-white`}>{comp && comp.compute_resource_name ? comp.compute_resource_name : ""}</span>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Instance Type</span>
                                                                                <span className={`align-self-center text-white`}>{comp && comp.instance_type && comp.instance_type.length ? comp.instance_type[0] : ""}</span>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Min Instance Count</span>
                                                                                <span className={`align-self-center text-white`}>{comp && comp.min_instance_count && comp.min_instance_count}</span>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Max Instance Count</span>
                                                                                <span className={`align-self-center text-white`}>{comp && comp.max_instance_count && comp.max_instance_count}</span>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Efa Enabled</span>
                                                                                <span className={`align-self-center text-white`}>{comp && comp.efa_enabled ? "True" : "False"}</span>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Placement Group Enabled</span>
                                                                                <span className={`align-self-center text-white`}>{comp && comp.placement_group_enabled ? "True" : "False"}</span>
                                                                            </div>
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Disable Simultaneous Multithreading</span>
                                                                                <span className={`align-self-center text-white`}>{comp && comp.disable_simultaneous_multithreading ? "True" : "False"}</span>
                                                                            </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null}
                                                                <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                                                {item && item.s3_access ?
                                                                    <div className="p-3 rounded bg-dark">
                                                                    {item.s3_access.map((buk, bukIndex) => {
                                                                        return(
                                                                            <div className={`d-flex ${(bukIndex && bukIndex+1 !== item.s3_access.length) ? 'mb-2' : ''}`}>
                                                                            <div className="">
                                                                                <span className="minWidth220 mr-3 align-self-center">Bucket</span>
                                                                                <span className={`align-self-center text-white`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">Write Access</span>
                                                                                <span className={`align-self-center text-white`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                                                            </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                    </div>
                                                                : null}
                                                            </div>
                                                            )
                                                        })
                                                    : fld === "storage_configuration" && selectedClusterDetails[fld]  ?
                                                       selectedClusterDetails[fld].map(storage => {
                                                            return (
                                                                Object.entries(storage).length ?
                                                                    Object.entries(storage).map(([key, value]) => {
                                                                        return(
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-white`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-white">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`align-self-center badge bg-mulbery text-white`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div calss="d-flex flex-wrap">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div calss="d-flex flex-wrap">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-white">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null
                                                            )
                                                        })
                                                    : fld === "head_node" || fld === "cluster_configuration" ?
                                                        selectedClusterDetails[fld] && Object.entries(selectedClusterDetails[fld]).length ?
                                                            Object.entries(selectedClusterDetails[fld]).map(([key, value]) => {
                                                                return(
                                                                    key === 's3_access' ?
                                                                        <React.Fragment>
                                                                        <p className={`mb-1 mt-2 text-purple f18 pb-1 border-bottom`}>S3 Access</p>
                                                                        <div className="p-3 rounded bg-dark">
                                                                            {value.map((buk, bukIndex) => {
                                                                                return(
                                                                                    <div className={`d-flex ${(bukIndex && bukIndex+1 !== value.length) ? 'mb-2' : ''}`}>
                                                                                    <div className="">
                                                                                        <span className="minWidth220 mr-3 align-self-center">Bucket</span>
                                                                                        <span className={`align-self-center text-white`}>{buk.bucket_name ? buk.bucket_name : ""}</span>
                                                                                    </div>
                                                                                    <div className="ml-3">
                                                                                        <span className="minWidth220 mr-3 align-self-center">Write Access</span>
                                                                                        <span className={`align-self-center text-white`}>{buk.enable_s3_write_access ? "True" : "False"}</span>
                                                                                    </div>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                        </React.Fragment>
                                                                    :
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">{removeUnderScore(key)}</span>
                                                                            {typeof value === 'boolean' ?
                                                                                <span className={`text-white`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-white">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`align-self-center badge bg-mulbery text-white`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div calss="d-flex flex-wrap">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div calss="d-flex flex-wrap">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-white">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                )
                                                            })
                                                        : null
                                                    : fld === "database_configuration" ?
                                                    selectedClusterDetails[fld] && Array.isArray(selectedClusterDetails[fld]) ? 
                                                        selectedClusterDetails[fld].map(dat => {
                                                            return(
                                                                Object.entries(dat).length ?
                                                                    Object.entries(dat).map(([key, value]) => {
                                                                        return(
                                                                            <div className="d-flex mt-3">
                                                                                <span className="minWidth220 mr-3 align-self-center">{removeUnderScore(key)}</span>
                                                                                {typeof value === 'boolean' ?
                                                                                    <span className={`text-white`}>{value ? 'True' : 'False'}</span>
                                                                                : isValidDateTime(value) ?
                                                                                    <span className="text-white">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                                : convertToLowerCase(key) === "status" ?
                                                                                    <span className={`align-self-center badge bg-mulbery text-white`}>{value}</span>
                                                                                : convertToLowerCase(key) === "tags" ?
                                                                                    <div calss="d-flex flex-wrap">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : Array.isArray(value) ?
                                                                                    <div calss="d-flex flex-wrap">
                                                                                        {value.map(item => {
                                                                                            return(
                                                                                                <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item}</span>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                : 
                                                                                    <span className="text-white">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                : null
                                                            )
                                                            })
                                                        : 
                                                            selectedClusterDetails[fld] && Object.entries(selectedClusterDetails[fld]).length ?
                                                                Object.entries(selectedClusterDetails[fld]).map(([key, value]) => {
                                                                    return(
                                                                        <div className="d-flex mt-3">
                                                                            <span className="minWidth220 mr-3 align-self-center">{removeUnderScore(key)}</span>
                                                                            {typeof value === 'boolean' ?
                                                                                <span className={`text-white`}>{value ? 'True' : 'False'}</span>
                                                                            : isValidDateTime(value) ?
                                                                                <span className="text-white">{momentConvertionUtcToLocalTime(value, "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                                            : convertToLowerCase(key) === "status" ?
                                                                                <span className={`align-self-center badge bg-mulbery text-white`}>{value}</span>
                                                                            : convertToLowerCase(key) === "tags" ?
                                                                                <div calss="d-flex flex-wrap">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : Array.isArray(value) ?
                                                                                <div calss="d-flex flex-wrap">
                                                                                    {value.map(item => {
                                                                                        return(
                                                                                            <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item}</span>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            : 
                                                                                <span className="text-white">{key === 'provider' ? value.toUpperCase() : value}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            : null
                                                    : selectedClusterDetails && selectedClusterDetails[fld] && fld !== 'head_node' && fld !== "cluster_configuration" && fld !== "database_configuration" && fld !== "queue_configuration" && fld !== "storage_configuration" ?
                                                        <div className="d-flex mt-3">
                                                            <span className="minWidth220 mr-3 align-self-center">{removeUnderScore(fld)}</span>
                                                            {typeof selectedClusterDetails[fld] === 'boolean' ?
                                                                <span className={`text-white`}>{selectedClusterDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedClusterDetails[fld]) ?
                                                                <span className="text-white">{momentConvertionUtcToLocalTime(selectedClusterDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`align-self-center badge bg-mulbery text-white`}>{selectedClusterDetails[fld]}</span>
                                                            : convertToLowerCase(fld) === "tags" ?
                                                                <div calss="d-flex flex-wrap">
                                                                    {selectedClusterDetails[fld].map(item => {
                                                                        return(
                                                                            <span className='badge text-white newDetailBadge mr-2 mb-1 f13'>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : 
                                                                <span className="text-white">{fld === 'provider' ? selectedClusterDetails[fld].toUpperCase() : selectedClusterDetails[fld]}</span>
                                                            }
                                                        </div>
                                                    : null}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    : state.selectedTab === 'Events' ?
                        <div className="rounded bg-dark3 p-3">
                            <div className='m-2 flex-grow-1 overflow-auto vh100'>
                                {state.showEventsLoading ?
                                    <div className='d-flex justify-content-center m-4'>
                                        <Spinner className='text-center' color='light'   />
                                    </div>
                                : state.listEvents && state.listEvents.length ?
                                    <div className='bg-dark3 p-2 mx-2 rounded'>
                                        <div className="d-flex mb-2 justify-content-between">
                                            <div className="d-flex mr-2 w-70">
                                                <p className="f12 m-0 align-self-center pt-1">Showing {state.filteredListEvents && state.filteredListEvents.length} of total {state.listEvents && state.listEvents.length} event(s)</p>
                                            </div>
                                            <div className="d-flex justify-content-end w-30 mb-n2">
                                                <div className='d-flex'>
                                                    {state.listEvents && state.listEvents.length ?
                                                        <Search
                                                            data={state.listEvents ? state.listEvents : []}
                                                            applyTags={false}
                                                            applyLiteDarkTags={true}
                                                            topClassName={'bg-transparent align-self-center w-100 mr-1 border f12'}
                                                            searchClassName={'px-2 f12'}
                                                            searchIconColor={'text-lightGray f12'}
                                                            searchPlaceHolder={'Search....'}
                                                            className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                                            filteredData={(filteredListEvents) => {
                                                                let totalPages = 1
                                                                if(filteredListEvents.length > state.perPage) {
                                                                    totalPages = Math.ceil(filteredListEvents.length / state.perPage)
                                                                }
                                                                setState(prevState => ({ 
                                                                    ...prevState,  
                                                                    // refreshClusters: true,
                                                                    filteredListEvents,
                                                                    totalPages
                                                                }))
                                                                // setState(prevState => ({ ...prevState, filteredListEvents }))
                                                            }}
                                                        />
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                            {state.filteredListEvents && state.filteredListEvents.length > state.perPage ?
                                                <div className="pagination errorPagePagination f12">
                                                    <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                                    <button><i className={`fal fa-arrow-to-left cursor-pointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                                    <button><i className={`fal fa-angle-left cursor-pointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                                    {/* <span>
                                                        Go to
                                                        <input
                                                            type='number'
                                                            value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPage}
                                                            onChange={e => {
                                                                const page = e.target.value ? Number(e.target.value) : ""
                                                                gotoPage(page)
                                                            }}
                                                            className="ml-1 inputClass"
                                                        />
                                                    </span> */}
                                                    <button><i className={`fal fa-angle-right cursor-pointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                                    <button><i className={`fal fa-arrow-to-right cursor-pointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                                </div>
                                            : null}
                                        </div>
                                        {state.filteredListEvents && state.filteredListEvents.length ?
                                            state.filteredListEvents.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                                return(
                                                    <div key={"fil_"+i} className={`px-2 mb-2 pb-1 ${!i ? "" : "border-mediumDarkGray-top"}`}> 
                                                        <div className="d-flex cursor-pointer text-wrap">
                                                            <p className="mb-0">
                                                                <span className="mb-0 mr-2 text-white f12 font-weight-bold">{item.timestamp ? momentConvertionUtcToLocalTime(item.timestamp, 'DD MMM YYYY HH:mm') : ""} </span>
                                                                {item.resource_type ?
                                                                    <span className={`mb-0 mr-2 f12 ml-2`}>{item.resource_type}</span>
                                                                : null}
                                                                {item.resource_status ?
                                                                    <span className={`mb-0 mr-2 f12 text-info ml-2`}>{item.resource_status}</span>
                                                                : null}
                                                                {item.user_id ?
                                                                    <span className={`mb-0 mr-2 f12 ml-2`}>{item.user_id}</span>
                                                                : null}
                                                                {item.resource_status_reason ?
                                                                    <span className="mb-0 ml-2 f12 text-white font-weight-bold">{item.resource_status_reason}</span>
                                                                : null}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <div className='d-flex justify-content-center m-4'>
                                                There are no events on this criteria. Please try adjusting your filter.
                                            </div>
                                        }
                                    </div>
                                :
                                    <div className='d-flex justify-content-center m-4'>
                                        Therer are no events on this criteria.
                                    </div>
                                }
                            </div>
                        </div>
                    : null}
                </div>
            </div>
        </div>
    )
}

export default ClusterDetailsSidePanel