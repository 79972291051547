import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Input } from 'reactstrap'
import { capitalizeAllLetter, getAccountNameFromId, getRegionName, momentSlashConvertionLocalToUtcTime, noSpace, onlyNumeric } from '../../utils/utility';
import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../actions/commonActionNew'
import { getJobFilters, submitJob, getJobBudget } from '../../actions/Collider/JobStatusAction';
 import { Store as CommonNotification } from 'react-notifications-component';
import MultiSelectSection from '../common/MultiSelectSection';
import Files from '../hpc/files/file-list/index'

const CreateJobPanel = ({ closeSidePanel }) => {
    
    const [state, setState] = useState({
        showLoading: false,
        job_type: 'manually',
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: [],
        clusters: [],
        scheduleRadio: 'run_immediately',
        budgetDetails: false
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);
    const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
    const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        dispatch(setCommonPropsDetails('fileManageFilterChange', {}))
      }, [dispatch])

      useEffect(() => {
        if(state.selectedProvider && state.selectedAccount && state.selectedRegion && state.selectedCluster) {
            let obj = {
                selectedProvider: state.selectedProvider,
                selectedAccount: state.selectedAccount,
                selectedRegion: state.selectedRegion,
                selectedClusters: state.selectedCluster,
              }
            dispatch(setCommonPropsDetails('fileManageFilterChange', obj))
        }
      }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedCluster])

    // function jsonToObject(jsonString) {
    //     try {
    //       const parsedObject = JSON.parse(jsonString);
    //       return parsedObject;
    //     } catch (error) {
    //       console.error('Error parsing JSON:', error);
    //       return null;
    //     }
    // }
      
    // // Example usage:
    // const jsonString = '{"name":"John","age":30,"hobbies":["reading","gaming","coding"],"address":{"street":"123 Main St","city":"Exampleville"}}';
    
    // const myObject = jsonToObject(jsonString);
    
    // if (myObject !== null) {
    // console.log(myObject);
    // } else {
    // console.log('JSON parsing failed.');
    // }
      

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]);

    useEffect(() => {
        let params = {}
        if(providers) {
            setState(prevState => ({ ...prevState, providers, selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : '')
            }));
        } else {
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({ ...prevState, providers: response, selectedProvider: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''
                        }));
                    }
            })
        }
    }, [dispatch, providers])

    // Call provider based accounts whenever the selectedProviders state is updated
    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'account_id';
            let label = state.selectedProvider
			if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propProjAccounts ? propProjAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propProjAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, dispatch, propProjAccounts]);

    useEffect(() => {
        if(state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            params.aggregate_by = 'region';
            let label = state.selectedProvider
            if(state.selectedAccount) {
                label = state.selectedProvider+'_'+state.selectedAccount
                params.account_id = state.selectedAccount
            }

            if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
            } else {
                dispatch(listClusterFilters(params))
                    .then((response) => {
                        if(response && response.length) {
							let obj = propProjRegions ? propProjRegions : {}
							obj[label] = response
							dispatch(setCommonPropsDetails('propProjRegions', obj))
							setState(prevState => ({ ...prevState, regions: response }));
						}
                    })
            }
        }
    }, [state.selectedProvider, state.selectedAccount, dispatch, propProjRegions]);

    useEffect(() => {
        if(state.selectedCluster && state.job_type === 'manually') {        
            let jobParams = {}
            jobParams.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                jobParams.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                jobParams.region = state.selectedRegion
            }
            jobParams.cluster_name = state.selectedCluster
            
            dispatch(getJobFilters(jobParams))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            queues: response.queues ? response.queues : [],
                            licenses: response.licenses ? response.licenses : [],
                            qos: response.qos ? response.qos : [],
                            qosFilter: response.queue_qos_filters ? response.queue_qos_filters : {},
                            qosParameters: response.qos_parameters ? response.qos_parameters : {},
                            jobFilters: response
                        }));
                    }
            })
        }
    }, [state.selectedCluster, dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.job_type])

    useEffect(() => {
        if(state.selectedQueue) {
            let qosFilter = state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].qos ? state.qosFilter[state.selectedQueue].qos :[]
            let qosMessage =  state.qosFilter[state.selectedQueue] && state.qosFilter[state.selectedQueue].message ? state.qosFilter[state.selectedQueue].message : ''
            setState(prevState => ({
                ...prevState,
                qos: qosFilter,
                qosMessage,
            }));
        }
    }, [state.selectedQueue ,state.qosFilter])

    useEffect(() => {
        if(state.qos_name) {
            let qosParValue = state.qosParameters[state.qos_name] ? state.qosParameters[state.qos_name] : {}
            setState(prevState => ({
                ...prevState,
                qosParValue
            }));
        }
    }, [state.qos_name, state.qosParameters])

    useEffect(() => {
        if (state.selectedProvider && state.selectedAccount && state.selectedRegion) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion) {
                params.region = state.selectedRegion
            }
            params.aggregate_by = 'cluster_name'
            dispatch(listClusterFilters(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            clusters: response,
                            fetchingCluster: false
                        }));
                    }
            })
        }
    }, [dispatch, state.selectedProvider, state.selectedAccount, state.selectedRegion])
	
	const handleInputChange = (label, value) => {
        setState(prevState => ({ ...prevState, [label]: value }))
	}    

	const handleCommand = e => {		
        const { name, value } = e.target;
		setState(prevState => ({ ...prevState, [name]: value }))
	}

    useEffect(() => {
        if(state.getJobBudgetDetail) {
            setState(prevState => ({ ...prevState, getJobBudgetDetail: false }))

            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            let inputValidationError = false
            if(state.job_type && state.job_type === "manually") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.qos_name
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.qos_name || !state.total_tasks || !state.cpus_per_task || !state.total_nodes || !state.tasks_per_node || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                    params.licenses_count = parseInt(state.licenses_count)
                }
            }

            
    
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true }))

            if(!inputValidationError) {
                dispatch(getJobBudget(params))
                    .then((response) => {
                        if(response && !response.error) {
                            setState(prevState => ({ ...prevState, budgetDetails: response, saveLoading: false }))
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, budgetDetails: [] }))
                        }
                    })
            }

        }
    }, [state, dispatch])

    useEffect(() => {
        if(state.callSaveFunction) {
            let params = {}
            params.provider = state.selectedProvider.toLowerCase()
            params.account_id = state.selectedAccount
            params.region = state.selectedRegion
            params.cluster_name = state.selectedCluster
            params.job_budget = state.budgetDetails
            let inputValidationError = false
            if(state.job_type && state.job_type === "manually") {
                params.queue_name = state.selectedQueue
                params.job_name = state.job_name
                if(state.selectedProvider !== 'GCP') {
                    params.qos_name = state.qos_name
                    if(state.working_directory && state.working_directory !== '') {
                        params.working_directory = state.working_directory
                    }
                    params.total_tasks = parseInt(state.total_tasks)
                    params.cpus_per_task = parseInt(state.cpus_per_task)
                    params.total_nodes = parseInt(state.total_nodes)
                    params.tasks_per_node = parseInt(state.tasks_per_node)
                    if(state.memory && state.memory !== '') {
                        params.memory = parseInt(state.memory)
                    }
                    params.command = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue ||  !state.job_name || !state.qos_name || !state.total_tasks || !state.cpus_per_task || !state.total_nodes || !state.tasks_per_node || !state.command) {
                        inputValidationError = true
                    }
                } else {
                    params.cpus = parseInt(state.cpus)
                    params.job_script = state.command

                    if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.selectedQueue || !state.job_name || !state.cpus || !state.command) {
                        inputValidationError = true
                    }
                }
                // if(state.job_account && state.job_account !== '') {
                //     params.job_account = state.job_account
                // }
                if(state.walltime && state.walltime !== '') {
                    params.walltime = state.walltime
                }
                // params.unlimited_ram = state.unlimited_ram ? true : false 
                // params.environment_variables = state.environment_variables

            } else {
                params.job_script = state.job_script
                if(!state.selectedProvider || !state.selectedAccount || !state.selectedRegion || !state.selectedCluster || !state.job_script || state.job_script === "") {
                    inputValidationError = true
                }
            }
            if(state.scheduled_at) {
                params.run_immediately = false
                params.scheduled_at = momentSlashConvertionLocalToUtcTime(state.scheduled_at, "YYYY-MM-DD HH:mm:00")
            } else {
                params.run_immediately = true
            }
            if(state.selectedProvider === 'AWS') {
                if(state.selectedLincense) {
                    params.license_name = state.selectedLincense
                    if(state.licenses_count) {
                        params.licenses_count = parseInt(state.licenses_count)
                    } else {
                        inputValidationError = true
                    }
                }
    
                if(state.selectedApplication) {
                    params.application = state.selectedApplication
                }
            }
    
            setState(prevState => ({ ...prevState, inputValidationError, saveLoading: inputValidationError ? false : true, callSaveFunction: false }))
            
            if(!inputValidationError) {
                dispatch(submitJob(params))
                    .then((response) => {
                        let messageType = 'danger'
                        let message = response && response.message ? response.message : 'Error in creating job'
                        if(response && !response.error) {
                            if(response.status) {
                                messageType = 'success'
                                message = response.message ? response.message : 'Job created successfully'
                            }
                        }
        
                        CommonNotification.addNotification({
                            //title: 'Wonderful!',
                            message: message,
                            type: messageType,
                            insert: 'top',
                            container: 'top-center',
                            // animationIn: ['animate__animated', 'animate__fadeIn'],
                            // animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                            duration: 5000,
                            onScreen: false,
                            pauseOnHover: true,
                            showIcon: true,
                            }
                        });

                        setState(prevState => ({ ...prevState, budgetDetails: false }))
        
                        if(response && !response.error) {
                            if(response.status) {
                                setTimeout(() => closeSidePanel(), 1000)
                            } else {
                                setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, saveLoading: false, callSaveFunction: false, budgetDetails: false }))
                        }
                        // setState(prevState => ({ ...prevState, createRespons: response }))
                    })
    
            }
        }
    },  [state.callSaveFunction, closeSidePanel, dispatch, state])

    const onKeyDownTimeRange = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { //backspace
			str = str.slice(0, -1)
			validateDateTime(str, state)
		} else {
			validateDateTime(time, state)
		}
	}

    const validateDateTime = (dateTime, state) => {
		let str = dateTime.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
		let date = str.substring(0, 2)

        let scheduleAt = ''
        if(strLength > 1 && parseInt(date) > 31) {
			scheduleAt += '01/'
		} else if(strLength > 1 && parseInt(date) < 10) {
			if(date.length === 2) {
				scheduleAt = date+'/'
			} else {
				scheduleAt += '0'+date+'/'
			}
		} else if(strLength > 1 && parseInt(date) <= 31) {
			scheduleAt += date+'/'
		}

		if(scheduleAt === '') {
			scheduleAt = dateTime
		}
		
		let month = str.substring(2, 4)
		
		if(month.length) {
			if(strLength > 3 && parseInt(month) > 12) {
				scheduleAt += '01/'
			} else if(strLength > 3 && parseInt(month) > 10) {
				scheduleAt += month+'/'
			} else if(strLength > 3 && month.length === 2) {
				scheduleAt += month+'/'
			} else {
                scheduleAt += month
            }
		}
		
        let year = str.substring(4, 8)
		if(strLength > 4 && year.length < 4) {
            scheduleAt += year
		} else if(strLength > 5 && year.length === 4) {
            scheduleAt += year+' '
        }

		let firstHour = str.substring(8, 10)

		if(strLength > 8 && parseInt(firstHour) > 23) {
			scheduleAt += '00:'
		} else if(strLength > 9 && parseInt(firstHour) <= 23) {
            scheduleAt += firstHour+':'
		} else if(strLength > 8 && parseInt(firstHour) <= 23) {
			scheduleAt += firstHour            
        } 
		
		let firstMinute = str.substring(10, 12)
		
		if(firstMinute.length) {
			if(strLength > 10 && parseInt(firstMinute) > 59) {
				scheduleAt += '00'
			} else if(strLength > 10 && parseInt(firstMinute) > 10) {
				scheduleAt += firstMinute
			} else {
				scheduleAt += firstMinute
			}
		}

        setState(prevState => ({ ...prevState, [state]: scheduleAt }))

    }

    const onKeyDownTime = (e, time, state) => {
		let str = time.replace(/[^0-9]/gi,'')
		if(e.keyCode === 8) { //backspace
			str = str.slice(0, -1)
			validateTime(str, state)
		} else {
			validateTime(time, state)
		}
	}

    const validateTime = (dateTime, state) => {
		let str = dateTime.replace(/[^0-9]/gi,'')
		
		let strLength = str.length
        let scheduleAt = ''
		let firstHour = str.substring(0, 2)
		if(strLength > 1 && parseInt(firstHour) > 23) {
			scheduleAt += '00:'
		} else if(strLength > 2 && parseInt(firstHour) <= 23) {
            scheduleAt += firstHour+':'
		} else if(strLength > 1 && parseInt(firstHour) <= 23) {
			scheduleAt += firstHour            
        } else if(strLength === 1) {
			scheduleAt += firstHour            
        }
		
		let firstMinute = str.substring(2, 4)
		
		if(firstMinute.length) {
			if(strLength > 2 && parseInt(firstMinute) > 59) {
				scheduleAt += '00:'
			} else if(strLength > 2 && parseInt(firstMinute) > 10) {
				scheduleAt += firstMinute
			} else {
				scheduleAt += firstMinute
			}
		} 
		
		let firstSeconds = str.substring(4, 6)
		
		if(firstSeconds.length) {
			if(strLength > 4 && parseInt(firstSeconds) > 59) {
				scheduleAt += ':00'
			} else if(strLength > 4 && parseInt(firstSeconds) > 10) {
				scheduleAt += ":"+firstSeconds
			} else {
				scheduleAt += ":"+firstSeconds
			}
		}

        setState(prevState => ({ ...prevState, [state]: scheduleAt }))

    }

    const handleFocus = (event) => {
        // let name =  event.target.getAttribute('name');        
        // setState(prevState => ({ ...prevState, help_field: name, showMessage: true }))
    }
    const handleBlur = () => {
        setState(prevState => ({ ...prevState, showMessage: false, help_message: '', help_field: '' }))
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const content = event.target.result;
          setState(prevState => ({ ...prevState, job_script: content, showLocalFileOptions: false }))
        };
    
        reader.readAsText(file);
    };

	const handleChildClick = (event, type, dropdownType, section) => {

		event.stopPropagation();
        
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='advanced-search' style={{ zIndex: 999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                    <div className='spinner-item'></div>
                </div>
            </div>
            <div className='search-content bg-dark2 w-70'>
                <div className='header-search bd-highlight bg-black d-flex justify-content-between p-2'>
                    <div className='flex-fill bd-highlight text-white'>
                        <h5>Create Job</h5>
                        <div className='mr-2 d-flex'>
                        </div>
                    </div>

                    <div className='text-right flex-fill bd-highlight align-self-center'>
                        <i className='far fa-times fa-lg text-white cursorPointer' onClick={() => closeSidePanel()}></i>
                    </div>
                </div>
                <div className='m-2 overflowYAuto'>
                    <div className='rounded bg-dark3 p-3'>
                        <div className='d-flex mt-2'>
                            <div className='py-1 w-25'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Provider <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span></p>
                                    {state.inputValidationError && !state.selectedProvider ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showProvider")
                                }}>
                                    <p className={`d-flex justify-content-between mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedProvider ? state.selectedProvider : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showProvider ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["provider_name", "provider_name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.providers}
                                                selectedValues={state.selectedProvider ? state.selectedProvider : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, 
                                                        selectedProvider: value,
                                                        selectedAccount: "",
                                                        selectedRegion: "",
                                                        selectedCluster: "",
                                                        selectedQueue: "",
                                                        fetchingCluster: true,
                                                        selectedLincense: "",
                                                        selectedApplication: "",
                                                        licenses_count: "",
                                                        budgetDetails: false
                                                    }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={true}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 w-25 pl-3'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Account <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span></p>
                                    {state.inputValidationError && !state.selectedAccount ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showAccount")
                                }}>
                                    <p className={`d-flex justify-content-between mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.selectedAccount ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedAccount ? getAccountNameFromId(state.selectedAccount, state.accounts) : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showAccount ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["account_id", "account_name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.accounts}
                                                selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedAccount: value, selectedCluster: "", selectedQueue: "", qos_name: "", fetchingCluster: true, budgetDetails: false }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 w-25 pl-3'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Region <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span></p>
                                    {state.inputValidationError && !state.selectedRegion ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showRegion")
                                }}>
                                    <p className={`d-flex justify-content-between mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.selectedRegion ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedRegion ? getRegionName(state.selectedRegion, state.regions) : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showRegion ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                fields={["region", "name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.regions}
                                                selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedRegion: value, selectedCluster: "", selectedQueue: "", qos_name: "", fetchingCluster: true, budgetDetails: false }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                            <div className='py-1 w-25 pl-3'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Cluster Name<span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span></p>
                                    {state.inputValidationError && !state.selectedCluster ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <div onClick={(event) => {
                                    event.preventDefault();
                                    handleChildClick(event, 'child', 'singleDropDown', "showCluster")
                                }}>
                                    <p className={`d-flex justify-content-between mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.selectedCluster ? 'text-white' : 'placeholder'}`}>
                                        {state.selectedCluster ? state.selectedCluster : 'Select'}
                                        <i className='fa fa-caret-down mt-1'/>
                                    </p>
                                    {state.showCluster && !state.fetchingCluster ?
                                        <div className='position-relative'>
                                            <MultiSelectSection 
                                                // fields={["region", "name"]}
                                                className={'w-70'}
                                                removeTop={true}
                                                options={state.clusters}
                                                selectedValues={state.selectedCluster ? state.selectedCluster : []}
                                                callbackMultiSelect={(value) => {
                                                    setState(prevState => ({ ...prevState, selectedCluster: value, selectedQueue: "", qos_name: "", budgetDetails: false }))
                                                }}
                                                singleSelection={true}
                                                hideSearch={false}
                                            />
                                        </div>
                                    : null}
                                </div>
                            </div>
                        </div>
                        {state.selectedProvider !== 'GCP' ?
                            <div className={`d-flex ${state.job_type === "schedule_at" ? "mt-2" : "mt-4"} `}>
                                <p className="form-check-label mb-0 mr-2 text-white mt-2 align-self-center">Create </p>
                                <div style={{ display: "inline" }} className="mt-2 align-self-center">
                                    <div className="form-check ml-2">
                                        <input type="radio"
                                            onChange={() => setState(prevState => ({ ...prevState, job_type: "manually", scheduled_at: "" }))}
                                            className="form-check-input"
                                            checked={state.job_type === "manually" ? true : false}
                                        />
                                        <p className="form-check-label mb-0 mr-2 text-white">Manual Job</p>
                                    </div>
                                </div>
                                <div style={{ display: "inline" }} className="mt-2 align-self-center ">
                                    <div className="form-check ml-2">
                                        <input type="radio"
                                            onChange={() => setState(prevState => ({ ...prevState, job_type: "script" }))}
                                            className="form-check-input"
                                            checked={state.job_type === "script" ? true : false}
                                        />
                                        <p className="form-check-label mb-0 text-white">Script Job</p>
                                    </div>
                                </div>
                            </div>
                        : null}
                        {state.job_type === "manually"  ?
                            <React.Fragment>
                                <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                                    <div className={`py-1 ${state.selectedProvider !== 'GCP' ? 'w-33' : 'w-25'}`}>
                                        <div className='d-flex justify-content-between'>
                                            <p className="b-block mb-0">Queue Name
                                            <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                            </p>
                                            {state.inputValidationError && !state.selectedQueue ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </div>
                                        <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'singleDropDown', "showQueueName")
                                        }}>
                                            <p className={`d-flex justify-content-between mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.selectedQueue ? 'text-white' : 'placeholder'}`}>
                                                {state.selectedQueue ? state.selectedQueue : 'Select'}
                                                <i className='fa fa-caret-down mt-1'/>
                                            </p>
                                            {state.showQueueName ?
                                                <div className='position-relative'>
                                                    <MultiSelectSection 
                                                        // fields={["account_id", "account_name"]}
                                                        className={'w-70'}
                                                        removeTop={true}
                                                        options={state.queues}
                                                        selectedValues={state.selectedQueue ? state.selectedQueue : []}
                                                        callbackMultiSelect={(value) => {
                                                            setState(prevState => ({ ...prevState, selectedQueue: value, budgetDetails: false,  }))
                                                        }}
                                                        singleSelection={true}
                                                        hideSearch={false}
                                                    />
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    <div className='py-1 w-33 pl-3'>
                                        <p className='b-block mb-0 d-flex justify-content-between'>
                                            <span className="b-block mb-0">Job Name <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span></span>
                                            {state.inputValidationError && !state.job_name ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null}
                                        </p>
                                        <Input
                                            type='text'
                                            placeholder='Enter job name'
                                            className={`inputTextbox bg-transparent border-lightGray text-white`}
                                            // maxlength='128'
                                            value={state.job_name ? state.job_name : ''}
                                            onChange={e => {
                                                handleInputChange('job_name', noSpace(e.target.value))
                                                setState(prevState => ({ ...prevState, budgetDetails: false }))
                                            }}
                                        />
                                        {/* <Input type="text" name="directoryPath" placeholder="Enter directory path" />
                                        <Input type="file" name="fileToSave"/> */}
                                    </div>
                                </div>
                                {state.selectedProvider !== 'GCP' ?
                                    <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                                        <div className='py-1 w-33'>
                                            <div className='d-flex justify-content-between'>
                                                <p className="b-block mb-0">Qos Name
                                                    <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                </p>
                                                {state.inputValidationError && !state.qos_name ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </div>
                                            <div onClick={(event) => {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', "showQosName")
                                            }}>
                                                <p className={`d-flex justify-content-between mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.qos_name ? 'text-white' : 'placeholder'}`}>
                                                    {state.qos_name ? state.qos_name : 'Select'}
                                                    <i className='fa fa-caret-down mt-1'/>
                                                </p>
                                                {state.showQosName ?
                                                    <div className='position-relative'>
                                                        <MultiSelectSection 
                                                            // fields={["account_id", "account_name"]}
                                                            className={'w-70'}
                                                            removeTop={true}
                                                            options={state.qos}
                                                            selectedValues={state.qos_name ? state.qos_name : []}
                                                            callbackMultiSelect={(value) => {
                                                                setState(prevState => ({ ...prevState, qos_name: value, budgetDetails: false }))
                                                            }}
                                                            singleSelection={true}
                                                            hideSearch={false}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                            {state.qosMessage ?
                                                <p className="text-purplishRed my-1 align-self-center">{state.qosMessage}</p>
                                            : null}
                                        </div>
                                        <div className={`${state.selectedProvider !== 'GCP' ? '' : 'py-1'} w-33 pl-3`}>
                                            <p className='b-block mb-0'>Working Directory</p>
                                            <Input
                                                type='text'
                                                placeholder='Enter working directory'
                                                className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                value={state.working_directory ? state.working_directory : ''}
                                                name={'working_directory'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => handleInputChange('working_directory', e.target.value)}
                                            />
                                        </div>
                                    </div>
                                : null}
                                {state.showMessage && (state.help_field === 'qos_name' || state.help_field === 'working_directory') ?
                                    <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                : null}
                                {state.qosParValue && Object.entries(state.qosParValue).length ?
                                    Object.entries(state.qosParValue).map(([key, value], qIndex) => {
                                        return(
                                            <span key={"row_"+qIndex} className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{key+' : '+value}
                                            </span>
                                        )
                                    })
                                : state.selectedQueue && state.qos_name && state.qosParValue ?
                                    <p className="text-purplishRed my-1 align-self-center">QOS parameters not found</p>
                                : null}
                                {state.selectedProvider !== 'GCP' ?
                                    <React.Fragment>
                                    <p className='my-2 f16 text-white'>Parameters</p>
                                    <div className='d-flex pt-2 border-mediumDarkGray p-3 rounded'>
                                        <div className='py-1 w-25'>
                                            <p className='b-block mb-0 d-flex'>
                                                Total Tasks <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                {state.inputValidationError && !state.total_tasks ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </p>
                                            <Input
                                                type='number'
                                                placeholder='Enter total tasks'
                                                className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                // maxlength='128'
                                                value={state.total_tasks ? state.total_tasks : ''}
                                                name={'total_tasks'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    handleInputChange('total_tasks', e.target.value)
                                                    setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                }}
                                            />
                                        </div>
                                        <div className='py-1 w-25 pl-3'>
                                            <p className='b-block mb-0 d-flex'>
                                                CPUS Per Task <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                {state.inputValidationError && !state.cpus_per_task ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </p>
                                            <Input
                                                type='number'
                                                placeholder='Enter cpus per tasks'
                                                className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                // maxlength='128'
                                                value={state.cpus_per_task ? state.cpus_per_task : ''}
                                                name={'cpus_per_task'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    handleInputChange('cpus_per_task', e.target.value)
                                                    setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                }}
                                            />
                                        </div>
                                        <div className='py-1 w-25 pl-3'>
                                            <p className='b-block mb-0 d-flex'>
                                                Total Nodes <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                {state.inputValidationError && !state.total_nodes ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </p>
                                            <Input
                                                type='number'
                                                placeholder='Enter total nodes'
                                                className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                // maxlength='128'
                                                value={state.total_nodes ? state.total_nodes : ''}
                                                name={'total_nodes'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    handleInputChange('total_nodes', e.target.value)
                                                    setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                }}
                                            />
                                        </div>
                                        <div className='py-1 w-25 pl-3'>
                                            <p className='b-block mb-0 d-flex'>
                                                Tasks Per Node <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                {state.inputValidationError && !state.tasks_per_node ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </p>
                                            <Input
                                                type='number'
                                                placeholder='Enter takss per node'
                                                className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                // maxlength='128'
                                                value={state.tasks_per_node ? state.tasks_per_node : ''}
                                                name={'tasks_per_node'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    handleInputChange('tasks_per_node', e.target.value)
                                                    setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    </React.Fragment>
                                : null}
                                {state.showMessage && (state.help_field === 'total_tasks' || state.help_field === 'cpus_per_task' || state.help_field === 'total_nodes' || state.help_field === 'tasks_per_node') ?
                                    <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                : null}
                                <div className='d-flex mt-2 pt-2 border-darkGray-top'>
                                    {state.selectedProvider !== 'GCP' ?
                                        <div className='py-1 w-25'>
                                            <p className='b-block mb-0'>Memory (MB)</p>
                                            <Input
                                                type='number'
                                                placeholder='Enter memory'
                                                className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                // maxlength='128'
                                                value={state.memory ? state.memory : ''}
                                                name={'memory'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => handleInputChange('memory', e.target.value)}
                                            />
                                        </div>
                                    : 
                                        <div className='py-1 w-25'>
                                            <p className='b-block mb-0 d-flex'>
                                                Total CPUs <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                {state.inputValidationError && !state.cpus ?
                                                    <span className='mb-0 text-red ml-2'>required</span>
                                                : null}
                                            </p>
                                            <Input
                                                type='number'
                                                placeholder='Enter total tasks'
                                                className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                // maxlength='128'
                                                value={state.cpus ? state.cpus : ''}
                                                name={'cpus'}
                                                onFocus={handleFocus}
                                                onBlur={handleBlur}
                                                onChange={e => handleInputChange('cpus', onlyNumeric(e.target.value))}
                                            />
                                        </div>
                                    }
                                    <div className='py-1 w-25 pl-3'>
                                        <p className='b-block mb-0'>Time</p>
                                        <Input
                                            type='text'
                                            placeholder='HH:mm:ss'
                                            className={`inputTextbox bg-transparent border-lightGray text-white`}
                                            // maxlength='128'
                                            value={state.walltime ? state.walltime : ''}
                                            onKeyDown={e => onKeyDownTime(e, e.target.value, 'walltime')}
                                            onChange={e => validateTime(e.target.value, 'walltime')}
                                            name={'walltime'}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                                
                                {state.selectedProvider === 'AWS' ?
                                    <React.Fragment>
                                    <p className='my-2 f16 text-white'>Licenses</p>
                                    <div className='mt-2 pt-2 border-mediumDarkGray p-3 rounded'>
                                        <div className='d-flex'>
                                            <div className={`py-1 w-25`}>
                                                <div className='d-flex justify-content-between'>
                                                    <p className="b-block mb-0">Application</p>
                                                </div>
                                                <div onClick={(event) => {
                                                    event.preventDefault();
                                                    handleChildClick(event, 'child', 'singleDropDown', "showLicense")
                                                }}>
                                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.selectedLincense ? 'text-white' : 'placeholder'}`}>
                                                        {state.selectedApplication ? state.selectedApplication : 'Select'}
                                                        <i className='fa fa-caret-down mt-1'/>
                                                    </p>
                                                    {state.showLicense ?
                                                        <div className='position-relative'>
                                                            <MultiSelectSection 
                                                                fields={["license_name", "application"]}
                                                                className={'w-70'}
                                                                removeTop={true}
                                                                options={state.licenses}
                                                                selectedValues={state.selectedLincense ? state.selectedLincense : []}
                                                                callbackMultiSelect={(value) => {
                                                                    setState(prevState => ({ ...prevState, selectedLincense: value, selectedApplication: state.licenses.filter(e => e.license_name === value)[0].application, budgetDetails: false }))
                                                                }}
                                                                singleSelection={true}
                                                                hideSearch={false}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                            {state.selectedApplication ?
                                                <div className='py-1 w-33 pl-3'>
                                                    <p className='b-block mb-0 d-flex'>
                                                        Licenses Request Count <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                                        {state.inputValidationError && !state.licenses_count ?
                                                            <span className='mb-0 text-red ml-2'>required</span>
                                                        : null}
                                                    </p>
                                                    <Input
                                                        type='number'
                                                        placeholder='Enter licenses count'
                                                        className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                        // maxlength='128'
                                                        value={state.licenses_count ? state.licenses_count : ''}
                                                        onChange={e => {
                                                            setState(prevState => ({ ...prevState, budgetDetails: false }))
                                                            handleInputChange('licenses_count', onlyNumeric(e.target.value))
                                                        }}
                                                    />
                                                    {/* <Input type="text" name="directoryPath" placeholder="Enter directory path" />
                                                    <Input type="file" name="fileToSave"/> */}
                                                </div>
                                            : null}
                                        </div>
                                    </div>
                                    </React.Fragment>
                                : null}
                                {state.showMessage && (state.help_field === 'memory' || state.help_field === 'walltime') ?
                                    <p className='my-1 text-purplishRed'>{state.jobFilters && state.jobFilters[state.help_field] ? state.jobFilters[state.help_field] :''}</p>
                                : null}
                            </React.Fragment>
                        : null}
                        <div className={`d-flex ${state.scheduleRadio === "schedule_at" ? "mt-2" : "mt-4"} `}>
                            <div style={{ display: "inline" }} className="mt-2 align-self-center">
                                <div className="form-check ml-2">
                                    <input type="radio"
                                        onChange={() => setState(prevState => ({ ...prevState, scheduleRadio: "run_immediately", scheduled_at: "" }))}
                                        className="form-check-input"
                                        checked={state.scheduleRadio === "run_immediately" ? true : false}
                                    />
                                    <p className="form-check-label mb-0 mr-2 text-white">Run Immediately</p>
                                </div>
                            </div>
                            <div style={{ display: "inline" }} className="mt-2 align-self-center ">
                                <div className="form-check ml-2">
                                    <input type="radio"
                                        onChange={() => setState(prevState => ({ ...prevState, scheduleRadio: "schedule_at" }))}
                                        className="form-check-input"
                                        checked={state.scheduleRadio === "schedule_at" ? true : false}
                                    />
                                    <p className="form-check-label mb-0 text-white">Schedule At</p>
                                </div>
                            </div>
                            {state.scheduleRadio === "schedule_at" ?
                                <div className='py-1 w-20 pl-3'>
                                    <p className='b-block mb-0'>Schedule At</p>
                                    <div className='d-flex'>
                                        <Input
                                            type='text'
                                            placeholder='DD/MM/YYY HH:mm'
                                            className={`inputTextbox bg-transparent border-lightGray text-white`}
                                            // maxlength='128'
                                            value={state.scheduled_at ? state.scheduled_at : ''}
                                            // onChange={e => handleInputChange(index, 'scheduled_at', e.target.value)}
                                            onKeyDown={e => onKeyDownTimeRange(e, e.target.value, 'scheduled_at')}
                                            onChange={e => validateDateTime(e.target.value, 'scheduled_at')}
                                        />
                                    </div>
                                </div>
                            : null}
                        </div>
                                
                        {state.job_type === "manually" ?
                            <div className='d-flex mt-2 pt-2 border-darkGray-top mb-5'>
                                <div className='py-1 w-100 mb-5'>
                                    <div className='d-flex justify-content-between'>
                                        <p className='b-block mb-0'>
                                            Command<span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                        </p>
                                        {state.inputValidationError && !state.command ?
                                            <span className='mb-0 text-red ml-2'>required</span>
                                        : null}
                                    </div>
                                    <Input
                                        type='textarea'
                                        className='inputTextbox border-lightGray text-white bg-transparent'
                                        placeholder='Enter command'
                                        rows='4'
                                        id='comment'
                                        // maxlength='256'
                                        name="command"
                                        onChange={handleCommand}
                                        value={state.command}
                                    />
                                </div>
                            </div>
                        : null}                        
                        
                        {state.job_type !== "manually" ?
                            <div className='mt-2 pt-2 border-darkGray-top mb-5'>
                                <div className='d-flex justify-content-end py-1 w-100 mb-2'>
                                    <span className='mb-22 badge border-info text-white' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: !state.showLocalFileOptions }))}>{!state.showLocalFileOptions ? 'Open' : 'Close'} a Local File</span>
                                    <span className='mb-22 badge border-info text-white ml-3' onClick={() => setState(prevState => ({ ...prevState, showLocalFileOptions: false, showFileBrowser: !state.showFileBrowser }))}>Browse shared folder</span>
                                </div>

                                {state.showLocalFileOptions ?
                                    <div className='bg-black p-2'>
                                        <p className='text-white'>Plese choose a file from your system</p>
                                        <input className='m-4' type="file" onChange={handleFileChange} />
                                    </div>
                                : state.showFileBrowser ?
                                    <div className='file-browser bg-black py-2'>
                                        <Files 
                                            fromScreen="jobs"
                                            selectedFile={(fileContent) => {
                                                console.log('selectedFile', fileContent)
                                                let job_script = fileContent && fileContent.data ? fileContent.data : ''
                                                setState(prevState => ({ ...prevState, job_script, showFileBrowser: false }))
                                            }}
                                        />
                                    </div>
                                :
                                    <div className='py-1 w-100 mb-5'>
                                        <p className='b-block mb-0'>
                                            Job Script
                                            {/* {state.inputValidationError && !state.command ?
                                                <span className='mb-0 text-red ml-2'>required</span>
                                            : null} */}
                                        </p>
                                        <Input
                                            type='textarea'
                                            className='inputTextbox border-lightGray text-white bg-transparent'
                                            placeholder='Enter script'
                                            rows='25'
                                            id='comment'
                                            // maxlength='256'
                                            name="job_script"
                                            onChange={handleCommand}
                                            value={state.job_script}
                                        />
                                    </div>
                                }
                            </div>
                        : null}
                    </div>

                    <Modal isOpen={state.confirmAction} toggle={() => setState(prevState => ({ ...prevState, confirmAction: !state.confirmAction, deleteWorksatationDetails: {} }))}>
                        <ModalHeader toggle={() => setState(prevState => ({ ...prevState, confirmAction: !state.confirmAction, deleteWorksatationDetails: {} }))}>Confirm Action</ModalHeader>
                        <ModalBody>
                            <p className=''>Budget Detail for the selected Details</p>
                            <div className='mb-2 d-flex text-black'>
                                <p className='mr-2 w-30'>Type</p>
                                <p className='mr-3'>:</p>
                                <p className=''>{state.budgetDetails && state.budgetDetails.budget_type ? state.budgetDetails.budget_type : 0 }</p>
                            </div>
                            <div className='mb-2 d-flex text-black'>
                                <p className='mr-2 w-30'>Total&nbsp;Budget</p>
                                <p className='mr-3'>:</p>
                                <p className=''>{state.budgetDetails && state.budgetDetails.total_budget ? state.budgetDetails.total_budget : 0 }</p>
                            </div>
                            <div className='mb-2 d-flex text-black'>
                                <p className='mr-2 w-30'>Available&nbsp;Budget</p>
                                <p className='mr-3'>:</p>
                                <p className=''>{state.budgetDetails && state.budgetDetails.budget_used ? state.budgetDetails.budget_used : 0 }</p>
                            </div>
                            <div className='mb-2 d-flex text-black'>
                                <p className='mr-2 w-30'>Job&nbsp;Cost</p>
                                <p className='mr-3'>:</p>
                                <p className=''>{state.budgetDetails && state.budgetDetails.job_cost ? state.budgetDetails.job_cost : 0 }</p>
                            </div>
                        </ModalBody>
                        <ModalFooter className="border-0">
                        <Button color="primary" onClick={() => setState(prevState => ({ ...prevState, showSaveLoading: true, callSaveFunction: true }))}>
                            {state.showSaveLoading ?
                                <Spinner size='sm' color='light' className='mx-2' />
                            : null}Continue
                        </Button>{' '}
                        {!state.showSaveLoading ?
                            <Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, showSaveLoading: false,  budgetDetails: false, confirmAction: false }))}>
                                Cancel
                            </Button>
                        : null}
                        </ModalFooter>
                    </Modal>
                    <div className='fixed-buttons justify-content-end bg-transparent border-0'>
                        <div className={`bg-muted py-2 w-70`}>
                            {state.budgetDetails ?
                                <div>
                                    <div className='d-flex pl-4'>
                                        <div className='d-flex align-self-center mr-3 text-white'>
                                            <span className='mr-2'>Budget&nbsp;Type</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.budget_type ? state.budgetDetails.budget_type : 0 }
                                            </span>
                                        </div>
                                        <div className='d-flex align-self-center mr-3 text-white'>
                                            <span className='mr-2'>Total&nbsp;Budget</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.total_budget ? state.budgetDetails.total_budget : 0 }
                                                <span className='ml-1 f14 text-primary-color'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                        <div className='d-flex align-self-center mr-3 text-white'>
                                            <span className='mr-2'>Used&nbsp;Budget</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.budget_used ? state.budgetDetails.budget_used : 0 }
                                                <span className='ml-1 f14 text-primary-color'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                        <div className='d-flex align-self-center mr-3 text-white'>
                                            <span className='mr-2'>Available&nbsp;Budget</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.available_budget ? state.budgetDetails.available_budget : 0 }
                                                <span className='ml-1 f14 text-primary-color'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                        <div className='d-flex align-self-center mr-5 text-white'>
                                            <span className='mr-2'>Job&nbsp;Cost</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.job_cost ? state.budgetDetails.job_cost : 0 }
                                                <span className='ml-1 f14 text-primary-color'>{state.budgetDetails && state.budgetDetails.unit ? capitalizeAllLetter(state.budgetDetails.unit) : '' }</span>
                                            </span>
                                        </div>
                                    </div>                                    
                                    <div className='d-flex mt-2 pl-4'>
                                        <div className='d-flex align-self-center mr-3 text-white'>
                                            <span className='mr-2'>Total&nbsp;Licenses</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.total_licenses ? state.budgetDetails.total_licenses : 0 }
                                            </span>
                                        </div>
                                        <div className='d-flex align-self-center mr-3 text-white'>
                                            <span className='mr-2'>Used&nbsp;Licenses</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.licenses_used ? state.budgetDetails.licenses_used : 0 }
                                            </span>
                                        </div>
                                        <div className='d-flex align-self-center mr-5 text-white'>
                                            <span className='mr-2'>Available&nbsp;Licenses</span>
                                            <span className='mr-2'>:</span>
                                            <span className='text-warning'>
                                                {state.budgetDetails && state.budgetDetails.available_licenses ? state.budgetDetails.available_licenses : 0 }
                                            </span>
                                        </div>
                                    </div>
                                    {state.budgetDetails && state.budgetDetails.message ?
                                        <p className='pl-4 text-danger'>{state.budgetDetails.message}</p>
                                    : null}
                                </div>
                            : null}
                            <div className={`align-self-center d-flex justify-content-end`}>
                                {state.saveLoading ?
                                    <button className={`btn btn-primary mr-2`}>
                                        <Spinner size='sm' color='light' className='mx-2' /> 
                                        Submitting
                                    </button>
                                :
                                    <div className='d-flex'>
                                        {state.budgetDetails && state.budgetDetails ?
                                            <button className={`d-flex justify-content-end ${state.budgetDetails.job_submission ? '' : 'disabled'} cursorPointer btn bg-info text-white`} 
                                            onClick={ () => {
                                                if(state.budgetDetails.job_submission) {
                                                    setState(prevState => ({ ...prevState, saveLoading: true, callSaveFunction: true }))
                                                }
                                            }}>Continue</button>
                                        : !state.budgetDetails ?
                                            <button className={`d-flex justify-content-end cursorPointer btn bg-green text-white`} onClick={ () => setState(prevState => ({ ...prevState, saveLoading: true, getJobBudgetDetail: true }))}>Submit</button>
                                        : null}
                                        <button className={`ml-2 d-flex justify-content-end cursorPointer btn btn-light`} onClick={ () => closeSidePanel()}>Cancel</button>
                                    </div>
                                } 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateJobPanel;