import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { Input } from "reactstrap";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../common/MultiSelectSection";
import { validateVolumeName, validateMountDir } from "../../../utils/utility";

const ExistingStorageSection = (props) => {
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function
  const [state, setState] = useState({storageArray: [0]});

  const callPageVist = useCallback(() => {
    let storageConfiguration = createUpdateCluster?.storage_configuration || [];
    let storageArray = [0];
  
    if (storageConfiguration.length) {
      storageArray = [];
      storageConfiguration.forEach((row, index) => {
        storageArray.push(index);
        setState((prevState) => ({
          ...prevState,
          [`storage_type_${index}`]: row.storage_type,
          ...Object.entries(row).reduce((acc, [key, value]) => {
            acc[`${key}_${index}`] = value;
            return acc;
          }, {})
        }));
      });

      setState((prevState) => ({
        ...prevState,
        storageArray
      }));
    }
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const addSection = (array) => {
    let rowList = state[array];
    if (state[array]) {
      let value = state[array][state[array].length - 1];
      value = value + 1;
      rowList.push(value);

      setState((prevState) => ({ ...prevState, [array]: rowList }));
    }
  };

  const removeSection = (array, index, i) => {
    setState((prevState) => ({
      ...prevState,
      ["file_system_id_" + index]: "",
      ["storage_id_" + index]: "",
      ["storage_name_" + index]: "",
      ["filesystem_name_" + index]: "",
      ["mount_dir_" + index]: "",
      ["deletion_policy_" + index]: ""
    }));

    let rowList = state[array];
    rowList.splice(i, 1);
    setState((prevState) => ({ ...prevState, [array]: rowList }));

    // setTimeout(() => {
    //   handleStorageInput();
    // }, 5000);
  };

  const handleStorageInput = (index, label, value, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let storageConfiguration = obj.storage_configuration && obj.storage_configuration.length ?  obj.storage_configuration : [];

    state.storageArray.forEach((sIndex) => {
      if (!storageConfiguration.filter((e) => e.arrayIndex === sIndex).length) {
        let dataRow = { arrayIndex: sIndex, storage_type: props.storageType};
        storageConfiguration.push(dataRow);
      }
      let selectedStorageInput = storageConfiguration.filter((e) => e.arrayIndex === sIndex)[0];
     
      if (sIndex === index) {
        setState((prevState) => ({
          ...prevState,
          [label + "_" + sIndex]: value
        }));
      }
      let inputValue = sIndex === index ? value : state[label + "_" + sIndex];
      selectedStorageInput[label] = type === "integer" ? parseInt(inputValue) : inputValue;
    });
    obj["storage_configuration"] = storageConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div onClick={(event) => handleChildClick(event, "parent")}>
      {state.storageArray &&
        state.storageArray.map((index, j) => {
          return (
            <div className="bg-dark3 p-3 rounded mt-3">
              {props.storageType === "Efs" ? (
                <div className={"d-flex mt-3"}>
                  <div className="py-1 w-100">
                    <div className="w-40">
                      <div className="d-flex justify-content-between">
                        <label className="align-self-center m-0">
                          Filesystem Id
                        </label>
                        {state.hasError && !state["file_system_id_" + index] ? (
                          <small className="text-issues">required</small>
                        ) : null}
                      </div>
                      <div
                        className="border-lightGray bg-transparent rounded-5"
                        onClick={(event) => {
                          if (!state["isFileSystemId_" + index]) {
                            event.preventDefault();
                            handleChildClick(
                              event,
                              "child",
                              "singleDropDown",
                              "isFileSystemId_" + index
                            );
                          }
                        }}
                      >
                        <p
                          className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                            state["file_system_id_" + index] &&
                            props.pageMode === "Create"
                              ? "text-white"
                              : "placeholder"
                          }`}
                        >
                          {state["file_system_id_" + index]
                            ? propsClusterFilters.efs &&
                              propsClusterFilters.efs.filter(
                                (e) =>
                                  e.value === state["file_system_id_" + index]
                              ).length
                              ? propsClusterFilters.efs.filter(
                                  (e) =>
                                    e.value === state["file_system_id_" + index]
                                )[0].key
                              : state["file_system_id_" + index]
                            : "Select"}
                          <i className="fa fa-caret-down mt-1" />
                        </p>
                        {state["isFileSystemId_" + index] &&
                        props.pageMode === "Create" ? (
                          <MultiSelectSection
                            fields={["value", "key"]}
                            options={
                              propsClusterFilters && propsClusterFilters.efs
                                ? propsClusterFilters.efs.map((d) => ({
                                    key: d,
                                    value: d,
                                  }))
                                : []
                            }
                            selectedValues={
                              state["file_system_id_" + index]
                                ? state["file_system_id_" + index]
                                : ""
                            }
                            callbackMultiSelect={(value) => {
                              if (!value || typeof value === "string") {
                                handleStorageInput(
                                  index,
                                  "file_system_id",
                                  value
                                );
                                setState((prevState) => ({
                                  ...prevState,
                                  ["file_system_id_" + index]: value,
                                }));
                              } else {
                                value.preventDefault();
                                handleChildClick(
                                  value,
                                  "search",
                                  "singleDropDown",
                                  ""
                                );
                              }
                            }}
                            singleSelection={true}
                            hideSearch={false}
                            topClass={"auto"}
                            widthClass={"minWidth220"}
                          />
                        ) : null}
                      </div>
                    </div>
                    {propsClusterFilters &&
                    propsClusterFilters.description &&
                    propsClusterFilters.description.storage_configuration &&
                    propsClusterFilters.description.storage_configuration
                      .fsx_storage_id ? (
                      <p className="mt-1 mb-0 text-info">
                        {
                          propsClusterFilters.description.storage_configuration
                            .fsx_storage_id
                        }
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : props.storageType === "FsxOntap" ? (
                <React.Fragment>
                  <div className={"d-flex mt-3"}>
                    <div className="py-1 w-100">
                      <div className="w-40">
                        <div className="d-flex justify-content-between">
                          <label className="align-self-center m-0">
                            Storage Id
                          </label>
                          {state.hasError &&
                          !state["storage_id_" + index] ? (
                            <small className="text-issues">required</small>
                          ) : null}
                        </div>
                        <div
                          className="border-lightGray bg-transparent rounded-5"
                          onClick={(event) => {
                            if (!state["isFsxStorageId_" + index]) {
                              event.preventDefault();
                              handleChildClick(
                                event,
                                "child",
                                "singleDropDown",
                                "isFsxStorageId_" + index
                              );
                            }
                          }}
                        >
                          <p
                            className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                              state["storage_id_" + index] &&
                              props.pageMode === "Create"
                                ? "text-white"
                                : "placeholder"
                            }`}
                          >
                            {state["storage_id_" + index]
                              ? propsClusterFilters.fsx &&
                                propsClusterFilters.fsx.filter(
                                  (e) =>
                                    e.value ===
                                    state["storage_id_" + index]
                                ).length
                                ? propsClusterFilters.fsx.filter(
                                    (e) =>
                                      e.value ===
                                      state["storage_id_" + index]
                                  )[0].key
                                : state["storage_id_" + index]
                              : "Select"}
                            <i className="fa fa-caret-down mt-1" />
                          </p>
                          {state["isFsxStorageId_" + index] &&
                          props.pageMode === "Create" ? (
                            <MultiSelectSection
                              fields={["value", "key"]}
                              options={
                                propsClusterFilters && propsClusterFilters.fsx
                                  ? propsClusterFilters.fsx.map((d) => ({
                                      key: d,
                                      value: d,
                                    }))
                                  : []
                              }
                              selectedValues={
                                state["storage_id_" + index]
                                  ? state["storage_id_" + index]
                                  : ""
                              }
                              callbackMultiSelect={(value) => {
                                if (!value || typeof value === "string") {
                                  handleStorageInput(
                                    index,
                                    "storage_id",
                                    value
                                  );
                                  setState((prevState) => ({
                                    ...prevState,
                                    ["storage_id_" + index]: value,
                                  }));
                                } else {
                                  value.preventDefault();
                                  handleChildClick(
                                    value,
                                    "search",
                                    "singleDropDown",
                                    ""
                                  );
                                }
                              }}
                              singleSelection={true}
                              hideSearch={false}
                              topClass={"auto"}
                              widthClass={"minWidth220"}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"d-flex mt-3"}>
                    <div class="py-1 w-40">
                      <p className="b-block mb-0">Volume name</p>
                      <Input
                        type="text"
                        placeholder="Enter volume name (Eg. data)"
                        className={`inputTextbox bg-transparent border-lightGray ${
                          props.pageMode !== "Create"
                            ? "disabled text-primary-color"
                            : "text-white"
                        }`}
                        // maxlength='128'
                        value={
                          state["storage_name_" + index]
                            ? state["storage_name_" + index]
                            : ""
                        }
                        onChange={(e) => {
                          if (props.pageMode === "Create") {
                            handleStorageInput(
                              index,
                              "storage_name",
                              validateVolumeName(e.target.value)
                            );
                          }
                        }}
                      />
                      {propsClusterFilters &&
                      propsClusterFilters.description &&
                      propsClusterFilters.description.storage_configuration &&
                      propsClusterFilters.description.storage_configuration
                        .volume_name ? (
                        <p className="mt-1 mb-0 text-info">
                          {
                            propsClusterFilters.description
                              .storage_configuration.volume_name
                          }
                        </p>
                      ) : null}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}

              {props.storageType === "Efs" ? (
                <React.Fragment>
                  <div className={"d-flex mt-3"}>
                    <div className="py-1 w-100">
                      <div className="w-40">
                        <p className="b-block mb-0">File system name</p>
                        <Input
                          type="text"
                          placeholder="Enter File system name (Eg. awuse1nprpc01efsdata)"
                          className={`inputTextbox bg-transparent border-lightGray ${
                            props.pageMode !== "Create"
                              ? "disabled text-primary-color"
                              : "text-white"
                          }`}
                          // maxlength='128'
                          value={
                            state["filesystem_name_" + index]
                              ? state["filesystem_name_" + index]
                              : ""
                          }
                          onChange={(e) => {
                            if (props.pageMode === "Create") {
                              handleStorageInput(
                                index,
                                "filesystem_name",
                                validateVolumeName(e.target.value)
                              );
                            }
                          }}
                        />
                      </div>
                      {propsClusterFilters &&
                      propsClusterFilters.description &&
                      propsClusterFilters.description.storage_configuration &&
                      propsClusterFilters.description.storage_configuration
                        .filesystem_name ? (
                        <p className="mt-1 mb-0 text-info">
                          {
                            propsClusterFilters.description
                              .storage_configuration.filesystem_name
                          }
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className={"d-flex mt-3"}>
                    <div class="py-1 w-40">
                      <p className="b-block mb-0">Mount Directory</p>
                      <Input
                        type="text"
                        placeholder="Enter mount directory (Eg. /data)"
                        className={`inputTextbox bg-transparent border-lightGray ${
                          props.pageMode !== "Create"
                            ? "disabled text-primary-color"
                            : "text-white"
                        }`}
                        // maxlength='128'
                        value={
                          state["mount_dir_" + index]
                            ? state["mount_dir_" + index]
                            : ""
                        }
                        onChange={(e) => {
                          if (props.pageMode === "Create") {
                            handleStorageInput(
                              index,
                              "mount_dir",
                              validateMountDir(e.target.value)
                            );
                          }
                        }}
                      />
                      {propsClusterFilters &&
                      propsClusterFilters.description &&
                      propsClusterFilters.description.storage_configuration &&
                      propsClusterFilters.description.storage_configuration
                        .mount_dir ? (
                        <p className="mt-1 mb-0 text-info">
                          {
                            propsClusterFilters.description
                              .storage_configuration.mount_dir
                          }
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className={"d-flex mt-3"}>
                    <div className="py-1 w-100">
                      <div className="w-40">
                        <div className="d-flex justify-content-between">
                          <label className="align-self-center m-0">
                            Deletion Policy
                          </label>
                          {state.hasError &&
                          !state["deletion_policy_" + index] ? (
                            <small className="text-issues">required</small>
                          ) : null}
                        </div>
                        <div
                          className="border-lightGray bg-transparent rounded-5"
                          onClick={(event) => {
                            if (!state["isDeletionPolicy_" + index]) {
                              event.preventDefault();
                              handleChildClick(
                                event,
                                "child",
                                "singleDropDown",
                                "isDeletionPolicy_" + index
                              );
                            }
                          }}
                        >
                          <p
                            className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                              state["deletion_policy_" + index] &&
                              props.pageMode === "Create"
                                ? "text-white"
                                : "placeholder"
                            }`}
                          >
                            {state["deletion_policy_" + index]
                              ? propsClusterFilters.deletion_policy &&
                                propsClusterFilters.deletion_policy.filter(
                                  (e) =>
                                    e.value ===
                                    state["deletion_policy_" + index]
                                ).length
                                ? propsClusterFilters.deletion_policy.filter(
                                    (e) =>
                                      e.value ===
                                      state["deletion_policy_" + index]
                                  )[0].key
                                : state["deletion_policy_" + index]
                              : "Select"}
                            <i className="fa fa-caret-down mt-1" />
                          </p>
                          {state["isDeletionPolicy_" + index] &&
                          props.pageMode === "Create" ? (
                            <MultiSelectSection
                              fields={["value", "key"]}
                              options={
                                propsClusterFilters &&
                                propsClusterFilters.deletion_policy
                                  ? propsClusterFilters.deletion_policy.map(
                                      (d) => ({
                                        key: d,
                                        value: d,
                                      })
                                    )
                                  : []
                              }
                              selectedValues={
                                state["deletion_policy_" + index]
                                  ? state["deletion_policy_" + index]
                                  : ""
                              }
                              callbackMultiSelect={(value) => {
                                if (!value || typeof value === "string") {
                                  handleStorageInput(
                                    index,
                                    "deletion_policy",
                                    value
                                  );
                                  setState((prevState) => ({
                                    ...prevState,
                                    ["deletion_policy_" + index]: value,
                                  }));
                                } else {
                                  value.preventDefault();
                                  handleChildClick(
                                    value,
                                    "search",
                                    "singleDropDown",
                                    ""
                                  );
                                }
                              }}
                              singleSelection={true}
                              hideSearch={false}
                              topClass={"auto"}
                              widthClass={"minWidth220"}
                            />
                          ) : null}
                        </div>
                      </div>
                      {propsClusterFilters &&
                      propsClusterFilters.description &&
                      propsClusterFilters.description.storage_configuration &&
                      propsClusterFilters.description.storage_configuration
                        .deletion_policy ? (
                        <p className="mt-1 mb-0 text-info">
                          {
                            propsClusterFilters.description
                              .storage_configuration.deletion_policy
                          }
                        </p>
                      ) : null}
                    </div>
                  </div>
                </React.Fragment>
              ) : null}

              <div className="d-flex justify-content-end">
                {state.storageArray.length > 1 ? (
                  <span
                    className={`cursorPointer text-red f16 align-self-center ml-4`}
                    onClick={() => removeSection("storageArray", index, j)}
                  >
                    {" "}
                    Remove
                  </span>
                ) : null}
                {state.storageArray.length - 1 === j ? (
                  <span
                    className={`cursorPointer text-info f16 align-self-center ml-4`}
                    onClick={() => addSection("storageArray")}
                  >
                    {" "}
                    Add
                  </span>
                ) : null}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ExistingStorageSection;
