/*************************************************
 * Collider
 * @exports
 * @file ClusterAction.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../components/ApiCalls'
import {
	GET_CATALOG_FILTERS,
	LIST_WORKSTATION_RESULTS,
	LIST_WORKSTATION_CATALOG_DETAILS,
	SUBSCRIBE_CATALOG,
	GET_WORKSTATION_METRICS,
	LIST_STACK_EVENTS,
	APPLY_WORKSTATION_ACTIONS,

	LIST_LIFECYCLE_POLICIES,
	LIST_LIFECYCLE_RULES,
	PUT_LIFECYCLE_POLICIES,
	DELETE_LIFECYCLE_POLICIES,

	GET_COVERED_SERVICES,
	LIST_COVERED_ASSETS,
	LIST_WORKSTATION_REQUESTS,
	LIST_WORKSTATION_REQUEST_HISTORY,
	CHANGE_WORKSTATION_STATUS,
	DISABLE_LIFECYCLE_POLICY
} from '../config'
import { refreshPage } from '../utils/utility';
// import { myLog, momentTime, momentDate, convertBytes, convertSeconds, convertBits, countFormater } from '../../utils/utility'
//import Cookies from 'js-cookie'
// import { ACTION_TYPES } from '../types';

/**
 * 23/08/23
 * Action to list-workstation-results
 * @param {Object} body
 * @param {Function} callback
 */
export const listWorkstationResults = (params) => {
	return async () => {
		try {
			const url = LIST_WORKSTATION_RESULTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 * 23/08/23
 * Action to list-workstation-catalog-details
 * @param {Object} body
 * @param {Function} callback
 */
export const listWorkstationCatalogDetails = (params) => {
	return async () => {
		try {
			const url = LIST_WORKSTATION_CATALOG_DETAILS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 * 23/08/23
 * Action to subscribe-catalog
 * @param {Object} body
 * @param {Function} callback
 */
export const subscribeCatalog = (params) => {
	return async () => {
		try {
			const url = SUBSCRIBE_CATALOG;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			// dispatch({ type: ACTION_TYPES.SET_LIST_CLUSTER_NAME, payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 * 23/08/23
 * Action to get-workstation-metrics
 * @param {Object} body
 * @param {Function} callback
 */
export const getWorkStationMetrics = (params) => {
	return async () => {
		try {
			const url = GET_WORKSTATION_METRICS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}


/**
 * 24/08/23
 * Action to list-stack-events
 * @param {Object} body
 * @param {Function} callback
 */
export const listStackEvents = (params) => {
	return async () => {
		try {
			const url = LIST_STACK_EVENTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 * 25/08/23
 * Action to list-stack-events
 * @param {Object} body
 * @param {Function} callback
 */
export const applyWorkstationActions = (params) => {
	return async () => {
		try {
			const url = APPLY_WORKSTATION_ACTIONS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 *  Action to list-lifecycle-policies
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 21/11/2022
 */
export const listLifecyclePolicies = (params) => {
	return async () => {
		try {
			const url = LIST_LIFECYCLE_POLICIES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 *  Action to list-lifecycle-rules
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 21/11/2022
 */
export const listLifecycleRules = (params) => {
	return async () => {
		try {
			const url = LIST_LIFECYCLE_RULES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 *  Action to put-lifecycle-policy
 * @param {Object} body
 * @param {Function} callback
 * @author prakash // on 21/11/2022
 */
 export const putLifecyclePolicy = (params) => {
	return async () => {
		try {
			const url = PUT_LIFECYCLE_POLICIES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

//delete-lifecycle-policies

export const deleteLifecyclePolicies = (params) => {
	return async () => {
		try {
			const url = DELETE_LIFECYCLE_POLICIES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}



/**
 * Action to list-covered-assets
 * @param {Object} body
 * @param {Function} callback
 */
export const listCoveredAssets = (params) => {
	return async () => {
		try {
			const url = LIST_COVERED_ASSETS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	}
}

/**
 * Action to get-covered-services
 * @param {Object} body
 * @param {Function} callback
 */
 export const getCoveredServices = (params) => {
	return async () => {
		try {
			const url = GET_COVERED_SERVICES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	}
}

/**
 * 19-01-24
 * Action to list-workstation-requests
 * @param {Object} body
 * @param {Function} callback
 */
export const listWorkstationRequests = (params) => {
	return async () => {
		try {
			const url = LIST_WORKSTATION_REQUESTS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 * 19-01-24
 * Action to list-workstation-request-history
 * @param {Object} body
 * @param {Function} callback
 */
export const listWorkstatioRequestHistory = (params) => {
	return async () => {
		try {
			const url = LIST_WORKSTATION_REQUEST_HISTORY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 * 19-01-24
 * Action to change-workstation-status
 * @param {Object} body
 * @param {Function} callback
 */
export const changeWorkstationStatus = (params) => {
	return async () => {
		try {
			const url = CHANGE_WORKSTATION_STATUS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
				refreshPage()
			}
			return error
		}
	};
}

/**
 * 22/04/24
 * Action to get-catalog-filters
 * @param {Object} body
 * @param {Function} callback
 */
export const getCatalogFilters = (params) => {
	return async () => {
		try {
			const url = GET_CATALOG_FILTERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
			// 	//localStorage.setItem('ACCESS_TOKEN', '');
			// 	//window.location.href = '/signin';
			// 	refreshPage()
			// }
			return error
		}
	};
}

/**
 * Action to get all providers
 * @param {Function} callback
 */
export const disableLifecyclePolicy = (params) => {
	return async (dispatch) => {
		try {
			const url = DISABLE_LIFECYCLE_POLICY;
			// Assuming your API endpoint for the GET request is 'YOUR_API_ENDPOINT'
			// const response = await ApiCalls.get(url, { params });
			const response = await ApiCalls.post(url, params);

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			return error.response && error.response.data
		}
	};
};