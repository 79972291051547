import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Store as CommonNotification } from 'react-notifications-component';

import { useDispatch } from 'react-redux';
import { Input, Spinner } from 'reactstrap';
import { saveFileContent } from '../../../../actions/files/FileAction';

const EditFile = ({state, newState, closeSidePanel}) => {
    const clickOut = useRef()
    const [editState, setEditState] = useState({
        showLoading: true,
        callHpcReports: true,
        editData: newState.fileContent && newState.fileContent.data ? newState.fileContent.data :''
    })

    const dispatch = useDispatch(); // Create a dispatch function
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel()
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
    }, [handleKeyDown])

    const saveFunction = () => {
        if(state.statsDetail && state.statsDetail.data &&state.statsDetail.data.type === 'file' && state.selectedProvider) {
          let data = state.statsDetail.data
          let params = {}
          params.provider = state.selectedProvider.toLowerCase()
          params.account_id = state.selectedAccount ? state.selectedAccount : []
          params.region = state.selectedRegion ? state.selectedRegion : []
          params.cluster_name = state.selectedClusters
          params.path = data.path
          if(data.owner) {
            params.owner = data.owner
          }
          params.data = editState.editData
          if(params.data) {
              dispatch(saveFileContent(params)).then((response) => {
                  let messageType = 'success'
                  let message = response.data && response.data.message ? response.data.message : 'Error in updaing file'
                  if(!response.status) {   
                      messageType = 'danger'
                      setEditState(prevEditState => ({ ...prevEditState, onSaveFunction: false }))
                  } else {
                      message = response.data && response.data.message ? response.data.message : 'Updated Successfully'
                      setEditState(prevEditState => ({ ...prevEditState, deleteModal: false, onSaveFunction: false }))
                  }
    
                  CommonNotification.addNotification({
                      //title: 'Wonderful!',
                      message: message,
                      type: messageType,
                      insert: 'top',
                      container: 'top-center',
                      // animationIn: ['animate__animated', 'animate__fadeIn'],
                      // animationOut: ['animate__animated', 'animate__fadeOut'],
                      dismiss: {
                          duration: 5000,
                          onScreen: false,
                          pauseOnHover: true,
                          showIcon: true,
                      }
                  });
              })   
          }
        }
      }

	const handleChildClick = (event, type, dropdownType, section) => {
        if (clickOut.current && !clickOut.current.contains(event.target)) {
            closeSidePanel()
        }
		event.stopPropagation();
		let clickedChild = []
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setEditState(prevEditState => ({ ...prevEditState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setEditState(prevEditState => ({ ...prevEditState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
          } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setEditState(prevEditState => ({ ...prevEditState, [row]: false }))
                })
            }
            
            setEditState(prevEditState => ({ ...prevEditState, clickedChild }))
          }
	}

    const handleEditData = e => {		
        const { name, value } = e.target;
		setEditState(prevEditState => ({ ...prevEditState, [name]: value }))
	}

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }} onClick={(event) => handleChildClick(event, 'parent')}>
            <div className="search-content bg-dark w-82 overflow-auto"  ref={clickOut}>
                <div className="header-search bd-highlight bg-black d-flex justify-content-between p-2">
                    <div className="d-flex bd-highlight text-white">
                        <h5>File Details</h5>
                    </div>

                    <div className="text-right flex-fill bd-highlight align-self-center">
                        <i className="far fa-times fa-lg text-white cursorPointer" onClick={() => closeSidePanel()}></i>
                    </div>
                </div>                
                {!editState.showEditOption ?
                    <p className='my-1 text-info text-right mr-2 cursor-pointer' onClick={() => setEditState(prevEditState => ({ ...prevEditState, showEditOption: true}))}><i className='fas fa-pencil text-white f12 mr-2'></i> Edit File</p>
                : null}
                <div className='d-flex mx-2 border-darkGray-top'>
                    <div className='py-1 w-100'>
                        {/* <p className='b-block mb-0'>
                            Job Script
                            {state.inputValidationError && !state.command ?
                                <span className='mb-0 text-red ml-2'>required</span>
                            : null}
                        </p> */}
                        <Input
                            type='textarea'
                            className={`inputTextbox border-${editState.showEditOption ? 'lightGray' : '0'} text-white bg-transparent`}
                            placeholder='Enter script'
                            rows='25'
                            id='editData'
                            // maxlength='256'
                            readOnly={editState.showEditOption ? '' : true}
                            name="editData"
                            onChange={handleEditData}
                            value={editState.editData}
                        />
                    </div>
                </div>
                {editState.showEditOption ?
                    <div className='fixed-buttons justify-content-end bg-transparent border-0'>
                        <div className='align-self-start'>
                            {editState.onSaveFunction ?
                                <button className={`btn btn-primary mr-2`}>
                                    <Spinner size='sm' color='light' className='mx-2' /> 
                                    Updating
                                </button>
                            :
                                <div className='d-flex'>
                                    {state.pageType !== "View" ?
                                        <button className={`btn btn-primary mr-2`} 
                                            onClick={() => {
                                                if(!editState.onSaveFunction) {
                                                    setEditState(prevEditState => ({ ...prevEditState, onSaveFunction: true }))
                                                    saveFunction()
                                                }
                                            }}>
                                            Update
                                        </button>
                                    : null}
                                    {!editState.onSaveFunction ?
                                        <button className={`btn btn-secondary mr-2`} 
                                            onClick={() => closeSidePanel()}>
                                            Cancel
                                        </button>
                                    : null}
                                </div>
                            } 
                        </div>
                    </div>
                : null}
            </div>
        </div>
    
    )
}
export default EditFile;
    