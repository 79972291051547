/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file RecommendationsRightSection.js
 * @author Prakash // on 05/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import _ from 'lodash'

import { momentConvertionUtcToLocalTime, getRegionName, getAccountNameFromId } from '../../../utils/utility'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import { setCostPropsDetails } from  '../../../actions/cost/CostAction'

import PrimarySecondaryMetricsSection from './PrimarySecondaryMetricsSection'
import IdleTimeSection from './IdleTimeSection'

import Chart from 'react-apexcharts'

const RecommendationsRightSection = () => {
	const sliderWrap = useRef()
    const [state, setState] = useState({
        minMaxHeight: "700px",
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 10,
        series: [],
        options: {},
        showRecomDetails: true,

        totalItemShowing: 0,
        showingRightIcon: true,
        showingOffStart: 1,
        showingOff: 0,

        showCurrentFeatures: true,
        showRecommendedFeatures: true,
        showRecommendedObjectFeatures: true,
    })
    const accounts = useSelector(state => state.filters.accounts);
    const regions = useSelector(state => state.filters.regions);
    const selectedRecommendedEvent = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.selectedRecommendedEvent ? state.cost.costPropsDetails.selectedRecommendedEvent : {});
    const selectedResourceRecommendedEvent = useSelector(state => state.cost.costPropsDetails && state.cost.costPropsDetails.selectedResourceRecommendedEvent ? state.cost.costPropsDetails.selectedResourceRecommendedEvent : {});

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        getWindowSize()

        window.addEventListener('resize', () => {
            getWindowSize()
        })
    }, [])

    const getWindowSize = () =>  {
        // const {innerWidth, innerHeight} = window;
		let browserBorder = 100
		let headerHeight = 15  
		let outerHeight = (window.outerHeight - browserBorder - headerHeight) +"px"

		setState(prevState => ({ ...prevState, outerHeight, minMaxHeight: outerHeight }))
    }

    //recommended events
    useEffect(() => {
        if(selectedRecommendedEvent && !_.isEqual(selectedRecommendedEvent, state.selectedRecommendedEvent)) {
            setState(prevState => ({
                ...prevState,
                selectedRecommendedEvent,
                selectedRecommendedEventLabel: selectedRecommendedEvent.label,
                selectedRecommendedEventTag: selectedRecommendedEvent.eventGroup,
                selectedRecommendedEventData: selectedRecommendedEvent.data,
                showMeticsChart: false,
                gandalfBarData: {},
                showIdealCharts: false,
                showRecommendedFeaturesSection: true,
                // callAfterStateRender: true
            }))
        }
    }, [selectedRecommendedEvent, state.selectedRecommendedEvent, state.selectedRecommendedEventData])

    useEffect(() => {
        if(state.selectedRecommendedEventData && state.selectedRecommendedEventLabel !== "assets_list") {
            setState(prevState => ({ ...prevState, showMeticsChart: true, callAfterStateRender: false }))

        }
    }, [state.selectedRecommendedEventData, state.selectedRecommendedEventLabel])

    useEffect(() => {
        if(state.selectedRecommendedEventTag === "Schedule") {
            setState(prevState => ({ ...prevState, showIdealCharts: true }))
        }
    }, [state.selectedRecommendedEventTag])    

    useEffect(() => {
        if(state.selectedRecommendedEventData && Object.entries(state.selectedRecommendedEventData).length) {
            if(state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.statistics) {
                multiAxisCharts()
            }
            if(!state.selectedRecommendedEventData || !state.selectedRecommendedEventData.additional_details || !state.selectedRecommendedEventData.additional_details.recommended_features) {
                multiAxisCharts()
            } else {
                slideLeft()
            }
        }
    }, [state.selectedRecommendedEventData])

    //recommended events

    // recommended resource events
    useEffect(() => {
        if(selectedResourceRecommendedEvent && !_.isEqual(selectedResourceRecommendedEvent, state.selectedResourceRecommendedEvent)) {
            setState(prevState => ({
                ...prevState,
                selectedResourceRecommendedEvent,
                selectedResourceTableData: selectedResourceRecommendedEvent.data,
                selectedRecommendedEventLabel: "",
                selectedRecommendedEventData: {},
                showIdealCharts: false,
                showMeticsChart: false,
                showRecommendedFeaturesSection: false,
                selectedResourceRecommendedEventLabel: selectedResourceRecommendedEvent.showBy,                        
            }))
        }
    }, [selectedResourceRecommendedEvent, state.selectedResourceRecommendedEvent])

    const onChangeRecommendationEvent = useCallback((label, tag, data, selectedEvent) => {
        let assetDetails = {}

        if(data.asset_id && assetDetails.asset_id !== "") {
            if(!data.resource_type || data.resource_type === "") {
                data.resource_type = data.resource_type ? data.resource_type : ""
            }
            if(!data.service_name || data.service_name === "") {
                data.service_name = data.service_name ? data.service_name : ""
            }
            if(!data.provider || data.provider === "") {
                data.provider = data.provider ? data.provider : ""
            }
            if(!data.account_id || data.account_id === "") {
                data.account_id = data.account_id ? data.account_id : ""
            }
            if(!data.region || data.region === "") {
                data.region = data.region ? data.region : ""
            }
        }
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = data
        obj.asset_details = assetDetails
        obj.showBy = state.showBy
        
        dispatch(setCostPropsDetails("selectedRecommendedEvent", obj))

        setState(prevState => ({ ...prevState, selectedRecommandation: selectedEvent}))
    }, [dispatch, state.showBy])

    useEffect(() => {
        setState(prevState => ({ ...prevState, showIdealCharts: false, showMeticsChart: false }))

        let lastRecord = state.selectedResourceTableData && state.selectedResourceTableData[state.selectedResourceTableData.length-1] ? state.selectedResourceTableData[state.selectedResourceTableData.length-1] : {}
        onChangeRecommendationEvent(lastRecord.label, lastRecord.tag, lastRecord, "")
        
    }, [state.selectedResourceTableData, onChangeRecommendationEvent])

    //recommened resource events
	
	const slideLeft = () => {
		let move = parseInt(state.totalItemShowing)
		setState(prevState => ({ ...prevState, totalItemShowing: state.totalItemShowing - 1 }))

        let totalRecommandations = state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.recommended_features ? state.selectedRecommendedEventData.additional_details.recommended_features.length : 0
        
        if(totalRecommandations) {
            let reqTabWidth = 0
            for (let i = 0; i < totalRecommandations; i++) {
                reqTabWidth += sliderWrap.current && sliderWrap.current.childNodes[i].getBoundingClientRect().width
            }
            const averageSliderItemWidth = reqTabWidth/totalRecommandations
            
            const sliderWrapWidth = sliderWrap.current && sliderWrap.current.getBoundingClientRect() && sliderWrap.current.getBoundingClientRect().width
            const totalItemShowing = Math.floor(sliderWrapWidth/averageSliderItemWidth -1);
            if(move > totalItemShowing) {
                if (sliderWrap.current.childNodes[move-1] && sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
                    sliderWrap.current.scrollLeft -= (sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)	
                    setState(prevState => ({ 
                        ...prevState, 
                        showingRightIcon: true, 
                        showingOff: parseInt(state.showingOff - 1), 
                        showingOffStart: state.showingOffStart < 1 ? 1 : parseInt(state.showingOffStart-1)
                    }))
                } else {
                    setState(prevState => ({ 
                        ...prevState, 
                        showingLeftIcon: false, 
                        totalItemShowing, 
                        allowedItems: totalItemShowing 
                    }))
                }
            } else {
                setState(prevState => ({ 
                    ...prevState, 
                    showingLeftIcon: false, 
                    totalItemShowing, 
                    allowedItems: totalItemShowing 
                }))
            }

			if(!state.showingOff || state.showingOff === 0) {
                let allRecommendations = state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.recommended_features ? state.selectedRecommendedEventData.additional_details.recommended_features.length : 0
				setState(prevState => ({ 
                    ...prevState, 
                    showingOff: allRecommendations < parseInt(totalItemShowing) ? allRecommendations : parseInt(totalItemShowing), showingLeftIcon: true 
                }))
			}
        }
	}

	const slideRight = (item) => {
        let totalRecommandations = state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.recommended_features ? state.selectedRecommendedEventData.additional_details.recommended_features.length : 0

		let reqTabWidth = 0
		for (let i = 0; i < totalRecommandations; i++) {
			reqTabWidth += sliderWrap.current.childNodes[i].getBoundingClientRect().width
        }
		const averageSliderItemWidth = reqTabWidth/totalRecommandations
		const sliderWrapWidth = sliderWrap.current.getBoundingClientRect().width
        let totalItemShowing = sliderWrapWidth/averageSliderItemWidth;
        if(totalItemShowing > state.selectedRecommendedEventData.additional_details.recommended_features.length) {
            totalItemShowing = state.selectedRecommendedEventData.additional_details.recommended_features.length
        }
        totalItemShowing = totalItemShowing ? Math.floor(totalItemShowing -1) : totalItemShowing

		let move = 0
		if(state.totalItemShowing === 0) {
			setState(prevState => ({ ...prevState, totalItemShowing: parseInt(totalItemShowing) }))
			move = parseInt(totalItemShowing)
		} else {
			move = parseInt(state.totalItemShowing + 1)
			setState(prevState => ({ ...prevState, totalItemShowing: state.totalItemShowing + 1 }))
        }
        
		if (sliderWrap.current.childNodes[move-1] && sliderWrap.current.childNodes[move-1].getBoundingClientRect().width) {
            if(item === '') {
                sliderWrap.current.scrollLeft += (sliderWrap.current.childNodes[move-1].getBoundingClientRect().width + 25)
            }
            
			if(!state.showingOff && state.showingOff < totalItemShowing) {
				setState(prevState => ({ ...prevState, showingOff: parseInt(totalItemShowing), showingLeftIcon: true }))
			} else{
				setState(prevState => ({ ...prevState, showingOffStart: (state.showingOffStart + 1), showingOff: parseInt(state.showingOff + 1), showingLeftIcon: true }))
			}
		} else {
			setState(prevState => ({ ...prevState, showingRightIcon: false, showingOff: totalRecommandations, totalItemShowing: totalRecommandations }))
		}
    }

    const multiAxisCharts = () => {
        let series = []
        let options = {}
        if(state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.statistics && state.selectedRecommendedEventData.additional_details.statistics.length) {
            let durationData = []
            let priceData = []
            let labels = []
            // let memoryUnit = ""
            state.selectedRecommendedEventData.additional_details.statistics.forEach(row => {
                labels.push(row.memory)
                priceData.push(row.price)
                durationData.push(row.duration)
                // memoryUnit = row.memory_unit
            })

            series = [{
                name: 'Invocation Time',
                type: 'line',
                data: durationData
            }, {
                name: 'Invocation Cost',
                type: 'line',
                data: priceData
            }]

            options = {
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: false
                    },
                    animations: {
                        enabled: false
                    },

                },
                stroke: {
                    curve: 'smooth'
                },
                // fill: {
                //     type:'solid',
                //     opacity: [0.35, 1],
                // },
                labels: labels,
                markers: {
                    size: 2
                },
                xaxis: {
                    show: true,
                    tooltip: {
                        enabled: false
                    },
                    axisBorder: {
                        show: true,
                        color:'#434B5E'
                    },
                    axisTicks: {
                        show: false
                    },
                    labels: {
                        show: true,
                        style: {
                            colors: "#50586A",
                            fontSize: '13px',
                            fontWeight: 'bolder',
                        },
                    }
                },
                yaxis: [
                    {
                        title: {
                            text: 'Invocation Time', 
                            style: {
                                color: "#50586A",
                                fontWeight: 600,
                            },
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: "#50586A",
                                fontSize: '13px',
                                fontWeight: 'bolder',
                            },
                        }
                    },
                    {
                        opposite: true,
                        title: {
                            text: 'Invocation Cost', 
                            style: {
                                color: "#50586A",
                                fontWeight: 600,
                            },
                        },
                        labels: {
                            show: true,
                            style: {
                                colors: "#50586A",
                                fontSize: '13px',
                                fontWeight: 'bolder',
                            },
                        }
                    },
                ],
                grid: {
                    show: false,			        
                    xaxis: {
                        lines: {
                            show: false
                        },
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 10
                    },
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    // y: {
                    //     formatter: function (y) {
                    //         if(typeof y !== "undefined") {
                    //             return  y.toFixed(0);
                    //         }
                            
                    //         return y;
                    //     }
                    // },
                    
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        let retrunData = '<div class="arrow_box">'
                        retrunData += '<div class="small"> <span class="text-gray3">Memory</span> : '+w.globals.categoryLabels[dataPointIndex]+'</div>'
                        w.globals.initialSeries.forEach(row => {
                            retrunData += '<div class="small"> <span class="text-gray3">'+row.name+'</span> : '+row.data[dataPointIndex]+'</div>'
                        })
                        retrunData += '</div>'
                        return retrunData

                    }
                },
                legend: {
                    show: true,
                    labels: {
                        colors: "#50586A",
                        useSeriesColors: false
                    }
                }
            }
        }

        setState(prevState => ({ ...prevState, multiAxisSeries: series, multiAxisOptions: options }))
    }    

    return (
        <div className=''>
            <div className={`m-0 overflowYAuto`}>
                {state.selectedResourceRecommendedEventLabel === "Service" ?
                    state.selectedResourceTableData && state.selectedResourceTableData.length ?
                        <div className={`card bg-dark border-0 p-3 mb-3`}>
                            <ResizeableDarkThemeTable
                                columns={[
                                    {
                                        Header: "Service",
                                        accessor: d => d.resource_type+" : "+d.service_name,
                                        width: 200
                                    },
                                    {
                                        Header: "Category",
                                        accessor: "category",
                                        width: 150
                                    },
                                    {
                                        Header: "Savings",
                                        // accessor: d => d.additional_details && d savings+" ("+d.price_unit && d.price_unit+"%)"+"  ("+d.percentage_savings && d.percentage_savings+"%)",
                                        Cell: cellInfo => (
                                            <div className='d-flex'>
                                                <p className="f12 m-0 text-success align-self-center mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.savings_billing_prev_month ? cellInfo.row.original.additional_details.current_features.savings_billing_prev_month : ''}</p>
                                                <p className="mb-0 text-primary-color f11 mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.price_unit ? cellInfo.row.original.additional_details.current_features.price_unit : ''}</p>
                                                <p className="mb-0 text-primary-color f11">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month ? "("+cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month+"%)" : ''}</p>
                                            </div>
                                        ),
                                        width: 150
                                    },
                                    {
                                        Header: "Asset",
                                        accessor: "asset_name",
                                        width: 200
                                    },
                                    {
                                        Header: "Resources",
                                        Cell: cellInfo => (
                                            (cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, accounts) + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : getRegionName(cellInfo.row.original.region, regions)) : '')
                                        ),
                                        width: 200
                                    },
                                    {
                                        Header: "Description",
                                        accessor: "description",
                                        width: 600
                                    }
                                ]}
                                data={state.selectedResourceTableData}
                                perPage={20}
                                dashboard={true}
                                sortByColumn={"Date"}
                                riskTooltip={[0]}
                                onClickRow={tableRow => 
                                    onChangeRecommendationEvent(tableRow.label, tableRow.tag, tableRow, "")
                                }
                                // defaultSelectedRecord={""}
                                // tableSize={"table-sm"}
                                // tableHead={"bg-dark"}
                                tooltipFor={[1,2]}
                            />
                        </div>
                    : null
                : null}
                
                {state.selectedRecommendedEventData && Object.entries(state.selectedRecommendedEventData).length ? 
                    <React.Fragment>                            
                        <div className={`card bg-dark border-0 p-3 mb-3`}>
                            <div className={`${state.showRecomDetails ? "mb-2" : ""} d-flex justify-content-between`} onClick={() => setState(prevState => ({ ...prevState, showRecomDetails: !state.showRecomDetails }))}>
                                <div className="text-white cursorPointer"><span className={`far ${state.showRecomDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Details</div>
                                {/* <div className="text-info">Showing event details</div> */}
                            </div>
                            <div className={`collapse ${state.showRecomDetails ? 'show' : ''}`}>
                                <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Category</p>
                                        <p className="mb-0 text-white">
                                            {state.selectedRecommendedEventData.category ? state.selectedRecommendedEventData.category : ""}
                                        </p>
                                    </div>
                                    <div className="pl-3 py-1 w-50">
                                        <p className="b-block mb-0">Event Time</p>
                                        <p className="mb-0 text-white">{state.selectedRecommendedEventData.timestamp ? momentConvertionUtcToLocalTime(state.selectedRecommendedEventData.timestamp, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                    {/* <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Utilization</p>
                                        <p className="mb-0 text-white">{state.selectedRecommendedEventData.utilization ? state.selectedRecommendedEventData.utilization : ""}</p>
                                    </div> */}
                                </div>
                                {/* <div className="d-flex border-mediumDarkGray-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Asset</p>
                                        <p className="mb-0 text-white">{state.selectedRecommendedEventData.asset_name ? state.selectedRecommendedEventData.asset_name : <span>&nbsp;</span>}</p>
                                    </div>
                                </div> */}
                                <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Provider / Account / Region</p>
                                        <p className="mb-0 text-white">
                                            {state.selectedRecommendedEventData.provider ? state.selectedRecommendedEventData.provider.toUpperCase() : <span>&nbsp;</span>}
                                            {state.selectedRecommendedEventData.account_id ? (' : ')+getAccountNameFromId(state.selectedRecommendedEventData.account_id, accounts) : (state.selectedDiagnosticRcaEvent && state.selectedDiagnosticRcaEvent.account_id ? state.selectedDiagnosticRcaEvent.account_id : <span>&nbsp;</span>)}
                                            {state.selectedRecommendedEventData.region ?  (' : ')+getRegionName(state.selectedRecommendedEventData.region, regions) : <span>&nbsp;</span>}
                                        </p>
                                    </div>                                    
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Service</p>
                                        <p className="mb-0 text-white">
                                            {state.selectedRecommendedEventData.resource_type ? state.selectedRecommendedEventData.resource_type : <span>&nbsp;</span>}
                                            {state.selectedRecommendedEventData.service_name ?  (' : ')+state.selectedRecommendedEventData.service_name : <span>&nbsp;</span>}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                    <div className="py-1 w-50">
                                        <p className="b-block mb-0">Asset</p>
                                        {state.selectedRecommendedEventData.id_name_map && state.selectedRecommendedEventData.id_name_map !== "" ?
                                            <p className="mb-0 text-white">
                                                <span className={`ml-1`}>{(state.selectedRecommendedEventData.id_name_map.value ? state.selectedRecommendedEventData.id_name_map.value : "")}</span>
                                                {state.selectedRecommendedEventData.id_name_map.key && state.selectedRecommendedEventData.id_name_map.key !== "" ?
                                                    <span className={`ml-1'} mb-0`}>
                                                        {" : "+state.selectedRecommendedEventData.id_name_map.key}
                                                    </span>
                                                : null}
                                            </p>
                                        : 
                                            <p className="mb-0 text-white">{state.selectedRecommendedEventData.asset_name ? state.selectedRecommendedEventData.asset_name : <span>&nbsp;</span>}</p>
                                        }
                                    </div>
                                    <div className="py-1 w-50 pl-3">
                                        <p className="b-block mb-0">Asset Time</p>
                                        <p className="mb-0 text-white">{state.selectedRecommendedEventData.event_time ? momentConvertionUtcToLocalTime(state.selectedRecommendedEventData.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-1">
                                    <div className="py-1 w-100">
                                        <p className="b-block mb-0">Description</p>
                                        <p className="mb-0 text-white">
                                            {state.selectedRecommendedEventData.description ? state.selectedRecommendedEventData.description : <span>&nbsp;</span>}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.current_features ?
                            <div className={`card bg-dark border-0 p-3 mb-3`}>
                                <div className={`${state.showCurrentFeatures ? "mb-2" : ""} d-flex`}>
                                    <span className={`far ${state.showCurrentFeatures ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}  onClick={() => setState(prevState => ({ ...prevState, showCurrentFeatures: !state.showCurrentFeatures }))}></span>
                                    <div className="text-white cursorPointer" onClick={() => setState(prevState => ({ ...prevState,  showCurrentFeatures: !state.showCurrentFeatures }))}>Observations</div>                                        
                                    {/* <div className="text-info">Showing event details</div> */}
                                </div>
                                {state.selectedRecommendedEventData.observation ?
                                    <p className="mb-1 f12 text-primary-color">
                                        {state.selectedRecommendedEventData.observation}
                                    </p>
                                : null}
                                {state.showCurrentFeatures ? 
                                    <React.Fragment>

                                    {state.selectedRecommendedEventLabel === "Lambda Right Sizing" ?
                                        <div className="d-flex">
                                            <div className="mb-3 col-10 px-0">
                                                <div className="rounded bg-dark3">
                                                    <div className="p-3">
                                                        <div className="d-flex mb-2">
                                                            <div className="py-1 w-50">
                                                                <p className="b-block mb-0">Duration {state.selectedRecommendedEventData.additional_details.current_features.duration_unit ? " ("+state.selectedRecommendedEventData.additional_details.current_features.duration_unit+")" : ''}
                                                                </p>
                                                                <p className="mb-0 text-white">{state.selectedRecommendedEventData.additional_details.current_features.duration ? state.selectedRecommendedEventData.additional_details.current_features.duration : ''}</p>
                                                            </div>
                                                            <div className="py-1 w-50 pl-3">
                                                                <p className="b-block mb-0">Memory {state.selectedRecommendedEventData.additional_details.current_features.memory_unit ? " ("+state.selectedRecommendedEventData.additional_details.current_features.memory_unit+")" : ""}:</p>
                                                                <p className="mb-0 text-white">{state.selectedRecommendedEventData.additional_details.current_features.memory ? state.selectedRecommendedEventData.additional_details.current_features.memory : ''}</p>
                                                            </div>
                                                            {/* <div className="py-1 w-33 pl-3">
                                                                <p className="b-block mb-0">Price {state.selectedRecommendedEventData.additional_details.current_features.price_unit ? "("+state.selectedRecommendedEventData.additional_details.current_features.price_unit+")" : ""}:</p>
                                                                <p className="mb-0 text-white">
                                                                    {state.selectedRecommendedEventData.additional_details.current_features.price_per_month ? state.selectedRecommendedEventData.additional_details.current_features.price_per_month : ''}
                                                                    {state.selectedRecommendedEventData.additional_details.current_features.price ? " / "+state.selectedRecommendedEventData.additional_details.current_features.price : ''}
                                                                </p>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                        
                                            <div className="my-3 col-2 justify-content-end pr-0">
                                                <p className="mb-0 f16 text-success">Savings {state.selectedRecommendedEventData.additional_details.current_features.price_unit ? "("+state.selectedRecommendedEventData.additional_details.current_features.price_unit+")" : ""}:</p>
                                                <p className="mb-0 mr-3 text-success f20">
                                                    {parseFloat(state.selectedRecommendedEventData.additional_details.current_features.savings).toFixed(2)}
                                                    {state.selectedRecommendedEventData.additional_details.current_features.savings ? 
                                                        state.selectedRecommendedEventData.additional_details.current_features.percentage_savings ?
                                                            <span className="text-primary-color f12 ml-1">({state.selectedRecommendedEventData.additional_details.current_features.percentage_savings}%)</span>
                                                        : null
                                                    : ""}
                                                </p>
                                                {/* <div className="flex-row ml-1 align-self-center">
                                                    <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                    <p className="mb-0 text-nowrap small">2032, 2031</p>
                                                </div> */}
                                            </div>
                                        </div>
                                    :
                                        <React.Fragment>
                                        
                                        <div className="d-flex">
                                            {/* <div class="borderMiddleBlue-lg"> </div>*/}
                                            <div className="featureBox shadow-none mr-2">
                                                <p className="mb-1 text-info">Current Features</p>
                                                <div className="px-3 rounded bg-dark3 border-info">
                                                    <div className="row p-3 sectionBox">
                                                        <div className={`w-100`}>
                                                            <div className="py-1">
                                                                <p className="b-block mb-0">Instance Type: <span className="ml-2 mb-0 text-white">{state.selectedRecommendedEventData.additional_details.current_features.instance_type ? state.selectedRecommendedEventData.additional_details.current_features.instance_type : ''}</span></p>
                                                            </div>
                                                            {state.selectedRecommendedEventData.additional_details.current_features.cpu ?
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">CPU:
                                                                    <span className="ml-2 mb-0 text-white">{state.selectedRecommendedEventData.additional_details.current_features.cpu}</span></p>
                                                                </div>
                                                            : state.selectedRecommendedEventData.additional_details.current_features.duration ? 
                                                                <div className="py-1">
                                                                    <p className="b-block mb-0">Duration {state.selectedRecommendedEventData.additional_details.current_features.duration_unit ? " ("+state.selectedRecommendedEventData.additional_details.current_features.duration_unit+")" : ''}:
                                                                    <span className="ml-2 mb-0 text-white"> {state.selectedRecommendedEventData.additional_details.current_features.duration}</span></p>
                                                                </div>
                                                            : null}
                                                            <div className="py-1">
                                                                <p className="b-block mb-0">Memory {state.selectedRecommendedEventData.additional_details.current_features.memory_unit ? " ("+state.selectedRecommendedEventData.additional_details.current_features.memory_unit+")" : ""}:
                                                                <span className="ml-2 mb-0 text-white">{state.selectedRecommendedEventData.additional_details.current_features.memory ? state.selectedRecommendedEventData.additional_details.current_features.memory : ''}</span></p>
                                                            </div>
                                                            <div className="py-1">
                                                                <p className="b-block mb-0">Network (gbps):
                                                                <span className="ml-2 mb-0 text-white">{state.selectedRecommendedEventData.additional_details.current_features.network_in_gbps ? state.selectedRecommendedEventData.additional_details.current_features.network_in_gbps : ''}</span></p>
                                                            </div>
                                                            <div className='bg-dark2 p-3 rounded'>
                                                                <p className="mb-1">Monthly {state.selectedRecommendedEventData.additional_details.current_features.price_unit ? "("+state.selectedRecommendedEventData.additional_details.current_features.price_unit+")" : ""}</p>
                                                                <div className='d-flex'>
                                                                    <div className='w-45 pr-2'>
                                                                        <p className="b-block mb-0">Price</p>
                                                                        <p className="mb-0 text-warning">
                                                                        {state.selectedRecommendedEventData.additional_details.current_features.price_per_month ? state.selectedRecommendedEventData.additional_details.current_features.price_per_month : ""}
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-55'>
                                                                        <p className="b-block mb-0">Savings</p>
                                                                        <p className="mb-0 text-success">
                                                                            {state.selectedRecommendedEventData.additional_details.current_features.savings ? state.selectedRecommendedEventData.additional_details.current_features.savings : ""}
                                                                            <span className="text-primary-color f12 ml-1">{state.selectedRecommendedEventData.additional_details.current_features.percentage_savings ? "("+state.selectedRecommendedEventData.additional_details.current_features.percentage_savings+"%)" : ''}</span>
                                                                        </p>                     
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='bg-dark2 p-3 rounded mt-2'>
                                                                <p className="mb-1">Usage {state.selectedRecommendedEventData.additional_details.current_features.price_unit ? "("+state.selectedRecommendedEventData.additional_details.current_features.price_unit+")" : ""}</p>
                                                                <div className='d-flex'>
                                                                    <div className='w-45 pr-2'>
                                                                        <p className="b-block mb-0">Price</p>
                                                                        <p className="mb-0 text-warning">
                                                                        {state.selectedRecommendedEventData.additional_details.current_features.billing_prev_month ? state.selectedRecommendedEventData.additional_details.current_features.billing_prev_month : ""}
                                                                        </p>
                                                                    </div>
                                                                    <div className='w-55'>
                                                                        <p className="b-block mb-0">Savings</p>
                                                                        <p className="mb-0 text-success">
                                                                            {state.selectedRecommendedEventData.additional_details.current_features.savings_billing_prev_month ? state.selectedRecommendedEventData.additional_details.current_features.savings_billing_prev_month : ""}
                                                                            <span className="text-primary-color f12 ml-1">{state.selectedRecommendedEventData.additional_details.current_features.percentage_savings_billing_prev_month ? "("+state.selectedRecommendedEventData.additional_details.current_features.percentage_savings_billing_prev_month+"%)" : ''}</span>
                                                                        </p>                     
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-100 overflow-hidden'>
                                                <div className="d-flex justify-content-between">
                                                    <p className="mb-1 ml-2">Recommendations</p>
                                                    {state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.recommended_features && state.selectedRecommendedEventData.additional_details.recommended_features.length ?
                                                        <div className="d-flex justify-content-end">
                                                            <small className="mr-3">
                                                                Showing 
                                                                {state.selectedRecommendedEventData.additional_details.recommended_features ?  
                                                                    " "+(state.showingOffStart) +" - "+ state.showingOff +" ("+state.selectedRecommendedEventData.additional_details.recommended_features.length+") "
                                                                : " 0"
                                                                } 
                                                            </small>
                                                            <span className="far fa-chevron-left mr-3 cursorPointer" onClick={() => slideLeft()}></span>
                                                            <span className={`far fa-chevron-right cursorPointer`}  onClick={() => slideRight("")}></span>
                                                        </div>
                                                    : null}
                                                </div>
                                                <div ref={sliderWrap} className="d-flex flex-nowrap overflow-hidden ml-2">
                                                    {state.showRecommendedFeatures ? 
                                                        state.selectedRecommendedEventData.additional_details.recommended_features && Object.entries(state.selectedRecommendedEventData.additional_details.recommended_features).map(([key, item]) => {
                                                            return(
                                                                <div className="featureBox shadow-none mr-3">
                                                                    <div className="px-3 rounded bg-dark3">
                                                                        <div className="row p-3 sectionBox">
                                                                            <div className={`w-100`}>
                                                                                <div className="py-1">
                                                                                    <p className="b-block mb-0">Instance Type: <span className="ml-2 mb-0 text-white">{item.instance_type ? item.instance_type : ''}</span></p>
                                                                                </div>
                                                                                {item.cpu ?
                                                                                    <div className="py-1">
                                                                                        <p className="b-block mb-0">CPU:
                                                                                        <span className="ml-2 mb-0 text-white">{item.cpu}</span></p>
                                                                                    </div>
                                                                                : item.duration ? 
                                                                                    <div className="py-1">
                                                                                        <p className="b-block mb-0">Duration {item.duration_unit ? " ("+item.duration_unit+")" : ''}:
                                                                                        <span className="ml-2 mb-0 text-white"> {item.duration}</span></p>
                                                                                    </div>
                                                                                : null}
                                                                                <div className="py-1">
                                                                                    <p className="b-block mb-0">Memory {item.memory_unit ? " ("+item.memory_unit+")" : ""}:
                                                                                    <span className="ml-2 mb-0 text-white">{item.memory ? item.memory : ''}</span></p>
                                                                                </div>
                                                                                <div className="py-1">
                                                                                    <p className="b-block mb-0">Network (gbps):
                                                                                    <span className="ml-2 mb-0 text-white">{item.network_in_gbps ? item.network_in_gbps : ''}</span></p>
                                                                                </div>
                                                                                <div className='bg-dark2 p-3 rounded'>
                                                                                    <p className="mb-1">Monthly {item.price_unit ? "("+item.price_unit+")" : ''}</p>
                                                                                    <div className='d-flex'>
                                                                                        <div className='w-45 pr-2'>
                                                                                            <p className="b-block mb-0">Price</p>
                                                                                            <p className="mb-0 text-warning">
                                                                                                {item.price_per_month ? item.price_per_month : ''}
                                                                                            </p>
                                                                                        </div>
                                                                                        <div className='w-55'>
                                                                                            <p className="b-block mb-0">Savings</p>
                                                                                            <p className="mb-0 text-success">
                                                                                                {item.savings ? item.savings : ''}
                                                                                                <span className="text-primary-color f12 ml-1">{item.percentage_savings ? "("+item.percentage_savings+"%)" : ''}</span>
                                                                                            </p>                     
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='bg-dark2 p-3 rounded mt-2'>
                                                                                    <p className="mb-1">Usage {item.price_unit ? "("+item.price_unit+")" : ''}</p>
                                                                                    <div className='d-flex'>
                                                                                        <div className='w-45 pr-2 align-self-center'>
                                                                                            <p className="b-block mb-0">-</p>
                                                                                        </div>
                                                                                        <div className='w-55'>
                                                                                            <p className="b-block mb-0">Savings</p>
                                                                                            <p className="mb-0 text-success">
                                                                                                {item.savings_billing_prev_month ? item.savings_billing_prev_month : ''}
                                                                                                <span className="text-primary-color f12 ml-1">{item.percentage_savings_billing_prev_month ? "("+item.percentage_savings_billing_prev_month+"%)" : ''}</span>
                                                                                            </p>                     
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        </React.Fragment>
                                        
                                    }
                        
                                    
                                    </React.Fragment>
                                : null}
                            </div>
                        :  state.selectedRecommendedEventData.observation ?
                            <div className="bg-dark p-3 mb-3">
                                <p className="f16 mb-1 text-white">Observations: <span className="ml-1 f12 text-primary-color">
                                    {state.selectedRecommendedEventData.observation}</span>
                                </p>
                            </div>
                        : null}

                        {state.showRecommendedFeaturesSection ?
                            state.selectedRecommendedEventLabel === "Lambda Right Sizing" ?
                                <div className={`card bg-dark border-0 p-3 mb-3`}>
                                    <div className={`${state.showRecommendedObjectFeatures ? "mb-2" : ""} d-flex justify-content-between`}>
                                        <div className="text-white cursorPointer" onClick={() => setState(prevState => ({ ...prevState, showRecommendedObjectFeatures: !state.showRecommendedObjectFeatures }))}><span className={`far ${state.showRecommendedObjectFeatures ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Recommendations</div>
                                    </div>
                                    {state.showRecommendedObjectFeatures ?
                                        state.selectedRecommendedEventData.additional_details.recommended_features ?
                                            <div className="featureBox shadow-none mr-3">
                                                <div className="px-3 rounded">
                                                    <div className="row p-3 sectionBox bg-dark3">
                                                        <div className={`w-100`}>
                                                            <div className="py-1">
                                                                <p className="b-block mb-0">Duration {state.selectedRecommendedEventData.additional_details.recommended_features.duration_unit ? " ("+state.selectedRecommendedEventData.additional_details.recommended_features.duration_unit+")" : ''}:
                                                                <span className="ml-2 mb-0 text-white"> {state.selectedRecommendedEventData.additional_details.recommended_features.duration}</span></p>
                                                            </div>

                                                            <div className="py-1">
                                                                <p className="b-block mb-0">Memory {state.selectedRecommendedEventData.additional_details.recommended_features.memory_unit ? " ("+state.selectedRecommendedEventData.additional_details.recommended_features.memory_unit+")" : ""}:
                                                                <span className="ml-2 mb-0 text-white">{state.selectedRecommendedEventData.additional_details.recommended_features.memory ? state.selectedRecommendedEventData.additional_details.recommended_features.memory : ''}</span></p>
                                                            </div>
                                                            <div className="py-1">
                                                                <p className="b-block mb-0 text-success f18">Savings {state.selectedRecommendedEventData.additional_details.recommended_features.price_unit ? "("+state.selectedRecommendedEventData.additional_details.recommended_features.price_unit+")" : ""}:</p>
                                                                <p className="mb-0 text-success f20">
                                                                    {state.selectedRecommendedEventData.additional_details.recommended_features.savings ? Math.floor(state.selectedRecommendedEventData.additional_details.recommended_features.savings) === "number" ? state.selectedRecommendedEventData.additional_details.recommended_features.savings : parseFloat(Math.floor(state.selectedRecommendedEventData.additional_details.recommended_features.savings)).toFixed(2) : ""}
                                                                    {state.selectedRecommendedEventData.additional_details.recommended_features.savings ? 
                                                                        state.selectedRecommendedEventData.additional_details.recommended_features.percentage_savings ?
                                                                            <span className="text-primary-color f12 ml-1">({state.selectedRecommendedEventData.additional_details.recommended_features.percentage_savings}"%")</span>
                                                                        : null
                                                                    : ""}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null
                                    : null}
                                </div>
                            : null
                        : null}
                        {state.selectedRecommendedEventData && state.selectedRecommendedEventData.additional_details && state.selectedRecommendedEventData.additional_details.statistics ?
                            <div className={`card bg-dark border-0 p-3 mb-3`}>
                                <div className={`${state.showStatisticsSection ? "mb-2" : ""} d-flex justify-content-between`}>
                                    <div className="text-white cursorPointer" onClick={() => setState(prevState => ({ ...prevState, showStatisticsSection: !state.showStatisticsSection }))}><span className={`far ${state.showStatisticsSection ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Statistics</div>
                                    {/* <div className="text-info">Showing event details</div> */}
                                </div>
                                {state.showStatisticsSection ?
                                    <div className={`rounded bg-dark3 border-0 p-3 mb-3`}>
                                        <div className="transparentTooltip">
                                            <Chart options={state.multiAxisOptions} series={state.multiAxisSeries} type="line" height={250} />
                                        </div>
                                    </div>
                                : null}
                            </div>
                        : null}
                    </React.Fragment>
                : null}
                {state.showIdealCharts ?
                    <div className={`rounded bg-dark border-0 p-3 mb-3`}>
                        <IdleTimeSection
                            recommendedEvent={state.selectedRecommendedEventData}
                        />
                    </div>
                : null}
                
                {state.showMeticsChart && state.selectedRecommendedEventData && Object.entries(state.selectedRecommendedEventData).length ?
                    <div className="my-3">
                        <PrimarySecondaryMetricsSection
                            page={"recommendation"}
                            startTime={state.selectedRecommendedEventData && state.selectedRecommendedEventData.start_time ? state.selectedRecommendedEventData.start_time : ""}
                            endTime={state.selectedRecommendedEventData && state.selectedRecommendedEventData.end_time ? state.selectedRecommendedEventData.end_time : ""}
                            metricsType={state.selectedRecommendedEventLabel}                                
                            selectedEvent = {state.selectedRecommendedEventData}
                            assetDetails={state.selectedRecommendedEventData}
                            brush={true}
                            sparkline={false}
                            yaxis={true}
                            yaxisLabel={true}
                            xaxis={true}
                            xaxisFormat={''}
                            xaxisLabel={true}
                            grid={false}
                            axisLabelColor={'#B8BBBE'}
                            legend={false}
                            stacked={false}
                            height={115}
                            horizontal={true}
                            barHeight={'40%'}
                            barEndShape={'rounded'}
                            columnWidth={'50%'}
                            gradient={true}
                            gradientColor={['#039BE5', '#5F5BA2']}
                            hideTooltipValue={true}
                            backgroundBarShape={'rounded'}
                            backgroundBarColors={['#333947']}
                            showBackgroundBarColors={false}
                            className={"transparentTooltip mt-n4 mb-n4"}
                            annotations={true}
                            // annotaionOptions={state.mertcisAnnotationTypes}
                        />
                    </div>
                : null}
                
                {state.selectedRecommendedEventData && Object.entries(state.selectedRecommendedEventData).length ?
                    <div className={`card bg-dark border-0 p-3 mt-3 displayNone`}>
                        <div className={`${state.showAssetDetails ? "mb-2" : ""} d-flex justify-content-between`} onClick={() => setState(prevState => ({ ...prevState, showAssetDetails: !state.showAssetDetails }))}>
                            <div className="text-white cursorPointer"><span className={`far ${state.showAssetDetails ? 'fa-minus-circle' : 'fa-plus-circle'} mr-2`}></span>Asset Details</div>
                            {/* <div className="text-info">Showing event details</div> */}
                        </div>
                        <div className={`collapse ${state.showAssetDetails ? 'show' : ''}`}>
                            <div className="d-flex mb-1 border-mediumDarkGray-bottom">
                                <div className="py-1 w-50">
                                    <p className="b-block mb-0">Provider / Account / Region</p>
                                    <p className="mb-0 text-white">
                                        {state.selectedRecommendedEventData.provider ? state.selectedRecommendedEventData.provider.toUpperCase() : <span>&nbsp;</span>}
                                        {state.selectedRecommendedEventData.account_id ? (' : ')+getAccountNameFromId(state.selectedRecommendedEventData.account_id, accounts) : (state.selectedDiagnosticRcaEvent && state.selectedDiagnosticRcaEvent.account_id ? state.selectedDiagnosticRcaEvent.account_id : <span>&nbsp;</span>)}
                                        {state.selectedRecommendedEventData.region ?  (' : ')+getRegionName(state.selectedRecommendedEventData.region, regions) : <span>&nbsp;</span>}
                                    </p>
                                </div>                                    
                                <div className="py-1 w-50 pl-3">
                                    <p className="b-block mb-0">Service</p>
                                    <p className="mb-0 text-white">
                                        {state.selectedRecommendedEventData.resource_type ? state.selectedRecommendedEventData.resource_type : <span>&nbsp;</span>}
                                        {state.selectedRecommendedEventData.service_name ?  (' : ')+state.selectedRecommendedEventData.service_name : <span>&nbsp;</span>}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="py-1 w-50">
                                    <p className="b-block mb-0">Asset</p>
                                    {state.selectedRecommendedEventData.id_name_map && state.selectedRecommendedEventData.id_name_map !== "" ?
                                        <p className="mb-0 text-white">
                                            <span className={`ml-1`}>{(state.selectedRecommendedEventData.id_name_map.value ? state.selectedRecommendedEventData.id_name_map.value : "")}</span>
                                            {state.selectedRecommendedEventData.id_name_map.key && state.selectedRecommendedEventData.id_name_map.key !== "" ?
                                                <span className={`ml-1'} mb-0`}>
                                                    {" : "+state.selectedRecommendedEventData.id_name_map.key}
                                                </span>
                                            : null}
                                        </p>
                                    : 
                                        <p className="mb-0 text-white">{state.selectedRecommendedEventData.asset_name ? state.selectedRecommendedEventData.asset_name : <span>&nbsp;</span>}</p>
                                    }
                                </div>
                                <div className="py-1 w-50 pl-3">
                                    <p className="b-block mb-0">Asset Time</p>
                                    <p className="mb-0 text-white">{state.selectedRecommendedEventData.event_time ? momentConvertionUtcToLocalTime(state.selectedRecommendedEventData.event_time, 'DD MMM YYYY HH:mm:ss') : <span>&nbsp;</span>}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                : null}
            </div>
        </div>
    )
}

export default RecommendationsRightSection