/*************************************************
 * Tvastar
 * @exports
 * @file QosDetails.js
 * @author Prakash // on 21/09/2022
 * @copyright © 2022 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

// import _ from 'lodash'

import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction'
// import { DynamicApiToDevCall } from '../../../actions/commonActionNew'
// import ObjectListInputs from './ObjectListInputs'
import { onlyNumeric } from '../../../utils/utility';
import MultiSelectSection from '../../common/MultiSelectSection';
import Search from '../../common/SearchWithHiddenInput';

const QosDetails = (props) => {
	const [state, setState] = useState({
		hasErrorInRuleForm: false,
		hasError: props.hasError,
		masterData: props.masterData,
		filteredMasterData: props.masterData,
		showQosDetails: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)
	const hasError = useSelector(state => state?.hpc?.hpcPropsDetails?.hasError || false)

	const formLabelBuilder = useCallback((item) => {
		let querybuilder = []
		let query = item.label
		let splitString = query && query !== "" ?query.split(" ") : []
		splitString.forEach(str => {
			let result = str.split("$");
			if(result.length === 1) {
				let dataRow = {}
				dataRow.input = false
				dataRow.string = result[0]				
				querybuilder.push(dataRow)
			} else {
				result.forEach((resRow, index) => {
					if(resRow !== "") {
						if(index === 1) {
							let dataRow = {}
							dataRow.input = true
							dataRow.field = item.parameter
							dataRow.component = item.component
							dataRow.string = result[0]
							querybuilder.push(dataRow)
						} else {
							let dataRow = {}
							dataRow.input = false
							dataRow.string = resRow
							querybuilder.push(dataRow)
						}
					}
				})
			}
		})

		setState(prevState => ({ ...prevState, [item.parameter+"_label"]: querybuilder }))

	}, [])

	useEffect(() => {
		let masterData = state.masterData
		
		masterData.forEach(item => {
			if(item.component === "select" || item.component === "multiselect") {
				if(item.options) {
					setState(prevState => ({ ...prevState, [item.parameter+"_options"]: item.options, [item.parameter+"_0"]: item.default ? item.default : "" }))
				} else {
					setState(prevState => ({ ...prevState, apiCallVariable: item.variable, [item.variable+"_0"]: item.default ? item.default : "" }))
				}
			} else {
				setState(prevState => ({ ...prevState, [item.parameter]: item.value, [item.parameter+'_existing']: item.value }))
			}

			formLabelBuilder(item)
		})

	}, [qosCreateEditInputs, formLabelBuilder, state.masterData, props.viewMode])

	useEffect(() => {
		if(state.onChangeInput) {
			setState(prevState => ({ ...prevState, onChangeInput: false }))
			let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
			let parameters = []
			props.masterData.forEach(row => {
				Object.entries(row).forEach(([rKey, rValue]) => {
					let errorMessage = ''
					if(rKey === 'value') {
						if(rValue && !state[row.parameter]) {
							// row.value = state[row.parameter] ? state[row.parameter] : ''
							// row.value = rValue
							errorMessage = 'Existing value should not be left empty'
							// row.value = rValue
							setState(prevState => ({ ...prevState, [row.parameter]: state[row.parameter+'_existing'] }))
							
						} else {
							row.value = state[row.parameter] ? state[row.parameter] : ''
						}
						
						setState(prevState => ({ ...prevState, [row.parameter+'_error']: errorMessage}))
					} else if(rKey === 'value_list') {
						row.value_list = state[row.parameter] ? state[row.parameter] : []
					}
				})
				if(row.value) {
					parameters.push(row)
				}
			})

			obj.parameters = parameters
			
			dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
		}
	}, [dispatch, qosCreateEditInputs, props, state])

	const onChangeInput = (field, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
			let parameters = []
			props.masterData.forEach(row => {
				Object.entries(row).forEach(([rKey, rValue]) => {
					let errorMessage = ''
					if(rKey === 'value') {
						if(field === row.parameter) {
							if(rValue && !value) {
								errorMessage = 'Existing value should not be empty'
								row.value = value
							}
							row.value = value
						} else if(rValue && !state[row.parameter]) {
							errorMessage = 'Existing value should not be empty'
						} else {
							row.value = state[row.parameter] ? state[row.parameter] : ''
						}
						
						setState(prevState => ({ ...prevState, [row.parameter+'_error']: errorMessage}))
					} else if(rKey === 'value_list') {
						row.value_list = state[row.parameter] ? state[row.parameter] : []
					}
				})
				if(row.value) {
					parameters.push(row)
				}
			})

			obj.parameters = parameters
			
			dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		props.onChildPageClick(event)

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<React.Fragment>
			<div className='d-flex justify-content-between'>
				<h5 className='text-warning'>QOS Details</h5>
				{state.showQosDetails ?
					state.masterData && state.masterData.length ?
						<div style={{minWidth: "300px"}}>
						<Search
							data={state.masterData ? state.masterData : []}
							applyTags={false}
							applyLiteDarkTags={true}
							topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
							searchClassName={'px-2 f12'}
							searchIconColor={'text-white f12'}
							searchPlaceHolder={'Search....'}
							className={"bg-transparent text-white form-control-sm f12"}
							filteredData={(filteredMasterData) => {
								// let totalPages = 1
								// if(filteredMasterData && filteredMasterData.length > state.perPage) {
								// 	totalPages = Math.ceil(filteredMasterData.length / state.perPage)
								// }
								setState(prevState => ({ 
									...prevState,
									filteredMasterData
								}))
							}}
						/>
						</div>
					: null
				: null}
			</div>

			{state.showQosDetails ?
				state.filteredMasterData && state.filteredMasterData.map((item, iIndex) => {
					return(
						<div key={'master_'+iIndex} className={`py-3 mb-2 ${iIndex ? 'border-mediumDarkGray-top' : ''} policiesPage`} onClick={(event) => handleChildClick(event, 'parent')}>					
							<div className='mb-2 d-flex flex-wrap'>
								<span className='mr-3 align-self-end f18 text-info'>{item.parameter}</span>
								<span>
									{item.component === "multiselect" ?
										<div className='w-8'
											onClick={(event) => {
												event.preventDefault();
												handleChildClick(event, 'child', 'muliselectDropDown', "doprdown_"+iIndex)
											}}
										>
											<p className={`d-flex mt-3 mb-0 pb-1 bg-transparent boderDottedBottom ${state[item.parameter] && state[item.parameter].length ? 'text-white' : 'placeholder'}`}>{state[item.parameter] && state[item.parameter].length ? state[item.parameter].length+' Selected' : 'Select'}</p>
											{state['doprdown_'+iIndex] ?
												<MultiSelectSection
													fields={["value", "label"]}
													options={state[item.options]}
													selectedValues={state[item.parameter] ? state[item.parameter] : ''}
													callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [item.parameter]: value }))}
													singleSelection={false}
													hideSearch={false}
													topClass={'auto'}
													widthClass={'w-8'}
												/>
											: null}
										</div>
									: item.component === "select" ?
										<div className='w-8'
											onClick={(event) => {
												if(state["showDropdown_"+iIndex]) {
													event.preventDefault();
													handleChildClick(event, 'child', 'singleDropDown', "showDropdown_"+iIndex)
												}
											}}
										>
											<p className={`d-flex mt-3 mb-0 pb-1 boderDottedBottom ${state[item.parameter] ? 'text-white' : 'placeholder'}`}>{state[item.parameter] ? state[item.parameter] : 'Select'}</p>
											{state['showDropdown_'+iIndex] ?
												<MultiSelectSection
													fields={["value", "label"]}
													options={state[item.options] ? state[item.options] : []}
													selectedValues={state[item.parameter] ? state[item.parameter] : ''}
													callbackMultiSelect={(value) => {
														if(!value || typeof(value) === 'string') {
															setState(prevState => ({ ...prevState, [item.parameter]: value, onChangeInput: true }))
														  } else {
															value.preventDefault();
															handleChildClick(value, 'child', 'search', "")
														  }
													}}
													singleSelection={true}
													hideSearch={false}
													topClass={'auto'}
													widthClass={'w-8'}
												/>
											: null}
										</div>
									: item.component === "text" ?
										<div className='d-flex'>
											<input 
												type="text" 
												className={`border-0 text-white bg-transparent boderDottedBottom textBoxWidth`}
												style={{minHeight: "38px"}}
												placeholder={"enter"}
												value={state[item.parameter]}
												onChange={e => {
													let value = (item.data_type === 'integer' && e.target.value ? onlyNumeric(e.target.value) : e.target.value) 
													setState(prevState => ({ ...prevState, [item.parameter]: value, onChangeInput: true }))
													// onChangeInput(item.parameter, value)
													
												}}
												// onKeyDown={e => {
												// 	setState(prevState => ({ ...prevState, onChangeInput: true }))
												// }}
											/>
											{state[item.parameter+'_error'] ?
												<small className='text-danger ml-2 align-self-center'>{state[item.parameter+'_error']}</small>
											: null}
										</div>
									: null}
								</span>
							</div>
							<small className='text-white'>{item.label}</small>
						</div>
					)
				})
			: null}
		</React.Fragment>
	)
}

export default QosDetails
