import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listAllProviders, listClusterFilters, setCommonPropsDetails } from '../../../../actions/commonActionNew';
import MultiSelectSection from "../../../common/MultiSelectSection";

export default function FileFilter(props) {
  const dispatch = useDispatch();
  const [ state, setState ] = useState({})
  const closeFilters = useSelector(state => state?.filters?.commonPropsDetails?.closeFilters || false);
  const providers = useSelector(state => state?.filters?.providers || false)
  const propProjAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propProjAccounts || false);
  const propProjRegions = useSelector(state => state?.filters?.commonPropsDetails?.propProjRegions || false);
  const propProjClusters = useSelector(state => state?.filters?.commonPropsDetails?.propProjClusters || false);
  
   // list all providers
  useEffect(() => {
    dispatch(setCommonPropsDetails('fileManageFilterChange', {}))
  }, [dispatch])

  useEffect(() => { 
    if(providers) {
      setState(prevState => ({ ...prevState, providers: providers, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "" }));
    } else {
      let params = {}
      dispatch(listAllProviders(params))
        .then((response) => {
          if(response) {
            setState(prevState => ({ ...prevState, providers: response }));
          }
        })
    }
  }, [dispatch, providers]);

  useEffect(() => {
    if(state.selectedProvider) {
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      params.aggregate_by = 'account_id';
      let label = state.selectedProvider
  
      if(propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
          setState(prevState => ({ ...prevState, accounts: propProjAccounts[label] }));
      } else {
          dispatch(listClusterFilters(params))
              .then((response) => {
                  if(response) {
                      if(response.length) {
                          let obj = propProjAccounts ? propProjAccounts : {}
                          obj[label] = response
                          dispatch(setCommonPropsDetails('propProjAccounts', obj))
                      }
                      setState(prevState => ({ ...prevState, accounts: response }));
                  }
              })
      }
    }
  }, [state.selectedProvider, propProjAccounts, dispatch])

  useEffect(() => {
    if(state.selectedProvider) {
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      params.aggregate_by = 'region';
      let label = state.selectedProvider
      if(state.selectedAccount && state.selectedAccount.length) {
        label = ''
        params.account_id = state.selectedAccount
      }

      if(propProjRegions && propProjRegions.hasOwnProperty(label)) {
          setState(prevState => ({ ...prevState, regions: propProjRegions[label] }));
      } else {
          dispatch(listClusterFilters(params))
              .then((response) => {
                  if(response && response.length) {
                      if(label) {
                        let obj = propProjRegions ? propProjRegions : {}
                        obj[label] = response
                        dispatch(setCommonPropsDetails('propProjRegions', obj))
                      }
                      setState(prevState => ({ ...prevState, regions: response }))
                  }
              })
      }
    }
  }, [dispatch, propProjRegions, state.selectedProvider, state.selectedAccount])

  useEffect(() => {
    if(state.selectedProvider) {
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      params.aggregate_by = 'cluster_name';
      let label = state.selectedProvider
      if(state.selectedAccount && state.selectedAccount.length) {
        label = ''
        params.account_id = state.selectedAccount
      }
      if(state.selectedRegion && state.selectedRegion.length) {
        label = ''
        params.region = state.selectedRegion
      }

      if(propProjClusters && propProjClusters.hasOwnProperty(label)) {
          setState(prevState => ({ ...prevState, clusters: propProjClusters[label] }));
      } else {
          dispatch(listClusterFilters(params))
              .then((response) => {
                  if(response && response.length) {
                      if(label) {
                        let obj = propProjClusters ? propProjClusters : {}
                        obj[label] = response
                        dispatch(setCommonPropsDetails('propProjClusters', obj))
                      }
                      setState(prevState => ({ ...prevState, clusters: response }))
                  }
              })
      }
    }
  }, [dispatch, propProjClusters, state.selectedProvider, state.selectedAccount, state.selectedRegion])

  useEffect(() => {
    if(closeFilters) {
      setState(prevState => ({ ...prevState, isProviderOpen: false, isAccountOpen: false, isRegionOpen: false }))

      dispatch(setCommonPropsDetails('closeFilters', false))
    }
  }, [closeFilters, dispatch])


  const handleChildClick = (event, type, dropdownType, section) => {
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
      if(state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach(row => {
              setState(prevState => ({ ...prevState, [row]: false }))
          })
      }
      clickedChild.push(section)
      setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
    } else if(type === 'parent') {
      if(state.clickedChild && state.clickedChild.length) {
          state.clickedChild.forEach(row => {
              setState(prevState => ({ ...prevState, [row]: false }))
          })
      }
      
      setState(prevState => ({ ...prevState, clickedChild }))
    }
	}

  useEffect(() => {
    if(state.selectedProvider && state.selectedAccount && state.selectedRegion && state.selectedClusters) {
      let obj = {
        selectedProvider: state.selectedProvider,
        selectedAccount: state.selectedAccount,
        selectedRegion: state.selectedRegion,
        selectedClusters: state.selectedClusters,
      }
      dispatch(setCommonPropsDetails('fileManageFilterChange', obj))
    }
  }, [state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedClusters, dispatch])

  return (
    <>
      <div className='row mx-0 topBasicFilter' onClick={(event) => handleChildClick(event, 'parent')}>
        <div className='mr-3 pl-0' style={{minWidth: '150px'}}>
            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} onClick={(event) => {
                if(!state.isProviderOpen) {
                  event.preventDefault();
                  handleChildClick(event, 'child', 'singleDropDown', "isProviderOpen")
                }
              }}
            >
              <label className='mb-1 f10'>Provider</label>
              <p className={`d-flex mt-2 mb-0 ${state.selectedProvider ? 'text-white' : 'placeholder'}`}>{state.selectedProvider ? state.selectedProvider : 'Select'}</p>
              {state.isProviderOpen ?
                <MultiSelectSection 
                  fields={["provider_name", "provider_name"]}
                  options={state.providers}
                  topClass={'auto'}
                  widthClass={'minWidth220'}
                  selectedValues={state.selectedProvider ? state.selectedProvider : ''}
                  callbackMultiSelect={(value) => {
                    if(!value || typeof(value) === 'string') {
                      setState(prevState => ({ ...prevState, selectedProvider: value }))
                    } else {
                      value.preventDefault();
                      handleChildClick(value, 'child', 'search', "")
                    }
                  }}
                  hideSearch={true}
                  singleSelection={true}
              />
              : null}
            </div>
        </div>
        <div className='mr-3 pl-0' style={{minWidth: '150px'}}>
            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} 
              onClick={(event) => {
                event.preventDefault();
                handleChildClick(event, 'child', 'muliselectDropDown', "isAccountOpen")
              }}
            >
                <label className='mb-2 mt-1 f10 d-flex'>Account</label>
                <p className={`d-flex mt-2 mb-0 ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</p>
                {state.isAccountOpen ?
                    <MultiSelectSection 
                        fields={["account_id", "account_name"]}
                        options={state.accounts}
                        topClass={'auto'}
                        widthClass={'minWidth220'}
                        selectedValues={state.selectedAccount ? state.selectedAccount : []}
                        callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value, callRegions: true }))}
                    />
                : null}
            </div>
        </div>
        <div className='mr-3 pl-0' style={{minWidth: '150px'}}>
            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} 
              onClick={(event) => {
                event.preventDefault();
                handleChildClick(event, 'child', 'muliselectDropDown', "isRegionOpen")
              }}
            >
              <label className='mb-2 mt-1 f10 d-flex'>Region</label>
              <p className={`d-flex mt-2 mb-0 ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</p>
              {state.isRegionOpen ?
                  <MultiSelectSection 
                    fields={["region", "name"]}
                    options={state.regions}
                    topClass={'auto'}
                    widthClass={'minWidth220'}
                    selectedValues={state.selectedRegion ? state.selectedRegion : []}
                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
                  />
              : null}
            </div>
        </div>
        <div className='mr-3 pl-0' style={{minWidth: '150px'}}>
            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} onClick={(event) => {
                if(!state.isClusterOpen) {
                  event.preventDefault();
                  handleChildClick(event, 'child', 'singleDropDown', "isClusterOpen")
                }
              }}
            >
              <label className='mb-1 f10'>Cluster</label>
              <p className={`d-flex mt-2 mb-0 ${state.selectedClusters ? 'text-white' : 'placeholder'}`}>{state.selectedClusters ? state.selectedClusters : 'Select'}</p>
              {state.isClusterOpen ?
                <MultiSelectSection 
                  // fields={["provider_name", "provider_name"]}
                  options={state.clusters}
                  topClass={'auto'}
                  widthClass={'minWidth220'}
                  selectedValues={state.selectedClusters ? state.selectedClusters : ''}
                  callbackMultiSelect={(value) => {
                    if(!value || typeof(value) === 'string') {
                      setState(prevState => ({ ...prevState, selectedClusters: value }))
                    } else {
                      value.preventDefault();
                      handleChildClick(value, 'child', 'search', "")
                    }
                  }}
                  hideSearch={true}
                  singleSelection={true}
              />
              : null}
            </div>
        </div>
      </div>
    </>
  )
}