/*************************************************
 * Collider
 * @exports
 * @file Sidebar.js
 * @author Rajasekar // on 02/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from 'react-router-dom';


// import store from 'store'
import { Link } from 'react-router-dom'

// import { STORE } from '../../utils/constants'
import { getUserMenu } from '../../actions/commonActionNew'
import { URL_PATH } from '../../config/urlPath';

// import profilePicture from '../../assets/images/profilePicture.jpg'

const icons = [
	{label: "Job Status", value: "fas fa-clipboard"},
	{label: "Spend Board", value: "fas fa-database"},
	{label: "Observability", value: "fas fa-binoculars"}, 
	{label: "Catalog", value: "fas fa-layer-group"},
	{label: "Workstations", value: "fas fa-desktop"},
	{label: "Clusters", value: "fas fa-network-wired"},
	{label: "Resources", value: "fal fa-landmark"}, 
	{label: "Cost", value: "fas fa-sack-dollar"},
	{label: "Admin", value: "fas fa-cogs"},
	{label: "Governance", value: "fal fa-columns"},
	
	// {label: "Automations", value: "fa fa-cogs"},
	// {label: "Cases", value: "fab fa-ethereum"}, 
	// {label: "Checks", value: "fal fa-album-collection"}, 
	// {label: "Diagnostics", value: "fal fa-border-none"},
	// {label: "Policy", value: "fal fa-columns"},
	// {label: "Clusters", value: "fas fa-bezier-curve"}
]

// const userName = typeof store.get(STORE.NAME) === 'object' ? store.get(STORE.NAME).username : ''
// const firstLetter = 
// 	typeof store.get(STORE.NAME) === 'object' ?
// 		store.get(STORE.NAME).username ? store.get(STORE.NAME).username && store.get(STORE.NAME).username[0] : store.get(STORE.NAME).firstName ? store.get(STORE.NAME).firstName[0] : ""
// 	: ''

const Sidebar = () => {
	const [state, setState] = useState({
		showSidebar: false,
	})
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
	const profileDetails = useSelector(state => state.filters.profileDetails);
	
	const dispatch = useDispatch(); // Create a dispatch function
	const location = useLocation();
	const navigate = useNavigate();
	const currentPath = location.pathname;
	
	useEffect(() => {
		dispatch(getUserMenu({}))
	}, [dispatch])

	// useEffect(() => {
		// 	setState(prevState => ({ ...prevState, profileDetails: profileDetails }))
		// }, [profileDetails])

	useEffect(() => {
		if(userMenu && Object.entries(userMenu).length) {
			let subMenus = []
			userMenu.menu && userMenu.menu.forEach(row => {
				if(row.submenulist && row.submenulist.length) {
					let menus = row.submenulist.map(e => e.link);
					subMenus = [...subMenus, ...menus]
				} else {
					subMenus.push(row.link)
				}
			})
			if(userMenu.additionalUrls) {
				subMenus = [...subMenus, ...userMenu.additionalUrls]
			}
			// console.log("subMenus", subMenus)
			// additionalUrls
			if(!subMenus.includes(currentPath)) {
	            navigate(URL_PATH.PERMISSION_DENINED);
			}

			// dispatch(setCommonPropsDetails('userMenuAccess', subMenus))
		}
	}, [userMenu, currentPath, navigate])
		

	const handleClick = () => {
		document.addEventListener('mouseOver', () => onClick());
	}

	const onClick = () => {
		setState(prevState => ({ ...prevState, close: false }))
	}
	
	/**
	 * Renders sidebar of the screen
	 */
	return (
		<div id='navigation bg-black' className={`navigation ${state.showSidebar ? '' : 'nav-collapsed'}`} onMouseOver={handleClick}>
			<div className='nav-content bg-black'>
				<div className='nav-header bg-black'>
					<div className='collapse-nav' onClick={() => setState(prevState => ({ ...prevState, showSidebar: !prevState.showSidebar }))}>
						<div className='open-nav'>
							<i className='fal fa-align-left' />
						</div>
					</div>
					<div className='logo'>
						<Link to=''>
							<img src='' alt='' />
						</Link>
					</div>
					<div className='user-caption'>{profileDetails && profileDetails.userName ? profileDetails.userName[0].toUpperCase() : (profileDetails && profileDetails.firstName ? profileDetails.firstName[0].toUpperCase() : "")}</div>
					<p className='text-white text-center mt-2 mb-0'>{profileDetails && profileDetails.userName ? profileDetails.userName : (profileDetails && profileDetails.firstName ? profileDetails.firstName : "")}</p>
				</div>
				
				<div className='main-nav'>
					<nav className='nav flex-column'>
						{userMenu && userMenu.menu && userMenu.menu.map((item, index) => {
							return (
								item.submenulist && item.submenulist.length ? 
									<React.Fragment  key={"parent_child_index_"+index}>
										<span className='d-flex cursorPointer' style={{padding: "0.7rem 1.5rem"}}
											onClick={() => setState(prevState => ({ ...prevState, ['show_menu_'+index]: !state['show_menu_'+index] }))}>
											<i className={`
												${icons.filter(e => e.label === item.menu).length ? icons.filter(e => e.label === item.menu)[0].value : "fal fa-search"} 
												${((item.submenulist && item.submenulist.length && item.submenulist.filter(e => e.link === currentPath).length) || item.link === currentPath) && (currentPath === item.link || item.submenulist) ? ' text-info' : ' text-primary-color'} fs-15 mr-2`}/>

											<span className={`nav-text ${((item.submenulist && item.submenulist.length && item.submenulist.filter(e => e.link === currentPath).length) || item.link === currentPath) && (currentPath === item.link || item.submenulist) ? 'text-info' : 'text-white'}`}>{item.menu}</span>
										</span>
										<div className={`dropdown-menu ${state['show_menu_'+index] ? 'show' : ''}`} style={{padding: "0.0rem 1.8rem"}}>
											{item.submenulist.map((sub, i) => {
												return(
													<Link
														key={"child_index_"+index+"_"+i}
														className={`dropdown-item ${(sub.link === currentPath) ? 'text-info' : ''}`}
														to={sub.link}
														// onClick={() => this.setState({ activeMenu: item.menu }) }
													>
														<span className='nav-text'>{sub.submenu}</span>
													</Link>
												)
											})}
										</div>
									</React.Fragment>
								: 
									<Link
										key={"parent_index_"+index}
										className='nav-link dropdown-toggle'
										to={(!item.submenulist || (item.submenulist && !item.submenulist.length)) ? item.link : ""}
										onClick={event => {
											if(item.submenulist && item.submenulist.length) {
												event.preventDefault()
												setState(prevState => ({ ...prevState, ['show_menu_'+index]: !state['show_menu_'+index] }))
											} else {
												setState(prevState => ({ ...prevState, activeMenu: item.menu }))
											}
										}}
									>
										<i className={`${icons.filter(e => e.label === item.menu).length ? icons.filter(e => e.label === item.menu)[0].value : "fal fa-search"} ${((item.submenulist && item.submenulist.length && item.submenulist.filter(e => e.link === window.location.pathname).length) || item.link === window.location.pathname) && (window.location.pathname === item.link || item.submenulist) ? ' text-info' : ' text-primary-color'} fs-15 mr-2`} />
										<span className={`nav-text ${window.location.pathname === item.link && (!item.submenulist || (item.submenulist && !item.submenulist.length)) ? 'text-info' : 'text-white'}`}>{item.menu}</span>
									</Link>
							)
						})}
					</nav>
				</div>
			</div>
		</div>
	)
}

export default Sidebar
