/*************************************************
 * Collider
 * @exports
 * @file hpcReducer.js
 * @author Prakash // on 09/08/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../actions/types'

const INITIAL_STATE = {
	hpcPropsDetails: {}
}

/**
 * To store filters list in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const hpcReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_HPC_PROPS_DETAILS:
			return {
				...state,
				hpcPropsDetails: action.payload,
			}
		default:
			return state
	}
}
