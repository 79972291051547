/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file BudgetLandingPage.js
 * @author Prakash // on 17/05/2023
 * @copyright © 2023 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import { listBudgetDetails, deleteBudgetDetails, setHpcPropsDetails } from '../../../actions/Collider/HpcAction'

import { capitalizeTheFirstLetterOfEachWord, momentConvertionUtcToLocalTime } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { URL_PATH } from '../../../config/urlPath';
import { CSVLink } from 'react-csv';
import { Store as CommonNotification } from 'react-notifications-component';

const BudgetLandingPage = () => {
	const[state, setState] = useState({
		showLoading: true,
		forecastOptions: [
			{label: "Monthly", value: "monthly"},
			{label: "Quarterly", value: "quarterly"},
			{label: "Half Yearly", value: "half-yearly"},
			{label: "Annual", value: "yearly"},
		],
		// selectedPeriod: "quarterly",
		totalItemShowing: 5,
		itemMovedLeft: 0,
		itemMovedRight: 0,

		emptyOptions: [],
		actionsAllowed: [],
		callSearch: true
	})
	
	const userMenu = useSelector(state => state?.filters?.userMenu || false);
	
	const navigate = useNavigate();
    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
					if(row.action) {
						actionsAllowed = row.action
					}
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(listBudgetDetails(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listBudgets: response, filterListBudgets: response, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listBudgets: [], filterListBudgets: [], showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
        let dataResult = state.filterListBudgets && state.filterListBudgets.length ? state.filterListBudgets : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListBudgets])	

    const navigateTo = () => {
        navigate(URL_PATH.HPC_CREATE_BUDGET); // Redirect to the specified path
    }

	const deleteRecord = () => {
		setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let params = {}
        params.budget_id = state.deleteDetails && state.deleteDetails.budget_id
        if(params.budget_id) {
            dispatch(deleteBudgetDetails(params)).then((response) => {
                // setState(prevState => ({ ...prevState, showActionLoading: false, showUpdateActionLoading: false }))
                let messageType = 'success'
                let message = response.message
                if(!response.status) {   
                    messageType = 'danger'
					setState(prevState => ({ ...prevState, showDeleteLoading: false }))
                } else {
                    setState(prevState => ({ ...prevState, deleteModal: false, callSearch: true, showDeleteLoading: false }))
                }
                // setState(prevState => ({ ...prevState, saveLoading: false, message, messageType, createErrorMessage }))

                CommonNotification.addNotification({
                    //title: 'Wonderful!',
                    message: message,
                    type: messageType,
                    insert: 'top',
                    container: 'top-center',
                    // animationIn: ['animate__animated', 'animate__fadeIn'],
                    // animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                        duration: 5000,
                        onScreen: false,
                        pauseOnHover: true,
                        showIcon: true,
                    }
                });
            })   
        }
	}

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
			<div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div>
			<div className='col-sm-12 p-0'>
				<div className='row mx-0 pb-2 bg-nero'>
					<div className='col-lg-12 col-md-12 col-sm-12 py-2'>
						<h6 className='text-white m-0'>Budgets</h6>
						<p className='text-white m-0'>Showing the list of Budgets</p>
					</div>			
				</div>
				<div className='row mx-0'>
					<div className='col-sm-12'>
						<div className='mt-2'>
							<div className='d-flex justify-content-between pt-2'>
								<div className=''>
									<p className='mb-0'>
										<small>Showing {state.filterListBudgets && state.filterListBudgets.length} out of total {state.listBudgets && state.listBudgets.length} budget(s)</small>
									</p>
								</div>
								
								<div className='align-self-center w-30'>
									<div className='d-flex justify-content-end'>
										{state.filterListBudgets && state.filterListBudgets.length ?
											<CSVLink
												data={state.filterListBudgets ? state.filterListBudgets : []} 
												headers={state.tableHeaders ? state.tableHeaders : []}
												filename={'job-lists.csv'}
												className={'mt-2'}
												target='_blank'
											>
												<span className='d-flex text-primary-color mr-2'>
													<i className='fas fa-download text-primary-color mr-2'/>
												</span>
											</CSVLink>
										: null}
										{state.listBudgets && state.listBudgets.length && state.listBudgets.length ?
											<Search
												data={state.listBudgets ? state.listBudgets : []}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-white f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white form-control-sm f12"}
												filteredData={(filterListBudgets) => setState(prevState => ({ ...prevState, filterListBudgets }))}
											/>
										: null}
										{state.actionsAllowed && state.actionsAllowed.includes('create') ?
											<button className={`btn bg-green p-1 text-white ml-2`} 
											onClick={() => {
												dispatch(setHpcPropsDetails('createInputs', {}))
												dispatch(setHpcPropsDetails('selectedAplliesTo', {}))
												navigateTo()
											}}>Create&nbsp;Budget</button>
										: null}
									</div>                                    
								</div>
							</div>

							<div className='row mx-0'>
								<div className='col-lg-12 col-12 px-0 py-1'>
									{state.filterListBudgets && state.filterListBudgets.length ?
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: 'Date',
													accessor: d => d.modified_at ? "modified_at" : 'created_at',
													Cell: cellInfo => (
													<span>
														{cellInfo.row.original.modified_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.modified_at, 'DD MMM YYYY HH:mm') : (cellInfo.row.original.created_at ? momentConvertionUtcToLocalTime(cellInfo.row.original.created_at, 'DD MMM YYYY HH:mm') : '')}
													</span>
													),
													width: 200
												},
												{
													Header: 'Name',
													accessor: 'budget_name',
													// Cell: cellInfo => (
													// 	<div className="d-flex justify-content-between align-self-center">
													// 		{state.actionsAllowed && state.actionsAllowed.includes('update') ?
													// 			<Link 
													// 				to={URL_PATH.HPC_CREATE_BUDGET} 
													// 				state={{
													// 					selectedData: cellInfo.row.original,
													// 					pageType: 'edit',
													// 				}}
													// 				className='text-info'>
													// 				{cellInfo.row.original.budget_name}
													// 			</Link>
													// 		: null}
													// 	</div>
													// ),
													width: 200,
												},
												{
													Header: 'Year',
													accessor: 'financial_year',
													width: 100,
												},
												{
													Header: 'Department',
													accessor: 'department',
													width: 150,
												},
												{
													Header: 'Initiative',
													accessor: 'initiative',
													width: 150,
												},
												{
													Header: 'Project',
													accessor: 'project',
													width: 150,
												},
												{
													Header: 'Actions',
													Cell: cellInfo => (
														<div className="d-flex justify-content-between align-self-center">
															{state.actionsAllowed && state.actionsAllowed.includes('update') ?
																<Link 
																	to={URL_PATH.HPC_CREATE_BUDGET} 
																	state={{
																		selectedData: cellInfo.row.original,
																		pageType: 'edit',
																	}}
																	className='text-info'>
																	Edit
																</Link>
															: null}
															{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
																<p className='ml-3 mb-0 text-danger cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, deleteModal: true, deleteDetails: cellInfo.row.original }))}>Delete</p>
															: null}
														</div>
													),
													width: 150
												},
												{
													Header: 'Description',
													accessor: 'description',
													width: 300
												},
											]}
											data={state.filterListBudgets}
											perPage={20}
											tableHead={'bg-black text-white'}
											tableBody={'bg-dark3 text-white'}
											tableType={'table-dark-hover'}
											perPageClass={`bg-transparent text-lightGray`}
											textColor={'text-black'}
											selectedColor={'bg-transparent'}
											dashboard={state.filterListBudgets && state.filterListBudgets.length ? true : false}
											onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedlistBudgets: tableRow }))}
											// tableSize={'table-sm'}
										/>
									: 
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									}
								</div>
								<Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, deleteWorksatationDetails: {} }))} {...state.selectedDeleteItem}>
									<ModalHeader close toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, deleteWorksatationDetails: {} }))}>Delete Budget</ModalHeader>
									<ModalBody>
										Are you sure you want to delete the budget <span className='f18 mx-1'>"{state.deleteDetails && state.deleteDetails.budget_name ? state.deleteDetails.budget_name : ''}"</span>
										{/* <Input
											type='text'
											placeholder='Enter Queue name   '
											className={`inputTextbox bg-transparent border-lightGray text-black`}
											// maxlength='128'
											value={state.queue_name ? state.queue_name : ''}
											onChange={e => handleInputChange('queue_name', e.target.value)}
										/> */}
										{state.deleteConfirmMessage ?
											<small className='text-red'>{state.deleteConfirmMessage}</small>
										: null}
										{/* Are you sure you want to delete the <span className='f18 mx-1 text-red'>{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : 'Name'}</span> queue */}

									</ModalBody>
									<ModalFooter className="border-0">
									<Button color="primary" onClick={() => deleteRecord()}>
										{state.showDeleteLoading ?
											<Spinner size='sm' color='light' className='mx-2' />
										: null}Ok
									</Button>{' '}
									<Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, deleteModal: false, deleteWorksatationDetails: {} }))}>
										Cancel
									</Button>
									</ModalFooter>
								</Modal>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BudgetLandingPage