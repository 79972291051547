/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file QueryResults.js
 * @author Prakash // on 13/09/2023
 * @copyright © 2023 Tvastar. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { Spinner, UncontrolledTooltip } from 'reactstrap'

import { getDayFromSelectedDuration, momentDateGivenFormat, momentConvertionUtcToLocalTime, momentConvertionLocalToUtcTime, currentLocaltime, subHours, subDays, capitalizeTheFirstLetterOfEachWord, alphaNumeric } from '../../../utils/utility'

import Select from 'react-select'
import MultiSelectSection from '../../common/MultiSelectSection';
import Search from '../../common/SearchComponent'
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import { startQueryExection } from '../../../actions/QueryAction'
import { getCostQueryResults } from '../../../actions/cost/CostQueryAction'
import { dynamicCallApi } from '../../../actions/DynamicLoadAction'


import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { CSVLink } from "react-csv";

const durationOptions = [
	{ value: '+1h', option: 'Last 1 hour' },
    { value: '+3h', option: 'Last 3 hours' }, 
    { value: '+6h', option: 'Last 6 hours' },
    { value: '+12h', option: 'Last 12 hours' },
	{ value: '+24h', option: 'Last 24 hours' },
	{ value: '+3d', option: 'Last 3 days' },
	{ value: '+7d', option: 'Last 1 week' },
	{ value: '+14d', option: 'Last 2 weeks' },
	{ value: '+30d', option: 'Last 1 month' },
	{ value: 'custom', option: 'Custom Date' },
]

const commonFields = ["severity", "event_time", "importance", "event_id", "description", "message", "provider", "event_source", "event_name", "asset_name", "account_id", "region", "resource_type", "service_name", "additional_details", "assets", "event_analysis"]

const queryDateTime = current => {
	return current.isBefore(currentLocaltime()) && current.isAfter(subDays(currentLocaltime(), 90))
}

const QueryResults = (props) => {
    // State variables for filters
    const accountRef = useRef()
    const regionRef = useRef()

    const [state, setState] = useState({
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        overAllTotalPages: 1,
        perPage: 100,

        accounts: [],
        regions: [],
        selectedQueryProvider: props.selectedProvider ? props.selectedProvider : '', 
        selectedQueryAccount: props.selectedAccount ? props.selectedAccount : [], 
        selectedQueryRegion: props.selectedRegion ? props.selectedRegion : [], 
        selectedQueryDuration: "",

        selectedProvider: props.selectedProvider,
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        selectedQuery: props.selectedQuery,
        selectedInput: props.selectedInput,
        querybuilder: props.querybuilder,
        queryStartTime: momentConvertionUtcToLocalTime(props.queryStartTime, "YYYY-MM-DD HH:mm:00"),
        queryEndTime: momentConvertionUtcToLocalTime(props.queryEndTime, "YYYY-MM-DD HH:mm:00"),
        duration: props.duration
    })
    // const providers = useSelector(state => state.filters.providers);
	const costQueryResult = useSelector(state => state?.cost?.costQueryResult || false)
    const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

    const dispatch = useDispatch(); // Create a dispatch function

    useEffect(() => {
        if(providers) {
            setState(prevState => ({
                ...prevState,
                providers,
                selectedProvider: props.selectedProvider ? props.selectedProvider : (props.providers && props.providers.length ? (props.providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : props.providers[0].provider_name) : ""),
            }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
					if(response) {
                        setState(prevState => ({
                            ...prevState,
                            providers: response,
                            selectedProvider: props.selectedProvider ? props.selectedProvider : (response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : '')
                        }));
                    }
            })
        }
        // Run the code inside this block once on component mount
    }, [dispatch, providers, props]); // Empty dependency array ensures this runs only once on mount

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

    useEffect(() => {
        setState(prevState => ({ ...prevState, processSelectedInput: true, structureQueryResults: true }))
    }, [state.selectedQuery])

    useEffect(() => {
        if(state.processSelectedInput) {
            setState(prevState => ({ ...prevState, processSelectedInput: false }))
            if(state.selectedQuery && state.selectedQuery.display_output && Object.entries(state.selectedQuery.display_output).length) {
                Object.values(state.selectedQuery.display_output).forEach((item, i) => {
                    if(i === Object.entries(state.selectedQuery.display_output).length - 1) {
                        setState(prevState => ({ ...prevState, querySelection: true }))
                    }
                    setState(prevState => ({ ...prevState, [item.output_field]: state.selectedInput && state.selectedInput[item.output_field] }))
                })
                
            }
    
            if(state.selectedQuery && state.selectedQuery.required_fields && state.selectedQuery.required_fields.length) {
                if(state.selectedQuery.required_fields.includes('account_id')){
                    setState(prevState => ({ 
                        ...prevState, 
                        // selectedQueryAccount: state.selectedInput && state.selectedInput.account_id && state.selectedInput.account_id !== "ALL" ? state.selectedInput.account_id : [] 
                    }))
                }
                if(state.selectedQuery.required_fields.includes('region')){
                    setState(prevState => ({ 
                        ...prevState, 
                        selectedQueryRegion: state.selectedInput && state.selectedInput.region && state.selectedInput.region !== "ALL" ? state.selectedInput.region : [] 
                    }))
                }
                if(state.selectedQuery.required_fields.includes('duration')){
                    let duration = state.duration
                    setState(prevState => ({ ...prevState,
                        selectedQueryDuration: state.duration,
                        showQueryDateTimePicker: duration === "custom" ? true : false
                    }))
                }
            }
        }
    }, [state.processSelectedInput, state.duration, state.selectedInput, state.selectedQuery])

    

    const dynamicCallApiFunction = useCallback((params, selectedQuery, apiDetails, outputField, outputType) => {
		dispatch(dynamicCallApi(apiDetails.api_name, apiDetails.api_resource, params))
            .then((response) => {
                if(response) {
                    let options = []
                    if(outputType === "list") {
                        let dataRow = {}
                        dataRow.key = "All"
                        dataRow.value = "All"
                        options.push(dataRow)
                    }
                    if(response.results) {
                        let results = response.results
                        results.forEach(res => {
                            if(apiDetails.field_name === "id_name_map") {
                                options.push(res[apiDetails.field_name])
                            } else {
                                let dataRow = {}
                                dataRow.key = res[apiDetails.field_name]
                                dataRow.value = res[apiDetails.field_name]
                                options.push(dataRow)
                            }
                        })
                    }
                    setState(prevState => ({ ...prevState,["options_"+outputField]: options }))
                    // if(outputType === "list") {
                    //     handleMultiSelectChange(outputField, state[outputField], options)
                    // }
                }
            })
	}, [dispatch])

    useEffect(() => { //querySelection
        if(state.querySelection) {
            setState(prevState => ({ ...prevState, querySelection: false }))
            let item = state.selectedQuery
            if(item && item.display_output && Object.entries(item.display_output).length) {
                Object.values(item.display_output).forEach(out => {
                    // if(out.default) {
                    //     this.setState({ [out.output_field]: item.default })
                    // }
                    if(out.api_details && Object.entries(out.api_details).length) {
                        let apiDetails = out.api_details
                        let params = apiDetails.api_request
                        if(apiDetails.additional_fields && apiDetails.additional_fields) {
                            apiDetails.additional_fields.forEach(addFields => {
                                if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                                    params.account_id = state.selectedAccount
                                }
                                if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                                    params.region = state.selectedRegion
                                }
                            })
                        }
                        let manditoryFieldsFilled = true
                        if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
                            apiDetails.mandatory_fields.forEach(manFields => {
                                if(!state[manFields] || state[manFields] === "" || (Array.isArray(state[manFields]) && !state[manFields].length)) {
                                    manditoryFieldsFilled = false
                                } else {
                                    params[manFields] = state[manFields]
                                }
                            })
                        }
                        if(manditoryFieldsFilled) {
                            dynamicCallApiFunction(params, item, apiDetails, out.output_field, out.output_type)
                        }

                    } else if(out.type === "dropdown" && out.output_type === "list") {
                        let options = ["All"]
                        options =  options.concat(out.values)
                        setState(prevState => ({ ...prevState, ["drop_options_"+out.output_field]: options }))
                        // handleMultiSelectChange(out.output_field, state[out.output_field], options)
                    }
                })
            }
        }
	}, [state.querySelection, state, dynamicCallApiFunction])

	useEffect(() => {
        if(state.selectedQueryDuration && state.queryDurationFunction) {
            let duration = state.selectedQueryDuration
            let period = duration.substring(duration.length, duration.length-1)
            let durationBtw = getDayFromSelectedDuration(duration)
            let startTime = ""
            if(period === "d") {
                startTime = momentDateGivenFormat(subDays(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
            } else {
                startTime = momentDateGivenFormat(subHours(new Date(), durationBtw), 'YYYY-MM-DD HH:mm:ss')
            }
            
            let endTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
    
            setState(prevState => ({ ...prevState, queryStartTime: startTime, queryEndTime: endTime, queryDurationFunction: false }))
        }
	}, [state.queryDurationFunction, state.selectedQueryDuration])

    useEffect(() => {
        if(state.structureQueryResults) {
            //  let listAllQueries = this.props.queryResult
            //  let listQueryLabel = this.props.queryResult.labels
            let listAllQueries = costQueryResult && costQueryResult.results ? costQueryResult.results : []
    
            let listQueryLabel = costQueryResult && costQueryResult.labels ? costQueryResult.labels : {}
                
            let totalPages = 1
            if(listAllQueries.length > state.perPage) {
                totalPages = Math.ceil(listAllQueries.length / state.perPage)
            }
    
            setState(prevState => ({ ...prevState, listAllQueries, selectedPeriodData: listAllQueries, filteredArray: listAllQueries, totalPages, listQueryLabel, structureQueryResults: false, formDownloadData: true }))            
        }
    }, [state.structureQueryResults, costQueryResult, state.perPage])
    

    useEffect(() => {
        if(state.formDownloadData && state.filteredArray && state.filteredArray.length) {
            let headers = [] 
            Object.keys(state.filteredArray[0]).forEach(item => {
                let dataRow = {}
                dataRow.label = capitalizeTheFirstLetterOfEachWord(item)
                dataRow.key = item
                headers.push(dataRow)
            })
    
            setState(prevState => ({ ...prevState,headers }))
        }
    }, [state.formDownloadData, state.filteredArray])
	
	const handleStartTimeChange = (date) => {
		let queryStartTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		let queryEndTime = state.queryEndTime
		if(new Date(queryStartTime).getTime() > new Date(queryEndTime).getTime() ) {
			queryEndTime = momentDateGivenFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')
		}
		setState(prevState => ({ ...prevState,queryStartTime, queryEndTime}))
	}

	const handleEndTimeChange = (date) => {
		let queryStartTime = state.queryStartTime
		let queryEndTime = momentDateGivenFormat(date, 'YYYY-MM-DD HH:mm:ss')
		if(new Date(queryEndTime).getTime() > new Date(queryStartTime).getTime() ) {
			setState(prevState => ({ ...prevState,queryEndTime}))
		}
	}

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }


	useEffect(() => {
        if(state.executeQuery && state.selectedQuery) {
            setState(prevState => ({ ...prevState, executeQuery: false }))
            let selectedQuery  = state.selectedQuery
            let hasError = false
    
            let params = {}
            params.query_id = selectedQuery.query_id
            // params.selectedCategory = selectedQuery.category
            if(selectedQuery.required_fields && selectedQuery.required_fields.length) {
                selectedQuery.required_fields.forEach(item => {
                    if(item === "account_id" && state.selectedQueryAccount && state.selectedQueryAccount.length && !state.selectedQueryAccount.includes("All")) {
                        // params.account_id = state.selectedQueryAccount.filter(e => e !== "All")
                        params.account_id = state.selectedQueryAccount
                    } else if(item === "account_id")  {
                        params.account_id = "ALL"
                    }
                    if(item === "region" && state.selectedQueryRegion && state.selectedQueryRegion.length && !state.selectedQueryRegion.includes("All")) {
                        params.region = state.selectedQueryRegion
                    } else if(item === "region")  {
                        params.region = "ALL"
                    }
                    if(item === "duration") {
                        params.start_time = momentConvertionLocalToUtcTime(state.queryStartTime, "YYYY-MM-DD HH:mm:00")
                        params.end_time = momentConvertionLocalToUtcTime(state.queryEndTime, "YYYY-MM-DD HH:mm:00")
                    }
                })
            }
    
            if(selectedQuery.display_output && Object.entries(selectedQuery.display_output).length) {
                Object.entries(selectedQuery.display_output).forEach(([key, value]) => {
                    if(value.type === "text") {                    
                        if(value.output_type === "string") {
                            if(!state[value.output_field] || state[value.output_field] === "") {
                                hasError = true
                            } else if(value.validation && Object.entries(value.validation).length) {
                                let validation = value.validation
                                if(validation.min && state[value.output_field].length < validation.min) {
                                    hasError = true
                                    setState(prevState => ({ ...prevState,['error_message_'+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
                                } else if(validation.max && state[value.output_field].length > validation.max) {
                                    hasError = true
                                    setState(prevState => ({ ...prevState,['error_message_'+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
                                } else {
                                    setState(prevState => ({ ...prevState,['error_message_'+value.output_field]: "" }))
                                }
                            } else {
                                params[value.output_field] = state[value.output_field]
                            }
                        } else if(value.output_type === "list") {
                            if(!state[value.output_field] || !state[value.output_field].length) {
                                hasError = true
                            } else {
                                if(value.api_details && value.include_all) {
                                    if(state[value.output_field] && state[value.output_field].includes("All")) {
                                        params[value.output_field] = ["All"]
                                    } else {
                                        params[value.output_field] = state[value.output_field]
                                    }
                                } else {
    
                                    if(state[value.output_field].length > 1 && state[value.output_field].includes("All")) {
                                        let data = state[value.output_field]
                                        params[value.output_field] = data.filter(e => e !== 'All')
                                    } else {
                                        params[value.output_field] = state[value.output_field]
                                    }
                                }
                            }
                        }
                    } else {
                        let output = state[value.output_field] ? state[value.output_field] : []
                        // if(value.output_type === "list") {
                        // 	output = state[value.output_field]
                        // }
                        
                        if(value.output_type === "string") {
                            if(!state[value.output_field] || state[value.output_field] === "") {
                                hasError = true
                            } else if(value.validation && Object.entries(value.validation).length) {
                                let validation = value.validation
                                if(validation.min && state[value.output_field].length < validation.min) {
                                    hasError = true
                                    setState(prevState => ({ ...prevState,['error_message_'+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
                                } else if(validation.max && state[value.output_field].length > validation.max) {
                                    hasError = true
                                    setState(prevState => ({ ...prevState,['error_message_'+value.output_field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
                                } else {
                                    setState(prevState => ({ ...prevState,['error_message_'+value.output_field]: "" }))
                                }
                            } else {
                                params[value.output_field] = state[value.output_field]
                            }
                        } else if(value.output_type === "list") {
                            if(!output || !output.length ) {
                                hasError = true
                            }
                        } else {
                            if(!value || value === "") {
                                hasError = true
                            }
                        }
                        if(output.includes("All") && value.output_type === "list") {
                            if(value.include_all) {
                                output = ["All"]
                            } else {
                                output = output.filter(e => e !== 'All')
                            }
                        }
                        params[value.output_field] = output
                    }
                })
            }    
    
            setState(prevState => ({ ...prevState, hasError }))
    
            if(!hasError) {
    
                if(selectedQuery.async) {
                    setState(prevState => ({ ...prevState, queryLoading: true, startQueryExectionFunction: params }))
                } else {
                    setState(prevState => ({ ...prevState, queryLoading: true, getQueryResult: true, getQueryResultParams: params }))
                }
            }
        }
	}, [state.executeQuery, state.selectedQuery, state])

	useEffect(() => {
        if(state.startQueryExectionFunction && Object.entries(state.startQueryExectionFunction).length) {
            dispatch(startQueryExection(state.startQueryExectionFunction))
                .then((response) => {
                    if(response) {
                        if(response.status === "FAILED") {
                            setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false }))
                        } else {
                            setState(prevState => ({ ...prevState, queryLoading: true, getQueryResult: true, getQueryResultParams: response }))
                        }
                    } else {
                        setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false }))
                    }
                })
        }
	}, [state.startQueryExectionFunction, dispatch])

	useEffect(() => {
        if(state.getQueryResult && state.getQueryResultParams) {
            dispatch(getCostQueryResults(state.getQueryResultParams))
                .then((response) => {
                    if(response) {
                        if(response.status === "RUNNING") {
                            setState(prevState => ({ ...prevState, getQueryResult: false }))
                            setTimeout(() => { setState(prevState => ({ ...prevState, getQueryResult: true })) }, 3000)
                        } else if(response.status === "FAILED") {
                            setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, structureQueryResults: true }))
                        } else {
                            setTimeout(() => {
                                setState(prevState => ({ ...prevState, queryResult: response, queryLoading: false, getQueryResult: false, structureQueryResults: true }))
                            }, 2000)
                        }
                    } else {
                        setState(prevState => ({ ...prevState, queryErrorMessage: response, queryLoading: false, getQueryResult: false }))
                    }
                })
        }
	}, [state.getQueryResult, state.getQueryResultParams, dispatch])
    

	const onKeyDown = (e, field, stateField) => {
		let array = state[stateField] && Array.isArray(state[stateField]) ? state[stateField] : []
		//|| e.keyCode === 188
		if (e.keyCode === 13 || e.keyCode === 9) {
			if(state[field] && state[field] !== "") {
				array.push(state[field].trim())
				if(e.keyCode === 9) {
					e.preventDefault();
				}
				setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
			}
		}
	}

	const onBlur = (e, field, stateField) => {
		let array = state[stateField] ? state[stateField] : []
		if(state[field] && state[field] !== "") {
			array.push(state[field].trim())
			setState(prevState => ({ ...prevState, [stateField]: array, [field]: "" }))
		}
	}

	const removeSelectedBadge = (field, value, index) => {
		let filteredReslt = state[field]
		filteredReslt.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: filteredReslt }))
	}

	const onChangeTextString = (outputStructure, field, value) => {
		let hasError = false
		let validation = outputStructure.validation
		if(validation) {
			if(validation.min && value.length < validation.min) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else if(validation.max && value.length > validation.max) {
				hasError = true
				setState(prevState => ({ ...prevState, ['error_message_'+field]: 'field should be greater than '+ validation.min +' and lesser than '+ validation.max }))
			} else {
				setState(prevState => ({ ...prevState, ['error_message_'+field]: "" }))
			}
			if(validation.data_type) {
				if(validation.data_type === "alphanumeric") {
					value = alphaNumeric(value)
				}
			}
		}
		
		setState(prevState => ({ ...prevState, [field]: value, hasError }))
	}

	const onChangeTextDropDown = (displayOutput, changedKey) => {
		let selectedQuery = state.selectedQuery
		let keysToReload = displayOutput[changedKey].api_details.keys_to_reload
		if(keysToReload && keysToReload.length) {
			keysToReload.forEach(item => {
				let out = displayOutput[item]
				// if(out.default) {
				// 	this.setState({ [out.output_field]: selectedQuery.default })
				// }
				if(out.api_details && Object.entries(out.api_details).length) {
					let apiDetails = out.api_details
					let params = apiDetails.api_request
					if(apiDetails.additional_fields && apiDetails.additional_fields) {
						apiDetails.additional_fields.forEach(addFields => {
							if(addFields === "account_id" && state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
								params.account_id = state.selectedAccount
							}
							if(addFields === "regions" && state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
								params.region = state.selectedRegion
							}
						})
					}
					let manditoryFieldsFilled = true
					if(apiDetails.mandatory_fields && apiDetails.mandatory_fields.length) {
						apiDetails.mandatory_fields.forEach(manFields => {
							if(!state[manFields] || state[manFields] === "" || (Array.isArray(state[manFields]) && !state[manFields].length)) {
								manditoryFieldsFilled = false
							} else {
								params[manFields] = state[manFields]
							}
						})
					}
					if(manditoryFieldsFilled) {
                        setState(prevState => ({ ...prevState, [out.output_field]: out.output_type === "text" ? "" : [] }),
							() => dynamicCallApiFunction(params, selectedQuery, apiDetails, out.output_field, out.output_type)
						)
					}
				}
			})
		}
	}

    const handleClickOutside = (event) => {
        if (accountRef.current && !accountRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isAccountOpen: false }));
        } else {
            setState(prevState => ({ ...prevState, isAccountOpen: true }));
        }
        if (regionRef.current && !regionRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isRegionOpen: false }));
        } else {
            setState(prevState => ({ ...prevState, isRegionOpen: true }));
        }
        // if (clusterRef.current && !clusterRef.current.contains(event.target)) {
        //     setState(prevState => ({ ...prevState, isClusterOpen: false }));
        // } else {
        //     setState(prevState => ({ ...prevState, isClusterOpen: true }));
        // }

		// if (daterRangeRef.current && !daterRangeRef.current.contains(event.target)) {
        //     setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		// }

        // if (topStatusRef.current && !topStatusRef.current.contains(event.target)) {
        //     setState(prevState => ({ ...prevState, showTopStatusFilter: false }));
		// }
    };

    return (
        <React.Fragment>
            <div className="rounded bg-black p-3 w-100" onClick={(event) => handleClickOutside(event)}>
                <div className="d-flex justify-content-between">
                    <div className="">
                        <h6 className="text-white ">Query Details</h6>
                        <p className="mb-0">{state.selectedQuery.query_id +" - "+ (state.selectedQuery.title ? state.selectedQuery.title : "")}</p>
                    </div>
                    <button className="btn btn-sm bg-ligtGray text-decoration-none text-white align-self-center" onClick={() => props.hideQueryResults()} >Back</button>
                </div>
                {state.querybuilder ?
                    <React.Fragment><div className={`form-group dlpDescriptionForm dlpDescriptionFormPlain bg-black rounded mb-2`}>
                        <div className={`d-flex flex-wrap ${state.queryLoading ? "disabled" : ""}`}>
                            {state.querybuilder ?
                                <React.Fragment>
                                    <p className={`mb-0 mr-2 align-self-end text-white`}>Query:</p>
                                    {state.querybuilder.map((qry, i) => {
                                        return(
                                            <React.Fragment key={'qur_'+i}>
                                            {!qry.input ?
                                                <p className={`mb-0 mr-2 align-self-end text-info`}>{qry.string}</p>
                                            :
                                                <React.Fragment>
                                                    {state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "text" ?
                                                        state.selectedQuery.display_output[qry.field].output_type === "string" ?
                                                            <React.Fragment>
                                                            {state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
                                                                <Select
                                                                    placeholder={'Select'}
                                                                    isSearchable={true}
                                                                    components={{
                                                                        IndicatorSeparator: () => null
                                                                    }}
                                                                    className={`selectOption mr-2 mt-3 ${state.hasError && !state[state.selectedQuery.display_output[qry.field].output_field] ? 'dottedDangerSelectLine' : ''}`}
                                                                    value={({
                                                                        value: state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field] !== "" ? state[state.selectedQuery.display_output[qry.field].output_field] : 'Select',
                                                                        label: state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field] !== "" ? state[state.selectedQuery.display_output[qry.field].output_field] : <span className="placeholder">Select</span>
                                                                    })}		
                                                                    options={state["options_"+state.selectedQuery.display_output[qry.field].output_field] && state["options_"+state.selectedQuery.display_output[qry.field].output_field].map(res => ({
                                                                        value: res.value,
                                                                        label: res.value+(res.value !== res.key ? " ("+res.key+")" : "")
                                                                    }))}															
                                                                    onChange={event => setState(prevState => ({ ...prevState, [state.selectedQuery.display_output[qry.field].output_field]: event.value }), () => onChangeTextDropDown(state.selectedQuery.display_output, qry.field))}
                                                                />
                                                            :
                                                                <React.Fragment>
                                                                <input 
                                                                    type="text" 
                                                                    className={`inputField mt-3 w-30 ${state.hasError && (!state[state.selectedQuery.display_output[qry.field].output_field] || state[state.selectedQuery.display_output[qry.field].output_field] === "" || state["error_message_"+state.selectedQuery.display_output[qry.field].output_field] !== "")  ? 'dottedDangerTextboxLine' : ''}`}
                                                                    placeholder={qry.field}
                                                                    value={state[state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.display_output[qry.field].output_field] : ''}
                                                                    onChange={e => onChangeTextString(state.selectedQuery.display_output[qry.field], state.selectedQuery.display_output[qry.field].output_field, e.target.value)}
                                                                />
                                                                {state.selectedQuery.display_output[qry.field].validation ?
                                                                    <React.Fragment>
                                                                    <i id={state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer"></i>
                                                                    <UncontrolledTooltip target={state.selectedQuery.display_output[qry.field].output_field}>
                                                                    <div className="uncontrolledTooltipLeftAlign">
                                                                        {state.selectedQuery.display_output[qry.field].validation.data_type ?
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Data Type:</span>
                                                                                {state.selectedQuery.display_output[qry.field].validation.data_type}
                                                                            </p>
                                                                        : null}
                                                                        {state.selectedQuery.display_output[qry.field].validation.min ?
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Min length:</span>
                                                                                {state.selectedQuery.display_output[qry.field].validation.min}
                                                                            </p>
                                                                        : null}
                                                                        {state.selectedQuery.display_output[qry.field].validation.max ?
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Max length:</span>
                                                                                {state.selectedQuery.display_output[qry.field].validation.max}
                                                                            </p>
                                                                        : null}
                                                                    </div>
                                                                    </UncontrolledTooltip>
                                                                    </React.Fragment>
                                                                : null}
                                                                </React.Fragment>
                                                            }
                                                            </React.Fragment>
                                                        : state.selectedQuery.display_output[qry.field].output_type === "list" ?
                                                            <React.Fragment>
                                                            {state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
                                                                <div className="mt-3" style={{minWidth: "150px"}}>
                                                                    <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, ["show_"+state.selectedQuery.display_output[qry.field].output_field]: !state["show_"+state.selectedQuery.display_output[qry.field].output_field] }))}>{state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field].length ? state[state.selectedQuery.display_output[qry.field].output_field].length+' Selected' : 'Select'}</p>
                                                                    {state["show_"+state.selectedQuery.display_output[qry.field].output_field] ?
                                                                        <div className='position-relative'>
                                                                            <MultiSelectSection 
                                                                                fields={["value", "value"]}
                                                                                // className={'position-unset'}
                                                                                removeTop={true}
                                                                                // borderClass={'boderDottedBottom'}
                                                                                options={state["options_"+state.selectedQuery.display_output[qry.field].output_field]}
                                                                                selectedValues={state[state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.display_output[qry.field].output_field] : []}
                                                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.display_output[qry.field].output_field]: value }))}
                                                                            />
                                                                        </div>
                                                                    : null}
                                                                </div>
                                                            :
                                                                <React.Fragment>
                                                                <input 
                                                                    type="text" 
                                                                    className={`inputField mt-3 w-30 ${state.hasError && (!state[state.selectedQuery.display_output[qry.field].output_field] || !state[state.selectedQuery.display_output[qry.field].output_field].length)  ? 'dottedDangerTextboxLine' : ''}`}
                                                                    placeholder={qry.field}
                                                                    value={state["listInput_"+state.selectedQuery.display_output[qry.field].output_field]}
                                                                    onKeyDown={e => onKeyDown(e, "listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.display_output[qry.field].output_field)}
                                                                    onBlur={e => onBlur(e, "listInput_"+state.selectedQuery.display_output[qry.field].output_field, state.selectedQuery.display_output[qry.field].output_field)}
                                                                    onChange={e => setState(prevState => ({ ...prevState,["listInput_"+state.selectedQuery.display_output[qry.field].output_field]: e.target.value }))}
                                                                />
                                                                {state.selectedQuery.display_output[qry.field].validation ?
                                                                    <React.Fragment>
                                                                    <i id={state.selectedQuery.display_output[qry.field].output_field} className="align-self-end fas fa-info-circle ml-2 mt-3 cursorPointer"></i>
                                                                    <UncontrolledTooltip target={state.selectedQuery.display_output[qry.field].output_field}>
                                                                    <div className="uncontrolledTooltipLeftAlign">
                                                                        {state.selectedQuery.display_output[qry.field].validation.data_type ?
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Data Type:</span>
                                                                                {state.selectedQuery.display_output[qry.field].validation.data_type}
                                                                            </p>
                                                                        : null}
                                                                        {state.selectedQuery.display_output[qry.field].validation.min ?
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Min length:</span>
                                                                                {state.selectedQuery.display_output[qry.field].validation.min}
                                                                            </p>
                                                                        : null}
                                                                        {state.selectedQuery.display_output[qry.field].validation.max ?
                                                                            <p className="mb-1 text-primary-color">
                                                                                <span className="text-white mr-1">Max length:</span>
                                                                                {state.selectedQuery.display_output[qry.field].validation.max}
                                                                            </p>
                                                                        : null}
                                                                    </div>
                                                                    </UncontrolledTooltip>
                                                                    </React.Fragment>
                                                                : null}
                                                                </React.Fragment>
                                                            }

                                                            {state.selectedQuery.display_output[qry.field].api_details && Object.entries(state.selectedQuery.display_output[qry.field].api_details).length ?
                                                                state[state.selectedQuery.display_output[qry.field].output_field] && (!state[state.selectedQuery.display_output[qry.field].output_field].includes("All")) ? 
                                                                    state[state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
                                                                        return(
                                                                            <span key={addIndex} className={`badge-square badge-secondary f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                                {state["options_"+state.selectedQuery.display_output[qry.field].output_field] && state["options_"+state.selectedQuery.display_output[qry.field].output_field].map(res => {
                                                                                    return(
                                                                                    res.value === tab ?
                                                                                        res.value +(res.key !== res.value ? " ("+res.key+")" : "")
                                                                                    : null
                                                                                    )
                                                                                })}
                                                                                <i onClick={ () => removeSelectedBadge(state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
                                                                            </span>
                                                                        )
                                                                    })
                                                                : state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field].includes("All") ?
                                                                    <span className={`badge-square badge-secondary f13 align-self-end p-1 ml-2 mr-1 mb-1`} > All </span>
                                                                : null
                                                            :
                                                                state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field].map((tab, addIndex) => {
                                                                    return(
                                                                        <span key={addIndex} className={`badge-square badge-secondary f13 align-self-end p-1 ${!addIndex ? "ml-2" : "ml-1"} mr-1 mb-1`} >
                                                                            {tab}
                                                                            <i onClick={ () => removeSelectedBadge(state.selectedQuery.display_output[qry.field].output_field, tab, addIndex) } className="ml-1 fal fa-times cursorPointer"></i>
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                            </React.Fragment>
                                                        : null
                                                    : state.selectedQuery.display_output && state.selectedQuery.display_output[qry.field] && state.selectedQuery.display_output[qry.field].type === "dropdown" ?
                                                        state.selectedQuery.display_output[qry.field].output_type === "list" ?
                                                            <div className="mt-3" style={{minWidth: "150px"}}>
                                                                <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field].length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}} onClick={() => setState(prevState => ({ ...prevState, ["show_"+state.selectedQuery.display_output[qry.field].output_field]: !state["show_second_"+state.selectedQuery.display_output[qry.field].output_field] }))}>{state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field].length ? state[state.selectedQuery.display_output[qry.field].output_field].length+' Selected' : 'Select'}</p>
                                                                {state["show_second_"+state.selectedQuery.display_output[qry.field].output_field] ?
                                                                    <div className='position-relative'>
                                                                        <MultiSelectSection 
                                                                            // fields={["account_id", "account_name"]}
                                                                            // className={'position-unset'}
                                                                            removeTop={true}
                                                                            // borderClass={'boderDottedBottom'}
                                                                            options={state["drop_options_"+state.selectedQuery.display_output[qry.field].output_field]}
                                                                            selectedValues={state[state.selectedQuery.display_output[qry.field].output_field] ? state[state.selectedQuery.display_output[qry.field].output_field] : []}
                                                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, [state.selectedQuery.display_output[qry.field].output_field]: value }))}
                                                                        />
                                                                    </div>
                                                                : null}
                                                            </div>
                                                        : state.selectedQuery.display_output[qry.field].output_type === "string" ? 
                                                            <Select
                                                                placeholder={'Select'}
                                                                isSearchable={false}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                className={`selectOption mr-2 mt-3 ${state.hasError && !state[state.selectedQuery.display_output[qry.field].output_field] ? 'dottedDangerSelectLine' : ''}`}
                                                                value={({
                                                                    value: state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field] !== "" ? state[state.selectedQuery.display_output[qry.field].output_field] : 'Select',
                                                                    label: state[state.selectedQuery.display_output[qry.field].output_field] && state[state.selectedQuery.display_output[qry.field].output_field] !== "" ? state[state.selectedQuery.display_output[qry.field].output_field] : <span className="placeholder">Select</span>
                                                                })}
                                                                options={state.selectedQuery.display_output[qry.field].values && state.selectedQuery.display_output[qry.field].values.map(item => ({
                                                                    value: item,
                                                                    label: item,	
                                                                }))}															
                                                                onChange={event => {
                                                                    setState(prevState => ({ 
                                                                        ...prevState,
                                                                        [state.selectedQuery.display_output[qry.field].output_field]: event.value
                                                                    }))
                                                                }}
                                                            />
                                                        : null
                                                    : null}
                                                </React.Fragment>
                                            }
                                            </React.Fragment>
                                        )
                                    })}

                                    {state.selectedQuery.required_fields ?
                                        <React.Fragment>
                                        {state.selectedQuery.required_fields.includes("account_id") ?
                                            <React.Fragment>
                                            <p className="mb-0 mx-2 align-self-end text-info">for Account</p>
                                            <div className="mt-3" style={{minWidth: "150px"}} ref={accountRef}>
                                                <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state.selectedQueryAccount && state.selectedQueryAccount.length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state.selectedQueryAccount && state.selectedQueryAccount.length ? state.selectedQueryAccount.length+' Selected' : 'Select'}</p>
                                                {state.isAccountOpen ?
                                                    <div className='position-relative'>
                                                        <MultiSelectSection 
                                                            fields={["account_id", "account_name"]}
                                                            // className={'position-unset'}
                                                            removeTop={true}
                                                            // borderClass={'boderDottedBottom'}
                                                            options={state.accounts}
                                                            selectedValues={state.selectedQueryAccount ? state.selectedQueryAccount : []}
                                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedQueryAccount: value }))}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                            </React.Fragment>
                                        : null}
                                        {state.selectedQuery.required_fields.includes("region") ?
                                            <React.Fragment>
                                            <p className="mb-0 mx-2 align-self-end text-info">Region</p>
                                            <div className="mt-3" style={{minWidth: "150px"}} ref={regionRef}>
                                                <p className={`d-flex boderDottedBottom rounded-5 mb-0 align-self-center pl-3 pt-2 ${state.selectedQueryRegion && state.selectedQueryRegion.length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>{state.selectedQueryRegion && state.selectedQueryRegion.length ? state.selectedQueryRegion.length+' Selected' : 'Select'}</p>
                                                {state.isRegionOpen ?
                                                    <div className='position-relative'>
                                                        <MultiSelectSection 
                                                            fields={["region", "name"]}
                                                            // className={'position-unset'}
                                                            removeTop={true}
                                                            // borderClass={'boderDottedBottom'}
                                                            options={state.regions}
                                                            selectedValues={state.selectedQueryRegion ? state.selectedQueryRegion : []}
                                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedQueryRegion: value }))}
                                                        />
                                                    </div>
                                                : null}
                                            </div>
                                            {/* <div className={`multiSelectOption align-self-end mt-3`}>
                                                <ReactMultiSelectCheckboxes
                                                    placeholderButtonLabel="Select"														
                                                    getDropdownButtonLabel={() => getMultiSelectedCount('selectedQueryRegion', state.selectedQueryRegion ? state.selectedQueryRegion : [])}
                                                    isSearchable={true}
                                                    options={state.regions && state.regions.map(reg => ({
                                                        value: reg.region,
                                                        label: reg.name,
                                                    }))}
                                                    onChange={arr => { handleMultiSelectChange('selectedQueryRegion', arr ? arr : []) }}
                                                    value={state.selectedQueryRegion && state.selectedQueryRegion.map(item => ({
                                                        value: item
                                                    }))}
                                                />
                                            </div> */}
                                            </React.Fragment>
                                        : null}
                                        {state.selectedQuery.required_fields.includes("duration") ?
                                            <React.Fragment>
                                            <p className="mb-0 mx-2 align-self-end text-info">Duration</p>
                                            <div className="position-relative d-inline-block w-20">
                                                <Select
                                                    placeholder={'Select'}
                                                    isSearchable={false}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    className={`selectOption mr-2 mt-3 ${state.hasError && !state.selectedQueryDuration ? 'dottedDangerSelectLine' : ''}`}
                                                    value={({
                                                        value: state.selectedQueryDuration ? state.selectedQueryDuration : 'Select',
                                                        label: state.selectedQueryDuration ? durationOptions.filter(e => e.value === state.selectedQueryDuration)[0].option : <span className="placeholder">Select</span>
                                                    })}															
                                                    options={durationOptions && durationOptions.map(item => ({
                                                        value: item.value,
                                                        label: item.option,	
                                                    }))}															
                                                    onChange={event => {
                                                        if(event.value !== 'custom') {
                                                            setState(prevState => ({ 
                                                                ...prevState,
                                                                selectedQueryDuration: event.value,
                                                                showQueryDateTimePicker: false,
                                                                queryDurationFunction: true
                                                            }))
                                                        } else {
                                                            setState(prevState => ({ ...prevState, showQueryDateTimePicker: true, selectedQueryDuration: event.value }))
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {state.showQueryDateTimePicker ?
                                                <div className="d-flex mt-2">
                                                    <p className="mb-0 mx-2 align-self-end text-info mt-3">start&nbsp;time</p>
                                                    <Datetime 
                                                        value={momentDateGivenFormat(state.queryStartTime, 'DD MMM YYYY hh:mm A')}
                                                        onChange={handleStartTimeChange}
                                                        dateFormat={'DD MMM YYYY'}
                                                        timeFormat={'hh:mm A'}
                                                        inputProps={{ readOnly: true, class: "inputField" }}
                                                        className="w-100 mt-4"
                                                        isValidDate={queryDateTime}
                                                    />
                                                    <p className="mb-0 mx-2 align-self-end text-info mt-3">end&nbsp;time</p>
                                                    <Datetime
                                                        value={momentDateGivenFormat(state.queryEndTime, 'DD MMM YYYY hh:mm A')}
                                                        onChange={handleEndTimeChange}
                                                        dateFormat={'DD MMM YYYY'}
                                                        timeFormat={'hh:mm A'}
                                                        inputProps={{ readOnly: true, class: "inputField" }}
                                                        className="w-100 mt-4"
                                                        isValidDate={queryDateTime}
                                                    />
                                                </div>
                                            : 
                                                // <div className="d-flex mt-4">
                                                // 	<p className="mb-0 mx-2 align-self-end text-info mt-3">start&nbsp;time</p>
                                                // 	<span className="mt-3 text-white">{momentDateGivenFormat(state.queryStartTime, "DD MMM YYYY hh:mm A")}</span>
                                                // 	<p className="mb-0 mx-2 align-self-end text-info mt-3">end&nbsp;time</p>
                                                // 	<span className="mt-3 text-white">{momentDateGivenFormat(state.queryEndTime, "DD MMM YYYY hh:mm A")}</span>
                                                // </div>
                                                null
                                            }
                                            
                                            </React.Fragment>
                                        : null}
                                        </React.Fragment>
                                    : null}
                                    <button className={`btn btn-primary f12 align-self-end ml-2`} onClick={() => setState(prevState => ({ ...prevState, queryErrorMessage: "", executeQuery: true }))}>
                                        {/* {state.queryLoading ?
                                            <Spinner className='text-center mr-2' color='light' size='sm' />
                                        : null} */}
                                        Run Query
                                    </button>
                                    {/* <span className={`f12 align-self-center ml-2 text-info ${state.queryLoading ? "disabled" : ""}`} onClick={() => this.setState({ queryErrorMessage: "" }, () => this.executeQuery())}>
                                        {state.queryLoading ?
                                            <Spinner className='text-center' color='light' size='sm' />
                                        : null}
                                        Run Query
                                    </span> */}
                                </React.Fragment>
                            : null}
                        </div>
                    </div>	
                    {/* <div className="d-flex justify-content-end w-99 mt-2">
                        <button className={`btn btn-primary f12 align-self-center mr-2`}onClick={() => this.setState({ queryErrorMessage: "" }, () => this.executeQuery())}>Run Query</button>
                        <button className={`btn btn-light f12 align-self-center`}onClick={() => this.setState({ ['expand_'+i]: false, selectedQuery: "" })}>Close</button>
                    </div> */}
                    </React.Fragment>
                : 
                    <p className="text-white displayNone">Query: {state.selectedQuery && state.selectedQuery.display_query ? state.selectedQuery.display_query : ""}</p>
                }

                {state.selectedQuery && state.selectedQuery.description ?
                    <div>
                        <span className="">Description</span>
                        {state.selectedQuery.description.split("\n").map(newLine => {
                            return(
                                <span className="d-flex">
                                {newLine.split("\t").map((tab, i) => {
                                    return(
                                        <span className={`${!i ? "" :""}`}>{tab}</span>
                                    )
                                })}
                                </span>
                            )
                        })}
                    </div>
                : null}
                {state.selectedQuery && state.selectedQuery.controls && Object.entries(state.selectedQuery.controls).length ?
                    <div className="d-flex mt-2 p-3 rounded bg-black">
                        {state.selectedQuery.controls.hasOwnProperty("Tactic") ?
                            <div className={`mr-2 border-right-black5
                                ${(!state.selectedQuery.controls.hasOwnProperty("Action/Impact") && !state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-100" 
                                : (!state.selectedQuery.controls.hasOwnProperty("Action/Impact") || !state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-50" : "w-20"}
                            `}>
                                <label className="text-white mb-2">Tactic</label>
                                {state.selectedQuery.controls.Tactic.map(ctrls => {
                                    return(
                                        <p className="mb-0 d-flex flex-wrap">{ctrls}</p>
                                    )
                                })}
                            </div>
                        : null}
                        {state.selectedQuery.controls.hasOwnProperty("Technique") ?
                            <div className={`mr-2 border-right-black5
                                ${(!state.selectedQuery.controls.hasOwnProperty("Action/Impact") && !state.selectedQuery.controls.hasOwnProperty("Tactic")) ? "w-100" 
                                : (!state.selectedQuery.controls.hasOwnProperty("Action/Impact") || !state.selectedQuery.controls.hasOwnProperty("Tactic")) ? "w-50" : "w-20"}
                            `}>
                                <label className="text-white mb-2">Technique</label>
                                {state.selectedQuery.controls.Technique.map(ctrls => {
                                    return(
                                        <p className="mb-0 d-flex flex-wrap">{ctrls}</p>
                                    )
                                })}
                            </div>
                        : null}
                        {state.selectedQuery.controls.hasOwnProperty("Action/Impact") ?
                            <div className={`
                                ${(!state.selectedQuery.controls.hasOwnProperty("Tactic") && !state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-100" : (!state.selectedQuery.controls.hasOwnProperty("Tactic") || !state.selectedQuery.controls.hasOwnProperty("Technique")) ? "w-45" : "w-45"}
                            `}>
                                <label className="text-white mb-2">Action/Impact</label>
                                {state.selectedQuery.controls["Action/Impact"].map(ctrls => {
                                    return(
                                        <p className="mb-0 d-flex flex-wrap">{ctrls}</p>
                                    )
                                })}
                            </div>
                        : null}
                    </div>
                : null}
            </div>
            {state.queryLoading ?
                <div className='d-flex justify-content-center m-4'> 
                    <Spinner className='text-center' color='light'   />
                </div>
            :
                <div className="rounded bg-black p-3 w-100 mt-2">
                    <div className="d-flex my-2 justify-content-between">
                        <div className="d-flex mr-2 w-40">
                            <p className="f12 m-0 align-self-center pt-1">Showing {state.filteredArray && state.filteredArray.length} of total {state.selectedPeriodData && state.selectedPeriodData.length}{state.selectedPeriodData && state.selectedPeriodData.length > 1 ? ' events' : ' event'}</p>
                            <p className="f12 m-0 align-self-center pt-1 text-info ml-1">
                                {state.queryStartTime ? 
                                    momentDateGivenFormat(state.queryStartTime, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.queryEndTime, 'DD MMM YYYY HH:mm') 
                                : null}
                            </p>
                        </div>
                        <div className="d-flex justify-content-end w-60 mb-n2">
                            {state.filteredArray && state.filteredArray.length ?
                                <CSVLink 
                                    data={state.filteredArray} 
                                    headers={state.headers ? state.headers : []}
                                    filename={"cost-"+state.selectedQuery.query_id+"-query-results.csv"}
                                    className={"align-self-center mr-2"}
                                    target="_blank"
                                >
                                    <i className="fas fa-download text-white"></i>
                                    {/* <span className="text-primary-color ml-2">csv</span> */}
                                </CSVLink>
                            : null}
                            {state.selectedPeriodData && state.selectedPeriodData.length ?
                                <Search
                                    data={state.selectedPeriodData ? state.selectedPeriodData : []}
                                    applyTags={false}
                                    applyLiteDarkTags={true}
                                    topClassName={'bg-black5 align-self-center w-50 mr-1 border-gray5 rounded-5 f12'}
                                    searchClassName={'px-2 f12'}
                                    searchIconColor={'text-gray5 f12'}
                                    searchPlaceHolder={'Search results....'}
                                    className={"bg-transparent text-white pl-0 form-control-sm f12"}
                                    filteredData={(filteredArray) => {
                                        setState(prevState => ({ ...prevState, filteredArray, formDownloadData: filteredArray && filteredArray.length ? true : false }))
                                    }}
                                />
                            : null}
                            {state.filteredArray && state.filteredArray.length > state.perPage ?
                                <div className="pagination errorPagePagination f12">
                                    <span className="mx-3">Page <strong>{state.currentPage} of {state.totalPages}</strong> </span>
                                    <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                    <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                    {/* <span>
                                        Go to
                                        <input
                                            type='number'
                                            value={state.pageNumber || state.pageNumber === "" ? state.pageNumber : state.currentPage}
                                            onChange={e => {
                                                const page = e.target.value ? Number(e.target.value) : ""
                                                gotoPage(page)
                                            }}
                                            className="ml-1 inputClass"
                                        />
                                    </span> */}
                                    <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                    <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                </div>
                            : null}
                        </div>
                    </div>
                    
                    <div className="d-flex errorSectionScroll">
                        <div className={`bg-black py-3 pl-2 w-100`}>
                            {state.alertListLoader ? 
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='light'   />
                                </div>
                            : state.filteredArray && state.filteredArray.length ?
                                state.filteredArray.slice(state.startRecord, state.startRecord + state.perPage).map((item, i) => {
                                    return(
                                        <React.Fragment>
                                        <div className={`d-flex mr-2 px-2 mb-2 pb-1 ${state.startRecord + state.perPage !== state.startRecord + i ? 'border-bottom-dark' : ''} cursorPointer`}>  
                                            <div className="d-flex">
                                                {item.severity === "severity" ?
                                                    <span className={`f10 mr-2 align-self-start mt-1 badge-sm risk-badge-${item.severity ? item.severity.toLowerCase() : 'critical'}`}>
                                                        {item.severity ? 
                                                            (item.severity.toLowerCase() === 'critical' ? 'C' : item.severity.toLowerCase() === 'high' ? 'H' : item.severity.toLowerCase() === 'medium' ? 'M' : 'L') 
                                                        : 'U'}
                                                    </span>
                                                : null}

                                                <p className="mb-0">
                                                    {item.event_time ?
                                                        <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">{item.event_time ? momentConvertionUtcToLocalTime(item.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                    : null}
                                                    <span className={`mb-0 mr-2 f12 text-purple-2`}>
                                                        {item.event_source ? (Array.isArray(item.event_source) ? item.event_source.join(", ") : item.event_source) : ""}
                                                        {item.event_name ? " "+item.event_name : ''}
                                                    </span>
                                                    {item.importance ?
                                                        <span className={`mb-0 f12 font-weight-bold mr-2`}>Importance <span className={`ml-2 risk-${item.importance ? item.importance.toLowerCase() : 'critical'}`}>
                                                                {item.importance}
                                                            </span>
                                                        </span>
                                                    : null}
                                                    {item.description ?
                                                        <span className={`mb-0 f12 font-weight-bold mr-2 text-white`}>Description: <span className={`text-white `}>{item.description}</span></span>
                                                    : null}
                                                    {item.event_id ?
                                                        <span className="mb-0 mr-2 text-white f12 font-weight-bold text-primary-color">Event Id: {item.event_id} </span>
                                                    : null}
                                                    {item.message ?
                                                        <span className={`mb-0 f12 font-weight-bold mr-2 text-white`}>Message: <span className={`ml-2 text-white `}>{item.message}</span></span>
                                                    : null}
                                                    {Object.entries(item).map(([key, value]) => {
                                                        return(
                                                            !commonFields.includes(key) ?
                                                                typeof value === 'object' ?
                                                                    <React.Fragment>
                                                                    <span className="mb-0 small mx-1">{key}</span>
                                                                    {Object.entries(value).map(([objKey, objValue]) => {
                                                                        return(
                                                                            <span className={`mb-0 f12 font-weight-bold mr-2`}><span className="">{objKey}</span>{' : '+objValue}</span>
                                                                        )
                                                                    })}
                                                                    </React.Fragment>
                                                                :
                                                                    <span className={`mb-0 f12 font-weight-bold mr-2`}><span className="">{(state.listQueryLabel[key] ? state.listQueryLabel[key] : key)}</span>{' : '+value}</span>
                                                            : null
                                                            
                                                        )
                                                    })}
                                                    {item.additional_details && Object.entries(item.additional_details).length ?
                                                        <React.Fragment>
                                                        <span className="mb-0 small mx-1">Additional Details</span>
                                                        {Object.entries(item.additional_details).map(([key, value]) => {
                                                            return(
                                                                <span className={`mb-0 f12 font-weight-bold mr-2`}><span className="">{key}</span>{' : '+value}</span>
                                                            )
                                                        })}
                                                        </React.Fragment>
                                                    : null}
                                                    {item.assets && Object.entries(item.assets).length ?
                                                        <React.Fragment>
                                                        <span className="mb-0 small mr-2">Resources: </span>
                                                        {item.assets.map((asst, i) => {
                                                            return(
                                                                <span className={`${state.showAssets ? "" : (!i ? "" : "displayNone")}`}>
                                                                    <span className={`mb-0 f12 font-weight-bold mr-2`}>{asst.asset_name}</span>                                                         
                                                                    {item.provider ?
                                                                        <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' '+item.provider}</span>
                                                                    : null}
                                                                    {asst.account_id ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+asst.account_id}</span>
                                                                    : null}
                                                                    {asst.region ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+asst.region}</span>
                                                                    : null}
                                                                    {asst.resource_type ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(asst.resource_type) ? asst.resource_type.join(', ') : asst.resource_type)}</span>
                                                                    : null}
                                                                    {asst.service_name ?
                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+asst.service_name}</span>
                                                                    : null}
                                                                </span>
                                                            )
                                                        })}
                                                        {item.assets.length > 1 ? 
                                                            <i className={`ml-2 text-info fas ${state.showAssets ? 'fa-minus-circle' : 'fa-plus-circle'}`} onClick={() => setState(prevState => ({ ...prevState,showAssets: !state.showAssets }))}></i>
                                                        : null}
                                                        </React.Fragment>
                                                    : 
                                                        <React.Fragment>
                                                            {item.asset_name ?
                                                                <span className="mb-0 mr-2 f12 text-white font-weight-bold">{item.asset_name}</span>
                                                            : null}
                                                            {item.account_id || item.region || item.resource_type || item.service_name ?
                                                                <span className="mb-0 small mx-2 text-primary-color">Resources: </span>
                                                            : null}                                                                
                                                            {item.provider ?
                                                                <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' '+item.provider}</span>
                                                            : null}
                                                            {item.account_id ?
                                                                <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' '+item.account_id}</span>
                                                            : null}
                                                            {item.region ?
                                                                <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' : '+item.region}</span>
                                                            : null}
                                                            {item.resource_type ?
                                                                <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' : '+(Array.isArray(item.resource_type) ? item.resource_type.join(', ') : item.resource_type)}</span>
                                                            : null}
                                                            {item.service_name ?
                                                                <span className="mb-0 f12 text-primary-color mr-1 font-weight-bold">{' : '+item.service_name}</span>
                                                            : null}
                                                        </React.Fragment>
                                                    }
                                                    {/* {item.event_analysis && Object.entries(item.event_analysis).length ?
                                                        <React.Fragment>
                                                        <span className="mb-0 small mx-1 text-white">Event Analysis</span>
                                                        {Object.entries(item.event_analysis).map(([key, value]) => {
                                                            return(
                                                                typeof value === 'string' ?
                                                                    <span className={`mb-0 f12 font-weight-bold ml-2`}><span className="text-white">{key}</span>{' : '+value}</span>
                                                                : null
                                                            )
                                                        })}
                                                        </React.Fragment>
                                                    : null} */}
                                                </p>
                                            </div>
                                        </div>
                                        </React.Fragment>
                                    )
                                })
                            :
                                <div className='d-flex justify-content-center m-4'>
                                    <p>There are no data on this criteria. Please try adjusting your query.</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default QueryResults