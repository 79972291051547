/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file Dashboard.js
 * @author Rajasekar // on 07/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner} from 'reactstrap'
import moment from 'moment'

import { CSVLink } from "react-csv";
import { listAssets, listClusterAssets, listAdvancedSearchAssets, getAdvancedSearchAssetsCount, getTotalAssetCount, listClusterNames } from '../../../actions/assets/assetsActionNew'
import { CUSTOM_CURRENT_DURATION_FILTER_OPTIONS } from '../../../utils/constants'
import { listAllProviders, listAllAccounts, listAllRegions, listAllServices, getAllTagsKeys, getAllTagsValues, getSearchMetaResults } from '../../../actions/commonActionNew'
import {
	getAssetDetails,
	getTopEvents,
	getRecentEvents,
	getEventGraphCounts,
} from '../../../actions/detectors/detectorsAction'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import Search from '../../common/SearchComponent'
// import AdvancedSearch from '../../common/AdvancedSearch'
import DetailsSection from './DetailsSection'
import { getAccountNameFromId, momentDateTime, datestartEndTime, capitalizeTheFirstLetterOfEachWord, getRegionName } from '../../../utils/utility'
import _ from 'lodash'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import Select from 'react-select'
import MultiSelectSection from '../../common/MultiSelectSection';

import DropdownTreeSelect from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'
import ResourcesAdvancedSearch from './AdvancedSearch/ResourcesAdvancedSearch';
// import AssetDetailsSidePanel from './AssetDetailsSidePanel'
let searchAssetInventory = null
const SearchResources = () => {
	const tabRef = useRef()
	const dayPickerRef = useRef()
	const durationRef = useRef()
	const accountRef = useRef()
	const regionRef = useRef()
	const serviceRef = useRef()
	const searchRef = useRef()
	const tagRef = useRef()	
	const tagValueRef = useRef()
	const clusterRef = useRef()
	const clusterValueRef = useRef()

	const [state, setState] = useState({
		selectedTab: 'asset_details',
		showLoading: true,
		showTabListDropdown: false,
		isActionDropdownOpen: false,
		showSupressInDetailsSection: false,
		showConfigureSection: false,

		showDetailsAssetSectionLoading: false,
		showDetailsMonitoringSectionLoading: false,
		showDetailsBillingSectionLoading: false,
		showDetailsEventsSectionLoading: false,
		showDetailsRelatedIssueLoading: false,
		showSupressSectionLoading: false,

		supressRules: [],
		showDetailsSection: false,
		showSupressForm: false,

		providers: [],
		providerIsOpen: false,
		selectedProvider: '',
		
		accounts: [],
		allAccounts: [],
		selectedAccount: [],
		isAccountOpen: false,
		
		selectedRegion: [],
		isRegionOpen: false,

		services: [],
		showDropdownTree: false,
		serviceResourceArray: [],
		filteredServiceResourceArray: [],
		selectedService: [],
		selectedServiceContent: 'All',
		isServiceOpen: false, 

		selectedCategory: '',
		selectedDuration: 'Current',
		relatedDurtaion: '',
		start_time : '',
		end_time: '',
		previousCustomDays: moment().subtract('180', 'days'),
		showDatepicker: false,
		durationType: '',
		filteredArray: [],
		totalAssetsList: [],
		dynamicHeaders: [],
		selectedTableRow: {},

		categoryCounts: [],

		assetActionSelectedTab: 0,

		topEventsData: {},
		recentEventsData: [],
		eventCountData: {},

		detailsTabNames: [
			{ name: 'Resource', value: 'asset_details' },
			{ name: 'Events', value: 'events' }
		],
		selectedTableTab: -1,
		selectedDetailsTab: 0,
		showDetailsTabListDropdown: false,
		onClickSupressCreateButton: false,
		selectedRowAssetDetails: [],
		selectedRowBillingDetails: [],
		selectedRowMonitoringSnapshot: {},

		dayWiseCountGraphData: {},
		relatedIssuesData: [],
		filteredRelatedIssuesData: [],
		assetConfigrationsList: [],
		summaryGraphData: {},

		tabItemsToShow: 0,

		detailsTabItemsToShow: 2,

		selectedMonitoringDuration: '2h',

		eventSeries: [],
		eventOptions: [],

		showAdvancedSearchModal: false,
		selectedAdvancedSearch: {},
		newSearchText: '',
		showSearchOptions: false,
		keyupTimer: undefined,
		showDropdown: false,
		
		modalHeader: 'Close Issue',
		modalClose: false,
		modalAlert: false,
		
		closeLoading: false,
		closeStatus: 'resolved',
		closeStatusType: '',
		closeAttachment: '',
		closeAttachmentKey: false,
		
		
		responseMessage: '',
		showMessage: false,
		hasErrorInRuleForm: false,

		tableClass: '',
		clusterList: [
			{label: "hpc-SLURM", value: "hpc-SLURM"},
			{label: "hpc-PBSPRO", value: "hpc-PBSPRO"},
			{label: "k8s-EKS", value: "k8s-EKS"},
			{label: "k8s-GKE", value: "k8s-GKE"}
		],
		emptyOptions: [],
	})
	

	const dispatch = useDispatch(); // Create a dispatch function
	const tagKeys = useSelector(state => state.filters.tagKeys);

	useEffect(() => {
		
		let assetTableColumns = []
		let dataRow = {}
		dataRow.Header = 'Date'
		dataRow.accessor = 'asset_time'
		dataRow.Cell = cellInfo => (
			<React.Fragment>
				<span>{momentDateTime(cellInfo.row.original.asset_time)}</span>
			</React.Fragment>
		)
		dataRow.width = 180
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Provider'
		dataRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
		dataRow.Cell = cellInfo => (
			<React.Fragment>
			<span className=''>
				{cellInfo.row.original.provider + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
			</span>
			</React.Fragment>
		)
		dataRow.width = 300
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Resource'
		dataRow.accessor = d => d.service_name + ' : ' + d.asset_name
		dataRow.Cell = cellInfo => (
			<span className=''>{cellInfo.row.original.service_name + ' : ' + cellInfo.row.original.asset_name}
			</span>
		)
		dataRow.width = 400
		assetTableColumns.push(dataRow)

		dataRow = {}
		dataRow.Header = 'Network'
		dataRow.accessor = 'vpc_id'		
		dataRow.width = 420			
		assetTableColumns.push(dataRow)

		setState(prevState => ({ ...prevState, assetTableColumns }))

		
		let cluserAssetTableColumns = []
		let clusterRow = {}
		clusterRow.Header = 'Date'
		clusterRow.accessor = 'asset_time'
		clusterRow.Cell = cellInfo => (
			<React.Fragment>
				<span>{momentDateTime(cellInfo.row.original.asset_time)}</span>
			</React.Fragment>
		)
		clusterRow.width = 180
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Provider'
		clusterRow.accessor = d => d.provider + ': ' + getAccountNameFromId(d.account_id, state.accounts) + ': ' + d.region === 'NA' ? 'Global' : d.region
		clusterRow.Cell = cellInfo => (
			<React.Fragment>
			<span className=''>
				{cellInfo.row.original.provider + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region === 'NA' ? 'Global' : cellInfo.row.original.region)}
			</span>
			</React.Fragment>
		)
		clusterRow.width = 200
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Resource'
		clusterRow.accessor = d => d.service_name + ' : ' + d.asset_name
		clusterRow.Cell = cellInfo => (
			<span className=''>{cellInfo.row.original.service_name + ' : ' + cellInfo.row.original.asset_name}
			</span>
		)
		clusterRow.width = 400
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Network'
		clusterRow.accessor = 'vpc_id'		
		clusterRow.width = 320			
		cluserAssetTableColumns.push(clusterRow)

		clusterRow = {}
		clusterRow.Header = 'Component'
		clusterRow.accessor = 'component'		
		clusterRow.width = 200			
		cluserAssetTableColumns.push(clusterRow)

		setState(prevState => ({ ...prevState, cluserAssetTableColumns }))
		let params = {}
		dispatch(listAllProviders(params))
			.then((response) => {
				if(response) {
					setState(prevState => ({
						...prevState,
						providers: response,
						selectedProvider: prevState.selectedProvider ? prevState.selectedProvider : (response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name) : ''),
						callFilterIdentifiers: true
					}));
				}
		})

		// const _this = this
		window.addEventListener('resize', () => {
			setState(prevState => ({ ...prevState, callSetTabs: true }))
		})		
	}, [dispatch, state.accounts])

    
    // Call provider based regions whenever the selectedProviders state is updated
    useEffect(() => {
        if (state.selectedProvider && state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();

			dispatch(listAllAccounts(params))
                .then((response) => {
					if(response) {
                        let filteredAccounts = response
                        // if (filteredAccounts.length) {
                        //     let accRow = { account_id: 'All', account_name: 'All', date: null, disabled: false, provider: 'All' };
                        //     filteredAccounts.unshift(accRow);
                        // }
                        setState(prevState => ({ ...prevState, accounts: filteredAccounts }));
                    }
            	})

			dispatch(listAllRegions(params))
				.then((response) => {
					if(response) {
						let providerRegions = response[0]?.provider_regions || [];
						// if (providerRegions.length) {
						//     let provider_regions_row = { availability_zones: [], city: 'All', country: 'All', geography: 'All', name: 'All', region: 'All', region_id: 'All', state: 'All' };
						//     providerRegions.unshift(provider_regions_row);
						// }
						
						setState(prevState => ({ ...prevState, regions: providerRegions  }))
					}
				});
			
			// get services
			dispatch(listAllServices(params))
				.then((response) => {
					let services = response
					let serviceResourceArray = []
					let serviceArray = []
					services && services.length &&
					_.chain(services)
					.groupBy('resource_type')
					.map((value, key) => ({ label: key, sub: value }))
					.value()
					.forEach((service, i) => {
						if(i === 0) {
							let dataRow = {}							
							dataRow.category = 'All'
							dataRow.default_region = 'All'
							dataRow.provider_name = 'All'
							dataRow.resource_type = 'All'
							dataRow.service_id = 'All'
							dataRow.service_name = 'All'
							serviceArray.push(dataRow)

							let serRow = {}
							serRow.label = 'All'
							serRow.value = 'All'
							serRow.expanded = true
							let serArray = []
							serRow.children = serArray
							serviceResourceArray.push(serRow)
						}
						
						let serRow = {}
						serRow.label = service.label
						serRow.value = service.label
						serRow.expanded = true
						let serArray = []
						//serviceResourceArray.push(service)
						service.sub.forEach((item, i) => {
							let serItem = {}
							serItem.label = item.service_name
							serItem.value = item.service_name
							serItem.expanded = true
							serArray.push(serItem)
							serviceArray.push(item)
						})
						serRow.children = serArray
						serviceResourceArray.push(serRow)
					})
					setState(prevState => ({ ...prevState, services, serviceArray, serviceResourceArray, filteredServiceResourceArray: serviceResourceArray }))
				})

			//get tag kyes
			dispatch(getAllTagsKeys(params))
        }
    }, [state.selectedProvider, dispatch])
	
	useEffect(() => {
		if(state.tag_key) {
			let params = {}
			params.tags = [{ "key": state.tag_key }]
			if(state.tag_key) {
				dispatch(getAllTagsValues(params))
					.then((response) => {
						setState(prevState => ({ ...prevState, tagValues: response }))
					})
			}
		}
	}, [state.tag_key, dispatch])

	useEffect(() => {
        if (state.callClusterNames) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
			params.cluster_type = state.clusterType
            
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
			if(state.selectedTags && state.selectedTags.length) {
				let tags = []
				let groupTags = _.groupBy(state.selectedTags, "key")
				
				Object.entries(groupTags).forEach(([key, value]) => {
					let dataRow = {}
					
					if(value.filter(e => e.value) && value.filter(e => e.value).length) {
						dataRow.key = key 
						dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
					} else {
						dataRow.key = key
					}
					tags.push(dataRow)
				})
				params.tags = tags
			}

            dispatch(listClusterNames(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
							clustersItems: response,
                            callClusterNames: false
                        }));
                    }
            })
        }
    }, [dispatch, state.callClusterNames, state.selectedProvider, state.selectedAccount, state.selectedRegion, state.selectedTags, state.clusterType])

	const addNewTag = () => {
		let tagsSelected = state.tagsSelected ? state.tagsSelected : []

		if(state.tag_key && state.tag_key !== "") {
			let filteredTags = tagsSelected.filter(e => e.key !== state.tag_key)
			if(state.tag_value && state.tag_value.length) {
				state.tag_value.forEach(row => {
					let dataRow = {
						key: state.tag_key,
						value: row,
					}
					filteredTags.push(dataRow)
				})
			} else {
				let dataRow = {
					key: state.tag_key
				}
				filteredTags.push(dataRow)
			}

			setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, tagsSelected: filteredTags, tag_key: "",  tag_value: [], tagValues: [], changesInFilter: true, callFilterIdentifiers: true }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.tagsSelected.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, tagsSelected: filteredResult, changesInFilter: true, callFilterIdentifiers: true }))
	}

	const addNewCluster = () => {
		let clusterSelected = state.clusterSelected ? state.clusterSelected : []

		if(state.clusterType && state.clusterType !== "") {
			let filteredCluster = clusterSelected.filter(e => e.key !== state.clusterType)
			if(state.clusterNames && state.clusterNames.length) {
				// state.clusterNames.forEach(row => {
				// })
				let dataRow = {}
				dataRow.key = state.clusterType
				dataRow.value = state.clusterNames
				filteredCluster.push(dataRow)
				// dataRow.value = state.clusterNames
			} else {
				let dataRow = {
					key: state.clusterType
				}
				filteredCluster.push(dataRow)
			}

			setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, clusterSelected: filteredCluster, clusterType: "",  clusterNames: [], changesInFilter: true }))
		}
	}

	const removeClusterSection = (item) => {
		let filteredResult = state.clusterSelected.filter(e => e !== item)
		setState(prevState => ({ ...prevState, clusterSelected: filteredResult }))
	}

	const removeClusterValue = (name) => {
		let value = state.clusterSelected[0].value
		let filteredResult = value.filter(e => e !== name)

		let clusterSelected = state.clusterSelected
		clusterSelected[0].value = filteredResult
		setState(prevState => ({ ...prevState, clusterSelected }))
	}
	/**
	 * Method to get all status, risk and category counts
	 */
	useEffect(() => {
		if(state.callToGetCounts) {
			let params = {}
			if (state.selectedProvider !== '' && state.selectedProvider !== 'All') {
				params.provider = state.selectedProvider.toLowerCase()
			}
			
			if (state.selectedAccount.length && !state.selectedAccount.includes('All')) {
				params.account_id = state.selectedAccount
			}

			if (state.selectedRegion.length && !state.selectedRegion.includes('All')) {
				params.region = state.selectedRegion
			}

			if (state.selectedService && state.selectedService.length && !state.selectedService.includes('All')) {
				params.service_name = state.selectedService
			}
			
			if (state.selectedCategory !== '' && state.selectedCategory !== 'all' && state.selectedCategory.length) {
				params.category = state.selectedCategory
			}
			
			if (state.selectedDuration !== 'Current') {
				if(state.selectedDuration === 'custom')  {
					params.start_time = state.start_time
					params.end_time = state.end_time
				} else {
					params.duration = state.selectedDuration
				}
			}
			
			if(state.selectedAdvancedSearch && Object.entries(state.selectedAdvancedSearch).length) {
				let advancedParams = {}
				advancedParams.aggregate_by = ["category"]
				if(state.selectedAdvancedSearch) {
					if(state.selectedAdvancedSearch.hasOwnProperty("event_name")) {
						advancedParams.event_name = state.selectedAdvancedSearch.event_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("users")) {
						advancedParams.users = state.selectedAdvancedSearch.users
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("ip")) {
						advancedParams.ip = state.selectedAdvancedSearch.ip
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("instance_type")) {
						advancedParams.instance_type = state.selectedAdvancedSearch.instance_type
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("service_name")) {
						advancedParams.service_name = state.selectedAdvancedSearch.service_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("vpc_id")) {
						advancedParams.vpc_id = state.selectedAdvancedSearch.vpc_id
					}							
				}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					advancedParams.tags = tags
				}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					advancedParams.tags = tags
				}
				advancedParams = {...params, ...advancedParams}

				dispatch(getAdvancedSearchAssetsCount(advancedParams))
					.then((response) => {
						setState(prevState => ({ ...prevState, categoryCounts: response, tabItemsToShow: response.length, callSetTabs: true }))
					})

				let assetCountParams = {}
				assetCountParams = {...params, ...assetCountParams}
				assetCountParams.aggregate_by = ["asset_id"]
				dispatch(getAdvancedSearchAssetsCount(assetCountParams))
					.then((response) => {
						setState(prevState => ({ ...prevState, totalAssetsCount: response && response.length, callToGetCounts: false }))					
					})

			} else {
				params.aggregate_by = ['category']
				dispatch(getTotalAssetCount(params))
					.then((response) => {
						setState(prevState => ({ ...prevState, categoryCounts: response, tabItemsToShow: response.length, callSetTabs: true }))
					})

				let assetCountParam = {}
				assetCountParam = {...params, ...assetCountParam}
				assetCountParam.aggregate_by = ['asset_id']
				
				dispatch(getTotalAssetCount(assetCountParam))
					.then((response) => {
						setState(prevState => ({ ...prevState, totalAssetsCount: response.length, callToGetCounts: false }))
					})
			}
		}
	}, [dispatch, state.callToGetCounts, state.end_time, state.selectedAccount, state.selectedAdvancedSearch, state.selectedCategory, state.selectedDuration, state.selectedProvider, state.selectedRegion, state.selectedService, state.selectedTags, state.start_time])
		
	useEffect(() => {
		if (state.callSetTabs && tabRef.current) {
			const maxTabWidth = tabRef.current.clientWidth - 130
			let reqTabWidth = 0
			let allowedTabLength = -1
			for (let i = 0; i < tabRef.current.childNodes.length; i++) {
				reqTabWidth += tabRef.current.childNodes[i].clientWidth
				if (reqTabWidth < maxTabWidth) {
					allowedTabLength++
				}
			}
			setState(prevState => ({ ...prevState, tabItemsToShow: allowedTabLength, callSetTabs: false }))
		}
	}, [state.callSetTabs])

	const getAssetDetailsFunction = useCallback((asset) => {
		setState(prevState => ({ ...prevState, showDetailsAssetSectionLoading: true, showSupressSectionLoading: true }))
		let params = {
			service_name: asset.service_name,
			asset_id: asset.asset_id,
		}
		dispatch(getAssetDetails(params))
			.then((response) => {
				setState(prevState => ({ ...prevState, 
					selectedRowAssetDetails: response,
					showDetailsAssetSectionLoading: false,
					showSupressSectionLoading: false,
				}))
			})
	}, [dispatch])

	useEffect(() => {
		if(state.callFilterIdentifiers) {
			setState(prevState => ({ 
				...prevState,
				showLoading: true, 
				filteredArray: [], 
				// totalAssetsList: [],
				dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
				selectedTab: 'asset_details', 
				relatedDurtaion: state.selectedDuration,
				categoryCounts: [],
				showDetailsSection: false,
				callFilterIdentifiers: false
			}))
			
			let params = {}
			if (state.selectedProvider !== '' && state.selectedProvider !== 'All') {
				params.provider = state.selectedProvider.toLowerCase()
			}

			if (state.selectedAccount.length && !state.selectedAccount.includes('All')) {
				params.account_id = state.selectedAccount
			}
			
			if (state.selectedRegion.length && !state.selectedRegion.includes('All')) {
				params.region = state.selectedRegion
			}

			if (state.selectedService && state.selectedService.length) {
				params.service_name = state.selectedService
			}

			if (state.selectedCategory && state.selectedCategory !== 'all' && state.selectedCategory.length) {
				params.category = state.selectedCategory	
			}
			
			if (state.selectedDuration !== 'Current') {
				if(state.selectedDuration === 'custom')  {
					params.start_time = state.start_time
					params.end_time = state.end_time
				} else {
					params.duration = state.selectedDuration
				}
			}

			let statusCountParams = {}
			statusCountParams = {...params, ...statusCountParams}

			//header satus pies chart		
			statusCountParams.aggregate_by = ['status']
			dispatch(getTotalAssetCount(statusCountParams))


			if(state.selectedAdvancedSearch && Object.entries(state.selectedAdvancedSearch).length && !state.searchText) {
				if(state.selectedService !== '') {
					let filteredServiceResourceArray = []
					state.serviceResourceArray.forEach(item => {
						let dataRow = {}
						dataRow.label = item.label
						dataRow.expanded = true
						dataRow.checked = false
						let childArray = []
						item.children.forEach(child => {
							let childRow = {}
							childRow.label = child.label
							childRow.value = child.label
							childRow.expanded = true
							childRow.checked = false
							if(state.selectedService.includes(child.label)) {
								childRow.checked = true
							}
							childArray.push(childRow)
							dataRow.children = childArray
						})
			
						filteredServiceResourceArray.push(dataRow)
					})
					
					let selectedServiceContent = state.selectedService && state.selectedService.length === 1 ? state.selectedService[0] : state.selectedService && state.selectedService.length > 1 ? state.selectedService.length +' Selected' : 'All'

					setState(prevState => ({ ...prevState, filteredServiceResourceArray, selectedServiceContent }))
				}

				let advancedParams = {}
				if(state.selectedAdvancedSearch) {
					if(state.selectedAdvancedSearch.hasOwnProperty("event_name")) {
						advancedParams.event_name = state.selectedAdvancedSearch.event_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("users")) {
						advancedParams.users = state.selectedAdvancedSearch.users
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("ip")) {
						advancedParams.ip = state.selectedAdvancedSearch.ip
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("instance_type")) {
						advancedParams.instance_type = state.selectedAdvancedSearch.instance_type
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("service_name")) {
						advancedParams.service_name = state.selectedAdvancedSearch.service_name
					}
					if(state.selectedAdvancedSearch.hasOwnProperty("vpc_id")) {
						advancedParams.vpc_id = state.selectedAdvancedSearch.vpc_id
					}
				}
				advancedParams = {...params, ...advancedParams}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					advancedParams.tags = tags
				}
					
				if(state.nextToken) {
					advancedParams.next_token = state.nextToken
				}

				dispatch(listAdvancedSearchAssets(advancedParams))
					.then((response) => {
						let nextToken = ""
						if(response.next_token) {
							nextToken = response.next_token	
						}
							
						let results = [] 
						if(state.nextToken && state.nextToken.length) {
							results = state.totalAssetsList && state.totalAssetsList.length ? state.totalAssetsList : []
						}
						
						if(response.results && response.results.length) {
							results = results.concat(response.results)
						}

						setState(prevState => ({
							...prevState,
							showLoading: false,
							nextToken,
							filteredArray: results,
							totalAssetsList: results,
							dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
							selectedTableRow: results && results.length ? results[0] : {},
							showDetailsSection: true,
							selectedDetailsTab: 0,
							callToGetCounts: true,
							callFormHeader: true
						}))
							// this.getAllCounts("advancedSearch")  need to work
						if(results && results.length) {
							getAssetDetailsFunction(results[0])
						}
					})
			} else {
				let asstParams = {}
				asstParams = {...params, ...asstParams}
				if (state.selectedDuration !== 'Current') {
					if(state.selectedDuration === 'custom')  {
						asstParams.start_time = state.start_time
						asstParams.end_time = state.end_time
					} else {
						asstParams.duration = state.selectedDuration
					}
				}
				if(state.selectedTags && state.selectedTags.length) {
					let tags = []
					let groupTags = _.groupBy(state.selectedTags, "key")
					
					Object.entries(groupTags).forEach(([key, value]) => {
						// if(value.fitler(e => e.value))
						let dataRow = {}
						
						if(value.filter(e => e.value) && value.filter(e => e.value).length) {
							dataRow.key = key 
							dataRow.value = value.filter(e => e.value) && value.filter(e => e.value).length ? value.map(e => e.value) : []
						} else {
							dataRow.key = key
						}
						tags.push(dataRow)
					})
					asstParams.tags = tags
				}
				
				if(state.selectedCluster && state.selectedCluster.length) {
					asstParams.cluster_type = state.selectedCluster[0].key
					if(state.selectedCluster[0].value && state.selectedCluster[0].value.length) {
						asstParams.cluster_name = state.selectedCluster[0].value
					}
					
					if(state.nextToken) {
						asstParams.next_token = state.nextToken
					}

					dispatch(listClusterAssets(asstParams))
						.then((response) => {

							setState(prevState => ({ ...prevState, showLoading: false }))
							let nextToken = ""
							if(response.next_token) {
								nextToken = response.next_token	
							}
								
							let results = [] 
							if(state.nextToken && state.nextToken.length) {
								results = state.totalAssetsList && state.totalAssetsList.length ? state.totalAssetsList : []
							}
							
							if(response.results && response.results.length) {
								results = results.concat(response.results)
							}
	
							if (results && results.length) {
								let categoryCounts = response.category_agg ? response.category_agg : []
	
								setState(prevState => ({ 
									...prevState, 
									showLoading: false,
									nextToken,
									filteredArray: results,
									totalAssetsList: results,
									categoryCounts,
									dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
									selectedTableRow: results && results.length ? results[0] : {},
									showDetailsSection: true,
									selectedDetailsTab: 0,
									totalAssetsCount: response.total ? response.total : 0,
									callFormHeader: true
								}))
							} else {
								setState(prevState => ({ ...prevState, showDropdownTree: false, selectedTableRow: {} }))
							}

							if(results && results.length) {
								getAssetDetailsFunction(results[0])
							}
						})
				} else {
					if(state.nextToken) {
						asstParams.next_token = state.nextToken
					}
					if(state.searchText) {
						asstParams.asset_search = state.searchText
					}
					dispatch(listAssets(asstParams))
						.then((response) => {
							let nextToken = ""
							if(response.next_token) {
								nextToken = response.next_token	
							}
								
							let results = [] 
							if(state.nextToken && state.nextToken.length) {
								results = state.totalAssetsList && state.totalAssetsList.length ? state.totalAssetsList : []
							}
							
							if(response.results && response.results.length) {
								results = results.concat(response.results)
							}
							
							if (results && results.length) {
								setState(prevState => ({ 
									...prevState,
									showLoading: false,
									nextToken,
									filteredArray: results,
									totalAssetsList: results,
									dynamicHeaders: state.selectedCluster && state.selectedCluster.length ? state.cluserAssetTableColumns : state.assetTableColumns,
									selectedTableRow: results && results.length ? results[0] : {},
									showDetailsSection: true,
									selectedDetailsTab: 0,
									callToGetCounts: true,
									callFormHeader: true,
								}))
										// getAllCounts("common") need to work
								if(results && results.length) {
									getAssetDetailsFunction(results[0])
								}									
							} else {
								setState(prevState => ({ ...prevState, showDropdownTree: false, selectedTableRow: {}, showLoading: false }))
							}
						})
				}
				setState(prevState => ({ ...prevState, selectedAdvancedSearch: {} }))
			}

		}
	}, [dispatch, state.callFilterIdentifiers, state.selectedDuration, state.selectedCluster, state.cluserAssetTableColumns, state.assetTableColumns, state, getAssetDetailsFunction])

	useEffect(() => {
		if(state.callStructureAdvancedSearchParams) {
			let adParams = state.selectedAdvancedSearch
		
			let selectedTags = []
			if(adParams.tags && adParams.tags.length)  {
				adParams.tags.forEach(row => {
					let addRow = {}
					addRow.key = row.label
					addRow.value = row.value
					selectedTags.push(addRow)
				})
			}
			
			if(adParams.duration)  {
				setState(prevState => ({ ...prevState, selectedDuration: adParams.duration }))
			} else if(adParams.start_time && adParams.end_time) {
				let start_time = adParams.start_time
				let end_time = adParams.end_time
				setState(prevState => ({ ...prevState, selectedDuration: "custom", start_time, end_time }))
			}

			setState(prevState => ({ 
				...prevState,
				showAdvancedSearchModal: false,
				showLoading: true,
				selectedAdvancedSearchFilters: adParams,
				selectedProvider: adParams.provider ? adParams.provider : "aws",
				selectedAccount: adParams.account_id && adParams.account_id.length ? adParams.account_id : [],
				selectedRegion: adParams.region && adParams.region.length ? adParams.region : [],
				selectedService: adParams.service_name && adParams.service_name.length ? adParams.service_name : [],
				selectedCategory: adParams.category && adParams.category.length ? adParams.category : [],
				selectedTags,
				tagsSelected: selectedTags,
				selectedTableTab: -1,
				totalAssetsList: [],
				nextToken: "",
				callFilterIdentifiers: true,
				callStructureAdvancedSearchParams: false
			}))
		}
	}, [state.callStructureAdvancedSearchParams, state.selectedAdvancedSearch])

	const getNextSetoffRecords = () => {
		setState(prevState => ({ ...prevState, callFilterIdentifiers: true }))
	}

	/**
	 * Method to return total category counts
	 */
	const getTotalCategoryCounts = () => {
		let count = 0
		if (state.categoryCounts && state.categoryCounts.length) {
			state.categoryCounts.forEach(item => {
				count += item.total_count
			})
		}
		return count
	}
	/**
	 * toggles tab list dropdown
	 */
	const toggleShowTabListDropdown = () => {
		setState(prevState => ({ 
			...prevState, 
			showTabListDropdown: !prevState.showTabListDropdown,
		}))
	}
	/**
	 * Handles on click tab dropdown
	 */
	const onClickDropDownItem = (item, index) => {
		let tempArray = state.categoryCounts
		tempArray.splice(index, 1)
		tempArray.splice(state.tabItemsToShow - 2, 0, item)
		setState(prevState => ({
			...prevState,
			categoryCounts: tempArray,
			selectedTableTab: state.tabItemsToShow - 2,
			selectedCategory: item.category,			
		}))
		getAllAssetsOnTabChange(item.category)
	}

	const getAllAssetsOnTabChange = selectedCategory => {
		setState(prevState => ({ ...prevState, nextToken: [], selectedCategory: selectedCategory !== "All" ? selectedCategory : [], callFilterIdentifiers: true }))
	}

	const getRelatedTableData = (category, selectedResourceStatus, status) => {

		let filteredRelatedIssuesData = state.relatedIssuesData
		if(category !== '' && category !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.category.includes(category))
		} 
		if(selectedResourceStatus !== '' && selectedResourceStatus !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.resolution_status_info ? item.resolution_status_info.status === selectedResourceStatus : selectedResourceStatus === 'pending' ? item.status === 'fail' : item.status === 'pass')
		}
		if(status !== '' && status !== undefined) {
			filteredRelatedIssuesData = filteredRelatedIssuesData.filter(item => item.status === status)
		} 
		setState(prevState => ({ ...prevState, filteredRelatedIssuesData }))
	}
	/**
	 * Get asset details of the detector
	 */
	

	const openCloseModal = (closeRow, relatedIssueDurtaion) => {
		setState(prevState => ({ ...prevState, 
			modalClose: !state.modalClose, selectedRelativeIssue: closeRow, relatedIssueDurtaion, closeLoading: false
		}))
	}

	/**
	 * Get asset events
	 */
	const getAssetEvents = asset => {
		setState(prevState => ({ ...prevState, showDetailsEventsSectionLoading: true }))
		
		const params = {}
		params.provider = asset.provider.toLowerCase()
		params.service_name = asset.service_name
		params.asset_id = asset.asset_id
		params.aggregate_by = 'event'
		if (state.selectedDuration !== 'Current') {
			if(state.selectedDuration === 'custom')  {
				params.start_time = state.start_time
				params.end_time = state.end_time
			} else {
				params.duration = state.selectedDuration
			}
		}
		params.account_id = asset.account_id
		params.region = asset.region
		dispatch(getTopEvents(params))
			.then((response) => {
				setState(prevState => ({ ...prevState, topEventsData: response, showDetailsEventsSectionLoading: false }))
			})

		const recentEventsParams = {
			service_name: asset.service_name,
			provider: asset.provider.toLowerCase(),
			asset_id: asset.asset_id,
		}
		dispatch(getRecentEvents(recentEventsParams))
			.then((response) => {
				setState(prevState => ({ ...prevState, recentEventsData: response, showDetailsEventsSectionLoading: false }))
			})

		const eventCountsParams = {}
		eventCountsParams.service_name = asset.service_name
		eventCountsParams.provider = asset.provider.toLowerCase()
		eventCountsParams.asset_id = asset.asset_id
		if (state.selectedDuration !== 'Current') {
			if(state.selectedDuration === 'custom')  {
				eventCountsParams.start_time = state.start_time
				eventCountsParams.end_time = state.end_time
				// eventCountsParams.duration = '+14d'
			} else {
				eventCountsParams.duration = state.selectedDuration
			}
		} else {
			eventCountsParams.duration = '+1d'
		}
		dispatch(getEventGraphCounts(eventCountsParams))
			.then((response) => {
				setState(prevState => ({ ...prevState, eventCountData: response, showDetailsEventsSectionLoading: false, eventSeries: [], eventOptions: [], showApexEventsGraph: true }))
			})
	}

	useEffect(()=> {
		if(state.showApexEventsGraph && state.eventCountData) {
			let graphLabel = []
			let createdGraphData = []
			// let modifiedGraphData = []
			let fontColors = []
			let startEndLabels = []
			if(state.eventCountData.data && state.eventCountData.data.count && state.eventCountData.data.count.length) {
				state.eventCountData.labels.forEach((item, x) => {
					graphLabel.push(moment(item).format('DD MMM'))
					if(x === 0 || x === (state.eventCountData.labels.length - 1)) {
						startEndLabels.push(moment(state.eventCountData.labels[x]).format('DD MMM'))
					} 
	
				})
				let data1 = []	
				state.eventCountData.data.count.forEach((item, x) => {
					createdGraphData.push(item)
					let dataItems1 = {}
					dataItems1.x = moment(state.eventCountData.labels[x]).format('DD MMM')
					dataItems1.y  = item
					data1.push(dataItems1)
	
					fontColors.push('#000')
				})
	
				let series = []
				let dataRow = {}
				dataRow.data = data1
				dataRow.name = 'pass'
				dataRow.labels = graphLabel
				series.push(dataRow)
	
				let options = {
					chart: {
					height: 100,
					type: 'area',
					zoom: {
						enabled: false
					},
					sparkline: {
						enabled: false
					},
					toolbar: {
						show: false,
					}
					},
					legend: {
						show: false
					},
					dataLabels: {
					enabled: false
					},
					stroke: {
						show: true,
						curve: 'smooth',
						lineCap: 'butt',
						colors: undefined,
						width: 2,
						dashArray: 0,      
					},
					title: {
					text: ''
					},
					grid: {
						xaxis: {
							lines: {
								show: false
							},
						},
						yaxis: {
							lines: {
								show: false
							}
						},
					},			
					yaxis: {
						labels: {
							show: true,
							style: {
								colors: fontColors
							},
							formatter: function(value) {
								return parseInt(value)
							}
						},
						tickAmount: 1
					},
					labels: graphLabel,
					xaxis: {
						Categories: [graphLabel],
						show: true,
						labels: {
							rotate: 0,
							show: true,
							formatter: function(value) {
								if(value === startEndLabels[0] || value === startEndLabels[1]) {
									return value
								}
							},
							style: {
								colors: fontColors
							},
							axisTicks: {
								show: false
							}
						},
						tooltip: {
							enabled: false
						},
						// min: min,
						// max: max,
					},
					colors: ['#FFD200', '#B96422'],
					// colors: ['#09D261', '#F44336'],
					// fill: {
					// 	colors: ["#CEFBFF"],
					// 	opacity: 0.7,
					// 	type: 'solid',
					// 	//opacity: 0.5,
					// },
					tooltip: {
						enabled: true,
						enabledOnSeries: false,
						shared: false,
						followCursor: false,
						intersect: false,
						inverseOrder: false,
						custom: function({series, seriesIndex, dataPointIndex, w}) {
							let val  = series[seriesIndex][dataPointIndex];
							//let val  = series[seriesIndex][dataPointIndex];
							let labelName = w.globals.initialSeries[0].labels[dataPointIndex]
							//let label = w.globals.initialSeries[seriesIndex][dataPointIndex]
							//val = Math.abs(Math.round(val))
							//let labelName = w.globals.categoryLabels[dataPointIndex]
							//let val = str.replace('-',series[seriesIndex][dataPointIndex]);
							return '<div class="arrow_box"> <span style="color:'+ w.globals.colors[seriesIndex] +'">' +  labelName + '</span> ' + val +
							'</div>'
						},
						fillSeriesColor: false,
						theme: false,
						style: {
						fontSize: '12px',				
						},
						onDatasetHover: {
							highlightDataSeries: false,
						},
						x: {
							show: false,
							format: 'dd MMM',
							formatter: undefined,
						},
						y: {
							show: false,
							formatter: undefined,
							title: {
								formatter: (seriesName) => seriesName,
							},
						},
						marker: {
							show: false,
						}
					}
				}
				
				setState(prevState => ({ ...prevState, eventSeries: series, eventOptions: options, showApexEventsGraph: false }))
			}
		}
	}, [state.showApexEventsGraph, state.eventCountData])

	/**
	 * Handles loading toggle from details section screen
	 */
	const handleLoadingToggle = (from, boolean) => {
		if (from === 'asset_details') setState(prevState => ({ ...prevState, howDetailsAssetSectionLoading: boolean }))
		if (from === 'events') setState(prevState => ({ ...prevState, howDetailsEventsSectionLoading: boolean }))

		setState(prevState => ({ ...prevState, selectedTab: from }))
	}
	
	const onClickAdvancedSearhBox = () => {
		if(state.newSearchText === '') {
			setState(prevState => ({ ...prevState, isLoading: true, showDropdown: true, searchResultState: [] }))

			let params = {
				provider: state.selectedProvider.toLowerCase(),
				size: 5,
			}		
			dispatch(getSearchMetaResults(params))
				.then((response) => {
					setState(prevState => ({ ...prevState, searchResultState: response, isLoading: false }))
				})
		}
	}

	const getSearchMetaResultsFunction = () => {
		if (state.keyupTimer) {
			clearTimeout(state.keyupTimer)
		}
		if(state.newSearchText.length > 2) {
			setState(prevState => ({ ...prevState, showDropdown: true }))
			let keyupTimer = setTimeout(() => {
				setState(prevState => ({ ...prevState, isLoading: true }))
				let params = {
					provider: state.selectedProvider.toLowerCase(),
					search_input: state.newSearchText,
					size: 5,
				}
				dispatch(getSearchMetaResults(params))
					.then((response) => {
						setState(prevState => ({ ...prevState, searchResultState: response, isLoading: false }))
					})
			}, 500)
			setState(prevState => ({ ...prevState, keyupTimer }))
		} else {
			setState(prevState => ({ ...prevState, showDropdown: false }))
		}		
	}

	const handleResetClick = () => {	
		setState(prevState => ({ ...prevState, from: undefined, to: undefined }))
	}

	const handleDayClick = (day) => {
		if(state.from === undefined) {
			// const range = DateUtils.addDayToRange(day, state);
			setState(prevState => ({ 
				...prevState,
				from: day, 
				durationTypeFrom: moment(day).format('DD MMM'),
				start_time: datestartEndTime(day, 'start')
			}))
		} else {
			var Difference_In_Time = day.getTime() - state.from.getTime(); 			
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			if(Difference_In_Days > 0) {
				setState(prevState => ({
					...prevState, 
					to: day, 
					showDatepicker: false, 
					relatedIssueDurtaion: 'custom',
					relatedDurtaion: 'custom',
					selectedDuration: 'custom',
					durationType: state.durationTypeFrom + ' - ' + moment(day).format('DD MMM'),
					end_time: datestartEndTime(day, 'end'),
					callFilterIdentifiers: true,
					totalAssetsList: [], filteredArray: [], nextToken: null,
				}))
			}
		}
	}

	const onChangeServiceDropdown = (currentNode, selectedNodes) => {
		let filteredServiceResourceArray = []	
		let selectedTreeService = []
		let selectedService = []
		if(selectedNodes.length && currentNode.label !== 'All') {
			selectedNodes.forEach(item => {
				if(item._depth === 0) {
					let child = {}
					child.label = item.label
					child.parent = 1
					selectedTreeService.push(child)
					state.serviceResourceArray.forEach(service => {
						if(service.label === item.label) {
							service.children.forEach(child => {
								selectedService.push(child.label)
							})
						}
					})
				} else {
					let child = {}
					child.label = item.label
					child.parent = 0
					selectedTreeService.push(child)
					selectedService.push(item.label)
				}
			})

			filteredServiceResourceArray = []
			state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					if(selectedTreeService.length) {					
						selectedTreeService.forEach(ser => {
							if(ser.parent === 1 && item.label === ser.label) {
								dataRow.checked = true
							}						
							if(ser.parent === 1 && item.label === ser.label) {
								childRow.checked = true
							} else if(ser.label === child.label){
								childRow.checked = true
							}			
						})
					}
					childArray.push(childRow)
					dataRow.children = childArray
				})		
				
				if(item.label === 'All' && currentNode.label === 'All') {
					dataRow.checked = true
				}
	
				filteredServiceResourceArray.push(dataRow)
			})
		} else if(currentNode.label === 'All' || !selectedNodes.length) {
			selectedTreeService= []
			selectedService = []
			filteredServiceResourceArray = []
			state.serviceResourceArray.forEach(item => {
				let dataRow = {}
				dataRow.label = item.label
				dataRow.expanded = true
				if(currentNode.checked) {
					dataRow.checked = true
				} else if(!currentNode.checked) {
					dataRow.checked = false
				}
				let childArray = []
				item.children.forEach(child => {
					let childRow = {}
					childRow.label = child.label
					childRow.value = child.label
					childRow.expanded = true
					childRow.parent = 1
					if(currentNode.checked) {
						childRow.checked = true
						selectedService.push(childRow.value)
					} else if(!currentNode.checked) {
						childRow.checked = false
					}
					childArray.push(childRow)
					selectedTreeService.push(childRow)
					dataRow.children = childArray					
				})
				filteredServiceResourceArray.push(dataRow)
			})
		}	

		let selectedServiceContent = selectedService.length === 1 ? selectedService : selectedService.length > 2 ? selectedService.length +' Selected' : 'All'
		setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, filteredServiceResourceArray, selectedService, selectedServiceContent, callFilterIdentifiers: true }))
	}

	const handleClickOutside = (event) => {
		if (accountRef.current && !accountRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isAccountOpen: false }));
        } else {
            setState(prevState => ({ ...prevState, isAccountOpen: true }));
        }
        if (regionRef.current && !regionRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isRegionOpen: false }));
        } else {
            setState(prevState => ({ ...prevState, isRegionOpen: true }));
        }

		if(durationRef.current && !durationRef.current.contains(event.target)) {
			if (dayPickerRef && !dayPickerRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showDatepicker: false }))
				handleResetClick()
			}
		}
		if(state.showTagFilterSection) {
			if (tagRef.current && !tagRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showTagFilterSection: false }))
			}

			if (tagValueRef.current &&  !tagValueRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, isTagValueOpen: false }))
			} else {
				setState(prevState => ({ ...prevState, isTagValueOpen: true }))
			}
		}
		if(state.showClusterFilterSection) {
			if (clusterRef.current && !clusterRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, showClusterFilterSection: false }))
			}

			if (clusterValueRef.current &&  !clusterValueRef.current.contains(event.target)) {
				setState(prevState => ({ ...prevState, isCLusterOpen: false }))
			} else {
				setState(prevState => ({ ...prevState, isCLusterOpen: true }))
			}
		}

		if (searchRef.current && !searchRef.current.contains(event.target)) {
			setState(prevState => ({ ...prevState, showDropdown: false }))
		} else {
			setState(prevState => ({ ...prevState, showDropdownTree: false }))
		}

	}

	useEffect(() => {
		if(state.callFormHeader) {
			let dataResult = state.filteredArray && state.filteredArray.length ? state.filteredArray : []
			if(dataResult.length) {
				let headers = []
				Object.entries(dataResult[0]).forEach(([key, value]) => {
					if(typeof value === "string") {
						let headerRow = {}
						headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
						headerRow.key = key
						headers.push(headerRow)
					}
				})
				setState(prevState => ({ ...prevState, headers, callFormHeader: false }))
			}
		}
	}, [state.callFormHeader, state.filteredArray])

	/**
	 * Renders detectors Dashboard
	 */
	const { from, to } = state;
	const modifiersState = { start: from, end: to };
	return (
		<div className='container-fluid overflow-auto flex-grow-1 bg-backGroundGray' onClick={(event) => handleClickOutside(event)}>
			
			{state.showAdvancedSearchModal ? (
				<ResourcesAdvancedSearch
					start_time={state.start_time}
					end_time={state.end_time}
					resetForm={state.resetForm}
					categoryCounts={state.categoryCounts}
					searchFrom='resources'
					providers={state.providers}
					onCloseModal={() => setState(prevState => ({ ...prevState, showAdvancedSearchModal: false }))}
					selectedProvider={state.selectedProvider}
					selectedAccount={state.selectedAccount}
					getFilteredList={(adParams, searchTitle) => {
						setState(prevState => ({ ...prevState, selectedAdvancedSearchTitle: searchTitle, selectedAdvancedSearch: adParams, callStructureAdvancedSearchParams: true }))
					}}
				/>
			) : null}
			<div className='row h-100'>
				<div className={ state.filteredArray && state.filteredArray.length && state.showDetailsSection ? 'col-sm-8 col-lg-9 p-0' : 'col-sm-12 p-0'}>
					<div className='title-section-new'>
						<div className='row'>
							<div className='col-lg-4'>
								<h6 className='text-white m-0'>Search Resources</h6>
								<p className='text-white m-0 mb-2'>Search the resources present in your Cloud</p>
							</div>
							<div className={`col-lg-8 text-right ${state.showLoading ? 'disabled' : ''}`}>
								<div className="input-group search-drop dark-feild mb-2 mr-sm-2">
									<div className="input-group-prepend">
										<div className="input-group-text bg-transparent">
											<i className="fal fa-search"></i>
										</div>
									</div>
									<input
										ref={searchRef}
										type='text'
										value={state.newSearchText}
										onChange={e => {
											setState(prevState => ({ ...prevState, newSearchText: e.target.value, searchResultState: [], isLoading: true }))
											getSearchMetaResultsFunction(e.target.value)
										}}
										className='form-control form-control-sm'
										placeholder='Search'
										onClick={(e => onClickAdvancedSearhBox())}
									/>
									
									<div className={`search-suggestion`}>
										{state.showDropdown && state.searchResultState && state.searchResultState.length ? 
											state.searchResultState.map((item, index) => {
												return (
													<div key={index} className="d-flex justify-content-between cursorPointer border-bottom p-2"
														onClick ={() =>  setState(prevState => ({ ...prevState, showAdvancedSearchModal: false, showLoading: true, selectedProvider: item.provider ? item.provider : "aws", selectedAdvancedSearch: item.parameters, callStructureAdvancedSearchParams: true }))}
													>
														<div className="w-100">
															<p className='font-weight-bold m-0 text-dark text-left p-0'>{item.title ? item.title : ''}</p>
														</div>
													</div>
												)
											})
										: 
											state.showDropdown && state.isLoading && (
												<div className='d-flex justify-content-center m-4'>
													<Spinner className='text-center' color='dark' size='sm' />
												</div>
											) 
										}
										{state.showDropdown && state.searchResultState && !state.isLoading ?
											<div className="d-flex justify-content-between cursorPointer border-bottom p-2 bg-light">
												<p className="m-0 text-cyon" onClick={() =>
												setState(prevState => ({ ...prevState,resetForm: state.resetForm + 1, showAdvancedSearchModal: true, showDropdown: false, newSearchText: '', searchText: '' }))}>
												<span className="fal fa-search mr-1"></span> Advanced Search</p>
											</div>
										: null
										}
									</div>
								</div>
								<div className='justify-content-between'>
									<button
										className='btn btn-link mr-5 p-0 fs-13'
										onClick={() =>
											setState(prevState => ({ 
												...prevState, 
												newSearchText: '',
												selectedAdvancedSearch: {},
												selectedCategory: [],
												selectedProvider: '',
												selectedAccount: [],
												selectedRegion: [],
												selectedDuration: 'Current',
												selectedService: [],
												selectedServiceContent: 'All',
												filteredServiceResourceArray: [],
												selectedTags: [],
												tagsSelected: [],
												clusterType: '',
												clustersItems: [],
												clusterNames: [],
												selectedCluster: [],
												callFilterIdentifiers: true
											}))
										}
									>
										Reset Filter
									</button>
									<button
										className='btn btn-link p-0 fs-13'
										onClick={() =>
											setState(prevState => ({ ...prevState, resetForm: state.resetForm + 1, showAdvancedSearchModal: true }))
										}
									>
										Advanced Search
									</button>
								</div>
							</div>
						</div>
						<div className='row mt-1'>
							<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild ml-3 mr-2`} style={{minWidth: '150px'}}>
								<label className='mb-1 f10'>Provider</label>
								<Select
									placeholder={'Select'}
									isSearchable={false}
									className='f13 p-0 colorBlack reactSelectDrop'
									components={{
										IndicatorSeparator: () => null,
									}}
									value={{
										value: state.selectedProvider && state.selectedProvider !== '' ? state.selectedProvider : 'Select',
										label: state.selectedProvider && state.selectedProvider !== '' ? state.selectedProvider : <span className='placeholder'>Select</span>
									}}
									options={
										state.providers && state.providers.length ?
											state.providers.map(row => ({
												value: row.provider_name,
												label: row.provider_name,
											}))
										: state.emptyOptions
									}
									onChange={event => {
										setState(prevState => ({ ...prevState, selectedProvider: event.value, changesInFilter: true, selectedAccount: [], selectedRegion: [], callFilterIdentifiers: true }))
									}}
								/>
							</div>
							<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-2`} style={{minWidth: '150px'}} ref={accountRef}>
								<label className='mb-2 mt-1 f10 d-flex'>Account</label>
								<p className={`d-flex mt-2 mb-0 ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</p>
								{state.isAccountOpen ?
									<MultiSelectSection 
										fields={["account_id", "account_name"]}
										topClass={"auto"}
										options={state.accounts}
										selectedValues={state.selectedAccount ? state.selectedAccount : []}
										callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, selectedAccount: value, callClusterList: true, callFilterIdentifiers: true }))}
										widthClass={'minWidth220'}
									/>
								: null}
							</div>
							<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-2`} style={{minWidth: '150px'}} ref={regionRef}>
								<label className='mb-2 mt-1 f10 d-flex'>Region</label>
								<p className={`d-flex mt-2 mb-0 ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</p>
								{state.isRegionOpen ?
									<MultiSelectSection 
										fields={["region", "name"]}
										topClass={"auto"}
										options={state.regions}
										selectedValues={state.selectedRegion ? state.selectedRegion : []}
										callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, selectedRegion: value, callClusterList: true, callFilterIdentifiers: true }))}
										widthClass={'minWidth220'}
									/>
								: null}
							</div>
							<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-2 ml-1 text-dark serviceFilterDropdown`} style={{minWidth: '150px'}} ref={serviceRef}>
								<label className="mb-2 text-white w-100">Service</label>
								<div className={`serviceTreeDropdown position-absolute mt-n1  ${state.filteredServiceResourceArray.length ?'': 'disabled'}`}>
									<DropdownTreeSelect 
										//values={state.selectedServiceContent}
										texts={{ placeholder: state.selectedServiceContent }}
										data={state.filteredServiceResourceArray}
										onChange={onChangeServiceDropdown}
										className="bootstrap-demo"
										keepTreeOnSearch={true}
										keepChildrenOnSearch={true}
									/>
								</div>
							</div>
							<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-2`} style={{minWidth: '150px'}} ref={tagRef}>
								<div className="" onClick={() => setState(prevState => ({ ...prevState, showTagFilterSection: true }))}>
									<label>Tags</label>
									<p className='pt-1 mb-0'>
										{state.selectedTags && state.selectedTags.length ?
											<span>{state.selectedTags.length} selected</span>
										:
											<span className="placeholder">Select</span>
										}
									</p>
								</div>
								{state.showTagFilterSection ?
									<div className="containerFilterSection costPopupTagSection border-lightGray mt-1" style={{ right: "10px" }}>
										<div className="p-3 bg-darkGray">
											<div className={`w-100 mt-1`}>
												<p className="mb-2 w-100 text-white">Tags</p>
												<div className={`d-flex`}>
													<label className="mb-1 w-40 text-white">Key</label>
													<label className="mb-1 w-40 text-white">Value</label>
												</div>
												<div className={`w-100`}>
													<div className={`d-flex mt-3`}>
														<Select
															isSearchable={true}
															components={{
																IndicatorSeparator: () => null
															}}
															className={`selectOption mr-2`}
															value={({
																value: state.tag_key,
																label: state.tag_key && state.tag_key !== "" ? state.tag_key : <span className="placeholder">Key</span>
															})}														
															
															options={
																tagKeys && tagKeys.length ?
																	tagKeys.map(item => ({
																		value: item,
																		label: item,	
																	}))
																: []
															}
															onChange={event => setState(prevState => ({ 
																...prevState, 
																tag_key: event.value,
																tagValues: [],
															}))}
														/>
														{state.tag_key && state.tag_key !== "" && !state.tagValues.length ?
															<div className='ml-3'>
																<Spinner className='text-center align-self-center' color='dark' size='sm' />
															</div>
														:
															<React.Fragment>
																<div className='w-40 mt-1 boderDottedBottom' ref={tagValueRef}>
																	<p className={`d-flex mt-2 mb-0 ${state.tag_value && state.tag_value.length ? 'text-white' : 'placeholder'}`}>{state.tag_value && state.tag_value.length ? state.tag_value.length+' Selected' : 'Select'}</p>
																	{state.isTagValueOpen ?
																		<div className='position-relative'>
																			<MultiSelectSection 
																				// fields={["account_id", "account_name"]}
																				className={'border-0'}
																				removeTop={true}
																				options={state.tagValues}
																				selectedValues={state.tag_value ? state.tag_value : []}
																				callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tag_value: value }))}
																				widthClass={'minWidth220'}
																			/>
																		</div>
																	: null}
																</div>
																<span className={`far fa-plus cursorPointer text-white f18 align-self-center ml-4`} onClick={() => addNewTag("tags")}></span>
															</React.Fragment>
														}
													</div>
												</div>
											</div>
											{state.tagsSelected && state.tagsSelected.length ? 
												state.tagsSelected.map((tag, index) => {
													return(
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{tag.key +(tag.value ? " : "+tag.value : "")}
															<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeTagSection(tag) } ></i>
														</span>
													)
												})
											: null}
											<div className="d-flex mt-3">
												{/* <span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => setState(prevState => ({ ...prevState, tagsSelected: [], selectedTags: [] }))}>Clear</span> */}
												{/* <button className={`btn btn-sm btn-light cursorPointer`} 
												onClick={() => setState(prevState => ({ ...prevState, showTagFilterSection: false, selectedTags: state.tagsSelected }))
													//</div>() => this.filterIdentifiers())  need to work
												}>Select</button> */}
											</div>
										</div>
									</div>
								: null}
							</div>
							<div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-2`} style={{maxWidth: '200px' }} ref={clusterRef}>
								<div className="" onClick={() => setState(prevState => ({ ...prevState, showClusterFilterSection: true }))}>
									<label>Cluster</label>
									<p className='pt-1 mb-0'>
										{state.selectedCluster && state.selectedCluster.length ?
											<span>{state.selectedCluster.length} selected</span>
										:
											<span className="placeholder">Select</span>
										}
									</p>
								</div>
								{state.showClusterFilterSection ?
									<div className="containerFilterSection costPopupTagSection border-lightGray mt-1" style={{ right: "10px" }}>
										<div className="p-3 bg-darkGray">
											<div className={`w-100 mt-1`}>
												<p className="mb-2 w-100 text-white">Type</p>
												<div className={`w-100`}>
													<div className={`d-flex mt-3`}>
														<Select
															isSearchable={true}
															components={{
																IndicatorSeparator: () => null
															}}
															className={`selectOption mr-2`}
															value={({
																value: state.clusterType,
																label: state.clusterType ? state.clusterList.filter(e => e.value === state.clusterType)[0].label : <span className="placeholder">Cluster</span>
															})}														
															
															options={
																state.clusterList && state.clusterList.length ?
																	state.clusterList.map(row => ({
																		value: row.value,
																		label: row.label,
																	}))
																: []
															}
															onChange={event => setState(prevState => ({ 
																...prevState, 
																clusterType: event.value,
																clusterSelected: [],
																clustersItems: [],
																callClusterNames: true
															}))}
														/>

														{state.clustersItems && !state.clustersItems.length ?
															<div className='ml-3'>
																<Spinner className='text-center align-self-center' color='dark' size='sm' />
															</div>
														:
															<React.Fragment>
															<div className='w-40 mt-1 boderDottedBottom' ref={clusterValueRef}>
																<p className={`d-flex mt-2 mb-0 ${state.clusterNames && state.clusterNames.length ? 'text-white' : 'placeholder'}`}>{state.clusterNames && state.clusterNames.length ? state.clusterNames.length+' Selected' : 'Select'}</p>
																{state.isCLusterOpen ?
																	<div className='position-relative'>
																		<MultiSelectSection 
																			fields={["cluster_name", "cluster_name"]}
																			className={'border-0'}
																			removeTop={true}
																			options={state.clustersItems}
																			selectedValues={state.clusterNames ? state.clusterNames : []}
																			callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, clusterNames: value }))}
																			widthClass={'minWidth220'}
																		/>
																	</div>
																: null}
															</div>
															<span className={`far fa-plus cursorPointer text-white f18 align-self-center ml-4`} onClick={() => addNewCluster()}></span>
															</React.Fragment>
														}
													</div>
												</div>
											</div>
											
											{state.clusterSelected && state.clusterSelected.length ? 
												state.clusterSelected.map((item, index) => {
													return(
														item.value && item.value.length ?
															item.value.map(nam => {
																return(
																	<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{item.key +" : "+nam}
																		<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeClusterValue(nam) } ></i>
																	</span>
																)
															})
														:
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{item.key}
																<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeClusterSection(item) } ></i>
															</span>
													)
												})
											: null}
											<div className="d-flex mt-3">
												<span className={`text-info mr-2 align-self-center cursorPointer`} onClick={() => setState(prevState => ({ ...prevState,  clusterSelected: [], selectedCluster: [] }))}>Clear</span>
												<button className={`btn btn-sm btn-light cursorPointer`} onClick={() => setState(prevState => ({ ...prevState, showClusterFilterSection: false, previousSelectedCluster: state.selectedCluster, selectedCluster: state.clusterSelected, totalAssetsList: [], nextToken: "" , callFilterIdentifiers: true }))}>Select</button>
											</div>
										</div>
									</div>
								: null}
							</div>
							<div className='form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-2' style={{maxWidth: '200px' }} ref={durationRef}>
								<label>Duration
									{state.durationType !== '' ? <small className="pl-3">{state.durationType}</small> : ''}
								</label>
								<select
									className='form-control'
									value={state.durationType !== '' ? 'custom' : state.selectedDuration}
									onChange={event => {
										if(event.target.value !== 'custom') {													
											// handleResetClick()
											setState(prevState => ({
												...prevState, 
												selectedDuration: event.target.value, 
												relatedDurtaion: event.target.value, 
												showDatepicker: false, 
												selectedDatePicker: false, 
												durationType: '', 
												callFilterIdentifiers: true,
												totalAssetsList: [], filteredArray: [], nextToken: null,
											}))
												// () => this.filterIdentifiers('duration') need to work
										} else { 
											setState(prevState => ({
												...prevState, 
												showDatepicker: true,
											}))
										}
									}}
									onClick={event => {
										if(event.target.value === 'custom') {
											setState(prevState => ({
												...prevState, 
												showDatepicker: true
											}))
										}
									}}
								>
									{CUSTOM_CURRENT_DURATION_FILTER_OPTIONS.map((item, index) => {
										return (
											<option key={index} value={item.value}>
												{item.option}
											</option>
										)
									})}
								</select>
							</div>						
						</div>
						<div className={`RangeExample customDayPicker ${state.showDatepicker ? '' : 'displayNone'}`} ref={dayPickerRef}>
							<DayPicker
								toMonth={new Date()}
								disabledDays={[
									{
										after: new Date(),
										before: (new Date(state.previousCustomDays))
									},
								]}
								className="Selectable"
								numberOfMonths= {3}																			
								selectedDays={[from, { from, to }]}
								modifiers={modifiersState}
								onDayClick={handleDayClick}
								tabIndex={0}
							/>								
							<div className="col-sm-12">
								<button className="resetBtn f15 float-right mb-1" onClick={handleResetClick}>Reset</button>
							</div>
						</div>
					</div>

					<div className='box custom-margin bg-white'>
						{state.categoryCounts && state.categoryCounts.length ? (
							<div>
								<ul
									ref={tabRef}
									className={`nav nav-tabs bd-highlight bg-backGroundGray text-black ${state.showLoading ? 'disabled' : ''}`}
									id='myTab'
									role='tablist'
								>
									{state.categoryCounts &&
										getTotalCategoryCounts() !== 0 &&
										state.categoryCounts.length &&
										state.categoryCounts.map((item, index) => {
											if (index < state.tabItemsToShow && item.total_count !== 0)
												return (
													<li
														className='nav-item'
														key={index}
														onClick={() => getAllAssetsOnTabChange(item.category)}
													>
														<span
															className={`nav-link custom-link text-black ${
																state.selectedTableTab === index ? 'active' : ''
															}`}
															id='profile-tab'
															data-toggle='tab'
															onClick={() => setState(prevState => ({ ...prevState, selectedTableTab: index }))}
															role='tab'
															aria-selected='false'
														>
															{item.category}
															<span className='badge badge-outline-light text-black d-table m-auto'>{item.total_count}</span>
														</span>
													</li>
												)
											return null
										})}
									<li
										className={`nav-item ml-auto bd-highlight align-self-center ${
											state.categoryCounts && state.categoryCounts.length >= state.tabItemsToShow ? '' : 'd-none'
										}`}
									>
										<ButtonDropdown
											isOpen={state.showTabListDropdown}
											toggle={toggleShowTabListDropdown}
										>
											<DropdownToggle
												tag='a'
												className={`nav-link custom-link ${
													state.categoryCounts && !state.categoryCounts.length && getTotalCategoryCounts() === 0 ? 'd-none' : ''
												}`}
											>
												<i className='fal fa-angle-double-right cursor-pointer' />
											</DropdownToggle>
											
											<DropdownMenu
												end
												// modifiers={{
												// 	name: 'setMaxHeight',
												// 	enabled: true,
												// 	fn: data => {
												// 		return {
												// 			...data,
												// 			styles: {
												// 				...data.styles,
												// 				overflow: 'auto',
												// 				maxHeight: 300,
												// 			},
												// 		}
												// 	},
												// }}
											>
												{state.categoryCounts &&
													getTotalCategoryCounts() !== 0 &&
													state.categoryCounts.map((item, index) => {
														if (index >= state.tabItemsToShow && item.total_count !== 0)
															return (
																<DropdownItem key={index} onClick={() => onClickDropDownItem(item, index)}>
																	{item.category}
																</DropdownItem>
															)
														return null
													})}
											</DropdownMenu>
										</ButtonDropdown>
									</li>
								</ul>
							</div>
						) : null}
						{/* {!state.showSelectedFilters ? 
							<p className='mb-0 text-info align-self-center mx-3 pt-3 cursorPointer' onClick={() => setState(prevState => ({ ...prevState, showSelectedFilters: true }))}>Selected Filters</p>
						: null} */}
						
						{!state.showLoading ?
							<div className="container-fluid">
								<div className="row mt-2">
									<div className="col-sm-5 align-self-center text-black">
										<small>Showing {state.totalAssetsList && state.totalAssetsList.length} out of total {state.totalAssetsCount && state.totalAssetsCount} asset(s)</small> 
										{state.nextToken ?
											<small className='text-info ml-3 cursorPointer' onClick={getNextSetoffRecords}>Next</small>
										: null}
									</div>
									<div className={`d-flex col-sm-7 justify-content-end`}>
										{!state.showSelectedFilters ? 
										<p className='mb-0 text-info align-self-center mr-3 cursorPointer' onClick={() => setState(prevState => ({ ...prevState, showSelectedFilters: true }))}>Selected&nbsp;Filters</p>
										: null}											
										<CSVLink 
											data={state.filteredArray} 
											headers={state.headers ? state.headers : []}
											filename={"resource-search.csv"}
											className={"align-self-center"}
											target="_blank"
										>
											<i className="fas fa-download text-primary-color mr-2"></i>
											{/* <span className="text-primary-color mx-2">csv</span> */}
										</CSVLink>
										{state.selectedCluster && state.selectedCluster.length ?
											<Search
												data={state.totalAssetsList && state.totalAssetsList}
												topClassName={'ml-2 w-80 bg-extraLightGray'}
												className={'bg-extraLightGray text-black'}
												// applyTags={false}
												// applyLiteDarkTags={true}
												filteredData={filteredArray => {
													if (filteredArray.length) {
														setState(prevState => ({ ...prevState, filteredArray, selectedTableRow: filteredArray[0] }))
													} else {
														setState(prevState => ({ ...prevState, filteredArray: [], selectedTableRow: {} }))
													}
												}}
												// className='form-control-sm bg-transparent textboxBorder'
												// topClassName={'bg-gray1'}
												searchClassName={'textboxBorder'}
												searchIconColor={'text-black'}
											/>
										:
											<div className='d-flex border-lightGray rounded'>
												<i className={`fal fa-search cursorPointer text-black align-self-center pl-2 w-10`} />
												<div className={`w-90`}>
													<input
														value={state.searchText}
														onChange={e => {
															setState(prevState => ({ ...prevState, searchText: e.target.value }))
															clearTimeout(searchAssetInventory)
															if(e.target.value.length > 2) {
																searchAssetInventory = setTimeout(() => { setState(prevState => ({ ...prevState, searchText: e.target.value, nextToken: null,  totalAssetsList: [], filteredArray: [], showLoading: true, callFilterIdentifiers: true }))}, 1000);
															} else if(!e.target.value.length) {
																setState(prevState => ({ ...prevState, totalAssetsList: [], filteredArray: [], nextToken: null, showLoading: true, callFilterIdentifiers: true }))
															}
														}}
														type='text'
														className={`bg-transparent text-black text-md pl-1 py-1 border-0 w-100`}
														placeholder='Search'
													/>
												</div>
											</div>
										}
									</div>
									
									{state.showSelectedFilters ?
										<div className='col-sm-12 col-lg-12 col-md-12 mt-2 d-flex justify-content-center'>
											<div className="bg-darkGray p-3 w-80">
												<div className='d-flex justify-content-between'>
													<p className="mb-2 w-100 text-white">Selected Filters</p>
													<i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showSelectedFilters: false }))}></i>
												</div>
												<div className='d-flex flex-wrap'>
													{state.selectedAccount && state.selectedAccount.length ?
														state.selectedAccount.map(row => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																	<span className='text-primary-color'>Account : </span>
																	{getAccountNameFromId(row, state.accounts)}
																</span>

															)
														})
													: 
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
															<span className='text-primary-color'>Account :</span>
															All
														</span>
													}
													{state.selectedRegion && state.selectedRegion.length ?
														state.selectedRegion.map(row => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																	<span className='text-primary-color'>Region :</span> 
																	{getRegionName(row, state.regions)}
																</span>

															)
														})
													: 
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
															<span className='text-primary-color'>Region :</span>
															All
														</span>
													}
													{state.selectedService && state.selectedService.length ?
														state.selectedService.map(row => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																	<span className='text-primary-color'>Service : </span> 
																	{row}
																</span>
															)
														})
													: 
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
															<span className='text-primary-color'>Service : </span> 
															All
														</span>
													}
													{state.selectedCategory && state.selectedCategory.length && !state.selectedCategory.includes('All') ?
														state.selectedCategory.map(row => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																	<span className='text-primary-color'>Category : </span> 
																	{row}
																</span>
															)
														})
													: 
														<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
															<span className='text-primary-color'>Service : </span> 
															All
														</span>
													}
													{state.selectedTags && state.selectedTags.length ?
														state.selectedTags.map(row => {
															return(
																<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																	<span className='text-primary-color'>Tag :</span> 
																	{row.key +" : "+ row.value}
																</span>
															)
														})
													: null}
													{state.selectedCluster && state.selectedCluster.length ?
														<div className='d-flex mr-2'>
															<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																<span className='text-primary-color'>Cluster :</span>
																{state.selectedCluster[0].key +" : "+ state.selectedCluster[0].value}
															</span>
														</div>
													: null}
													
													{state.selectedAdvancedSearch && Object.entries(state.selectedAdvancedSearch).length ?
														<React.Fragment>
														{state.selectedAdvancedSearch.hasOwnProperty("event_name") ?
															<React.Fragment>
															{state.selectedAdvancedSearch.event_name.include ?
																state.selectedAdvancedSearch.event_name.include.map(evt => {
																	return (
																		<div className='d-flex mr-2'>
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>Event : include :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															{state.selectedAdvancedSearch.event_name.exclude ?
																state.selectedAdvancedSearch.event_name.exclude.map(evt => {
																	return (
																		<div className='d-flex mr-2'>
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>Event : exclude :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															</React.Fragment>
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("users") ?
															state.selectedAdvancedSearch.users.map(usr => {
																return (
																	<div className='d-flex mr-2'>
																		<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																			<span className='text-primary-color'>Accessed By :</span>
																			{usr}
																		</span>
																	</div>
																)
															})
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("ip") ?
															<React.Fragment>
															{state.selectedAdvancedSearch.ip.include ?
																state.selectedAdvancedSearch.ip.include.map(evt => {
																	return (
																		<div className='d-flex mr-2'>
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>IP : include :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															{state.selectedAdvancedSearch.ip.exclude ?
																state.selectedAdvancedSearch.ip.exclude.map(evt => {
																	return (
																		<div className='d-flex mr-2'>
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>IP : exclude :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															</React.Fragment>
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("instance_type") ?
															state.selectedAdvancedSearch.instance_type.map(inst => {
																return (
																	inst.instance_type.map(typ => {
																		return (
																		<div className='d-flex mr-2'>
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>{inst.resource_type} :</span>
																				{typ}
																			</span>
																		</div>
																		)
																	})
																)
															})
														: null}
														{state.selectedAdvancedSearch.hasOwnProperty("vpc_id") ?
															<React.Fragment>
															{state.selectedAdvancedSearch.vpc_id.include ?
																state.selectedAdvancedSearch.vpc_id.include.map(evt => {
																	return (
																		<div className='d-flex mr-2'>
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>Network : include :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															{state.selectedAdvancedSearch.vpc_id.exclude ?
																state.selectedAdvancedSearch.vpc_id.exclude.map(evt => {
																	return (
																		<div className='d-flex mr-2'>
																			<span className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">
																				<span className='text-primary-color'>Network : exclude :</span>
																				{evt}
																			</span>
																		</div>
																	)
																})
															: null}
															</React.Fragment>
														: null}
														</React.Fragment>
													: null}
												</div>
												{state.selectedFilters && state.selectedFilters.length ? 
													<p className="text-cyon f14 mb-0 font-weight-bold align-self-center cursorPointer mr-2 ml-4" onClick={ () => setState(prevState => ({ ...prevState, showSlectedFilters: false }))}>Hide Filters</p>
												: null}
											</div>
										</div>
									: null}
								</div>
								<div className='tab-content py-1' id='myTabContent'>
									<div className={`tab-pane fade show active ${state.tableClass}`} id='home' role='tabpanel' aria-labelledby='home-tab'>
										{state.filteredArray && state.filteredArray.length && !state.showLoading ? (
											<ResizeableDarkThemeTable
												columns={state.dynamicHeaders}											
												tableHead={"bg-black text-white"}
												tableBody={"bg-white"}
												tableType={"table-light-hover"}
												data={state.filteredArray}
												onClickRow={tableRow => {
													setState(prevState => ({
														...prevState,
														selectedTableRow: tableRow,
														selectedMonitoringDuration: '2h',
														selectedDetailsTab: 0,
														selectedTab: 'asset_details',
														relatedDurtaion: state.selectedDuration,
														// selectedAssetDetails: tableRow
													}));														
													getAssetDetailsFunction(tableRow)
												}}
												selectedRecord={state.selectedTableRow}
												perPage={20}
												perPageClass={`bg-transparent text-lightGray`}
												//tooltipFor={[]}
												riskTooltip={[0]}
												rounded={true}
												textColor={"text-black"}
												selectedColor={"bg-backGroundGray"}
												dashboard={state.filteredArray && state.filteredArray.length ? true : false}
											/>
										) : (!state.showLoading && state.filteredArray && !state.filteredArray.length) ||
											(state.filteredArray && !state.filteredArray.length && !state.showLoading) ? (
											<div className='d-flex justify-content-center m-4'>
												<p>There are no data on this criteria. Please try adjusting your filter.</p>
											</div>
										) : null}
									</div>
								</div>
							</div>
						: state.showLoading ? (
							<div className='d-flex justify-content-center m-4'>
								<Spinner className='text-center' color='dark' />
							</div>
						) : null}
					</div>
				</div>
				<div className={state.filteredArray && state.filteredArray.length  ? 'col-sm-4 col-lg-3 p-0 bg-dark3' : 'displayNone'}>
					<div className={ state.filteredArray && state.filteredArray.length && state.selectedTableRow &&
							Object.entries(state.selectedTableRow).length && !state.showConfigureSection ? 'detail' : 'd-none'
						}
					>
						<div className='detail-title bg-nero'>
							<div className='d-flex'>
								<div className={`d-flex flex-column health-score mr-3 justify-content-end  bg-muted`}>
									<h5 className='m-0 text-white'>&nbsp;</h5>
									<small className='fs-12 badge badge-primary rounded-0 mt-1'>Health</small>
								</div>
								<div className='align-self-center text-white'>
									<table>
										<tbody>
											<tr>
												<td className='text-right pr-2 align-top'>{state.selectedTableRow.provider ? state.selectedTableRow.provider.toUpperCase() : ''}&nbsp;: </td>
												<td>
													{state.selectedTableRow.region === 'NA' ? 
														'Global' 
													: state.regions && state.selectedTableRow && state.selectedTableRow.region ? 
														getRegionName(state.selectedTableRow.region, state.regions)
													: 
														state.selectedTableRow && state.selectedTableRow.region
													}
												</td>
											</tr>
											<tr>
												<td className='text-right pr-2 align-top'>Account&nbsp;: </td>
												<td>
													{state.accounts && state.selectedTableRow && state.selectedTableRow.account_id ? 
														getAccountNameFromId(state.selectedTableRow.account_id, state.accounts)
													: 
														state.selectedTableRow && state.selectedTableRow.account_id
													}
												</td>
											</tr>
											<tr>
												<td className='text-right pr-2 align-top'>Resource&nbsp;: </td>
												<td>{state.selectedTableRow.resource_type}</td>
											</tr>
											<tr>
												<td className='text-right pr-2 align-top'>Service&nbsp;: </td>
												<td>{state.selectedTableRow.service_name}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<span className='badge badge-pill badge-secondary p-2 mt-2 text-break text-wrap text-white font-weight-bold'>
								{state.selectedTableRow && state.selectedTableRow.asset_name}
							</span>
						</div>
						<div className='detail-content bg-backgroundGray'>
							{state.showDetailsSection ? (
								<DetailsSection
									selectedTableRow={state.selectedTableRow}
									selectedTab={state.selectedTab}
									detailsTabNames={state.detailsTabNames}
									detailsTabItemsToShow={state.detailsTabItemsToShow}
									showDetailsTabListDropdown={state.showDetailsTabListDropdown}
									selectedDetailsTab={state.selectedDetailsTab}
									selectedRowAssetDetails={state.selectedRowAssetDetails}
									selectedRowMonitoringSnapshot={state.selectedRowMonitoringSnapshot}
									selectedRowBillingDetails={state.selectedRowBillingDetails}
									showDetailsAssetSectionLoading={state.showDetailsAssetSectionLoading}
									showDetailsRelatedIssueLoading={state.showDetailsRelatedIssueLoading}
									showDetailsBillingSectionLoading={state.showDetailsBillingSectionLoading}
									showDetailsMonitoringSectionLoading={state.showDetailsMonitoringSectionLoading}
									showDetailsEventsSectionLoading={state.showDetailsEventsSectionLoading}
									toggleDetailsSectionLoading={(from, bool) => handleLoadingToggle(from, bool)}
									getAssetDetails={item => getAssetDetailsFunction(item)}
									openCloseModal={(item, relatedIssueDurtaion) => openCloseModal(item, relatedIssueDurtaion)}
									getAssetEvents={(item, duration) => getAssetEvents(item, duration)}
									eventSeries={state.eventSeries}
									eventOptions={state.eventOptions}
									dayWiseCountGraphData={state.dayWiseCountGraphData}
									recentSeries = {state.recentSeries}
									recentOptions = {state.recentOptions}
									setSelectedDetailsTabIndex={index => setState(prevState => ({ ...prevState, selectedDetailsTab: index }))}
									relatedIssuesData={state.relatedIssuesData}
									filteredRelatedIssuesData={state.filteredRelatedIssuesData}
									getRelatedTableData={(category, selectedResourceStatus, status) => getRelatedTableData(category, selectedResourceStatus, status)}
									topEventsData={state.topEventsData}
									recentEventsData={state.recentEventsData}
									eventGraphData={state.eventCountData}
									relatedDurtaion={state.relatedDurtaion}
									categoryCounts={state.categoryCounts}
								/>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SearchResources
