/*************************************************
 * Collider
 * @exports
 * @file governanceReducer.js
 * @author Rajasekar // on 02/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import { ACTION_TYPES } from '../../actions/types'

const INITIAL_STATE = {
	rules: {},
	newRules: [],

	assetDetails: {},
	selectedTableRow: {},
	selectedFilters: {
		provider: '',
		service: '',
		priority: '',
		show: 'templates',
	},
	categoryList: [],
	resourceCount: [],
	providerCount: [],
	priorityCount: [],
	editRuleDetails: {},
	editNewRuleDetails: {},
	configurations: [],
	tagging: {},
	tagGroups: [],
	selectedTagGroup: {},
	tags: [],
	masterTags: [],
	editTagGroupDetails: {},
	tagGroupId: '',
	tagGroupConditions: [],
	tagConditionId: '',
	policies: {},
	appliesTagAssets: [],
	governancePropsDetails: {}
}

/**
 * To store governance details in redux store
 * @param {Object} state
 * @param {Object} action
 */
export const governanceReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ACTION_TYPES.SET_RULES_LIST:
			return {
				...state,
				rules: { ...action.payload },
			}
		case ACTION_TYPES.SET_NEW_RULES_LIST:
			return {
				...state,
				newRules: [ ...action.payload ],
			}

		case ACTION_TYPES.SET_POLICY_LIST:
			return {
				...state,
				policies: { ...action.payload },
			}
		case ACTION_TYPES.SET_POLICY_SELECTED_TABLE_ROW:
			return {
				...state,
				selectedPolicyTableRow: { ...action.payload },
			}
		case ACTION_TYPES.SET_GOVERNANCE_SELECTED_TABLE_ROW:
			return {
				...state,
				selectedTableRow: { ...action.payload },
			}
		case ACTION_TYPES.SET_SELECTED_GOVERNANCE_FILTER:
			return {
				...state,
				selectedFilters: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_CATEGORY_LIST:
			return {
				...state,
				categoryList: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_CATEGORY_LIST_NEW:
			return {
				...state,
				categoryListNew: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_PRIORITY_COUNT_LIST:
			return {
				...state,
				priorityCount: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_PROVIDER_COUNT_LIST:
			return {
				...state,
				providerCount: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_RESOURCE_COUNT_LIST:
			return {
				...state,
				resourceCount: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_LOGTYPE:
			return {
				...state,
				logTypeProps: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_BLOCKSTORE:
			return {
				...state,
				blockStoreProps: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_ENGINE_RDS:
			return {
				...state,
				engineRds: action.payload,
			}	
		case ACTION_TYPES.SET_GOVERNANCE_SIZING_EC2:
			return {
				...state,
				sizingEc2: action.payload,
			}
		case ACTION_TYPES.SET_EDIT_RULE_DETAILS:
			return {
				...state,
				editRuleDetails: action.payload,
			}
		case ACTION_TYPES.SET_NEW_EDIT_RULE_DETAILS:
			return {
				...state,
				editNewRuleDetails: action.payload,
			}
		case ACTION_TYPES.SET_GOVERNANCE_CONFIG_LIST:
			return {
				...state,
				configurations: action.payload,
			}
		case ACTION_TYPES.SET_ASSETS_BY_TAG_OPERATIONS:
			return {
				...state,
				tagging: {
					...state.tagging,
					assetsByTagOperations: action.payload,
				},
			}
		case ACTION_TYPES.SET_NON_COMPLAINT_ASSETS:
			return {
				...state,
				tagging: {
					...state.tagging,
					nonComplaintAssets: action.payload,
				},
			}
		case ACTION_TYPES.SET_TAG_COMPLIANCE_AGGREGATION_COUNT:
			return {
				...state,
				tagging: {
					...state.tagging,
					tagComplianceAggregationCount: action.payload,
				},
			}
		case ACTION_TYPES.SET_TAG_COMPLIANCE_TOTAL_COUNT:
			return {
				...state,
				tagging: {
					...state.tagging,
					complianceTotalCount: action.payload,
				},
			}
		case ACTION_TYPES.SET_TAG_OPERATION_COUNT:
			return {
				...state,
				tagging: {
					...state.tagging,
					operationCount: action.payload,
				},
			}
		case ACTION_TYPES.SET_APPLIED_TAGS_DAYWISE_COUNT:
			return {
				...state,
				tagging: {
					...state.tagging,
					appliedTagsDayWiseCount: action.payload,
				},
			}
		case ACTION_TYPES.SET_TAG_GROUPS:
			return {
				...state,
				tagGroups: action.payload,
			}
		case ACTION_TYPES.SET_TAG_GROUPS_SELECTED_TABLE_ROW:
			return {
				...state,
				selectedTagGroup: action.payload,
			}
		case ACTION_TYPES.SET_ALL_TAGS:
			return {
				...state,
				tags: action.payload,
			}
		case ACTION_TYPES.SET_ALL_MASTER_TAG_GROUPS:
			return {
				...state,
				masterTags: action.payload,
			}
		case ACTION_TYPES.SET_EDIT_TAG_GROUPS_DETAILS:
			return {
				...state,
				editTagGroupDetails: action.payload,
			}
		case ACTION_TYPES.SET_TAG_GROUP_ID:
			return {
				...state,
				tagGroupId: action.payload,
			}
		case ACTION_TYPES.SET_ASSET_TAG_GROUP_CONDITION_ID:
			return {
				...state,
				tagConditionId: action.payload,
			}

		case ACTION_TYPES.SET_ASSET_TAG_GROUP_CONDITIONS:
			return {
				...state,
				tagGroupConditions: action.payload,
			}
		case ACTION_TYPES.SET_APPLIED_TAG_ASSETS:
			return {
				...state,
				appliesTagAssets: action.payload,
			}
		case ACTION_TYPES.SET_TO_INITIAL_CREATE:
			return {
				...state,
				tagGroupId: '',
				tagGroupConditions: [],
				tagConditionId: '',
			}
		case ACTION_TYPES.SET_GOVERNANCE_PROPS_DETAILS:
			return {
				...state,
				governancePropsDetails: action.payload,
			}
		case ACTION_TYPES.SET_EDIT_TAG_GROUP_DETAILS_TO_INITIAL:
			return {
				...state,
				tags: [],
				masterTags: [],
				editTagGroupDetails: {},
				tagGroupId: '',
				tagGroupConditions: [],
				tagConditionId: '',
			}
		default:
			return state
	}
}
