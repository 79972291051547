import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import _ from 'lodash'
import { listWorkstationResults, getCatalogFilters } from '../../actions/CatalogAction'
import { convertBytes, convertSeconds, convertBits, countFormater, numberFormater, capitalizeFirstLetter } from '../../utils/utility'
import WorkstationSidePanel from './WorkstationSidePanel'

let searchWorstation = null

const Summary = (props) => {

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedProvider: props.selectedProvider,
        catalogType: props.selecedProvider && props.selecedProvider.toLowerCase(),
        selectedAccount: props.selectedAccount,
        selectedRegion: props.selectedRegion,
        emptyOptions: [],
        startRecord: 0, 
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const location = useLocation();
    const receivedData = location.state;
    const userMenu = useSelector(state => state?.filters?.userMenu || false);

    // const locationData = location.state || false
    const currentPath = location.pathname;

    useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.workstation_action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].workstation_action
                    }
				} else {
                    if(row.action) {
						actionsAllowed = row.action
					} else if(row.workstation_action) {
						actionsAllowed = row.workstation_action
					}
                }
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])


    // useEffect(() => {
    //     setState(prevState => ({ ...prevState, callListWorkstationResults : true }))
    // }, [])

    const handleCatalogTypeChange = (event) => {
        setState(prevState => ({ ...prevState, showLoading: true, listCatalogs: [], filteredCatalogs: [], nextCatalogToken: [], catalogType: event.target.value, callListWorkstationResults: true }))
    }

    useEffect(() => {
        let params = {}
        if(state.selectedProvider && state.selectedProvider !== '') {
            params.provider = state.selectedProvider.toLowerCase()
        }        
        dispatch(getCatalogFilters(params))
            .then((response) => {
                if(response) {
                    let results = response.results ? response.results : []
                    let mappings = response.mappings ? response.mappings : []                    
                    setState(prevState => ({ ...prevState, catalogTypes: mappings, catalogResponse: results, catalogType: state.selectedProvider.toLowerCase(),  callListWorkstationResults: true }))
                }
            })
    }, [state.selectedProvider, dispatch])

    useEffect(() => {
        if(state.resetFilter) {
            setState(prevState => ({ ...prevState, resetFilter: false }));
        }
    }, [state.resetFilter]);

    useEffect(() => {
        if(state.callListWorkstationResults) {
            setState(prevState => ({ ...prevState, callListWorkstationResults: false }))
            let params = {}
            params.summary =true
            if(state.selectedProvider && state.selectedProvider !== '') {
                params.provider = state.selectedProvider.toLowerCase()
            }
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount.filter(e => e !== "All")
            }
            
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion.filter(e => e !== "All")
            }
            
            if(props.subscribedRow && props.subscribedRow.workstation_id) {
                params.workstation_id = props.subscribedRow && props.subscribedRow.workstation_id
            }

            if(state.catalogType) {
                params.catalog_type = state.catalogType.toLowerCase()
            }

            if(state.searchText) {
                params.search_input = state.searchText
            }
            params.size = state.perPage

            if(state.nextCatalogToken && state.nextCatalogToken.length) {
                params.next_token = state.nextCatalogToken
            }

            if(state.selectedStatus) {
                params.status = state.selectedStatus
            }

            if(state.sort_order) {
                params.sort_order = state.sort_order
                params.sort_by = state.sort_by
            }

            params.metrics = false

    
            dispatch(listWorkstationResults(params))
                .then((response) => {
					if(response) {
                        
                        let totalResults = [] 
                        let startRecord = state.startRecord
                        let currentPage = state.currentPage 
                        if(state.nextCatalogToken && state.nextCatalogToken.length) {
                            totalResults = state.workstationList &&  state.workstationList.length ? state.workstationList : []
                            startRecord = startRecord + state.perPage;
                            currentPage = currentPage+ 1
                        }

                        if(response.results && response.results.length) {
                            totalResults = totalResults.concat(response.results)
                            // if(state.nextCatalogToken && state.nextCatalogToken.length) {
                            //     setState(prevState => ({ ...prevState, startRecord: state.startRecord + state.perPage }))
                            // }
                        }

                        let totalPages = 1                
                        if(totalResults && totalResults.length > state.perPage) {
                            totalPages = Math.ceil(totalResults.length / state.perPage)
                        }

                        let json = response.workstation_details ? response.workstation_details : []

                        let detailsFormat = _.orderBy(json, ['order'], ['asc'])

                        setState(prevState => ({ ...prevState, statusSection: response.total_count ? response.total_count : {}, workstationList: totalResults, filterdWorkStationList: totalResults, totalRecords: response.total ? response.total : 0, selectedFilters: params, detailsFormat, showLoading: false, callFormGraph: true, totalPages, startRecord, currentPage, nextCatalogToken: response.next_token }));
                    }
                })
        }
    }, [state.callListWorkstationResults, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion, receivedData, state.workstationList, state.nextCatalogToken, props, state.startRecord, state.currentPage, state.perPage, state.catalogType, state.searchText, state.selectedStatus, state.sort_by, state.sort_order])

    // Function to navigate between pages
    const navigatePage = (action, currentPage) => {
        // Update startRecord and currentPage based on action
        let startRecord = state.startRecord
        if(action === 'next' && currentPage === state.totalPages && state.nextCatalogToken && state.nextCatalogToken.length) {
            setState(prevState => ({ ...prevState, showLoading: true, callListWorkstationResults: true }))
        } else if(action === 'next' && currentPage !== state.totalPages) {
            startRecord = startRecord + state.perPage;
            setState(prevState => ({ ...prevState, currentPage: currentPage+ 1 }));
        } else if(action === 'previous' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: currentPage - 1 }));
            startRecord = startRecord - state.perPage;
        } else if(action === 'start' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: 1 }));
            startRecord = 0;
        } else if(action === 'end' && currentPage !== 0) {
            setState(prevState => ({ ...prevState, currentPage: state.totalPages }));
            startRecord = (state.totalPages - 1) * state.perPage;
        }
        setState(prevState => ({ ...prevState, startRecord }))
    }

    // const gotoPage = (pageNumber) => {
    //     // Go to the specified page and update state        
    //     if(pageNumber > 0  && pageNumber <= state.totalPages) {
    //         let startRecord = (pageNumber - 1) * state.perPage
    //         setState(prevState => ({ ...prevState, currentPage: pageNumber, pageNumber, startRecord }))
    //     } else  if(pageNumber > state.totalPages) {
    //         setState(prevState => ({ ...prevState, pageNumber: state.currentPage }))
    //     } else {
    //         setState(prevState => ({ ...prevState, pageNumber }))
    //     }
    // }

    const getGrapUnitValue = useCallback((val ,unit, valueType) => {
        if(val !== '' && unit !== '') {
            if(unit === 'Percent') {
                if(valueType === "round") {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
                } else {
                    val = (val > 1 || val < 0) ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
                }
                val = val + '%';
            } else if(unit === 'Bytes' || unit === 'Kilobytes' || unit === 'Megabytes' || unit === 'Gigabytes' || unit === 'Terabytes' || unit === 'Bytes/Second' || unit === 'Kilobytes/Second' || unit === 'Megabytes/Second' || unit === 'Gigabytes/Second' || unit === 'Terabytes/Second') {
                val = convertBytes(val, unit, valueType)
            } else if(unit === 'Seconds' || unit === 'Microseconds' || unit === 'Milliseconds' || unit === 'Bits/Second' || unit === 'Kilobits/Second' || unit === 'Megabits/Second' || unit === 'Gigabits/Second' || unit === 'Terabits/Second') {
                val = convertSeconds(val, unit, valueType)
            } else if(unit === 'Bits' || unit === 'Kilobits' || unit === 'Megabits' || unit === 'Gigabits' || unit === 'Terabits') {
                val = convertBits(val, unit, valueType)
            } else if(unit === 'Count') {
                // val = val > 1 ? parseInt(val) : (!val ? val : parseFloat(val).toFixed(2))
                val = val ? Math.ceil(val) : 0
                val = countFormater(val)
            } else {
                val = parseInt(val)
            }
        } else if(val !== '') {          
            if(valueType === "round") { 
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(0))
            } else {
                val = val > 1 ? Math.ceil(val) : (!val ? val : parseFloat(val).toFixed(2))
            }
        }
        return val
    }, [])

    useEffect(() => {
        if(state.callFormGraph) {
            let graphDetails = state.filterdWorkStationList ? state.filterdWorkStationList : []
            if(graphDetails && graphDetails.length) {
                graphDetails.forEach(row => {
                    if(row.metrics && Object.entries(row.metrics).length) {
                        let graph = row.metrics
                        let graphData = graph.data
                        let graphLabels = graph.dates                

                        let cpuRow = {}
                        let cpuSum = 0

                        let memoryRow = {}
                        let memorySum = 0
                        
                        let diskRow = {}
                        let diskSum = 0

                        let networkRow = []
                        let networkAvg = []

                        Object.entries(graphData).forEach(([key, item]) => {                    
                            let avgData = {}
                            if(item.statistics) {
                                if(item.statistics.avg) {
                                    if(item.unit) {
                                        // avgData.avg = item.statistics.avg 
                                        avgData.avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                    } else {
                                        avgData.avg = item.statistics.avg
                                    }
                                }
                                if(item.statistics.max) {
                                    if(item.unit) {
                                        avgData.max = getGrapUnitValue(item.statistics.max, item.unit, "round")
                                    } else {
                                        avgData.max = item.statistics.max
                                    }
                                }
                            }
                            if(item.category === "CPU") {
                                let data = []                        
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        cpuSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                cpuRow.data = data
                                cpuRow.labels = graphLabels
                                
                                setState(prevState => ({ ...prevState, ['statistics_cpu_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Memory") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        memorySum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                memoryRow.data = data
                                memoryRow.labels = graphLabels
                                setState(prevState => ({ ...prevState, ['statistics_memory_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Disk") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        diskSum += val
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }    
                                diskRow.data = data
                                diskRow.labels = graphLabels

                                setState(prevState => ({ ...prevState, ['statistics_disk_'+row.workstation_id]: avgData }))
                            } else if(item.category === "Network") {
                                let data = []
                                if(item.values && item.values.length) {
                                    item.values.forEach(val => {
                                        if(item.unit) {
                                            val = getGrapUnitValue(val, item.unit)
                                        }
                                        data.push(val)
                                    })
                                }
                                
                                let newData = networkRow && networkRow.data ? networkRow.data : {}
                                newData[key] = data
                                
                                networkRow.data = newData
                                networkRow.labels = graphLabels
        
                                if(item.statistics) {
                                    if(item.statistics.avg) {
                                        let avg = 0
                                        if(item.unit) {
                                            avg = getGrapUnitValue(item.statistics.avg, item.unit, "round")
                                        } else {
                                            avg = item.statistics.avg
                                        }
                                        networkAvg.push(avg)
                                    }
                                }
        
                                setState(prevState => ({ ...prevState, ['statistics_network_'+row.workstation_id]: networkAvg }))
                            }
                        })
                        if(cpuSum > 0 && cpuRow && Object.entries(cpuRow).length) {
                            setState(prevState =>  ({ ...prevState, ['cpu_'+row.workstation_id]: cpuRow }))
                        }
                        if(memorySum > 0 && memoryRow && Object.entries(memoryRow).length) {
                            setState(prevState =>  ({ ...prevState, ['memory_'+row.workstation_id]: memoryRow }))
                        } 
                        if(diskSum > 0 && diskRow && Object.entries(diskRow).length) {
                            setState(prevState =>  ({ ...prevState,['disk_'+row.workstation_id]: diskRow }))
                        }
                        if(networkRow && Object.entries(networkRow).length) {
                            setState(prevState =>  ({ ...prevState,['network_'+row.workstation_id]: networkRow }))
                        }
                    }
                })
            }

        }
    }, [state.callFormGraph, state.filterdWorkStationList, getGrapUnitValue])
    
    return (
        state.showLoading ?
            <div className='d-flex justify-content-center m-4'>
                <Spinner className='text-center' color='light' />
            </div>
        :
            <React.Fragment>
            {state.selectedDetails && Object.entries(state.selectedDetails).length ? 
                <WorkstationSidePanel 
                    closeSidePanel={(string) => setState(prevState => ({...prevState, selectedDetails: {}, selectedAction: "", callListWorkstationResults: string === 'refresh' ? true : false, nextCatalogToken: string === 'refresh' ? [] : state.nextCatalogToken, showLoading: false }))}
                    selectedDetails={state.selectedDetails} 
                    selectedAction={state.selectedAction}
                    detailsFormat={state.detailsFormat}
                />
            : null}
            <div className='p-2 flex-grow-1 overflow-auto'>
                <div className='d-flex justify-content-center'>
                    {Object.entries(state.statusSection).map(([key, value], index) => {
                        return(
                            <div key={'key__'+index}>
                                <div className={`dash-box border-${(key.toLowerCase() === "failed" || key.toLowerCase() === "rejected") ? "danger" : (key.toLowerCase() === "stopped" || key.toLowerCase() === "hibernated") ? "yellow" : key.toLowerCase() === "requested" ? 'warning' : key.toLowerCase() === "approved" ? 'success' :  "info"} bg-${state.selectedStatus === key ? ((key.toLowerCase() === "failed" || key.toLowerCase() === "rejected") ? "danger" : (key.toLowerCase() === "stopped" || key.toLowerCase() === "hibernated") ? "yellow" : key.toLowerCase() === "requested" ? 'warning' : key.toLowerCase() === "approved" ? 'success' :  "info") : 'transparent'} text-${state.selectedStatus !== key ? ((key.toLowerCase() === "failed" || key.toLowerCase() === "rejected") ? "danger" : (key.toLowerCase() === "stopped" || key.toLowerCase() === "hibernated") ? "yellow" : key.toLowerCase() === "requested" ? 'warning' : key.toLowerCase() === "approved" ? 'success' :  "info") : 'white'} cursor-pointer`} onClick={() => setState(prevState => ({ ...prevState, selectedStatus: state.selectedStatus !== key ? key : '', nextCatalogToken: [], startRecord: 0, currentPage: 1, showLoading: true, callListWorkstationResults: true }))} >
                                    <p className='mb-0'>{numberFormater(value)}</p>
                                    <small>{capitalizeFirstLetter(key)}</small>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='d-flex justify-content-between'>
                    <p className='mb-0 align-self-center'>
                        <small>Showing {state.filterdWorkStationList && state.filterdWorkStationList.length} out of total {state.totalRecords} workstation(s)</small>
                        {state.selectedFilters && state.selectedFilters.provider ?
                            <React.Fragment>
                            <small className='ml-1'>obtained from the</small>
                            <small className='ml-1 text-info'>{state.selectedFilters.provider.toUpperCase()}</small>
                            <small className='ml-1'>provider</small>
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.account_id && state.selectedFilters.account_id.length ?
                            <React.Fragment>
                            <small className='ml-1'>with the account</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.account_id[0]}</small>
                            {state.selectedFilters.account_id.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            </React.Fragment>
                        : null}
                        {state.selectedFilters && state.selectedFilters.region && state.selectedFilters.region.length ?
                            <React.Fragment>
                            <small className='mx-1'>in the</small>
                            <small className='badge badge-outline-info mr-2'>{state.selectedFilters.region[0]}</small>
                            {state.selectedFilters.region.length > 1 ?
                                <small className='ml-2 text-info'>more</small>
                            : null}
                            <small className='ml-1'>region</small>
                            </React.Fragment>
                        : null}
                    </p>
                    <div className='w-50'>
                        <div className='d-flex mr-2 justify-content-end w-100 mb-1'>
                            <div className={`transparentSelectBorderBottom align-self-end mr-2`}>
                                <select className="form-control" onChange={handleCatalogTypeChange} value={state.catalogType}>
                                    {/* <option key="All" value="All">ALL</option> */}
                                    {state.catalogTypes && state.catalogTypes.map((cat) => {
                                        return(
                                            <option key={cat.value} value={cat.value}>{cat.label}</option>
                                        )
                                    })}
                                </select>
                            </div>
                            {state.workstationList && state.workstationList.length ?
                                <div className='w-50'>
                                    <div className='d-flex justify-content-end w-100'>
                                        <div className='d-flex w-100 border-lightGray rounded-5'>
                                            <div className={`input-group-prepend w-10`}>
                                                <div className={`input-group-text bg-transparent`}>
                                                    <i className={`fal fa-search cursorPointer text-lightGray f12`} />
                                                </div>
                                            </div>
                                            <div className={`input-enclose w-90`}>
                                                <input
                                                    value={state.searchText}
                                                    onChange={e => {
                                                        setState(prevState => ({ ...prevState, searchText: e.target.value }))
                                                        clearTimeout(searchWorstation)
                                                        if(e.target.value.length > 2) {
                                                            searchWorstation = setTimeout(() => { setState(prevState => ({ ...prevState, callListWorkstationResults: true, nextCatalogToken: [], showLoading: true }))}, 2000); 
                                                        } else if(!e.target.value.length) {
                                                            setState(prevState => ({ ...prevState, callListWorkstationResults: true, showLoading: true }))
                                                        }
                                                    }}
                                                    type='text'
                                                    className={`form-control bg-transparent pl-2 form-control-sm f12 text-white border-lightGray-left border-0`}
                                                    placeholder='Search'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : null}
                        </div>
                        {state.workstationList && state.workstationList.length ? 
                            <div className='d-flex justify-content-end w-100 mb-1'>
                                {state.totalRecords > state.perPage ?
                                    <div className='pagination errorPagePagination f12 mr-2'>
                                        <span className='mx-3 text-nowrap'>Page <strong>{state.currentPage} of {Math.ceil(state.totalRecords / state.perPage)}</strong> </span>
                                        <button><i className={`fal fa-arrow-to-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('start', state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-angle-left cursorPointer ${state.currentPage === 1 ? 'disabled text-muted' : 'text-info'}`} onClick={() => navigatePage('previous', state.currentPage)}></i></button> 
                                        <span className='displayNone'>
                                            Go to
                                            <input
                                                type='number'
                                                value={state.pageNumber || state.pageNumber === '' ? state.pageNumber : state.currentPage}
                                                onChange={e => {
                                                    const page = e.target.value ? Number(e.target.value) : ''
                                                    this.gotoPage(page)
                                                }}
                                                className='ml-1 inputClass'
                                            />
                                        </span>
                                        <button><i className={`fal fa-angle-right cursorPointer ${state.currentPage === state.totalPages ? (state.nextCatalogToken ? 'text-info' : 'text-muted') : 'text-info'}`} onClick={() => navigatePage('next', state.currentPage)}></i></button> 
                                        <button><i className={`fal fa-arrow-to-right cursorPointer ${state.currentPage === state.totalPages ? 'text-muted' : 'text-info'}`} onClick={() => navigatePage('end', state.currentPage)}></i></button>
                                    </div>
                                : null}
                            </div>
                        : null}
                    </div>
                </div>
                <div className='table-responsive my-2'>
                    <table className='table table-striped mb-0'>
                        <thead className='text-white bg-darkGray'>
                            <tr>
                                <th className='f16 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'workstation_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'workstation_name', nextCatalogToken: [], callListWorkstationResults: true }))}> 
                                    {state.sort_by === 'workstation_name' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Name
                                </th>
                                <th className='f16 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'status' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'status', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'status' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Status
                                </th>
                                <th className='f16 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'instance_type' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'instance_type', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'instance_type' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Capacity
                                </th>
                                <th className='f16 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'asset_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'asset_name', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'asset_name' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    Asset
                                </th>
                                <th className='f16 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'public_ip_address' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'public_ip_address', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'public_ip_address' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    public IP/Private IP
                                </th>
                                {/* <th className='f16'>Storage</th> */}
                                <th className='f16 cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, sort_order: state.sort_by === 'user_name' ? (state.sort_order === 'asc' ? 'desc' : (state.sort_order === 'desc' ? '' : 'asc')) : 'asc', sort_by: 'user_name', nextCatalogToken: [], callListWorkstationResults: true }))}>
                                    {state.sort_by === 'user_name' && (state.sort_order === 'asc' ? <i className='fa fa-caret-down mt-1 mr-1'/> : state.sort_order === 'desc' ? <i className='fa fa-caret-up mt-1 mr-1'/> : null)}
                                    User
                                </th>
                                <th className='f16'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='bg-dark3 text-white'>
                            {state.filterdWorkStationList && state.filterdWorkStationList.length ?
                                state.filterdWorkStationList.slice(state.startRecord, state.startRecord + state.perPage).map((row, index)=> {
                                    return(
                                        <tr className={`${!index ? '' : 'border-darkGray-top'}`}>
                                            <td calss="text-white">{row.workstation_name}</td>
                                            <td>
                                                <span className={`badge badge-outline-badge badge-outline-${(row.status === "failed" || row.status === "rejected") ? "red" : (row.status === "stopped" || row.status === "hibernated") ? "yellow" : row.status === "requested" ? "warning" : row.status === "approved" ? "success" : "info"} text-${(row.status === "failed" || row.status === "rejected") ? "red" : (row.status === "stopped" || row.status === "hibernated") ? "yellow" : row.status === "requested" ? "warning" : row.status === "approved" ? "success" : "info"}`}>{capitalizeFirstLetter(row.status)}</span>
                                            </td>
                                            
                                            <td className="text-white">
                                                {row.instance_type}
                                                <div className='d-flex'>
                                                    ({row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                    <span className='ml-1'>{row.vcpus} vCPU,</span>
                                                    <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>)
                                                </div>                                                
                                                {row.volume_details && row.volume_details.length && row.volume_details[0].volume_size ?
                                                    <span className='text-white'>Storage {row.volume_details[0].volume_size} GB</span>
                                                : null}
                                            </td>
                                            <td>
                                                <span className='text-white'>{(row.id_name_map && !row.id_name_map.key && row.id_name_map.value ? row.id_name_map.value : "")}
                                                    {row.id_name_map && row.id_name_map.key ?
                                                        (<span className={`ml-1 text-info mb-0`}>
                                                        {row.id_name_map.key }</span>)
                                                    : null}
                                                </span>
                                            </td>
                                            <td className="text-white">
                                                <div className='mb-1'>{row.public_ip_address}</div> 
                                                <div className=''>
                                                    {row.public_ip_address && row.private_ip_address ? ' / ' : ''}
                                                    {row.private_ip_address}
                                                </div>
                                            </td>
                                            {/* <td className="text-white displayNone">
                                                <div className='d-flex'>
                                                    {row.gpu ? <span>{row.gpu+" GPU, "}</span> : null}
                                                    <span className='ml-1'>{row.vcpus} vCPU,</span>
                                                    <span className='ml-1'>{row.memory} {row.memory_unit ? row.memory_unit : 'GB'} </span>
                                                    {row.volume_details && row.volume_details.length && row.volume_details[0].volume_size ?
                                                        <span className='text-white ml-2'>({row.volume_details[0].volume_size} GB)</span>
                                                    : null}
                                                </div>
                                            </td> */}
                                            <td className='w-20'>
                                                <div className='d-flex flex-wrap gap-2'>
                                                    {Array.isArray(row.user_name) ?
                                                        row.user_name.map((usr, usrIndex) => {
                                                            return(
                                                                <p className='text-white mb-0'>{usr + (usrIndex+1 !== row.user_name.length ? ', ' : '')}</p>
                                                            )
                                                        })
                                                    :
                                                        <span className='text-white'>{row.user_name}</span>
                                                    }
                                                        
                                                    {/* {Array.isArray(row.user_name) && row.user_name.length > 0 ?
                                                        <span className='text-info ml-2'>more</span>
                                                    : null} */}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-between align-self-center">
                                                    <span className='text-info cursor-pointer' onClick={() => setState(prevState => ({ ...prevState, selectedDetails: row }))}>Details</span>
                                                </div>
                                            </td>
                                        </tr>
                                    )

                                })
                            : 
                                <tr>
                                    <td colSpan={8} className="text-center">
                                        <p className='mb-0'>There are no data on this criteria. Please try adjusting your filter.</p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            </React.Fragment>
    );
};
export default Summary