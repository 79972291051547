/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Tvastar
 * @exports
 * @file LandingPage.js
 * @author Prakash // on 05/02/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 * 
 *************************************************/
import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
// import { setAiopsPropsDetails } from '../../../actions/aiops/AiopsAction'
import { setCommonPropsDetails } from '../../../actions/commonActionNew'
import { getAlertsDailyTrend } from '../../../actions/Collider/ObservabilityAction'

import { momentDateGivenFormat, momentConvertionLocalToUtcTime } from '../../../utils/utility'
import ApexBarChart from '../../common/charts/ApexBarChart';
import AlertsDashboard from './AlertsDashboard';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import FilterSection from '../../common/FiltersSection';

const LandingPage = () => {
    const clickOutside = useRef() 
    
    const [state, setState] = useState({
        pageType: "Events",
        selectedDuration: 'currentMonth',
        topSectionFilters: ['provider', 'project', 'account', 'region', 'duration'],
    })

    const dispatch = useDispatch(); // Create a dispatch function
    const providers = useSelector(state => state?.filters?.providers || false);

    useEffect(() => {
        if(state.showLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState,  showLoading: false })) }, 5000);
        }
    }, [state.showLoading])
            
    const handleSelectedFilters = (filter) => {
        setState(prevState => ({ 
            ...prevState,
            selectedProvider: filter.selectedProvider,
            selectedProject: filter.selectedProject,
            selectedProjectTags: filter.selectedProjectTags,
            selectedAccount: filter.selectedAccount ? filter.selectedAccount : [],
            selectedRegion: filter.selectedRegion ? filter.selectedRegion : [],
            datePickerStartDate: filter.datePickerStartDate,
            datePickerEndDate: filter.datePickerEndDate,
            callSearch: true,
            showLoading: true
        }))
    }

    const onReset = () => {
        setState(prevState => ({ ...prevState, selectedProvider: providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : "", selectedProject: "", selectedProjectTags: [], selectedAccount: [], selectedRegion: [], callSearch: true }));
    }

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({ ...prevState, alertSeries: [],  alertOptions: {}, showLoading: true, callAlertsSection: true, callSearch: false
            }))
    
            let obj = {}
            obj.start_time = state.datePickerStartDate
            obj.end_time = state.datePickerEndDate
            obj.selectedProvider = state.selectedProvider
            obj.selectedProjectTags = state.selectedProjectTags
            obj.selectedAccount = state.selectedAccount
            obj.selectedRegion = state.selectedRegion
            dispatch(setHpcPropsDetails('hpcAlertPageFilters', obj))
            dispatch(setHpcPropsDetails('alertDetails', {}))

            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
            
        }
    }, [dispatch, state.callSearch, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedProjectTags, state.selectedProvider, state.selectedRegion])

    useEffect(() => {
        if(state.callAlertsSection) {
            setState(prevState => ({ ...prevState, callAlertsSection: false }))
            let params = {}
            params.provider = state.selectedProvider.toLowerCase();    
            if(state.selectedProjectTags && state.selectedProjectTags.length) {
                params.project_tags = state.selectedProjectTags
            }        
            if(state.selectedAccount && state.selectedAccount.length) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length) {
                params.region = state.selectedRegion
            }
            params.start_time = momentConvertionLocalToUtcTime(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss')
            params.end_time = momentConvertionLocalToUtcTime(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
            dispatch(getAlertsDailyTrend(params))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.results ? response.results : {}
                        setState(prevState => ({ ...prevState, alertsDailyTrend: results }))
                    }
                }) 
            }
    }, [dispatch, state.callAlertsSection, state.datePickerEndDate, state.datePickerStartDate, state.selectedAccount, state.selectedProjectTags, state.selectedProvider, state.selectedRegion])

    useEffect(() => {
        let graphDetails = state.alertsDailyTrend
        let eventCount = 0
        if(graphDetails && graphDetails.data && graphDetails.data.count) {
            eventCount = graphDetails.data.count.reduce((a, b) => a + b, 0)   
        }

        let graphData = {}
        if(graphDetails && graphDetails.labels) {
            graphData.labels = graphDetails.labels
            graphData.data = graphDetails.data.count ? graphDetails.data.count : []
        }

        setState(prevState => ({ ...prevState, eventCount, graphData }))
    }, [state.alertsDailyTrend, state.datePickerStartDate, state.datePickerEndDate])
    
    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (clickOutside.current && !clickOutside.current.contains(event.target)) {
            dispatch(setCommonPropsDetails('clickedonParent', true))
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                </div>
            </div>
            <div className='row text-white mx-0 pb-2 bg-nero'>
                <div className='col-lg-3 col-md-12 col-sm-12 py-2 align-self-center'>
                    <h6 className='text-white m-0'>Alerts</h6>
                    <p className='text-white m-0'>Consolidated view of hpc alerts</p>
                </div>
                <div className='col-lg-9 col-9 py-2' ref={clickOutside}>
                    <div className='row mx-0 justify-content-end'>
                        <FilterSection
                            filters={state.topSectionFilters}
                            topFilterOptions= {state.topFilterOptions}
                            selectedFilters={(filter) => handleSelectedFilters(filter)}
                            clickedonParent={state.clickedonParent}
                            filterAdvancedSearch={false}
                            onClickAdvancedSearch={() => setState(prevState => ({ ...prevState, showAdvancedFilters: true }))}
                            onReset={onReset}
                        />
                    </div>
                </div>
            </div>
            <div className='p-2'>
                <div className="rounded bg-dark p-3">
                    <div className="d-flex justify-content-between">
                        <p className="mb-1">Showing data for 
                            <span className="text-info ml-1">
                                {state.datePickerStartDate ?  momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY HH:mm') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY HH:mm')  : ""}
                            </span>
                            <span className="ml-2">(local time)</span>
                        </p>
                    </div>
                    <div className={`d-flex w-100 ${!state.minimizeGraphSection && state.alertSeries && state.alertSeries.length ? '' : 'mt-4'} ${(state.minimizeGraphSection && state.pageType !== "Events" ? "displayNone" : "") }`}>
                        <div className="w-5 d-flex justify-content-end">
                            <p className="mb-0 align-self-center pr-3 mt-n2">Alerts</p>
                        </div>

                        <div className="w-80">
                            {state.graphData && Object.entries(state.graphData).length ?
                                <ApexBarChart
                                    graphData={state.graphData}
                                    sparkline={false}
                                    yaxis={false}
                                    yaxisLabel={false}
                                    xaxis={false}
                                    xaxisFormat={'datetime'}
                                    xaxisLabel={true}
                                    graidXaxisBorder={true}
                                    showTitle={true} //xaxis title
                                    axisLabelColor={'#666666'}
                                    paddingLeft={0}
                                    legend={false}
                                    legendPostion={'bottom'}
                                    legendHorizontalAlign={'center'}
                                    legendUseSeriesColor={true}
                                    stacked={false}
                                    height={80}
                                    horizontal={false}
                                    // barHeight={'40%'}
                                    barEndShape={'flat'}
                                    // columnWidth={'5%'}
                                    gradient={false}
                                    gradientColor={['#009fdf', '#CD5A9F']}
                                    hideTooltipValue={true}
                                    backgroundBarShape={'rounded'}
                                    backgroundBarColors={['#333947']}
                                    showBackgroundBarColors={false}
                                    className={'transparentTooltip'}
                                    dataLabels= {false}
                                    dataLabelsTextAnchor = {'end'}
                                    dataLabelPosition={'right'}
                                    colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                    dataLabelsOffsetX={0}
                                    chartDistributed={true}
                                    dataPointSelection={false}
                                />
                            : null}
                        </div>
                        <div className="d-flex pl-2 w-15 align-self-center">
                            {/* <small className="mb-0 text-purple-2">Total: </small> */}
                            <small className="ml-2 mb-0 text-white f14">{state.eventCount && state.eventCount}</small>
                        </div>
                    </div>
                </div>
                <AlertsDashboard 
                    loading={() => this.setState({ showLoading: false })}
                    onChangePageType={(pageType) => this.onChangePageType(pageType)}
                    pageTypes={'Alerts'}
                />
            </div>
        </div>
    )
}

export default LandingPage