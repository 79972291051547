/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file RecommendationsLeftSection.js
 * @author Prakash // on 04/02/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders, listAllAccounts, listAllRegions, setCommonPropsDetails } from '../../../actions/commonActionNew'
import _ from 'lodash'
import Select from 'react-select';
import MultiSelectSection from '../../common/MultiSelectSection';

// import Search from '../../common/SearchComponent'
import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import { momentConvertionUtcToLocalTime, capitalizeFirstLetter, getAccountNameFromId, getRegionName } from '../../../utils/utility'
import { setCostPropsDetails, getCostRecommendations } from  '../../../actions/cost/CostAction'
import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable'
import { useCallback } from 'react';


const RecommendationsLeftSection = () => {
    const accountRef = useRef();
    const regionRef = useRef();
    
    const [state, setState] = useState({
        minMaxHeight: "700px",
        expandMenuId: "",
        activeCollapseMenu: "",
        showLoading: true,
        recommendationType: "All",

        startRecord: 0,
        perPage: 1,
        currentPage: 1,

        activeTab: "Consolidated",
        showBy: "Resource"
    })

    const providers = useSelector(state => state?.filters?.providers || false);
	const propAccounts = useSelector(state => state?.filters?.commonPropsDetails?.propAccounts || false);
    const propRegions = useSelector(state => state?.filters?.commonPropsDetails?.propRegions || false);

	const dispatch = useDispatch(); // Create a dispatch function

	useEffect(() => {
        // Use the setState setter function to update the state
        if(providers) {
            setState(prevState => ({
                ...prevState,
                providers: providers,
                // selectedProvider: prevState.selectedProvider ? prevState.selectedProvider.toLowerCase() : (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name).toLowerCase(),
                selectedProvider: prevState.selectedProvider && prevState.selectedProvider !== '' ? prevState.selectedProvider : (providers && providers.length ? (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name) : ""),
                callSearch: true
            }));
        } else {
            let params = {}
            dispatch(listAllProviders(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            providers: response,
                            selectedProvider: prevState.selectedProvider ? prevState.selectedProvider.toLowerCase() : (response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? 'AWS' : response[0].provider_name).toLowerCase() : ''),
                            callSearch: true
                        }));
                    }
            })
        }
        // Run the code inside this block once on component mount
    }, [dispatch, providers]); // Empty dependency array ensures this runs only once on mount

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider

            if(propAccounts && propAccounts.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, accounts: propAccounts[label] }));
            } else {
                dispatch(listAllAccounts(params))
                    .then((response) => {
                        if(response) {
                            if(response.length) {
                                let obj = propAccounts ? propAccounts : {}
                                obj[label] = response
                                dispatch(setCommonPropsDetails('propAccounts', obj))
                                setState(prevState => ({ ...prevState, accounts: response }));
                            }
                        }
                    })
            }
        }
    }, [state.selectedProvider, propAccounts, dispatch]);

    useEffect(() => {
        if (state.selectedProvider) {
            let params = {};
            params.provider = state.selectedProvider.toLowerCase();
            let label = state.selectedProvider
            if(propRegions && propRegions.hasOwnProperty(label)) {
                setState(prevState => ({ ...prevState, regions: propRegions[label] }));
            } else {
                dispatch(listAllRegions(params))
                    .then((response) => {
                        if(response && response.length) {
							let results = response[0]?.provider_regions || []
							let obj = propRegions ? propRegions : {}
							obj[label] = results
							dispatch(setCommonPropsDetails('propRegions', obj))
							setState(prevState => ({ ...prevState, regions: results }));
						}
                    })
            }
        }
    }, [state.selectedProvider, propRegions, dispatch]);

    // Call getFilterData whenever the providers state is updated
    useEffect(() => {
        if (state.callSearch) {
			let params = {}            
            params.provider = state.selectedProvider.toLowerCase();
            if(state.selectedAccount && state.selectedAccount.length && !state.selectedAccount.includes("All")) {
                params.account_id = state.selectedAccount
            }
            if(state.selectedRegion && state.selectedRegion.length && !state.selectedRegion.includes("All")) {
                params.region = state.selectedRegion
            }
            if(state.selectedProvider) {
                dispatch(getCostRecommendations(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, recommendedDetails: response, showLoading: false, expandMenuId: "", activeCollapseMenu: "", filteredResouceArray: response.checks ? response.checks : [], callSearch: false, formRecommendedData: true }))
                        }
                    })
                }
            } else {
                setState(prevState => ({ ...prevState, showLoading: false, callSearch: false }))
            }
    }, [state.callSearch, dispatch, state.selectedAccount, state.selectedProvider, state.selectedRegion]);

    const filterRecommendations = useCallback((type, selectedArray) => {
        let selectedRecommendationGroup = ""
        if(type !== "All") {
            if(type === state.selectedRecommendationGroup) {
                selectedArray = state.totalRecommendedData
            } else {
                selectedRecommendationGroup = type
            }
        }
        
        let observedRecommendations = []
        selectedArray.forEach((item, index) => {
            setState(prevState => ({ ...prevState, ["recommendation_label_"+index]: item.label, ["recommendation_data_"+index]: item.data, ["filtered_recommendation_data_"+index]: item.data }))
            let detailView = false
            if(item.data && item.data.length) {
                detailView = true
            }
            item.detailView = detailView
            observedRecommendations.push(item)
        })

        // observedRecommendations = _.orderBy(observedRecommendations, ["orderBy"], ["asc"])

        observedRecommendations = [
            ...observedRecommendations.filter(e => e.flag),
            ...observedRecommendations.filter(e => !e.flag && e.detailView),
            ...observedRecommendations.filter(e => !e.flag && !e.detailView),
        ];
        
        setState(prevState => ({ ...prevState, selectedRecommendationGroup, observedRecommendations, searchData: observedRecommendations, filteredObservedRecommendation: observedRecommendations }))
    }, [state.selectedRecommendationGroup, state.totalRecommendedData])

    useEffect(() => {
        if(state.formRecommendedData) {
            let groupedRecommendations = []
            let totalRecommendedData = []
            let tableViewData = []
            if(state.recommendedDetails && Object.entries(state.recommendedDetails).length) {
                let filteredData = state.recommendedDetails.checks.filter(e => e.data.length)  //remove the diagnotstics which has now data
                if(filteredData && filteredData.length) {
                    groupedRecommendations = _.chain(filteredData).groupBy("tag")
                    .map((value, key) => ({ 
                        label: key ? key : "Not Grouped", 
                        sub: _.orderBy(value, ["flag"], ["desc"]),
                        observedFlag: value.filter(e => e.flag).length 
                    })).value()
        
        
                    groupedRecommendations = _.orderBy(groupedRecommendations, ["observedFlag"], ["desc"])    
                }
        
                //for table View section
        
                state.recommendedDetails && state.recommendedDetails.checks && state.recommendedDetails.checks.forEach(row => {
                    if(row.data && row.data.length) {
                        row.data.forEach(item => {
                            item.label = row.label
                            item.tag = row.tag
                            // item.percentage_savings = row.percentage_savings
                            // item.price_unit = row.price_unit
                            // item.savings = row.savings
                            tableViewData.push(item)
                        })
                    }
                })
        
                //for table ViewSection
                
                if(groupedRecommendations && groupedRecommendations.length) {
                    totalRecommendedData = _.orderBy(filteredData, ["flag"], ["desc"])
                }
            }
        
            setState(prevState => ({ ...prevState, totalRecommendedData, groupedRecommendations, tableViewData, filteredTableArray: tableViewData, formRecommendedData: false }))

            filterRecommendations("All", totalRecommendedData)  
        }

    }, [state.formRecommendedData, state.recommendedDetails, filterRecommendations])

    useEffect(() => {
		let browserBorder = 100
		let headerHeight = 180
        let filterSection = 95

        let groupSection = 0
        if(state.groupedRecommendations && state.groupedRecommendations.length) {
            groupSection = 30
            let groupRowsHeight = Math.ceil(state.groupedRecommendations.length / 5)
            groupRowsHeight = 44 * groupRowsHeight

            groupSection = groupSection + groupRowsHeight
        }

        let outerHeight = (window.outerHeight - browserBorder - headerHeight - groupSection - filterSection) +"px"

		setState(prevState => ({ ...prevState, minMaxHeight: outerHeight }))
    }, [state.groupedRecommendations])

    useEffect(() => {
        if(state.activeTab === "Table") {
            filterTableResults()
        }
    }, [state.activeTab])

    const filterTableResults = (event) => {
        let filteredTableArray = state.tableViewData

        if(state.selectedRecommendationGroup && state.selectedRecommendationGroup !== "All") {
            filteredTableArray = filteredTableArray.filter(e => e.category === state.selectedRecommendationGroup)
        }

        if(state.searchTableText && state.searchTableText !== "") {
			filteredTableArray =
				filteredTableArray &&
				filteredTableArray.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.searchTableText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        filteredTableArray = _.orderBy(filteredTableArray, ['start_time'], ['desc'])

        setState(prevState => ({ ...prevState, filteredTableArray }))
    }

    useEffect(() => {
        filterTableResults()
        filteredResourceTableResults()
    }, [state.selectedRecommendationGroup, state.searchTableText])

    useEffect(() => {
        if(state.showBy === "Resource" || state.showBy === "Service" ) {
            filterTableResults()
            filteredResourceTableResults()
        }
    }, [state.showBy])

    useEffect(() => {
        if(state.filteredTableArray && state.filteredTableArray.length && state.activeTab === "Table" && state.showBy === "Resource") {
            onChangeRecommendationEvent(state.filteredTableArray[state.filteredTableArray.length-1].label, state.filteredTableArray[state.filteredTableArray.length-1].tag, state.filteredTableArray[state.filteredTableArray.length-1], 0, "", state.filteredTableArray[state.filteredTableArray.length-1].asset_id)
        }
    }, [state.filteredTableArray, state.activeTab, state.showBy])

    const filteredResourceTableResults = (event) => {
        let filteredResouceArray = state.recommendedDetails && state.recommendedDetails.checks ? state.recommendedDetails.checks : []

        if(state.selectedRecommendationGroup && state.selectedRecommendationGroup !== "All") {
            filteredResouceArray = filteredResouceArray.filter(e => e.tag === state.selectedRecommendationGroup)
        }

        if(state.searchTableText && state.searchTableText !== "") {
			filteredResouceArray =
				filteredResouceArray &&
				filteredResouceArray.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.searchTableText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        setState(prevState => ({ ...prevState, filteredResouceArray }))
    }

    useEffect(() => {
        if(state.filteredResouceArray && state.filteredResouceArray.length && state.activeTab === "Table" && state.showBy === "Service") {
            onChangeServiceRecommendationEvent(state.filteredResouceArray[state.filteredResouceArray.length-1].label, state.filteredResouceArray[state.filteredResouceArray.length-1].tag, state.filteredResouceArray[state.filteredResouceArray.length-1])
        }
    }, [state.filteredResouceArray, state.activeTab, state.showBy])

    useEffect(() => {
        filterResults()
    }, [state.searchText])

    const filterResults = () => {
        let recommendationType = state.recommendationType
        if(state.searchText) {
            recommendationType = state.searchText
        }
        let observedRecommendations = state.observedRecommendations

        if(recommendationType !== "All") {
            if(recommendationType === "observed") {
                observedRecommendations = observedRecommendations.filter(e => e.flag)
            } else {
                observedRecommendations = observedRecommendations.filter(e => !e.flag)
            }
        }

        if(state.searchText && state.searchText !== "") {
			observedRecommendations =
				observedRecommendations &&
				observedRecommendations.filter(item => {
					let isPresent = []
					isPresent = recursiveSearch(item, state.searchText).flat(5).filter(bool => bool === true)
					if (isPresent[0]) {
						return true
					} else {
						return false
					}
				})
        }

        setState(prevState => ({ ...prevState, recommendationType, filteredObservedRecommendation: observedRecommendations }))
    }

	// function to search every object in array by recurseive
	const recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == "object") {
				return recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === "array") {
				return item.map((arrayElement, i) => recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(props.searchFilter.includes(key)){
				return item[key] !== null
					? item[key]
							.toString()
							.toLowerCase()
							.indexOf(searchValue.toString().toLowerCase()) > -1
					: false
				// }else{
				// return false;
				// }
			}
		})
	}

    const expandCollapse = (expandMenuId, label, tag) => {
        if(state.activeCollapseMenu !== expandMenuId) {
            onClickExpandMenu(expandMenuId)
        } else {
            let obj = {}
            obj.label = "assets_list"
            obj.eventGroup = ""
            // obj.data = state.recommendedDetails ? state.recommendedDetails : []
            obj.data = []
            dispatch(setCostPropsDetails("selectedRecommendedEvent", obj))
        }
        setState(prevState => ({ 
            ...prevState, 
            expandMenuId: expandMenuId === state.expandMenuId ? "" : expandMenuId, 
            activeCollapseMenu: expandMenuId === state.expandMenuId ? "" : expandMenuId,
            startRecord: 0,
            currentPage: 1,
        }))
    }

    const onClickExpandMenu = (expandMenuId) => {
        if(state.observedRecommendations && Object.entries(state.observedRecommendations).length) {
            state.observedRecommendations && state.observedRecommendations.forEach((item, index) => {
                setState(prevState => ({ 
                    ...prevState, 
                    ["recommendation_label_"+index]: item.category, 
                    ["recommendation_data_"+index]: item.data, 
                    ["filtered_recommendation_data_"+index]: item.data 
                }))

                if(index === expandMenuId) {
                    onChangeRecommendationEvent(item.label, item.tag, item.data[0], index, "recomm_"+expandMenuId+"_0", item.data[0].asset_id)
                }
            })
        }    
    }

    const onChangeRecommendationEvent = (label, tag, data, index, selectedEvent, assetId) => {
        let assetDetails = {}

        if(data.asset_id && assetDetails.asset_id !== "") {
            if(!data.resource_type || data.resource_type === "") {
                data.resource_type = data.resource_type ? data.resource_type : ""
            }
            if(!data.service_name || data.service_name === "") {
                data.service_name = data.service_name ? data.service_name : ""
            }
            if(!data.provider || data.provider === "") {
                data.provider = data.provider ? data.provider : ""
            }
            if(!data.account_id || data.account_id === "") {
                data.account_id = data.account_id ? data.account_id : ""
            }
            if(!data.region || data.region === "") {
                data.region = data.region ? data.region : ""
            }
        }
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = data
        obj.asset_details = assetDetails
        obj.showBy = state.showBy
        dispatch(setCostPropsDetails("selectedRecommendedEvent", obj));

        setState(prevState => ({
            ...prevState,
            selectedRecommandation: selectedEvent
        }))
    }

    const onChangeServiceRecommendationEvent = (label, tag, checks) => {
        let obj = {}
        obj.label = label
        obj.eventGroup = tag
        obj.data = checks.data
        obj.showBy = state.showBy
        
        dispatch(setCostPropsDetails("selectedResourceRecommendedEvent", obj))
    }

	const handleInput = e => {
		const { name, value } = e.target;

		setState(prevState => ({ ...prevState, [name]: value, showLoginError: false }))
	}
    const handleMultiSelectChange = (field, arrayValue, state) => {
		let value = arrayValue.map(item => item.value ? item.value : item)
		let selectedValue = []

		if(state) {
			let prevState = state[field] ? state[field] : []
			if(prevState.length === 1 && prevState[0] === "All") {
				prevState = []
			}
			if(value.includes("All")) {
				if(!prevState.includes("All")) {
					selectedValue = value
				} else {
					const index = value.indexOf("All");
					if (index > -1) {
						value.splice(index, 1);
					}
					selectedValue = value
				}
			} else if(!prevState.includes("All")) {
				selectedValue = value
			}

			if(selectedValue.length && !selectedValue.includes("All")) {
				if(!selectedValue.includes("All") && selectedValue.length === (state.length -1)) {
					selectedValue.push("All")
					// let dataRow = {
					// 	label: "All",
					// 	value: "All",
					// }
				}
			}
		} else {
			selectedValue = value
		}

		let selectedDays = state.selectedDays ? state.selectedDays : []
		if(state.selectedDays && !state.selectedDays.length) {
			selectedDays = selectedValue
		} else {
			selectedValue.forEach(item => {
				if(selectedDays.includes(item)) {
					const index = selectedDays.indexOf(item);
					if (index > -1) {
						selectedDays.splice(index, 1);
					}
				} else{
					selectedDays.push(item)
				}
			})
		}

		setState(prevState => ({ ...prevState,[field]: selectedValue, selectedDays }))
	}
	
	const getMultiSelectedCount = (type, array) => {
		return array && array.length && array.includes("All") ? "All" : (array && array.length ? array.length +" Selected" : "All")
	}
    
    const handleClickOutside = (event) => {
        if (accountRef.current && !accountRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isAccountOpen: false }));
        } else {
            setState(prevState => ({ ...prevState, isAccountOpen: true }));
        }
    
        if (regionRef.current && !regionRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isRegionOpen: false }));
        } else {
            setState(prevState => ({ ...prevState, isRegionOpen: true }));
        }
    };

    const onReset = () => {
        setState(prevState => ({ ...prevState, 
            selectedProvider: (providers.filter(e => e.provider_name === 'AWS').length ? 'AWS' : providers[0].provider_name).toLowerCase(),
            selectedAccount: [],
            selectedRegion: []
        }));
    }

    return (        
        <div className='col-sm-12 p-0' onClick={(event) => handleClickOutside(event)}>
            <div className='row text-white mx-0 pb-2 bg-nero'>
                <div className='col-lg-12 col-12 py-2'>
                    <h6 className='text-white m-0'>Observed Recommendations</h6>
                    <p className='text-white m-0'>Summary of analysis and observations of the chosen Cloud resources</p>
                </div>
            </div>
            <div className='d-flex justify-content-end pr-2 text-white mx-0 pb-2 bg-nero'>
                <div className='w-75 py-2'>
                    <div className='d-flex mx-0 d-flex justify-content-end'>
                        <div className='w-33 pr-2'>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`}>
                                <label className='mb-1 f10'>Provider</label>
                                <Select
                                    placeholder={'Select'}
                                    isSearchable={false}
                                    className='f13 p-0 colorBlack reactSelectDrop'
                                    components={{
                                        IndicatorSeparator: () => null,
                                    }}
                                    value={{
                                        value: state.selectedProvider && state.selectedProvider !== '' ? state.selectedProvider : 'Select',
                                        label: state.selectedProvider ? state.selectedProvider.toUpperCase() : <span className='placeholder'>Select</span>
                                    }}
                                    options={
                                        state.providers && state.providers.length ?
                                            state.providers.map(row => ({
                                                value: row.provider_name,
                                                label: row.provider_name,
                                            }))
                                        : state.emptyOptions
                                    }
                                    onChange={event => {
                                        setState(prevState => ({ ...prevState, selectedProvider: event.value, selectedAccount: [], selectedRegion: [] }))
                                    }}
                                />
                            </div>
                        </div>
                        <div className='w-33 pr-2'>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} ref={accountRef}>
                                <label className='mb-2 mt-1 f10 d-flex'>Account</label>
                                <p className={`d-flex mt-2 mb-0 ${state.selectedAccount && state.selectedAccount.length ? 'text-white' : 'placeholder'}`}>{state.selectedAccount && state.selectedAccount.length ? state.selectedAccount.length+' Selected' : 'Select'}</p>
                                {state.isAccountOpen ?
                                    <div className='position-relative'>
                                        <MultiSelectSection 
                                            // fields={["account_id", "account_name"]}
                                            // className={'position-unset'}
                                            removeTop={true} 
                                            fields={["account_id", "account_name"]}
                                            options={state.accounts}
                                            selectedValues={state.selectedAccount ? state.selectedAccount : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedAccount: value }))}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                        <div className='w-33 pr-2'>
                            <div className={`form-group my-2 cursorPointer flex-fill bd-highlight mb-sm-0 styled-feild`} ref={regionRef}>
                                <label className='mb-2 mt-1 f10 d-flex'>Region</label>
                                <p className={`d-flex mt-2 mb-0 ${state.selectedRegion && state.selectedRegion.length ? 'text-white' : 'placeholder'}`}>{state.selectedRegion && state.selectedRegion.length ? state.selectedRegion.length+' Selected' : 'Select'}</p>
                                {state.isRegionOpen ?
                                    <div className='position-relative'>
                                            <MultiSelectSection 
                                                // fields={["account_id", "account_name"]}
                                            // className={'position-unset'}
                                            removeTop={true}
                                            fields={["region", "name"]}
                                            options={state.regions}
                                            selectedValues={state.selectedRegion ? state.selectedRegion : []}
                                            callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, selectedRegion: value }))}
                                        />
                                    </div>
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-3 text-right align-self-center '>
                    <div className={`ml-3`}>
                        <div className='btn-group' role='group' aria-label='Basic example'>
                            <button type='button' className={`cursorPointer btn btn bg-cyon text-white`} onClick={() => setState(prevState => ({ ...prevState, callSearch: true }))}>Search</button>
                            <button type='button' className={`cursorPointer btn btn bg-cyon text-white`} onClick={() => onReset()}><i className='far fa-redo'/></button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className='row mx-0 mt-2'>
                <div className='col-lg-12 mt-2 text-white'>
                    <div className="d-flex justify-content-between border-mediumDarkGray-bottom mb-2">
                        <ul className={`nav nav-tabs border-0 bg-transparent shadow-none rounded mb-0`} id="myTab" role="tablist">
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer ${state.activeTab === 'Consolidated' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, activeTab: 'Consolidated' }))}>Consolidated View</span>
                            </li>
                            <li className="nav-item">
                                <span className={`nav-link text-white cursorPointer  ${state.activeTab === 'Table' ? ' active' : ''}`} onClick={() => {
                                    setState(prevState => ({ ...prevState, activeTab: 'Table', showBy: 'Resource' }))
                                    setTimeout(() => {
                                        filterTableResults() 
                                    }, 1000);
                                }}>Table View</span>
                            </li>
                        </ul>
                        <div className="w-50 align-self-center">
                            <div className="d-flex justify-content-end mb-n2">
                                {state.activeTab !== "Table" ? 
                                    state.totalRecommendedData && state.totalRecommendedData.length ?
                                        <div className={`input-group ml-2 mb-0 bg-transparent border-lightGray rounded-5 f12 align-self-center`}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text bg-transparent px-2 f12">
                                                    <i className="fal fa-search text-lightGray f12"></i>
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className={`form-control form-control-sm bg-transparent text-white pl-0 f12`}
                                                placeholder="Search"
                                                name="searchText"
                                                value={state.searchText ? state.searchText : ""}
                                                onChange={handleInput}
                                            />
                                        </div>
                                    : null
                                :
                                    state.tableViewData && state.tableViewData.length ?
                                        <div className={`input-group ml-2 mb-0 bg-transparent border-lightGray rounded-5 f12 align-self-center`}>
                                            <div className="input-group-prepend">
                                                <div className="input-group-text bg-transparent px-2 f12">
                                                    <i className="fal fa-search text-lightGray f12"></i>
                                                </div>
                                            </div>
                                            <input
                                                type="text"
                                                className={`form-control form-control-sm bg-transparent text-white pl-0 f12`}
                                                placeholder="Search"
                                                value={state.searchTableText}
                                                onChange={e => 
                                                    setState(prevState => ({ ...prevState, searchTableText: e.target.value }))
                                                }
                                            />
                                        </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    {state.groupedRecommendations && state.groupedRecommendations.length ?
                        <React.Fragment>
                        <div className="d-flex justify-content-between">
                            <p className="my-1 text-primary-color">Showing the observerd recommendations with <span className={`text-success`}>Savings  <span className="text-primary-color f12">{state.recommendedDetails && state.recommendedDetails.price_unit ? " ("+ state.recommendedDetails.price_unit+")" : ""}</span> of {state.recommendedDetails && state.recommendedDetails.savings && state.recommendedDetails.savings}<span className="text-primary-color f12">({state.recommendedDetails && state.recommendedDetails.percentage_savings && state.recommendedDetails.percentage_savings}%)</span> </span></p>
                            
                            {state.activeTab === "Table" ? 
                                <div className="d-flex">
                                    <p className="mb-0 mr-2 mt-2 text-muted">show by</p>
                                    <div>
                                        <select className='form-control bg-transparent border-lightGray select1 text-lightGray' style={{minWidth: '150px'}} id="mySelect"
                                            onChange={(e) => setState(prevState => ({ 
                                                ...prevState, 
                                                showBy: e.target.value, 
                                                searchTableText: "", 
                                                showByOptions: false, 
                                                selectedRecommendationGroup: "All"
                                            }))}>
                                            <option value={'Resource'}>Resource</option>
                                            <option value={'Service'}>Service</option>
                                        </select>
                                    </div>
                                </div>
                            : null}
                        </div>
                        <div className={`row ml-1 mt-1 mb-2`}>
                            {state.groupedRecommendations.map(item => {
                                return(
                                    <div className={`w-20 mb-2 cursorPointer pr-2`}>
                                        <div className={`p-2 bg-dark rounded ${state.selectedRecommendationGroup === item.label ? "border-info" : item.observedFlag ? "success-border" : "border-purplishRed"}`} onClick={() => filterRecommendations(item.label, item.sub)}>
                                            <div className="d-flex">
                                                <i className={`mr-2 align-self-center fa fa-circle ${item.observedFlag ? "text-success" : "text-purplishRed"}`}></i>
                                                <span className={`small ${item.observedFlag ? "text-success" : "text-purplishRed"}`}>{item.label && item.label !== "undefined" ? item.label : "Others"} <span className={`small text-primary-color`}>({item.sub && item.sub.length})</span></span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        </React.Fragment>
                    : null}
                    <div className="d-flex">
                        <div className={`w-100`}>
                            <div className={`bg-dark3 p-2 overflowYAuto`}>
                                {state.alertListLoader ? 
                                    <div className="d-flex justify-content-center m-4">
                                        {/* <Spinner className="text-center" color='dark' size="lg" /> */}
                                    </div>
                                : state.activeTab !== "Table" ? 
                                    state.filteredObservedRecommendation && state.filteredObservedRecommendation.length ?
                                        state.filteredObservedRecommendation.map((item, index) => {
                                            return(
                                                <React.Fragment>
                                                <div className={`p-2 ${index ? "border-mediumDarkGray-top" : ""} ${state.expandMenuId === index ? "bg-muted" : ""}`}> 
                                                    <div className={`mb-0 d-flex justify-content-between ${item.detailView ? "cursorPointer" : ""}`} onClick={() => expandCollapse(index, item.label, item.tag)} data-toggle="collapse" data-target={"#collapse_"+index}aria-expanded="true" aria-controls={"collapse_"+index}>
                                                        <div><span className={`far ${state.expandMenuId === index ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>{item.label}</div>
                                                        <div className="">
                                                            <span className={`${item.flag ? "text-success" : "text-purplishRed"}`}>Savings <span className="text-white f12">({item.price_unit ? item.price_unit : "USD"})</span> of {item.savings ? item.savings : 0} <span className="text-white f12">({(item.percentage_savings ? item.percentage_savings : 0)}%)</span> </span>
                                                            {/* <span className={`far fa-exclamation-triangle ${item.flag ? "text-success" : "text-purplishRed"} ml-3`}></span> */}
                                                        </div>
                                                    </div>

                                                    {state.expandMenuId === index ?
                                                        <div className="mt-1">
                                                            <div className="accordion" id="accordionExample">
                                                                <div className={`card border-0 bg-dark ${index !== state.observedRecommendations.length-1 ? "" : ""}`}>
                                                                    <div id={"collapse_"+index} className={`collapse ${state.expandMenuId === index ? "show" : ""}`} aria-labelledby={"heading_"+index} data-parent="#accordionExample">
                                                                        <div className="card-body">
                                                                            <div className="d-flex justify-content-between mt-1">
                                                                                <div className="d-flex">
                                                                                    <p className="small mb-0 mr-2 mt-1">Showing {state["filtered_recommendation_data_"+index] && state["filtered_recommendation_data_"+index].length} {item.label} </p>
                                                                                </div>
                                                                                <div className="form-group mb-0">
                                                                                    <SearchWithHiddenInput
                                                                                        ID={"search_id_"+index}
                                                                                        data={state["recommendation_data_"+index]}
                                                                                        applyLiteDarkTags={true}
                                                                                        className="form-control-sm text-white bg-lightGray border-0"
                                                                                        InputGroupPretendWidth={"w-20"}
                                                                                        inputEncloseClass={"w-80"}
                                                                                        hideInputSection={true}
                                                                                        searchClassName={"border-0"}
                                                                                        searchIconColor={"text-lightGray"}
                                                                                        topClassName={"mb-2"}
                                                                                        filteredData={(filteredArray) => {
                                                                                            setState(prevState => ({ ...prevState, 
                                                                                                ["filtered_recommendation_data_"+index]: filteredArray,
                                                                                                ["recommendation_count_"+index]: filteredArray.length   
                                                                                            }))
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="container-fluid">
                                                                                {state["filtered_recommendation_data_"+index] && state["filtered_recommendation_data_"+index].length ?
                                                                                    state["filtered_recommendation_data_"+index].map((dig, i) => {
                                                                                        return(
                                                                                            <div className={`row px-2 py-1 ${i ? "border-mediumDarkGray-top" : ""} ${state.selectedRecommandation === "recomm_"+index+"_"+i ? "bg-muted text-white disabled" : " text-white cursorPointer"}`} onClick={() => onChangeRecommendationEvent(item.label, item.tag, dig, index, "recomm_"+index+"_"+i, dig.asset_id)}>
                                                                                                <p className="mb-0">
                                                                                                    {dig.severity ?
                                                                                                        <span className={`mr-2 risk-${dig.severity ? dig.severity.toLowerCase() : ""}`}>{capitalizeFirstLetter(dig.severity)}</span>
                                                                                                    : null}
                                                                                                    {dig.event_time ?
                                                                                                        <span className="mb-0 mr-2 f12 font-weight-bold text-primary-color">{dig.event_time ? momentConvertionUtcToLocalTime(dig.event_time, 'DD MMM YYYY HH:mm') : ''} </span>
                                                                                                    : null}
                                                                                                    {dig.asset_name ?
                                                                                                        <span className="mb-0 mr-2 f12 font-weight-bold">{dig.asset_name}</span>
                                                                                                    : null}
                                                                                                    <span className="mb-0 small mr-1 text-primary-color">Resource</span>
                                                                                                    {dig.account_id ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' '+dig.account_id}</span>
                                                                                                    : null}
                                                                                                    {dig.region ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+dig.region}</span>
                                                                                                    : null}
                                                                                                    {dig.resource_type ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold">{' : '+(Array.isArray(dig.resource_type) ? dig.resource_type.join(', ') : dig.resource_type)}</span>
                                                                                                    : null}
                                                                                                    {dig.service_name ?
                                                                                                        <span className="mb-0 f12 text-primary-color font-weight-bold mr-2">{' : '+dig.service_name}</span>
                                                                                                    : null}
                                                                                                    {dig.description ?
                                                                                                        <span className="mb-0 ml-1 f12 font-weight-bold">{dig.description}</span>
                                                                                                    : null}
                                                                                                </p>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                : null}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    : null}
                                                </div>
                                                </React.Fragment>
                                            )
                                        })
                                    :
                                        <div className="d-flex justify-content-center m-4">
                                            <p>There are no data on this criteria. Please try adjusting your filter.</p>
                                        </div>
                                :
                                    state.showBy === "Resource" ?
                                        state.filteredTableArray && state.filteredTableArray.length ?
                                            <ResizeableDarkThemeTable
                                                columns={[
                                                    {
                                                        Header: "Service",
                                                        accessor: d => d.resource_type+" : "+d.service_name,
                                                        width: 200
                                                    },
                                                    {
                                                        Header: "Category",
                                                        accessor: "category",
                                                        width: 150
                                                    },
                                                    {
                                                        Header: "Savings",
                                                        // accessor: d => d.additional_details && d savings+" ("+d.price_unit && d.price_unit+"%)"+"  ("+d.percentage_savings && d.percentage_savings+"%)",
                                                        Cell: cellInfo => (
                                                            <div className='d-flex'>
                                                                <p className="f12 m-0 text-success align-self-center mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.savings_billing_prev_month ? cellInfo.row.original.additional_details.current_features.savings_billing_prev_month : ''}</p>
                                                                <p className="mb-0 text-primary-color f11 mr-1">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.price_unit ? cellInfo.row.original.additional_details.current_features.price_unit : ''}</p>
                                                                <p className="mb-0 text-primary-color f11">{cellInfo.row.original.additional_details && cellInfo.row.original.additional_details.current_features && cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month ? "("+cellInfo.row.original.additional_details.current_features.percentage_savings_billing_prev_month+"%)" : ''}</p>
                                                            </div>
                                                        ),
                                                        width: 150
                                                    },
                                                    {
                                                        Header: "Asset",
                                                        accessor: "asset_name",
                                                        width: 200
                                                    },
                                                    {
                                                        Header: "Resources",
                                                        Cell: cellInfo => (
                                                            (cellInfo.row.original.provider ? cellInfo.row.original.provider.toUpperCase() : '') + ': ' + getAccountNameFromId(cellInfo.row.original.account_id, state.accounts) + ': ' + (cellInfo.row.original.region ? (cellInfo.row.original.region === 'NA' ? 'Global' : getRegionName(cellInfo.row.original.region, state.regions)) : '')
                                                        ),
                                                        width: 200
                                                    },
                                                    {
                                                        Header: "Description",
                                                        accessor: "description",
                                                        width: 600
                                                    }
                                                ]}
                                                data={state.filteredTableArray}
                                                perPage={20}
                                                dashboard={state.filteredTableArray && state.filteredTableArray.length ? true : false}
                                                sortByColumn={"Date"}
                                                riskTooltip={[0]}
                                                onClickRow={tableRow => {
                                                    onChangeRecommendationEvent(tableRow.label, tableRow.tag, tableRow, 0, "", tableRow.asset_id)
                                                }}
                                                // defaultSelectedRecord={""}
                                                // tableSize={"table-sm"}
                                                // tableHead={"bg-dark"}
                                                // //tooltipFor={}
                                            />
                                        :
                                            <div className="d-flex justify-content-center m-4">
                                                <p>There are no data on this criteria. Please try adjusting your filter 0.</p>
                                            </div>
                                    : 
                                        state.filteredResouceArray && state.filteredResouceArray.length ?
                                            <ResizeableDarkThemeTable
                                                columns={[
                                                    {
                                                        Header: "Service",
                                                        accessor: d => d.resource_type,
                                                        width: 200
                                                    },
                                                    {
                                                        Header: "Category",
                                                        accessor: "tag",
                                                        width: 150
                                                    },
                                                    {
                                                        Header: "Savings",
                                                        accessor: d => d.savings+" ("+d.price_unit && d.price_unit+")  ("+d.percentage_savings && d.percentage_savings+"%)",
                                                        Cell: cellInfo => (
                                                            <div className='d-flex'>
                                                                <p className="f12 m-0 text-success align-self-center mr-1">{cellInfo.row.original.savings ? cellInfo.row.original.savings : ''}</p>
                                                                <p className="mb-0 text-primary-color f11 mr-1">{cellInfo.row.original.price_unit ? cellInfo.row.original.price_unit : ''}</p>
                                                                <p className="mb-0 text-primary-color f11">{cellInfo.row.original.percentage_savings ? "("+cellInfo.row.original.percentage_savings+"%)" : ''}</p>
                                                            </div>
                                                        ),
                                                        width: 150
                                                    },
                                                    {
                                                        Header: "Description",
                                                        accessor: "description",
                                                        width: 600
                                                    }
                                                ]}
                                                data={state.filteredResouceArray}
                                                perPage={20}
                                                dashboard={state.filteredResouceArray && state.filteredResouceArray.length ? true : false}
                                                sortByColumn={"Date"}
                                                riskTooltip={[0]}
                                                onClickRow={tableRow => {
                                                    onChangeServiceRecommendationEvent(tableRow.label, tableRow.tag, tableRow)
                                                }}
                                                // defaultSelectedRecord={""}
                                                // tableSize={"table-sm"}
                                                // tableHead={"bg-dark"}
                                                // //tooltipFor={[1,2]}
                                            />
                                        :
                                            <div className="d-flex justify-content-center m-4">
                                                <p>There are no data on this criteria. Please try adjusting your filter 1.</p>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RecommendationsLeftSection