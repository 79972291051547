/*************************************************
 * Collider
 * @exports
 * @file CostAction.js
 * @author parakash // on 3-02-2023
 * @copyright © 20214Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'

import {
    LIST_QOS_POLICIES,
    GET_QOS_PARAMETERS,
    CREATE_QOS_POLICY,
    GET_QOS_POLICY,
    EDIT_QOS_POLICY,
    REMOVE_QOS_POLICY
} from '../../config'

/**
 * Action to list-qos-policies
 * @param {Object} body
 * @param {Function} callback
*/
export const listQosPolicies = (params) => {
	return async () => {
		try {

			const url = LIST_QOS_POLICIES;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}

/**
 * Action to get-qos-parameters
 * @param {Object} body
 * @param {Function} callback
*/
export const getQosParameters = (params) => {
	return async () => {
		try {

			const url = GET_QOS_PARAMETERS;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response && error.response.data ? error.response.data : {}
		}
	};
}

/**
 * Action to create-qos-policy
 * @param {Object} body
 * @param {Function} callback
*/
export const createQosPolicy = (params) => {
	return async () => {
		try {

			const url = CREATE_QOS_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}

/**
 * Action to get-qos-policy
 * @param {Object} body
 * @param {Function} callback
*/
export const getQosPolicy = (params) => {
	return async () => {
		try {

			const url = GET_QOS_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}

/**
 * Action to edit-qos-policy
 * @param {Object} body
 * @param {Function} callback
*/
export const editQosPolicy = (params) => {
	return async () => {
		try {

			const url = EDIT_QOS_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}

/**
 * Action to remove_qos_policy
 * @param {Object} body
 * @param {Function} callback
*/
export const removeQosPolicy = (params) => {
	return async () => {
		try {

			const url = REMOVE_QOS_POLICY;
			// Assuming your API endpoint for the POST request is 'YOUR_API_ENDPOINT'
			const response = await ApiCalls.post(url, params)

			// You can dispatch an action with the fetched data here
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_SUCCESS', payload: response.data });

			// Return the response data so you can use it in the component
			return response.data;
		} catch (error) {
			// Handle error and dispatch an action in case of failure
			//   dispatch({ type: 'FETCH_TROUBLESHOOT_RESULTS_FAILURE', payload: error });

			// Throw the error to be caught in the component if needed
			// throw error;
			// if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				// refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
				//window.location.href = '/signin';
			// }
			return error.response
		}
	};
}