/*************************************************
 * Tvastar
 * @exports
 * @file GroupAssocationDetails.js
 * @author Prakash // on 06/03/2024
 * @copyright © 2024 Tvastar. All rights reserved.
 *************************************************/
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction'
import { listUsers } from '../../../actions/commonActionNew'
import MultiSelectSection from '../../common/MultiSelectSection';
import { useLocation } from 'react-router-dom';

const GroupAssocationDetails = (props) => {
	const [state, setState] = useState({
		hasErrorInRuleForm: false,
		hasError: props.hasError,
		masterData: props.masterData,
		filteredMasterData: props.masterData,
		callUserList: true
	})

	const dispatch = useDispatch(); // Create a dispatch function
	const qosCreateEditInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.qosCreateEditInputs || false)
	const usersList = useSelector(state => state?.filters?.listUsers || false);

	const location = useLocation();
	const receivedData = location.state || false

	const handleInputChange = useCallback((label, value) => {
		let obj = qosCreateEditInputs ? qosCreateEditInputs : {}
		obj[label] = value
		dispatch(setHpcPropsDetails('qosCreateEditInputs', obj))
	}, [dispatch, qosCreateEditInputs])

	useEffect(() => {
		if(receivedData && Object.entries(receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedUsers: receivedData.selectedData && receivedData.selectedData.user_name ? receivedData.selectedData.user_name : []
			}))

			dispatch(setHpcPropsDetails('qosCreateEditInputs', receivedData.selectedData))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [receivedData, dispatch])

	useEffect(() => {
        if (state.callUserList) {
			setState(prevState => ({ ...prevState, callUserList: false }));
            let params = {};
            if(!usersList) {
                dispatch(listUsers(params))
                    .then((response) => {
                        if(response) {
                            setState(prevState => ({ ...prevState, usersList: response }));
                        }
                    })
            } else {
                setState(prevState => ({ ...prevState, usersList }));
            }
        }
    }, [dispatch, state.callUserList, usersList])

    const removeItem = (field, value) => {
        setState(prevState => ({ ...prevState, [field]: state[field].filter(e => e !== value) }))
		handleInputChange('requested_user_name', state[field].filter(e => e !== value))
    }

	const handleChildClick = (event, type, dropdownType, section) => {	
		event.stopPropagation();

		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

	return (
		<React.Fragment>
		<div className='d-flex justify-content-between'>
			<h5 className='text-warning'>Associations</h5>
		</div>
		<div className={`pb-3 mb-2 policiesPage`} onClick={(event) => handleChildClick(event, 'parent')}>
			<div className='d-flex mt-2 pt-2'>
				<div className='py-1 w-50'>
					<div className='w-60'>
						<div className='d-flex justify-content-between'>
							<p className="b-block mb-0">User</p>
							{state.inputValidationError && !state.selectedUsers ?
								<span className='mb-0 text-red ml-2'>required</span>
							: null}
						</div>
						<div className='border-lightGray bg-transparent rounded-5' onClick={(event) => {
							event.preventDefault();
							handleChildClick(event, 'child', 'muliselectDropDown', "showUser")
						}}>
							<p className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${state.selectedUsers && state.selectedUsers.length ? 'text-white' : 'placeholder'}`}>
								{state.selectedUsers && state.selectedUsers.length ? state.selectedUsers.length+' Selected' : 'Select'}
								<i className='fa fa-caret-down mt-1 mr-1'/>
							</p>
							{state.showUser ?
								<MultiSelectSection
									// fields={["user", "label"]}
									options={state.usersList}
									selectedValues={state.selectedUsers ? state.selectedUsers : ''}
									callbackMultiSelect={(value) => {
										setState(prevState => ({ ...prevState, selectedUsers: value }))
										handleInputChange('requested_user_name', value)
									}}
									singleSelection={false}
									hideSearch={false}
									topClass={'auto'}
									widthClass={'widthMaxContent'}
								/>
							: null}
						</div>
					</div>
				</div>
			</div>
			{state.selectedUsers && state.selectedUsers.length ? 
				state.selectedUsers.map(row => {
					return(
						<span key={row} className="badge badge-outline-info mr-2 mt-2 align-self-center f14 px-2">{row}
							<i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeItem('selectedUsers', row) } ></i>
						</span>
					)
				})
			: null}
		</div>
		</React.Fragment>
	)
}

export default GroupAssocationDetails
