/*************************************************
 * Collider
 * @exports
 * @file Header.js
 * @author Rajasekar // on 02/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'; // Import useDispatch hook to dispatch actions

import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { getUserProfile } from '../../actions/commonActionNew'
import { capitalizeFirstLetter } from '../../utils/utility'


import { useMsal } from "@azure/msal-react";
import { msalConfig } from '../../authConfig';

const Header = () => {
	const { instance } = useMsal()

	const [state, setState] = useState({
		showDropdown: false,
		profile: {}
	})

	const profileDetails = useSelector(state => state.filters.profileDetails);
	
	const dispatch = useDispatch(); // Create a dispatch function

	/**
	 * toggles profile settings dropdown
	 */
	const toggleDropdown = () => {
		setState(prevState => ({ ...prevState, showDropdown: !prevState.showDropdown }))
	}

	const handleLogout = () => {		
		// localStorage.removeItem('ACCESS_TOKEN');
		// localStorage.removeItem('ACCESS_TOKEN_EXPIERS_ON');
		localStorage.clear();
		sessionStorage.clear()
		
		const logoutRequest = {
			postLogoutRedirectUri: msalConfig.auth.redirectUri,
		};
	
		instance.logoutRedirect(logoutRequest);;
		// instance.logoutRedirect()
	}

	useEffect(() => {
		if(!Object.entries(profileDetails).length) {
			dispatch(getUserProfile({}))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, profile: response }))
					}
				})
		} else {
			setState(prevState => ({ ...prevState, profile: profileDetails }))
		}
	}, [dispatch, profileDetails])

	// const logout = () => {
	// 	//localStorage.setItem('ACCESS_TOKEN', '');
	// 	Cookies.remove("user_id")
	// 	// Cookies.remove('myCookie', { path: '/sigin'});
	// 	//window.location.href = '/signin';
	// }

	/**
	 * Renders Page header
	 */
	return (
		<div className='top-header-collider	'>
			<div className="d-flex justify-content-between px-3">
				<div className="small py-2 text-white">Tachyon</div>
				<div className='btn-group'>
					<ButtonDropdown isOpen={state.showDropdown} toggle={toggleDropdown}>
						<DropdownToggle tag='a' className='nav-link pr-0 cursorPointer' caret>
							{state.profile && Object.entries(state.profile).length && (state.profile.firstName ? capitalizeFirstLetter(state.profile.firstName)+ ' '+capitalizeFirstLetter(state.profile.lastName) : (state.profile.userName ? capitalizeFirstLetter(state.profile.userName) : ""))}
						</DropdownToggle>
						<DropdownMenu end>
							{/* <DropdownItem>
								Profile
							</DropdownItem> */}
							<DropdownItem onClick={handleLogout}>Logout</DropdownItem>
						</DropdownMenu>
					</ButtonDropdown>
				</div>
			</div>
		</div>
	)
}

export default Header