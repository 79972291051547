/*************************************************
 * Collider
 * @exports
 * @file assetsAction.js
 * @author Rajasekar // on 05/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import ApiCalls from '../../components/ApiCalls'
import {
	SYNCHRONIZER_END_POINT,
	LIST_ASSETS,
	GET_ADVANCED_SEARCH_LIST,
	GET_TOTAL_ASSET_COUNT,
	GET_DAILY_ASSET_COUNT,
	GET_ASSET_DETAIL_EXT,
	GET_ASSET_NETWORK_DETAILS,
	GET_ASSET_SG_DETAILS,
	GET_ASSET_STORAGE_DETAILS,

	LIST_CLUSTER_ASSETS,
	LIST_CLUSTER_NAMES,
	LIST_ADVANCED_SEARCH_ASSETS,
	GET_ADVANCED_TOTAL_ASSETS_COUNT
} from '../../config'
import { refreshPage } from '../../utils/utility'
import { ACTION_TYPES } from '../types'

/**
 * Action to get all assets list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAssets = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_ASSETS;
			const response = await ApiCalls.post(url, body);
			dispatch({
				type: ACTION_TYPES.SET_ASSETS_LIST,
				payload: response.data,
			})
			callback(true, response && response.data)
		} catch (error) {

			dispatch({
				type: ACTION_TYPES.SET_SUPPRESSION_LIST,
				payload: {},
			})

			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to listAdvancedSearchAssets
 * @param {Object} body
 * @param {Function} callback
 */
export const listAdvancedSearchAssets = (body, callback) => {
	return async dispatch => {
		try {
			const url = LIST_ADVANCED_SEARCH_ASSETS;
			const response = await ApiCalls.post(url, body);
			// dispatch({
			// 	type: ACTION_TYPES.SET_ASSETS_LIST,
			// 	payload: response.data,
			// })
			callback(true, response && response.data)
		} catch (error) {

			// dispatch({
			// 	type: ACTION_TYPES.SET_SUPPRESSION_LIST,
			// 	payload: {},
			// })

			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to getAdvancedSearchAssets
 * @param {Object} body
 * @param {Function} callback
 */
export const getAdvancedSearchAssetsCount = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_ADVANCED_TOTAL_ASSETS_COUNT;
			const response = await ApiCalls.post(url, body);
			// dispatch({
			// 	type: ACTION_TYPES.SET_ASSETS_LIST,
			// 	payload: response.data,
			// })
			callback(true, response && response.data)
		} catch (error) {

			// dispatch({
			// 	type: ACTION_TYPES.SET_SUPPRESSION_LIST,
			// 	payload: {},
			// })

			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

export const getAdvancedSearchResults = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_ADVANCED_SEARCH_LIST;
			const response = await ApiCalls.post(url, body);

			let advancedAssetsList = response && response.data

			dispatch({
				type: ACTION_TYPES.SET_ADVANCED_ASSETS_LIST,
				payload: advancedAssetsList,
			})
			dispatch({
				type: ACTION_TYPES.SET_ADVANCED_SELECTED_TABLE_ROW,
				payload: advancedAssetsList && advancedAssetsList.data && advancedAssetsList.data.length ? advancedAssetsList.data[0] : {},
			})
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 * Action to get total Asset Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getTotalAssetCount = (body, countFor, callback) => {
	return async dispatch => {
		try {
			const url = GET_TOTAL_ASSET_COUNT;
			const response = await ApiCalls.post(url, body);
			const totalAssetCount = response && response.data
			if (countFor === 'provider') {
				dispatch({
					type: ACTION_TYPES.SET_PROVIDER_COUNT,
					payload: totalAssetCount,
				})
			}
			if (countFor === 'status') {
				dispatch({
					type: ACTION_TYPES.SET_STATUS_COUNT,
					payload: totalAssetCount,
				})
			}
			if (countFor === 'category') {
				dispatch({
					type: ACTION_TYPES.SET_CATEGORY_COUNT,
					payload: totalAssetCount,
				})
			}
			callback(true, totalAssetCount)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 * Action to get daily Asset Count
 * @param {Object} body
 * @param {Function} callback
 */
export const getDailyAssetCount = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_DAILY_ASSET_COUNT;
			const response = await ApiCalls.post(url, body);
			dispatch({
				type: ACTION_TYPES.SET_ASSET_COUNT,
				payload: response && response.data,
			})
			callback(true)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 * Action to get Asset detail ext
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetDetailExt = (body, callback) => {
	return async () => {
		try {
			const url = GET_ASSET_DETAIL_EXT;
			const response = await ApiCalls.post(url, body);
			// dispatch({
			// 	type: ACTION_TYPES.SET_ASSET_DETAILS,
			// 	payload: response && response.data,
			// })

			callback(true, response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get Asset network detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetNetworkDetails = (body, callback) => {
	return async dispatch => {
		try {
			const url = SYNCHRONIZER_END_POINT + GET_ASSET_NETWORK_DETAILS;
			const response = await ApiCalls.post(url, body);
			
			dispatch({
				type: ACTION_TYPES.SET_ASSET_NETWORK_DETAILS,
				payload: response && response.data,
			})
			callback(true)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get Asset storage detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetStorageDetails = (body, callback) => {
	return async dispatch => {
		try {
			const url = SYNCHRONIZER_END_POINT + GET_ASSET_STORAGE_DETAILS;
			const response = await ApiCalls.post(url, body);

			dispatch({
				type: ACTION_TYPES.SET_ASSET_STORAGE_DETAILS,
				payload: response && response.data,
			})
			callback(true)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get Asset sg detail
 * @param {Object} body
 * @param {Function} callback
 */
export const getAssetSGDetails = (body, callback) => {
	return async dispatch => {
		try {
			const url = SYNCHRONIZER_END_POINT + GET_ASSET_SG_DETAILS;
			const response = await ApiCalls.post(url, body);

			dispatch({
				type: ACTION_TYPES.SET_ASSET_SG_DETAILS,
				payload: response && response.data,
			})
			callback(true)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}
/**
 * Action to set selected table row
 */
export const setSelectedTableRow = row => {
	return async dispatch => {
		dispatch({
			type: ACTION_TYPES.SET_SELECTED_TABLE_ROW,
			payload: row,
		})
	}
}
/**
 * Action to set selected filter
 */
export const setSelectedFilter = (filter, selectedValue) => {
	return async (dispatch, getState) => {
		let selectedFilters = getState().assets.selectedFilters
		selectedFilters[filter] = selectedValue
		dispatch({
			type: ACTION_TYPES.SET_SELECTED_FILTER,
			payload: selectedFilters,
		})
	}
}


/**
 * Action to set resource props details
 */
 export const setResourcePropsDetails = (key, value) => {
	return async (dispatch, getState) => {
		const resourcesPropsDetails = JSON.parse(JSON.stringify(getState().assets.resourcesPropsDetails))
		resourcesPropsDetails[key] = value
		
		dispatch({
			type: ACTION_TYPES.SET_RESOURCES_PROPS_DETAILS,
			payload: resourcesPropsDetails,
		})
	}
}

/**
 * Action to list-cluster-assets
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusterAssets = (body, callback) => {
	return async () => {
		try {
			const url = LIST_CLUSTER_ASSETS;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to list-cluster-names
 * @param {Object} body
 * @param {Function} callback
 */
export const listClusterNames = (body, callback) => {
	return async () => {
		try {
			const url = LIST_CLUSTER_NAMES;
			const response = await ApiCalls.post(url, body);
			callback(true, response && response.data)
		} catch (error) {
			let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}
			callback(false, respone)
		}
	}
}

/**
 * Action to get all assets list
 * @param {Object} body
 * @param {Function} callback
 */
export const listAssetsNew = (params) => {	
	return async dispatch => {		
		try {
			const url = LIST_ASSETS;
			const response = await ApiCalls.post(url, params);

			dispatch({ type: ACTION_TYPES.SET_ASSETS_LIST, payload: response.data, })
			
			return response && response.data
		} catch (error) {
			// let respone = {}
			if(error && error.response && (error.response.status === 403 || error.response.data.token_expired)) {
				refreshPage()
				//localStorage.setItem('ACCESS_TOKEN', '');
                // //window.location.href = '/signin';
			}

			return
		}
	}
}