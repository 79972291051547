/*************************************************
 * Collider
 * @exports
 * @file CreateMappingLeft.js
 * @author Prakash // on 19/03/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/

import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'reactstrap'
import { getAllTagsKeys, getAllTagsValues } from '../../../actions/commonActionNew'
import MultiSelectSection from '../../common/MultiSelectSection';
import { setHpcPropsDetails } from '../../../actions/Collider/HpcAction';
import AlertConfirmation from '../../common/AlertConfirmation';

const CreateMappingLeft = (props) => {
	const[state, setState] = useState({
		initArray: [0],
        selectedInitSection: 'initiativeSection_0'
	})

	const dispatch = useDispatch(); // Create a dispatch function
	
    const createMappingInputs = useSelector(state => state?.hpc?.hpcPropsDetails?.createMappingInputs || false);
    const onClickMappingSave = useSelector(state => state?.hpc?.hpcPropsDetails?.onClickMappingSave || false);    
    const tagKeys = useSelector(state => state?.filters?.tagKeys || false);

    console.log("createMappingInputs", createMappingInputs)

    const passInitProps = useCallback((init, value) => {
        let obj = {}
        if(value) {
            obj.initiative = value
            obj.init = init
        }
        dispatch(setHpcPropsDetails('selectedMappingInitiative', obj))
    }, [dispatch])

    const expandInitiative = useCallback((selectedInitSection, selectedInitiative) => {
        if(selectedInitSection === state.selectedInitSection) {
            selectedInitiative = ''
            selectedInitSection = ''
        } else {
            if(state['initiative_'+selectedInitiative]) {
                passInitProps(selectedInitiative, state['initiative_'+selectedInitiative])
            }
        }
        setState(prevState => ({ ...prevState, selectedInitSection, selectedInitiative }))        
    }, [passInitProps, state])

    // useEffect(() => {
    //     let obj = {}
    //     obj.selectedInitSection = 'initiativeSection_0'
    //     obj.selectedInitiative = 0
    //     dispatch(setHpcPropsDetails('selectedMappingInitiative', obj))

    //     setState(prevState => ({ ...prevState, selectedInitSection: 'initiativeSection_0', selectedInitiative: 0 }))
    // }, [dispatch])

    useEffect(() => {
        if(!tagKeys || !tagKeys.length) {
            let params = {}
            dispatch(getAllTagsKeys(params))
        }
    }, [tagKeys, dispatch])

	const getAllTagFunction = (field, selectedKey, init) => {
		if(selectedKey) {
			let params = {}
			params.tags = [{ "key": selectedKey }]
			dispatch(getAllTagsValues(params))
				.then((response) => {
					if(response) {
                        if(init >= 0 && state['selectedTags_'+init] && state['selectedTags_'+init].length) {
                            let value = state['selectedTags_'+init].filter(e => e.key === selectedKey).map(e => e.value)
                            setState(prevState => ({ ...prevState, ['tagValue_'+init]: value }))
                        } else if(state.selectedTags && state.selectedTags.length) {
                            let value = state.selectedTags.filter(e => e.key === selectedKey).map(e => e.value)
                            setState(prevState => ({ ...prevState, tagValue: value }))
                        }
						setState(prevState => ({ ...prevState, [field]: response }))
					}
				})
		}
	}

	useEffect(() => {
		if(props.receivedData && Object.entries(props.receivedData).length) {
			setState(prevState => ({ 
				...prevState,
				selectedData: props.receivedData.selectedData ? props.receivedData.selectedData : '',
				pageType: props.receivedData.pageType ? props.receivedData.pageType : '',
                formatEditStructure: true
			}))

			dispatch(setHpcPropsDetails('onClickMappingSave', props.receivedData.selectedData))
		} else {
			setState(prevState => ({ 
				...prevState,
				pageType: 'Create',
			}))
		}
	}, [props.receivedData, dispatch])

    useEffect(() => {
        if(state.pageType === 'Edit') {

        }
    }, [state.pageType])

    useEffect(() => {
        if(state.formatEditStructure) {
            setState(prevState => ({ ...prevState, formatEditStructure: false }))
            let selectedData = state.selectedData
            let department = selectedData.department ? selectedData.department : ''
            let tags = selectedData.tags ? selectedData.tags : ''
            console.log("selectedData", selectedData)

            let initArray = []
            if(selectedData.initiatives && selectedData.initiatives.length) {
                selectedData.initiatives.forEach((init, iIndex) => {
                    initArray.push(iIndex)
                    setState(prevState => ({ ...prevState, ['initiative_'+iIndex]: init.initiative ? init.initiative : '', ['selectedTags_'+iIndex]: init.tags ? init.tags : [] }))

                    if(!iIndex) {
                        passInitProps(iIndex, init.initiative)
                    }
                })
            } else {
                initArray = [0]   
            }
            
            setState(prevState => ({ ...prevState, department, selectedTags: tags, initArray }))
        }
    }, [state.formatEditStructure, state.selectedData])

    const handleInputChange = (label, value) => {
		let obj = createMappingInputs ? createMappingInputs : {}
        obj[label] = value
        if(label !== 'tags') {
            setState(prevState => ({ ...prevState, [label]: value}))
        }
        dispatch(setHpcPropsDetails('createMappingInputs', obj))
    }
    const handleInitativeInputChange = (label, value, init) => {
        if(label === 'initiative') {
            setState(prevState => ({ ...prevState, [label+'_'+init]: value}))            
        }
		let obj = createMappingInputs ? createMappingInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        console.log("initiatives", initiatives)
        if(initiatives && initiatives.length && initiatives.filter(e => e.initiative === state['initiative_'+init]).length) {
            let selectedInitiativeData = initiatives.filter(e => e.initiative === state['initiative_'+init]).length ? initiatives.filter(e => e.initiative === state['initiative_'+init])[0] : {}
            selectedInitiativeData.initiative = (label === 'initiative' ? value : state['initiative_'+init])
            selectedInitiativeData.tags = (label === 'tags' ? value : state['selectedTags_'+init])
            // selectedInitiativeData.projects = selectedInitiativeData.
            // initiatives.push(selectedInitiativeData)
            // console.log("selectedInitiativeData", selectedInitiativeData)
        } else {
            let dataRow = {}
            dataRow.initiative = (label === 'initiative' ? value : state['initiative_'+init])
            dataRow.tags = (label === 'tags' ? value : state['selectedTags_'+init])
            initiatives.push(dataRow)
            obj.initiatives = initiatives
        }
        // console.log("initiatives", initiatives)
        if(label === 'initiative') {
            passInitProps(init, value)
        }
        dispatch(setHpcPropsDetails('createMappingInputs', obj))
    }

	const addDepartmentTags = () => {
		let selectedTags = state.selectedTags ? state.selectedTags : []

		if(state.tagKey && state.tagKey !== "") {
			let filteredTags = selectedTags.filter(e => e.key !== state.tagKey)
			state.tagValue.forEach(row => {
				let dataRow = {
					key: state.tagKey,
					value: row,
				}
				filteredTags.push(dataRow)
			})

            handleInputChange('tags', filteredTags)
			setState(prevState => ({ ...prevState, selectedTags: filteredTags, tagKey: '', tagValue: [], tagValueOptions: [] }))
		}
	}

	const removeTagSection = (tag) => {
		let filteredResult = state.selectedTags.filter(e => e !== tag)
		setState(prevState => ({ ...prevState, selectedTags: filteredResult }))
	}

    const addInitiativeTags = (init) => {
		let selectedTags = state['selectedTags_'+init] ? state['selectedTags_'+init] : []

		if(state['tagKey_'+init] && state['tagKey_'+init] !== "") {
			let filteredTags = selectedTags.filter(e => e.key !== state['tagKey_'+init])
			state['tagValue_'+init].forEach(row => {
				let dataRow = {
					key: state['tagKey_'+init],
					value: row,
				}
				filteredTags.push(dataRow)
			})
            handleInitativeInputChange('tags', filteredTags, init)
			setState(prevState => ({ ...prevState, ['selectedTags_'+init]: filteredTags, ['tagKey_'+init]: '', ['tagValue_'+init]: [], ['tagValueOptions_'+init]: [] }))
		}
	}

    const removeInitiativeTags = (tag, init) => {
		let filteredResult = state['selectedTags_'+init].filter(e => e !== tag)
		setState(prevState => ({ ...prevState, ['selectedTags_'+init]: filteredResult }))
	}

    const addFilterArray = (field) => {
		let rowList = state[field] ? state[field] : [0]
		if (state[field]) {
			let value = state[field][state[field].length - 1]
			value = value + 1
			rowList.push(value)

            // setState(prevState => ({ ...prevState, selectedInitSection: 'initiativeSection_'+value, selectedInitiative:  }))
            expandInitiative('initiativeSection_'+value, value)
		}

        console.log("rowList", rowList)

		setState(prevState => ({ ...prevState, [field]: rowList }))

	}

	const removeFilterArray = (field, index) => {
        setState(prevState => ({ ...prevState, ['initiative_'+rowList[index]]: '', ['selectedTags_'+rowList[index]]: '' }))
		let rowList = state[field] ? state[field] : [0]
        if(field === 'initArray') {
            setState(prevState => ({ ...prevState, ['proj_'+rowList[index]]: []}))
        }
		rowList.splice(index, 1);
		setState(prevState => ({ ...prevState, [field]: rowList }))

        let obj = createMappingInputs ? createMappingInputs : {}
        let initiatives = obj.initiatives ? obj.initiatives : []
        obj.initiatives = initiatives.filter(e => e.initiative !== state['initiative_'+index])

        console.log("createMappingInputs", createMappingInputs)

        dispatch(setHpcPropsDetails('createMappingInputs', obj))

        dispatch(setHpcPropsDetails('selectedMappingInitiative', false))

    }

    useEffect(() => {
        if(onClickMappingSave) {
            if(onClickMappingSave !== 'save' && state.hasError) {
                dispatch(setHpcPropsDetails('onClickMappingSave', 'stopLoading'))
            } else if(onClickMappingSave === 'save') {
                // validateFunction()       
            }
        }
    }, [onClickMappingSave, state.hasError, dispatch])

    const confirmActionDetails = (field, index) => {
        let obj = {
            field: field,
            index: index,
            message: 'Are you sure you want to remove the selected initiative',
            heading: 'Confirm Removal'
        }
        setState(prevState => ({ ...prevState, confirmDetails: obj, confirmAction: true }))
    }

	const handleChildClick = (event, type, dropdownType, section) => {
        event.stopPropagation();
        let clickedChild = []            
        if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}
	
	return (
        <div className='col-lg-12 col-md-12 col-sm-12 p-0 h100' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className='row text-white mx-0 p-2 bg-muted'>                
                <div className="p-3 rounded bg-dark2 w-100 vh100 overflow-auto">
                    <div className='mb-2'>
                        <div className='d-flex mt-2'>
                            <div className='py-1 w-40'>
                                <div className='d-flex justify-content-between'>
                                    <p className="b-block mb-0">Department <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span></p>
                                    {state.inputValidationError && !state.department ?
                                        <span className='mb-0 text-red ml-2'>required</span>
                                    : null}
                                </div>
                                <Input
                                    type='text'
                                    placeholder='Enter department name'
                                    className={`inputTextbox bg-transparent border-lightGray text-white`}
                                    // maxlength='128'
                                    value={state.department ? state.department : ''}
                                    onChange={e => handleInputChange('department', e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='mt-2'>
                            <div className='d-flex justify-content-between mt-2'>
                                <p className="b-block mb-0">Tags</p>
                            </div>
                            <div className='d-flex mt-1'>
                                <div className='py-1 w-40'>
                                    <div onClick={(event) => {
                                            if(!state.isTagOpen) {
                                                event.preventDefault();
                                                handleChildClick(event, 'child', 'singleDropDown', 'isTagOpen')
                                            }
                                        }}
                                    >
                                        <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.tagKey ? 'text-white' : 'placeholder'}`}>
                                            {state.tagKey ? state.tagKey : 'Select'}
                                            <i className='fa fa-caret-down mt-1'/>
                                        </p>
                                        {state.isTagOpen ?
                                            <MultiSelectSection
                                                options={tagKeys}
                                                selectedValues={state.tagKey ? state.tagKey : ''}
                                                callbackMultiSelect={(value) => {
                                                    if(!value || typeof(value) === 'string') {
                                                        setState(prevState => ({ ...prevState, tagKey: value,  tagValueOptions: [], tagValue: []}))
                                                        getAllTagFunction('tagValueOptions', value)
                                                    } else {
                                                        value.preventDefault()
                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                    }
                                                }}  
                                                singleSelection={true}
                                                hideSearch={false}
                                                topClass={'auto'}
                                                widthClass={'minWidth220'}
                                            />
                                        : null}
                                    </div>
                                </div>
                                <div className='py-1 w-40 ml-3'>
                                    <div onClick={(event) => {
                                            event.preventDefault();
                                            handleChildClick(event, 'child', 'muliselectDropDown', 'isTagValueOpen')
                                        }}
                                    >
                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state.tagKey ? 'text-white' : 'placeholder'}`}>
                                        {state.tagValue && state.tagValue.length ? state.tagValue.length +' Selected'  : 'Select'}
                                            <i className='fa fa-caret-down mt-1'/>
                                        </p>
                                        {state.isTagValueOpen && state.tagKey ?
                                            <MultiSelectSection
                                                // fields={["provider_name", "provider_name"]}
                                                options={state.tagValueOptions}
                                                selectedValues={state.tagValue ? state.tagValue : []}
                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, tagValue: value }))}
                                                singleSelection={false}
                                                hideSearch={false}
                                                topClass={'auto'}
                                                widthClass={'minWidth220'}
                                            />
                                        : null}
                                    </div>
                                </div>
                                <span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => addDepartmentTags()}></span>
                            </div>
                            <div className='d-flex flex-wrap'>
                                {state.selectedTags && state.selectedTags.length ? 
                                    state.selectedTags.map((tag, index) => {
                                        return(
                                            <span key={'dep_tag_'+index} className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
                                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeTagSection(tag) } ></i>
                                            </span>
                                        )
                                    })
                                : null}
                            </div>
                        </div>
                    </div>
                    <p className='mt-2 mb-1 text-white'>Initiatives</p>
                    {state.initArray ?
                        state.initArray.map((init, initIndex) => {
                            return(
                                <div className='p-3 bg-dark rounded-5 mb-2' key={'init_'+initIndex}>
                                    <div className={`mb-0 cursorPointer`} 
                                        onClick={() => expandInitiative('initiativeSection_'+init, init)}
                                    >
                                        <div className='d-flex justify-content-between'>
                                            <div><span className={`far ${state.selectedInitSection === 'initiativeSection_'+init ? "fa-minus-circle" : "fa-plus-circle"} mr-2`} ></span>{state['initiative_'+init]}</div>
                                        </div>                                
                                    </div>
                                    {state.selectedInitSection === 'initiativeSection_'+init ?
                                        <div className='border-lightGray rounded p-3 mt-2'>
                                            <div className='d-flex'>
                                                <div className='py-1 w-40'>
                                                    <div className='d-flex justify-content-between'>
                                                        <p className="b-block mb-0">Initiative <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span></p>
                                                        {state.inputValidationError && !state['initiative_'+init] ?
                                                            <span className='mb-0 text-red ml-2'>required</span>
                                                        : null}
                                                    </div>
                                                    <Input
                                                        type='text'
                                                        placeholder='Enter initaitve name'
                                                        className={`inputTextbox bg-transparent border-lightGray text-white`}
                                                        // maxlength='128'
                                                        value={state['initiative_'+init] ? state['initiative_'+init] : ''}
                                                        onChange={e => handleInitativeInputChange('initiative', e.target.value, init)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between mt-2'>
                                                <p className="b-block mb-0">Tags</p>
                                            </div>
                                            <div className='d-flex mt-1'>
                                                <div className='py-1 w-40'>
                                                    <div onClick={(event) => {
                                                            if(!state['isTagOpen_'+init]) {
                                                                event.preventDefault();
                                                                handleChildClick(event, 'child', 'singleDropDown', 'isTagOpen_'+init)
                                                            }
                                                        }}
                                                    >
                                                        <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state["tagKey_"+init] ? 'text-white' : 'placeholder'}`}>
                                                            {state["tagKey_"+init] ? state["tagKey_"+init] : 'Select'}
                                                            <i className='fa fa-caret-down mt-1'/>
                                                        </p>
                                                        {state['isTagOpen_'+init] ?
                                                            <MultiSelectSection
                                                                options={tagKeys}
                                                                selectedValues={state["tagKey_"+init] ? state["tagKey_"+init] : ''}
                                                                callbackMultiSelect={(value) => {
                                                                    if(!value || typeof(value) === 'string') {
                                                                        setState(prevState => ({ ...prevState, ["tagKey_"+init]: value,  ["tagValueOptions_"+init]: [], ["tagValue_"+init]: []}))
                                                                        getAllTagFunction("tagValues_"+init, value, init)
                                                                    } else {
                                                                        value.preventDefault()
                                                                        handleChildClick(value, "search", 'singleDropDown', "")
                                                                    }
                                                                }}  
                                                                singleSelection={true}
                                                                hideSearch={false}
                                                                topClass={'auto'}
                                                                widthClass={'minWidth220'}
                                                            />
                                                        : null}
                                                    </div>
                                                </div>
                                                <div className='py-1 w-40 ml-3'>
                                                    <div onClick={(event) => {
                                                            event.preventDefault();
                                                            handleChildClick(event, 'child', 'muliselectDropDown', ['isTagValueOpen_'+init])
                                                        }}
                                                    >
                                                    <p className={`d-flex justify-content-between mr-2 mb-0 bg-transparent border-lightGray p-2 rounded-5 ${state["tagKey_"+init] ? 'text-white' : 'placeholder'}`}>
                                                        {state["tagValue_"+init] && state["tagValue_"+init].length ? state["tagValue_"+init].length +' Selected'  : 'Select'}
                                                            <i className='fa fa-caret-down mt-1'/>
                                                        </p>
                                                        {state['isTagValueOpen_'+init] ?
                                                            <MultiSelectSection
                                                                // fields={["provider_name", "provider_name"]}
                                                                options={state['tagValues_'+init]}
                                                                selectedValues={state["tagValue_"+init] ? state["tagValue_"+init] : ''}
                                                                callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, ["tagValue_"+init]: value }))}
                                                                singleSelection={false}
                                                                hideSearch={false}
                                                                topClass={'auto'}
                                                                widthClass={'minWidth220'}
                                                            />
                                                        : null}
                                                    </div>
                                                </div>
                                                <span className={`far fa-plus cursorPointer f18 align-self-center ml-4`} onClick={() => addInitiativeTags(init)}></span>
                                            </div>
                                            <div className='d-flex flex-wrap'>
                                                {state["selectedTags_"+init] && state["selectedTags_"+init].length ? 
                                                    state["selectedTags_"+init].map((tag, index) => {
                                                        return(
                                                            <span key={'tag_'+init+'_'+index} className="badge badge-secondary mr-2 mt-2 align-self-center f14 px-2">{tag.key +" : "+tag.value}
                                                                <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removeInitiativeTags(tag, init) } ></i>
                                                            </span>
                                                        )
                                                    })
                                                : null}
                                            </div>
                                        </div>
                                    : null}
                                    <div className='d-flex mt-2'>
                                        {state.pageType !== 'View' ?
                                            <React.Fragment>
                                            {state.initArray.length === initIndex+1 && state['initiative_'+init] ?
                                                <span className={`align-self-center mr-3 cursor-pointer text-info`} onClick={() => addFilterArray('initArray')} >
                                                    {/* <i className={`far fa-plus f16 mt-2 mb-1 align-self-end mr-2`}></i> */} 
                                                    Add
                                                </span>
                                            : null}
                                            {state.initArray.length > 1 ?
                                                <span className={`align-self-center mr-2 cursor-pointer text-danger`} onClick={() => {
                                                    // removeFilterArray('initArray', initIndex)
                                                    confirmActionDetails('initArray', initIndex)
                                                }} >Remove
                                                </span>
                                            : null}
                                            </React.Fragment>
                                        : null}
                                    </div>
                                    
                                    {state.confirmAction && state.confirmDetails ?
                                        <AlertConfirmation
                                            confirmDetails={state.confirmDetails}
                                            confirmation={(action) => {
                                                if(action === 'confirmed') {
                                                    removeFilterArray(state.confirmDetails.field, state.confirmDetails.index)
                                                }
                                                setState(prevState => ({ ...prevState, confirmAction: false, confirmDetails: false }))
                                            }}
                                        />
                                    : null}
                                </div>
                            )
                        })
                    : null}                    
                </div>
			</div>
		</div>
	)
}

export default CreateMappingLeft