import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { Input } from "reactstrap";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";

const ExistingDatabaseSection = (props) => {
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const [state, setState] = useState({});

  const callPageVist = useCallback(() => {
    let databaseConfiguration =
      createUpdateCluster && createUpdateCluster.database_configuration
        ? createUpdateCluster.database_configuration
        : {};

        if (databaseConfiguration) {
          Object.keys(databaseConfiguration).map(([key, value]) => {
            let clusterFields = [
              "database",
              "user_name",
              "password_secret_arn"
            ];
            if (clusterFields.includes(key)) {
              setState((prevState) => ({ ...prevState, [key]: value }));
            }
          });
        }
  }, [createUpdateCluster, propsClusterFilters]);

  useEffect(() => callPageVist(), [callPageVist]);

  const handleDatabaseInput = (label, value) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let databaseConfiguration = obj.database_configuration
      ? obj.database_configuration
      : {};
    databaseConfiguration[label] = value;

    obj["database_configuration"] = databaseConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div
      className="rounded bg-dark p-3"
      onClick={(event) => handleChildClick(event, "parent")}
    >
      <div className={"d-flex"}>
        <div className="py-1 w-100">
          <div className="w-33">
            <p className="b-block mb-0">Database Uri</p>
            <Input
              type="text"
              placeholder="Enter database uri"
              className={`inputTextbox bg-transparent border-lightGray text-white`}
              // maxlength='128'
              value={
                createUpdateCluster &&
                createUpdateCluster.database_configuration &&
                createUpdateCluster.database_configuration.database
                  ? createUpdateCluster.database_configuration.database
                  : ""
              }
              onChange={(e) =>
                handleDatabaseInput("database", e.target.value)
              }
            />
          </div>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.database_configuration &&
          propsClusterFilters.description.database_configuration
            .database ? (
            <p className="mt-1 mb-0 w-50 text-info">
              {
                propsClusterFilters.description.database_configuration
                  .database
              }
            </p>
          ) : null}
        </div>
      </div>
      <div className={"d-flex mt-3"}>
        <div className="py-1 w-100">
          <div className="w-33">
            <p className="b-block mb-0">Username</p>
            <Input
              type="text"
              placeholder="Enter username (Eg. admin)"
              className={`inputTextbox bg-transparent border-lightGray text-white`}
              // maxlength='128'
              value={
                createUpdateCluster &&
                createUpdateCluster.database_configuration &&
                createUpdateCluster.database_configuration.user_name
                  ? createUpdateCluster.database_configuration.user_name
                  : ""
              }
              onChange={(e) => handleDatabaseInput("user_name", e.target.value)}
            />
          </div>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.database_configuration &&
          propsClusterFilters.description.database_configuration.user_name ? (
            <p className="mt-1 mb-0 text-info">
              {propsClusterFilters.description.database_configuration.user_name}
            </p>
          ) : null}
        </div>
      </div>
      <div className={"d-flex mt-3"}>
        <div className="py-1 w-100">
          <div className="w-67">
            <p className="b-block mb-0">
              Password secret amazon resource name (ARN)
            </p>
            <Input
              type="text"
              placeholder="Enter secret arn"
              className={`inputTextbox bg-transparent border-lightGray text-white`}
              // maxlength='128'
              value={
                createUpdateCluster &&
                createUpdateCluster.database_configuration &&
                createUpdateCluster.database_configuration.password_secret_arn
                  ? createUpdateCluster.database_configuration
                      .password_secret_arn
                  : ""
              }
              onChange={(e) =>
                handleDatabaseInput("password_secret_arn", e.target.value)
              }
            />
          </div>
          {propsClusterFilters &&
          propsClusterFilters.description &&
          propsClusterFilters.description.database_configuration &&
          propsClusterFilters.description.database_configuration
            .password_secret_arn ? (
            <p className="mt-1 mb-0 text-info">
              {
                propsClusterFilters.description.database_configuration
                  .password_secret_arn
              }
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ExistingDatabaseSection;
