/*************************************************
 * Collider
 * @exports
 * @file CreateCluster.js
 * @author Prakash // on 30/06/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import { Store as CommonNotification } from "react-notifications-component";
// import { momentConvertionUtcToLocalTime } from '../../../utils/utility'
import {
  listAllProviders,
  listClusterFilters,
  setCommonPropsDetails,
} from "../../../actions/commonActionNew";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import {
  createCluster,
  updateCluster,
  getClusterFilters,
} from "../../../actions/Collider/ClusterAction";
import ClusterSection from "./ClusterSection";
import HeadNodeSection from "./HeadNodeSection";
import DatabaseSection from "./DatabaseSection";
import QueueSection from "./QueueSection";
import StorageSection from "./StorageSection";
import ReviewSection from "./ReviewSection"
import { URL_PATH } from "../../../config/urlPath";
import AuthenticationSection from "./AuthenticationSection";
import MultiSelectSection from "../../common/MultiSelectSection";
import {
  capitalizeAllLetter,
  getAccountNameFromId,
  getRegionName,
} from "../../../utils/utility";

const CreateCluster = () => {
  const providers = useSelector((state) => state?.filters?.providers || false);
  const propProjAccounts = useSelector(
    (state) => state?.filters?.commonPropsDetails?.propProjAccounts || false
  );
  const propProjRegions = useSelector(
    (state) => state?.filters?.commonPropsDetails?.propProjRegions || false
  );
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );
  // console.log(createUpdateCluster);
  const dispatch = useDispatch(); // Create a dispatch function
  const navigate = useNavigate();
  const location = useLocation();
  const receivedData = location.state || false;

  const [pageNumber, setPageNumber] = useState(0);
  // let pageNumber = 0
  const [state, setState] = useState({
    selectedProvider: "",
    selectedAccount: "",
    selectedRegion: "",
    steps: [
      "Cluster",
      "Authentication",
      "Head Node",
      "Queues",
      "Database",
      "Storage",
      "Review"
    ],
    selectedSection: "Cluster",
    schedulers: [{ label: "Slurm", value: "slurm" }],
    selectedScheduler: "slurm",
  });

  useEffect(() => {
    if (receivedData && Object.entries(receivedData).length) {
      setState((prevState) => ({
        ...prevState,
        selectedData: receivedData.selectedData
          ? receivedData.selectedData
          : "",
        pageType: receivedData.pageType ? receivedData.pageType : "",
      }));
      if (receivedData.pageType === "Create") {
        setState((prevState) => ({ ...prevState, pageType: "Create" }));
        let obj = {};
        obj.provider = "aws";
        obj.scheduler = "cluster";
        dispatch(setHpcPropsDetails("createUpdateCluster", obj));
      } else if (receivedData.pageType === "Fix"){
        setState((prevState) => ({ ...prevState, pageType: "Create" }));
        dispatch(
          setHpcPropsDetails("createUpdateCluster", receivedData.selectedData)
        );
      } else {
        setState((prevState) => ({ ...prevState, pageType: receivedData.pageType }));
        dispatch(
          setHpcPropsDetails("createUpdateCluster", receivedData.selectedData)
        );
      }
    } else {
    }
  }, [receivedData, dispatch]);

  // list all providers
  useEffect(() => {
    if (providers) {
      setState((prevState) => ({
        ...prevState,
        providers: providers,
        selectedProvider:
          providers && providers.length
            ? providers.filter((e) => e.provider_name === "AWS").length
              ? "AWS"
              : providers[0].provider_name
            : "",
      }));
    } else {
      let params = {};
      dispatch(listAllProviders(params));
    }
  }, [dispatch, providers]);

  useEffect(() => {
    if (state.selectedProvider) {
      setState((prevState) => ({ ...prevState, callSearch: true }));
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      params.aggregate_by = "account_id";
      let label = state.selectedProvider;

      if (propProjAccounts && propProjAccounts.hasOwnProperty(label)) {
        setState((prevState) => ({
          ...prevState,
          accounts: propProjAccounts[label],
        }));
      } else {
        dispatch(listClusterFilters(params)).then((response) => {
          if (response) {
            if (response.length) {
              let obj = propProjAccounts ? propProjAccounts : {};
              obj[label] = response;
              dispatch(setCommonPropsDetails("propProjAccounts", obj));
              setState((prevState) => ({ ...prevState, accounts: response }));
            }
          }
        });
      }
    }
  }, [state.selectedProvider, propProjAccounts, dispatch]);

  useEffect(() => {
    if (state.selectedProvider) {
      setState((prevState) => ({ ...prevState, callSearch: true }));
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      params.aggregate_by = "region";
      let label = state.selectedProvider;
      if (state.selectedAccount && state.selectedAccount.length) {
        label = "_" + state.selectedAccount;
        params.account_id = state.selectedAccount;
      }

      if (propProjRegions && propProjRegions.hasOwnProperty(label)) {
        setState((prevState) => ({
          ...prevState,
          regions: propProjRegions[label],
        }));
      } else {
        dispatch(listClusterFilters(params)).then((response) => {
          if (response && response.length) {
            let obj = propProjRegions ? propProjRegions : {};
            obj[label] = response;
            dispatch(setCommonPropsDetails("propProjRegions", obj));
            setState((prevState) => ({ ...prevState, regions: response }));
          }
        });
      }
    }
  }, [
    state.selectedProvider,
    state.selectedAccount,
    propProjRegions,
    dispatch,
  ]);

  useEffect(() => {
    if (state.selectedProvider) {
      setState((prevState) => ({ ...prevState, callSearch: true }));
      let params = {};
      params.provider = state.selectedProvider.toLowerCase();
      if (state.selectedAccount) {
        params.account_id = state.selectedAccount;
      }
      if (state.selectedRegion) {
        params.region = state.selectedRegion;
      }

      if (!propsClusterFilters || !Object.entries(propsClusterFilters).length) {
        dispatch(getClusterFilters(params)).then((response) => {
          if (response) {
            dispatch(setHpcPropsDetails("propsClusterFilters", response));
          }
        });
      }
    }
  }, [
    state.selectedProvider,
    state.selectedAccount,
    state.selectedRegion,
    dispatch,
    propsClusterFilters,
  ]);

  const handleInputChange = (label, value) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    obj[label] = value;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const onFinish = () => {
    let hasError = false;
    let data = createUpdateCluster;

    if (!data.provider || !data.account_id || !data.region) {
      hasError = true;
    }
    if (!data.cluster_name) {
      hasError = true;
    }
    dispatch(setHpcPropsDetails("createUpdateClusterValidation", hasError));
    // setState(prevState => ({ ...prevState, saveLoading: hasError ? false : true }))
    setState((prevState) => ({
      ...prevState,
      hasError,
      saveLoading: hasError ? false : true,
    }));

    if (!hasError) {
      if (!state.pageType || state.pageType !== "Edit") {
        setState((prevState) => ({
          ...prevState,
          callCreateCluster: true,
          saveLoading: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          callUpdateCluster: true,
          saveLoading: true,
        }));
      }
    }
  };

  useEffect(() => {
    if (state.callCreateCluster) {
      setState((prevState) => ({ ...prevState, callCreateCluster: false }));
      let params = createUpdateCluster;
      delete params.scheduler;
      dispatch(createCluster(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in saving cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluster saved successfully";
            dispatch(setHpcPropsDetails("createUpdateCluster", {}));
            navigate(URL_PATH.CLUSTER_DETAILS);
          } else {
            setState((prevState) => ({ ...prevState, saveLoading: false }));
          }
          CommonNotification.addNotification({
            //title: 'Wonderful!',
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ['animate__animated', 'animate__fadeIn'],
            // animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callCreateCluster, createUpdateCluster, dispatch, navigate]);

  useEffect(() => {
    if (state.callUpdateCluster) {
      setState((prevState) => ({ ...prevState, callUpdateCluster: false }));
      let params = createUpdateCluster;
      dispatch(updateCluster(params)).then((response) => {
        if (response) {
          let messageType = "danger";
          let message = response.message
            ? response.message
            : "Error in updating cluster";
          if (response.status) {
            messageType = "success";
            message = response.message
              ? response.message
              : "Cluser updated successfully";
          }
          setState((prevState) => ({ ...prevState, saveLoading: false }));
          CommonNotification.addNotification({
            //title: "Wonderful!",
            message: message,
            type: messageType,
            insert: "top",
            container: "top-center",
            // animationIn: ["animate__animated", "animate__fadeIn"],
            // animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: false,
              pauseOnHover: true,
              showIcon: true,
            },
          });

          if (response.status) {
            setState((prevState) => ({
              ...prevState,
              disableFinish: false,
              finishBtn: "Save",
              queryLoading: false,
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showLoading: false,
              finishBtn: "Saved",
            }));
          }
        } else {
          setState((prevState) => ({
            ...prevState,
            disableFinish: false,
            finishBtn: "Save",
            queryLoading: false,
          }));
        }
      });
    }
  }, [state.callUpdateCluster, createUpdateCluster, dispatch]);

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  const handlePreviousPage = () => {
    setPageNumber(pageNumber - 1);
    setState((prevState) => ({
      ...prevState,
      selectedSection: state.steps[pageNumber - 1],
    }));
  };

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1);
    setState((prevState) => ({
      ...prevState,
      selectedSection: state.steps[pageNumber + 1],
    }));
  };

  return (
    <div
      className="container-fluid overflow-auto flex-grow-1 bg-muted"
      onClick={(event) => handleChildClick(event, "parent")}
    >
      <div
        className={`loaderOverlay ${state.showLoading ? "" : "displayNone"}`}
      >
        <div className="overlayEqualizerLoader">
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 p-0">
          <div className="title-section pb-3">
            <div className="d-flex justify-content-between">
              <div className="align-self-center">
                <div className="d-flex">
                  <h6 className="text-white m-0">
                    {state.pageType === "Edit" ? "Modify" : "Create"} Cluster
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12 p-0">
          <div className="rounded bg-dark p-3 m-2">
            <div className={`row`}>
              <div className="py-1 col-lg-3 col-md-4 col-sm-6">
                <div className="d-flex justify-content-between">
                  <label className="align-self-center m-0">Provider </label>
                  {state.hasError &&
                  createUpdateCluster &&
                  !createUpdateCluster.provider ? (
                    <small className="text-issues">required</small>
                  ) : null}
                </div>
                <div
                  className="border-lightGray bg-transparent rounded-5"
                  onClick={(event) => {
                    if (!state.isProviderOpen) {
                      event.preventDefault();
                      handleChildClick(
                        event,
                        "child",
                        "singleDropDown",
                        "isProviderOpen"
                      );
                    }
                  }}
                >
                  <p
                    className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${
                      createUpdateCluster && createUpdateCluster.provider
                        ? "text-white hiddenEllipses"
                        : "placeholder"
                    }`}
                  >
                    {createUpdateCluster && createUpdateCluster.provider
                      ? capitalizeAllLetter(createUpdateCluster.provider)
                      : "Select"}
                    <i className="fa fa-caret-down mt-1" />
                  </p>
                  {state.isProviderOpen1 && state.pageType !== "Edit" ? (
                    <MultiSelectSection
                      fields={["provider_name", "provider_name"]}
                      options={state.providers}
                      selectedValues={
                        state.selectedProvider ? state.selectedProvider : ""
                      }
                      callbackMultiSelect={(value) => {
                        if (!value || typeof value === "string") {
                          handleInputChange("provider", value.toLowerCase());
                          dispatch(
                            setHpcPropsDetails("propsClusterFilters", {})
                          );
                          setState((prevState) => ({
                            ...prevState,
                            selectedProvider: value,
                            accounts: [],
                            regions: [],
                            selectedAccount: "",
                            selectedRegion: "",
                          }));
                        } else {
                          value.preventDefault();
                          handleChildClick(
                            value,
                            "search",
                            "singleDropDown",
                            ""
                          );
                        }
                      }}
                      singleSelection={true}
                      hideSearch={false}
                      topClass={"auto"}
                      widthClass={"minWidth220"}
                    />
                  ) : null}
                </div>
              </div>
              <div className="py-1 col-lg-3 col-md-4 col-sm-6">
                <div className="d-flex justify-content-between">
                  <label className="align-self-center m-0">Account </label>
                  {state.hasError &&
                  createUpdateCluster &&
                  !createUpdateCluster.account_id ? (
                    <small className="text-issues">required</small>
                  ) : null}
                </div>
                <div
                  className="border-lightGray bg-transparent rounded-5"
                  onClick={(event) => {
                    if (!state.isAccountOpen) {
                      event.preventDefault();
                      handleChildClick(
                        event,
                        "child",
                        "singleDropDown",
                        "isAccountOpen"
                      );
                    }
                  }}
                >
                  <p
                    className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${
                      createUpdateCluster && createUpdateCluster.account_id
                        ? "text-white hiddenEllipses"
                        : "placeholder"
                    }`}
                  >
                    {createUpdateCluster && createUpdateCluster.account_id
                      ? getAccountNameFromId(
                          createUpdateCluster.account_id,
                          state.accounts
                        )
                      : "Select"}
                    <i className="fa fa-caret-down mt-1" />
                  </p>
                  {state.isAccountOpen && state.pageType !== "Edit" ? (
                    <MultiSelectSection
                      fields={["account_id", "account_name"]}
                      options={state.accounts}
                      selectedValues={
                        state.selectedAccount ? state.selectedAccount : []
                      }
                      callbackMultiSelect={(value) => {
                        if (!value || typeof value === "string") {
                          setState((prevState) => ({
                            ...prevState,
                            selectedAccount: value,
                            cluster_name: "",
                          }));
                          handleInputChange("account_id", value);
                          handleInputChange("region", "");
                          dispatch(
                            setHpcPropsDetails("propsClusterFilters", {})
                          );
                        } else {
                          value.preventDefault();
                          handleChildClick(
                            value,
                            "search",
                            "singleDropDown",
                            ""
                          );
                        }
                      }}
                      singleSelection={true}
                      hideSearch={false}
                      topClass={"auto"}
                      widthClass={"minWidth220"}
                      // addAllField={true}
                    />
                  ) : null}
                </div>
              </div>
              <div className="py-1 col-lg-3 col-md-4 col-sm-6 mt-lg-0 mt-sm-2">
                <div className="d-flex justify-content-between">
                  <label className="align-self-center m-0">Region </label>
                  {state.hasError &&
                  createUpdateCluster &&
                  !createUpdateCluster.region ? (
                    <small className="text-issues hid">required</small>
                  ) : null}
                </div>
                <div
                  className="border-lightGray bg-transparent rounded-5"
                  onClick={(event) => {
                    if (!state.isRegionOpen) {
                      event.preventDefault();
                      handleChildClick(
                        event,
                        "child",
                        "singleDropDown",
                        "isRegionOpen"
                      );
                    }
                  }}
                >
                  <p
                    className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${
                      createUpdateCluster && createUpdateCluster.region
                        ? "text-white hiddenEllipses"
                        : "placeholder"
                    }`}
                  >
                    {createUpdateCluster && createUpdateCluster.region
                      ? getRegionName(createUpdateCluster.region, state.regions)
                      : "Select"}
                    <i className="fa fa-caret-down mt-1" />
                  </p>
                  {state.isRegionOpen && state.pageType !== "Edit" ? (
                    <MultiSelectSection
                      fields={["region", "name"]}
                      options={state.regions}
                      selectedValues={
                        state.selectedRegion ? state.selectedRegion : []
                      }
                      callbackMultiSelect={(value) => {
                        if (!value || typeof value === "string") {
                          setState((prevState) => ({
                            ...prevState,
                            selectedRegion: value,
                            cluster_name: "",
                          }));
                          handleInputChange("region", value);
                          dispatch(
                            setHpcPropsDetails("propsClusterFilters", {})
                          );
                        } else {
                          value.preventDefault();
                          handleChildClick(
                            value,
                            "search",
                            "singleDropDown",
                            ""
                          );
                        }
                      }}
                      singleSelection={true}
                      hideSearch={false}
                      topClass={"auto"}
                      widthClass={"minWidth220"}
                      // addAllField={true}
                    />
                  ) : null}
                </div>
              </div>
              <div className="py-1 col-lg-3 col-md-4 col-sm-6 mt-lg-0 mt-sm-2">
                <div className="d-flex justify-content-between">
                  <label className="align-self-center m-0">Scheduler</label>
                  {state.hasError &&
                  createUpdateCluster &&
                  !createUpdateCluster.scheduler ? (
                    <small className="text-issues">required</small>
                  ) : null}
                </div>
                <div
                  className="border-lightGray bg-transparent rounded-5"
                  onClick={(event) => {
                    if (!state.showScheduler) {
                      event.preventDefault();
                      handleChildClick(
                        event,
                        "child",
                        "singleDropDown",
                        "showScheduler"
                      );
                    }
                  }}
                >
                  <p
                    className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 ${
                      state.selectedScheduler
                        ? "text-white hiddenEllipses"
                        : "placeholder"
                    }`}
                  >
                    {state.selectedScheduler
                      ? state.schedulers.filter(
                          (e) => e.value === state.selectedScheduler
                        )[0].label
                      : "Select"}
                    <i className="fa fa-caret-down mt-1" />
                  </p>
                  {state.showScheduler && state.pageType !== "Edit" ? (
                    <MultiSelectSection
                      fields={["value", "label"]}
                      options={state.schedulers}
                      selectedValues={
                        state.selectedScheduler ? state.selectedScheduler : ""
                      }
                      callbackMultiSelect={(value) => {
                        if (!value || typeof value === "string") {
                          handleInputChange("scheduler", value);
                          dispatch(
                            setHpcPropsDetails("propsClusterFilters", {})
                          );
                          setState((prevState) => ({
                            ...prevState,
                            selectedScheduler: value,
                          }));
                        } else {
                          value.preventDefault();
                          handleChildClick(
                            value,
                            "search",
                            "singleDropDown",
                            ""
                          );
                        }
                      }}
                      singleSelection={true}
                      hideSearch={false}
                      topClass={"auto"}
                      widthClass={"minWidth220"}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 p-2 mb-4">
          <div class="card bg-black3 mb-2">
            <div class="card-body p-0 w-100">
              <div class="stepper mx-0">
                {state.steps &&
                  state.steps.map((stp, index) => {
                    return (
                      <div
                        key={"stp_" + index}
                        class={`step ${
                          state.selectedSection === stp ? "" : "cursor-pointer"
                        }`}
                        onClick={() =>
                          {setPageNumber(index)
                             setState((prevState) => ({
                            ...prevState,
                            selectedSection: stp,
                          }))}
                        }
                      >
                        <div
                          class={`circle ${
                            state.selectedSection === stp ? "bg-info" : ""
                          }`}
                        >
                          {index + 1}
                        </div>
                        <div class="label">
                          <strong>Step {index + 1}</strong>
                          <p
                            className={`mb-0 ${
                              state.selectedSection === stp
                                ? "text-info"
                                : "cursor-pointer"
                            }`}
                          >
                            {stp}
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {state.selectedSection === "Cluster" ? (
            <ClusterSection
              pageMode={state.pageType}
              selectedData={state.selectedData}
            />
          ) : state.selectedSection === "Authentication" ? (
            <AuthenticationSection
              pageMode={state.pageType}
              selectedData={state.selectedData}
            />
          ) : state.selectedSection === "Head Node" ? (
            <HeadNodeSection
              pageMode={state.pageType}
              selectedData={state.selectedData}
            />
          ) : state.selectedSection === "Database" ? (
            <DatabaseSection
              pageMode={state.pageType}
              selectedData={state.selectedData}
              provider={state.provider}
              accounts={state.accounts}
              regions={state.regions}
            />
          ) : state.selectedSection === "Queues" ? (
            <QueueSection
              pageMode={state.pageType}
              selectedData={state.selectedData}
            />
          ) : state.selectedSection === "Storage" ? (
            <StorageSection
              pageMode={state.pageType}
              selectedData={state.selectedData}
              provider={state.provider}
              accounts={state.accounts}
              regions={state.regions}
            />
          ) : state.selectedSection === "Review" ? (
            <ReviewSection
              closeSidePanel={() => setState(prevState => ({ ...prevState, showClusterDetails: false, selectedData: {} }))}
              selectedClusterDetails={state.selectedData}
              detailsFormat={state.selectedData}
              pageMode={state.pageType}
              selectedData={state.selectedData}
              provider={state.provider}
              accounts={state.accounts}
              regions={state.regions}
            />
          ) : null}
        </div>
      </div>
      <div className="d-flex justify-content-end bg-transparent border-0 my-2">
        {state.pageType === "Edit" ? (
          <div className="py-1 align-self-center mr-3">
            <label className="mb-0">
              <input
                type="checkbox"
                className="inputdemo mt-1"
                checked={state.force_update ? true : false}
                onChange={() => {
                  let value = !state.force_update;
                  handleInputChange("force_update", value);
                }}
              />
              <span className="slider round small pl-1"></span>
            </label>
            <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
              Force update: Edit the cluster while the compute fleet is still
              running
            </span>
          </div>
        ) : null}
        <div className="d-flex">
          {state.pageType !== "View" ? (
            state.saveLoading ? (
              <button className={`btn btn-primary mr-2`}>
                <Spinner size="sm" color="light" className="mx-2" />
                {state.pageType === "Edit" ? "Updating" : "Saving"}
              </button>
            ) : (
              <button
                className={pageNumber === state.steps.length - 1 ? `d-flex justify-content-end cursorPointer btn bg-info text-white` : 'd-none'}
                onClick={() => onFinish()}
              >
                {state.pageType === "Edit" ? "Update" : "Submit"}
              </button>
            )
          ) : null}
          <p
            className={
              pageNumber === 0 ? "d-none" : "mb-0 btn btn-light text-muted ml-1"
            }
            onClick={() => handlePreviousPage()}
          >
            Previous
          </p>
          <p
            className={
              pageNumber < state.steps.length - 1
                ? "mb-0 btn btn-light text-muted ml-1"
                :  "d-none"
            }
            onClick={() => handleNextPage()}
          >
            Next
          </p>
          <p
            className="mb-0 btn btn-secondary text-white ml-1"
            onClick={() => {
              navigate(URL_PATH.CLUSTER_DETAILS);
            }}
          >
            Cancel
          </p>
          {/* <Link
                        className='float-right btn btn-light text-muted text-decoration-none ml-1'
                        to={URL_PATH.MANAGE_QOS}
                    >
                        Cancel
                    </Link> */}
        </div>
      </div>
    </div>
  );
};

export default CreateCluster;
