import React from 'react';
import moment from 'moment'
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { getDayFromSelectedDuration, momentDateGivenFormat } from '../../../../utils/utility'

class DurationFilterSection extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            showDate: false,
            showDatepicker: false,
            // selectedDuration: '+7d',
            inputComponent: this.props.inputComponent
            //pervious90Days: moment().subtract('365', 'days'),
        };

        this.handleDayClick = this.handleDayClick.bind(this);        
		this.handleResetClick = this.handleResetClick.bind(this);
    };

    componentDidMount = () => {}

    handleResetClick = () => {
        this.setState({ showDatepicker: true }, 
            () => this.getInitialState()
        );
	}

    getInitialState() {
		return {
		  from: undefined,
		  to: undefined,
		};
		
	}

	handleDayClick(day) {
		if(this.state.from === undefined) {
			// const range = DateUtils.addDayToRange(day, this.state);
			this.setState({ from: day });
		} else {
			// const newRange = {
			// 	from: this.state.from,
			// 	to: day
			// }
			// var Difference_In_Time = day.getTime() - this.state.from.getTime(); 			
			// var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			// if(Difference_In_Days < 6) {
			// 	return;
			// }

			// let durtaion = '+'+(Difference_In_Days+1)+'d';
			
			// const range = DateUtils.addDayToRange(day, newRange);
			this.setState(
				{ to: day, showDatepicker: false }
			);
		}
	}

    selectedOptionFunction = (value, inputs, sIndex) => {
        this.setState({ 
            showDurationDropDown: false,
            selectedDuration: value, 
            showDatepicker: value === 'custom' ? true : false,
            showDate: value === 'custom' ? true : false  
        }, 
            () => this.durationFinder() 
        )
    }

	durationFinder = () => {
		if(this.state.selectedDuration === 'custom') {
			this.setState({ from: undefined, to: undefined, showDatepicker: true, showDate: true })
		} else {            
            let days = getDayFromSelectedDuration(this.state.selectedDuration)
			let beginDate = moment().subtract(days, 'days')
			let closeDate = moment()
			//console.log(closeDate._d)
			this.setState({ from: beginDate._d, to: closeDate._d})
		}
	}

    applyFilter = () => {
        let obj = {}
        obj['from'] = this.state.from === undefined ? '' :  momentDateGivenFormat(this.state.from, "YYYY-MM-DD");
        obj['to'] = this.state.to === undefined ? '' : momentDateGivenFormat(this.state.to, "YYYY-MM-DD");
        obj['start_time'] = this.state.from === undefined ? '' :  moment(this.state.from).format("YYYY-MM-DD 00:00:00")
		obj['end_time'] = this.state.to === undefined ? '' :  moment(this.state.to).format("YYYY-MM-DD 23:59:59")
        obj['selectedDuration'] = this.state.selectedDuration
        
        return this.props.selectedInput(obj)
    }

    closeFilter = () => {
        return this.props.selectedInput(false);
    }
    
    render() {
        const { from, to } = this.state;
		const modifiers = { start: from, end: to };
        return (
            
            <div className='container-fluid mt-2 py-3 bg-dark3 containerBorderFilterSection'>
                <p className="text-white mb-2">Duration</p>
                <input type="text" className={`comparisonTextbox bg-transparent text-white border-mediumDarkGray py-2 pl-3`}
                    // onChange={e => {this.setState({ searchText: e.target.value }, () => this.filterOnchange())}}
                    // onKeyUp={this.onKeyUp}
                    value={this.state.selectedDuration && this.state.inputComponent[0].options.filter(e => e.value === this.state.selectedDuration).length ? this.state.inputComponent[0].options.filter(e => e.value === this.state.selectedDuration)[0].label : ""}
                    /*{this.state.selectedDefaultOption === '' ? this.state.searchText : this.state.selectedDefaultOption +': ' } */
                    onClick={ () => this.setState({ showDurationDropDown: true,   }) }
                    placeholder={"Select Duration"}
                />
                {this.state.showDurationDropDown ? 
                    <div className={`dropdownOptions topAuto mt-1 bg-white border-mediumDarkGray position-unset w-25 border-radius-0`}>
                        {this.state.inputComponent[0] && this.state.inputComponent[0].options && this.state.inputComponent[0].options.map((row, sIndex) => {
                            return(
                                <button className="d-flex justify-content-between bg-transparent text-black cursorPointer" onClick={() => this.selectedOptionFunction(row.value, this.state.inputComponent[0], sIndex)}>
                                    <p className="mb-0 text-left">{row.label}</p>
                                </button>
                            )
                        })}
                    </div>
                : null}
                <div className={`d-flex my-2 zapInputDark ${this.state.showDate ? '' : 'displayNone'}`}>
                    <div className="mr-3 mt-2 w-20">
                        <p className="text-white mb-2">From Date</p>
                        <input 
                            type="text" 
                            className={`pl-2 bg-transparent border-mediumDarkGray w-100 text-white`}
                            style={{minHeight: "38px"}}
                            placeholder={"From date"}
                            value={this.state.from === undefined ? '' :  momentDateGivenFormat(this.state.from, "DD MMM YYYY")}
                            onClick={ () => this.setState({ showDatepicker: this.state.showDatepicker ? false : true }) }
                        />
                    </div>
                    <div className="mt-2 w-20">
                        <p className="text-whtie mb-2">To Date</p>
                        <input 
                            type="text" 
                            className={`pl-2 bg-transparent border-mediumDarkGray w-100 text-white`}
                            style={{minHeight: "38px"}}
                            placeholder={"To date"}
                            value={this.state.to === undefined ? '' :  momentDateGivenFormat(this.state.to, "DD MMM YYYY")}
                            onClick={ () => this.setState({ showDatepicker: this.state.showDatepicker ? false : true }) }
                        />
                    </div>
                    <div className={`mr-1 align-self-center ${this.state.from !== undefined || this.state.to !== undefined ? '' : 'displayNone'} `}>
                        <label className="text-white m-0 w-100">&nbsp;</label>
                        <p className="mb-0 text-cyon mt-3 ml-4" onClick={this.handleResetClick}>Reset</p>
                    </div>
                </div>

                <div className={`RangeExample ${this.state.showDatepicker ? '' : 'displayNone'}`}>
                    <DayPicker
                        toMonth={new Date()}
                        disabledDays={[
                            {
                                after: new Date(),
                                //before: (new Date(this.state.pervious90Days))
                            },
                        ]}
                        className="Selectable"
                        numberOfMonths= {2}
                        selectedDays={[from, { from, to }]}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                        tabIndex='none'
                    />
                    
                </div>
                
                <div className='row mt-2 mb-3'>
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 justify-content-end d-flex">
                        <span className="dropCancelBtn d-block p-2 mr-2" onClick={ () => this.closeFilter()}>
                            Cancel
                        </span>
                        <span className="dropApplyBtn d-block p-2" onClick={ () => this.applyFilter()}>
                            Apply
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default DurationFilterSection;
