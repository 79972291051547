/*************************************************
 * Kiosk
 * @exports
 * @file Api.js
 * @author Prakash // on 19/12/2019
 * @copyright © 2019 Kiosk. All rights reserved.
 *************************************************/
import axios from 'axios';
import { msalConfig, tokenRequest } from '../authConfig'
import { PublicClientApplication } from "@azure/msal-browser";
import { KIOSK_END_POJNT } from '../config'
import { momentDateFormat, subMinutes } from '../utils/utility';

const msalInstance = new PublicClientApplication(msalConfig);

let tokenAcquired = false;

// Define an asynchronous function to initialize MSAL and acquire the token
const initializeMsal = async () => {
    try {
        await msalInstance.initialize();
        const account = msalInstance.getAllAccounts()[0];
        
        if(account.idTokenClaims) {
            if(account.idTokenClaims.exp) {
                let date = new Date(account.idTokenClaims.exp * 1000); // Convert seconds to milliseconds
                if(subMinutes(momentDateFormat(date,'YYYY-MM-DD HH:mm:ss'), 20) < momentDateFormat(new Date(), 'YYYY-MM-DD HH:mm:ss')) {
                    tokenAcquired = false 
                } else if(localStorage.getItem('ACCESS_TOKEN')){
                    tokenAcquired = true
                }
            } else {
                tokenAcquired = false
            }
        } else {
            tokenAcquired = false
        }

        if (!tokenAcquired && account) {
            let accessTokenRequest = tokenRequest
            accessTokenRequest.account = msalInstance.getAllAccounts()[0];
            let data = await msalInstance.acquireTokenSilent(accessTokenRequest).then((response) => {
                const accessToken = response?.idToken;
                const expiresOn = response?.expiresOn;
                
                localStorage.setItem('ACCESS_TOKEN', accessToken ?? '');
                localStorage.setItem('ACCESS_TOKEN_EXPIRES_ON', expiresOn ?? '');
                tokenAcquired = true;
                
                return response?.idToken
                
            })
            .catch(error => {
                localStorage.clear();
                sessionStorage.clear()
                
                const logoutRequest = {
                    postLogoutRedirectUri: msalConfig.auth.redirectUri,
                };

                msalInstance.logoutRedirect(logoutRequest)
            })
            return data
        } else if(tokenAcquired && account) {
            return localStorage.getItem('ACCESS_TOKEN')
        } else if(!tokenAcquired || !account) {
            const logoutRequest = {
                postLogoutRedirectUri: msalConfig.auth.redirectUri,
            };

            msalInstance.logoutRedirect(logoutRequest)
        }
    } catch (error) {
        console.error('MSAL initialization error:', error);
    }
}

const ApiCalls = axios.create({ 
    baseURL: KIOSK_END_POJNT 
}) // Replace with your API's base URL });

ApiCalls.interceptors.request.use(async (config) => {
    if (typeof(config.formContentType) == 'undefined')
        config.headers['Content-Type'] = 'application/json'
    let accessToken = await initializeMsal()
    if(accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;        
    }
    return config
})

export default ApiCalls;