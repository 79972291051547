import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { URL_PATH } from '../config/urlPath';
// import { currentLocaltime } from '../utils/utility'

import CostRecommendation from '../components/cost/Recommendations/Recommendations'
import CostExplorer from '../components/cost/Explorer/LandingPage'
import CostAnomalies from '../components/cost/Anomalies/LandingPage'
import CostQueries from '../components/cost/Queries/QueryEvents' //still in class component
import CostComparison from '../components/cost/Comparison/LandingPage'

import CostBudgetDashboard from '../components/cost/Budget/BudgetDashboard'
import CostBudgetList from '../components/cost/Budget/ManageBudget'
import CostBudgetCreate from '../components/cost/Budget/CreateBudget/CreateNewBudget'

import HpcBudget from '../components/hpc/Budget/LandingPage'
import HpcCreateBudget from '../components/hpc/Budget/CreateBudget/CreateNewBudget'
import HpcSpendboard from '../components/hpc/Spendboard/LandingPage'
import HpcDashboard from '../components/hpc/Dashboard/LandingPage'
import HpcJobStatus from '../components/JobStatus/Dashboard';
// import Spendboard from '../components/Spendboard';
import HpcCatalogLandingPage from '../components/Catalog/LandingPage'
import HpcWorkStationRequestLandingPage from '../components/Catalog/Request/LandingPage'
import HpcClusterDetails from '../components/cluster/ClusterDetails'
import HpcCreateCluster from '../components/cluster/Create/CreateCluster'
import HpcQueueDetails from '../components/Queues/QueueDetails'
import HpcObservability from '../components/Observability/LandingPage'
import HpcAlerts from '../components/hpc/Alerts/LandingPage'
import HpcReports from '../components/hpc/Reports/ReportSample'
import HpcStatus from '../components/hpc/Status/LandingPage'
import HpcLicenses from '../components/hpc/Licenses/LandingPage'


import AdminUsers from '../components/Admin/Users/ManageUsers'
import AdminDepartmentMapping from '../components/Admin/DepartmentMapping/LandingPage'
import AdminDepartmentMappingCreate from '../components/Admin/DepartmentMapping/CreateMapping'

import ResourcesQueryEvents from '../components/resources/Queries/QueryEvents'
import LifecycleLandingPage from '../components/resources/Lifecycle/LandingPage'
import ResourcesLandingPage from '../components/resources/dashboard/LandingPage'
import ResourcesSearch from '../components/resources/searchModule/Search'
import AssetsComparison from '../components/resources/comparison/AssetsComparison'

import GovernanceDashboard from '../components/governance/Dashboard'
import GovernanceManageRules from '../components/governance/ManagePolicies'
import GovernanceCreateNewRule from '../components/governance/PolicyDetails'
import GovernanceEditNewRule from '../components/governance/PolicyDetails'


import LoadingPage from '../components/loadingPage';
import PermissionDenied from '../components/PermissionDenied';

import Files from '../components/hpc/files';
import ManageQos from '../components/hpc/Qos/LandingPage'
import CreateUpdateQos from '../components/hpc/Qos/CreateUpdateQos'
import CreateUpdateGroup from '../components/hpc/Qos/CreateUpdateGroup'

import LifecycleNotifications from '../components/common/LifecycleNotifications'


export const AppRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<HpcJobStatus />} />
      <Route path={URL_PATH.LOADING_PAGE} element={<LoadingPage />} />
      <Route path={URL_PATH.LIFECYCLE_NOTIFICATIONS} element={<LifecycleNotifications />} />
      <Route path={URL_PATH.PERMISSION_DENINED} element={<PermissionDenied />} />
      <Route path={URL_PATH.JOB_STATUS} element={<HpcJobStatus />} />
      {/* <Route path={URL_PATH.SPEND_BOARD} element={<Spendboard />} /> */}
      <Route path={URL_PATH.CATALOG_LANDINGPAGE} element={<HpcCatalogLandingPage />} />
      <Route path={URL_PATH.WORKSTATION_REQUEST_LANDINGPAGE} element={<HpcWorkStationRequestLandingPage />} />
      <Route path={URL_PATH.CLUSTER_DETAILS} element={<HpcClusterDetails />} />
      <Route path={URL_PATH.CREATE_CLUSTER} element={<HpcCreateCluster />} />
      <Route path={URL_PATH.QUEUE_DETAILS} element={<HpcQueueDetails />} />
      <Route path={URL_PATH.OBSERVABILITY} element={<HpcObservability />} />
      <Route path={URL_PATH.HPC_BUDGET} element={<HpcBudget />} />
      <Route path={URL_PATH.HPC_CREATE_BUDGET} element={<HpcCreateBudget />} />
      <Route path={URL_PATH.HPC_SPENDBOARD} element={<HpcSpendboard />} />
      <Route path={URL_PATH.HPC_DASHBOARD} element={<HpcDashboard />} />
      <Route path={URL_PATH.HPC_ALERTS} element={<HpcAlerts />} />

      <Route path={URL_PATH.ADMIN_USERS} element={<AdminUsers />} />
      <Route path={URL_PATH.ADMIN_DEPARTMENT_MAPPING} element={<AdminDepartmentMapping />} />
      <Route path={URL_PATH.ADMIN_DEPARTMENT_MAPPING_CREATE} element={<AdminDepartmentMappingCreate />} />
      
      <Route path={URL_PATH.HPC_REPORTS} element={<HpcReports />} /> 
      <Route path={URL_PATH.HPC_STATUS} element={<HpcStatus />} />
      <Route path={URL_PATH.HPC_LICENSES} element={<HpcLicenses />} />
      
      <Route path={URL_PATH.COST_RECOMMENDATION} element={<CostRecommendation />} />
      <Route path={URL_PATH.COST_EXPLORER} element={<CostExplorer />} />
      <Route path={URL_PATH.COST_ANOMALIES} element={<CostAnomalies />} />
      <Route path={URL_PATH.COST_QUERIES} element={<CostQueries />} />
      <Route path={URL_PATH.COST_COMPARISON} element={<CostComparison />} />

      <Route path={URL_PATH.COST_BUDGET_DASHBOARD} element={<CostBudgetDashboard />} />
      <Route path={URL_PATH.COST_BUDGET_LIST} element={<CostBudgetList />} />
      <Route path={URL_PATH.COST_BUDGET_CREATE} element={<CostBudgetCreate />} />

      
      <Route path={URL_PATH.RESOURCES_QUERY_EVENTS} element={<ResourcesQueryEvents />} />
      <Route path={URL_PATH.LIFECYCLE_LANDING_PAGE} element={<LifecycleLandingPage />} />
      <Route path={URL_PATH.RESOURCES_LANDING_PAGE} element={<ResourcesLandingPage />} />
      <Route path={URL_PATH.RESOURCES_SEARCH} element={<ResourcesSearch />} />
      {/* <Route path={URL_PATH.RESOURCE_COMPARISON} element={ComparisionOption} /> */}
      {/* <Route path={URL_PATH.CONSOLIDATED_COMPARISON} element={ConsolidatedComparison} /> */}
      <Route path={URL_PATH.ASSETS_COMPARISON} element={<AssetsComparison />} />

      {/* <Route path="*" element={<NotFoundPage />} /> */}

      <Route path={URL_PATH.GOVERNANCE_DASHBOARD} element={<GovernanceDashboard />} />
      <Route path={URL_PATH.GOVERNANCE_MANAGE_RULES} element={<GovernanceManageRules />} />
      <Route path={URL_PATH.GOVERNANCE_CREATE_NEW_RULE} element={<GovernanceCreateNewRule />} />
      <Route path={URL_PATH.GOVERNANCE_EDIT_NEW_RULE} element={<GovernanceEditNewRule />} />

      <Route path={URL_PATH.MANAGE_QOS} element={<ManageQos />} />
      <Route path={URL_PATH.CREATE_QOS} element={<CreateUpdateQos />} />
      <Route path={URL_PATH.EDIT_QOS} element={<CreateUpdateQos />} />
      <Route path={URL_PATH.CREATE_GROUP} element={<CreateUpdateGroup />} />
      
      <Route path={URL_PATH.FILES} element={<Files />} /> 

    </Routes>
  );
};
