/*************************************************
 * Collider
 * @exports
 * @file BudgetDashboard.js
 * @author Prakash // on 22/12/2023
 * @copyright © 2023 Collider. All rights reserved.
 *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { listAllProviders } from '../../../actions/commonActionNew'
import { Spinner, UncontrolledTooltip } from 'reactstrap'
import { listDepartmentsInitiativesProjects, getTopSpenders, getTopOverspenders, getSummary, getObservations, getCloudCommitmentVsSpend, getTopSpendersForecast, getUntaggedResources, getCurrencyConversionDetails } from '../../../actions/cost/BudgetAction'
import _ from 'lodash'
import { capitalizeTheFirstLetterOfEachWord, momentDateGivenFormat, splitString, subDays, thousandSeparator } from '../../../utils/utility'
import { DURATION_OPTIONS } from '../../../utils/constants'

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';

import ApexBarChart from '../../common/charts/ApexBarChart'
import ApexNonStackedBarChart from '../../common/charts/ApexNonStackedBarChart';
import ApexDonutChartNew from '../../common/charts/ApexDonutChartNew';

import SearchWithHiddenInput from '../../common/SearchWithHiddenInput'
import moment from 'moment';
import MultiSelectSection from '../../common/MultiSelectSection';
import { setCostPropsDetails } from '../../../actions/cost/CostAction';
import CloudCommitmentSpend from './CloudCommitmentSpend';
import ApexHorizontalStackedBarChart from '../../common/charts/ApexHorizontalStackedBarChart';

let callSingleClick = null
const BudgetDashboard = () => {
    const durationRef = useRef();
    const searchRef = useRef();

    // State variables for filters
    const [state, setState] = useState({
        showLoading: true,
        selectedDuration: 'currentMonth',
        selectionRange: [{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        }],
        months: [{label: 'Jan', value: 0}, {label:'Feb', value: 1}, {label:'Mar', value: 2}, {label:'Apr', value: 3}, {label:'May', value: 4}, {label:'Jun', value: 5}, {label:'Jul', value: 6}, {label:'Aug', value: 7}, {label:'Sep', value: 8}, {label:'Oct', value: 9}, {label:'Nov', value: 10}, {label:'Dec', value: 11}],
        selectedSearchItem: 'Department',
        // cloudProviderId: ['aws', 'gcp']
        currencyUnit: 'usd'
    })

    const [clickCount, setClickCount] = useState(0)

    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
    ]);

    const ExcelJS = require('exceljs');
    const dispatch = useDispatch(); // Create a dispatch function
    const departmentInitiativeProjectsAccounts = useSelector(state => state?.cost?.costPropsDetails?.departmentInitiativeProjectsAccounts);
    const departmentDetails = useSelector(state => state?.cost?.costPropsDetails?.departmentDetails);
    const currentCloudCommitSpendObservations = useSelector(state => state?.cost?.currentCloudCommitSpendObservations);
    const cloudCommitVsSpend = useSelector(state => state?.cost?.costPropsDetails?.cloudCommitVsSpend);

    useEffect(() => {
        let params = {}
        dispatch(listAllProviders(params))
            .then((response) => {
                if(response) {
                    setState(prevState => ({
                        ...prevState,
                        providers: response,
                        cloudProviderId: response && response.length ? (response.filter(e => e.provider_name === 'AWS').length ? ['AWS'] : [response[0].provider_name]) : '',
                        callSearch: true
                    }));
                }
            })
		
        let currPrams={
            convert_from: 'usd', 
            convert_to: 'gbp'
        }
    
        dispatch(getCurrencyConversionDetails(currPrams))
			.then((response) => {
				setState(prevState => ({ ...prevState, conversionDetails: response.conversion_details ? response.conversion_details : '' }))
			})
	}, [dispatch])

    useEffect(() => {
        if(state.showSummaryLoading) {
            setTimeout(() => { setState(prevState => ({ ...prevState, showSummaryLoading: false })) }, 10000);
        }
    }, [state.showSummaryLoading])

    useEffect(() => {
        const currentMonth = new Date().getMonth(); // Month is zero-based (0 for January, 1 for February, ..., 11 for December)
       	let datePickerStartDate = ''
        let datePickerEndDate = ''
        if(state.selectedDuration === "currentMonth") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "currentYear") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "lastMonth") {
            datePickerStartDate = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-01 00:00:00')            
            datePickerEndDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD 23:59:59')
        } else if(state.selectedDuration === "quarterly") {
            if (currentMonth >= 0 && currentMonth <= 2) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-03-31 23:59:59')
            } else if (currentMonth > 2 && currentMonth < 6) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-04-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else if (currentMonth > 5 && currentMonth < 9) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-09-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-10-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "half-yearly") {
            if (currentMonth >= 0 && currentMonth <= 5) {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-06-30 23:59:59')
            } else {
                datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-07-01 00:00:00')
                datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
            }
        } else if(state.selectedDuration === "yearly") {
            datePickerStartDate = momentDateGivenFormat(new Date(), 'YYYY-01-01 00:00:00')
            datePickerEndDate = momentDateGivenFormat(new Date(), 'YYYY-12-31 23:59:59')
        } else if(state.selectedDuration === 'previous-year') {
            let newDate = new Date();
            // Set the current date to the beginning of the current year
            newDate.setMonth(0, 1); // January 1st          

            // Subtract one year to get the start date of the previous year
            newDate.setFullYear(newDate.getFullYear() - 1);
            datePickerStartDate = momentDateGivenFormat(newDate, 'YYYY-01-01 00:00:00')

            // Set the current date to the end of the previous year
            newDate.setFullYear(newDate.getFullYear(), 11, 31); // December 31st            
            datePickerEndDate = momentDateGivenFormat(newDate, 'YYYY-12-31 23:59:59')
        }
        if(state.selectedDuration !== 'custom') {
            let previousYearDate = subDays(datePickerEndDate, 365)
            let lastYear = momentDateGivenFormat(previousYearDate, 'YYYY')
            setState(prevState => ({ ...prevState, lastYear, datePickerStartDate, datePickerEndDate }))
    
            // setState(prevState => ({ ...prevState, lastYear }))
            // setState(prevState => ({ 
            //     ...prevState, 
            //     datePickerStartDate, 
            //     datePickerEndDate,
            //     graphTopSpenders: {},
            //     // graphOverSpenders: {},
            //     // graphOverSpendersForecast: {},
            //     previousMonth: false,
            //     previousYear: false,
            //     yearlyTableComparision: [],
            //     comparisonPreviousYear: [],
            //     currentObsevations: [],
            //     showCloudProviderSection: false,
            //     callSearch: true,
            // }));

            let dateSelection= {
				startDate: new Date(datePickerStartDate),
				endDate: new Date(datePickerEndDate),
				key: 'selection'
			}

			setDateState([dateSelection])

            // dispatch(setCostPropsDetails('departmentDetails', {}))
            // dispatch(setCostPropsDetails('departmentDetailsYearlyComparision', {}))
            // dispatch({ type: 'SET_CLOUD_COMMITMENT_VS_SPEND', payload: {} });
        }

	}, [state.selectedDuration, dispatch])

    useEffect(() => {
        if(state.callSummaryFunction) {
            setState(prevState => ({ ...prevState, callSummaryFunction: false, showSummaryLoading: true }))
            let aggregateBy = 'department'
            let labelName = state.selectedDepartment
            let params = {
                "department": state.selectedDepartment,
                'start_time': momentDateGivenFormat(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentDateGivenFormat(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),                
            }   
            let department = ''
            let initiative = ''
            let project = ''
            let isAccount = ''
            if(state.selectedSearchItem !== 'Department') {
                let splitValue = splitString(state.selectedSearchItem, ':')
                department = splitValue[0]

                isAccount = splitValue[splitValue.length-1]
                if(isAccount === 'accounts') {
                    if(splitValue.length === 3) {
                        initiative = splitValue[1]
                    }
                } else {
                    if(splitValue.length >= 2) {
                        initiative = splitValue[1]
                    }
                    if(splitValue.length >= 3) {
                        project = splitValue[2]
                    }
                }
            }

            if(department) {
                aggregateBy = 'initiative'
            }
            if(initiative) {
                aggregateBy = 'project'
            }

            if(state.selectedInitiative) {
                params.initiative = state.selectedInitiative
                labelName = state.selectedDepartment+'_'+state.selectedInitiative
            }

            if(state.selectedProject) {
                params.project = state.selectedProject
                labelName = state.selectedDepartment+'_'+state.selectedInitiative+'_'+state.selectedProject
            }

            if(state.selectedAccount) {
                // params.account_id = account
                labelName = state.selectedDepartment+'_'+state.selectedInitiative+'_'+state.selectedProject+'_'+state.selectedAccount
            }

            if(project || isAccount === "accounts") {
                // aggregateBy = 'billingUnit'
                if(department) {
                    aggregateBy = 'department   '
                }
                if(initiative) {
                    aggregateBy = 'initiative'
                }
                if(project) {
                    aggregateBy = 'project'
                }
                params.accounts_view = true
                params.billingUnit = state.selectedAccount
            }

            params.aggregate_by = aggregateBy
            if(state.searchProvider && state.searchProvider.length) {
                let provider = state.searchProvider.map(v => v.toLowerCase());
                params.cloud_provider = provider
            } else if(state.cloudProviderId && state.cloudProviderId.length) {
                let provider = state.cloudProviderId.map(v => v.toLowerCase());
                params.cloud_provider = provider
            }
            if(state.without_tax) {
                params.without_tax = state.without_tax
            }
            if(state.without_savings) {
                params.without_savings = state.without_savings
            }
            if(state.without_credits_discounts) {
                params.without_credits_discounts = state.without_credits_discounts
            }

            params.currency_unit = state.currencyUnit

            labelName += '_'+state.currencyUnit
            

            if(departmentDetails && departmentDetails.hasOwnProperty(labelName)) {
                setState(prevState => ({
                    ...prevState,
                    summaryDetails: departmentDetails[labelName],
                    distributionDonutData: {},
                    tableData: [],
                    callDistributionDonutDetails: true,
                    spendCommitment: {},
                    aggregateBy,                 
                    callSpendCommitmentDetails: true,  
                    showLoading: false,
                    showSummaryLoading: false
                }));
            } else {
                dispatch(getSummary(params))
                    .then((response) => {
                        if(response && !response.error) {
                            let obj = departmentDetails ? departmentDetails : {}
                            obj[labelName] = response && response.results ? response.results : {}
                            dispatch(setCostPropsDetails('departmentDetails', obj))
                            setState(prevState => ({
                                ...prevState,
                                summaryDetails: response && response.results ? response.results : {},
                                distributionDonutData: {},
                                callDistributionDonutDetails: true,
                                spendCommitment: {},
                                aggregateBy,
                                callSpendCommitmentDetails: true,
                                showLoading: false,
                                showSummaryLoading: false
                            }));
                        }
                    })
            }
        }
    }, [state.callSummaryFunction, state.selectedDepartment, state.selectedInitiative, state.selectedProject, state.selectedAccount, state.datePickerStartDate, state.datePickerEndDate, state.selectedSearchItem, dispatch, departmentDetails, state.searchProvider, state.cloudProviderId, state.without_savings, state.without_tax, state.without_credits_discounts, state.currencyUnit])

    useEffect(() => {
        if(state.callTopGraphSection && state.datePickerStartDate && state.datePickerEndDate) {
            setState(prevState => ({ ...prevState, callTopGraphSection : false, graphTopSpenders: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, taggedNonTaggedDistribution: {}, distributionDonutData: {}, spendCommitment: {} }))
            let department = ''
            let initiative = ''
            let project = ''
            let isAccount = ''
            if(state.selectedSearchItem !== 'Department') {
                let splitValue = splitString(state.selectedSearchItem, ':')
                department = splitValue[0] 

                isAccount = splitValue[splitValue.length-1]
                if(isAccount === 'accounts') {
                    if(splitValue.length === 3) {
                        initiative = splitValue[1]
                    }
                } else {
                    if(splitValue.length > 1) {
                        initiative = splitValue[1]
                    }
                    if(splitValue.length > 2) {
                        project = splitValue[2]
                    }
                }
            }
            let aggregateBy = 'department'

            let overallParams = {
                'start_time': momentDateGivenFormat(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                'end_time': momentDateGivenFormat(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss'),                
            }
            if(department) {
                aggregateBy = 'initiative'
                overallParams.department = department
            }
            if(initiative) {
                aggregateBy = 'project'
                overallParams.initiative = initiative
            }
            if(project) {
                aggregateBy = 'project'
                overallParams.project = project
            }

            if(isAccount === 'accounts' || project) {
                // aggregateBy = 'billingUnit'
                if(department) {
                    aggregateBy = 'department'
                }
                if(initiative) {
                    aggregateBy = 'initiative'
                }
                if(project) {
                    aggregateBy = 'project'
                }
                overallParams.accounts_view = true
            }

            overallParams.aggregate_by = aggregateBy
            
            let spenders = {}
            if(state.searchProvider && state.searchProvider.length) {
                let provider = state.searchProvider.map(v => v.toLowerCase());
                spenders.cloud_provider = provider
            } else if(state.cloudProviderId && state.cloudProviderId.length) {
                let provider = state.cloudProviderId.map(v => v.toLowerCase());
                spenders.cloud_provider = provider
            }
            if(state.without_tax) {
                spenders.without_tax = state.without_tax
            }
            if(state.without_savings) {
                spenders.without_savings = state.without_savings
            }
            if(state.without_credits_discounts) {
                spenders.without_credits_discounts = state.without_credits_discounts
            }

            spenders.currency_unit = state.currencyUnit

            spenders = {...overallParams, ...spenders}

            spenders.start_time = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            spenders.end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

            spenders.start_time = state.datePickerStartDate
            spenders.end_time = state.datePickerEndDate
    
            dispatch(getTopSpenders(spenders))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.results ? response.results : {}
                        if(isAccount !== 'accounts' && !project) {
                            let graphTopSpenders = {}
                            graphTopSpenders.labels = results.labels ? results.labels : []
                            graphTopSpenders.data = results.data ? results.data : []
                            graphTopSpenders.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                            setState(prevState => ({ ...prevState, topSpenders: results, graphTopSpenders }));
                        } else {
                            let graphTopSpenders = {}
                            graphTopSpenders.labels = results.labels ? results.labels : []
                            graphTopSpenders.data = results.data ? results.data : []
                            graphTopSpenders.total = results.total_spend ? results.total_spend : []
                            graphTopSpenders.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                            graphTopSpenders.belowLabelToolTip = results.tooltip ? results.tooltip : []
                            
                            let data = results.donut_chart && results.donut_chart.labels.length ? results.donut_chart.data : []
                            
                            let donut = []
                            let donut_total_count = 0
                            results.donut_chart && results.donut_chart.labels && results.donut_chart.labels.forEach((lab, index) => {
                                let donutRow = {}
                                donutRow.item_name = lab
                                donutRow.item_count = data[index] ? data[index] : 0
                                donut.push(donutRow)
                                donut_total_count += data[index] ? data[index] : 0 
                            })
                            donut = _.orderBy(donut, ['item_count'], ['desc'])
                    
                            let taggedNonTaggedDistribution = {
                                'items': donut,
                                'label': 'Total',
                                'total_count': donut_total_count
                            }
                            
                            setState(prevState => ({ ...prevState, topSpenders: results, graphTopSpenders, taggedNonTaggedDistribution }));
                        }
                    }
                })
            
            if(isAccount !== 'accounts') {
                dispatch(getTopOverspenders(spenders))
                    .then((response) => {
                        if(response && !response.error) {
                            // let results = response.results ? response.results : {}
                            // let graphOverSpenders = {}
                            // graphOverSpenders.labels = results.labels ? results.labels : []
                            // graphOverSpenders.data = results.data ? results.data : []
                            // graphOverSpenders.unit = '%'
                            // setState(prevState => ({
                            //     ...prevState,
                            //     topOverSepnders: results,
                            //     graphOverSpenders
                            // }));

                            let results = response.results ? response.results : {}

                            // let results = {
                            //     "data": {
                            //       "overspending": [
                            //         900,
                            //         700
                            //       ],
                            //       "underspenindg": [
                            //           0,
                            //           1823
                            //         ]
                            //     },
                            //     "labels": [
                            //       "RDD",
                            //       "CITO"
                            //     ]
                            // }
                            
                            let graphData = {}
                            graphData.labels = results.labels ? results.labels : []
                            graphData.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                            graphData.unit = '%'
                            results.data && Object.entries(results.data).length && Object.entries(results.data).forEach(([key, value]) => {
                                graphData[key] = value
                            })
                            let additionalTooltip = {}
                            if(results.spend && results.spend.length) {
                                additionalTooltip.spend = results.spend
                            }
                            if(results.budget && results.budget.length) {
                                additionalTooltip.budget = results.budget
                            }
                            graphData.additionalTooltip = additionalTooltip

                            setState(prevState => ({ ...prevState, topOverSepnders: results, graphOverSpenders: graphData }));
                        }
                    })
            }

            // if(!currentCloudCommitSpendObservations || !currentCloudCommitSpendObservations.results ) {
                dispatch(getObservations(spenders))
                    .then((response) => {
                        if(response && !response.error) {
                            let results = response.results ? response.results : {}
                            setState(prevState => ({
                                ...prevState,
                                currentObsevations: results,
                            }));
                        }
                    })
            // } else {
            //     let results = currentCloudCommitSpendObservations.results ? currentCloudCommitSpendObservations.results : {}
            //     setState(prevState => ({
            //         ...prevState,
            //         currentObsevations: results,
            //     }));
            // }

            let overSpenders = {}
            overSpenders.start_time = momentDateGivenFormat(new Date(), 'YYYY-MM-01 00:00:00')
            overSpenders.end_time = momentDateGivenFormat(new Date(), 'YYYY-MM-DD 23:59:59')

            overSpenders = {...spenders, ...overSpenders}
    
            dispatch(getTopSpendersForecast(overSpenders))
                .then((response) => {
                    if(response && !response.error) {
                        let results = response.results ? response.results : {}

                        let graphOverSpendersForecast = {}
                        graphOverSpendersForecast.labels = results.labels ? results.labels : []
                        graphOverSpendersForecast.data = results.forecast_data ? results.forecast_data : []
                        graphOverSpendersForecast.total = results.total_spend ? results.total_spend : []
                        graphOverSpendersForecast.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
                        graphOverSpendersForecast.belowLabelToolTip = results.tooltip ? results.tooltip : []
                        
                        setState(prevState => ({ ...prevState, graphOverSpendersForecast, overSepndersForecast: results }))
                    }
                })

        }
    }, [state.callTopGraphSection, state.selectedSearchItem, state.datePickerStartDate, state.datePickerEndDate, state.selectedAccount, dispatch, currentCloudCommitSpendObservations, state.searchProvider, state.cloudProviderId, state.without_savings, state.without_tax, state.without_credits_discounts, state.currencyUnit])

    useEffect(() => {
        if(state.callSearch) {
            setState(prevState => ({
                ...prevState,
                callSearch: false,
                graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {},
                showCloudProviderSection: state.selectedSearchItem === 'Provider' ? true : false,
                showLoading: true
            }))

            dispatch(setCostPropsDetails('departmentDetails', {}))
            dispatch(setCostPropsDetails('departmentDetailsYearlyComparision', {}))
            dispatch({ type: 'SET_CLOUD_COMMITMENT_VS_SPEND', payload: {} });
            
            setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 4000);
            if(!departmentInitiativeProjectsAccounts || !departmentInitiativeProjectsAccounts.length) {
                let depParams = {}
                if(state.searchProvider && state.searchProvider.length) {
                    let provider = state.searchProvider.map(v => v.toLowerCase());
                    depParams.cloud_provider = provider
                } else if(state.cloudProviderId && state.cloudProviderId.length) {
                    let provider = state.cloudProviderId.map(v => v.toLowerCase());
                    depParams.cloud_provider = provider
                }
                dispatch(listDepartmentsInitiativesProjects(depParams))
                    .then((response) => {
                        let results =  response && response.results ? response.results : []
                        setState(prevState => ({
                            ...prevState,
                            departmentInitiativeProjectsAccounts: results,
                            callDepartmentStructure: true,
                            callCloudProviderDetailApi: true
                        }))
                    })
            } else {
                setState(prevState => ({
                    ...prevState,
                    departmentInitiativeProjectsAccounts,
                    callDepartmentStructure: true
                }))
            }
        }
    }, [state.callSearch, state.datePickerStartDate, state.datePickerEndDate, dispatch, departmentInitiativeProjectsAccounts, state.selectedSearchItem, state.searchProvider, state.cloudProviderId])

    useEffect(() => {
        if(state.callCloudProviderDetailApi) {
            setState(prevState => ({ ...prevState, callCloudProviderDetailApi: false }))
            
            if(cloudCommitVsSpend && cloudCommitVsSpend.hasOwnProperty(state.currencyUnit)) {
                setState(prevState => ({ ...prevState, cloudCommitment: cloudCommitVsSpend.results }));
            } else {
                let depParams = {
                    'start_time': momentDateGivenFormat(state.datePickerStartDate, 'YYYY-MM-DD HH:mm:ss'),
                    'end_time': momentDateGivenFormat(state.datePickerEndDate, 'YYYY-MM-DD HH:mm:ss')
                }
                if(state.without_tax) {
                    depParams.without_tax = state.without_tax
                }
                if(state.without_savings) {
                    depParams.without_savings = state.without_savings
                }
                if(state.without_credits_discounts) {
                    depParams.without_credits_discounts = state.without_credits_discounts
                }
                depParams.currency_unit = state.currencyUnit
                dispatch(getCloudCommitmentVsSpend(depParams))
                    .then((response) => {
                        if(response && !response.error) {
                            let results =  response && response.results ? response.results : {}
                            setState(prevState => ({ ...prevState, cloudCommitment: results }))

                            if(results && Object.entries(results).length) {
                                let obj = cloudCommitVsSpend ? cloudCommitVsSpend : {}
                                obj[state.currencyUnit] = results
                                dispatch(setCostPropsDetails('cloudCommitVsSpend', obj))
                            }
                        } else {
                            setState(prevState => ({ ...prevState, cloudCommitment: {} }))
                        }
                    })
            }
        }
    }, [state.callCloudProviderDetailApi, state, cloudCommitVsSpend, dispatch])

    useEffect(() => {
        if(state.callDepartmentStructure) {
            setState(prevState => ({ ...prevState, callDepartmentStructure: false, isSearchOpen: false }))
            let listItems = []

            let department = ''
            let initiative = ''
            let project = ''
            let isAccount = ''
            if(state.selectedSearchItem === 'Provider') {
                setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedItem: department,selectedInitiative: '', selectedProject: '', selectedProjectTags: [], selectedAccount: '', callCloudProviderDetailApi: true, showCloudProviderSection: true }))
            } else {
                let splitState = ['Department']
                if(state.selectedSearchItem !== 'Department') {
                    let splitValue = splitString(state.selectedSearchItem, ':')
                    splitState = splitValue
                    department = splitValue[0]
                    isAccount = splitValue[splitValue.length-1]
                    if(isAccount === 'accounts') {
                        if(splitValue.length === 3) {
                            initiative = splitValue[1]
                        }
                    } else {
                        if(splitValue.length >= 2) {
                            initiative = splitValue[1]
                        }
                        if(splitValue.length >= 3) {
                            project = splitValue[2]
                        }
                    }
                }

                if(state.selectedSearchItem !== "Department") {
                    if(isAccount === 'accounts')  {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        let projectDetails = []
                        if(departDetails && departDetails.length) {
                            if(initiative) {
                                let initiatives = departDetails[0].initiatives ? departDetails[0].initiatives : []
                                projectDetails = initiatives.filter(e => e.initiative === initiative)
                                let projects = projectDetails[0].projects ? projectDetails[0].projects : []
                                projects.length && projects.forEach(proj => {
                                    proj.accounts && proj.accounts.forEach(acc => {
                                        if(!listItems.filter(e => e.label === acc).length) {
                                            let dataRow = {}
                                            dataRow.label = acc
                                            listItems.push(dataRow)
                                        }
                                    })
                                })
                            } else {
                                departDetails.forEach(dep => {
                                    if(dep.initiatives && dep.initiatives.length) {
                                        dep.initiatives.forEach(int => {
                                            if(int.projects && int.projects.length) {
                                                int.projects.forEach(proj => {
                                                    proj.accounts && proj.accounts.forEach(acc => {
                                                        if(!listItems.filter(e => e.label === acc).length) {
                                                            let dataRow = {}
                                                            dataRow.label = acc
                                                            listItems.push(dataRow)
                                                        }
                                                    })
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                        let selectedAccount = ''
                        if(listItems && listItems.length) {
                            selectedAccount = listItems[0].label
                        }
    
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject: project, selectedAccount, selectedItem: selectedAccount, callSummaryFunction: true, getUntaggedResourcesDetails: true, callTopGraphSection: true }))
                    } else if(project) {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        let projectDetails = [] 
                        if(departDetails && departDetails.length) {
                            let initiatives = departDetails[0].initiatives ? departDetails[0].initiatives : []
                            projectDetails = initiatives.filter(e => e.initiative === initiative)
                        }
                        let accountDetails = []
                        if(projectDetails && projectDetails.length) {
                            let projects = projectDetails[0].projects ? projectDetails[0].projects : []
                            accountDetails = projects.filter(e => e.project === project)
                        }
    
                        let selectedAccount = ''
                        if(accountDetails && accountDetails.length) {
                            accountDetails[0].accounts && accountDetails[0].accounts.forEach((row, rIndex) => {
                                if(!rIndex) {
                                    selectedAccount = row
                                }
                                let dataRow = {}
                                dataRow.label = row
                                listItems.push(dataRow)
                            })
                        }
    
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject: project, selectedAccount, selectedItem: selectedAccount, callSummaryFunction: true, callTopGraphSection: true, getUntaggedResourcesDetails: true }))
                    } else if(initiative) {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        let projectDetails = [] 
                        if(departDetails && departDetails.length) {
                            let initiatives = departDetails[0].initiatives ? departDetails[0].initiatives : []
                            projectDetails = initiatives.filter(e => e.initiative === initiative)
                        }
                        let selectedProject = ''
                        if(projectDetails && projectDetails.length) {
                            projectDetails[0].projects && projectDetails[0].projects.forEach((row, rIndex) => {
                                if(!rIndex) {
                                    selectedProject = row.project
                                }
                                let dataRow = {}
                                dataRow.label = row.project
                                listItems.push(dataRow)
                            })
                        }
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject, selectedAccount: '', selectedItem: selectedProject, callSummaryFunction: true, callTopGraphSection: true }))
                    } else {
                        let departDetails = state.departmentInitiativeProjectsAccounts.filter(e => e.department === department)
                        if(departDetails && departDetails.length) {
                            departDetails[0].initiatives && departDetails[0].initiatives.forEach((row, rIndex) => {
                                if(!rIndex) {
                                    initiative = row.initiative
                                }
                                let dataRow = {}
                                dataRow.label = row.initiative
                                listItems.push(dataRow)
                            })
                        }
                        
                        setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedInitiative: initiative, selectedProject: '', selectedProjectTags: [], selectedAccount: '', selectedItem: initiative, callSummaryFunction: true, callTopGraphSection: true }))
                    }
                } else {
                    state.departmentInitiativeProjectsAccounts.forEach((row, rIndex) => {
                        if(!rIndex) {
                            department = row.department
                        }
                        let dataRow = {}
                        dataRow.label = row.department
                        listItems.push(dataRow)
                    })
    
                    setState(prevState => ({ ...prevState, listItems, filteredListItems: listItems, selectedDepartment: department, selectedItem: department, selectedInitiative: '', selectedProject: '', selectedProjectTags: [], selectedAccount: '', callSummaryFunction: true, callTopGraphSection: true, getUntaggedResourcesDetails: true }))
                }

                setState(prevState => ({ ...prevState, splitState }))
            }
        }

    }, [state.callDepartmentStructure, state.selectedSearchItem, state.departmentInitiativeProjectsAccounts, state.selectedInitiative, state.selectedDepartment])

    useEffect(() => {
        if(state.departmentInitiativeProjectsAccounts) {
            let structedSearchData = []
            let searchRow = {}
            searchRow.label = 'Provider'
            structedSearchData.push(searchRow)
            searchRow = {}
            searchRow.label = 'Department'
            structedSearchData.push(searchRow)
            state.departmentInitiativeProjectsAccounts.forEach(row => {
                searchRow = {}
                searchRow.label = row.department
                structedSearchData.push(searchRow)

                searchRow = {}
                searchRow.label = row.department+':accounts'
                structedSearchData.push(searchRow)
                if(row.initiatives && row.initiatives.length) {
                    row.initiatives.forEach(int => {
                        searchRow = {}
                        searchRow.label = row.department+':'+int.initiative
                        structedSearchData.push(searchRow)

                        searchRow = {}
                        searchRow.label = row.department+':'+int.initiative+':accounts'
                        structedSearchData.push(searchRow)
                        if(int.projects && int.projects.length) {
                            int.projects.forEach(proj => {
                                searchRow = {}
                                searchRow.label = row.department+':'+int.initiative+':'+proj.project
                                structedSearchData.push(searchRow)
                                // if(proj.accounts && proj.accounts.length) {
                                //     proj.accounts.forEach(acc => {
                                //         searchRow = {}
                                //         searchRow.label = row.department+':'+int.initiative+':'+proj.project+':'+acc
                                //         structedSearchData.push(searchRow)
                                //     })
                                // }
                            })
                        }
                    })
                }
            })
            setState(prevState => ({ ...prevState, structedSearchData, filteredSearchItem: structedSearchData }))
        }
    }, [state.departmentInitiativeProjectsAccounts])

    useEffect(() => {
        if(state.callDistributionDonutDetails) {
            let donut = []
            let donut_total_count = 0

            let data = state.summaryDetails && state.summaryDetails.distribution && state.summaryDetails.distribution.data && state.summaryDetails.distribution.data.length ? state.summaryDetails.distribution.data : []

            state.summaryDetails && state.summaryDetails.distribution && state.summaryDetails.distribution.labels && state.summaryDetails.distribution.labels.forEach((lab, index) => {
                let donutRow = {}
                donutRow.item_name = lab
                donutRow.item_count = data[index] ? data[index] : 0
                donut.push(donutRow)
                donut_total_count += data[index] ? data[index] : 0 
            })
            donut = _.orderBy(donut, ['item_count'], ['desc'])
    
            let distributionDonutData = {
                'items': donut,
                'label': 'Total',
                'total_count': donut_total_count
            }

            setState(prevState => ({ ...prevState, distributionDonutData, callDistributionDonutDetails: false }));
        }
    }, [state.callDistributionDonutDetails, state.summaryDetails])

    useEffect(() => {
        if(state.callSpendCommitmentDetails) {
            let spendCommitment = state.summaryDetails && state.summaryDetails.spend_vs_commitment ? state.summaryDetails.spend_vs_commitment : {}

            let graphData = {}
            graphData.labels = spendCommitment.dates ? spendCommitment.dates : []
            graphData.prefixUnit = (state.currencyUnit === 'usd' ? '$' : '£')
            spendCommitment.data && Object.entries(spendCommitment.data).length && Object.entries(spendCommitment.data).forEach(([key, value]) => {
                if(key === 'commitment' || key === 'spend') {
                    graphData[key] = value
                }
            })

            let tableDetails = state.summaryDetails && state.summaryDetails.table_details && state.summaryDetails.table_details.details ? state.summaryDetails.table_details.details : []
            let tableNotes = state.summaryDetails && state.summaryDetails.table_details && state.summaryDetails.table_details.note ? state.summaryDetails.table_details.note : ''
            
            let tableData = tableDetails && tableDetails.length ? _.orderBy(tableDetails, ['order'], ['asc']) : []
            setState(prevState => ({ ...prevState, spendCommitment: graphData, callSpendCommitmentDetails: false, tableData, tableNotes }))
        }
    }, [state.callSpendCommitmentDetails, state.summaryDetails, state.aggregateBy, state.currencyUnit])

    const handleChildClick = (event, type, dropdownType, section) => {	
        
		if (durationRef.current && !durationRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, showDateRangePicker: false }));
		}
        if (searchRef.current && !searchRef.current.contains(event.target)) {
            setState(prevState => ({ ...prevState, isSearchOpen: false }));
		}

		event.stopPropagation();
		let clickedChild = []
		if(type === 'child') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            clickedChild.push(section)
            setState(prevState => ({ ...prevState, [section]: dropdownType === "singleDropDown" && state[section] ? false : true, clickedChild }))
        } else if(type === 'parent') {
            if(state.clickedChild && state.clickedChild.length) {
                state.clickedChild.forEach(row => {
                    setState(prevState => ({ ...prevState, [row]: false }))
                })
            }
            setState(prevState => ({ ...prevState, clickedChild }))
        }
	}

    const handleSelect = (date) => {
        let datePickerStartDate = momentDateGivenFormat(date.selection.startDate, 'YYYY-MM-DD 00:00:00');
        let datePickerEndDate = ""
        if(date.selection.startDate !== date.selection.endDate) {
            datePickerEndDate = momentDateGivenFormat(date.selection.endDate, 'YYYY-MM-DD 23:59:59');
        }

        let previousYearDate = subDays(datePickerEndDate, 365)
        let lastYear = momentDateGivenFormat(previousYearDate, 'YYYY')

        if(datePickerStartDate && datePickerEndDate) {
            setState(prevState => ({
                ...prevState,
                datePickerStartDate,
                datePickerEndDate,
                lastYear,
                selectedDuration: "custom",
                graphTopSpenders: {},
                taggedNonTaggedDistribution: {},
                graphOverSpenders: {},
                graphOverSpendersForecast: {},
                showCloudProviderSection: false,
                currentObsevations: [],
                searchProvider: [],
                callSearch: true,
            }));    
        }

        setDateState([date.selection])
    };

    const onClickDepartment = (value) => {
        if(state.selectedItem !== value) {
            setState(prevState => ({ ...prevState, previousYear: false, previousMonth: false, searchProvider: [] }))
        }

        if(state.selectedSearchItem !== 'Department') {
            let splitValue = splitString(state.selectedSearchItem, ':')
            let isAccount = splitValue[splitValue.length-1]
            if(isAccount === 'accounts')  {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: splitValue.length > 2 ? splitValue[1] : '', selectedProject: splitValue.length > 3 ? splitValue[2] : '', selectedAccount: value, selectedItem: value, callSummaryFunction: true, getUntaggedResourcesDetails: true }))
            } else if(splitValue.length > 2) {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: splitValue[1], selectedProject: splitValue[2], selectedAccount: value, selectedItem: value, callSummaryFunction: true }))
            } else if(splitValue.length > 1) {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: splitValue[1], selectedProject: value, selectedAccount: '', selectedItem: value, callSummaryFunction: true }))
            } else {
                setState(prevState => ({ ...prevState, selectedDepartment: splitValue[0], selectedInitiative: value, selectedItem: value, selectedProject: '', selectedProjectTags: [], selectedAccount: '', callSummaryFunction: true }))
            }
        } else {
            setState(prevState => ({ ...prevState, selectedDepartment: value, selectedItem: value, selectedInitiative: '', selectedProject: '', selectedProjectTags: [], selectedAccount: '', callSummaryFunction: true }))
        }
    }

    const handleBreadCrumb = (type, splIndex) => {
        let selectedSearchItemList = type !== 'Department' ? type.slice(0, splIndex) : type
        let selectedSearchItem = ''
        if(type !== 'Department') {
            selectedSearchItemList.forEach((ser, serIndex) => {
                if(serIndex) {
                    selectedSearchItem += ':'+ser
                } else {
                    selectedSearchItem = ser
                }
            })
        } else {
            selectedSearchItem = type
        }
        setState(prevState => ({ 
            ...prevState,
            graphTopSpenders: {},
            taggedNonTaggedDistribution: {},
            graphOverSpenders: {},
            graphOverSpendersForecast: {},
            selectedSearchItem,
            callDepartmentStructure: true,
        }))
    }

    useEffect(() => {
        let dataResult = state.tableData && state.tableData.length ? state.tableData : [];
        if (dataResult.length) {
            let headers = [];
            let headerRow = { label: 'Label', key: 'label' };
            headers.push(headerRow);

            headerRow = { label: 'Year', key: 'year' };
            headers.push(headerRow);

            state.months.forEach((mnt) => {
                headerRow = { label: mnt.label, key: mnt.label };
                headers.push(headerRow);
            });

            setState(prevState => ({ ...prevState, tableHeaders: headers }));
        }
    }, [state.tableData, state.months]);

    const spendboardExcelExport = async () => {
        const data = state.tableData || [];
        const headers = state.tableHeaders || [];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        worksheet.addRow(headers.map(header => header.label));
    
        data.forEach(item => {
            let formattedItem = {};

            if (!item?.split || item?.split === false) {
                formattedItem = { label: item.label, year: item.year };
                if (Array.isArray(item.data)) {
                    state.months.forEach((mnt, index) => {
                        // Replace null or blank values with 0
                        const value = item.data[index];
                        formattedItem[mnt.label] = (value === null || value === undefined || value === '') ? 0 : value;
                    });
                } else {
                    state.months.forEach(mnt => {
                        formattedItem[mnt.label] = 0; // Default to 0 if no data
                    });
                }
            } else {
                formattedItem = { label: item.label };
            }
        const row = worksheet.addRow(Object.values(formattedItem));

        if (formattedItem.label === 'Budget') {
            row.eachCell((cell) => {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFCCCB' } // Light red for highlighting
                };
            });
        }

        const yearIndex = headers.findIndex(header => header.label === 'Year') + 1;
        const yearCell = row.getCell(yearIndex);
        if (yearCell.value === 2024) {
            yearCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFCCCB' } // Light purple for 2024
            };
        } else if (yearCell.value === 2023) {
            yearCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFFCC' } // Light yellow for 2023
            };
        } else {
            yearCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'CCCCCC' } // Gray for other years
            };
        }

        if (item.right_align) {
            row.getCell(1).alignment = { horizontal: 'right' };
          }
    });

        // Set border style
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        const firstColumn = worksheet.getColumn(1);
        data.forEach((item, rowIndex) => {
            const cell = worksheet.getCell(rowIndex + 2, 1);
            if (item.split) {
                cell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'ADD8E6' }
                };
            }
                cell.border = {
                    right: { style: 'thick' }
                };
        });

        // Highlight the first row
        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }
            };
            cell.border = borderStyle;
        });

        const lastRow = worksheet.lastRow.number;
        const lastColumn = headers.length;

        // Set the border style
        const bottomBorderStyle = {
            bottom: { style: 'thick' }};

        const rightBorderStyle = {
            right: { style: 'thick' }
        };

        for (let col = 1; col <= lastColumn; col++) {
            const cell = worksheet.getCell(lastRow, col);
            cell.border = bottomBorderStyle;
            if (col === 1){
                cell.border = {bottom: { style: 'thick' }, right: { style: 'thick' } }
            }
        }

        // Apply borders to the last column
        for (let row = 2; row <= lastRow; row++) {
            const cell = worksheet.getCell(row, lastColumn);
            cell.border = rightBorderStyle;
            if (row === lastRow){
                cell.border = {bottom: { style: 'thick' }, right: { style: 'thick' } }
            }
        }
        

        firstColumn.width = 30; // Set width of first column
        for (let col = 2; col <= headers.length; col++) {
            worksheet.getColumn(col).width = 10; // Set width for other columns
        }

        const currencyNote = state.summaryDetails?.table_details?.currency_note || '';
        if (currencyNote) {
            worksheet.addRow()
            worksheet.addRow()
            worksheet.addRow(["Note: " + currencyNote]); // Adjust the number of empty strings based on your header count
        }
    
        // Save the workbook
        const fileName = state.summaryDetails?.table_details?.download
            ? `${state.summaryDetails.table_details.download}-budget-spendboard.xlsx`
            : 'budget-spendboard.xlsx';
    
        await workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    const untaggedResourceExcelExport = async () => {
        const data = state.untagggedDownloads || [];
        const headers = state.untaggedHeaders || [];
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        worksheet.addRow(headers.map(header => header.label));
    
        data.forEach(item => {
            let formattedItem = { reason: item.reason, resource_id: item.resource_id, resource_type: item.resource_type  };
            const row = worksheet.addRow(Object.values(formattedItem));
        });

        // Set border style
        const borderStyle = {
            top: { style: 'thick' },
            left: { style: 'thick' },
            bottom: { style: 'thick' },
            right: { style: 'thick' }
        };

        // Highlight the first row
        worksheet.getRow(1).eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }
            };
            cell.border = borderStyle;
        });

        // Get the last row with values
        const lastRow = worksheet.actualRowCount;
        const lastColumn = headers.length;

        const rightBorderStyle = {
            right: { style: 'thick' }
        };

        for (let row = 2; row <= lastRow; row++) {
            for (let col = 1; col <= lastColumn; col++) {
                const cell = worksheet.getCell(row, col);
                cell.border = rightBorderStyle;
            }
        }

        for (let col = 1; col <= headers.length; col++) {
            if (col === 2){
                worksheet.getColumn(col).width = 200;
            } else {
                worksheet.getColumn(col).width = 30;
            }
        }

        // Save the workbook
        const fileName = state.untaggedDownlaodLabel ? `${state.untaggedDownlaodLabel+'.xlsx'}` : 'untaggged-resource-downloads.xlsx';

        await workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    };

    const automaiotnUpdateStatus = (field) => {
		setState(prevState => ({ ...prevState, [field]: !state[field], searchProvider: [], callSearch: true }))
    }

    useEffect(() => {
        if(state.getUntaggedResourcesDetails) {
            setState(prevState => ({ ...prevState, getUntaggedResourcesDetails: false }))
            let params = {
                account_id: state.selectedItem
            }
            dispatch(getUntaggedResources(params))
                .then((response) => {
                    if(response) {
                        let results = response && response.results ? response.results : []
                        let untaggedHeaders = []
                        if(results.length) {
                            Object.keys(results[0]).forEach(key => {
                                if (key !== "createdBy"){
                                    let headerRow = {}
                                    headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
                                    headerRow.key = key
                                    untaggedHeaders.push(headerRow)
                                }
                            })
                        }
                        setState(prevState => ({ ...prevState, untagggedDownloads: results, untaggedHeaders, untaggedDownlaodLabel: response.download }))
                    }
                })
        }

    }, [state.getUntaggedResourcesDetails, dispatch, state.selectedItem])

    const checkForDoubleClick = (label) => {
        let selectedBar = label

        setClickCount((prevCount) => prevCount + 1);
        // Check if it's a double-click
        if (clickCount === 1) {
            clearTimeout(callSingleClick)
          // Reset the click count and perform the action for double-click (e.g., open the folder)
          if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
              if(state.selectedSearchItem !== "Department") {
                  selectedBar = state.selectedSearchItem+':'+label
              }
              setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, searchProvider: [], callDepartmentStructure: true  }))
          }
          setClickCount(0);
        } else {
            callSingleClick = setTimeout(() => { onClickDepartment(selectedBar) }, 400 )
            setTimeout(() => { setClickCount(0) }, 300)        
        }

    }

    return (
        <div className='container-fluid overflow-auto flex-grow-1 px-0' onClick={(event) => handleChildClick(event, 'parent')}>
            <div className={`loaderOverlay ${state.showLoading ? '' : 'displayNone'}`}>
                <div className='overlayEqualizerLoader'>
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                    <div className='spinner-item' />
                </div>
            </div>
            <div className='row text-white mx-0 pb-2 bg-nero'>
                <div className='col-lg-4 col-md-12 col-sm-12 py-2'>
                    <h6 className='text-white m-0'>Budget Status</h6>
                    <p className='text-white m-0'>Consolidated view of budgets</p>
                    {state.structedSearchData && state.structedSearchData.length ?
                        <div className='mt-2' onClick={(event) => { setState(prevState => ({ ...prevState, isSearchOpen: true}))}} ref={searchRef}>
                            <SearchWithHiddenInput
                                data={state.structedSearchData}
                                applyTags={false}
                                applyLiteDarkTags={true}
                                topClassName={'bg-extraLightGray text-black f12 border-extraLightGray'}
                                searchClassName={'px-2 f12'}
                                searchIconColor={'text-black f12'}
                                searchPlaceHolder={'Search Department'}
                                className={'bg-transparent text-black pl-0 form-control-sm f12 w-100'}
                                filteredData={(filteredSearchItem, searchText ) => {
                                    setState(prevState => ({ ...prevState, filteredSearchItem: searchText === "" ? state.structedSearchData : filteredSearchItem, emptySearchText: false }))
                                }}
                                resetSearchBox={state.emptySearchText}
                            />

                            {state.isSearchOpen ?
                                <div className={`filterDropdownOptions w-100 border resourceFilter mt-1 bg-white rounded-5`} id="dropdown" style={{top: "85px"}}>
                                    {state.filteredSearchItem && state.filteredSearchItem.map((ser, sIndex) => {
                                        return(
                                            <div key={'drop_'+sIndex}>
                                                <div className={`d-flex py-1 optionDiv cursorPointer ${state.selectedSearchItem === ser.label ? 'bg-info text-white' : 'bg-white text-black'}`} onClick={() => setState(prevState => ({ ...prevState, searchProvider: state.providers.map(e => e.provider_name.toLowerCase()), graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: ser.label, callDepartmentStructure: true, emptySearchText: true, previousMonth: false, previousYear: false }))}>
                                                    <p className={`ml-1 mb-0 text-left f13`}>{ser.label}</p>
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            : null}
                        </div>
                    : null}
                </div>
                <div className='col-lg-8 col-md-12 col-sm-12 py-2'>
                    <div className='row mx-0 topBasicFilter justify-content-end'>   
                        <div className='d-flex align-self-center mr-3'>
                            {/* <div className="toggleOnOffSwitch d-flex mr-2 align-self-center" > */}
                            <div className="d-flex mr-2 align-self-center" >
                                <label className="switch mb-0">
                                    <input type="checkbox" 
                                        className="inputdemo" 
                                        checked={state.without_tax ? true : false}
                                        onChange={(event) => automaiotnUpdateStatus('without_tax')}
                                    />
                                    <span className="slider round small pl-1"></span>
                                </label>
                            </div>
                            <span className="mb-0 text-lightGray f16 small">Without Tax</span>
                        </div>
                        <div className='mr-3 align-self-center'>
                            <div className='d-flex align-self-center mb-2'>
                                {/* <div className="toggleOnOffSwitch d-flex mr-2 align-self-center" > */}
                                <div className="d-flex mr-2 align-self-center" >
                                    <label className="switch mb-0">
                                        <input type="checkbox" 
                                            className="inputdemo" 
                                            checked={state.without_savings ? true : false}
                                            onChange={(event) => automaiotnUpdateStatus('without_savings')}
                                        />
                                        <span className="slider round pl-1"></span>
                                    </label>
                                </div>
                                <span className="mb-0 text-lightGray f16 small">Without Savings Plan</span>
                            </div>
                            <div className='d-flex align-self-center'>
                                {/* <div className="toggleOnOffSwitch d-flex mr-2 align-self-center" > */}
                                <div className="d-flex mr-2 align-self-center" >
                                    <label className="switch mb-0">
                                        <input type="checkbox" 
                                            className="inputdemo" 
                                            checked={state.without_credits_discounts ? true : false}
                                            onChange={(event) => automaiotnUpdateStatus('without_credits_discounts')}
                                        />
                                        <span className="slider round pl-1"></span>
                                    </label>
                                </div>
                                <span className="mb-0 text-lightGray f16 small">Without Credits & Discounts</span>
                            </div>
                        </div>
                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild zIndex1 mr-3`} style={{minWidth: '150px', maxWidth: '150px'}}
                            onClick={(event) => {                                    
                                event.preventDefault();
                                handleChildClick(event, 'child', 'muliselectDropDown', "isProviderOpen")
                            }}
                        >
                            <label className='mb-2 mt-1 f10 d-flex'>Provider</label>
                            <p className={`d-flex mt-2 mb-0 ${state.cloudProviderId && state.cloudProviderId.length ? 'text-white' : 'placeholder'}`}>{state.cloudProviderId && state.cloudProviderId.length ? (state.cloudProviderId.length === 1 ? state.cloudProviderId[0] : state.cloudProviderId.length+ ' Selected') : 'Select'}</p>
                            {state.isProviderOpen ?
                                <MultiSelectSection 
                                    fields={["provider_name", "provider_name"]}
                                    options={state.providers}
                                    selectedValues={state.cloudProviderId ? state.cloudProviderId : []}
                                    callbackMultiSelect={(value) => setState(prevState => ({ ...prevState, cloudProviderId: value, searchProvider: [], callSearch: true }))}
                                    hideSearch={true}
                                    widthClass={'w-10'}
                                    // addAllField={true}
                                />
                            : null}
                        </div>
                        
                        <div className={`form-group my-2 cursorPointer flex-fill bd-highlight styled-feild mr-3`} style={{minWidth: '200px', maxWidth: '200px'}}
                            onClick={(event) => {                                    
                                event.preventDefault();
                                handleChildClick(event, 'child', 'singleDropDown', "isDurationOpen")
                        }}>
                            <label className='mb-2 mt-1 f10 d-flex'>Duration</label>
                            <p className={`d-flex mt-2 mb-0 ${state.datePickerStartDate ? 'text-white' : 'placeholder'}`}>
                                {state.datePickerStartDate ?
                                    <span> {momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY')}</span>
                                : 'Select'}
                            </p>
                            {state.isDurationOpen ?
                                <MultiSelectSection
                                    fields={["value", "label"]}
                                    options={DURATION_OPTIONS}
                                    widthClass={'widthMaxContent'}
                                    selectedValues={state.selectedDuration ? state.selectedDuration : ''}
                                    callbackMultiSelect={(value) => {
                                        if(value !== 'custom') {
                                            setState(prevState => ({ ...prevState, selectedDuration: value, searchProvider: [], callSearch: true }))
                                        } else {
                                            setState(prevState => ({ ...prevState, showDateRangePicker: value === 'custom' ? true : false }))
                                        }
                                    }}
                                    singleSelection={true}
                                    hideSearch={true}
                                />
                            : null}
                        </div>
                        {state.showDateRangePicker ?
                            <div className="position-absolute z999" 
                            // style={{"z-index": "999999999","right": "20px","height": "450px"}} 
                            ref={durationRef}>
                                <div className="RangePickerWithStartEndTime text-black5 pt-2 ml-n1">
                                    {/* <div className='d-flex justify-content-between'>
                                        <p className="mb-2 w-100 text-white">Date Picker</p>
                                        <i className="far fa-times align-self-center cursorPointer f18 mb-1" onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}></i>
                                    </div> */}
                                    <div className='headerDateRange' style={{'z-index': '999999999','right': '30px', 'top': '50px'}}>
                                        <DateRange
                                            editableDateInputs={true}
                                            onChange={handleSelect}
                                            moveRangeOnFirstSelection={false}
                                            ranges={dateState}
                                        />
                                    </div>
                                    <div className={`d-flex flex-wrap pt-2 border-top displayNone`}>
                                        <span className={`mr-2 f12 align-self-center text-white`}>Last</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+7d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+7d" }))}>1 Week</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+14d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+14d" }))}>2 Week</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+30d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+30d" }))}>1 Month</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+60d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+60d" }))}>2 Month</span>
                                        <span className={`mr-2 font-weight-bold f12 cursorPointer align-self-center ${state.selectedDuration === "+90d" ? "badge badge-primary text-white" : "text-filterBlue"} `} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false, durationActive: true, selectedDuration: "+90d" }))}>3 Months</span>
                                    </div>
                                    <div className='d-flex justify-content-end pt-2 mt-2 border-top displayNone'>
                                        <button className={`btn-sm bg-light text-decoration-nonep-2 text-black mr-3`} onClick={() => setState(prevState => ({ ...prevState, showDateRangePicker: false }))}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        : null}
                        {/* <div className='btn-group align-self-center' role='group' aria-label='Basic example' style={{minWidth: '100px', maxWidth: '100px'}}>
                            <button type='button' className={`cursorPointer btn btn bg-cyon text-white`} onClick={() => {
                                setState(prevState => ({ ...prevState, callSearch: true, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {} }))

                            }}>Search</button>
                            <button type='button' className={`cursorPointer btn btn bg-cyon text-white`}><i className='far fa-redo'/></button>
                        </div> */}
                    </div>
                </div>
            </div>
            {state.selectedSearchItem === 'Provider' && state.showCloudProviderSection ?
                <div className='row mx-0 my-2'>
                    <CloudCommitmentSpend
                        currencyUnit={state.currencyUnit}
                        datePickerStartDate={state.datePickerStartDate}
                        datePickerEndDate={state.datePickerEndDate}
                        without_tax = {state.without_tax}
                        without_savings = {state.without_savings}
                        without_credits_discounts = {state.without_credits_discounts}
                        updateCurrency={(currencyUnit) => setState(prevState => ({ ...prevState, currencyUnit }))}
                    />
                </div>
            : state.selectedSearchItem !== 'Provider' ?
                <React.Fragment>
                <div className='row mx-0 my-2' id='chartContainer'>
                    <div className='col-lg-12'> 
                        {state.conversionDetails ?
                            <div className='d-flex mb-1 text-lightGray'>{state.conversionDetails}</div>
                        : null}
                        <div className='d-flex justify-content-between'>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb bg-transparent mb-0 p-1">
                                    <li className="breadcrumb-item cursor-pointer text-white"
                                        // onClick={() => handleBreadCrumb('Department')}
                                    >Organization</li>
                                    {state.splitState && state.splitState.length >= 1 && state.splitState[0] !== 'Department' ?
                                        <li className="breadcrumb-item cursor-pointer text-white"
                                            onClick={() => handleBreadCrumb('Department')}
                                        >Departments</li>
                                    : null}
                                    {state.splitState && state.splitState.length ?
                                        state.splitState.map((spl, splIndex) => {
                                            return(
                                                spl !== 'accounts' ?
                                                    <li key={'split_'+splIndex} className={`breadcrumb-item ${state.splitState[splIndex+1] && state.splitState[splIndex+1] !== 'accounts' ? 'cursor-pointer text-white' : 'active text-info'}`}
                                                    onClick={() => handleBreadCrumb(state.splitState, splIndex+1)}
                                                    >
                                                        {spl === 'Department' ? 'Departments' : spl}
                                                    </li>
                                                : null
                                            )
                                        })
                                    : null}
                                </ol>
                            </nav>                            
                            <div className='d-flex justify-content-end'>
                                <p className='mb-1'>Showing data for 
                                    <span className='text-cyon ml-1'>
                                        {state.datePickerStartDate ? 
                                            momentDateGivenFormat(state.datePickerStartDate, 'DD MMM YYYY') +' - '+ momentDateGivenFormat(state.datePickerEndDate, 'DD MMM YYYY') 
                                        : null}
                                    </span>
                                    {/* <span className='ml-2'>(local time)</span> */}
                                </p>
                                <div className='d-flex ml-3 mb-1 text-right text-primary-color'>Currency is in 
                                    <div className='d-flex justify-content-start'>
                                        <div className='d-flex mr-1'>
                                            <div className="ml-1 form-check">
                                                <input type="radio"
                                                    className="form-check-input"
                                                    checked={state.currencyUnit === 'usd' ? true : false}  
                                                    onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true, callSummaryFunction: true, callTopGraphSection: true, callCloudProviderDetailApi: true }))}
                                                    value={state.currencyUnit ? true : false}
                                                />
                                            </div>
                                            <p className="form-check-label mb-0 mr-1 text-white align-self-center text-primary-color" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'usd', showLoading: true, callSummaryFunction: true, callTopGraphSection: true, callCloudProviderDetailApi: true }))}>USD</p>
                                        </div>
                                        <div className='d-flex mr-1'>
                                            <div className="form-check">
                                                <input type="radio"
                                                    className="form-check-input"
                                                    checked={state.currencyUnit === 'gbp' ? true : false}  
                                                    onChange={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true, callSummaryFunction: true, callTopGraphSection: true, callCloudProviderDetailApi: true }))}
                                                    value={state.currencyUnit ? true : false}
                                                />
                                            </div>
                                            <p className="form-check-label mb-0 mr-1 text-white align-self-center text-primary-color" onClick={() => setState(prevState => ({ ...prevState, currencyUnit: 'gbp', showLoading: true, callSummaryFunction: true, callTopGraphSection: true, callCloudProviderDetailApi: true }))}>GBP</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {state.selectedSearchItem !== 'Provider' && state.currentObsevations && Object.entries(state.currentObsevations).length ? 
                        <div className={`col-lg-12 mb-2`}>
                            <div className='bg-dark3 p-3 rounded h-100'>
                                <p className='mb-1 f16 text-white'>Cloud Provider Commitment Observations</p>
                                    <div className='d-flex justfy-content-between'>
                                        {Object.entries(state.currentObsevations).map(([oKey, oValue], oIndex) => {
                                            return(
                                                <ul className={`bg-muted rounded w-50 p-2 ${!oIndex ? 'mr-3' : ' '}`} key={'obs_'+oIndex}>
                                                    {oValue.map((obs, obsIndex) => {
                                                        return (
                                                            <li key={'obs_'+obsIndex} className="list-group-item bg-transparent pl-3 mb-1 pt-1 pr-0 py-0 m-0 custom-list-group-item">{obs}</li>
                                                        )
                                                    })}
                                                </ul>
                                            )
                                        })}
                                    </div>
                            </div>
                        </div>
                    : null}
                    {state.topSpenders && state.topSpenders.note ?
                        <p className='col-lg-12 mb-1 f14'>Note: <span className='text-white'>{state.topSpenders.note}</span></p>
                    : null}
                    {state.graphTopSpenders && Object.entries(state.graphTopSpenders).length ?
                        <div className={`col-lg-4 pr-0`}>
                            <div className='bg-dark3 p-3 rounded h-100' id="highSpending">
                                <div className='d-flex justify-content-between'>
                                    <div className='text-white'>
                                        <p className='mb-0'>{state.topSpenders && state.topSpenders.title ? state.topSpenders.title : 'Top 5 High Spenders'} </p>
                                        {/* {state.selectedSearchItem === 'Department' ? 'Departments' : (splitString(state.selectedSearchItem, ':').length > 1 ? 'Projects' : 'Initiatives')} */}
                                        {/* <small>Consolidated view of {state.selectedSearchItem === 'department' ? 'departments' : (splitString(state.selectedSearchItem, ':').length > 1 ? 'projects' : 'initiatives')} High Spending</small> */}
                                        <small>{state.topSpenders && state.topSpenders.description ? state.topSpenders.description : <span>&nbsp;</span> }</small>
                                    </div>
                                </div>
                                
                                {state.selectedAccount ?
                                    <div className='mb-n4'>
                                        <ApexHorizontalStackedBarChart
                                            graphData={state.graphTopSpenders}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            xaxis={true}
                                            xaxisFormat={'categoryString'}
                                            xaxisLabel={true}
                                            showTitle={true} //xaxis title
                                            axisLabelColor={'#666666'}
                                            paddingLeft={0}
                                            legend={false}
                                            legendPostion={'bottom'}
                                            legendHorizontalAlign={'center'}
                                            legendUseSeriesColor={true}
                                            stacked={true}
                                            height={275}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={false}
                                            gradientColor={['#009fdf', '#CD5A9F']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={'transparentTooltip'}
                                            dataLabels= {true}
                                            dataLabelsTextAnchor = {'end'}
                                            dataLabelPosition={'right'}
                                            colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                            dataLabelsOffsetX={10}
                                            chartDistributed={true}
                                            dataPointSelection={true}
                                            tooltipFixed={'left'}
                                            hideTotal={true}
                                            selectedGraphData={(item) => {
                                                let selectedBar = item
                                                if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
                                                    if(state.selectedSearchItem !== "Department") {
                                                        selectedBar = state.selectedSearchItem+':'+item
                                                    }

                                                    setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, searchProvider: [], callDepartmentStructure: true  }))
                                                }
                                            }}
                                        />
                                    </div>
                                :           
                                    <div className='mb-n4'>
                                        <ApexBarChart
                                            graphData={state.graphTopSpenders}
                                            sparkline={false}
                                            yaxis={true}
                                            yaxisLabel={true}
                                            xaxis={true}
                                            xaxisFormat={'string'}
                                            xaxisLabel={true}
                                            showTitle={true} //xaxis title
                                            axisLabelColor={'#666666'}
                                            paddingLeft={0}
                                            legend={false}
                                            legendPostion={'bottom'}
                                            legendHorizontalAlign={'center'}
                                            legendUseSeriesColor={true}
                                            stacked={false}
                                            height={275}
                                            horizontal={true}
                                            barHeight={'40%'}
                                            barEndShape={'rounded'}
                                            columnWidth={'25%'}
                                            gradient={false}
                                            gradientColor={['#009fdf', '#CD5A9F']}
                                            hideTooltipValue={true}
                                            backgroundBarShape={'rounded'}
                                            backgroundBarColors={['#333947']}
                                            showBackgroundBarColors={false}
                                            className={'transparentTooltip'}
                                            dataLabels= {true}
                                            dataLabelsTextAnchor = {'end'}
                                            dataLabelPosition={'right'}
                                            colors={['#009FDF']}
                                            dataLabelsOffsetX={0}
                                            chartDistributed={true}
                                            dataPointSelection={true}
                                            selectedGraphData={(item) => {
                                                let selectedBar = item
                                                if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
                                                    if(state.selectedSearchItem !== "Department") {
                                                        selectedBar = state.selectedSearchItem+':'+item
                                                    }
                                                    setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, searchProvider: [], callDepartmentStructure: true  }))
                                                }
                                            }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    : null}
                    
                    {!state.selectedAccount && state.graphOverSpenders && Object.entries(state.graphOverSpenders).length ?
                        <div className='col-lg-4 pr-0' id="overSpending">
                            <div className='bg-dark3 p-3 rounded h-100'>
                                <div className='d-flex justify-content-between'>                            
                                    <div className='text-white'>
                                        <p className='mb-0'>{state.topOverSepnders && state.topOverSepnders.title ? state.topOverSepnders.title : 'Top Over Spenders'}</p>
                                        {/* {state.selectedSearchItem === 'Department' ? 'Departments' : (splitString(state.selectedSearchItem, ':').length > 1 ? 'Projects' : 'Initiatives')} */}
                                        {/* <small>Consolidated view of {state.selectedSearchItem === 'Department' ? 'departments' : (splitString(state.selectedSearchItem, ':').length > 1 ? 'projects' : 'initiatives')} over spending</small> */}
                                        <small>{state.topOverSepnders && state.topOverSepnders.description ? state.topOverSepnders.description : <span>&nbsp;</span> }</small>
                                    </div>
                                </div>
                                <div className='mb-n4 mb-n3'>
                                    {/* <ApexBarChart
                                        graphData={state.graphOverSpenders}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={false}
                                        yaxisTitle={'Percentage'}
                                        xaxisFormat={'string'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#666666'}
                                        paddingLeft={0}
                                        legend={true}
                                        legendPostion={'bottom'}
                                        legendHorizontalAlign={'center'}
                                        legendUseSeriesColor={true}
                                        stacked={false}
                                        height={275}
                                        horizontal={false}
                                        barHeight={'40%'}
                                        barEndShape={'flat'}
                                        columnWidth={'25%'}
                                        gradient={false}
                                        gradientColor={['#009fdf', '#CD5A9F']}
                                        hideTooltipValue={true}
                                        backgroundBarShape={'flat'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                        // addToolTtipValueUnit={'%'}
                                        dataLabels= {false}
                                        dataLabelsTextAnchor = {'end'}
                                        dataLabelPosition={'right'}
                                        colors={["#8e3a80", "#ff8200", "#D2291C", "#43B02A", "#0057b8", "#FFCD00"]}
                                        dataLabelsOffsetX={0}
                                        chartDistributed={true}
                                        dataPointSelection={true}
                                        selectedGraphData={(item) => {
                                            let selectedBar = item
                                            if(state.selectedSearchItem !== "Department") {
                                                selectedBar = state.selectedSearchItem+':'+item
                                            }
                                            setState(prevState => ({ ...prevState, graphTopSpenders: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar }))
                                        }}
                                    /> */}
                                    
                                    <ApexNonStackedBarChart 
                                        graphData={state.graphOverSpenders}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        yaxisTitle={'Percentage'}
                                        xaxis={true}
                                        xaxisFormat={'categoryString'}
                                        xaxisLabel={true}
                                        axisLabelColor={'#666666'}
                                        paddingLeft={15}
                                        legend={true}
                                        stacked={false}
                                        height={270}
                                        horizontal={false}
                                        // barHeight={'40%'}
                                        barEndShape={'flat'}
                                        columnWidth={'55%'}
                                        gradient={true}
                                        stroke={'hide'}
                                        // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                        // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                        colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                        hideTooltipValue={true}
                                        backgroundBarShape={'flat'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                    />
                                </div>
                            </div>
                        </div>
                    : null}
                    
                    {state.graphOverSpendersForecast && Object.entries(state.graphOverSpendersForecast).length ?
                        <div className={`col-lg-4 pr-0`} id="forecastSpending">
                            <div className='bg-dark3 p-3 rounded h-100'>
                                <div className='d-flex justify-content-between'>
                                    <div className='text-white'>
                                        <p className='mb-0'>{state.overSepndersForecast && state.overSepndersForecast.title ? state.overSepndersForecast.title : 'Top 5 High Spenders Forecast'} </p>                                        
                                        <small>{state.overSepndersForecast && state.overSepndersForecast.description ? state.overSepndersForecast.description : <span>&nbsp;</span> }</small>
                                    </div>
                                </div>
                                <div className='mb-n4'> 
                                    <ApexBarChart
                                        graphData={state.graphOverSpendersForecast}
                                        sparkline={false}
                                        yaxis={true}
                                        yaxisLabel={true}
                                        xaxis={true}
                                        xaxisFormat={'string'}
                                        xaxisLabel={true}
                                        showTitle={true} //xaxis title
                                        axisLabelColor={'#666666'}
                                        paddingLeft={0}
                                        legend={false}
                                        legendPostion={'bottom'}
                                        legendHorizontalAlign={'center'}
                                        legendUseSeriesColor={true}
                                        stacked={false}
                                        height={275}
                                        horizontal={true}
                                        barHeight={'40%'}
                                        barEndShape={'rounded'}
                                        columnWidth={'25%'}
                                        gradient={false}
                                        gradientColor={['#009fdf', '#CD5A9F']}
                                        hideTooltipValue={true}
                                        backgroundBarShape={'rounded'}
                                        backgroundBarColors={['#333947']}
                                        showBackgroundBarColors={false}
                                        className={'transparentTooltip'}
                                        dataLabels= {true}
                                        dataLabelsTextAnchor = {'end'}
                                        dataLabelPosition={'right'}
                                        colors={['#009FDF']}
                                        dataLabelsOffsetX={0}
                                        chartDistributed={true}
                                        dataPointSelection={true}
                                        selectedGraphData={(item) => {
                                            let selectedBar = item
                                            if(state.splitState[state.splitState.length-1] !== 'accounts' && splitString(state.selectedSearchItem, ':').length <= 3) {
                                                if(state.selectedSearchItem !== "Department") {
                                                    selectedBar = state.selectedSearchItem+':'+item
                                                }
                                                setState(prevState => ({ ...prevState, graphTopSpenders: {}, taggedNonTaggedDistribution: {}, graphOverSpenders: {}, graphOverSpendersForecast: {}, selectedSearchItem: selectedBar, searchProvider: [], callDepartmentStructure: true  }))
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    : null}

                    {state.selectedAccount ?
                        <div className={`col-lg-4`} id="taggedDistribution">
                            <div className='bg-dark3 p-3 rounded h-100'>
                                <div className='d-flex justify-content-between'>
                                    <div className='text-white'>
                                        <p className='mb-0'>Tagged Non-tagged Distribution</p>
                                        {/* <small>Distribution view of {state.selectedSearchItem === 'Department' ? state.selectedSearchItem : (splitString(state.selectedSearchItem, ':').length > 1 ? 'projects' : 'initiatives')}</small> */}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center mt-3'>
                                    {state.taggedNonTaggedDistribution && Object.entries(state.taggedNonTaggedDistribution).length ?
                                        <ApexDonutChartNew
                                            labelColor={"#999999"}
                                            valueColor={"#999999"}
                                            graphData={state.taggedNonTaggedDistribution}
                                            legend={false}
                                            showTotal={true}
                                            showTotalLable={true}
                                            showGraphHeading={false}
                                            // height={310}
                                            // width={380}
                                            legendWidth={200}
                                            size={'70%'}
                                            gradient={false}
                                            // gradientColor={['#A88CCC', '#D88ACF']}
                                            colors={["#8E3A80", "#873B8C", "#7F3C98", "#783CA4", "#703DB0", "#693EBC"]}
                                            className={'transparentTooltip'}
                                        />
                                    : null}
                                </div>
                            </div>
                        </div>
                    : null}
                </div>
                <div className='row mx-0'>
                    <div className='col-sm-12'>
                        <div className='row'>
                            <div className='col-sm-3'>
                                <div className="bg-dark3 rounded overflowYAuto h-100">
                                    <div className="pt-3">
                                        <div className="d-flex justify-content-between mb-3 px-3">
                                            <p className="mb-0 mt-1 small text-white">Showing {state.filteredListItems && state.filteredListItems.length} of Total {state.listItems && state.listItems.length} {state.selectedSearchItem === 'Department' ? 'Departments' : (splitString(state.selectedSearchItem, ':').length > 2 ? 'Accounts' : splitString(state.selectedSearchItem, ':').length > 1 ? 'Projects' : 'Initiatives')}</p>
                                            <div className="">
                                                {state.listItems && state.listItems.length ?
                                                    <SearchWithHiddenInput
                                                        data={state.listItems}
                                                        applyTags={false}
                                                        applyLiteDarkTags={true}
                                                        topClassName={'bg-transparent text-white f12 border-lightGray rounded-5'}
                                                        searchClassName={'px-2 f12'}
                                                        searchIconColor={'text-white f12'}
                                                        searchPlaceHolder={'Search...'}
                                                        className={'bg-transparent text-white pl-0 form-control-sm f12'}
                                                        filteredData={(filteredListItems) => setState(prevState => ({ ...prevState, filteredListItems }))}
                                                    />
                                                : null}
                                            </div>
                                        </div>
                                        {state.filteredListItems && state.filteredListItems.length ? 
                                            state.filteredListItems.map((item, i) => {
                                                return(
                                                    <div key={'dep_'+i} className={`cursorPointer 
                                                        ${(state.selectedItem === item.label) ? "bg-dark" : ""} px-3`} 
                                                        onClick={() => checkForDoubleClick(item.label)}
                                                    >
                                                        <div className={`d-flex justify-content-between py-2 ${i !== 0 ? "border-mediumGray-top" : ""}`}>
                                                            <div className="w-50 align-self-center">
                                                                <h6 className="font-weight-bold text-white my-2">{item.label}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        :
                                            <div className='d-flex justify-content-center m-4'>
                                                <p>There are no data on this criteria. Please try adjusting your search.</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-9 pl-0' id='summaryDetails'>
                                {state.showSummaryLoading ? 
                                    <div className='row'>
                                        <div className='d-flex justify-content-center m-4 p-4'>
                                            <Spinner className='text-center' color='light'   />
                                        </div>
                                    </div>
                                :
                                    <React.Fragment>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                            <div className='bg-dark3 p-3 rounded h-100'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='text-white'>
                                                        <p className='mb-0'>{state.summaryDetails && state.summaryDetails.distribution && state.summaryDetails.distribution.title ? state.summaryDetails.distribution.title : 'Spend Distribution'}</p>
                                                        {/* <small>Distribution view of {state.selectedSearchItem === 'Department' ? state.selectedSearchItem : (splitString(state.selectedSearchItem, ':').length > 1 ? 'projects' : 'initiatives')}</small> */}
                                                    </div>
                                                </div>
                                                <div className='d-flex justify-content-center mt-3'>
                                                    {state.distributionDonutData && Object.entries(state.distributionDonutData).length ?
                                                        <ApexDonutChartNew
                                                            labelColor={"#999999"}
                                                            valueColor={"#999999"}
                                                            graphData={state.distributionDonutData}
                                                            legend={false}
                                                            showTotal={true}
                                                            showTotalLable={true}
                                                            showGraphHeading={false}
                                                            // height={310}
                                                            // width={380}
                                                            legendWidth={200}
                                                            size={'70%'}
                                                            gradient={false}
                                                            // gradientColor={['#A88CCC', '#D88ACF']}
                                                            colors={["#8E3A80", "#873B8C", "#7F3C98", "#783CA4", "#703DB0", "#693EBC"]}
                                                            className={'transparentTooltip'}
                                                        />
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 pl-0'>
                                            <div className='bg-dark3 p-3 rounded h-100'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='text-white'>
                                                        <p className='mb-0'>Monthly Spend {state.selectedAccount ? '' : 'vs Commitment'}</p>
                                                        {/* <small>Spend vs Commitment view of {state.selectedSearchItem === 'Department' ? 'Departments' : (splitString(state.selectedSearchItem, ':').length > 1 ? 'projects' : 'initiatives')}</small> */}
                                                    </div>
                                                </div>
                                                <div className=''> 
                                                    {state.spendCommitment && Object.entries(state.spendCommitment).length ? 
                                                        <div className='mt-n2 mb-n3'>
                                                            <ApexNonStackedBarChart 
                                                                graphData={state.spendCommitment}
                                                                sparkline={false}
                                                                yaxis={true}
                                                                yaxisLabel={true}
                                                                xaxis={true}
                                                                xaxisFormat={'datetime'}
                                                                xaxisLabel={true}
                                                                axisLabelColor={'#666666'}
                                                                paddingLeft={-5}
                                                                legend={true}
                                                                stacked={false}
                                                                height={270}
                                                                horizontal={false}
                                                                // barHeight={'40%'}
                                                                barEndShape={'flat'}
                                                                columnWidth={'55%'}
                                                                gradient={true}
                                                                stroke={'hide'}
                                                                // colors={['#5F5BA2', '#9A77D1', '#3F73AD', '#24A597']} //['#ffadad', '#ffd6a5',	'#caffbf',	'#9bf6ff']
                                                                // colors={['#FFCD00', '#ff8200', '#0057b8', '#8e3a80']}
                                                                colors={['#854097', '#693EBC', '#775BA2', '#9A77D1']}
                                                                hideTooltipValue={true}
                                                                backgroundBarShape={'flat'}
                                                                backgroundBarColors={['#333947']}
                                                                showBackgroundBarColors={false}
                                                                className={'transparentTooltip'}
                                                            />
                                                        </div>
                                                    : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-3 pl-0'>
                                            <div className='bg-dark3 border-mediumDarkGray rounded h-100'>
                                                <div className='d-flex'>
                                                    <div className='py-2 w-50 px-1'>
                                                        <p className='text-white text-center mb-1 f14'>YTD</p>
                                                        <p className='text-center f15 mb-1 text-warning d-flex justify-content-between'><span className='mr-1 f12 text-lightGray'>Budget</span> {state.summaryDetails && state.summaryDetails.ytd_budget ? thousandSeparator(state.summaryDetails.ytd_budget) : 0}</p>                                          
                                                        <p className='text-center f15 mb-1 text-warning d-flex justify-content-between'><span className='mr-1 f12 text-lightGray'>Spend</span> {state.summaryDetails && state.summaryDetails.ytd ? thousandSeparator(state.summaryDetails.ytd) : 0}</p>
                                                    </div>
                                                    <div className='py-2 border-mediumDarkGray-left w-50 px-1'>
                                                        <p className='text-white text-center mb-1 f14'>MTD</p>
                                                        <p className='text-center f15 mb-1 text-warning d-flex justify-content-between'><span className='mr-1 f12 text-lightGray'>Budget</span> {state.summaryDetails && state.summaryDetails.mtd_budget ? thousandSeparator(state.summaryDetails.mtd_budget) : 0}</p>
                                                        <p className='text-center f15 mb-1 text-warning d-flex justify-content-between'><span className='mr-1 f12 text-lightGray'>Spend</span>{state.summaryDetails && state.summaryDetails.mtd ? thousandSeparator(state.summaryDetails.mtd) : 0}</p>                                            
                                                    </div>
                                                </div>
                                                {state.summaryDetails && state.summaryDetails.observations ?
                                                    <div className='p-2 border-mediumDarkGray-top'>
                                                        <p className='text-white f14 my-2'>Observations</p>
                                                        <ul className="list-group">
                                                            {state.summaryDetails.observations.map((obs, oIndex) => {
                                                                return(
                                                                    <li key={'obs_'+oIndex} className="list-group-item bg-transparent pl-3 pr-0 py-0 m-0 custom-list-group-item">{obs}</li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row my-2'>
                                        <div className='col-sm-12 overflow-x-auto'>
                                            <div className='d-flex my-2 justify-content-between'>
                                                <p className='mb-0'>Budget vs Spending vs Forecast</p>
                                                <div className='d-flex justify-content-start'>
                                                    <div className='d-flex mr-2'>
                                                        <div className="ml-1 form-check">
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                checked={state.previousMonth}
                                                                onChange={() => setState(prevState => ({ ...prevState, previousMonth: !prevState.previousMonth, previousYear: false }))}
                                                            />
                                                        </div>
                                                        <p className="form-check-label mb-0 mr-2 text-white align-self-center text-primary-color">Compare Previous Month</p>
                                                    </div>
                                                    <div className='d-flex mr-2'>
                                                        <div className="ml-1 form-check">
                                                            <input type="checkbox"
                                                                className="form-check-input"
                                                                checked={state.previousYear}
                                                                onChange={() => setState(prevState => ({ ...prevState, previousYear: !prevState.previousYear, previousMonth: false }))}
                                                            />
                                                        </div>
                                                        <p className="form-check-label mb-0 mr-2 text-white align-self-center text-primary-color">Compare Previous Year</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    {state.tableNotes ? (
                                                        <p className='w-100 mb-1 f12'>
                                                            Note: <span className='text-white'>{state.tableNotes}</span>
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className='d-flex mb-2'>
                                                    {state.selectedAccount && state.untagggedDownloads && state.untagggedDownloads.length ?
                                                        <span onClick={untaggedResourceExcelExport}  className='btn btn-sm bg-green px-2 py-1 rounded d-flex text-white mr-2'>
                                                                <i className='fas fa-download text-white mr-2 f12 align-self-center'/> {state.untaggedDownlaodLabel ? state.untaggedDownlaodLabel : 'Untagged Resources'}
                                                        </span>
                                                    : null}
                                                    {state.tableData && state.tableData.length ? (
                                                        <span 
                                                            onClick={spendboardExcelExport} 
                                                            className='my-2 mr-3 d-flex justify-end cursor-pointer hover:text-blue-500'
                                                            >
                                                                <i className='fas fa-download text-white mr-2 f12 align-self-center'/>
                                                        </span>
                                                    ) : null}
                                                </div>
                                            </div>

                                            {/* <div className='d-flex bg-black text-white'>
                                                <div className='py-2 justify-content-start pl-3 d-flex align-self-center text-white bg-black w-16' />
                                                {state.months.map(mnt => {
                                                    return(
                                                        <div key={'mnt_'+mnt.value} className='py-2 align-self-center text-white bg-black w-7 text-right pr-3'>{mnt.label}</div>
                                                    )
                                                })}
                                            </div> */}
                                        </div>
                                        {/* <div className='col-sm-12 overflow-x-auto'>
                                            {state.tableData && state.tableData.length ?
                                                state.tableData.map((row, rIndex) => {
                                                    return(
                                                    row.year && (!row.previous_year_data || (row.previous_year_data && state.previousYear))?
                                                        <div className={`d-flex bg-white ${row.split ? 'border-darkGray-top' : ''}`} key={'table_'+rIndex}>
                                                            <div className={`d-flex py-3 pl-3 justify-content-start d-flex align-self-center text-black ${!row.split ? 'w-16' : 'w-80'}`}>
                                                                {!row.split ?
                                                                    <span className='mr-2 text-info'>({row.year ? row.year : momentDateGivenFormat(state.datePickerEndDate, 'YYYY')})</span>
                                                                : null}
                                                                <span className={`${row.split ? 'text-info f16' : 'text-nowrap'}`}>{row.label ? row.label : "Heading"}</span>

                                                                {row.description ?
                                                                    <span className='align-self-center ml-1' id={'desc_info_'+rIndex}>
                                                                        <i className="align-self-end fas fa-info-circle ml-2 cursor-pointer text-info"></i>
                                                                        <UncontrolledTooltip target={'desc_info_'+rIndex}>
                                                                            <div className='bg-black p-2 rounded'>
                                                                                <div key={rIndex} className={`py-2 text-white break-all`} style={{"maxWidth":"350px"}}>
                                                                                    <ul className="pl-2 mb-0 f12 text-lightGray font-semibold">
                                                                                        {Array.isArray(row.description) ?
                                                                                            row.description.map(des => {
                                                                                                return(
                                                                                                    <li className='mb-2'> {des}</li>
                                                                                                )
                                                                                            })
                                                                                        : 
                                                                                            row.description
                                                                                        }
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </UncontrolledTooltip>
                                                                    </span>
                                                                : null}
                                                            </div>
                                                            {row.data && row.data.length ?
                                                                row.data.map((dat, dIndex) => {
                                                                    return(
                                                                        <div key={'tab_'+dIndex} className='w-7 py-3 pr-2'>
                                                                            <div key={'table_'+rIndex+'_'+dIndex} className='justify-content-end d-flex align-self-center text-black w-100'>
                                                                                {dat ? thousandSeparator(dat) : '-'}
                                                                            </div>
                                                                            {state.previousMonth && row.spend_comparison_with_previous_period && row.spend_comparison_with_previous_period[dIndex] ?
                                                                                row.spend_comparison_with_previous_period[dIndex].percentage ?
                                                                                    <p className={`small mb-0 text-black w-100 pt-1`}>
                                                                                        {row.spend_comparison_with_previous_period[dIndex].increase ? 'up' : 'down'}
                                                                                        <span className={`text-${row.spend_comparison_with_previous_period[dIndex].increase ? 'danger' : 'success'}`}>
                                                                                            <span className={`ml-1 fa fa-arrow-${row.spend_comparison_with_previous_period[dIndex].increase ? 'up' : 'down'}`}></span> {row.spend_comparison_with_previous_period[dIndex].percentage+'%'}
                                                                                        </span>
                                                                                    </p>
                                                                                : null
                                                                            : state.previousYear && row.spend_comparison_with_previous_year && row.spend_comparison_with_previous_year[dIndex] ?
                                                                                row.spend_comparison_with_previous_year[dIndex].percentage ?
                                                                                    <p className={`small mb-0 text-black w-100 pt-1`}>
                                                                                        {row.spend_comparison_with_previous_year[dIndex].increase ? 'up' : 'down'}
                                                                                        <span className={`text-${row.spend_comparison_with_previous_year[dIndex].increase ? 'danger' : 'success'}`}>
                                                                                            <span className={`ml-1 fa fa-arrow-${row.spend_comparison_with_previous_year[dIndex].increase ? 'up' : 'down'}`}></span> {row.spend_comparison_with_previous_year[dIndex].percentage+'%'}
                                                                                        </span>
                                                                                    </p>
                                                                                : null
                                                                            : null}

                                                                        </div>
                                                                    )
                                                                })
                                                            : null}
                                                        </div>
                                                    : null
                                                    )
                                                })
                                            : null}
                                        </div> */}
                                        <div className='col-sm-12 overflow-x-auto'>
                                            <div className="relative overflow-auto mt-2">
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-white bg-black">
                                                        <tr>
                                                            <th scope="col" className="px-4 py-3">&nbsp;</th>
                                                            {state.months.map(mnt => {
                                                                return(
                                                                    <th key={'mnt_'+mnt.value} className='px-4 py-3 text-center'>{mnt.label}</th>
                                                                )
                                                            })}
                                                        </tr>
                                                    </thead>
                                                    <tbody className='text-black bg-white'>
                                                        {state.tableData && state.tableData.length ?
                                                            state.tableData.map((row, rIndex) => {
                                                                return(
                                                                    row.year && (!row.previous_year_data || (row.previous_year_data && state.previousYear)) ?
                                                                        <tr key={'tab_'+rIndex} className={`${row.split ? 'border-darkGray-top' : ''}`}>
                                                                            <td className='px-2 py-3 text-left d-flex'>
                                                                                {!row.split ?
                                                                                    <span className='mr-2 text-info'>({row.year ? row.year : momentDateGivenFormat(state.datePickerEndDate, 'YYYY')})</span>
                                                                                : null}
                                                                                <span className={`${row.split ? 'text-info text-lg text-nowrap' : 'text-nowrap'}`}>{row.label ? row.label : "Heading"}</span>
                                                                                {row.description ?
                                                                                <span className='align-self-center ml-1' id={'desc_info_'+rIndex}>
                                                                                    {/* <Icon icon="fa:info-circle" className="text-info align-self-center cursor-pointer" /> */}
                                                                                    <i className="align-self-end fas fa-info-circle ml-2 cursor-pointer text-info"></i>
                                                                                    <UncontrolledTooltip target={'desc_info_'+rIndex}>
                                                                                        <div className='bg-black p-2 rounded-md break-all'>
                                                                                            <div key={rIndex} className={`py-2 text-white break-all`} style={{"maxWidth":"350px"}}>
                                                                                                <ul className="pl-2 mb-0 f12 text-lightGray font-semibold">
                                                                                                    {Array.isArray(row.description) ?
                                                                                                        row.description.map(des => {
                                                                                                            return(
                                                                                                                <li className='mb-2'> {des}</li>
                                                                                                            )
                                                                                                        })
                                                                                                    : 
                                                                                                        row.description
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </UncontrolledTooltip>
                                                                                </span>
                                                                                : null}
                                                                            </td>
                                                                            {row.data && row.data.length ?
                                                                                state.months.map((mnt, dIndex) => { 
                                                                                    return(
                                                                                        <td key={'tab_'+rIndex+'_'+dIndex} className='px-4 py-3 text-center'>
                                                                                            <div key={'table_'+rIndex+'_'+dIndex} className='justify-content-end d-flex align-self-center text-black w-100'>
                                                                                                {row.data[dIndex] ? thousandSeparator(row.data[dIndex]) : (typeof row.data[dIndex] === 'undefined' ? '' :  '-')}
                                                                                            </div>
                                                                                            {state.previousMonth && row.spend_comparison_with_previous_period && row.spend_comparison_with_previous_period[dIndex] ?
                                                                                                row.spend_comparison_with_previous_period[dIndex].percentage ?
                                                                                                    <p className={`small mb-0 text-black w-100 pt-1`}>
                                                                                                    {row.spend_comparison_with_previous_period[dIndex].increase ? 'up' : 'down'}
                                                                                                        <span className={`text-${row.spend_comparison_with_previous_period[dIndex].increase ? 'danger' : 'success'}`}>
                                                                                                            <span className={`ml-1 fa fa-arrow-${row.spend_comparison_with_previous_period[dIndex].increase ? 'up' : 'down'}`}></span> {row.spend_comparison_with_previous_period[dIndex].percentage+'%'}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                : null
                                                                                            : state.previousYear && row.spend_comparison_with_previous_year && row.spend_comparison_with_previous_year[dIndex] ?
                                                                                                row.spend_comparison_with_previous_year[dIndex].percentage ?
                                                                                                    <p className={`small mb-0 text-black w-100 pt-1`}>
                                                                                                    {row.spend_comparison_with_previous_year[dIndex].increase ? 'up' : 'down'}
                                                                                                    <span className={`text-${row.spend_comparison_with_previous_year[dIndex].increase ? 'danger' : 'success'}`}>
                                                                                                        <span className={`ml-1 fa fa-arrow-${row.spend_comparison_with_previous_year[dIndex].increase ? 'up' : 'down'}`}></span> {row.spend_comparison_with_previous_year[dIndex].percentage+'%'}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                : null
                                                                                            : null} 

                                                                                        </td>
                                                                                    )
                                                                                })
                                                                            : 
                                                                                <td colSpan={12}>&nbsp;</td>
                                                                            }
                                                                        </tr>
                                                                    : null
                                                                )
                                                            })
                                                        : null}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>                    
                    </div>
                </div>
                </React.Fragment>
            : null}
        </div>
    );
};

export default BudgetDashboard