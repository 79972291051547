/*************************************************
 * Collider
 * @exports
 * @file SearchComponent.js
 * @author Rajasekar // on 02/06/2023
 * @copyright © 2019 Collider. All rights reserved.
 *************************************************/
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Search extends Component {
	constructor(props) {
		super(props)
		this.state = { searchText: '' }
	}

	componentDidMount = () =>{}

	// componentDidUpdate = (prevProps) => {
	// 	if(this.props.resetSearchBox !== prevProps.resetSearchBox) {
	// 		this.setState({ searchText: '' }, () => this._handleSearch())
	// 	}
	// }
	componentDidUpdate = (prevProps) => {
		if(this.props.resetSearchBox && this.props.resetSearchBox !== prevProps.resetSearchBox) {
			this.setState({ searchText: '' }, () => this._handleSearch())
			// this.setState({ searchText: this.props.searchTextReenter ? this.props.searchTextReenter : '' }, () => this._handleSearch()) //to maintian the search text after filte search
		}
		
	}

	/**
	 * Handles search using the search text
	 */
	_handleSearch = () => {
		let currentList = []
		let newList = []
		if (this.state.searchText !== '') {
			currentList = this.props.data
			newList =
				currentList &&
				currentList.filter(item => {
					let isPresent = []
					isPresent = this.recursiveSearch(item, this.state.searchText).flat(5).filter(bool => bool === true)
					// isPresent = this.recursiveSearch(item, this.state.searchText).flat().filter(bool => bool === true)
					// isPresent = this.recursiveSearch(item, this.state.searchText).flat().some(a => a.includes(true))
					// let merged = [].concat.apply([], isPresent);
					if (isPresent.length) {
						return true
					} else {
						return false
					}
				})
		} else {
			newList = this.props.data
			this.props.filteredData(newList, this.state.searchText)
		}
		if (newList) {
			// this.props.filteredData(newList, this.state.searchText) //to maintian the search text after filte search
			this.props.filteredData(newList, this.state.searchText)
		}
	}

	// function to search every object in array by recurseive
	recursiveSearch = (item, searchValue) => {
		return Object.keys(item).map(key => {
			if (item[key] !== null && typeof item[key] == 'object') {
				return this.recursiveSearch(item[key], searchValue)
				// eslint-disable-next-line valid-typeof
			} else if (typeof item[key] === 'array') {
				return item.map((arrayElement, i) => this.recursiveSearch(arrayElement[i], searchValue))
			} else {
				// if(this.props.searchFilter.includes(key)){
				return item[key] && item[key] !== null 
						? item[key].toString().toLowerCase().indexOf(searchValue.toString().toLowerCase()) > -1
						: false
				// }else{
				// return false;
				// }
			}
		})
	}

	render() {
		return (
			<div className={`input-group mb-2 ${!this.props.applyTags && !this.props.applyLiteDarkTags ? 'dark-feild' : ''} ${this.props.topClassName}`}>
				{this.props.applyTags ? (
						<div className='form-group mb-0'>
							<input
								type='text'
								value={this.state.searchText}
								onChange={e => {
									this.setState({ searchText: e.target.value }, () => this._handleSearch())
								}}
								className='form-control form-control-sm'
								placeholder={this.props.searchPlaceHolder && this.props.searchPlaceHolder !== '' ? this.props.searchPlaceHolder : 'Search'}
							/>
						</div>
				) : (
					<React.Fragment>
						<div className='input-group-prepend'>
							<div className={`input-group-text bg-transparent ${this.props.searchClassName}`}>
								<i className={`fal fa-search ${this.props.searchIconColor}`} />
								{/* <i class={`fas fa-magnifying-glass ${this.props.searchIconColor}`}></i> */}
							</div>
						</div>
						<input
							value={this.state.searchText}
							onChange={e => {
								this.setState({ searchText: e.target.value }, () => this._handleSearch())
							}}
							type='text'
							className={`form-control ${this.props.className ? this.props.className : ''}`}
							placeholder={this.props.searchPlaceHolder && this.props.searchPlaceHolder !== '' ? this.props.searchPlaceHolder : 'Search'}
						/>
					</React.Fragment>
				)}
			</div>
		)
	}
}

/**
 * Type of the props used in the component
 */
Search.propTypes = {
	data: PropTypes.array,
	filteredData: PropTypes.func,
	applyTags: PropTypes.bool,
}

export default Search
