/*************************************************
 * Collider
 * @exports
 * @file ApexSemiCircleGauge.js
 * @author Prakash // on 25/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 *************************************************/

import React, { useCallback, useEffect, useState } from 'react'
import { capitalizeFirstLetter } from '../../../utils/utility'
import Chart from 'react-apexcharts'


const ApexSemiCircleGauge = (props) => {

    const [state, setState] = useState({})

	const drawGraph = useCallback((series, labels) => {	
		let options = {
			chart: {
				// height: 280,
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                  enabled: true
                },
				zoom: {
					enabled: false,
				},	
			},
			colors: ["#8e3a80"],
			plotOptions: {
				radialBar: {
					startAngle: -90,
					endAngle: 90,
					track: {
						background: '#333',
						startAngle: -90,
						endAngle: 90,
					},
					dataLabels: {
						name: {
							show: false,
						},
						value: {
							color: "#FFF",
							fontSize: "20px",
							show: true
						}
					}
				}
			},		
			grid: {
				padding: {
					top: 0,
					right: -100,
					bottom: 0,
					left: -100
				}
			},
			fill: {
				type: "gradient",
				gradient: {
					shade: "dark",
					type: "horizontal",
					gradientToColors: ["#9A77D1"],
					stops: [0, 100]
				}
			},
			stroke: {
			  lineCap: "butt"
			},
			labels: labels            
		}
        setState(prevState => ({ ...prevState, series, options }))
    }, [])
    
    useEffect(() => {
        let graphData = props.graphData;
        let series = []
        let labels = []
		 if(graphData === 'mockData') {
            series = [300]
            labels = props.labels ? props.labels : ['Critical', 'High', 'Medium', 'Low']
        } else {
			series = graphData.data
			labels = graphData.labels
		}
		
		drawGraph(series, labels)
    }, [props, drawGraph])

	return (
		state.series ?
			<div className={`${props.className}`}>
				<Chart options={state.options} series={state.series} type="radialBar" width="200"/>
			</div>
		: null
	)
}

export default ApexSemiCircleGauge;