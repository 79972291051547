/*************************************************
 * Collider
 * @exports
 * @file CostAction.js
 * @author parakash // on 3-02-2023
 * @copyright © 20214Collider. All rights reserved.
 *************************************************/

import ApiCalls from '../../components/ApiCalls'
import {
	GET_COST_QUERIES,
	GET_COST_QUERY_RESULTS
} from '../../config'
import { ACTION_TYPES } from '../types';

/**
 * Action to get-cost-queries
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostQueries = (body, callback) => {
	return async () => {
		try {
			const url = GET_COST_QUERIES;
			const response = await ApiCalls.post(url, body)
			return response.data;
		} catch (error) {
			return error.response
		}
	}
}

/**
 * Action to get-cost-query-results
 * @param {Object} body
 * @param {Function} callback
 */
export const getCostQueryResults = (body, callback) => {
	return async dispatch => {
		try {
			const url = GET_COST_QUERY_RESULTS;
			const response = await ApiCalls.post(url, body)
			dispatch({ type: ACTION_TYPES.SET_COST_QUERY_RESULT, payload: response.data });
			return response.data;
		} catch (error) {
			return error.response
		}
	}
}