import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import { capitalizeTheFirstLetterOfEachWord, convertToLowerCase, isValidDateTime, momentConvertionUtcToLocalTime, removeUnderScore } from '../../utils/utility';
import { getJobMetrics } from '../../actions/Collider/JobStatusAction';

import MetricsTab from '../common/Mertics/MetricsTab';
import LogsTab from './LogsTab';
import ApexSemiCircleGauge from '../common/charts/ApexSemiCircleGauge';


const JobDetailsSidePanel = ({ selectedJobDetails, closeSidePanel, detailsFormat }) => {
    const dispatch = useDispatch(); // Create a dispatch function
    
    const [state, setState] = useState({
        showLoading: false,
        minMaxHeight: "770px",
        selectedTab: "Details",
        startRecord: 0,
        currentPage: 1,
        totalPages: 1,
        perPage: 20,
        logsList: [],
        filteredLogsList: [],
    })
    
    const handleKeyDown = useCallback((event) => {
        if (event.keyCode === 27) {
            closeSidePanel();
        }
    }, [closeSidePanel]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        getWindowSize();
    }, [handleKeyDown]);

    const getWindowSize = () => {
        let browserBorder = 100;
        let headerHeight = 120;
        let minMaxHeight = (window.outerHeight - browserBorder - headerHeight) + "px";
        setState(prevState => ({ ...prevState, minMaxHeight }))
    };

    useEffect(() => {
        if (state.selectedTab === "Observability") {
            let params = {}
            params.start_time = selectedJobDetails && selectedJobDetails.start_time
            params.end_time = selectedJobDetails && selectedJobDetails.end_time
            params.asset_id = selectedJobDetails && selectedJobDetails.asset_id
            if(selectedJobDetails && selectedJobDetails.created_at) {
                params.created_at = selectedJobDetails.created_at
            }
            // Fetch job metrics data here using params and update the state
            dispatch(getJobMetrics(params))
                .then((response) => {
                    if(response) {
                        setState(prevState => ({
                            ...prevState,
                            metricDetails: response,
                            showMetricLoading: false,
                            formSemiGaugeGrap: true
                        }));
                    }
                })
        }
    }, [state.selectedTab, dispatch, selectedJobDetails])

    useEffect(() => {
        if(state.metricDetails && state.metricDetails.length) {
            let graphDetails = state.metricDetails
                graphDetails.forEach(graph => {
                    let graphData = graph.data
                    Object.entries(graphData).forEach(([key, item], i) => {
                        if(item.unit === 'Percent' && (item.category === 'CPU' || item.category === 'Memory')) {
                            let graphData = {}
                            let maxValue = item.statistics && item.statistics.max ? Math.round(item.statistics.max) : 0
                            graphData.data = [maxValue]
                            graphData.labels = key

                            setState(prevState => ({ ...prevState, ['semeGauge_'+key]: graphData }))
                        }

                    })
                })
        }
    }, [state.metricDetails])

    return (
        <div className="advanced-search" style={{ zIndex: 9999999 }}>
            <div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
                <div className="overlayEqualizerLoader">
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                    <div className="spinner-item"></div>
                </div>
            </div>
            <div className="search-content bg-muted w-70">
                <div className="header-search bd-highlight bg-black d-flex justify-content-between p-2">
                    <div className="flex-fill bd-highlight text-white">
                        <h5>Job Details</h5>
                    </div>

                    <div className="text-right flex-fill bd-highlight align-self-center">
                        <i className="far fa-times fa-lg text-white cursorPointer" onClick={() => closeSidePanel()}></i>
                    </div>
                </div>
                <div className={`px-2`}>
                    <ul className={`nav nav-tabs bg-transparent text-white shadow-none border-bottom-black5`} id="myTab" role="tablist">
                        <li className="nav-item">
                            <span className={`nav-link cursorPointer text-white ${state.selectedTab === 'Details' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Details' }))}>Details</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link cursorPointer text-white ${state.selectedTab === 'Script' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Script' }))}>Script</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link cursorPointer text-white ${state.selectedTab === 'Licenses' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Licenses', showLogTab: true }))}>Licenses</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link cursorPointer text-white ${state.selectedTab === 'Observability' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Observability', showMetricLoading: true }))}>Observability</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link cursorPointer text-white ${state.selectedTab === 'Logs' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Logs', showLogTab: true }))}>Logs</span>
                        </li>
                        {/* <li className="nav-item">
                            <span className={`nav-link cursorPointer ${state.selectedTab === 'Cost' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Cost' }))}>Cost</span>
                        </li>
                        <li className="nav-item">
                            <span className={`nav-link cursorPointer ${state.selectedTab === 'Output Summary' ? ' active' : ''}`} onClick={() => setState(prevState => ({ ...prevState, selectedTab: 'Output Summary' }))}>Output Summary</span>
                        </li> */}
                    </ul>
                </div>
                <div className='m-2 overflowYAuto'>
                    {state.selectedTab === "Details" ? 
                        <React.Fragment>
                        <div className="rounded bg-dark3 p-3">
                            <React.Fragment>
                            {detailsFormat && detailsFormat.length && detailsFormat.map((row, index) => {
                                return(
                                    <div className={`${!index ? '' : 'mt-2'}`} key={'key_'+index}>
                                        <p className={`mb-1 text-cyon f20 pb-1 border-bottom`}>{capitalizeTheFirstLetterOfEachWord(row.label)}</p>
                                        <small>{row.description}</small>
                                        {row.fields && row.fields.map(fld => {
                                            return(
                                                fld === 'licenses' && selectedJobDetails && Array.isArray(selectedJobDetails[fld]) ?
                                                    selectedJobDetails[fld].map(item => {
                                                        return(
                                                            Object.entries(item).map(([key, value]) => {
                                                                return(
                                                                    <div className="d-flex mt-3">
                                                                        <span className="minWidth220 align-self-center">{removeUnderScore(key)}</span>
                                                                        <span className="text-white">{value}</span>
                                                                    </div>
                                                                )
                                                            })
                                                        )
                                                    })
                                                :
                                                    <div className="d-flex mt-3" key={fld}>
                                                        <span className="minWidth220 align-self-center">{removeUnderScore(fld)}</span>
                                                        {selectedJobDetails && selectedJobDetails[fld] ?
                                                            <React.Fragment>
                                                            {typeof selectedJobDetails[fld] === 'boolean' ?
                                                                <span className={`text-white`}>{selectedJobDetails[fld] ? 'True' : 'False'}</span>
                                                            : isValidDateTime(selectedJobDetails[fld]) ?
                                                                <span className="text-white">{momentConvertionUtcToLocalTime(selectedJobDetails[fld], "DD MMM YYYY HH:mm")}<span className='ml-2 text-info'>(local time)</span></span>
                                                            : convertToLowerCase(fld) === "status" ?
                                                                <span className={`align-self-center badge badge-outline-${convertToLowerCase(selectedJobDetails[fld])}`}>{selectedJobDetails[fld]}</span>
                                                            : convertToLowerCase(fld) === "tags" ?
                                                                <div calss="d-flex flex-wrap">
                                                                    {selectedJobDetails[fld].map(item => {
                                                                        return(
                                                                            <span className='badge text-white newDetailBadge mr-2 mb-1 f13' key={item.key}>{item.key +' : '+item.value}</span>
                                                                        )
                                                                    })}
                                                                </div>
                                                            : 
                                                                <span className="text-white">{fld === 'provider' ? selectedJobDetails[fld].toUpperCase() : selectedJobDetails[fld]}</span>
                                                                // <span className="text-white">{selectedJobDetails[fld]}</span>
                                                            }
                                                            </React.Fragment>
                                                        : null}
                                                    </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                            {selectedJobDetails && selectedJobDetails.job_script ?
                                <React.Fragment>
                                <p className={`mt-2 mb-1 text-cyon f20 pb-1 border-bottom`}>Job Script</p>
                                <div className='text-white bg-dark rounded p-3'>
                                    <pre className='text-white' style={{lineHeight: 2.5}}>{selectedJobDetails.job_script}</pre>
                                </div>
                                </React.Fragment>
                            : null}
                            
                            </React.Fragment>
                        </div>
                        </React.Fragment>
                    : state.selectedTab === "Script" ?
                        <div className="rounded bg-dark3 p-3">
                            {selectedJobDetails && selectedJobDetails.job_script ?
                                <React.Fragment>
                                <p className={`mt-2 mb-1 text-cyon f20 pb-1 border-bottom`}>Job Script</p>
                                <div className='text-white bg-dark rounded p-3'>
                                    <pre className='text-white' style={{lineHeight: 2.5}}>{selectedJobDetails.job_script}</pre>
                                </div>
                                </React.Fragment>
                            : 
                                <div className='d-flex justify-content-center m-4'>
                                    Script not available for the selecetd job
                                </div>
                            }
                        </div>
                    : state.selectedTab === "Observability" ?
                        <div className=''>
                            {state.showMetricLoading ?
                                <div className='d-flex justify-content-center m-4'>
                                    <Spinner className='text-center' color='light' />
                                </div>
                            : state.metricDetails ?
                                <React.Fragment>
                                {state.metricDetails && state.metricDetails.length ?
                                    state.metricDetails.map((item, index) => {
                                        return(
                                            <div className='mt-2 d-flex' key={'met_'+index}>
                                                {item.data && Object.keys(item.data).map((key) => {
                                                    return(
                                                        state['semeGauge_'+key] ?
                                                            <div className='mr-2' key={key}>
                                                                <p className="text-white mb-0 f17 mb-1 text-info d-flex justify-content-center">{key}</p>
                                                                <ApexSemiCircleGauge 
                                                                    graphData={state['semeGauge_'+key]}
                                                                />
                                                            </div>
                                                        : null
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                                
                                : null}
                                <MetricsTab
                                    metricDetails={state.metricDetails}
                                    selectedDetails={selectedJobDetails}
                                    height={175}
                                    showStatistics={["min", "avg", "max"]}
                                />
                                </React.Fragment>
                            : null}
                        </div>
                    : state.selectedTab === 'Licenses' ?
                        <div className="rounded">
                            <p className={`mb-2`}>Showing the list of licenses used in job <span className="text-info">{selectedJobDetails && selectedJobDetails.job_name ? selectedJobDetails.job_name : ""}</span> </p>
                            <div className='table-responsive mt-2'>
                                <table className='table table-striped mb-0 border-0'>
                                    <thead className='text-white bg-dark2'>
                                        <tr>
                                            <th className='f16'>Application</th>
                                            <th className='f16'>License Name</th>
                                            <th className='f16'>License Count</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-white bg-dark'>
                                        {selectedJobDetails.licenses && selectedJobDetails.licenses.length ?
                                            selectedJobDetails.licenses.map((row, index)=> {
                                                return(
                                                    <tr key={'lic_'+index} className={`${!index ? '' : 'border-top'}`}>
                                                        <td calss="text-white">{row.application_name ? row.application_name : ''}</td>
                                                        <td calss="text-white">{row.license_name ? row.license_name : ''}</td>
                                                        <td calss="text-white">{row.licenses_count ? row.licenses_count : ''}</td>
                                                    </tr>
                                                )
                                            })
                                        : 
                                            <div className='d-flex justify-content-center m-4'>
                                                There are no licenses for this job.
                                            </div>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    : state.selectedTab === "Logs" ?
                        <LogsTab
                            selectedJobDetails={selectedJobDetails}
                        />
                    : null}
                </div>
            </div>
        </div>
    );
};

export default JobDetailsSidePanel;