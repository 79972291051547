/*************************************************
 * Collider
 * @exports
 * @file ApexDonutWithToolTipChart.js
 * @author Prakash // on 28/06/2023
 * @copyright © 2020 Collider. All rights reserved.
 *************************************************/

import React, { useCallback, useEffect, useState } from 'react'
import { capitalizeFirstLetter } from '../../../utils/utility'
import Chart from 'react-apexcharts'


const ApexDonutWithToolTipChart = (props) => {
    const [state, setState] = useState({})
	
	const drawGraph = useCallback((series, labels, colors, overAll, overAllLabel, graphHeading) => {
		let total = {}
		total['show'] = props.showTotal
		total['label'] = labels[0] === 'No Data' ? overAllLabel : overAllLabel
		total['fontSize'] = '10px'
		total['color'] = props.labelColor ? props.labelColor : '#FFFFFF'
		if(labels[0] !== 'No Data') {
			total['formatter'] = function (w) {
				// return labels[0] === 'No Data' ? '' : overAll
				let data = w.globals.seriesTotals.reduce((a, b) => {
					return a + b
				}, 0)
				if(props.totalLableType === 'integer') {
					return parseFloat(data).toFixed(0)
				} else {
					return parseFloat(data).toFixed(2)
				}
			}
		} else {
			// total['formatter'] = function (w) {	
			// 	return 0
			// }
		}

		let fillData = {}
		if(props.gradient) {
			fillData = {
				type: 'gradient',
			}
		}
	
		let options = {
			stroke: {
				width: 0
			},	
			chart: {
				type: 'donut',
				sparkline: {
					enabled: true
				},
				zoom: {
					enabled: false,
				},
				// offsetX: -10
			},
			legend: {
				show: labels[0] === 'No Data' ? false : props.legend,
				fontSize: '12px',
				fontFamily: 'Didact Gothic',
				labels: {
					colors: '#A9A9A9',
					useSeriesColors: false
				},
				width: props.legendWidth ?  props.legendWidth : "",				
				floating: false,
				position: 'right',
				horizontalAlign: 'center',
				markers: {
					width: 10,
					height: 10,
					strokeWidth: 0,
					strokeColor: '#FFFFFF',
					fillColors: undefined,
					radius: 12,
					customHTML: undefined,
					onClick: undefined,
					offsetX: 0,
					offsetY: 0
				},
				formatter: function(seriesName, opts) {
					return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
				},
				// tooltipHoverFormatter: function(seriesName, opts) {
				// 	return seriesName + ' - <strong>' + opts.w.globals.series[opts.seriesIndex] + '</strong>'
				// }
			},
			dataLabels: {
				enabled: false
			},
			fill: fillData,
			labels: labels,
			colors: colors,
			tooltip: {
				enabled: true,
				custom: function({ series, seriesIndex, w }) {
					let color = w.globals.colors[seriesIndex]
					return '<div class="arrow_box">' +
                      '<span class="mr-2 d-flex">' +  w.globals.labels[seriesIndex].label + '</span>' +
                      '<span class="d-flex whiteSpaceNoWrap" style="color:'+ color +'">' + w.globals.labels[seriesIndex].tooltip  + '</span>' +
                      '</div>'
				},
				// fixed: {
				// 	enabled: true,
				// 	position: 'topRight',
				// 	offsetX: 0,
				// 	offsetY: 0,
				// }
			},
			plotOptions: {
				offsetX: 10,
    			offsetY: 0,
				pie: {
					donut: {
						size: props.size,
						labels: {
							show: props.showTotalLable,
							value: {								
								fontSize: '10px',
								fontWeight: 700,
								color: props.valueColor ? props.valueColor : '#FFFFFF',
								offsetY: 0
							},
							name: {
								show: false,
								fontSize: '10px',
								color: props.valueColor ? props.valueColor : '#FFFFFF',
								offsetY: -2
							},	
							total: total
						}
					},      
				}
			}
		}

        setState(prevState => ({ ...prevState, series, options, graphHeading }))
    }, [props])
    
    useEffect(() => {
        let overAll = 0
        let overAllLabel = 'Total'
        let graphData = props.graphData;
        let series = []
        let labels = []
		let graphHeading = ''
		let colors = props.colors
		let formatedColorsArray = []
		if(graphData === 'successFailMockData') {
			overAll = 110
			// overAllLabel = 'Total'
            series = [60, 50]
            labels = ['Success', 'Fail']
		} else if(graphData === 'mockData') {
			overAll = 84
            series = [30, 10, 4, 40]
            labels = props.labels ? props.labels : ['Critical', 'High', 'Medium', 'Low']
        } else {
			graphData && Object.entries(graphData).length && Object.entries(graphData).forEach(([key, value]) => {
				if(key === 'items') {
					let totalCount = 0
					if(value.length) {
						value.forEach(item => {
							totalCount += item.item_count
							series.push(item.item_count)
							labels.push(item.item_name)
						})
					} else {
						series = [100]
						colors = [props.emptyGraphColor ? props.emptyGraphColor : '#D9D9D6']
						labels = ['No Data']
					}

					if(!graphData.total) {
						overAll = Number.isInteger(totalCount) ? totalCount : parseFloat(totalCount).toFixed(2)
					}
					
					if(!totalCount) {
						series = [100]
						colors = [props.emptyGraphColor ? props.emptyGraphColor : '#D9D9D6']
						labels = ["No Data"]
					}
				}
			})

            if(formatedColorsArray && formatedColorsArray.length) {
                colors = formatedColorsArray
            }

			// if(series.length === 2 && colors.length > 2) {
			// 	colors = [colors[0], '#D9D9D6']
			// }
		}

		overAll = overAll ? parseFloat(overAll).toFixed(2) : 0
		
		drawGraph(series, labels, colors, overAll, overAllLabel, graphHeading)
    }, [props, drawGraph])

	return (
		state.series ?
			<div className={`${props.className} apexCommonDonutChart`}>
				<p className={`font-weight-bold mb-1 ${props.graphHeadingPosition ? props.graphHeadingPosition : 'text-center'} ${props.showGraphHeading ? '' : 'displayNone'}`}>{state.graphHeading ? state.graphHeading : ''}</p>
				<Chart options={state.options} series={state.series} type="donut" height={props.height} width={props.width} />
			</div>
		: null
	)
}

export default ApexDonutWithToolTipChart;