import React, { useState } from 'react';
import TreeView from './TreeView';
import { Spinner } from 'reactstrap';

const TreeNode = ({ node, onNodeToggle, onNodeToggleFolder, onNodeClick }) => {
  const [loaderMountPathExpand, setLoaderMountPathExpand] = useState(false)
  const handleNodeToggle = async (node) => {
    if (onNodeToggle) {
      setLoaderMountPathExpand(true)
      await onNodeToggle(node);
      await onNodeToggleFolder(node);
      setLoaderMountPathExpand(false)
    }
  };
  
  return (
    <li>
      <div className='d-flex'>
        <span onClick={() => handleNodeToggle(node)}>
          {(node && node.expanded && node.children) ? <><i className="fa fa-angle-down" aria-hidden="true"></i> </> : (node.type === 'directory') ? <><i className="fa fa-angle-right" aria-hidden="true"></i> </>: (<></>)}
          {loaderMountPathExpand && <Spinner size="sm"></Spinner> }
        </span> 
        <span className='d-flex text-nowrap ' onClick={() => {
          onNodeClick(node)
          handleNodeToggle(node)
        }}>{(node.type === 'directory') ? <i className="fa fa-folder fa-1x mr-1 align-self-center" style={{color: '#FFE9A2'}}></i> : <i className="fa fa-file fa-1x mr-1"></i> } {node.name}</span>
      </div>
      {node && node.children && node.expanded && (
        <TreeView
          data={node.children}
          onNodeToggle={onNodeToggle}
          onNodeClick={onNodeClick}
          onNodeToggleFolder={onNodeToggleFolder}
          parent={false}
        />
      )}
    </li>
  );
};

export default TreeNode;