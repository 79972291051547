/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-mixed-spaces-and-tabs */
/*************************************************
 * Collider
 * @exports
 * @file ManageUsers.js
 * @author Prakash // on 18/01/2024
 * @copyright © 2024 Collider. All rights reserved.
 **************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch hook to dispatch actions
import { useLocation } from 'react-router-dom';

import { deleteUser, listAllUserDetails } from '../../../actions/UserAction'

import { capitalizeAllLetter, capitalizeTheFirstLetterOfEachWord, currentLocaltime, momentConvertionUtcToLocalTime, momentDateFormat, thousandSeparator } from '../../../utils/utility'
import Search from '../../common/SearchWithHiddenInput'

import ResizeableDarkThemeTable from '../../designComponents/Table/ResizeableDarkThemeTable';
import { CSVLink } from 'react-csv';
import CreateUpdateUserPanel from './CreateUpdateUserPanel';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Store as CommonNotification } from 'react-notifications-component';

const ManageUsers = () => {	
	const[state, setState] = useState({
		showLoading: true,
		selectedUserDetails: {},
		actionsAllowed: []
	})

	const userMenu = useSelector(state => state?.filters?.userMenu || false);

    const dispatch = useDispatch(); // Create a dispatch function\
    const location = useLocation();
	const currentPath = location.pathname;

	useEffect(() => {
        if(userMenu && Object.entries(userMenu).length) {
			let actionsAllowed = []
			userMenu.menu && userMenu.menu.forEach(row => {
                if(row.submenulist && row.submenulist.length) {
                    if(row.submenulist.filter(e => e.link === currentPath && e.action).length) {
                        actionsAllowed = row.submenulist.filter(e => e.link === currentPath)[0].action
                    }
				} else {
					if(row.action) {
						actionsAllowed = row.action
					}
				}
			})
            setState(prevState => ({ ...prevState, actionsAllowed }))
		}
    }, [userMenu, currentPath])

	useEffect(() => {
		setState(prevState => ({ ...prevState, callSearch: true }));
		setTimeout(() => { setState(prevState => ({ ...prevState, showLoading: false })) }, 3000)
	}, [])

	useEffect(() => {
		if(state.callSearch) {
			setState(prevState => ({ ...prevState, callSearch: false }))
			let params = {}
			dispatch(listAllUserDetails(params))
				.then((response) => {
					if(response) {
						setState(prevState => ({ ...prevState, listUsers: response, filterListUsers: response, showLoading: false }))
					} else {
						setState(prevState => ({ ...prevState, listUsers: [], filterListUsers: [], showLoading: false }))
					}
				})
		}
	}, [state.callSearch, dispatch])

	useEffect(() => {
        let dataResult = state.filterListUsers && state.filterListUsers.length ? state.filterListUsers : [];
		if(dataResult.length) {
			let headers = []
			Object.entries(dataResult[0]).forEach(([key, value]) => {
				if(typeof value === 'string') {
					let headerRow = {}
					headerRow.label = capitalizeTheFirstLetterOfEachWord(key)
					headerRow.key = key
					headers.push(headerRow)
				}
			})

			setState(prevState => ({ ...prevState, tableHeaders: headers }))
		}
    }, [state.filterListUsers])

    const deleteUserFunction = () => {
        setState(prevState => ({ ...prevState, showDeleteLoading: true }))
        let item = state.deleteUserDetails 
        let params = {}
		params.delete_user_id = item.userId
		dispatch(deleteUser(params))
			.then((response) => {
				if(response) {
					let messageType = 'danger'
					let message = response && response.message ? response.message : "Error in deleting"

					if(response.status) {
						messageType = 'success'
						// toggle()
						setTimeout(() => { setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal, callSearch: true, showDeleteLoading: false }))}, 1000)
					} else {
						setState(prevState => ({ ...prevState, showDeleteLoading: false }))
					}


					CommonNotification.addNotification({
						//title: 'Wonderful!',
						message: message,
						type: messageType,
						insert: 'top',
						container: 'top-center',
						// animationIn: ['animate__animated', 'animate__fadeIn'],
						// animationOut: ['animate__animated', 'animate__fadeOut'],
						dismiss: {
							duration: 5000,
							onScreen: false,
							pauseOnHover: true,
							showIcon: true,
						}
					});
				}
			})
        // } else {
        //     if(!state.queue_name || state.queue_name === '') {
        //         setState(prevState => ({ ...prevState, deleteConfirmMessage: "Please enter queue name", showDeleteLoading: false }))
        //     } else if(state.queue_name !== item.queue_name) {
        //         setState(prevState => ({ ...prevState, deleteConfirmMessage: "Entered Queue name does not match selected queue name", showDeleteLoading: false }))
        //     }
        // }

    }

	/**
	 * Renders cost comparison Landing page
	*/
	return (
		<div className='container-fluid overflow-auto flex-grow-1 px-0'>
			<div className={`loaderOverlay ${state.showLoading ? "" : 'displayNone'}`}>
				<div className="overlayEqualizerLoader">
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
					<div className="spinner-item"></div>
				</div>
			</div>
			
			
			<div className='col-sm-12 p-0'>
				<div className='row mx-0 pb-2 bg-nero'>
					<div className='col-lg-3 col-3 py-2'>
						<h6 className='text-white m-0'>Manage Users</h6>
						<p className='text-white m-0'>Manage list of users</p>
					</div>
					<div className='col-lg-9 col-9 py-2'>
                        <div className='row mx-0 d-flex justify-content-end'>
							{state.allocationDetails && state.allocationDetails.length ?
								state.allocationDetails.map(row => {
									return(
										<div className='bg-dark3 border-mediumDarkGray rounded h-100 px-5 py-3 mr-3'>
											<div className='d-flex justify-content-center'>
												<div className='w-fit-content'>
													<p className='text-white text-center mb-1 f14'>{capitalizeAllLetter(row.cloudProviderId)}&nbsp;Commitment<span className='ml-1 text-lightGray f14'>({momentDateFormat(currentLocaltime(), 'YYYY')})</span></p>
													<p className='text-center f18 mb-0 text-warning'>{thousandSeparator(row.allocation)} <span className='ml-1 text-lightGray f14'>GBP</span></p>
												</div>
											</div>
										</div>
									)
								})	
							: null}
						</div>
					</div>				
				</div>
				{state.showCreateUserPanel ?
					<CreateUpdateUserPanel 
						closeSidePanel={() => setState(prevState => ({...prevState, showCreateUserPanel: false }))}
						pageMode={state.pageMode}
						refreshApi={() => setState(prevState => ({...prevState, callSearch: true  }))}
						selectedUserDetails={state.selectedUserDetails}
					/>
				: null}
				<div className='row mx-0'>
					<div className='col-sm-12'>
						<div className='mt-2'>
							<div className='d-flex justify-content-between pt-2'>
								<div className=''>
									<p className='mb-0'>
										<small>Showing {state.filterListUsers && state.filterListUsers.length ? state.filterListUsers.length : 0 } out of total {state.listUsers && state.listUsers.length ? state.listUsers.length : 0} user(s)</small>
										{state.selectedProvider ?
											<React.Fragment>
											<small className='ml-1'>obtained from the</small>
											<small className='ml-1 text-info'>{state.selectedProvider.toUpperCase()}</small>
											<small className='ml-1'>provider</small>
											</React.Fragment>
										: null}
										{state.selectedAccount && state.selectedAccount.length ?
											<React.Fragment>
											<small className='ml-1'>with the account</small>
											<small className='badge badge-outline-info mx-1'>{state.selectedAccount[0]}</small>
											{state.account_id.length > 1 ?
												<small className='ml-1 text-info'>more</small>
											: null}
											</React.Fragment>
										: null}
										{state.selectedRegion && state.selectedRegion.length ?
											<React.Fragment>
											<small className='ml-1'>in the</small>
											<small className='badge badge-outline-info mx-1'>{state.selectedRegion[0]}</small>
											{state.selectedRegion.length > 1 ?
												<small className='ml-1 text-info'>more</small>
											: null}
											<small className='ml-1'>region</small>
											</React.Fragment>
										: null}
									</p>
								</div>
								
								<div className='align-self-center w-30'>
									<div className='d-flex justify-content-end'>
										{state.filterListUsers && state.filterListUsers.length ?
											<CSVLink
												data={state.filterListUsers ? state.filterListUsers : []} 
												headers={state.tableHeaders ? state.tableHeaders : []}
												filename={'job-lists.csv'}
												className={'mt-2'}
												target='_blank'
											>
												<span className='d-flex text-primary-color mr-2'>
													<i className='fas fa-download text-primary-color mr-2'/>
												</span>
											</CSVLink>
										: null}
										{state.listUsers && state.listUsers.length ?
											<Search
												data={state.listUsers ? state.listUsers : []}
												applyTags={false}
												applyLiteDarkTags={true}
												topClassName={'bg-transparent f12 border-mediumDarkGray rouded align-self-center'}
                                                searchClassName={'px-2 f12'}
                                                searchIconColor={'text-white f12'}
                                                searchPlaceHolder={'Search....'}
                                                className={"bg-transparent text-white form-control-sm f12"}
												filteredData={(filterListUsers) => setState(prevState => ({ ...prevState, filterListUsers }))}
											/>
										: null}
										
										{state.actionsAllowed && state.actionsAllowed.includes('create') ?
											<button className={`btn bg-green p-1 ml-3 text-white align-self-center`} onClick={() => {
												setState(prevState => ({ ...prevState, showCreateUserPanel: true, pageMode: 'Create' }))
											}}>Create&nbsp;User</button>
										: null}
									</div>                                    
								</div>
							</div>

							<div className='row mx-0'>
								<div className='col-lg-12 col-12 px-0 py-1'>
									{state.filterListUsers && state.filterListUsers.length ?
										<ResizeableDarkThemeTable
											columns={[
												{
													Header: 'Date',
													accessor: d => d.registeredDate,
													Cell: cellInfo => (
													<span>
														{cellInfo.row.original.registeredDate ? momentConvertionUtcToLocalTime(cellInfo.row.original.registeredDate, 'DD MMM YYYY HH:mm') : ''}
													</span>
													),
													width: 200
												},
												{
													Header: 'Organization',
													accessor: 'organization',
													width: 200,
												},
												{
													Header: 'Name',
													accessor: 'userName',
													Cell: cellInfo => (
														<div className="d-flex justify-content-between cursor-pointer align-self-center">
															<div className='text-info mr-2'
																onClick={() => setState(prevState => ({
																	...prevState,
																	pageMode: 'View', 
																	showCreateUserPanel: true,
																	selectedUserDetails: cellInfo.row.original
																}))}
															>{cellInfo.row.original.userName}</div>
														</div>
													),
													
													width: 250,
												},
												{
													Header: 'Short Name',
													accessor: 'shortName',
													Cell: cellInfo => (
														<div className="d-flex justify-content-between cursor-pointer align-self-center">
															<div className='text-info mr-2'
																onClick={() => setState(prevState => ({
																	...prevState,
																	pageMode: 'View', 
																	showCreateUserPanel: true,
																	selectedUserDetails: cellInfo.row.original
																}))}
															>{cellInfo.row.original.shortName}</div>
														</div>
													),
													
													width: 200,
												},
												{
													Header: 'Email',
													accessor: 'emailId',
													width: 250,
												},
												{
													Header: ' ',
													Cell: cellInfo => (
														<div className="d-flex justify-content-between cursor-pointer align-self-center">
															{/* <div className='text-info mr-2'
																onClick={() => setState(prevState => ({
																	...prevState,
																	pageMode: 'View', 
																	selectedUserDetails: cellInfo.row.original,
																	showCreateUserPanel: true
																}))}
															>View </div> */}
															{state.actionsAllowed && state.actionsAllowed.includes('update') ?
																<div className='text-info mr-2'
																	onClick={() => setState(prevState => ({
																		...prevState,
																		pageMode: 'Edit', 
																		selectedUserDetails: cellInfo.row.original,
																		showCreateUserPanel: true
																	}))}
																>Edit </div>
															: null}
															{state.actionsAllowed && state.actionsAllowed.includes('delete') ?
																<div className='text-danger mr-2'
																	onClick={() => setState(prevState => ({
																		...prevState,
																		deleteModal: true,
																		deleteUserDetails: cellInfo.row.original
																	}))}
																>Remove </div>
															: null}
														</div>
													),
													width: 100
												},
												{
													Header: 'Role',
													accessor: 'roleName',
													width: 350,
												},
											]}
											data={state.filterListUsers}
											perPage={20}
											tableHead={'bg-black text-white'}
											tableBody={'bg-dark3 text-white'}
											tableType={'table-dark-hover-none'}
											perPageClass={`bg-transparent text-lightGray`}
											textColor={'text-black'}
											selectedColor={'bg-transparent'}
											dashboard={state.filterListUsers && state.filterListUsers.length ? true : false}
											sortByColumn={''}
											riskTooltip={[0]}
											onClickRow={tableRow => setState(prevState => ({ ...prevState, selectedListUsers: tableRow }))}
											// tableSize={'table-sm'}
										/>
									: 
										<div className='d-flex justify-content-center m-4'>
											<p>There are no data on this criteria. Please try adjusting your filter.</p>
										</div>
									}
									<Modal isOpen={state.deleteModal} toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))} {...state.selectedDeleteItem}>
										<ModalHeader toggle={() => setState(prevState => ({ ...prevState, deleteModal: !state.deleteModal }))}>Delete User</ModalHeader>
										<ModalBody>
											Are you sure you want to delete the user <span className='f18 mx-1'>"{state.deleteUserDetails && state.deleteUserDetails.userName ? state.deleteUserDetails.userName : ''}"</span>
											{/* <Input
												type='text'
												placeholder='Enter Queue name   '
												className={`inputTextbox bg-transparent border-lightGray text-black`}
												// maxlength='128'
												value={state.queue_name ? state.queue_name : ''}
												onChange={e => handleInputChange('queue_name', e.target.value)}
											/> */}
											{state.deleteConfirmMessage ?
												<small className='text-red'>{state.deleteConfirmMessage}</small>
											: null}
											{/* Are you sure you want to delete the <span className='f18 mx-1 text-red'>{state.selectedDeleteItem && state.selectedDeleteItem.queue_name ? state.selectedDeleteItem.queue_name : 'Name'}</span> queue */}

										</ModalBody>
										<ModalFooter className="border-0">
										<Button color="primary" onClick={() => deleteUserFunction()}>
											{state.showDeleteLoading ?
												<Spinner size='sm' color='light' className='mx-2' />
											: null}Ok
										</Button>{' '}
										<Button color="secondary" onClick={() => setState(prevState => ({ ...prevState, deleteModal: false }))}>
											Cancel
										</Button>
										</ModalFooter>
									</Modal>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManageUsers