import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux"; // Import useDispatch hook to dispatch actions
import { Input } from "reactstrap";
import { setHpcPropsDetails } from "../../../actions/Collider/HpcAction";
import MultiSelectSection from "../../common/MultiSelectSection";
import { noSpace, onlyNumeric } from "../../../utils/utility";

const HeadNodeSection = (props) => {
  const createUpdateCluster = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.createUpdateCluster || false
  );
  const propsClusterFilters = useSelector(
    (state) => state?.hpc?.hpcPropsDetails?.propsClusterFilters || false
  );

  const dispatch = useDispatch(); // Create a dispatch function

  const [state, setState] = useState({});

  const callPageVist = useCallback(() => {
    let clusterConfiguration =
      createUpdateCluster && createUpdateCluster.cluster_configuration
        ? createUpdateCluster.cluster_configuration
        : {};

    Object.entries(clusterConfiguration).forEach(([key, value]) => {
      if (key !== "s3_access") {
        if (key === "on_node_configured_script" && value) {
          setState((prevState) => ({ ...prevState, showNodeScript: true }));
        } else if (key === "on_node_updated_script" && value) {
          setState((prevState) => ({ ...prevState, showUpdatedScript: true }));
        }
        setState((prevState) => ({ ...prevState, [key]: value }));
      }
    });
    let s3AccessArray = [0];
    if (
      clusterConfiguration.s3_access &&
      clusterConfiguration.s3_access.length
    ) {
      s3AccessArray = [];
      clusterConfiguration.s3_access.forEach((row, index) => {
        s3AccessArray.push(index);
        setState((prevState) => ({
          ...prevState,
          ["bucket_name_" + index]: row.bucket_name ? row.bucket_name : "",
          ["enable_write_access_" + index]: row.enable_write_access
            ? true
            : false,
        }));
      });
    }
    setState((prevState) => ({ ...prevState, s3AccessArray }));
  }, [createUpdateCluster]);

  useEffect(() => callPageVist(), [callPageVist]);

  const addPolicies = () => {
    let policies = state.policies ? state.policies : [];
    policies.push(state.policy);

    handleConfigurationInputChange("policies", policies);
    setState((prevState) => ({ ...prevState, policies, policy: "" }));
  };

  const removePolicies = (value) => {
    let policies = state.policies.filter((e) => e !== value);

    handleConfigurationInputChange("policies", policies);
    setState((prevState) => ({ ...prevState, policies }));
  };

  const addS3Section = (array) => {
    let rowList = state[array];
    if (state[array]) {
      let value = state[array][state[array].length - 1];
      value = value + 1;
      rowList.push(value);

      setState((prevState) => ({ ...prevState, [array]: rowList }));
    }

    setTimeout(() => {
      handleConfigurationInputChange("s3_access");
    }, 500);
  };

  const removeS3Section = (array, index, i) => {
    setState((prevState) => ({
      ...prevState,
      ["bucket_name_" + index]: "",
      ["enable_write_access_" + index]: false,
    }));

    let rowList = state[array];
    rowList.splice(i, 1);
    setState((prevState) => ({ ...prevState, [array]: rowList }));

    setTimeout(() => {
      handleConfigurationInputChange("s3_access");
    }, 500);
  };

  const removefromList = (value, listField) => {
    let filteredListItem = state[listField].filter((e) => e !== value);

    let array = [];
    filteredListItem.forEach((row) => {
      let rowValue = {
        value: row,
        label: row,
      };
      array.push(rowValue);
    });

    setState((prevState) => ({ ...prevState, [listField]: filteredListItem }));
  };

  const handleConfigurationInputChange = (label, value, valueField, type) => {
    let obj = createUpdateCluster ? createUpdateCluster : {};
    let clusterConfiguration = obj.cluster_configuration
      ? obj.cluster_configuration
      : {};
    if (label === "s3_access") {
      let s3Accesss = [];
      if (valueField) {
        state.s3AccessArray.forEach((row) => {
          let dataRow = {};
          let bucketName = state["bucket_name_" + row];
          if ("bucket_name_" + row === valueField) {
            bucketName = value;
          }

          let enableWriteAccess = state["enable_write_access_" + row]
            ? true
            : false;
          if ("enable_write_access_" + row === valueField) {
            enableWriteAccess = value ? true : false;
          }

          dataRow.bucket_name = bucketName;
          dataRow.enable_write_access = enableWriteAccess;
          if (bucketName) {
            s3Accesss.push(dataRow);
          }
        });
      } else {
        state.s3AccessArray.forEach((row) => {
          let dataRow = {
            bucket_name: state["bucket_name_" + row],
            enable_write_access: state["enable_write_access_" + row]
              ? true
              : false,
          };
          if (state["bucket_name_" + row]) {
            s3Accesss.push(dataRow);
          }
        });
      }
      clusterConfiguration[label] = s3Accesss;
    } else {
      clusterConfiguration[label] =
        type === "integer" ? parseInt(value) : value;
    }

    obj["cluster_configuration"] = clusterConfiguration;
    dispatch(setHpcPropsDetails("createUpdateCluster", obj));

    setState((prevState) => ({ ...prevState, [label]: value }));
  };

  const handleChildClick = (event, type, dropdownType, section) => {
    event.stopPropagation();

    let clickedChild = [];
    if (type === "child") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }
      clickedChild.push(section);
      setState((prevState) => ({
        ...prevState,
        [section]:
          dropdownType === "singleDropDown" && state[section] ? false : true,
        clickedChild,
      }));
    } else if (type === "parent") {
      if (state.clickedChild && state.clickedChild.length) {
        state.clickedChild.forEach((row) => {
          setState((prevState) => ({ ...prevState, [row]: false }));
        });
      }

      setState((prevState) => ({ ...prevState, clickedChild }));
    }
  };

  return (
    <div
      className="rounded bg-dark p-3"
      onClick={(event) => handleChildClick(event, "parent")}
    >
      <div className={`d-flex`}>
        <div className="py-1 w-15">
          <div className="d-flex justify-content-between">
            <label className="align-self-center m-0">Instance Type</label>
            {state.hasError && state.instance_type ? (
              <small className="text-issues">required</small>
            ) : null}
          </div>
          <div
            className="border-lightGray bg-transparent rounded-5"
            onClick={(event) => {
              if (!state.showInstanceType) {
                event.preventDefault();
                handleChildClick(
                  event,
                  "child",
                  "singleDropDown",
                  "showInstanceType"
                );
              }
            }}
          >
            <p
              className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                state.instance_type && props.pageMode === "Create"
                  ? "text-white"
                  : "placeholder"
              }`}
            >
              {state.instance_type ? state.instance_type : "Select"}
              {props.pageMode === "Create" ? (
                <i className="fa fa-caret-down mt-1" />
              ) : null}
            </p>
            {state.showInstanceType && props.pageMode === "Create" ? (
              <MultiSelectSection
                fields={["instance_type", "instance_type"]}
                // className={}
                removeTop={true}
                options={
                  propsClusterFilters && propsClusterFilters.instance_type
                    ? propsClusterFilters.instance_type
                    : []
                }
                selectedValues={
                  state["instance_type"] ? state["instance_type"] : []
                }
                callbackMultiSelect={(value) => {
                  if (!value || typeof value === "string") {
                    setState((prevState) => ({
                      ...prevState,
                      ["instance_type"]: value,
                    }));
                    handleConfigurationInputChange("instance_type", value);
                  } else {
                    value.preventDefault();
                    handleChildClick(value, "search", "singleDropDown", "");
                  }
                }}
                singleSelection={true}
                hideSearch={false}
                topClass={"auto"}
                widthClass={"minWidth220"}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className={`d-flex mt-3`}>
        <div className="py-1 w-33">
          <div className="d-flex justify-content-between">
            <label className="align-self-center m-0">Subnet Id </label>
            {state.hasError && !state.subnet_id ? (
              <small className="text-issues">required</small>
            ) : null}
          </div>
          <div
            className="border-lightGray bg-transparent rounded-5"
            onClick={(event) => {
              if (!state.showSubnetId) {
                event.preventDefault();
                handleChildClick(
                  event,
                  "child",
                  "singleDropDown",
                  "showSubnetId"
                );
              }
            }}
          >
            <p
              className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                state.subnet_id && props.pageMode === "Create"
                  ? "text-white"
                  : "placeholder"
              }`}
            >
              {state.subnet_id ? state.subnet_id : "Select"}
              {props.pageMode === "Create" ? (
                <i className="fa fa-caret-down mt-1" />
              ) : null}
            </p>
            {state.showSubnetId && props.pageMode === "Create" ? (
              <MultiSelectSection
                options={
                  propsClusterFilters && propsClusterFilters.subnet_ids
                    ? propsClusterFilters.subnet_ids
                    : []
                }
                selectedValues={state.subnet_id ? state.subnet_id : ""}
                callbackMultiSelect={(value) => {
                  if (!value || typeof value === "string") {
                    setState((prevState) => ({
                      ...prevState,
                      subnet_id: value,
                    }));
                    handleConfigurationInputChange("subnet_id", value);
                  } else {
                    value.preventDefault();
                    handleChildClick(value, "search", "singleDropDown", "");
                  }
                }}
                singleSelection={true}
                hideSearch={false}
                topClass={"auto"}
                widthClass={"minWidth220"}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-flex mt-3">
        <div
          className="py-1 w-33"
          onClick={(event) => {
            event.preventDefault();
            handleChildClick(
              event,
              "child",
              "muliselectDropDown",
              "showSecurityGraoupDropdown"
            );
          }}
        >
          <p className="b-block mb-0">Security Group IDs</p>
          <p
            className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${
              state.security_group_ids && state.security_group_ids.length
                ? "text-white"
                : "placeholder"
            }`}
            style={{ height: "38px" }}
          >
            {state.security_group_ids && state.security_group_ids.length
              ? state.security_group_ids.length + " Selected"
              : "Select"}
            <i className="fa fa-caret-down mt-1" />
          </p>
          {state.showSecurityGraoupDropdown ? (
            <div className="position-relative">
              <MultiSelectSection
                // fields={["account_id", "account_name"]}
                // className={}
                removeTop={true}
                options={
                  propsClusterFilters && propsClusterFilters.security_group_ids
                    ? propsClusterFilters.security_group_ids
                    : []
                }
                selectedValues={
                  state.security_group_ids ? state.security_group_ids : []
                }
                callbackMultiSelect={(value) => {
                  handleConfigurationInputChange("security_group_ids", value);
                  setState((prevState) => ({
                    ...prevState,
                    security_group_ids: value,
                  }));
                }}
                widthClass={"minWidth220"}
              />
            </div>
          ) : null}
          {state.security_group_ids && state.security_group_ids.length
            ? state.security_group_ids.map((row) => {
                return (
                  <span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">
                    {row}
                    <i
                      className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                      onClick={() => removefromList(row, "security_group_ids")}
                    ></i>
                  </span>
                );
              })
            : null}
        </div>
      </div>
      <p className="text-white f16 mt-3">Scripts</p>
      <div className="bg-dark3 p-3 rounded mt-3">
        <div className={"d-flex"}>
          <div className="py-1 w-33 align-self-center">
            <div className="d-flex">
              <label className="mb-0">
                <input
                  type="checkbox"
                  className="inputdemo mt-1"
                  checked={state.showNodeScript ? true : false}
                  onChange={() => {
                    setState((prevState) => ({
                      ...prevState,
                      showNodeScript: !state.showNodeScript,
                    }));
                  }}
                />
              </label>
              <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                Run script on node configured
              </span>
            </div>
          </div>
        </div>
        {state.showNodeScript ? (
          <div className={"d-flex my-3"}>
            <div className="py-1 w-33">
              <div className="d-flex justify-content-between">
                {/* <label className='align-self-center m-0'>Node Configured Script</label> */}
                {state.hasError && !state.on_node_configured_script ? (
                  <small className="text-issues">required</small>
                ) : null}
              </div>
              <Input
                type="text"
                placeholder="Enter s3 file path"
                className="inputTextbox border-lightGray bg-transparent text-white"
                // maxlength='128'
                value={
                  state.on_node_configured_script
                    ? state.on_node_configured_script
                    : ""
                }
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    on_node_configured_script: e.target.value,
                  }));
                  handleConfigurationInputChange(
                    "on_node_configured_script",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}
        <div className={"d-flex"}>
          <div className="py-1 w-33 align-self-center">
            <div className="d-flex">
              <label className="mb-0">
                <input
                  type="checkbox"
                  className="inputdemo mt-1"
                  checked={state.showUpdatedScript ? true : false}
                  onChange={() => {
                    setState((prevState) => ({
                      ...prevState,
                      showUpdatedScript: !state.showUpdatedScript,
                    }));
                  }}
                />
              </label>
              <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                Run script on node updated
              </span>
            </div>
          </div>
        </div>
        {state.showUpdatedScript ? (
          <div className={"d-flex mt-3"}>
            <div className="py-1 w-33">
              <div className="d-flex justify-content-between">
                {/* <label className='align-self-center m-0'>Node Updated Script</label> */}
                {state.hasError && !state.on_node_updated_script ? (
                  <small className="text-issues">required</small>
                ) : null}
              </div>
              <Input
                type="text"
                placeholder="Enter s3 file path"
                className="inputTextbox border-lightGray bg-transparent text-white"
                // maxlength='128'
                value={
                  state.on_node_updated_script
                    ? state.on_node_updated_script
                    : ""
                }
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    on_node_updated_script: e.target.value,
                  }));
                  handleConfigurationInputChange(
                    "on_node_updated_script",
                    e.target.value
                  );
                }}
              />
            </div>
          </div>
        ) : null}
      </div>
      <p className="text-white f16 mt-3">
        Security Configuration and Permissions
      </p>
      <div className="bg-dark3 p-3 rounded mt-3">
        <div className={"d-flex"}>
          <div className="py-1 w-100">
            <div className="w-33">
              <div className="d-flex justify-content-between">
                <label className="align-self-center m-0">Key pair</label>
                {state.hasError && !state["key_name"] ? (
                  <small className="text-issues">required</small>
                ) : null}
              </div>
              <div
                className="border-lightGray bg-transparent rounded-5"
                onClick={(event) => {
                  if (!state["isKeyPair"]) {
                    event.preventDefault();
                    handleChildClick(
                      event,
                      "child",
                      "singleDropDown",
                      "isKeyPair"
                    );
                  }
                }}
              >
                <p
                  className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                    state["key_name"] && props.pageMode === "Create"
                      ? "text-white"
                      : "placeholder"
                  }`}
                >
                  {state["key_name"]
                    ? propsClusterFilters.key_pair.filter(
                        (e) => e.value === state["key_name"]
                      ).length
                      ? propsClusterFilters.key_pair.filter(
                          (e) => e.value === state["key_name"]
                        )[0].key
                      : state["key_name"]
                    : "Select"}
                  <i className="fa fa-caret-down mt-1" />
                </p>
                {state["isKeyPair"] && props.pageMode === "Create" ? (
                  <MultiSelectSection
                    fields={["value", "key"]}
                    options={
                      propsClusterFilters && propsClusterFilters.key_pair
                        ? propsClusterFilters.key_pair.map((d) => ({
                            key: d,
                            value: d,
                          }))
                        : []
                    }
                    selectedValues={state["key_name"] ? state["key_name"] : ""}
                    callbackMultiSelect={(value) => {
                      if (!value || typeof value === "string") {
                        handleConfigurationInputChange("key_name", value);
                        setState((prevState) => ({
                          ...prevState,
                          ["key_name"]: value,
                        }));
                      } else {
                        value.preventDefault();
                        handleChildClick(value, "search", "singleDropDown", "");
                      }
                    }}
                    singleSelection={true}
                    hideSearch={false}
                    topClass={"auto"}
                    widthClass={"minWidth220"}
                  />
                ) : null}
              </div>
            </div>
            {propsClusterFilters &&
            propsClusterFilters.description &&
            propsClusterFilters.description.cluster_configuration &&
            propsClusterFilters.description.cluster_configuration.key_name ? (
              <p className="mt-1 mb-0 text-info">
                {propsClusterFilters.description.cluster_configuration.key_name}
              </p>
            ) : null}
          </div>
        </div>
        <div className={"d-flex mt-3"}>
          <div className="py-1 w-100">
            <div className="w-33">
              <div className="d-flex justify-content-between">
                <label className="align-self-center m-0">Allowed IPs</label>
                {state.hasError && !state.allowed_ips ? (
                  <small className="text-issues">required</small>
                ) : null}
              </div>
              <Input
                type="text"
                placeholder="Enter ip"
                className="inputTextbox border-lightGray bg-transparent text-white"
                // maxlength='128'
                value={state.allowed_ips ? state.allowed_ips : ""}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    allowed_ips: noSpace(e.target.value),
                  }));
                  handleConfigurationInputChange(
                    "allowed_ips",
                    noSpace(e.target.value)
                  );
                }}
              />
            </div>
            {propsClusterFilters &&
            propsClusterFilters.description &&
            propsClusterFilters.description.cluster_configuration &&
            propsClusterFilters.description.cluster_configuration
              .allowed_ips ? (
              <p className="mt-1 mb-0 text-info">
                {
                  propsClusterFilters.description.cluster_configuration
                    .allowed_ips
                }
              </p>
            ) : null}
          </div>
        </div>
        <div className={"mt-3"}>
          {/* <div className='py-1 w-67'>
                        <div onClick={(event) => {
                            event.preventDefault();
                            handleChildClick(event, 'child', 'muliselectDropDown', "showPolicies")
                        }}>
                            <p className='b-block mb-0 d-flex'>
                                IAM policies <span className="fa fa-star text-purplishRed ml-1 f8 align-self-center"></span>
                                {state.inputValidationError && (!state.policies || !state.policies.length) ?
                                    <span className='mb-0 text-red ml-2'>required</span>
                                : null}
                            </p>
                            <p className={`d-flex justify-content-between border-lightGray rounded-5 mb-0 align-self-center px-2 pt-2 ${state.policies && state.policies.length ? 'text-white' : 'placeholder'}`} style={{height: "38px"}}>
                                {state.policies && state.policies.length ? state.policies.length+' Selected' : 'Select'}
                                <i className='fa fa-caret-down mt-1'/>
                            </p>
                            {state.showPolicies && props.pageMode !== 'Edit' ?
                                <div className='position-relative'>
                                    <MultiSelectSection 
                                        // fields={["account_id", "account_name"]}
                                        // className={'position-unset'}
                                        removeTop={true}
                                        options={propsClusterFilters && propsClusterFilters.policies ? propsClusterFilters.policies : []}
                                        selectedValues={state.policies ? state.policies : []}
                                        callbackMultiSelect={(value) => {
                                            setState(prevState => ({ ...prevState, policies: value }))
                                            handleConfigurationInputChange('policies', value)
                                        }}
                                        widthClass={'minWidth220'}
                                    />
                                </div>
                            : null}
                        </div>
                    </div>
                    {state.policies && Array.isArray(state.policies) && state.policies.length ? 
                        state.policies.map(row => {
                            return(
                                <span className='badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2'>{row}
                                    {props.pageMode !== 'View' ?
                                        <i className='ml-2 f14 fas fa-times-circle cursorPointer text-gray3' onClick={ () => removePolicies( row) } ></i>
                                    : null}
                                </span>
                            )
                        })
                    : null} */}
          <div className="py-1 w-67">
            <div className="d-flex justify-content-between">
              <label className="align-self-center m-0">IAM Policies</label>
              {state.hasError && !state.policy ? (
                <small className="text-issues">required</small>
              ) : null}
            </div>
            <div className="d-flex">
              <Input
                type="text"
                placeholder="Enter policy"
                className="inputTextbox border-lightGray bg-transparent text-white"
                // maxlength='128'
                value={state.policy ? state.policy : ""}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    policy: e.target.value,
                  }));
                }}
              />
              <span
                className={`far fa-plus cursor-pointer f18 mb-1 align-self-center ml-3`}
                onClick={() => addPolicies()}
              ></span>
            </div>
          </div>
          {state.policies &&
          Array.isArray(state.policies) &&
          state.policies.length
            ? state.policies.map((row) => {
                return (
                  <span className="badge border-gray3 mr-2 mt-2 align-self-center f14 text-info px-2">
                    {row}
                    <i
                      className="ml-2 f14 fas fa-times-circle cursorPointer text-gray3"
                      onClick={() => removePolicies(row)}
                    ></i>
                  </span>
                );
              })
            : null}
        </div>
      </div>

      <p className="b-block text-white mb-2 mt-3">Local Storage</p>
      <div className="rounded bg-dark2 p-3 mb-3">
        <div className="d-flex">
          <div className="py-1 w-20">
            <p className="b-block mb-0">Root Volume Size (GB)</p>
            <Input
              type="number"
              placeholder="Enter volume size"
              className={`inputTextbox bg-transparent border-lightGray ${
                props.pageMode !== "Create"
                  ? "disabled text-primary-color"
                  : "text-white"
              }`}
              // maxlength='128'
              value={state.volume_size ? state.volume_size : ""}
              onChange={(e) => {
                if (props.pageMode === "Create") {
                  handleConfigurationInputChange(
                    "volume_size",
                    onlyNumeric(e.target.value),
                    "integer"
                  );
                }
              }}
            />
          </div>
          <div className="py-1 w-20 ml-3">
            {/* <p className='b-block mb-0 d-flex'>
                            Volume Type
                        </p> */}
            <div className="d-flex justify-content-between">
              <label className="align-self-center m-0">Volume Type</label>
              {state.hasError && state.volume_type ? (
                <small className="text-issues">required</small>
              ) : null}
            </div>
            <div
              className="border-lightGray bg-transparent rounded-5"
              onClick={(event) => {
                if (!state.showVollumeType) {
                  event.preventDefault();
                  handleChildClick(
                    event,
                    "child",
                    "singleDropDown",
                    "showVollumeType"
                  );
                }
              }}
            >
              <p
                className={`d-flex bg-transparent justify-content-between mt-2 px-2 mb-2 hiddenEllipses ${
                  state.volume_type && props.pageMode === "Create"
                    ? "text-white"
                    : "placeholder"
                }`}
              >
                {state.volume_type ? state.volume_type : "Select"}
                {props.pageMode === "Create" ? (
                  <i className="fa fa-caret-down mt-1" />
                ) : null}
              </p>
              {state.showVollumeType && props.pageMode === "Create" ? (
                <MultiSelectSection
                  options={
                    propsClusterFilters && propsClusterFilters.volume_type
                      ? propsClusterFilters.volume_type
                      : []
                  }
                  selectedValues={state.volume_type ? state.volume_type : ""}
                  callbackMultiSelect={(value) => {
                    if (!value || typeof value === "string") {
                      setState((prevState) => ({
                        ...prevState,
                        volume_type: value,
                      }));
                      handleConfigurationInputChange("volume_type", value);
                    } else {
                      value.preventDefault();
                      handleChildClick(value, "search", "singleDropDown", "");
                    }
                  }}
                  singleSelection={true}
                  hideSearch={false}
                  topClass={"auto"}
                  widthClass={"minWidth220"}
                />
              ) : null}
            </div>
            {/* {props.pageMode !== 'Create' ?
                            <p className='mb-0 text-white'>{state.volume_type ? state.volume_type : ''}</p>        
                        :
                            <Input
                                type='text'
                                placeholder='Enter volume type'
                                className={`inputTextbox bg-transparent border-mediumDarkGray text-white`}
                                // maxlength='128'
                                value={state.volume_type ? state.volume_type : ''}
                                onChange={e => handleConfigurationInputChange('volume_type', e.target.value)}
                            />
                        } */}
          </div>
          <div className="py-1 w-20 pl-3 mt-3 align-self-center">
            <div className="d-flex align-self-center mr-3">
              <div className="d-flex mr-2 align-self-center">
                <label className="mb-0">
                  <input
                    type="checkbox"
                    className="inputdemo mt-1"
                    checked={state.volume_encrypted ? true : false}
                    disabled={props.pageMode !== "Create" ? true : false}
                    onChange={(e) => {
                      handleConfigurationInputChange(
                        "volume_encrypted",
                        !state.volume_encrypted
                      );
                    }}
                  />
                  <span className="slider round small pl-1"></span>
                </label>
              </div>
              <span className="mb-0 text-lightGray f16">
                Encrypt Root Volume
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-2">
        <p className="d-flex b-block text-white my-2">S3 Access</p>
        <div className="rounded bg-dark2 p-3 mb-3">
          {state.s3AccessArray
            ? state.s3AccessArray.map((index, i) => {
                return (
                  <React.Fragment>
                    <div className="d-flex mt-2">
                      <div className="py-1 w-20">
                        <p className="b-block mb-0">
                          Bucket Name
                          {state.inputValidationError &&
                          state["enable_write_access_" + index] &&
                          (!state["bucket_name_" + index] ||
                            state["bucket_name_" + index] === "") ? (
                            <span className="mb-0 text-red ml-2">required</span>
                          ) : null}
                        </p>

                        <Input
                          type="text"
                          placeholder="Enter bucket name"
                          className={`inputTextbox bg-transparent border-lightGray text-white`}
                          // maxlength='128'
                          value={
                            state["bucket_name_" + index]
                              ? state["bucket_name_" + index]
                              : ""
                          }
                          onChange={(e) => {
                            setState((prevState) => ({
                              ...prevState,
                              ["bucket_name_" + index]: e.target.value,
                            }));
                            handleConfigurationInputChange(
                              "s3_access",
                              e.target.value,
                              "bucket_name_" + index
                            );
                          }}
                        />
                      </div>
                      <div className="py-1 w-25 pl-3 align-self-center mt-3">
                        <div className="d-flex">
                          <label className="mb-0">
                            <input
                              type="checkbox"
                              className="inputdemo mt-1"
                              checked={
                                state["enable_write_access_" + index]
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                let enable_write_access =
                                  !state["enable_write_access_" + index];
                                setState((prevState) => ({
                                  ...prevState,
                                  ["enable_write_access_" + index]:
                                    enable_write_access,
                                }));
                                handleConfigurationInputChange(
                                  "s3_access",
                                  enable_write_access,
                                  "enable_write_access_" + index
                                );
                              }}
                            />
                          </label>
                          <span className="mb-0 text-lightGray f16 ml-2 text-nowrap">
                            Enable write access
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      {state.s3AccessArray.length > 1 ? (
                        <span
                          className={`cursorPointer text-red f16 align-self-center ml-4`}
                          onClick={() =>
                            removeS3Section("s3AccessArray", index, i)
                          }
                        >
                          {" "}
                          Remove
                        </span>
                      ) : null}
                      {state.s3AccessArray.length - 1 === i ? (
                        <span
                          className={`cursorPointer text-info f16 align-self-center ml-4`}
                          onClick={() => addS3Section("s3AccessArray")}
                        >
                          {" "}
                          Add
                        </span>
                      ) : null}
                    </div>
                  </React.Fragment>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default HeadNodeSection;
